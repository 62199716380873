import React from 'react';
import { FormRow, Modal } from '@commandbar/design-system/components/antd';
import { CategorySelect } from '../CommmandDetail/CategorySelect';
import { Plus } from '@commandbar/design-system/icons/react';
import { CmdButton } from '@commandbar/design-system/cmd';

interface IProps {
  onCreate: (categoryID: number) => void;
  type: 'page' | 'action';
  disabled?: boolean;
}

const NewCommandModal = (props: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number | undefined>(undefined);

  const { onCreate } = props;

  const MAX_WIDTH = 450;

  const onClose = () => setOpen(false);
  const onTriggerClick = () => {
    if (props.disabled) {
      return;
    }
    setOpen(true);
  };
  const onOk = () => {
    if (value !== undefined) {
      onCreate(value);
    }
    onClose();
  };

  const footer = [
    <CmdButton key="close" onClick={onClose}>
      Close
    </CmdButton>,
    <CmdButton key="ok" variant="primary" onClick={onOk} disabled={!value}>
      Create
    </CmdButton>,
  ];

  return (
    <>
      <CmdButton onClick={onTriggerClick} variant="primary" disabled={props.disabled} icon={<Plus />}>
        New
      </CmdButton>
      <Modal
        visible={open}
        onCancel={onClose}
        okButtonProps={{ disabled: !value }}
        title={`Create a new ${props.type}`}
        width={MAX_WIDTH}
        footer={<div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>{footer}</div>}
      >
        <div style={{ maxWidth: MAX_WIDTH - 50 }}>
          <FormRow
            title={'Category:'}
            input={<CategorySelect selectedCategoryId={undefined} onCategorySelect={(id: number) => setValue(id)} />}
          />
        </div>
      </Modal>
    </>
  );
};

export default NewCommandModal;
