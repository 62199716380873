import { GenericStep, IStepSelectionParameter, StepType, initStep } from './Step';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';

export interface MultiSelectStep extends GenericStep<StepType.MultiSelect> {
  argument: IStepArgumentType;
  selected: IStepSelectionParameter | null;
}

export const initMultiSelectStep = (argument: IStepArgumentType): MultiSelectStep => {
  const s = initStep(StepType.MultiSelect) as MultiSelectStep;
  s.argument = argument;
  s.selected = null;
  return s;
};
