import { CallbackFunction } from '@commandbar/internal/client/CommandBarClientSDK';

export const goTo = (id: string, history: any) => {
  const el = document.getElementById(id);
  if (el !== null) {
    window.scrollTo(0, el.offsetTop - 80);
  } else {
    history.push(`/docs#${id}`);
    goTo(id, history);
  }
};

export const registerRouterFunc = (history: any) => {
  const routerFunc = (newUrl: string) => {
    const isDocRoute = newUrl.startsWith('/docs') || newUrl.startsWith('/sdk');
    if (isDocRoute) {
      window.open(`https://www.commandbar.com${newUrl}`);
    } else {
      history.push(newUrl);
    }
  };

  window.CommandBar.addRouter(routerFunc);
};

export const registerCallbacks = (callbacks: Record<string, CallbackFunction<any, any>>) => {
  Object.keys(callbacks).forEach((key) => {
    window.CommandBar.addCallback(key, callbacks[key]);
  });

  return () => {
    Object.keys(callbacks).forEach((key) => {
      window.CommandBar.removeCallback(key);
    });
  };
};
