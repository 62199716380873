import React from 'react';
import styled from '@emotion/styled';
import { WarningBannerBase } from './WarningBannerBase';

const Anchor = styled.a`
  text-decoration: underline;
  font-weight: 600;
  color: #583900;
`;

export const TargetingWarning = () => (
  <WarningBannerBase>
    Targeting rules will prevent the experience from showing at this URL.{' '}
    <Anchor href="https://www.commandbar.com/docs/nudges/targeting/nudge-links/" target="_blank" rel="noreferrer">
      Learn more ↗
    </Anchor>
  </WarningBannerBase>
);

export const InterpolationWarning = () => (
  <WarningBannerBase>
    Make sure to replace the metadata keys in curly brackets with the actual values before sending this link.{' '}
  </WarningBannerBase>
);
