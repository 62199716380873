import React, { useEffect, useMemo } from 'react';
import { SortableMultiSelect } from '@commandbar/design-system/components/antd';
import * as Command from '@commandbar/internal/middleware/command';
import { useAppContext } from 'editor/src/AppStateContext';
import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { Plus } from '@commandbar/design-system/icons/react';
import { CmdButton } from '@commandbar/design-system/cmd';

interface Props {
  organization: IOrganizationType;
  updateOrganizationSettingsProperty: (
    field: keyof IOrganizationSettingsType,
    newVal: any,
  ) => Promise<IOrganizationSettingsType>;
}

const FallbackCommandsSettings = (props: Props) => {
  const { commands } = useAppContext();
  const [showSelect, setShowSelect] = React.useState<boolean>(false);

  const options = commands
    .filter((command) => !Command.isProgrammatic(command) && Command.showInDefaultList(command))
    .map((command) => ({
      label: command.text,
      value: command.id,
    }));

  const fallbackCommands = useMemo(() => {
    const fallbacks = props.organization.fallback_commands;

    return commands
      .filter((command) => fallbacks.indexOf(command.id) !== -1)
      .sort((a, b) => fallbacks.indexOf(a.id) - fallbacks.indexOf(b.id));
  }, [props.organization, commands]);

  useEffect(() => {
    if (fallbackCommands.length > 0) {
      setShowSelect(true);
    }
  }, [fallbackCommands]);

  const onChangeOrSortEnd = async (selected: typeof options) => {
    const newFallbackCommands = selected.map((o) => o.value);
    props.updateOrganizationSettingsProperty('fallback_commands', newFallbackCommands);
  };

  return (
    <>
      {fallbackCommands.length || showSelect ? (
        <SortableMultiSelect
          options={options}
          defaultValue={fallbackCommands.map((command) => ({
            label: command.text,
            value: command.id,
          }))}
          onSortEnd={onChangeOrSortEnd}
          onChange={onChangeOrSortEnd}
          menuPortalTarget={document.body}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CmdButton icon={<Plus />} size="lg" onClick={() => setShowSelect(true)}>
            Add a fallback command
          </CmdButton>
        </div>
      )}
    </>
  );
};

export default FallbackCommandsSettings;
