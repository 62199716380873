import React from 'react';
import { Modal as AntModal } from '@commandbar/design-system/components/antd';
import styled from '@emotion/styled';

const Modal = styled(AntModal)`
  .ant-modal-header {
    padding: 12px 16px;
    border-radius: 10px 10px 0 0;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-body {
    padding: 16px;
  }
`;

type Props = React.ComponentProps<typeof AntModal> & {
  maxWidth?: number;
};

/**
 * Temporary Modal overriding Ant Components before we have our own Modal component in the design system
 */
export const PreviewModal: React.FC<Props> = (props) => {
  return (
    <Modal {...props} closeIcon={null} footer={null} centered style={{ ...props.style, maxWidth: props.maxWidth }} />
  );
};
