import { cva } from 'class-variance-authority';

export const dividerStyles = cva(['relative', 'w-full', 'border-solid', 'border-0', 'border-b', 'border-gray300'], {
  variants: {
    spacing: {
      xxs: ['my-xxs'],
      xs: ['my-xs'],
      sm: ['my-sm'],
      md: ['my-md'],
      lg: ['my-lg'],
      xl: ['my-xl'],
    },
    orientation: {
      start: '',
      center: '',
      end: '',
    },
    side: {
      left: '',
      right: '',
    },
  },
  compoundVariants: [
    {
      orientation: 'start',
      side: 'left',
      className: 'w-[5%]',
    },
    {
      orientation: 'start',
      side: 'right',
      className: 'w-[95%]',
    },
    {
      orientation: 'center',
      side: 'left',
      className: 'w-[50%]',
    },
    {
      orientation: 'center',
      side: 'right',
      className: 'w-[50%]',
    },
    {
      orientation: 'end',
      side: 'left',
      className: 'w-[95%]',
    },
    {
      orientation: 'end',
      side: 'right',
      className: 'w-[5%]',
    },
  ],
});
