import { GenericStep, IStepSelectionParameter, initStep, StepType } from './Step';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';

export interface TextInputStep extends GenericStep<StepType.TextInput> {
  argument: IStepArgumentType;
  selected: IStepSelectionParameter | null;
}

export const initTextInputStep = (argument: IStepArgumentType): TextInputStep => {
  const s = initStep(StepType.TextInput) as TextInputStep;
  s.argument = argument;
  s.selected = null;
  return s;
};
