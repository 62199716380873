import { getConditions, INamedRule } from '@commandbar/internal/middleware/helpers/rules';
import {
  IArgumentType,
  IEditorCommandTypeLite,
  IRecordSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import _ from 'lodash';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { useAppContext } from 'editor/src/AppStateContext';
import { KeyStatus } from '../ActiveBadge';
import { useContextSettings } from './useContextSettings';
import * as Command from '@commandbar/internal/middleware/command';
import { SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';

export interface ContextDataObject {
  key: string;
  active: KeyStatus;
  commands: IEditorCommandTypeLite[];
}

const isActive = (context: Record<string, any>, callbackKeys: string[], key: string): KeyStatus => {
  // Active in context
  if (Boolean(context[key])) return 'active';

  // Has async search fn defined
  if (callbackKeys.includes(`commandbar-search-${key}`)) return 'search';

  // Has loader defined
  if (callbackKeys.includes(`commandbar-initialvalue-${key}`)) return 'loader';

  return 'inactive';
};

const isRecord = (key: string, contextSettings: IRecordSettingsByContextKey) => {
  return contextSettings[key]?.search === true;
};

const isArgumentChoice = (key: string, contextSettings: IRecordSettingsByContextKey) => {
  return contextSettings[key]?.search === false;
};

const findContextReferencesInString = (str: string): string[] | undefined => {
  const COMMANDBAR_CONTEXT_REFERENCE_REGEXP_EXTRACT = /((?!{{)context\.)([^{{]+)(?=}})+/g;
  return str
    .match(COMMANDBAR_CONTEXT_REFERENCE_REGEXP_EXTRACT)
    ?.map((match: string) => match.replace(/^(context.)/, ''));
};

const getReferencedMetadataFromRule = (rule: INamedRule): string[] => {
  const result = [];
  for (const condition of getConditions(rule.expression)) {
    if (condition.type === 'context' && !!condition.field) {
      result.push(condition.field);
    }
  }
  return result;
};

const getReferencedMetadataFromCommand = (command: IEditorCommandTypeLite, rules: INamedRule[]): string[] => {
  const referencedMetadata: string[] = [];

  // availability + recommendations
  [...getConditions(command.recommend_expression), ...getConditions(command.availability_expression)].forEach(
    (condition) => {
      if (condition.type === 'context' && !!condition.field) referencedMetadata.push(condition.field);
      if (condition.type === 'named_rule') {
        const namedRule = rules.find((r) => r.id === condition.rule_id);
        if (namedRule) {
          referencedMetadata.push(...getReferencedMetadataFromRule(namedRule));
        }
      }
    },
  );

  // text
  const matches = findContextReferencesInString(command.text);
  if (matches) {
    referencedMetadata.push(...matches);
  }

  // url
  const url = command.template.type === 'link' && command.template.value;
  if (url) {
    const matches = findContextReferencesInString(url);
    if (matches) {
      referencedMetadata.push(...matches);
    }
  }

  return Array.from(new Set(referencedMetadata));
};

export const useContextPartition = (): {
  records: ContextDataObject[];
  recordsByKey: { [key: string]: ContextDataObject };
  args: ContextDataObject[];
  metadata: ContextDataObject[];
} => {
  const { current: contextSettings } = useContextSettings();
  const { commands, rules } = useAppContext();
  const {
    context: currentContext,
    allCallbacks: callbackKeys,
    programmaticCommands,
  } = useWindowInfo([contextSettings]);

  const getContextArguments = (commands: IEditorCommandTypeLite[]): IArgumentType[] => {
    return commands.reduce<IArgumentType[]>((acc, cur) => {
      const args = Object.values(cur.arguments);

      const relatedArguments = args.filter((argument) => argument.type === 'context');

      return acc.concat(relatedArguments);
    }, []);
  };

  const getRelatedCommands = (
    record: string,
    allCommands: IEditorCommandTypeLite[],
    filterForRecordAction?: boolean,
  ): IEditorCommandTypeLite[] => {
    return [...allCommands, ...programmaticCommands].filter((c) => {
      if (filterForRecordAction) {
        return Command.isRecordAction(c, record);
      } else {
        const args = Object.values(c.arguments);
        return !!args.find((argument) => argument.type === 'context' && argument.value === record);
      }
    });
  };

  const keysFromCurrentContext = Object.keys(currentContext);

  const referencedKeys = commands.flatMap((c) => getReferencedMetadataFromCommand(c, rules));
  const base = Array.from(
    new Set([
      ...keysFromCurrentContext,
      ...referencedKeys,
      ...getContextArguments(commands).map((v) => v.value as string),
    ]),
  );

  const records: ContextDataObject[] = [];
  const args: ContextDataObject[] = [];
  const metadata: ContextDataObject[] = [];

  const commandsByReferencedKeys: Record<string, IEditorCommandTypeLite[] | undefined> = {};
  commands.forEach((command) => {
    const referencedKeys = getReferencedMetadataFromCommand(command, rules);

    referencedKeys.forEach((referencedKey) => {
      const array = (commandsByReferencedKeys[referencedKey] ||= []);
      array.push(command);
    });
  });

  // foreach item in base, place in one of {records, args, metadata}
  // excludes keys with SDK_INTERNAL_PREFIX -- these shouldn't be shown in the Editor UI at all
  base
    .filter((key) => !key.startsWith(SDK_INTERNAL_PREFIX))
    .forEach((key) => {
      if (isRecord(key, contextSettings)) {
        records.push({
          key,
          active: isActive(currentContext, callbackKeys, key),
          commands: getRelatedCommands(key, commands),
        });
      } else if (isArgumentChoice(key, contextSettings)) {
        args.push({
          key,
          active: isActive(currentContext, callbackKeys, key),
          commands: getRelatedCommands(key, commands),
        });
      } else {
        // metadata
        // add in referenced keys
        metadata.push({
          key,
          active: isActive(currentContext, callbackKeys, key),
          commands: commandsByReferencedKeys[key] ?? [],
        });
      }
    });

  const recordsByKey = _.chain(records)
    .groupBy((record) => record.key)
    .mapValues((x) => x[0])
    .value();

  return { records, recordsByKey, args, metadata };
};
