import Hotkey from './Hotkey';
import { MousetrapInstance } from './mousetrap_fork';

// Inspired by mousetrap-record https://github.com/davejm/mousetrap-record/blob/master/mousetrap-record.js
export class KeyRecorder {
  public mousetrap: MousetrapInstance;
  public onType?: (val: string) => void;
  public onFinish?: (val: string | undefined) => void;
  public validate: boolean;

  // are we recording?
  public recording = true;

  // the transformed master sequence of what to return
  public recordedSequence: Array<Array<string>> = [];

  // the raw user input, not transformed yet
  public currentRecordedKeys: Array<string> = [];
  public recordedCharacterKey = false;
  public recordTimer = -1;
  public comboCount = 0;

  public constructor(
    mousetrap: MousetrapInstance,
    onType?: (val: string) => void,
    onFinish?: (val: string | undefined) => void,
    validate = true,
  ) {
    this.mousetrap = mousetrap;

    this.mousetrap.handleKey = this.handleKey;

    this.onType = onType;
    this.onFinish = onFinish;
    this.validate = validate;
  }

  public handleKey = (character: string, modifiers: Array<string>, e: KeyboardEvent) => {
    if (this.recording) {
      e.stopPropagation();
      e.preventDefault();
      if (e.type === 'keydown') {
        if (!Hotkey.isModifier(character) && this.recordedCharacterKey) {
          this.recordCurrentCombo();
        }

        modifiers.map((modifier: string) => {
          this.recordKey(modifier);
          return null;
        });
        this.recordKey(character);

        if (!!this.onType) {
          const liveResults = this.recordedSequence.slice(0, this.comboCount);
          liveResults.push(this.currentRecordedKeys);
          this.onType(Hotkey.normalizeSequence(liveResults));
        }
      } else if (e.type === 'keyup' && this.currentRecordedKeys.length > 0) {
        this.recordCurrentCombo();
        this.comboCount = this.comboCount + 1;
      }
    }
  };

  public finishRecording = () => {
    if (!!this.onFinish) {
      let final: string | undefined = Hotkey.normalizeSequence(this.recordedSequence);

      if (this.validate && !Hotkey.validate(final, true)) final = undefined;
      this.onFinish(final);
    }

    this.recording = false;
  };

  public recordKey = (key: string) => {
    if (this.currentRecordedKeys.includes(key)) {
      return;
    }

    this.currentRecordedKeys.push(key);

    if (!Hotkey.isModifier(key)) {
      this.recordedCharacterKey = true;
    }
  };

  public stop = () => {
    this.recording = false;
  };

  public recordCurrentCombo = () => {
    this.recordedSequence.push(this.currentRecordedKeys);
    this.currentRecordedKeys = [];
    this.recordedCharacterKey = false;
    this.restartRecordTimer();
  };

  // Wait for 1 second after the last keypress before exiting
  public restartRecordTimer = () => {
    clearTimeout(this.recordTimer);
    this.recordTimer = window.setTimeout(this.finishRecording, 1000);
  };
}
