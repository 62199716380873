import * as t from 'io-ts';

import { read } from './generics';
import { ChatV } from './chat';

export const ChatAnalyticsV = t.type({
  total_count: t.number,
  current_count: t.number,
  next: t.union([t.null, t.number]),
  previous: t.union([t.null, t.number]),
  chat_id_found: t.boolean,

  results: t.array(ChatV),
});

export class ChatAnalytics {
  public static listChats = read(ChatAnalyticsV, 'ml/chats');
}
