import { Option, CommandOption } from '.';
import type { RequestType } from '@cb/types/entities/command/actions';
import type { ICommandType } from '@commandbar/internal/middleware/types';
import _get from 'lodash/get';
import { interpolate, interpolateObject } from 'shared/util/Interpolate';
import { checkSelector } from '@commandbar/internal/util/dom';
import ClientSearch from '../../search/ClientSearch';
import { CBStore } from 'shared/store/global-store';
import { isCommandOption } from '../../selectors';
import { InternalError } from 'shared/util/Errors';
import Logger from '@commandbar/internal/util/Logger';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { getSentry } from '@commandbar/internal/util/sentry';
import { isCommandAvailable } from 'shared/services/targeting/command-targeting';
import { passesAudienceConditions } from 'shared/services/targeting/audience';

export const hasInitialValueFnDefined = (key: string, _: CBStore) =>
  Object.keys(_.callbacks).some((callbackKey: string) => callbackKey.includes(`commandbar-initialvalue-${key}`));

const isValidArg = (command: ICommandType, arg: string, _: CBStore): { isValid: boolean; isValidReason: string } => {
  const invalid = (msg: string) => {
    return { isValid: false, isValidReason: msg };
  };

  const valid = () => {
    return { isValid: true, isValidReason: '' };
  };
  const argumentConfig = command.arguments[arg];

  if (!argumentConfig) {
    getSentry()?.captureMessage('Config for argument is missing.', 'error', {
      captureContext: {
        contexts: {
          command,
        },
      },
    });

    return invalid(`Config for ${arg} is missing.`);
  }

  switch (argumentConfig.type) {
    case 'context':
      const options = _get(_.context, argumentConfig.value);

      // If object search is defined for an argument, validate the arg
      // Don't invalidate if empty. Otherwise this could invalidate commands
      // when the context keys are empty on search
      if (ClientSearch.isDefined(argumentConfig.value, _)) {
        return valid();
      }

      if (hasInitialValueFnDefined(argumentConfig.value, _)) {
        return valid();
      }

      if (options === undefined) {
        return invalid(`${argumentConfig.value} isn't defined in context.`);
      }

      if (!Array.isArray(options)) {
        return invalid(`${argumentConfig.value} isn't an array in context.`);
      }

      if (options.length === 0) {
        return invalid(`${argumentConfig.value} is an empty array in context.`);
      }

      return valid();
    default:
      return valid();
  }
};

// VA: FIXME: This doesn't need to be called on every argument (like it is today) vs. at the template level
const isClickableArg = (
  command: ICommandType,
  arg: string,
  _: CBStore,
): { isClickable: boolean; isClickableReason: string } => {
  const notClickable = (msg: string) => {
    return { isClickable: false, isClickableReason: msg };
  };

  const clickable = () => {
    return { isClickable: true, isClickableReason: '' };
  };

  if (
    command.template.type === 'click' ||
    command.template.type === 'clickBySelector' ||
    command.template.type === 'clickByXpath'
  ) {
    let values;
    try {
      values = command.template.value.map((el: any) => interpolate(el, _, true, true));
    } catch (e) {
      return notClickable(String(e));
    }

    if (!checkSelector(values[0])) {
      return notClickable(`Cannot find element to click: [${values[0]}]`);
    }
  }
  return clickable();
};

export const isAvailable = (
  option: Option,
  _: CBStore,
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  if (!isCommandOption(option)) {
    return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }

  if (
    option.command.show_in_spotlight_search === false &&
    _.flags?.['release-search-experiences-in-spotlight'] &&
    option.command.template.type !== 'helpdoc'
  ) {
    return { isAvailable: false, isAvailableReason: 'Not available in Spotlight search', reasonIsUserDefined: false };
  }

  if (_.flags?.['release-audiences-for-command-types'] && !passesAudienceConditions(_, 'commands', option.command)) {
    return { isAvailable: false, isAvailableReason: 'Not available for current audience', reasonIsUserDefined: false };
  }

  return isCommandAvailable(option.command, _);
};

// DEPRECATED

const isExecutable = (command: ICommandType, _: CBStore) => {
  switch (command.template.type) {
    case 'helpdoc':
    // falls through
    case 'link':
      // Is the interpolated URL valid?
      const _url = interpolate(command.template.value, _, true, true, true);

      switch (command.template.operation) {
        case 'router':
          const routerFunc = _.callbacks['commandbar-router'];
          if (!routerFunc) {
            throw new InternalError('Link is of router type, but router is not defined.');
          }
          return;
        case 'self':
        // falls through
        case 'blank':
        // falls through
        default:
          return;
      }
    case 'admin':
    // falls through
    case 'builtin':
    // falls through
    case 'callback':
      const callbackName = command.template.value;
      const callback = _.callbacks[callbackName];

      if (!callback) {
        throw new InternalError(`Callback is not available: [${callbackName}]`);
      }

      return;
    case 'clickByXpath':
    // falls through
    case 'clickBySelector':
    // falls through
    case 'click':
      const values = command.template.value.map((el: any) => interpolate(el, _, true, true));
      if (!checkSelector(values[0])) {
        throw new InternalError(`Cannot find element to click: [${values[0]}]`);
      }
      return;
    case 'appcues':
      if (!(window as any).Appcues || !(window as any).Appcues.show) {
        throw new InternalError('Appcues is not available');
      }
      return;
    case 'pendo_guide':
      if (!(window as any).pendo || !(window as any).pendo.showGuideById) {
        throw new InternalError('Pendo is not available');
      }
      return;
    case 'request':
      const { url, headers, body, onSuccess, onError } = command.template.value as RequestType;

      // XXX: The interpolateObject function throws an error if the object is not valid.
      const _interpolatedRequest = interpolateObject({
        s: {
          url,
          headers,
          body,
        },
        _,
        interpolateContext: true,
        interpolateArgs: true,
      }) as Omit<RequestType, 'method'>;

      let onSuccessFunc: any = undefined;
      if (onSuccess) {
        onSuccessFunc = _.callbacks[onSuccess];
        if (!onSuccessFunc) {
          Logger.error(`onSuccess callback for request is not available, [${onSuccess}]`);
        }
      }

      let onErrorFunc: any = undefined;
      if (onError) {
        onErrorFunc = _.callbacks[onError];
        if (!onErrorFunc) {
          Logger.error(`onError callback for request is not available, [${onError}]`);
        }
      }
      return;
    case 'webhook':
    // falls through
    case 'video':
    // falls through
    case 'trigger':
    // falls through
    case 'script':
      return;
    default:
      throw new InternalError('Invalid command execute type.');
  }
};

/**
 * Checks whether all of a command's arguments are presently valid,
 * in order to filter out commands which can't be executed from
 * the user's viewable options.
 */
export const isValid = (commandOption: CommandOption, _: CBStore): { isValid: boolean; isValidReason: string } => {
  const args = Object.keys(commandOption.command.arguments);

  // AND across args
  for (const arg of args) {
    const { isValid, isValidReason } = isValidArg(commandOption.command, arg, _);
    if (!isValid) {
      return { isValid, isValidReason };
    }
    const { isClickable, isClickableReason } = isClickableArg(commandOption.command, arg, _);
    if (!isClickable) {
      return { isValid: isClickable, isValidReason: isClickableReason };
    }
  }

  // we don't need to validate commands on the standalone editor
  if (isStudioPreview()) {
    return { isValid: true, isValidReason: '' };
  }

  try {
    isExecutable(commandOption.command, _);
  } catch (err) {
    if (err instanceof InternalError) {
      return { isValid: false, isValidReason: err.message };
    } else {
      getSentry()?.captureException(err);

      return { isValid: false, isValidReason: 'Failed to validate if command is executable' };
    }
  }

  return { isValid: true, isValidReason: '' };
};
