import {
  AT_SUGGESTIONS,
  DATE_ONLY_IN_UNITS_PLURAL,
  DATE_ONLY_IN_UNITS_SINGULAR,
  IN_UNITS_PLURAL,
  IN_UNITS_SINGULAR,
  MONTHS,
  THIS_NEXT_UNITS,
  WEEKDAYS,
} from './constants';
import { DATE_AND_TIME_FORMAT_ID } from './DateTime';
import {
  getSuggestionsFromStringArray,
  normalizeNumber,
  parseDate,
  parseToSuggestionFormat,
  suggestAt,
} from './helpers';

const getInUnits = (isSingular: boolean, formatId: number) => {
  if (isSingular) {
    return formatId === DATE_AND_TIME_FORMAT_ID ? IN_UNITS_SINGULAR : DATE_ONLY_IN_UNITS_SINGULAR;
  }

  return formatId === DATE_AND_TIME_FORMAT_ID ? IN_UNITS_PLURAL : DATE_ONLY_IN_UNITS_PLURAL;
};

export const handleInSuggestions = (formatId: number, beginning: string, secondWord: string, thirdWord: string) => {
  const number = normalizeNumber(secondWord);

  if (MONTHS.includes(secondWord)) {
    return parseToSuggestionFormat(parseDate(`${beginning} ${secondWord}`), formatId);
  }

  const isSingularUnits = number === 1 || number === 'one';

  const inUnits = getInUnits(isSingularUnits, formatId);

  if (!thirdWord) {
    const withSuggestedUnits = inUnits.map((unit) => `${beginning} ${number} ${unit}`);
    return getSuggestionsFromStringArray(withSuggestedUnits, formatId);
  }

  const suggestedThirdWord = inUnits.find((unit) => unit.startsWith(thirdWord));

  if (suggestedThirdWord) {
    const string = `${beginning} ${number} ${suggestedThirdWord}`;
    return parseToSuggestionFormat(parseDate(string), formatId, string);
  }
};

export const handleOnSuggestions = (
  formatId: number,
  beginning: string,
  secondWord: string,
  thirdWord: string,
  completeString: string,
) => {
  if (!secondWord) {
    return getSuggestionsFromStringArray(
      WEEKDAYS.map((day) => `on ${day}`),
      formatId,
    );
  }

  const day = WEEKDAYS.find((day) => day.toLowerCase().includes(secondWord.trim().toLowerCase()));

  if (day && !thirdWord) {
    return parseToSuggestionFormat(parseDate(`${beginning} ${day}`), formatId);
  }

  if (thirdWord !== 'at') {
    return parseToSuggestionFormat(parseDate(completeString), formatId);
  }

  const words = completeString.split(' ');
  const indexOfAt = words.indexOf('at');

  if (indexOfAt !== -1 && indexOfAt !== 0 && words.length - indexOfAt < 3) {
    const lastWord = words[words.length - 1];
    if (lastWord && lastWord !== 'at') {
      return parseToSuggestionFormat(parseDate(completeString), formatId);
    }

    return getSuggestionsFromStringArray(suggestAt(words), formatId);
  }

  return parseToSuggestionFormat(parseDate(completeString), formatId);
};

export const handleThisNextSuggestions = (
  formatId: number,
  beginning: string,
  secondWord: string,
  thirdWord: string,
) => {
  if (!secondWord) {
    return getSuggestionsFromStringArray(
      THIS_NEXT_UNITS.map((unit) => `${beginning} ${unit}`),
      formatId,
    );
  }

  const toSuggest = WEEKDAYS.concat(THIS_NEXT_UNITS).filter((day) => day.startsWith(secondWord));

  if (toSuggest.length && !thirdWord) {
    return getSuggestionsFromStringArray(
      toSuggest.map((suggestion) => `${beginning} ${suggestion}`),
      formatId,
    );
  }
};

export const handleAtSuggestions = (
  formatId: number,
  beginning: string,
  secondWord: string,
  thirdWord: string,
  completeString: string,
) => {
  if (secondWord) {
    return parseToSuggestionFormat(parseDate(completeString), formatId);
  }

  return getSuggestionsFromStringArray(AT_SUGGESTIONS, formatId);
};

export const handleNumberSuggestion = (integer: string, formatId: number) => {
  const isSingular = parseInt(integer, 10) === 1;
  const day = isSingular ? 'day' : 'days';
  const week = isSingular ? 'week' : 'weeks';

  const suggestions = [`in ${integer} ${day}`, `in ${integer} ${week}`];
  return getSuggestionsFromStringArray(suggestions, formatId);
};
