import { runBooleanExpression } from '@commandbar/commandbar/shared/services/targeting/helpers';
import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import { CBStore } from 'shared/store/global-store';

export const dontShowChecklist = (_: CBStore, checklist: IChecklist) => {
  const checklistData = getChecklistData(_, checklist);
  return checklistData?.isCompleted || checklistData?.isSkipped;
};

export const previouslyShownChecklist = (_: CBStore) => {
  return _.checklists.find((checklist) => {
    return checklist.id === _.endUserStore.data.checklist_interactions?.activeChecklistId;
  });
};

export const getChecklistData = (_: CBStore, checklist: IChecklist) => {
  return _.endUserStore.data.checklist_interactions?.checklists?.[checklist.id];
};

export const isItemCompleted = (_: CBStore, parent: IChecklist, item: IChecklistItem) => {
  return !!getChecklistData(_, parent)?.items?.[item.id]?.completedTS;
};

export const getChecklistNumCompletedItems = (_: CBStore, checklist: IChecklist) => {
  const itemData = getChecklistData(_, checklist)?.items;
  return checklist.items.filter((item) => !!itemData?.[item.id]?.completedTS).length;
};

export const hasIncompleteItems = (_: CBStore, checklist: IChecklist) => {
  const itemData = getChecklistData(_, checklist)?.items || {};

  for (const item of checklist.items) {
    if (!itemData[item.id]?.completedTS) {
      return true;
    }
  }
  return false;
};

export const isExpanded = (_: CBStore, checklist: IChecklist) => {
  const expandedState = getChecklistData(_, checklist)?.isExpanded;
  return expandedState === undefined ? checklist.open_by_default : expandedState;
};

export const isChecklistInPreviewMode = (checklist: IChecklist) => {
  return !!checklist._editorState;
};

export const getChecklistById = (_: CBStore, id: number) => {
  return _.checklists.find((checklist) => checklist.id === id);
};

export const checklistPassesPageConditions = (_: CBStore, checklist: IChecklist) => {
  return !checklist.show_expression || runBooleanExpression(checklist.show_expression, _, '').passed;
};
