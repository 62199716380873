import { ICommandType } from '@commandbar/internal/middleware/types';
import { runBooleanConditions, runBooleanExpression } from './helpers';
import { CBStore } from 'shared/store/global-store';
import { IRule } from '@commandbar/internal/middleware/helpers/rules';

export const isCommandAvailable = (
  command: ICommandType,
  _: CBStore,
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  if (command.availability_expression) {
    const expr = command.availability_expression;

    const { passed, reason, reasonIsUserDefined } = runBooleanExpression(expr, _, 'Availability condition');
    return { isAvailable: passed, isAvailableReason: reason, reasonIsUserDefined };
  } else {
    return isCommandAvailableLegacy(command, _);
  }
};

const isCommandAvailableLegacy = (
  command: ICommandType,
  _: CBStore,
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  const conditions: IRule[] = command.availability_rules;

  if (conditions.length === 0) {
    // no conditions defined
    return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }
  const { passed, reason, reasonIsUserDefined } = runBooleanConditions(conditions, _, 'Availability condition');
  return { isAvailable: passed, isAvailableReason: reason, reasonIsUserDefined };
};
