import { CmdSheet, CmdButton, CmdButtonTabs, CmdTooltip, CmdAvatar } from '@commandbar/design-system/cmd';

import { ClockRewind, UserSquare, X } from '@commandbar/design-system/icons/react';
import { IEndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import { Code, CodeSnippet } from 'editor/src/editor/copilot/ChatDebugPanel';

const UserDetailDrawer = ({
  endUser,
  onOpenChange,
}: {
  endUser: IEndUserAdmin | null;
  onOpenChange: (value: boolean) => void;
}) => {
  if (!endUser) return null;

  return (
    <CmdSheet open={!!endUser} onOpenChange={onOpenChange} modal={true}>
      <CmdSheet.Content
        style={{ width: '560px', right: '12px' }}
        onInteractOutside={(event) => event.preventDefault()}
        closeable={false}
        overlay={true}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px' }}>
          <CmdSheet.Title style={{ margin: '0' }}>
            <span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {' '}
              <CmdAvatar size="md" variant="initials">
                {endUser.slug[0].toUpperCase()}
              </CmdAvatar>
              {endUser.slug}
            </span>
          </CmdSheet.Title>
          <CmdSheet.Close asChild>
            <CmdButton variant="link" icon={<X />} />
          </CmdSheet.Close>
        </div>
        <div
          style={{
            padding: '0px 16px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'auto',
          }}
        >
          <CmdButtonTabs
            className="w-full"
            variant="group"
            activeKey={'userProperties'}
            tabs={[
              {
                label: (
                  <CmdTooltip message={'Coming soon'}>
                    <div style={{ cursor: 'not-allowed', display: 'flex', gap: '8px', alignItems: 'center' }}>
                      {' '}
                      <ClockRewind /> History
                    </div>
                  </CmdTooltip>
                ),
                key: 'history',
              },
              {
                label: (
                  <>
                    <UserSquare /> User properties
                  </>
                ),
                key: 'userProperties',
              },
            ]}
          />

          <CodeSnippet>
            <Code>
              {JSON.stringify(endUser?.user_properties, null, 2)
                ?.split('\n')
                .map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
            </Code>
          </CodeSnippet>
        </div>
      </CmdSheet.Content>
    </CmdSheet>
  );
};

export default UserDetailDrawer;
