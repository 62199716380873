import React, { useRef, ChangeEvent, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { CB_COLORS, Icon, Tooltip } from '@commandbar/design-system/components/antd';
import {
  CmdButton,
  CmdDivider,
  CmdDropdown,
  CmdInput,
  CmdLabel,
  CmdTag,
  CmdTextarea,
  CmdTypography,
  cmdToast,
} from '@commandbar/design-system/cmd';
import { Bookmark, ImagePlus, Plus, Trash04, XClose } from '@commandbar/design-system/icons/react';
import useChatSettings from '../useEditor/useChatSettings';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';
import {
  ICopilotPersonalityAdjectivesType,
  ICopilotPersonalityResponseFormatType,
  ICopilotPersonalityResponseLengthType,
  ICopilotPersonalityType,
  ICopilotSettingsPreviewType,
} from '@commandbar/internal/middleware/types';
import Sender from 'editor/src/management/Sender';
import { isEqual } from 'lodash';
import { useAppContext } from 'editor/src/AppStateContext';
import ImageUploader, { Image } from '../components/DragUpload/ImageUploader';

interface AvatarUploadProps {
  copilotAvatar: string | null;
  copilotAvatarV2: Image | null;
  updateCopilotAvatar: (logo: string | ArrayBuffer | null) => void;
  updateCopilotAvatarV2: (image: Image | null) => void;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({
  copilotAvatar,
  copilotAvatarV2,
  updateCopilotAvatar,
  updateCopilotAvatarV2,
}) => {
  const { organization, flags } = useAppContext();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      return;
    }

    const isValidSize = file.size / 1024 / 1024 < 0.7;
    const isValidType = file.type === 'image/svg+xml';

    if (!isValidSize) {
      cmdToast.error('Image must be smaller than 700kb.');
      return;
    }

    if (!isValidType) {
      cmdToast.error('Image must be an SVG.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = (e) => {
      const fileContents = e.target?.result || '';
      updateCopilotAvatar(fileContents);
    };
    reader.readAsDataURL(file);
  };

  const handleReplaceImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleResetToDefault = () => {
    updateCopilotAvatar('');
    updateCopilotAvatarV2(null);
  };

  if (flags['release-copilot-avatar-cdn']) {
    const thumbnailImage: Image | null = (() => {
      if (copilotAvatarV2) return copilotAvatarV2;

      if (copilotAvatar) {
        return {
          src: copilotAvatar,
          file_name: 'custom_avatar.png',
          size: '',
        };
      }

      return null;
    })();

    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        {!thumbnailImage && (
          <Icon
            icon={'https://cdn.commandbar.com/_images/copilot_avatar_commandai.png'}
            style={{
              height: '32px',
              width: '32px',
              borderRadius: '6px',
            }}
          />
        )}
        <div style={{ flex: 1 }}>
          <ImageUploader
            onUpload={updateCopilotAvatarV2}
            onDelete={handleResetToDefault}
            thumbnail={thumbnailImage}
            organizationId={organization.id}
            validFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp', 'image/svg+xml']}
            supportedFilesHelperText=".svg, .png, .jpg, .webp, or .gif supported"
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '6px',
        }}
      >
        <Icon
          icon={copilotAvatar || 'https://cdn.commandbar.com/_images/copilot_avatar_commandai.png'}
          style={{
            height: '32px',
            width: '32px',
            border: copilotAvatar ? '1px solid rgba(0, 0, 0, 0.08)' : '0',
            borderRadius: '6px',
          }}
        />
      </div>

      <input
        type="file"
        accept="image/svg+xml"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <CmdButton icon={<ImagePlus />} onClick={handleReplaceImageClick} style={{ whiteSpace: 'nowrap', width: '100%' }}>
        Choose...
      </CmdButton>
      {copilotAvatar && <CmdButton icon={<Trash04 />} onClick={handleResetToDefault} />}
    </div>
  );
};

const debouncedPreviewSettings = debounce((settings: ICopilotSettingsPreviewType) => {
  Sender.previewCopilotSettings(settings);
}, 1000);

const PersonalitySettings = () => {
  const { flags } = useAppContext();
  const { copilotName, copilotAvatar, copilotAvatarV2, promptModifier, copilotPersonality, updatePersonalitySettings } =
    useChatSettings();

  const [currentCopilotName, setCurrentCopilotName] = useState(copilotName);
  const [currentCopilotAvatar, setCurrentCopilotAvatar] = useState(copilotAvatar);
  const [currentCopilotAvatarV2, setCurrentCopilotAvatarV2] = useState(copilotAvatarV2);
  const [currentCopilotPersonality, setCurrentCopilotPersonality] =
    useState<ICopilotPersonalityType>(copilotPersonality);
  const [currentCustomInstructions, setCurrentCustomInstructions] = React.useState(promptModifier);

  const [adjectiveSearch, setAdjectiveSearch] = useState('');
  const adjectiveSearchInputRef = useRef<HTMLInputElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const personalityOptionsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentCopilotName(copilotName || 'Copilot');
  }, [copilotName]);

  useEffect(() => {
    setCurrentCopilotAvatar(copilotAvatar);
  }, [copilotAvatar]);

  useEffect(() => {
    setCurrentCopilotAvatarV2(copilotAvatarV2);
  }, [copilotAvatarV2]);

  useEffect(() => {
    setCurrentCopilotPersonality(copilotPersonality || { template: 'professional' });
  }, [copilotPersonality]);

  useEffect(() => {
    setCurrentCustomInstructions(promptModifier);
  }, [promptModifier]);

  // Debounce the preview on changes to name and custom instructions
  const previewingStarted = useRef(false);
  useEffect(() => {
    if (
      currentCopilotName !== copilotName ||
      currentCopilotAvatar !== copilotAvatar ||
      currentCopilotAvatarV2 !== copilotAvatarV2 ||
      currentCustomInstructions !== promptModifier ||
      !isEqual(currentCopilotPersonality, copilotPersonality)
    ) {
      previewingStarted.current = true;
    }

    if (
      !previewingStarted.current ||
      (currentCopilotPersonality?.template === 'custom' && currentCopilotPersonality.adjectives.length === 0) ||
      currentCustomInstructions.length > 2000
    ) {
      return;
    }

    debouncedPreviewSettings({
      personality: currentCopilotPersonality,
      name: currentCopilotName,
      avatar: currentCopilotAvatar || currentCopilotAvatarV2?.src || null,
      custom_instructions: currentCustomInstructions,
    });
  }, [
    currentCopilotName,
    currentCustomInstructions,
    currentCopilotAvatar,
    currentCopilotAvatarV2,
    currentCopilotPersonality,
  ]);

  // Stop previewing when component unmounts
  useEffect(() => {
    return () => {
      debouncedPreviewSettings.cancel();
      Sender.previewCopilotSettings(null);
    };
  }, []);

  // This is ugly but need to make the width of Radix dropdowns responsive
  useEffect(() => {
    const handleResize = () => {
      if (personalityOptionsContainer.current) {
        setContainerWidth(personalityOptionsContainer.current?.clientWidth || 400);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const templateOptions: Record<ICopilotPersonalityType['template'], { name: string; description: string }> = {
    professional: {
      name: 'Professional',
      description: '🤵‍♂️ Formal, 🔧 Resourceful, 🤔 Thoughtful',
    },
    friendly: {
      name: 'Friendly',
      description: '🤝 Sociable, 🤔 Thoughtful, 🎩 Courteous',
    },
    confident: {
      name: 'Confident',
      description: '💪 Assured, 🗣️ Assertive, 🎯 Decisive',
    },
    serious: {
      name: 'Serious',
      description: '🕯️ Solemn, 🌪️ Intense, 🤔 Thoughtful',
    },
    empathetic: {
      name: 'Empathetic',
      description: '❤️️️ Compassionate, 🧠 Understanding, 👐 Supportive',
    },
    custom: {
      name: 'Custom',
      description: 'Set your own custom tone',
    },
  };

  const templateOptionsArray = Object.keys(templateOptions).map((key) => ({
    ...templateOptions[key as ICopilotPersonalityType['template']],
    value: key,
  }));

  const adjectiveOptions: Record<ICopilotPersonalityAdjectivesType, string> = {
    abrasive: '🧽 Abrasive',
    adventurous: '🌍 Adventurous',
    affable: '😊 Affable',
    arrogant: '😏 Arrogant',
    assertive: '🗣️ Assertive',
    assured: '💪 Assured',
    belligerent: '😠 Belligerent',
    brave: '🦁 Brave',
    cheerful: '😄 Cheerful',
    compassionate: '❤️ Compassionate',
    condescending: '🙄 Condescending',
    courteous: '🎩 Courteous',
    creative: '🎨 Creative',
    cynical: '🤨 Cynical',
    decisive: '🎯 Decisive',
    detached: '🌫️ Detached',
    diligent: '🐜 Diligent',
    eloquent: '📖 Eloquent',
    empathetic: '🤗 Empathetic',
    evasive: '🐍 Evasive',
    formal: '🤵 Formal',
    frivolous: '🎈 Frivolous',
    garrulous: '🗣️ Garrulous',
    generous: '💸 Generous',
    impulsive: '⚡ Impulsive',
    innovative: '💡 Innovative',
    intense: '🌪️ Intense',
    judgmental: '⚖️ Judgmental',
    jovial: '😂 Jovial',
    manipulative: '🎭 Manipulative',
    obstinate: '🐘 Obstinate',
    optimistic: '☀️ Optimistic',
    perceptive: '🔍 Perceptive',
    pessimistic: '☔ Pessimistic',
    quarrelsome: '🥊 Quarrelsome',
    respectful: '🙇‍♂️ Respectful',
    resourceful: '🔧 Resourceful',
    sarcastic: '😒 Sarcastic',
    sincere: '💖 Sincere',
    sociable: '🤝 Sociable',
    solemn: '🕯️ Solemn',
    supportive: '👐 Supportive',
    tactful: '🕊️ Tactful',
    thoughtful: '🤔 Thoughtful',
    understanding: '🧠 Understanding',
    unreliable: '🎲 Unreliable',
    vain: '💄 Vain',
    vivacious: '💃 Vivacious',
    warm: '☕ Warm',
    withdrawn: '🌑 Withdrawn',
    witty: '🤓 Witty',
    zealous: '🔥 Zealous',
  };

  const adjectiveOptionsKeys = Object.keys(adjectiveOptions) as ICopilotPersonalityAdjectivesType[];

  const updateTemplateType = (value: string) => {
    const templateValue = value as ICopilotPersonalityType['template'];

    if (templateValue === 'custom') {
      setCurrentCopilotPersonality({
        template: 'custom',
        adjectives: [],
        response_length: 'short',
        response_format: 'mixed',
      });
    } else {
      setCurrentCopilotPersonality({
        template: templateValue,
      });
    }
  };

  const addAdjective = (adjectiveStr: string) => {
    const adjective = adjectiveStr as ICopilotPersonalityAdjectivesType;
    if (currentCopilotPersonality?.template === 'custom') {
      setCurrentCopilotPersonality({
        ...currentCopilotPersonality,
        adjectives: [...(currentCopilotPersonality.adjectives || []), adjective],
      });
    }
    setAdjectiveSearch('');
  };

  const removeAdjective = (adjectiveStr: string) => {
    const adjective = adjectiveStr as ICopilotPersonalityAdjectivesType;
    if (currentCopilotPersonality?.template === 'custom') {
      setCurrentCopilotPersonality({
        ...currentCopilotPersonality,
        adjectives: (currentCopilotPersonality.adjectives || []).filter((adj) => adj !== adjective),
      });
    }
  };

  const disabledReasons: string[] = [];
  if (currentCopilotPersonality?.template === 'custom' && currentCopilotPersonality.adjectives.length === 0) {
    disabledReasons.push('You must add at least one adjective.');
  }
  if (currentCustomInstructions.length > 4000) {
    disabledReasons.push('Custom instructions must be 4000 characters or less.');
  }

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }} ref={personalityOptionsContainer}>
      <div style={{ display: 'flex', gap: 16, flexDirection: 'row' }}>
        <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
          <CmdLabel tooltip="The name displayed at the top of the chat window. Copilot can also refer to itself as this when chatting.">
            Name
          </CmdLabel>
          <CmdInput
            value={currentCopilotName}
            onChange={(e) => setCurrentCopilotName(e.target.value)}
            placeholder="Copilot"
            maxLength={50}
            style={{ width: '100%', flexGrow: 1 }}
            fullWidth
          />
        </div>
        <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
          <CmdLabel
            tooltip={
              flags['release-copilot-avatar-cdn'] ? (
                <span>Copilot's avatar shows up beside its messages in chat</span>
              ) : (
                <span>
                  Copilot's avatar shows up beside its messages in chat. Must be an SVG, should not exceed 700kb, and
                  40×40px is recommended.
                </span>
              )
            }
          >
            Avatar
          </CmdLabel>
          <AvatarUpload
            copilotAvatar={currentCopilotAvatar}
            copilotAvatarV2={currentCopilotAvatarV2}
            updateCopilotAvatar={(logo) => setCurrentCopilotAvatar(logo as string)}
            updateCopilotAvatarV2={(image) => setCurrentCopilotAvatarV2(image)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
        <CmdLabel tooltip="Copilot's personality. Choose a built-in option, or create your own.">Personality</CmdLabel>
        <CmdDropdown.Menu>
          <CmdDropdown.Trigger>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'left',
                background: 'white',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                borderRadius: '8px',
                padding: '8px 16px',
                width: containerWidth,
                flexGrow: 1,
                cursor: 'pointer',
                outline: 'none',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <CmdTypography.Body fontWeight="medium">
                  {templateOptions[currentCopilotPersonality.template].name}
                </CmdTypography.Body>
                <CmdTypography.Body fontSize="sm">
                  {templateOptions[currentCopilotPersonality.template].description}
                </CmdTypography.Body>
              </div>
              <CaretDown />
            </div>
          </CmdDropdown.Trigger>
          <CmdDropdown.Content>
            <CmdDropdown.RadioGroup value={currentCopilotPersonality.template} onValueChange={updateTemplateType}>
              {templateOptionsArray.map((template, index) => (
                <div key={template.value}>
                  {index === templateOptionsArray.length - 1 && <CmdDivider />}
                  <CmdDropdown.RadioItem
                    key={template.value}
                    value={template.value}
                    style={{
                      marginTop: index === 0 ? 0 : '4px',
                      marginBottom: index === templateOptionsArray.length - 1 ? 0 : '4px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: containerWidth - 48,
                        padding: '4px',
                      }}
                    >
                      <CmdTypography.Body fontWeight="medium">{template.name}</CmdTypography.Body>
                      <CmdTypography.Body fontSize="sm">{template.description}</CmdTypography.Body>
                    </div>
                  </CmdDropdown.RadioItem>
                </div>
              ))}
            </CmdDropdown.RadioGroup>
          </CmdDropdown.Content>
        </CmdDropdown.Menu>
      </div>

      {currentCopilotPersonality?.template === 'custom' && (
        <>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
            <CmdLabel tooltip="Use these adjectives to describe the personality of your Copilot.">
              Personality adjectives
            </CmdLabel>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 7 }}>
              {currentCopilotPersonality.adjectives.map((adjective) => (
                <div
                  key={adjective}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '4px 8px',
                    borderRadius: '20px',
                    border: `1px solid rgba(0,0,0,0.16)`,
                    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.16)',
                    color: CB_COLORS.neutral800,
                    fontWeight: 500,
                    outline: 'none',
                    cursor: 'default',
                  }}
                >
                  <CmdTypography.Body>{adjectiveOptions[adjective]}</CmdTypography.Body>
                  <XClose
                    style={{ marginLeft: '4px', cursor: 'pointer' }}
                    onClick={removeAdjective.bind(null, adjective)}
                  />
                </div>
              ))}
              {currentCopilotPersonality.adjectives.length < 3 && (
                <CmdDropdown.Menu>
                  <CmdDropdown.Trigger style={{ outline: 'none' }}>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 12px 4px 8px',
                        borderRadius: '20px',
                        border: `2px solid ${CB_COLORS.neutral300}`,
                        borderStyle: 'dashed',
                        color: CB_COLORS.neutral600,
                        fontWeight: 500,
                        cursor: 'pointer',
                        outline: 'none',
                        height: '32px',
                      }}
                    >
                      <Plus />
                      Add
                    </div>
                  </CmdDropdown.Trigger>
                  <CmdDropdown.Content style={{ transform: 'translatex(calc(50% - 32px))', padding: 0 }}>
                    <CmdDropdown.RadioGroup value={currentCopilotPersonality.template} onValueChange={addAdjective}>
                      <input
                        style={{
                          width: '100%',
                          margin: 0,
                          outline: 'none',
                          border: 0,
                          borderRadius: 0,
                          borderBottom: `1px solid ${CB_COLORS.neutral300}`,
                          boxShadow: 'none !important',
                          background: 'none',
                          padding: '6px 12px',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                        placeholder="Search tones..."
                        value={adjectiveSearch}
                        onChange={(e) => setAdjectiveSearch(e.target.value)}
                        ref={adjectiveSearchInputRef}
                        autoFocus
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          maxHeight: '255px',
                          overflowY: 'auto',
                        }}
                      >
                        {(() => {
                          const filteredAdjectives = adjectiveOptionsKeys
                            .filter((adjective) => !currentCopilotPersonality.adjectives.includes(adjective))
                            .filter((adjective) => adjective.toLowerCase().includes(adjectiveSearch.toLowerCase()));

                          if (filteredAdjectives.length === 0) {
                            return (
                              <div
                                style={{
                                  fontStyle: 'italic',
                                  color: CB_COLORS.neutral600,
                                  padding: '4px 12px 6px 12px',
                                }}
                              >
                                No results
                              </div>
                            );
                          }

                          return filteredAdjectives.map((adjective, index) => (
                            <CmdDropdown.RadioItem
                              key={adjective}
                              value={adjective}
                              style={{
                                marginTop: index === 0 ? 0 : '2px',
                                marginBottom: index === adjectiveOptionsKeys.length - 1 ? 0 : '2px',
                                width: '100%',
                                borderRadius: 0,
                              }}
                              onMouseMove={() => {
                                adjectiveSearchInputRef.current?.focus();
                              }}
                              onMouseOut={() => {
                                adjectiveSearchInputRef.current?.focus();
                              }}
                            >
                              <CmdTypography.Body>{adjectiveOptions[adjective]}</CmdTypography.Body>
                            </CmdDropdown.RadioItem>
                          ));
                        })()}
                      </div>
                    </CmdDropdown.RadioGroup>
                  </CmdDropdown.Content>
                </CmdDropdown.Menu>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', gap: 8, flexDirection: 'row' }}>
            <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
              <CmdLabel tooltip="How long Copilot's responses are.">Response length</CmdLabel>
              <CmdDropdown.Menu>
                <CmdDropdown.Trigger>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textAlign: 'left',
                      background: 'white',
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      borderRadius: '8px',
                      padding: '8px 16px',
                      cursor: 'pointer',
                      width: containerWidth * 0.5 - 8,
                      flex: 1,
                      outline: 'none',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {currentCopilotPersonality.response_length === 'short' && 'Short, terse'}
                    {currentCopilotPersonality.response_length === 'conversational' && 'Conversational'}
                    {currentCopilotPersonality.response_length === 'long' && 'Long, thorough'}
                    <CaretDown />
                  </div>
                </CmdDropdown.Trigger>
                <CmdDropdown.Content>
                  <CmdDropdown.RadioGroup
                    value={currentCopilotPersonality.response_length}
                    onValueChange={(value) => {
                      setCurrentCopilotPersonality({
                        ...currentCopilotPersonality,
                        response_length: value as ICopilotPersonalityResponseLengthType,
                      });
                    }}
                  >
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="short">
                      Short, terse
                    </CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="conversational">
                      Conversational
                    </CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="long">
                      Long, thorough
                    </CmdDropdown.RadioItem>
                  </CmdDropdown.RadioGroup>
                </CmdDropdown.Content>
              </CmdDropdown.Menu>
            </div>

            <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
              <CmdLabel tooltip="How Copilot's responses are formatted.">Response format</CmdLabel>
              <CmdDropdown.Menu>
                <CmdDropdown.Trigger>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textAlign: 'left',
                      background: 'white',
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      borderRadius: '8px',
                      padding: '8px 16px',
                      cursor: 'pointer',
                      width: containerWidth * 0.5 - 8,
                      flex: 1,
                      outline: 'none',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {currentCopilotPersonality.response_format === 'lists' && 'Lists'}
                    {currentCopilotPersonality.response_format === 'paragraphs' && 'Paragraphs'}
                    {currentCopilotPersonality.response_format === 'mixed' && 'Mixed'}
                    <CaretDown />
                  </div>
                </CmdDropdown.Trigger>
                <CmdDropdown.Content>
                  <CmdDropdown.RadioGroup
                    value={currentCopilotPersonality.response_format}
                    onValueChange={(value) => {
                      setCurrentCopilotPersonality({
                        ...currentCopilotPersonality,
                        response_format: value as ICopilotPersonalityResponseFormatType,
                      });
                    }}
                  >
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="lists">
                      Lists
                    </CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="paragraphs">
                      Paragraphs
                    </CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem style={{ width: containerWidth * 0.5 - 20 }} value="mixed">
                      Mixed
                    </CmdDropdown.RadioItem>
                  </CmdDropdown.RadioGroup>
                </CmdDropdown.Content>
              </CmdDropdown.Menu>
            </div>
          </div>
        </>
      )}

      <div style={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
        <CmdLabel
          tooltip={`Additional instructions for Copilot's behavior. For example "Respond in Spanish" or "When appropriate, mention we have a free trial".`}
        >
          Custom instructions
        </CmdLabel>
        <CmdTextarea
          name="prompt"
          value={currentCustomInstructions}
          onChange={(e) => setCurrentCustomInstructions(e.target.value)}
          placeholder="e.g. Always end off messages with Ciao Bella"
          fullWidth
          style={{
            border: `1px solid ${CB_COLORS.neutral300}`,
            height: '64px',
            resize: 'vertical',
          }}
        />
      </div>
      <CmdTag
        variant="info"
        prefixElement={<Bookmark style={{ minWidth: 14 }} />}
        style={{ padding: 6, marginTop: -6, width: '100%' }}
        dismissibleKey="copilot-personality-learn-more"
      >
        Setting up prompts for Copilot.{' '}
        <a
          href="https://www.commandbar.com/docs/copilot/settings/copilot-personality/"
          target="_blank"
          style={{ marginLeft: 3, color: 'inherit', fontWeight: 600 }}
          rel="noreferrer"
        >
          Learn more.
        </a>
      </CmdTag>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12 }}>
        {(currentCopilotAvatar !== copilotAvatar ||
          currentCopilotName !== copilotName ||
          currentCopilotPersonality !== copilotPersonality ||
          currentCustomInstructions !== promptModifier) && (
          <CmdButton
            variant="link"
            onClick={() => {
              setCurrentCopilotName(copilotName || 'Copilot');
              setCurrentCopilotAvatar(copilotAvatar || '');
              setCurrentCopilotPersonality(copilotPersonality || { template: 'professional' });
              setCurrentCustomInstructions(promptModifier);
            }}
          >
            Reset
          </CmdButton>
        )}
        <Tooltip showIf={disabledReasons.length > 0} content={disabledReasons.join(' ')}>
          <CmdButton
            variant="primary"
            onClick={() => {
              updatePersonalitySettings(
                currentCopilotName,
                currentCopilotAvatar,
                currentCopilotAvatarV2,
                currentCopilotPersonality,
                currentCustomInstructions,
              );
            }}
            disabled={
              (currentCopilotAvatar === copilotAvatar &&
                currentCopilotAvatarV2 === copilotAvatarV2 &&
                currentCopilotName === copilotName &&
                currentCopilotPersonality === copilotPersonality &&
                currentCustomInstructions === promptModifier) ||
              disabledReasons.length > 0 ||
              !currentCopilotName
            }
          >
            Save
          </CmdButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default PersonalitySettings;
