/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { SkeletonProps } from './types';
import { handleStyleElementClick } from './helpers';

const ModalThumbnail = ({ setActiveCategory, onMouseOverElement, onMouseOutElement }: SkeletonProps) => (
  <svg
    width="104"
    height="66"
    viewBox="0 0 104 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onMouseOver={onMouseOverElement}
    onMouseOut={onMouseOutElement}
    onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
    id="bar-styles-nudgeModal"
  >
    <rect width="104" height="66" rx="4" fill="#E8E8E8" />
    <g filter="url(#filter0_d_994_339)">
      <rect x="28" y="15" width="48" height="36" rx="2" fill="white" shapeRendering="crispEdges" />
      <rect x="32" y="19" width="31.5882" height="2" fill="#E7E7E9" />
      <rect x="32" y="23" width="40" height="24" fill="#E7E7E9" />
      <g clipPath="url(#clip0_994_339)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49 32.7823C49 32.1882 49.6371 31.8115 50.1579 32.0978L54.9662 34.7428C55.5058 35.0394 55.5058 35.8148 54.9662 36.1115L50.1583 38.7565C49.6375 39.0428 49.0004 38.6661 49.0004 38.0719V32.7823H49Z"
          fill="#2755F0"
        />
      </g>
      <rect x="68.6655" y="19" width="3.33468" height="2" fill="#E7E7E9" />
    </g>
    <defs>
      <filter
        id="filter0_d_994_339"
        x="24"
        y="15"
        width="56"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_994_339" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_994_339" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_339" result="shape" />
      </filter>
      <clipPath id="clip0_994_339">
        <rect width="7" height="7" fill="white" transform="translate(49 32)" />
      </clipPath>
    </defs>
  </svg>
);

const PopoverThumbnail = ({ setActiveCategory, onMouseOverElement, onMouseOutElement }: SkeletonProps) => (
  <svg
    width="104"
    height="66"
    viewBox="0 0 104 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onMouseOver={onMouseOverElement}
    onMouseOut={onMouseOutElement}
    onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
    id="bar-styles-nudges"
  >
    <rect width="104" height="66" rx="4" fill="#E8E8E8" />
    <g filter="url(#filter0_d_994_62)">
      <rect x="56" y="6" width="42" height="22" rx="2" fill="white" shapeRendering="crispEdges" />
      <rect x="60" y="10" width="26.85" height="2" fill="#E7E7E9" />
      <rect x="60" y="14" width="34" height="2" fill="#E7E7E9" />
      <rect x="91.1655" y="10" width="2.83448" height="2" fill="#E7E7E9" />
      <rect x="82" y="20" width="12" height="4" rx="1" fill="#2755F0" />
    </g>
    <defs>
      <filter
        id="filter0_d_994_62"
        x="52"
        y="6"
        width="50"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_994_62" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_994_62" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_62" result="shape" />
      </filter>
    </defs>
  </svg>
);

const PinThumbnail = ({ setActiveCategory, onMouseOverElement, onMouseOutElement }: SkeletonProps) => (
  <svg
    width="104"
    height="66"
    viewBox="0 0 104 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
    onMouseOver={onMouseOverElement}
    onMouseOut={onMouseOutElement}
    id="bar-styles-nudgePin"
  >
    <rect width="104" height="66" rx="4" fill="#E8E8E8" />
    <g filter="url(#filter0_d_994_365)">
      <rect x="37.667" y="26" width="42" height="14" rx="2" fill="white" shapeRendering="crispEdges" />
      <rect x="41.667" y="30" width="26.85" height="2" fill="#E7E7E9" />
      <rect x="41.667" y="34" width="34" height="2" fill="#E7E7E9" />
      <rect x="72.8325" y="30" width="2.83448" height="2" fill="#E7E7E9" />
    </g>
    <rect x="24.667" y="28" width="10" height="10" rx="5" fill="#2755F0" fillOpacity="0.15" />
    <g filter="url(#filter1_d_994_365)">
      <rect x="27.667" y="31" width="4" height="4" rx="2" fill="#2755F0" />
    </g>
    <rect x="34.667" y="32.8281" width="5" height="5" transform="rotate(-45 34.667 32.8281)" fill="white" />
    <defs>
      <filter
        id="filter0_d_994_365"
        x="33.667"
        y="26"
        width="50"
        height="22"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_994_365" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_994_365" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_365" result="shape" />
      </filter>
      <filter
        id="filter1_d_994_365"
        x="23.667"
        y="31"
        width="12"
        height="12"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_994_365" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_994_365" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_365" result="shape" />
      </filter>
    </defs>
  </svg>
);

const NudgeView = (props: SkeletonProps) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        marginTop: '50px',
        maxWidth: '400px',
        '& > *': {
          width: '150px',
          height: 'auto',
        },
      }}
    >
      <ModalThumbnail {...props} />
      <PopoverThumbnail {...props} />
      <PinThumbnail {...props} />
    </div>
  );
};

export default NudgeView;
