import { Form, Modal, Select } from '@commandbar/design-system/components/antd';
import { CmdButton, CmdInput, CmdLabel, CmdTextarea } from '@commandbar/design-system/cmd';
import { ButtonRow, DrawerTitle, FooterRow, StyledDrawer } from '../shared/styles';
import { useCallback, useEffect, useState } from 'react';
import { Trash04, XClose } from '@commandbar/design-system/icons/react';
import debounce from 'lodash/debounce';
import { IKeyword } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useReportEvent } from 'editor/src/hooks/useEventReporting';
import styled from '@emotion/styled';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';

const FullWidthLabelFormItem = styled(Form.Item)`
  label {
    width: 100%;
  }
`;

const KeywordEditDrawer = ({
  open,
  onClose,
  activeFile: activeKeyword,
}: {
  open: boolean;
  onClose: () => void;
  activeFile: IKeyword | undefined;
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [updates, setUpdates] = useState(0);
  const { user } = useAuth();
  const { reportEvent } = useReportEvent();

  const {
    dispatch: {
      keywords: { save, delete: deleteKeyword },
    },
  } = useAppContext();

  useEffect(() => {
    if (activeKeyword) {
      form.setFieldsValue({
        isLive: activeKeyword.is_live,
        keyword: activeKeyword.keyword,
        definition: activeKeyword.definition,
      });
    } else {
      form.resetFields();
    }
  }, [activeKeyword]);

  const onDrawerClose = () => {
    setIsValid(false);
    setUpdates(0);
    form.resetFields();
    onClose();
  };

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, form.getFieldValue('isLive') ? 'editor' : 'contributor');

  const checkIsValid = (numUpdates: number, loading?: boolean) => {
    setIsValid(
      numUpdates > 0 &&
        isAllowedToSave &&
        !loading &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        Object.values(form.getFieldsValue(['keyword', 'definition'])).filter((val) => val === undefined || val === '')
          .length === 0,
    );
  };

  useEffect(() => {
    checkIsValid(updates);
  }, [updates]);

  useModS(() => {
    if (!isValid) return;

    onFinish({
      keyword: form.getFieldValue('keyword'),
      definition: form.getFieldValue('definition'),
      isLive: form.getFieldValue('isLive'),
    });
  });

  const onFinish = useCallback(
    async ({ keyword, definition, isLive }: { keyword: string; definition: string; isLive: boolean }) => {
      setIsSaving(true);

      try {
        await save({
          id: activeKeyword?.id ?? -1,
          keyword: keyword.trim(),
          definition: definition,
          is_live: isLive,
        });
      } catch (error) {
        // ntd, handled in save
      } finally {
        setIsSaving(false);
        onDrawerClose();
      }
    },
    [form, activeKeyword?.id],
  );

  const FooterButtons = () => (
    <ButtonRow>
      <CmdButton variant={'link'} onClick={onDrawerClose}>
        Cancel
      </CmdButton>
      <CmdButton disabled={!isValid} loading={isSaving} variant="primary" type="submit" form="create-file">
        {activeKeyword ? 'Save Changes' : 'Create Keyword'}
        <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
      </CmdButton>
    </ButtonRow>
  );

  return (
    <StyledDrawer
      key={'create-file-drawer' + activeKeyword ? activeKeyword?.id.toString() : '-1'}
      width="500px"
      placement="right"
      onClose={onDrawerClose}
      open={open}
      closeIcon={<XClose />}
      maskStyle={{ color: '#000000', opacity: '0.2' }}
      title={<DrawerTitle>{activeKeyword ? 'Edit Keyword' : 'New Keyword'}</DrawerTitle>}
      footer={
        activeKeyword ? (
          <FooterRow>
            <CmdButton
              disabled={!isAllowedToSave}
              variant="link"
              icon={<Trash04 />}
              onClick={() => {
                Modal.confirm({
                  title: 'This will permanently delete this keyword. Are you sure you want to proceed?',
                  onOk: async () => {
                    await deleteKeyword(activeKeyword.id);
                    reportEvent('keyword deleted', {
                      segment: true,
                      highlight: true,
                      slack: true,
                      payloadMessage: `${activeKeyword.id}`,
                      eventProps: {
                        id: activeKeyword.id,
                      },
                    });
                    onDrawerClose();
                  },
                  cancelText: 'Cancel',
                });
              }}
            ></CmdButton>
            <FooterButtons />
          </FooterRow>
        ) : (
          <FooterButtons />
        )
      }
    >
      <Form
        style={{ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
        layout="vertical"
        key={'create-file' + activeKeyword ? activeKeyword?.id.toString() : '-1'}
        name="create-file"
        form={form}
        onFinish={onFinish}
        onValuesChange={debounce(() => {
          setUpdates((updates) => updates + 1);
        }, 300)}
      >
        <div>
          <Form.Item
            key={'isLive'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Status</CmdLabel>}
            name={'isLive'}
            initialValue={activeKeyword?.is_live ?? true}
          >
            <Select disabled={!isAllowedToPublish}>
              <Select.Option key={'unpublished'} value={false}>
                Unpublished
              </Select.Option>
              <Select.Option key={'published'} value={true}>
                Published
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            key={'keyword'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Keyword</CmdLabel>}
            name={'keyword'}
            rules={[
              { required: true, message: 'This is a required field.' },
              { min: 3, message: 'Keyword must have at least 3 characters.' },
              {
                pattern: /^\s*\S+(?: \S+){0,4}\s*$/,
                message: 'Enter a phrase of up to five words',
              },
            ]}
          >
            <CmdInput fullWidth placeholder="Keyword..." />
          </Form.Item>
          <FullWidthLabelFormItem
            key={'definition'}
            required={true}
            validateTrigger="onChange"
            labelCol={{ span: 24 }}
            label={
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <CmdLabel>Definition</CmdLabel>
                <CmdLabel
                  style={{
                    color:
                      200 - (form.getFieldValue('definition')?.length || activeKeyword?.definition?.length || 0) <= 15
                        ? '#B91C1C'
                        : undefined,
                  }}
                >
                  {200 - (form.getFieldValue('definition')?.length || activeKeyword?.definition?.length || 0)}
                </CmdLabel>
              </div>
            }
            name={'definition'}
            rules={[{ required: true, message: 'This is a required field.' }]}
          >
            <CmdTextarea
              fullWidth
              maxLength={200}
              minLength={5}
              placeholder="Keyword definition"
              style={{ minHeight: '128px' }}
            />
          </FullWidthLabelFormItem>
        </div>
      </Form>
    </StyledDrawer>
  );
};

export default KeywordEditDrawer;
