/** Fixme: instead of being callbacks, this can probably be actions */

import type {
  Action,
  ChatHandoffAction,
  OpenChatAction,
  RequestType,
  IClickAction,
  IHelpDocAction,
  ILinkAction,
} from '@cb/types/entities/command/actions';
import { CBStore } from '../global-store';
import { getCommandById } from '../global-selectors';
import { InternalError } from 'shared/util/Errors';
import { checkSelector, clickElement, clickElementsWithPause } from '@commandbar/internal/util/dom';
import { interpolate, interpolateObject } from 'shared/util/Interpolate';
import { commandToHelpHubDoc, isHelpHubWidgetAvailable } from 'products/helphub/service-selectors';
import * as GlobalActions from '../global-actions';
import * as HelpHubServiceActions from 'products/helphub/service-actions';
import type { ExecutionEventSource } from 'shared/services/analytics/EventHandler';
import type { ICommandType, IMessageType } from '@commandbar/internal/middleware/types';

import * as Reporting from 'shared/services/analytics/Reporting';
import Logger from '@commandbar/internal/util/Logger';
import { getSentry } from '@commandbar/internal/util/sentry';
import { NativeCallbacks, getNativeCallback } from 'shared/util/hooks/useMobileExperience';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { getSDK } from '@commandbar/internal/client/globals';
import { getNudgeById } from 'products/nudges/service-selectors';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import helpdocService from 'shared/services/services/helpdocService';
import * as SpotlightServiceActions from 'products/spotlight/service-actions';
import {
  dangerouslyRunJS,
  executeCallback,
  runRequestCommandAndReportFailure,
  selectContextForExternalUse,
} from './executable-selectors';
import { IntercomIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import { getChecklistById } from '@commandbar/commandbar/products/checklists/service-selectors';
import type { Coordinates2D } from '@commandbar/commandbar/products/nudges/components/utils';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: {
      app_id: string;
      user_id?: string;
      email?: string;
      user_hash?: string;
      hide_default_launcher?: boolean;
    };
    Beacon: any;
    FreshworksWidget: any;
    fcWidget: any;
    $crisp: any;
    gist: any;
    zE: any;
    LiveChatWidget: any;
    olark: any;
    HubSpotConversations: any;
    drift: any;
    Pylon: any;
    webkit?: {
      messageHandlers?: { commandbar__onFallbackAction?: { postMessage: NativeCallbacks['onFallbackAction'] } };
    };
    CommandBarAndroidInterface?: {
      commandbar__onFallbackAction: NativeCallbacks['onFallbackAction'];
    };
  }
}

export const helpDocExecutable = (_: CBStore, action: ILinkAction | IHelpDocAction, command: ICommandType) => {
  if (!isHelpHubWidgetAvailable(_) || (!_.organization?.helphub_enabled && _.organization?.copilot_enabled))
    return linkExecutable(_, action);

  const query = _.spotlight.inputText;

  return () => {
    if (!_.organization?.id) {
      Logger.warn(`Search error: Organization ID not found`);
      linkExecutable(_, action)();
    } else {
      if (isStudioPreview() && !_.helpHub.visible) {
        _.callbacks['__standalone-editor-cb_helpdoc_triggered'](command.text);
      } else {
        HelpHubServiceActions.setHelpHubVisible(_, true, {
          article: commandToHelpHubDoc(command),
          query,
        });
      }
    }
  };
};

export const linkExecutable = (_: CBStore, action: ILinkAction | IHelpDocAction, forceNewTab?: boolean) => {
  // Is the interpolated URL valid?
  const _url = interpolate(action.value, _, true, true, true);

  if (!!forceNewTab) {
    return () => window.open(_url, '_blank');
  }

  switch (action.operation) {
    case 'router':
      const routerFunc = _.callbacks['commandbar-router'];
      if (routerFunc) {
        return () => routerFunc(_url);
      } else {
        throw new InternalError('Link is of router type, but router is not defined.');
      }
    case 'self':
      return () => window.open(_url, '_self');
    case 'blank':
      return () => window.open(_url, '_blank');
    default:
      return () => window.open(_url, '_blank');
  }
};

export const clickExecutable = (_: CBStore, action: IClickAction) => {
  const values = action.value.map((el: any) => interpolate(el, _, true, true));
  if (!checkSelector(values[0])) {
    throw new InternalError(`Cannot find element to click: [${values[0]}]`);
  }

  const reportFailure = (_selector: string) => {
    Reporting.error('checklist_item_element_not_found', {
      questlist_id: _.activeChecklist?.id,
    });

    getSentry()?.captureMessage(`Click failed`);
  };

  if (action.value.length === 1) {
    return () => clickElement(values[0], reportFailure);
  } else {
    return () => clickElementsWithPause(0, values, reportFailure);
  }
};

const openIntercomChat = async (_: CBStore, args?: Record<string, any>) => {
  if (_.organization?.integrations.intercom?.enabled && args?.messageId) {
    const visitorId: string | undefined = window.Intercom('getVisitorId');
    try {
      const data = await IntercomIntegration.createConversation({
        message_id: args.messageId,
        intercom_user_id: window.intercomSettings.user_id ?? visitorId,
        intercom_user_type: window.intercomSettings.user_id ? 'user' : 'visitor',
      });

      if (data?.conversation_id) {
        await loadIntercomConversation(data.conversation_id);
        return;
      }
    } catch (err) {}
  }

  window.Intercom('showNewMessage');
};

/**
 * Loads an Intercom conversation and resolves promise once the Intercom widget is shown.
 * @param {string | undefined} conversationId - The ID of the Intercom conversation to be shown.
 * @returns {Promise<void>} A promise that resolves when the conversation window is shown.
 */
const loadIntercomConversation = async (conversationId: string | undefined): Promise<void> => {
  return new Promise<void>((resolve) => {
    window.Intercom('onShow', () => {
      resolve();
    });
    window.Intercom('showConversation', conversationId);
  });
};

const openChatExecutable = async (_: CBStore, action: OpenChatAction, args?: Record<string, any>) => {
  let cb: () => Promise<void> | void | undefined = () => undefined;

  switch (action?.meta?.type) {
    case 'intercom':
      cb = () => openIntercomChat(_, args);
      break;
    case 'helpscout':
      cb = () => window.Beacon('open');
      break;
    case 'freshdesk':
      cb = () => window.FreshworksWidget('open');
      break;
    case 'freshchat': {
      cb = async () => {
        const topicName = _.organization?.integrations.freshchat?.topic_name;
        window.fcWidget.open(topicName ? { name: topicName } : undefined);
      };
      break;
    }
    case 'gist':
      cb = () => window.gist.chat('open');
      break;
    case 'crisp':
      cb = () => {
        window.$crisp.push(['do', 'chat:open']);
        window.$crisp.push(['do', 'chat:show']);
      };
      break;
    case 'zendesk': {
      cb = () => {
        try {
          // Core widget
          window.zE('messenger', 'open');
        } catch (_e) {
          // Classic widget as fallback
          window.zE('webWidget', 'open');
          // Show the launcher in case they are hiding it
          // This is a noop if the widget is already showing
          window.zE('webWidget', 'show');
        }
      };
      break;
    }
    case 'liveChat':
      cb = () => window.LiveChatWidget.call('maximize');
      break;
    case 'olark':
      cb = () => window.olark('api.box.expand');
      break;
    case 'hubspot':
      cb = () => window.HubSpotConversations.widget.open();
      break;
    case 'drift':
      cb = () => window.drift.api.openChat();
      break;
    case 'pylon':
      cb = () => window.Pylon('show');
  }

  // This is to add temporary support for triggering a native fallback in our mobile native SDKs
  // Originally was added to support native fallback in Copilot but is now also being used to support in Additional Resources in HelpHub
  const nativeCallback = getNativeCallback();
  if (window._cbIsWebView && nativeCallback) {
    cb = () => nativeCallback(JSON.stringify(action));
  }

  try {
    await cb();
  } catch (e) {
    getSentry()?.captureException(e);
  }
};

const chatHandoffExecutable = async (_: CBStore, action: ChatHandoffAction, args?: Record<string, any>) => {
  let cb: () => Promise<void> | void | undefined = () => undefined;

  switch (action?.meta?.type) {
    case 'intercom': {
      cb = () => openIntercomChat(_, args);
      break;
    }
    case 'freshchat': {
      cb = () => {
        if (args && 'chatHistory' in args) {
          const { chatHistory } = args;

          const copilot_chat_history = (chatHistory as IMessageType[])
            .map(({ message_type, value }) => {
              if (message_type === 'AI') {
                return `<strong>${_.organization?.copilot_name || 'Copilot'}:</strong> ${value.answer}`;
              }

              if (message_type === 'USER') {
                return `<strong>User:</strong> ${value.question}\n\n`;
              }

              return '';
            })
            .join('');

          window.fcWidget.conversation.setConversationProperties({
            [`cf_${_.organization?.integrations.freshchat?.conversation_property_name ?? 'copilot_chat_history'}`]:
              copilot_chat_history,
          });
        }

        const topicName = _.organization?.integrations.freshchat?.topic_name;
        window.fcWidget.open(topicName ? { name: topicName } : undefined);
      };
      break;
    }
  }

  try {
    await cb();
  } catch (e) {
    getSentry()?.captureException(e);
  }
};

export const executeAction = (
  _: CBStore,
  action: Action,
  e?: React.MouseEvent,
  args: Record<string, any> = {},
  executionEventSource: ExecutionEventSource = { type: 'helphub_recommendation' },
  callback?: (openedNewTab: boolean) => void,
) => {
  let openedNewTab = false;

  // Note that this executes all commands, even commands with arguments. This could
  // potentially get us into trouble if an org has the bar disabled + uses a command
  // with arguments in their additional resources, but it's also still useful to have
  // this functionality and the risk should be very low.
  switch (action?.type) {
    case 'open_bar':
      if (isStudioPreview()) {
        _.callbacks['__standalone-editor-open-spotlight-action']();
      } else {
        getSDK().open(action.value, { categoryFilter: action.categoryFilter });
      }
      break;
    case 'open_helphub':
      getSDK().openHelpHub();
      break;
    case 'open_copilot':
      getSDK().openCopilot({ query: action.query });
      break;
    case 'clickBySelector':
    case 'clickByXpath':
    case 'click':
      const value = typeof action.value === 'string' ? [action.value] : action.value;
      clickExecutable(_, {
        type: action.type,
        value: value,
      })();
      break;
    case 'execute_command':
      // need to use synchronous getCommandById here to update engine.spotlight.steps immediately before trying to rebase and fulfill
      const command = getCommandById(_, action.meta.command);
      if (command) {
        if (command.template.type === 'link' && command.template.operation === 'blank') {
          openedNewTab = true;
        }

        GlobalActions.executeCommand(_, command, undefined, undefined, executionEventSource, e, true);
        let shouldRebaseAndFulfill = false;
        _.spotlight.steps.forEach((step) => {
          if (
            step.type === 'textinput' ||
            step.type === 'longtextinput' ||
            step.type === 'select' ||
            step.type === 'multiselect'
          ) {
            const value = args[step.argument.userDefinedValue];
            if (value) {
              step.selected = {
                data: value,
                type: 'parameter',
                category: null,
              };
              step.completed = true;
              shouldRebaseAndFulfill = true;
            }
          }
        });

        if (shouldRebaseAndFulfill) {
          SpotlightServiceActions.rebase(SpotlightServiceActions.completeNextStepAndSubsequentExecuteSteps(_));
        }
      } else {
        helpdocService.getHelpdocCommand(_, action.meta.command).then((command) => {
          if (command) {
            GlobalActions.executeCommand(_, command, undefined, undefined, executionEventSource, e, true);
          }
        });
      }

      break;
    case 'link':
      if (isStudioPreview()) {
        const _url = interpolate(action.value, _, true, true, true);
        // the standalone editor has a custom router that will display
        // a message indicating where the link would go in lieu of actually
        // navigating to it
        _.callbacks['commandbar-router'](_url);
        return;
      } else {
        linkExecutable(_, action, !!e && getTriggerKey(e))();

        if (action.operation === 'blank') {
          openedNewTab = true;
        }
      }
      break;
    case 'open_chat':
      if (isStudioPreview()) {
        const upperCase = `${action?.meta?.type.charAt(0).toUpperCase()}${action?.meta?.type.slice(1)}`;
        _.callbacks['__standalone-editor-cb_hh_cta'](upperCase);
      } else {
        openChatExecutable(_, action, args).then(() => {
          HelpHubServiceActions.setHelpHubVisible(_, false, { article: null });
        });
      }
      break;
    case 'chat_handoff': {
      if (isStudioPreview()) {
        const upperCase = `${action?.meta?.type.charAt(0).toUpperCase()}${action?.meta?.type.slice(1)}`;
        _.callbacks['__standalone-editor-cb_hh_cta'](upperCase);
      } else {
        chatHandoffExecutable(_, action, args).then(() => {
          HelpHubServiceActions.setHelpHubVisible(_, false, { article: null });
        });
      }
      break;
    }
    case 'questlist':
      const questlist = getChecklistById(_, action.value);
      if (questlist) {
        GlobalActions.activatePushExperience(_, questlist, 'questlist');
      }
      break;
    case 'nudge': {
      const nudge = getNudgeById(_, action.value);
      if (nudge) {
        const x = e?.clientX;
        const y = e?.clientY;
        const source =
          x && y
            ? {
                experience: executionEventSource.type,
                position: [x, y] satisfies Coordinates2D,
              }
            : undefined;

        GlobalActions.activatePushExperience(_, nudge, 'nudge', source);
      }
      break;
    }
  }

  if (callback) {
    callback(openedNewTab);
  }
};

/** This takes a command and runs it with provided argument selections. It assumes argument selections are complete */
export const commandExecutableWithFullArgumentSelections = (
  _: CBStore,
  command: ICommandType,
  argumentSelections: { [argName: string]: unknown },
  overrideBehavior?: { openLinkInNewTab?: boolean },
): (() => unknown) => {
  // Overwrite callback, click, help, and link command functionality on standalone editor
  if (isStudioPreview()) {
    switch (command.template.type) {
      case 'helpdoc':
        return helpDocExecutable(_, command.template, command);
      case 'link':
        try {
          const _url = interpolate(command.template.value, _, true, true, true);
          return () => _.callbacks['commandbar-router'](_url);
        } catch (err) {
          getSentry()?.captureException(err);
          console.error('Could not interpolate context: ', command.template.value);
          return () => _.callbacks['commandbar-router'](command.template.value);
        }
      case 'admin':
      case 'builtin':
      case 'callback':
      case 'clickByXpath':
      case 'clickBySelector':
      case 'click':
        return () => {
          executeCallback(_, argumentSelections, command, _.callbacks['__standalone-editor-cb']);
        };
      default:
        // Fallback to regular functionality for all other command types
        break;
    }
  }

  switch (command.template.type) {
    case 'helpdoc':
      return helpDocExecutable(_, command.template, command);
    case 'link':
      return linkExecutable(_, command.template, overrideBehavior?.openLinkInNewTab);
    case 'admin':
    case 'builtin':
    case 'callback':
      const callbackName = command.template.value;
      const callback = _.callbacks[callbackName];
      if (!callback) {
        throw new InternalError(`Callback is not available: [${callbackName}]`);
      }
      return () => {
        executeCallback(_, argumentSelections, command, callback);
      };
    case 'clickByXpath':
    case 'clickBySelector':
    case 'click':
      return clickExecutable(_, command.template);
    case 'appcues':
      if (!(window as any).Appcues || !(window as any).Appcues.show) {
        throw new InternalError('Appcues is not available');
      }
      return () => {
        const contentId = command.template.value.toString();
        (window as any).Appcues.show(contentId);
      };
    case 'pendo_guide':
      if (isStudioPreview()) {
        return () => {
          _.callbacks['__standalone-editor-cb'](command.template.value);
        };
      } else if (!(window as any).pendo) {
        throw new InternalError('Pendo is not available');
      }

      return () => {
        const guideId = command.template.value.toString();
        (window as any).pendo.showGuideById(guideId);
      };
    case 'request':
      const {
        method = 'get',
        url,
        headers,
        body,
        // onSend,
        onSuccess,
        onError,
      } = command.template.value as RequestType;

      const interpolatedRequest = interpolateObject({
        s: {
          url,
          headers,
          body,
        },
        _,
        interpolateContext: true,
        interpolateArgs: true,
      }) as Omit<RequestType, 'method'>;

      const requestFn = (req: RequestType) => {
        const headers = !!req.headers ? new Headers(req.headers as HeadersInit) : undefined;
        const requestOptions = {
          method: req.method,
          headers,
          body: ['patch', 'put', 'post'].includes(req.method) ? JSON.stringify(req.body) : undefined,
        };

        return fetch(req.url, requestOptions);
      };

      let onSuccessFunc: any = undefined;
      if (onSuccess) {
        onSuccessFunc = _.callbacks[onSuccess];
        if (!onSuccessFunc) {
          Logger.error(`onSuccess callback for request is not available, [${onSuccess}]`);
        }
      }

      let onErrorFunc: any = undefined;
      if (onError) {
        onErrorFunc = _.callbacks[onError];
        if (!onErrorFunc) {
          Logger.error(`onError callback for request is not available, [${onError}]`);
        }
      }

      return () => {
        const request = () =>
          requestFn({
            method,
            ...interpolatedRequest,
          });

        runRequestCommandAndReportFailure(request, command, onSuccessFunc, onErrorFunc);
      };
    case 'webhook':
      return () => {
        const webhook = command.template.value.toString();
        const context = selectContextForExternalUse(_, false);

        const payload = {
          args: argumentSelections,
          context,
        };

        fetch(webhook, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
      };
    case 'video':
      return () => {
        /** No-op */
      };
    case 'trigger':
      const action = command.template.value;
      return () => {
        executeAction(_, action, undefined, argumentSelections, { type: 'bar' });
      };
    case 'script':
      return () => {
        let script = '';
        const org_uid = _?.organization?.id ? _?.organization?.id.toString() : '';

        const context = selectContextForExternalUse(_, false);

        // FIXME: feature flag
        // catchandrelease
        if (['8eafe599'].includes(org_uid)) {
          script = command.template.value.toString();
        }
        dangerouslyRunJS(script, argumentSelections, context);
      };
    default:
      throw new InternalError('Invalid command execute type.');
  }
};
