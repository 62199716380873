import React from 'react';
import { CmdTypography } from '../../typography';

interface CmdTableNoSearchResultProps {
  children?: React.ReactChild;
}

export const CmdTableNoSearchResult = ({ children }: CmdTableNoSearchResultProps): React.ReactElement => {
  return (
    <div className="flex flex-col items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center gap-lg">
      {!!children ? <>{children}</> : <CmdTypography.Body>No search results</CmdTypography.Body>}
    </div>
  );
};
