import React from 'react';
import { useHistory } from 'react-router-dom';
import { BracketsSlash } from '@commandbar/design-system/icons/react';
import { CardBase } from './CardBase';
import { ICON_WIDTH } from './styles';

export const CodeInstructionsCard = () => {
  const history = useHistory();
  return (
    <CardBase
      title="Install in a web app"
      description="Guides for React, Next.js, Rails, and more"
      onClick={() => history.push('/installation-guide/web-app')}
      leftIcon={<BracketsSlash width={ICON_WIDTH} color="#ED7A27" />}
      background="linear-gradient(96.46deg, #FDEEE3 -5.42%, #FFFFFF 18.75%, #FFFFFF 24.12%)"
      iconShadow="0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(237, 122, 39, 0.16), inset 1.5px -1.5px 3px rgba(237, 122, 39, 0.25)"
    />
  );
};
