/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { IconType } from 'react-icons';

import { getColor } from './styles';

export type MenuItem = {
  title: string;
  Icon: IconType | React.FunctionComponent;
  route?: string;
  highlight?: boolean;
  handler?: () => void;
};

type Props = {
  item: MenuItem;
  active: boolean;
  badge: boolean;
  onClick: () => void;
};

const hoverStyle = css`
  background: ${getColor('mainPurpleLight')};
  color: ${getColor('mainPurple')};
`;

const activeStyle = css`
  color: ${getColor('mainPurple')};
  background: linear-gradient(0deg, rgba(39, 85, 240, 0.1), rgba(39, 85, 240, 0.1)), #ffffff !important;
`;

const iconStyle = css`
  width: 16px;
  height: 16px;
`;

const highlightStyle = css`
  color: ${getColor('mainPurple')};
`;

const className = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: 16px;
  font-size: 14px;
  color: #51515c;
  padding: 8px;
  font-weight: 600;
  background: ${getColor('white')};
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    ${hoverStyle}
  }
`;

const badgeStyle = css`
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;

const iconContainerClassName = css`
  display: flex;
  font-size: 14px;
  margin-right: 10px;
`;

export const NavItem = (props: Props) => {
  const {
    item: { title, route, Icon, handler, highlight },
    active,
    badge,
    onClick,
  } = props;

  const handleItemClick = () => {
    handler ? handler() : changeRoute();
  };

  const changeRoute = () => {
    if (!route) {
      return;
    }

    // In case of route change / response slowness, don't want to block animation
    onClick();
  };

  return (
    <div
      data-custom-type="menu_item"
      css={[className, active && activeStyle, highlight && highlightStyle]}
      onClick={handleItemClick}
    >
      <span css={iconContainerClassName}>
        <div style={{ position: 'relative', display: 'inherit' }}>
          <Icon css={[iconStyle]} style={{ opacity: active ? 1 : 0.7 }} />
          {badge && <sup css={badgeStyle}></sup>}
        </div>
      </span>
      {title}
    </div>
  );
};
