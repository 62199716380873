import { CreatableSelect } from '@commandbar/design-system/components/antd';
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import slugify from '@commandbar/internal/util/slugify';
import React from 'react';

import { useAppContext } from 'editor/src/AppStateContext';

type Props = {
  onCategorySelect: (categoryId: number) => void;
  selectedCategoryId: number | undefined;
  emptyLabel?: string;
  commandType?: string;
};

export const CategorySelect = (props: Props) => {
  const { onCategorySelect, selectedCategoryId, emptyLabel } = props;
  const {
    organization,
    categories,
    dispatch: {
      categories: { save: saveCategory },
    },
  } = useAppContext();

  const selectOptions = categories.map((category: ICommandCategoryType) => ({
    value: category.name,
    label: category.name,
    id: category.id,
  }));

  if (!!emptyLabel) {
    selectOptions.unshift({
      value: emptyLabel,
      label: emptyLabel,
      id: -1,
    });
  }

  const handleChange = (item: { id: number; label: string; value: string; __isNew__?: boolean }) => {
    if (item.__isNew__) {
      const name = item.value;

      const payload: ICommandCategoryType = {
        id: -1,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organization: organization!.id,
        name,
        sort_key: null,
        icon: null,
        icon_color: null,
        image_color: null,
        image: null,
        setting_hide_before_search: false,
        setting_max_options_count: null,
        setting_pin_to_bottom: false,
        search_tab_enabled: false,
        search_tab_name: '',
        slash_filter_enabled: true,
        slash_filter_keyword: slugify(name),
        search_tab_instruction: '',
        render_as: 'list',
        track_recents: false,
      };

      return saveCategory(payload).then((category: any) => {
        onCategorySelect(category.id);
      });
    }

    onCategorySelect(item.id);
  };

  const defaultOption = selectOptions.find((option) => option.id === selectedCategoryId);

  const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
    return candidate.data.__isNew__ || candidate.label.toLowerCase().startsWith(input.toLowerCase());
  };

  return (
    <CreatableSelect
      // @ts-expect-error: FIXME types
      onChange={handleChange}
      options={selectOptions}
      filterOption={filterOptions}
      defaultValue={defaultOption}
      placeholder="Select a category (or type to create)"
    />
  );
};
