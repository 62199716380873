import * as t from 'io-ts';
import * as axiosInstance from './network';
import { decodeToPromise } from './generics';
import { CommandV } from './command';
import { IOrganizationType, ISearchExperiencesPayloadType, ISearchExperiencesResponseType } from './types';

export const FilterV = t.union([t.partial({ labels: t.array(t.string) }), t.null]);

export const ExperienceTypeOptions = t.union([
  t.array(
    t.union([
      t.literal('page'),
      t.literal('action'),
      t.literal('helpdoc'),
      t.literal('video'),
      t.literal('nudge'),
      t.literal('checklist'),
    ]),
  ),
  t.undefined,
]);

export const CommandExperience = t.type({
  type: t.union([t.literal('page'), t.literal('action'), t.literal('helpdoc'), t.literal('video')]),
  experience_id: t.number,
  experience: CommandV,
  excerpt: t.string,
  score: t.array(t.number),
});

export const OtherExperience = t.type({
  type: t.union([t.literal('nudge'), t.literal('checklist')]),
  experience_id: t.number,
  experience: t.undefined,
  excerpt: t.string,
  score: t.array(t.number),
});

export const ExperienceHitV = t.union([CommandExperience, OtherExperience]);

export const SearchExperiencesResponseV = t.type({
  data: t.array(t.type({ hit: ExperienceHitV })),
});

export const SearchExperiencesPayloadV = t.partial({
  query: t.string,
  widget: t.union([t.literal('helphub'), t.literal('spotlight')]),
  experience_types: t.string, // Messy to enumerate ('helpdoc' | 'video' | 'helpdoc,video'...)
  max_results: t.union([t.number, t.undefined]),
  user_properties: t.union([t.record(t.string, t.unknown), t.undefined]),
  distinct_id: t.union([t.string, t.undefined]),
  filter: FilterV,
});

export class ExperiencesSearch {
  public static search = async (
    orgUID: IOrganizationType['id'],
    payload: ISearchExperiencesPayloadType,
    options?: axiosInstance.FetchOptions,
  ) => {
    const serializedPayload = Object.entries(payload)
      .filter(([_key, value]) => Boolean(value))
      .map(([key, value]) => {
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }

        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');

    const result = await axiosInstance.get<ISearchExperiencesResponseType>(
      `search/experiences/${orgUID}/?${serializedPayload}`,
      {
        ...options,
      },
    );

    return await decodeToPromise(t.exact(SearchExperiencesResponseV), result.data);
  };
}
