import React, { useEffect, useState } from 'react';
import { Layout, Steps, Typography, Page } from '@commandbar/design-system/components/antd';
import { IOrganizationType, IUserType } from '@commandbar/internal/middleware/types';
import HeapConfigurationStep from './HeapConfigurationStep';
import HeapSyncStep from './HeapSyncStep';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { HeapConfigProvider } from './HeapConfigProvider';
import { IHeapConfig } from './utils';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Send events to Heap',
    stepHeading: 'Toggle on to send events to Heap',
    component: HeapConfigurationStep,
  },
  {
    title: 'Sync with Heap',
    stepHeading: 'Allow Heap segments to be used within CommandBar.',
    component: HeapSyncStep,
  },
];

interface IHeapIntegrationProps {
  organization: IOrganizationType;
  user: IUserType;
}

const HeapSetupSteps = (props: IHeapIntegrationProps) => {
  const stepMarker = (dot: any) => <>{dot}</>;
  const [stepIdx, setStepIdx] = useState<number>(0);

  const { stepHeading, component: CurrentStepComponent } = STEPS[stepIdx];

  const { reportEvent } = useReportEvent();

  const handlePrev = () => {
    setStepIdx((stepIdx) => Math.max(stepIdx - 1, 0));
    reportEvent('heap integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Prev Step',
    });
  };

  const handleNext = () => {
    setStepIdx((stepIdx) => Math.min(stepIdx + 1, STEPS.length - 1));
    reportEvent('heap integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Next Step',
    });
  };

  return (
    <div>
      <Steps current={stepIdx} progressDot={stepMarker}>
        {STEPS.map((e: any, idx: number) => (
          <Step key={`step-${idx}`} title={e.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Typography.Title level={4}>
            {stepIdx + 1}. {stepHeading}
          </Typography.Title>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <CurrentStepComponent handlePrev={handlePrev} handleNext={handleNext} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const HeapIntegrationPage = (props: IHeapIntegrationProps) => {
  const { reportEvent } = useReportEvent();
  const [heapConfig, setHeapConfig] = useState<IHeapConfig>({
    orgID: props.user.organization,
    send_events_to_heap: false,
  });

  useEffect(() => {
    reportEvent('heap integration visited', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, []);

  return (
    <Page section="Integrations" title="Heap" description="Connect Heap with Commandbar.">
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        <HeapConfigProvider value={{ heapConfig, setHeapConfig }}>
          <HeapSetupSteps {...props} />
        </HeapConfigProvider>
      </Layout.Content>
    </Page>
  );
};

export default HeapIntegrationPage;
