import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { BrowserCondition as BrowserConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { BrowserType } from '@commandbar/internal/util/operatingSystem';
import { ConditionProps } from '../../types';

export const BrowserCondition: React.FC<ConditionProps<BrowserConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  const options: { label: string; value: BrowserType }[] = [
    {
      label: 'Arc',
      value: 'arc',
    },
    {
      label: 'Brave',
      value: 'brave',
    },
    {
      label: 'Chrome',
      value: 'chrome',
    },
    {
      label: 'Edge',
      value: 'edge',
    },
    {
      label: 'Firefox',
      value: 'firefox',
    },
    {
      label: 'Opera',
      value: 'opera',
    },
    {
      label: 'Safari',
      value: 'safari',
    },
    {
      label: '< IE 10',
      value: 'ie10',
    },
    {
      label: 'IE 11',
      value: 'ie11',
    },
  ];

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.values}
          options={options}
          placeholder="value"
          style={{ width: '100%' }}
          onBlur={() => setHasBlurred(true)}
          onChange={(values) => {
            onConditionChange({
              ...condition,
              values,
            });
          }}
          filterOption={(input, option) =>
            !!option?.value?.toLowerCase().includes(input.toLowerCase()) ||
            !!option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          size="small"
          mode="multiple"
        />
      </Col>
    </Row>
  );
};
