import React, { Fragment, CSSProperties, ReactNode, useState } from 'react';
import { zxcvbnAsync, zxcvbnOptions, OptionsDictionary } from '@zxcvbn-ts/core';

import PasswordStrengthBarItem from './PasswordStrengthBarItem';

const rootStyle: CSSProperties = {
  position: 'relative',
};

const wrapStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0 0',
};

const spaceStyle: CSSProperties = {
  width: 4,
};

const descStyle: CSSProperties = {
  margin: '5px 0 0',
  color: '#898792',
  fontSize: 14,
  textAlign: 'right',
};

let dictionary: OptionsDictionary;

const packages = {
  'common/passwords': '/assets/zxcvbn/passwords.json',
  'en/commonWords': '/assets/zxcvbn/commonWords.json',
  'en/firstnames': '/assets/zxcvbn/firstnames.json',
  'en/lastnames': '/assets/zxcvbn/lastnames.json',
};

const loadDictionaries = async () => {
  if (dictionary) {
    return dictionary;
  }

  const promises = Object.entries(packages).map(async ([name, url]) => {
    const response = await fetch(url);

    return [name, await response.json()];
  });

  const dictionaries = await Promise.all(promises);

  dictionary = dictionaries.reduce((acc, [name, value]) => {
    acc[name] = value;
    return acc;
  }, {} as OptionsDictionary);

  return dictionary;
};

const validatePassword = async (password: string) => {
  const dictionary = await loadDictionaries();

  zxcvbnOptions.setOptions({
    dictionary,
  });

  return zxcvbnAsync(password, []);
};

export type PasswordStrengthBarProps = {
  className?: string;
  style?: CSSProperties;
  scoreWordClassName?: string;
  scoreWordStyle?: CSSProperties;
  password: string;
  barColors?: string[];
  scoreWords?: ReactNode[];
  minLength: number;
};

export const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = (props) => {
  const {
    className,
    style,
    scoreWordClassName,
    scoreWordStyle,
    password,
    barColors = ['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281'],
    scoreWords = ['weak', 'weak', 'okay', 'good', 'strong'],
    minLength,
  } = props;

  const [score, setScore] = useState(0);

  const scoreWord = password.length >= minLength ? scoreWords[score] : 'too short';

  const validationRunning = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (validationRunning.current) {
      return;
    }

    if (password.length >= minLength) {
      try {
        validationRunning.current = true;
        validatePassword(password).then((results) => {
          setScore(results.score);
        });
      } finally {
        validationRunning.current = false;
      }
    } else {
      setScore(0);
    }
  }, [password]);

  return (
    <div className={className} style={{ ...rootStyle, ...style }}>
      <div style={wrapStyle}>
        {[1, 2, 3, 4].map((el: number) => (
          <Fragment key={`password-strength-bar-item-${el}`}>
            {el > 1 && <div style={spaceStyle} />}
            <PasswordStrengthBarItem score={score} itemNum={el} barColors={barColors} />
          </Fragment>
        ))}
      </div>
      <p className={scoreWordClassName} style={{ ...descStyle, ...scoreWordStyle }}>
        {scoreWord}
      </p>
    </div>
  );
};

export default PasswordStrengthBar;
