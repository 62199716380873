import * as t from 'io-ts';

import { createObject, decodeThrowing, listObject, updateObject } from './generics';

export const TypeV = t.union([
  t.literal('any'),
  t.literal('string'),
  t.literal('numeric'),
  t.literal('boolean'),
  t.literal('datetime'),
  t.literal('array'),
  t.literal('object'),
]);

export const UserPropertyV = t.type({
  id: t.string,
  name: t.string,
  description: t.union([t.string, t.null]),
  type: TypeV,
  hidden: t.boolean,
  last_seen_at: t.union([t.string, t.null]),
});

export type UserPropertyListParams = {
  /* true or false */
  include_hidden?: string;
};

export type IUserProperty = t.TypeOf<typeof UserPropertyV>;

export class UserProperty {
  public static decode = (data: any) => decodeThrowing(UserPropertyV, data);

  public static create = createObject(UserPropertyV, UserPropertyV, 'properties');
  public static update = updateObject(UserPropertyV, UserPropertyV, 'properties');
  public static list = (params?: UserPropertyListParams) => listObject(UserPropertyV, `properties`)(params);
}
