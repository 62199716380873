import type { ICommandType } from '@commandbar/internal/middleware/types';
import type { IEndUserStoreData } from '@cb/types/entities/endUser';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { getSentry } from '@commandbar/internal/util/sentry';
import { CBStore } from 'shared/store/global-store';
import { recentUIDs } from './selectors';

interface PrimitiveWithTimestamp {
  type: 'command' | 'record';
  optionUID: string;
  timestamp: number;
}
export interface CommandWithTimestamp extends PrimitiveWithTimestamp {
  type: 'command';
  command: ICommandType;
}
export interface RecordWithTimestamp extends PrimitiveWithTimestamp {
  type: 'record';
  record: any;
  categoryKey: string;
}

export type LocalStorageKey = keyof EndUserStore['data'];

export interface EndUserStore {
  data: {
    recents: (CommandWithTimestamp | RecordWithTimestamp)[];
  } & IEndUserStoreData;
  verified: boolean;
  hasRemoteLoaded: boolean;
  derived: {
    recentUIDs: string[];
  };
}

export const emptyEndUserStoreState: () => EndUserStore = () => {
  return {
    data: {
      recents: [],
      hotkeys: {},
      checklist_interactions: {},
      nudges_interactions: {},
      analytics: undefined,
      properties: {},
    },
    verified: false,
    hasRemoteLoaded: false,
    derived: {
      recentUIDs: [],
    },
  };
};

export const initLocalEndUserState = (enabledKeys: LocalStorageKey[], endUser: CBStore['endUser']): EndUserStore => {
  const retVal = emptyEndUserStoreState();
  if (typeof endUser == 'undefined') return retVal;

  // Only keep the most recent N commands
  let recents = enabledKeys.includes('recents') ? getFromEndUserStore('recents', [], endUser) : [];

  // FIXME: Remove this after 2023-06-01 when we can be fairly sure that all active users have been migrated to the new key
  // Fall back to legacy localStorage key for recents as they are local-only
  if (recents.length === 0 && enabledKeys.includes('recents')) {
    const legacyRecents = parseLocalStorageJSON(`userstore.recents`, []);

    if (legacyRecents.length > 0) {
      recents = legacyRecents;
      LocalStorage.set(getStoreKey('recents', endUser), JSON.stringify(recents));
    }
  }

  // Only keep the recents that were executed within the last 3 days
  recents = recents.filter((r: any) => r.timestamp > Date.now() - 1000 * 60 * 60 * 72);

  if (enabledKeys.includes('recents')) {
    retVal.data.recents = recents;
    retVal.derived.recentUIDs = recentUIDs(recents);
  }
  if (enabledKeys.includes('hotkeys') && !endUser?.hotkeys_debug) {
    retVal.data.hotkeys = getFromEndUserStore('hotkeys', {}, endUser);
  }
  if (enabledKeys.includes('checklist_interactions')) {
    retVal.data.checklist_interactions = getFromEndUserStore('checklist_interactions', {}, endUser);
  }
  if (enabledKeys.includes('nudges_interactions')) {
    retVal.data.nudges_interactions = getFromEndUserStore('nudges_interactions', {}, endUser);
  }
  if (enabledKeys.includes('analytics')) {
    retVal.data.analytics = getFromEndUserStore('analytics', undefined, endUser);
  }
  if (enabledKeys.includes('properties')) {
    retVal.data.properties = getFromEndUserStore('properties', {}, endUser);
  }

  return retVal;
};

/** Helpers */
function parseLocalStorageJSON<T>(field: string, defaultValue: T): T {
  try {
    return JSON.parse(LocalStorage.get(field, 'false') as string) || defaultValue;
  } catch (err) {
    getSentry()?.captureException(err);
    return defaultValue;
  }
}

export function getStoreKey(store: string, enduser: CBStore['endUser']) {
  return !!enduser?.slug ? `userstore.${enduser.slug}.${store}` : `userstore.${store}`;
}

function getFromEndUserStore<T>(field: LocalStorageKey, defaultValue: T, enduser: CBStore['endUser']) {
  const storekey = getStoreKey(field, enduser);
  return parseLocalStorageJSON(storekey, defaultValue);
}
