import { GenericStep, IStepSelectionCommand, initStep, StepType } from './Step';
import { RecordOption } from '../../options/option-utils';

export interface BaseStep extends GenericStep<StepType.Base> {
  selected: IStepSelectionCommand | null;
  resource: RecordOption | null;
}

/**
 * ~~ note on the type assertion and performance here ~~
 *
 * this is elegant, but it involves iterating the object keys and creating a new object which is not free; doubtful
 * v8 can optimize this super well
 *
 *    export const initBaseStep = (resource: RecordOption | null): BaseStep => ({
 *      ...initStep(StepType.Base),
 *      selected: null,
 *      resource,
 *    });
 *
 * this is much faster because only one object is created and there is no iteration needed, only catch is a brief
 * window of unsafe access to `s` until all properties are initialized (type assertions create regions of unsafe code)
 *
 *    export const initBaseStep = (resource: RecordOption | null): BaseStep => {
 *      const s = initStep(StepType.Base) as BaseStep;
 *      s.selected = null;
 *      s.resource = resource;
 *      return s;
 *    };
 */
export const initBaseStep = (resource: RecordOption | null): BaseStep => {
  const s = initStep(StepType.Base) as BaseStep;
  s.selected = null;
  s.resource = resource;
  return s;
};
