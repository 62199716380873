import React, { useState, useMemo, useEffect } from 'react';
import { Collapse, Input, Tooltip, SubHeading } from '@commandbar/design-system/components/antd';
import { Trash02 } from '@commandbar/design-system/icons/react';
import { ActionEditor } from '../ActionEditor';
import { StyledTextArea } from '../helphub/shared';
import { CmdButton, CmdDivider, CmdDropdown, CmdLabel, CmdSwitch, CmdTypography } from '@commandbar/design-system/cmd';
import { PanelLabel } from '../components/styled';
import { ReactComponent as CaretUp } from '../../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';
import { AudienceSelect } from '../AudienceSelector';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { ICopilotFallback } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import styled from '@emotion/styled';

const FallbackItem = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FallbackItemHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: #ffffff;
  padding: 12px 16px;
  gap: 8px;
`;

const BorderedPanel = styled(Collapse.Panel)`
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &.ant-collapse-item-active {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  &.ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }
`;

const triggerOptions = [
  { value: 'no_answer', label: 'Answer not found', description: 'Copilot was unable to generate an answer' },
  {
    value: 'negative_feedback',
    label: 'Negative feedback',
    description: 'User marked a Copilot message as unhelpful or negative',
  },
];

interface FallbackFormProps {
  fallback: ICopilotFallback;
  index: number;
  activeKeys: string[];
  handleSaveFallback: (updatedFallback: ICopilotFallback) => void;
  handleDeleteFallback: (id: number, index: number) => void;
  toggleActiveKey: (index: number) => void;
}

const FallbackForm: React.FC<FallbackFormProps> = ({
  fallback,
  index,
  activeKeys,
  handleSaveFallback,
  handleDeleteFallback,
  toggleActiveKey,
}) => {
  const { rules } = useAppContext();
  const [localFallback, setLocalFallback] = useState(fallback);
  const disabledReasons = useMemo(() => {
    const reasons: string[] = [];

    if (!localFallback.title) {
      reasons.push('Title cannot be empty.');
    }
    if (!localFallback.message) {
      reasons.push('Fallback message cannot be empty.');
    }
    if (localFallback.ctas.length > 0 && !localFallback.ctas[0]?.cta) {
      reasons.push('CTA text cannot be empty.');
    }

    return reasons;
  }, [localFallback.title, localFallback.message, localFallback.ctas, localFallback.ctas[0]]);

  const isSaveDisabled = disabledReasons.length > 0;

  useEffect(() => {
    setLocalFallback(fallback);
  }, [fallback]);

  const handleInputChange = (field: keyof ICopilotFallback, value: any) => {
    setLocalFallback({ ...localFallback, [field]: value });
  };

  const handleSave = () => {
    handleSaveFallback(localFallback);
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={() => null}
      expandIconPosition="end"
      style={{
        borderRadius: '8px',
        marginBottom: '16px',
      }}
      key={index}
      activeKey={activeKeys}
      onChange={() => toggleActiveKey(index)}
    >
      <BorderedPanel
        key={`fallback-${index}`}
        header={
          <FallbackItemHeader>
            <PanelLabel style={{ fontSize: '14px' }}>
              {localFallback.title}{' '}
              {activeKeys.includes(`fallback-${index}`) ? (
                <CaretUp style={{ color: CB_COLORS.neutral600 }} />
              ) : (
                <CaretDown style={{ color: CB_COLORS.neutral600 }} />
              )}
            </PanelLabel>
            {!localFallback.is_default && (
              <Trash02
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteFallback(localFallback.id, index);
                }}
                style={{ cursor: 'pointer' }}
              />
            )}
          </FallbackItemHeader>
        }
      >
        <FallbackItem key={localFallback.id}>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <SubHeading>Title</SubHeading>
            <Input
              disabled={localFallback.is_default}
              value={localFallback.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              style={{ width: '100%' }}
            />
          </div>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <SubHeading>Trigger</SubHeading>
            <div style={{ display: 'flex' }}>
              <CmdDropdown.Menu>
                <CmdDropdown.Trigger
                  disabled={localFallback.is_default}
                  style={{
                    width: '100%',
                    cursor: localFallback.is_default ? 'not-allowed' : 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textAlign: 'left',
                      background: localFallback.is_default ? '#f5f5f5' : '#FFFFFF',
                      border: '1px solid rgba(0, 0, 0, 0.08)',
                      borderRadius: '8px',
                      padding: '12px',
                      flexGrow: 1,
                      outline: 'none',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <CmdTypography.Body
                        style={{
                          color: localFallback.is_default ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.85)',
                        }}
                        fontWeight="medium"
                      >
                        {triggerOptions.find((option) => option.value === localFallback.trigger_event)?.label}
                      </CmdTypography.Body>
                      <CmdTypography.Body
                        style={{
                          color: localFallback.is_default ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.5)',
                        }}
                        fontSize="sm"
                      >
                        {triggerOptions.find((option) => option.value === localFallback.trigger_event)?.description}
                      </CmdTypography.Body>
                    </div>
                    <CaretDown />
                  </div>
                </CmdDropdown.Trigger>
                <CmdDropdown.Content matchTriggerWidth>
                  <CmdDropdown.RadioGroup
                    value={localFallback.trigger_event}
                    onValueChange={(e) => {
                      handleInputChange('trigger_event', e);
                    }}
                  >
                    {triggerOptions.map((template, index) => (
                      <div key={template.value}>
                        {index === triggerOptions.length - 1 && <CmdDivider />}
                        <CmdDropdown.RadioItem
                          key={template.value}
                          value={template.value}
                          style={{
                            marginTop: index === 0 ? 0 : '4px',
                            marginBottom: index === triggerOptions.length - 1 ? 0 : '4px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '2px',
                              padding: '4px',
                            }}
                          >
                            <CmdTypography.Body fontWeight="medium">{template.label}</CmdTypography.Body>
                            <CmdTypography.Body
                              style={{
                                color: '#797C85',
                              }}
                              fontSize="sm"
                            >
                              {template.description}
                            </CmdTypography.Body>
                          </div>
                        </CmdDropdown.RadioItem>
                      </div>
                    ))}
                  </CmdDropdown.RadioGroup>
                </CmdDropdown.Content>
              </CmdDropdown.Menu>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <SubHeading>Audience</SubHeading>
            <AudienceSelect
              hasCustomAudience={false}
              disabled={localFallback.is_default}
              rules={rules}
              audience={localFallback.audience || { type: 'all_users' }}
              onChange={(audience) => handleInputChange('audience', audience)}
            />
          </div>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <CmdLabel>Copilot Response</CmdLabel>
            <StyledTextArea
              rows={3}
              value={localFallback.message}
              onChange={(e) => handleInputChange('message', e.target.value)}
              style={{ border: `1px solid ${CB_COLORS.neutral300}` }}
            />
          </div>
          <div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '4px' }}>
              <CmdLabel tooltip="Set a button with an action that will appear below your fallback message">
                CTA
              </CmdLabel>
              <CmdSwitch
                checked={localFallback.ctas.length > 0}
                onChange={(checked) => {
                  const updatedCTAs = checked ? [{ cta: '', action: { type: 'link', value: '' } }] : [];
                  handleInputChange('ctas', updatedCTAs);
                }}
                style={{ marginLeft: 'auto' }}
              />
            </div>
            {localFallback.ctas[0] && (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Input
                  value={localFallback.ctas[0].cta ?? ''}
                  onChange={(e) => {
                    const updatedCTAs = [{ ...localFallback.ctas[0], cta: e.target.value }];
                    handleInputChange('ctas', updatedCTAs);
                  }}
                  placeholder="Chat with us"
                />
                <ActionEditor
                  possibleTypes={[
                    'click',
                    'execute_command',
                    'help_doc',
                    'video',
                    'link',
                    'page',
                    'open_chat',
                    'chat_handoff',
                    'open_helphub',
                    'open_bar',
                    'nudge',
                    'questlist',
                  ]}
                  lightBorder
                  action={localFallback.ctas[0].action}
                  onActionChange={(action) => {
                    if (action.type === 'dismiss' || action.type === 'snooze') return;
                    const updatedCTAs = [{ ...localFallback.ctas[0], action }];
                    handleInputChange('ctas', updatedCTAs);
                  }}
                />
              </div>
            )}
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 12, marginTop: '8px' }}
            >
              <Tooltip showIf={disabledReasons.length > 0} content={disabledReasons.join(' ')}>
                <CmdButton
                  variant="primary"
                  onClick={handleSave}
                  disabled={
                    isSaveDisabled ||
                    (localFallback.title === fallback.title &&
                      localFallback.trigger_event === fallback.trigger_event &&
                      localFallback.audience === fallback.audience &&
                      localFallback.message === fallback.message &&
                      localFallback.ctas === fallback.ctas)
                  }
                >
                  Save
                </CmdButton>
              </Tooltip>
            </div>
          </div>
        </FallbackItem>
      </BorderedPanel>
    </Collapse>
  );
};

export default FallbackForm;
