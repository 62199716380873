import { ICommandType } from '@commandbar/internal/middleware/types';

interface IStepSelection {
  type: 'command' | 'resource' | 'parameter';
}

export interface IStepSelectionCommand extends IStepSelection {
  category: number | null;
  label: string;
  data: ICommandType;
}

export interface IResourceCategory {
  contextKey: string;
  label: string;
}

export interface IStepSelectionParameter extends IStepSelection {
  category: null;
  data: any;
}

export enum StepType {
  Base = 'base',
  Execute = 'execute',
  LongTextInput = 'longtextinput',
  MultiSelect = 'multiselect',
  Select = 'select',
  TextInput = 'textinput',
  Dashboard = 'dashboard',
}

export interface GenericStep<T extends StepType> {
  completed: boolean;
  type: T;
}

export const initStep = <T extends StepType>(type: T): GenericStep<T> => ({ completed: false, type });
