import React from 'react';
import { CmdCard } from '../cards';
import { CmdDivider } from '../divider';
import { CmdTypography } from '../typography';

interface Stats {
  color: string;
  title: string;
  metric: string;
}

interface GraphDetailsStatCardProps {
  title: string;
  subtitle: string;
  metric: string;
  values?: Stats[];
}

export const GraphDetailsStatCard = ({ title, subtitle, metric, values }: GraphDetailsStatCardProps) => {
  return (
    <CmdCard className="px-md py-md">
      <CmdTypography.HelpText variant="secondary" fontSize="sm" fontWeight="medium">
        {subtitle}
      </CmdTypography.HelpText>
      <div className="flex justify-between my-sm">
        <CmdTypography.Body fontWeight="bold" variant="tertiary">
          {title}
        </CmdTypography.Body>
        <CmdTypography.Body fontWeight="bold">{metric}</CmdTypography.Body>
      </div>
      {values && (
        <>
          <div className="my-md">
            <CmdDivider />
          </div>
          <div className="flex gap-sm flex-col">
            {values.map(({ title, color, metric }, index) => (
              <div className="flex justify-between items-center" key={index}>
                <div className="flex gap-sm items-center">
                  <div className="bg-[color] h-md w-md rounded-sm" style={{ backgroundColor: color }}></div>
                  <CmdTypography.Body variant="secondary" fontSize="sm">
                    {title}
                  </CmdTypography.Body>
                </div>
                <CmdTypography.Body fontWeight="bold" fontSize="sm">
                  {metric}
                </CmdTypography.Body>
              </div>
            ))}
          </div>
        </>
      )}
    </CmdCard>
  );
};
