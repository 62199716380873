/**
 * This is a modification of the night owl theme in prism
 * https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/src/styles/prism/night-owl.js
 */

import React from 'react';

const theme: Record<string, React.CSSProperties> = {
  'code[class*="language-"]': {
    color: '#9CA4B9',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    fontSize: '1em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: 'white',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    fontSize: '1em',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '0.5em 0',
    background: '#070D1D',
    overflow: 'visible',
  },
  'pre[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'pre[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'code[class*="language-"]::-moz-selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'code[class*="language-"] ::-moz-selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'pre[class*="language-"]::selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'pre[class*="language-"] ::selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'code[class*="language-"]::selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  'code[class*="language-"] ::selection': {
    textShadow: 'none',
    background: 'rgba(29, 59, 83, 0.99)',
  },
  ':not(pre) > code[class*="language-"]': {
    color: 'white',
    background: '#011627',
    padding: '0.1em',
    borderRadius: '0.3em',
    whiteSpace: 'normal',
  },
  comment: {
    color: '#9CA4B9',
    fontStyle: 'normal',
  },
  prolog: {
    color: 'rgb(99, 119, 119)',
    fontStyle: 'italic',
  },
  cdata: {
    color: 'rgb(99, 119, 119)',
    fontStyle: 'italic',
  },
  punctuation: {
    color: 'rgb(199, 146, 234)',
  },
  '.namespace': {
    color: 'rgb(178, 204, 214)',
  },
  deleted: {
    color: 'rgba(239, 83, 80, 0.56)',
    fontStyle: 'italic',
  },
  symbol: {
    color: 'rgb(128, 203, 196)',
  },
  property: {
    color: 'rgb(128, 203, 196)',
  },
  tag: {
    color: '#7379FF',
  },
  operator: {
    color: '#7379FF',
  },
  keyword: {
    color: '#7379FF',
  },
  boolean: {
    color: '#829DE7',
  },
  number: {
    color: 'rgb(247, 140, 108)',
  },
  constant: {
    color: 'rgb(130, 170, 255)',
  },
  function: {
    color: '#4AE4F9',
  },
  builtin: {
    color: 'rgb(130, 170, 255)',
  },
  char: {
    color: 'rgb(130, 170, 255)',
  },
  selector: {
    color: 'rgb(199, 146, 234)',
    fontStyle: 'italic',
  },
  doctype: {
    color: 'rgb(199, 146, 234)',
    fontStyle: 'italic',
  },
  'attr-name': {
    color: 'rgb(173, 219, 103)',
    fontStyle: 'italic',
  },
  inserted: {
    color: 'rgb(173, 219, 103)',
    fontStyle: 'italic',
  },
  string: {
    color: '#68D18C',
  },
  url: {
    color: 'rgb(173, 219, 103)',
  },
  entity: {
    color: 'rgb(173, 219, 103)',
  },
  '.language-css .token.string': {
    color: '#68D18C',
  },
  '.style .token.string': {
    color: '#68D18C',
  },
  'class-name': {
    color: 'rgb(255, 203, 139)',
  },
  atrule: {
    color: 'rgb(255, 203, 139)',
  },
  'attr-value': {
    color: 'rgb(255, 203, 139)',
  },
  regex: {
    color: 'rgb(214, 222, 235)',
  },
  important: {
    color: 'rgb(214, 222, 235)',
    fontWeight: 'bold',
  },
  variable: {
    color: '#68D18C',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
};

export default theme;
