import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';

import { Copy04, Play } from '@commandbar/design-system/icons/react';
import { CaretDown } from '../helphub/shared';
import { useAppContext } from 'editor/src/AppStateContext';
import Sender from 'editor/src/management/Sender';

import { Dropdown, Tooltip, CB_COLORS } from '@commandbar/design-system/components/antd';
import { DEBUG_NUDGE_PARAM, isValidURL } from '@commandbar/internal/util/location';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import { isNudgeLive } from './utils';
import { usePreview } from 'editor/src/hooks/usePreview';
import { StudioPreviewSetupInstructions } from '../components/PreviewButton/StudioPreviewSetupIntructions';
import { useIsChromeExtensionInsalled } from 'editor/src/hooks';

const Container = styled.div`
  position: relative;
`;

const StyledButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ disabled }) => (disabled ? '#d9d9d9' : 'rgba(10, 10, 15, 0.24)')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)'};
`;

const StyledPlayButton = styled.button`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  border: none;
  color: rgb(81, 81, 92);
  font-size: 14px;
  font-weight: 600;
  gap: 4px;
  line-height: 1.5715;
  width: 32px;
  padding: 0;
  border-radius: 4px 0px 0px 4px;

  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)'};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.black)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledStandalonePlayButton = styled(StyledPlayButton)<{ disabled?: boolean }>`
  width: 100%;
  padding: 0 12px;
  border-radius: 4px;
`;

interface StandaloneEditorButtonProps {
  nudge: INudgeType;
  isDirty: boolean;
  onChange: (nudge: INudgeType) => void;
  isSaving: boolean;
}

export const StandaloneEditorPreviewButton = ({ nudge, isDirty, isSaving }: StandaloneEditorButtonProps) => {
  const [showExtensionSetupInstructions, setShowExtensionSetupInstructions] = useState(false);

  const { organization } = useAppContext();
  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();
  const { flags } = useAppContext();
  const { onStart } = usePreview();

  const isUnsavedItem = Number(nudge.id) < 0 || isSaving;
  const isPreviewDisabled = isUnsavedItem || (isNudgeLive(nudge) && isDirty);

  const handlePlayClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (flags['release-preview-v-2']) {
      onStart({ type: 'preview', skipPrompt: e.metaKey, experience: { type: 'nudge', nudge } });
    } else if (isChromeExtensionInstalled && isValidURL(organization.base_url, true)) {
      let baseUrl = organization.base_url;
      if (!baseUrl.includes('://') && baseUrl.includes('.')) {
        baseUrl = `https://${baseUrl}`;
      }
      const url = new URL(baseUrl);
      const urlParams = url.searchParams;
      urlParams.set(DEBUG_NUDGE_PARAM, encodeURIComponent(nudge.id));
      window.open(url, '_blank');
    } else {
      setShowExtensionSetupInstructions(!showExtensionSetupInstructions);
    }
  };

  return (
    <Container>
      <Tooltip
        content="Save your nudge before previewing"
        showIf={flags['release-preview-v-2'] ? isPreviewDisabled : isUnsavedItem}
      >
        <StyledButtonContainer disabled={flags['release-preview-v-2'] ? isPreviewDisabled : isUnsavedItem}>
          <StyledStandalonePlayButton
            onClick={handlePlayClick}
            disabled={flags['release-preview-v-2'] ? isPreviewDisabled : isUnsavedItem}
          >
            <Play width={12} color={isPreviewDisabled ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.neutral700} />
          </StyledStandalonePlayButton>
        </StyledButtonContainer>
      </Tooltip>
      {showExtensionSetupInstructions && !flags['release-preview-v-2'] && (
        <StudioPreviewSetupInstructions
          isChromeExtensionInstalled={isChromeExtensionInstalled}
          onClose={() => setShowExtensionSetupInstructions(false)}
        />
      )}
    </Container>
  );
};

const Caret = styled.button`
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  border: none;
  background: #fff;
  border-left: 1px solid ${CB_COLORS.neutral100};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07);

  box-shadow: ${({ disabled }) =>
    disabled ? 'none' : '0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07)'};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.black)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const OverlayContainer = styled.div`
  display: flex;
  width: 212px;
  padding: 4px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #dfdfe2;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
`;

const DropDownButton = styled.button<{ disabled?: boolean }>`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 9px 8px;
  margin: 0;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  transition: background 0.2s ease-in-out;

  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : CB_COLORS.neutral800)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${CB_COLORS.neutral100};
  }
`;

interface PreviewButtonProps {
  nudge: INudgeType;
  isDisabled?: boolean;
}

export const PreviewButton = ({ nudge, isDisabled }: PreviewButtonProps) => {
  const { isStudio } = useAppContext();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const openInNewTab = useCallback(
    (url: string) => {
      const currentUrl = new URL(url);
      const urlParams = currentUrl.searchParams;
      urlParams.set(DEBUG_NUDGE_PARAM, encodeURIComponent(nudge.id));
      window.open(currentUrl, '_blank');
    },
    [nudge.id],
  );

  return (
    <Tooltip content="Save your nudge before previewing" showIf={isDisabled}>
      <StyledButtonContainer>
        <StyledPlayButton
          disabled={isDisabled}
          onClick={() => {
            Sender.closeNudgeMocks();
            Sender.hideEditor();
            Sender.startNudgeDebug(nudge);
          }}
        >
          <Play width={12} color={CB_COLORS.neutral700} />
        </StyledPlayButton>

        <Dropdown
          disabled={isDisabled}
          open={dropDownOpen}
          onOpenChange={setDropDownOpen}
          trigger={['hover']}
          placement="bottomLeft"
          overlay={
            <OverlayContainer>
              <DropDownButton
                onClick={() => {
                  Sender.closeNudgeMocks();
                  Sender.hideEditor();
                  Sender.startNudgeDebug(nudge);
                }}
              >
                <Play width={16} height={16} opacity={0.5} color={CB_COLORS.neutral1000} />
                Test
              </DropDownButton>

              <DropDownButton
                onClick={async () => {
                  const { url } = await Sender.getHostUrl(isStudio);
                  openInNewTab(url);
                }}
              >
                <Copy04 opacity={0.5} color={CB_COLORS.neutral1000} />
                Test in a new tab
              </DropDownButton>
            </OverlayContainer>
          }
        >
          <Caret disabled={isDisabled}>
            <CaretDown width={12} height={12} color={CB_COLORS.neutral500} />
          </Caret>
        </Dropdown>
      </StyledButtonContainer>
    </Tooltip>
  );
};
