import { useState, useRef, useCallback } from 'react';

// In Salesforce LWC, ResizeObserver is not available and we need to use a polyfill
let ResizeObserver = window.ResizeObserver;
if (!!process.env.SALESFORCE_BUILD) {
  // FIXME: This was the only way I could get the polyfill tree-shaken properly in our regular build
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { ResizeObserver: Polyfill } = require('@juggle/resize-observer');
  ResizeObserver = Polyfill;
}

// 2024-05-07 JL NOTE:
// This is a better API than the original `useResizeObserver` because it doesn't require storing the element in state,
// which can cause a re-render loop. Will plan to replace uses of `useResizeObserver` with this one in the future.
// --

const useResizeObserver = () => {
  const [size, setSize] = useState<{ width: number; height: number } | null>(null);

  const observer = useRef<ResizeObserver | null>(null);

  const callbackRef = useCallback(
    (element: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      if (!element) {
        setSize(null);
        return;
      }

      observer.current = new ResizeObserver((entries) => {
        const [entry] = entries;
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      });
      observer.current.observe(element);
    },
    [setSize],
  );

  return { ref: callbackRef, width: size?.width, height: size?.height };
};

export default useResizeObserver;
