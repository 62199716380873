/** @jsx jsx */
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { ChangeEventHandler } from 'react';
import { jsx } from '@emotion/core';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledFormFieldBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledFormFieldBlock';
import StyledBaseInput from '@commandbar/internal/client/themesV2/components/shared/StyledBaseInput';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useSurveyResponse } from '../SurveyResponseProvider';

const TextInputShortBlock = ({ placeholder }: { placeholder: string }) => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setSurveyResponse({ type: 'string', value });
  };

  return _.flags?.['release-themes-v2'] ? (
    <StyledFormFieldBlock>
      <StyledBaseInput
        themeV2={themeV2}
        value={(surveyResponse?.value as string) || ''}
        onChange={handleTextChange}
        aria-label={placeholder}
        placeholder={placeholder}
        onClick={(e) => e.stopPropagation()}
      />
    </StyledFormFieldBlock>
  ) : (
    <input
      value={(surveyResponse?.value as string) || ''}
      onChange={handleTextChange}
      aria-label={placeholder}
      placeholder={placeholder}
      onClick={(e) => e.stopPropagation()}
      style={{
        width: '100%',
        padding: theme.surveyTextInputShort.padding,
        margin: theme.surveyTextInputShort.margin,
        color: theme.surveyTextInputShort.color,
        fontFamily: theme.surveyTextInputShort.fontFamily,
        fontSize: isMobileDevice() ? '16px' : theme.surveyTextInputShort.fontSize,
        border: theme.surveyTextInputShort.border,
        borderRadius: theme.surveyTextInputShort.borderRadius,
        background: theme.surveyTextInputShort.background,
        boxShadow: theme.surveyTextInputShort.boxShadow,
        lineHeight: '16px',
        fontWeight: 400,
        resize: 'none',
      }}
      css={{
        '::placeholder': {
          color: '#A2A2A9',
        },
      }}
    />
  );
};

export default TextInputShortBlock;
