import { CmdSortableColumn, CmdTypography } from '@commandbar/design-system/cmd';

import { useParams } from 'react-router-dom';
import { TableRowData } from '@commandbar/internal/middleware/analytics/common';
import { CmdColumnDef } from '@commandbar/design-system/cmd';
import { useAnalyticsContext } from '../AnalyticsContext';
import NudgeDetailPanelTrigger from './surveys/NudgeDetailPanel';
import { formatNumber, formatPercentage } from '../utils';
import Table from '../components/Table';
import { SurveyDetailsWithResponses } from '@commandbar/internal/middleware/analytics/surveys';

const SurveyResponseTable = ({
  surveyDetails,
  isLoading,
  error,
  fetchData,
  rows,
}: {
  surveyDetails?: SurveyDetailsWithResponses;
  isLoading: boolean;
  error: boolean;
  fetchData: () => void;
  rows: { [key: string | number]: any }[];
}) => {
  const { id } = useParams<{ id: string }>();
  const { timeFilterRange } = useAnalyticsContext();

  const columns: CmdColumnDef<TableRowData, string | number>[] = [
    {
      accessorKey: 'step_title',
      header: 'Step',
      enableGlobalFilter: true,
      cell: (cell) => (
        <NudgeDetailPanelTrigger
          nudgeId={id}
          triggerDisplayValue={cell.getValue().toString()}
          initilActiveIndex={Number(cell.row.id)}
          timeFilterRange={timeFilterRange}
          surveyDetails={surveyDetails}
        />
      ),
    },
    {
      accessorKey: 'responses',
      header: ({ column }) => <CmdSortableColumn column={column} title="Responses" />,
      cell: ({ getValue }) => formatNumber(getValue()),
    },
    {
      accessorKey: 'response_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Response rate" />,
      cell: ({ getValue }) => formatPercentage(getValue()),
    },
  ];

  return (
    <Table
      toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Responses</CmdTypography.Body>}
      columns={columns}
      data={rows || []}
      isLoading={isLoading}
      empty={{
        error,
        refetchData: fetchData,
      }}
    />
  );
};

export default SurveyResponseTable;
