import * as React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { cn } from '../util';
import { buttonStyles } from '../button';
import { X } from '@commandbar/design-system/icons/react';
import { type VariantProps } from 'class-variance-authority';

const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
const AlertDialogPortal = AlertDialogPrimitive.Portal;

const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay> & { zIndex?: string | number }
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(
      'fixed inset-0 bg-black/30 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 z-max',
      className,
    )}
    {...props}
    ref={ref}
  />
));
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName;

type AlertDialogContentProps = {
  overlayClosable?: boolean;
  overlay?: boolean;
};

const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content> & AlertDialogContentProps
>(({ className, overlayClosable = false, overlay = true, ...props }, ref) => (
  <AlertDialogPortal>
    {overlay && (
      <AlertDialogOverlay
        onClick={() => {
          if (overlayClosable) {
            const escapeEvent = new KeyboardEvent('keydown', { key: 'Escape' });
            document.dispatchEvent(escapeEvent);
          }
        }}
      />
    )}
    <AlertDialogPrimitive.Content
      ref={ref}
      className={cn(
        'fixed mt-0 left-[50%] bg-gray100 top-[50%] z-max grid min-w-[300px] translate-x-[-50%] translate-y-[-50%] shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-md',
        className,
      )}
      {...props}
    />
  </AlertDialogPortal>
));

AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName;

const AlertDialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="relative">
    <div className={cn('flex flex-col space-y-2 text-center sm:text-left', className)} {...props} />
  </div>
);
AlertDialogHeader.displayName = 'AlertDialogHeader';

const AlertDialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 pl-lg pr-lg pb-lg', className)}
    {...props}
  >
    {props.children}
  </div>
);
AlertDialogFooter.displayName = 'AlertDialogFooter';

type AlertDialogTitleProps = {
  size?: 'sm' | 'base' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  hasCloseButton?: boolean;
};

const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title> & AlertDialogTitleProps
>(({ className, size = 'base', hasCloseButton = true, ...props }, ref) => (
  <AlertDialogPrimitive.Title
    ref={ref}
    className={cn(
      `text-${size} mb-0 p-lg flex justify-between items-center border-solid border-[1px] border-transparent border-b-elementBase`,
      className,
    )}
    {...props}
  >
    {props.children}

    {hasCloseButton && (
      <X
        className="cursor-pointer"
        onClick={() => {
          const escapeEvent = new KeyboardEvent('keydown', { key: 'Escape' });
          document.dispatchEvent(escapeEvent);
        }}
      />
    )}
  </AlertDialogPrimitive.Title>
));
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName;

const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description
    ref={ref}
    className={cn('text-base text-muted-foreground p-lg', className)}
    {...props}
  />
));
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName;

interface ButtonProps extends VariantProps<typeof buttonStyles> {
  buttonText?: string;
  onClick?: () => void;
}

const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action> & ButtonProps
>(
  (
    {
      className,
      buttonText = 'Confirm',
      disabled = false,
      variant = 'primary',
      size,
      modifier,
      fullWidth,
      loading,
      ...props
    },
    ref,
  ) => {
    return (
      <AlertDialogPrimitive.Action
        ref={ref}
        className={cn(buttonStyles({ variant, disabled, size, modifier, fullWidth, loading }), className)}
        {...props}
      >
        {buttonText}
      </AlertDialogPrimitive.Action>
    );
  },
);
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName;

const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel> & ButtonProps
>(
  (
    {
      className,
      buttonText = 'Cancel',
      variant = 'link',
      disabled = false,
      size,
      modifier,
      fullWidth,
      loading,
      ...props
    },
    ref,
  ) => (
    <AlertDialogPrimitive.Cancel
      ref={ref}
      className={cn(buttonStyles({ variant, disabled, size, modifier, fullWidth, loading }), className)}
      {...props}
    >
      {buttonText}
    </AlertDialogPrimitive.Cancel>
  ),
);
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName;

interface CmdModalProps extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Root> {
  children?: React.ReactNode;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  title?: string;
  description?: string | React.ReactElement;
  trigger?: React.ReactElement;
  overlayClosable?: boolean;
  overlay?: boolean;
  width?: string | number;
  zIndex?: number;
}

const CmdModal: React.FC<CmdModalProps> & {
  Trigger: typeof AlertDialogTrigger;
  Content: typeof AlertDialogContent;
  Header: typeof AlertDialogHeader;
  Footer: typeof AlertDialogFooter;
  Title: typeof AlertDialogTitle;
  Description: typeof AlertDialogDescription;
  Action: typeof AlertDialogAction;
  Cancel: typeof AlertDialogCancel;
} = ({
  children,
  title,
  description,
  cancelButtonProps,
  confirmButtonProps,
  trigger,
  overlayClosable,
  overlay,
  width = '400px',
  zIndex,
  ...props
}) => {
  if (!children) {
    return (
      <AlertDialogPrimitive.Root {...props}>
        {trigger && <CmdModal.Trigger asChild>{trigger}</CmdModal.Trigger>}
        <AlertDialogPortal>
          <CmdModal.Content
            className={cn(
              'fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] shadow-lg duration-200 sm:rounded-md bg-gray100',
              `width-[${width}]`,
            )}
            overlay={overlay}
            overlayClosable={overlayClosable}
          >
            <CmdModal.Header>
              <CmdModal.Title>{title}</CmdModal.Title>
              <CmdModal.Description>{description}</CmdModal.Description>
            </CmdModal.Header>
            <CmdModal.Footer>
              {!!cancelButtonProps && (
                <CmdModal.Cancel {...cancelButtonProps} disabled={!!cancelButtonProps?.disabled} />
              )}
              {!!confirmButtonProps && (
                <CmdModal.Action {...confirmButtonProps} disabled={!!confirmButtonProps?.disabled} />
              )}
            </CmdModal.Footer>
          </CmdModal.Content>
        </AlertDialogPortal>
      </AlertDialogPrimitive.Root>
    );
  }
  return <AlertDialogPrimitive.Root {...props}>{children}</AlertDialogPrimitive.Root>;
};

CmdModal.Trigger = AlertDialogTrigger;
CmdModal.Content = AlertDialogContent;
CmdModal.Header = AlertDialogHeader;
CmdModal.Footer = AlertDialogFooter;
CmdModal.Title = AlertDialogTitle;
CmdModal.Description = AlertDialogDescription;
CmdModal.Action = AlertDialogAction;
CmdModal.Cancel = AlertDialogCancel;

export { CmdModal };
