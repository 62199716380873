import React, { useEffect } from 'react';

import { Alert, Tooltip, Typography } from '@commandbar/design-system/components/antd';

import { IChecklist, IEditorCommandType, INudgeType } from '@commandbar/internal/middleware/types';
import { defaults, INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import { AsyncClickButton } from './AsyncClickButton';
import EditingRule from './EditingRule';
import { CmdButton } from '@commandbar/design-system/cmd';
import { Edit03, Trash04 } from '@commandbar/design-system/icons/react';
import ConditionGroupEditor from '../conditions/ConditionGroupEditor';

export const Rule = ({
  rule,
  isDuplicateName,
  entitiesUsingThisRule = { nudges: [], checklists: [], commands: [] },
  onSave,
  onDelete,
  onEditing,
  onRuleChange,
  initialEditing = false,
  allowAddingAndRemovingRules = true,
}: {
  rule: Partial<INamedRule>;
  isDuplicateName: (rule: INamedRule) => boolean;
  entitiesUsingThisRule?: { nudges: INudgeType[]; checklists: IChecklist[]; commands: IEditorCommandType[] };
  initialEditing?: boolean;
  allowAddingAndRemovingRules?: boolean;
  onSave: (rule: INamedRule) => Promise<void>;
  onRuleChange?: (rule: INamedRule) => void;
  onDelete?: () => Promise<void>;
  onEditing?: (editing: boolean) => void;
}) => {
  const { expression = { type: 'AND', exprs: [] }, name } = rule;
  const [error, setError] = React.useState<string | null>(null);
  const [editing, setEditing] = React.useState<boolean>(initialEditing);

  const onSaveRule = async (rule: INamedRule) => {
    setError(null);

    try {
      await onSave(rule);
    } catch (e) {
      setError(String(e));
      throw e;
    } finally {
      // XXX: This value is reset because this component will be unmounted
      // setEditing(false);
    }
  };

  const numEntitiesUsingThisRule = Object.values(entitiesUsingThisRule)
    .map((entities) => entities.length)
    .reduce((a, b) => a + b, 0);

  const entitiesUsingThisRuleTitles = [
    ...entitiesUsingThisRule.commands.map((c) => `${c.text} (command)`),
    ...entitiesUsingThisRule.nudges.map((n) => `${n.slug} (nudge)`),
    ...entitiesUsingThisRule.checklists.map((c) => `${c.title} (checklist)`),
  ];

  useEffect(() => {
    onEditing && onEditing(editing);
  }, [onEditing, editing]);

  const isBuiltIn = typeof rule.id === 'string';

  if (!editing) {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ flex: 1, cursor: !isBuiltIn ? 'pointer' : 'not-allowed' }}
            onClick={!isBuiltIn ? () => setEditing(true) : undefined}
          >
            <Typography.Text>{name || '<name>'}</Typography.Text> {!isBuiltIn && <Edit03 />}
          </div>
          {numEntitiesUsingThisRule > 0 ? (
            <Tooltip placement="top" content={'in use by: ' + entitiesUsingThisRuleTitles.join(', ')}>
              <Typography.Text type="secondary" style={{ cursor: 'help', marginRight: 12 }} italic>
                <div>
                  <CmdButton variant="link" disabled icon={<Trash04 />} />
                </div>
              </Typography.Text>
            </Tooltip>
          ) : (
            onDelete && (
              <div>
                <AsyncClickButton onClick={onDelete} icon={<Trash04 />} />
              </div>
            )
          )}
        </div>
        <ConditionGroupEditor expr={expression} disabled />
      </div>
    );
  }

  return (
    <>
      {error && (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Error saving rule:
              <Typography.Text code>{error.slice(0, 100)}</Typography.Text>
            </span>
          }
          type="error"
        />
      )}
      <EditingRule
        initialRule={{
          ...defaults,
          id: -1,
          name: '',
          expression: { type: 'AND', exprs: [] },
          created: undefined,
          ...rule,
        }}
        allowAddingAndRemovingRules={allowAddingAndRemovingRules}
        isDuplicateName={isDuplicateName}
        onSave={onSaveRule}
        onRuleChange={onRuleChange}
      />
    </>
  );
};

export default Rule;
