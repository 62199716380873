import snarkdown from '@bpmn-io/snarkdown';
import styled from '@emotion/styled';
import React, { type MouseEventHandler } from 'react';

import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';

const NudgeTitleBlock = styled.div`
  width: 100%;
  display: flex;
  padding: var(--layout-padding);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 2px;
`;

const NudgeTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  color: var(--content-primary);

  /* label/lg/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-snug);
`;

const NudgeContent = styled.div`
  align-self: stretch;
  color: var(--content-secondary);

  ol,
  ul,
  li {
    all: revert;
  }
  img {
    max-width: 100%;
  }

  /* label/base/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);
`;

export const parseMarkdown = (str: string) => {
  const parsed = snarkdown(str).replace(/(<a href="(https?:)?\/\/.*?")>/g, '$1 target="_blank" rel="nofollow">');
  return sanitizeHtml(parsed);
};

const StyledNudgeTitleBlock = ({
  title,
  content,
  dismissible,
  draggable,
  handleContentLinkClick,
}: {
  title?: string;
  content?: string;
  dismissible: boolean;
  draggable?: boolean;
  handleContentLinkClick?: MouseEventHandler;
}) => {
  return (
    <NudgeTitleBlock>
      {title && (
        <NudgeTitle
          style={{
            marginRight: dismissible ? 'calc(var(--layout-padding) + 20px)' : '0px',
            minHeight: dismissible ? '20px' : '0px',
            position: 'relative',
          }}
        >
          {draggable && (
            <div
              data-draggable="drag-zone"
              data-testid="drag-zone"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                userSelect: 'none',
                cursor: 'move',
              }}
            />
          )}
          {title}
        </NudgeTitle>
      )}
      {content && (
        <NudgeContent dangerouslySetInnerHTML={{ __html: parseMarkdown(content) }} onClick={handleContentLinkClick} />
      )}
    </NudgeTitleBlock>
  );
};

export default StyledNudgeTitleBlock;
