import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '../antd/colors';
import { Link } from 'react-router-dom';

export const DASHBOARD_ICON_WIDTH = 24;
export const DASHBOARD_ACTION_ICON_WIDTH = 16;

const Container = styled.div<{ background: string; height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: ${({ height }) => (height ? height : '250px')};

  background: ${(props) => props.background};
  border: 1px solid rgba(10, 10, 15, 0.32);

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 1px rgba(0, 0, 0, 0.08), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
`;

const FOOTER_HEIGHT = '72px';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 32px;
  width: 100%;
  height: calc(100% - ${FOOTER_HEIGHT});
`;

const IconContainer = styled.div<{ shadow: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 48px;
  height: 48px;

  background: #ffffff;
  box-shadow: ${(props) => props.shadow};
  border-radius: 150px;
`;

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  color: #000000;
`;

const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: ${CB_COLORS.neutral700};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 8px;

  width: 100%;
  height: ${FOOTER_HEIGHT};
  box-sizing: border-box;
  background-color: #fff;
  border-top: 1px solid ${CB_COLORS.neutral500};
`;

const LinkStyled = styled(Link)`
  background: #ffffff;
  padding: 8px 16px;
  border: 1px solid ${CB_COLORS.neutral500};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  flex: 1 0 0;
  white-space: nowrap;

  &:hover {
    background: ${CB_COLORS.neutral100};
    color: ${CB_COLORS.neutral900};
    border: 1px solid ${CB_COLORS.neutral500};
  }
`;

const Button = styled.button`
  background: #ffffff;
  padding: 8px 16px;
  border: 1px solid ${CB_COLORS.neutral500};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  flex: 1 0 0;
  white-space: nowrap;

  &:hover {
    background: ${CB_COLORS.neutral100};
    color: ${CB_COLORS.neutral900};
    border: 1px solid ${CB_COLORS.neutral500};
  }
`;

const ButtonIcon = styled.div`
  width: 13px;
  margin-right: 6px;
  color: ${CB_COLORS.neutral1000};
  display: flex;
  align-items: center;
`;

const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 12px;
  gap: 8px;
  font-weight: 600;
  font-size: 12px;
  width: 53px;
  height: 22px;
  background: ${CB_COLORS.black};
  color: ${CB_COLORS.white};
  border-radius: 100px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

type TCardCSSProperties = {
  iconShadow: string;
  height?: string;
  background: string;
};
type TDashboardCardAction = {
  icon?: JSX.Element;
  label: string;
  to?: string;
  onClick?: React.MouseEventHandler<Element>;
};

interface IDashboardCardBase {
  styles: TCardCSSProperties;
  icon: JSX.Element;
  title: string;
  description: string | JSX.Element;
  actions: TDashboardCardAction[];
  isNew?: boolean;
}

export const DashboardCardBase = ({ styles, icon, title, description, actions, isNew }: IDashboardCardBase) => {
  return (
    <Container background={styles.background} height={styles.height}>
      <Body>
        <IconWrapper>
          <IconContainer shadow={styles.iconShadow}>{icon}</IconContainer>
          {!!isNew && <Tag>NEW</Tag>}
        </IconWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Body>
      <Footer>
        {actions.map((action, index) => {
          if (action.to)
            return (
              <LinkStyled
                key={`${action.label}${index}`}
                to={action.to}
                onClick={(e) => {
                  action.onClick?.(e);
                }}
              >
                {action.icon && <ButtonIcon>{action.icon}</ButtonIcon>} {action.label}
              </LinkStyled>
            );
          return (
            <Button key={`${action.label}${index}`} onClick={action.onClick}>
              {action.icon && <ButtonIcon>{action.icon}</ButtonIcon>} {action.label}
            </Button>
          );
        })}
      </Footer>
    </Container>
  );
};
