import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { ConditionProps } from '../../types';
import {
  DeadendsCondition,
  ExecutionsCondition,
  OpensCondition,
  SessionsCondition,
  ShortcutsCondition,
} from '@commandbar/internal/middleware/conditions';

export const ActivityCondition: React.FC<
  ConditionProps<ShortcutsCondition | OpensCondition | DeadendsCondition | ExecutionsCondition | SessionsCondition>
> = () => {
  return (
    <Row align="middle" wrap={false} style={{ gap: '4px' }}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <ConditionValueInput />
    </Row>
  );
};
