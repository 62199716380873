/** @jsx jsx */
import React from 'react';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from './ButtonWithReset';

const styles: CSSObject = {
  display: 'flex',
  width: '100%',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  color: 'var(--button-tertiary-content)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--button-tertiary-border-color)',
  background: 'var(--button-tertiary-background)',
  flex: '1 1 0',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  userSelect: 'none',

  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-semibold)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderColor: 'var(--button-tertiary-border-color-hover)',
    background: 'var(--button-tertiary-background-hover)',
    color: 'var(--button-tertiary-content-hover)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    background: 'var(--button-tertiary-background-hover)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: 'var(--button-tertiary-border-color)',
    background: 'var(--button-tertiary-background)',
    color: 'var(--button-tertiary-content)',
  },
};

const slug = 'tertiaryButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
};

const StyledTertiaryButton = ({
  themeV2,
  prefixIcon,
  suffixIcon,
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset css={[overrides, css(styles)]} {...props}>
      {prefixIcon}
      <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </span>
      {suffixIcon}
    </ButtonWithReset>
  );
};

StyledTertiaryButton.styles = styles;
StyledTertiaryButton.slug = slug;
StyledTertiaryButton.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledTertiaryButton themeV2={{ themeV2: themeV2, mode: mode }}>Button</StyledTertiaryButton>
);

export default StyledTertiaryButton;
