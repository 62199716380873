import React from 'react';
import { Alert } from '@commandbar/design-system/components/antd';

const RouterWarning = () => (
  <a
    href="https://www.commandbar.com/docs/sdk/lifecycle/addrouter/"
    target="_blank"
    rel="noopener noreferrer"
    style={{
      display: 'inline-block',
      marginBottom: 10,
      width: '100%',
    }}
  >
    <Alert
      type="warning"
      message={<span>You must define a router for this option to work. Click here to learn more.</span>}
      showIcon
      style={{ width: '100%' }}
    />
  </a>
);

export default RouterWarning;
