import * as t from 'io-ts';

import { listObject } from './generics';

export const HistoryEventV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      changed_by: t.string,
      description: t.string,
      created: t.string,
      sha1: t.string,
      version_num: t.number,
    }),
    t.partial({}),
  ],
  'HistoryEvent',
);

export class HistoryEvent {
  public static list = listObject(HistoryEventV, 'histories');
}
