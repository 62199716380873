import type { Action, LabeledAction } from '@cb/types/entities/command/actions';
import type {
  IChecklist,
  IEditorCommandTypeLite,
  INudgeType,
  IRecommendationSet,
  IRuleExpression,
} from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import _ from 'lodash';
import React from 'react';
import {
  HeaderRow,
  Tooltip,
  Input,
  SortableList,
  SubHeading,
  Container,
  Modal,
  SimplePanel,
  Header,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { Trash04, FlipBackward, AlertTriangle } from '@commandbar/design-system/icons/react';
import { useIsEditorOpen } from 'editor/src/hooks';
import { CmdButton, CmdSwitch, cmdToast } from '@commandbar/design-system/cmd';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { StyledSelect } from '../nudges/styled';
import { CaretDown, DragHandle } from './shared';
import Select from 'antd/lib/select';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { ActionEditor } from '../ActionEditor';
import { DetailTabPane, DetailTabPaneInner, DetailTabs } from '../components/styled';
import ConditionGroupEditor from '../conditions/ConditionGroupEditor';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { AudienceSelect } from '../AudienceSelector';

enum RecommendationSetFormTabs {
  DETAILS = 'tab-details',
  TARGETING = 'tab-targeting',
}

const CurrentFormAction = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${CB_COLORS.neutral600};
`;

const NameInput = styled(Input.TextArea, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: 0;
  max-width: 200px !important;
  min-height: 0 !important;
  font-size: 16px;
  line-height: 16px !important;
  color: ${CB_COLORS.neutral1000};
  border: ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};

  &:focus,
  &:hover {
    border: 1px solid ${CB_COLORS.neutral500};
  }

  &.ant-input {
    transition: all 0.3s, height 0s, font-size 0s;
  }
`;

const getRecommendationLabel = (
  labeledAction: LabeledAction,
  commands: IEditorCommandTypeLite[],
  questlists: IChecklist[],
  nudges: INudgeType[],
) => {
  switch (labeledAction.action.type) {
    case 'execute_command':
      if (labeledAction.action.meta.command === 'cmd') {
        return 'Unlabeled action';
      } else {
        const commandID = labeledAction.action.meta.command;
        const command = commands.find((c: IEditorCommandTypeLite) => c.id === Number(commandID));
        return command?.text || 'Unlabeled document';
      }
    case 'click':
      return 'Unlabeled click';
    case 'open_chat':
      return 'Open third-party chat';
    case 'chat_handoff':
      return 'Chat handoff';
    case 'questlist':
      const questlistID = labeledAction.action.value;
      const questlist = questlists.find((c) => c.id === Number(questlistID));
      return questlist?.title || 'Unlabeled Checklist';
    case 'nudge':
      const nudgeID = labeledAction.action.value;
      const nudge = nudges.find((c) => c.id === Number(nudgeID));
      if (nudge) {
        return (nudge && nudge.slug) || (nudge.steps.length === 1 ? nudge.steps[0].title : 'Unlabeled Nudge');
      }
      return 'Unlabeled Nudge';
    case 'link':
      return labeledAction.action.value || 'Unlabeled link';
    case 'open_copilot':
      return 'Open Copilot';
    case 'open_helphub':
      return 'Open HelpHub';
    case 'open_bar':
      return 'Open Spotlight';
    default:
      return 'Recommendation';
  }
};

const RecommendationSetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RecommendationSetForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const DraggableRow = styled.div`
  height: 48px;
  border-radius: 4px;
  gap: 8px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0px;
  padding: 8px;
  background-color: ${CB_COLORS.neutral0};
`;

const TrashButton = styled.button`
  border: 1px solid ${CB_COLORS.neutral300};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  width: 40px;
  height: 100%;
`;

const Recommendation = ({
  labeledAction,
  onChange,
  del,
  draggable = true,
}: {
  labeledAction: LabeledAction;
  onChange: (action: Action, label: string) => void;
  del?: () => void;
  draggable?: boolean;
}) => {
  const { commands, checklists, nudges } = useAppContext();
  const defaultLabel = getRecommendationLabel(labeledAction, commands, checklists, nudges);

  const label = labeledAction.cta || '';

  const onActionChange = (action: Action) => {
    onChange(action, action.type === labeledAction.action.type ? label : '');
  };

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(labeledAction.action, e.target.value);
  };

  return (
    <DraggableRow>
      <div style={{ visibility: draggable ? 'visible' : 'hidden' }}>
        <DragHandle />
      </div>
      <div style={{ width: '60%' }}>
        <ActionEditor
          possibleTypes={[
            'click',
            'execute_command',
            'help_doc',
            'video',
            'link',
            'page',
            'open_chat',
            'open_copilot',
            'open_bar',
            'nudge',
            'questlist',
          ]}
          lightBorder
          action={labeledAction.action}
          onActionChange={onActionChange}
        />
      </div>
      <Input
        placeholder={defaultLabel.includes('Unlabeled') ? '' : defaultLabel}
        value={labeledAction.cta}
        style={{
          width: '40%',
          padding: '5px 8px',
        }}
        onChange={onLabelChange}
      />

      <TrashButton onClick={del}>
        <Trash04 color={CB_COLORS.neutral600} width={16} height={16} />
      </TrashButton>
    </DraggableRow>
  );
};

const RuleExpression = ({
  expr,
  disabled = false,
  onChange,
}: {
  expr: IRuleExpression;
  disabled?: boolean;
  onChange: (expr: IRuleExpression) => void;
}) => {
  const targetingType = expr.type === 'LITERAL' && expr.value === true ? 'all_pages' : 'custom';

  return (
    <>
      <StyledSelect<'all_pages' | 'custom'>
        disabled={disabled}
        value={targetingType}
        style={{ width: '100%' }}
        onChange={(e) => {
          if (e === 'all_pages') {
            onChange({ type: 'LITERAL', value: true });
          } else {
            onChange({ type: 'CONDITION', condition: { type: 'url', operator: 'includes', value: '' } });
          }
        }}
        suffixIcon={<CaretDown />}
      >
        <Select.Option title={'On all pages'} value={'all_pages'}>
          On all pages
        </Select.Option>
        <Select.Option value={'custom'}>If...</Select.Option>
      </StyledSelect>

      {targetingType === 'custom' && (
        <ConditionGroupEditor disabled={disabled} onChange={onChange} expr={expr} includeCategories={['Page']} />
      )}
    </>
  );
};

interface RecommendationSetDetailProps {
  initialRecommendationSet: IRecommendationSet;
  onClose: () => void;
  onDelete: (recommendationSet: IRecommendationSet) => void;
  onSave: (recommendationSet: IRecommendationSet) => Promise<void>;
  updatePreview: (recommendationSet: IRecommendationSet) => void;
}

const RecommendationSetDetail = (props: RecommendationSetDetailProps) => {
  const { onClose, initialRecommendationSet: initialRecommendationSetWithKey, onDelete, onSave, updatePreview } = props;
  const {
    isStudio,
    organization,
    rules,
    loading: appContextLoading,
    commandBarReady,
    hasUnsavedChangesRef,
  } = useAppContext();
  const isEditorOpen = useIsEditorOpen();
  const initialRecommendationSet = _.omit(initialRecommendationSetWithKey, 'key');

  const [dirty, setDirty] = React.useState<IRecommendationSet>({
    ...initialRecommendationSet,
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<string>(RecommendationSetFormTabs.DETAILS);
  const { user } = useAuth();

  const isNewRecSet = initialRecommendationSet.id < 0;
  const isDirty = !_.isEqual(initialRecommendationSet, dirty) || isNewRecSet;

  React.useEffect(() => {
    hasUnsavedChangesRef.current = isDirty;
  }, [isDirty]);

  React.useEffect(() => {
    if (!appContextLoading && commandBarReady && (isEditorOpen || isStudio)) {
      updatePreview(dirty);
    }
  }, [appContextLoading, isEditorOpen, isStudio, dirty, commandBarReady]);

  const saveRecommendationSet = async (recommendationSet: IRecommendationSet) => {
    setIsSaving(true);
    try {
      await onSave(recommendationSet);
    } catch (error) {
      cmdToast.error('Error saving recommendation set');
    } finally {
      setIsSaving(false);
    }
  };

  useModS(() => saveRecommendationSet(dirty));

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, props.initialRecommendationSet.is_live ? 'editor' : 'contributor');

  return (
    <Container>
      <HeaderRow style={{ width: '100%' }}>
        <RecommendationSetHeader>
          <div style={{ display: 'flex', gap: 8 }}>
            <CmdButton onClick={onClose}>
              <FlipBackward />
            </CmdButton>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <CurrentFormAction>
                {initialRecommendationSet?.id !== -1 ? 'Edit Recommendation Set' : 'Create Recommendation Set'}
              </CurrentFormAction>
              <NameInput
                value={dirty.name}
                onChange={(e) => setDirty({ ...dirty, name: e.target.value })}
                bordered={false}
                spellCheck={false}
                placeholder="name"
                autoSize
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            {dirty.id !== -1 && (
              <Tooltip
                content={
                  !isAllowedToSave
                    ? 'You do not have the required permissions to perform this action'
                    : "You can't delete the default recommendation set."
                }
                placement="bottom"
                showIf={dirty.default || !isAllowedToSave}
              >
                <CmdButton
                  variant="link"
                  onClick={() => {
                    Modal.confirm({
                      icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                      title: `Are you sure you'd like to delete '${dirty.name}'?`,
                      async onOk() {
                        try {
                          onDelete(initialRecommendationSet);
                        } catch {
                          cmdToast.error('Error deleting Recommendation Set');
                        }
                      },
                    });
                  }}
                  icon={<Trash04 />}
                  disabled={dirty.default || !isAllowedToSave}
                />
              </Tooltip>
            )}

            <Tooltip
              content={<span>You do not have permissions to make this change</span>}
              placement="bottom"
              showIf={!isAllowedToPublish}
              containerStyle={{ display: 'flex', alignItems: 'center' }}
            >
              <CmdSwitch
                checked={!!dirty.is_live}
                onCheckedChange={async (e: boolean) => {
                  if (dirty.default && !e) {
                    cmdToast.error("You can't unpublish the default recommendation set.");
                    return;
                  }

                  setIsSaving(true);
                  const newSet = { ...dirty, is_live: e };
                  setDirty(newSet);
                  saveRecommendationSet(newSet);
                  setIsSaving(false);
                }}
                onLabel="Live"
                offLabel="Draft"
                disabled={isSaving || !isAllowedToPublish}
              />
            </Tooltip>
            <Tooltip
              content="You do not have permissions to make this change"
              placement="bottom"
              showIf={!isAllowedToSave}
            >
              <CmdButton
                onClick={() => saveRecommendationSet(dirty)}
                disabled={!isDirty || isSaving || !isAllowedToSave}
                variant={'primary'}
              >
                Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
              </CmdButton>
            </Tooltip>
          </div>
        </RecommendationSetHeader>
      </HeaderRow>

      <RecommendationSetForm>
        <DetailTabs destroyInactiveTabPane={true} type="card" activeKey={activeTab} onChange={setActiveTab}>
          <DetailTabPane tab={'Details'} key={RecommendationSetFormTabs.DETAILS} style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <SimplePanel>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '4px 16px' }}>
                  <Header>Recommendations</Header>
                  <SortableList
                    nodes={[
                      ...dirty.actions
                        .filter(
                          (action) =>
                            (action.action.type !== 'open_bar' || organization.bar_enabled) &&
                            (action.action.type !== 'open_helphub' || organization.helphub_enabled) &&
                            (action.action.type !== 'open_copilot' || organization.copilot_enabled),
                        )
                        .map((labeledAction, index) => {
                          return (
                            <Recommendation
                              labeledAction={labeledAction}
                              key={index}
                              del={() => {
                                const actions = [...dirty.actions];
                                actions.splice(index, 1);
                                setDirty({ ...dirty, actions });
                              }}
                              onChange={(newAction, cta) => {
                                const actions = [...dirty.actions];
                                actions.splice(index, 1, { action: newAction, cta });
                                setDirty({ ...dirty, actions });
                              }}
                            />
                          );
                        }),
                      <Recommendation
                        draggable={false}
                        labeledAction={{
                          action: { type: 'execute_command', meta: { command: '', type: 'helpdoc' } },
                          cta: '',
                        }}
                        key={dirty.actions.length}
                        onChange={(newAction, cta) => {
                          const actions = [...dirty.actions, { action: newAction, cta }];
                          setDirty({ ...dirty, actions });
                        }}
                      />,
                    ]}
                    onSort={(oldIndex, newIndex) => {
                      const { actions } = dirty;
                      const action = actions[oldIndex];
                      const newActions = [...actions.slice(0, oldIndex), ...actions.slice(oldIndex + 1)];
                      newActions.splice(newIndex, 0, action);
                      setDirty({ ...dirty, actions: newActions });
                    }}
                    useDragHandle
                  />
                </div>
              </SimplePanel>
            </DetailTabPaneInner>
          </DetailTabPane>

          <DetailTabPane tab={'Targeting'} key={RecommendationSetFormTabs.TARGETING} style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <SimplePanel>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '4px 16px' }}>
                  <SubHeading>Who</SubHeading>
                  <AudienceSelect
                    disabled={dirty.default /* default recommendation set always targets All Users / All Pages */}
                    audience={dirty.audience}
                    rules={rules}
                    onChange={(audience) => {
                      setDirty({ ...dirty, audience });
                    }}
                  />
                  <SubHeading>Where</SubHeading>

                  <RuleExpression
                    disabled={dirty.default /* default recommendation set always targets All Users / All Pages */}
                    expr={dirty.show_expression}
                    onChange={(expr) => {
                      setDirty({ ...dirty, show_expression: expr });
                    }}
                  />
                </div>
              </SimplePanel>
            </DetailTabPaneInner>
          </DetailTabPane>
        </DetailTabs>
      </RecommendationSetForm>
    </Container>
  );
};

export default RecommendationSetDetail;
