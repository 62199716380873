import * as t from 'io-ts';

import { CommandV } from './command';
import { decodeToPromise, listObject, readObject, updateObject } from './generics';
import { FilterV } from './helpDocsSearch';
import { ActionV, LabeledActionV } from '@cb/types/entities/command/actions';
import { CopilotPersonalityV } from './helpers/copilotPersonality';
import * as axiosInstance from './network';
import type {
  IAIAnswerPayloadType,
  IChatOwnershipType,
  ICreateAnswerFeedbackPayloadType,
  ICreateChatPayloadType,
  IExternalChatHistoryType,
  IExternalChatPayloadType,
  IHandoffPayloadType,
  IOrganizationType,
  IQuestionSuggestionsPayloadType,
  IUserAttachmentType,
} from './types';

export const ContinuationV = t.string;

export const SourceDocV = t.type({
  command: CommandV,
  passage_heading: t.union([t.string, t.null]),
});

const CommandExperience = t.type({
  type: t.literal('command'),
  value: t.union([t.number, t.string]), // command id
  description: t.union([t.string, t.undefined]),
});
const NudgeExperience = t.type({
  type: t.literal('nudge'),
  value: t.number, // nudge id
  description: t.union([t.string, t.undefined]),
});
const QuestlistExperience = t.type({
  type: t.literal('questlist'),
  value: t.number, // command id
  description: t.union([t.string, t.undefined]),
});

export const ExperienceV = t.union([CommandExperience, NudgeExperience, QuestlistExperience]);

export const AIAnswerValue = t.intersection([
  t.type({
    answer: t.string,
    command_id: t.union([t.number, t.null]),
    command_title: t.string,
    passage_id: t.union([t.number, t.null]),
    passage_heading: t.union([t.string, t.null]),
    doc: t.union([
      t.type({
        title: t.string,
        excerpt: t.string,
        content: t.string,
        command: CommandV,
      }),
      t.null,
    ]),
    source_docs: t.array(SourceDocV),
  }),
  t.partial({
    message_id: t.number,
    chat_id: t.string,
    copilot_argument_values: t.union([t.record(t.string, t.unknown), t.null]),
    copilot_argument_values_abort: t.boolean,
    copilot_command: CommandV,
    experiences: t.union([t.null, t.array(ExperienceV)]),
  }),
]);

export const AIMessageExtraV = t.type({
  type: t.union([t.literal('button_primary'), t.literal('button_secondary')]),
  text: t.string,
  action: t.union([ActionV, t.undefined]),
  message: t.union([t.string, t.undefined]),
});

export const AIMessageV = t.type({
  message_type: t.literal('AI'),
  value: AIAnswerValue,
  uuid: t.union([t.null, t.string]),
  error: t.string,
  incomplete: t.boolean,
  no_answer: t.boolean,
  used_fallback: t.boolean,
  feedback: t.union([t.null, t.type({ rating: t.union([t.literal(-1), t.literal(0), t.literal(1)]) })]),
  continuations: t.union([t.null, t.array(ContinuationV)]),
  fallback_ctas: t.union([t.null, t.array(t.string)]), // deprecated
  fallback_labeled_actions: t.array(LabeledActionV),
  extras: t.array(AIMessageExtraV),
  modified: t.string,
});

export const UserAttachmentV = t.type({
  type: t.string,
  title: t.union([t.undefined, t.string]),
  description: t.union([t.undefined, t.string]),
  media_preview_url: t.string,
});

export const UserMessageV = t.type({
  message_type: t.literal('USER'),
  value: t.type({
    question: t.string,
    command_ids: t.array(t.number),
    attachments: t.array(UserAttachmentV),
  }),
  uuid: t.union([t.null, t.string]),
  modified: t.string,
});

export const AgentMessageV = t.type({
  message_type: t.literal('AGENT'),
  value: t.string,
  uuid: t.union([t.null, t.string]),
  error: t.string,
  author: t.string,
  modified: t.string,
});

export const MessageV = t.union([AIMessageV, UserMessageV, AgentMessageV]);

export const QuestionSuggestionsV = t.type({
  suggestions: t.array(t.string),
});

export const QuestionSuggestionsPayloadV = t.partial({
  user: t.union([t.string, t.null, t.undefined]),
  page_context: t.type({
    title: t.string,
    description: t.union([t.string, t.null]),
    h1s: t.array(t.string),
    h2s: t.array(t.string),
    h3s: t.array(t.string),
  }),
});

export const ContinuationsPayloadV = t.type({
  command_ids: t.union([t.array(t.number), t.undefined]),
  passage_ids: t.union([t.array(t.number), t.undefined]),
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.string,
});

export const CopilotSettingsPreviewV = t.union([
  t.type({
    avatar: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    personality: t.union([CopilotPersonalityV, t.null]),
    custom_instructions: t.union([t.string, t.null]),
  }),
  t.null,
]);

// Does not include avatar
export const CopilotSettingsPreviewPayloadV = t.union([
  t.type({
    name: t.union([t.string, t.null]),
    personality: t.union([CopilotPersonalityV, t.null]),
    custom_instructions: t.union([t.string, t.null]),
  }),
  t.null,
]);

export const AIAnswerPayloadV = t.intersection([
  t.type({
    /** @deprecated Removed entirely, since we no longer can ask AI about a specific doc */
    command_ids: t.union([t.array(t.number), t.undefined]),
    query: t.string,
    user: t.union([t.string, t.null, t.undefined]),
    chat_id: t.union([t.string, t.undefined]),
    filter: t.union([FilterV, t.undefined]),
    disable_experiences: t.union([t.boolean, t.undefined]),
  }),
  t.partial({
    system_template_override: t.string,
    settings_override: CopilotSettingsPreviewPayloadV,
    copilot_api_headers: t.record(t.string, t.string),
    user_timezone: t.string,
    end_user_id: t.string,
    hmac: t.string,
    attachments: t.array(UserAttachmentV),
    user_properties_override: t.record(t.string, t.any),
    is_admin: t.boolean,
  }),
]);

export const AIAnswerV = t.type({
  chat_id: t.string,
  message_id: t.string,
});

export const HandoffPayloadV = t.type({
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.string,
});

export const HandoffV = t.type({
  chat_id: t.string,
});

export const ExternalChatPayloadV = t.type({
  content: t.string,
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.union([t.string, t.undefined]),
});

export const ExternalChatV = t.type({
  chat_id: t.string,
});

export const ExternalChatHistoryV = t.type({
  chat_id: t.string,
  control: t.union([t.literal('INTERNAL'), t.literal('EXTERNAL')]),
  history: t.array(t.union([AgentMessageV, UserMessageV, AIMessageV])),
});

export const ChatOwnershipV = t.type({
  control: t.union([t.literal('INTERNAL'), t.literal('EXTERNAL')]),
});

export const CreateAnswerFeedbackPayloadV = t.type({
  message_id: t.number,
  rating: t.number,
  user_properties_override: t.union([t.record(t.string, t.any), t.undefined]),
});

export const ChatV = t.type({
  id: t.string,
  answer: t.union([t.null, t.number]),
  messages: t.array(MessageV),
  created: t.string,
  modified: t.string,
  context: t.any,
  organization: t.number,
  end_user: t.union([t.null, t.number, t.string]),
  editor_tags: t.array(t.string),
  impersonate_mode: t.boolean,
});

export const CreateChatPayloadV = t.type({
  organization: t.union([t.string, t.number]),
  user: t.union([t.string, t.null, t.undefined]),
  impersonate_mode: t.union([t.null, t.undefined, t.boolean]),
});

// maybe can move these to a `service` in `commandbar`
export class Chat {
  public static get = readObject(ChatV, 'ml/chats');
  public static update = updateObject(ChatV, ChatV, 'ml/chats');
  public static list = listObject(ChatV, 'ml/chats');

  public static create = async (payload: ICreateChatPayloadType) => {
    const result = await axiosInstance.post(`ml/copilot/`, payload);
    return await decodeToPromise(
      t.type({
        chat_id: t.string,
      }),
      result.data,
    );
  };

  public static createAnswerFeedback = async (
    orgUID: IOrganizationType['id'],
    payload: ICreateAnswerFeedbackPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/feedback`, payload);
    return await decodeToPromise(t.union([t.undefined, t.null, t.exact(AIAnswerV)]), result.data);
  };

  public static setTags = async (payload: { chat_id: string; tags: string[] }) => {
    const result = await axiosInstance.put(`ml/chats/tags/`, payload);
    return await decodeToPromise(t.array(t.string), result.data);
  };

  public static generateAIAnswer = async (
    orgUID: IOrganizationType['id'],
    payload: IAIAnswerPayloadType,
    config?: axiosInstance.FetchOptions,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/live`, payload, {
      ...config,
    });
    return await decodeToPromise(t.exact(AIAnswerV), result.data);
  };

  public static handoff = async (
    orgUID: IOrganizationType['id'],
    payload: IHandoffPayloadType,
    config?: axiosInstance.FetchOptions,
  ) => {
    const result = await axiosInstance.post(`integrations/sunshine/${orgUID}/handoff/`, payload, config);
    return await decodeToPromise(t.exact(HandoffV), result.data);
  };

  public static getChatOwnership = async (
    orgUID: IOrganizationType['id'],
    chatID: string,
    config?: axiosInstance.FetchOptions,
  ): Promise<IChatOwnershipType> => {
    const result = await axiosInstance.get(`integrations/sunshine/${orgUID}/${chatID}/control`, config);
    return await decodeToPromise(t.exact(ChatOwnershipV), result.data);
  };

  public static sendExternalChat = async (
    orgUID: IOrganizationType['id'],
    payload: IExternalChatPayloadType,
    config?: axiosInstance.FetchOptions,
  ) => {
    const result = await axiosInstance.post(`integrations/sunshine/${orgUID}/chat/`, payload, config);
    return await decodeToPromise(t.exact(ExternalChatV), result.data);
  };

  public static getExternalChatHistory = async (
    orgUID: IOrganizationType['id'],
    chatID: string,
    startingMessageUuid?: string,
    config?: axiosInstance.FetchOptions,
  ): Promise<IExternalChatHistoryType> => {
    const result = await axiosInstance.get(
      `integrations/sunshine/${orgUID}/${chatID}/live` +
        (startingMessageUuid ? `?starting_message_uuid=${encodeURIComponent(startingMessageUuid)}` : ''),
      config,
    );
    return await decodeToPromise(t.exact(ExternalChatHistoryV), result.data);
  };

  public static collectArgumentValues = async (
    orgUID: IOrganizationType['id'],
    chat_id: string,
    command_id: number,
    user: string | null | undefined,
    query: string,
    attachments: IUserAttachmentType[],
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/arguments`, {
      command_id,
      chat_id,
      user,
      query,
      attachments,
    });
    return await decodeToPromise(t.exact(AIAnswerV), result.data);
  };

  public static generateQuestionSuggestions = async (
    orgUID: IOrganizationType['id'],
    payload: IQuestionSuggestionsPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/question-suggestions/${orgUID}`, payload);
    return await decodeToPromise(t.exact(QuestionSuggestionsV), result.data);
  };

  public static readMessage = async (chatId: string, messageId: string, signal?: AbortSignal) => {
    const result = await axiosInstance.get(`ml/chats/${chatId}/${messageId}`, { signal: signal });

    return await decodeToPromise(MessageV, result.data);
  };

  public static abortAnswerGeneration = async (chatId: string, messageId: string) => {
    const response = await axiosInstance.post(`ml/chats/${chatId}/${messageId}/abort`);
    return { success: response.status === 200 };
  };
}
