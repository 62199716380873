import styled from '@emotion/styled';

import { Form } from '@commandbar/design-system/components/antd';

export const FormItemHorizontal = styled(Form.Item)`
  .ant-row.ant-form-item-row {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  .ant-form-item-label {
    white-space: nowrap;
    text-align: end;
    padding: 0;
    flex: none !important;
  }

  [data-radix-popper-content-wrapper] {
    text-align: start;
  }

  .ant-form-item-control {
    flex: none !important;
    width: auto !important;
  }

  .ant-form-item-label > label {
    height: 32px;
    cursor: pointer;

    &::after {
      display: block;
      content: '';
      position: relative;
      margin-block: 0;
      margin-inline-start: 2px;
      margin-inline-end: 8px;
    }
  }
`;
