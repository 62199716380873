import React, { type ReactNode, useCallback } from 'react';

import { CmdInput, CmdSwitch } from '@commandbar/design-system/cmd';
import type { INudgeStepSurveyBlockType } from '@commandbar/internal/middleware/types';
import * as S from '../styled';

interface ValueInputProps {
  value: boolean;
  onValueChange: (value: boolean) => void;
}

const ValueInput = ({ value, onValueChange }: ValueInputProps) => (
  <S.DropdownOptionAction>
    Required
    <CmdSwitch
      checked={value}
      onCheckedChange={onValueChange}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  </S.DropdownOptionAction>
);

interface MessageInputProps {
  message: string;
  onMessageChange: (message: string) => void;
}

const MessageInput = ({ message, onMessageChange }: MessageInputProps) => (
  <S.DropdownOptionAction>
    <CmdInput
      onChange={(e) => {
        onMessageChange(e.target.value);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      value={message}
    />
  </S.DropdownOptionAction>
);

interface SurveyValidationProps {
  initialValue: {
    value: boolean;
    message: string;
  };
  onChange: (updatedValidation: INudgeStepSurveyBlockType['meta']['validation']) => void;
  wrapOption?: (option: ReactNode) => ReactNode;
}

export const SurveyValidation = ({ initialValue, onChange, wrapOption }: SurveyValidationProps) => {
  const [requirement, setRequirement] = React.useState(initialValue);

  const wrappedOrRaw = useCallback((component) => (wrapOption ? wrapOption(component) : component), [wrapOption]);

  return (
    <>
      {wrappedOrRaw(
        <ValueInput
          value={requirement.value}
          onValueChange={(value) => {
            setRequirement((oldRequirement) => ({ ...oldRequirement, value: value }));
            onChange({
              required: value
                ? {
                    value: true,
                    message: requirement.message,
                  }
                : {
                    value: false,
                    message: null,
                  },
            });
          }}
        />,
      )}
      {requirement.value &&
        wrappedOrRaw(
          <MessageInput
            message={requirement.message}
            onMessageChange={(message) => {
              setRequirement({ value: true, message });
              onChange({
                required: {
                  value: true,
                  message,
                },
              });
            }}
          />,
        )}
    </>
  );
};
