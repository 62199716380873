import React, { useMemo } from 'react';
import _ from 'lodash';
import { ConditionTypeSelect } from '../ConditionTypeSelect';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { ContextCondition as ContextConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { GenericAutoComplete } from '../GenericAutoComplete';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';
import { useContextPartition } from 'editor/src/editor/context/contextSettings/useContextPartition';
import getAllValidPathsOfObjectProps from 'editor/src/editor/utils/getAllValidPathsOfObjectProps';
import { ConditionProps } from '../../types';

export const MetadataCondition: React.FC<ConditionProps<ContextConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();
  const { context: windowContext } = useWindowInfo();
  const { metadata } = useContextPartition();

  const options = useMemo(() => {
    const paths = metadata.flatMap(({ key }) => {
      const paths = getAllValidPathsOfObjectProps(windowContext[key], key);

      if (paths.length > 0) return paths;

      return [key];
    });

    // paths can be duplicated;
    // metadata paths referenced by commands are included in `metadata` so e.g.
    // there can be a "user" object in metadata which expands above to
    // ['user.id', 'user.name', 'user.email'] and then a command references
    // 'user.id', we will have a duplicate 'user.id' path
    return _.uniq(paths).map((path) => ({ label: path, value: path }));
  }, [metadata, windowContext]);

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />

      <Col flex="3 1 auto" style={{ overflow: 'hidden' }}>
        <GenericAutoComplete
          value={condition.field}
          options={options}
          onChange={(field) => {
            onConditionChange({ ...condition, field });
          }}
          onBlur={() => setHasBlurred(true)}
          disabled={disabled}
        />
      </Col>

      <OperatorSelect />
      <ConditionValueInput />
    </Row>
  );
};
