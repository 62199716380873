import { Condition } from '@commandbar/internal/middleware/conditions';
import { getConditions, RuleExpression } from '@commandbar/internal/middleware/helpers/rules';
import { booleanOperatorTypes, numericOperatorTypes } from './types';

export const isConditionGroupValid = (
  conditionGroup: RuleExpression /* TODO: Replace this with a ConditionGroup type */,
): boolean => {
  return getConditions(conditionGroup).every(
    (condition) => isValidCondition(condition as Condition) && isValidURLValue(condition as Condition),
  );
};

export const isValidCondition = (condition: Condition) => {
  if (condition.type === 'always') {
    return true;
  }

  if (condition.type === 'named_rule') {
    return typeof condition.rule_id === 'string' || condition.rule_id >= 0;
  }

  if (condition.type === 'audience') {
    return condition.audience_id >= 0;
  }

  if (condition.type === 'intent') {
    return condition.values.length > 0;
  }

  if (condition.type === 'help_doc_interaction') {
    // TODO: Ensure that the help_doc_id points to a valid help doc
    return condition.help_doc_id !== -1;
  }

  if (condition.type === 'video_interaction') {
    // TODO: Ensure that the video_id points to a valid video
    return condition.video_id !== -1;
  }

  if (condition.type === 'survey_response') {
    return true;
  }

  // TODO: Move all this validation logic to the backend
  const isTypeValid =
    (condition.type === 'user_property' && condition.field) ||
    (condition.type === 'ab_test' && condition.field) ||
    (condition.type === 'context' && condition.field) ||
    (condition.type === 'event_property' && condition.field) ||
    condition.type === 'url' ||
    condition.type === 'hostname' ||
    condition.type === 'element' ||
    condition.type === 'executions' ||
    condition.type === 'shortcuts' ||
    condition.type === 'last_seen' ||
    condition.type === 'first_seen' ||
    condition.type === 'sessions' ||
    condition.type === 'opens' ||
    condition.type === 'deadends' ||
    condition.type === 'heap' ||
    condition.type === 'hubspot' ||
    condition.type === 'amplitude' ||
    condition.type === 'nudge_interaction' ||
    condition.type === 'questlist_interaction' ||
    condition.type === 'device_type' ||
    condition.type === 'browser' ||
    condition.type === 'os' ||
    condition.type === 'language';

  return isTypeValid && condition.operator && isValidConditionValue(condition);
};

export const getUrlConditionError = (condition: Condition) => {
  if (condition.type === 'url' && condition.value) {
    if (condition.value.startsWith('https://') || condition.value.startsWith('http://')) {
      return 'Please use a relative url (e.g., /home) or select the "Current Domain" type.';
    }

    // ENG-6389: allow dots in a URL path regex match, disallow otherwise
    if (condition.value.includes('.') && condition.operator !== 'matchesRegex') {
      return 'Please use a relative url (e.g., /home) or select the "Current Domain" type.';
    }
  }

  if (condition.type === 'hostname' && condition.value) {
    if (condition.value.startsWith('https://') || condition.value.startsWith('http://')) {
      return 'Please remove the protocol (http(s)://) from the domain';
    }

    if (condition.value.includes('/')) {
      return "Please use a domain like 'example.com' or 'sub.example.com' without a path or select the 'Current URL Path' type.";
    }
  }

  return null;
};

export const isValidURLValue = (condition: Condition) => {
  return !getUrlConditionError(condition);
};

export const isValidConditionValue = (condition: Condition) => {
  if ('values' in condition) {
    return condition.values.length > 0;
  }

  if (!('value' in condition)) return true;

  const isBooleanOperator = condition.operator && booleanOperatorTypes.includes(condition.operator);

  // When using boolean operators (ie. isTruthy, isFalsey, etc.), an explicit value is not used so we can return true
  if (isBooleanOperator) {
    return true;
  }

  const { operator, value } = condition;

  const isNumericOperator = operator && numericOperatorTypes.includes(operator);
  const isNumber = !isNaN(Number(value));

  // Numeric operators need numeric values
  if (isNumericOperator) {
    return isNumber;
  }

  return !!value;
};
