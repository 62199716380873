import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { LanguageCondition as LangaugeConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionProps } from '../../types';

const options = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Arabic (Algeria)', value: 'ar-dz' },
  { label: 'Arabic (Bahrain)', value: 'ar-bh' },
  { label: 'Arabic (Egypt)', value: 'ar-eg' },
  { label: 'Arabic (Iraq)', value: 'ar-iq' },
  { label: 'Arabic (Jordan)', value: 'ar-jo' },
  { label: 'Arabic (Kuwait)', value: 'ar-kw' },
  { label: 'Arabic (Lebanon)', value: 'ar-lb' },
  { label: 'Arabic (Libya)', value: 'ar-ly' },
  { label: 'Arabic (Morocco)', value: 'ar-ma' },
  { label: 'Arabic (Oman)', value: 'ar-om' },
  { label: 'Arabic (Qatar)', value: 'ar-qa' },
  { label: 'Arabic (Saudi Arabia)', value: 'ar-sa' },
  { label: 'Arabic (Syria)', value: 'ar-sy' },
  { label: 'Arabic (Tunisia)', value: 'ar-tn' },
  { label: 'Arabic (U.A.E.)', value: 'ar-ae' },
  { label: 'Arabic (Yemen)', value: 'ar-ye' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese (Hong Kong)', value: 'zh-hk' },
  { label: 'Chinese (PRC)', value: 'zh-cn' },
  { label: 'Chinese (Singapore)', value: 'zh-sg' },
  { label: 'Chinese (Taiwan)', value: 'zh-tw' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch (Belgium)', value: 'nl-be' },
  { label: 'Dutch (Standard)', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'English (Australia)', value: 'en-au' },
  { label: 'English (Belize)', value: 'en-bz' },
  { label: 'English (Canada)', value: 'en-ca' },
  { label: 'English (Ireland)', value: 'en-ie' },
  { label: 'English (Jamaica)', value: 'en-jm' },
  { label: 'English (New Zealand)', value: 'en-nz' },
  { label: 'English (South Africa)', value: 'en-za' },
  { label: 'English (Trinidad)', value: 'en-tt' },
  { label: 'English (United Kingdom)', value: 'en-gb' },
  { label: 'English (United States)', value: 'en-us' },
  { label: 'Estonian', value: 'et' },
  { label: 'Faeroese', value: 'fo' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French (Belgium)', value: 'fr-be' },
  { label: 'French (Canada)', value: 'fr-ca' },
  { label: 'French (Luxembourg)', value: 'fr-lu' },
  { label: 'French (Standard)', value: 'fr' },
  { label: 'French (Switzerland)', value: 'fr-ch' },
  { label: 'Gaelic (Scotland)', value: 'gd' },
  { label: 'German (Austria)', value: 'de-at' },
  { label: 'German (Liechtenstein)', value: 'de-li' },
  { label: 'German (Luxembourg)', value: 'de-lu' },
  { label: 'German (Standard)', value: 'de' },
  { label: 'German (Switzerland)', value: 'de-ch' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian (Standard)', value: 'it' },
  { label: 'Italian (Switzerland)', value: 'it-ch' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Macedonian (FYROM)', value: 'mk' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Malaysian', value: 'ms' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Norwegian (Bokmål)', value: 'nb' },
  { label: 'Norwegian (Nynorsk)', value: 'nn' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Brazil)', value: 'pt-br' },
  { label: 'Portuguese (Portugal)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Rhaeto-Romanic', value: 'rm' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Romanian (Republic of Moldova)', value: 'ro-md' },
  { label: 'Russian', value: 'ru' },
  { label: 'Russian (Republic of Moldova)', value: 'ru-md' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Sorbian', value: 'sb' },
  { label: 'Spanish', value: 'es' },
  { label: 'Spanish (Argentina)', value: 'es-ar' },
  { label: 'Spanish (Bolivia)', value: 'es-bo' },
  { label: 'Spanish (Chile)', value: 'es-cl' },
  { label: 'Spanish (Colombia)', value: 'es-co' },
  { label: 'Spanish (Costa Rica)', value: 'es-cr' },
  { label: 'Spanish (Dominican Republic)', value: 'es-do' },
  { label: 'Spanish (Ecuador)', value: 'es-ec' },
  { label: 'Spanish (El Salvador)', value: 'es-sv' },
  { label: 'Spanish (Guatemala)', value: 'es-gt' },
  { label: 'Spanish (Honduras)', value: 'es-hn' },
  { label: 'Spanish (Mexico)', value: 'es-mx' },
  { label: 'Spanish (Nicaragua)', value: 'es-ni' },
  { label: 'Spanish (Panama)', value: 'es-pa' },
  { label: 'Spanish (Paraguay)', value: 'es-py' },
  { label: 'Spanish (Peru)', value: 'es-pe' },
  { label: 'Spanish (Puerto Rico)', value: 'es-pr' },
  { label: 'Spanish (Spain)', value: 'es-es' },
  { label: 'Spanish (Uruguay)', value: 'es-uy' },
  { label: 'Spanish (Venezuela)', value: 'es-ve' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Swedish (Finland)', value: 'sv-fi' },
  { label: 'Thai', value: 'th' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Tswana', value: 'tn' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'ua' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Venda', value: 've' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'ji' },
  { label: 'Zulu', value: 'zu' },
];

export const LanguageCondition: React.FC<ConditionProps<LangaugeConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.values}
          options={options}
          placeholder="value"
          style={{ width: '100%' }}
          onBlur={() => setHasBlurred(true)}
          onChange={(values) => {
            onConditionChange({
              ...condition,
              values,
            });
          }}
          filterOption={(input, option) =>
            !!option?.value?.toLowerCase().includes(input.toLowerCase()) ||
            !!option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          size="small"
          mode="multiple"
        />
      </Col>
    </Row>
  );
};
