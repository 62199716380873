import React from 'react';
import { SkeletonProps } from './types';
import { handleStyleElementClick } from './helpers';

const HelpHubView = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      width="381"
      height="494"
      viewBox="0 0 381 494"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
    >
      <g filter="url(#filter0_d_602_1530)">
        <g clipPath="url(#clip0_602_1530)" id="bar-styles-helpHub">
          <rect x="25" y="18" width="331" height="444" rx="8.71369" fill="white" />
          <rect x="24.5643" y="17.5643" width="331.871" height="32.8714" fill="#EDEDEE" />
          <rect x="41" y="30" width="125" height="8" rx="4" fill="#CDCDD0" />
          <rect x="24.5643" y="17.5643" width="331.871" height="32.8714" stroke="#E7E7E9" strokeWidth="0.871369" />
          <mask
            id="path-5-outside-1_602_1530"
            maskUnits="userSpaceOnUse"
            x="25"
            y="49"
            width="331"
            height="49"
            fill="black"
          >
            <rect fill="white" x="25" y="49" width="331" height="49" />
            <path d="M25 50H356V98H25V50Z" />
          </mask>
          <path d="M25 50H356V98H25V50Z" fill="white" id="bar-styles-helpHubInput" />
          <rect x="34.5" y="59.5" width="312" height="29" rx="14.5" fill="white" />
          <rect x="49" y="70" width="107" height="8" rx="4" fill="#E7E7E9" />
          <rect x="34.5" y="59.5" width="312" height="29" rx="14.5" stroke="#E7E7E9" strokeWidth="3" />
          <path d="M25 50.8714H356V49.1286H25V50.8714Z" fill="#E7E7E9" mask="url(#path-5-outside-1_602_1530)" />
          <rect x="41" y="114" width="80" height="8" rx="4" fill="#E7E7E9" />
          <mask id="path-10-inside-2_602_1530" fill="white">
            <path d="M25 130H356V218H25V130Z" />
          </mask>
          <path d="M25 130H356V218H25V130Z" fill="#F5F0FE" id="bar-styles-helpHubInstantAnswerItem" />
          <rect x="41" y="154" width="16" height="16" rx="8" fill="#A795D0" />
          <rect x="65" y="154" width="96" height="12" rx="4" fill="#A795D0" />
          <rect x="65" y="174" width="72" height="8" rx="4" fill="#A795D0" />
          <rect x="145" y="174" width="52" height="8" rx="4" fill="#A795D0" />
          <rect x="205" y="174" width="135" height="8" rx="4" fill="#A795D0" />
          <rect x="65" y="186" width="275" height="8" rx="4" fill="#A795D0" />
          <path
            d="M25 131H356V129H25V131ZM356 217H25V219H356V217Z"
            fill="#A795D0"
            mask="url(#path-10-inside-2_602_1530)"
          />
          <rect x="25.5" y="218.5" width="330" height="87" fill="white" id="bar-styles-helpHubDocItem" />
          <rect x="41" y="242" width="16" height="16" rx="8" fill="#E7E7E9" />
          <rect x="65" y="242" width="96" height="12" rx="4" fill="#E7E7E9" />
          <rect x="65" y="262" width="72" height="8" rx="4" fill="#E7E7E9" />
          <rect x="145" y="262" width="52" height="8" rx="4" fill="#E7E7E9" />
          <rect x="205" y="262" width="135" height="8" rx="4" fill="#E7E7E9" />
          <rect x="65" y="274" width="275" height="8" rx="4" fill="#E7E7E9" />
          <rect x="25.5" y="218.5" width="330" height="87" stroke="#E7E7E9" />
          <rect x="25.5" y="306.5" width="330" height="87" fill="white" />
          <rect x="41" y="330" width="16" height="16" rx="8" fill="#E7E7E9" />
          <rect x="65" y="330" width="96" height="12" rx="4" fill="#E7E7E9" />
          <rect x="65" y="350" width="72" height="8" rx="4" fill="#E7E7E9" />
          <rect x="145" y="350" width="52" height="8" rx="4" fill="#E7E7E9" />
          <rect x="205" y="350" width="135" height="8" rx="4" fill="#E7E7E9" />
          <rect x="65" y="362" width="275" height="8" rx="4" fill="#E7E7E9" />
          <rect x="25.5" y="306.5" width="330" height="87" stroke="#E7E7E9" />
          <rect x="25.5" y="394.5" width="330" height="87" fill="white" />
          <rect x="41" y="418" width="16" height="16" rx="8" fill="#E7E7E9" />
          <rect x="65" y="418" width="96" height="12" rx="4" fill="#E7E7E9" />
          <rect x="65" y="438" width="72" height="8" rx="4" fill="#E7E7E9" />
          <rect x="145" y="438" width="52" height="8" rx="4" fill="#E7E7E9" />
          <rect x="205" y="438" width="135" height="8" rx="4" fill="#E7E7E9" />
          <rect x="65" y="450" width="275" height="8" rx="4" fill="#E7E7E9" />
          <rect x="25.5" y="394.5" width="330" height="87" stroke="#E7E7E9" />
        </g>
        <rect
          x="25.4357"
          y="18.4357"
          width="330.129"
          height="443.129"
          rx="8.27801"
          stroke="#EDEDEE"
          strokeWidth="0.871369"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_602_1530"
          x="0.60166"
          y="0.572614"
          width="379.797"
          height="492.797"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97095" />
          <feGaussianBlur stdDeviation="12.1992" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.06 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_602_1530" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_602_1530" result="shape" />
        </filter>
        <clipPath id="clip0_602_1530">
          <rect x="25" y="18" width="331" height="444" rx="8.71369" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpHubView;
