import { CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import { Star01 } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import { timeAgoFormat } from '../../utils';
import {
  SurveyDetailsWithResponsesNudgeStepResponses,
  SurveyDetailsWithResponsesNudgeStepSurveyDetail,
} from '@commandbar/internal/middleware/analytics/surveys';

const RatingItem = styled.div<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  border: 1px solid ${({ isActive }) => (isActive ? '#EAB308' : '#ccc')};
  background-color: ${({ isActive }) => (isActive ? '#FFFAEF' : 'transparent')};
  border-radius: 4px;
  box-shadow: ${({ isActive }) =>
    isActive
      ? '0px 0px 0px 3px rgba(221, 158, 35, 0.19)'
      : '0px -2px 0px 0px rgba(0, 0, 0, 0.07) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.07)'};
`;

const EmojiRating = ({ value, emojis }: { value: number; emojis: string[] }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', fontSize: '24px' }}>
      {emojis.map((emoji, index) => (
        <RatingItem key={index} isActive={index + 1 === value}>
          {emoji}
        </RatingItem>
      ))}
    </div>
  );
};

const StarRating = ({ max, value }: { max: number; value: number }) => (
  <div style={{ display: 'flex', gap: '8px' }}>
    {Array.from({ length: max }, (_, i) => i + 1).map((starIndex) => (
      <RatingItem key={starIndex} isActive={starIndex === value}>
        <Star01
          fill={starIndex === value ? '#fbeec4' : 'none'}
          color={starIndex === value ? '#DD9E23' : '#ccc'}
          height={24}
          width={24}
        />
      </RatingItem>
    ))}
  </div>
);

const NumberRating = ({ max, value }: { max: number; value: number }) => {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {Array.from({ length: max + 1 }, (_, i) => i).map((number) => (
        <RatingItem key={number} isActive={number === value}>
          {number}
        </RatingItem>
      ))}
    </div>
  );
};

const SurveyResponses = ({
  stepDetails,
  responses,
}: {
  stepDetails?: SurveyDetailsWithResponsesNudgeStepSurveyDetail;
  responses?: SurveyDetailsWithResponsesNudgeStepResponses[];
}) => {
  const survey_type = stepDetails?.type;

  const renderResponse = (value: number | string) => {
    if (survey_type === 'survey_text' || survey_type === 'survey_text_short' || survey_type === 'survey_list') {
      return <CmdTypography.Body>{value}</CmdTypography.Body>;
    } else if (stepDetails?.type === 'survey_rating') {
      if (stepDetails.meta.type === 'stars') {
        return <StarRating max={stepDetails.meta.options} value={Number(value)} />;
      } else if (stepDetails.meta.type === 'emojis') {
        return <EmojiRating emojis={stepDetails.meta.emojis} value={Number(value)} />;
      } else if (stepDetails.meta.type === 'numbers' || stepDetails.meta.type === 'nps') {
        return <NumberRating max={stepDetails.meta.options} value={Number(value)} />;
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {responses?.map((res: SurveyDetailsWithResponsesNudgeStepResponses) => (
        <CmdCard key={`${res.value}-${res.created_timestamp}`} style={{ padding: '12px' }}>
          <CmdCard.Content style={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
            {renderResponse(res.value)}
            <div
              style={{
                display: 'flex',
                gap: '12px',
              }}
            >
              <CmdTypography.HelpText variant="secondary">
                {timeAgoFormat(res.created_timestamp)}
              </CmdTypography.HelpText>
              <CmdTypography.HelpText variant="secondary">{res.end_user}</CmdTypography.HelpText>
            </div>
          </CmdCard.Content>
        </CmdCard>
      ))}
    </div>
  );
};

export default SurveyResponses;
