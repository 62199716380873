import { useState, useEffect } from 'react';

const useHasScroll = (ref: React.RefObject<HTMLDivElement>) => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => setHasScrolled(true);
    ref.current && ref.current.addEventListener('scroll', onScroll);

    return () => {
      if (ref.current) return ref.current.removeEventListener('scroll', onScroll);
    };
  }, [hasScrolled]);

  return { hasScrolled };
};

export default useHasScroll;
