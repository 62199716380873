import groupBy from 'lodash/groupBy';
import React, { Fragment } from 'react';

import { CmdDropdown } from '@commandbar/design-system/cmd';
import type { TriggerableEntity } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { TRIGGER_CATEGORIES, TRIGGER_TYPES, type TriggerCategory, type TriggerTypes, allTriggers } from '.';
import { StyledSelect } from '../../styled';

import { ReactComponent as CaretDown } from '../../../../img/caret_down.svg';

const isTriggerCategory = (maybeCategory: string): maybeCategory is TriggerCategory =>
  maybeCategory in TRIGGER_CATEGORIES;

const isTriggerType = (maybeTriggerType: string): maybeTriggerType is keyof TriggerTypes =>
  maybeTriggerType in TRIGGER_TYPES;

interface TypeSelectProps {
  dirty: TriggerableEntity;
  handleTriggerTypeChange: (triggerType: TriggerableEntity['trigger']['type']) => void;
  categoryFilter?: (category: string) => boolean;
  triggerFilter?: (trigger: (typeof allTriggers)[number]) => boolean;
}

export const TypeSelect = ({ dirty, handleTriggerTypeChange, categoryFilter, triggerFilter }: TypeSelectProps) => (
  <CmdDropdown.Menu>
    <CmdDropdown.Trigger className="w-full">
      <StyledSelect
        value={TRIGGER_TYPES[dirty.trigger.type].label}
        className="text-left"
        suffixIcon={<CaretDown />}
        placeholder="Select a trigger"
      />
    </CmdDropdown.Trigger>

    <CmdDropdown.Content side="bottom" matchTriggerWidth>
      <CmdDropdown.RadioGroup
        onValueChange={(key) => {
          if (isTriggerType(key)) {
            handleTriggerTypeChange(key);
          }
        }}
      >
        {Object.entries(groupBy(allTriggers, 'category'))
          .filter(([category]) => categoryFilter?.(category) ?? true)
          .map(([category, triggers], idx) => (
            <Fragment key={category}>
              {idx !== 0 && <CmdDropdown.Separator />}
              <CmdDropdown.Group>
                {isTriggerCategory(category) && (
                  <CmdDropdown.Label className="font-medium text-contentBase text-sm normal-case">
                    {TRIGGER_CATEGORIES[category]}
                  </CmdDropdown.Label>
                )}

                {triggers
                  .filter((trigger) => triggerFilter?.(trigger) ?? true)
                  .map(({ key, label, description, icon }) => (
                    <CmdDropdown.RadioItem key={key} value={key} style={{ padding: '8px' }}>
                      <div className="flex items-center gap-sm">
                        {icon && (
                          <div
                            className="flex items-center rounded-md border p-sm"
                            style={{
                              border: icon.border,
                              background: icon.background,
                            }}
                          >
                            {icon.component}
                          </div>
                        )}
                        <div className="flex flex-col font-medium">
                          <span className="text-base text-contentMid" style={{ lineHeight: '100%' }}>
                            {label}
                          </span>
                          {description && (
                            <span className="text-contentBase text-sm" style={{ lineHeight: '140%' }}>
                              {description}
                            </span>
                          )}
                        </div>
                      </div>
                    </CmdDropdown.RadioItem>
                  ))}
              </CmdDropdown.Group>
            </Fragment>
          ))}
      </CmdDropdown.RadioGroup>
    </CmdDropdown.Content>
  </CmdDropdown.Menu>
);
