function slugify(str: string) {
  str = str
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/[^a-z0-9-]/g, '') // remove invalid chars
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export default slugify;
