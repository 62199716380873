/*****************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useMemo } from 'react';

import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import { compareObjs } from '@commandbar/internal/middleware/utils';
import CategoryGroup from './CategoryGroup';
import { Col, Row } from '@commandbar/design-system/components/antd';

import NewCategoryModal from './NewCategoryModal';
import { useAppContext } from 'editor/src/AppStateContext';
import slugify from '@commandbar/internal/util/slugify';
import * as Command from '@commandbar/internal/middleware/command';
import { CmdSearchInput } from '@commandbar/design-system/cmd';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  commands: IEditorCommandTypeLite[];
  type: 'page' | 'action';
  handleCategoryChange: (command: ICommandCategoryType) => void;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const CommandList = ({ commands, handleCategoryChange }: IProps) => {
  const storedScrollPosition = parseFloat(window.sessionStorage.getItem('commandListScrollPosition') || '0');
  const [scrollPosition] = React.useState(storedScrollPosition);
  const [searchText, setSearchText] = React.useState('');

  const { dispatch, categories, organization } = useAppContext();

  React.useEffect(() => {
    scrollToCommand();
    window.sessionStorage.removeItem('commandListScrollPosition');
  }, []);

  const commandsByCategory = useMemo(() => {
    return commands.reduce<Record<string | number, IEditorCommandTypeLite[]>>(
      (acc, command) => {
        const categoryId = command.category;
        const showInDefaultList = Command.showInDefaultList(command);

        if (categoryId === null && showInDefaultList) {
          acc.uncategorized.push(command);

          return acc;
        } else if (categoryId === null) {
          return acc;
        }

        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }

        acc[categoryId].push(command);

        return acc;
      },
      { uncategorized: [] },
    );
  }, [commands]);

  const scrollToCommand = () => {
    const categoryGroupWrapper = document.getElementById('category-group_wrapper');
    categoryGroupWrapper?.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    });
  };

  const categoryCards = categories.sort(compareObjs).map((category) => {
    return (
      <CategoryGroup
        key={`category-${category.id}`}
        category={category}
        commands={commandsByCategory[category.id] || []}
        searchText={searchText}
        openSettings={() => handleCategoryChange(category)}
      />
    );
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
      <Row style={{ marginBottom: 8, padding: '0 8px', rowGap: '4px' }} align="middle" gutter={8}>
        <Col flex="1 1 auto">
          <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </Col>
        <Col>
          <NewCategoryModal
            onCreate={(name: string) => {
              const newCategory: ICommandCategoryType = {
                id: -1,
                organization: organization.id.toString(),
                name,
                sort_key: null,
                icon: null,
                icon_color: null,
                image_color: null,
                image: null,
                setting_hide_before_search: false,
                setting_max_options_count: null,
                setting_pin_to_bottom: false,
                search_tab_enabled: false,
                search_tab_name: '',
                slash_filter_enabled: true,
                slash_filter_keyword: slugify(name),
                search_tab_instruction: '',
                render_as: 'list',
                track_recents: false,
              };
              dispatch.categories.save(newCategory);
            }}
          />
        </Col>
      </Row>
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          borderTop: '10px solid #fff',
        }}
        id="category-group_wrapper"
      >
        {!!commandsByCategory.uncategorized.length && (
          <CategoryGroup
            key={`category-uncategorized`}
            category={undefined}
            commands={commandsByCategory.uncategorized}
            searchText={searchText}
            openSettings={null}
          />
        )}
        {searchText
          ? categoryCards
          : categoryCards.map((card) => (
              <div style={{ margin: '10px 0' }} key={card.key}>
                {card}
              </div>
            ))}
      </div>
    </div>
  );
};

export default CommandList;
