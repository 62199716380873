import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

import _omit from 'lodash/omit';
import _sample from 'lodash/sample';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _without from 'lodash/without';
import _cloneDeep from 'lodash/cloneDeep';

import { deleteRecordAndRecordActions, getLinkedRecords, IAlgoliaConfig } from './utils';
import { browseIndex, IAlgoliaIndex, listIndexes } from './algolia-api';
import { Code } from '../../../blocks';
import {
  Heading,
  Spin,
  Alert,
  FormInstance,
  Skeleton,
  Result,
  Tooltip,
  Form,
  Modal,
  Select,
  List,
  AutocompleteTextArea,
} from '@commandbar/design-system/components/antd';

import { useAlgoliaConfigContext } from './AlgoliaConfigProvider';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { CmdButton, CmdSwitch } from '@commandbar/design-system/cmd';

const { useForm } = Form;

interface IConfigureIndexesStepProps {
  handleNext: Function;
  handlePrev: Function;
}

interface IConfigureIndexModalProps {
  algoliaConfig: IAlgoliaConfig;
  index: IAlgoliaIndex;
  form: FormInstance;
}

const ConfigureIndexModal = ({ algoliaConfig, form, index }: IConfigureIndexModalProps) => {
  const [loading, setLoading] = useState(true);
  const [sampleRecord, setSampleRecord] = useState(undefined);
  const [keys, setKeys] = useState<string[]>([]);

  const getValidKeys = (data: any) => Object.keys(data).filter((k) => typeof data[k] === 'string');

  useEffect(() => {
    form.resetFields();
    const { applicationID, apiKey } = algoliaConfig;

    if (!applicationID || !apiKey) {
      return;
    }

    browseIndex(applicationID, apiKey, index.name).then((data) => {
      const randomRecord = _sample(data);
      setKeys(getValidKeys(randomRecord));
      setSampleRecord(randomRecord);
      setLoading(false);
    });
  }, []);

  const modalRef = React.useRef<HTMLDivElement>(null);

  return (
    <Spin spinning={loading} tip="Loading index data...">
      <Alert
        type="info"
        message={
          <div style={{ color: 'black' }}>
            <p>
              We automatically pulled some fields from a record in the index. Have records of different types without
              shared fields? Not to worry! You can customize these in code later.
            </p>
          </div>
        }
        showIcon
        style={{
          margin: '40px 0',
        }}
      />
      {!loading && (
        <div
          style={{
            margin: '30px 0px',
          }}
        >
          <Code content={JSON.stringify(sampleRecord, null, 2)} language="json" />
        </div>
      )}
      <Form
        form={form}
        layout="horizontal"
        size="small"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        initialValues={{ behaviour: 'blank' }}
      >
        <Heading text="Search Settings" />
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <CreatableSelect
            options={keys.map((k) => ({ value: k, label: k }))}
            isClearable={true}
            placeholder="Please select a key for label"
          />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[{ required: false }]}>
          <CreatableSelect
            options={keys.map((k) => ({ value: k, label: k }))}
            isClearable={true}
            placeholder="Please select a key for description"
          />
        </Form.Item>
        <Form.Item label="Icon" name="icon" rules={[{ required: false }]}>
          <CreatableSelect
            options={keys.map((k) => ({ value: k, label: k }))}
            isClearable={true}
            placeholder="Please select a key for icon"
          />
        </Form.Item>
        <Form.Item label="Searchable fields" name="fields" rules={[{ required: false }]}>
          <CreatableSelect
            options={keys.map((k) => ({ value: k, label: k }))}
            isMulti={true}
            placeholder="Searchable fields"
          />
        </Form.Item>
        <Heading text="Action" />
        <Form.Item
          name="url"
          help="This is the url that will be navigated to when the record is selected. You can use {{record}} to reference properties of the selected record."
          label="URL"
          rules={[{ required: true }]}
        >
          <div ref={modalRef}>
            <AutocompleteTextArea
              placeholder={'/path/{{record.url}}'}
              value={form.getFieldValue('url')}
              onChange={(e) => form.setFieldValue('url', e)}
              options={keys.map((key) => ({ value: `record.${key}`, addOn: `` }))}
              container={modalRef.current}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="behaviour"
          help={
            <span>
              When selecting the `Use router` option, you must also define a router for it to work. Click{` `}
              <a
                href="https://www.commandbar.com/docs/sdk/lifecycle/addrouter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{' '}
              to learn more
            </span>
          }
          label="Behaviour"
          rules={[{ required: false }]}
        >
          <Select size="middle">
            <Select.Option value="blank">New tab</Select.Option>
            <Select.Option value="self">Same tab</Select.Option>
            <Select.Option value="router">Use router</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Spin>
  );
};

interface IConfigureIndexListItemProps {
  item: IAlgoliaIndex;
  conflicting: boolean;
  isIndexConfigured: Function;
  setConfiguredRecords: Function;
}

const ConfigureIndexListItem = ({
  item,
  conflicting,
  isIndexConfigured,
  setConfiguredRecords,
}: IConfigureIndexListItemProps) => {
  const [configured, setConfigured] = useState(isIndexConfigured(item.name) || conflicting);
  const [configureIndexForm] = useForm();
  const { algoliaConfig, setAlgoliaConfig } = useAlgoliaConfigContext();
  const { reportEvent } = useReportEvent();

  const handleRemoveIndex = (indexName: string) => {
    setAlgoliaConfig((config: IAlgoliaConfig) => ({
      ...config,
      indexes: _omit(config.indexes, indexName),
    }));
    setConfigured(false);
  };

  const handleConfigureIndex = (form: FormInstance, indexName: string, closeModal: Function) =>
    form.validateFields().then(() => {
      const values = form.getFieldsValue();

      const cleanedValues: any = {};

      for (const key in values) {
        if (typeof values[key] === 'object' && !!values[key] && values[key].hasOwnProperty('value')) {
          cleanedValues[key] = values[key].value;
        } else if (key === 'fields' && Array.isArray(values.fields)) {
          cleanedValues.fields = values.fields.map((field: { value: string; label: string }) => field.value);
        } else {
          cleanedValues[key] = values[key];
        }
      }

      setAlgoliaConfig((oldConfig: IAlgoliaConfig) => {
        const config = _cloneDeep(oldConfig);
        return _set(config, `indexes.${indexName}`, {
          name: indexName,
          __cbConfig: cleanedValues,
        });
      });
      closeModal();
      setConfigured(true);
    });

  return (
    <List.Item
      actions={[
        <Tooltip
          showIf={conflicting}
          content={
            <span>This index has already been configured with an existing command and record under the same name.</span>
          }
          key={`configure-${item.name}`}
        >
          <CmdSwitch
            checked={configured}
            onCheckedChange={(checked) =>
              checked
                ? Modal.confirm({
                    maskClosable: false,
                    icon: undefined,
                    okText: 'Done',
                    content: (
                      <ConfigureIndexModal
                        algoliaConfig={algoliaConfig}
                        index={item}
                        form={configureIndexForm}
                        key={item.name}
                      />
                    ),
                    onOk: (closeModal) => {
                      handleConfigureIndex(configureIndexForm, item.name, closeModal);
                      reportEvent('integration edited', {
                        segment: true,
                        highlight: true,
                        slack: true,
                        payloadMessage: item.name,
                        eventProps: {
                          name: item.name,
                        },
                      });
                    },
                    onCancel: () => setConfigured(false),

                    closable: false,
                    title: (
                      <span>
                        Configure <em>{item.name}</em>
                      </span>
                    ),
                    width: '50vw',
                  })
                : Modal.confirm({
                    content: conflicting ? (
                      <span>
                        This index has already been configured with an existing command and record under the same name.
                        All records and linked commands will be deleted. Reconfigure anyway?
                      </span>
                    ) : (
                      'Deselecting an index will reset the index configuration'
                    ),
                    onOk: () => {
                      if (conflicting) {
                        deleteRecordAndRecordActions(algoliaConfig.orgID, item.name);
                      }
                      handleRemoveIndex(item.name);
                      setConfiguredRecords((configuredRecords: string[]) => _without(configuredRecords, item.name));
                      setConfigured(false);
                      reportEvent('integration deleted', {
                        segment: true,
                        highlight: true,
                        slack: true,
                        payloadMessage: item.name,
                        eventProps: {
                          name: item.name,
                        },
                      });
                    },
                    onCancel: () => {
                      if (isIndexConfigured(item.name)) {
                        setConfigured(true);
                      }
                    },
                  })
            }
          />
        </Tooltip>,
      ]}
    >
      <Skeleton title={false} loading={false} active>
        <List.Item.Meta
          title={item.name}
          description={
            <div>
              <small>{`${item.entries} records`}</small>
              <br />
              <small>{`Created: ${item.createdAt}`}</small>
            </div>
          }
        />
      </Skeleton>
    </List.Item>
  );
};

export const ConfigureIndexesStep = ({ handlePrev, handleNext }: IConfigureIndexesStepProps) => {
  const [configuredRecords, setConfiguredRecords] = useState<string[]>([]);
  const { algoliaConfig } = useAlgoliaConfigContext();
  const [indexes, setIndexes] = useState<IAlgoliaIndex[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { applicationID, apiKey } = algoliaConfig;

    const getConfiguredRecords = async () => {
      const { resource_options } = await Organization.read(algoliaConfig.orgID);
      const linkedRecords = await getLinkedRecords(algoliaConfig.orgID);

      setConfiguredRecords(Array.from(new Set([...Object.keys(resource_options), ...linkedRecords])));
    };

    if (!applicationID || !apiKey) {
      return;
    }

    (async function () {
      await getConfiguredRecords();

      const fetchedIndexes = await listIndexes(applicationID, apiKey);
      setIndexes(fetchedIndexes.filter(({ entries }) => entries !== 0));
      setLoading(false);
    })();
  }, []);

  const isIndexConfigured = (indexName: string) => {
    const indexConfig = _get(algoliaConfig, `indexes.${indexName}.__cbConfig`);
    return indexConfig && indexConfig.label;
  };

  return (
    <Spin spinning={loading} tip="Fetching indexes...">
      <Alert
        type="info"
        message={
          <span style={{ color: 'black' }}>
            Don&apos;t see an index in the list? Make sure your API key has the necessary permissions configured and the
            index has at least one record.
          </span>
        }
        showIcon
        style={{
          margin: '40px 0',
        }}
      />
      {indexes.length ? (
        <List
          itemLayout="horizontal"
          dataSource={indexes}
          renderItem={(item, idx) => (
            <ConfigureIndexListItem
              key={`${item.name}-${idx}`}
              item={item}
              conflicting={configuredRecords.includes(item.name)}
              isIndexConfigured={isIndexConfigured}
              setConfiguredRecords={setConfiguredRecords}
            />
          )}
        />
      ) : (
        <Result status="warning" title="No indexes found." />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
        <CmdButton variant="primary" onClick={() => handlePrev()}>
          Go Back
        </CmdButton>
        <CmdButton
          variant="primary"
          onClick={() => handleNext()}
          disabled={
            !algoliaConfig.indexes ||
            (algoliaConfig.indexes !== undefined && !Object.keys(algoliaConfig.indexes).length)
          }
        >
          Done
        </CmdButton>
      </div>
    </Spin>
  );
};
