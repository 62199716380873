import { CmdButton, CmdDropdown } from '@commandbar/design-system/cmd';
import { Check, Minus, Trash04 } from '@commandbar/design-system/icons/react';
import { IBatchOperation, IEditorCommandTypeLite, IKeyword } from '@commandbar/internal/middleware/types';
import { StatusType } from 'editor/src/editor/components/FilterSelect';
import { capitalize } from 'lodash';
import { Key, useEffect, useState } from 'react';
import { useLoadCategories, useBulkEditCommands } from './hooks';
import { useParams } from 'react-router';
import { useAppContext } from 'editor/src/AppStateContext';
import styled from '@emotion/styled';
import { onIntegrationError } from '../../integrations/shared/utils';
import { Exception } from '@sentry/react';

interface BulkSelectButtonProps {
  selectedRowKeys: Key[];
  allRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
}

const BulkSelectButton = ({ selectedRowKeys, allRowKeys, setSelectedRowKeys }: BulkSelectButtonProps) => {
  const [checked, setChecked] = useState(allRowKeys.length === selectedRowKeys.length);

  useEffect(() => {
    setChecked(allRowKeys.length === selectedRowKeys.length);
  }, [selectedRowKeys]);

  return (
    <CmdButton
      onClick={() => {
        setChecked((checked) => !checked);
        setSelectedRowKeys(!checked ? allRowKeys : []);
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '16px',
          width: '16px',
          background: '#2754ee',
          border: '1px solid #1a389d',
          borderRadius: '6px',
          color: 'white',
          alignItems: `${checked ? 'baseline' : 'center'}`,
          justifyContent: 'center',
        }}
      >
        {checked ? <Check /> : <Minus />}
      </div>
    </CmdButton>
  );
};

interface CategoryDropdownProps {
  selectedCategoryId: number;
  onSelect: (id: number) => void;
}

const CategoryDropdown = ({ selectedCategoryId, onSelect }: CategoryDropdownProps) => {
  const categories = useLoadCategories();
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.SelectTrigger>
        {categories.find((c) => c.id === selectedCategoryId)?.name || 'Category'}
      </CmdDropdown.SelectTrigger>
      <CmdDropdown.Content>
        <CmdDropdown.RadioGroup value="all" onValueChange={(v) => onSelect(parseInt(v))}>
          {categories.map((category) => (
            <CmdDropdown.RadioItem key={category.id} value={category.id.toString()}>
              {category.name}
            </CmdDropdown.RadioItem>
          ))}
        </CmdDropdown.RadioGroup>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

interface StatusDropdownProps {
  selectedStatus: StatusType;
  onSelect: (status: StatusType) => void;
}

const StatusDropdown = ({ selectedStatus, onSelect }: StatusDropdownProps) => (
  <CmdDropdown.Menu>
    <CmdDropdown.SelectTrigger>
      {selectedStatus !== 'all' ? capitalize(selectedStatus) : 'Status'}
    </CmdDropdown.SelectTrigger>
    <CmdDropdown.Content>
      <CmdDropdown.RadioGroup value="all" onValueChange={(v) => onSelect(v as StatusType)}>
        <CmdDropdown.RadioItem value="published">Published</CmdDropdown.RadioItem>
        <CmdDropdown.RadioItem value="unpublished">Unpublished</CmdDropdown.RadioItem>
      </CmdDropdown.RadioGroup>
    </CmdDropdown.Content>
  </CmdDropdown.Menu>
);

interface BulkEditControlsProps {
  selectedRowKeys: Key[];
  setShowBulkEditButton: (show: boolean) => void;
  setIsLoading: (loading: boolean) => void;
  setSelectedRowKeys?: (keys: Key[]) => void;
  allRowKeys?: Key[];
}

const CommandBasedBulkEditControls = ({
  selectedRowKeys,
  setShowBulkEditButton,
  helpDocs,
  setIsLoading,
  setSelectedRowKeys,
  allRowKeys,
}: BulkEditControlsProps & { helpDocs: IEditorCommandTypeLite[] }) => {
  const {
    bulkCommandCategory: bulkHelpDocCategory,
    setBulkCommandCategory: setBulkHelpDocCategory,
    bulkCommandStatus: bulkHelpDocStatus,
    setBulkCommandStatus: setBulkHelpDocStatus,
    handleBulkSubmit,
  } = useBulkEditCommands({ commands: helpDocs, setIsLoading, setSelectedRowKeys });

  const { organization } = useAppContext();

  useEffect(() => {
    setShowBulkEditButton(selectedRowKeys.length > 0);
  }, [selectedRowKeys, setShowBulkEditButton]);

  const params = useParams<{ page: string }>();

  const type = params.page;

  return (
    <StyledSpacedContainer>
      <FlexStartContainer>
        {type === 'videos' && setSelectedRowKeys && allRowKeys && (
          <BulkSelectButton
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            allRowKeys={allRowKeys}
          />
        )}
        {(type === 'videos' || (type !== 'answers' && organization?.in_bar_doc_search)) && (
          <CategoryDropdown selectedCategoryId={bulkHelpDocCategory} onSelect={setBulkHelpDocCategory} />
        )}
        <StatusDropdown selectedStatus={bulkHelpDocStatus} onSelect={setBulkHelpDocStatus} />
        <CmdButton
          variant="primary"
          onClick={() => handleBulkSubmit(selectedRowKeys, false)}
          disabled={bulkHelpDocCategory === -1 && bulkHelpDocStatus === 'all'}
          icon={<Check />}
        >
          Apply Changes
        </CmdButton>
      </FlexStartContainer>
      <CmdButton variant="destructive" icon={<Trash04 />} onClick={() => handleBulkSubmit(selectedRowKeys, true)}>
        Delete
      </CmdButton>
    </StyledSpacedContainer>
  );
};
const KeywordBulkEditControls = ({
  selectedRowKeys,
  setShowBulkEditButton,
  keywords,
  setIsLoading,
  setSelectedRowKeys,
}: BulkEditControlsProps & { keywords: IKeyword[] }) => {
  const [bulkCommandStatus, setBulkCommandStatus] = useState<StatusType>('all');

  const {
    dispatch: {
      keywords: { bulkUpdate },
    },
  } = useAppContext();

  useEffect(() => {
    setShowBulkEditButton(selectedRowKeys.length > 0);
  }, [selectedRowKeys, setShowBulkEditButton]);

  return (
    <StyledSpacedContainer>
      <FlexStartContainer>
        <StatusDropdown selectedStatus={bulkCommandStatus} onSelect={setBulkCommandStatus} />
        <CmdButton
          variant="primary"
          onClick={async () => {
            setIsLoading(true);
            const isLiveStatus =
              bulkCommandStatus === 'published' ? true : bulkCommandStatus === 'unpublished' ? false : undefined;

            const toUpdate = keywords.filter((c) => selectedRowKeys.some((k) => k === c.id));

            const batch: IBatchOperation[] = toUpdate.map((keyword) => ({
              op: 'update',
              id: keyword.id,
              data: {
                is_live: isLiveStatus ?? keyword.is_live,
              },
            }));
            try {
              await bulkUpdate(batch, 'Bulk update of keywords');
              setSelectedRowKeys?.([]);
            } catch (err) {
              onIntegrationError(err as Exception);
            }

            setIsLoading(false);
          }}
          disabled={bulkCommandStatus === 'all'}
          icon={<Check />}
        >
          Apply Changes
        </CmdButton>
      </FlexStartContainer>
      <CmdButton
        variant="destructive"
        icon={<Trash04 />}
        onClick={async () => {
          setIsLoading(true);

          const toUpdate = keywords.filter((c) => selectedRowKeys.some((k) => k === c.id));

          const batch: IBatchOperation[] = toUpdate.map((keyword) => ({
            op: 'delete',
            id: keyword.id,
            data: {},
          }));
          try {
            await bulkUpdate(batch, 'Bulk deletion of keywords');
            setSelectedRowKeys?.([]);
          } catch (err) {
            onIntegrationError(err as Exception);
          }

          setIsLoading(false);
        }}
      >
        Delete
      </CmdButton>
    </StyledSpacedContainer>
  );
};

const BulkEditControls = <T extends IEditorCommandTypeLite | IKeyword>({
  selectedRowKeys,
  setShowBulkEditButton,
  editableData,
  setIsLoading,
  setSelectedRowKeys,
  allRowKeys,
}: BulkEditControlsProps & { editableData: T[] }) => {
  if (editableData.length === 0) {
    return null;
  }

  if ('text' in editableData[0]) {
    return (
      <CommandBasedBulkEditControls
        selectedRowKeys={selectedRowKeys}
        setShowBulkEditButton={setShowBulkEditButton}
        helpDocs={editableData as IEditorCommandTypeLite[]}
        setIsLoading={setIsLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        allRowKeys={allRowKeys}
      />
    );
  } else if ('keyword' in editableData[0]) {
    return (
      <KeywordBulkEditControls
        selectedRowKeys={selectedRowKeys}
        setShowBulkEditButton={setShowBulkEditButton}
        keywords={editableData as IKeyword[]}
        setIsLoading={setIsLoading}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    );
  }

  return null;
};

export default BulkEditControls;

export const FlexStartContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledSpacedContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
