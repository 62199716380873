import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { HeapCondition as HeapConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionProps } from '../../types';

export const HeapCondition: React.FC<ConditionProps<HeapConditionType>> = ({ condition }) => {
  const { onConditionChange, setHasBlurred, disabled } = useConditionEditor();
  const { organization } = useAppContext();

  const heapSegment =
    !!condition.field && !organization?.integrations?.heap?.segments?.hasOwnProperty(condition.field)
      ? 'Not Available'
      : condition.field;

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />

      <Col flex="1 1 auto" style={{ overflow: 'hidden' }}>
        <Select
          value={heapSegment}
          onChange={(value) => {
            onConditionChange({ ...condition, field: value });
          }}
          dropdownMatchSelectWidth={false}
          style={{ maxWidth: '100%', width: '100%' }}
          size="small"
          placeholder="Select a Heap Segment"
          onBlur={() => setHasBlurred(true)}
          disabled={disabled}
        >
          {organization?.integrations?.heap?.segments
            ? Object.entries(organization?.integrations?.heap?.segments).map(([key, value]) => {
                return (
                  <Select.Option value={key} key={key}>
                    {value}
                  </Select.Option>
                );
              })
            : null}
        </Select>
      </Col>
      <OperatorSelect />
    </Row>
  );
};
