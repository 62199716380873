import styled from '@emotion/styled';
import { CSSProperties } from 'react';

import { Col, Collapse, Row, Typography } from 'antd';
import { CB_COLORS } from './colors';
import Input from './Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const StyledHeader = styled.div<{ sidepanel?: boolean }>`
  display: flex;
  gap: 4px;
  padding: ${(props) => (props.sidepanel ? '12px 12px' : `16px 12px`)};
  background: #fff;
  justify-content: space-between;
`;

export const Header = styled.h2`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #000000;
`;

export const Subheader = styled.h3`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${CB_COLORS.neutral700};
`;

export const PaddingContainerSM = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const HeaderRow = styled(Row)`
  width: 100%;
  gap: 16px;
  padding: 16px;
`;

export const HeaderCol = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3px;
`;

export const CurrentFormAction = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${CB_COLORS.neutral600};
`;

export const SlugEditor = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SlugInput = styled(Input.TextArea, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: 0;
  max-width: 200px !important;
  min-height: 0 !important;
  font-size: 16px;
  line-height: 16px !important;
  color: ${CB_COLORS.neutral1000};
  border: ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};

  &:focus,
  &:hover {
    border: 1px solid ${CB_COLORS.neutral500};
  }

  &.ant-input {
    transition: all 0.3s, height 0s, font-size 0s;
  }
`;

export const StyledCollapse = styled(Collapse)<{ sidepanel?: boolean }>`
  & .ant-collapse {
    border-radius: 8px;
    border: 1px solid ${CB_COLORS.neutral400};
  }

  & .ant-collapse-item {
    border: none;
  }

  /*box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.34);*/
  overflow: hidden;

  & .ant-collapse-content {
    border-top: ${(props) => (props.sidepanel ? 'none' : `1px solid ${CB_COLORS.neutral0}}`)};
    border-bottom: ${(props) => (props.sidepanel ? 'none' : `1px solid ${CB_COLORS.neutral300}}`)};
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const { Panel } = Collapse;
export const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
`;

export const StyledSubPanel = styled(StyledPanel)`
  & > .ant-collapse-content {
    background: ${CB_COLORS.neutral0};
  }
`;

export const StyledPanelChildren = styled.div<{ sidepanel?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.sidepanel ? '0px 4px 4px 4px' : '16px')};
  gap: ${(props) => (props.sidepanel ? '4px' : '16px')};
`;

export const InputContainer = styled.div<{ width?: CSSProperties['width'] }>`
  width: ${(props) => props?.width ?? '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

export const ClickRecorderContainer = styled.div`
  display: flex;
  alignitems: center;
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${CB_COLORS.neutral800};
  user-select: none;
`;

export const TypographyCode = styled(Typography.Text)`
  code {
    white-space: pre-wrap;
  }
`;

export const MediaBlockStyledInput = styled(Input)`
  height: 32px;
  padding: 0 9px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;

  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

export const DashedBorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${CB_COLORS.neutral500};
  padding: 16px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  height: 64px;
`;
