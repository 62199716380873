import React from 'react';
import styled from '@emotion/styled';
import TextCustomizationInput from '../../TextCustomizationInput';

const SettingsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const TextCustomizationSettings = () => {
  return (
    <SettingsBlock>
      <TextCustomizationInput msgid="spotlight.recommended-header-label" label="Recommended header label" />
      <TextCustomizationInput msgid="spotlight.recents-header-label" label="Recents header label" />
      <TextCustomizationInput msgid="spotlight.experiences-header-label" label="Experiences header label" />
      <TextCustomizationInput msgid="spotlight.fallback-results-header" label="Fallback results header" />
    </SettingsBlock>
  );
};

export default TextCustomizationSettings;
