// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Flag03 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M4 3h15.587c.462 0 .694 0 .826.097a.5.5 0 0 1 .202.357c.015.163-.104.361-.342.758l-2.026 3.376c-.09.15-.135.225-.152.305a.5.5 0 0 0 0 .214c.017.08.062.155.152.305l2.026 3.376c.238.397.357.595.342.758a.5.5 0 0 1-.202.357c-.132.097-.364.097-.826.097H4V3Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 13h15.587c.462 0 .694 0 .826-.097a.5.5 0 0 0 .202-.357c.015-.163-.104-.361-.342-.758l-2.026-3.376c-.09-.15-.135-.225-.152-.305a.5.5 0 0 1 0-.214c.017-.08.062-.155.152-.305l2.026-3.376c.238-.397.357-.595.342-.758a.5.5 0 0 0-.202-.357C20.28 3 20.049 3 19.587 3H4v18"
    />
  </svg>
);
export default Flag03;
