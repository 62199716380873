import React from 'react';
import styled from '@emotion/styled';
import { Input, InputProps, TextAreaProps, CB_COLORS } from '@commandbar/design-system/components/antd';

const StyledInput = styled(Input)`
  .ant-input-group-addon,
  .ant-input {
    border-color: ${CB_COLORS.neutral500};
  }
  .ant-input-group-addon {
    background-color: ${CB_COLORS.neutral100};
    width: 41px;
  }
  .ant-input {
    border-left: none;
    font-weight: 500;
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  .ant-input-group-addon,
  .ant-input {
    border-color: ${CB_COLORS.neutral500};
  }
`;

type TextInputProps = ({ textArea: true } & TextAreaProps) | ({ textArea: false } & InputProps);

export const TextInput = (props: TextInputProps) => {
  const preventEventBubbling = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | React.FocusEvent<HTMLElement, Element>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const inputEventResets = {
    onClick: preventEventBubbling,
    onFocus: preventEventBubbling,
    onKeyDown: (e: React.KeyboardEvent) => e.stopPropagation(),
    onSelect: (e: React.SyntheticEvent) => e.stopPropagation(),
  };

  return props.textArea ? (
    <StyledTextArea {...inputEventResets} {...props} />
  ) : (
    <StyledInput {...inputEventResets} {...props} />
  );
};
