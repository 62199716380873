import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import type { Action } from '@cb/types/entities/command/actions';
import type { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { useEffect } from 'react';
import {
  Collapse,
  Dropdown,
  FormRow,
  Input,
  SortableList,
  Space,
  Tooltip,
  CB_COLORS,
  Container,
  HeaderCol,
  HeaderRow,
  FeatureAnnouncementCard,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';

import FormFactorStepDivider from '../components/FormFactorStepDivider';
import { SortableHandle } from 'react-sortable-hoc';
import GoalEditor from './GoalEditor';
import {
  DragIcon,
  PanelHeader,
  PanelLabel,
  DetailLink,
  DetailTabs,
  DetailTabPane,
  DetailTabPaneInner,
  OverlaySettingsContainer,
  OverlaySettingsOption,
  OverlayDisplayValue,
  OverlayCheckIconContainer,
} from '../components/styled';
import Sender from '../../management/Sender';
import { useReportEvent } from '../../hooks/useEventReporting';
import useWindowInfo from '../../hooks/useWindowInfo';

import {
  Trash04,
  Check,
  Grid03,
  ArrowUpRight,
  InfoCircle,
  FlipBackward,
  AlertHexagon,
  DotsVertical,
  ReverseLeft,
  Copy06,
  Settings02,
  Copy02,
} from '@commandbar/design-system/icons/react';
import { Targeting } from '../components/Targeting';
import { PreviewButton } from '../components/PreviewButton';
import { ReactComponent as CaretUp } from '../../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { useUsage } from '../../hooks/useUsage';
import { StyledTextArea } from '../helphub/shared';
import { CHECKLIST_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useHistory, useLocation } from 'react-router';
import { useIsEditorOpen } from 'editor/src/hooks';
import { ActionEditor } from '../ActionEditor';
import {
  CmdDivider,
  CmdSwitch,
  CmdButton,
  CmdDropdown,
  CmdLabel,
  cmdToast,
  CmdTooltip,
} from '@commandbar/design-system/cmd';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { isValidStartPageUrl } from '@commandbar/internal/client/share_links';
import Select from 'antd/lib/select';
import { isConditionGroupValid } from '../conditions/validate';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';
import ThemeSelect from '../components/ThemeSelect';
import EndUserChooser from '../components/EndUserChooser';
import { Checklist } from '@commandbar/internal/middleware/checklist';
import { ShareLinkModal } from '../components/ShareLinkModal';
import { DropdownColumn, DropdownRow, DropdownRowLabel } from '../styled';
import TextAreaWithInterpolation from '../components/TextAreaWithInterpolation';

const PaddingContainerSM = styled.div`
  padding: 8px 12px;
`;

const PageTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 2px 5px;
  color: #3d465d;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const Subheader2 = styled.h2`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
  color: ${CB_COLORS.blue1000};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral500};

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

const Subheader3 = styled.h3`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${CB_COLORS.neutral700};
`;

const StyledHeader = styled.div`
  padding: 5px 15px;
`;

const { Panel } = Collapse;

const BackgroundPanel = styled(Panel)`
  & .ant-collapse-content {
    background-color: #f2f2f2;
  }
`;

const BorderedPanel = styled(Panel)`
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &.ant-collapse-item-active {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  &.ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }

  & .ant-collapse-content {
    padding: 16px;
  }
`;

const PaddedCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 16px !important;
  }
`;

const DragHandle = SortableHandle(() => <DragIcon />);

interface ChecklistDetailProps {
  initialChecklist: IChecklist;
  setInitial: (checklist: IChecklist) => void;
  onClose: () => void;
  allChecklists: IChecklist[];
  onDelete: (id: number) => void;
  onDuplicate: (checklist: IChecklist) => Promise<IChecklist | null>;
}

const ChecklistDetail = (props: ChecklistDetailProps) => {
  const { onClose, initialChecklist } = props;

  const { dispatch, isStudio, commandBarReady, flags, organization, editorTags, hasUnsavedChangesRef } =
    useAppContext();
  const isEditorOpen = useIsEditorOpen();
  const [enduserSlugToReset, setEnduserSlugToReset] = React.useState<string>('');
  const { user } = useAuth();

  const [dirty, setDirty] = React.useState<IChecklist>({
    ...props.initialChecklist,
    _editorState: { timestamp: Date.now() },
  });
  const dirtyRef = React.useRef<IChecklist>(dirty);
  const [isSaving, setIsSaving] = React.useState(false);
  const [useCustomTheme, setUseCustomTheme] = React.useState<boolean>(
    props.initialChecklist.custom_theme !== undefined && props.initialChecklist.custom_theme !== null,
  );

  const { hasRouter, context: windowContext } = useWindowInfo();
  const history = useHistory();

  const { reportEvent } = useReportEvent();
  const { exceeding } = useUsage();
  const isNewChecklist = props.initialChecklist.id < 0;
  const isDirty = !_.isEqual(props.initialChecklist, dirty);

  const [shareLinkModalOpen, setShareLinkModalOpen] = React.useState(false);
  const toggleShareLinkModal = () => setShareLinkModalOpen((previous) => !previous);

  const preventNavigation = isDirty && !isSaving;
  const isAllowedToSave = hasRequiredRole(user, props.initialChecklist.is_live ? 'editor' : 'contributor');

  useEffect(() => {
    hasUnsavedChangesRef.current = preventNavigation && isAllowedToSave;
  }, [preventNavigation]);

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isContributorOrHigher = hasRequiredRole(user, 'contributor');

  const startPageUrlError = !!dirty.share_page_url_or_path
    ? isValidStartPageUrl(dirty.share_page_url_or_path, !dirty.audience ? null : dirty.show_expression)
    : { isValid: true, error: '' }; // do not evaluate on page targeting if pre-audience questlist because the page targeting condition could also include other condition types

  const possibleErrors: Array<{ condition: boolean; message: string }> = [
    {
      condition: !dirty.title && !dirty.description,
      message: 'Title and description must not both be empty.',
    },
    {
      condition: dirty.trigger.type === 'on_command_execution' && !dirty.trigger.meta.command,
      message: 'Trigger: Command must be set.',
    },
    {
      condition: dirty.trigger.type === 'on_event' && !dirty.trigger.meta.event,
      message: 'Trigger: Event name must be set.',
    },
    {
      condition: dirty.trigger.type === 'when_element_appears' && !dirty.trigger.meta.selector,
      message: 'Trigger: Element must be set.',
    },
    {
      condition: !isConditionGroupValid(dirty.show_expression),
      message: 'Some Targeting ("Where") conditions are invalid.',
    },
    {
      condition: dirty.audience?.type === 'rule_expression' ? !isConditionGroupValid(dirty.audience.expression) : false,
      message: 'Custom Audience for Targeting has invalid conditions.',
    },
    {
      condition: dirty.copilot_suggest && !dirty.copilot_description,
      message:
        'Checklist is enabled for suggestion in copilot but has no description. The description is required to help Copilot make better suggestions.',
    },
    {
      condition: !startPageUrlError.isValid,
      message: 'Invalid start page: ' + startPageUrlError.error,
    },
    {
      condition: !isAllowedToSave,
      message: 'You do not have the required permission to make changes to this checklist.',
    },
  ];

  for (let i = 0; i < dirty.items.length; i++) {
    const item = dirty.items[i];

    possibleErrors.push(
      ...[
        {
          condition: !item.title && !item.description,
          message: `Item ${i + 1}: Title and description must not both be empty.`,
        },
        {
          condition: item.goal.type === 'command_executed' && !item.goal.meta.command,
          message: `Item ${i + 1} goal: Command must be set.`,
        },
        {
          condition: item.goal.type === 'element_clicked' && !item.goal.value,
          message: `Item ${i + 1} goal: Element to be clicked must be set.`,
        },
        {
          condition: item.goal.type === 'conditions_met' && getConditions(item.goal.expression).length === 0,
          message: `Item ${i + 1} goal: Conditions must be set.`,
        },
        {
          condition: item.goal.type === 'page_visited' && !item.goal.value,
          message: `Item ${i + 1} goal: Page location part must be set.`,
        },
        {
          condition: item.action.type === 'link' && !item.action.value,
          message: `Item ${i + 1} action: Url must be set.`,
        },
        {
          condition: item.action.type === 'click' && !item.action.value.length,
          message: `Item ${i + 1} action: Element to click must be set.`,
        },
        {
          condition: item.action.type === 'execute_command' && item.action.meta.type === 'action',
          message: `Item ${i + 1} action: Command must be set.`,
        },
        {
          condition: item.action.type === 'execute_command' && item.action.meta.command === 'helpdoc',
          message: `Item ${i + 1} action: Article or file must be selected.`,
        },
        {
          condition: item.action.type === 'execute_command' && item.action.meta.command === 'video',
          message: `Item ${i + 1} action: Video must be selected.`,
        },
        {
          condition: item.action.type === 'nudge' && item.action.value < 0,
          message: `Item ${i + 1} action: Nudge must be set.`,
        },
        {
          condition: item.action.type === 'questlist' && item.action.value < 0,
          message: `Item ${i + 1} action: Checklist must be set.`,
        },
        {
          condition: item.action.type === 'open_chat' && item.action.meta.type === '',
          message: `Item ${i + 1} action: Chat provider must be selected.`,
        },
        {
          condition: item.goal.type === 'conditions_met' && !isConditionGroupValid(item.goal.expression),
          message: `Item ${i + 1} goal: Some conditions are invalid.`,
        },
        {
          condition: !!(
            item.goal.type === 'event_tracked' &&
            item.goal.condition_group &&
            !isConditionGroupValid(item.goal.condition_group)
          ),
          message: `Item ${i + 1} goal: Some conditions are invalid.`,
        },
        {
          condition: item.action.type === 'link' && item.action.operation === 'router' && !hasRouter,
          message: `Item ${i + 1} action: Link operation is set to "router", but there is no router function defined.`,
        },
      ].filter(({ condition }) => condition),
    );
  }

  const errors = possibleErrors.filter(({ condition }) => condition);
  const hasError = errors.length > 0;

  const [activeKey, setActiveKey] = React.useState('1');
  const [activeItemKey, setActiveItemKey] = React.useState<number | undefined>(undefined);

  const location = useLocation();
  const isEditingTemplate = location.pathname.includes('template');

  const onChange = async (c: IChecklist) => {
    c._editorState = { ...c._editorState, timestamp: Date.now() };
    dirtyRef.current = c;
    setDirty(c);
  };

  const onTargetingChange = async (changes: Partial<IChecklist>) => {
    const newChecklistState = { ...dirtyRef.current, ...changes };

    onChange(newChecklistState);
    onResetPreview(newChecklistState);
  };

  const onResetPreview = (changes: Partial<IChecklist>) => {
    const newChecklistState = { ...dirtyRef.current, ...changes };
    Sender.previewChecklist({ ...newChecklistState, _editorState: { timestamp: Date.now() } }, true);
  };

  const onItemChange = async <T extends keyof IChecklistItem>(field: T, value: IChecklistItem[T], index: number) => {
    const newVal = {
      ...dirtyRef.current,
      items: [...dirtyRef.current.items],
      _editorState: { initial_active_item: index },
    };
    newVal.items[index] = { ...newVal.items[index], [field]: value };
    reportEvent('questlist item edited', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        action: newVal.items[index].action,
        goal: newVal.items[index].goal,
      },
    });
    onChange(newVal);
  };

  const onSave = async (checklist: IChecklist) => {
    if (!(isDirty || isNewChecklist) || hasError || isSaving) {
      return;
    }

    setIsSaving(true);
    const newChecklist = await dispatch.checklists.save(checklist);
    if (newChecklist.id !== checklist.id) {
      history.replace(`${CHECKLIST_ROUTE}/${newChecklist.id}`);
    }
    props.setInitial(newChecklist);

    setIsSaving(false);
  };

  useModS(() => onSave(dirty));

  React.useEffect(() => {
    if (commandBarReady && (isEditorOpen || isStudio)) {
      Sender.previewChecklist(
        { ...dirty, _editorState: { ...dirty._editorState, expanded: dirty.open_by_default } },
        false,
      );
    }
  }, [dirty, isStudio, isEditorOpen, commandBarReady]);

  React.useEffect(() => {
    return () => {
      Sender.stopChecklistPreview();
    };
  }, []);

  React.useEffect(() => {
    dirtyRef.current = props.initialChecklist;
    setDirty(dirtyRef.current);
  }, [props.initialChecklist]);

  const onSort = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newItems = [...dirty.items];
    const removed = newItems.splice(oldIndexOfMovedObj, 1)[0];

    newItems.splice(newIndexOfMovedObj, 0, removed);

    const newVal = {
      ...dirty,
      items: newItems,
    };

    onChange(newVal);
  };

  const addNewItem = () => {
    const newItems = [...dirty.items];
    newItems.push({
      id: -1,
      title: 'New item',
      description: '',
      action: { type: 'link', value: '', operation: 'self' },
      goal: { type: 'cta_clicked' },
      cta: 'Go',
      celebrate: false,
      skippable: false,
    });

    const payloadMessage = initialChecklist?.title
      ? `${initialChecklist.title} (ID: ${initialChecklist.id})`
      : `${initialChecklist.description} (ID: ${initialChecklist.id}`;
    reportEvent('questlist item created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: payloadMessage,
      eventProps: {
        name: payloadMessage,
        action: 'link',
        goal: 'cta_clicked',
      },
    });

    onChange({
      ...dirty,
      items: newItems,
    });

    setActiveItemKey(newItems.length - 1);
  };

  const errorList = (_errors: Array<{ message: string }>) => (
    <ul
      style={{
        listStyleType: 'none',
        padding: 0,
      }}
    >
      {_errors.map(({ message }) => (
        <li key={message}>{message}</li>
      ))}
    </ul>
  );

  return (
    <Container>
      <ShareLinkModal shareableEntity={dirty} isOpen={shareLinkModalOpen} setIsOpen={setShareLinkModalOpen} />
      <HeaderRow justify="space-between" wrap={true} align="middle">
        <HeaderCol style={{ width: 'fit-content' }}>
          <CmdButton onClick={onClose}>
            <FlipBackward />
          </CmdButton>

          <PageTitle>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <PageTitle>{props.initialChecklist?.id !== -1 ? 'Edit checklist' : 'Create checklist'}</PageTitle>
            </div>
          </PageTitle>

          <Tooltip
            key={`right-tooltip-status-${dirty.id}`}
            content={
              exceeding.isAtOrOverLiveQuestlists ? (
                <span>You have hit your organization's maximum number of allowed live checklists</span>
              ) : (
                errorList(errors)
              )
            }
            showIf={hasError || exceeding.isAtOrOverLiveQuestlists || !isAllowedToPublish}
            placement="top"
          >
            <CmdSwitch
              checked={!!dirty.is_live}
              onCheckedChange={async (e: boolean) => {
                setIsSaving(true);
                const newChecklist = await dispatch.checklists.save({ ...dirtyRef.current, is_live: e });
                if (newChecklist.id !== dirty.id) {
                  history.replace(`${CHECKLIST_ROUTE}/${newChecklist.id}`);
                }
                props.setInitial(newChecklist);
                setIsSaving(false);

                if (e) {
                  reportEvent('questlist published', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: dirty.title,
                    eventProps: {
                      name: dirty.title,
                      item_count: dirty.items.length,
                    },
                  });
                }
              }}
              onLabel="Live"
              offLabel="Draft"
              disabled={
                hasError || isSaving || (exceeding.isAtOrOverLiveQuestlists && !dirty.is_live) || !isAllowedToPublish
              }
            />
          </Tooltip>
        </HeaderCol>

        <Space>
          <CmdTooltip message="Test this nudge in your app" showIf={isStudio}>
            <PreviewButton
              onClick={() => {
                Sender.hideEditor();
                Sender.previewChecklist({ ...dirty, _editorState: { timestamp: Date.now() } }, true);
              }}
            />
          </CmdTooltip>

          <Tooltip showIf={hasError} content={errorList(errors)} placement="left">
            <div>
              <CmdButton
                variant="primary"
                onClick={() => onSave(dirty)}
                disabled={!(isDirty || isNewChecklist) || hasError || isSaving}
                style={{
                  ...(hasError && {
                    border: '1px solid rgb(185, 28, 28)',
                    boxShadow: '0px 0px 0px 2px rgba(185, 28, 28, .3)',
                  }),
                }}
              >
                {hasError && <AlertHexagon />}
                Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
              </CmdButton>
            </div>
          </Tooltip>

          {(!isStudio || (isStudio && isAllowedToPublish)) && (
            <CmdDropdown.Menu>
              <CmdDropdown.Trigger>
                <CmdButton variant="link" className="px-sm">
                  <DotsVertical />
                </CmdButton>
              </CmdDropdown.Trigger>

              <CmdDropdown.Content className="cursor-auto" style={{ width: '236px' }}>
                {isContributorOrHigher && (
                  <CmdDropdown.Item
                    disabled={Number(dirty.id) < 0 || isEditingTemplate}
                    onClick={async () => {
                      await props.onDuplicate(dirty);
                    }}
                  >
                    <Copy02 /> Duplicate
                  </CmdDropdown.Item>
                )}
                {!isEditingTemplate && (
                  <CmdDropdown.Item
                    disabled={Number(dirty.id) < 0 || isEditingTemplate}
                    onClick={() => {
                      navigator.clipboard.writeText(`${dirty.id}`).then(() => {
                        cmdToast.success('Nudge id copied to clipboard.');
                      });
                    }}
                  >
                    <Copy06 /> Copy ID
                  </CmdDropdown.Item>
                )}
                <CmdDropdown.Item disabled={Number(dirty.id) < 0} onClick={toggleShareLinkModal}>
                  <Copy06 /> Trigger link...
                </CmdDropdown.Item>
                <CmdDropdown.Separator />
                {!isStudio && (
                  <CmdDropdown.Item
                    onClick={async (e) => {
                      e.stopPropagation();
                      Sender.resetChecklist(dirty.id);
                      cmdToast.success(`Checklist history reset for ${windowContext?.id}`);
                    }}
                  >
                    <ReverseLeft />
                    Reset for current user
                  </CmdDropdown.Item>
                )}
                <DropdownColumn style={{ padding: '8px' }}>
                  <DropdownRowLabel className="flex items-center gap-xs">
                    Tags
                    <CmdTooltip message="Add tags to help organize your Checklists in the Editor.">
                      <div className="flex">
                        <InfoCircle />
                      </div>
                    </CmdTooltip>
                  </DropdownRowLabel>
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Create or select a tag..."
                    onChange={(values) => {
                      const newDirty = { ...dirty, editor_tags: values };
                      setDirty(newDirty);
                    }}
                    value={dirty.editor_tags}
                    options={editorTags.map((tag) => ({ value: tag }))}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </DropdownColumn>
                {isStudio && isAllowedToPublish && (
                  <>
                    <CmdDropdown.Separator />
                    <div style={{ padding: '8px', display: 'flex', gap: 8, flexDirection: 'column', flex: 1 }}>
                      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
                        <div className="flex items-center gap-xs">
                          <CmdLabel>Reset user history</CmdLabel>
                          <CmdTooltip message="Reset a user’s seen, completed, and dismissed history for this checklist.">
                            <div className="flex">
                              <InfoCircle />
                            </div>
                          </CmdTooltip>
                        </div>
                        <EndUserChooser
                          endUserSlugs={enduserSlugToReset ? [enduserSlugToReset] : []}
                          onEndUserChange={(end_user) => setEnduserSlugToReset(end_user.slug)}
                        />
                      </div>

                      <CmdButton
                        fullWidth
                        disabled={dirty.id < 0 || !enduserSlugToReset}
                        icon={<ReverseLeft />}
                        onClick={async () => {
                          const result = await Checklist.resetInteractions(enduserSlugToReset, dirty.id);

                          if (result.did_reset) {
                            cmdToast.success(`Interaction history reset for ${enduserSlugToReset}`);
                          } else {
                            cmdToast.error(
                              `There are no interactions for this experience and ${enduserSlugToReset} that could be reset`,
                            );
                          }
                        }}
                      >
                        Reset
                      </CmdButton>
                    </div>
                  </>
                )}
                {isAllowedToSave && dirty.id !== -1 && (
                  <>
                    <CmdDropdown.Separator />
                    <CmdDropdown.Item
                      onClick={() => {
                        props.onDelete(props.initialChecklist.id);
                      }}
                    >
                      <Trash04 /> Delete
                    </CmdDropdown.Item>
                  </>
                )}
              </CmdDropdown.Content>
            </CmdDropdown.Menu>
          )}
        </Space>
      </HeaderRow>

      <UpgradeCTA product="checklists" padding="0 16px" />

      {props.initialChecklist?.id === -1 &&
        (flags['release-search-experiences-in-help-hub'] || flags['release-search-experiences-in-spotlight']) && (
          <div style={{ padding: '8px 24px' }}>
            <FeatureAnnouncementCard
              identifier={'checklists-search'}
              title={'Checklists will now show up in Spotlight and Helphub search results'}
            >
              This change makes it easier for users to discover Checklists that might be helpful to them. You can still
              prevent them from showing up in search results by updating the searchability settings in the menu on the
              right below.
            </FeatureAnnouncementCard>
          </div>
        )}

      <FlexColumn>
        <CmdDropdown.Menu>
          <CmdDropdown.Trigger className="absolute right-4 z-10" style={{ top: '8px' }}>
            <CmdButton variant="link" icon={<Settings02 />} />
          </CmdDropdown.Trigger>

          <CmdDropdown.Content className="cursor-auto" style={{ width: '288px' }}>
            <DropdownRow>
              <DropdownRowLabel>Dismissible</DropdownRowLabel>
              <CmdSwitch
                checked={dirty.dismissible}
                onCheckedChange={(checked) =>
                  onChange({
                    ...dirtyRef.current,
                    dismissible: checked,
                  })
                }
              />
            </DropdownRow>
            <DropdownRow>
              <DropdownRowLabel>Skippable</DropdownRowLabel>
              <CmdSwitch
                checked={dirty.skippable}
                onCheckedChange={(checked) =>
                  onChange({
                    ...dirtyRef.current,
                    skippable: checked,
                  })
                }
              />
            </DropdownRow>
            <DropdownRow>
              <DropdownRowLabel>Open by default</DropdownRowLabel>
              <CmdSwitch
                checked={dirty.open_by_default}
                onCheckedChange={(checked) =>
                  onChange({
                    ...dirtyRef.current,
                    open_by_default: checked,
                  })
                }
              />
            </DropdownRow>
            <DropdownRow>
              <DropdownRowLabel>Celebrate on completion 🎉</DropdownRowLabel>
              <CmdSwitch
                checked={dirty.celebrate}
                onCheckedChange={(checked) =>
                  onChange({
                    ...dirtyRef.current,
                    celebrate: checked,
                  })
                }
              />
            </DropdownRow>

            <CmdDropdown.Separator />

            <CmdDropdown.Label className="flex items-center gap-xs text-sm">
              Searchable in
              <CmdTooltip message="Make this checklist searchable from other experiences">
                <div className="flex">
                  <InfoCircle />
                </div>
              </CmdTooltip>
            </CmdDropdown.Label>

            {flags['release-search-experiences-in-help-hub'] && (
              <DropdownRow>
                <DropdownRowLabel>HelpHub</DropdownRowLabel>
                <CmdTooltip
                  showIf={!organization.helphub_enabled}
                  message="You must first enable HelpHub to make this searchable."
                >
                  <CmdSwitch
                    checked={dirty.show_in_helphub_search && organization.helphub_enabled}
                    onCheckedChange={() =>
                      onChange({
                        ...dirtyRef.current,
                        show_in_helphub_search: !dirtyRef.current.show_in_helphub_search,
                      })
                    }
                    disabled={!organization.helphub_enabled}
                  />
                </CmdTooltip>
              </DropdownRow>
            )}

            {flags['release-search-experiences-in-spotlight'] && (
              <DropdownRow>
                <DropdownRowLabel>Spotlight</DropdownRowLabel>
                <CmdTooltip
                  showIf={!organization.bar_enabled}
                  message="You must first enable Spotlight to make this searchable."
                >
                  <CmdSwitch
                    checked={dirty.show_in_spotlight_search && organization.bar_enabled}
                    onCheckedChange={() =>
                      onChange({
                        ...dirtyRef.current,
                        show_in_spotlight_search: !dirtyRef.current.show_in_spotlight_search,
                      })
                    }
                    disabled={!organization.bar_enabled}
                  />
                </CmdTooltip>
              </DropdownRow>
            )}

            <DropdownRow>
              <DropdownRowLabel>Copilot</DropdownRowLabel>
              <CmdSwitch
                checked={dirty.copilot_suggest}
                onCheckedChange={() =>
                  onChange({
                    ...dirtyRef.current,
                    copilot_suggest: !dirtyRef.current.copilot_suggest,
                  })
                }
              />
            </DropdownRow>

            {dirty.copilot_suggest && (
              <>
                <DropdownColumn>
                  <DropdownRowLabel>CTA Label</DropdownRowLabel>
                  <Input
                    value={dirty.copilot_cta_label}
                    onChange={(e) =>
                      onChange({
                        ...dirtyRef.current,
                        copilot_cta_label: e.target.value,
                      })
                    }
                    placeholder={dirty.title}
                    style={{
                      border: `1px solid ${CB_COLORS.neutral300}`,
                    }}
                  />
                </DropdownColumn>

                <CmdDropdown.Separator />

                <DropdownColumn>
                  <DropdownRowLabel className="flex items-center gap-xs">
                    Description
                    <CmdTooltip message="Copilot will use this to make smart suggestions to users.">
                      <div className="flex">
                        <InfoCircle />
                      </div>
                    </CmdTooltip>
                  </DropdownRowLabel>
                  <StyledTextArea
                    value={dirty.copilot_description}
                    onChange={(e) =>
                      onChange({
                        ...dirtyRef.current,
                        copilot_description: e.target.value,
                      })
                    }
                    rows={2}
                    placeholder="Describe the Checklist and how the user will benefit from it."
                    style={{
                      border: `1px solid ${CB_COLORS.neutral300}`,
                    }}
                  />
                </DropdownColumn>
              </>
            )}

            {flags['release-themes-v2'] && (
              <>
                <CmdDropdown.Separator />
                <DropdownRow>
                  <DropdownRowLabel>Custom theme</DropdownRowLabel>
                  <CmdSwitch
                    checked={useCustomTheme}
                    onCheckedChange={(checked) => {
                      if (!checked) {
                        onChange({ ...dirtyRef.current, custom_theme: null });
                      }
                      setUseCustomTheme(checked);
                    }}
                  />
                </DropdownRow>
                {useCustomTheme && (
                  <DropdownRow style={{ marginBottom: '8px' }}>
                    <ThemeSelect
                      value={dirty.custom_theme}
                      onChange={(themeId) => {
                        onChange({ ...dirtyRef.current, custom_theme: themeId });
                      }}
                      style={{ flex: 1 }}
                    />
                  </DropdownRow>
                )}
              </>
            )}
          </CmdDropdown.Content>
        </CmdDropdown.Menu>

        <DetailTabs destroyInactiveTabPane={true} type="card">
          <DetailTabPane tab={'Details'} key="tab-details" style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <div>
                <div style={{ paddingBottom: 75, width: '100%' }}>
                  <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Collapse
                      defaultActiveKey={activeKey}
                      activeKey={activeKey}
                      expandIconPosition="end"
                      onChange={() => setActiveKey(activeKey !== '1' ? '1' : '0')}
                      expandIcon={() => null}
                      style={{
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                        background: activeKey === '1' ? '#FFFFFF' : '#F2F2F2',
                        borderRadius: '8px',
                      }}
                    >
                      <Panel
                        key="1"
                        header={
                          <StyledHeader>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Subheader2>Checklist</Subheader2>
                              {activeKey === '1' ? (
                                <React.Fragment>
                                  <CaretUp style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: '8px',
                                      marginLeft: 'auto',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Dropdown
                                      trigger={['click']}
                                      placement="bottomLeft"
                                      overlay={
                                        <OverlaySettingsContainer style={{ width: '150px' }}>
                                          <OverlaySettingsOption
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              onChange({
                                                ...dirtyRef.current,
                                                position: 'bottomRight',
                                              });
                                            }}
                                          >
                                            <OverlayCheckIconContainer visible={dirty.position === 'bottomRight'}>
                                              <Check width={13} />
                                            </OverlayCheckIconContainer>
                                            Bottom Right
                                          </OverlaySettingsOption>
                                          <OverlaySettingsOption
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              onChange({
                                                ...dirtyRef.current,
                                                position: 'bottomLeft',
                                              });
                                            }}
                                          >
                                            <OverlayCheckIconContainer visible={dirty.position === 'bottomLeft'}>
                                              <Check width={13} />
                                            </OverlayCheckIconContainer>
                                            Bottom Left
                                          </OverlaySettingsOption>
                                        </OverlaySettingsContainer>
                                      }
                                    >
                                      <OverlayDisplayValue
                                        gap={'6px'}
                                        onClick={(e: any) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <IconContainer>
                                          <Grid03 width={12} />
                                        </IconContainer>
                                        {dirty.position === 'bottomLeft' ? 'Bottom Left' : 'Bottom Right'}
                                        <IconContainer>
                                          <CaretDown />
                                        </IconContainer>
                                      </OverlayDisplayValue>
                                    </Dropdown>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <CaretDown style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              )}
                            </div>
                            <Subheader3>
                              {activeKey !== '1' && dirty.title ? `"${dirty.title}"...` : 'What gets shown?'}
                            </Subheader3>
                          </StyledHeader>
                        }
                      >
                        <PaddingContainerSM>
                          <FormRow
                            title="Title:"
                            input={
                              <TextAreaWithInterpolation
                                onChange={(e) =>
                                  onChange({
                                    ...dirtyRef.current,
                                    title: e,
                                  })
                                }
                                value={dirty.title}
                              />
                            }
                          />
                          <FormRow
                            title="Description:"
                            subtitle="(optional)"
                            input={
                              <TextAreaWithInterpolation
                                onChange={(e) =>
                                  onChange({
                                    ...dirtyRef.current,
                                    description: e,
                                  })
                                }
                                value={dirty.description}
                              />
                            }
                          />
                        </PaddingContainerSM>
                      </Panel>
                    </Collapse>

                    <FormFactorStepDivider />

                    <PaddedCollapse
                      onChange={() => setActiveKey(activeKey !== '2' ? '2' : '0')}
                      activeKey={activeKey}
                      expandIconPosition="end"
                      expandIcon={() => null}
                      style={{
                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                        background: activeKey === '2' ? '#FFFFFF' : '#F2F2F2',
                        borderRadius: '8px',
                      }}
                    >
                      <BackgroundPanel
                        key="2"
                        header={
                          <StyledHeader>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                              <Subheader2>Items </Subheader2>
                              {activeKey === '2' ? (
                                <CaretUp style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              ) : (
                                <CaretDown style={{ color: CB_COLORS.neutral600, height: '32px' }} />
                              )}
                            </div>
                            <Subheader3>{dirty.items.length} checklist items</Subheader3>
                          </StyledHeader>
                        }
                      >
                        <SortableList
                          nodes={dirty.items.map((item, index) => {
                            return (
                              <Collapse
                                key={index}
                                bordered={false}
                                expandIcon={() => null}
                                expandIconPosition="end"
                                style={{
                                  borderRadius: '8px',
                                  background: '#FFFFFF',
                                }}
                                activeKey={`${activeItemKey}-panel`}
                                onChange={() => {
                                  setActiveItemKey((activeItemKey) => (activeItemKey === index ? undefined : index));

                                  Sender.previewChecklist(
                                    { ...dirty, _editorState: { timestamp: Date.now(), initial_active_item: index } },
                                    false,
                                  );
                                }}
                              >
                                <BorderedPanel
                                  header={
                                    <PanelHeader style={{ height: '40px' }}>
                                      <DragHandle />
                                      <PanelLabel>
                                        {index + 1}: {item.title || ''}
                                        {`${index}-panel` === `${activeItemKey}-panel` ? (
                                          <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                                        ) : (
                                          <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                                        )}
                                      </PanelLabel>

                                      {`${index}-panel` === `${activeItemKey}-panel` && (
                                        <CmdDropdown.Menu>
                                          <CmdDropdown.Trigger>
                                            <CmdButton variant="link" icon={<DotsVertical />} />
                                          </CmdDropdown.Trigger>

                                          <CmdDropdown.Content className="cursor-auto" style={{ width: '254px' }}>
                                            <DropdownRow>
                                              <DropdownRowLabel>Skippable</DropdownRowLabel>
                                              <CmdSwitch
                                                checked={item.skippable}
                                                onCheckedChange={(checked) => onItemChange('skippable', checked, index)}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </DropdownRow>
                                            <DropdownRow>
                                              <DropdownRowLabel>Celebrate on completion 🎉</DropdownRowLabel>
                                              <CmdSwitch
                                                checked={item.celebrate}
                                                onCheckedChange={(checked) => onItemChange('celebrate', checked, index)}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              />
                                            </DropdownRow>
                                          </CmdDropdown.Content>
                                        </CmdDropdown.Menu>
                                      )}

                                      <CmdButton
                                        icon={<Trash04 />}
                                        variant="link"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const deleted = dirtyRef.current.items[index];
                                          const newItems = [...dirtyRef.current.items];
                                          newItems.splice(index, 1);

                                          const newVal = {
                                            ...dirtyRef.current,
                                            items: newItems,
                                            _editorState: { initial_active_item: index },
                                          };

                                          reportEvent('questlist item deleted', {
                                            segment: true,
                                            highlight: true,
                                            slack: true,
                                            eventProps: {
                                              action: deleted.action.type,
                                              goal: deleted.goal.type,
                                            },
                                          });

                                          onChange(newVal);
                                        }}
                                      />
                                    </PanelHeader>
                                  }
                                  key={`${index}-panel`}
                                >
                                  <FormRow
                                    title="Title:"
                                    key={`${item.id}-title`}
                                    input={
                                      <TextAreaWithInterpolation
                                        onChange={(e) => {
                                          onItemChange('title', e, index);
                                        }}
                                        value={item.title}
                                      />
                                    }
                                  />
                                  <FormRow
                                    title="Description:"
                                    subtitle="(optional)"
                                    input={
                                      <TextAreaWithInterpolation
                                        onChange={(e) => {
                                          onItemChange('description', e, index);
                                        }}
                                        value={item.description}
                                      />
                                    }
                                  />
                                  <CmdDivider spacing="xl" />
                                  <FormRow
                                    title="When clicked..."
                                    input={
                                      <ActionEditor
                                        possibleTypes={[
                                          'click',
                                          'link',
                                          'page',
                                          'execute_command',
                                          'questlist',
                                          'nudge',
                                          'help_doc',
                                          'video',
                                          'open_bar',
                                          'open_chat',
                                          'open_copilot',
                                          'open_helphub',
                                        ]}
                                        action={item.action as Action}
                                        onActionChange={(action) => {
                                          onItemChange('action', action as IChecklistItem['action'], index);
                                        }}
                                      />
                                    }
                                  />
                                  <FormRow
                                    title="CTA label:"
                                    input={
                                      <Input
                                        value={item.cta}
                                        onChange={(e) => {
                                          onItemChange('cta', e.target.value, index);
                                        }}
                                      />
                                    }
                                  />
                                  <CmdDivider spacing="xl" />
                                  <GoalEditor
                                    item={item}
                                    onChange={(goal) => {
                                      onItemChange('goal', goal, index);
                                    }}
                                  />
                                </BorderedPanel>
                              </Collapse>
                            );
                          })}
                          onSort={onSort}
                          useDragHandle
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                          <CmdButton onClick={addNewItem}>Add item</CmdButton>
                        </div>
                      </BackgroundPanel>
                    </PaddedCollapse>
                  </Space>
                </div>
              </div>
            </DetailTabPaneInner>
          </DetailTabPane>
          <DetailTabPane tab="Targeting" key="tab-targeting" style={{ padding: '16px' }}>
            <DetailTabPaneInner>
              <Targeting dirty={dirty} handleOnChange={onTargetingChange} />
            </DetailTabPaneInner>
          </DetailTabPane>
          {!isNewChecklist && (
            <DetailTabPane
              tab={
                <DetailLink
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/analytics/checklists/${props.initialChecklist.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Analytics&nbsp;&nbsp;
                  <ArrowUpRight />
                </DetailLink>
              }
              key="tab-analytics"
              disabled={true}
            />
          )}
          ;
        </DetailTabs>
      </FlexColumn>
    </Container>
  );
};

export default ChecklistDetail;
