import { CmdStackedBarChart, CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import { AnalyticsSpotlightData } from '@commandbar/internal/middleware/analytics/spotlight';
import { StateLegendItem } from '../../components/StateLegendItem';
import { useAppContext } from 'editor/src/AppStateContext';

type StackedChartProps = {
  data?: AnalyticsSpotlightData;
  isLoading?: boolean;
};

const StackedBarChart = ({ data, isLoading = false }: StackedChartProps) => {
  const { links_total, opens_total, actions_total, stacked_bar_chart_data, copilot_queries_total, success_rate } =
    data || {};
  const { organizationSettings } = useAppContext();

  return (
    <CmdCard>
      <CmdCard.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <div>
            <CmdTypography.Body fontWeight="semi-bold">Activity</CmdTypography.Body>
            <CmdTypography.HelpText variant="secondary" fontWeight="medium">
              See how users have been interacting with Spotlight
            </CmdTypography.HelpText>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '24px',
          }}
        >
          <StateLegendItem
            title="Opens"
            percentageChange={opens_total?.percent_change}
            metric={opens_total?.metric}
            isLoading={isLoading}
          />

          <StateLegendItem
            title="Page clicks"
            percentageChange={links_total?.percent_change}
            metric={links_total?.metric}
            lineColor="#4a70f2"
            isLoading={isLoading}
          />

          <StateLegendItem
            title="Action clicks"
            percentageChange={actions_total?.percent_change}
            metric={actions_total?.metric}
            lineColor="#F87171"
            isLoading={isLoading}
          />

          <StateLegendItem
            title={organizationSettings?.spotlight_ask_copilot_label ?? 'Ask Copilot'}
            percentageChange={copilot_queries_total?.percent_change}
            metric={copilot_queries_total?.metric}
            isLoading={isLoading}
          />

          <StateLegendItem
            title="Success rate"
            percentageChange={success_rate?.percent_change}
            metric={success_rate?.metric}
            isLoading={isLoading}
            format="percentage"
          />
        </div>
      </CmdCard.Header>
      <CmdCard.Content style={{ height: '300px' }}>
        <CmdStackedBarChart chartData={stacked_bar_chart_data?.data} isLoading={isLoading} />
      </CmdCard.Content>
    </CmdCard>
  );
};

export default StackedBarChart;
