import { useState } from 'react';
import { Typography, Page, Layout, Steps } from '@commandbar/design-system/components/antd';
import { IOrganizationType, IUserType } from '@commandbar/internal/middleware/types';
import { CredentialsStep } from './CredentialsStep';
import { IAlgoliaConfig } from './utils';
import { ConfigureIndexesStep } from './ConfigureIndexes';
import { FinalizingStep } from './FinalizingStep';
import { AlgoliaConfigProvider } from './AlgoliaConfigProvider';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Provide your Algolia credentials',
    component: CredentialsStep,
  },
  {
    title: 'Configure Indexes',
    component: ConfigureIndexesStep,
  },
  {
    title: 'Try it out!',
    component: FinalizingStep,
  },
];

interface IAlgoliaIntegrationProps {
  organization: IOrganizationType;
  user: IUserType;
}

const AlgoliaSetupSteps = (props: IAlgoliaIntegrationProps) => {
  const stepMarker = (dot: any) => <>{dot}</>;
  const [stepIdx, setStepIdx] = useState<number>(0);

  const { title, component: CurrentStepComponent } = STEPS[stepIdx];

  const handlePrev = () => {
    setStepIdx((stepIdx) => Math.max(stepIdx - 1, 0));
  };

  const handleNext = () => {
    setStepIdx((stepIdx) => Math.min(stepIdx + 1, STEPS.length - 1));
  };

  return (
    <div>
      <Steps current={stepIdx} progressDot={stepMarker}>
        {STEPS.map((e: any, idx: number) => (
          <Step key={`step-${idx}`} title={e.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <CurrentStepComponent handlePrev={handlePrev} handleNext={handleNext} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const AlgoliaIntegrationPage = (props: IAlgoliaIntegrationProps) => {
  const { user } = props;

  const [algoliaConfig, setAlgoliaConfig] = useState<IAlgoliaConfig>({
    orgID: user.organization,
    indexes: {},
  });

  return (
    <Page section="Integrations" title="Algolia" description="Connect Algolia with Commandbar.">
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        <div style={{ background: '#fff' }}>
          <AlgoliaConfigProvider value={{ algoliaConfig, setAlgoliaConfig }}>
            <AlgoliaSetupSteps {...props} />
          </AlgoliaConfigProvider>
        </div>
      </Layout.Content>
    </Page>
  );
};

export default AlgoliaIntegrationPage;
