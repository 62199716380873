/** @jsx jsx */
import { type CSSObject, css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import type { IThemeV2Type } from '@commandbar/internal/middleware/types';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from './ButtonWithReset';
import StyledIconContainer from './StyledIconContainer';
import StyledPrimaryButton from './StyledPrimaryButton';

const getFeaturedCardStyles = (backgroundImage?: string): CSSObject => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  padding: '0px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textAlign: 'left',
  flex: '1 0 0',
  maxWidth: 'calc(50% - var(--layout-gap) / 2)',
  cursor: 'pointer',

  borderRadius: 'var(--layout-radius-button)',
  border: '1px solid var(--base-accent)',
  background: 'var(--base-accent)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  overflow: 'hidden',

  ...(backgroundImage
    ? {
        backgroundImage: `url('${encodeURI(backgroundImage)}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : {}),

  '&:hover': {
    border: '1px solid var(--border-primary-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
});

const loadingStyles: CSSObject = {
  background: 'linear-gradient(89deg, #d9d9d9 13.8%, #c9c9c9 30.92%, #d9d9d9 48.56%)',
};

const FeaturedTitle = styled.div`
  color: var(--button-primary-content);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  /* label/xl/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-tight);
`;

const FeaturedTitleWithGradient = styled.div`
  color: var(--button-primary-content);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* label/xl/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-tight);

  &:before {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: var(--layout-radius-card);
    content: '';
    background: linear-gradient(transparent calc(66.67% - 16px), var(--base-accent) calc(93.08% - 16px));
  }
`;

const FeaturedIconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  position: absolute;
  right: 12px;
  bottom: 12px;

  border-radius: 100px;
  background: var(--base-black);
`;

const FeaturedIcon = styled(StyledIconContainer)`
  color: var(--base-white);
`;

type HelpDocCardProps = {
  type: string;
  label: string;
  loading: boolean;
  backgroundImage?: string;
  icon: JSX.Element;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

export const StyledHelpDocCard = React.forwardRef<
  HTMLButtonElement,
  HelpDocCardProps & React.HTMLAttributes<HTMLButtonElement>
>(({ children, type, label, loading, backgroundImage, icon, themeV2, style, ...props }, ref) => {
  // we're using the primary button overrides for the HH featured card just for the box shadow styles
  // TEMPORARY -- this will be refactored upcoming
  const { lightModeOverridesObject, darkModeOverridesObject } = useComponentOverrides(
    StyledPrimaryButton.slug,
    themeV2,
  );
  const overrides = [
    css({
      '--form-control-shadow': lightModeOverridesObject['--form-control-shadow'],
      '--form-control-shadow-color': lightModeOverridesObject['--form-control-shadow-color'],
      '&:hover': {
        '--form-control-shadow': lightModeOverridesObject['&:hover']?.['--form-control-shadow'],
        '--form-control-shadow-color': lightModeOverridesObject['&:hover']?.['--form-control-shadow-color'],
      },
    }),
    themeV2.mode === 'dark_mode' &&
      css({
        '--form-control-shadow': darkModeOverridesObject['--form-control-shadow'],
        '--form-control-shadow-color': darkModeOverridesObject['--form-control-shadow-color'],
        '&:hover': {
          '--form-control-shadow': darkModeOverridesObject['&:hover']?.['--form-control-shadow'],
          '--form-control-shadow-color': darkModeOverridesObject['&:hover']?.['--form-control-shadow-color'],
        },
      }),
  ];

  return (
    <ButtonWithReset
      css={[overrides, css(getFeaturedCardStyles(backgroundImage)), loading && css(loadingStyles)].filter(Boolean)}
      ref={ref}
      style={style}
      {...props}
    >
      {!loading &&
        (backgroundImage ? (
          <FeaturedTitle>{label}</FeaturedTitle>
        ) : (
          <FeaturedTitleWithGradient>{label}</FeaturedTitleWithGradient>
        ))}
      <FeaturedIconContainer>
        <FeaturedIcon>{icon}</FeaturedIcon>
      </FeaturedIconContainer>
    </ButtonWithReset>
  );
});

export default StyledHelpDocCard;
