import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Spin } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  pointer-events: none;
  user-select: none;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #000;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled(Loading01)`
  animation: ${spin} 1.5s linear infinite;
`;

export const UploadingView = () => (
  <Container>
    <Spin spinning={true} indicator={<LoadingSpinner width={20} />} />
    Uploading...
  </Container>
);
