/** @jsx jsx  */
import React from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import { IThemeV2Type, IUserAttachmentType } from '@commandbar/internal/middleware/types';
import Dialog from 'rc-dialog';
import StyledTertiaryIconButton from '../shared/StyledTertiaryIconButton';
import { XClose } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding: var(--layout-padding);
  gap: var(--layout-gap);
  border-radius: var(--layout-radius-card);
  background: var(--background-primary);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const Title = styled.div`
  /* label/base/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);

  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  color: var(--content-secondary);

  /* label/sm/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);

  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledImage = styled.img`
  cursor: pointer;
  border-radius: var(--layout-radius-button);
  height: 32px;
  width: 32px;
`;

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode'; generatedCSSClassname: string };
  attachment: IUserAttachmentType;
};

const StyledCopilotAttachmentMessage = ({ themeV2, attachment }: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Container>
      <StyledImage onClick={() => setShowModal(true)} src={attachment.media_preview_url} />
      <TextContainer>
        <Title>{attachment.title}</Title>
        <Subtitle>{attachment.description}</Subtitle>
      </TextContainer>
      <Dialog
        visible={showModal}
        onClose={() => setShowModal(false)}
        aria-modal="true"
        wrapClassName={`commandbar-nudge-modal ${themeV2.generatedCSSClassname}`}
        bodyStyle={{
          background: 'var(--background-primary)',
          borderRadius: '0px',
          padding: '0px',
        }}
        style={{
          background: 'var(--background-primary)',
          border: 'none',
          borderRadius: '0px',
          margin: 'var(--layout-padding)',
          width: '35%',
        }}
        maskStyle={{ zIndex: Z.Z_MASK }}
        css={css`
          .rc-dialog-close {
            opacity: 1;
          }
          .rc-dialog-content {
            background: var(--background-primary);
            border-radius: 0;
          }
        `}
        closeIcon={
          <StyledTertiaryIconButton
            style={{
              position: 'fixed',
              top: 'var(--layout-padding)',
              right: 'var(--layout-padding)',
              color: 'var(--content-primary)',
            }}
            themeV2={themeV2}
            onClick={() => setShowModal(false)}
          >
            <XClose />
          </StyledTertiaryIconButton>
        }
      >
        <img
          style={{ width: '100%', height: 'fit-content' }}
          onClick={() => setShowModal(true)}
          src={attachment.media_preview_url}
          alt={attachment.media_preview_url}
        />
      </Dialog>
    </Container>
  );
};

export default StyledCopilotAttachmentMessage;
