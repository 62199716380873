import React, { useRef } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';
import useResizeObserver from './useResizeObserver';
import { FunnelData } from '@commandbar/internal/middleware/analytics/common';
import { AxisTop } from '@visx/axis';
import { GridColumns } from '@visx/grid';
import { Loading } from './loading';

const trimText = (text: string, threshold: number) => {
  if (text.length <= threshold) return text;
  return text.substring(0, threshold).concat('...');
};

export type CmdFunnelChartProps = {
  data: FunnelData | undefined;
  isLoading?: boolean;
};

// Chart
const chartAxisHeight = 25;
const numTicks = 5;
const minAxisCount = 10;

// Step title
const stepTitleWidth = 250;
const stepTitleLeftPadding = 10;

// Step count
const stepCountRightOffset = 15;

const CmdFunnelChart: React.FC<CmdFunnelChartProps> = ({ data, isLoading }) => {
  const resizeRef = useRef<HTMLDivElement>(null);
  const { width: parentWidth, height: parentHeight } = useResizeObserver<HTMLDivElement>(resizeRef, [isLoading, data]);

  if (isLoading || !data) {
    return <Loading />;
  }

  const height = data.steps.length * 56 + chartAxisHeight;

  const maxCount = Math.max(...data.steps.map((d) => d.count));

  const xScale = scaleLinear<number>({
    domain: [0, maxCount < minAxisCount ? minAxisCount : maxCount + 10],
    nice: true,
    range: [0, parentWidth - stepTitleWidth - stepTitleLeftPadding - 25],
  });

  const yScale = scaleBand<string>({
    domain: data.steps.map(({ label }, index) => label || `No title - ${index + 1}`),
    padding: 0.25,
    range: [chartAxisHeight, height],
  });

  return (
    <div ref={resizeRef} style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
      <svg width={parentWidth} height={Math.max(height, parentHeight)}>
        <Group left={0} top={0} width={parentWidth} height={height}>
          <GridColumns
            left={stepTitleWidth + stepTitleLeftPadding}
            top={chartAxisHeight}
            scale={xScale}
            height={Math.max(height, parentHeight) - chartAxisHeight}
            numTicks={numTicks}
          />

          {data.steps.map(({ count, label }, index) => {
            const labelWithFallback = label || `No title - ${index + 1}`;
            const barHeight = yScale.bandwidth();
            const barWidth = xScale(count);
            const barY = yScale(labelWithFallback) || 0 + chartAxisHeight;

            const showStepCountInBar = barWidth > 100;

            const stepCountX = showStepCountInBar
              ? barWidth - stepCountRightOffset + stepTitleWidth + stepTitleLeftPadding
              : barWidth + stepCountRightOffset + stepTitleWidth + stepTitleLeftPadding;
            const stepCountY = barY + barHeight / 2;

            return (
              <Group key={`bar-${index}`}>
                <StepNumber x={0} y={barY} width={barHeight} height={barHeight} index={index + 1} />

                <text
                  x={barHeight + stepTitleLeftPadding}
                  y={barY + barHeight / 2}
                  dy=".32em"
                  fontSize={14}
                  textAnchor="start"
                  fill="black"
                  fontWeight="500"
                >
                  {trimText(labelWithFallback, 22)}
                </text>

                <BarRounded
                  x={stepTitleWidth + stepTitleLeftPadding}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  radius={6}
                  right
                  className="fill-blue700"
                />

                {count > 0 && (
                  <text
                    x={stepCountX}
                    y={stepCountY}
                    dy=".32em"
                    fontSize={14}
                    textAnchor={showStepCountInBar ? 'end' : 'start'}
                    fill={showStepCountInBar ? 'white' : 'black'}
                    fontWeight="500"
                  >
                    {count.toLocaleString()}
                  </text>
                )}
              </Group>
            );
          })}

          <AxisTop
            scale={xScale}
            left={stepTitleWidth + stepTitleLeftPadding}
            numTicks={numTicks}
            labelOffset={10}
            orientation="bottom"
            stroke="black"
            tickStroke="black"
            tickLabelProps={() => ({
              fill: '#797C85',
              fontSize: 11,
              textAnchor: 'middle',
            })}
            tickFormat={(value) => `${value.toLocaleString()}`}
            hideAxisLine
            hideTicks
          />
        </Group>
      </svg>
    </div>
  );
};

const StepNumber: React.FC<{ x: number; y: number; width: number; height: number; index: number }> = ({
  x,
  y,
  width,
  height,
  index,
}) => {
  return (
    <Group>
      <rect x={x} y={y} width={width} height={height} fill="#FFF" stroke="#00000014" strokeWidth={1} rx="6" />
      <Text
        x={x + width / 2}
        y={y + height / 2}
        dy=".33em"
        textAnchor="middle"
        fontSize={14}
        fill="black"
        style={{
          pointerEvents: 'none',
        }}
        fontWeight="500"
      >
        {index}
      </Text>
    </Group>
  );
};

export { CmdFunnelChart };
