import { INamedRule, defaults } from '@commandbar/internal/middleware/helpers/rules';

export const builtInRules: INamedRule[] = [
  {
    ...defaults,
    is_audience: false,
    created: undefined,
    id: 'new-user',
    name: 'New users',
    expression: {
      type: 'AND',
      exprs: [
        {
          type: 'CONDITION',
          condition: {
            type: 'sessions',
            operator: 'isLessThan',
            value: '5',
          },
        },
      ],
    },
  },
  {
    ...defaults,
    is_audience: false,
    created: undefined,
    id: 'stale-user',
    name: 'Stale users',
    expression: {
      type: 'AND',
      exprs: [
        {
          type: 'CONDITION',
          condition: {
            type: 'last_seen',
            operator: 'isGreaterThan',
            value: '5',
          },
        },
      ],
    },
  },
];
