import { CmdInput, CmdLabel } from '@commandbar/design-system/cmd';
import React, { useState } from 'react';
import { useAppContext } from '../AppStateContext';
import { LOCALIZED_MESSAGE_DEFAULTS } from '@commandbar/internal/middleware/localizedMessage';
import styled from '@emotion/styled';

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const TextCustomizationInput = ({ label, msgid }: { label: string; msgid: string }) => {
  const { dispatch, localizedMessages } = useAppContext();
  const [value, setValue] = useState<string | undefined>(localizedMessages[msgid]);

  return (
    <InputBlock>
      <CmdLabel>{label}</CmdLabel>
      <CmdInput
        fullWidth
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onBlur={() => {
          if (value !== undefined) {
            dispatch.localizedMessages.update({
              msgstr: value,
              msgid,
            });
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.currentTarget.blur();
        }}
        placeholder={LOCALIZED_MESSAGE_DEFAULTS[msgid]}
      />
    </InputBlock>
  );
};

export default TextCustomizationInput;
