import React from 'react';
import { CmdTypography } from '../typography';
import { CmdEmptyGraph } from '../empty-graph';

const Loading = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CmdEmptyGraph height={44} width={55} />
      <CmdTypography.Body fontWeight="semi-bold">Loading your data</CmdTypography.Body>
      <CmdTypography.Body variant="secondary" fontSize="sm">
        This won't take too long
      </CmdTypography.Body>
    </div>
  );
};

export { Loading };
