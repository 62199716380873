import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { Col, Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { ConditionProps } from '../../types';
import { FirstSeenCondition, LastSeenCondition } from '@commandbar/internal/middleware/conditions';

export const FirstLastSeenCondition: React.FC<ConditionProps<FirstSeenCondition | LastSeenCondition>> = () => {
  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <ConditionValueInput />
      <Col>days&nbsp;ago</Col>
    </Row>
  );
};
