import { createContext, useContext } from 'react';
import { IAlgoliaConfig } from './utils';

interface IAlgoliaConfigContext {
  algoliaConfig: IAlgoliaConfig;
  setAlgoliaConfig: Function;
}

export const AlgoliaConfigContext = createContext<IAlgoliaConfigContext>({
  algoliaConfig: {
    orgID: '',
    indexes: {},
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAlgoliaConfig: () => {},
});
export const AlgoliaConfigProvider = AlgoliaConfigContext.Provider;
export const useAlgoliaConfigContext = () => useContext(AlgoliaConfigContext);
