import React from 'react';

import { Select } from '@commandbar/design-system/components/antd';
import { SelectProps } from 'antd/lib/select';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdStatusDot, CmdTooltip } from '@commandbar/design-system/cmd';
import { hasUnpublishedChanges } from 'editor/src/services/themes';

// value is the theme's ID
const ThemeSelect = (props: SelectProps<string>) => {
  const { themes } = useAppContext();

  return (
    <Select placeholder={'Select a theme...'} {...props}>
      {themes.map((theme) => {
        if (!theme.uuid) return null;
        if (theme.archived && props.value !== theme.uuid) return null;

        return (
          <Select.Option disabled={theme.archived} key={theme.uuid} value={theme.uuid}>
            {theme.name} {theme.archived ? ' (deleted) ⚠️' : ''}
            {hasUnpublishedChanges(theme) && (
              <CmdTooltip message="This theme has unpublished changes">
                <CmdStatusDot style={{ marginLeft: '8px' }} variant="warning" />
              </CmdTooltip>
            )}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default ThemeSelect;
