/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';

import { Heading, Input, Radio } from '@commandbar/design-system/components/antd';
/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  onNameChange: (e: any) => void;
  canEdit: boolean;
  name: string;
  id: number;
  action: React.ReactNode;
  showRadio?: boolean;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

export const ContextPanelHeader = (props: IProps) => {
  const { showRadio = true } = props;

  if (!props.canEdit) {
    return (
      <Heading
        text={
          <span>
            {showRadio && (
              <span onClick={(e) => e.stopPropagation()}>
                <Radio value={props.id} />
              </span>
            )}
            <span style={{ lineHeight: '24px', marginLeft: 8 }}>{props.name}</span>
          </span>
        }
        rightActions={<div style={{ display: 'flex', marginLeft: 10, marginRight: 32 }}>{props.action}</div>}
      />
    );
  } else {
    return (
      <Heading
        text={
          <span onClick={(e) => e.stopPropagation()}>
            {showRadio && (
              <span onClick={(e) => e.stopPropagation()}>
                <Radio value={props.id} />
              </span>
            )}
            <Input
              onKeyDown={(e) => e.stopPropagation()}
              size="small"
              placeholder="Context Name"
              defaultValue={props.name}
              onChange={props.onNameChange}
              style={{ maxWidth: 'calc(100% - 60px)' }}
            />
          </span>
        }
        rightActions={<div style={{ display: 'flex', marginLeft: 10, marginRight: 32 }}>{props.action}</div>}
      />
    );
  }
};
