// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Rocket01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9.248 7.904a1.001 1.001 0 0 0-.13-.054L6.38 6.938a1 1 0 0 0-.962.185L2.561 9.541a1 1 0 0 0 .3 1.701l2.426.894a.97.97 0 0 0 .3.06 4.64 4.64 0 0 1 .859-1.297l2.802-2.995Zm2.465 10.435a.998.998 0 0 0 .057.256l.909 2.465a1 1 0 0 0 1.701.3l2.418-2.857a1 1 0 0 0 .185-.962l-.823-2.47a1.002 1.002 0 0 0-.2-.346l-2.938 2.75a4.66 4.66 0 0 1-1.31.864ZM17.5 6.393a26.78 26.78 0 0 0-2.238-1.97c-.528-.418-.792-.627-.83-.804a.484.484 0 0 1 .111-.43c.118-.137.44-.19 1.084-.298l3.721-.62a2 2 0 0 1 2.302 2.301l-.62 3.717c-.107.646-.16.969-.297 1.087a.483.483 0 0 1-.431.11c-.176-.036-.387-.302-.807-.832a27.256 27.256 0 0 0-1.994-2.26Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13 11-9.5 9.5M14.018 3.538a22.613 22.613 0 0 1 3.483 2.855 22.598 22.598 0 0 1 2.876 3.515M9.255 7.896 6.38 6.938a1 1 0 0 0-.962.185L2.56 9.541a1 1 0 0 0 .3 1.701l2.707.998m6.113 6.112.997 2.708a1 1 0 0 0 1.702.3l2.418-2.857a1 1 0 0 0 .185-.962l-.958-2.875M19.349 2.27l-4.907.817a2.443 2.443 0 0 0-1.383.741L6.446 10.9a4.652 4.652 0 0 0 6.576 6.575l7.07-6.613c.392-.367.652-.853.74-1.382l.818-4.907a2 2 0 0 0-2.301-2.301Z"
    />
  </svg>
);
export default Rocket01;
