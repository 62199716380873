import React from 'react';

import { Input, Typography } from '@commandbar/design-system/components/antd';

import {
  IEditorAvailabilityRule,
  isCompoundExpression,
  isMultiValueRule,
  isInteractionCondition,
} from '@commandbar/internal/middleware/helpers/rules';
import { IRuleExpression } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { invertAvailabilityRuleOperator } from 'editor/src/editor/conditions/operators';
import { builtInRules } from '../../../RulesTab/builtInRulesDefintions';

const RuleNotMatchingText = ({ rule }: { rule: IEditorAvailabilityRule }) => {
  const { rules } = useAppContext();

  const displayType: { [key in IEditorAvailabilityRule['type']]: string } = {
    audience: 'the audience',
    user_property: 'the user property',
    event_property: 'the event property',
    context: '',
    url: 'the current URL',
    hostname: 'the current Domain',
    element: 'there is a DOM element with',
    named_rule: 'rule',
    executions: 'the users number of executed commands',
    shortcuts: 'the users number of executed shortcuts',
    last_seen: 'the users last seen at',
    first_seen: 'the users first seen at',
    sessions: 'the users number of sessions',
    opens: 'the users number of searches',
    deadends: 'the users number of deadends',
    heap: 'the Heap Segment the user is in',
    hubspot: 'the HubSpot List the user is in',
    amplitude: 'the Amplitude Cohort the user is in',
    nudge_interaction: 'the Nudge for the user',
    questlist_interaction: 'the Checklist for the user',
    help_doc_interaction: 'the article or file for the user',
    video_interaction: 'the video for the user',
    browser: "the user's browser",
    os: "the user's operating system of their computer",
    language: "the user's language configured in the browser",
    device_type: 'if the user is on a mobile device or desktop',
    ab_test: 'the A/B test group',
    intent: "the user's search history",
    survey_response: "the user's survey response",
  };

  const type = displayType[rule.type];

  if (rule.type === 'named_rule') {
    return (
      <>
        {type}{' '}
        <i style={{ textDecoration: 'underline' }}>
          {rules.find(({ id }) => id === rule.rule_id)?.name ||
            builtInRules.find(({ id }) => id === rule.rule_id)?.name ||
            '<unknown>'}
        </i>{' '}
        does not match
      </>
    );
  }

  const { operator } = rule;

  const valueDisplay = () => {
    if (isMultiValueRule(rule)) {
      return rule.values.join(', ');
    } else if (operator === 'isTrue' || operator === 'isFalse') {
      return;
    }

    return rule.value;
  };

  return (
    <>
      {type} {rule.type === 'context' ? rule.field : null}{' '}
      {invertAvailabilityRuleOperator(operator, isInteractionCondition(rule))} {valueDisplay()}
    </>
  );
};

const ReasonsEditor = ({
  expr,
  disabled = false,
  onChange,
}: {
  expr: IRuleExpression;
  disabled?: boolean;
  onChange: (expr: IRuleExpression) => void;
}) => {
  if (isCompoundExpression(expr)) {
    const updateByIndex = (index: number) => (e: IRuleExpression) => {
      onChange({
        ...expr,
        exprs: [...expr.exprs.slice(0, index), e, ...expr.exprs.slice(index + 1)],
      });
    };

    return (
      <div>
        {expr.exprs.map((e, index) => (
          <ReasonsEditor key={index} disabled={disabled} expr={e} onChange={updateByIndex(index)} />
        ))}
      </div>
    );
  }
  if (expr.type === 'CONDITION') {
    const condition = expr.condition;
    return (
      <div>
        <Typography.Text strong>If </Typography.Text>

        <Typography.Text style={{ background: '#e6e6ff' }}>
          <RuleNotMatchingText rule={condition} />
        </Typography.Text>
        <Input
          disabled={disabled}
          placeholder="(optional) enter a reason to display"
          value={condition.reason}
          onChange={(e) => {
            onChange({ ...expr, condition: { ...condition, reason: e.target.value } });
          }}
          style={{ width: '100%' }}
        />
      </div>
    );
  }

  return null;
};

export default ReasonsEditor;
