/** @jsx jsx  */
/** @jsxFrag */
import React from 'react';
import CodeMirror from 'codemirror';
import { Controlled as CodeMirrorComponent } from 'react-codemirror2';
import { css, jsx } from '@emotion/core';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/lib/codemirror.css';
import './AutoCompleteTextArea.css';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { applyFormatting } from './applyFormatting';
import { CB_COLORS } from '@commandbar/design-system/components/antd/colors';

interface IProps {
  onChange: (e: string) => void;
  options: { value: string; addOn: string }[];
  value: string | undefined;
  placeholder?: string;
  // A ref to a container can be provided to render the list of suggestions
  // on an open modal
  container?: HTMLElement | null;
  enableFormatting?: boolean;
  small?: boolean;
  defaultStyles?: boolean;
  error?: boolean;
  errorMessage?: string;
  codeEditor?: boolean;
}

const AutocompleteTextArea = (props: IProps) => {
  const [localValue, setLocalValue] = React.useState<string>(props.value || '');
  React.useEffect(() => {
    setLocalValue(props.value || '');
  }, [props.value]);

  const delayedOnChange = React.useCallback(
    _.debounce((value: string) => {
      props.onChange(value);
    }, 100),
    [props.onChange],
  );

  const wrapper = React.useRef<{ [current: string]: any }>();

  // Filter the hints based on the user's input
  const filterList = (token: any) => {
    const tokenStr = token.string;
    const search = tokenStr.replace('{{', '').toLowerCase();
    return [
      ...props.options
        .filter((t) => !search.length || t.value.toLowerCase().includes(search))
        .map((t) => ({
          text: t.value,
          render: (el: any) => {
            const toRender = (
              <div className="hint-item">
                <span>{t.value}</span>
                <span className="hint-addon">{t.addOn}</span>
              </div>
            );
            ReactDOM.render(toRender, el);
          },
          // replace the full token. By default it appends the choice to the current string
          hint: (_editor: any, _self: any, _data: any) => {
            replaceToken(token, `{{${t.value}`);
          },
        })),
    ];
  };

  // Replaces a token in the textarea with the hint. By default it appends to the token
  const replaceToken = (token: any, newStr: string) => {
    const pos = wrapper.current?.getCursor(); // or {line , ch };
    wrapper.current?.replaceRange(newStr, { line: pos.line, ch: token.start }, { line: pos.line, ch: token.end });
  };

  // Mode that puts {{<word> into it's own token
  CodeMirror.defineMode('words', function () {
    return {
      token: function (stream, _state) {
        if (stream.match(/\{\{\w*/)) return 'variable';
        stream.next();
        return null;
      },
    };
  });

  // Trigger the dropdown hint menu
  const showHint = (_t: string) => {
    if (wrapper.current) {
      CodeMirror.showHint(
        wrapper.current as CodeMirror.Editor,
        function () {
          const token = wrapper.current?.getTokenAt((wrapper.current as CodeMirror.Editor).getCursor());
          return {
            from: (wrapper.current as CodeMirror.Editor).getCursor(),
            to: (wrapper.current as CodeMirror.Editor).getCursor(),
            list: filterList(token),
          };
        },
        {
          completeSingle: false,
          container: props.container,
        },
      );
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
      <CodeMirrorComponent
        css={
          props.defaultStyles
            ? undefined
            : css`
                .CodeMirror {
                  --codemirror-padding: ${props.small ? '4px' : '8px'};
                  background: ${props.error
                    ? 'rgba(199, 58, 39, 0.04)'
                    : props.codeEditor
                    ? '#24282c'
                    : CB_COLORS.white};
                  box-shadow: ${props.error ? '0px 0px 0px 3px rgba(199, 58, 39, 0.16)' : 'none'};
                  border-color: ${props.error ? '#C73A27' : CB_COLORS.neutral500};
                  ${props.codeEditor ? `color: ${CB_COLORS.neutral100};` : ''}
                  ${props.codeEditor ? 'font-family: monospace; min-height: 140px;' : ''}
                }
              `
        }
        editorDidMount={(editor) => {
          wrapper.current = editor;
        }}
        onBeforeChange={(editor: any, data: any, value: string) => {
          // See the change inside controlled component immediately
          setLocalValue(value);
          // But update the value on top component after a while to avoid input lag
          // onBlur won't work with state correctly since it's a native DOM event
          delayedOnChange(value);
        }}
        onChange={(_editor: any, _data: any, _value: string) => {
          const token = wrapper.current?.getTokenAt((wrapper.current as CodeMirror.Editor).getCursor());
          // Show a hint if we're starting the token or backspaced after it's started
          if (token.string === '{{' || (token.removed?.length && token.string.length > 2)) {
            showHint(token.string);
          }
        }}
        value={localValue}
        options={{
          mode: 'words',
          lineNumbers: false,
          lineWrapping: true,
          readOnly: false,
          autoCloseBrackets: true,
          placeholder: props.placeholder,

          // This option fixes the cursor positioning in case of line wrap
          inputStyle: 'contenteditable',
          extraKeys: {
            ...{ 'Shift-Tab': false, Tab: false },
            ...(props.enableFormatting
              ? {
                  'Cmd-B': (editor) => applyFormatting(editor, 'bold'),
                  'Ctrl-B': (editor) => applyFormatting(editor, 'bold'),
                  'Cmd-I': (editor) => applyFormatting(editor, 'italic'),
                  'Ctrl-I': (editor) => applyFormatting(editor, 'italic'),
                }
              : {}),
          },
        }}
      />
      {props.error && props.errorMessage && (
        <span
          style={{
            color: 'rgb(199, 58, 39)',
            fontSize: '12px',
          }}
        >
          {props.errorMessage}
        </span>
      )}
    </div>
  );
};

export default AutocompleteTextArea;
