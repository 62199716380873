import * as t from 'io-ts';

export const ChecklistInteractionStateV = t.intersection([
  t.partial({
    isSkipped: t.boolean,
    isCompleted: t.boolean,
    isExpanded: t.boolean,
    isSeen: t.boolean,
    items: t.record(
      t.string,
      t.type({
        completedTS: t.string,
      }),
    ),
  }),
  t.partial({}),
]);
export type ChecklistInteractionState = t.TypeOf<typeof ChecklistInteractionStateV> & unknown;

export const NudgeInteractionStateV = t.intersection([
  t.partial({
    currentStep: t.number,
    nudgeSeen: t.boolean,
    nudgeInteracted: t.boolean,
    seenTs: t.array(t.number),
    snoozedUntilTs: t.number,
    nudgeDismissed: t.boolean,
    nudgeCompleted: t.boolean,
    nudgeDismissedTs: t.array(t.number),
    nudgeCompletedTs: t.array(t.number),
  }),
  t.partial({
    stepIndexStack: t.array(t.number),
  }),
]);
export type NudgeInteractionState = t.TypeOf<typeof NudgeInteractionStateV> & unknown;

export const ChecklistInteractionsV = t.record(t.number, ChecklistInteractionStateV);
export type ChecklistInteractions = t.TypeOf<typeof ChecklistInteractionsV> & unknown;

export const NudgeInteractionsV = t.record(t.number, NudgeInteractionStateV);
export type NudgeInteractions = t.TypeOf<typeof NudgeInteractionsV> & unknown;

export const UserRemotePropertiesV = t.record(t.string, t.any);
export type IUserRemoteProperties = t.TypeOf<typeof UserRemotePropertiesV> & unknown;

export const EndUserStoreDataV = t.type({
  hotkeys: t.any,
  checklist_interactions: t.partial({
    activeChecklistId: t.union([t.number, t.null]),
    checklists: ChecklistInteractionsV,
  }),
  nudges_interactions: t.union([NudgeInteractionsV, t.undefined]),
  analytics: t.union([
    t.type({
      num_command_executions: t.number,
      num_shortcut_command_executions: t.number,
      num_sessions: t.number,
      num_opens: t.number,
      num_deadends: t.number,
      first_seen_at: t.string,
      last_seen_at: t.string,
    }),
    t.undefined,
    t.null,
  ]),
  properties: UserRemotePropertiesV,
});
export type IEndUserStoreData = t.TypeOf<typeof EndUserStoreDataV> & unknown;

export const EndUserV = t.type({
  username: t.string,
  slug: t.string,
  organization: t.string,
  has_access: t.boolean,
  has_opened: t.boolean,
  hotkeys_debug: t.boolean,
  hmac: t.union([t.string, t.undefined]),
  identifier: t.string,
});
export type IEndUserType = t.TypeOf<typeof EndUserV> & unknown;

export const DecideExperienceResponseV = t.record(
  t.union([t.string, t.number]),
  t.union([t.boolean, t.record(t.string, t.any), t.undefined, t.null]),
);

export const DecideResponseV = t.union([
  t.type({
    user_property_targeting: t.union([t.boolean, t.undefined, t.null]),
    nudges: t.union([DecideExperienceResponseV, t.undefined, t.null]),
    checklists: t.union([DecideExperienceResponseV, t.undefined, t.null]),
    recommendation_sets: t.union([DecideExperienceResponseV, t.undefined, t.null]),
    commands: t.union([DecideExperienceResponseV, t.undefined, t.null]),
  }),
  t.undefined,
  t.null,
]);
export type IDecideResponseType = t.TypeOf<typeof DecideResponseV> & unknown;
