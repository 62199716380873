/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';

const styles: CSSObject = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: 'var(--layout-gap)',
  color: 'var(--content-primary)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  /* label/base/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-background)',
    outline: 'none',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&::placeholder': {
    color: 'var(--content-secondary)',
  },
};

const slug = 'input';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const InputWithReset = styled.input`
  all: unset;
  box-sizing: border-box;
`;

const StyledBaseInput = Object.assign(
  React.forwardRef<HTMLInputElement, Props & React.InputHTMLAttributes<HTMLInputElement>>(
    ({ themeV2, ...props }, ref) => {
      const { overrides } = useComponentOverrides(slug, themeV2);

      return <InputWithReset css={[overrides, css(styles)]} ref={ref} {...props} />;
    },
  ),
  {
    slug: slug,
    styles: styles,
    renderMock: (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
      <StyledInputMock themeV2={themeV2} mode={mode} />
    ),
  },
);

const StyledInputMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2: themeV2, mode: mode });
  return <InputWithReset css={[overrides, css(styles)]} placeholder="Input" />;
};

export default StyledBaseInput;
