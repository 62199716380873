import { AnalyticsV2 } from './client';
import { getSDK } from '@commandbar/internal/client/globals';
import { _analytics } from '@commandbar/internal/client/symbols';
import { CBStore, HelpHubDoc } from '@commandbar/commandbar/shared/store/global-store';
import { CBEventSource } from './schema';
import { serializeObject } from './serializers';
import { Option } from '@commandbar/commandbar/products/spotlight/types';
import type { Action } from '@cb/types/entities/command/actions';
import type {
  IAIMessageType,
  IChecklist,
  IChecklistItem,
  IExperienceHitType,
  INudgeType,
  IUserMessageType,
} from '@commandbar/internal/middleware/types';
import { NudgeStepButton, SurveyResponseEvent } from '../EventHandler';
import type { IDecideResponseType, IUserRemoteProperties } from '@cb/types/entities/endUser';
import { Context as CopilotContext } from '@commandbar/commandbar/products/copilot/service-actions';
import { getNudgeStep } from '@commandbar/internal/util/nudges';

const getClient = (): AnalyticsV2 | undefined => {
  const sdk = getSDK();
  return sdk?.[_analytics];
};

export const Track = {
  identify: (userProperties?: IUserRemoteProperties) => {
    // Values for user property and ID are added via automatic enrichments
    getClient()?.trackCBEvent?.(
      'cb:identify',
      {},
      {
        end_user_properties: userProperties,
      },
      true,
    );
  },
  decide: (decideResult: IDecideResponseType, userProperties: IUserRemoteProperties) => {
    getClient()?.trackCBEvent?.(
      'cb:decide',
      {},
      { decide: decideResult || undefined, end_user_properties: userProperties },
      true,
    );
  },
  internal: {
    sdkMethodCalled: (method: string /* TODO: Add some sort of args tracking */) => {
      getClient()?.trackCBEvent?.('cb:internal:sdk_method_called', { method });
    },
  },
  // NOTE: The conditional chaining on `trackCBEvent` is needed because of some very old spotlight tests that only partially initialize the SDK
  spotlight: {
    /**
     * Fired whenever Spotlight is opened.
     * @param spotlightState The current state of the spotlight when it was opened.
     * @param source The source of the event.
     */
    opened: (spotlightState: CBStore['spotlight'], source?: CBEventSource) => {
      getClient()?.trackCBEvent?.(
        'cb:spotlight_opened',
        {},
        {
          spotlight: serializeObject(spotlightState),
          source,
        },
      );
    },

    /**
     * Fired whenever Spotlight is closed.
     * @param spotlightState The current state of the spotlight when it was closed.
     */
    closed: (spotlightState: CBStore['spotlight'], inputText?: string) => {
      getClient()?.trackCBEvent?.(
        'cb:spotlight_closed',
        {
          inputText, // TODO: Fill this in
        },
        {
          spotlight: serializeObject(spotlightState),
        },
      );
    },

    /**
     * Fired whenever input is typed into the search bar in Spotlight. These are debounced to once every 500ms.
     * @param spotlightState The current state of the spotlight when the search input was typed.
     */
    searchInput: (spotlightState: CBStore['spotlight'], inputText: string) => {
      getClient()?.trackCBEvent?.(
        'cb:spotlight_search_input',
        {
          inputText,
        },
        {
          spotlight: serializeObject(spotlightState),
        },
      );
    },

    /**
     * Fired whenever a search is executed in Spotlight.
     * @param spotlightState The current state of the spotlight when the search was executed.
     */
    searchExecuted: (spotlightState: CBStore['spotlight']) => {
      getClient()?.trackCBEvent?.('cb:spotlight_search_executed', {}, { spotlight: serializeObject(spotlightState) });
    },

    /**
     * Fired whenever a search result is clicked in Spotlight.
     * @param spotlightState The current state of the spotlight when the search result was clicked.
     * @param result The result that was clicked.
     */
    searchResultClicked: (spotlightState: CBStore['spotlight'], option: Option) => {
      getClient()?.trackCBEvent?.(
        'cb:spotlight_search_result_clicked',
        {
          option: serializeObject(option),
        },
        { spotlight: serializeObject(spotlightState) },
      );
    },
  },

  nudge: {
    /**
     * Properties that will be included on all nudge events.
     */
    _getCommonProperties: (nudge: INudgeType, stepIndex: number) => {
      const nudgeStep = getNudgeStep(nudge, stepIndex);
      const isLastStep = stepIndex === nudge.steps.length - 1;

      return {
        nudgeType: nudge.type || 'unknown',
        nudgeId: nudge.id,
        nudgeStepId: nudgeStep?.id,
        isLastStep,
      };
    },

    /**
     * Fired whenever a nudge is viewed.
     * @param nudge The nudge that was viewed.
     * @param nudgeStep The step of the nudge that was viewed.
     * @param source The source that triggered the event, if applicable.
     */
    viewed: (nudge: INudgeType, stepIndex: number, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('cb:nudge_viewed', Track.nudge._getCommonProperties(nudge, stepIndex), {
        source,
        nudge: serializeObject(nudge),
      });
    },

    /**
     * Fired whenever a nudge is dismissed.
     * @param nudge The nudge that was dismissed.
     * @param nudgeStep The step of the nudge that was dismissed.
     */
    dismissed: (nudge: INudgeType, stepIndex: number) => {
      getClient()?.trackCBEvent?.('cb:nudge_dismissed', Track.nudge._getCommonProperties(nudge, stepIndex), {
        nudge: serializeObject(nudge),
      });
    },

    /**
     * Fired whenever a nudge is snoozed.
     * @param nudge The nudge that was snoozed.
     * @param nudgeStep The step of the nudge that was snoozed.
     */
    snoozed: (nudge: INudgeType, stepIndex: number) => {
      getClient()?.trackCBEvent?.('cb:nudge_snoozed', Track.nudge._getCommonProperties(nudge, stepIndex), {
        nudge: serializeObject(nudge),
      });
    },

    /**
     * Fired whenever the CTA of a nudge is clicked.
     * @param nudge The nudge that was clicked.
     * @param nudgeStep The step of the nudge that was clicked.
     */
    ctaClicked: (nudge: INudgeType, stepIndex: number, button: NudgeStepButton | undefined) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_cta_clicked',
        {
          ...Track.nudge._getCommonProperties(nudge, stepIndex),
          button: button ? serializeObject(button) : undefined,
        },
        {
          nudge: serializeObject(nudge),
        },
      );
    },

    /**
     * Fired whenever a survey is submitted.
     * @param nudge The nudge that the survey was submitted for.
     * @param nudgeStep The step of the nudge that the survey was submitted for.
     * @param response The response to the survey.
     */
    surveySubmitted: (nudge: INudgeType, stepIndex: number, response: SurveyResponseEvent['response']) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_survey_submitted',
        {
          ...Track.nudge._getCommonProperties(nudge, stepIndex),
          response: {
            type: response.type,
            value: response.value,
            max: 'max' in response ? response.max : undefined,
          },
        },
        {
          nudge: serializeObject(nudge),
        },
      );
    },
  },

  checklist: {
    /**
     * Properties that will be included on all nudge events.
     */
    _getCommonProperties: (checklist: IChecklist, item?: IChecklistItem, hasIncompleteItems?: boolean) => {
      // NOTE: We use the same properties as nudges because we are moving towards treating checklists as a specific type of nudge.
      return {
        nudgeType: 'checklist',
        nudgeId: checklist.id,
        nudgeStepId: item?.id,
        hasIncompleteItems,
      };
    },

    /**
     * Fired whenever a checklist is viewed.
     * @param checklist The checklist that was viewed.
     * @param source The source that triggered the event, if applicable.
     */
    viewed: (checklist: IChecklist, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('cb:nudge_viewed', Track.checklist._getCommonProperties(checklist), {
        source,
        nudge: serializeObject(checklist),
      });
    },

    /**
     * Fired whenever a checklist is dismissed, or when the 'Done' button is clicked and all steps are completed/skipped.
     * @param checklist The checklist that was dismissed.
     */
    dismissed: (checklist: IChecklist, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_dismissed',
        Track.checklist._getCommonProperties(checklist, undefined, hasIncompleteItems),
        {
          nudge: serializeObject(checklist),
        },
      );
    },

    /**
     * Fired whenever all the items of a checklist have been skipped or completed.
     * @param checklist The checklist that was completed.
     */
    completed: (checklist: IChecklist) => {
      getClient()?.trackCBEvent?.('cb:nudge_checklist_completed', Track.checklist._getCommonProperties(checklist), {
        nudge: serializeObject(checklist),
      });
    },

    /**
     * Fired whenever a checklist item is skipped.
     * @param checklist The checklist that was dismissed.
     * @param item The item that was skipped.
     */
    itemSkipped: (checklist: IChecklist, item: IChecklistItem, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_checklist_item_skipped',
        Track.checklist._getCommonProperties(checklist, item, hasIncompleteItems),
        {
          nudge: serializeObject(checklist),
        },
      );
    },

    /**
     * Fired whenever a checklist item is completed.
     * @param checklist The checklist that was dismissed.
     * @param item The item that was completed.
     */
    itemCompleted: (checklist: IChecklist, item: IChecklistItem, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_checklist_item_completed',
        Track.checklist._getCommonProperties(checklist, item, hasIncompleteItems),
        {
          nudge: serializeObject(checklist),
        },
      );
    },

    /**
     * Fired whenever the CTA of a checklist is clicked.
     * @param nudge The nudge that was clicked.
     * @param nudgeStep The step of the nudge that was clicked.
     */
    ctaClicked: (checklist: IChecklist, item: IChecklistItem) => {
      getClient()?.trackCBEvent?.(
        'cb:nudge_cta_clicked',
        {
          ...Track.checklist._getCommonProperties(checklist, item),
        },
        {
          nudge: serializeObject(checklist),
        },
      );
    },
  },

  assistance: {
    /**
     * Fired whenever the assistance modal is opened.
     * @param source The source of the event.
     */
    modalOpened: (source?: CBEventSource) => {
      getClient()?.trackCBEvent('cb:assistance_modal_opened', {}, { source });
    },

    /**
     * Fired whenever the assistance modal is closed.
     */
    modalClosed: () => {
      getClient()?.trackCBEvent('cb:assistance_modal_closed');
    },
  },

  copilot: {
    /**
     * Properties that will be included on all Copilot events.
     * @param copilotState The current state of Copilot when the event was fired.
     * @returns The properties to include in the event.
     */
    _getCommonProperties: (chatId: string) => {
      return {
        chatId,
      };
    },

    /**
     * Fired whenever Copilot is opened.
     * @param source The source of the event.
     */
    viewed: (source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('cb:copilot_viewed', {}, { source });
    },

    /**
     * Fired whenever a Copilot chat is started.
     * @param copilotState The current state of Copilot when the chat was started.
     */
    chatStarted: (chatId: string, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('cb:copilot_chat_started', Track.copilot._getCommonProperties(chatId), {
        source,
      });
    },

    /**
     * Fired whenever a Copilot chat is restarted.
     * @param copilotState The current state of Copilot when the chat was restarted.
     */
    chatRestarted: (chatId: string, copilotState: CopilotContext) => {
      getClient()?.trackCBEvent?.('cb:copilot_chat_restarted', Track.copilot._getCommonProperties(chatId), {
        copilot: serializeObject(copilotState),
      });
    },

    /**
     * Fired whenever a Copilot chat is ended.
     * @param message The message that was sent to the chat.
     */
    chatMessageSent: (chatId: string, copilotState: CopilotContext, message: IUserMessageType | IAIMessageType) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_chat_message_sent',
        {
          ...Track.copilot._getCommonProperties(chatId),
          message: serializeObject(message),
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },

    /**
     * Fired whenever the feedback button is clicked in Copilot.
     * @param copilot The current state of Copilot when the feedback button was clicked.
     * @param feedback The feedback that was submitted.
     */
    chatMessageFeedback: (chatId: string, messageId: string, copilotState: CopilotContext, feedback: -1 | 1) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_chat_message_feedback',
        {
          ...Track.copilot._getCommonProperties(chatId),
          messageId,
          feedback: feedback.toString(),
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },

    /**
     * Fired whenever a suggested follow-up is clicked in Copilot.
     * @param copilot The current state of Copilot when the follow-up was clicked.
     */
    followUpClicked: (chatId: string, copilotState: CopilotContext, followUp: string) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_follow_up_clicked',
        {
          ...Track.copilot._getCommonProperties(chatId),
          followUp,
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },

    /**
     * Fired whenever a source is clicked in a Copilot message.
     * @param copilot The current state of Copilot when the source was clicked.
     */
    sourceClicked: (chatId: string, messageId: string, copilotState: CopilotContext, href: string) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_source_clicked',
        {
          ...Track.copilot._getCommonProperties(chatId),
          messageId,
          href,
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },

    /**
     * Fired whenever a link is clicked in a Copilot message.
     * @param copilot The current state of Copilot when the link was clicked.
     */
    linkClicked: (chatId: string, messageId: string, copilotState: CopilotContext, href: string) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_link_clicked',
        {
          ...Track.copilot._getCommonProperties(chatId),
          messageId,
          href,
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },

    /**
     * Fired whenever an experience suggestion is clicked in Copilot.
     * @param copilot The current state of Copilot when the message was scrolled.
     */
    experienceClicked: (chatId: string, copilotState: CopilotContext, experience: any) => {
      getClient()?.trackCBEvent?.(
        'cb:copilot_experience_clicked',
        {
          ...Track.copilot._getCommonProperties(chatId),
          experience,
        },
        {
          copilot: serializeObject(copilotState),
        },
      );
    },
  },

  helphub: {
    /**
     * Fired whenever HelpHub is viewed.
     * @param helpHubState The current state of HelpHub when it was viewed.
     * @param source The source of the event.
     * @returns void
     */
    viewed: (helpHubState: CBStore['helpHub'], source?: CBEventSource) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_viewed',
        {},
        {
          helphub: serializeObject(helpHubState),
          content: serializeObject(helpHubState.hubDoc),
          source,
        },
      );
    },

    /**
     * Fired whenever input is typed into the search bar in HelpHub. These are debounced to once every 500ms.
     * @param helpHubState The current state of HelpHub when the search input was typed.
     * @returns void
     */
    searchInput: (helpHubState: CBStore['helpHub'], inputText: string) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_search_input',
        {
          inputText,
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },

    /**
     * Fired whenever a search is executed in HelpHub.
     * @param helpHubState The current state of HelpHub when the search is executed.
     * @returns void
     */
    searchExecuted: (helpHubState: CBStore['helpHub'], inputText: string) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_search_executed',
        {
          inputText,
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },

    /**
     * Fired whenever a search result is clicked in HelpHub.
     * @param helpHubState The current state of HelpHub when the search result is clicked.
     * @param target The search result that was clicked.
     * @returns void
     */
    searchResultClicked: (helpHubState: CBStore['helpHub'], result: HelpHubDoc | IExperienceHitType) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_search_result_clicked',
        {
          result: serializeObject(result),
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },

    /**
     * Fired whenever a suggested query is clicked in HelpHub.
     * @param helpHubState The current state of HelpHub when the suggested query is clicked.
     * @param query The suggested query that was clicked.
     * @returns void
     */
    suggestedQueryClicked: (helpHubState: CBStore['helpHub'], query: string) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_suggested_query_clicked',
        {
          query,
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },

    /**
     * Fired whenever the link to a document in HelpHub is copied.
     * @param helpHubState The current state of HelpHub when the document link is copied.
     * @param href The URL of the document that was copied.
     * @returns void
     */
    docLinkCopied: (helpHubState: CBStore['helpHub'], href: string) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_doc_link_copied',
        {
          href,
        },
        {
          helphub: serializeObject(helpHubState),
          content: serializeObject(helpHubState.hubDoc),
        },
      );
    },

    /**
     * Fired whenever an item in a recommendation set is clicked in HelpHub.
     * @param helpHubState The current state of HelpHub when the recommendation is clicked.
     * @param recommendation The recommendation that was clicked.
     */
    recommendationClicked: (helpHubState: CBStore['helpHub'], recommendation: Action) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_recommendation_clicked',
        {
          recommendation: serializeObject(recommendation),
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },

    /**
     * Fired whenever an item in the additional resources section is clicked in HelpHub.
     * @param helpHubState The current state of HelpHub when the additional resource is clicked.
     * @param resource The additional resource that was clicked.
     */
    additionalResourceClicked: (helpHubState: CBStore['helpHub'], resource: Action) => {
      getClient()?.trackCBEvent?.(
        'cb:helphub_additional_resource_clicked',
        {
          resource: serializeObject(resource),
        },
        {
          helphub: serializeObject(helpHubState),
        },
      );
    },
  },

  content: {
    _getCommonProperties: (helpHubState: CBStore['helpHub']) => {
      return {
        docType: helpHubState.hubDoc?.type,
        docId: helpHubState.hubDoc?.command.id,
      };
    },

    /**
     * Fired whenever a piece of content is viewed. As of right now, this is only used for HelpHub.
     * @param helpHubState The current state of HelpHub when the content was viewed. This includes the current document.
     * @param source The source of where the document was opened from - if applicable.
     */
    viewed: (helpHubState: CBStore['helpHub'], source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('cb:content_viewed', Track.content._getCommonProperties(helpHubState), {
        helphub: serializeObject(helpHubState),
        content: serializeObject(helpHubState.hubDoc),
        source,
      });
    },

    /**
     * Fired whenever a piece of content is closed or navigated away from. As of right now, this is only used for HelpHub.
     * @param helpHubState The current state of HelpHub when the content was closed. This includes the current document.
     */
    closed: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('cb:content_closed', Track.content._getCommonProperties(helpHubState), {
        helphub: serializeObject(helpHubState),
        content: serializeObject(helpHubState.hubDoc),
      });
    },

    /**
     * Fired whenever a link in a piece of content is clicked.
     * @param helpHubState The current state of HelpHub when the link was clicked. This includes the current document.
     */
    linkClicked: (helpHubState: CBStore['helpHub'], href: string) => {
      getClient()?.trackCBEvent?.(
        'cb:content_link_clicked',
        {
          ...Track.content._getCommonProperties(helpHubState),
          href,
        },
        {
          helphub: serializeObject(helpHubState),
          content: serializeObject(helpHubState.hubDoc),
        },
      );
    },

    /**
     * Fired whenever a piece of content is scrolled.
     * @param helpHubState The current state of HelpHub when the content was scrolled. This includes the current document.
     */
    scrolled: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('cb:content_scrolled', Track.content._getCommonProperties(helpHubState), {
        helphub: serializeObject(helpHubState),
        content: serializeObject(helpHubState.hubDoc),
      });
    },

    /**
     * Fired whenever the play button of a video is clicked.
     * @param helpHubState The current state of HelpHub when the play button was clicked. This includes the current document.
     */
    playClicked: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('cb:content_play_clicked', Track.content._getCommonProperties(helpHubState), {
        helphub: serializeObject(helpHubState),
        content: serializeObject(helpHubState.hubDoc),
      });
    },

    /**
     * Fired whenever the pause button of a video is clicked.
     * @param helpHubState The current state of HelpHub when the pause button was clicked. This includes the current document.
     * @todo Instrument this event
     */
    pauseClicked: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('cb:content_pause_clicked', Track.content._getCommonProperties(helpHubState), {
        helphub: serializeObject(helpHubState),
        content: serializeObject(helpHubState.hubDoc),
      });
    },
  },
};
