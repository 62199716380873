import { cn } from '../util';
import { type VariantProps } from 'class-variance-authority';
import React from 'react';
import { tagStyles } from './styles';
import { XClose } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/colors';
type BaseButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'>;
type VariantButtonProps = VariantProps<typeof tagStyles>;

export type TagProps = BaseButtonProps &
  VariantButtonProps & {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    prefixElement?: React.ReactElement | string;
    suffixElement?: React.ReactElement | string;
    style?: React.CSSProperties;
    dismissibleKey?: string;
  };

const CmdTag = React.forwardRef<HTMLButtonElement, TagProps>((props, ref) => {
  const {
    className,
    children,
    disabled = false,
    suffixElement,
    prefixElement,
    variant = 'default',
    style,
    dismissibleKey,
    ...otherProps
  } = props;

  const fullDismissibleKey = dismissibleKey ? `commandbar.dismissible.${dismissibleKey}` : '';
  const [dismissed, setDismissed] = React.useState(
    dismissibleKey ? localStorage.getItem(fullDismissibleKey) === 'true' : false,
  );

  if (dismissibleKey && dismissed) {
    return null;
  }

  const dismiss = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (dismissibleKey) {
      setDismissed(true);
      localStorage.setItem(fullDismissibleKey, 'true');
    }
  };

  if (props.onClick === undefined) {
    return (
      <div className={cn(tagStyles({ variant, disabled, asButton: false }), className)} style={style}>
        {prefixElement}
        <span className="flex items-center">{children}</span>
        {suffixElement}
        {dismissibleKey && !dismissed && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <XClose
              width={14}
              height={14}
              color={CB_COLORS.neutral600}
              className="cursor-pointer"
              onClick={(e) => dismiss(e)}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <button
      ref={ref}
      className={cn(tagStyles({ variant, disabled, asButton: true }), className)}
      disabled={!!disabled}
      {...otherProps}
      onClick={props.onClick}
    >
      {prefixElement}
      <span className="flex items-center">{children}</span>
      {suffixElement}
      {dismissibleKey && !dismissed && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <XClose
            width={14}
            height={14}
            color={CB_COLORS.neutral600}
            className="cursor-pointer"
            onClick={(e) => dismiss(e)}
          />
        </div>
      )}
    </button>
  );
});

export { CmdTag };
