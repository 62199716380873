import Logger from '../util/Logger';
import { getProxySDK } from '../client/proxy';
import { _configuration, _sentry } from '../client/symbols';

/**
 * XXX: This is a temporary hack so that we can remove the `Hub` type from the build output of the commandbar package.
 * Because this file is imported by middleware/types.tsx, which is imported everywhere, we need to make sure it doesn't contain the `Hub` type.
 * Once we break up the types.tsx file into smaller files, we can remove this hack.
 */
const getSentryForNetwork = () => {
  return getProxySDK()?.[_sentry] as {
    captureException: (e: unknown) => void;
  };
};

const MAX_WAIT_TIME_MS = 30000;

let baseUrl: string | undefined;

export const setBaseURL = (url: string | undefined) => {
  baseUrl = url;
};

export const getBaseURL = (): string => {
  if (baseUrl) {
    return baseUrl;
  }

  let url = getProxySDK()?.[_configuration]?.api;

  if (url === undefined) {
    const override = new URL(window.location.href).searchParams.get('api');
    if (!!override) {
      url = override;
    } else {
      // Netlify hack for preview environments
      // Get lc and see if we're in a preview environment
      const lc = localStorage.getItem('commandbar.lc');
      if (lc && lc.includes('api=preview_')) {
        // Get the preview auth url
        url = `https://${/api=preview_([^;$]+)/.exec(lc)?.[1]}.commandbar.xyz:8000`;
      } else {
        url = `${process.env.REACT_APP_API_URL}`;
      }
    }
  }

  Logger.blue('[target]', url);
  return url;
};

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
};

const assertNotAirgapped = () => {
  const airgap = getProxySDK()[_configuration]?.airgap;
  if (airgap) {
    Logger.red('blocking unexpected request in airgapped mode');
    throw new Error('unexpected request in airgapped mode');
  }
};

const isRetryable = ({ method, path }: { method: string; path: string }) => {
  const shouldRetryForMethod = method.toLowerCase() === 'get';
  const shouldRetryForEndpoint =
    ['commands', 'categories', 'placeholders', 'settings', 'contexts'].includes(path) || path.includes('/config/');

  return shouldRetryForMethod && shouldRetryForEndpoint;
};

const getCookie = (name: string): string | undefined => {
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');

    const cookie = cookies.find((cookie) => {
      const [key, _] = cookie.split('=');

      if (key.trim() === name) {
        return true;
      }

      return false;
    });

    if (cookie) {
      const [, value] = cookie.split('=');
      return value;
    }
  }

  return undefined;
};

export type FetchOptions = {
  signal?: AbortSignal;
  headers?: Record<string, string>;
  credentials?: RequestCredentials;
  keepalive?: boolean;
};

export type Response<T> = {
  data: T;
  status: number;
  statusText: string;
  headers: Record<string, string>;
};

export const put = <T = any>(
  url: string,
  data: string | object | Blob | undefined = undefined,
  options: FetchOptions = {},
) => _fetch<T>('PUT', url, data, options);

export const post = <T = any>(
  url: string,
  data: string | object | Blob | undefined = undefined,
  options: FetchOptions = {},
) => _fetch<T>('POST', url, data, options);

export const get = <T = any>(url: string, options: FetchOptions = {}) => _fetch<T>('GET', url, undefined, options);

export const patch = <T = any>(
  url: string,
  data: string | object | undefined = undefined,
  options: FetchOptions = {},
) => _fetch<T>('PATCH', url, data, options);

export const del = <T = any>(url: string, data: string | object | undefined = undefined, options: FetchOptions = {}) =>
  _fetch<T>('DELETE', url, data, options);

export const getFetchHeaders = (uploadFile?: boolean) => {
  const csrftoken = getCookie('csrftoken');
  const sentryProject = process.env.SENTRY_PROJECT;
  const sentryRelease = process.env.SENTRY_RELEASE;
  const launchcode = getProxySDK()?.[_configuration]?.launchcode;

  return {
    ...(uploadFile ? { accept: 'application/json' } : DEFAULT_HEADERS),
    ...(launchcode && { 'X-cb-lc': launchcode }),
    ...(sentryProject && { 'X-cb-proj': sentryProject }),
    ...(sentryRelease && { 'X-cb-release': sentryRelease }),
    'X-CSRFToken': csrftoken ?? '',
  };
};

const _fetch = async <T>(
  method: string,
  path: string,
  data: string | object | undefined,
  options: FetchOptions = {},
  numRetries = 5,
): Promise<Response<T>> => {
  assertNotAirgapped();

  const uploadFile = path.endsWith('upload_file/') || path.endsWith('upload');

  // convert data to string
  if (
    data !== undefined &&
    typeof data !== 'string' &&
    !(uploadFile && data instanceof FormData) &&
    !(data instanceof Blob)
  ) {
    data = JSON.stringify(data);
  }

  let _baseURL = getBaseURL();

  // Redirect `/t/` posts to a separate server
  if (path.endsWith('/t/') && ['https://api.commandbar.com'].includes(_baseURL)) {
    _baseURL = 'https://t.commandbar.com';
    options.credentials = 'omit';
  }

  let json: any, response: globalThis.Response, responseHeaders: Record<string, string>;

  // remove leading / from path
  while (path.startsWith('/')) path = path.slice(1);

  let n = 0;
  do {
    response = await fetch(_baseURL + '/' + path, {
      method,
      credentials: options.credentials || 'include',
      headers: {
        ...getFetchHeaders(uploadFile),
        ...options.headers,
      },
      body: data,
      signal: options.signal,
      keepalive: options.keepalive,
    });

    responseHeaders = Object.fromEntries(response.headers.entries());

    let shouldRetry = false;
    if (!response.ok) {
      if (isRetryable({ method, path })) {
        shouldRetry = true;
      } else {
        // emulates existing error behavior, see https://github.com/tryfoobar/monobar/blob/e118b37ec4284ab8cb2b1a740e0b03559c526a57/internal/src/middleware/network.ts#L254
        try {
          let data = await response.text();

          try {
            data = JSON.parse(data);
          } catch (e) {
            getSentryForNetwork()?.captureException(e);
          }

          return Promise.reject(data || 'Something went wrong');
        } catch (e) {
          getSentryForNetwork()?.captureException(e);
          throw new Error('Something went wrong');
        }
      }
      json = null;
    } else {
      if (response.status === 204) json = null;
      else if (response.headers.get('content-length') === '0') json = null;
      else json = await response.json();
    }

    if (!shouldRetry) break;

    // Try again after a delay.
    // eslint-disable-next-line no-loop-func
    await new Promise((resolve) =>
      // wait 2^n * 100ms + random jitter up to 1s, up to a max of 30s
      setTimeout(resolve, Math.min(Math.pow(2, n) * 100 + Math.floor(Math.random() * 1000), MAX_WAIT_TIME_MS)),
    );
    n++;
  } while (n <= numRetries);

  return {
    data: json,
    status: response.status,
    statusText: response.statusText,
    headers: responseHeaders,
  };
};

export const getBlob = async (url: string, options: FetchOptions = {}): Promise<Blob> => {
  assertNotAirgapped();

  const _baseURL = getBaseURL();

  let response: globalThis.Response;

  try {
    response = await fetch(_baseURL + '/' + url, {
      method: 'GET',
      credentials: options.credentials || 'include',
      headers: {
        ...getFetchHeaders(),
        ...options.headers,
      },
      signal: options.signal,
      keepalive: options.keepalive,
    });

    if (!response.ok) {
      let errorData = await response.text();
      try {
        errorData = JSON.parse(errorData);
      } catch (e) {
        getSentryForNetwork()?.captureException(e);
      }
      return Promise.reject(errorData || 'Something went wrong');
    }

    return await response.blob();
  } catch (e) {
    getSentryForNetwork()?.captureException(e);
    throw new Error('Something went wrong fetching the blob');
  }
};
