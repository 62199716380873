import { CmdCalendarRangePicker } from '@commandbar/design-system/cmd';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import { useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const default_filter = {
  start_date: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
  end_date: dayjs().format('YYYY-MM-DD'),
};

export const useTimeFilter = () => {
  const [timeFilterRange, setFilterRange] = useState<DateFilter>(default_filter);

  const debouncedSetFilterRange = useCallback(
    debounce((value: DateFilter) => setFilterRange(value), 1000),
    [],
  );

  const handleCustomFilterChange = (value: DateFilter) => {
    debouncedSetFilterRange(value);
  };

  useEffect(() => {
    return () => debouncedSetFilterRange.cancel();
  }, [debouncedSetFilterRange]);

  return {
    handleCustomFilterChange,
    timeFilterRange,
    setFilterRange,
  };
};

interface CustomTimeRangeProps {
  handleCustomFilterChange: (value: DateFilter) => void;
  timeFilterRange: DateFilter;
}

export const CustomTimeRange = ({ handleCustomFilterChange, timeFilterRange }: CustomTimeRangeProps) => {
  const onDateChange = (date: DateRange) => {
    handleCustomFilterChange({
      start_date: date.from ? dayjs(date.from).format('YYYY-MM-DD') : '',
      end_date: date.to ? dayjs(date.to).format('YYYY-MM-DD') : '',
    });
  };
  return <CmdCalendarRangePicker onDateChange={onDateChange} timeFilterRange={timeFilterRange} />;
};

export default CustomTimeRange;
