import styled from '@emotion/styled';

import { CB_COLORS } from '@commandbar/design-system/components/antd';

const FooterContainer = styled.div`
  display: flex;
  padding: 8px 8px 8px 12px;
  justify-content: space-between;
  align-items: center;
  background: ${CB_COLORS.neutral0} !important;
  border-radius: 4px;
`;

const RowCount = styled.div<{ show: boolean }>`
  color: ${({ show }) => (show ? CB_COLORS.neutral800 : 'transparent')};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
`;

const PaginationContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const PaginationButton = styled.button<{ disabled: boolean }>`
  all: unset;
  display: flex;
  height: 6px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 15, 0.24);
  background: #fff;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.07) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
`;

export interface Pagination {
  totalItems: number;
  currentItems: number;
  previousPage: number | null;
  currentPage: number;
  nextPage: number | null;
}

interface FooterProps {
  setCurrentPage: (page: number) => void;
  pagination: Pagination;
}

const Footer = ({ pagination, setCurrentPage }: FooterProps) => {
  const { totalItems, currentItems, previousPage, nextPage } = pagination;

  return (
    <FooterContainer>
      <RowCount show={!!totalItems}>
        <span>{currentItems || 0}</span>
        <span style={{ fontWeight: 400 }}> of </span>
        <span>{totalItems || 0}</span>
      </RowCount>

      <PaginationContainer>
        <PaginationButton
          disabled={previousPage === null}
          onClick={() => {
            if (previousPage !== null) setCurrentPage(previousPage);
          }}
        >
          {'<- Prev'}
        </PaginationButton>
        <PaginationButton
          disabled={nextPage === null}
          onClick={() => {
            if (nextPage !== null) setCurrentPage(nextPage);
          }}
        >
          {'Next ->'}
        </PaginationButton>
      </PaginationContainer>
    </FooterContainer>
  );
};

export default Footer;
