/** @jsx jsx  */
import Hotkey from '@commandbar/internal/client/Hotkey';
import { KeyRecorder } from '@commandbar/internal/client/KeyRecorder';
import Mousetrap from '@commandbar/internal/client/mousetrap_fork.js';
import { css, jsx, keyframes } from '@emotion/core';
import React, { useRef } from 'react';
import { Input, InputRef } from '@commandbar/design-system/components/antd';
import { ReverseLeft, XCircle } from '@commandbar/design-system/icons/react';

interface IProps {
  value: string;
  onChange(value: string): void;
  platform: 'mac' | 'win';
  enableRecording?: boolean;
  defaultValue?: string;
  allowTypingUpdates?: boolean;
  showModString?: boolean;
}

export const KeyboardShortcutInput = ({
  value,
  onChange,
  platform,
  enableRecording = true,
  defaultValue = '',
  allowTypingUpdates = true,
  showModString = false,
}: IProps) => {
  const [shortcut, setShortcut] = React.useState<string>(Hotkey.toPlatformSpecificString(value, platform));
  const [switchToEditing, setSwitchToEditing] = React.useState<boolean>(false);
  const [recorder, setRecorder] = React.useState<KeyRecorder | null>(null);
  const [editing, setEditing] = React.useState(!enableRecording);

  const recordingElement = useRef(document.createElement('div'));

  const convertToRepresentation = (v: string) => {
    if (showModString) {
      return Hotkey.toModString(v, platform);
    } else {
      return Hotkey.toPlatformSpecificString(v, platform);
    }
  };

  const inputCallbackRef = (node: InputRef) => {
    if (switchToEditing && node) {
      node.focus();
    }
    setSwitchToEditing(false);
  };

  const pulsate = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgb(255,134,134);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 3px rgb(255,82,82);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgb(255,82,82);
    }`;

  React.useEffect(() => {
    setShortcut(Hotkey.normalize(convertToRepresentation(value)));
  }, [value]);

  const onRecordingFinish = (mousetrapString: string | undefined) => {
    if (!!mousetrapString) {
      valueChanged(mousetrapString);
    }

    if (recordingElement?.current) recordingElement.current.blur();
  };

  const valueChanged = (value: string | undefined) => {
    if (value === undefined) {
    } else {
      setShortcut(convertToRepresentation(value));
      onChange(value);
    }
  };

  const valueChangedTyping = (value: string | undefined) => {
    if (value === undefined) {
    } else {
      setShortcut(convertToRepresentation(value));

      if (allowTypingUpdates) {
        onChange(value);
      }
    }
  };

  const clickRecordingInput = () => {
    // if user clicks again into input while recording, switch to editing directly
    if (recorder !== null && switchToEditing) {
      recorder.stop();
      setRecorder(null);

      setEditing(true);
    }
  };

  const mouseDownRecordingInput = () => {
    // if user clicks again into input while recording, switch to editing directly
    if (recorder !== null) {
      setSwitchToEditing(true);
    } else {
      setRecorder(
        new KeyRecorder(
          new Mousetrap(document.body, true),
          (value) => {
            setShortcut(convertToRepresentation(value));
          },
          onRecordingFinish,
          false,
        ),
      );
    }
  };

  const keyUpTabInput = (key: string) => {
    if (key === 'Tab' && recorder === null) {
      setSwitchToEditing(true);
      setEditing(true);
    }
  };

  const stopRecording = () => {
    if (recorder !== null) {
      recorder.stop();
      setRecorder(null);
    }
  };

  const inputAddition = () => {
    if (recorder !== null) {
      return (
        <div
          css={css`
            background: rgb(255, 82, 82);
            box-shadow: 0 0 0 0 rgb(255, 82, 82);
            animation: ${pulsate} 2s infinite;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            transform: scale(1);
            display: inline-block;
            float: right;
            align: center;
            top: 13px;
            right: 13px;
            position: absolute;
          `}
        />
      );
    } else if (!!shortcut) {
      return (
        <button
          css={css`
            display: inline-block;
            float: right;
            top: 8px;
            right: 13px;
            position: absolute;
            border: none;
            cursor: pointer;
            background-color: transparent;
          `}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.currentTarget.blur();
          }}
          onClick={() => {
            valueChanged(defaultValue);
            recordingElement.current.blur();
          }}
        >
          {defaultValue === '' ? <XCircle /> : <ReverseLeft width={16} height={16} />}
        </button>
      );
    } else {
      return null;
    }
  };

  const recordedContent = (text: string, regex: RegExp) => {
    if (!!!text) {
      return (
        <span className="ant-select-selection-placeholder" style={{ lineHeight: '24px' }}>
          {recorder === null ? 'Click here to record..' : 'Recording...'}.
        </span>
      );
    }

    text = text.trim().replaceAll(/\s/g, ' then ');
    const textArray = text.trim().split(regex);
    const toRet = textArray
      .map((str, index) => {
        if (str === 'then') {
          return ' ' + str + ' ';
        }

        return <kbd key={`key-${index}`}>{str}</kbd>;
      })
      .filter((str) => !!str);
    return toRet;
  };

  if (editing) {
    return (
      <Input
        className={`${!!!shortcut || Hotkey.validate(shortcut) ? '' : 'ant-tag-red'}`}
        style={{ width: '100%' }}
        value={shortcut}
        ref={inputCallbackRef}
        onChange={(evt) => {
          valueChangedTyping(evt.target.value);
        }}
        onBlur={(evt) => {
          setEditing(!enableRecording);

          if (!allowTypingUpdates) {
            valueChanged(evt.target.value);
          }
        }}
      />
    );
  }

  return (
    <div
      ref={recordingElement}
      className={`${!!!shortcut || Hotkey.validate(shortcut) ? 'ant-input' : 'ant-input ant-tag-red'}`}
      contentEditable="true"
      suppressContentEditableWarning={true}
      style={{ width: '100%', minHeight: '38px' }}
      onBlur={() => {
        stopRecording();
      }}
      onMouseDown={() => {
        mouseDownRecordingInput();
      }}
      onClick={() => {
        clickRecordingInput();
      }}
      onKeyUp={(evt) => {
        keyUpTabInput(evt.key);
      }}
    >
      {recordedContent(shortcut, /\s/)}
      {inputAddition()}
      {/* // here button */}
    </div>
    //
  );
};
