import styled from '@emotion/styled';
import { Typography } from '@commandbar/design-system/components/antd';

export const RulePrefix = styled(Typography.Text)`
  width: 60px;
  display: inline-block;
  opacity: 0.45;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 7px;
  text-align: center;
`;
