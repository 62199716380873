import React, { useState } from 'react';
import { AlertTriangle, Copy02, FolderPlus, Lock03, Trash04 } from '@commandbar/design-system/icons/react';
import { IRecommendationSet } from '@commandbar/internal/middleware/types';

import {
  FeatureAnnouncementCard,
  Table,
  SimplePanel,
  Row,
  Col,
  Tabs,
  TabPane,
  DropdownMenu,
  Modal,
  StatusBadge,
} from '@commandbar/design-system/components/antd';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { useAppContext } from 'editor/src/AppStateContext';
import { Container } from './shared';
import useRecommendationSets from '../useEditor/useRecommendationSets';
import Sender from '../../management/Sender';
import { useIsEditorOpen } from 'editor/src/hooks';
import { CmdButton, CmdSearchInput, cmdToast } from '@commandbar/design-system/cmd';
import { Route, RouteComponentProps, Switch, useHistory, useParams, useLocation } from 'react-router';
import { HELPHUB_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import RecommendationSetDetail from './RecommendationSetDetail';
import LogoutHeader from '../components/LogoutHeader';
import { PaddingContainer } from '../Router';
import HelpHubSettings from './HelpHubSettings';
import { INamedRule, getConditions, scoreRecommendationSet } from '@commandbar/internal/middleware/helpers/rules';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { listRules } from '@commandbar/internal/middleware/organization';
import _ from 'lodash';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const pageColumn = (recommendationSet: IRecommendationSet, searchText?: boolean) => {
  if (recommendationSet.default) {
    if (searchText) return 'Any';
    return (
      <span>
        Any
        <Lock03 style={{ marginBottom: -3, marginLeft: 2, color: CB_COLORS.neutral500 }} />
      </span>
    );
  }

  const condition = getConditions(recommendationSet.show_expression)?.[0];
  if (
    !condition ||
    (recommendationSet.show_expression.type === 'LITERAL' && recommendationSet.show_expression.value === true)
  ) {
    return 'Any';
  }

  if ((condition.type === 'url' || condition.type === 'hostname') && condition.value) {
    if (searchText) return condition.value;
    return condition.value.length > 20 ? `${condition.value.slice(0, 20)}...` : condition.value;
  }

  return 'Custom';
};

const audienceColumn = (recommendationSet: IRecommendationSet, rules: INamedRule[], searchText?: boolean) => {
  if (recommendationSet.default) {
    if (searchText) return 'All users';
    return (
      <span>
        All users
        <Lock03 style={{ marginBottom: -3, marginLeft: 2, color: CB_COLORS.neutral500 }} />
      </span>
    );
  }

  if (recommendationSet.audience?.type === 'all_users') {
    return 'All users';
  } else if (recommendationSet.audience?.type === 'named_rule_reference') {
    const ruleID = recommendationSet.audience.rule_reference.rule_id;
    return rules.find((rule) => rule.id === ruleID)?.name ?? '';
  } else {
    return 'Custom';
  }
};

type HelpHubRecommendationsProps = RouteComponentProps<{ commandId: string }>;

const HelpHubRecommendations: React.FC<HelpHubRecommendationsProps> = ({ match }) => {
  const { defaultRecommendationSet, recommendationSets, actions } = useRecommendationSets();
  const { isStudio, commandBarReady, loading: appContextLoading, organization } = useAppContext();
  const isEditorOpen = useIsEditorOpen();
  const history = useHistory();
  const params = useParams<{ page?: string }>();
  const location = useLocation();
  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [activeRecommendationSet, setActiveRecommendationSet] = useState<IRecommendationSet | undefined>(undefined);
  const [searchText, setSearchText] = useState('');
  const rules = React.useRef<INamedRule[]>([]);
  const previewedActions = React.useRef<IRecommendationSet['actions']>([]);

  React.useEffect(() => {
    (async () => {
      if (organization) {
        rules.current = await listRules(organization.id.toString());
      }
    })();
  }, [organization]);

  // Sorted by specificity
  const allRecommendationSets = React.useMemo(() => {
    const sortedRecommendationSets = recommendationSets?.sort((a, b) => {
      return scoreRecommendationSet(a) - scoreRecommendationSet(b);
    });

    if (defaultRecommendationSet && !defaultRecommendationSet?.name.length) {
      defaultRecommendationSet.name = 'Recommendation Set';
    }

    return [...(defaultRecommendationSet ? [defaultRecommendationSet] : []), ...(sortedRecommendationSets || [])];
  }, [recommendationSets, defaultRecommendationSet]);

  const filteredRecommendationSets = React.useMemo(() => {
    return allRecommendationSets.filter((recommendationSet) => {
      if (!searchText) return true;
      if (!recommendationSet) return false;

      // Filter by name, page, and audience
      const lowerSearchText = searchText.toLowerCase();
      return (
        recommendationSet.name.toLowerCase().includes(lowerSearchText) ||
        (pageColumn(recommendationSet, true) as string).toLowerCase().includes(lowerSearchText) ||
        (audienceColumn(recommendationSet, rules.current, true) as string).toLowerCase().includes(lowerSearchText)
      );
    });
  }, [allRecommendationSets, searchText]);

  const updatePreview = (recommendationSet: IRecommendationSet) => {
    if (recommendationSet && !_.isEqual(previewedActions.current, recommendationSet.actions)) {
      previewedActions.current = recommendationSet.actions;
      Sender.previewRecommendationSet(recommendationSet);
    }
  };

  React.useEffect(() => {
    // If there is no default recommendation set, create one
    if (defaultRecommendationSet && defaultRecommendationSet.id < 0) {
      actions.defaultRecommendationSet.update(defaultRecommendationSet);
    }
  }, [defaultRecommendationSet]);

  // Preview the default recommendation set by default
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const commandId = Number(queryParams.get('commandId'));

    if (commandId) {
      Sender.openHelpHub({ articleId: commandId });
    } else if (!appContextLoading && commandBarReady && (isEditorOpen || isStudio)) {
      Sender.openHelpHub();
    }
  }, [isEditorOpen, isStudio, commandBarReady]);

  React.useEffect(() => {
    if (!activeRecommendationSet && defaultRecommendationSet) {
      updatePreview(defaultRecommendationSet);
    }
  }, [activeRecommendationSet, defaultRecommendationSet]);

  React.useEffect(() => {
    return () => {
      Sender.stopRecommendationSetPreview();
      Sender.closeHelpHub();
    };
  }, []);

  React.useEffect(() => {
    if (appContextLoading || !organization) return;

    if (!params.page) {
      setActiveRecommendationSet(undefined);
      return;
    } else {
      const recommendationSet = allRecommendationSets?.find((c) => c.id === Number(params.page));
      if (recommendationSet) {
        setActiveRecommendationSet(recommendationSet);
      }
    }
  }, [organization, activeRecommendationSet, params.page, appContextLoading, allRecommendationSets]);

  const updateActiveRecommendationSet = (recommendationSet: IRecommendationSet | undefined) => {
    setActiveRecommendationSet(recommendationSet);

    if (!!recommendationSet) {
      const path = `${HELPHUB_ROUTE}/${recommendationSet.id}`;
      if (history.location.pathname !== path) {
        history.push(path);
      }
    } else {
      history.replace(HELPHUB_ROUTE);
    }
  };

  const addNewRecommendationSet = async () => {
    const newSet = await actions.recommendationSets.addNew();
    updateActiveRecommendationSet(newSet);
  };

  const CreateButton = ({ addNew }: { addNew: () => void }) => {
    const [loading, setLoading] = useState(false);
    return (
      <CmdButton
        onClick={async () => {
          if (loading) return;
          setLoading(true);
          try {
            await addNew();
          } catch {
            cmdToast.error('Error creating new recommendation set');
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        icon={<FolderPlus />}
        variant={'primary'}
      >
        <span>New</span>
      </CmdButton>
    );
  };

  const columns = [
    {
      title: 'Recommendation set',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, recommendationSet: IRecommendationSet) => {
        return recommendationSet.name.length > 28
          ? `${recommendationSet.name.slice(0, 28)}...`
          : recommendationSet.name;
      },
    },
    {
      title: 'Page',
      dataIndex: 'page',
      key: 'page',
      render: (_: any, recommendationSet: IRecommendationSet) => {
        return pageColumn(recommendationSet);
      },
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
      render: (_: any, recommendationSet: IRecommendationSet) => {
        return audienceColumn(recommendationSet, rules.current);
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_live',
      key: 'is_live',
      render: (_: any, recommendationSet: IRecommendationSet) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {recommendationSet.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
          ) : (
            <StatusBadge color="orange" text="Draft" />
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, recommendationSet: IRecommendationSet) => {
        const canEdit = hasRequiredRole(user, recommendationSet.is_live ? 'editor' : 'contributor');
        if (!canEdit) return null;

        return (
          <DropdownMenu
            keyName="rec-set-actions"
            items={[
              {
                name: 'Duplicate',
                icon: <Copy02 />,
                onClick: async () => {
                  try {
                    const newSet: IRecommendationSet = {
                      ...recommendationSet,
                      name: `${recommendationSet.name} (copy)`,
                      default: false,
                      id: -1,
                    };
                    actions.recommendationSets.addNew(newSet);

                    // Find the new set (which has the highest id) and navigate to it
                    if (!recommendationSets) return;
                    const maxId = Math.max(...allRecommendationSets.map((s) => s.id));
                    const newSetIndex = allRecommendationSets.findIndex((s) => s.id === maxId);
                    setCurrentPage(Math.ceil((newSetIndex + 1) / 10));
                    cmdToast.success('Recommendation set duplicated.');
                  } catch (err) {
                    cmdToast.error('Error duplicating recommendation set.');
                  }
                },
              },
              {
                name: 'Delete',
                icon: <Trash04 />,
                hidden: recommendationSet.default,
                onClick: () => {
                  Modal.confirm({
                    icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                    title: `Are you sure you'd like to delete '${recommendationSet.name}'?`,
                    async onOk() {
                      try {
                        await actions.recommendationSets.del(recommendationSet.id);
                        cmdToast.success('Recommendation Set deleted.');
                      } catch {
                        cmdToast.error('Error deleting Recommendation Set');
                      }
                    },
                  });
                },
              },
            ].filter((item) => !item.hidden)}
          />
        );
      },
    },
  ];

  if (activeRecommendationSet) {
    return (
      <RecommendationSetDetail
        key={activeRecommendationSet.id}
        initialRecommendationSet={activeRecommendationSet}
        onClose={() => {
          const createdNewRecommendationSet = activeRecommendationSet.id > -1;
          createdNewRecommendationSet || history.length <= 1 ? history.replace(HELPHUB_ROUTE) : history.goBack();
        }}
        onDelete={async (recommendationSet: IRecommendationSet) => {
          await actions.recommendationSets.del(recommendationSet.id);
          history.goBack();
          cmdToast.success('Recommendation set deleted.');
        }}
        onSave={async (recommendationSet: IRecommendationSet) => {
          if (recommendationSet.default) {
            await actions.defaultRecommendationSet.update(recommendationSet);
          } else {
            await actions.recommendationSets.update(recommendationSet);
          }
          history.replace(`${HELPHUB_ROUTE}/${recommendationSet.id}`);
          cmdToast.success('Recommendation set saved.');
        }}
        updatePreview={updatePreview}
      />
    );
  } else {
    return (
      <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
        <Switch>
          <Route path={`${match.path}/:page?`}>
            {({ match }) => {
              return (
                <>
                  <LogoutHeader />
                  <Tabs
                    activeKey={
                      match?.url === editorRoutes.HELPHUB_SETTINGS_ROUTE ? match.url : editorRoutes.HELPHUB_ROUTE
                    }
                    onChange={(key) => history.push(key)}
                    isStudio={isStudio}
                    destroyInactiveTabPane={true}
                    type="card"
                    tabBarStyle={{
                      paddingTop: isStudio ? '9px' : 0,
                      marginTop: -10,
                      paddingLeft: '16px',
                      display: 'block',
                    }}
                  >
                    <TabPane tab="Recommendations" key={editorRoutes.HELPHUB_ROUTE}>
                      <PaddingContainer>
                        <Container>
                          <FeatureAnnouncementCard
                            identifier="recommendation_sets"
                            title="Recommendation Sets"
                            docsLink="https://www.commandbar.com/docs/nudges/helphub/recommendation-sets/"
                          >
                            <span>Suggest recommended help content (docs, videos, links, etc.) to your users.</span>
                          </FeatureAnnouncementCard>

                          <SimplePanel>
                            <Row
                              justify="end"
                              style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}
                            >
                              <Col style={{ flex: 1 }}>
                                <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                              </Col>
                              {hasRequiredRole(user, 'contributor') && (
                                <Col>
                                  <CreateButton addNew={addNewRecommendationSet} />
                                </Col>
                              )}
                            </Row>
                            <Table
                              pagination={{
                                pageSize: 10,
                                hideOnSinglePage: true,
                                current: currentPage,
                                onChange: (page) => {
                                  history.replace(`${history.location.pathname}?page=${page}`);
                                  setCurrentPage(page);
                                },
                              }}
                              rowClassName="editable-row"
                              columns={columns}
                              dataSource={filteredRecommendationSets.map((n) => ({ ...n, key: n.id }))}
                              onRow={(recommendationSet: IRecommendationSet, _rowIndex: any) => {
                                return {
                                  onClick: (_e: React.MouseEvent) => {
                                    updateActiveRecommendationSet(recommendationSet);
                                  },
                                };
                              }}
                            />
                          </SimplePanel>
                        </Container>
                      </PaddingContainer>
                    </TabPane>

                    {hasRequiredRole(user, 'editor') && (
                      <TabPane tab="Settings" key={editorRoutes.HELPHUB_SETTINGS_ROUTE}>
                        <PaddingContainer>
                          <HelpHubSettings />
                        </PaddingContainer>
                      </TabPane>
                    )}
                  </Tabs>
                </>
              );
            }}
          </Route>
        </Switch>
      </div>
    );
  }
};

export default HelpHubRecommendations;
