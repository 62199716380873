import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';

import { IAPI } from './types';

export const APIV = t.type(
  {
    id: t.number,
    archived: t.boolean,
    is_live: t.boolean,
    title: t.string,
    description: t.string,
    method: t.union([t.literal('GET'), t.literal('POST'), t.literal('PUT'), t.literal('PATCH'), t.literal('DELETE')]),
    url: t.string,
    body: t.string,
    headers: t.string,
    require_confirmation: t.boolean,
    confirm_cta: t.string,
    cancel_cta: t.string,
    workflows_only: t.boolean,
    parameters: t.array(
      t.type({
        archived: t.boolean,
        name: t.string,
        description: t.string,
        type: t.union([t.literal('string'), t.literal('number'), t.literal('boolean')]),
        required: t.boolean,
      }),
    ),
  },
  'APIBase',
);

export class API {
  public static decode = (data: any) => {
    const API: IAPI = { ...data };

    return decodeThrowing(APIV, API);
  };

  public static create = createObject(APIV, APIV, 'apis');

  public static update = updateObject(APIV, APIV, 'apis');

  public static delete = deleteObject(APIV, 'apis');
  public static list = listObject(APIV, 'apis');
  public static read = readObject(APIV, 'apis');
}
