import React, { ReactNode, useMemo, useState } from 'react';

import type { ITemplate } from '@cb/types/entities/command/template';
import ActionTypeSelectorOption from './components/ActionTypeSelectorOption';
import OptionWrapper from './components/OptionWrapper';
import { CurrentItemContainer, GroupHeading, IconContainer, Container } from './styled';

import {
  ChevronDown,
  CodeCircle03,
  CursorClick02,
  Data,
  Dataflow04,
  File02,
  Link01,
  Pendo,
  PlaySquare,
  Server06,
  TerminalSquare,
  ZapFast,
} from '@commandbar/design-system/icons/react';
import { ReactSelect } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';

export type SelectorOption = {
  value: ITemplate['type'];
  label: string;
  description: string;
  icon: ReactNode;
  color: string;
  deprecated?: boolean;
};

const getSelectorOptions = (isPendoGuideEnabled: boolean) => {
  return [
    {
      value: 'callback',
      label: 'Callback',
      description: 'Trigger a function provided to CommandBar.',
      icon: <TerminalSquare height={20} width={20} />,
      color: '#49aa19',
    },
    {
      value: 'video',
      label: 'Video',
      description: 'Embed a video.',
      icon: <PlaySquare height={20} width={20} />,
      color: '#1ee5bd',
    },
    {
      value: 'helpdoc',
      label: 'Article or file',
      description: 'Embed an article synced from a source or a file.',
      icon: <File02 height={20} width={20} />,
      color: '#e54c21',
    },
    {
      value: 'click',
      label: 'Click',
      description: `Simulate clicks in the user's DOM.`,
      icon: <CursorClick02 height={20} width={20} />,
      color: '#d89614',
    },
    {
      value: 'trigger',
      label: 'Trigger',
      description: 'Trigger an experience.',
      icon: <ZapFast width={20} height={20} />,
      color: '#ff4a00',
    },
    {
      value: 'request',
      label: 'Web Request',
      description: 'Make HTTP requests.',
      icon: <Server06 height={20} width={20} />,
      color: '#b19cd9',
    },
    {
      value: 'pendo_guide',
      label: 'Pendo Guide',
      description: 'Trigger a Pendo guide.',
      icon: <Pendo height={20} width={20} />,
      color: '#eb225a',
      deprecated: !isPendoGuideEnabled,
    },
    /** Deprecated command types.
     * Leave them here for labels & icons for old commands that have these types
     */
    {
      value: 'webhook',
      label: 'Webhook',
      description: `Post to a webook.`,
      icon: <Dataflow04 height={20} width={20} />,
      color: '#ff4a00',
      deprecated: true,
    },
    {
      value: 'script',
      label: 'Script',
      description: `Run a JS script.`,
      icon: <CodeCircle03 height={20} width={20} />,
      color: '#b19cd9',
      deprecated: true,
    },
    {
      value: 'appcues',
      label: 'Appcues',
      description: `Trigger an Appcues flow`,
      icon: <Data height={20} width={20} />,
      color: '#5757f5',
      deprecated: true,
    },
  ] as SelectorOption[];
};

const linkSelectorOption: SelectorOption[] = [
  {
    value: 'link',
    label: 'Link',
    description: 'Open a static or dynamic link.',
    icon: <Link01 height={20} width={20} />,
    color: '#177ddc',
  },
];

type Props = {
  templateType: string;
  onCommandTypeChange: (nextType: ITemplate['type']) => void;
  isDisabled?: boolean;
  isRecordAction?: boolean;
};

const ActionTypeSelector = ({ templateType, onCommandTypeChange, isDisabled, isRecordAction }: Props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { flags } = useAppContext();

  const isPendoGuideEnabled = !!flags['enable-pendo-open-guide-action'];
  const selectorOptions = useMemo(() => getSelectorOptions(isPendoGuideEnabled), [isPendoGuideEnabled]);

  // filter out video and helpdoc options, unless we are viewing a video or helpdoc
  // we will only need these selector options if we are viewing the legacy edit view for a video or helpdoc command
  const updatedSelectorOptions = [...selectorOptions, ...(isRecordAction ? linkSelectorOption : [])].filter((option) =>
    option.value === 'video' || option.value === 'helpdoc' ? templateType === option.value : true,
  );

  const current: SelectorOption | undefined = updatedSelectorOptions.find((obj) => {
    let value = templateType;
    if (['click', 'clickBySelector', 'clickByXpath'].includes(templateType)) {
      value = 'click';
    }

    return obj.value === value;
  });

  if (!current) {
    throw new Error('Command type not found.');
  }

  const options: SelectorOption[] = updatedSelectorOptions.filter((o) => !o.deprecated);

  const handleChange = (option: SelectorOption) => {
    onCommandTypeChange(option.value);
    setDropdownVisible(false);
  };

  return (
    <Container tabIndex={0} onBlur={() => setDropdownVisible(false)}>
      <CurrentItemContainer
        disabled={isDisabled}
        dropdownVisible={dropdownVisible}
        onClick={() => {
          if (!isDisabled) {
            setDropdownVisible((prevState) => !prevState);
          }
        }}
      >
        <ActionTypeSelectorOption
          disabled={isDisabled}
          label={current.label}
          description={current.description}
          icon={current.icon}
          color={current.color}
          noBorder
        />
        {!isDisabled && (
          <IconContainer>
            <ChevronDown
              {...(dropdownVisible && {
                style: {
                  transform: 'rotate(180deg)',
                  transition: 'all 0.2s ease',
                },
              })}
            />
          </IconContainer>
        )}
      </CurrentItemContainer>

      {dropdownVisible && (
        <ReactSelect
          value={current}
          options={options}
          components={{
            GroupHeading: (props) => <GroupHeading children={props.children} />,
            Option: OptionWrapper,
            Control: () => null,
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          isDisabled={isDisabled}
          menuIsOpen
          menuPlacement="bottom"
          captureMenuScroll={false}
          onChange={(value) => {
            if (value) {
              handleChange(value);
            }
          }}
          onBlur={() => setDropdownVisible(false)}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 5,
              margin: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              overflow: 'hidden',
              border: '1px solid #e6e6e8',
              borderTopWidth: 0,
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: 'none', // remove this to enable scroll once we add more options
              padding: 0,
            }),
            group: (provided) => ({
              ...provided,
              padding: 0,
            }),
          }}
        />
      )}
    </Container>
  );
};
export default ActionTypeSelector;
