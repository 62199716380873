// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const PaintPour = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <g opacity={0.12}>
      <path
        fill="currentColor"
        d="M22 16a2 2 0 1 1-4 0c0-1.105 2-3 2-3s2 1.895 2 3Zm-10.737.737 4.606-4.606c.396-.396.594-.594.668-.822a1 1 0 0 0 .049-.309H1.828a2 2 0 0 0 .098.618c.149.457.545.853 1.337 1.645l3.474 3.474c.792.792 1.188 1.188 1.645 1.337a2 2 0 0 0 1.236 0c.457-.149.853-.545 1.645-1.337Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M22 16a2 2 0 1 1-4 0c0-1.105 2-3 2-3s2 1.895 2 3Zm-10.737.737 4.606-4.606c.396-.396.594-.594.668-.822a1 1 0 0 0 .049-.309H1.828a2 2 0 0 0 .098.618c.149.457.545.853 1.337 1.645l3.474 3.474c.792.792 1.188 1.188 1.645 1.337a2 2 0 0 0 1.236 0c.457-.149.853-.545 1.645-1.337Z"
      />
    </g>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 11H2m8-7L8 2m6 20H2m20-6a2 2 0 1 1-4 0c0-1.105 2-3 2-3s2 1.895 2 3ZM9 3l6.869 6.869c.396.396.594.594.668.822a1 1 0 0 1 0 .618c-.074.228-.272.426-.668.822l-4.606 4.606c-.792.792-1.188 1.188-1.645 1.337a2 2 0 0 1-1.236 0c-.457-.149-.853-.545-1.645-1.337l-3.474-3.474c-.792-.792-1.188-1.188-1.337-1.645a2 2 0 0 1 0-1.236c.149-.457.545-.853 1.337-1.645L9 3Z"
    />
  </svg>
);
export default PaintPour;
