import { useState } from 'react';
import * as Sentry from '@sentry/react';

export const useCSVDownload = ({ name, download }: { name: string; download: () => Promise<Blob> }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<string>('');

  const downloadCsv = () => {
    setIsDownloading(true);
    const now = new Date();
    download()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `CommandBar-${name}-${now.toISOString().split('T')[0]}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        a.style.display = 'none';
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setDownloadError(error);
      })
      .finally(() => setIsDownloading(false));
  };

  return {
    isDownloading,
    downloadCsv,
    downloadError,
  };
};
