import chroma from 'chroma-js';

/**
 * Chroma errors out in case the color passed to it is an invalid CSS colors
 * To prevent situations where invalid colors break the bar, we validate the
 * user-specified color to see if its valid. If yes, we initialize the color
 * with chroma constructor. If not, we initialize it with a default color.
 *
 * We cannot do a simple check for color validation because often times the
 * call to chroma() is chained with other functions.
 */
export const getChromaColor = (color: string) => {
  return chroma.valid(color) ? chroma(color) : chroma('#000');
};
