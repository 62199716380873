import React, { useEffect } from 'react';

import { useParams } from 'react-router';
import CommandTable from './commands/CommandsTab/CommandTable';
import { useAppContext } from '../AppStateContext';
import { FeatureAnnouncementCard, Row, SimplePanel, PaddingContainer } from '@commandbar/design-system/components/antd';
import { ScrollContainer } from './Router';
import * as Command from '@commandbar/internal/middleware/command';
import { IEditorCommandType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import Sender from '../management/Sender';
import { ActiveCommand } from './commands/ActiveCommand';
import { ACTIONS_ROUTE, PAGES_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import AnalyticRuleAlert from './components/AnalyticRuleAlert';

export const getCommandType = (c: IEditorCommandTypeLite): 'page' | 'action' | undefined => {
  if (c.template.type === 'link') return 'page';
  if (c.template.type !== 'helpdoc' && c.template.type !== 'video') return 'action';
  return undefined;
};

export const getCommandRoute = (c: IEditorCommandTypeLite) => {
  return getCommandType(c) === 'page' ? PAGES_ROUTE : ACTIONS_ROUTE;
};

const PagesOrActions = ({
  type,
  activeCommand,
  setActiveCommand,
}: {
  type: 'page' | 'action';
  activeCommand: IEditorCommandType | undefined;
  setActiveCommand: (command: IEditorCommandType | undefined) => void;
}) => {
  const params = useParams<{ commandId?: string }>();
  const { commands, isStudio } = useAppContext();
  const commandsToShow = commands.filter((c) => Command.showInDefaultList(c) && getCommandType(c) === type);

  useEffect(() => {
    if (isStudio) {
      Sender.openBar();
      return () => {
        Sender.closeBar();
      };
    }
  }, []);

  return !!params.commandId ? (
    <ActiveCommand activeCommand={activeCommand} setActiveCommand={setActiveCommand} />
  ) : (
    <ScrollContainer>
      <PaddingContainer>
        <FeatureAnnouncementCard
          identifier="bar-commands"
          title={<Row align="middle">Creating {type}s</Row>}
          docsLink={type === 'page' ? 'https://www.commandbar.com/docs/platform/building-blocks/pages/' : undefined}
        >
          <span>
            Enable your users to {type === 'page' ? 'navigate around your app' : 'do things in your app'} by adding{' '}
            {type}s.
          </span>
        </FeatureAnnouncementCard>

        <SimplePanel>
          <AnalyticRuleAlert type={type} data={commands} />

          <CommandTable commands={commandsToShow} type={type} setActiveCommand={setActiveCommand} />
        </SimplePanel>
      </PaddingContainer>
    </ScrollContainer>
  );
};

export default PagesOrActions;
