import Debug from 'debug';
import LocalStorage from './LocalStorage';

const debug = Debug('cb');

// calling enable will override localStorage config, we do that only if there is no config set for `cb`
const configInStorage = localStorage.getItem('debug');
if (configInStorage === null || !configInStorage.startsWith('cb')) {
  Debug.enable('*:warn,*:error');
}

const log = debug.extend(process.env.REACT_APP_FRAME_NAME ? process.env.REACT_APP_FRAME_NAME.toLowerCase() : '__');
log.log = console.log.bind(console);

const info = log.extend('info');
info.log = console.info.bind(console);

const warn = log.extend('warn');
warn.log = console.warn.bind(console);

const error = log.extend('error');
error.log = console.info.bind(console);

const red = (...args: any[]) => log('%c   ', 'background: red; color: white; display: block;', ...args);
const green = (...args: any[]) => log('%c   ', 'background: green; color: black; display: block;', ...args);
const blue = (...args: any[]) => log('%c   ', 'background: blue; color: black; display: block;', ...args);
const orange = (...args: any[]) => log('%c   ', 'background: orange; color: black; display: block;', ...args);
const yellow = (...args: any[]) => log('%c   ', 'background: yellow; color: black; display: block;', ...args);
const purple = (...args: any[]) => log('%c   ', 'background: purple; color: black; display: block;', ...args);
const black = (...args: any[]) => log('%c   ', 'background: black; color: black; display: block;', ...args);

const portalsend = !!LocalStorage.get('debug.portal', false)
  ? console.log.bind(
      window.console,
      `%c ###SEND [${process.env.REACT_APP_FRAME_NAME}] `,
      'background: darkcyan; color: black; display: block;',
    )
  : () => {
      return;
    };
const portalreceive = !!LocalStorage.get('debug.portal', false)
  ? console.log.bind(
      window.console,
      `%c ###RECEIVE  [${process.env.REACT_APP_FRAME_NAME}] `,
      'background: steelblue; color: black; display: block;',
    )
  : () => {
      return;
    };

const Logger = {
  debug,
  log,
  info,
  warn,
  error,

  red,
  green,
  blue,
  orange,
  yellow,
  purple,
  black,

  portalsend,
  portalreceive,
};

export default Logger;
