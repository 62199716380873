import React from 'react';
import { CmdDropdown, CmdInput, CmdTypography } from '@commandbar/design-system/cmd';
import { EndUserAdmin, IEndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import { debounce } from 'lodash';
import Check from '@commandbar/design-system/icons/react/Check';
import User02 from '@commandbar/design-system/icons/react/User02';
import styled from '@emotion/styled';
import { Inbox01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/colors';

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 4px;
  padding: 8px;
`;

interface EndUserChooserProps {
  endUserSlugs: string[];
  onEndUserChange: (endEuser: IEndUserAdmin) => void;
  disabled?: boolean;
  placeholder?: string;
  prefixText?: string;
  prefixIcon?: React.ReactNode | null;
  style?: React.CSSProperties;
  searchPlaceholder?: string;
}

const EndUserChooser = ({
  endUserSlugs,
  onEndUserChange,
  disabled,
  placeholder = 'Select a user',
  prefixIcon = <User02 />,
  prefixText,
  style,
  searchPlaceholder = 'Find a user...',
}: EndUserChooserProps) => {
  const [endUsers, setEndUsers] = React.useState<IEndUserAdmin[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  React.useEffect(() => {
    const debouncedGetUsers = debounce(async (query: string) => {
      const allUsers = await EndUserAdmin.getFilteredEndUsers(query);
      setEndUsers(allUsers);
    }, 200);

    debouncedGetUsers(searchTerm);

    return () => {
      debouncedGetUsers.cancel();
    };
  }, [searchTerm]);

  return (
    <CmdDropdown.Menu>
      <CmdDropdown.SelectTrigger
        disabled={disabled}
        style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', ...style }}
      >
        {prefixIcon}
        {prefixText && <CmdTypography>{prefixText}</CmdTypography>}
        <CmdTypography
          variant={endUserSlugs.length === 0 ? 'primary' : 'contentMid'}
          style={{ maxWidth: '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {endUserSlugs.length === 0 ? placeholder : endUserSlugs.length === 1 ? endUserSlugs[0] : endUserSlugs.length}
        </CmdTypography>
      </CmdDropdown.SelectTrigger>
      <CmdDropdown.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '376px',
        }}
      >
        <CmdInput
          autoFocus
          clearable
          fullWidth
          inline
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CmdDropdown.Separator />
        {endUsers.length > 0 ? (
          <>
            {endUsers.map((user) => (
              <CmdDropdown.Item
                key={user.id}
                onClick={() => {
                  onEndUserChange(user);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <CmdTypography variant="contentMid" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.slug}
                </CmdTypography>
                {endUserSlugs.includes(user.slug) && <Check />}
              </CmdDropdown.Item>
            ))}
          </>
        ) : (
          <EmptyState>
            <Inbox01 color={CB_COLORS.neutral400} height={'30px'} width={'30px'} />
            <CmdTypography style={{ color: CB_COLORS.neutral400 }}>No users found</CmdTypography>
          </EmptyState>
        )}
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

export default EndUserChooser;
