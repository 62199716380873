import React from 'react';

import { NavLink as Link, NavLinkProps, useLocation } from 'react-router-dom';

import useMediaQuery from '../../util/useMediaQuery';
import { MenuItem, MenuItemGroup, MenuItemLink } from './menu_items';

import { useReportEvent } from '../../hooks/useEventReporting';
import { Menu } from '@commandbar/design-system/components/antd';
import { Caret } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';

import type { LocationState } from 'history';

/** This function finds the menu item that most closely matches
 * the current path to highlight.
 */
function extractClosestKeyMatch(path: string, items: MenuItem[]) {
  const linksAndGroups = items.filter((item) => item.type !== 'node' && item.route !== '') as (
    | MenuItemLink
    | MenuItemGroup
  )[];
  const routes = linksAndGroups
    .flatMap((item) => (item.type === 'group' ? item.children : item))
    .map((item) => item.route);

  const pathParts = path.split('/');
  while (pathParts.length > 0) {
    const currentPath = `${pathParts.join('/')}`;
    if (routes.includes(currentPath)) {
      return currentPath;
    }
    pathParts.pop();
  }

  return path;
}

type ConditionalLinkProps<S = LocationState> = React.PropsWithoutRef<NavLinkProps<S>> &
  React.RefAttributes<HTMLAnchorElement>;

/**
 * Wraps a component in a <Link> if `to` is supplied, otherwise returns the children in a <span>
 */
const ConditionalLink = (props: Omit<ConditionalLinkProps, 'to'> & Partial<Pick<ConditionalLinkProps, 'to'>>) => {
  if (props.to) {
    const { to, ...rest } = props;
    return <Link to={to} {...rest} />;
  } else {
    return <span onClick={props.onClick}>{props.children}</span>;
  }
};

const InnerLinkSubMenu = styled(Menu.SubMenu)`
  &.ant-menu-submenu .ant-menu-submenu-title {
    padding: 0 18px !important;
    font-weight: 500 !important;
  }

  &.ant-menu-submenu .ant-menu-item {
    padding-left: 44px !important;
  }

  &.ant-menu-submenu-root-selected .ant-menu-submenu-title {
    font-weight: 600 !important;
    background-color: #e6ecfe !important;
  }

  &.ant-menu-submenu-root-selected svg:not(.ant-menu-submenu-caret) path {
    fill: #e6ecfe !important;
    stroke: #072eab !important;
  }

  &.ant-menu-submenu-root-selected .ant-menu-submenu-title a {
    color: #031963 !important;
  }
`;

type LinkSubMenuProps = {
  activeKey: string;
  item: MenuItemGroup;

  renderMenuItem: (item: MenuItem, index: number) => React.ReactNode;
  renderItemBody: (item: MenuItemLink | MenuItemGroup) => React.ReactNode;
};

const LinkSubMenu: React.FC<LinkSubMenuProps> = ({ activeKey, item, renderMenuItem, renderItemBody }) => {
  const isSubLinkActive = item.children.some((child) => activeKey.includes(child.route));

  const [openKeys, setOpenKeys] = React.useState<string[]>(isSubLinkActive ? [item.title] : []);

  return (
    <Menu
      activeKey={activeKey}
      selectedKeys={[activeKey]}
      openKeys={openKeys}
      mode="inline"
      theme="light"
      expandIcon={({ isOpen }) => (
        <Caret
          onClick={() => setOpenKeys((k) => (k.length ? [] : [item.title]))}
          height={14}
          width={14}
          style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
          className="ant-menu-submenu-caret"
          viewBox="-4 0 16 6"
        />
      )}
    >
      <InnerLinkSubMenu
        key={item.title}
        className={activeKey === item.route ? 'ant-menu-submenu-root-selected' : ''}
        title={
          <ConditionalLink onClick={() => setOpenKeys((k) => (k.length ? [] : [item.title]))}>
            {renderItemBody(item)}
          </ConditionalLink>
        }
      >
        {item.children.map(renderMenuItem)}
      </InnerLinkSubMenu>
    </Menu>
  );
};

const SubMenu = styled(Menu.SubMenu)`
  &.ant-menu-submenu .ant-menu-submenu-title {
    padding: 0 18px !important;
  }
`;

type MainMenuProps = {
  isCollapsed: boolean;
  items: MenuItem[];
  onClick?: () => void;
  showBadge?: boolean;
  groupTitle?: string;
};

const MainMenu = ({ isCollapsed, items, onClick }: MainMenuProps) => {
  const isSmallScreen = useMediaQuery('mobile');
  const { reportEvent } = useReportEvent();
  const { pathname } = useLocation();

  const activeKey = extractClosestKeyMatch(pathname, items);

  const renderItemBody = (item: MenuItemLink | MenuItemGroup) => (
    <div
      style={{
        overflow: !isCollapsed && !isSmallScreen ? 'hidden' : 'visible',
        textOverflow: 'ellipsis',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
      }}
      className={item.meta?.className}
    >
      {item.icon && <item.icon height={16} width={16} />}
      {!isCollapsed && <span>{item.title}</span>}
    </div>
  );

  const renderMenuItem = (item: MenuItem, index: number) => {
    switch (item.type) {
      case 'link':
        return (
          <Menu.Item
            style={{ paddingLeft: '18px' }}
            key={item.route}
            onClick={() => {
              !!item.route && window.scrollTo(0, 0);

              onClick?.();
              item?.meta?.onClick?.();

              reportEvent('main menu clicked', {
                segment: true,
                highlight: true,
                slack: false,
                payloadMessage: item.title,
              });
            }}
          >
            {<ConditionalLink to={item.route}>{renderItemBody(item)}</ConditionalLink>}
          </Menu.Item>
        );
      case 'group':
        if (item.route !== undefined) {
          return (
            <LinkSubMenu
              key={index}
              item={item}
              activeKey={activeKey}
              renderMenuItem={renderMenuItem}
              renderItemBody={renderItemBody}
            />
          );
        } else {
          return renderMenuItemGroup(item);
        }
      case 'node':
        return <React.Fragment key={index}>{item.node}</React.Fragment>;
      default:
        break;
    }
  };

  const renderMenuItemGroup = (item: MenuItemGroup) => {
    return (
      <SubMenu key={item.title} title={<ConditionalLink to={item.route}>{renderItemBody(item)}</ConditionalLink>}>
        {item.children.map((item, index) => renderMenuItem(item, index))}
      </SubMenu>
    );
  };

  return (
    <Menu
      activeKey={activeKey}
      selectedKeys={[activeKey]}
      defaultOpenKeys={['Nudge', 'Assist']}
      mode="inline"
      theme="light"
      expandIcon={({ isOpen }) => (
        <Caret height={14} width={14} style={{ transform: isOpen ? 'rotate(180deg)' : '' }} viewBox="-4 0 16 6" />
      )}
      id="main-nav-menu"
    >
      {items.map((item, index) => renderMenuItem(item, index))}
    </Menu>
  );
};

export default MainMenu;
