import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.34);
  overflow: hidden;
`;

const Header = styled.div`
  cursor: pointer;
  border-bottom: 1px solid ${CB_COLORS.neutral300};
`;

const Body = styled.div<{ expanded?: boolean; disabled?: boolean }>`
  max-height: ${(props) => (props.expanded ? '200vh' : '0')};
  transition: max-height 0.4s;
  pointer-events: ${(props) => (props.disabled ? 'none' : undefined)};
  opacity: ${(props) => (props.disabled ? '0.5' : undefined)};
`;

interface IAccordionItem {
  onClick: () => void;
  header: React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
  disabled?: boolean;
}

export const AccordionItem = ({ header, onClick, children, expanded, disabled }: IAccordionItem) => {
  return (
    <Container>
      <Header onClick={onClick}>{header}</Header>

      <div style={{ cursor: disabled ? 'not-allowed' : undefined }}>
        <Body disabled={disabled} expanded={expanded}>
          {children}
        </Body>
      </div>
    </Container>
  );
};
