import React from 'react';
import type { INudgeStepContentBlockType, INudgeType } from '@commandbar/internal/middleware/types';
import { findButtonBlocks } from '../utils';
import { CTAForm } from './CTAForm';

interface IButton {
  nudge: INudgeType;
  stepIndex: number;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

export const CTABlock = ({ onBlockChange, onBlockDelete, nudge, stepIndex }: IButton) => {
  const step = nudge.steps[stepIndex];
  const buttonBlocks = findButtonBlocks(step.content).sort((a, b) =>
    b.type === 'button' && b.meta?.button_type === 'secondary' ? -1 : 1,
  );
  return (
    <React.Fragment>
      {buttonBlocks.map((buttonBlock, i) => (
        <CTAForm
          key={`${i}-${buttonBlock.meta?.button_type}`}
          onBlockChange={onBlockChange}
          buttonBlock={buttonBlock}
          onBlockDelete={onBlockDelete}
          nudge={nudge}
          stepIndex={stepIndex}
        />
      ))}
    </React.Fragment>
  );
};
