/**
 * 'match' allows you to implement exhaustive "pattern matching." You will get a compile-time type error if your match is not exhaustive.
 * This is useful for handling heterogenous arrays of objects, for example.
 *
 * Example usage:
 *
 *   type Fruit = "banana" | "orange" | "mango";
 *   type Crate = { type: Fruit }
 *
 *   const crate = {type: 'banana'};
 *
 *   const fruitTypeNumber = match(
 *     (c: Crate) => c.type,
 *     {'banana': (crate: Crate) => 1,
 *     'orange': (crate: Crate) => 2,
 *     'mango': (crate: Crate) => 3}
 *   )(crate); // returns "1" in this case
 *
 * @param getKey  function that returns a key from the object; key must be a string, number, or symbol
 * @param cases   functions to handle each of the keys returned by getKey
 * @returns value returned by the matching case
 */

function match<K extends string | number | symbol, T, R>(getKey: (value: T) => K, cases: Record<K, (value: T) => R>) {
  return (value: T) => {
    return cases[getKey(value)](value);
  };
}

/**
 * Helper used to apply `match` to a list of items.
 *
 * @param getKey  function that returns a key from the object; key must be a string, number, or symbol
 * @param cases   functions to handle each of the keys returned by getKey
 * @returns array of values returned by the matching case
 */
export function matchAll<K extends string | number | symbol, T, R>(
  values: T[],
  getKey: (value: T) => K,
  cases: Record<K, (value: T) => R>,
) {
  const matcher = match(getKey, cases);
  return values.map(matcher);
}
