import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Input, Form } from '@commandbar/design-system/components/antd';
import { FormLayout2 } from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { CmdButton, CmdDivider } from '@commandbar/design-system/cmd';
import { GoogleBrand } from '@commandbar/design-system/icons/react';

export const Login: React.FC = () => {
  const { login, error, setError } = useAuth();
  const history = useHistory();
  const [authType, setAuthType] = React.useState('unknown');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const search = useLocation().search;
  const state = useLocation<any>().state;
  const redirectPath = state?.from;
  const msg = new URLSearchParams(search).get('error');

  const isDev = process.env.IS_PREVIEW || process.env.REACT_APP_API_URL === 'https://api-dev.commandbar.com';

  const [emailValidateType, setEmailValidateType] = React.useState('onBlur');

  const { appsumo_activation_email } = queryString.parse(window.location.search);

  useEffect(() => {
    if (!!redirectPath?.pathname?.length) {
      history.push({ search: `?next=${redirectPath?.pathname}?${redirectPath?.search}`, state });
    }

    if (msg) {
      setError(msg);
    }

    if (appsumo_activation_email) {
      form.setFieldsValue({ email: appsumo_activation_email });
    }
  }, []);

  // FIXME: We should remove this as it allows for an attacker to easily dump emails
  const checkAuthType = async (email: string) => {
    try {
      const data = await Auth.getLoginType(email);
      setAuthType(data);

      if (data === 'google') {
        signInWithGoogle();
      }
    } catch {
      setAuthType('basic');
    }
  };

  const signin = async (username: string, password: string) => {
    const success = await login(username, password);

    if (success) {
      history.push(redirectPath || '/home');
    }
  };

  const onFinish = async (values: any) => {
    if (authType === 'unknown') {
      checkAuthType(values.email);
    } else {
      setLoading(true);
      await signin(values.email, values.password);
      setLoading(false);
    }
  };

  // TODO: Show a message when an account doesn't exist
  const signInWithGoogle = async () => {
    setLoading(true);

    // TODO: Redirect the user after they log in
    // const auth_redirect = `${window.location.origin}/${redirectPath || 'home'}`;

    // TODO: Create a dedicated auth redirect page
    const authorization_url = await Auth.google({
      redirect_uri: window.location.origin,
      is_signup: false,
    });

    if (!authorization_url) {
      setError('Something went wrong');
    } else {
      window.open(authorization_url, '_self');
    }
    setLoading(false);
  };

  return (
    <FormLayout2
      error={error}
      h2={
        !!appsumo_activation_email ? (
          <span>We matched your existing account to your new AppSumo license. Please sign in to continue.</span>
        ) : undefined
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          id="email"
          validateTrigger={emailValidateType}
          rules={[{ type: 'email', max: 30, message: 'Invalid Email Address' }]}
        >
          <Input
            onBlur={() => setEmailValidateType('onChange')}
            placeholder="Enter your email"
            type="email"
            autoComplete="email"
            disabled={!!appsumo_activation_email}
          />
        </Form.Item>

        {authType === 'basic' && (
          <Form.Item label="Password">
            <Form.Item name="password" noStyle>
              <Input.Password placeholder="Enter your password" autoFocus />
            </Form.Item>
            <div style={{ textAlign: 'end' }}>
              <a href={`${process.env.REACT_APP_AUTH_URL}/auth/password_reset/`}>Forgot your password?</a>
            </div>
          </Form.Item>
        )}

        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={loading} loading={loading}>
            Continue
          </CmdButton>
        </Form.Item>
      </Form>
      <CmdDivider spacing="sm" />
      {!isDev && (
        <>
          <CmdButton fullWidth onClick={signInWithGoogle}>
            <GoogleBrand />
            Sign in with Google
          </CmdButton>
          <CmdButton onClick={() => history.push('/login/sso')} fullWidth>
            Sign in with SSO
          </CmdButton>
        </>
      )}

      <div
        style={{
          overflowWrap: 'break-word',
          color: '#8e8e8e',
          fontSize: '12px',
          textAlign: 'center',
          lineHeight: '20px',
        }}
      >
        By signing in, you agree to our <br></br>
        <a href="https://commandbar.com/terms" style={{ color: '#8e8e8e', textDecoration: 'underline' }}>
          Terms of Service
        </a>{' '}
        and&nbsp;
        <a href="https://commandbar.com/privacy" style={{ color: '#8e8e8e', textDecoration: 'underline' }}>
          Privacy Policy
        </a>
      </div>
    </FormLayout2>
  );
};

export default Login;
