import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { SelectProps, Select } from '@commandbar/design-system/components/antd';
import {
  codeFrameworkKeys,
  FrameworkInstructions,
  TCodeFrameworkKey,
} from '../FrameworkInstructions/FrameworkInstructions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const selectStyles: React.CSSProperties = {
  width: '100%',
  height: '40px !important',
  marginBottom: '32px',
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '14px',
};

type TInstructionsOption = { label: string; value: TCodeFrameworkKey };
const options: SelectProps<any, TInstructionsOption>['options'] = [
  {
    label: 'Vanilla JS',
    value: 'vanillajs',
  },
  {
    label: 'React',
    value: 'react',
  },
  {
    label: 'Next.js',
    value: 'nextjs',
  },
  {
    label: 'Angular',
    value: 'angular',
  },
  {
    label: 'Remix',
    value: 'remix',
  },
  {
    label: 'Svelte',
    value: 'svelte',
  },
  {
    label: 'Vue',
    value: 'vue',
  },
  {
    label: 'Ruby on Rails',
    value: 'rubyOnRails',
  },
];

const ACTIVE_KEY_NAME = 'instructions';
export const CodeInstructions = ({ organizationUid }: { organizationUid: IOrganizationType['id'] }) => {
  const defaultKey: TCodeFrameworkKey = 'vanillajs';
  const history = useHistory();
  const [activeKey, setActiveKey] = React.useState<TCodeFrameworkKey>(() => {
    const params = new URLSearchParams(history.location.search);
    const defaultPathname = `${history.location.pathname}?${ACTIVE_KEY_NAME}=${defaultKey}`;
    const key = params.get(ACTIVE_KEY_NAME);
    if (!key) {
      history.replace(defaultPathname);
      return defaultKey;
    } else {
      const validInstructionsKey = codeFrameworkKeys.find((validKey) => validKey === key);
      if (!!validInstructionsKey) {
        return validInstructionsKey;
      } else {
        history.replace(defaultPathname);
        return defaultKey;
      }
    }
  });

  const onChange = (key: TCodeFrameworkKey) => {
    setActiveKey(key);
    history.replace(`${history.location.pathname}?${ACTIVE_KEY_NAME}=${key}`);
  };

  return (
    <Container>
      <Select options={options} defaultValue={activeKey} onChange={onChange} style={selectStyles} />
      <FrameworkInstructions organizationUid={organizationUid} frameworkKey={activeKey} />
    </Container>
  );
};
