import React from 'react';
import { CardBase } from './CardBase';
import { CodeSquare02 } from '@commandbar/design-system/icons/react';
import { ICON_WIDTH } from './styles';
import styled from '@emotion/styled';

const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;

  color: #000000;
`;

const COPY = 'copy';
const COPIED = 'copied!';

export const ShareInstructionsCard = () => {
  const [copyText, setCopyText] = React.useState(COPY);
  const onClick: React.MouseEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const installationLink = 'https://www.commandbar.com/docs/platform/installation/installing-in-web-app/';
    try {
      await navigator.clipboard.writeText(installationLink);
      setCopyText(COPIED);
      setTimeout(() => {
        setCopyText(COPY);
      }, 1000);
    } catch (err) {
      /**
       * do nothing
       */
    }
  };
  return (
    <CardBase
      title="Share install instructions"
      description="Get a link to instructions you can share in Slack, email, etc"
      onClick={onClick}
      leftIcon={<CodeSquare02 width={ICON_WIDTH} color="#31B255" />}
      rightIcon={<Text>{copyText}</Text>}
      background="linear-gradient(96.46deg, #E5F5EA -5.42%, #FFFFFF 18.75%, #FFFFFF 24.12%);"
      iconShadow="0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(49, 178, 85, 0.16), inset 1.5px -1.5px 3px rgba(49, 178, 85, 0.25)"
    />
  );
};
