import React, { useMemo } from 'react';

import { InputContainer, StyledLabel } from '@commandbar/design-system/components/antd';
import type { TriggerableEntity } from '@commandbar/internal/middleware/helpers/pushTrigger';
import type { INudgeAnchorableStepType } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { Panel } from '.';
import { AudienceSelect } from '../../AudienceSelector';
import ConditionGroupEditor from '../../conditions/ConditionGroupEditor';
import { CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO } from '../../conditions/categories';
import { AvailabilityDependency } from '../../conditions/components/AvailabilityDependency';
import { TypographyCode } from '../../nudges/styled';
import { Section } from './Section';
import { BorderedContainer } from './styled';

interface AudienceSelectorProps {
  audience: NonNullable<TriggerableEntity['audience']>;
  handleAudienceChange: (audience: TriggerableEntity['audience']) => void;
}

export const AudienceSelector = ({ audience, handleAudienceChange }: AudienceSelectorProps) => {
  const { rules } = useAppContext();

  const verboseAudience = useMemo(() => {
    switch (audience.type) {
      case 'all_users':
        return 'All users';
      case 'rule_expression':
        return 'Custom audience';
      case 'named_rule_reference':
        return rules.find((rule) => rule.id === audience.rule_reference.rule_id)?.name || 'Custom rule';
      default:
        break;
    }

    return '';
  }, [audience, rules]);

  return (
    <Section sectionKey={Panel.Audience} heading="Who" subheading={verboseAudience} defaultExpanded>
      <InputContainer>
        <StyledLabel>Audience</StyledLabel>
        <AudienceSelect rules={rules} audience={audience} onChange={handleAudienceChange} />
      </InputContainer>
    </Section>
  );
};

interface ExpressionSelectorProps {
  showExpression: TriggerableEntity['show_expression'];
  handleShowExpressionChange: (showExpression: TriggerableEntity['show_expression']) => void;
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
  anchorableSteps?: INudgeAnchorableStepType[];
}

export const ExpressionSelector = ({
  showExpression,
  handleShowExpressionChange,
  onStartClickRecorder,
  anchorableSteps,
}: ExpressionSelectorProps) => {
  const { isStudio } = useAppContext();

  return (
    <Section sectionKey={Panel.Audience} heading="Who" defaultExpanded>
      {
        <InputContainer>
          {/* legacy -- remove when all triggerables have 'audience' defined */}
          <StyledLabel>Conditions</StyledLabel>
          <BorderedContainer>
            {anchorableSteps?.map(({ form_factor }, i) => (
              <AvailabilityDependency
                key={i}
                type="element"
                field={form_factor.anchor}
                operator="exists on the page"
                inputStyle={{ height: '24px' }}
                message={
                  <span>
                    Element {<TypographyCode code>{form_factor.anchor}</TypographyCode>} must be present on the DOM for
                    the command to be available.
                  </span>
                }
              />
            ))}
            <ConditionGroupEditor
              onChange={handleShowExpressionChange}
              expr={showExpression}
              path="show_expression"
              onStartClickRecorder={isStudio ? onStartClickRecorder : undefined}
              includeCategories={CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO}
              excludeConditionTypes={['named_rule']}
            />
          </BorderedContainer>
        </InputContainer>
      }
    </Section>
  );
};
