import React from 'react';
import { DashedBorderWrapper, Modal } from '@commandbar/design-system/components/antd';
import { AlertTriangle, Plus } from '@commandbar/design-system/icons/react';
import useChatSettings from '../useEditor/useChatSettings';
import { ICopilotFallback } from '@commandbar/internal/middleware/types';
import { CmdButton } from '@commandbar/design-system/cmd';
import FallbackForm from './FallbackForm';

const FallbackSettings = () => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const { copilotFallbacks, actions } = useChatSettings();

  const handleSaveFallback = (updatedFallback: ICopilotFallback) => {
    actions.copilotFallbacks.update(updatedFallback);
  };

  const handleDeleteFallback = (id: number, index: number) => {
    Modal.confirm({
      icon: (
        <AlertTriangle
          height={22}
          width={22}
          color="#FAAD14"
          style={{
            float: 'left',
            marginRight: '16px',
          }}
        />
      ),
      title: 'Are you sure you want to delete this fallback? This cannot be undone.',
      async onOk() {
        setActiveKeys((keys) => keys.filter((key) => key !== `fallback-${index}`));
        actions.copilotFallbacks.delete(id);
      },
    });
  };

  const handleAddFallback = () => {
    const newFallback: ICopilotFallback = {
      id: -1, // Temporary ID for new fallbacks
      title: 'New Fallback',
      trigger_event: 'no_answer',
      audience: { type: 'all_users' },
      message: '',
      ctas: [],
      is_default: false,
    };
    actions.copilotFallbacks.create(newFallback);
    setActiveKeys((keys) => [...keys, `fallback-${copilotFallbacks.length}`]);
  };

  const toggleActiveKey = (index: number) => {
    setActiveKeys((keys) => {
      return activeKeys.includes(`fallback-${index}`)
        ? keys.filter((key) => key !== `fallback-${index}`)
        : [...keys, `fallback-${index}`];
    });
  };

  return (
    <div>
      {copilotFallbacks.map((fallback, index) => {
        return (
          <FallbackForm
            key={`fallback-${index}`}
            fallback={fallback}
            index={index}
            activeKeys={activeKeys}
            handleSaveFallback={handleSaveFallback}
            handleDeleteFallback={handleDeleteFallback}
            toggleActiveKey={toggleActiveKey}
          />
        );
      })}
      <DashedBorderWrapper
        style={{
          background: '#F9F9F9',
        }}
      >
        <CmdButton icon={<Plus />} onClick={handleAddFallback}>
          Add fallback
        </CmdButton>
      </DashedBorderWrapper>
    </div>
  );
};

export default FallbackSettings;
