import React, { createContext, useContext, useState } from 'react';

import type { IUsage } from '@cb/types/entities/internal/billing';

import { Billing } from '@commandbar/internal/middleware/billing';

import { useReloadable } from './useReloadable';

type UsageContextValue = {
  usage: IUsage | null;
  exceeding: {
    isAtOrOverLiveNudges: boolean;
    isAtOrOverLiveQuestlists: boolean;
  };
  isLoading: boolean;
  refetch: () => Promise<void>;
};

export const UsageContext = createContext<UsageContextValue>({
  usage: null,
  exceeding: {
    isAtOrOverLiveNudges: false,
    isAtOrOverLiveQuestlists: false,
  },
  isLoading: true,
  refetch: async () => {
    console.warn('UsageContext has not been initialized yet. This is a noop.');
  },
});

export const UsageProvider: React.FC = ({ children }) => {
  const [usage, setUsage] = useState<IUsage | null>(null);

  const [isLoading, refetch] = useReloadable<IUsage>(
    async () => {
      return await Billing.readUsage();
    },
    setUsage,
    [],
  );

  const contextValue = {
    usage,
    exceeding: {
      isAtOrOverLiveNudges: Boolean(
        usage && usage.nudges_live_limit !== 0 && usage.nudges_live_usage >= usage.nudges_live_limit,
      ),
      isAtOrOverLiveQuestlists: Boolean(
        usage && usage.questlists_live_limit !== 0 && usage.questlists_live_usage >= usage.questlists_live_limit,
      ),
    },
    isLoading,
    refetch,
  };

  return <UsageContext.Provider value={contextValue}>{children}</UsageContext.Provider>;
};

export const useUsage = () => {
  return useContext(UsageContext);
};
