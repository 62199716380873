import React from 'react';
import { handleStyleElementClick } from './helpers';
import { SkeletonProps } from './types';

const QuestlistView = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      width="435"
      height="377"
      viewBox="0 0 435 377"
      fill="none"
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
    >
      <g id="ql">
        <rect width="435" height="377" fill="white" />
        <g id="bar-styles-checklists">
          <g filter="url(#filter0_d_218_740)">
            <rect x="28" y="33" width="380" height="312" rx="8" fill="white" />
            <rect x="28.5" y="33.5" width="379" height="311" rx="7.5" stroke="#DFDFE2" />
          </g>
          <g id="ql-header">
            <path
              d="M36 33.5H400C404.142 33.5 407.5 36.8579 407.5 41V88.5H28.5V41C28.5 36.8579 31.8579 33.5 36 33.5Z"
              fill="#F7F7F8"
              stroke="#DFDFE2"
            />
            <path
              d="M187.058 54.6698L190.79 51.0771C190.897 50.9743 191.102 50.9743 191.21 51.0771L194.942 54.6698C195.081 54.8038 194.956 55 194.732 55H187.268C187.044 55 186.919 54.8038 187.058 54.6698Z"
              fill="#D5D5DD"
            />
            <rect x="43" y="47" width="133" height="15" rx="3" fill="#D5D5DD" />
            <rect x="43" y="67" width="63" height="10" rx="3" fill="#DCDCE2" />
          </g>
          <g id="ql-progress">
            <rect
              x="0.5"
              y="-0.5"
              width="379"
              height="31"
              transform="matrix(1 0 0 -1 28 119)"
              fill="white"
              stroke="#DFDFE2"
            />
            <rect x="43" y="98" width="22" height="11.4286" rx="3" fill="#DCDCE2" />
            <rect x="75" y="98" width="320" height="11.4286" rx="5.71428" fill="#DCDCE2" />
            <rect x="75" y="98" width="108" height="11.4286" rx="5.71428" fill="#6875FE" />
          </g>
          <g>
            <g id="bar-styles-checklistItem">
              <rect
                x="0.5"
                y="-0.5"
                width="379"
                height="135"
                transform="matrix(1 0 0 -1 28 254)"
                fill="white"
                stroke="#DFDFE2"
              />
              <rect x="74" y="141" width="64" height="15" rx="3" fill="#D5D5DD" />
              <rect x="74" y="211" width="80" height="21" rx="3" fill="#0A0A0F" />
              <rect x="146" y="141" width="131" height="15" rx="3" fill="#D5D5DD" />
              <rect x="75" y="165" width="227" height="12" rx="3" fill="#DCDCE2" />
              <rect x="75" y="186" width="143" height="12" rx="3" fill="#DCDCE2" />
              <rect x="310" y="165" width="63" height="12" rx="3" fill="#DCDCE2" />
              <path
                id="checkbox (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55 141H47C45.3431 141 44 142.343 44 144V152C44 153.657 45.3431 155 47 155H55C56.6569 155 58 153.657 58 152V144C58 142.343 56.6569 141 55 141ZM47 140C44.7909 140 43 141.791 43 144V152C43 154.209 44.7909 156 47 156H55C57.2091 156 59 154.209 59 152V144C59 141.791 57.2091 140 55 140H47Z"
                fill="#CDCDD0"
              />
            </g>
            <g id="ql-item-non-expanded">
              <rect
                x="0.5"
                y="-0.5"
                width="379"
                height="44"
                transform="matrix(1 0 0 -1 28 298)"
                fill="white"
                stroke="#DFDFE2"
              />
              <rect x="74" y="269" width="64" height="15" rx="3" fill="#D5D5DD" />
              <rect x="146" y="269" width="131" height="15" rx="3" fill="#D5D5DD" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55 270H47C45.3431 270 44 271.343 44 273V281C44 282.657 45.3431 284 47 284H55C56.6569 284 58 282.657 58 281V273C58 271.343 56.6569 270 55 270ZM47 269C44.7909 269 43 270.791 43 273V281C43 283.209 44.7909 285 47 285H55C57.2091 285 59 283.209 59 281V273C59 270.791 57.2091 269 55 269H47Z"
                fill="#CDCDD0"
              />
            </g>
            <rect x="327" y="311" width="63" height="21" rx="3" fill="#D5D5DD" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_218_740"
          x="20"
          y="33"
          width="396"
          height="334"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="10" operator="erode" in="SourceAlpha" result="effect1_dropShadow_218_740" />
          <feOffset dy="14" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_218_740" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_218_740" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default QuestlistView;
