import {
  IChecklist,
  INudgeType,
  IRuleExpression,
  IRecommendationSet,
  ICommandTypeLite,
} from '@commandbar/internal/middleware/types';
import { getSentry } from '@commandbar/internal/util/sentry';
import { Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import { CBStore } from 'shared/store/global-store';
import { isRemoteEnduserStoreEnabled } from 'shared/store/end-user/selectors';
import evaluateRules, { evaluateRuleExpression } from '@commandbar/internal/client/RulesParser';
import { IRule } from '@commandbar/internal/middleware/helpers/rules';
import { isSilentMode } from 'shared/services/analytics/helpers';

export const trackDecideError = (
  _: CBStore,
  experience: IChecklist | INudgeType | IRecommendationSet | ICommandTypeLite,
  error: Error,
) => {
  getSentry()?.withScope((scope) => {
    scope.setTag('feature:server-side-targeting', 'true');

    scope.setContext('experience', experience);
    scope.setContext('endUser', {
      identifier: _.endUser?.identifier,
    });
    scope.setContext('endUserStore', {
      store: _.endUserStore,
    });
    scope.setContext('remoteDecideResult', _.decide || {});

    getSentry()?.captureException(error);
  });
};

type RunBooleanExpressionContext = {
  eventProperties?: Metadata;
};

export const runBooleanExpression = (
  expr: IRuleExpression,
  _: CBStore,
  prefix = '',
  context: RunBooleanExpressionContext = {},
) => {
  const { eventProperties = {} } = context;

  const failed = (msg: string, reasonIsUserDefined = false) => {
    return { passed: false, reason: msg, reasonIsUserDefined };
  };

  const passed = () => {
    return { passed: true, reason: '', reasonIsUserDefined: false };
  };
  try {
    const isEndUserAnalyticsAvailable = isRemoteEnduserStoreEnabled(_) && isSilentMode() !== true;
    const result = evaluateRuleExpression(
      expr,
      _.context,
      _.location,
      _.endUserStore.data.analytics,
      _.endUserStore.data.properties,
      {
        nudges: _.endUserStore.data.nudges_interactions,
        checklists: _.endUserStore.data.checklist_interactions.checklists,
      },
      isEndUserAnalyticsAvailable,
      Object.keys(_?.organization?.integrations?.heap?.segments || {}),
      Object.keys(_?.organization?.integrations?.hubspot?.lists || {}),
      Object.keys(_?.organization?.integrations?.amplitude?.cohorts || {}),
      eventProperties,
    );

    if (result.passed) {
      return passed();
    } else {
      if (result.userDefinedReason) {
        return failed(result.userDefinedReason, true);
      } else {
        return failed(`${prefix}: Rules were not met.`);
      }
    }
  } catch (e) {
    return failed(`${prefix}: Something went wrong in parsing rules. ${String(e)}`);
  }
};

// DEPRECATED
export const runBooleanConditions = (conditions: IRule[], _: CBStore, prefix: string) => {
  const failed = (msg: string, reasonIsUserDefined = false) => {
    return { passed: false, reason: msg, reasonIsUserDefined };
  };

  const passed = () => {
    return { passed: true, reason: '', reasonIsUserDefined: false };
  };

  try {
    const result = evaluateRules(
      conditions,
      _.context,
      _.location,
      _.endUserStore.data.analytics,
      _.endUserStore.data.properties,
      {
        nudges: _.endUserStore.data.nudges_interactions,
        checklists: _.endUserStore.data.checklist_interactions.checklists,
      },
    );
    if (result.passed) {
      return passed();
    } else {
      const userDefinedReason = result.failedRules?.find((rule) => !!rule.reason)?.reason;

      if (userDefinedReason) {
        return failed(userDefinedReason, true);
      } else {
        return failed(`${prefix}: Rules were not met.`);
      }
    }
  } catch (e) {
    return failed(`${prefix}: Something went wrong in parsing rules. ${String(e)}`);
  }
};
