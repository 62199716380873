import React from 'react';
import styled from '@emotion/styled';

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconContainer = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <IconContainer {...props}>{children}</IconContainer>
);

export default StyledIconContainer;
