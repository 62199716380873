import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { IUserContext } from '@commandbar/internal/middleware/types';

import { Tooltip, Modal } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import JSONEditor from '../../components/JSONEditor';
import { ContextPanelHeader } from './ContextPanelHeader';
import { getCommandsByContextKey } from '../../context/helpers';
import { CommandsByContextKeyTable } from './ContextPanel';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { CheckCircle, InfoCircle, RefreshCw01 } from '@commandbar/design-system/icons/react';
import { CmdButton, CmdTag } from '@commandbar/design-system/cmd';

type VariableTagsProps = {
  missingArgs: string[];
  onMissingButtonClick: () => void;
};

const VariableTags = (props: VariableTagsProps) => {
  const { missingArgs, onMissingButtonClick } = props;

  return (
    <span key="context-tags" style={{ marginTop: '-3px' }} onClick={onMissingButtonClick}>
      {missingArgs.length > 0 ? (
        <Tooltip
          content={`There are ${missingArgs.length} key${
            missingArgs.length > 1 ? 's' : ''
          } referenced by commands that are missing in metadata. Click to learn more.`}
        >
          <CmdTag variant="failure" prefixElement={<InfoCircle />}>
            {`${missingArgs.length} missing`}
          </CmdTag>
        </Tooltip>
      ) : (
        <CmdTag variant="success" prefixElement={<CheckCircle />}>
          All metadata keys included
        </CmdTag>
      )}
    </span>
  );
};

export const InspectMetadata = () => {
  const { context: windowContext, reload: reloadWindowContext } = useWindowInfo();
  const { commands } = useAppContext();
  const [context, setContext] = useState<IUserContext>({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setContext(windowContext);
  }, [windowContext]);

  const onChange = (obj: any) => {
    const newContext = obj.jsObject;

    setContext(newContext);
  };

  const commandsByContextKey = getCommandsByContextKey(commands);
  const missingArgs = Object.keys(commandsByContextKey).filter((arg: string) => !get(context, arg));

  return (
    <>
      <ContextPanelHeader
        id={0}
        name="Current metadata (readonly)"
        canEdit={false}
        onNameChange={() => {
          return {};
        }}
        showRadio={false}
        action={[
          <VariableTags
            key="variable-tags"
            missingArgs={missingArgs}
            onMissingButtonClick={() => setShowModal(true)}
          />,
          <RefreshCw01 key="context-reload" onClick={reloadWindowContext} />,
        ]}
      />
      <JSONEditor contextID={0} json={context} onChange={onChange} readOnly height={600} />
      <Modal
        visible={showModal}
        closable={false}
        footer={[
          <CmdButton
            key="footer-button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </CmdButton>,
        ]}
        okText={null}
        mask={false}
        width={400}
      >
        <CommandsByContextKeyTable commandsByContextKey={commandsByContextKey} context={context} />
      </Modal>
    </>
  );
};
