import { useEffect } from 'react';

import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { useReportEvent } from '../hooks/useEventReporting';
import {
  SetDefaultOrganizationIdMessage,
  ShareStudioAuthMessage,
} from '@commandbar/internal/client/extension/messages';
import Auth from '@commandbar/internal/client/authentication';

const ShareDataWithCommandBarChromeExtension = ({ organization }: { organization: IOrganizationType }) => {
  const { reportEvent } = useReportEvent();

  const shareData = async () => {
    if (!organization?.id) return;

    const response = await SetDefaultOrganizationIdMessage.sendToExtensionFromStudio({
      defaultOrganizationId: organization.id.toString(),
    });

    if (response?.status === 'ok') {
      reportEvent('extension installed', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: 'Chrome Extension Is Installed',
      });
    }

    const token = await Auth.token();
    await ShareStudioAuthMessage.sendToExtensionFromStudio(
      token
        ? {
            token,
            organizationId: organization.id,
          }
        : null,
    );
  };

  // When the Studio tab is loaded before the extension is installed, the user can get into a state where the extension
  // is installed but the Studio tab doesn't know about it. This effect will share the data with the extension when the
  // Studio tab is focused to get that update propagated.
  // This effect is also needed for a race condition when the extension automatically puts the tab into focus after installing
  useEffect(() => {
    window.addEventListener('focus', shareData);
    return () => {
      window.removeEventListener('focus', shareData);
    };
  }, []);

  useEffect(() => {
    if (organization.id) shareData();
  }, [organization.id]);

  return null;
};

export default ShareDataWithCommandBarChromeExtension;
