import React from 'react';
import { GetVersionMessage } from '@commandbar/internal/client/extension/messages';

interface UseIsChromeExtensionInstalled {
  isChromeExtensionInstalled: boolean;
}

export const useIsChromeExtensionInsalled = (): UseIsChromeExtensionInstalled => {
  const [isChromeExtensionInstalled, setIsChromeExtensionInstalled] = React.useState<boolean>(false);

  React.useEffect(() => {
    const getIsChromeExtensionInstalled = () => {
      GetVersionMessage.sendToExtensionFromStudio().then((response) => {
        if (response?.status === 'ok') {
          setIsChromeExtensionInstalled(true);
        }
      });
    };

    getIsChromeExtensionInstalled();

    window.addEventListener('focus', getIsChromeExtensionInstalled);
    return () => {
      window.removeEventListener('focus', getIsChromeExtensionInstalled);
    };
  }, []);

  return { isChromeExtensionInstalled };
};
