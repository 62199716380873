import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CmdButton, CmdTypography } from '@commandbar/design-system/cmd';
import { Layout, Page as StyledPage, Typography } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import Spinner from 'commandbar.com/src/components/table/Spinner';
import { useAppContext } from 'editor/src/AppStateContext';

const Body = () => {
  const { organization } = useAppContext();
  const [integrations, setIntegrations] = useState<IOrganizationType['integrations']>();
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  useEffect(() => {
    setIsLoadingForm(true);
    Organization.readInternal(organization.id.toString())
      .then((org) => {
        setIntegrations(org.integrations);
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  }, [organization.id]);

  if (isLoadingForm) {
    return (
      <Spinner>
        <Loading01 />
      </Spinner>
    );
  }

  if (integrations?.sunshine?.enabled) {
    return (
      <div>
        <Typography.Title level={4}>Zendesk Chat Handoff</Typography.Title>

        <CmdTypography.Body>Please reach out to support to disable this integration.</CmdTypography.Body>
      </div>
    );
  }

  return (
    <div>
      <Typography.Title level={4}>Enable Zendesk Chat Handoff</Typography.Title>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <CmdTypography.Body>
            Our Zendesk chat integration uses the Sunshine Conversations API. We'll integrate Copilot into your existing
            switchboard or work with you to create a new one.
          </CmdTypography.Body>
          <CmdTypography.Body>To enable, please reach out to support.</CmdTypography.Body>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <CmdTypography.Body>
              We'll need a <strong>Conversations API key</strong> with the following info to get started:
            </CmdTypography.Body>

            <ul>
              <li>App ID</li>
              <li>Key ID</li>
              <li>Secret key</li>
            </ul>
          </div>
        </div>

        <Link to="/integrations">
          <CmdButton>Back</CmdButton>
        </Link>
      </div>
    </div>
  );
};

const Page = () => (
  <StyledPage section="Integrations" title="Zendesk" description="Handoff Copilot chats to Zendesk agents.">
    <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Body />
        </div>
      </div>
    </Layout.Content>
  </StyledPage>
);

export default Page;
