import React from 'react';
import { showToast } from 'shared/util/hooks/useToast';
import { ref } from 'valtio';

import type { Coordinates2D } from '@commandbar/commandbar/products/nudges/components/utils';
import type { INudgePinStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { getSentry } from '@commandbar/internal/util/sentry';
import type { CBStore } from 'shared/store/global-store';
import { interpolateObject } from 'shared/util/Interpolate';
import { continueNudge, dismissNudge, dismissNudgeTemporarily } from '../store/actions';
import {
  getNudgeService,
  getPinAnchors,
  isBannerStep,
  isCursorStep,
  isNudgeDismissible,
  isTooltipStep,
} from '../store/selectors';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import Pin from './Pin';
import { Cursor } from './Pin/Cursor';
import { DraggablePopover } from './Popover';
import { Tooltip } from './Tooltip';
import { showBanner } from './Banner/BannerContext';
import Banner from './Banner/Banner';

export enum RenderMode {
  DEFAULT,
  MOCK,
}

type RenderNudge = (
  _: CBStore,
  nudge: INudgeType,
  stepIndex?: number,
  options?: Partial<{
    renderMode: RenderMode.MOCK;
    forceOpen: boolean;
    anchorOverride: string;
    source: {
      animateIn: boolean;
      startPosition: Coordinates2D;
    };
  }>,
) => void;

export const renderNudge: RenderNudge = (_, nudge, stepIndex = 0, options = {}) => {
  const renderMode = options?.renderMode ?? RenderMode.DEFAULT;

  nudge = interpolateObject({ s: nudge, _, interpolateContext: true, interpolateArgs: false });
  const dismissible = isNudgeDismissible(nudge);

  const step = getNudgeStep(nudge, stepIndex);

  const stepCount =
    nudge.show_step_counter && stepIndex !== undefined && nudge.steps.length > 1
      ? `${stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  const onEscapeKeyDown = () => {
    if (dismissible) {
      dismissNudge(_, nudge, renderMode);
    }
  };

  switch (step?.form_factor.type) {
    case 'popover': {
      if (step.form_factor.position === 'center' || isStudioPreview()) {
        showToast(`${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`, {
          element: (
            <DraggablePopover
              nudge={nudge}
              renderMode={renderMode}
              stepCount={stepCount}
              stepIndex={stepIndex}
              center
            />
          ),
          position: 'center',
          duration: Infinity,
          onEscapeKeyDown,
        });
      } else {
        showToast(`${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`, {
          element: (
            <DraggablePopover nudge={nudge} renderMode={renderMode} stepCount={stepCount} stepIndex={stepIndex} />
          ),
          position: step.form_factor.position,
          duration: Infinity,
          onEscapeKeyDown,
        });
      }
      break;
    }
    case 'modal': {
      _.currentModalNudge = ref({
        nudge,
        stepIndex,
        stepCount,
        renderMode,
      });
      break;
    }
    case 'pin': {
      const { offset: nudgeOffset, is_open_by_default, is_showing_mask } = step.form_factor;
      const isOpenByDefault = is_open_by_default === undefined ? true : is_open_by_default;
      const isShowingMask = !!is_showing_mask;
      const offset = nudgeOffset === undefined ? { x: '0px', y: '0px' } : nudgeOffset;

      if (options.source?.animateIn && isCursorStep(nudge, step)) {
        showToast(`${nudge.id}-${String(step.id)}`, {
          element: (
            <Cursor
              nudge={nudge}
              step={step}
              sourceCoordinates={options.source.startPosition}
              handleDestroy={() => dismissNudge(_, nudge, renderMode)}
              onTargetElementClick={(e) => {
                const { targetEl, advanceTriggerEl } = getPinAnchors(_, {
                  step,
                  anchorOverride: options.anchorOverride,
                });
                /* eslint-disable commandbar/no-event-target */
                const target = e.target as Node;
                if (advanceTriggerEl?.contains(target) || targetEl?.contains(target)) {
                  if (_.extension.recorder.enabled) {
                    dismissNudgeTemporarily(_, {
                      nudge,
                      renderMode,
                      stepIndex,
                      anchorOverride: options.anchorOverride,
                    });
                  } else {
                    const service = getNudgeService(_, nudge.id);
                    service?.send({ type: 'ADVANCE', isPinClick: true });
                  }
                }
              }}
            />
          ),
          onEscapeKeyDown,
          onEnterKeyDown: () => continueNudge(_, nudge),
        });
      } else {
        showToast(`${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`, {
          element: (
            <Pin
              pin={{
                nudge,
                step: step as INudgePinStepType,
                isOpenByDefault,
                isShowingMask,
                offset,
              }}
              renderMode={renderMode}
              stepCount={stepCount}
              stepIndex={stepIndex}
              anchorOverride={options?.anchorOverride}
            />
          ),
          duration: Infinity,
          onEscapeKeyDown,
          onEnterKeyDown: () => continueNudge(_, nudge),
          shouldStealFocus: isShowingMask,
        });
      }

      break;
    }
    case 'tooltip': {
      if (isTooltipStep(step)) {
        const id = `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`;

        showToast(id, {
          asChild: true,
          element: (
            <Tooltip
              forceOpen={options?.forceOpen}
              nudge={nudge}
              renderMode={renderMode}
              step={step}
              anchorOverride={options?.anchorOverride}
              handleDestroy={() => dismissNudge(_, nudge, renderMode)}
            />
          ),
        });
      }
      break;
    }
    case 'banner': {
      if (isBannerStep(step)) {
        const id = `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`;
        showBanner(id, {
          element: <Banner nudge={nudge} stepIndex={stepIndex} renderMode={renderMode} />,
          sticky: step?.form_factor.placement === 'sticky',
          position: step?.form_factor.position,
        });
      }
      break;
    }
    default: {
      getSentry()?.captureMessage(`Unknown form factor type: ${step?.form_factor}`, 'error', {
        captureContext: {
          tags: {
            product: 'nudges',
          },
        },
      });
      showToast(`${nudge.id}-${String(step?.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`, {
        element: <DraggablePopover nudge={nudge} renderMode={renderMode} stepCount={stepCount} stepIndex={stepIndex} />,
        position: 'top-right',
        duration: Infinity,
        onEscapeKeyDown,
      });
    }
  }
};
