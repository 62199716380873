// DEMO: https://daniel-lundin.github.io/react-dom-confetti/

import * as React from 'react';
import ConfettiDOM from 'react-dom-confetti';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import type { IConfettiConfig } from '@commandbar/internal/middleware/confetti';

import Z from '@commandbar/internal/client/Z';

const CB_CONFETTI_EVENT = 'CommandBar.Confetti';

const DEFAULT_CONFIG: IConfettiConfig = {
  spread: 360,
};

export const releaseConfetti = (customConfig?: IConfettiConfig | boolean) => {
  const detail =
    !!customConfig && typeof customConfig !== 'boolean'
      ? {
          ...DEFAULT_CONFIG,
          ...customConfig,
        }
      : DEFAULT_CONFIG;

  dispatchCustomEvent(CB_CONFETTI_EVENT, { detail });
};

const Confetti = () => {
  const [active, setActive] = React.useState(false);
  const [config, setConfig] = React.useState(DEFAULT_CONFIG);

  React.useEffect(() => {
    const listen = (e: any) => {
      const detail = e?.detail ?? DEFAULT_CONFIG;

      setConfig(detail);

      setActive(true);

      setTimeout(() => setActive(false), 30);
    };

    document.addEventListener(CB_CONFETTI_EVENT, listen);
    return () => {
      document.removeEventListener(CB_CONFETTI_EVENT, listen);
    };
  }, [active]);

  return (
    <div style={{ position: 'absolute', top: '35vh', right: '50vw', zIndex: Z.Z_CONFETTI }}>
      <ConfettiDOM active={active} config={config} />
    </div>
  );
};

export default Confetti;
