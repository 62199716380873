// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Pin01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6.878 4.194c-.294-.735-.441-1.102-.38-1.397a1 1 0 0 1 .427-.63C7.175 2 7.572 2 8.363 2h7.274c.791 0 1.187 0 1.439.167a1 1 0 0 1 .426.63c.061.295-.086.662-.38 1.397l-1.008 2.52c-.042.107-.064.16-.079.215a.997.997 0 0 0-.029.15c-.006.056-.006.114-.006.23v2.13c0 .208 0 .312.02.411a1 1 0 0 0 .09.254c.046.09.11.172.24.334l1.57 1.962c.666.833.999 1.249 1 1.599a1 1 0 0 1-.377.782c-.273.219-.806.219-1.872.219H7.329c-1.066 0-1.599 0-1.872-.219A1 1 0 0 1 5.08 14c0-.35.333-.766.999-1.599l1.57-1.962a2.03 2.03 0 0 0 .24-.334.985.985 0 0 0 .09-.254c.02-.1.02-.203.02-.411V7.31c0-.116 0-.174-.007-.23a1 1 0 0 0-.028-.15c-.015-.055-.037-.108-.08-.215l-1.007-2.52Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15v7M8 7.308v2.13c0 .209 0 .313-.02.412a1.001 1.001 0 0 1-.09.254c-.046.09-.11.172-.24.334L6.08 12.4c-.666.833-.999 1.249-1 1.599a1 1 0 0 0 .377.782C5.73 15 6.263 15 7.329 15h9.342c1.066 0 1.599 0 1.872-.219A1 1 0 0 0 18.92 14c0-.35-.333-.766-.999-1.599l-1.57-1.962a2.03 2.03 0 0 1-.24-.334 1 1 0 0 1-.09-.254C16 9.75 16 9.647 16 9.44V7.31c0-.116 0-.174.006-.23.006-.05.016-.1.03-.15.014-.055.036-.108.078-.215l1.008-2.52c.294-.735.441-1.102.38-1.397a1 1 0 0 0-.426-.63C16.824 2 16.428 2 15.636 2H8.364c-.791 0-1.187 0-1.438.167a1 1 0 0 0-.427.63c-.061.295.086.662.38 1.397l1.008 2.52c.042.107.064.16.079.215a1 1 0 0 1 .028.15c.007.056.007.114.007.23Z"
    />
  </svg>
);
export default Pin01;
