import React from 'react';
import { INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { isConditionGroupValid } from '../conditions/validate';
import dayjs from 'dayjs';
import { isValidStartPageUrl } from '@commandbar/internal/client/share_links';
import { useUsage } from 'editor/src/hooks/useUsage';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { findButtonBlocks, findHelpDocBlocks, findImageBlocks, findVideoBlocks, isSurveyBlock } from './utils';

import {
  isCTALinkValid,
  isCTAClickValid,
  isCTANudgeValid,
  isCTAChecklistValid,
  isCTAHelpDocValid,
  isCTAActionValid,
  isCTAGoToStepValid,
  isCTAChatValid,
  isCTALabelValid,
} from './helpers';

interface ValidationError {
  condition: boolean;
  message: string;
  step?: INudgeStepType;
  name?: string;
}

export const useTourDraftErrors = (initialNudge: INudgeType, dirty: INudgeType): Array<ValidationError> => {
  const { exceeding } = useUsage();
  const { user } = useAuth();

  const startPageUrlError = !!dirty.share_page_url_or_path
    ? isValidStartPageUrl(dirty.share_page_url_or_path, !dirty.audience ? null : dirty.show_expression) // do not evaluate on page targeting if pre-audience nudge because the page targeting condition could also include other condition types
    : { isValid: true, error: '' };
  const isAllowedToSave = hasRequiredRole(user, initialNudge.is_live ? 'editor' : 'contributor');

  return [
    {
      condition: dirty.trigger.type === 'on_command_execution' && !dirty.trigger.meta.command,
      message: 'Trigger: Command must be set.',
    },
    {
      condition: !isConditionGroupValid(dirty.show_expression),
      message: 'Some Targeting ("Where") conditions are invalid.',
    },
    {
      condition: dirty.audience?.type === 'rule_expression' ? !isConditionGroupValid(dirty.audience.expression) : false,
      message: 'Custom Audience for Targeting has invalid conditions.',
    },
    {
      condition: dirty.trigger.type === 'on_event' && !dirty.trigger.meta.event,
      message: 'Trigger: Event name must be set.',
    },
    {
      condition: dirty.trigger.type === 'when_element_appears' && !dirty.trigger.meta.selector,
      message: 'Trigger: Element must be set.',
    },
    {
      condition: dirty.is_scheduled && dirty.scheduled_start_time === null && dirty.scheduled_end_time === null,
      message: 'Start or end time must be selected for scheduled nudges.',
    },
    {
      condition:
        dirty.is_scheduled &&
        dirty.scheduled_start_time !== null &&
        dirty.scheduled_end_time !== null &&
        dayjs(dirty.scheduled_end_time).isBefore(dayjs(dirty.scheduled_start_time)),
      message: 'Scheduled end time must come after scheduled start time.',
    },
    {
      condition:
        dirty.is_scheduled && dirty.scheduled_end_time !== null && dayjs(dirty.scheduled_end_time).isBefore(dayjs()),
      message: 'Scheduled nudge cannot end before current time.',
    },
    {
      condition: dirty.copilot_suggest && !dirty.copilot_description,
      message:
        'Nudge is enabled for suggestion in copilot but has no description. The description is required to help Copilot make better suggestions.',
    },
    {
      condition: !startPageUrlError.isValid,
      message: 'Invalid start page: ' + startPageUrlError.error,
    },
    {
      condition: !!dirty.snoozable && dirty.snooze_label.length === 0,
      message: 'Snoozable nudges require a snooze button label',
    },
    {
      condition:
        exceeding.isAtOrOverLiveNudges &&
        (dirty.is_live || dirty.is_scheduled) &&
        !(initialNudge.is_live || initialNudge.is_scheduled),
      message: "You have hit your organization's maximum number of allowed live nudges.",
    },
    {
      condition:
        dirty.steps.findIndex(({ form_factor }) => form_factor.type === 'tooltip') > 0 ||
        dirty.steps.filter(({ form_factor }) => form_factor.type === 'tooltip').length > 1,
      message: 'Tooltips may only be the first step of a nudge.',
    },
    {
      condition: !isAllowedToSave,
      message: 'You do not have the required permission to make changes to this nudge',
    },
    {
      condition:
        dirty.steps.findIndex(({ form_factor }) => form_factor.type === 'banner') > 0 ||
        dirty.steps.filter(({ form_factor }) => form_factor.type === 'banner').length > 1,
      message: 'Banners may only be the first step of a nudge.',
    },
    {
      condition:
        dirty.steps.findIndex(({ form_factor }) => form_factor.type === 'banner') > 0 ||
        dirty.steps.filter(({ form_factor }) => form_factor.type === 'banner').length > 1 ||
        dirty.steps.some(
          ({ form_factor, content }) =>
            form_factor.type === 'banner' &&
            content.some((block) => block.type !== 'button' && block.type !== 'markdown'),
        ),
      message: 'Banners cannot have survey blocks or media content.',
    },
  ].filter(({ condition }) => condition);
};

export const getStepErrors = (step: INudgeStepType): Array<ValidationError> => {
  const buttons = findButtonBlocks(step.content);
  const surveyBlock = step.content.find((block) => isSurveyBlock(block));
  const conditionalActions = buttons.flatMap((button) => button.meta?.conditional_actions).filter(Boolean);

  return [
    {
      condition:
        (step.form_factor.type === 'pin' || step.form_factor.type === 'tooltip') &&
        !step.form_factor.anchor &&
        !step.form_factor.anchor_selector?.text &&
        !step.form_factor.anchor_selector?.selector,
      message: 'Anchor must be set.',
      step,
    },
    {
      condition: !step?.content && !step.title,
      message: 'Both title and content cannot be empty.',
      step,
    },
    {
      condition: isCTALinkValid(buttons),
      message: 'Link URL must be set.',
      step,
    },
    {
      condition: isCTAClickValid(buttons),
      message: 'Path to clickable element must be set.',
      step,
    },
    {
      condition: isCTANudgeValid(buttons),
      message: 'Nudge to trigger must be set.',
      step,
    },
    {
      condition: isCTAChecklistValid(buttons),
      message: 'Checklist to trigger must be set.',
      step,
    },
    {
      condition: isCTAHelpDocValid(buttons),
      message: 'Article or file to show must be selected.',
      step,
    },
    {
      condition: isCTAActionValid(buttons),
      message: 'Command to trigger must be set.',
      step,
    },
    {
      condition: isCTAGoToStepValid(buttons),
      message: 'Step to go to must be selected.',
      step,
    },
    {
      condition: isCTAChatValid(buttons),
      message: 'Chat provider must be selected.',
      step,
    },
    {
      condition: isCTALabelValid(buttons) && !!surveyBlock,
      message: 'Survey steps must include a button to submit the response',
      step,
    },
    {
      condition: !!surveyBlock && !step.title,
      message: 'Survey steps must include a title. You will use that to identify the survey in the results.',
      step,
    },

    // conditional action validation
    {
      condition:
        conditionalActions.length > 0 &&
        conditionalActions.some(
          (conditionalAction) => conditionalAction?.action.type === 'link' && !conditionalAction.action.value,
        ),
      message: 'Conditional link actions must have a URL.',
      step,
    },
    {
      condition:
        conditionalActions.length > 0 &&
        conditionalActions.some((conditionalAction) => conditionalAction?.operand === ''),
      message: 'Conditional action is missing a value.',
      step,
    },
  ].filter(({ condition }) => condition);
};

export const getStepDraftErrors = (step: INudgeStepType): Array<ValidationError> => {
  const images = findImageBlocks(step.content);
  const videos = findVideoBlocks(step.content);
  const helpDocs = findHelpDocBlocks(step.content);

  const errors = [
    {
      condition: images.some((image) => !!image && !image.meta.src),
      message: 'Image src must be set.',
      step,
    },
    {
      condition: videos.some((video) => !!video && video.meta.type === 'url' && !video.meta.src),
      message: 'Video url must be set.',
      step,
    },
    {
      condition: videos.some((video) => !!video && video.meta.type === 'command' && !video.meta.command),
      message: 'Video command must be selected.',
      step,
    },
    {
      condition: helpDocs.some((helpDoc) => !!helpDoc && !helpDoc.meta.command),
      message: 'Help article preview must be set.',
      step,
    },
  ].filter(({ condition }) => condition);

  return errors;
};

export const getErrorList = (
  _errors: Array<{ errors: Array<ValidationError>; index: number }>,
  audienceErrors: Array<ValidationError>,
) => {
  const text = (message: string, index?: number, step?: INudgeStepType) => {
    const stepPrefix = `Step ${index} ${step?.title && `(${step?.title}) `}- `;

    return `${stepPrefix}${message}`;
  };

  return (
    <ul
      style={{
        listStyleType: 'none',
        padding: 0,
      }}
    >
      {_errors.map(({ errors, index }, i) =>
        errors.map(({ message, step }, j) => <li key={`steperror-${i}-${j}`}>{text(message, index, step)}</li>),
      )}
      {audienceErrors.map((error, index) => (
        <li key={`audienceerror-${index}`}>{error.message}</li>
      ))}
    </ul>
  );
};
