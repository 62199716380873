import * as t from 'io-ts';

import { createObject, deleteObject, updateObject } from './generics';

export const ContextV = t.intersection(
  [
    t.type({
      id: t.number,
      name: t.string,
      organization: t.string,
      value: t.record(t.string, t.any),
    }),
    t.partial({}),
  ],
  'Command',
);

export class Context {
  public static create = createObject(ContextV, ContextV, 'contexts');
  public static update = updateObject(ContextV, ContextV, 'contexts');
  public static delete = deleteObject(ContextV, 'contexts');
}
