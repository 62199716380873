import * as t from 'io-ts';
import { PathReporter as reporter } from 'io-ts/PathReporter';

import { pipe } from 'fp-ts/function';
import { fold } from 'fp-ts/Either';

import * as axiosInstance from './network';

// TODO: catch io-ts errors with CommandBar.error() instead of throwing a browser error

export function mkQueryString(params: Record<string, string> | undefined): string {
  let queryString = '';
  if (params) {
    const urlSearchParams = new URLSearchParams(params);
    queryString = '?' + urlSearchParams.toString();
  }

  return queryString;
}

// Apply a validator and get the result in a Promise
// Source: https://www.olioapps.com/blog/checking-types-real-world-typescript/
function decodeToPromise<T, O, I>(validator: t.Type<T, O, I>, input: I): Promise<T> {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      (_errors: any) => {
        const messages = reporter.report(result);

        const _payload = {
          error: `io-ts error -> ${messages.join('; ').toString()}`,
          errorDetail: JSON.stringify(input),
          url: window.location.href,
        };

        // FIXME: add slack logging here

        return Promise.reject(new Error(messages.join('\n')));
      },
      (value: any) => {
        return Promise.resolve(value);
      },
    ),
  );
}

export function decodeToPromiseExact<T, O, I>(validator: t.Type<T, O, I> & t.HasProps, input: I): Promise<T> {
  return decodeToPromise(t.exact(validator), input);
}

function decodeThrowing<T, O, I>(validator: t.Type<T, O, I>, input: I): T {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      (_errors: any) => {
        const messages = reporter.report(result);

        const _payload = {
          error: `io-ts error -> ${messages.join('; ').toString()}`,
          errorDetail: JSON.stringify(input),
          url: window.location.href,
        };

        // FIXME: add slack logging here

        throw new Error(`${messages.join('\n')}\nwhen parsing ${JSON.stringify(input, null, 2)}`);
      },
      (value) => value,
    ),
  );
}

function decodeErr(err: any): Record<string, unknown> | string {
  if (typeof err === 'string') return err;
  if (err instanceof Error) return err.toString();
  return err?.response?.data || JSON.stringify(err);
}

const GenericObject = t.type({
  id: t.union([t.number, t.string]),
});

export type GenericObjectType = t.TypeOf<typeof GenericObject>;

export const GenericBatchRequest = t.type({
  batch: t.array(t.unknown),
  note: t.string,
});

function createObject<T, Q, O, I>(
  output: t.Type<T, O, I> & t.HasProps,
  input: t.Type<Q, O, I> & t.HasProps,
  url: string,
  params?: Record<string, string>,
  options?: axiosInstance.FetchOptions,
): (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => Promise<T> {
  return async (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.post(`${url}/${queryString}`, t.exact(input).encode(object), options);

      if (!!onSuccess) {
        onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function createObjectWithDecoder<T, Q, O, I>(
  decode: (result: any) => T,
  input: t.Type<Q, O, I> & t.HasProps,
  url: string,
  params?: Record<string, string>,
  options?: axiosInstance.FetchOptions,
): (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => Promise<T> {
  return async (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.post(`${url}/${queryString}`, t.exact(input).encode(object), options);

      if (!!onSuccess) {
        onSuccess();
      }

      return await decode(result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function readObject<T, O, I>(
  arg: t.Type<T, O, I> & t.HasProps,
  url: string,
  options?: axiosInstance.FetchOptions,
): (
  arg0: string,
  params?: Record<string, string>,
  callbacks?: {
    onSuccess?: () => void;
    onError?: (err: string) => void;
  },
) => Promise<T> {
  return async (
    id: string,
    params: Record<string, string> = {},
    callbacks?: {
      onSuccess?: () => void;
      onError?: (err: string) => void;
    },
  ) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.get(`${url}/${id}/${queryString}`, options);

      if (!!callbacks?.onSuccess) {
        callbacks.onSuccess();
      }

      return await decodeToPromiseExact(arg, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!callbacks?.onError) {
        callbacks?.onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

// FIXME: We can do these requests in parallel instead of in sequece
function listObject<T, O, I>(
  arg: t.Type<T, O, I> & t.HasProps,
  obj: string,
  options?: axiosInstance.FetchOptions,
): (params?: Record<string, string>, onSuccess?: () => void, onError?: (err: string) => void) => Promise<T[]> {
  return async (params?: Record<string, string>, onSuccess?: () => void, onError?: (err: string) => void) => {
    let objects: T[] = [];
    let path: string | null = `${obj}/`;
    while (path !== null) {
      try {
        const queryString = mkQueryString(params);
        const result: any = await axiosInstance.get(`${path}${queryString}`, options);

        const data: any = result.data;

        // Is this list paginated?
        if (data.hasOwnProperty('results')) {
          objects = objects.concat(data['results']);
        } else {
          objects = data;
          path = null;
        }

        if (data.hasOwnProperty('next')) {
          path = new URL(data['next']).pathname;
        } else {
          path = null;
        }

        if (!!onSuccess) {
          onSuccess();
        }
      } catch (err) {
        const data = decodeErr(err);

        if (!!onError) {
          onError(JSON.stringify(data));
        }
        path = null;
      }
    }
    return objects;
  };
}

function updateObject<T, O, I, Q extends GenericObjectType>(
  output: t.Type<T, O, I> & t.HasProps,
  input: t.Type<Q, O, I> & t.HasProps,
  url: string,
  params?: Record<string, string>,
  options?: axiosInstance.FetchOptions,
): (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => Promise<T> {
  return async (object: Q, onSuccess?: () => void, onError?: (err: string) => void) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.patch(
        `${url}/${object.id}/${queryString}`,
        t.exact(input).encode(object),
        options,
      );

      if (!!onSuccess) {
        onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

// Should change the return value to accept an object of type T (mandated to have an id field) instead of an id
function deleteObject<T, O, I>(
  arg: t.Type<T, O, I> & t.HasProps,
  url: string,
  options?: axiosInstance.FetchOptions,
): (
  id: number | string,
  params?: Record<string, string>,
  onSuccess?: () => void,
  onError?: (err: string) => void,
) => Promise<void> {
  return async (
    id: number | string,
    params?: Record<string, string>,
    onSuccess?: () => void,
    onError?: (err: string) => void,
  ) => {
    try {
      const _result = await axiosInstance.del(`${url}/${id}/`, { ...params }, options);

      if (!!onSuccess) {
        onSuccess();
      }

      return Promise.resolve();
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function getURLString(urlArgs?: { [arg: string]: string }) {
  let urlString = '';
  if (urlArgs) {
    Object.keys(urlArgs).forEach((key, i) => {
      if (i === 0) {
        urlString = `?${key}=${urlArgs[key]}`;
      } else {
        urlString = `${urlString}&${key}=${urlArgs[key]}`;
      }
    });
  }
  urlString = urlString.replace(/\+/g, '%2B');
  return urlString;
}

function readObjectDetail<T, O, I>(
  arg: t.Type<T, O, I> & t.HasProps,
  url: string,
  detail: string,
  options?: axiosInstance.FetchOptions,
): (
  arg0: string,
  urlArgs?: { [arg: string]: string },
  onSuccess?: () => void,
  onError?: (err: string) => void,
) => Promise<T> {
  return async (
    id: string,
    urlArgs?: { [arg: string]: string },
    onSuccess?: () => void,
    onError?: (err: string) => void,
  ) => {
    const urlString = getURLString(urlArgs);

    try {
      const result = await axiosInstance.get(`${url}/${id}/${detail}/${urlString}`, options);

      if (!!onSuccess) {
        onSuccess();
      }

      return await decodeToPromiseExact(arg, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function updateObjectDetail<T, O, I, J, K, Q extends GenericObjectType>(
  output: t.Type<T, O, I> & t.HasProps,
  input: t.Type<Q, K, J> & t.HasProps,
  url: string,
  detail: string,
  options?: axiosInstance.FetchOptions,
): (
  object: Q,
  urlArgs?: { [arg: string]: string },
  onSuccess?: () => void,
  onError?: (err: string) => void,
) => Promise<T> {
  return async (
    object: Q,
    urlArgs?: { [arg: string]: string },
    onSuccess?: () => void,
    onError?: (err: string) => void,
  ) => {
    const urlString = getURLString(urlArgs);

    try {
      const result = await axiosInstance.patch(
        `${url}/${object.id}/${detail}/${urlString}`,
        t.exact(input).encode(object),
        options,
      );

      if (!!onSuccess) {
        onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function createObjectDetail<T, O, I, J, K, Q extends GenericObjectType>(
  output: t.Type<T, O, I> & t.HasProps,
  input: t.Type<Q, K, J> & t.HasProps,
  url: string,
  detail: string,
  options?: axiosInstance.FetchOptions,
): (
  object: Q,
  onSuccess?: () => void,
  onError?: (err: string) => void,
  urlArgs?: { [arg: string]: string },
) => Promise<T> {
  return async (
    object: Q,
    onSuccess?: () => void,
    onError?: (err: string) => void,
    urlArgs?: { [arg: string]: string },
  ) => {
    const urlString = getURLString(urlArgs);

    try {
      const result = await axiosInstance.post(
        `${url}/${object.id}/${detail}/${urlString}`,
        t.exact(input).encode(object),
        options,
      );

      if (!!onSuccess) {
        onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!onError) {
        onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

async function loadIDList(ids: number[], klass: any, method = 'read', urlArgs?: { [arg: string]: string }) {
  const ignoreRejects = (p: Promise<any>) => {
    return p.catch((_e: any) => {
      return undefined;
    });
  };

  const promises = ids.map(async (id: number) => {
    return await klass[method](id, urlArgs);
  });

  const data = await Promise.all(promises.map(ignoreRejects));
  const filteredData = data.filter((a: any) => {
    return a !== undefined;
  });

  return filteredData;
}

function read<T, O, I>(
  output: t.Type<T, O, I> & t.HasProps,
  url: string,
  options?: axiosInstance.FetchOptions,
): (
  params?: Record<string, string>,
  callbacks?: {
    onSuccess?: () => void;
    onError?: (err: string) => void;
  },
) => Promise<T> {
  return async (
    params: Record<string, string> = {},
    callbacks?: {
      onSuccess?: () => void;
      onError?: (err: string) => void;
    },
  ) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.get(`${url}/${queryString}`, options);

      if (!!callbacks?.onSuccess) {
        callbacks.onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!callbacks?.onError) {
        callbacks?.onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

function update<T, O, I, Q>(
  output: t.Type<T, O, I> & t.HasProps,
  input: t.Type<Q, O, I> & t.HasProps,
  url: string,
  options?: axiosInstance.FetchOptions,
): (
  object: Q,
  params?: Record<string, string>,
  callbacks?: {
    onSuccess?: () => void;
    onError?: (err: string) => void;
  },
) => Promise<T> {
  return async (
    object: Q,
    params: Record<string, string> = {},
    callbacks?: {
      onSuccess?: () => void;
      onError?: (err: string) => void;
    },
  ) => {
    try {
      const queryString = mkQueryString(params);
      const result = await axiosInstance.patch(`${url}/${queryString}`, t.exact(input).encode(object), options);

      if (!!callbacks?.onSuccess) {
        callbacks.onSuccess();
      }

      return await decodeToPromiseExact(output, result.data);
    } catch (err) {
      const data = decodeErr(err);

      if (!!callbacks?.onError) {
        callbacks?.onError(JSON.stringify(data));
      }

      return Promise.reject(data);
    }
  };
}

export {
  createObject,
  createObjectWithDecoder,
  createObjectDetail,
  read,
  readObject,
  listObject,
  update,
  updateObject,
  deleteObject,
  GenericObject,
  readObjectDetail,
  updateObjectDetail,
  loadIDList,
  decodeToPromise,
  decodeThrowing,
};
