import {
  INudgeStepContentBlockType,
  INudgeStepType,
  INudgeContentListBlock,
} from '@commandbar/internal/middleware/types';
import React from 'react';
import { ListBlockForm } from './ListBlockForm';

interface IMediaBlocks {
  step: INudgeStepType;
  onBlockChange: (block: INudgeStepContentBlockType, resetLogicBlocks?: boolean, deleteLogicBlocks?: boolean) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

export const ListBlocks = ({ step, onBlockChange, onBlockDelete }: IMediaBlocks) => {
  const listBlocks = step.content.filter((content) => content.type === 'survey_list');

  if (!!listBlocks.length) {
    return (
      <React.Fragment>
        {listBlocks.map((listBlock, i) => (
          <ListBlockForm
            key={`${listBlock?.sort_key}-${i}`}
            block={listBlock as INudgeContentListBlock}
            onBlockChange={onBlockChange}
            onBlockDelete={onBlockDelete}
          />
        ))}
      </React.Fragment>
    );
  }

  return null;
};
