import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cn } from '../util';
import { avatarContainerStyles, avatarStyles } from './style';

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => <AvatarPrimitive.Root ref={ref} className={className} {...props} />);
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image ref={ref} className={cn('aspect-square h-full w-full', className)} {...props} />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const colorPalette = [
  '#FB923C',
  '#6E8DF5',
  '#69C015',
  '#EF4444',
  '#F59E0B',
  '#10B981',
  '#3B82F6',
  '#A855F7',
  '#EC4899',
  '#374151',
];

// Simple hash function to get a consistent hash code from initials
function hashCode(s: string) {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = (h * 31 + s.charCodeAt(i)) | 0; // Use bitwise OR to convert to 32bit integer
  }
  return h;
}
// Function to get a color from our palette based on the initials
function getColorFromInitials(initials: React.ReactNode) {
  if (typeof initials !== 'string') {
    return colorPalette[0];
  }

  const index = Math.abs(hashCode(initials)) % colorPalette.length;
  return colorPalette[index];
}

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ children, className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    style={{ backgroundColor: getColorFromInitials(children) }}
    className={cn('flex h-full w-full items-center justify-center text-gray100 font-semibold', className)}
    {...props}
  >
    {children}
  </AvatarPrimitive.Fallback>
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

interface AvatarProps {
  variant: 'ai' | 'initials' | 'photo';
  size: 'sm' | 'md' | 'lg';
  src?: string;
  children?: React.ReactNode;
  alt?: string;
  className?: string;
}

const CmdAvatar = ({ variant, size, src, children, alt, className, ...otherProps }: AvatarProps) => {
  return (
    <Avatar {...otherProps} className={avatarContainerStyles({ size })}>
      {variant === 'photo' && src ? (
        <AvatarImage className={avatarStyles({ size })} src={src} alt={alt} />
      ) : variant === 'initials' && children ? (
        <AvatarFallback className={cn(avatarStyles({ size }), className)}>{children}</AvatarFallback>
      ) : (
        <AvatarImage
          className={avatarStyles({ size })}
          src={'https://cdn.commandbar.com/_images/copilot_avatar_commandai.png'}
          alt={alt}
        />
      )}
    </Avatar>
  );
};

export { CmdAvatar };
