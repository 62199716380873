import styled, { ComponentSelector } from '@emotion/styled';

import { CB_COLORS, Collapse } from '@commandbar/design-system/components/antd';

export const IntegrationsPageHeader = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
`;

export const SectionTitle = styled.h2`
  padding: 8px 0;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${CB_COLORS.neutral700};
`;

export const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Card = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.7; pointer-events: none;'}
  display: flex;
  flex-direction: column;
  position: relative;
  width: 260px;
  min-height: 250px;
  background: ${({ disabled }) => (disabled ? CB_COLORS.neutral0 : '#ffffff')};
  border: 1px solid ${CB_COLORS.neutral300};
  border-radius: 8px;
  transition: box-shadow 0.3s, border-color 0.3s;

  --shadow-color: 0deg 0% 65%;
  --shadow-elevation-medium: 0.1px 0.2px 0.3px hsl(var(--shadow-color) / 0.43),
    0.6px 0.9px 1.2px -1.2px hsl(var(--shadow-color) / 0.44), 2.2px 3.5px 4.7px -2.4px hsl(var(--shadow-color) / 0.44);
  --shadow-elevation-high: 0.1px 0.2px 0.3px hsl(var(--shadow-color) / 0.3),
    0.6px 1px 1.3px -0.5px hsl(var(--shadow-color) / 0.3), 1.3px 2.1px 2.8px -1px hsl(var(--shadow-color) / 0.3),
    2.5px 4px 5.3px -1.5px hsl(var(--shadow-color) / 0.3), 4.5px 7.3px 9.7px -2px hsl(var(--shadow-color) / 0.31),
    7.8px 12.6px 16.8px -2.4px hsl(var(--shadow-color) / 0.31);

  box-shadow: var(--shadow-elevation-medium);

  &:hover,
  &:focus {
    border-color: transparent;
    box-shadow: var(--shadow-elevation-high);
    cursor: pointer;
  }
`;

export const SuggestionCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 260px;
  min-height: 250px;
  padding: 24px;
  background: linear-gradient(115.78deg, #e0eafa 8.38%, #fbf6f2 57.29%, #fdf4fb 92.2%);
  border-radius: 8px;

  --shadow-color: 0deg 0% 65%;
  --shadow-elevation-medium: 0.1px 0.2px 0.3px hsl(var(--shadow-color) / 0.43),
    0.6px 0.9px 1.2px -1.2px hsl(var(--shadow-color) / 0.44), 2.2px 3.5px 4.7px -2.4px hsl(var(--shadow-color) / 0.44);

  box-shadow: var(--shadow-elevation-medium);
`;

export const SuggestionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonRow = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  gap: 8px;
  width: 100%;
  border-top: 1px solid ${CB_COLORS.neutral300};
  background: #ffffff;
  padding: 16px;
  justify-content: end;
  border-radius: 0px 0px 8px 8px;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
`;

export const IntegrationActionButtons = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 56px;
  position: absolute;
  bottom: 0;
  background: ${CB_COLORS.neutral0};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SuggestionTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const SuggestionContent = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'ss17' on;
  color: #000000;
`;

export const SuggestionLink = styled.button`
  all: unset;
  color: #2855f0;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;

  &:hover,
  &:focus {
    color: #5075f3;
  }
`;

export const Header = styled.div<{ active?: boolean }>`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0px 18px;
  border-bottom: 1.5px solid #dfdfe2;
  background-color: ${(props) => (props.active ? '#E6FEEE' : undefined)};
  border-radius: 8px 8px 0px 0px;

  .integration-logo {
    height: 24px;

    &.freshdesk {
      height: 48px;
    }

    &.document360 {
      margin-top: 5px;
    }

    &.slack {
      height: 45px;
    }
  }
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #35530e;
  position: absolute;
  right: 0;
  width: 32px;
  height: 32px;
  background: #b3e3c4;
  border-radius: 8px 0px 0px 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 4px;
`;

export const IntegrationTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const IntegrationDescription = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${CB_COLORS.neutral800};
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-header-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }

  .ant-collapse-content-box {
    padding: 24px !important;
  }

  .ant-form-item-control-input {
    min-height: 0px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label > label {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 14px;
    color: ${CB_COLORS.neutral800} !important;
  }

  .ant-collapse-header {
    border-bottom: 1px solid ${CB_COLORS.neutral0};
    padding: 16px !important;
  }

  .ant-input {
    padding: 8px 16px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
    height: 40px;
    border: 1px solid ${CB_COLORS.neutral500};
  }
` as unknown as typeof Collapse & ComponentSelector;

export const Integrations = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
