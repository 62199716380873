import styled from '@emotion/styled';

import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import { useIntegrationsContext } from '../../util/IntegrationContext';

import { Menu, Dropdown, Modal } from '@commandbar/design-system/components/antd';
import { DotsVertical, EyeOff, Lock03, Settings02, Trash04 } from '@commandbar/design-system/icons/react';
import { useReportEvent } from '../../../hooks/useEventReporting';

import type { Source } from '../../integrations/shared/types';
import { onIntegrationError } from '../../integrations/shared/utils';
import { Exception } from '@sentry/react';
import { CmdButton, CmdDivider, CmdSwitch } from '@commandbar/design-system/cmd';
import { PROCESSING_OPERATION } from '../sources/SourceProcessingProgress';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';

export const DropdownMenu = styled(Menu)`
  display: flex;
  width: 207px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
`;

export const DropdownMenuItem = styled(Menu.Item)`
  width: 100%;
  padding: 8px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;

  & .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }
`;

interface SettingsDropdownProps {
  source: Source;
  onSettingsClick: () => void;
  onDelete?: () => void;
  refresh?: () => void;
}

const NoClickDiv = styled.div`
  width: 100%;
`;

const SettingsDropdown = ({ source, onSettingsClick, onDelete, refresh }: SettingsDropdownProps) => {
  const { refreshHelpDocIntegrations, addLoadingSource } = useIntegrationsContext();
  const { reportEvent } = useReportEvent();

  const {
    dispatch: {
      commands: { reload },
    },
  } = useAppContext();

  const { user } = useAuth();

  if (!hasRequiredRole(user, 'editor')) {
    return null;
  }

  return (
    <Dropdown
      overlay={
        <NoClickDiv onClick={(e) => e.stopPropagation()}>
          <DropdownMenu>
            {source.type !== 'custom' && (
              <DropdownMenuItem key="settings" onClick={onSettingsClick}>
                <Settings02 />
                Settings
              </DropdownMenuItem>
            )}
            <DropdownMenuItem key="training-only">
              <EyeOff />
              <span style={{ flexGrow: 1 }}>Training Only</span>
              <CmdSwitch
                defaultChecked={source.meta?.training_only}
                onCheckedChange={async (e) => {
                  try {
                    await HelpDocsIntegration.update({
                      ...source,
                      meta: { ...source.meta, training_only: e },
                    });
                    await refreshHelpDocIntegrations();
                    refresh?.();
                    reload();
                  } catch (e) {
                    onIntegrationError(e as Exception, source.slug, 'Failed to update integration.');
                  }
                }}
              />
            </DropdownMenuItem>
            <DropdownMenuItem key="private">
              <Lock03 />
              <span style={{ flexGrow: 1 }}>Private</span>
              <CmdSwitch
                defaultChecked={!!source.helphub_view_article_button_hidden}
                onCheckedChange={async (e) => {
                  try {
                    await HelpDocsIntegration.update({
                      ...source,
                      helphub_view_article_button_hidden: e,
                    });
                    await refreshHelpDocIntegrations();
                    refresh?.();
                  } catch (e) {
                    onIntegrationError(e as Exception, source.slug, 'Failed to update integration.');
                  }
                }}
              />
            </DropdownMenuItem>
            <CmdDivider spacing="sm" />
            <DropdownMenuItem
              key="delete"
              onClick={() => {
                Modal.confirm({
                  title: `This will delete the source and all related documents. Are you sure you want to remove your ${source?.name} source?`,
                  onOk: async () => {
                    try {
                      addLoadingSource(source.id, PROCESSING_OPERATION.DELETING);
                      HelpDocsIntegration.delete(source.id);
                      reportEvent('integration deleted', {
                        segment: true,
                        highlight: true,
                        slack: true,
                        payloadMessage: `${source.type}-${source.id}`,
                        eventProps: {
                          id: source.id,
                          name: source.type,
                        },
                      });
                      if (onDelete) onDelete();
                    } catch (err) {
                      onIntegrationError(err as Exception, source.slug);
                    }
                  },
                  cancelText: 'Cancel',
                });
              }}
            >
              <Trash04 />
              Delete Source
            </DropdownMenuItem>
          </DropdownMenu>
        </NoClickDiv>
      }
      trigger={['click']}
      placement="bottomLeft"
    >
      <CmdButton variant="link" onClick={(e) => e.stopPropagation()}>
        <DotsVertical />
      </CmdButton>
    </Dropdown>
  );
};

export default SettingsDropdown;
