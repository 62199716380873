import React from 'react';

import type { IBillingProfile } from '@cb/types/entities/internal/billing';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';

import { Input, Tooltip, CB_COLORS, Form, Menu, Dropdown, Modal } from '@commandbar/design-system/components/antd';
import { Copy02, PlusCircle } from '@commandbar/design-system/icons/react';
import { getDropdownStyles } from './styles';

import { LogOut04, Building01, ArrowNarrowRight, ChevronSelectorVertical } from '@commandbar/design-system/icons/react';
import { useCallAsyncFunction } from '@commandbar/internal/util/useCallAsyncFunction';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import styled from '@emotion/styled';
import { useForm } from 'antd/lib/form/Form';
import { getSettingsSubItems } from './menu_items';
import { Link } from 'react-router-dom';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';
import { useReportEvent } from '../../hooks/useEventReporting';
import { CmdAvatar } from '@commandbar/design-system/cmd/avatar';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { getRoleRestrictionForPath } from 'commandbar.com/src/Router';
import { capitalize } from 'lodash';
import { cmdToast } from '@commandbar/design-system/cmd';

const styles = getDropdownStyles();

const ProfileMenuButton = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { user, logout } = useAuth();
  const { organization, flags } = useAppContext();
  const { reportEvent } = useReportEvent();

  const onLogout = () => {
    reportEvent('logged out', { segment: true, highlight: true });
    logout();
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [newWorkspaceForm] = useForm();

  const teams =
    useCallAsyncFunction<IOrganizationType[]>(() =>
      axiosInstance.get('/organizations/').then((response) => response.data as IOrganizationType[]),
    ).result || [];

  const switchTeams = async (team: IOrganizationType) => {
    try {
      await axiosInstance.patch('/profiles/switch_organization/', { org_uuid: team.id });
      window.location.reload();
    } catch (err) {
      cmdToast.error('Something went wrong');
    }
  };

  const billingProfile =
    useCallAsyncFunction<IBillingProfile>(() =>
      axiosInstance.get('/billing/profile/').then((response) => response.data as IBillingProfile),
    ).result || null;

  /** Enable create workspace if already has a tenant or on appsumo */
  const allowCreateWorkspace =
    (teams.length > 1 || !!billingProfile?.appsumo_invoice_item_id) && hasRequiredRole(user, 'admin');
  const disableCreateWorkspace = teams.length >= 5 && !user?.email.endsWith('@commandbar.com');

  const createWorkspace = () => {
    Modal.confirm({
      title: 'Confirm',
      content: (
        <Form form={newWorkspaceForm}>
          <Form.Item name="workspaceName" rules={[{ required: true, message: 'Please input your value!' }]}>
            <Input placeholder="Workspace name" />
          </Form.Item>
        </Form>
      ),
      okText: 'Create new workspace',
      cancelText: 'Cancel',
      onOk: () => {
        if (!allowCreateWorkspace) return;
        newWorkspaceForm.validateFields().then((values) => {
          const { workspaceName } = values;
          axiosInstance
            .post('/new-workspace/', { name: workspaceName })
            .then((response) => {
              newWorkspaceForm.resetFields();
              switchTeams(response.data as IOrganizationType);
            })
            .catch((err) => cmdToast.error(err));
        });
      },
    });
  };

  // copy org id to clipboard
  const copyOrgId = (orgId: string) => () => {
    navigator.clipboard.writeText(orgId);
    cmdToast.success('Org ID copied to clipboard');
  };

  if (!user) return null;

  let initials = `${user.profile.first_name ? user.profile.first_name[0] : ''}${
    user.profile.last_name ? user.profile.last_name[0] : ''
  }`;
  if (!initials && user.profile.email) {
    initials = user.profile.email[0];
  }

  if (!initials) {
    initials = 'U';
  }
  initials = initials.toUpperCase();

  const menu = (
    <Menu style={{ border: `1px solid ${CB_COLORS.neutral500}` }}>
      <div style={{ padding: '4px 16px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #eee' }}>
        <div
          style={{
            color: styles.dropdownMenu.color,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <b>{user?.email}</b>
          <div style={{ fontSize: 12 }}>Account {capitalize(user.profile.role)}</div>
          {user?.organization && (
            <div style={{ display: 'flex' }}>
              <div style={{ fontSize: 12 }}>Org ID: {user.organization}</div>
              <button
                title="Copy org ID"
                style={{
                  padding: '4px',
                  background: 'white',
                  border: 0,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 3,
                }}
                onClick={copyOrgId(user.organization)}
              >
                <Copy02 height={13} width={13} opacity={0.8} />
              </button>
            </div>
          )}
        </div>
      </div>
      {teams.map((team: any) =>
        organization?.id === team.id ? null : (
          <Menu.Item
            key={`switch-team-${team.id}`}
            onClick={() => switchTeams(team)}
            icon={<Building01 height={16} width={16} />}
            title={team.name}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div
                style={{
                  whiteSpace: 'nowrap',
                  maxWidth: '160px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                Switch to <span style={{ fontWeight: '500' }}>{team.name} </span>
              </div>
              <ArrowNarrowRight height={14} width={14} opacity={0.5} />
            </div>
          </Menu.Item>
        ),
      )}
      {allowCreateWorkspace && (
        <Menu.Item
          key={`create-team`}
          onClick={createWorkspace}
          icon={<PlusCircle height={16} width={16} />}
          title={'Create workspace'}
          disabled={disableCreateWorkspace}
        >
          <Tooltip
            content="You've reached your account limit. Please contact the Command AI team to create more workspaces."
            showIf={disableCreateWorkspace}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div
                style={{
                  whiteSpace: 'nowrap',
                  maxWidth: '160px',
                  overflow: 'hidden',
                }}
              >
                Create new workspace
              </div>
            </div>
          </Tooltip>
        </Menu.Item>
      )}

      {getSettingsSubItems(flags, user).map((item) =>
        hasRequiredRole(user, getRoleRestrictionForPath(item.route)) ? (
          <Menu.Item
            key={item.route}
            disabled={item.route === '/billing' && billingProfile?.on_free_trial}
            icon={item.icon && <item.icon height={16} width={16} />}
          >
            <Link to={item.route}>
              <span>{item.title}</span>
            </Link>
          </Menu.Item>
        ) : null,
      )}

      <Menu.Item key="logout" onClick={onLogout} icon={<LogOut04 height={16} width={16} />} title="">
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Wrapper isOpen={isOpen} isCollapsed={isCollapsed}>
      <Dropdown
        overlayClassName="profile-menu-dropdown"
        overlay={menu}
        trigger={['click']}
        placement={'top'}
        onOpenChange={(open) => setIsOpen(open)}
        forceRender={true} // make CommandBar callbacks in menu children available
      >
        <div style={{ cursor: 'pointer', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
          {isCollapsed ? (
            <CmdAvatar size="md" variant="initials">
              {initials}
            </CmdAvatar>
          ) : (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <CmdAvatar size="md" variant="initials">
                  {initials}
                </CmdAvatar>
                <div style={{ whiteSpace: 'pre-wrap' }}>{organization?.name}</div>
              </div>
              <ChevronSelectorVertical height={16} width={16} />
            </>
          )}
        </div>
      </Dropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isOpen: boolean; isCollapsed: boolean }>`
  background: ${({ isOpen }) => isOpen && styles.activeDropdown.background};
  padding: ${({ isCollapsed }) => (isCollapsed ? '16px 24px' : '16px 14px')};

  ${({ isOpen }) =>
    !isOpen &&
    `
  &:hover {
    background: ${CB_COLORS.neutral100};
    color: ${CB_COLORS.neutral900};
  }
  `}
`;

export default ProfileMenuButton;
