/** @jsx jsx */
/** @jsxFrag React.Fragment */

import { Keyframes, SerializedStyles, keyframes, css, jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';

/** A little bit of a misnomer - this utility is a delayed mount by one tick and a
 * delayed unmount based on delayTime. Both make animations smoother
 */
export const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      if (delayTime === 0) {
        setShouldRender(false);
        return;
      }
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

interface AnimationProps {
  keyframes: Keyframes;
  durationMs?: number;
  timingFunction?: string;
}

const defaultAnimationProps = {
  timingFunction: 'ease',
  durationMs: 300,
};

export const AnimationTransition = (props: {
  children: React.ReactNode;
  entry?: AnimationProps;
  exit?: AnimationProps;
  isMounted: boolean;
  style?: React.CSSProperties;
}) => {
  const { children, entry, exit, isMounted } = props;
  const unmountDelay = !!exit ? exit.durationMs || defaultAnimationProps.durationMs : 0;
  const shouldRender = useDelayUnmount(isMounted, unmountDelay);
  const [containerStyle, setContainerStyle] = useState<SerializedStyles | undefined>(undefined);
  const generateAnimationCSS = (animationProps?: AnimationProps): SerializedStyles | undefined => {
    if (animationProps) {
      const duration = `${animationProps.durationMs || defaultAnimationProps.durationMs}ms forwards`;
      const timingFn = animationProps.timingFunction || defaultAnimationProps.timingFunction;

      return css`
        animation: ${animationProps.keyframes} ${duration} ${timingFn};
      `;
    }
    return undefined;
  };

  useEffect(() => {
    if (isMounted) {
      setContainerStyle(generateAnimationCSS(entry));
    } else if (shouldRender) {
      setContainerStyle(generateAnimationCSS(exit));
    }
  }, [isMounted, shouldRender, entry, exit]);

  return (
    <>
      {shouldRender && (
        <div css={containerStyle} style={props.style}>
          {children}
        </div>
      )}
    </>
  );
};

/** Built-ins */

const fadeInSlideDownExpand = keyframes`
0% {
  opacity: 0;
  transform: translateY(10px);
  scale: 0.85;

}
100% {
  opacity: 1;
  transform: translateY(0px);
  scale: 1;
}
`;

const fadeInSlideDown = keyframes`
0% {
  opacity: 0;
  transform: translateY(10px);
}
100% {
  opacity: 1;
  transform: translateY(0px);
}
`;

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

const slideUp = (distance: number) => keyframes`
0% {
  transform: translateY(${distance}px);
}
100% {
  transform: translateY(0px);
}
`;

export const builtinKeyframes = {
  fadeInSlideDown,
  fadeOut,
  slideUp,
  fadeIn,
  fadeInSlideDownExpand,
};
