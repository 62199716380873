import { cva } from 'class-variance-authority';

export const tooltipStyles = cva([
  'z-[1000]',
  'max-w-[250px]',
  'overflow-hidden',
  'rounded-md',
  'border',
  'bg-black',
  'text-base',
  'px-3',
  'py-1.5',
  'text-sm',
  'text-gray100',
  'shadow-md',
  'animate-in',
  'fade-in-0',
  'zoom-in-95',
  'data-[state=closed]:animate-out',
  'data-[state=closed]:fade-out-0',
  'data-[state=closed]:zoom-out-95',
  'data-[side=bottom]:slide-in-from-top-2',
  'data-[side=left]:slide-in-from-right-2',
  'data-[side=right]:slide-in-from-left-2',
  'data-[side=top]:slide-in-from-bottom-2',
  'whitespace-normal',
  'word-wrap: break-word',
]);
