import styled from '@emotion/styled';
import React from 'react';

import { CB_COLORS, Dropdown, DropdownProps } from '@commandbar/design-system/components/antd';
import { Settings03 } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';

const MenuIconContainer = styled.div`
  padding: 8px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.3s;
  transition: all 0.3s;

  color: ${CB_COLORS.neutral500};

  &:hover {
    background-color: ${CB_COLORS.neutral0};
    color: #000;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  color: ${CB_COLORS.neutral800};
`;

interface SettingsDropdownProps {
  dropdownOverlay: DropdownProps['overlay'];
}

export const SettingsDropdown = ({ dropdownOverlay }: SettingsDropdownProps) => (
  <Dropdown
    trigger={['click']}
    placement="bottomRight"
    overlay={dropdownOverlay}
    overlayStyle={{ zIndex: Z.Z_EDITOR_OVERLAY }}
  >
    <MenuIconContainer
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Settings03 />
    </MenuIconContainer>
  </Dropdown>
);
