import React from 'react';
import { Heading, Alert, Col, Row, Typography } from '@commandbar/design-system/components/antd';
import usePlaceholders, { str_to_slug } from './usePlaceholders';
import NewObjectForm from '../CommmandDetail/arguments/NewObjectForm';

import type { IOrganizationType, IPlaceholderType } from '@commandbar/internal/middleware/types';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { Trash04 } from '@commandbar/design-system/icons/react';

interface IProps {
  organization: IOrganizationType;
}

const PlaceholderList = (props: IProps) => {
  const { placeholders, createPlaceholder, deletePlaceholder } = usePlaceholders(props.organization.id.toString());

  return (
    <div style={{ marginLeft: '12px' }}>
      <Alert
        message="Using placeholder texts"
        description={
          <div>
            You can define placeholders to show in the input of CommandBar before a user starts typing. CommandBar will
            cycle through placeholders at random if multiple are defined.
            <br />
            <br />
            Try showing users sample commands (&quot;Try: Create a new dashboard&quot;) or nudge types of record
            searches (&quot;Search for a person or dashboard&quot;).
          </div>
        }
        type="info"
        showIcon
      />
      <Row gutter={4} align="middle" style={{ marginBottom: 14 }} wrap={false}>
        <Col style={{ overflow: 'hidden' }}>
          <Heading text="Active Placeholder Texts" />
        </Col>
      </Row>
      {placeholders.map((placeholder: IPlaceholderType) => (
        <PlaceholderRow
          key={`placeholder-${placeholder.id}`}
          placeholder={placeholder}
          onDelete={() => deletePlaceholder(placeholder.id)}
        />
      ))}
      <NewObjectForm
        buttonText="Add another placeholder"
        placeholderText="Placeholder"
        onCreate={(newArg: string) => {
          if (!newArg.length) {
            cmdToast.error('Placeholder cannot be empty.');
          } else if (placeholders.map(({ slug }) => slug).includes(str_to_slug(newArg))) {
            cmdToast.error('Placeholder must be unique.');
          } else {
            createPlaceholder(newArg);
          }
        }}
        autocomplete
      />
    </div>
  );
};

const PlaceholderRow = (props: { placeholder: IPlaceholderType; onDelete: () => void }) => {
  return (
    <Row style={{ marginBottom: 8, display: 'flex', flexDirection: 'row' }}>
      <Col span={14}>
        <Typography.Text>{props.placeholder.text}</Typography.Text>
      </Col>
      <Col>
        <CmdButton icon={<Trash04 />} onClick={props.onDelete} variant="link" />
      </Col>
    </Row>
  );
};

export default PlaceholderList;
