import { type MouseEventHandler, useCallback } from 'react';

import Logger from '@commandbar/internal/util/Logger';
import { useStore } from 'shared/util/hooks/useStore';

const defaultOpen = (url: string) => {
  window.open(url, '_blank');
};

export const useLinkClickHandler = (onRouterNavigation?: () => void) => {
  const _ = useStore();

  const handleLinkClick: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      const routerFunc = _.callbacks['commandbar-router'];
      const { target } = event;

      // no router means we'll pass and let the browser handle like usual
      if (routerFunc && target instanceof HTMLAnchorElement) {
        event.preventDefault();

        try {
          const { hostname: targetHostname, pathname, search, hash } = new URL(target.href);
          if (targetHostname === window.location.hostname) {
            routerFunc(pathname + search + hash);
            onRouterNavigation?.();
          } else {
            // fallback to regular navigation if navigating to another domain
            defaultOpen(target.href);
          }
        } catch (e) {
          Logger.warn(e);
          // fallback to regular navigation if router fails
          defaultOpen(target.href);
        }
      }
    },
    [_.callbacks['commandbar-router'], onRouterNavigation],
  );

  return handleLinkClick;
};
