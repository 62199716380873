import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from './colors';
import { Slider as AntSlider, Collapse, type InputProps } from 'antd';
import Input from './Input';

export const PaddingContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ValidatingInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ValidatingStyledInput = styled(Input, { shouldForwardProp: (prop) => prop !== 'error' })<{
  error?: boolean;
}>`
  &&& {
    border-color: ${(props) => (props.error ? '#C73A27' : CB_COLORS.neutral300)};
    background: ${(props) => (props.error ? 'rgba(199, 58, 39, 0.04)' : CB_COLORS.white)};
    box-shadow: ${(props) => (props.error ? '0px 0px 0px 3px rgba(199, 58, 39, 0.16)' : 'none')};
  }
`;

const ValidationMessage = styled.span`
  color: rgb(199, 58, 39);
  font-size: 0.875rem;
`;

interface ValidatingInputProps extends InputProps {
  error?: boolean;
  errorMessage?: string;
}

export const ErrorSignalingInput = ({ error, errorMessage, ...inputProps }: ValidatingInputProps) => (
  <ValidatingInputContainer>
    <ValidatingStyledInput error={error} {...inputProps} />
    {error && errorMessage && <ValidationMessage>{errorMessage}</ValidationMessage>}
  </ValidatingInputContainer>
);

export const StickyHeaderCollapsePanel = styled(Collapse.Panel)`
  & {
    .ant-collapse-header {
      position: sticky !important;
      top: 0;
      z-index: 1;
    }
  }
`;

export const Slider = styled(AntSlider)`
  & {
    .ant-slider-track,
    .ant-slider-rail {
      border-radius: 0;
    }

    .ant-slider-step {
      left: 4px;
    }

    .ant-slider-dot {
      width: 0;
      border-radius: 0;
      border-width: 1px;
      height: 12px;
      top: -4px;

      &.ant-slider-dot-active {
      }
    }

    .ant-slider-mark-text {
      max-width: 65px;
    }
  }
`;
