import styled from '@emotion/styled';

export const ICON_WIDTH = 24;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
