import React from 'react';

export const EngagementTracking = (props: {
  children: React.ReactNode;
  anchorClickHandler: (event: Event) => void;
  videoPlayHandler: () => void;
}) => {
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);
  const [dirty, setDirty] = React.useState<number>(0);

  React.useEffect(() => {
    if (!ref) return;

    const observer = new MutationObserver(() => {
      setDirty((x) => x + 1);
    });
    observer.observe(ref, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  React.useEffect(() => {
    if (!ref) return;

    const anchors = ref.querySelectorAll('a');
    const videos = ref.querySelectorAll('video');

    const unsub: (() => void)[] = [];

    anchors.forEach((element: Element) => {
      element.addEventListener('click', props.anchorClickHandler);
      unsub.push(() => element.removeEventListener('click', props.anchorClickHandler));
    });

    videos.forEach((element: Element) => {
      element.addEventListener('play', props.videoPlayHandler);
      unsub.push(() => element.removeEventListener('play', props.videoPlayHandler));
    });

    return () => {
      unsub.forEach((u) => u());
    };
  }, [ref, dirty]);

  return (
    <div ref={setRef} style={{ height: '100%' }}>
      {props.children}
    </div>
  );
};
