import { cva } from 'class-variance-authority';

export const buttonStyles = cva(
  [
    'rounded-button',
    'whitespace-nowrap',
    'shadow-sm',
    'font-medium',
    'flex',
    'border-none',
    'items-center',
    'gap-2',
    'justify-center',
    'w-fit',
    'relative',
    'top-0',
    'select-none',
    'cursor-pointer',
    'active:top-[1px]',
    'focus:shadow-sm',
    'focus-visible:outline-none',
  ],
  {
    variants: {
      variant: {
        default: [
          'bg-gray100',
          'text-gray700',
          'border border-solid',
          'border-gray400',
          'hover:text-gray1000',
          'hover:border-gray500',
          'py-sm',
          'px-md',
          '[&>svg:not(#cmdSpinner)]:text-gray600',
        ],
        primary: [
          'bg-blue700',
          'text-gray100',
          'border border-solid border-transparent',
          'hover:bg-blue800',
          'py-sm',
          'px-md',
          '[&>svg:not(#cmdSpinner)]:text-blue200',
          'focus:border-blue700',
        ],
        link: [
          'bg-transparent',
          'border border-solid border-transparent',
          'text-gray700',
          'hover:text-gray1000',
          'hover:bg-elementBase',
          'py-sm',
          'px-md',
          'shadow-none',
          'hover:[>svg:not(#cmdSpinner)]:text-gray800',
          '[>svg:not(#cmdSpinner)]:text-gray600',
        ],
        destructive: [
          'bg-gray100',
          'border border-solid',
          'border-red900',
          'hover:border-red800',
          'text-red900',
          'hover:text-red800',
          'py-sm',
          'px-md',
          'focus:shadow-inputError',
          'focus:border-red800',
        ],
        inverse: [
          'bg-transparent',
          'border border-solid border-transparent',
          'h-xxl',
          'w-xxl',
          'text-contentBase',
          'hover:text-contentInverse',
          'hover:bg-backgroundHoverInverse',
          'focus:border-blue700',
          'focus:shadow-button',
        ],
      },
      modifier: {
        filled: ['border border-transparent', 'bg-transparent', 'shadow-none'],
        outline: ['bg-gray100', 'border border-current'],
        blueHover: ['hover:text-blue800', 'hover:bg-blue200'],
      },
      size: {
        xs: ['w-[16px]', 'h-[16px]'],
        sm: ['text-sm', 'h-xl'],
        default: ['text-base', 'h-xxl'],
        lg: ['h-[40px]'],
      },
      fullWidth: {
        true: 'w-full',
      },
      disabled: {
        true: [
          'bg-gray200',
          'text-gray600',
          'border border-solid border-gray300',
          'cursor-not-allowed',
          'hover:bg-gray200',
          'hover:text-gray600',
          'hover:border',
          'hover:border-solid',
          'hover:border-gray300',
          'py-sm',
          'px-md',
          'active:top-0',
          'shadow-none',
          '[&>svg:not(#cmdSpinner)]:text-gray400',
        ],
      },
      iconOnly: {
        true: ['px-sm py-sm h-xxl'],
      },
      loading: {
        true: ['cursor-not-allowed active:top-0'],
      },
    },
    compoundVariants: [
      {
        disabled: true,
        variant: 'default',
        class:
          'border text-contentDisabled border-elementDisabled bg-surfaceDisabled hover:bg-surfaceDisabled hover:border-elementDisabled hover:text-contentDisabled',
      },
      {
        disabled: true,
        variant: 'link',
        class: 'border-transparent bg-transparent hover:bg-transparent hover:border-transparent',
      },
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export const tabVariants = cva(
  [
    'data-[state=on]:bg-gray100',
    'data-[state=on]:shadow-sm',
    'data-[state=on]:text-gray1000',
    'hover:text-gray1000',
    'flex',
    'bg-transparent',
    'border-none',
    'text-base',
    'text-gray600',
    'px-lg',
    'rounded-sm',
    'font-medium',
    'cursor-pointer',
    'h-[24px]',
    'items-center',
  ],
  {
    variants: {
      variant: {
        default: ['bg-transparent'],
        active: ['bg-gray100'],
      },
    },
  },
);

export const toggleVariants = cva([
  'data-[state=on]:bg-blue100',
  'data-[state=on]:shadow-none',
  'data-[state=on]:text-blue900',
  'hover:text-gray1000',
  'hover:bg-elementBase',
  'flex',
  'bg-transparent',
  'border-none',
  'text-base',
  'text-gray600',
  'p-sm',
  'rounded-sm',
  'font-medium',
  'cursor-pointer',
  'h-xxl',
  'items-center',
  'w-full',
]);
