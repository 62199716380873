export type Device = 'mobile' | 'tablet' | 'desktop' | 'wide' | 'extra-wide';

export const mediaQueries: {
  [key in Device]: string;
} = {
  mobile: '(max-width: 812px)',
  tablet: '(max-width: 1024px)',
  desktop: '(max-width: 1425px)',
  wide: '(max-width: 1600px)',
  'extra-wide': '(min-width: 1600px)',
};
