import styled, { type ComponentSelector } from '@emotion/styled';
import type { SelectProps } from 'antd/lib/select';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from 'react';

import { Select, Tag } from '@commandbar/design-system/components/antd';

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selector {
    height: 75px;
    display: block !important;
    overflow: auto;
    padding: 6px;
  }

  .ant-select-selection-placeholder {
    top: 16px !important;
  }
` as unknown as typeof Select & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

const StyledTag = ({ label, onClose }: CustomTagProps) => (
  <Tag onClose={onClose} style={{ whiteSpace: 'normal' }} closable>
    {label}
  </Tag>
);

export const TagSelect = (props: Omit<SelectProps, 'mode'>) => (
  <StyledSelect mode="tags" tagRender={StyledTag} dropdownStyle={{ display: 'none' }} {...props} />
);
