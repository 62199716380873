import { useCallback } from 'react';

import { CmdButton, CmdSwitch, CmdTypography, cmdToast } from '@commandbar/design-system/cmd';
import { Form } from '@commandbar/design-system/components/antd';
import Amplitude from '@commandbar/internal/middleware/integrations/amplitude';
import * as Organization from '@commandbar/internal/middleware/organization';
import Logger from '@commandbar/internal/util/Logger';
import { useAppContext } from 'editor/src/AppStateContext';
import type { StepProps } from './ActivationPage';
import { IntegrationInstructionsSection } from '../thin-analytics/styles';
import { additionalInstructions } from '../thin-analytics/Instructions';

export const ConfigurationStep = ({ handleNext, amplitudeConfig, setAmplitudeConfig }: StepProps) => {
  const { organization } = useAppContext();

  const updateConfiguration = useCallback(() => {
    Organization.readInternal(organization.id.toString()).then(({ integrations }) => {
      Organization.updateInternal({
        id: organization.id.toString(),
        integrations: {
          ...integrations,
          amplitude: {
            ...integrations.amplitude,
            enabled: !amplitudeConfig.enabled,
          },
        },
      });
    });
    setAmplitudeConfig((config) => ({ ...config, enabled: !config.enabled }));
  }, [amplitudeConfig.enabled, organization.id, setAmplitudeConfig]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Form.Item
        validateTrigger="onSubmit"
        initialValue={amplitudeConfig.enabled}
        key="enabled"
        label="Send Command AI events to Amplitude"
        name="enabled"
        help={
          'Use Amplitude as a data destination. Measure the impact of your Bar, Nudges, Checklists, and more - all within Amplitude.'
        }
      >
        <CmdSwitch
          offLabel="Do not send"
          onLabel="Send"
          checked={amplitudeConfig.enabled}
          onCheckedChange={updateConfiguration}
        />
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CmdButton
          variant="primary"
          type="submit"
          onClick={async () => {
            try {
              const { api_key } = await Amplitude.activate();
              setAmplitudeConfig((config) => ({ ...config, api_key }));
              handleNext();
            } catch (e) {
              Logger.error(e);
              cmdToast.error('Unable to activate integration. Please try again');
            }
          }}
        >
          Next
        </CmdButton>
      </div>

      {amplitudeConfig.enabled && (
        <IntegrationInstructionsSection>
          <div>
            <CmdTypography.H2>Sending Properties to CommandBar</CmdTypography.H2>
            {additionalInstructions.amplitude.sendProperties()}
          </div>

          <div>
            <CmdTypography.H2>Sending Events to CommandBar</CmdTypography.H2>
            {additionalInstructions.amplitude.sendEvents()}
          </div>
        </IntegrationInstructionsSection>
      )}
    </div>
  );
};
