import * as React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { cn } from '../util';
import { Caret01, Check } from '@commandbar/design-system/icons/react';
import Caret from '@commandbar/design-system/icons/react/Caret';
import { buttonStyles, CmdButton } from '../button';
import { CmdCheckbox } from '../checkbox';

const CmdDropdownMenu = DropdownMenuPrimitive.Root;

type CmdDropdownMenuTriggerProps = React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger> & {
  className?: string;
  onButtonClick?: () => void;
  asSplitButton?: boolean;
  hideArrow?: boolean;
  disabled?: boolean;
};

const CmdDropdownMenuSelectTrigger: React.FC<CmdDropdownMenuTriggerProps> = ({
  children,
  className,
  onButtonClick,
  asSplitButton,
  hideArrow,
  disabled,
  ...props
}) => {
  return (
    <DropdownMenuPrimitive.Trigger
      {...props}
      className={cn(
        'h-[32px] flex items-center gap-sm justify-between whitespace-nowrap rounded-button border border-elementMid hover:border-elementTop bg-gray100 px-sm py-sm text-base shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 relative',
      )}
      disabled={disabled}
    >
      <div className={cn('flex items-center gap-xs')}>{children}</div>
      {!hideArrow && (
        <div className={cn('w-lg')}>
          <Caret01 className="absolute right-[6px] top-[25%] rotate-180" />
        </div>
      )}
    </DropdownMenuPrimitive.Trigger>
  );
};

const CmdDropdownMenuTrigger: React.FC<CmdDropdownMenuTriggerProps> = ({
  children,
  className,
  onButtonClick,
  asSplitButton,
  disabled,
  ...props
}) => {
  const disabledStyle = disabled ? 'border border-gray-300 bg-gray-100 cursor-not-allowed' : '';
  return (
    <>
      {asSplitButton ? (
        <div className={cn(buttonStyles({ variant: 'default', disabled }), 'p-0 gap-0')}>
          <CmdButton
            onClick={onButtonClick}
            variant="link"
            {...props}
            className={`w-fit p-xs ${disabledStyle}`}
            disabled={disabled}
          >
            {children}
          </CmdButton>
          <DropdownMenuPrimitive.Trigger className={cn('bg-transparent border-none p-0', disabledStyle)} {...props}>
            <div className={`border-[0] border-l border-solid cursor-pointer border-gray-200 h-[30px] w-[30px]`}>
              <Caret className="absolute right-[2px] top-[45%]" />
            </div>
          </DropdownMenuPrimitive.Trigger>
        </div>
      ) : (
        <DropdownMenuPrimitive.Trigger
          className={cn('bg-transparent border-none p-0 w-fit focus-visible:outline-none', disabledStyle, className)}
          disabled={disabled}
          {...props}
        >
          {children}
        </DropdownMenuPrimitive.Trigger>
      )}
    </>
  );
};

const CmdDropdownMenuGroup = DropdownMenuPrimitive.Group;

const CmdDropdownMenuPortal = DropdownMenuPrimitive.Portal;

const CmdDropdownMenuSub = DropdownMenuPrimitive.Sub;

const CmdDropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

const CmdDropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
      inset && 'pl-8',
      className,
    )}
    {...props}
  >
    {children}
    <Caret className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
));
CmdDropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

const CmdDropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    ref={ref}
    className={cn(
      'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
CmdDropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

const CmdDropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    container?: HTMLElement;
    matchTriggerWidth?: boolean;
  }
>(({ className, sideOffset = 4, matchTriggerWidth, style, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal container={props.container}>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-max min-w-[8rem] overflow-hidden rounded-md border bg-gray100 p-1 text-popover-foreground shadow-md cursor-pointer',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      style={{
        width: matchTriggerWidth ? 'var(--radix-dropdown-menu-trigger-width)' : undefined,
        maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
        overflowY: 'auto',
        ...style,
      }}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));
CmdDropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const CmdDropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'relative gap-sm flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg:not(#cmdSpinner)]:text-gray800 text-base hover:bg-gray200 focus:bg-gray200',
      inset && 'pl-8',
      className,
    )}
    {...props}
  />
));
CmdDropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const CmdDropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> & {
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
  }
>(({ className, children, checked, onCheckedChange, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
    checked={checked}
    onCheckedChange={onCheckedChange}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <CmdCheckbox checked={checked} />
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
CmdDropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

const CmdDropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      'relative flex gap-sm justify-between select-none items-center rounded-sm text-base outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 px-md py-xs hover:bg-gray200 focus:bg-gray200 cursor-pointer',
      className,
    )}
    {...props}
  >
    {children}
    <DropdownMenuPrimitive.ItemIndicator className={cn('h-lg')}>
      <Check className="text-gray600" />
    </DropdownMenuPrimitive.ItemIndicator>
  </DropdownMenuPrimitive.RadioItem>
));
CmdDropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const CmdDropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn('px-2 py-1.5 text-base font-medium text-gray600', className)}
    {...props}
  />
));
CmdDropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const CmdDropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-gray200', className)} {...props} />
));
CmdDropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const CmdDropdownMenuItemRight = ({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('ml-auto', className)} {...props} />;
};
CmdDropdownMenuItemRight.displayName = 'DropdownMenuItemRight';

const CmdDropdown = {
  Menu: CmdDropdownMenu,
  Trigger: CmdDropdownMenuTrigger,
  SelectTrigger: CmdDropdownMenuSelectTrigger,
  Content: CmdDropdownMenuContent,
  Item: CmdDropdownMenuItem,
  CheckboxItem: CmdDropdownMenuCheckboxItem,
  RadioItem: CmdDropdownMenuRadioItem,
  Label: CmdDropdownMenuLabel,
  Separator: CmdDropdownMenuSeparator,
  ItemAction: CmdDropdownMenuItemRight,
  Group: CmdDropdownMenuGroup,
  Portal: CmdDropdownMenuPortal,
  Sub: CmdDropdownMenuSub,
  SubContent: CmdDropdownMenuSubContent,
  SubTrigger: CmdDropdownMenuSubTrigger,
  RadioGroup: CmdDropdownMenuRadioGroup,
};

export { CmdDropdown };
