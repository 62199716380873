import React, { useMemo } from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { GenericAutoComplete } from '../GenericAutoComplete';
import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { ABTestCondition as ABTestConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { getConditions, isABTestCondition } from '@commandbar/internal/middleware/helpers/rules';
import { IChecklist, INudgeType } from '@commandbar/internal/middleware/types';
import { ConditionProps } from '../../types';

type AudienceBasedExperience = INudgeType | IChecklist;

const processAudience = (
  audience: AudienceBasedExperience['audience'],
  conditionFields: Set<ABTestConditionType['field']>,
) => {
  if (audience?.type !== 'rule_expression') return;

  for (const condition of getConditions(audience.expression)) {
    if (isABTestCondition(condition)) {
      conditionFields.add(condition.field);
    }
  }
};

export const ABTestCondition: React.FC<ConditionProps<ABTestConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();
  const { nudges, checklists } = useAppContext();

  const options = useMemo(() => {
    const conditionFields = new Set<ABTestConditionType['field']>();

    const addConditions = <T extends AudienceBasedExperience>(items: T[]) => {
      for (const item of items) {
        processAudience(item.audience, conditionFields);
      }
    };

    addConditions(nudges);
    addConditions(checklists);

    return Array.from(conditionFields).map((field) => ({
      label: field,
      value: field,
    }));
  }, [nudges, checklists]);

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <Col flex="1 2 auto">
        <GenericAutoComplete
          options={options}
          onChange={(field) => {
            onConditionChange({ ...condition, field });
          }}
          allowArbitraryValue
          disabled={disabled}
          placeholder="Group"
          onBlur={() => setHasBlurred(true)}
        />
      </Col>
      <OperatorSelect />
      <Col style={{ overflow: 'hidden' }} flex="0 0 auto">
        <Select
          value={condition.value}
          onChange={(value) => onConditionChange({ ...condition, value })}
          size="small"
          onBlur={() => setHasBlurred(true)}
        >
          <Select.Option value="A">A</Select.Option>
          <Select.Option value="B">B</Select.Option>
        </Select>
      </Col>
    </Row>
  );
};
