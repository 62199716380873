/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React, { SetStateAction, useRef } from 'react';

import * as Command from '@commandbar/internal/middleware/command';
import { Rule } from '@commandbar/internal/middleware/rule';

import { CommandCategory } from '@commandbar/internal/middleware/commandCategory';

import {
  IAPI,
  IBatchOperation,
  IChecklist,
  ICommandCategoryType,
  IEditorCommandType,
  IEditorCommandTypeLite,
  IExperienceTemplate,
  IKeyword,
  ILocalizedMessage,
  ILocalizedMessagePatch,
  INudgeType,
  IOrganizationSettingsType,
  IOrganizationType,
  IThemeType,
  IUserType,
  IWorkflow,
} from '@commandbar/internal/middleware/types';
import { INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import { compareObjs } from '@commandbar/internal/middleware/utils';
import { getNewSortkey } from '../components/SortableTable';

import Sender from '../../management/Sender';
import { useReportEvent } from '../../hooks/useEventReporting';
import { get } from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import { Checklist } from '@commandbar/internal/middleware/checklist';
import { useUsage } from '../../hooks/useUsage';
import { useReloadable } from '../../hooks/useReloadable';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';
import { nudgeTypeDisplay } from '../nudges/NudgeList';
import dayjs from 'dayjs';
import { getDisplayTitle } from '@commandbar/internal/util/nudges';
import { Flags, fetchFlags } from '@commandbar/internal/middleware/flags';
import { reportErrorToUser } from '../utils/ErrorReporting';
import collectEditorTags from '../utils/collectEditorTags';
import { ExperienceTemplate } from '@commandbar/internal/middleware/experienceTemplate';
import { Tag } from '@commandbar/internal/middleware/tag';
import { API } from '@commandbar/internal/middleware/api';
import { LocalizedMessage, LocalizedMessages } from '@commandbar/internal/middleware/localizedMessage';
import { ITag } from '@commandbar/internal/middleware/tag';
import { Workflow } from '@commandbar/internal/middleware/workflow';
import { Keyword } from '@commandbar/internal/middleware/keyword';
import { Theme } from '@commandbar/internal/middleware/theme';
import { cmdToast } from '@commandbar/design-system/cmd';
import { getReportingLabelForCommand } from '../commands/utils';

const useEditor = (user: IUserType, isStudio?: boolean) => {
  /******************************************************************/
  /* Internal
  /******************************************************************/
  const [organization, setOrganization] = React.useState<IOrganizationType | undefined>(undefined);
  const [organizationSettings, setOrganizationSettings] = React.useState<IOrganizationSettingsType | undefined>(
    undefined,
  );
  const [categories, setCategories] = React.useState<ICommandCategoryType[]>([]);
  const [commands, _setCommands] = React.useState<IEditorCommandTypeLite[]>([]);
  const [answers, _setAnswers] = React.useState<IEditorCommandType[]>([]);
  const [nudges, _setNudges] = React.useState<INudgeType[]>([]);
  const [checklists, _setChecklists] = React.useState<IChecklist[]>([]);
  const [keywords, _setKeywords] = React.useState<IKeyword[]>([]);
  const [themes, _setThemes] = React.useState<IThemeType[]>([]);
  const [flags, setFlags] = React.useState<Flags | undefined>(undefined);
  const [templates, setTemplates] = React.useState<IExperienceTemplate[]>([]);
  const [tags, setTags] = React.useState<ITag[]>([]);
  const [APIs, setAPIs] = React.useState<IAPI[]>([]);
  const [workflows, setWorkflows] = React.useState<IWorkflow[]>([]);

  const [_localizedMessages, _setLocalizedMessages] = React.useState<ILocalizedMessage[]>([]);
  const [localizedMessages, setLocalizedMessages] = React.useState<LocalizedMessages>({});

  const hasUnsavedChangesRef = useRef<boolean>(false);

  const [commandBarReady, setCommandBarReady] = React.useState<boolean>(false);
  const [editorPreviewDevice, setEditorPreviewDevice] = React.useState<DeviceType>('desktop');
  const [editorCopilotOverrides, setEditorCopilotOverrides] = React.useState<{
    user?: string;
    userProperties?: Record<string, any>;
    filter?: string[];
  }>({});

  const setAnswers = (newAnswers: SetStateAction<IEditorCommandType[]>) => {
    if (!isStudio) {
      return _setAnswers(newAnswers);
    } else {
      // Update state and handle side effects
      _setAnswers((prevAnswers) => {
        // Update the state
        const updateAnswers = typeof newAnswers === 'function' ? newAnswers(prevAnswers) : newAnswers;

        const answerExperiences = updateAnswers.map((answer: IEditorCommandType) => {
          const daysSinceLastSync = -dayjs(answer.modified).diff(dayjs(), 'day');

          const lastUpdated = daysSinceLastSync ? `${daysSinceLastSync}d ago` : 'Today';
          return {
            id: answer.id,
            label: answer.text,
            description: `${answer.is_live ? 'Published' : 'Unpublished'} - Last updated: ${lastUpdated}`,
          };
        });

        window.CommandBar.addRecords('answers', answerExperiences);

        // Return the new state
        return updateAnswers;
      });
    }
  };

  const setCommands = (newCommands: SetStateAction<IEditorCommandTypeLite[]>) => {
    if (!isStudio) {
      return _setCommands(newCommands);
    } else {
      // Update state and handle side effects
      _setCommands((prevCommands) => {
        // If you need to do something based on the previous state, do it here

        // Update the state
        const updatedCommands = typeof newCommands === 'function' ? newCommands(prevCommands) : newCommands;

        const content = [];
        const pages = [];
        const actions = [];

        for (const command of updatedCommands) {
          const contentType = Command.getContentType(command);

          if (contentType) {
            const descriptionItems = [];
            let contentEditUrl = '';

            switch (contentType) {
              case 'source':
                descriptionItems.push(`Source Doc (${command.third_party_source})`);
                contentEditUrl = `/content/sources/${command?.integration?.id}/?id=${command.id}`;
                break;
              case 'answer':
                descriptionItems.push(`Answer`);
                contentEditUrl = `/content/answers/?id=${command.id}`;
                break;
              case 'file':
                descriptionItems.push(`File`);
                contentEditUrl = `/content/files/?id=${command.id}`;
                break;
              case 'video':
                descriptionItems.push(`Video`);
                contentEditUrl = `/content/videos/?id=${command.id}`;
                break;
              default:
                break;
            }

            content.push({
              id: command.id,
              label: command.text,
              description: descriptionItems.join(' - '),
              url: contentEditUrl,
            });
          } else if (command.template.type === 'link' && Command.showInDefaultList(command)) {
            pages.push({
              id: command.id,
              label: command.text,
              description: command.template.value,
            });
          } else if (Command.showInDefaultList(command))
            actions.push({
              id: command.id,
              label: command.text,
              description: command.template.type,
            });
        }

        window.CommandBar.addRecords('content', content);
        window.CommandBar.addRecords('pages', pages);
        window.CommandBar.addRecords('action', actions);

        // Return the new state
        return updatedCommands;
      });
    }
  };

  const setChecklists = (newChecklists: SetStateAction<IChecklist[]>) => {
    if (!isStudio) {
      return _setChecklists(newChecklists);
    } else {
      // Update state and handle side effects
      _setChecklists((prevChecklists) => {
        // If you need to do something based on the previous state, do it here

        // Update the state
        const updatedChecklists = typeof newChecklists === 'function' ? newChecklists(prevChecklists) : newChecklists;

        const checklistExperiences = updatedChecklists.map((checklist: IChecklist) => {
          let itemString = '';
          if (checklist.items.length === 1) {
            itemString = '1 item';
          } else if (checklist.items.length > 1) {
            itemString = `${checklist.items.length} items`;
          }
          return {
            id: checklist.id,
            label: checklist.title,
            description: `${itemString}${checklist.description ? ' - ' + checklist.description : ''}`,
          };
        });

        window.CommandBar.addRecords('checklists', checklistExperiences);

        // Return the new state
        return updatedChecklists;
      });
    }
  };

  const setKeywords = (newKeywords: SetStateAction<IKeyword[]>) => {
    if (!isStudio) {
      return _setKeywords(newKeywords);
    } else {
      // Update state and handle side effects
      _setKeywords((prevKeywords) => {
        // If you need to do something based on the previous state, do it here

        // Update the state
        const updatedkeywords = typeof newKeywords === 'function' ? newKeywords(prevKeywords) : newKeywords;

        const keywordExperiences = updatedkeywords.map((keyword: IKeyword) => {
          return {
            id: keyword.id,
            keyword: keyword.keyword,
            defintion: keyword.definition,
          };
        });

        window.CommandBar.addRecords('keywords', keywordExperiences);

        // Return the new state
        return updatedkeywords;
      });
    }
  };

  const setThemes = (newThemes: SetStateAction<IThemeType[]>) => {
    if (!isStudio) {
      return _setThemes(newThemes);
    } else {
      // Update state and handle side effects
      _setThemes((prevThemes) => {
        // If you need to do something based on the previous state, do it here

        // Update the state
        const updatedThemes = typeof newThemes === 'function' ? newThemes(prevThemes) : newThemes;

        const themeRecords = updatedThemes.map((theme: IThemeType) => {
          return {
            id: theme.id,
            name: theme.name,
            slug: theme.slug,
          };
        });

        window.CommandBar.addRecords('themes', themeRecords);

        // Return the new state
        return updatedThemes;
      });
    }
  };

  const setNudges = (newNudges: SetStateAction<INudgeType[]>) => {
    if (!isStudio) {
      return _setNudges(newNudges);
    } else {
      // Update state and handle side effects
      _setNudges((prevNudges) => {
        // If you need to do something based on the previous state, do it here

        // Update the state
        const updatedNudges = typeof newNudges === 'function' ? newNudges(prevNudges) : newNudges;

        const tours = [];
        const announcements = [];
        const surveys = [];

        for (const nudge of updatedNudges) {
          let itemString = '';
          if (nudge.steps.length === 1) {
            itemString = '1 step';
          } else if (nudge.steps.length > 1) {
            itemString = `${nudge.steps.length} steps`;
          }

          const description = `${itemString} - ${nudge.is_live ? 'Published' : 'Unpublished'}`;

          if (nudge.type === 'product_tour') {
            tours.push({
              id: nudge.id,
              label: getDisplayTitle(nudge),
              description: description,
            });
          } else if (nudge.type === 'announcement') {
            announcements.push({
              id: nudge.id,
              label: getDisplayTitle(nudge),
              description: description,
            });
          } else if (nudge.type === 'survey') {
            surveys.push({
              id: nudge.id,
              label: getDisplayTitle(nudge),
              description: description,
            });
          }
        }

        window.CommandBar.addRecords('product_tours', tours);
        window.CommandBar.addRecords('announcements', announcements);
        window.CommandBar.addRecords('surveys', surveys);

        // Return the new state
        return updatedNudges;
      });
    }
  };

  const [rules, setRules] = React.useState<INamedRule[]>([]);
  const { reportEvent } = useReportEvent();
  const { refetch: refetchUsage } = useUsage();

  const orgId = organization && String(organization.id);

  React.useEffect(() => {
    Sender.shareOrganization(organization || null);
  }, [orgId]);

  /******************************************************************/

  // Bulk update command sort keys within a category
  const onCommandReorder = async (
    oldIndexOfMovedObj: number,
    newIndexOfMovedObj: number,
    categoryID: number | null,
  ) => {
    const category = categories.find((category) => category.id === categoryID);
    // Get the commands within this category
    const sortedCommands = commands.filter((command) => command.category === categoryID).sort(compareObjs);
    const unchangedCommands = commands.filter((command) => command.category !== categoryID);

    const sortedCommandsWithUpdatedSortKey = sortedCommands.map((command, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      return { ...command, sort_key: newSortKey };
    });

    const tempNewCommands = [...unchangedCommands, ...sortedCommandsWithUpdatedSortKey];

    // const promises = sortedCommands.map((command: IEditorCommandType, currentIndex: number) => {
    //   const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
    //   const newCommand = { ...command, sort_key: newSortKey };
    //   tempNewCommands.push(newCommand);

    //   // Only make an api call if the category has changed
    //   if (newCommand.sort_key === command.sort_key) return Promise.resolve(command);
    //   else return Command.update(newCommand);
    // });
    // Temporary set new categories so front end is not jumpy
    setCommands(tempNewCommands);
    const batch: IBatchOperation[] = [];

    sortedCommands.forEach((command, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      if (newSortKey !== command.sort_key) {
        batch.push({ op: 'update', id: command.id, data: { sort_key: newSortKey } });
      }
    });

    let note = 'Changing sort order of commands';
    if (category) {
      note += ` in category ${category.name}`;
    }
    const result = await Command.batch({ batch, note });
    const resultsByCommandId: { [id: number]: IEditorCommandType } = {};
    result.batch.forEach((command) => {
      resultsByCommandId[command.id] = command;
    });

    // Once new objs are received, reset
    const newCommands = sortedCommands.map((command) => resultsByCommandId[command.id] || command);
    setCommands([...unchangedCommands, ...newCommands]);

    Sender.reload(['reloadCommands']);
  };

  // Bulk update category sort keys
  const updateCategorySortKeys = async (sortKeyMapping: { [id: number]: number }) => {
    const batch: IBatchOperation[] = [];

    categories.forEach((category) => {
      if (sortKeyMapping.hasOwnProperty(category.id) && sortKeyMapping[category.id] !== category.sort_key) {
        batch.push({ op: 'update', id: category.id, data: { sort_key: sortKeyMapping[category.id] } });
      }
    });

    const note = 'Changing sort order of categories';
    const result = await CommandCategory.batch({ batch, note });
    const resultsByCategoryId: { [id: number]: ICommandCategoryType } = {};
    result.batch.forEach((category) => {
      resultsByCategoryId[category.id] = category;
    });

    const newCategories = categories.map((category) => resultsByCategoryId[category.id] || category);
    setCategories(newCategories);

    Sender.reload(['reloadCommands']);
  };

  const bulkUpdate = async (batch: IBatchOperation[], note: string) => {
    if (batch.length < 1) {
      return;
    }

    const result = await Command.batch({ batch, note });
    const resultsByCommandId: { [id: number]: IEditorCommandType } = {};
    const deleted = new Set<number>();

    batch.forEach((entry) => {
      if (entry.op === 'delete') {
        deleted.add(entry.id);
      }
    });

    result.batch.forEach((command) => {
      resultsByCommandId[command.id] = command;
    });

    let newCommands: IEditorCommandTypeLite[] = [];
    newCommands = commands.map((command) => resultsByCommandId[command.id] || command);
    newCommands = newCommands.filter((command) => !deleted.has(command.id));
    setCommands(newCommands);

    cmdToast.success(`Changes applied successfully`);

    Sender.reload(['reloadCommands']);
  };

  const bulkUpdateKeywords = async (batch: IBatchOperation[], note: string) => {
    if (batch.length < 1) {
      return;
    }

    const result = await Keyword.batch({ batch, note });
    const resultsByKeywordId: { [id: number]: IKeyword } = {};
    const deleted = new Set<number>();

    batch.forEach((entry) => {
      if (entry.op === 'delete') {
        deleted.add(entry.id);
      }
    });

    result.batch.forEach((command) => {
      resultsByKeywordId[command.id] = command;
    });

    let newKeywords: IKeyword[] = [];
    newKeywords = keywords.map((keyword) => resultsByKeywordId[keyword.id] || keyword);
    newKeywords = newKeywords.filter((keyword) => !deleted.has(keyword.id));
    setKeywords(newKeywords);

    cmdToast.success(`Changes applied successfully`);

    await Sender.reload(['reloadOrganization']);
  };

  // Bulk update category sort keys
  const onCategoryReorder = async (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const sortedCurrentCategories = categories.sort(compareObjs);

    const sortedCategoriesWithUpdatedSortKey = sortedCurrentCategories.map((category, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      return { ...category, sort_key: newSortKey };
    });

    setCategories(sortedCategoriesWithUpdatedSortKey);
    const batch: IBatchOperation[] = [];

    sortedCurrentCategories.forEach((category, currentIndex) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);
      if (newSortKey !== category.sort_key) {
        batch.push({ op: 'update', id: category.id, data: { sort_key: newSortKey } });
      }
    });

    const note = 'Changing sort order of categories';
    const result = await CommandCategory.batch({ batch, note });
    const resultsByCategoryId: { [id: number]: ICommandCategoryType } = {};
    result.batch.forEach((category) => {
      resultsByCategoryId[category.id] = category;
    });

    const newCategories = sortedCurrentCategories.map((category) => resultsByCategoryId[category.id] || category);
    setCategories(newCategories);
  };

  const saveCategory = async (newObj: ICommandCategoryType, opts = { notify: true }) => {
    if (newObj.id < 0) {
      try {
        const newCategory = await CommandCategory.create(newObj);
        setCategories((oldCategories) => [...oldCategories, newCategory]);
        reportEvent('category created', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${newCategory.name} (ID: ${newCategory.id})`,
        });
        cmdToast.success('New category created.');

        Sender.reload(['reloadCommands']);

        return newCategory;
      } catch (err) {
        if (String(err).includes('UNIQUE')) {
          cmdToast.error('Could not create category because another category has the same name.');
        } else {
          reportErrorToUser(err);
        }
      }
    } else {
      try {
        const onSuccess = opts.notify ? () => cmdToast.success('Category updated') : undefined;
        const newCategory = await CommandCategory.update(newObj, onSuccess);
        setCategories((oldCategories) =>
          oldCategories.map((category: ICommandCategoryType) =>
            category.id === newCategory.id ? newCategory : category,
          ),
        );
        await Sender.reload(['reloadCommands']);
        reportEvent('category edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${newCategory.name} (ID: ${newCategory.id})`,
        });

        return newCategory;
      } catch (err) {
        if (String(err).includes('unique')) {
          cmdToast.error('Could not update category because another category has the same name.');
        } else {
          reportErrorToUser(err);
        }
      }
    }
  };

  const deleteCategory = async (categoryID: number) => {
    const category = categories.find((category) => category.id === categoryID);
    if (!category) {
      return;
    }

    const commandsToDelete = commands.filter((command) => command.category === categoryID);
    const batch: IBatchOperation[] = [];

    commandsToDelete.forEach((command) => {
      batch.push({ op: 'delete', id: command.id });
    });

    const note = `Delete commands from category ${category.name}`;

    if (batch.length > 0) {
      let commandDeletionSuccessful = false;
      await Command.batch({ batch, note })
        .then(() => {
          setCommands(commands.filter((command) => command.category !== categoryID));
          cmdToast.success(`Pages and actions from category ${category.name} deleted`);

          Sender.reload(['reloadCommands']);
          commandDeletionSuccessful = true;
        })
        .catch((e) => {
          reportErrorToUser(e);
        });
      if (!commandDeletionSuccessful) {
        return;
      }
    }

    return await CommandCategory.delete(categoryID)
      .then(() => {
        setCategories((oldCategories: ICommandCategoryType[]) => [
          ...oldCategories.filter((el) => el.id !== categoryID),
        ]);
        cmdToast.success(`Category ${category.name} deleted`);

        reportEvent('category deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${category.name} (ID: ${category.id}))`,
        });

        Sender.reload(['reloadCommands']);
      })
      .catch((e) => {
        reportErrorToUser(e);
      });
  };

  const saveCommand = async (newObj: IEditorCommandType, opts = { notify: true, throttle: true }) => {
    const toReload: ('reloadCommands' | 'reloadHelpHub')[] = ['reloadCommands'];

    if (newObj.template.type === 'helpdoc') {
      toReload.push('reloadHelpHub');
    }

    const isAnswer = newObj.template.type === 'helpdoc' && newObj.template.doc_type === 'answer';
    const upperCaseLabel = getReportingLabelForCommand(newObj);
    const isCommand = upperCaseLabel === 'Action' || upperCaseLabel === 'Page';
    if (newObj.id < 0) {
      return await Command.create(
        newObj,
        opts.notify ? () => cmdToast.success(`${upperCaseLabel} updated`) : undefined,
        reportErrorToUser,
      ).then(async (newCommand) => {
        // Refresh the total number of live commands
        if (isCommand) {
          refetchUsage();
        }
        Sender.reload(toReload);

        if (isAnswer) {
          setAnswers((oldAnswers) => [newCommand, ...oldAnswers]);
        } else {
          setCommands((oldCommands) => [newCommand, ...oldCommands]);
        }

        reportEvent(`${upperCaseLabel.toLowerCase()} created`, {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });

        return newCommand;
      });
    } else {
      const handleNewCommand = async (newCommand: IEditorCommandType) => {
        // Refresh the total number of live commands
        if (isCommand) {
          refetchUsage();
        }
        Sender.reload(toReload);

        if (isAnswer) {
          setAnswers((oldAnswers) => oldAnswers.map((answer) => (answer.id === newCommand.id ? newCommand : answer)));
        } else {
          setCommands((oldCommands) =>
            oldCommands.map((command) => (command.id === newCommand.id ? newCommand : command)),
          );
        }

        reportEvent(`${upperCaseLabel.toLowerCase()} edited`, {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });

        return newCommand;
      };

      const onSuccess = opts.notify ? () => cmdToast.success(`${upperCaseLabel} updated`) : undefined;

      if (opts.throttle) {
        return await Command.update(newObj, onSuccess, reportErrorToUser).then(handleNewCommand);
      } else {
        return await Command.updateWithoutThrottle(newObj, onSuccess, reportErrorToUser).then(handleNewCommand);
      }
    }
  };
  const savePartialCommand = async (command: Pick<IEditorCommandType, 'id'> & Partial<IEditorCommandType>) => {
    Command.updatePartial(command)
      .then((newCommand) => {
        const toReload: ('reloadCommands' | 'reloadHelpHub')[] = ['reloadCommands'];
        if (newCommand.template.type === 'helpdoc') {
          toReload.push('reloadHelpHub');
        }

        Sender.reload(toReload);
        setCommands((oldCommands) =>
          oldCommands.map((command) => (command.id === newCommand.id ? newCommand : command)),
        );
        reportEvent('command edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: newCommand.text,
          eventProps: {
            commandId: newCommand.id,
            commandType: newCommand.template.type,
          },
        });
      })
      .catch(() => cmdToast.error('Something went wrong'));
  };

  const deleteCommand = async (command: IEditorCommandTypeLite) => {
    const isAnswer = command.template.type === 'helpdoc' && command.template.doc_type === 'answer';
    const upperCaseLabel = getReportingLabelForCommand(command);

    await Command.del(command.id, undefined, () => cmdToast.success(`${upperCaseLabel} deleted`), reportErrorToUser);

    if (isAnswer) {
      setAnswers((oldAnswers) => [...oldAnswers.filter((el) => el.id !== command.id)]);
    } else {
      setCommands((oldCommands) => [...oldCommands.filter((el) => el.id !== command.id)]);
    }

    // Refresh the total number of live commands
    refetchUsage();
    Sender.reload(['reloadCommands']);

    reportEvent(`${isAnswer ? 'answer' : 'command'} deleted`, {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: command.text,
      eventProps: {
        commandId: command.id,
        commandType: command.template.type,
      },
    });
  };

  const addRule = async (rule: INamedRule, fromExistingCondition: boolean) => {
    const displayName = rule.is_audience ? 'Audience' : 'Rule';
    const result = fromExistingCondition
      ? await Rule.createFromExistingCondition(
          rule,
          () => cmdToast.success(`${displayName} created!`),
          reportErrorToUser,
        )
      : await Rule.create(rule, () => cmdToast.success(`${displayName} created!`), reportErrorToUser);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges', 'reloadHelpHub']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [result, ...rules]);
    reportEvent('rule created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `${rule.name} (ID: ${result.id}, Audience?: ${rule.is_audience}))`,
    });
  };

  const changeRule = (id: string | number) => async (rule: INamedRule) => {
    const displayName = rule.is_audience ? 'Audience' : 'Rule';
    const idx = rules.findIndex((rule) => rule.id === id);
    if (idx < 0) throw new Error(`Rule with id ${id} not found`);

    await Rule.update(rule, () => cmdToast.success(`${displayName} updated!`), reportErrorToUser);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges', 'reloadHelpHub']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [...rules.slice(0, idx), rule, ...rules.slice(idx + 1)]);
    reportEvent('rule edited', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        name: rule.name,
      },
    });
  };

  const removeRule = (id: string | number) => async () => {
    const idx = rules.findIndex((rule) => rule.id === id);
    if (idx < 0) throw new Error(`Rule with id ${id} not found`);

    const deletedRule = rules[idx];

    const displayName = deletedRule.is_audience ? 'Audience' : 'Rule';
    await Rule.delete(rules[idx].id, undefined, () => cmdToast.success(`${displayName} deleted`), reportErrorToUser);

    await Sender.reload(['reloadCommands', 'reloadChecklists', 'reloadNudges', 'reloadHelpHub']);
    await reloadCommands();
    await reloadNudges();
    await reloadChecklists();

    setRules((rules) => [...rules.slice(0, idx), ...rules.slice(idx + 1)]);

    reportEvent('audience deleted', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        name: deletedRule.name,
      },
    });
  };

  /** Nudges */

  const saveNudge = async (nudge: INudgeType) => {
    if (Nudge.isNew(nudge)) {
      const newNudge = await Nudge.create(
        nudge,
        () => cmdToast.success(`${nudgeTypeDisplay(nudge.type)} created!`),
        reportErrorToUser,
      );
      setNudges((oldList) => [newNudge, ...oldList]);

      // Recalculate the number of live nudges
      refetchUsage();
      Sender.reload(['reloadNudges', 'reloadHelpHub']);

      const payloadMessage = newNudge?.slug ? `${newNudge.slug} (ID: ${newNudge.id})` : `Untitled (ID: ${newNudge.id})`;
      reportEvent('nudge created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: newNudge.id,
          item_count: newNudge.steps.length,
          audience: newNudge.audience?.type,
          trigger: newNudge.trigger,
          template_source: newNudge.template_source,
        },
      });

      return newNudge;
    } else {
      const updatedNudge = await Nudge.update(
        nudge,
        () => cmdToast.success(`${nudgeTypeDisplay(nudge.type)} updated!`),
        reportErrorToUser,
      );
      setNudges((oldList) => oldList.map((v: INudgeType) => (v.id === updatedNudge.id ? updatedNudge : v)));

      // Recalculate the number of live nudges
      refetchUsage();
      Sender.reload(['reloadNudges', 'reloadHelpHub']);

      const payloadMessage = updatedNudge?.slug
        ? `${updatedNudge.slug} (ID: ${updatedNudge.id})`
        : `Untitled (ID: ${updatedNudge.id})`;
      reportEvent('nudge edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: updatedNudge.id,
          item_count: updatedNudge.steps.length,
          audience: updatedNudge.audience?.type,
          trigger: updatedNudge.trigger,
          template_source: updatedNudge.template_source,
        },
      });

      return updatedNudge;
    }
  };

  const deleteNudge = async (nudge: INudgeType) => {
    const postDelete = () => {
      const deletedNudge = nudges.find((v) => v.id === nudge.id);
      const payloadMessage = deletedNudge?.slug
        ? `${deletedNudge.slug} (ID: ${deletedNudge.id})`
        : `Untitled (ID: ${deletedNudge?.id})`;
      setNudges((oldList) => oldList.filter((v) => v.id !== nudge.id));

      reportEvent('nudge deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          id: deletedNudge?.id,
          item_count: deletedNudge?.steps.length,
          audience: deletedNudge?.audience?.type,
          trigger: deletedNudge?.trigger,
          template_source: deletedNudge?.template_source,
        },
      });
    };

    return await Nudge.delete(
      nudge.id,
      undefined,
      () => cmdToast.success(`${nudgeTypeDisplay(nudge.type)} deleted`),
      reportErrorToUser,
    )
      .then(postDelete)
      .then(async () => {
        // Recalculate the number of live nudges
        refetchUsage();
        Sender.reload(['reloadNudges', 'reloadHelpHub']);
      });
  };

  const saveChecklist = async (checklist: IChecklist) => {
    if (checklist.id < 0) {
      const newChecklist = await Checklist.create(
        checklist,
        () => cmdToast.success('Checklist created!'),
        reportErrorToUser,
      );

      setChecklists((oldList) => [newChecklist, ...oldList]);

      // Recalculate the number of live checklists
      refetchUsage();
      Sender.reload(['reloadChecklists', 'reloadHelpHub']);

      const payloadMessage = newChecklist.title
        ? `${newChecklist.title} (ID: ${newChecklist.id})`
        : `${newChecklist.description} (ID: ${newChecklist.id})`;

      reportEvent('questlist created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          item_count: checklist.items.length,
          name: payloadMessage,
          template_source: newChecklist.template_source,
        },
      });

      return newChecklist;
    } else {
      const updatedChecklist = await Checklist.update(
        checklist,
        () => cmdToast.success('Checklist updated!'),
        reportErrorToUser,
      );

      const payloadMessage = checklist.title
        ? `${checklist.title} (ID: ${checklist.id})`
        : `${checklist.description} (ID: ${checklist.id})`;
      setChecklists((oldList) => oldList.map((v: IChecklist) => (v.id === updatedChecklist.id ? updatedChecklist : v)));

      // Recalculate the number of live checklists
      refetchUsage();
      Sender.reload(['reloadChecklists', 'reloadHelpHub']);

      reportEvent('questlist edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          item_count: checklist.items.length,
          name: payloadMessage,
          template_source: checklist.template_source,
        },
      });

      return updatedChecklist;
    }
  };

  const saveTemplate = async (template: IExperienceTemplate) => {
    if (template.id < 0) {
      const newTemplate = await ExperienceTemplate.create(
        template,
        () => cmdToast.success('Template created!'),
        reportErrorToUser,
      );

      setTemplates((oldList) => [newTemplate, ...oldList]);

      reportEvent('template created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: 'ID: ' + newTemplate.id,
        eventProps: {
          type: template.type,
        },
      });

      return newTemplate;
    } else {
      const updatedTemplate = await ExperienceTemplate.update(
        template,
        () => cmdToast.success('Template updated!'),
        reportErrorToUser,
      );

      setTemplates((oldList) =>
        oldList.map((t: IExperienceTemplate) => (t.id === updatedTemplate.id ? updatedTemplate : t)),
      );

      reportEvent('template edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: 'ID: ' + updatedTemplate.id,
        eventProps: {
          type: updatedTemplate.id,
        },
      });

      return updatedTemplate;
    }
  };

  const deleteChecklist = async (toDeleteID: number) => {
    return await Checklist.delete(toDeleteID, undefined, () => cmdToast.success('Checklist deleted'), reportErrorToUser)
      .then(() => setChecklists((oldList) => oldList.filter((v) => v.id !== toDeleteID)))
      .then(async () => {
        // Recalculate the number of live checklists
        refetchUsage();
        Sender.reload(['reloadChecklists', 'reloadHelpHub']);

        const deletedChecklist = checklists.find((v) => v.id === toDeleteID);
        const payloadMessage = deletedChecklist?.title
          ? `${deletedChecklist.title} (ID: ${toDeleteID})`
          : `${deletedChecklist?.description} (ID: ${toDeleteID})`;

        reportEvent('questlist deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
            template_source: deletedChecklist?.template_source,
          },
        });
      });
  };

  const deleteTemplate = async (toDeleteID: number) => {
    return await ExperienceTemplate.delete(
      toDeleteID,
      undefined,
      () => cmdToast.success('Template deleted'),
      reportErrorToUser,
    )
      .then(() => setTemplates((oldList) => oldList.filter((t) => t.id !== toDeleteID)))
      .then(async () => {
        const deletedTemplate = templates.find((t) => t.id === toDeleteID);

        reportEvent('Template deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: 'ID: ' + deletedTemplate?.id,
          eventProps: {
            type: deletedTemplate?.type,
          },
        });
      });
  };

  const saveAPI = async (api: IAPI) => {
    if (api.id < 0) {
      const newAPI = await API.create(api, () => cmdToast.success('API created!'), reportErrorToUser);

      setAPIs((oldList) => [newAPI, ...oldList]);

      // Recalculate the number of live apis
      refetchUsage();
      Sender.reload(['reloadHelpHub']);

      const payloadMessage = `${newAPI.title} (ID: ${newAPI.id})`;

      reportEvent('api created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return newAPI;
    } else {
      const updatedAPI = await API.update(api, () => cmdToast.success('API saved.'), reportErrorToUser);

      const payloadMessage = `${api.title} (ID: ${api.id})`;
      setAPIs((oldList) => oldList.map((v: IAPI) => (v.id === updatedAPI.id ? updatedAPI : v)));

      refetchUsage();
      Sender.reload(['reloadHelpHub']);

      reportEvent('api edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return updatedAPI;
    }
  };

  const saveKeyword = async (keyword: IKeyword) => {
    if (keyword.id < 0) {
      const newKeyword = await Keyword.create(keyword, () => cmdToast.success('Keyword created!'), reportErrorToUser);

      setKeywords((oldList) => [newKeyword, ...oldList]);

      await Sender.reload(['reloadOrganization']);

      const payloadMessage = `${newKeyword.keyword} (ID: ${newKeyword.id})`;

      reportEvent('keyword created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return newKeyword;
    } else {
      const updatedKeyword = await Keyword.update(keyword, () => cmdToast.success('Keyword saved.'), reportErrorToUser);

      const payloadMessage = `${keyword.keyword} (ID: ${keyword.id})`;

      setKeywords((oldList) => oldList.map((v: IKeyword) => (v.id === updatedKeyword.id ? updatedKeyword : v)));

      await Sender.reload(['reloadOrganization']);

      reportEvent('keyword edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return updatedKeyword;
    }
  };
  const saveTheme = async (theme: Partial<IThemeType>) => {
    if (!theme?.id) {
      const newTheme = await Theme.create(theme, () => cmdToast.success('Theme created!'), reportErrorToUser);

      setThemes((oldList) => [newTheme, ...oldList]);

      await Sender.reload(['reloadThemes']);

      reportEvent('theme created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: newTheme?.slug,
      });

      return newTheme;
    } else {
      try {
        const updatedTheme = await Theme.update(theme);

        cmdToast.success('Theme saved.');

        setThemes((oldList) => oldList.map((v: IThemeType) => (v.id === updatedTheme.id ? updatedTheme : v)));

        await Sender.reload(['reloadThemes']);

        reportEvent('theme edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: theme.slug,
        });

        return updatedTheme;
      } catch (error) {
        reportErrorToUser(error);
      }
    }
  };

  const deleteTheme = async (toDeleteSlug: string) => {
    return await Theme.delete(toDeleteSlug, undefined, () => cmdToast.success('Theme deleted.'), reportErrorToUser)
      .then(() => setThemes((oldList) => oldList.filter((v) => v.slug !== toDeleteSlug)))
      .then(async () => {
        const payloadMessage = toDeleteSlug;

        reportEvent('theme deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
          },
        });
      });
  };

  const deleteAPI = async (toDeleteID: number) => {
    return await API.delete(toDeleteID, undefined, () => cmdToast.success('API deleted.'), reportErrorToUser)
      .then(() => setAPIs((oldList) => oldList.filter((v) => v.id !== toDeleteID)))
      .then(async () => {
        const deletedAPI = APIs.find((v) => v.id === toDeleteID);
        const payloadMessage = `${deletedAPI?.title} (ID: ${toDeleteID})`;

        reportEvent('api deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
          },
        });
      });
  };

  const deleteKeyword = async (toDeleteID: number) => {
    return await Keyword.delete(toDeleteID, undefined, () => cmdToast.success('Keyword deleted.'), reportErrorToUser)
      .then(() => setKeywords((oldList) => oldList.filter((v) => v.id !== toDeleteID)))
      .then(async () => {
        const deletedKeyword = keywords.find((v) => v.id === toDeleteID);
        const payloadMessage = `${deletedKeyword?.keyword} (ID: ${toDeleteID})`;

        reportEvent('keyword deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
          },
        });
      });
  };

  const saveWorkflow = async (workflow: IWorkflow) => {
    if (workflow.id < 0) {
      const newWorkflow = await Workflow.create(
        workflow,
        () => cmdToast.success('Workflow created!'),
        reportErrorToUser,
      );

      setWorkflows((oldList) => [newWorkflow, ...oldList]);

      // Recalculate the number of live workflows
      refetchUsage();
      Sender.reload(['reloadHelpHub']);

      const payloadMessage = `${newWorkflow.title} (ID: ${newWorkflow.id})`;

      reportEvent('workflow created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return newWorkflow;
    } else {
      const updatedWorkflow = await Workflow.update(
        workflow,
        () => cmdToast.success('Workflow saved.'),
        reportErrorToUser,
      );

      const payloadMessage = `${workflow.title} (ID: ${workflow.id})`;
      setWorkflows((oldList) => oldList.map((w: IWorkflow) => (w.id === workflow.id ? updatedWorkflow : w)));

      refetchUsage();
      Sender.reload(['reloadHelpHub']);

      reportEvent('workflow edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
        eventProps: {
          name: payloadMessage,
        },
      });

      return updatedWorkflow;
    }
  };

  const deleteWorkflow = async (toDeleteID: number) => {
    return await Workflow.delete(toDeleteID, undefined, () => cmdToast.success('Workflow deleted.'), reportErrorToUser)
      .then(() => setWorkflows((oldList) => oldList.filter((w) => w.id !== toDeleteID)))
      .then(async () => {
        refetchUsage();

        const deletedWorkflow = workflows.find((w) => w.id === toDeleteID);
        const payloadMessage = `${deletedWorkflow?.title} (ID: ${toDeleteID})`;

        reportEvent('workflow deleted', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: payloadMessage,
          eventProps: {
            name: payloadMessage,
          },
        });
      });
  };

  const updateLocalizedMessage = async (localizedMessage: ILocalizedMessagePatch) => {
    const idx = _localizedMessages.findIndex((messages) => messages.msgid === localizedMessage.msgid);

    if (idx < 0) {
      if (localizedMessage.msgstr.length === 0) return;
      const newMessage = await LocalizedMessage.create({ ...localizedMessage, id: -1 });
      _setLocalizedMessages((messages) => [...messages, newMessage]);
      setLocalizedMessages((messages) => ({ ...messages, [newMessage.msgid]: newMessage.msgstr }));
    } else {
      if (localizedMessage.msgstr.length > 0) {
        const updatedMessage = { ..._localizedMessages[idx], msgstr: localizedMessage.msgstr };
        await LocalizedMessage.update(updatedMessage);
        _setLocalizedMessages((messages) => [
          ...messages.slice(0, idx),
          updatedMessage,
          ..._localizedMessages.slice(idx + 1),
        ]);
        setLocalizedMessages((messages) => ({ ...messages, [localizedMessage.msgid]: localizedMessage.msgstr }));
      } else {
        await LocalizedMessage.delete(_localizedMessages[idx].id);
        _setLocalizedMessages((messages) => [...messages.slice(0, idx), ...messages.slice(idx + 1)]);
        const updatedMessages = localizedMessages;
        delete updatedMessages[localizedMessage.msgid];
        setLocalizedMessages(updatedMessages);
      }
    }

    await Sender.reload(['reloadLocalizedMessages', 'reloadOrganization']);
    cmdToast.success('Text customization updated.');

    reportEvent('localized message edited', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        name: localizedMessage.msgid,
      },
    });
  };

  /** Reloadable */

  const [isLoadingOrganization, reloadOrganization] = useReloadable<IOrganizationType>(
    async () => {
      const { data } = await get(`organizations/${user.organization}/`);
      return data;
    },
    setOrganization,
    [user],
  );

  const updateOrganization = async (updatedOrganization: IOrganizationType, updateRemote = true) => {
    let _organization = updatedOrganization;

    try {
      if (!!updateRemote) {
        _organization = await Organization.update(updatedOrganization);
        cmdToast.success('Settings updated.');
      }
      setOrganization(_organization);
      Sender.reload(['reloadOrganization']);
      return _organization;
    } catch (e) {
      reportErrorToUser(e);
      await reloadOrganization();

      throw e;
    }
  };
  const [isLoadingOrganizationSettings] = useReloadable<IOrganizationSettingsType>(
    async () => {
      return await OrganizationSettings.read();
    },
    setOrganizationSettings,
    [user],
  );

  const updateOrganizationSetting = async (settings: Partial<IOrganizationSettingsType>) => {
    setOrganization((oldOrg) => {
      if (!oldOrg) return oldOrg;
      const newOrg: IOrganizationType = { ...oldOrg, ...settings };
      return newOrg;
    });

    try {
      const newSettings = await OrganizationSettings.update(settings);
      setOrganization((oldOrg) => (oldOrg ? { ...oldOrg, ...newSettings } : oldOrg));
      Sender.reload(['reloadOrganization']);
      cmdToast.success('Settings updated.');

      return newSettings;
    } catch (e) {
      // try to reload organization -- our changes may not have been applied
      reportErrorToUser(e);
      await reloadOrganization();
      throw e;
    }
  };

  const editorTags: string[] = collectEditorTags({ commands, nudges, checklists });

  const [isLoadingRules, reloadRules] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listRules(orgId);
    },
    setRules,
    [orgId],
  );

  const [isLoadingCommands, reloadCommands] = useReloadable(
    async () => {
      if (!orgId) return [];

      const commands = await Organization.listCommands(orgId);
      return commands.filter(
        (command) => command.template.type !== 'helpdoc' || command.template.doc_type !== 'answer',
      );
    },
    setCommands,
    [orgId],
  );

  const [isLoadingAnswers, reloadAnswers] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listAnswers(orgId);
    },
    setAnswers,
    [orgId],
  );

  const [isLoadingCategories, reloadCategories] = useReloadable(
    async () => {
      if (!orgId) return [];

      return await Organization.listCommandCategories(orgId);
    },
    setCategories,
    [orgId],
  );

  const [isLoadingNudges, reloadNudges] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Nudge.list();
    },
    setNudges,
    [orgId],
  );

  const [isLoadingChecklists, reloadChecklists] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Checklist.list();
    },
    setChecklists,
    [orgId],
  );

  const [isLoadingKeywords, reloadKeywords] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Keyword.list();
    },
    setKeywords,
    [orgId],
  );

  const [isLoadingThemes, reloadThemes] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Theme.list();
    },
    setThemes,
    [orgId],
  );

  const [isLoadingTemplates, reloadTemplates] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await ExperienceTemplate.list();
    },
    setTemplates,
    [orgId],
  );

  const [isLoadingTags, reloadTags] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Tag.list();
    },
    setTags,
    [orgId],
  );

  const [isLoadingAPIs, reloadAPIs] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await API.list();
    },
    setAPIs,
    [orgId],
  );

  const [isLoadingWorkflows, reloadWorkflows] = useReloadable(
    async () => {
      if (!orgId) return [];
      return await Workflow.list();
    },
    setWorkflows,
    [orgId],
  );

  const [isLoadingFlags] = useReloadable(
    async () => {
      if (!orgId) return undefined;
      return await fetchFlags(orgId);
    },
    setFlags,
    [orgId],
  );

  const [isLoadingLocalizedMessages, reloadLocalizedMessages] = useReloadable(
    async () => {
      if (!orgId) return {};
      const messages = await LocalizedMessage.list();
      _setLocalizedMessages(messages);
      const messageObject = messages.reduce<Record<string, string>>((obj, m) => {
        obj[m.msgid] = m.msgstr;
        return obj;
      }, {});
      return messageObject;
    },
    setLocalizedMessages,
    [orgId],
  );

  if (!organization) return null;
  if (!flags) return null;

  return {
    state: {
      organization,
      commandBarReady,
      editorPreviewDevice,
      editorCopilotOverrides,
      flags,
      organizationSettings,
      commands,
      categories,
      rules,
      nudges,
      checklists,
      answers,
      editorTags,
      templates,
      tags,
      APIs,
      workflows,
      localizedMessages,
      keywords,
      themes,
      hasUnsavedChangesRef,
      loading:
        isLoadingOrganization ||
        isLoadingOrganizationSettings ||
        isLoadingRules ||
        isLoadingCommands ||
        isLoadingCategories ||
        isLoadingNudges ||
        isLoadingChecklists ||
        isLoadingAnswers ||
        isLoadingFlags ||
        isLoadingTemplates ||
        isLoadingTags ||
        isLoadingAPIs ||
        isLoadingWorkflows ||
        isLoadingLocalizedMessages ||
        isLoadingKeywords ||
        isLoadingThemes,
    },
    dispatch: {
      setCommandBarReady,
      setEditorPreviewDevice,
      setEditorCopilotOverrides,
      reloadAll: () => {
        reloadOrganization();
        reloadCommands();
        reloadRules();
        reloadCategories();
      },
      organization: {
        reload: reloadOrganization,
        update: updateOrganization,
        updateSetting: updateOrganizationSetting,
      },
      commands: {
        reload: reloadCommands,
        setList: setCommands,
        reorder: onCommandReorder,
        save: saveCommand,
        savePartial: savePartialCommand,
        delete: deleteCommand,
        bulkUpdate: bulkUpdate,
      },
      rules: {
        reload: reloadRules,
        setList: setRules,
        addRule,
        removeRule,
        changeRule,
      },
      categories: {
        reload: reloadCategories,
        setList: setCategories,
        reorder: onCategoryReorder,
        updateSortKeys: updateCategorySortKeys,
        save: saveCategory,
        delete: deleteCategory,
      },
      nudges: {
        save: saveNudge,
        delete: deleteNudge,
        reload: reloadNudges,
      },
      answers: {
        reload: reloadAnswers,
      },
      checklists: {
        save: saveChecklist,
        delete: deleteChecklist,
        reload: reloadChecklists,
      },
      templates: {
        save: saveTemplate,
        delete: deleteTemplate,
        reload: reloadTemplates,
      },
      tags: {
        reload: reloadTags,
      },
      apis: {
        save: saveAPI,
        delete: deleteAPI,
        reload: reloadAPIs,
      },
      workflows: {
        save: saveWorkflow,
        delete: deleteWorkflow,
        reload: reloadWorkflows,
      },
      localizedMessages: {
        update: updateLocalizedMessage,
        reload: reloadLocalizedMessages,
      },
      keywords: {
        save: saveKeyword,
        delete: deleteKeyword,
        reload: reloadKeywords,
        bulkUpdate: bulkUpdateKeywords,
      },
      themes: {
        reload: reloadThemes,
        save: saveTheme,
        delete: deleteTheme,
      },
    },
  };
};

export default useEditor;

export type ICommandTableState = Exclude<ReturnType<typeof useEditor>, null>['state'];
export type ICommandTableDispatch = Exclude<ReturnType<typeof useEditor>, null>['dispatch'];

export const freshCommand = (
  organization: IOrganizationType,
  categoryID: number | undefined,
  hasRouter?: boolean,
  icon?: string | null,
  image?: string | null,
  type?: 'link' | 'callback',
): IEditorCommandType =>
  Command.decodeEditorCommand({
    id: -1,
    organization: organization.id.toString(),
    category: categoryID || null,
    icon: icon || null,
    image: image || null,
    text: '',
    template: {
      type: type || 'link',
      value: '',
      operation: hasRouter ? 'router' : 'blank',
      commandType: 'independent',
    },
  });
