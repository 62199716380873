import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import type { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import { AnalyticsSurveys, type SurveyDetailsWithResponses } from '@commandbar/internal/middleware/analytics/surveys';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { getSurveyDetailResponseDemoData } from '../../analyticDemoData';

const useSurveyDetails = ({
  nudgeId,
  listFilter,
  timeFilterRange,
}: {
  nudgeId: string;
  listFilter: string;
  timeFilterRange: DateFilter;
}) => {
  const [surveyDetails, setSurveyDetails] = useState<SurveyDetailsWithResponses>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const { isDemoData, includeAdminData } = useAnalyticsContext();

  useEffect(() => {
    if (isDemoData) {
      setSurveyDetails(getSurveyDetailResponseDemoData(location.pathname.includes('nps')));
    } else {
      fetchData();
    }
  }, [nudgeId, listFilter, isDemoData]);

  const fetchData = useCallback(() => {
    setIsLoading(true);

    AnalyticsSurveys.readDetailResponses(nudgeId, {
      sort_order: listFilter,
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
    })
      .then((res) => {
        setSurveyDetails(res);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('There was an error', error);
      })
      .finally(() => setIsLoading(false));
  }, [includeAdminData, listFilter, nudgeId, timeFilterRange]);

  return {
    surveyDetails,
    fetchData,
    isLoading,
  };
};

export default useSurveyDetails;
