// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const EmptyGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 60 43" {...props}>
    <path fill="url(#paint0_linear_306_35069)" d="M9 18.5c0-1.1046.8954-2 2-2h8c1.1046 0 2 .8954 2 2v19H9v-19Z" />
    <path fill="url(#paint1_linear_306_35069)" d="M25 3.5c0-1.1046.8954-2 2-2h8c1.1046 0 2 .8954 2 2v34H25v-34Z" />
    <path fill="url(#paint2_linear_306_35069)" d="M41 9.5c0-1.1046.8954-2 2-2h8c1.1046 0 2 .8954 2 2v28H41v-28Z" />
    <path stroke="#D0D1D4" strokeLinecap="round" strokeWidth={1.2} d="M2.5 37.5h55" />
    <defs>
      <linearGradient id="paint0_linear_306_35069" x1={15} x2={15} y1={16.5} y2={37.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#D0D1D4" stopOpacity={0.2} />
        <stop offset={1} stopColor="#D0D1D4" />
      </linearGradient>
      <linearGradient id="paint1_linear_306_35069" x1={31} x2={31} y1={1.5} y2={37.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#8E9198" stopOpacity={0.2} />
        <stop offset={1} stopColor="#8E9198" />
      </linearGradient>
      <linearGradient id="paint2_linear_306_35069" x1={47} x2={47} y1={7.5} y2={37.5} gradientUnits="userSpaceOnUse">
        <stop stopColor="#BABBC0" stopOpacity={0.2} />
        <stop offset={1} stopColor="#BABBC0" />
      </linearGradient>
    </defs>
  </svg>
);
export default EmptyGraph;
