import * as t from 'io-ts';

import { createObject, deleteObject, listObject, readObject, updateObject } from './generics';

export const LocalizedMessageV = t.type(
  {
    id: t.number,
    msgid: t.string,
    msgstr: t.string,
  },
  'LocalizedMessage',
);

export const LocalizedMessagePatchV = t.intersection([
  t.type({
    msgid: t.string,
    msgstr: t.string,
  }),
  t.partial({
    id: t.number,
  }),
]);

export const LOCALIZED_MESSAGE_DEFAULTS: { [key: string]: string } = {
  'copilot.title': '{{copilot-name}}',
  'copilot.chat-label': 'Chat with {{copilot-name}}',
  'copilot.chat-input-placeholder': 'What can we help you find?',

  'helphub.title': 'HelpHub',
  'helphub.search-input-placeholder': 'Search or ask a question',
  'helphub.ask-ai-button-label': 'Ask {{copilot-name}}',
  'helphub.helphub-search-button-label': 'Search',
  'helphub.featured-content-label': 'Recommended',
  'helphub.additional-resources-label': 'Additional resources',
  'helphub.view-article-button-label': 'View article',

  'spotlight.recommended-header-label': 'Recommended',
  'spotlight.recents-header-label': 'Recents',
  'spotlight.experiences-header-label': 'Experiences',
  'spotlight.fallback-results-header': 'No results for {{query}}. Try one of the items below:',
};

export type LocalizedMessages = Record<keyof typeof LOCALIZED_MESSAGE_DEFAULTS, string>;

export class LocalizedMessage {
  public static list = listObject(LocalizedMessageV, 'localized_messages');
  public static read = readObject(LocalizedMessageV, 'localized_messages');
  public static update = updateObject(LocalizedMessageV, LocalizedMessageV, 'localized_messages');
  public static create = createObject(LocalizedMessageV, LocalizedMessageV, 'localized_messages');
  public static delete = deleteObject(LocalizedMessageV, 'localized_messages');
}
