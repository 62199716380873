import React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Sender from '../../management/Sender';

import { ReactComponent as ElementNotFoundSvg } from '../../img/element_not_found.svg';

import { Target04 } from '@commandbar/design-system/icons/react';
import { Tooltip, CB_COLORS } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { ElementInformation } from '@commandbar/internal/middleware/types';

const Container = styled.div`
  height: fit-content;
  width: 100%;
`;

const DefaultButton = styled.div<{ disabled?: boolean; unavailable?: boolean }>`
  display: flex;
  padding: 8px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${CB_COLORS.neutral500};
  box-sizing: border-box;

  cursor: pointer;
  transition: all 0.3;

  &:hover {
    color: ${(props) => (props.unavailable ? '#A36A00' : props.disabled ? '' : CB_COLORS.neutral1000)};

    & div {
      color: ${(props) => (props.unavailable ? '#A36A00' : props.disabled ? '' : CB_COLORS.neutral1000)};
    }
  }
`;

const Label = styled.div<{ picked: boolean; unavailable: boolean; disabled?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) =>
    props.disabled ? CB_COLORS.neutral500 : props.picked && props.unavailable ? '#A36A00' : CB_COLORS.neutral800};
`;

const ElementNotFoundIcon = styled(ElementNotFoundSvg)`
  width: 16px;
`;

interface ITargetElementClickRecorder {
  value: ElementInformation;
  onValueChange: (value: ElementInformation) => void;
  children?: React.ReactNode;
  onStartClickRecorder?: (skipPrompt?: boolean) => void;
  recorderDisabled?: boolean;
}

export const TargetElementClickRecorder = ({
  value,
  onValueChange,
  children,
  onStartClickRecorder,
  recorderDisabled,
}: ITargetElementClickRecorder) => {
  const id = React.useMemo(() => Math.random(), []);
  const appContext = useAppContext();

  const selectorSet = !!value.selector || !!value.text;

  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;
    // no selectors mean the user cancelled the click recorder
    if (data.selectors.length > 0) {
      onValueChange(data.selectors[0]);
    }
    return respondSuccess();
  };

  usePortal({
    commandbar: {
      onClickRecorderComplete,
    },
  });

  const [selectorState, setSelectorState] = useState<'ok' | 'invisible' | 'not_found'>('ok');

  useEffect(() => {
    if (!selectorSet || appContext?.isStudio) {
      setSelectorState('ok');
    } else {
      Sender.checkIfSelectorValid(value).then((result) => {
        if (result.elementFound && result.elementVisible) {
          setSelectorState('ok');
        } else if (result.elementFound && !result.elementVisible) {
          setSelectorState('invisible');
        } else {
          setSelectorState('not_found');
        }
      });
    }
  }, [value, appContext.isStudio]);

  const handleRecorderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (appContext?.isStudio && onStartClickRecorder) {
      if (!recorderDisabled) onStartClickRecorder(e.metaKey);
    } else {
      Sender.openClickRecorder(id, [], true);
    }
  };

  const isSelectorValid = selectorState === 'ok';
  const showTooltip = !isSelectorValid || recorderDisabled === true;

  return (
    <Tooltip
      showIf={showTooltip}
      placement="bottom"
      content={
        recorderDisabled
          ? 'Save your nudge before using the element selector'
          : selectorState === 'invisible'
          ? 'Target element found on this page, but is hidden'
          : 'Target element not found on this page'
      }
    >
      <Container onClick={handleRecorderClick}>
        {!!children ? (
          <>{children}</>
        ) : (
          <DefaultButton disabled={recorderDisabled}>
            <Label disabled={recorderDisabled} picked={selectorSet} unavailable={!isSelectorValid}>
              <Target04 width={16} />
              {selectorSet ? 'Change Target' : 'Pick Target...'}
              {!isSelectorValid && <ElementNotFoundIcon />}
            </Label>
          </DefaultButton>
        )}
      </Container>
    </Tooltip>
  );
};
