import { CBStore } from 'shared/store/global-store';
import { IStepArgumentType, isContextArgument } from '@commandbar/internal/middleware/types';
import { initParameterOption } from './option-utils/ParameterOption';
import { getArgumentChoiceLabel, getContextSettings } from '../selectors';

export const convertParameterToOption = (_: CBStore, parameter: unknown, argument: IStepArgumentType) => {
  const contextSettings = getContextSettings(_);
  const label = getArgumentChoiceLabel(argument, parameter, contextSettings);
  const contextSettingsForKey = isContextArgument(argument) ? contextSettings[argument.value.toString()] : undefined;
  return initParameterOption(_, label, parameter, contextSettingsForKey);
};
