import React from 'react';

import {
  canonicalize,
  IEditorAvailabilityRule,
  isCompoundExpression,
} from '@commandbar/internal/middleware/helpers/rules';
import { IRuleExpression, IRuleExpressionAnd, IRuleExpressionOr } from '@commandbar/internal/middleware/types';
import { ConditionEditor } from './ConditionEditor';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { RulePrefix } from './components/styled';
import { CmdButton, CmdDivider, CmdDropdown } from '@commandbar/design-system/cmd';
import { LayoutAlt03, Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { Condition } from '@commandbar/internal/middleware/conditions';
import {
  ConditionTypeCategory,
  ConditionTypeCategoryName,
  getConditionTypeCategories,
  defaultConditions,
} from './categories';
import { useAppContext } from 'editor/src/AppStateContext';

type CompoundConditionGroupEditorProps = {
  expr: IRuleExpressionAnd | IRuleExpressionOr;
  disabled?: boolean;
  nestingLevel?: number;
  onChange?: (expr: IRuleExpression) => void;

  categories: ConditionTypeCategory[];
  newConditionDefaultType: Condition['type'];
  // For Preview flow in the extension, we need to know which field to start recording on
  // and which field gets the value from the recorder so a path is calculated and passed down
  path?: string;
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
};

const CompoundConditionGroupEditor: React.FC<CompoundConditionGroupEditorProps> = ({
  expr,
  disabled = false,
  nestingLevel = 0,
  onChange = () => {
    return null;
  },

  newConditionDefaultType,
  categories,
  path,
  onStartClickRecorder,
}) => {
  const addCondition = () => {
    onChange({
      ...expr,
      exprs: [
        ...expr.exprs,
        {
          type: 'CONDITION',
          condition: defaultConditions[newConditionDefaultType] as IEditorAvailabilityRule,
        },
      ],
    });
  };

  const addConditionGroup = () => {
    onChange({
      ...expr,
      exprs: [
        ...expr.exprs,
        {
          type: 'AND',
          exprs: [
            {
              type: 'CONDITION',
              condition: defaultConditions[newConditionDefaultType] as IEditorAvailabilityRule,
            },
          ],
        },
      ],
    });
  };

  const deleteConditionByIndex = (index: number) => {
    const exprs = [...expr.exprs.slice(0, index), ...expr.exprs.slice(index + 1)];

    // when 0 conditions, change type to "AND" so that it evaluates to "true" by default
    const type = exprs.length < 1 ? 'AND' : expr.type;

    onChange({
      ...expr,
      type,
      exprs,
    });
  };

  const replaceConditionAtIndex = (index: number) => (newCondition: Condition) => {
    const toUpdate = expr.exprs[index];
    if (toUpdate.type !== 'CONDITION') return;

    // If the operator changes, reset the value
    if (
      'operator' in newCondition &&
      'operator' in toUpdate.condition &&
      newCondition.operator !== toUpdate.condition.operator
    ) {
      if ('value' in newCondition) {
        newCondition.value = '';
      }
    }

    onChange({
      ...expr,
      exprs: [
        ...expr.exprs.slice(0, index),
        { ...toUpdate, condition: newCondition as IEditorAvailabilityRule },
        ...expr.exprs.slice(index + 1),
      ],
    });
  };

  const updateExprByIndex = (index: number) => (newExpr: IRuleExpression) => {
    onChange({
      ...expr,
      exprs: [...expr.exprs.slice(0, index), newExpr, ...expr.exprs.slice(index + 1)],
    });
  };

  const updateBooleanOperator = (newOperator: 'AND' | 'OR') => {
    onChange({
      ...expr,
      type: newOperator,
    });
  };

  const isNested = nestingLevel >= 1;

  return (
    <div
      style={
        isNested
          ? {
              border: '1px solid #E6E6E8',
              padding: '12px 16px',
              margin: '2px 0px',
              background: '#EEEEEE60',
              borderRadius: 3,
            }
          : {}
      }
    >
      <div style={{ marginBottom: 16 }}>
        {expr.exprs.map((rule, index) => {
          return (
            <>
              {index !== 0 ? (
                <Row align="middle" style={{ opacity: disabled ? 0.6 : 1, overflow: 'hidden', padding: '12px 0' }}>
                  {index === 1 ? (
                    <Select disabled={disabled} value={expr.type} onChange={updateBooleanOperator} size="small">
                      <Select.Option value={'AND'}>AND</Select.Option>
                      <Select.Option value={'OR'}>OR</Select.Option>
                    </Select>
                  ) : (
                    <RulePrefix>{expr.type}</RulePrefix>
                  )}

                  <Col flex="auto" style={{ marginLeft: 8 }}>
                    <CmdDivider />
                  </Col>
                </Row>
              ) : null}

              <Row align="middle" style={{ opacity: disabled ? 0.6 : 1, overflow: 'hidden' }} wrap={false}>
                <Col flex="auto">
                  {isCompoundExpression(rule) && (
                    <CompoundConditionGroupEditor
                      disabled={disabled}
                      nestingLevel={nestingLevel + 1}
                      expr={rule}
                      onChange={updateExprByIndex(index)}
                      categories={categories}
                      newConditionDefaultType={newConditionDefaultType}
                      path={`${path}.${index}.exprs`}
                      onStartClickRecorder={onStartClickRecorder}
                    />
                  )}

                  {rule.type === 'CONDITION' && (
                    <ConditionEditor
                      key={`${index}-${expr.exprs.length}`}
                      disabled={disabled}
                      expr={expr}
                      condition={rule.condition as Condition}
                      onConditionChange={replaceConditionAtIndex(index)}
                      categories={categories}
                      path={`${path}.${index}.condition`}
                      onStartClickRecorder={onStartClickRecorder}
                    />
                  )}
                </Col>

                {!disabled && (
                  <Col flex="0 0 auto">
                    <CmdButton
                      variant="link"
                      size="sm"
                      icon={<Trash04 />}
                      onClick={() => {
                        deleteConditionByIndex(index);
                      }}
                    ></CmdButton>
                  </Col>
                )}
              </Row>
            </>
          );
        })}
      </div>

      {!disabled && (
        <div style={{ marginBottom: 16 }}>
          {isNested ? (
            <CmdButton onClick={addCondition} icon={<Plus />}>
              Add condition
            </CmdButton>
          ) : (
            <CmdDropdown.Menu>
              <CmdDropdown.Trigger>
                <CmdButton icon={<Plus />}>Add condition</CmdButton>
              </CmdDropdown.Trigger>
              <CmdDropdown.Content>
                <CmdDropdown.Item onClick={addCondition}>
                  <Plus />
                  Add condition
                </CmdDropdown.Item>
                <CmdDropdown.Item onClick={addConditionGroup}>
                  <LayoutAlt03 />
                  Add condition group
                </CmdDropdown.Item>
              </CmdDropdown.Content>
            </CmdDropdown.Menu>
          )}
        </div>
      )}
    </div>
  );
};

type ConditionGroupEditorProps = {
  expr: IRuleExpression;
  disabled?: boolean;
  nestingLevel?: number;
  onChange?: (expr: IRuleExpression) => void;

  includeCategories?: ConditionTypeCategoryName[];
  excludeCategories?: ConditionTypeCategoryName[];

  excludeConditionTypes?: Condition['type'][];
  path?: string;
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
};

const ConditionGroupEditor: React.FC<ConditionGroupEditorProps> = (_props) => {
  const { expr, disabled, includeCategories = null, excludeCategories = [], excludeConditionTypes = [] } = _props;
  const { organization, flags, organizationSettings } = useAppContext();

  const { categories, newConditionDefaultType } = getConditionTypeCategories(
    organization,
    flags,
    expr,
    includeCategories,
    excludeCategories,
    excludeConditionTypes,
    organizationSettings?.silent_mode ?? false,
  );
  const props = { ..._props, categories };

  const onChange = (e: IRuleExpression) => {
    if (!props.onChange) return;
    return props.onChange(canonicalize(e));
  };

  const path = props.path ? `${props.path}.exprs` : 'exprs';
  if (isCompoundExpression(expr)) {
    return (
      <CompoundConditionGroupEditor
        {...props}
        onChange={onChange}
        expr={expr}
        categories={categories}
        newConditionDefaultType={newConditionDefaultType}
        path={path}
      />
    );
  }

  if (expr.type === 'CONDITION') {
    return (
      <CompoundConditionGroupEditor
        onChange={onChange}
        expr={{ type: 'AND', exprs: [expr] }}
        categories={categories}
        path={path}
        onStartClickRecorder={_props.onStartClickRecorder}
        newConditionDefaultType={newConditionDefaultType}
      />
    );
  }

  const addNewRule = () => {
    onChange({
      type: 'AND',
      exprs: [
        {
          type: 'CONDITION',
          condition: defaultConditions[newConditionDefaultType] as IEditorAvailabilityRule,
        },
      ],
    });
  };

  const addConditionGroup = () => {
    onChange({
      type: 'AND',
      exprs: [
        {
          type: 'AND',
          exprs: [
            {
              type: 'CONDITION',
              condition: defaultConditions[newConditionDefaultType] as IEditorAvailabilityRule,
            },
          ],
        },
      ],
    });
  };

  if (disabled) return null;

  return (
    <div>
      <CmdDropdown.Menu>
        <CmdDropdown.Trigger>
          <CmdButton icon={<Plus />}>Add condition</CmdButton>
        </CmdDropdown.Trigger>
        <CmdDropdown.Content>
          <CmdDropdown.Item onClick={addNewRule}>
            <Plus />
            Add condition
          </CmdDropdown.Item>
          <CmdDropdown.Item onClick={addConditionGroup}>
            <LayoutAlt03 />
            Add condition group
          </CmdDropdown.Item>
        </CmdDropdown.Content>
      </CmdDropdown.Menu>
    </div>
  );
};

export default ConditionGroupEditor;
