import { INudgeType } from '../middleware/types';
import dayjs from 'dayjs';

export const isInScheduledPeriod = (nudge: INudgeType): boolean =>
  nudge.is_scheduled &&
  (nudge.scheduled_start_time === null || dayjs(nudge.scheduled_start_time) <= dayjs()) &&
  (nudge.scheduled_end_time === null || dayjs() <= dayjs(nudge.scheduled_end_time));

export const isPastScheduledPeriod = (nudge: INudgeType): boolean =>
  nudge.is_scheduled && nudge.scheduled_end_time !== null && dayjs(nudge.scheduled_end_time).isBefore(dayjs());

export const getDaysSinceDate = (date?: string | null) => -dayjs(date).diff(dayjs(), 'day');

const getHoursSinceDate = (date?: string | null) => -dayjs(date).diff(dayjs(), 'hour');

export const getMinutesSinceDate = (date?: string | null) => -dayjs(date).diff(dayjs(), 'minute');

export const getTimeSinceDate = (date?: string | null) => {
  let time = '';
  const minSince = getMinutesSinceDate(date);
  if (minSince < 60) {
    if (minSince === 0) {
      time = 'Now';
    } else {
      time = minSince + ' min';
      if (minSince > 1) {
        time += 's';
      }
      time += ' ago';
    }
  } else {
    const hoursSince = getHoursSinceDate(date);
    if (hoursSince < 24) {
      time = hoursSince + ' hour';
      if (hoursSince > 1) {
        time += 's';
      }
      time += 'ago';
    } else {
      const daysSince = getDaysSinceDate(date);
      if (daysSince > 0) {
        time = daysSince + ' day';
        if (daysSince > 1) {
          time += 's';
        }
        time += ' ago';
      }
    }
  }
  return time;
};
