/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from '../shared/ButtonWithReset';

const RatingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--form-control-gap);
  align-self: stretch;
  user-select: none;
`;

const Number = styled.div`
  text-align: center;
`;

const styles: CSSObject = {
  display: 'flex',
  height: 'var(--form-control-height)',
  width: 'var(--form-control-width)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  cursor: 'pointer',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  color: 'var(--content-primary)',

  /* label/base/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
};

const selectedStyles: CSSObject = {
  borderColor: 'var(--base-accent)',
  background: 'var(--form-control-active-background)',

  '&:hover': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-active-background)',
  },
};

const slug = 'nudgeRatingNumbers';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  numOptions: number;
  selected: (index: number) => boolean;
  onClick: (index: number) => void;
};

const StyledNudgeRatingNumbers = ({ themeV2, numOptions, selected, onClick }: Props) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <RatingContainer>
      {[...Array(numOptions)].map((_, i) => (
        <ButtonWithReset
          data-testid={'survey-option'}
          key={i + 1}
          css={[overrides, css(styles), selected(i) && css(selectedStyles)].filter(Boolean)}
          onClick={() => onClick(i)}
        >
          <Number>{i}</Number>
        </ButtonWithReset>
      ))}
    </RatingContainer>
  );
};

StyledNudgeRatingNumbers.styles = styles;
StyledNudgeRatingNumbers.slug = slug;
StyledNudgeRatingNumbers.activeStyles = selectedStyles;
StyledNudgeRatingNumbers.renderMock = (
  themeV2: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode',
  active: boolean,
  onClick: () => void,
) => (
  <StyledNudgeRatingNumbers
    themeV2={{ themeV2: themeV2, mode: mode }}
    numOptions={1}
    selected={() => !!active}
    onClick={onClick}
  />
);

export default StyledNudgeRatingNumbers;
