import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { init } from 'commandbar';
import { Route, Switch, useHistory } from 'react-router';
import { CB_COLORS, Spin } from '@commandbar/design-system/components/antd';

import 'editor/src/App.css';

import type { IUserType } from '@commandbar/internal/middleware/types';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';

import { UsageProvider } from 'editor/src/hooks/useUsage';
import Auth from '@commandbar/internal/client/authentication';
import { MobilePreview } from '@commandbar/internal/client/MobilePreview';
import { LoadingLogoAnimation } from '../LoadingLogoAnimation';
import { getEditorPath } from 'commandbar.com/src/Router';
import {
  ACTIONS_ROUTE,
  COPILOT_PARENT_ROUTE,
  PAGES_ROUTE,
  SPOTLIGHT_ROUTE,
  THEME_ROUTE,
} from '@commandbar/internal/proxy-editor/editor_routes';

import { BrowserContent, CommandBarInlineMount, MockBrowser } from './Editor.styles';

import { useAppContext } from 'editor/src/AppStateContext';
import DashboardWidget from 'editor/src/DashboardWidget';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { _updateEditorCopilotOverrides } from '@commandbar/internal/client/symbols';
import { getSDK } from '@commandbar/internal/client/globals';
import { LEGACY_INLINE_BAR } from '@commandbar/internal/client/CommandBarClientSDK';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';

interface EditorProps {
  user: IUserType;
}

const bootCommandBar = async (
  user: IUserType,
  overrides: { user?: string; userProperties?: Record<string, any>; filter?: string[] },
) => {
  const usingOverrides =
    (!!overrides.user && overrides.user !== user.profile.user) || !!overrides.userProperties || !!overrides.filter;
  const userId = user.email.includes('@commandbar.com') ? user.profile.user : `cb-dashboard:${user.email}`;

  if (usingOverrides) {
    getSDK()[_updateEditorCopilotOverrides](overrides);
  }

  if (overrides.filter && overrides.filter.length > 0) {
    window.CommandBar.setHelpHubFilter({ labels: overrides.filter });
  }

  await window.CommandBar.boot(overrides.user ?? userId, overrides.userProperties ?? undefined, {
    canOpenEditor: false,
    impersonateMode: usingOverrides,
  });
};

const initializeCommandBar = async (
  user: IUserType,
  launchcode: string | null,
  overrides: { user?: string; userProperties?: Record<string, any>; filter?: string[] },
) => {
  (window as any).CommandBar = undefined;
  const token = await Auth.token();

  // TODO: handle the logged out case
  if (token) localStorage.setItem('commandbar.token', token);

  if (launchcode) {
    localStorage.setItem('commandbar.lc', launchcode);
  }

  init(user.organization);

  // INFO: stub router function will make all router commands available
  // This function is used for all link command types
  window.CommandBar.addRouter((url: string) => {
    cmdToast({
      title: `This command would navigate to ${url}`,
      variant: 'info',
      action: (
        <CmdButton
          onClick={() => {
            try {
              const { href } = new URL(url);
              window.open(href);
            } catch (error) {
              cmdToast.error(`Invalid or relative URL: ${url}`);
            }
          }}
        >
          Open
        </CmdButton>
      ),
    });
  });

  window.CommandBar.addCallback('__standalone-editor-cb', (commandArguments, context) => {
    console.info('Command executed called with: ', { arguments: commandArguments, context });
    cmdToast.success(`Command executed! Check the console for information about any arguments or context.`);
  });
  window.CommandBar.addCallback('__standalone-editor-cb_hh_cta', (chat_provider) => {
    console.info(`This will launch the ${chat_provider} chat widget`);
    cmdToast.info(`This will launch the ${chat_provider} chat widget`);
  });
  window.CommandBar.addCallback('__standalone-editor-open-spotlight-action', () => {
    console.info(`This will launch Spotlight`);
    cmdToast.info(`This will launch Spotlight`);
  });
  window.CommandBar.addCallback('__standalone-editor-checklist-action-cta-clicked', () => {
    console.info('Checklist item CTA clicked');
    cmdToast.success(
      `Checklist item CTA clicked! Use the in-app editor to demo questlist item completion and click actions.`,
    );
  });
  window.CommandBar.addCallback('__standalone-editor-cb_nudge_triggered', (nudgeTitle) => {
    console.info(`Nudge (title: ${nudgeTitle}) shown`);
    cmdToast.success(`Nudge shown! Use the in-app editor to see exactly where the nudge will appear.`);
  });
  window.CommandBar.addCallback('__standalone-editor-cb_questlist_triggered', (questlistTitle) => {
    console.info(`Checklist (title: ${questlistTitle}) shown`);
    cmdToast.success(`Checklist shown! Use the in-app editor to see exactly where the checklists will appear.`);
  });
  window.CommandBar.addCallback('__standalone-editor-cb_helpdoc_triggered', (helpdocTitle) => {
    console.info(`Document (title: ${helpdocTitle}) opened`);
    cmdToast.success(`Document opened in HelpHub! Use the in-app editor to what it looks like.`);
  });

  return bootCommandBar(user, overrides);
};

// TODO: bring back "mobile experience" toggle from Page.tsx see https://github.com/tryfoobar/monobar/blob/labs/commandbar.com/src/components/editor/Page.tsx#L235

const BarPreview = ({ user }: { user: IUserType }) => {
  const [state, setState] = useState<{ ok?: true; error?: true; loading?: true }>({ loading: true });
  const [editorPreviewDevice, setEditorPreviewDevice] = useState<DeviceType | null>();
  const [editorCopilotOverrides, setEditorCopilotOverrides] = useState<{
    user?: string;
    userProperties?: Record<string, any>;
    filter?: string[];
  }>({});
  const [editorPath, setEditorPath] = useState(window.parent.location.pathname);

  const launchCode = (() => {
    const previewDeployPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+)\.dashboard\.commandbar\.com$/;
    const preview2DeployPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+)\.commandbar\.xyz$/;

    const domain = window.location.host;
    if (['labs.commandbar.com', 'labs.dashboard.commandbar.com'].includes(domain)) {
      return 'api=labs;commandbar=labs';
    } else if (['dev.commandbar.com', 'dev.dashboard.commandbar.com'].includes(domain)) {
      return 'api=dev;commandbar=dev';
    } else {
      const match = domain.match(previewDeployPattern);
      const match2 = domain.match(preview2DeployPattern);
      if (match) {
        return `api=dev;commandbar=preview_${match[2]}`;
      } else if (match2) {
        return `api=preview_${match2[2]};commandbar=preview2_${match2[2]}`;
      }
    }
    return null;
  })();

  useEffect(() => {
    if (state.ok) {
      // only run after initial boot
      bootCommandBar(user, editorCopilotOverrides).then(() =>
        window.CommandBar.openHelpHub({ chatOnly: true, articleId: null }),
      );
    }
  }, [editorCopilotOverrides]);

  useLayoutEffect(() => {
    // TODO: launch code should always match the lc in the parent app.cb.com
    /** Used for local dev to automatically use certain launch codes */
    const result = initializeCommandBar(user, launchCode || null, editorCopilotOverrides);
    result
      .then(() => {
        setState({ ok: true });
        window.parent.postMessage({ type: 'commandbar-ready', state: { ok: true } }, window.origin);
      })
      .catch((e) => {
        console.error('Error initializing CommandBar library', e);
        setState({ error: true });
        window.parent.postMessage({ type: 'commandbar-ready', state: { error: true } }, window.origin);
      });

    if (launchCode) {
      cmdToast.warning('Using Launch Code: ' + launchCode);
    }
  }, []);

  useEffect(() => {
    const messageHandler = (e: MessageEvent<any>) => {
      try {
        if (e.data?.type === 'commandbar-set-editor-preview-device') {
          if (e.data?.device) {
            setEditorPreviewDevice(e.data?.device);
          }
        } else if (e.data?.type === 'commandbar-set-editor-copilot-overrides') {
          if (e.data?.data) {
            setEditorCopilotOverrides(e.data?.data);
          }
        } else if (e.data.type === 'commandbar-editor-route-change') {
          if (e.data?.data) {
            setEditorPath(e.data.data);
          }
        }
      } catch (e) {}
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const isLegacyInlineBarOrg = user.organization in LEGACY_INLINE_BAR;
  const isMobilePreview = editorPreviewDevice === 'mobile';
  const isSpotlightRoute = editorPath?.startsWith(`/editor${SPOTLIGHT_ROUTE}`);
  const isPagesRoute = editorPath?.startsWith(`/editor${PAGES_ROUTE}`);
  const isActionsRoute = editorPath?.startsWith(`/editor${ACTIONS_ROUTE}`);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {state.error ? (
        <div
          style={{
            margin: '128px 16px',
            fontSize: 14,
            background: '#f9df70',
            padding: '32px 16px',
            border: '1px solid gray',
            textAlign: 'center',
          }}
        >
          Something went wrong; please reload the page and try again. If that doesn't work, reach us at{' '}
          <a href="mailto:support@commandbar.com">support@commandbar.com</a>
        </div>
      ) : (
        <MockBrowser>
          <BrowserContent>
            {/* DO NOT REMOVE -- used by Widget code to check if we are in the standalone editor */}
            <div id="commandbar-is-studio-preview" />

            {isLegacyInlineBarOrg && (
              <CommandBarInlineMount
                id="commandbar-inline-root"
                isMobilePreview={isMobilePreview}
                show={isSpotlightRoute || isPagesRoute || isActionsRoute}
              />
            )}

            {/* This is always rendered because the Widgets may need to find the special mobile preview divs
             * (commandbar-mobile-preview-wrapper and commandbar-mobile-nudge-mock-pin)
             * when we toggle to "mobile preview mode"; it is shown when mobile preview mode is turned on. */}
            <MobilePreview style={!isMobilePreview ? { visibility: 'hidden' } : undefined} />
          </BrowserContent>
        </MockBrowser>
      )}
    </div>
  );
};

type HostedIframeProps = {
  isThemeDetailPage: boolean;
  onError: (message: string) => void;
  fullWidth?: boolean;
  shouldShow?: boolean;
};

const HostedIframe = React.forwardRef<HTMLIFrameElement, HostedIframeProps>((props, ref) => {
  const { isThemeDetailPage, onError, shouldShow } = props;

  const {
    commandBarReady,
    dispatch: { setCommandBarReady },
  } = useAppContext();

  useEffect(() => {
    const messageHandler = (e: MessageEvent<any>) => {
      try {
        if (e.data?.type === 'commandbar-ready') {
          if (e.data?.state?.ok) {
            setCommandBarReady(true);
          } else if (e.data?.state?.error) {
            onError(`Error initializing CommandBar: ${e.data?.state?.error}`);
          }

          window.removeEventListener('message', messageHandler);
        }
      } catch (e) {}
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return (
    <div
      style={{
        background: '#D7D7D7',
        display: shouldShow ? 'flex' : 'none',
        width: !!props.fullWidth ? '100%' : '50%',
        flex: 'none',
        ...(isThemeDetailPage && {
          width: '65%',
          borderTop: `1px solid ${CB_COLORS.neutral300}`,
          borderRight: `1px solid ${CB_COLORS.neutral300}`,
        }),
      }}
    >
      {!commandBarReady && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Spin indicator={<LoadingLogoAnimation />} />
        </div>
      )}
      <iframe
        ref={ref}
        id="commandbar-editor-bar-preview"
        /* query params:
         *  - 'cb_editor' causes the Proxy to be loaded -- this is needed for Sender message passing to work
         *  - 'lc' launchcode override, if specified, is "forwarded" to the iframe */
        src={'/editor/editor-bar-preview'}
        title="Preview"
        style={{
          flex: 1,
          border: 'none',
          userSelect: 'none',
          ...(!commandBarReady && { width: '0px' }),
        }}
      />
    </div>
  );
});

const EditorAndHostedIFrame = () => {
  const [error, setError] = useState<boolean>(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const history = useHistory();
  const { organizationSettings } = useAppContext();
  const { user } = useAuth();

  const showEditArea =
    !history.location.pathname.startsWith(`/editor${SPOTLIGHT_ROUTE}`) || hasRequiredRole(user, 'editor');

  const isThemeListPage = history.location.pathname === `/editor${THEME_ROUTE}`;
  const isThemeDetailPage = history.location.pathname.startsWith(`/editor${THEME_ROUTE}`) && !isThemeListPage;
  const isCopilotPage = history.location.pathname.startsWith(`/editor${COPILOT_PARENT_ROUTE}`);

  if (
    isThemeDetailPage &&
    history.location.pathname.endsWith('components') &&
    organizationSettings?.skins_field_set !== 'pro'
  ) {
    history.replace(`/editor${THEME_ROUTE}`);
  }

  const { editorPreviewDevice, editorCopilotOverrides } = useAppContext();

  useLayoutEffect(() => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow?.postMessage(
      { type: 'commandbar-set-editor-preview-device', device: editorPreviewDevice },
      window.origin,
    );
  }, [editorPreviewDevice]);

  useLayoutEffect(() => {
    if (!iframeRef.current || !isCopilotPage) return;
    iframeRef.current.contentWindow?.postMessage(
      { type: 'commandbar-set-editor-copilot-overrides', data: editorCopilotOverrides },
      window.origin,
    );
  }, [editorCopilotOverrides]);

  useLayoutEffect(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow?.postMessage(
      { type: 'commandbar-editor-route-change', data: history.location.pathname },
      window.origin,
    );
  }, [history.location.pathname]);

  const editorArea = (
    <div
      style={{
        minWidth: isThemeDetailPage && !history.location.pathname.endsWith('components') ? '360px' : undefined,
        width:
          isThemeListPage || (isThemeDetailPage && history.location.pathname.endsWith('components'))
            ? '100%'
            : isThemeDetailPage
            ? '35%'
            : '50%',
        flex: 'none',
        background: '#f2f2f2',
        display: showEditArea ? undefined : 'none',
      }}
    >
      <UsageProvider>
        <DashboardWidget
          initialRoute={
            '/' + (getEditorPath(`${window.location.pathname}${window.location.search}${window.location.hash}`) || '')
          }
        />
      </UsageProvider>
    </div>
  );

  // two possible layouts:
  //  - theme base editor: editor on right, widget preview on left (tableau)
  //  - all other pages: editor on left, widget preview on right
  //
  // Additionally, show a loading skeleton for "editorArea" until the
  // widget preview is ready if we are showing a widget preview.
  // This ensures that any effects using "Sender" to send messages to
  // the iframe will work.
  const content = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          ...(isThemeDetailPage && { flexDirection: 'row-reverse', paddingTop: 48, position: 'relative' }),
        }}
      >
        {editorArea}
        {!error ? (
          <HostedIframe
            ref={iframeRef}
            isThemeDetailPage={isThemeDetailPage}
            shouldShow={!isThemeListPage}
            fullWidth={!showEditArea}
            onError={(message) => {
              console.error('Error initializing iframe', message);
              setError(true);
            }}
          />
        ) : (
          <div
            style={{
              margin: '128px 16px',
              fontSize: 14,
              background: '#f9df70',
              padding: '32px 16px',
              border: '1px solid gray',
              textAlign: 'center',
            }}
          >
            Something went wrong; please reload the page and try again. If that doesn't work, reach us at{' '}
            <a href="mailto:support@commandbar.com">support@commandbar.com</a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      /* NOTE: this `id` is needed for some CSS rules */ id="commandbar-editor-main"
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      {content()}
    </div>
  );
};

const Editor = ({ user }: EditorProps) => {
  return (
    <Switch>
      <Route path="/editor/editor-bar-preview">
        {/* NOTE: this will be rendered in an iframe */}
        <BarPreview user={user} />
      </Route>
      <Route>
        <EditorAndHostedIFrame />
      </Route>
    </Switch>
  );
};

export default Editor;
