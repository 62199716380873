import React from 'react';

import { Select, Row, Col, InputNumber, Tooltip } from '@commandbar/design-system/components/antd';

import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { KeyboardShortcutInput } from '../CommmandDetail/KeyboardShortcutEditor/components/KeyboardShortcutInput';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import { CmdSwitch } from '@commandbar/design-system/cmd';
import { InfoCircle } from '@commandbar/design-system/icons/react';

interface IProps {
  organization: IOrganizationType;
  updateOrganizationProperty: (field: keyof IOrganizationType, newVal: any) => Promise<IOrganizationType>;
  updateOrganizationSettingsProperty: (
    field: keyof IOrganizationSettingsType,
    newVal: any,
  ) => Promise<IOrganizationSettingsType>;
}

function isInt(value: any) {
  if (typeof value === 'number') {
    return Number.isInteger(value);
  }

  if (typeof value === 'string') {
    const intRegexPattern = /^-?[0-9]+$/;
    return intRegexPattern.test(value);
  }

  return false;
}

const BarLauncherSettings = (props: IProps) => {
  const [invalidX, setInvalidX] = React.useState(false);
  const [invalidY, setInvalidY] = React.useState(false);
  const os = getOperatingSystem();

  const onChangeType = async (value: any) => {
    props.updateOrganizationProperty('launcher_type', value);
  };

  const onChangeShortcut = async (value: string | null) => {
    props.updateOrganizationSettingsProperty('summon_hotkey_override', value);
  };

  const onChangePosition = async (value: any) => {
    props.updateOrganizationProperty('launcher_position', value);
  };

  const onBlurX = async (e: any) => {
    const { value } = e.target;
    if (!isInt(value)) {
      return setInvalidX(true);
    }
    setInvalidX(false);

    const intValue = parseInt(value, 10);
    if (props.organization.launcher_offset_x === intValue) {
      return;
    }

    props.updateOrganizationProperty('launcher_offset_x', intValue);
  };

  const onBlurY = async (e: any) => {
    const { value } = e.target;
    if (!isInt(value)) {
      return setInvalidY(true);
    }
    setInvalidY(false);

    const intValue = parseInt(value, 10);
    if (props.organization.launcher_offset_y === intValue) {
      return;
    }

    props.updateOrganizationProperty('launcher_offset_y', intValue);
  };

  return (
    <div>
      <Row gutter={[8, 8]} align="middle" style={{ marginBottom: '8px' }}>
        <Col span={6}>Type:</Col>
        <Col span={18}>
          <Select defaultValue={props.organization.launcher_type} style={{ width: '100%' }} onChange={onChangeType}>
            <Select.Option value="minimal">Default</Select.Option>
            <Select.Option value="alternate">Alternate</Select.Option>
            <Select.Option value="none">None</Select.Option>
          </Select>
        </Col>
        <Col span={6}>Position:</Col>
        <Col span={18}>
          <Select
            defaultValue={props.organization.launcher_position}
            style={{ width: '100%' }}
            onChange={onChangePosition}
          >
            <Select.Option value="bottomRight">Bottom right</Select.Option>
            <Select.Option value="topRight">Top right</Select.Option>
            <Select.Option value="bottomLeft">Bottom left</Select.Option>
            <Select.Option value="topLeft">Top left</Select.Option>
          </Select>
        </Col>
        <Col span={6}>Offset X (px):</Col>
        <Col span={18}>
          <InputNumber
            style={{ border: invalidX ? '2px solid red' : '' }}
            defaultValue={props.organization.launcher_offset_x}
            onFocus={() => setInvalidX(false)}
            onBlur={onBlurX}
            step={10}
          />
        </Col>
        <Col span={6}>Offset Y (px):</Col>
        <Col span={18}>
          <InputNumber
            style={{ border: invalidY ? '2px solid red' : '' }}
            defaultValue={props.organization.launcher_offset_y}
            onFocus={() => setInvalidY(false)}
            onBlur={onBlurY}
            step={10}
          />
        </Col>
      </Row>

      <Row gutter={[8, 8]} align="top">
        <Col span={6}>Hotkey:</Col>
        <Col span={18}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CmdSwitch
                checked={props.organization.summon_hotkey_override !== 'none'}
                onCheckedChange={(checked: boolean) => {
                  if (checked) {
                    onChangeShortcut(null);
                  } else {
                    onChangeShortcut('none');
                  }
                }}
                tooltip="If disabled, the Bar will only open via a launcher or programmatic calls of '.open()'."
              />
            </div>

            {props.organization.summon_hotkey_override !== 'none' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <KeyboardShortcutInput
                  value={
                    props.organization.summon_hotkey_override === null
                      ? 'mod+k'
                      : props.organization.summon_hotkey_override
                  }
                  onChange={(value) => onChangeShortcut(Hotkey.toModString(value))}
                  platform={['mac', 'ios'].includes(os) ? 'mac' : 'win'}
                  enableRecording={true}
                  defaultValue={'mod+k'}
                  allowTypingUpdates={false}
                  showModString={true}
                />
                <span style={{ marginLeft: 24, marginRight: 16 }}>
                  {' '}
                  <Tooltip
                    content={
                      "The recorded shortcut is automatically translated into a platform-independent representation. 'mod' represents Command on macOS and Ctrl on Windows/Linux"
                    }
                    placement="bottom"
                  >
                    <InfoCircle height={16} width={16} />
                  </Tooltip>
                </span>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BarLauncherSettings;
