import React, { useEffect, useState } from 'react';

import { User01, UsersX, UsersCheck } from '@commandbar/design-system/icons/react';
import { CmdColumnDef, CmdDataTable, CmdDropdown } from '@commandbar/design-system/cmd';
import { INudgeType, IRuleExpression } from '@commandbar/internal/middleware/types';
import { Rule, AudienceUser } from '@commandbar/internal/middleware/rule';
import { useAppContext } from 'editor/src/AppStateContext';

type Filter = {
  value: 'eligible' | 'ineligible' | 'completed';
  title: string;
  icon: React.ReactNode;
};

const filterOptions: Filter[] = [
  {
    value: 'eligible',
    title: 'Eligible users',
    icon: <User01 />,
  },
  {
    value: 'ineligible',
    title: 'Ineligible users',
    icon: <UsersX />,
  },
  {
    value: 'completed',
    title: 'Completed',
    icon: <UsersCheck />,
  },
];

const AudienceTable = ({
  audience,
  id,
  isNudge,
}: {
  audience: INudgeType['audience'];
  id: number | string;
  isNudge: boolean;
}) => {
  const { rules } = useAppContext();

  const [selectedFilter, setSelectedFilter] = useState<Filter['value']>(filterOptions[0].value);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<'InvalidOrEmptyConditionGroup' | 'NotImplementedError' | string | null>(
    null,
  );
  const [audienceDetails, setAudienceDetails] = React.useState<AudienceUser[]>([]);

  const fetchAudienceDetails = async (expr: IRuleExpression | null) => {
    setLoading(true);

    await Rule.readAudienceDetails({
      expr: expr,
      id: id,
      is_nudge: isNudge,
    })
      .then((res) => setAudienceDetails(res.users))
      .catch((error) => {
        setAudienceDetails([]);

        if ('code' in (error as { code: string })) {
          setError((error as { code: string }).code);
          return;
        }

        setError('Something went wrong');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchAudienceDetails(
      audience?.type === 'rule_expression'
        ? audience.expression
        : audience?.type === 'named_rule_reference'
        ? rules.filter(
            (rule) => audience?.type === 'named_rule_reference' && audience.rule_reference.rule_id === rule.id,
          )[0].expression
        : null,
    );
  }, [audience]);

  const details = filterOptions.filter((option) => option.value === selectedFilter)[0];

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'end_user',
      header: 'User id',
      enableGlobalFilter: true,
    },
    ...(selectedFilter === 'completed' ? [{ accessorKey: 'action', header: 'Action', enableGlobalFilter: true }] : []),
  ];

  const filtered = audienceDetails.filter((user) => {
    if (selectedFilter === 'eligible' && user.eligible && !user.action) {
      return user;
    } else if (selectedFilter === 'ineligible' && !user.eligible) {
      return user;
    } else if (selectedFilter === 'completed' && user.action) {
      return user;
    }
    return null;
  });

  return (
    <CmdDataTable
      columns={columns}
      isLoading={loading}
      emptyResultChildren={error ? <>There was an error.</> : <>No data</>}
      data={filtered || []}
      toolBarChildren={
        <CmdDropdown.Menu>
          <CmdDropdown.SelectTrigger style={{ width: '100%', marginRight: '8px' }}>
            {details.icon}
            {details.title}
          </CmdDropdown.SelectTrigger>
          <CmdDropdown.Content style={{ width: '200px' }}>
            <CmdDropdown.RadioGroup
              value={selectedFilter}
              onValueChange={(value) => setSelectedFilter(value as Filter['value'])}
            >
              {filterOptions.map((option) => (
                <>
                  <CmdDropdown.RadioItem value={option.value}>
                    <span style={{ gap: '8px', display: 'flex', alignContent: 'center' }}>
                      {option.icon}
                      {option.title}
                    </span>
                  </CmdDropdown.RadioItem>

                  {option.value === 'ineligible' && <CmdDropdown.Separator />}
                </>
              ))}
            </CmdDropdown.RadioGroup>
          </CmdDropdown.Content>
        </CmdDropdown.Menu>
      }
    />
  );
};

export default AudienceTable;
