import * as React from 'react';
import { SVGProps } from 'react';
const Shadow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M14.666 5.52365L13.9302 4.78782C12.7586 3.61625 10.8591 3.61625 9.68755 4.78782L4.78734 9.68804C3.61576 10.8596 3.61576 12.7591 4.78734 13.9307L5.52316 14.6665H12.9517C13.8985 14.6665 14.666 13.899 14.666 12.9522V5.52365Z"
      fill="black"
    />
    <path d="M3.33398 13.0002L2.33398 12.0002H12.0007V2.3335L13.0007 3.3335V13.0002H3.33398Z" fill="white" />
    <path
      d="M1.33398 4.33154C1.33398 2.67469 2.67713 1.33154 4.33398 1.33154H11.0007C11.5529 1.33154 12.0007 1.77926 12.0007 2.33154V11.9998H2.33399C1.7817 11.9998 1.33398 11.5521 1.33398 10.9998V4.33154Z"
      fill="#D9D9D9"
    />
  </svg>
);
export default Shadow;
