import styled from '@emotion/styled';

export const StepsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

export const ContentBox = styled.div`
  width: 80%;
  border: 1px solid hsl(0, 0%, 75%);
  border-radius: 12px;
  padding: 34px 32px;
`;

export const StepContent = styled.div`
  margin-top: 30px;
`;
