import React, { createContext, useContext, useMemo, useState } from 'react';

import { PreviewURLModal } from '../editor/components/Preview/PreviewURLModal';
import { useIsChromeExtensionInsalled } from './useIsChromeExtensionInstalled';
import { INudgeType } from '@commandbar/internal/middleware/types';
import { ExperienceState } from '@commandbar/internal/client/extension/shared';
import { ChromeExtensionInstallModal } from '../editor/components/Preview/ChromeExtensionInstallModal';
import { StartClickRecorderMessage, StartPreviewMessage } from '@commandbar/internal/client/extension/messages';
import { COMMANDBAR_CHROME_EXTENSION_URL } from '@commandbar/internal/client/ChromeExtension';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAppContext } from '../AppStateContext';
import { cmdToast } from '@commandbar/design-system/cmd';

function sanitizePreviewUrl(_url: string) {
  let url = _url;
  if (url && !/^https?:\/\//i.test(url)) {
    url = 'https://' + _url;
  }
  return url;
}

type PreviewAction = {
  type: 'preview' | 'element';
  skipPrompt?: boolean;
  experience: ExperienceState;
  previewUrl?: string;
};

type PreviewContextValue = {
  isPreviewURLModalOpen: boolean;
  isInstallModalOpen: boolean;
  previewUrl: string;
  field?: ExperienceState['field'];
  experience?: ExperienceState;
  onInstall: () => void;
  onCancel: () => void;
  onFinish: (previewUrl: string) => void;
  onStart: (action: PreviewAction) => void;
  displayType?: 'preview' | 'element';
};

export const PreviewContext = createContext<PreviewContextValue>({
  isPreviewURLModalOpen: false,
  isInstallModalOpen: false,
  previewUrl: '',
  onFinish: () => {
    throw new Error('onFinish not implemented');
  },
  onStart: () => {
    throw new Error('onStart not implemented');
  },
  onCancel: () => {
    throw new Error('onCancel not implemented');
  },
  onInstall: () => {
    throw new Error('onInstall not implemented');
  },
});

type Props = {
  onSave: (nudge: INudgeType) => Promise<INudgeType>;
};
export const PreviewProvider: React.FC<Props> = ({ children, onSave }) => {
  const { organization } = useAppContext();
  const [displayType, setDisplayType] = useState<'preview' | 'element'>();
  const [experience, setExperience] = useState<ExperienceState>();
  const [previewUrl, setPreviewUrl] = useState('');

  // In the case the nudge has never been previewed, but the click recorder was used for a Step's position.
  // we should default the preview URL to the same one used for a step
  const initialPreviewURL = useMemo(() => {
    if (experience?.type === 'nudge') {
      if (experience.stepIndex !== undefined) {
        return (
          previewUrl ||
          experience.nudge.steps[experience.stepIndex].preview_url ||
          experience.nudge.preview_url ||
          organization.base_url
        );
      } else {
        return (
          previewUrl ||
          experience.nudge.preview_url ||
          experience.nudge.steps.find((step) => !!step.preview_url)?.preview_url ||
          organization.base_url
        );
      }
    }

    return '';
  }, [experience, organization.base_url, previewUrl]);

  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();
  const [isInstallModalOpen, setInstallModalOpen] = useState(false);
  const [isPreviewURLModalOpen, setPreviewURLModalOpen] = useState(false);

  const saveExperience = async (experience: ExperienceState, previewUrl: string) => {
    const newNudge = { ...experience.nudge };
    if (experience.type === 'nudge') {
      if (experience.stepIndex !== undefined) {
        newNudge.steps[experience.stepIndex].preview_url = previewUrl;
      } else {
        newNudge.preview_url = previewUrl;
      }
    }
    await onSave(newNudge);
  };

  const initiatePreviewExperience = async (previewUrl: string, action: PreviewAction) => {
    try {
      setInstallModalOpen(false);
      setPreviewURLModalOpen(false);
      const promises = [];
      promises.push(saveExperience(action.experience, previewUrl));

      LocalStorage.remove(`extension.action.${action.type}`);
      if (action.type === 'element') {
        promises.push(
          StartClickRecorderMessage.sendToExtensionFromStudio({
            origin: 'recorder',
            organizationId: organization.id,
            previewUrl,
            experience: action.experience,
          }),
        );
      } else if (action.type === 'preview' && action.experience?.type === 'nudge') {
        promises.push(
          StartPreviewMessage.sendToExtensionFromStudio({
            previewUrl,
            organizationId: organization.id,
            experience: { type: 'nudge', nudge: action.experience.nudge },
          }),
        );
      }

      await Promise.all(promises);
    } catch (err) {
      console.log(err);
      cmdToast.error('Failed to communicate with the CommandBar Chrome Extension. Please try again.');
    }
  };

  // Entry point for starting a preview or element selection
  const onStart = async (action: PreviewAction) => {
    setExperience(action.experience);
    setDisplayType(action.type);

    const url = sanitizePreviewUrl(action.previewUrl || previewUrl || initialPreviewURL);

    const payload = {
      organizationId: organization.id,
      experience,
      previewUrl: url,
    };
    LocalStorage.set(`extension.action.${action.type}`, JSON.stringify(payload));

    if (!url || !action.skipPrompt) {
      setPreviewURLModalOpen(true);
    } else if (!isChromeExtensionInstalled) {
      setInstallModalOpen(true);
      setPreviewURLModalOpen(false);
    } else if (experience) {
      await initiatePreviewExperience(url, { ...action, experience: experience });
    }
  };

  const onFinish = (newPreviewUrl: string) => {
    setPreviewUrl(newPreviewUrl);
    if (experience && displayType) {
      onStart({ type: displayType, experience: experience, skipPrompt: true, previewUrl: newPreviewUrl });
    }
  };

  const onInstall = () => {
    setInstallModalOpen(false);
    setPreviewURLModalOpen(false);
    setExperience(undefined);
    window.open(COMMANDBAR_CHROME_EXTENSION_URL, '_blank');
  };

  const onCancel = () => {
    setInstallModalOpen(false);
    setPreviewURLModalOpen(false);
    setDisplayType(undefined);
  };

  const contextValue: PreviewContextValue = {
    previewUrl,
    displayType,
    isInstallModalOpen,
    isPreviewURLModalOpen,
    onStart,
    onInstall,
    onCancel,
    onFinish,
  };

  return (
    <>
      <PreviewContext.Provider value={contextValue}>{children}</PreviewContext.Provider>
      <ChromeExtensionInstallModal open={isInstallModalOpen} onInstall={onInstall} onCancel={onCancel} />
      {isPreviewURLModalOpen && (
        <PreviewURLModal
          open={isPreviewURLModalOpen}
          displayType={displayType}
          onCancel={onCancel}
          initialPreviewURL={sanitizePreviewUrl(initialPreviewURL)}
          onFinish={onFinish}
        />
      )}
    </>
  );
};

export const usePreview = () => {
  return useContext(PreviewContext);
};
