import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';

import { AudienceV } from './helpers/audience';
import { ActionV } from '@cb/types/entities/command/actions';
import { RuleExpressionV } from './helpers/rules';
import { IRecommendationSet } from './types';

export const RecommendationSetBaseV = t.type(
  {
    id: t.number,
    widget: t.string,
    default: t.boolean,
    name: t.string,
    show_expression: RuleExpressionV,
    audience: AudienceV,
  },
  'RecommendationSetBase',
);

export const RecommendationSetAdditionalV = t.type(
  {
    actions: t.array(
      t.type({
        action: ActionV,
        cta: t.string,
      }),
    ),
    is_live: t.boolean,
  },
  'RecommendationSetAdditional',
);

export const RecommendationSetV = t.intersection(
  [RecommendationSetBaseV, RecommendationSetAdditionalV],
  'RecommendationSet',
);

type IRecommendationSetAdditional = t.TypeOf<typeof RecommendationSetAdditionalV>;

const defaults: IRecommendationSetAdditional = {
  actions: [],
  is_live: true,
};

export class RecommendationSet {
  public static decode = (data: any) => {
    const recommendationSet: IRecommendationSet = { ...defaults, ...data };

    return decodeThrowing(RecommendationSetV, recommendationSet);
  };

  public static create = createObject(RecommendationSetV, RecommendationSetV, 'recommendation_sets');

  public static update = updateObject(RecommendationSetV, RecommendationSetV, 'recommendation_sets');

  public static delete = deleteObject(RecommendationSetV, 'recommendation_sets');
  public static list = listObject(RecommendationSetV, 'recommendation_sets');
  public static read = readObject(RecommendationSetV, 'recommendation_sets');
}
