import * as React from 'react';
import { CmdLabel } from '../typography';

import { cn } from '../util';
import { inputStyles } from './styles';

export interface CmdTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  tooltip?: React.ReactElement | string;
  fullWidth?: boolean;
}

const CmdTextarea = React.forwardRef<HTMLTextAreaElement, CmdTextareaProps>(
  ({ className, label, tooltip, fullWidth, disabled, ...props }, ref) => {
    return (
      <div className={cn('flex flex-col w-fit gap-xs', fullWidth && 'w-full')}>
        {label && (
          <CmdLabel disabled={disabled} tooltip={tooltip}>
            {label}
          </CmdLabel>
        )}
        <textarea
          className={cn(inputStyles({ disabled, fullWidth }), 'resize-y h-xxxxxl', className)}
          ref={ref}
          disabled={disabled}
          {...props}
        />
      </div>
    );
  },
);
CmdTextarea.displayName = 'CmdTextarea';

export { CmdTextarea };
