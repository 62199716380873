import * as React from 'react';

import sanitizeHtml from './sanitizeHtml';
import InlineSVG from 'react-inlinesvg';

interface IProps {
  htmlstring: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  onClick?: any;
  useDefaultSVGColor?: boolean;
  ariaHidden?: boolean;
}

const purifiedCache = new Map();

const SVG = (props: IProps) => {
  const { htmlstring, style, useDefaultSVGColor, ariaHidden, ...restProps } = props;
  const sanitizer = sanitizeHtml;

  const src = React.useMemo(() => {
    const cached = purifiedCache.get(htmlstring);
    if (!!cached) {
      return cached;
    } else {
      const sanitized = sanitizer(htmlstring, ['use']);
      purifiedCache.set(htmlstring, sanitized);
      return sanitized;
    }
    // allow use tags https://developer.mozilla.org/en-US/docs/Web/SVG/Element/use
  }, [htmlstring]);

  let extraStyles: React.CSSProperties = {};
  if (src.includes('data-commandbar')) {
    // Fetch additional styles from <svg data-commandbar>
    const extraData = new DOMParser().parseFromString(src, 'text/html').querySelector('svg')?.dataset?.commandbar;
    const isRound = extraData?.includes('round');
    extraStyles = { borderRadius: isRound ? '50%' : undefined };
  }

  return (
    <InlineSVG
      // THe preProcessor wont run on props change, so we need to specify a key
      // If we need a more fully supported svg class, consider https://www.npmjs.com/package/react-svg
      aria-hidden={ariaHidden}
      key={`svg-${props?.style?.['color'] ?? ''}`}
      src={src}
      preProcessor={(code) => {
        if (useDefaultSVGColor) {
          return code;
        } else {
          return code.replace(/fill=".*?"/g, 'fill="currentcolor"');
        }
      }}
      style={{ ...style, ...extraStyles }}
      {...restProps}
    />
  );
};

export default SVG;
