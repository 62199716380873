import React from 'react';
import { Alert, Col, Row, Input, Form, Checkbox, Select } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const Insided: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.INSIDED,
  name: 'Insided',
  slug: 'insided',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/insided.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/insided-icon.ico',
  isDisabled: false,
  useDryRun: true,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={
        "In order to import Insided community content, you'll have to first find your Insided client_id, client_secret, and domain."
      }
    />
  ),
  route: '/insided',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'client_id'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.client_id}
          required={true}
          label={'Client ID'}
          name={'client_id'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your client_id. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'client_secret'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.client_secret}
          required={true}
          label={'Client Secret'}
          name={'client_secret'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your client_secret. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          label="Domain"
          name="domain"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9-_]{1,256}\.[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.com$/gi,
              message: `Please enter a valid URL (e.g., community.yourdomain.com). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input style={{ width: '100%' }} placeholder="community.yourdomain.com" />
        </Form.Item>
        <Form.Item
          key={editingSource?.type + 'region'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.region ?? 'us'}
          tooltip="The region where your Insided community is hosted."
          label="Region"
          name="region"
          rules={[{ required: true, message: 'This is a required field.' }]}
          style={{ flex: 5 }}
          required
        >
          <Select style={{ width: '100%' }}>
            <Select.Option value="eu">EU</Select.Option>
            <Select.Option value="us">US</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          key={editingSource?.type + 'content_types'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.content_types}
          label="Content Types"
          name="content_types"
        >
          <Checkbox.Group name="content_types">
            <Row>
              <Col span={12}>
                <Checkbox value="article">Articles</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="conversation">Conversations</Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Checkbox value="question">Questions</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="productUpdate">Product Updates</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      client_id: data['client_id'],
      client_secret: data['client_secret'],
      domain: data['domain'],
      region: data['region'],
      content_types: data['content_types'],
    };
  },
  requiredFields: ['client_id', 'client_secret', 'domain', 'region', 'content_types'],
  fetchedFields: [],
};

export default Insided;
