export interface IAlgoliaIndex {
  name: string;
  entries: number;
  createdAt: string;
}

const getAlgoliaHeaders = (applicationID: string, apiKey: string) => {
  return {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Algolia-API-Key': apiKey,
    'X-Algolia-Application-Id': applicationID,
  };
};

export const getAPIPermissions = async (applicationID: string, apiKey: string): Promise<any> => {
  const res = await fetch(`https://${applicationID}-dsn.algolia.net/1/keys/${apiKey}`, {
    headers: getAlgoliaHeaders(applicationID, apiKey),
  });
  const data = await res.json();

  return data;
};

export const listIndexes = async (applicationID: string, apiKey: string): Promise<IAlgoliaIndex[]> => {
  const res = await fetch(`https://${applicationID}-dsn.algolia.net/1/indexes`, {
    headers: getAlgoliaHeaders(applicationID, apiKey),
  });
  const data = await res.json();

  return data.items;
};

export const browseIndex = async (applicationID: string, apiKey: string, indexName: string): Promise<any[]> => {
  const res = await fetch(`https://${applicationID}-dsn.algolia.net/1/indexes/${indexName}/browse`, {
    method: 'POST',
    headers: getAlgoliaHeaders(applicationID, apiKey),
    body: JSON.stringify({}),
  });
  const data = await res.json();

  return data.hits;
};
