/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';
import ButtonWithReset from './ButtonWithReset';

export const styles: CSSObject = {
  display: 'flex',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--button-tertiary-border-color)',
  background: 'var(--button-tertiary-background)',
  color: 'var(--button-tertiary-content)',
  cursor: 'pointer',

  /* label/base/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderColor: 'var(--button-tertiary-border-color-hover)',
    background: 'var(--button-tertiary-background-hover)',
    color: 'var(--button-tertiary-content-hover)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
};

export const selectedStyles: CSSObject = {
  background: 'var(--button-primary-background)',
  borderColor: 'var(--button-primary-border-color)',
  color: 'var(--button-primary-content)',

  '&:hover': {
    background: 'var(--button-primary-background)',
    borderColor: 'var(--button-primary-border-color)',
    color: 'var(--button-primary-content)',
  },

  '&:focus': {
    background: 'var(--button-primary-background)',
  },
};

const slug = 'toggleButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  isSelected: boolean;
};

const StyledToggleButton = ({
  themeV2,
  isSelected,
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset css={[overrides, css(styles), isSelected && css(selectedStyles)].filter(Boolean)} {...props}>
      {children}
    </ButtonWithReset>
  );
};

StyledToggleButton.slug = slug;
StyledToggleButton.styles = styles;
StyledToggleButton.activeStyles = selectedStyles;
StyledToggleButton.renderMock = (
  themeV2: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode',
  active: boolean,
  onClick: () => void,
) => (
  <StyledToggleButton themeV2={{ themeV2: themeV2, mode: mode }} isSelected={!!active} onClick={onClick}>
    All items
  </StyledToggleButton>
);

export default StyledToggleButton;
