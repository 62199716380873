import React from 'react';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import { CmdCard, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { Arc } from '@visx/shape';
import { Group } from '@visx/group';
import { formatPercentage } from '../../utils';
import { Text } from '@visx/text';

const Gauge = ({ value }: { value: number }) => {
  const width = 200;
  const height = 100;

  return (
    <svg width={width} height={height}>
      <Group top={height} left={width / 2}>
        <Arc
          startAngle={-Math.PI / 2}
          endAngle={Math.PI / 2}
          cornerRadius={3}
          innerRadius={75}
          outerRadius={85}
          fill="#E7E8EA"
        />
        <Arc
          startAngle={-Math.PI / 2}
          endAngle={-Math.PI / 2 + Math.PI * value}
          cornerRadius={3}
          innerRadius={75}
          outerRadius={85}
          fill="#69C015"
        />
      </Group>
      <Text x={width / 2} y={height - 10} fontSize={24} fontWeight={600} fontFamily="Inter" textAnchor="middle">
        {formatPercentage(value)}
      </Text>
    </svg>
  );
};

type GaugeChartProps = {
  metric: number | null | undefined;
};

const GaugeChart: React.FC<GaugeChartProps> = ({ metric }) => {
  return (
    <CmdCard style={{ padding: '24px', display: 'flex', flexDirection: 'column' }}>
      <CmdCard.Header style={{ display: 'flex', alignItems: 'center', gap: '8px', flexDirection: 'row' }}>
        <CmdTypography.Body fontWeight="semi-bold">Avg strike rate</CmdTypography.Body>
        <CmdTooltip message="Avg strike rate of all users who closed a nudge in less than 2s">
          <div style={{ height: 16, width: 16 }}>
            <InfoCircle color="#797C85" />
          </div>
        </CmdTooltip>
      </CmdCard.Header>

      <CmdCard.Content
        style={{
          marginTop: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {metric !== null && metric !== undefined ? (
          <>
            <Gauge value={metric} />
            {/*<div style={{ position: 'absolute', top: '160px' }}>
              <CmdTypography.Body fontWeight="semi-bold">You’re doing great! 🤩</CmdTypography.Body>
              <CmdTypography.HelpText variant="secondary">88% of your nudges</CmdTypography.HelpText>
              <CmdTypography.HelpText variant="secondary">are being engaged with.</CmdTypography.HelpText>
            </div>*/}
          </>
        ) : (
          <Gauge value={0} />
        )}
      </CmdCard.Content>
    </CmdCard>
  );
};

export default GaugeChart;
