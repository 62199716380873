import React from 'react';

import { Column } from '@tanstack/react-table';

import { cn } from '../../util';
import { SwitchVertical01, SwitchVertical02 } from '@commandbar/design-system/icons/react';

interface CmdColumnProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export const CmdSortableColumn = <TData, TValue>({ column, title, className }: CmdColumnProps<TData, TValue>) => {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center gap-xs', className)}>
      <span>{title}</span>
      <div
        className="cursor-pointer flex"
        onClick={() => {
          column.toggleSorting(column.getIsSorted() === 'asc' ? true : false);
        }}
      >
        {column.getIsSorted() === 'desc' ? <SwitchVertical02 color="#797C85" /> : <SwitchVertical01 color="#797C85" />}
      </div>
    </div>
  );
};
