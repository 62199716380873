import React from 'react';
import { useHistory } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

export const useAuthorizationCode = () => {
  const [code, setCode] = React.useState<string | undefined>(undefined);
  const [state, setStateParam] = React.useState<string | undefined | null>(undefined);

  const [finalRedirect, setFinalRedirect] = React.useState<string | undefined>(undefined);

  const [error, setError] = React.useState<string | undefined>(undefined);

  const query = useQuery();
  const history = useHistory();

  React.useEffect(() => {
    setFinalRedirect('');
    setError(undefined);

    const code = query.get('code');
    const state = query.get('state');
    const finalRedirect = query.get('final_redirect');

    query.delete('code');
    query.delete('state');
    query.delete('final_redirect');

    history.replace({
      search: query.toString(),
    });

    if (!code) {
      return;
    }

    setCode(code);
    setStateParam(state);
    // FIXME: This fallback value should really be Org.base_url
    setFinalRedirect(finalRedirect ?? `${process.env.REACT_APP_DASHBOARD_URL}/dashboard`);
  }, []);

  return { finalRedirect, error, code, state };
};
