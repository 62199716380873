import { DependencyList, useEffect, useRef, useState } from 'react';

export function useReloadable<T>(
  fetcher: () => Promise<T>,
  set: (data: T) => void,
  deps: DependencyList = [],
): [boolean, () => Promise<void>] {
  const refreshNonce = useRef<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refresh = () => {
    const nonce = ++refreshNonce.current;

    return (async () => {
      try {
        setIsLoading(true);
        const data = await fetcher();
        if (nonce === refreshNonce.current) set(data);
      } finally {
        if (nonce === refreshNonce.current) setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    refresh();
  }, [...deps]);

  return [isLoading, refresh];
}
