import { Modal, Select } from '@commandbar/design-system/components/antd';
import { ReactNode, useState } from 'react';
import { useIntegrationsContext } from '../../../util/IntegrationContext';
import { IntegrationSpec } from '../../../integrations/shared/types';
import SourceEditDrawer from './SourceEditDrawer';
import { CmdButton, CmdRadioGroup } from '@commandbar/design-system/cmd';
import styled from '@emotion/styled';
import { ArrowRight } from '@commandbar/design-system/icons/react';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const RadioContainer = ({ active, children }: { active: boolean; children: React.ReactNode }) => {
  return (
    <div
      style={{
        width: '360px',
        border: 'solid',
        borderRadius: '8px',
        padding: '24px 16px',
        borderColor: `${active ? '#2754EE' : '#BABBC0'}`,
        background: `${active ? '#edf1ff80' : '#fff'}`,
        boxShadow: `${active ? '0 0 0px 3px rgba(39, 84, 238, 0.16)' : 'none'}`,
        marginLeft: '-40px',
        paddingLeft: '40px',
        marginTop: '22px',
      }}
    >
      {children}
    </div>
  );
};

const RadioCard = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <>
      <p style={{ fontWeight: '500', lineHeight: '14x', margin: 0 }}>{title}</p>
      <p style={{ fontWeight: '400', margin: 0 }}>{subtitle}</p>
    </>
  );
};

const RadioItem = styled.div`
  padding-left: 20px;
  margin-top: -22px;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`;

enum SourceType {
  HELP_CENTER = 'help_center',
  WEBSITE = 'website',
}

interface AddSourceStepProps {
  selectedSourceType: SourceType;
  onSelectedSourceTypeChange: (sourceType: SourceType) => void;
}

const AddSourceStep = ({ selectedSourceType, onSelectedSourceTypeChange }: AddSourceStepProps) => (
  <CmdRadioGroup
    value={selectedSourceType}
    onValueChange={(v) => onSelectedSourceTypeChange(v as SourceType)}
    style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
  >
    <RadioItem>
      <CmdRadioGroup.Item
        value="help_center"
        id="help_center"
        label={
          <RadioContainer active={selectedSourceType === 'help_center'}>
            <RadioCard title="Connect a help center" subtitle="Sync content from your existing help center" />
          </RadioContainer>
        }
      />
    </RadioItem>

    <RadioItem>
      <CmdRadioGroup.Item
        value="website"
        id="website"
        label={
          <RadioContainer active={selectedSourceType === 'website'}>
            <RadioCard title="Add a website" subtitle="Sync content from any public website" />
          </RadioContainer>
        }
      />
    </RadioItem>
  </CmdRadioGroup>
);

interface ChooseProviderStepProps {
  onSelectedHelpProviderChange: (helpProvider: IntegrationSpec['slug']) => void;
  options: ReactNode;
  selectedHelpProvider?: IntegrationSpec['slug'];
}

const ChooseHelpProviderStep = ({
  selectedHelpProvider,
  onSelectedHelpProviderChange,
  options,
}: ChooseProviderStepProps) => (
  <Select value={selectedHelpProvider} onChange={onSelectedHelpProviderChange} style={{ width: '100%' }}>
    {options}
  </Select>
);

interface EditSourceStepProps {
  integration: IntegrationSpec | undefined;
  setIntegration: (integ: IntegrationSpec | undefined) => void;
  onCancel: () => void;
  onComplete: () => void;
}

const EditSourceStep = ({ integration, setIntegration, onCancel, onComplete }: EditSourceStepProps) => (
  <SourceEditDrawer
    integration={integration}
    setIntegration={setIntegration}
    onCancel={onCancel}
    onComplete={onComplete}
    open={!!integration}
  />
);

interface SetupModalFooterProps {
  onBack: () => void;
  onNext: () => void;
  backText: string;
}

const SetupModalFooter = ({ onBack, onNext, backText }: SetupModalFooterProps) => (
  <ModalFooter>
    <CmdButton variant="link" key="footer-back-buttom" onClick={onBack}>
      {backText}
    </CmdButton>
    <CmdButton key="footer-next-buttom" variant="primary" onClick={onNext}>
      Next
      <ArrowRight />
    </CmdButton>
  </ModalFooter>
);

enum Step {
  ADD_SOURCE = 0,
  CHOOSE_HELP_PROVIDER = 1,
  EDIT_SOURCE = 2,
}

const AddSourceFlow = ({ onClose }: { onClose: () => void }) => {
  const { helpDocIntegrations, refreshHelpDocIntegrations } = useIntegrationsContext();
  const { user } = useAuth();

  const isCommandBarUser = !!user && user?.email.includes('@commandbar.com');

  const [stepToShow, setStepToShow] = useState(Step.ADD_SOURCE);
  const [integType, setIntegType] = useState(SourceType.HELP_CENTER);
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationSpec['slug']>('archbee');

  const modalSteps = {
    [Step.ADD_SOURCE]: <AddSourceStep selectedSourceType={integType} onSelectedSourceTypeChange={setIntegType} />,
    [Step.CHOOSE_HELP_PROVIDER]: (
      <ChooseHelpProviderStep
        selectedHelpProvider={selectedIntegration}
        onSelectedHelpProviderChange={setSelectedIntegration}
        options={helpDocIntegrations
          .filter((integ) => !(integ.slug === 'web_v2' || integ.slug === 'web'))
          .filter((integ) => isCommandBarUser || !integ.internalOnly)
          .sort((a, b) => (a.slug < b.slug ? -1 : 1))
          .map((integ) => (
            <Select.Option key={integ.slug} value={integ.slug}>
              {integ.name}
              {integ.internalOnly && ' (Internal Only)'}
            </Select.Option>
          ))}
      />
    ),
  };

  if (stepToShow === Step.EDIT_SOURCE) {
    return (
      <EditSourceStep
        integration={helpDocIntegrations.find((integ) => integ.slug === selectedIntegration)}
        setIntegration={(integ) => setSelectedIntegration(integ?.slug || '')}
        onComplete={async () => {
          await refreshHelpDocIntegrations();
          onClose();
        }}
        onCancel={onClose}
      />
    );
  }

  return (
    <Modal
      title="New Source"
      onCancel={onClose}
      width={420}
      footer={
        <SetupModalFooter
          onBack={() => {
            if (stepToShow === 0) {
              onClose();
            } else {
              setStepToShow(stepToShow - 1);
            }
          }}
          onNext={() => {
            if (stepToShow === Step.ADD_SOURCE) {
              if (integType === 'help_center') {
                setStepToShow(Step.CHOOSE_HELP_PROVIDER);
              } else {
                setSelectedIntegration('web_v2');
                setStepToShow(Step.EDIT_SOURCE);
              }
            } else {
              setStepToShow(stepToShow + 1);
            }
          }}
          backText={stepToShow === 0 ? 'Cancel' : 'Back'}
        />
      }
      closable
      open
    >
      {modalSteps[stepToShow]}
    </Modal>
  );
};

export default AddSourceFlow;
