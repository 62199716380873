import * as axiosInstance from '../middleware/network';
import { IUserType } from '../middleware/types';

type AuthAuthorizeResponse =
  | {
      success: true;
    }
  | {
      success: false;
      error: string;
    };

const authorize = async (code: string, state?: string | null): Promise<AuthAuthorizeResponse> => {
  try {
    const url = `/auth/authorize/?ts=${Date.now().toString()}`;
    const res = await axiosInstance.post(url, {
      code,
      state: state ?? '',
    });

    const { user } = res.data;

    if (!user) {
      return { success: false, error: 'Invalid login.' };
    }

    return { success: true };
  } catch (error: any) {
    if ('error' in error) {
      return { success: false, error: error.error };
    } else {
      return { success: false, error: 'Invalid login.' };
    }
  }
};

type AuthBasicResponse = {
  success: boolean;
  error?: string;
};

const basic = async (username: string, password: string): Promise<AuthBasicResponse> => {
  try {
    const res = await axiosInstance.post('/auth/basic/', {
      username,
      password,
    });

    // TODO: Check for res.ok if possible
    if (res) {
      return { success: true };
    } else {
      return { success: false, error: 'Something went wrong' };
    }
  } catch (err: any) {
    const error = err?.detail ?? err?.message ?? 'Invalid login';
    return { success: false, error };
  }
};

const sso = async (email: string, auth_redirect: string, final_redirect?: string): Promise<string | null> => {
  return await axiosInstance
    .post('/auth/sso/', {
      email,
      auth_redirect,
      final_redirect,
    })
    .then((result: any) => {
      return result.data;
    })
    .catch((_err: any) => {
      return null;
    });
};

interface GoogleOauthInput {
  redirect_uri: string;
  is_signup: boolean | undefined;
}

const google = async (input: GoogleOauthInput): Promise<string | null> => {
  return await axiosInstance.post('/auth/google/', input).then((result: any) => {
    return result.data;
  });
};

const sendVerificationEmail = async (email: string) => {
  return await axiosInstance
    .post('/auth/send-verification/', {
      email,
    })
    .then((result: any) => {
      return result.data;
    });
};

const getLoginType = async (email: string) => {
  return await axiosInstance
    .post('/auth/check/', {
      email,
    })
    .then((result: any) => {
      return result.data;
    });
};

const verifyEmail = async (token: string): Promise<void> => {
  await axiosInstance.post('/auth/verify-email/', {
    token,
  });
};

const setupOrg = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/new/', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

const register = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/basic/register/', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

const acceptInvite = async (values: Record<string, string>) => {
  return await axiosInstance
    .post('/auth/basic/register/?accept=1', {
      ...values,
    })
    .then((result: any) => {
      return result.data;
    });
};

type AuthUserResponse =
  | {
      success: true;
      user: IUserType;
    }
  | {
      success: false;
      error?: string;
    };

const user = async (): Promise<AuthUserResponse> => {
  try {
    const res = await axiosInstance.get('/auth/current/');

    if (!res.data) {
      return { success: false, error: 'Invalid.' };
    }

    return { success: true, user: res.data };
  } catch (error: any) {
    // TODO: we should proably log the user out here
    return {
      success: false,
      error: error?.detail ?? 'Invalid.',
    };
  }
};

const token = async (): Promise<string | null> => {
  return await axiosInstance.get('/auth/token/').then((result: any) => {
    return result.data?.token;
  });
};

const Auth = {
  basic,
  sso,
  google,
  user,
  sendVerificationEmail,
  verifyEmail,
  getLoginType,
  setupOrg,
  register,
  acceptInvite,
  authorize,
  token,
};

export default Auth;
