import { CBStore } from './global-store';
import { sub } from './util/sub';
import merge from 'lodash/merge';
import { ref } from 'valtio';
import debounce from 'lodash/debounce';

import { getElement, isElementInformation } from '@commandbar/internal/util/dom';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Reporting from 'shared/services/analytics/Reporting';
import { recentUIDs } from 'shared/store/end-user/selectors';

import * as NudgeServiceActions from 'products/nudges/service-actions';
import * as ChecklistServiceActions from 'products/checklists/service-actions';

import { queryExperiences, queryHelpDocs } from 'shared/sdk/search';
import { ShareLinkType, deconstructShareLink } from '@commandbar/internal/client/share_links';
import { getNudgeById } from 'products/nudges/service-selectors';

import * as GlobalActions from './global-actions';
import { logChanges } from './util/logChanges';
import { getSDK } from '@commandbar/internal/client/globals';
import { _configUser, _reloadCommands } from '@commandbar/internal/client/symbols';
import { findOp, getPrevValue, Ops } from './util/hasOp';
import isEqual from 'lodash/isEqual';
import {
  setupAlgoliaIntegration,
  setupAnalyticsIntegrations,
  tearDownAlgoliaIntegration,
} from 'shared/util/clientSideIntegrations';
import { getHelpHubRecommendations } from 'products/helphub/service-selectors';
import {
  StartPreviewMessage,
  UpdateNudgeStepMessage,
  StartClickRecorderMessage,
} from '@commandbar/internal/client/extension/messages';
import { activatePushExperience } from './global-actions';
import { resetTimedTriggers } from './global-actions';
import { passesGlobalLimit } from 'products/nudges/service-selectors';

const deriveEndUserData = (_: CBStore) => {
  /**
   * For optimization, store derived info based on end user store info
   */
  _.endUserStore.derived = {
    recentUIDs: recentUIDs(_.endUserStore.data.recents),
  };
};

const mergeContextSettings = (_: CBStore) => {
  _.contextSettings = merge({}, _.serverContextSettings, _.localContextSettings);
};

const locationChanged = (_: CBStore) => {
  if (!_.endUserStore.hasRemoteLoaded) {
    return false;
  }
  // FIXME: when_page_reached is deprecated an cannot be selected for new nudges and checklists. Need to keep only for pre-existing nudges and checklists. Should remove this once possible
  NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_page_reached', meta: { url: _.location.href } });
  ChecklistServiceActions.triggerChecklists(_, { type: 'when_page_reached', meta: { url: _.location.href } });

  const deconstructedShareLink = deconstructShareLink(_.organization?.share_link_param || 'cb-eid', _.location.search);

  if (deconstructedShareLink) {
    // Get the current URL
    const currentURL = new URL(_.location.href);
    const removeUrlParameter = () => {
      // Remove the parameter from the URL
      currentURL.searchParams.delete('cb-eid');

      // Construct the new URL without the parameter
      const newUrl = currentURL.toString();

      // Use replaceState to update the URL without reloading the page
      window.history.replaceState({}, document.title, newUrl);
    };

    if (deconstructedShareLink?.type === ShareLinkType.NUDGE) {
      const nudge = getNudgeById(_, deconstructedShareLink.id);
      if (nudge) {
        activatePushExperience(_, nudge, 'n');
      }
    } else if (deconstructedShareLink?.type === ShareLinkType.QUESTLIST) {
      const sharedChecklist = _.checklists.find((checklist) => checklist.id === deconstructedShareLink.id);
      if (sharedChecklist) {
        activatePushExperience(_, sharedChecklist, 'q');
      }
    }

    removeUrlParameter();
  }
};

const locationSub = (_: CBStore) => {
  /** inspired by https://dirask.com/posts/JavaScript-on-location-changed-event-on-url-changed-event-DKeyZj */

  const history = window.history;

  const pushState = history.pushState;
  const replaceState = history.replaceState;

  let cancelled = false;
  let oldHref = document.location.href;

  if (!!process.env.SALESFORCE_BUILD) {
    // In Salesforce sandboxed iFrames, we can't override pushState and replaceState and therefor can't listen to history changes
    // Instead, for salesforce only, we use a MutationObserver to listen for changes in the body and trigger a location change if the href changes
    const bodyList = document.body;
    const observer = new MutationObserver(function () {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href;
        _.location = ref({ ...window.location });
      }
    });

    observer.observe(bodyList, {
      childList: true,
      subtree: true,
    });
  } else {
    history.pushState = function (...args) {
      pushState.apply(history, args);
      if (cancelled) return;
      if (oldHref === document.location.href) return;

      oldHref = document.location.href;
      // NOTE: spreading window.location into new object here triggers subscribers to _.location
      // without this, since window.location seems to be a singleton, subscribers would not be triggered
      _.location = ref({ ...window.location });
    };

    history.replaceState = function (...args) {
      replaceState.apply(history, args);
      if (cancelled) return;
      if (oldHref === document.location.href) return;

      oldHref = document.location.href;
      // NOTE: spreading window.location into new object here triggers subscribers to _.location
      // without this, since window.location seems to be a singleton, subscribers would not be triggered
      _.location = ref({ ...window.location });
    };
  }

  const checkHrefChange = () => {
    if (oldHref !== document.location.href) {
      oldHref = document.location.href;
      _.location = ref({ ...window.location });
    }
  };

  window.addEventListener('hashchange', checkHrefChange);

  return () => {
    // NOTE: unfortunately, it is not possible to uninstall our pushState and replaceState intermediaries
    // because someone else may be holding a reference to them, or even may have replaced them in a similar
    // fashion. If we just restore the original functions, any subsequent changes to pushState and replaceState
    // would be overwritten.
    //
    // Instead, we simply cancel the effects and leave them in place.
    window.removeEventListener('hashchange', checkHrefChange);
    cancelled = true;
  };
};

const helpHubSearch = async (_: CBStore) => {
  const query = _.helpHub.query || '';

  if (!_.organization) return;

  if (!query) {
    // Don't query for default recommendations if there's already a rec set
    const recommendations = await getHelpHubRecommendations(_);
    if (recommendations.length) {
      return;
    }
  }

  _.helpHub.loading = true;

  let promises;
  if (_.flags?.['release-search-experiences-in-help-hub']) {
    const maxResults = query ? undefined : 10;
    promises = [
      queryExperiences(_, query, 'helphub', undefined, maxResults).then((experiences) => {
        _.helpHub.experienceSearchResults = experiences;
        if (!_.helpHub.experienceSearchResults.length) {
          Reporting.noResultsForQuery(query, { type: 'helphub' });
        }
      }),
    ];
  } else {
    promises = [
      queryHelpDocs(_.organization.id, query, _.endUser, _.helpHub.filter).then((docs) => {
        _.helpHub.searchResults = docs;
        if (!_.helpHub.searchResults.length) {
          Reporting.noResultsForQuery(query, { type: 'helphub' });
        }
      }),
    ];
  }

  Promise.allSettled(promises).finally(() => {
    _.helpHub.loading = false;
  });
};

let mutationObserver: MutationObserver | null = null;
const setupMutationObserver = (_: CBStore) => {
  if (mutationObserver) mutationObserver.disconnect();

  // Only setup the observer if we are using nudges or checklists and at least one of them is triggered
  // when an element appears.
  if (
    _.flags?.['enable-when-element-appears-trigger'] &&
    (_.products.includes('nudges') || _.products.includes('checklists')) &&
    _.triggerableSelectors.length > 0
  ) {
    // Debounce the triggers to avoid triggering too many times.
    // We could run into a huge performance issue without this because it would triggered
    // everytime something is changed in the DOM.
    const debouncedTriggers = debounce(() => {
      for (let i = 0; i < _.triggerableSelectors.length; i++) {
        const selector = _.triggerableSelectors[i];
        const element = getElement(selector);

        if (element) {
          const style = window.getComputedStyle(element);
          const isDisplayNone = style.display === 'none';
          const isVisibilityHidden = style.visibility === 'hidden';

          const isHidden = isDisplayNone || isVisibilityHidden;

          // HACK: It's still possible for an element to be hidden due to its parent element
          // having overflow: hidden and our element being outside of its bounds
          // I've left this here for now because it's a rare case and I don't want to
          // introduce a performance hit for everyone by calling getBoundingClientRect
          // on every element and its parents.
          if (!isHidden) {
            NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_element_appears', meta: { selector } });
            ChecklistServiceActions.triggerChecklists(_, { type: 'when_element_appears', meta: { selector } });
            // remove the selector from the list so we don't trigger it again
            _.triggerableSelectors.splice(i, 1);
          }
        }
      }
    }, 1500);

    mutationObserver = new MutationObserver(() => {
      debouncedTriggers();
    });
  }

  if (_.triggerableSelectors.length > 0 && passesGlobalLimit(_)) {
    mutationObserver?.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['style', 'class'],
    });
  }

  return () => {
    mutationObserver?.disconnect();
  };
};

const persistTestMode = (_: CBStore) => {
  if (!_.isAdmin) return;
  if (_.testMode) {
    LocalStorage.set('testMode', true);
  } else {
    LocalStorage.remove('testMode');
  }
};

const viewLatestViaEnvOverrideOnEditorOpen = (_: CBStore) => {
  if (_.isEditorVisible) {
    GlobalActions.setEnvOverride(_, { env: 'latest' });
  }
};

const reloadCommandsOnEnvChange = (_: CBStore) => {
  if (_.active) {
    getSDK()[_reloadCommands]();
  }
};

const processClientSideIntegrations = (_: CBStore, ops: Ops) => {
  const replacedOrg = getPrevValue<CBStore['organization']>(findOp('set', ['organization'], ops));

  if (isEqual(replacedOrg?.integrations, _.organization?.integrations)) {
    return;
  } else {
    if (replacedOrg?.integrations) {
      tearDownAlgoliaIntegration(replacedOrg.integrations);
    }
  }

  if (!_.organization?.integrations) return;

  setupAnalyticsIntegrations(_.organization.integrations);

  return setupAlgoliaIntegration(_.organization.integrations);
};

const extensionSub = (_: CBStore) => {
  if (_.isAdmin) {
    // Listen for message from the Preview content script to start the preview
    const unsubStartPreview = StartPreviewMessage.addPageListener(async (data) => {
      // Recall _configUser so that the extension which has the context of auth can use this in an admin context
      await getSDK()[_configUser]();

      if (data.experience?.type === 'nudge') {
        _.extension.preview.enabled = true;
        _.extension.preview.experience = data.experience;
        NudgeServiceActions.startDebugSession(_, data.experience.nudge);
      }
    });

    // Listen for message from the Recorder content script to start the recorder
    const unsubStartRecorder = StartClickRecorderMessage.addPageListener(async (data) => {
      // If the initiator is preview, don't let the Preview content script start the recorder
      if (data.origin === 'preview') {
        _.extension.recorder.enabled = true;
        return;
      }

      NudgeServiceActions.closeAllNudgeMocks(_);
      _.extension.recorder.enabled = true;
      _.extension.recorder.experience = data.experience;
    });

    // Listen for message from the Preview or Recorder content scripts to update the nudge step and show the updated nudge step
    const unsubNudgeStep = UpdateNudgeStepMessage.addPageListener(async (data) => {
      const stringSelector = isElementInformation(data.value) ? data.value.selector : data.value;
      if (data.origin === 'preview' && _.extension.preview.experience) {
        // Right now when the preview updates the nudge step, it's only because the recorder made an update so we can disable the recorder here
        _.extension.recorder.enabled = false;
        const nudge = getNudgeById(_, _.extension.preview.experience.nudge.id);
        if (nudge) {
          NudgeServiceActions.updateNudgeStepForPreview(_, nudge, data.stepIndex, data.field, stringSelector);
        }
      } else if (data.origin === 'recorder' && _.extension.recorder.experience) {
        if (_.extension.recorder.enabled && _.extension.recorder.experience) {
          _.extension.recorder.experience.field = data.field;
          NudgeServiceActions.showStepMock(
            _,
            _.extension.recorder.experience.nudge,
            _.extension.recorder.experience.stepIndex,
            {
              anchorOverride: stringSelector,
            },
          );
        }
      }
    });

    return () => {
      unsubStartPreview();
      unsubNudgeStep();
      unsubStartRecorder();
    };
  }
};

export const initSharedSubs = (_: CBStore) => [
  sub(_, mergeContextSettings, [['serverContextSettings'], ['localContextSettings']]),

  sub(_, locationChanged, [
    ['endUserStore', 'hasRemoteLoaded'], // NOTE: need this because nudges and checklist triggering depends on _.endUser
    ['location', 'href'],
  ]),

  sub(_, setupMutationObserver, [['triggerableSelectors'], ['products']]),

  sub(_, deriveEndUserData, [['endUserStore', 'data', 'recents']]),
  sub(_, helpHubSearch, [
    ['helpHub', 'query'],
    ['helpHub', 'filter'],
  ]),
  locationSub(_),
  sub(_, LocalStorage.get('logChanges', false) ? (_, ops) => logChanges(ops) : () => null, '*'),
  sub(_, persistTestMode, [['testMode']]),
  sub(_, viewLatestViaEnvOverrideOnEditorOpen, [['isEditorVisible']]),
  sub(_, debounce(reloadCommandsOnEnvChange, 25), [['env'], ['envOverride']]),
  sub(_, processClientSideIntegrations, [['organization', 'integrations']]),
  sub(_, (_) => resetTimedTriggers(_), [['location']]),
  sub(_, extensionSub, [['isAdmin']]),
];
