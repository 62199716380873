import { useState } from 'react';

import type { IProfileType } from '@commandbar/internal/middleware/types';
import { Row } from '@commandbar/design-system/components/antd';
import FilterSelect from 'editor/src/editor/components/FilterSelect';

import { useTeamMembersContext } from '../../util/TeamMembersContext';
import Check from '@commandbar/design-system/icons/react/Check';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { reportErrorToUser } from 'editor/src/editor/utils/ErrorReporting';
import {
  CmdSearchInput,
  CmdDropdown,
  cmdToast,
  CmdAvatar,
  CmdColumnDef,
  CmdDataTable,
} from '@commandbar/design-system/cmd';

const getNameColumn = (profile: IProfileType) => {
  return profile.is_active ? `${profile.first_name} ${profile.last_name}` : profile.email.split('@')[0];
};

const TeamMembersTable = () => {
  const { loading, members, removeMember, changeRole } = useTeamMembersContext();
  const [selectedRole, setSelectedRole] = useState<IProfileType['role'] | 'all'>('all');

  const { user } = useAuth();

  const [searchTerm, setSearchTerm] = useState('');

  const filteredMembers = members
    .filter((member) => {
      const lowerCaseEmail = (member.email || '').toLowerCase();
      const lowerCaseFirstName = (member.first_name || '').toLowerCase();
      const lowerCaseLastName = (member.last_name || '').toLowerCase();

      return (
        (selectedRole === 'all' || member.role === selectedRole) &&
        (lowerCaseEmail.includes(searchTerm.toLowerCase()) ||
          lowerCaseFirstName.includes(searchTerm.toLowerCase()) ||
          lowerCaseLastName.includes(searchTerm.toLowerCase()))
      );
    })
    .sort((a, b) => {
      const keyA = getNameColumn(a);

      const keyB = getNameColumn(b);

      return keyA.localeCompare(keyB, 'en', { sensitivity: 'base' });
    });

  const _changeRole = (email: string, role: IProfileType['role']) => {
    const payload = {
      email: email,
      role,
    };

    axiosInstance
      .post('/organizations/change_teammate_role/', payload)
      .then((_response: any) => {
        changeRole(email, role);
        cmdToast.success("Succesfully changed teammate's role.");
      })
      .catch((err) => {
        reportErrorToUser(err);
      });
  };

  const columns: (currentUserEmail: string) => CmdColumnDef<IProfileType, any>[] = (currentUserEmail: string) => [
    {
      header: 'Full name',
      accessorKey: 'first_name',
      cell: ({ row }) => {
        const record = row.original;
        let initials = `${record.first_name ? record.first_name[0] : ''}${record.last_name ? record.last_name[0] : ''}`;
        if (!initials && record.email) {
          initials = record.email[0];
        }

        if (!initials) {
          initials = 'U';
        }
        initials = initials.toUpperCase();

        const name = getNameColumn(record);

        return (
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {' '}
            <CmdAvatar size="sm" variant="initials" className="bg-blue200">
              {initials}
            </CmdAvatar>
            {name}
            {!record.is_active && <span style={{ color: '#797C85' }}>(invited)</span>}
          </span>
        );
      },
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ cell }) => <span className="text-gray700">{cell.renderValue()}</span>,
    },
    {
      header: 'Role',
      accessorKey: 'role',
      cell: ({ row }) => {
        const record = row.original;
        const currentRole = record.role || '';
        return (
          <CmdDropdown.Menu>
            <CmdDropdown.SelectTrigger
              disabled={record.email === currentUserEmail}
              style={{ width: '100%', border: 'none', boxShadow: 'none' }}
            >
              {currentRole.charAt(0).toUpperCase() + currentRole.slice(1)}
            </CmdDropdown.SelectTrigger>
            <CmdDropdown.Content style={{ minWidth: '150px' }}>
              <CmdDropdown.Item
                style={{ justifyContent: 'space-between' }}
                onClick={() => _changeRole(record.email, 'admin')}
              >
                Admin {record.role === 'admin' && <Check />}
              </CmdDropdown.Item>
              <CmdDropdown.Item
                style={{ justifyContent: 'space-between' }}
                onClick={() => _changeRole(record.email, 'editor')}
              >
                Editor {record.role === 'editor' && <Check />}
              </CmdDropdown.Item>
              <CmdDropdown.Item
                style={{ justifyContent: 'space-between' }}
                onClick={() => _changeRole(record.email, 'contributor')}
              >
                Contributor {record.role === 'contributor' && <Check />}
              </CmdDropdown.Item>
              <CmdDropdown.Item
                style={{ justifyContent: 'space-between' }}
                onClick={() => _changeRole(record.email, 'viewer')}
              >
                Viewer {record.role === 'viewer' && <Check />}
              </CmdDropdown.Item>
              <CmdDropdown.Separator />
              <CmdDropdown.Item
                style={{ justifyContent: 'space-between', color: '#991B1B' }}
                onClick={() => {
                  const payload = {
                    email: record.email,
                  };

                  axiosInstance
                    .post('/organizations/revoke_teammate/', payload)
                    .then((_response: any) => {
                      cmdToast.success('Succesfully removed teammate.');
                      removeMember(record);
                    })
                    .catch((err) => {
                      reportErrorToUser(err);
                    });
                }}
              >
                Revoke access
              </CmdDropdown.Item>
            </CmdDropdown.Content>
          </CmdDropdown.Menu>
        );
      },
    },
  ];

  return (
    <CmdDataTable
      showPagination={filteredMembers.length > 10}
      hasGlobalSearch={false}
      data={filteredMembers}
      columns={columns(user?.email || '')}
      isLoading={loading}
      getRowId={(row) => row.email}
      rowCount={filteredMembers.length}
      toolBarChildren={
        <Row style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <FilterSelect
            selectLabel="Role"
            selectedOption={selectedRole}
            setSelectedOption={setSelectedRole}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Admin', value: 'admin' },
              { label: 'Editor', value: 'editor' },
              { label: 'Contributor', value: 'contributor' },
              { label: 'Viewer', value: 'viewer' },
            ]}
          />
          <CmdSearchInput
            style={{ minWidth: '218px' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Row>
      }
    />
  );
};

export default TeamMembersTable;
