/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import styled from '@emotion/styled';
import ActionTypeSelector from '../ActionTypeSelector/ActionTypeSelector';
import ActionDetailPanel from '../ActionDetailPanel';
import KeyboardShortcutEditor from '../KeyboardShortcutEditor/KeyboardShortcutEditor';
import { CategorySelect } from '../CategorySelect';
import { SynonymSuggestions } from '../SynonymSuggestions';

import ArgumentList from '../arguments/ArgumentList';

import { ICommandDetailDispatch } from '../CommandDetail';

import {
  DetailPreviewObjectType,
  IEditorCommandType,
  IOrganizationType,
  IRuleExpression,
} from '@commandbar/internal/middleware/types';
import {
  commandDefault,
  FormRow,
  Heading,
  Input,
  Checkbox,
  Modal,
  Select,
  AutoComplete,
  SimplePanel,
  Dropdown,
  SubHeading,
  Tooltip,
  PaddingContainer,
  CB_COLORS,
  Tag,
} from '@commandbar/design-system/components/antd';
import { AvailabilityPanel } from '../ConditionRulePanels/AvailabilityPanel';
import { RecommendationPanel } from '../ConditionRulePanels/RecommendationPanel';
import { commandCanBeSuggestedInCopilot, getCategoryField } from '../../utils';
import { getColorsFromCommands } from '../../../utils/ColorUtils';
import CodeEditor from 'react-simple-code-editor';
import hljs from 'highlight.js/lib/core';
import htmlbars from 'highlight.js/lib/languages/htmlbars';
import {
  countAvailabilityConditionsAndDependencies,
  countRecommendationConditions,
} from 'editor/src/editor/conditions/helpers';

import { FormRowContainer, TabBadge, TabTitleContainer, DirtyIndicator, RecordActionContainer } from './styled';
import { maybePluralize } from '@commandbar/internal/middleware/utils';
import Hotkey from '@commandbar/internal/client/Hotkey';
import * as Command from '@commandbar/internal/middleware/command';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AutoCompleteTextArea from '../../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from '../../../../hooks/useWindowInfo';
import NextStepCommandsSelect from '../NextStepCommandsSelect';
import { getFirstDetail } from '@commandbar/internal/middleware/detailPreview';
import ImageUploader from '../../../components/DragUpload/ImageUploader';
import {
  DetailTabPane,
  DetailTabs,
  MenuIconContainer,
  OverlaySettingsContainer,
  OverlaySettingsSection,
  OverlaySettingsVerticalSection,
} from '../../../components/styled';
import { InfoCircle, Settings03 } from '@commandbar/design-system/icons/react';
import { StyledTextArea } from '../../../helphub/shared';
import IconPicker from '../../../components/IconPicker/IconPicker';
import PrettyCodeEditor from 'editor/src/editor/components/PrettyCodeEditor';
import { ANNOUNCEMENTS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { CmdDivider, CmdSwitch } from '@commandbar/design-system/cmd';
import { getCommandType } from 'editor/src/editor/PagesOrActions';
import { AppState, useAppContext } from 'editor/src/AppStateContext';

hljs.registerLanguage('html', htmlbars);

/*******************************************************************************/
/* Interface
/*******************************************************************************/

interface IProps extends RouteComponentProps {
  appState: AppState;
  command: IEditorCommandType;
  isDirty: boolean;
  updateCommandState: ICommandDetailDispatch;
  organization: IOrganizationType;
  disabled?: boolean;
}

interface IState {
  dirtyTabs: {
    availability: boolean;
    recommendation: boolean;
  };
}

const capitalize = (s: string | null | undefined): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 6px;
  right: 16px;
  z-index: 10;
`;

/*******************************************************************************/
/* Component
/*******************************************************************************/

class VisualCommandEditor extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      dirtyTabs: {
        availability: false,
        recommendation: false,
      },
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    // If `isDirty` goes from true->false, command was saved, so clear all dirtyTabs
    if (prevProps.isDirty && !this.props.isDirty) {
      this.setState({
        dirtyTabs: {
          availability: false,
          recommendation: false,
        },
      });
    }
  }

  public getAvailabilityTabBadge = () => {
    const { count } = countAvailabilityConditionsAndDependencies(this.props.command);

    return count === 0 ? 'Always' : maybePluralize(count, 'rule', 'rules', true);
  };

  public getRecommendationTabBadge = () => {
    const { alwaysRecommend, neverRecommend, count } = countRecommendationConditions(this.props.command);

    if (neverRecommend) {
      return 'Never';
    }

    if (alwaysRecommend) {
      return 'Always';
    }

    return maybePluralize(count, 'rule', 'rules', true);
  };

  public setTabDirtyState = (tabName: keyof IState['dirtyTabs'], isDirty: boolean) => {
    const { dirtyTabs } = this.state;

    this.setState({
      dirtyTabs: {
        ...dirtyTabs,
        [tabName]: isDirty,
      },
    });
  };

  public getTabDirtyState = (tabName: keyof IState['dirtyTabs']): boolean => {
    return this.state.dirtyTabs[tabName];
  };

  public createNewNudge = () => {
    this.props.history.push(ANNOUNCEMENTS_ROUTE, { command: this.props.command });
  };

  public render() {
    const {
      command,
      disabled,
      updateCommandState: { updateCommand },
      appState,
    } = this.props;

    if (!Command.showInDefaultList(command)) {
      return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          <RecordActionContainer>
            <PaddingContainer>
              <SimplePanel>
                <GeneralFieldsForm {...this.props} />
              </SimplePanel>
            </PaddingContainer>
          </RecordActionContainer>
        </div>
      );
    }

    const canBeSuggestedInCopilot = commandCanBeSuggestedInCopilot(command.arguments);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          pointerEvents: disabled ? 'none' : 'all',
          position: 'relative',
        }}
      >
        {command.template.type !== 'video' && command.template.type !== 'helpdoc' && (
          <DropdownStyled
            trigger={['click']}
            placement="bottomLeft"
            overlay={
              <OverlaySettingsContainer width="288px">
                <React.Fragment>
                  {appState.flags['release-search-experiences-in-help-hub'] && (
                    <OverlaySettingsSection
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <SubHeading>Searchable in HelpHub</SubHeading>
                      <Tooltip
                        showIf={!appState.organization.helphub_enabled}
                        content={'You must first enable HelpHub to make this searchable.'}
                      >
                        <CmdSwitch
                          checked={command.show_in_helphub_search && appState.organization.helphub_enabled}
                          onCheckedChange={() =>
                            updateCommand((draft) => {
                              draft.show_in_helphub_search = !draft.show_in_helphub_search;
                            })
                          }
                          disabled={!appState.organization.helphub_enabled}
                        />
                      </Tooltip>
                    </OverlaySettingsSection>
                  )}

                  {appState.flags['release-search-experiences-in-spotlight'] && (
                    <OverlaySettingsSection
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <SubHeading>Searchable in Spotlight</SubHeading>
                      <Tooltip
                        showIf={!appState.organization.bar_enabled}
                        content={'You must first enable Spotlight to make this searchable.'}
                      >
                        <CmdSwitch
                          checked={command.show_in_spotlight_search && appState.organization.bar_enabled}
                          onCheckedChange={() =>
                            updateCommand((draft) => {
                              draft.show_in_spotlight_search = !draft.show_in_spotlight_search;
                            })
                          }
                          disabled={!appState.organization.bar_enabled}
                        />
                      </Tooltip>
                    </OverlaySettingsSection>
                  )}

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>Suggest in Copilot</SubHeading>
                    <CmdSwitch
                      checked={command.copilot_suggest}
                      onCheckedChange={() =>
                        updateCommand((draft) => {
                          draft.copilot_suggest = !draft.copilot_suggest;
                        })
                      }
                      disabled={!canBeSuggestedInCopilot && !command.copilot_suggest}
                      tooltip={
                        !canBeSuggestedInCopilot ? 'Only text, date, and set arguments are supported.' : undefined
                      }
                    />
                  </OverlaySettingsSection>

                  {command.copilot_suggest && (
                    <>
                      <OverlaySettingsVerticalSection>
                        <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>CTA Label</div>
                        </SubHeading>
                        <Input
                          value={command.copilot_cta_label}
                          onChange={(e) =>
                            updateCommand((draft) => {
                              draft.copilot_cta_label = e.target.value;
                            })
                          }
                          placeholder={command.text}
                          style={{
                            border: `1px solid ${CB_COLORS.neutral300}`,
                          }}
                        />
                      </OverlaySettingsVerticalSection>
                      <OverlaySettingsVerticalSection>
                        <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: '4px' }}>Description</div>
                          <Tooltip content="Copilot will use this to make smart suggestions to users.">
                            <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                          </Tooltip>
                        </SubHeading>
                        <StyledTextArea
                          value={command.copilot_description}
                          onChange={(e) =>
                            updateCommand((draft) => {
                              draft.copilot_description = e.target.value;
                            })
                          }
                          rows={2}
                          placeholder="Describe the Command and the outcome once executed."
                          style={{
                            border: `1px solid ${CB_COLORS.neutral300}`,
                          }}
                        />
                      </OverlaySettingsVerticalSection>
                    </>
                  )}

                  <CmdDivider spacing="md" />

                  <OverlaySettingsVerticalSection>
                    <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '4px' }}>Tags</div>
                      <Tooltip content="Add tags to help organize your Pages and Actions in the Editor.">
                        <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                      </Tooltip>
                    </SubHeading>
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      placeholder="Create or select a tag..."
                      onChange={(values) => {
                        updateCommand((draft) => {
                          draft.editor_tags = values;
                        });
                      }}
                      value={command.editor_tags}
                      options={appState.editorTags.map((tag) => ({ value: tag }))}
                    />
                  </OverlaySettingsVerticalSection>
                </React.Fragment>
              </OverlaySettingsContainer>
            }
          >
            <MenuIconContainer
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Settings03 />
            </MenuIconContainer>
          </DropdownStyled>
        )}

        <DetailTabs
          destroyInactiveTabPane={true}
          type="card"
          tabBarStyle={{
            paddingLeft: '16px',
          }}
        >
          <DetailTabPane tab="General" key="tab-general" style={{ padding: '16px' }}>
            <SimplePanel>
              <GeneralFieldsForm {...this.props} />
            </SimplePanel>
          </DetailTabPane>

          <DetailTabPane
            style={{ padding: '16px' }}
            tab={
              <TabTitleContainer>
                {this.getTabDirtyState('availability') && <DirtyIndicator />}
                Availability <TabBadge>{this.getAvailabilityTabBadge()}</TabBadge>
              </TabTitleContainer>
            }
            key="tab-availability"
          >
            <SimplePanel>
              <AvailabilityPanel
                command={command}
                onChange={(expr: IRuleExpression) => {
                  updateCommand((draft) => {
                    draft.availability_expression = expr;
                  });

                  this.setTabDirtyState('availability', true);
                }}
              />
            </SimplePanel>
          </DetailTabPane>
          <DetailTabPane
            style={{ padding: '16px' }}
            tab={
              <TabTitleContainer>
                {this.getTabDirtyState('recommendation') && <DirtyIndicator />}
                Recommendation <TabBadge>{this.getRecommendationTabBadge()}</TabBadge>
              </TabTitleContainer>
            }
            key="tab-recommendation"
          >
            <SimplePanel>
              <RecommendationPanel
                command={command}
                onChangeAlwaysRecommend={(value: boolean) => {
                  updateCommand((draft) => {
                    draft.always_recommend = value;
                  });
                }}
                onChange={(expr: IRuleExpression) => {
                  updateCommand((draft) => {
                    draft.recommend_expression = expr;
                  });

                  this.setTabDirtyState('recommendation', true);
                }}
              />
            </SimplePanel>
          </DetailTabPane>
        </DetailTabs>
      </div>
    );
  }
}

const GeneralFieldsForm = (props: IProps) => {
  const {
    command,
    updateCommandState: { updateCommand, recordAction, template },
  } = props;

  const { categories, commands } = useAppContext();

  const isShownInDefaultList = Command.showInDefaultList(command);

  const getIcon = (): string | null => {
    const category_icon = getCategoryField(categories, command.category, 'icon');
    if (category_icon) {
      return category_icon;
    }

    if (command.icon) {
      return command.icon;
    }

    return commandDefault(command);
  };

  const toggleShowInDefaultList = () => {
    if (isShownInDefaultList) {
      Modal.confirm({
        title: 'Are you sure you want to turn off showing this action as a command?',
        content:
          'Doing this will clear certain fields that are only applicable to actions shown as commands (e.g., shortcut, category, preview).',
        onOk: () => {
          recordAction.hideInDefaultList();
        },
      });
    } else {
      recordAction.showInDefaultList();
    }
  };

  const synTagRender = (cust_props: CustomTagProps) => {
    const { label, onClose } = cust_props;
    const oldTag = command.tags.some((t) => t === label);
    return (
      <Tag color={oldTag ? 'geekblue' : 'blue'} closable={true} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const hideDefaultListFields: React.CSSProperties = { display: isShownInDefaultList ? undefined : 'none' };
  const isRecordAction = Command.isRecordAction(command);
  const isThirdParty = !!command.third_party_source;
  const isCustomHelpDoc = !isThirdParty && commands.find((c) => c.id === command.id)?.template.type === 'helpdoc';
  const isVideo = commands.find((c) => c.id === command.id)?.template.type === 'video';

  const { context } = useWindowInfo();

  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));

  const typeLabel = getCommandType(command) === 'page' ? 'Page' : 'Action';

  return (
    <div>
      <FormRowContainer>
        <FormRow
          title="Title:"
          keepTooltipSpace
          info={
            isThirdParty && command.third_party_source !== 'web' && command.third_party_source !== 'web_v2'
              ? 'This field is not editable as it is provided by ' + capitalize(command.third_party_source)
              : undefined
          }
          input={
            <Input
              disabled={isThirdParty && command.third_party_source !== 'web' && command.third_party_source !== 'web_v2'}
              placeholder={`${typeLabel} name`}
              value={command.text}
              onChange={(e) => updateCommand((draft) => (draft.text = e.target.value))}
            />
          }
        />
        <FormRow
          title="Icon:"
          keepTooltipSpace
          info={
            getCategoryField(categories, command.category, 'icon') != null
              ? `An icon has already been set for this ${typeLabel}'s category. To edit this icon, first remove the category icon.`
              : undefined
          }
          input={
            <IconPicker
              disabled={getCategoryField(categories, command.category, 'icon') != null}
              color={getCategoryField(categories, command.category, 'icon_color') ?? command.icon_color}
              icon={getIcon()} // fix
              colors={getColorsFromCommands(commands)}
              onColorSelect={(color) =>
                updateCommand((draft) => {
                  draft.icon_color = color;
                })
              }
              onIconSelect={(icon) => {
                updateCommand((draft) => {
                  draft.icon = icon;
                });
              }}
            />
          }
        />
        {getCategoryField(categories, command.category, 'render_as') === 'grid' && (
          <FormRow
            title="Image:"
            keepTooltipSpace
            info={
              getCategoryField(categories, command.category, 'image') != null
                ? `An image has already been set for this ${typeLabel}'s category. To edit this image, first remove the category image.`
                : undefined
            }
            input={
              <IconPicker
                disabled={getCategoryField(categories, command.category, 'image') != null}
                color={getCategoryField(categories, command.category, 'image_color') ?? command.image_color}
                icon={getCategoryField(categories, command.category, 'image') ?? command.image}
                onColorSelect={(color) => updateCommand((draft) => (draft.image_color = color))}
                colors={getColorsFromCommands(commands)}
                onIconSelect={(icon) => {
                  updateCommand((draft) => {
                    draft.image = icon;
                  });
                }}
              />
            }
          />
        )}
        {(command.template.type !== 'link' || isRecordAction) && (
          <FormRow
            title="Type:"
            keepTooltipSpace
            input={
              <ActionTypeSelector
                templateType={command.template.type}
                onCommandTypeChange={template.updateType}
                isDisabled={isThirdParty || isCustomHelpDoc || isVideo}
                isRecordAction={isRecordAction}
              />
            }
          />
        )}
        <ActionDetailPanel
          command={command}
          onTemplateOptionsChange={template.updateOptions}
          onValueChange={template.updateValue}
          onClickElemListAdd={template.addClickElem}
          onClickElemListDelete={template.deleteClickElem}
          isThirdParty={isThirdParty}
          updateCommandState={props.updateCommandState}
          /* commandState={props.commandState} */
        />
        <FormRow
          keepTooltipSpace
          title="Thumbnail:"
          input={
            <ImageUploader
              onUpload={(thumbnail) => updateCommand((draft) => (draft.thumbnail = thumbnail))}
              onDelete={() => updateCommand((draft) => (draft.thumbnail = null))}
              thumbnail={props.command.thumbnail}
              organizationId={props.organization.id}
            />
          }
          info="Thumbnails are displayed when pages or actions are included as part of a HelpHub recommendation set."
        />
        {isShownInDefaultList && <PreviewEditor command={command} updateCommand={updateCommand} />}
        {command.template.type === 'helpdoc' && (
          <FormRow
            gutter={[8, 24]}
            title="Use for training only"
            info="Content will only be used to improve AI generated Copilot answers and won't be shown in search results."
            input={
              <div style={{ display: 'flex' }}>
                <Checkbox
                  defaultChecked={command.training_only}
                  onChange={(e) => {
                    updateCommand((draft) => {
                      draft.training_only = e.target.checked;
                    });
                  }}
                />
              </div>
            }
          />
        )}
      </FormRowContainer>

      <Heading text="Arguments" />
      <FormRowContainer>
        <ArgumentList
          arguments={props.command.arguments}
          updateCommandState={props.updateCommandState}
          recordSettings={props.organization.resource_options}
          command={props.command}
        />
      </FormRowContainer>
      {isShownInDefaultList && <Heading text="Other" />}
      <FormRowContainer style={hideDefaultListFields}>
        <FormRow
          title="Category:"
          required={true}
          satisfied={props.command.category != null}
          input={
            <CategorySelect
              selectedCategoryId={props.command.category as number}
              onCategorySelect={(id: number) => {
                updateCommand((draft) => (draft.category = id));
              }}
            />
          }
        />
        <FormRow
          title="Next steps:"
          required={false}
          info={
            props.command.template.type !== 'video' && !props.command.show_preview
              ? 'Next step commands can only be added to video commands or commands with a preview.'
              : 'Add a list of upto 3 commands to be displayed after the command is executed.'
          }
          input={
            <NextStepCommandsSelect
              disabled={
                props.command.template.type !== 'video' &&
                !props.command.show_preview &&
                props.command.template.type !== 'helpdoc'
              }
              commands={props.command.next_steps}
              onChange={(nextStepCommands: number[]) => {
                updateCommand((draft) => (draft.next_steps = nextStepCommands));
              }}
            />
          }
        />
        <FormRow
          title="Synonyms:"
          input={
            <Select
              key="synonym-select"
              mode="tags"
              style={{ width: '100%' }}
              tagRender={synTagRender}
              value={props.command.tags.map((s) => ({ value: s, label: s }))}
              placeholder="Type a synonym and press enter (optional)"
              onChange={(tags: any) => {
                updateCommand((draft) => {
                  draft.tags = tags as string[]; // TODO: type of `tags` should be {value: string, label: string}[], why it is string[] instead?
                });
              }}
              dropdownStyle={{ display: 'none' }}
            />
          }
          info={`Add alternate search terms to this ${typeLabel} by typing and then pressing enter.`}
        />
        <SynonymSuggestions
          organization={props.organization}
          command={props.command}
          onSelect={(synonym: string) => {
            updateCommand((draft) => {
              draft.tags.push(synonym);
            });
          }}
          onDeny={(synonym: string) => {
            updateCommand((draft) => {
              draft.generated_tags.deny ||= [];
              draft.generated_tags.deny.push(synonym);
            });
          }}
        />
        <FormRow
          title="Heading:"
          keepTooltipSpace
          info={
            isThirdParty
              ? 'This field is not editable as it is provided by ' + capitalize(props.command.third_party_source)
              : undefined
          }
          input={
            isThirdParty ? (
              <Input.TextArea value={props.command.heading} autoSize={{ minRows: 1, maxRows: 5 }} disabled />
            ) : (
              <AutoCompleteTextArea
                onChange={(heading) =>
                  updateCommand((draft) => {
                    draft.heading = heading;
                  })
                }
                value={command.heading}
                placeholder="(optional)"
                options={[...contextKeys]}
              />
            )
          }
        />
        <FormRow
          title="Explanation:"
          keepTooltipSpace
          info={
            isThirdParty
              ? 'This field is not editable as it is provided by ' + capitalize(props.command.third_party_source)
              : undefined
          }
          input={
            isThirdParty ? (
              <Input.TextArea value={props.command.explanation} autoSize={{ minRows: 1, maxRows: 5 }} disabled />
            ) : (
              <AutoCompleteTextArea
                onChange={(explanation) =>
                  updateCommand((draft) => {
                    draft.explanation = explanation;
                  })
                }
                value={command.explanation}
                placeholder="(optional)"
                options={[...contextKeys]}
              />
            )
          }
        />
        <KeyboardShortcutEditor
          hotkey_mac={props.command.hotkey_mac}
          hotkey_win={props.command.hotkey_win}
          onShortcutsChangeMac={(value) =>
            updateCommand((draft) => (draft.hotkey_mac = Hotkey.toModString(value, 'mac')))
          }
          onShortcutsChangeWin={(value: string) =>
            updateCommand((draft) => (draft.hotkey_win = Hotkey.toModString(value, 'win')))
          }
          currentCommand={props.command}
        />

        <FormRow
          title="Celebrate:"
          input={
            <Checkbox
              defaultChecked={!!props.command.celebrate}
              onChange={(e) => {
                const celebrate = e.target.checked ? {} : null;
                updateCommand((draft) => (draft.celebrate = celebrate));
              }}
            />
          }
          info={`Throw some confetti after a ${typeLabel} execution 😃🎉`}
        />
      </FormRowContainer>
      {isRecordAction && (
        <>
          <Heading text="Record action properties" />
          <FormRowContainer>
            <FormRow
              title="Show as command"
              keepTooltipSpace
              input={
                <CmdSwitch
                  onCheckedChange={() => {
                    toggleShowInDefaultList();
                  }}
                  checked={isShownInDefaultList}
                  style={{ marginLeft: 10 }}
                />
              }
            />
          </FormRowContainer>
        </>
      )}
    </div>
  );
};

interface IPreviewEditorProps {
  command: IEditorCommandType;
  updateCommand: (recipe: (draft: IEditorCommandType) => void) => void;
}

const SelectCustomComponent = ({ value, onChange }: { value: string; onChange: (newValue: string) => void }) => {
  const { components } = useWindowInfo();
  const options = components.map(({ key, name }) => ({
    value: key,
    label: name && name !== '' ? `${name} (${key})` : key,
  }));

  return <AutoComplete filterOption style={{ width: '100%' }} onChange={onChange} options={options} value={value} />;
};

const PreviewEditor = ({ command, updateCommand }: IPreviewEditorProps) => {
  const detailHelpers: { [key in DetailPreviewObjectType['type']]: { placeholder: string; label: string } } = {
    plaintext: { placeholder: 'this text previews the command', label: 'Detail Text' },
    markdown: { placeholder: '# heading', label: 'Markdown text' },
    html: { placeholder: '<p>I am a placeholder, edit here!</p>', label: 'HTML' },
    react: { placeholder: '<p>Preview</p>', label: 'React Component' },
    video: { placeholder: 'https://domain.com/media/video.mp4', label: 'Video URL' },
    component: { placeholder: 'Preview component', label: 'Component' },
  };
  const detail = getFirstDetail(command.content);

  const previewValueInput = (() => {
    switch (detail?.type ?? 'html') {
      case 'video':
        return (
          <Input.TextArea
            onChange={(e) =>
              updateCommand((draft) => {
                draft.content = [
                  {
                    type: 'video',
                    value: e.target.value,
                  },
                ];
              })
            }
            value={detail?.value || ''}
            placeholder={detailHelpers.video.placeholder}
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
        );
      case 'html':
        return (
          <PrettyCodeEditor
            placeholder={detailHelpers.html.placeholder}
            value={detail?.value || (command.arguments?.__html__?.value as any)?.source || ''}
            language="jsx"
            onValueChange={(code: string) => {
              if (detail?.value) {
                updateCommand((draft) => {
                  draft.content = [{ type: 'html', value: code }];
                });
              } else if ('__html__' in command.arguments) {
                updateCommand((draft) => {
                  draft.arguments = {
                    ...draft.arguments,
                    __html__: {
                      ...draft.arguments.__html__,
                      value: {
                        source: code,
                      },
                      is_private: true,
                      type: 'html',
                    },
                  };
                });
              } else {
                updateCommand((draft) => {
                  draft.content = [{ type: 'html', value: code }];
                });
              }
            }}
          />
        );
      case 'component':
        return (
          <SelectCustomComponent
            value={detail?.value || ''}
            onChange={(value) =>
              updateCommand((draft) => {
                draft.content = [
                  {
                    type: 'component',
                    value,
                  },
                ];
              })
            }
          />
        );

      default:
        return (
          <CodeEditor
            placeholder={!!detail?.type ? detailHelpers[detail?.type].placeholder : ''}
            value={detail?.value || ''}
            onValueChange={(code) => {
              updateCommand((draft) => {
                draft.content = [
                  {
                    type: detail?.type ?? 'html',
                    value: code,
                  },
                ];
              });
            }}
            highlight={(code) => {
              let highlighted;
              try {
                const highlight = hljs.highlight('htmlbars', code);
                highlighted = highlight.value;
              } catch (err) {
                highlighted = 'Error...';
              }

              return highlighted;
            }}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              border: '1px solid rgba(0,0,0,0.1)',
              borderRadius: '4px',
              paddingBottom: '18px',
            }}
          />
        );
    }
  })();

  return (
    <>
      <FormRow
        gutter={[8, 24]}
        title="Preview"
        info="Render an inline  preview within Spotlight"
        input={
          <div style={{ display: 'flex' }}>
            <Checkbox
              defaultChecked={command.show_preview}
              onChange={(e) => {
                updateCommand((draft) => {
                  draft.show_preview = e.target.checked;
                });
              }}
            />
          </div>
        }
      />

      {command.show_preview && command?.template.type !== 'helpdoc' && (
        <div>
          <FormRow
            title="Preview Type:"
            input={
              <Select
                value={detail?.type ?? 'html'}
                style={{ width: '100%' }}
                onChange={(val) => {
                  updateCommand((draft) => {
                    if (!detail) {
                      draft.content = [{ type: val, value: '' }];
                    } else {
                      draft.content = [
                        {
                          ...detail,
                          type: val,
                        },
                      ];
                    }
                  });
                }}
              >
                {/* <Select.Option value="plaintext">Plain Text</Select.Option> */}
                {/* <Select.Option value="markdown">Markdown</Select.Option> */}
                <Select.Option value="html">HTML</Select.Option>
                {/* <Select.Option value="video">Video</Select.Option> */}
                <Select.Option value="component">Custom Component</Select.Option>
              </Select>
            }
            keepTooltipSpace
          />
          <FormRow
            title={detail?.type ? detailHelpers[detail.type].label : ''}
            keepTooltipSpace
            input={previewValueInput}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(VisualCommandEditor);
