/** @jsx jsx */
import { jsx } from '@emotion/core';
import type { Placement } from '@floating-ui/dom';
import {
  type CSSProperties,
  type HTMLAttributes,
  type HTMLProps,
  type ReactElement,
  type ReactNode,
  type RefCallback,
  type RefObject,
  forwardRef,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';

import { roundByDPR } from '@commandbar/commandbar/products/nudges/components/utils';
import Z from '@commandbar/internal/client/Z';
import StyledCloseButtonContainer from '@commandbar/internal/client/themesV2/components/nudge/StyledCloseButtonContainer';
import StyledTertiaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledTertiaryIconButton';
import { useFocusTrap } from '../../util/hooks/useFocusTrap';
import { useMergeRefs } from '../../util/hooks/useMergeRefs';
import { useStore } from '../../util/hooks/useStore';
import { Slot } from '../Slot';
import { useThemeV2Context } from '../ThemeV2Context';
import { TestID } from './constants';
import {
  ClickMachineContext,
  HoverMachineContext,
  PopoverContextProvider,
  type PopoverService,
  type TriggerType,
  type UsePopoverSelector,
  usePopoverContext,
} from './state';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

type WrapperProps = { children: ReactNode };

/************************************
 * Trigger Components
 ************************************/

type TriggerProps = WrapperProps & HTMLProps<HTMLButtonElement> & Partial<{ asChild: boolean }>;

const ClickTrigger = forwardRef<HTMLButtonElement, TriggerProps>(
  ({ children, style, asChild, ...props }, forwardedRef) => {
    const clickTriggerRef = ClickMachineContext.useSelector(({ context }) => context.trigger.ref);
    const open = ClickMachineContext.useSelector(({ matches }) => matches('open'));
    const { send } = ClickMachineContext.useActorRef();
    const { onEnter } = useAnimatedWidget();
    const measuredRef: RefCallback<HTMLButtonElement> = useCallback(
      (node) => {
        if (node !== null) {
          send({ type: 'UPDATE_TRIGGER_DIMENSIONS', dimensions: node.getBoundingClientRect() });
        }
      },
      [send],
    );

    const mergedRef = useMergeRefs(clickTriggerRef, forwardedRef, measuredRef);

    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        data-testid={TestID.ClickTrigger}
        {...props}
        ref={mergedRef}
        aria-haspopup="dialog"
        data-state={open ? 'open' : 'closed'}
        style={{
          cursor: 'pointer',
          ...style,
        }}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          send('OPEN');
          onEnter();
        }}
      >
        {children}
      </Comp>
    );
  },
);

const HoverTrigger = forwardRef<HTMLButtonElement, TriggerProps>(
  ({ children, style, asChild, ...props }, forwardedRef) => {
    const hoverTriggerRef = HoverMachineContext.useSelector(({ context }) => context.trigger.ref);
    const open = HoverMachineContext.useSelector(({ matches }) => matches('open'));
    const { send } = HoverMachineContext.useActorRef();
    const { onEnter, onExit } = useAnimatedWidget();

    const measuredRef: RefCallback<HTMLButtonElement> = useCallback(
      (node) => {
        if (node !== null) {
          send({ type: 'UPDATE_TRIGGER_DIMENSIONS', dimensions: node.getBoundingClientRect() });
        }
      },
      [send],
    );

    const mergedRef = useMergeRefs(hoverTriggerRef, forwardedRef, measuredRef);

    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        data-testid={TestID.HoverTrigger}
        {...props}
        ref={mergedRef}
        data-state={open ? 'open' : 'closed'}
        style={{
          cursor: 'default',
          ...style,
        }}
        tabIndex={-1}
        type="button"
        onMouseEnter={() => {
          send('OPEN');
          onEnter();
        }}
        onMouseLeave={() => {
          onExit(() => {
            send('CLOSE');
          });
        }}
      >
        {children}
      </Comp>
    );
  },
);

export const PopoverTrigger = forwardRef<HTMLButtonElement, TriggerProps>(
  ({ children, style: customStyles, ...props }, ref) => {
    const { triggerType } = usePopoverContext();
    const TriggerComponent = triggerType === 'hover' ? HoverTrigger : ClickTrigger;

    const style: CSSProperties = {
      zIndex: 1,
      position: 'absolute',
      background: 'none',
      border: 'none',
      padding: 0,
      ...customStyles,
    };

    return (
      <TriggerComponent {...props} ref={ref} style={style}>
        {children}
      </TriggerComponent>
    );
  },
);

/************************************
 * Content Components
 ************************************/

type PixelValue = `${number}px`;

const wrapInPx = (value: number): PixelValue => `${value}px`;

type HoverBufferPositioning<T extends number | PixelValue = PixelValue> =
  | { top: T; left: T; height: T }
  | { bottom: T; left: T; height: T }
  | { left: T; top: T; width: T }
  | { right: T; top: T; width: T };

const getHoverBufferPositioning = (placement: Placement, offset: number): HoverBufferPositioning<number> => {
  switch (placement) {
    case 'top-end':
    case 'top-start':
    case 'top': {
      return { bottom: -offset, left: 0, height: offset };
    }
    case 'bottom-end':
    case 'bottom-start':
    case 'bottom': {
      return { top: -offset, left: 0, height: offset };
    }
    case 'left-end':
    case 'left-start':
    case 'left': {
      return { right: -offset, top: 0, width: offset };
    }
    case 'right-end':
    case 'right-start':
    case 'right': {
      return { left: -offset, top: 0, width: offset };
    }
  }
};

type ContentProps = WrapperProps & {
  labelledBy: HTMLAttributes<HTMLDivElement>['aria-labelledby'];
  describedBy: HTMLAttributes<HTMLDivElement>['aria-describedby'];
  style?: CSSProperties;
  portalToId?: string;
  shouldStealFocus?: boolean;
} & Partial<HTMLProps<HTMLDivElement>>;

const HoverPopoverContent = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, style, labelledBy, describedBy, portalToId, ...props }, forwardedRef) => {
    const { send } = HoverMachineContext.useActorRef();
    const { animStyles, isAnimatedWidget, onEnter, onExit } = useAnimatedWidget();

    const hoverContentRef = HoverMachineContext.useSelector(({ context }) => context.content.ref);
    const offset = HoverMachineContext.useSelector(({ context }) => context.content.offset);
    const isOpen = HoverMachineContext.useSelector(({ matches }) => matches('open'));
    const x = HoverMachineContext.useSelector(({ context }) => context.content.position?.x);
    const y = HoverMachineContext.useSelector(({ context }) => context.content.position?.y);

    const placement = HoverMachineContext.useSelector(({ context }) => context.content.placement);
    const hoverBufferStyles = Object.entries(getHoverBufferPositioning(placement, offset)).reduce((acc, [key, val]) => {
      // @ts-expect-error - Object.entries cannot infer the type of the key
      acc[key] = wrapInPx(val);
      return acc;
    }, {} as HoverBufferPositioning);

    const mergedRef = useMergeRefs(hoverContentRef, forwardedRef);

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <div
        data-testid={TestID.HoverContent}
        {...props}
        ref={mergedRef}
        aria-describedby={describedBy}
        aria-labelledby={labelledBy}
        css={{
          '::before': {
            content: '""',
            position: 'absolute',
            height: '100%',
            width: '100%',
            pointerEvents: 'auto',
            opacity: 0,
            ...hoverBufferStyles,
          },
        }}
        role="tooltip"
        style={{
          ...style,
          top: 0,
          left: 0,
          // If this is an animated widget then the control of visibility is up to the AnimatedWidget context
          ...(!isAnimatedWidget && {
            display: isOpen ? 'block' : 'none',
          }),
          ...(isAnimatedWidget && {
            ...animStyles,
            // When the state machine says that the nudge is open, respect the animation hook's opacity
            opacity: isOpen ? animStyles.opacity : 0,
          }),
          // Apply the transform at the end to keep the tooltip positioning and any animation transforms
          transform: `translate(${roundByDPR(x ?? 0)}px, ${roundByDPR(y ?? 0)}px) ${animStyles.transform ?? ''}`,
        }}
        onMouseEnter={() => {
          send('OPEN');
          onEnter();
        }}
        // INFO: This is to keep the content open when moving between the gap between the trigger and the content
        onMouseLeave={() => {
          onExit(() => {
            send('CLOSE');
          });
        }}
      >
        {children}
      </div>
    );
  },
);

const ClickPopoverContent = ({
  children,
  style,
  labelledBy,
  describedBy,
  shouldStealFocus = true,
  ...props
}: ContentProps) => {
  const { send } = ClickMachineContext.useActorRef();
  const ref = ClickMachineContext.useSelector(({ context }) => context.content.ref);
  const isOpen = ClickMachineContext.useSelector(({ matches }) => matches('open'));
  const x = ClickMachineContext.useSelector(({ context }) => context.content.position?.x);
  const y = ClickMachineContext.useSelector(({ context }) => context.content.position?.y);
  const { animStyles, isAnimatedWidget, onExit } = useAnimatedWidget();

  useFocusTrap(ref, { shouldStealFocus: shouldStealFocus && isOpen });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      data-testid={TestID.ClickContent}
      {...props}
      ref={ref}
      aria-describedby={describedBy}
      aria-labelledby={labelledBy}
      aria-modal={isOpen}
      role="dialog"
      style={{
        ...style,
        top: 0,
        left: 0,
        // If this is an animated widget then the control of visibility is up to the AnimatedWidget context
        ...(!isAnimatedWidget && {
          display: isOpen ? 'block' : 'none',
        }),
        ...(isAnimatedWidget && {
          ...animStyles,
          // When the state machine says that the nudge is open, respect the animation hook's opacity
          opacity: isOpen ? animStyles.opacity : 0,
        }),
        // Apply the transform at the end to keep the tooltip positioning and any animation transforms
        transform: `translate(${roundByDPR(x ?? 0)}px, ${roundByDPR(y ?? 0)}px) ${animStyles.transform ?? ''}`,
      }}
      tabIndex={-1}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          onExit(() => {
            send('CLOSE');
          });
        }
      }}
    >
      {children}
    </div>
  );
};

export const PopoverContent = forwardRef<HTMLDivElement, ContentProps>(
  ({ children, labelledBy, describedBy, portalToId, style: customStyles, ...props }, ref) => {
    const { triggerType } = usePopoverContext();
    const ContentComponent = triggerType === 'hover' ? HoverPopoverContent : ClickPopoverContent;

    const style: CSSProperties = {
      position: 'absolute',
      pointerEvents: 'auto',
      zIndex: 0,
      ...customStyles,
    };

    const Comp = (
      <ContentComponent describedBy={describedBy} labelledBy={labelledBy} {...props} ref={ref} style={style}>
        {children}
      </ContentComponent>
    );

    if (portalToId) {
      const container = document.getElementById(portalToId);
      if (container) {
        return createPortal(Comp, container);
      }
    }

    return Comp;
  },
);

/************************************
 * Close Components
 ************************************/

const Close = ({ children, style, ...props }: WrapperProps & HTMLProps<HTMLButtonElement>) => {
  const { send } = ClickMachineContext.useActorRef();
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { onExit } = useAnimatedWidget();
  return _.flags?.['release-themes-v2'] ? (
    <StyledCloseButtonContainer>
      <StyledTertiaryIconButton
        themeV2={themeV2}
        data-testid={TestID.Close}
        aria-label="close"
        onClick={() => {
          onExit(() => {
            send('CLOSE');
          });
        }}
      >
        {children}
      </StyledTertiaryIconButton>
    </StyledCloseButtonContainer>
  ) : (
    <button
      data-testid={TestID.Close}
      {...props}
      aria-label="close"
      style={{
        display: 'flex',
        cursor: 'pointer',
        pointerEvents: 'auto',
        background: 'none',
        border: 'none',
        padding: 0,
        ...style,
      }}
      type="button"
      onClick={() => {
        send('CLOSE');
      }}
    >
      {children}
    </button>
  );
};

export const PopoverClose = ({ children, ...props }: WrapperProps & HTMLProps<HTMLButtonElement>) => {
  const { triggerType } = usePopoverContext();

  return triggerType === 'click' ? <Close {...props}>{children}</Close> : null;
};

export interface PopoverOptions {
  forceOpen: boolean;
  defaultOpen: boolean;
  triggerRef: RefObject<HTMLButtonElement>;
  offset: number;
  triggerType: TriggerType;
  placement: Placement;
  autoPlacement: boolean;
}

/************************************
 * Provider
 ************************************/

interface MeasuredPopoverProps {
  children: (dimensions: DOMRect | null) => ReactElement;
}

const MeasuredPopover = ({ children }: MeasuredPopoverProps) => {
  const { triggerType } = usePopoverContext();
  const machine = triggerType === 'hover' ? HoverMachineContext : ClickMachineContext;
  const triggerDimensions = machine.useSelector(({ context }) => context.trigger.dimensions);

  return children(triggerDimensions);
};

type Children =
  | { children: (actor: PopoverService, useSelector: UsePopoverSelector) => ReactNode }
  | { children: ReactNode };

type PopoverProps = HTMLProps<HTMLDivElement> & Partial<PopoverOptions> & Children;

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      children,
      style,
      forceOpen = false,
      defaultOpen = false,
      placement = 'bottom',
      offset = 8,
      triggerType = 'hover',
      autoPlacement = true,
      ...props
    },
    forwardedRef,
  ) => (
    <PopoverContextProvider
      forceOpen={forceOpen}
      defaultOpen={defaultOpen}
      offset={offset}
      placement={placement}
      triggerType={triggerType}
      autoPlacement={autoPlacement}
    >
      {(actor, useSelector) => (
        <MeasuredPopover>
          {(triggerDimensions) => (
            <div
              ref={forwardedRef}
              {...props}
              style={{
                position: 'relative',
                alignSelf: 'center',
                zIndex: Z.Z_NUDGE - 2,
                opacity: triggerDimensions ? 1 : 0,
                top: placement === 'top' ? -offset : 0,
                width: triggerDimensions?.width,
                height: triggerDimensions?.height,
                ...style,
              }}
            >
              {typeof children === 'function' ? children(actor, useSelector) : children}
            </div>
          )}
        </MeasuredPopover>
      )}
    </PopoverContextProvider>
  ),
);
