import styled, { ComponentSelector } from '@emotion/styled';
import { CSSProperties } from 'react';
import {
  Input,
  Select,
  Collapse,
  Typography,
  Menu,
  CB_COLORS,
  AutoComplete,
  Dropdown,
} from '@commandbar/design-system/components/antd';
import { OverlaySettingsOption, StyledExperienceSelect } from '../components/styled';

export const ChecklistSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    height: 75px;
    display: block !important;
    overflow: auto;
    padding: 6px;
  }
  .ant-select-selection-placeholder {
    top: 16px !important;
  }
`;

export const DropdownOptionAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const OverlayContainer = styled(Menu)<{ width?: string; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ disabled }) => (disabled ? `${CB_COLORS.neutral0}` : `${CB_COLORS.white}`)};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '168px')};
  padding: 12px 0;
  gap: 12px;

  overflow: hidden;
  font-weight: 500;
`;

export const Divider = styled.div`
  background: ${CB_COLORS.neutral300};
  height: 1px;
`;

export const TitleRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  gap: 8px;
  align-items: center;
`;

export const DropDownOption = styled(Menu.Item)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px;
  height: 40px;
  gap: 8px;
  box-sizing: border-box;
  font-weight: 500;
  background-color: ${({ disabled }) => (!disabled ? 'transparent' : CB_COLORS['neutral0'])} !important;
  color: ${({ disabled }) => (!disabled ? CB_COLORS.neutral1000 : CB_COLORS.neutral600)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  transition: 0.3s all;

  &:hover {
    ${({ disabled }) => disabled && `color: ${CB_COLORS.neutral600};`}
    ${({ disabled }) => !disabled && `background-color: ${CB_COLORS.neutral100} !important;`}
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)`
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.34);
  overflow: hidden;

  & .ant-collapse-content {
    border-top: 1px solid ${CB_COLORS.neutral300};
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
`;

export const TypographyCode = styled(Typography.Text)`
  code {
    white-space: pre-wrap;
  
`;

export const MenuIconContainer = styled.div`
  padding: 4px;
  width: 24x;
  height: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    background-color: ${CB_COLORS.neutral100};
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
  color: ${CB_COLORS.blue1000};
`;

export const NudgeContentFormContainer = styled.div`
  margin-bottom: 16px;
`;

export const SectionContainer = styled.div`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  gap: 8px;
`;

export const InputContainer = styled.div<{ width?: CSSProperties['width'] }>`
  width: ${(props) => props?.width ?? '100%'};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${CB_COLORS.neutral800};
  user-select: none;
`;
export const StyledSubLabel = styled.label`
  font-weight: 500;
  line-height: 14px;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  color: #555d72;
`;

export const MediaBlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;
  background: #ffffff;
`;

export const StyledSelect = styled(Select)`
  border: 1px solid ${CB_COLORS.neutral400};
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);

  & .ant-select-selector {
    border: none !important;
    border-radius: 6px !important;
  }

  & .ant-select-selection-item {
    top: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  & .ant-select-selection-placeholder {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
` as unknown as typeof Select & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

export const PlainSelect = styled(Select)`
  & .ant-select-selector {
    box-shadow: none;
  }
` as unknown as typeof Select & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

export const MediaBlockInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 8px;
  gap: 16px;
  background: ${CB_COLORS.neutral0};
  border-radius: 8px;
`;

export const MediaBlockStyledInput = styled(Input)`
  height: 32px;
  padding: 0 9px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;

  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

export const MediaBlockStyledTextArea = styled(Input.TextArea)`
  padding: 8px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  resize: none;
  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
`;

export const DeleteIconContainer = styled.div`
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${CB_COLORS.neutral500};
  border-radius: 4px;

  transition: 0.3s all;

  &:hover {
    color: ${CB_COLORS.neutral1000};
    background-color: ${CB_COLORS.neutral0};
  }
`;

export const CombinationInput = styled.div<{ lightBorder?: boolean }>`
  width: 100%;
  display: flex;
  border: 1px solid ${(props) => (props.lightBorder ? CB_COLORS.neutral300 : CB_COLORS.neutral400)};
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
`;

export const DropdownLabelDetail = styled.span`
  margin-left: 4px;
`;

export const StyledExperienceAutoComplete = styled(AutoComplete)`
  border: none;
  border-radius: 0 6px 6px 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  overflow: hidden;

  & .ant-select-selector {
    border: none !important;
    border-radius: 0 6px 6px 0 !important;
  }

  & .ant-select-selection-item {
    top: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  & .ant-select-selection-search {
    right: 30px !important;
  }

  & .ant-select-selection-placeholder {
    position: relative;
    top: 1px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }
` as unknown as typeof AutoComplete & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

export const StyledExperienceSelectInline = styled(StyledExperienceSelect)`
  & .ant-select-selector {
    border-radius: 0 4px 4px 0 !important;
  }
` as unknown as typeof Select & ComponentSelector;

export const SuffixInput = styled(Input)`
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${CB_COLORS.white};
  border: none;
  border-left: 1px solid ${CB_COLORS.neutral300};
  border-radius: 0 6px 6px 0;
  box-shadow: none !important;

  &.ant-input,
  & .ant-input {
    top: 1px;
    height: 30px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 14px;
    color: ${CB_COLORS.neutral1000};
  }

  &.ant-input:placeholder-shown,
  & .ant-input:placeholder-shown {
    position: relative;
    top: 1px;
    height: 30px;
    font-size: 14px !important;
    line-height: 14px;
    font-weight: 500;
    color: ${CB_COLORS.neutral500};
  }

  &:hover,
  &:focus {
    border-left-color: ${CB_COLORS.neutral300} !important;
  }

  & .ant-input-affix-wrapper {
    border: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

export const PageTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3px;
`;

export const SlugInput = styled(Input.TextArea, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: 0;
  max-width: 200px !important;
  min-height: 0 !important;
  font-size: 16px;
  line-height: 16px !important;
  color: ${CB_COLORS.neutral1000};
  border: ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};

  &:focus,
  &:hover {
    border: 1px solid ${CB_COLORS.neutral500};
  }

  &.ant-input {
    transition: all 0.3s, height 0s, font-size 0s;
  }
`;

export const CurrentFormAction = styled.span`
  font-size: 10px;
  line-height: 12px;
  color: ${CB_COLORS.neutral600};
`;

export const SlugEditor = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const NudgeForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 10;
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownDisplay = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const OverlaySettingsOptionStyled = styled(OverlaySettingsOption)<{ selected: boolean }>`
  background: ${(props) => (props.selected ? CB_COLORS.neutral0 : 'auto')};
`;
