import React from 'react';
import { IUserContext } from '@commandbar/internal/middleware/types';
import { Typography } from '@commandbar/design-system/components/antd';

export enum CONTEXT_ERROR {
  SIZE = 'size_error',
  MISC = 'misc_error',
}

const isContextBig = (context: IUserContext) => {
  const MAX_CONTEXT_SIZE = 50000;
  return JSON.stringify(context).length > MAX_CONTEXT_SIZE;
};

export const parseContextForError = (context: IUserContext): CONTEXT_ERROR | null => {
  try {
    if (isContextBig(context)) {
      return CONTEXT_ERROR.SIZE;
    }
    return null;
  } catch (e) {
    // FIXME: Add parsing for circular JSON strings
    //        This can be dangerous though because circular parsing is slow
    //        and this can cripple a page for big deeply nested contexts
    return CONTEXT_ERROR.MISC;
  }
};

export const ContextErrorAlert = (props: { error: CONTEXT_ERROR | null }) => {
  const { error } = props;
  if (error === CONTEXT_ERROR.SIZE) {
    return (
      <div>
        <span>{'Metadata too large to render. Type '}</span>
        <Typography.Text code>window.CommandBar.shareContext()</Typography.Text>
        <span>{" in your browser's console to see the current metadata. "}</span>
      </div>
    );
  }

  if (error === CONTEXT_ERROR.MISC) {
    return (
      <div>
        <span>
          {
            "Metadata is unable to be rendered. It could be because it's too large or contains circular references. Type "
          }
        </span>
        <Typography.Text code>window.CommandBar.shareContext()</Typography.Text>
        <span>{" in your browser's console to see the current metadata. "}</span>
      </div>
    );
  }

  return null;
};
