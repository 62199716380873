export interface IDefaultShortcut {
  browser: string;
  os: string;
  shortcut: string;
  desc: string;
  std_desc: string;
}

const shortcuts: IDefaultShortcut[] = [
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: "'",
    desc: 'Quick Find within link-text only',
    std_desc: 'Quick Find within link-text only',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: "'",
    desc: 'Quick Find within link-text only',
    std_desc: 'Quick Find within link-text only',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: '/', desc: 'Quick Find', std_desc: 'Quick Find' },
  { browser: 'Firefox', os: 'Win', shortcut: '/', desc: 'Quick Find', std_desc: 'Quick Find' },
  { browser: 'Safari', os: 'Mac', shortcut: '←', desc: 'Scroll left', std_desc: 'Scroll left' },
  { browser: 'Safari', os: 'Mac', shortcut: '→', desc: 'Scroll right', std_desc: 'Scroll right' },
  { browser: 'Safari', os: 'Mac', shortcut: '↑', desc: 'Scroll up', std_desc: 'Scroll up' },
  { browser: 'Safari', os: 'Mac', shortcut: '↓', desc: 'Scroll down', std_desc: 'Scroll down' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+-',
    desc: 'Make everything on the page smaller',
    std_desc: 'Zoom out',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+-', desc: 'Zoom Out', std_desc: 'Zoom out' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+-', desc: 'Zoom out', std_desc: 'Zoom out' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+-', desc: 'Zoom out of webpage content', std_desc: 'Zoom out' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+-', desc: 'Zoom out', std_desc: 'Zoom out' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+,',
    desc: 'Open the Settings page in a new tab',
    std_desc: 'Open settings',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+,',
    desc: 'Open Preferences (Settings) page',
    std_desc: 'Open settings',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+,', desc: 'Open Preferences', std_desc: 'Open settings' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+.', desc: 'Stop', std_desc: 'Stop page load' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+.',
    desc: 'Stop webpage from loading',
    std_desc: 'Stop page load',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+[',
    desc: 'Open the previous page in your browsing history for the current tab',
    std_desc: 'Go back',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+[', desc: 'Back', std_desc: 'Go back' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+[', desc: 'Navigate backward', std_desc: 'Go back' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+[', desc: 'Go to previous page in history', std_desc: 'Go back' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+]',
    desc: 'Open the next page in your browsing history for the current tab',
    std_desc: 'Go forward',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+]', desc: 'Forward', std_desc: 'Go forward' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+]', desc: 'Navigate forward', std_desc: 'Go forward' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+]', desc: 'Go to next page in history', std_desc: 'Go forward' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+/', desc: 'Toggle status bar', std_desc: 'Toggle status bar' },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+`',
    desc: 'Cycle through open windows',
    std_desc: 'Cycle through open windows',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+←', desc: 'Back', std_desc: 'Go back' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+←', desc: 'Go back one page', std_desc: 'Go back' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+←',
    desc: 'Open the previous page in your browsing history for the current tab',
    std_desc: 'Go back',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+←', desc: 'Go to previous page in history', std_desc: 'Go back' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+→', desc: 'Forward', std_desc: 'Go forward' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+→', desc: 'Go forward one page', std_desc: 'Go forward' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+→',
    desc: 'Open the next page in your browsing history for the current tab',
    std_desc: 'Go forward',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+→', desc: 'Go to next page in history', std_desc: 'Go forward' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+↑',
    desc: 'Change the Default Search Engine',
    std_desc: 'Change the Default Search Engine',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+↑', desc: 'Go to Top of Page', std_desc: 'Jump to top of page' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+↑', desc: 'Go to top of page', std_desc: 'Jump to top of page' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+↑',
    desc: 'Scroll to top of webpage',
    std_desc: 'Jump to top of page',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+↓',
    desc: 'Go to Bottom of Page',
    std_desc: 'Jump to bottom of page',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+↓',
    desc: 'Go to bottom of page',
    std_desc: 'Jump to bottom of page',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+↓',
    desc: 'Scroll to bottom of webpage',
    std_desc: 'Jump to bottom of page',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+plus',
    desc: 'Make everything on the page bigger',
    std_desc: 'Zoom in',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+plus', desc: 'Zoom In', std_desc: 'Zoom in' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+plus', desc: 'Zoom in', std_desc: 'Zoom in' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+plus', desc: 'Zoom in on webpage content', std_desc: 'Zoom in' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+plus', desc: 'Zoom in', std_desc: 'Zoom in' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+0',
    desc: 'Return everything on the page to the default size',
    std_desc: 'Reset page zoom',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+0', desc: 'Zoom Reset', std_desc: 'Reset page zoom' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+0', desc: 'Reset zoom to 100%', std_desc: 'Reset page zoom' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+0',
    desc: 'Restore webpage content to default size',
    std_desc: 'Reset page zoom',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+0', desc: 'Reset zoom level', std_desc: 'Reset page zoom' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+9', desc: 'Go to Last Tab', std_desc: 'Go to last tab' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+9',
    desc: 'Jump to the rightmost tab',
    std_desc: 'Jump to rightmost tab',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+9',
    desc: 'Switch to last tab i.e. tab at end of tab bar',
    std_desc: 'Switch to last tab',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+9', desc: 'Switch to the last tab', std_desc: 'Switch to last tab' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+a', desc: 'Select All', std_desc: 'Select all' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+a', desc: 'Select all', std_desc: 'Select all' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+alt+c', desc: 'Inspector', std_desc: 'Inspector' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+alt+e', desc: 'Network', std_desc: 'Network' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+alt+i',
    desc: 'Toggle Developer Tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+alt+k',
    desc: 'Web Console',
    std_desc: 'Open JavaScript console',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+alt+m',
    desc: 'Responsive Design View',
    std_desc: 'Responsive Design View',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+b', desc: 'Bookmarks sidebar', std_desc: 'Open bookmarks bar' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+c', desc: 'Copy', std_desc: 'Copy' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+c', desc: 'Copy', std_desc: 'Copy' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+ctrl+1',
    desc: 'Toggle Bookmarks sidebar',
    std_desc: 'Open bookmarks bar',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+ctrl+2',
    desc: 'Toggle Reading List sidebar',
    std_desc: 'Toggle Reading List sidebar',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+ctrl+f',
    desc: 'Turn full-screen mode on or off',
    std_desc: 'Toggle full-screen',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+ctrl+f',
    desc: 'Turn full-screen mode on or off',
    std_desc: 'Toggle full-screen',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+ctrl+f',
    desc: 'Enter full-screen mode',
    std_desc: 'Toggle full-screen',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+ctrl+f',
    desc: 'Toggle Full Screen view',
    std_desc: 'Toggle full-screen',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+d',
    desc: 'Save your current webpage as a bookmark',
    std_desc: 'Bookmark tab',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+d', desc: 'Bookmark This Page', std_desc: 'Bookmark tab' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+d',
    desc: 'Save your current tab as a favorite',
    std_desc: 'Bookmark tab',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+d',
    desc: 'Save current webpage as bookmark',
    std_desc: 'Bookmark tab',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+e',
    desc: 'When Find Bar is open, search for selected text',
    std_desc: 'Find selected on page',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+e',
    desc: 'Find selected text on page',
    std_desc: 'Find selected on page',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+end',
    desc: 'Move the dotted rectangle to left, right, first or last tab.',
    std_desc: 'Move the dotted rectangle to left, right, first or last tab.',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+f',
    desc: 'Open the Find Bar to search the current page',
    std_desc: 'Find on page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+f', desc: 'Find in This Page', std_desc: 'Find on page' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+f', desc: 'Find on page', std_desc: 'Find on page' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+f', desc: 'Find on page', std_desc: 'Find on page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+f', desc: 'Find text on page', std_desc: 'Find on page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+f', desc: 'Find selected text on page', std_desc: 'Find on page' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+g',
    desc: 'Jump to the next match to your Find Bar search',
    std_desc: 'Find next on page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+g', desc: 'Find Again', std_desc: 'Find next on page' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+g',
    desc: 'Jump to the next match to your Find Bar search',
    std_desc: 'Find next on page',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+g', desc: 'Find next', std_desc: 'Find next on page' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+g',
    desc: 'Move forward through matching text results',
    std_desc: 'Find next on page',
  },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+h', desc: 'Hide Google Chrome', std_desc: 'Hide' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+home',
    desc: 'Move the dotted rectangle to left, right, first or last tab.',
    std_desc: 'Move the dotted rectangle to left, right, first or last tab.',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+i',
    desc: 'Email link to current page',
    std_desc: 'Email link to current page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+i', desc: 'Page Info', std_desc: 'Page Info' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+j', desc: 'Downloads', std_desc: 'Open downloads' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+j', desc: 'Open Downloads page', std_desc: 'Open downloads' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+k',
    desc: 'Focus Address bar for Web Search (writes "? " there)',
    std_desc: 'Focus address bar',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+k', desc: 'Focus Search bar', std_desc: 'Focus address bar' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+l',
    desc: 'Select address bar content',
    std_desc: 'Focus address bar',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+l', desc: 'Focus Address Bar', std_desc: 'Focus address bar' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+l', desc: 'Focus address bar', std_desc: 'Focus address bar' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+l',
    desc: 'Select the URL in the address bar to edit',
    std_desc: 'Focus address bar',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+l',
    desc: 'Jump to the address bar',
    std_desc: 'Focus address bar',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+m',
    desc: 'Minimize the current window',
    std_desc: 'Minimize window',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+m', desc: 'Minimize window', std_desc: 'Minimize window' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+m', desc: 'Minimize a window', std_desc: 'Minimize window' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+m', desc: 'Minimize current window', std_desc: 'Minimize window' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+n', desc: 'Open a new window', std_desc: 'New window' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+n', desc: 'New Window', std_desc: 'New window' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+n', desc: 'Open a new window', std_desc: 'New window' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+n', desc: 'Open new window', std_desc: 'New window' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+n', desc: 'Open new window', std_desc: 'New window' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+o', desc: 'Open File', std_desc: 'Open file' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+o',
    desc: 'Open a file from your computer in Edge',
    std_desc: 'Open file',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+o', desc: 'Open local file', std_desc: 'Open file' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+o', desc: 'Open file', std_desc: 'Open file' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+o',
    desc: 'Open a file from your computer in Google Chrome',
    std_desc: 'Open file',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+-',
    desc: 'Make webpage text smaller',
    std_desc: 'Zoom out',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+option+←',
    desc: 'Go one Tab to the Left',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+←',
    desc: 'Jump to the previous open tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+option+→',
    desc: 'Go one Tab to the Right',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+→',
    desc: 'Jump to the next open tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+↑',
    desc: 'Load previous Reading List item',
    std_desc: 'Load previous Reading List item',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+↓',
    desc: 'Load next Reading List item',
    std_desc: 'Load next Reading List item',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+plus',
    desc: 'Make webpage text bigger',
    std_desc: 'Zoom in',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+1,2..9',
    desc: 'Open first/second/… bookmark listed under Favorites',
    std_desc: 'Open first/second/… bookmark listed under Favorites',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+option+a', desc: 'Show Page Resources', std_desc: 'Page Info' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+b',
    desc: 'Open the Bookmark Manager',
    std_desc: 'Open the Bookmark Manager',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+b',
    desc: 'Show Bookmarks Editor',
    std_desc: 'Show Bookmarks Editor',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+c',
    desc: 'Show JavaScript Console',
    std_desc: 'Open JavaScript console',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+option+e', desc: 'Empty browser caches', std_desc: 'Empty cache' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+option+f',
    desc: 'Focus Search bar',
    std_desc: 'Focus address bar',
  },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+option+f', desc: 'Search the web', std_desc: 'Search the web' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+option+f', desc: 'Search the web', std_desc: 'Search the web' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+i',
    desc: 'Show Web Inspector',
    std_desc: 'Show Web Inspector',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+i',
    desc: 'Open Developer Tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+j',
    desc: 'Open the JavaScript Console',
    std_desc: 'Open JavaScript console',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+option+j',
    desc: 'Open the JavaScript Console',
    std_desc: 'Open JavaScript console',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+option+l',
    desc: 'Open Downloads in a new tab',
    std_desc: 'Open downloads',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+option+l', desc: 'Toggle Downloads', std_desc: 'Open downloads' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+option+m',
    desc: 'Minimize all windows of the front app',
    std_desc: 'Minimize all windows of the front app',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+p',
    desc: 'Open the Page Setup dialog',
    std_desc: 'Open the Page Setup dialog',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+r',
    desc: 'Reload webpage from origin i.e. without using browser cache',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+option+r',
    desc: 'Toggle Reader Mode',
    std_desc: 'Toggle reader mode',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+r',
    desc: 'Toggle Responsive Design mode',
    std_desc: 'Toggle Responsive Design mode',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+s',
    desc: 'Search results SnapBack',
    std_desc: 'Search results SnapBack',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+shift+i',
    desc: 'Connect Web Inspector',
    std_desc: 'Connect Web Inspector',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+shift+t',
    desc: 'Start/stop timeline recording',
    std_desc: 'Start/stop timeline recording',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+shift+w',
    desc: 'Close all windows',
    std_desc: 'Close all windows',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+t',
    desc: 'Open new tab at end of tab bar',
    std_desc: 'Open new tab at end of tab bar',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+option+u',
    desc: 'Display non-editable HTML source code for the current page',
    std_desc: 'View page source',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+u',
    desc: 'Show Page Source',
    std_desc: 'Show page sourrce',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+option+w',
    desc: 'Close all tabs except current tab',
    std_desc: 'Close all other tabs',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+p',
    desc: 'Open options to print the current page',
    std_desc: 'Print page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+p', desc: 'Print', std_desc: 'Print page' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+p', desc: 'Print the current tab', std_desc: 'Print page' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+p', desc: 'Print current page', std_desc: 'Print page' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+q', desc: 'Quit Google Chrome', std_desc: 'Quit' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+q', desc: 'Quit', std_desc: 'Quit' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+q', desc: 'Quit', std_desc: 'Quit' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+q', desc: 'Quit Opera', std_desc: 'Quit' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+q', desc: 'Quit Safari', std_desc: 'Quit' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+r', desc: 'Reload', std_desc: 'Refresh' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+r', desc: 'Reload the current tab', std_desc: 'Refresh' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+r', desc: 'Reload the current page', std_desc: 'Refresh' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+r', desc: 'Reload webpage', std_desc: 'Refresh' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+s',
    desc: 'Open options to save the current page',
    std_desc: 'Save page as',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+s', desc: 'Save Page As', std_desc: 'Save page as' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+s', desc: 'Save the current tab', std_desc: 'Save page as' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+s', desc: 'Save a page locally', std_desc: 'Save page as' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+[',
    desc: 'Switch to previous tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+]',
    desc: 'Switch to next tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+\\',
    desc: 'Toggle Tab Overview',
    std_desc: 'Toggle Tab Overview',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+\\',
    desc: 'Toggle Tab Overview',
    std_desc: 'Toggle Tab Overview',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+a', desc: 'Add-ons', std_desc: 'Add-ons' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+b',
    desc: 'Show or hide the Bookmarks Bar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+b',
    desc: 'Show/hide the Bookmarks toolbar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+b',
    desc: 'Show or hide the favorites bar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+b',
    desc: 'Toggle Favorites bar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+c',
    desc: 'Start/stop element selection',
    std_desc: 'Start/stop element selection',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+c',
    desc: 'Open Developer tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+d',
    desc: 'Bookmark All Tabs',
    std_desc: 'Bookmark all open tabs',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+d',
    desc: 'Save all open tabs as favorites in a new folder',
    std_desc: 'Bookmark all open tabs',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+d',
    desc: 'Add current webpage to Reading List',
    std_desc: 'Add current webpage to Reading List',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+d',
    desc: 'Save all open tabs as bookmarks in a new folder',
    std_desc: 'Save all open tabs as bookmarks in a new folder',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+delete',
    desc: 'Clear Recent History',
    std_desc: 'Clear recent history',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+delete',
    desc: 'Open clear browsing data options',
    std_desc: 'Open clear browsing data options',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+delete',
    desc: 'Open the Clear Browsing Data options',
    std_desc: 'Open the Clear Browsing Data options',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+shift+e',
    desc: 'Open Extensions page',
    std_desc: 'Open extensions',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+end',
    desc: 'Move Tab to end',
    std_desc: 'Move tab to end',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+f',
    desc: 'Toggle Full Screen',
    std_desc: 'Toggle full-screen mode',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+shift+g',
    desc: 'Find previous',
    std_desc: 'Find previous match on page',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+g',
    desc: 'Jump to the previous match to your Find Bar search',
    std_desc: 'Find previous match on page',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+g',
    desc: 'Find Previous',
    std_desc: 'Find previous match on page',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+g',
    desc: 'Jump to the previous match to your Find Bar search',
    std_desc: 'Find previous match on page',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+g',
    desc: 'Move backward through matching text results',
    std_desc: 'Find previous match on page',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+shift+h', desc: 'Open History page', std_desc: 'Open history' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+h',
    desc: 'Open your home page in the current tab',
    std_desc: 'Open home page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+h', desc: 'History sidebar', std_desc: 'Open history' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+h',
    desc: 'Open your home page in the current tab',
    std_desc: 'Open home page',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+shift+h', desc: 'Go to homepage', std_desc: 'Open home page' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+home',
    desc: 'Move Tab to start',
    std_desc: 'Move tab to start',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+j', desc: 'Browser Console', std_desc: 'Browser Console' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+j',
    desc: 'Open the Downloads page in a new tab',
    std_desc: 'Open the Downloads page in a new tab',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+shift+l', desc: 'Toggle sidebar', std_desc: 'Toggle sidebar' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+m',
    desc: 'Log in as a different user, browse as a Guest, or access payment and password info',
    std_desc: 'Login as a different user',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+m',
    desc: 'Log in a different user or browse as a guest',
    std_desc: 'Login as a different user',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+n',
    desc: 'Open a new window in Incognito mode',
    std_desc: 'Open new private window',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+n',
    desc: 'Open a new window in InPrivate mode',
    std_desc: 'Open new private window',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+shift+n',
    desc: 'Open new private window',
    std_desc: 'Open new private window',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+n',
    desc: 'Open new private window',
    std_desc: 'Open new private window',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+n',
    desc: 'Undo Close Window',
    std_desc: 'Undo close window',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+o',
    desc: 'Show All Bookmarks (Library Window)',
    std_desc: 'Open bookmarks',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+p',
    desc: 'New Private Window',
    std_desc: 'Open new private window',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+r',
    desc: 'Reload your current page, ignoring cached content',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+r',
    desc: 'Reload (override cache)',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+r',
    desc: 'Reload the current tab, ignoring cached content',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+r',
    desc: 'Toggle Reader view for current webpage',
    std_desc: 'Toggle Reader view for current webpage',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+return',
    desc: 'Open address bar URL in new foreground tab',
    std_desc: 'Open address bar URL in new foreground tab',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+return',
    desc: 'Open Focused Link in New Foreground Tab',
    std_desc: 'Open Focused Link in New Foreground Tab',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+return',
    desc: 'Open Search in New Background Tab',
    std_desc: 'Open Search in New Background Tab',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+s',
    desc: 'Take a screenshot',
    std_desc: 'Take a screenshot',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+t', desc: 'Undo Close Tab', std_desc: 'Undo close tab' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+t',
    desc: 'Reopen the last closed tab, and switch to it',
    std_desc: 'Undo close tab',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+shift+t',
    desc: 'Reopen last closed tab',
    std_desc: 'Undo close tab',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'command+shift+t',
    desc: 'Reopen last closed tab/window',
    std_desc: 'Undo close tab',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+t',
    desc: 'Reopen previously closed tabs in the order they were closed',
    std_desc: 'Reopen previously closed tabs in the order they were closed',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+u',
    desc: 'Start or stop Read Aloud',
    std_desc: 'Start or stop Read Aloud',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+shift+v',
    desc: 'Paste (as plain text)',
    std_desc: 'Paste as plaintext',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+v',
    desc: 'Paste format free text',
    std_desc: 'Paste as plaintext',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+shift+w',
    desc: 'Close the current window',
    std_desc: 'Close window',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+w', desc: 'Close Window', std_desc: 'Close window' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'command+shift+w',
    desc: 'Close the current window',
    std_desc: 'Close window',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+shift+w', desc: 'Close current window', std_desc: 'Close window' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+shift+z', desc: 'Redo', std_desc: 'Redo' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+shift+z', desc: 'Redo', std_desc: 'Redo' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'command+space',
    desc: 'Select or deselect the tab with the dotted rectangle. The other tabs stay selected or deselected.',
    std_desc: 'Select or deselect the tab with the dotted rectangle. The other tabs stay selected or deselected.',
  },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+t', desc: 'Open a new tab, and jump to it', std_desc: 'New tab' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+t', desc: 'New Tab', std_desc: 'New tab' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+t', desc: 'Open a new tab, and switch to it', std_desc: 'New tab' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+t', desc: 'Open new tab', std_desc: 'New tab' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+t', desc: 'Open new tab', std_desc: 'New tab' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+u', desc: 'Page Source', std_desc: 'View page source' },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'command+u',
    desc: 'View current page source',
    std_desc: 'View page source',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+v', desc: 'Paste', std_desc: 'paste' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+v', desc: 'Paste', std_desc: 'paste' },
  { browser: 'Chrome', os: 'Mac', shortcut: 'command+w', desc: 'Close the current tab', std_desc: 'Close tab' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+w', desc: 'Close Tab', std_desc: 'Close tab' },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+w', desc: 'Close the current tab', std_desc: 'Close tab' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+w', desc: 'Close current tab', std_desc: 'Close tab' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+w', desc: 'Close current tab', std_desc: 'Close tab' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+x', desc: 'Cut', std_desc: 'Cut' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+x', desc: 'Cut', std_desc: 'Cut' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'command+y',
    desc: 'Open the History page in a new tab',
    std_desc: 'Open history',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'command+y', desc: 'Open History in a new tab', std_desc: 'Open history' },
  { browser: 'Safari', os: 'Mac', shortcut: 'command+y', desc: 'Show browser history', std_desc: 'Open history' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'command+z', desc: 'Undo', std_desc: 'Undo' },
  { browser: 'Opera', os: 'Mac', shortcut: 'command+z', desc: 'Undo', std_desc: 'Undo' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt',
    desc: 'Set focus on the first item in the favorites bar',
    std_desc: 'Set focus on the first item in the favorites bar',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt or f10',
    desc: 'Toggle Menu Bar activation (showing it temporarily when hidden)',
    std_desc: 'Toggle Menu Bar activation (showing it temporarily when hidden)',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'alt+←', desc: 'Back', std_desc: 'Go back' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+←',
    desc: 'Open the previous page in your browsing history for the current tab',
    std_desc: 'Go back',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'alt+←', desc: 'Navigate backward', std_desc: 'Go back' },
  { browser: 'Opera', os: 'Win', shortcut: 'alt+←', desc: 'Go back one page', std_desc: 'Go back' },
  { browser: 'Firefox', os: 'Win', shortcut: 'alt+→', desc: 'Forward', std_desc: 'Go forward' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+→',
    desc: 'Open the next page in your browsing history for the current tab',
    std_desc: 'Go forward',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'alt+→', desc: 'Navigate forward', std_desc: 'Go forward' },
  { browser: 'Opera', os: 'Win', shortcut: 'alt+→', desc: 'Go forward one page', std_desc: 'Go forward' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+↑',
    desc: 'Switch Search Engine',
    std_desc: 'Switch Search Engine',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+↓',
    desc: 'Switch Search Engine',
    std_desc: 'Switch Search Engine',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+↓',
    desc: 'View menu to switch, add or manage search engines',
    std_desc: 'View menu to switch, add or manage search engines',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'alt+d', desc: 'Focus Address Bar', std_desc: 'Focus address bar' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+d',
    desc: 'Jump to the address bar',
    std_desc: 'Focus address bar',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+d',
    desc: 'Select the URL in the address bar to edit',
    std_desc: 'Select the URL in the address bar to edit',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+e',
    desc: 'Open the Settings and more "..." menu',
    std_desc: 'Open settings',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+e',
    desc: 'Open the Chrome menu',
    std_desc: 'Open the Chrome menu',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+enter',
    desc: 'Open Address or Search in New Foreground Tab',
    std_desc: 'Open Address or Search in New Foreground Tab',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+enter',
    desc: 'Save Focused Link',
    std_desc: 'Save Focused Link',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+enter',
    desc: 'Save Focused Link',
    std_desc: 'Save Focused Link',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+f',
    desc: 'Open the Settings and more "..." menu',
    std_desc: 'Open settings',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+f',
    desc: 'Open the Chrome menu',
    std_desc: 'Open the Chrome menu',
  },
  { browser: 'Chrome', os: 'Win', shortcut: 'alt+f then x', desc: 'Quit Google Chrome', std_desc: 'Quit' },
  { browser: 'Chrome', os: 'Win', shortcut: 'alt+f4', desc: 'Close the current window', std_desc: 'Close window' },
  { browser: 'Firefox', os: 'Win', shortcut: 'alt+f4', desc: 'Close Window', std_desc: 'Close window' },
  { browser: 'Edge', os: 'Win', shortcut: 'alt+f4', desc: 'Close the current window', std_desc: 'Close window' },
  { browser: 'Opera', os: 'Win', shortcut: 'alt+f4', desc: 'Close current window', std_desc: 'Close window' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+home',
    desc: 'Open your home page in the current tab',
    std_desc: 'Open homepage',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+home',
    desc: 'Open your home page in the current tab',
    std_desc: 'Open homepage',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'alt+home', desc: 'Home', std_desc: 'Open homepage' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+home',
    desc: 'Open your home page in the current tab',
    std_desc: 'Open homepage',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'alt+p',
    desc: 'Open Preferences (Settings) page',
    std_desc: 'Open settings',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+shift+b',
    desc: 'Set focus on the first item in the favorites bar',
    std_desc: 'Set focus on the first item in the favorites bar',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'alt+shift+enter',
    desc: 'Open Address or Search in New Background Tab',
    std_desc: 'Open Address or Search in New Background Tab',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+shift+i',
    desc: 'Open a feedback form',
    std_desc: 'Open a feedback form',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+shift+i',
    desc: 'Open the Send feedback dialog',
    std_desc: 'Open the Send feedback dialog',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'alt+shift+t',
    desc: 'Set focus on the first item in the toolbar',
    std_desc: 'Set focus on the first item in the toolbar',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+space then n',
    desc: 'Minimize the current window',
    std_desc: 'Minimize window',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'alt+space then x',
    desc: 'Maximize the current window',
    std_desc: 'Maximize window',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'alt+tab',
    desc: 'Cycle through open windows',
    std_desc: 'Cycle through open windows',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'arrow keys',
    desc: 'Select left, right, first or last tab. Deselect all other tabs.',
    std_desc: 'Select left, right, first or last tab. Deselect all other tabs.',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+-',
    desc: 'Make everything on the page smaller',
    std_desc: 'Zoom out',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+-', desc: 'Zoom Out', std_desc: 'Zoom out' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+-', desc: 'Zoom out', std_desc: 'Zoom out' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+-', desc: 'Zoom out', std_desc: 'Zoom out' },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'ctrl+`',
    desc: 'View previous active tab',
    std_desc: 'View previous active tab',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+`',
    desc: 'View previous active tab',
    std_desc: 'View previous active tab',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+↑', desc: 'Go to Top of Page', std_desc: 'Jump to top of page' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+↓',
    desc: 'Go to Bottom of Page',
    std_desc: 'Jump to bottom of page',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+↓',
    desc: 'Change the Default Search Engine',
    std_desc: 'Change the Default Search Engine',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+plus',
    desc: 'Make everything on the page bigger',
    std_desc: 'Zoom in',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+plus', desc: 'Zoom In', std_desc: 'Zoom in' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+plus', desc: 'Zoom in', std_desc: 'Zoom in' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+plus', desc: 'Zoom in', std_desc: 'Zoom in' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+0',
    desc: 'Return everything on the page to the default size',
    std_desc: 'Reset page zoom',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+0', desc: 'Zoom Reset', std_desc: 'Reset page zoom' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+0', desc: 'Reset zoom to 100%', std_desc: 'Reset page zoom' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+0', desc: 'Reset zoom level', std_desc: 'Reset page zoom' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+1', desc: 'Jump to tab 1', std_desc: 'Jump to tab 1' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+2', desc: 'Jump to tab 2', std_desc: 'Jump to tab 2' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+3', desc: 'Jump to tab 3', std_desc: 'Jump to tab 3' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+4', desc: 'Jump to tab 4', std_desc: 'Jump to tab 4' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+5', desc: 'Jump to tab 5', std_desc: 'Jump to tab 5' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+6', desc: 'Jump to tab 6', std_desc: 'Jump to tab 6' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+7', desc: 'Jump to tab 7', std_desc: 'Jump to tab 7' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+8', desc: 'Jump to tab 8', std_desc: 'Jump to tab 8' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+9', desc: 'Go to Last Tab', std_desc: 'Go to last tab' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+9',
    desc: 'Jump to the rightmost tab',
    std_desc: 'Jump to rightmost tab',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+9',
    desc: 'Switch to the last tab',
    std_desc: 'Switch to last tab',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+a', desc: 'Select All', std_desc: 'Select all' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+a', desc: 'Select all', std_desc: 'Select all' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+arrow keys',
    desc: 'Move the dotted rectangle to left, right, first or last tab.',
    std_desc: 'Move the dotted rectangle to left, right, first or last tab.',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+b', desc: 'Bookmarks sidebar', std_desc: 'Bookmarks sidebar' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+c', desc: 'Copy', std_desc: 'Copy' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+c', desc: 'Copy', std_desc: 'Copy' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+c', desc: 'Copy', std_desc: 'Copy' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+d',
    desc: 'Save your current webpage as a bookmark',
    std_desc: 'Bookmark tab',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+d', desc: 'Bookmark This Page', std_desc: 'Bookmark tab' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+d',
    desc: 'Save your current tab as a favorite',
    std_desc: 'Bookmark tab',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+e', desc: 'Focus Search bar', std_desc: 'Focus address bar' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+e',
    desc: 'Search from anywhere on the page',
    std_desc: 'Search from anywhere on the page',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+e', desc: 'Search the web', std_desc: 'Search the web' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+end',
    desc: 'Move the dotted rectangle to left, right, first or last tab.',
    std_desc: 'Move the dotted rectangle to left, right, first or last tab.',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+enter',
    desc: 'Add www. to the beginning and .com to the end of text typed in the address bar',
    std_desc: 'Add www. to the beginning and .com to the end of text typed in the address bar',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+enter',
    desc: 'Open Focused Link in New Background Tab',
    std_desc: 'Open Focused Link in New Background Tab',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+enter',
    desc: 'Open Search in New Background Tab',
    std_desc: 'Open Search in New Background Tab',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+f', desc: 'Find in This Page', std_desc: 'Find on page' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+f', desc: 'Find on page', std_desc: 'Find on page' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+f', desc: 'Find on page', std_desc: 'Find on page' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+f',
    desc: 'Open the Find Bar to search the current page',
    std_desc: 'Open the Find Bar to search the current page',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'ctrl+f2',
    desc: 'Jump to the main menu bar',
    std_desc: 'Jump to the main menu bar',
  },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+f4', desc: 'Close the current tab', std_desc: 'Close tab' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+f4', desc: 'Close Tab', std_desc: 'Close tab' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+f4',
    desc: 'Close the current page in the current tab',
    std_desc: 'Close tab',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'ctrl+f5',
    desc: 'Move cursor to the address bar',
    std_desc: 'Focus address bar',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+f5',
    desc: 'Move cursor to the address bar',
    std_desc: 'Focus address bar',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+f5', desc: 'Reload (override cache)', std_desc: 'Hard refresh' },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'ctrl+fn+↑',
    desc: 'Switch to the previous tab',
    std_desc: 'Go one tab to the left',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'ctrl+fn+↓', desc: 'Switch to the next tab', std_desc: 'Switch to last tab' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+g', desc: 'Find Again', std_desc: 'Find next on page' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+g',
    desc: 'Jump to the next match to your Find Bar search',
    std_desc: 'Find next on page',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+g', desc: 'Find next', std_desc: 'Find next on page' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+g',
    desc: 'Jump to the next match to your Find Bar search',
    std_desc: 'Find next match on page',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+h',
    desc: 'Open the History page in a new tab',
    std_desc: 'Open history',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+h', desc: 'History sidebar', std_desc: 'Open history' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+h', desc: 'Open History in a new tab', std_desc: 'Open history' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+h', desc: 'Open History page', std_desc: 'Open history' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+home',
    desc: 'Move the dotted rectangle to left, right, first or last tab.',
    std_desc: 'Move the dotted rectangle to left, right, first or last tab.',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+i', desc: 'Page Info', std_desc: 'Page Info' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+j',
    desc: 'Open the Downloads page in a new tab',
    std_desc: 'Open downloads',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+j', desc: 'Downloads', std_desc: 'Open downloads' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+j',
    desc: 'Open Downloads in a new tab',
    std_desc: 'Open downloads',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+j', desc: 'Open Downloads page', std_desc: 'Open downloads' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+k', desc: 'Focus Search bar', std_desc: 'Focus address bar' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+k',
    desc: 'Search from anywhere on the page',
    std_desc: 'Search from anywhere on the page',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+k', desc: 'Search the web', std_desc: 'Search the web' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+l', desc: 'Focus Address Bar', std_desc: 'Focus address bar' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+l', desc: 'Focus address bar', std_desc: 'Focus address bar' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+l',
    desc: 'Jump to the address bar',
    std_desc: 'Focus address bar',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+l',
    desc: 'Select the URL in the address bar to edit',
    std_desc: 'Select the URL in the address bar to edit',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'ctrl+m', desc: 'Toggle tab menu', std_desc: 'Toggle tab menu' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+m', desc: 'Toggle tab menu', std_desc: 'Toggle tab menu' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'ctrl+m', desc: 'Mute/Unmute Audio', std_desc: 'Toggle mute' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+m', desc: 'Mute/Unmute Audio', std_desc: 'Toggle mute' },
  { browser: 'Edge', os: 'Mac', shortcut: 'ctrl+m', desc: 'Toggle tab mute on and off', std_desc: 'Toggle mute' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+m', desc: 'Toggle tab mute on and off', std_desc: 'Toggle mute' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+n', desc: 'Open a new window', std_desc: 'New window' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+n', desc: 'New Window', std_desc: 'New window' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+n', desc: 'Open a new window', std_desc: 'New window' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+n', desc: 'Open new window', std_desc: 'New window' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+o', desc: 'Open File', std_desc: 'Open file' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+o',
    desc: 'Open a file from your computer in Edge',
    std_desc: 'Open file',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+o', desc: 'Open local file', std_desc: 'Open file' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+o',
    desc: 'Open a file from your computer in Google Chrome',
    std_desc: 'Open file',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+p',
    desc: 'Open options to print the current page',
    std_desc: 'Print page',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+p', desc: 'Print', std_desc: 'Print page' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+p', desc: 'Print the current tab', std_desc: 'Print page' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+p', desc: 'Print current page', std_desc: 'Print page' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+page down',
    desc: 'Go one Tab to the Right',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+page down',
    desc: 'Go one Tab to the Right',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+page up',
    desc: 'Go one Tab to the Left',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+page up',
    desc: 'Go one Tab to the Left',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+pgdn',
    desc: 'Jump to the next open tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+pgdn',
    desc: 'Switch to the next tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+pgup',
    desc: 'Jump to the previous open tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+pgup',
    desc: 'Switch to the previous tab',
    std_desc: 'Go one tab to the left',
  },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+r', desc: 'Reload the current page', std_desc: 'Refresh' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+r', desc: 'Reload', std_desc: 'Refresh' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+r', desc: 'Reload the current tab', std_desc: 'Refresh' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+r', desc: 'Reload the current page', std_desc: 'Refresh' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+s',
    desc: 'Open options to save the current page',
    std_desc: 'Save page as',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+s', desc: 'Save Page As', std_desc: 'Save page as' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+s', desc: 'Save the current tab', std_desc: 'Save page as' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+s', desc: 'Save a page locally', std_desc: 'Save page as' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+a', desc: 'Add-ons', std_desc: 'Add-ons' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+b',
    desc: 'Show or hide the Bookmarks Bar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+b',
    desc: 'Show/hide the Bookmarks toolbar',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+b',
    desc: 'Show or hide the favorites bar',
    std_desc: 'Toggle bookmarks bar',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+c', desc: 'Inspector', std_desc: 'Inspector' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+d',
    desc: 'Save all open tabs as bookmarks in a new folder',
    std_desc: 'Bookmark all open tabs',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+d',
    desc: 'Bookmark All Tabs',
    std_desc: 'Bookmark all open tabs',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+d',
    desc: 'Save all open tabs as favorites in a new folder',
    std_desc: 'Bookmark all open tabs',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+del',
    desc: 'Clear Recent History',
    std_desc: 'Clear recent history',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+delete',
    desc: 'Open clear browsing data options',
    std_desc: 'Open clear browsing data options',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+delete',
    desc: 'Open the Clear Browsing Data options',
    std_desc: 'Open the Clear Browsing Data options',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+shift+e',
    desc: 'Open Extensions page',
    std_desc: 'Open extensions',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+e', desc: 'Network', std_desc: 'Network' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+end',
    desc: 'Move Tab to end',
    std_desc: 'Move tab to end',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+enter',
    desc: 'Open Focused Link in New Foreground Tab',
    std_desc: 'Open Focused Link in New Foreground Tab',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+enter',
    desc: 'Open Search in New Foreground Tab',
    std_desc: 'Open Search in New Foreground Tab',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+g',
    desc: 'Jump to the previous match to your Find Bar search',
    std_desc: 'Find previous on page',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+g',
    desc: 'Find Previous',
    std_desc: 'Find previous on page',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+g',
    desc: 'Jump to the previous match to your Find Bar search',
    std_desc: 'Find previous on page',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+shift+g',
    desc: 'Find previous',
    std_desc: 'Find previous on page',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+h',
    desc: 'Library window (History)',
    std_desc: 'Library window (History)',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+home',
    desc: 'Move Tab to start',
    std_desc: 'Move tab to start',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+i',
    desc: 'Open Developer tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+i',
    desc: 'Toggle Developer Tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+j',
    desc: 'Open Developer Tools',
    std_desc: 'Open developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+j',
    desc: 'Browser Console',
    std_desc: 'Open JavaScript console',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+k',
    desc: 'Duplicate the current tab',
    std_desc: 'Duplicate current tab',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+k',
    desc: 'Web Console',
    std_desc: 'Open JavaScript console',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+l',
    desc: "Paste and search or Paste and go (if it's a URL)",
    std_desc: "Paste and search or Paste and go (if it's a URL)",
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+m',
    desc: 'Log in as a different user, browse as a Guest, or access payment and password info',
    std_desc: 'Login as different user',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+m',
    desc: 'Log in a different user or browse as a guest',
    std_desc: 'Login as different user',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+m',
    desc: 'Responsive Design View',
    std_desc: 'Responsive Design View',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+n',
    desc: 'Open a new window in Incognito mode',
    std_desc: 'New private window',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+n',
    desc: 'Open a new window in InPrivate mode',
    std_desc: 'New private window',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+shift+n',
    desc: 'Open new private window',
    std_desc: 'New private window',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+n',
    desc: 'Undo Close Window',
    std_desc: 'Undo close window',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+o',
    desc: 'Open the Bookmark Manager',
    std_desc: 'Toggle bookmarks bar',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+o',
    desc: 'Show All Bookmarks (Library Window)',
    std_desc: 'Open bookmarks',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+o',
    desc: 'Open Favorites management',
    std_desc: 'Open Favorites management',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+p',
    desc: 'New Private Window',
    std_desc: 'New private window',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+p',
    desc: 'Print using the system dialog',
    std_desc: 'Print using the system dialog',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+shift+page down',
    desc: 'Move Tab Right',
    std_desc: 'Move tab right',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+page down',
    desc: 'Move Tab Right',
    std_desc: 'Move tab right',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'ctrl+shift+page up', desc: 'Move Tab Left', std_desc: 'Move tab left' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+page up',
    desc: 'Move Tab Left',
    std_desc: 'Move tab left',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+q', desc: 'Quit', std_desc: 'Quit' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+r',
    desc: 'Reload your current page, ignoring cached content',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+r',
    desc: 'Reload (override cache)',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+r',
    desc: 'Reload the current tab, ignoring cached content',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+s',
    desc: 'Take a screenshot',
    std_desc: 'Take a screenshot',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+t',
    desc: 'Reopen previously closed tabs in the order they were closed',
    std_desc: 'Reopen last closed tab',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+t',
    desc: 'Undo Close Tab',
    std_desc: 'Reopen last closed tab',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+t',
    desc: 'Reopen the last closed tab, and switch to it',
    std_desc: 'Reopen last closed tab',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+shift+t',
    desc: 'Reopen last closed tab',
    std_desc: 'Reopen last closed tab',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+shift+tab',
    desc: 'Cycle back through open tabs',
    std_desc: 'Cycle back through open tabs',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'ctrl+shift+tab',
    desc: 'Cycle back through open tabs',
    std_desc: 'Cycle back through open tabs',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+tab',
    desc: 'Jump to the previous open tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+shift+tab',
    desc: 'Go one Tab to the Left',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'ctrl+shift+tab',
    desc: 'Go to the previous tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+tab',
    desc: 'Switch to the previous tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+tab',
    desc: 'Go one Tab to the Left',
    std_desc: 'Go one tab to the left',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'ctrl+shift+tab', desc: 'Show All Tabs', std_desc: 'Show All Tabs' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+tab', desc: 'Show All Tabs', std_desc: 'Show All Tabs' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'ctrl+shift+tab',
    desc: 'Switch to previous tab',
    std_desc: 'Go one tab to the left',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+u',
    desc: 'Start or stop Read Aloud',
    std_desc: 'Start or stop Read Aloud',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+shift+v',
    desc: 'Paste (as plain text)',
    std_desc: 'Paste as plaintext',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+v',
    desc: 'Paste without formatting',
    std_desc: 'Paste as plaintext',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+shift+w',
    desc: 'Close the current window',
    std_desc: 'Close window',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+w', desc: 'Close Window', std_desc: 'Close window' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+shift+w',
    desc: 'Close the current window',
    std_desc: 'Close window',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+shift+x', desc: 'Quit Opera', std_desc: 'Quit' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+shift+y', desc: 'Open Collections', std_desc: 'Open Collections' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+shift+z', desc: 'Redo', std_desc: 'Redo' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+shift+z', desc: 'Redo', std_desc: 'Redo' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+space',
    desc: 'Select or deselect the tab with the dotted rectangle. The other tabs stay selected or deselected.',
    std_desc: 'Select or deselect the tab with the dotted rectangle. The other tabs stay selected or deselected.',
  },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+t', desc: 'Open a new tab, and jump to it', std_desc: 'New tab' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+t', desc: 'New Tab', std_desc: 'New tab' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+t', desc: 'Open a new tab, and switch to it', std_desc: 'New tab' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+t', desc: 'Open new tab', std_desc: 'New tab' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+tab',
    desc: 'Cycle through Tabs in Recently Used Order',
    std_desc: 'Cycle thrrough tabs in recently used order',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+tab',
    desc: 'Cycle through Tabs in Recently Used Order',
    std_desc: 'Cycle thrrough tabs in recently used order',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+tab',
    desc: 'Jump to the next open tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'ctrl+tab',
    desc: 'Go one Tab to the Right',
    std_desc: 'Go one tab to the right',
  },
  { browser: 'Edge', os: 'Mac', shortcut: 'ctrl+tab', desc: 'Go to the next tab', std_desc: 'Go one tab to the right' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'ctrl+tab',
    desc: 'Switch to the next tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'ctrl+tab',
    desc: 'Cycle forward through open tabs',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+tab',
    desc: 'Cycle forward through open tabs',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'ctrl+tab',
    desc: 'Switch to next tab',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'ctrl+tab',
    desc: 'Go one Tab to the Right',
    std_desc: 'Go one tab to the right',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'ctrl+u',
    desc: 'Display non-editable HTML source code for the current page',
    std_desc: 'View page source',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+u', desc: 'Page Source', std_desc: 'View page source' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+u', desc: 'View source', std_desc: 'View page source' },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'ctrl+u',
    desc: 'View current page source',
    std_desc: 'View page source',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+v', desc: 'Paste', std_desc: 'Paste' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+v', desc: 'Paste', std_desc: 'Paste' },
  { browser: 'Chrome', os: 'Win', shortcut: 'ctrl+w', desc: 'Close the current tab', std_desc: 'Close tab' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+w', desc: 'Close Tab', std_desc: 'Close tab' },
  { browser: 'Edge', os: 'Win', shortcut: 'ctrl+w', desc: 'Close the current tab', std_desc: 'Close tab' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+w', desc: 'Close current tab', std_desc: 'Close tab' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+x', desc: 'Cut', std_desc: 'Cut' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+x', desc: 'Cut', std_desc: 'Cut' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+y', desc: 'Redo', std_desc: 'Redo' },
  { browser: 'Firefox', os: 'Win', shortcut: 'ctrl+z', desc: 'Undo', std_desc: 'Undo' },
  { browser: 'Opera', os: 'Win', shortcut: 'ctrl+z', desc: 'Undo', std_desc: 'Undo' },
  { browser: 'Firefox', os: 'Win', shortcut: 'cut', desc: 'Cut', std_desc: 'Cut' },
  { browser: 'Firefox', os: 'Win', shortcut: 'del', desc: 'Delete', std_desc: 'Delete' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'delete', desc: 'Delete', std_desc: 'Delete' },
  { browser: 'Firefox', os: 'Win', shortcut: 'delete', desc: 'Delete', std_desc: 'Delete' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'delete',
    desc: 'Deleted selected bookmark/folder',
    std_desc: 'Deleted selected bookmark/folder',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'end', desc: 'Go to Bottom of Page', std_desc: 'Jump to page bottom' },
  { browser: 'Firefox', os: 'Win', shortcut: 'end', desc: 'Go to Bottom of Page', std_desc: 'Jump to page bottom' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'end',
    desc: 'Go to the bottom of the page',
    std_desc: 'Jump to bottom pf page',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'end', desc: 'End', std_desc: 'Jump to bottom pf page' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'end',
    desc: 'Go to the bottom of the page, Move keyboard focus to last item of pane',
    std_desc: 'Jump to bottom pf page',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'end',
    desc: 'Go to bottom of page',
    std_desc: 'Jump to bottom pf page',
  },
  { browser: 'Chrome', os: 'Mac', shortcut: 'esc', desc: 'Stop the page loading', std_desc: 'Stop page load' },
  { browser: 'Chrome', os: 'Win', shortcut: 'esc', desc: 'Stop the page loading', std_desc: 'Stop page load' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'esc', desc: 'Stop', std_desc: 'Stop page load' },
  { browser: 'Firefox', os: 'Win', shortcut: 'esc', desc: 'Stop', std_desc: 'Stop page load' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f1',
    desc: 'Open the Chrome Help Center in a new tab',
    std_desc: 'Open help',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'f1', desc: 'Open Help', std_desc: 'Open help' },
  { browser: 'Opera', os: 'Win', shortcut: 'f1', desc: 'Open help', std_desc: 'Open help' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f10',
    desc: 'Set focus on the rightmost item in the Chrome toolbar',
    std_desc: 'Set focus on the rightmost item in the Chrome toolbar',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f10',
    desc: 'Set focus on the Settings and more "…" button',
    std_desc: 'Set focus on the Settings and more "…" button',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f10+enter',
    desc: 'Open the Settings and more "..." menu',
    std_desc: 'Open settings',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f11',
    desc: 'Turn full-screen mode on or off',
    std_desc: 'Toggle full-screen mode',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'f11',
    desc: 'Toggle Full Screen',
    std_desc: 'Toggle full-screen mode',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f11',
    desc: 'Turn full-screen mode on or off',
    std_desc: 'Toggle full-screen mode',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'f11',
    desc: 'Enter full-screen mode',
    std_desc: 'Toggle full-screen mode',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f12',
    desc: 'Open Developer Tools',
    std_desc: 'Toggle developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'f12',
    desc: 'Toggle Developer Tools',
    std_desc: 'Toggle developer tools',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'f12',
    desc: 'Toggle Developer Tools',
    std_desc: 'Toggle developer tools',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'f12',
    desc: 'Open Developer Tools (toggle)',
    std_desc: 'Toggle developer tools',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'f12', desc: 'Open Developer tools', std_desc: 'Toggle developer tools' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'f3', desc: 'Find Again', std_desc: 'Find next on page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'f3', desc: 'Find Again', std_desc: 'Find next on page' },
  { browser: 'Edge', os: 'Win', shortcut: 'f3', desc: 'Find on page', std_desc: 'Find on page' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f3',
    desc: 'Open the Find Bar to search the current page',
    std_desc: 'Open the Find Bar to search the current page',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'f4', desc: 'Search the web', std_desc: 'Search the web' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'f4',
    desc: 'View menu to switch, add or manage search engines',
    std_desc: 'View menu to switch, add or manage search engines',
  },
  { browser: 'Chrome', os: 'Win', shortcut: 'f5', desc: 'Reload the current page', std_desc: 'Refresh' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'f5', desc: 'Reload', std_desc: 'Refresh' },
  { browser: 'Firefox', os: 'Win', shortcut: 'f5', desc: 'Reload', std_desc: 'Refresh' },
  { browser: 'Edge', os: 'Win', shortcut: 'f5', desc: 'Reload the current tab', std_desc: 'Refresh' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'f6', desc: 'Focus Address Bar', std_desc: 'Focus address bar' },
  { browser: 'Firefox', os: 'Win', shortcut: 'f6', desc: 'Focus Address Bar', std_desc: 'Focus address bar' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'f6',
    desc: 'Move to Next Frame, Pop-Up',
    std_desc: 'Move to Next Frame, Pop-Up',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'f6',
    desc: 'Move to Next Frame, Pop-Up',
    std_desc: 'Move to Next Frame, Pop-Up',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f6',
    desc: 'Switch focus to next pane',
    std_desc: 'Switch focus to next pane',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f6',
    desc: 'Switch focus to unfocused dialog (if showing) and all toolbars',
    std_desc: 'Switch focus to unfocused dialog (if showing) and all toolbars',
  },
  { browser: 'Chrome', os: 'Mac', shortcut: 'f7', desc: 'Turn on caret browsing', std_desc: 'Toggle caret browsing' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'f7',
    desc: 'Turn on caret browsing',
    std_desc: 'Toggle caret browsing',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'f7', desc: 'Caret Browsing', std_desc: 'Toggle caret browsing' },
  { browser: 'Firefox', os: 'Win', shortcut: 'f7', desc: 'Caret Browsing', std_desc: 'Toggle caret browsing' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f7',
    desc: 'Turn caret browsing on or off',
    std_desc: 'Toggle caret browsing',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'f9',
    desc: 'Enter or exit Immersive Reader',
    std_desc: 'Enter or exit Immersive Reader',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'f9',
    desc: 'Enter or exit Immersive Reader',
    std_desc: 'Enter or exit Immersive Reader',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'f9', desc: 'Toggle Reader Mode', std_desc: 'Toggle reader mode' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'fn+→',
    desc: 'Scroll to bottom of webpage',
    std_desc: 'Jump to bottom of page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'fn+↑', desc: 'Go Up a Screen', std_desc: 'Scroll up page' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'fn+↓', desc: 'Go Down a Screen', std_desc: 'Scroll down page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'home', desc: 'Home', std_desc: 'Home' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'home',
    desc: 'Go to the top of the page',
    std_desc: 'Jump to top of page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'home', desc: 'Go to Top of Page', std_desc: 'Jump to top of page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'home', desc: 'Go to Top of Page', std_desc: 'Jump to top of page' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'home',
    desc: 'Go to the top of the page, Move keyboard focus to first item of pane',
    std_desc: 'Jump to top of page',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'home', desc: 'Go to top of page', std_desc: 'Jump to top of page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'home', desc: 'Scroll to top of webpage', std_desc: 'Jump to top of page' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'n or j or →', desc: 'Next page', std_desc: 'Scroll down page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'n or j or →', desc: 'Next page', std_desc: 'Scroll down page' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+↑',
    desc: 'Switch Search Engine',
    std_desc: 'Switch Search Engine',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+↑',
    desc: 'View menu to switch, add or manage search engines',
    std_desc: 'View menu to switch, add or manage search engines',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+↓',
    desc: 'View menu to switch, add or manage search engines',
    std_desc: 'View menu to switch, add or manage search engines',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'option+arrow key',
    desc: 'Scroll in larger increments',
    std_desc: 'Scroll in larger increments',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'option+home', desc: 'Home', std_desc: 'Home' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+return',
    desc: 'Open Address or Search in New Foreground Tab',
    std_desc: 'Open Address or Search in New Foreground Tab',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+return',
    desc: 'Save Focused Link',
    std_desc: 'Save Focused Link',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'option+shift+return',
    desc: 'Open Address or Search in New Background Tab',
    std_desc: 'Open Address or Search in New Background Tab',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'p or k or ←', desc: 'Previous page', std_desc: 'Go back' },
  { browser: 'Firefox', os: 'Win', shortcut: 'p or k or ←', desc: 'Previous page', std_desc: 'Go back' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'pgdn',
    desc: 'Scroll down a webpage, a screen at a time',
    std_desc: 'Scroll down page',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'pgdn',
    desc: 'Scroll down webpage, one screen at a time',
    std_desc: 'Scroll down page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'pgdn', desc: 'Go Down a Screen', std_desc: 'Scrroll down page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'pgdn', desc: 'Go Down a Screen', std_desc: 'Scrroll down page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'pgdn', desc: 'Scroll down a screen', std_desc: 'Scrroll down page' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'pgup', desc: 'Go Up a Screen', std_desc: 'Scroll up page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'pgup', desc: 'Go Up a Screen', std_desc: 'Scroll up page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'pgup', desc: 'Scroll up a screen', std_desc: 'Scroll up page' },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'pgup',
    desc: 'Scroll up a webpage, a screen at a time',
    std_desc: 'Scroll up page',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'pgup',
    desc: 'Scroll up webpage, one screen at a time',
    std_desc: 'Scroll up page',
  },
  { browser: 'Firefox', os: 'Win', shortcut: 'print', desc: 'Print', std_desc: 'Print page' },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'return',
    desc: 'Highlight name of selected bookmark/folder for editing OR',
    std_desc: 'Highlight name of selected bookmark/folder for editing OR',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'return',
    desc: 'Open Focused Bookmark or Link in Current Tab',
    std_desc: 'Open Focused Bookmark or Link in Current Tab',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'shift+alt+t',
    desc: 'Set focus on the first item in the Chrome toolbar',
    std_desc: 'Set focus on the first item in the Chrome toolbar',
  },
  {
    browser: 'Safari',
    os: 'Mac',
    shortcut: 'shift+click',
    desc: 'Add linked page to Reading List',
    std_desc: 'Add linked page to Reading List',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+enter',
    desc: 'Open Address or Search in New Window',
    std_desc: 'Open Address or Search in New Window',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+enter',
    desc: 'Open Focused Bookmark or Link in New Window',
    std_desc: 'Open Focused Bookmark or Link in New Window',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'shift+esc',
    desc: 'Open the Chrome Task Manager',
    std_desc: 'Open the Chrome Task Manager',
  },
  { browser: 'Edge', os: 'Win', shortcut: 'shift+f10', desc: 'Open context menu', std_desc: 'Open context menu' },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+f10',
    desc: 'Toggle Context Menu',
    std_desc: 'Toggle Context Menu',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'shift+f2', desc: 'Developer Toolbar', std_desc: 'Developer Toolbar' },
  { browser: 'Firefox', os: 'Win', shortcut: 'shift+f2', desc: 'Developer Toolbar', std_desc: 'Developer Toolbar' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'shift+f3',
    desc: 'Find Previous',
    std_desc: 'Find previous match on page',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+f3',
    desc: 'Find Previous',
    std_desc: 'Find previous match on page',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'shift+f4', desc: 'Scratchpad', std_desc: 'Scratchpad' },
  { browser: 'Firefox', os: 'Win', shortcut: 'shift+f4', desc: 'Scratchpad', std_desc: 'Scratchpad' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'shift+f5',
    desc: 'Reload the current tab, ignoring cached content',
    std_desc: 'Hard refresh',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'shift+f5',
    desc: 'Reload your current page, ignoring cached content',
    std_desc: 'Refresh',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'shift+f5', desc: 'Profiler', std_desc: 'Profiler' },
  { browser: 'Firefox', os: 'Win', shortcut: 'shift+f5', desc: 'Profiler', std_desc: 'Profiler' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'shift+f6',
    desc: 'Move to Previous Frame, Pop-Up',
    std_desc: 'Move to Previous Frame, Pop-Up',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+f6',
    desc: 'Move to Previous Frame, Pop-Up',
    std_desc: 'Move to Previous Frame, Pop-Up',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'shift+f6',
    desc: 'Switch focus to previous pane',
    std_desc: 'Switch focus to previous pane',
  },
  { browser: 'Firefox', os: 'Mac', shortcut: 'shift+f7', desc: 'Style Editor', std_desc: 'Style Editor' },
  { browser: 'Firefox', os: 'Win', shortcut: 'shift+f7', desc: 'Style Editor', std_desc: 'Style Editor' },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'shift+return',
    desc: 'Open Address or Search in New Window',
    std_desc: 'Open Address or Search in New Window',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'shift+return',
    desc: 'Open Focused Bookmark or Link in New Window',
    std_desc: 'Open Focused Bookmark or Link in New Window',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'shift+space', desc: 'Page up', std_desc: 'Scroll up page' },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'shift+space',
    desc: 'Scroll up a webpage, a screen at a time',
    std_desc: 'Scroll up page',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'shift+space',
    desc: 'Scroll up a webpage, a screen at a time',
    std_desc: 'Scroll up page',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'shift+space',
    desc: 'Scroll up webpage, one screen at a time',
    std_desc: 'Scroll up page',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'shift+space', desc: 'Page up', std_desc: 'Scroll up page' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'shift+space', desc: 'Go Up a Screen', std_desc: 'Scroll up page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'shift+space', desc: 'Go Up a Screen', std_desc: 'Scroll up page' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'shift+space',
    desc: 'Scroll up webpage, one screen at a time',
    std_desc: 'Scroll up page',
  },
  { browser: 'Safari', os: 'Mac', shortcut: 'shift+space', desc: 'Scroll up a screen', std_desc: 'Scroll up page' },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'shift+tab',
    desc: 'Cycle back through page elements',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'shift+tab',
    desc: 'Browse clickable items moving backward',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'shift+tab',
    desc: 'Browse clickable items moving backward',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'shift+tab',
    desc: 'Focus Previous Link or Input Field',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'shift+tab',
    desc: 'Focus Previous Link or Input Field',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'shift+tab',
    desc: 'Move to the previous control',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'shift+tab',
    desc: 'Go to previous tab stop',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'shift+tab',
    desc: 'Cycle back through page elements',
    std_desc: 'Focus previous clickable item',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'space',
    desc: 'Scroll down a webpage, a screen at a time',
    std_desc: 'Scrroll down page',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'space',
    desc: 'Scroll down a webpage, a screen at a time',
    std_desc: 'Scrroll down page',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'space',
    desc: 'Scroll down webpage, one screen at a time',
    std_desc: 'Scrroll down page',
  },
  { browser: 'Opera', os: 'Mac', shortcut: 'space', desc: 'Page down', std_desc: 'Scrroll down page' },
  { browser: 'Opera', os: 'Win', shortcut: 'space', desc: 'Page down', std_desc: 'Scrroll down page' },
  { browser: 'Firefox', os: 'Mac', shortcut: 'space', desc: 'Go Down a Screen', std_desc: 'Scrroll down page' },
  { browser: 'Firefox', os: 'Win', shortcut: 'space', desc: 'Go Down a Screen', std_desc: 'Scrroll down page' },
  { browser: 'Safari', os: 'Mac', shortcut: 'space', desc: 'Scroll down a screen', std_desc: 'Scroll down page' },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'space',
    desc: 'Scroll down webpage, one screen at a time',
    std_desc: 'Scroll down page',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'space',
    desc: 'Show List of All Bookmarks',
    std_desc: 'Show list of all bookmarks',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'space',
    desc: 'Show List of All Bookmarks',
    std_desc: 'Show list of all bookmarks',
  },
  {
    browser: 'Chrome',
    os: 'Mac',
    shortcut: 'tab',
    desc: 'Browse clickable items moving forward',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Chrome',
    os: 'Win',
    shortcut: 'tab',
    desc: 'Browse clickable items moving forward',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Firefox',
    os: 'Mac',
    shortcut: 'tab',
    desc: 'Focus Next Link or Input Field',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Firefox',
    os: 'Win',
    shortcut: 'tab',
    desc: 'Focus Next Link or Input Field',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Edge',
    os: 'Mac',
    shortcut: 'tab',
    desc: 'Move to the next control',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Edge',
    os: 'Win',
    shortcut: 'tab',
    desc: 'Go to next tab stop',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Opera',
    os: 'Win',
    shortcut: 'tab',
    desc: 'Cycle forward through page elements',
    std_desc: 'Focus next clickable item',
  },
  {
    browser: 'Opera',
    os: 'Mac',
    shortcut: 'tab',
    desc: 'Cycle forward through page elements',
    std_desc: 'Focus next clickable item',
  },
  { browser: 'Opera', os: 'Win', shortcut: 'windows+m', desc: 'Minimize a window', std_desc: 'Minimize window' },
];
export default shortcuts;
