import React from 'react';
import ReactSelectCreatable from 'react-select/creatable';
import { selectStyles } from './styles';

export const CreatableSelect = (props: React.ComponentProps<typeof ReactSelectCreatable>) => (
  <ReactSelectCreatable
    styles={props.styles ?? selectStyles}
    components={{
      IndicatorSeparator: () => null,
      ...props.components,
    }}
    menuPlacement="auto"
    {...props}
  />
);
