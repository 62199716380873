import { INamedRule, getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { useAppContext } from 'editor/src/AppStateContext';
import { isConditionGroupValid } from 'editor/src/editor/conditions/validate';

interface ValidationError {
  condition: boolean;
  message: string;
}

export const isDuplicateName = (rules: INamedRule[], rule: INamedRule) =>
  !!rules.filter(({ name, id }) => name.toLowerCase().trim() === rule.name.toLowerCase().trim() && id !== rule.id)
    .length;

export const useRuleValidationError = (newRule: INamedRule): Array<ValidationError> => {
  const { rules } = useAppContext();

  return [
    {
      condition: !getConditions(newRule.expression).length,
      message: 'Must have condition. Use the Add condition button.',
    },
    {
      condition: isDuplicateName && isDuplicateName(rules, newRule),
      message: 'Audience name is already in use.',
    },
    {
      condition: newRule.name === '',
      message: 'Audience name cannot be empty.',
    },
    {
      condition: !isConditionGroupValid(newRule.expression),
      message: 'Conditions are not valid.',
    },
  ].filter(({ condition }) => condition);
};
