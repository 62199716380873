import React from 'react';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import {
  Typography,
  Input,
  Page,
  Form,
  Popover,
  CB_COLORS,
  Layout,
  FormRow,
} from '@commandbar/design-system/components/antd';
import { Code } from './blocks';

import { CmdButton, CmdSwitch, cmdToast } from '@commandbar/design-system/cmd';

interface IProps {
  organization: IOrganizationType;
}

const CODE_HMAC_PYTHON = `import hmac
import hashlib
import datetime

h = hmac.new(
  b'abc123', # secret key (keep safe!)
  bytes(user.id, encoding='utf-8'), # user's id
  digestmod=hashlib.sha256 # hash function
)

# Make sure the date is in UTC and in the format YYYYMMDD
today = datetime.datetime.utcnow().date().strftime("%Y%m%d")
h.update(bytes(today, encoding='utf-8'))
h.hexdigest();
`;

const CODE_BOOT_SYNTAX = `window.CommandBar.boot('myUserId', {}, { hmac: 'myUserHMAC'});`;

const IdentityVerification = (props: IProps) => {
  const [secret, setSecret] = React.useState('loading...............');
  const [calculatorHash, setCalculatorHash] = React.useState('');
  const [forceIdentityVerification, setForceIdentityVerification] = React.useState(
    props.organization.force_end_user_identity_verification,
  );

  React.useEffect(() => {
    getSecret();
  }, []);

  const getSecret = async () => {
    try {
      const res = await axiosInstance.get(`/organizations/${props.organization.id}/users/security/`);
      const _secret = res.data?.end_user_secret ?? 'something went wrong......';
      setSecret(_secret);
    } catch {
      setSecret('something went wrong.....');
    }
  };

  /**
   * Unused for now
   */
  const _rotateSecret = async () => {
    try {
      const res = await axiosInstance.patch(`/organizations/${props.organization.id}/users/security/`);
      const _secret = res.data?.end_user_secret ?? 'something went wrong......';
      setSecret(_secret);
    } catch {}
  };

  const copySecret = () => {
    navigator.clipboard.writeText(secret);
    cmdToast.info('Secret copied to clipboard.');
  };

  const onFinish = async (values: any) => {
    try {
      const response = await axiosInstance.post(`/organizations/${props.organization.id}/users/hmac/`, { ...values });
      setCalculatorHash(response.data);
    } catch (err) {
      setCalculatorHash('');
    }
  };

  return (
    <Page section="Admin" title="Identity Verification" description="Enable user customization">
      <Layout.Content style={{ padding: '10px 10px', flexGrow: 1, height: 'inherit' }}>
        <Typography.Title level={2}>What is identity verification?</Typography.Title>
        <div>
          Identity verification ensures that none of your users can impersonate other users. It works by using a
          server-side-generated HMAC (generated using a shared secret) to tell your frontend who the logged-in user is.
          We also use identity verification for authenticating HelpHub content.
        </div>
        <br />
        <div>
          We recommend turning on identity verification for maximum security. It should only take a few minutes and the
          added security is well worth the effort.
        </div>
        <br />
        <Typography.Title level={2}>Your secret</Typography.Title>
        <div
          style={{
            padding: '20px',
            backgroundColor: '#f0f2ff',
            borderRadius: '6px',
            border: `1px solid ${CB_COLORS.primary}`,
          }}
        >
          <div>Identity Verification Secret</div>
          <div style={{ display: 'flex' }}>
            <Input.Password placeholder="" value={secret} style={{ flexGrow: 1 }} />
            <div style={{ width: '12px' }} />
            <CmdButton onClick={copySecret}>Copy</CmdButton>
            <div style={{ width: '12px' }} />
            <Popover
              content="Please contact support@commandbar.com to rotate your Org secret."
              title="This cannot be undone."
              trigger="click"
            >
              <CmdButton variant="destructive">Rotate</CmdButton>
            </Popover>
          </div>
        </div>
        <br />
        <div style={{ background: '#F7F7F8', padding: '24px', paddingBottom: '8px', borderRadius: '8px' }}>
          <FormRow
            title="Force Identity Verification"
            info={`Enable this setting for enhanced security`}
            input={
              <CmdSwitch
                checked={forceIdentityVerification}
                onCheckedChange={async (checked) => {
                  try {
                    await OrganizationSettings.update({
                      ...{ force_end_user_identity_verification: checked },
                    });
                    if (!checked) {
                      // if they turn off HMAC, then we need to disallow copilot chat history
                      await OrganizationSettings.update({
                        ...{ copilot_chat_history_enabled: false },
                      });
                    }
                    setForceIdentityVerification(checked);
                  } catch (error) {
                    console.error('Error updating Force Identity Verification: ', error);
                  }
                }}
              />
            }
          />
        </div>
        <br />
        <Typography.Title level={2}>Setting up identity verification</Typography.Title>
        <p>
          <Typography.Text>{`First, on your server, you'll need to generate an HMAC of the user_id with SHA256 whenever a user logs into your app.`}</Typography.Text>
        </p>
        <p>
          <Typography.Text>{`Here's an example of one way you might do that.`}</Typography.Text>
        </p>
        <Code content={CODE_HMAC_PYTHON} title={'HMAC (Python)'} language="python" wrap />
        <p>
          <Typography.Text>{`Next, you can pass the HMAC to CommandBar via the boot command.`}</Typography.Text>
        </p>
        <Code content={CODE_BOOT_SYNTAX} title={'Boot Syntax'} wrap />
        <Typography.Title level={2}>Troubleshooting</Typography.Title>
        <p>
          <Typography.Text>{`Make sure that your hashes are being computed the same way as ours.`}</Typography.Text>
        </p>
        <div style={{ padding: '20px', border: '1px solid gray', borderRadius: '6px' }}>
          <Typography.Title level={4} type="secondary">
            HMAC Calculator
          </Typography.Title>
          <Form layout="vertical" onFinish={onFinish} autoComplete="off">
            <Form.Item label="Secret" name="secret" id="secret" rules={[{}]}>
              <Input placeholder="YOUR_SECRET" />
            </Form.Item>

            <Form.Item label="User ID" name="user">
              <Input placeholder="User ID or email" />
            </Form.Item>

            <Form.Item>
              <CmdButton fullWidth variant="primary" type="submit">
                Generate user HMAC
              </CmdButton>
            </Form.Item>
          </Form>
          <div>Expected user HMAC</div>
          <Input value={calculatorHash} />
        </div>
      </Layout.Content>
    </Page>
  );
};

export default IdentityVerification;
