import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { GoogleChrome, XClose, ChevronUp, Check } from '@commandbar/design-system/icons/react';
import { COMMANDBAR_CHROME_EXTENSION_ID } from '@commandbar/internal/client/ChromeExtension';

import { ReactComponent as StudioPreviewSetupStep2Svg } from '../../../img/StudioPreviewSetupStep2.svg';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdButton } from '@commandbar/design-system/cmd';
import { ArrowUpRight } from '@commandbar/design-system/icons/react';
import { isValidURL } from '@commandbar/internal/util/location';

const Container = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 8px);

  z-index: ${Z.Z_INDEX_MAX};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0;

  font-family: 'Inter';

  width: 400px;
  height: auto;

  background: ${CB_COLORS.white};
  border: 1px solid ${CB_COLORS.neutral300};

  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  transition: all 0.3s;
`;
const Header = styled.div`
  padding: 16px;
  width: 100%;
  height: 52px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${CB_COLORS.neutral300};
`;
const HeaderTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: ${CB_COLORS.neutral800};
`;

const CloseButton = styled.div`
  padding: 4px;
  cursor: pointer;
`;
const StepContainer = styled.div`
  transition-delay: 0.5s;
  transition: all 0.5s;
  width: 100%;
  border-bottom: 1px solid ${CB_COLORS.neutral300};

  &:last-child {
    border-bottom: none;
  }
`;
const StepHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const StepTitle = styled.div<{ active?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;

  color: ${(props) => (props.active ? CB_COLORS.neutral1000 : CB_COLORS.neutral600)};
`;

const ChevronContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  background: ${(props) => (props.active ? CB_COLORS.neutral100 : 'transparent')};
  color: ${(props) => (props.active ? CB_COLORS.black : CB_COLORS.neutral500)};
  border-radius: 50%;

  transform: rotate(${(props) => (props.active ? '0deg' : '180deg')});
  transition: all 0.4s;
`;

const StepBody = styled.div`
  margin-top: -8px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
`;
const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;

  color: ${CB_COLORS.neutral800};
`;
const Button = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  cursor: pointer;

  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;

  background: ${(props) => (props.disabled ? '#E0E2DF' : CB_COLORS.neutral1000)};
  color: ${(props) => (props.disabled ? '#858585' : '#fff')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

type TStep = {
  title: string;
  body: JSX.Element;
  collapsable: boolean;
};

interface IStudioPreviewSetupInstructionsProps {
  onClose: React.MouseEventHandler<HTMLDivElement>;
  isChromeExtensionInstalled: boolean;
}
export const StudioPreviewSetupInstructions = ({
  onClose,
  isChromeExtensionInstalled,
}: IStudioPreviewSetupInstructionsProps) => {
  const { organization } = useAppContext();
  const baseUrl = organization.base_url;

  const getActiveStepIndex = () => {
    if (isChromeExtensionInstalled && isValidURL(baseUrl, true)) {
      return 2;
    }
    if (isChromeExtensionInstalled) {
      return 1;
    }
    return 0;
  };
  const [activeStepIndex, setActiveStepIndex] = React.useState<number | undefined>(() => getActiveStepIndex());

  React.useEffect(() => {
    /**
     * The isChromeExtensionInstalled check maybe not repond to us
     * right away so without this block, the activeStepIndex will
     * not reflect the installation state of the extension correctly
     */
    if (isChromeExtensionInstalled && activeStepIndex === 0) {
      setActiveStepIndex(1);
    }
  }, [isChromeExtensionInstalled]);

  const notChromeSteps: TStep[] = [
    {
      collapsable: false,
      title: 'Google Chrome required ',
      body: (
        <>
          <Description>
            To preview experiences inside your app you'll need our browser extension which is currently only supported
            in Google Chrome.
          </Description>
          <Button
            onClick={() => {
              window.open('https://www.google.com/chrome/', '_blank');
            }}
          >
            <GoogleChrome width={14} style={{ opacity: 0.5 }} /> Get Google Chrome ↗
          </Button>
        </>
      ),
    },
  ];
  const chromeSteps: TStep[] = [
    {
      collapsable: true,
      title: 'Install Chrome extension',
      body: (
        <>
          <Description>
            For previewing your work inside your app or when building embedded experiences like a product tour.
          </Description>
          <Button
            disabled={isChromeExtensionInstalled}
            onClick={() => {
              if (isChromeExtensionInstalled) return;
              window.open(
                `https://chrome.google.com/webstore/detail/commandbar-editor/${COMMANDBAR_CHROME_EXTENSION_ID}`,
                '_blank',
              );
            }}
          >
            {isChromeExtensionInstalled ? (
              <>
                <Check width={15} /> Install Chrome Extension
              </>
            ) : (
              <>
                <GoogleChrome width={14} style={{ opacity: 0.5 }} /> Install Chrome Extension ↗
              </>
            )}
          </Button>
        </>
      ),
    },
    {
      collapsable: true,
      title: 'Set App Domain',
      body: (
        <>
          <Description>Where you'll use CommandBar:</Description>
          <div
            style={{
              background: '#F9F9F9',
              borderRadius: '6px',
              padding: '16px',
              marginTop: '8px',
              display: 'flex',
              gap: 10,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CmdButton onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/configure`, '_blank')}>
              Configure your app domain
              <ArrowUpRight />
            </CmdButton>
          </div>
        </>
      ),
    },
    {
      collapsable: true,
      title: 'Open the extension in your app',
      body: (
        <>
          <Description>
            With your app open, click the CommandBar extension to preview, edit, and publish your work.
          </Description>
          <StudioPreviewSetupStep2Svg />
          <Button disabled={!isValidURL(baseUrl, true)} onClick={() => window.open(baseUrl, '_blank')}>
            Open Your App ↗
          </Button>
        </>
      ),
    },
  ];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Disabling because window.chrome may or may not exist but tslint is not recognising that
  const isChrome = !!window.chrome;
  const steps = isChrome ? chromeSteps : notChromeSteps;
  return (
    <Container>
      <Header>
        <HeaderTitle>Setup</HeaderTitle>
        <CloseButton onClick={onClose}>
          <XClose width={20} color={CB_COLORS.neutral500} />
        </CloseButton>
      </Header>
      {steps.map((step, index) => (
        <StepContainer key={index}>
          <StepHeader onClick={() => setActiveStepIndex(index === activeStepIndex ? undefined : index)}>
            <StepTitle active={index === activeStepIndex}>
              {steps.length > 1 && `${index + 1}.`} {step.title}
            </StepTitle>
            {step.collapsable && (
              <ChevronContainer active={index === activeStepIndex}>
                <ChevronUp width={16} />
              </ChevronContainer>
            )}
          </StepHeader>
          {index === activeStepIndex && <StepBody>{step.body}</StepBody>}
        </StepContainer>
      ))}
    </Container>
  );
};
