import React, { ReactNode } from 'react';

import { Container, Subtitle, InnerContainer, Title, TitleContainer, ActionContainer } from './styled';
import { CmdButton } from '@commandbar/design-system/cmd';
import { ReverseLeft } from '@commandbar/design-system/icons/react';

interface IProps {
  title?: ReactNode;
  titlePlaceholder?: string;
  subTitle?: ReactNode;
  actions?: ReactNode[];
  onBackPress?(): void;
}

export const DetailsHeader = ({ actions = [], title, titlePlaceholder, subTitle, onBackPress }: IProps) => {
  return (
    <Container>
      <Subtitle>{subTitle}</Subtitle>
      <InnerContainer style={{ flexWrap: 'wrap' }}>
        <CmdButton
          onClick={() => {
            if (onBackPress) {
              onBackPress();
            }
          }}
        >
          <ReverseLeft />
        </CmdButton>

        <TitleContainer>
          <Title placeholder={!title}>{title ? title : titlePlaceholder}</Title>
        </TitleContainer>

        {actions.map((action, index) => (
          <ActionContainer key={`header-action-${index}`}>{action}</ActionContainer>
        ))}
      </InnerContainer>
    </Container>
  );
};
