// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const CursorClick01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.817 13.373c.529-.285.793-.427.86-.598a.5.5 0 0 0-.044-.451c-.099-.155-.386-.243-.96-.42L9.445 8.445c-.468-.144-.703-.216-.861-.158a.5.5 0 0 0-.297.297c-.058.158.014.393.158.861l3.459 11.228c.177.574.265.861.42.96a.5.5 0 0 0 .45.044c.172-.067.314-.331.599-.86l2.492-4.627a1.08 1.08 0 0 1 .09-.153.508.508 0 0 1 .082-.082 1.08 1.08 0 0 1 .153-.09l4.627-2.492Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 3.5V2M5.06 5.06 4 4m1.06 9L4 14.06m9-9L14.06 4M3.5 9H2m13.864 7.19-2.491 4.627c-.285.529-.427.793-.598.86a.5.5 0 0 1-.451-.044c-.155-.099-.243-.386-.42-.96L8.445 9.445c-.144-.468-.216-.703-.158-.861a.5.5 0 0 1 .297-.297c.158-.058.393.014.861.158l11.228 3.459c.574.177.86.265.96.42a.5.5 0 0 1 .044.45c-.067.172-.331.314-.86.599l-4.627 2.492a1.08 1.08 0 0 0-.153.09.493.493 0 0 0-.082.082 1.08 1.08 0 0 0-.09.153Z"
    />
  </svg>
);
export default CursorClick01;
