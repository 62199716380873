/** @jsx jsx */
import { OptionGroup, TabGroup } from '../../store/options/option-utils/OptionGroup';
import { jsx } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { useStore } from 'shared/util/hooks/useStore';
import { useAction } from 'shared/util/hooks/useAction';
import { ISearchFilter } from 'shared/store/global-store';
import * as SpotlightActions from '../../store/actions';
import React from 'react';
import Icon from 'shared/components/Icon';
import { selectSearchTabs } from '../../store/selectors';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import StyledSpotlightMajorCategoriesContainer from '@commandbar/internal/client/themesV2/components/spotlight/StyledSpotlightMajorCategoriesContainer';
import StyledToggleButton from '@commandbar/internal/client/themesV2/components/shared/StyledToggleButton';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';

/*******************************************************************************/
/* Render
/*******************************************************************************/

export const createSearchFilter = (group: OptionGroup): ISearchFilter => {
  let emptyInputMessage = undefined;

  if (group.hasHotloadedCommands) {
    if (!!group.searchTabInstruction) {
      emptyInputMessage = group.searchTabInstruction;
    } else {
      emptyInputMessage = 'Type to search';
    }
  }

  return {
    fn: group.filterFn,
    slug: group.key,
    inputTag: group.name,
    placeholder: group.searchTabInstruction || undefined,
    renderAs: group.renderAs,
    // FIXME: we need a better way to handle this. It's a way to avoid "No results" if the options are async
    emptyInputMessage: emptyInputMessage,
  };
};

/*******************************************************************************/
/* Render
/*******************************************************************************/
const SearchTabs = (props: { direction: 'vertical' | 'horizontal'; hide?: boolean }) => {
  const { theme }: { theme: ITheme } = useTheme();
  const store = useStore();
  const { focusedIndex, sortedOptions, searchFilter } = store.spotlight;
  const setSearchFilter = useAction(SpotlightActions.setSearchFilter);
  const themeV2 = useThemeV2Context();

  const tabGroups = selectSearchTabs(store, props.direction === 'vertical');
  const focusInput = useAction((_) => {
    if (!isMobileDevice()) {
      _.spotlight.refContainer?.current?.focus();
    }
  });

  const onTabSelect = (tab: OptionGroup) => {
    setSearchFilter(createSearchFilter(tab));
  };

  const onTabReset = () => {
    setSearchFilter(undefined);
  };

  if (tabGroups.length === 0) {
    return <div />;
  }

  const activeTabGroup = tabGroups.find((tabGroup) => tabGroup.key === searchFilter?.slug);

  const isFocusable = () => {
    // Only focusable when outside of the list.
    return focusedIndex === -1 || sortedOptions.length === 0;
  };

  const renderTabHeading = (tabs: TabGroup[], tab: TabGroup, index: number) => {
    if (props.direction !== 'vertical') {
      return false;
    }

    // render actual header
    if (!!tab.header && ((index - 1 >= 0 && tab.header !== tabGroups[index - 1].header) || index === 0)) {
      return true;
    }

    // render missing header as whitespace for first tab without header
    if (!tab.header && index - 1 >= 0 && !!tabs[index - 1].header) {
      return true;
    }
  };

  return store.flags?.['release-themes-v2'] ? (
    <StyledSpotlightMajorCategoriesContainer
      aria-hidden={true}
      onClick={focusInput}
      id="commandbar-search-tab-container"
    >
      <StyledToggleButton themeV2={themeV2} key={'all'} isSelected={activeTabGroup === undefined} onClick={onTabReset}>
        All
      </StyledToggleButton>
      {tabGroups.map((tabGroup, index) => {
        return (
          <StyledToggleButton
            themeV2={themeV2}
            key={index}
            isSelected={!!(activeTabGroup && activeTabGroup.key === tabGroup.key)}
            onClick={() => onTabSelect(tabGroup)}
          >
            {tabGroup.name}
          </StyledToggleButton>
        );
      })}
    </StyledSpotlightMajorCategoriesContainer>
  ) : (
    <div
      aria-hidden={true}
      style={{
        display: 'flex',
        paddingTop: theme.searchTabsContainer.paddingTop,
        paddingRight: theme.searchTabsContainer.paddingRight,
        paddingLeft: theme.searchTabsContainer.paddingLeft,
        paddingBottom: theme.searchTabsContainer.paddingBottom,
        width: '100%',
      }}
      onClick={focusInput}
      id="commandbar-search-tab-container"
    >
      <div
        role="tablist"
        css={
          props.direction === 'horizontal'
            ? {
                display: 'flex',
                gap: 4,
                alignItems: 'center',
                overflowX: 'auto',
              }
            : { display: 'flex', gap: 4, flexDirection: 'column', width: '100%' }
        }
      >
        <SearchTab
          direction={props.direction}
          name={theme.searchTab.labelAllTab}
          isFocusable={isFocusable()}
          active={activeTabGroup === undefined}
          onTabSelect={onTabReset}
        />

        {tabGroups.map((tabGroup, index) => {
          return (
            <React.Fragment key={index}>
              {renderTabHeading(tabGroups, tabGroup, index) && (
                <span
                  style={{
                    padding: theme.searchTabGroupHeading.padding,
                    fontWeight: theme.searchTabGroupHeading.fontWeight,
                    textAlign: theme.searchTabGroupHeading.textAlign as
                      | 'start'
                      | 'end'
                      | 'left'
                      | 'right'
                      | 'center'
                      | 'justify'
                      | 'match-parent'
                      | undefined,
                    color: theme.searchTabGroupHeading.color,
                    fontSize: theme.searchTabGroupHeading.fontSize,
                  }}
                >
                  {tabGroup.header}
                </span>
              )}
              <SearchTab
                direction={props.direction}
                tabGroup={tabGroup}
                isFocusable={isFocusable()}
                active={(activeTabGroup && activeTabGroup.key === tabGroup.key) ?? false}
                onTabSelect={() => onTabSelect(tabGroup)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const SearchTab = ({
  direction,
  tabGroup,
  active,
  name = tabGroup?.searchTabName || tabGroup?.name,
  isFocusable,
  onTabSelect,
}: {
  direction: 'vertical' | 'horizontal';
  tabGroup?: TabGroup;
  active: boolean;
  name?: string;
  isFocusable: boolean;
  onTabSelect: () => void;
}) => {
  const { theme }: { theme: ITheme } = useTheme();
  const { optionCountsByTabGroup, showLoadingIndicator } = useStore().spotlight;
  const icon = tabGroup?.icon ?? null;

  const optionCount = (tabGroup && optionCountsByTabGroup.get(tabGroup.key)) ?? null;

  // if optionCount is `null` we will display the UI as if there *are* options available
  const hasOptions = optionCount != null && !showLoadingIndicator ? optionCount > 0 : true;

  /** Performnace: This is expensive to be dynamically calculated on each render */
  const style = React.useMemo(
    () => tabStyle({ theme, hasOptions, isActive: active, direction }),
    [active, direction, theme, hasOptions],
  );

  return (
    <button
      role="tab"
      aria-label={`search-filter-${name}`}
      aria-selected={active}
      aria-disabled={active}
      tabIndex={isFocusable ? 0 : -1}
      // @ts-expect-error FIXME emotion types on spread
      css={style}
      onClick={onTabSelect}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onTabSelect();
        }
      }}
      data-testid="search-tab"
    >
      {!!icon && (
        <Icon
          icon={icon}
          style={{ marginRight: theme.searchTab.iconMarginRight, verticalAlign: 'middle' }}
          useDefaultSVGColor={true}
          size={theme.searchTab.iconSize}
        />
      )}
      <span
        css={{
          whiteSpace: direction === 'horizontal' ? 'nowrap' : 'normal',
        }}
      >
        {name}
      </span>
    </button>
  );
};

const tabStyle = ({
  theme,
  isActive,
  hasOptions,
  direction,
}: {
  theme: ITheme;
  isActive: boolean;
  hasOptions: boolean;
  direction: 'horizontal' | 'vertical';
}) => {
  const {
    activeColor,
    activeBorder,
    activeBorderWidth,
    activeBackground,
    borderRadius,
    fontSize,
    fontWeight,
    inactiveColor,
    inactiveBorder,
    inactiveBorderWidth,
    inactiveBackground,
    noResultsColor,
    noResultsBorder,
    noResultsBackground,
    noResultsOpacity,
    hoverColor,
    hoverBorder,
    hoverBorderWidth,
    hoverBackground,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    inactivePadding,
    hoverPadding,
    spacing,
    textAlign,
    transitionTime,
  } = theme.searchTab;

  const styleColorAndHover = (() => {
    if (isActive) {
      return {
        color: activeColor,
        background: activeBackground,
        border: activeBorder,
        ...(activeBorderWidth && { borderWidth: activeBorderWidth }),
      };
    } else {
      return {
        color: inactiveColor,
        background: inactiveBackground,
        border: inactiveBorder,
        ...(inactiveBorderWidth && { borderWidth: inactiveBorderWidth }),
        ...(inactivePadding && { padding: inactivePadding }),
        '&:hover': {
          color: hoverColor,
          border: hoverBorder,
          ...(hoverBorderWidth && { borderWidth: hoverBorderWidth }),
          background: hoverBackground,
          ...(hoverPadding && { padding: hoverPadding }),
        },
        ...(!hasOptions && {
          opacity: noResultsOpacity,
          ...(noResultsColor && { color: noResultsColor }),
          ...(noResultsBorder && { border: noResultsBorder }),
          ...(noResultsBackground && { background: noResultsBackground }),
        }),
      };
    }
  })();

  return {
    fontFamily: theme.main.fontFamily,
    fontSize,
    lineHeight: 1,
    display: 'inline-block',
    textAlign,
    cursor: 'pointer',
    transition: `all ${transitionTime} cubic-bezier(0.645, 0.045, 0.355, 1)`,
    fontWeight,
    height: 'fit-content',
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    marginBottom: direction === 'vertical' ? spacing : 0,
    marginRight: direction === 'horizontal' ? spacing : 0,
    borderRadius,
    ...(direction === 'horizontal' && {
      display: 'flex',
      alignItems: 'center',
    }),
    ...styleColorAndHover,
    '&:last-child': {
      marginBottom: 0,
      marginRight: 0,
    },
  };
};

export default SearchTabs;
