/*******************************************************************************/
/* Imports
/*******************************************************************************/
import React from 'react';

import { CmdButton } from '@commandbar/design-system/cmd';
import { CheckCircle } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';

/*******************************************************************************/

type UseIntercomOAuthOptions = Partial<{
  isHelpDocsIntegration: boolean;
  sourceId: number;
}>;

export const useIntercomOAuth = (options?: UseIntercomOAuthOptions) => {
  const { organization } = useAppContext();

  const clientID = process.env.REACT_APP_INTERCOM_ID;
  const apiURL = process.env.REACT_APP_API_URL?.includes('localhost:8000')
    ? 'http://localhost:8000'
    : process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
  const redirectUri = encodeURIComponent(`${apiURL}/integrations/intercom`);

  let stateUrl: string;
  if (options?.isHelpDocsIntegration) {
    stateUrl = `${window.location.href}?is_help_docs_integration=true`;

    if (options.sourceId) {
      stateUrl += `&source_id=${options.sourceId}`;
    }
  } else {
    stateUrl = `${window.location.href}?is_help_docs_integration=false&org_id=${organization.id}`;
  }

  const state = encodeURIComponent(stateUrl);
  const authorizationUri = `https://app.intercom.com/oauth?client_id=${clientID}&redirect_uri=${redirectUri}&state=${state}`;

  return () => window.location.replace(authorizationUri);
};

interface IProps {
  token?: string | null | undefined;
  // TODO: Remove this once everyone has migrated to the Intercom V2 app
  isV2Token?: boolean;
  sourceId?: number;
  isHelpDocsIntegration?: boolean;
}

const IntercomOAuth = ({ token, isV2Token, isHelpDocsIntegration, sourceId }: IProps) => {
  const onClick = useIntercomOAuth({ isHelpDocsIntegration, sourceId });

  if (token) {
    // TODO: Remove this once everyone has migrated to the Intercom V2 app
    if (isV2Token) {
      return <CheckCircle color="green" />;
    }

    return <CmdButton onClick={onClick}>Refresh Access</CmdButton>;
  }

  return <CmdButton onClick={onClick}>Click to Enable</CmdButton>;
};

export default IntercomOAuth;
