import { CBStore } from './global-store';
import { ICommandType } from '@commandbar/internal/middleware/types';
import * as Command from '@commandbar/internal/middleware/command';

export * from './executables/executable-selectors';

export const getCommands = (_: CBStore) => {
  const hotloadedCommandIds = new Set(_.hotloadedCommands.map((c) => c.id));

  const commands = [
    /* commands in engine.hotloadedCommands may overlap with engine.commands, so omit those from `commands` before merging */
    ..._.commands.filter((c) => !hotloadedCommandIds.has(c.id)),

    ..._.programmaticCommands,
    ..._.hotloadedCommands,
  ];

  return _.organization?.in_bar_doc_search === true
    ? commands
    : commands.filter((command) => command.template.type !== 'helpdoc');
};

export const getCommandById = (_: CBStore, id: string | number) => {
  if (_.mockCommands.length > 0) {
    const mockCommand = _.mockCommands.find((c) => c.id === id || Command.commandUID(c) === id || c.name === id);
    if (mockCommand) {
      return mockCommand;
    }
  }
  return getCommands(_).find((c) => c.id === id || Command.commandUID(c) === id || c.name === id);
};
export const findCommandById = (list: ICommandType[], id: string | number) =>
  list.find((c) => c.id === id || Command.commandUID(c) === id || c.name === id);

export const getLocalCommandOrHelpdoc = (_: CBStore, id: string | number) => {
  // Get any command, including help docs — help docs are excluded from getCommandById
  const allCommands = [..._.commands, ..._.programmaticCommands, ..._.hotloadedCommands];
  return allCommands.find((c) => c.id === id || Command.commandUID(c) === id || c.name === id);
};

export const selectEndUserEndpoint = (_: CBStore): string | null => {
  if (!_.endUser) return null;

  return `/u/${_.endUser.identifier}/`;
};
