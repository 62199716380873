import React from 'react';
import styled from '@emotion/styled';
import StyledIconContainer from './StyledIconContainer';

const Circle: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
    <circle cx="3" cy="3" r="2.5" fill="white" />
  </svg>
);

const Radio = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 1000px;
  border: 1px solid var(--form-control-border-color);
  background: var(--form-control-background);

  &:hover {
    border: 1px solid var(--form-control-border-color-hover);
  }
`;

const SelectedRadio = styled(Radio)`
  background: var(--button-primary-background);
  border: 1px solid var(--button-primary-background);

  &:hover {
    border: 1px solid var(--button-primary-background);
  }
`;

const CircleContainer = styled(StyledIconContainer)`
  width: var(--layout-padding);
  height: var(--layout-padding);
  color: var(--base-white);
`;

const StyledCheckboxIcon = ({ selected }: { selected: boolean }) => {
  return selected ? (
    <SelectedRadio>
      <CircleContainer>
        <Circle />
      </CircleContainer>
    </SelectedRadio>
  ) : (
    <Radio />
  );
};

export default StyledCheckboxIcon;
