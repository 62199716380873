import { IChecklist } from '@commandbar/internal/middleware/types';

export const emptyChecklist: IChecklist = {
  id: -1,
  title: '',
  audience: { type: 'all_users' },
  show_expression: { type: 'LITERAL', value: true },
  trigger: { type: 'when_share_link_viewed' },
  items: [],
  description: '',
  is_live: false,
  celebrate: false,
  template_source: 'none',
  position: 'bottomRight',
  skippable: true,
  dismissible: false,
  open_by_default: true,
  share_page_url_or_path: '',
  show_in_spotlight_search: true,
  show_in_helphub_search: true,
  copilot_suggest: false,
  copilot_cta_label: '',
  copilot_description: '',
  editor_tags: [],
  custom_theme: null,
};

export const saasOnboarding: IChecklist = {
  ...emptyChecklist,
  title: 'Get Started',
  template_source: 'saas_onboarding',
  items: [
    {
      id: 1,
      title: 'Invite your team',
      cta: 'Add Team',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/team',
      },
      celebrate: false,
    },
    {
      id: 2,
      title: 'Secure your account',
      cta: 'Setup 2FA',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/security',
      },
      celebrate: false,
    },
    {
      id: 3,
      title: 'Create your first Project',
      cta: 'Create Project',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/project',
      },
      celebrate: false,
    },
  ],
};

export const featureExploration: IChecklist = {
  ...emptyChecklist,
  title: 'Create your email campaign',
  template_source: 'feature_exploration',
  items: [
    {
      id: 1,
      title: 'Pick your template',
      description: 'Templates are quick way to create a great looking email.',
      cta: 'View Templates',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/template',
      },
      celebrate: false,
    },
    {
      id: 2,
      title: 'Add your content',
      description: 'Keep it snappy and add some visuals.',
      cta: 'Add Content',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/content',
      },
      celebrate: false,
    },
    {
      id: 3,
      title: 'Choose your audience',
      description:
        'Once you’re happy with the content choose who it will go to – whether that’s all your customers or just a small segment. ',
      cta: 'Select an Audience',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/audience',
      },
      celebrate: false,
    },
    {
      id: 4,
      title: 'Hit send',
      description: 'It’s time to send your first campaign. Don’t forget to spellcheck it. ',
      cta: 'Send It',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/send',
      },
      celebrate: false,
    },
  ],
};

export const newFeatureLaunch: IChecklist = {
  ...emptyChecklist,
  title: 'Check out our new feature!',
  template_source: 'feature_exploration',
  items: [
    {
      id: 1,
      title: 'Read our changelog entry',
      cta: 'Read',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/changelog',
      },
      celebrate: false,
    },
    {
      id: 2,
      title: 'Try it out',
      cta: 'Go there now',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/feature',
      },
      celebrate: false,
    },
    {
      id: 3,
      title: 'Take a tour',
      cta: 'Start tour',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/tour',
      },
      celebrate: false,
    },
    {
      id: 4,
      title: 'Tell us what you think',
      cta: 'Start survey',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/survey',
      },
      celebrate: false,
    },
  ],
};

export const newAdminUser: IChecklist = {
  ...emptyChecklist,
  title: 'Get your team’s account up and running',
  template_source: 'feature_exploration',
  items: [
    {
      id: 1,
      title: 'Watch our onboarding video',
      cta: 'Watch',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/watch',
      },
      celebrate: false,
    },
    {
      id: 2,
      title: 'Manage your users list',
      cta: 'Go to team page',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/team',
      },
      celebrate: false,
    },
    {
      id: 3,
      title: 'Verify your settings',
      cta: 'Open settings page',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/settings',
      },
      celebrate: false,
    },
    {
      id: 4,
      title: 'Take a quick tour',
      cta: 'Start tour',
      skippable: false,
      goal: { type: 'cta_clicked' },
      action: {
        operation: 'self',
        type: 'link',
        value: '/tour',
      },
      celebrate: false,
    },
  ],
};
