import React from 'react';
import { CardBase } from './CardBase';
import { useHistory } from 'react-router-dom';
import { CursorClick01 } from '@commandbar/design-system/icons/react';
import { ICON_WIDTH } from './styles';

export const NoCodeInstructionsCard = () => {
  const history = useHistory();
  return (
    <CardBase
      title="Install in a no-code app"
      description="Guides for Webflow, WordPress, Wix, and more"
      onClick={() => history.push('/installation-guide/no-code')}
      leftIcon={<CursorClick01 width={ICON_WIDTH} color="#9951F4" />}
      background="linear-gradient(96.46deg, #EDDEFF -5.42%, #FFFFFF 18.75%, #FFFFFF 24.12%)"
      iconShadow="0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(153, 81, 244, 0.16), inset 1.5px -1.5px 3px rgba(153, 81, 244, 0.25)"
    />
  );
};
