// NOTE: This file is only for reporting for admin users (editor, cb.com, etc.), not for end users.
/*******************************************************************************/
/* Imports
/*******************************************************************************/
/* internal imports */
import logToSlack from '../client/slack';

/* external imports */
import { IUserType } from '../middleware/types';
import { getOperatingSystem } from './operatingSystem';
import { getSentry } from './sentry';
import { H } from 'highlight.run';

/*******************************************************************************/
/* Meat and Potatoes
/*******************************************************************************/
export interface IIdentifyUserOptions {
  segment?: boolean;
  highlight?: boolean;
  sentry?: boolean;
}
export const identifyUserBase = (user: IUserType, options: IIdentifyUserOptions) => {
  const email = user ? user.email : undefined;
  const organization = user ? user.organization : undefined;
  const organization_name = user ? user.organization_name : undefined;
  const { segment = true, highlight = true, sentry = true } = options;
  if (segment) {
    (window as any).analytics?.identify(email, {
      email: email,
      organization: organization,
      organization_name: organization_name,
    });
  }

  if (highlight && email) {
    H.identify(email, {
      email: email,
      organization: organization || '',
      organization_name: organization_name || '',
    });

    if (sentry) {
      getSentry()?.addBreadcrumb({
        type: 'identify_user',
        data: { organization: organization, organization_name: organization_name, email: email },
        level: 'info',
      });
    }
  }
};
export interface IReportEventOptions {
  segment?: boolean;
  highlight?: boolean;
  sentry?: boolean;
  slack?: boolean;
  payloadMessage?: string;
  eventProps?: any;
  messageType?: 'editor_actions' | 'editor_documentation';
  requireEmailToReport?: boolean;
  backupEmail?: string;
}
export const reportEventBase = (user: IUserType | undefined | null, event: string, options: IReportEventOptions) => {
  const {
    segment = true,
    highlight = true,
    sentry = true,
    slack = false,
    payloadMessage = '',
    eventProps = {},
    messageType = 'editor_actions',
    requireEmailToReport = true,
    backupEmail = undefined,
  } = options;
  const email = user ? user.email : backupEmail;
  const source = getOperatingSystem();
  const organization = user ? user.organization : undefined;
  const organization_name = user ? user.organization_name : undefined;
  const inProduction = !(process.env.NODE_ENV && process.env.NODE_ENV === 'development');

  /** If we're requiring email, exclude commandbar.com emails */
  if (!requireEmailToReport || (email && !email.includes('@commandbar.com'))) {
    if (inProduction) {
      if (segment) {
        (window as any).analytics?.track(
          event,
          {
            email: email,
            source: source,
            organization: organization,
            organization_name: organization_name,
            item: payloadMessage,
            ...eventProps,
          },
          {
            context: {
              groupId: organization, // used by June
            },
          },
        );
      }
      if (highlight) {
        H.track(event, {
          email: email || '',
          organization: organization || '',
          organization_name: organization_name || '',
          item: payloadMessage,
        });
      }

      if (sentry) {
        getSentry()?.addBreadcrumb({
          type: 'event',
          category: event,
          data: { organization: organization, organization_name: organization_name, email: email },
          message: payloadMessage,
          level: 'info',
        });
      }
    }

    if (slack) {
      switch (messageType) {
        case 'editor_actions':
          logToSlack({
            type: 'editor_changes',
            title: `${event} in ${organization_name} (${organization}) by ${email}`,
            message: payloadMessage,
            metadata: { orgId: organization },
          });
          break;
        case 'editor_documentation':
          logToSlack({
            type: 'editor_changes',
            title: `${email} visited documentation`,
            message: payloadMessage,
            metadata: { orgId: organization },
          });
          break;
      }
    }
  }
};
