import React, { CSSProperties } from 'react';
import { Helmet } from 'react-helmet';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import { Typography } from './index';

const Page = (props: {
  section?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  style?: CSSProperties;
  headerChildren?: React.ReactNode;
  headerRowChildren?: React.ReactNode;
  whiteBg?: boolean;
  whiteHeader?: boolean;
  disableVerticalContentPadding?: boolean;
}) => {
  const windowWidth = useWindowSize().width;
  const isSmallScreen = windowWidth < 900;

  return (
    <div
      style={{
        background: props.whiteBg === undefined || props.whiteBg ? '#fff' : '#F3F4F5',
        width: '100%',
        ...(props.style || {}),
      }}
    >
      <Helmet>
        <title>{props.title ? `${props.title} | ` : ''}Command AI</title>
      </Helmet>

      <div
        style={{
          background: props.whiteBg === undefined || props.whiteHeader ? '#fff' : undefined,
          padding: `24px ${isSmallScreen ? '24px' : '32px'}`,
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          zIndex: 998,
          position: 'sticky',
          top: 0,
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
          {(props.section || props.title || props.description || props.headerChildren) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <div
                    style={{
                      fontSize: '11px',
                      fontWeight: 600,
                      textTransform: 'uppercase',
                      color: '#141414',
                      opacity: 0.5,
                    }}
                  >
                    {props?.section}
                  </div>
                  {props.title && (
                    <Typography.Title level={1} style={{ marginBottom: 5, opacity: 1, fontSize: '24px' }}>
                      {props.title}
                    </Typography.Title>
                  )}
                  <div style={{ fontSize: '14px', color: '#797C85', fontWeight: 500 }}>{props?.description}</div>
                </div>
              </span>
              {props.headerChildren}
            </div>
          )}
          {props.headerRowChildren}
        </div>
      </div>
      <div
        style={{
          padding: `${props.disableVerticalContentPadding ? '0px' : '24px'} ${isSmallScreen ? '24px' : '32px'}`,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Page;
