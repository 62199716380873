import { IChecklistItem } from '@commandbar/internal/middleware/types';
import React from 'react';
import {
  FormRow,
  Input,
  InputContainer,
  Select,
  StyledLabel,
  Tooltip,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { isSelectorFragile, SELECTOR_WARNING_TEXT } from '../commands/CommmandDetail/ActionDetailPanel';
import { EventAutoComplete } from '../commands/CommmandDetail/EventAutoComplete';
import { BorderedContainer } from '../components/styled';
import { AlertTriangle } from '@commandbar/design-system/icons/react';
import ConditionGroupEditor from '../conditions/ConditionGroupEditor';
import { ClickRecorder } from '../components/ClickRecorder';
import { CommandSelect } from '../CommandSelect';

const GoalEditor = ({
  item,
  onChange = () => {
    return null;
  },
}: {
  item: IChecklistItem;
  onChange?: (goal: IChecklistItem['goal']) => void;
}) => {
  const { checklists, commands, nudges } = useAppContext();
  const { goal, cta, description } = item;

  return (
    <div>
      <FormRow
        title="Mark complete when..."
        input={
          <Select
            value={goal.type}
            style={{ width: '100%' }}
            onChange={(e: IChecklistItem['goal']['type'] | null | undefined) => {
              if (e === 'cta_clicked') {
                onChange({ type: 'cta_clicked' });
              } else if (e === 'command_executed') {
                onChange({ type: 'command_executed', meta: { command: '' } });
              } else if (e === 'page_visited') {
                onChange({ type: 'page_visited', value: '' });
              } else if (e === 'element_clicked') {
                onChange({ type: 'element_clicked', value: '' });
              } else if (e === 'conditions_met') {
                onChange({ type: 'conditions_met', expression: { type: 'LITERAL', value: true } });
              } else if (e === 'event_tracked') {
                onChange({ type: 'event_tracked', event: '' });
              }
            }}
          >
            <Select.Option value={'cta_clicked'}>{`${cta || description ? 'CTA' : 'Step'} clicked`}</Select.Option>
            <Select.Option value={'command_executed'}>Action executed</Select.Option>
            <Select.Option value={'page_visited'}>Page visited</Select.Option>
            <Select.Option value={'element_clicked'}>Element clicked</Select.Option>
            <Select.Option value={'conditions_met'}>When conditions are met</Select.Option>
            <Select.Option value={'event_tracked'}>When an event is tracked</Select.Option>
          </Select>
        }
      />

      {goal.type === 'command_executed' && (
        <FormRow
          title="Action:"
          input={
            <CommandSelect
              standalone
              value={
                commands.find((c) => c.id.toString() === goal.meta.command) ? parseFloat(goal.meta.command) : undefined
              }
              type={'action'}
              commandFilter={(c) => !['helpdoc', 'video', 'link'].includes(c.template.type)}
              onChange={(e) => !!e && onChange({ type: 'command_executed', meta: { command: e.toString() } })}
              commands={commands}
            />
          }
        />
      )}
      {goal.type === 'page_visited' && (
        <FormRow
          title="Page location contains:"
          input={
            <Input
              value={goal.value}
              onChange={(e) => !!e && onChange({ type: 'page_visited', value: e.target.value })}
            />
          }
        />
      )}
      {goal.type === 'element_clicked' && (
        <>
          <FormRow
            title="Element:"
            input={
              <>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      onKeyDown={(e) => e.stopPropagation()}
                      value={goal.value}
                      onChange={(e) => onChange({ type: 'element_clicked', value: e.target.value })}
                      placeholder="CSS selector or XPath"
                      suffix={
                        <ClickRecorder
                          onValueChange={(e) => onChange({ type: 'element_clicked', value: e.selector })}
                        />
                      }
                    />
                    {isSelectorFragile(goal.value) && (
                      <Tooltip content={SELECTOR_WARNING_TEXT}>
                        <AlertTriangle color="rgb(216, 150, 20)" style={{ marginLeft: '8px' }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
      {goal.type === 'conditions_met' && (
        <FormRow
          title="Conditions:"
          pushInputIntoNextLine
          input={
            <BorderedContainer>
              <ConditionGroupEditor
                onChange={(expr) => onChange({ type: 'conditions_met', expression: expr })}
                expr={goal.expression}
                // INFO: server evaluated conditions do not work for the goal editor at this time
                excludeCategories={['Event']}
                excludeConditionTypes={[
                  'intent',
                  'user_property',
                  'named_rule',
                  'audience',
                  'help_doc_interaction',
                  'survey_response',
                ]}
              />
            </BorderedContainer>
          }
        />
      )}
      {goal.type === 'event_tracked' && (
        <FormRow
          title="Event:"
          input={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <InputContainer>
                <StyledLabel>Event name</StyledLabel>
                <EventAutoComplete
                  onChange={(event) => onChange({ type: 'event_tracked', event: event })}
                  initialEventName={goal.event}
                  checklists={checklists}
                  nudges={nudges}
                  size="middle"
                />
              </InputContainer>

              <InputContainer>
                <StyledLabel>Event properties</StyledLabel>
                <ConditionGroupEditor
                  onChange={(expr) => {
                    onChange({
                      ...goal,
                      condition_group: expr,
                    });
                  }}
                  expr={
                    goal.condition_group || {
                      type: 'AND',
                      exprs: [],
                    }
                  }
                  includeCategories={['Event']}
                />
              </InputContainer>
            </div>
          }
        />
      )}
    </div>
  );
};

export default GoalEditor;
