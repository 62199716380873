import React from 'react';
import { handleStyleElementClick } from './helpers';
import { SkeletonProps } from './types';

const NoResultsViewSkeleton = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      width="470"
      height="288"
      viewBox="0 0 470 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
    >
      <g filter="url(#filter0_d_92_3694)">
        <g clipPath="url(#clip0_92_3694)">
          <rect x="26" y="19" width="418.257" height="236.535" rx="8.71369" fill="white" />
          <rect x="25.5643" y="18.5643" width="419.129" height="56.639" fill="white" />
          <rect x="43.4277" y="35" width="24.0036" height="24" rx="12" fill="#E7E7E9" />
          <rect x="79.4316" y="38.1702" width="139.419" height="17.4274" rx="3.48548" fill="#E7E7E9" />
          <rect x="26" y="73.8962" width="522.822" height="0.435685" fill="black" fillOpacity="0.01" />
          <rect x="25.5643" y="18.5643" width="419.129" height="56.639" stroke="#E7E7E9" strokeWidth="0.871369" />
          <rect id="bar-styles-noResults" width="418.257" height="125" transform="translate(26 109.622)" fill="white" />
          <rect x="155.129" y="152" width="160" height="16" rx="4" fill="#E7E7E9" />
          <rect x="195.129" y="184" width="80" height="8" rx="2" fill="#E7E7E9" />
          <rect width="418.257" height="20.9129" transform="translate(26 234.622)" fill="#EDEDEE" />
          <rect x="414.889" y="239.523" width="12" height="12" rx="3.48548" fill="white" />
        </g>
        <rect
          x="25.5643"
          y="18.5643"
          width="419.129"
          height="237.407"
          rx="9.14938"
          stroke="#EDEDEE"
          strokeWidth="0.871369"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_92_3694"
          x="0.730566"
          y="0.701276"
          width="468.797"
          height="287.075"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97095" />
          <feGaussianBlur stdDeviation="12.1992" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.06 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_92_3694" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_92_3694" result="shape" />
        </filter>
        <clipPath id="clip0_92_3694">
          <rect x="26" y="19" width="418.257" height="236.535" rx="8.71369" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoResultsViewSkeleton;
