import { createContext, useContext } from 'react';
import { IHeapConfig } from './utils';

interface IHeapConfigContext {
  heapConfig: IHeapConfig;
  setHeapConfig: Function;
}

export const HeapConfigContext = createContext<IHeapConfigContext>({
  heapConfig: {
    orgID: '',
    send_events_to_heap: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHeapConfig: () => {},
});
export const HeapConfigProvider = HeapConfigContext.Provider;
export const useHeapConfigContext = () => useContext(HeapConfigContext);
