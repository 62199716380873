import { EditableThemeField, ThemeFieldGroup } from './editableFieldTrees';
import type { IThemeType, IThemeV2Type } from '@cb/types/entities/theme';
import { DEFAULT_THEME } from '@commandbar/internal/client/themesV2/defaults';
import type { IThemeAnimatedWidgetType } from '@commandbar/internal/middleware/theme';
import { getAnimationType } from '@commandbar/internal/client/themesV2/components/animations/helpers';

const normalize = (val: number, max: number, min: number) => {
  return (val - min) / (max - min);
};

const hexToAlpha = (alphaHexString: string) => {
  return Math.round(normalize(parseInt(alphaHexString, 16), 255, 0) * 100);
};

const alphaToHex = (opacityPercent: number) => {
  const val = Math.round((opacityPercent / 100) * 255).toString(16);
  if (val.length === 1) {
    return '0' + val;
  }
  return val;
};

const isRGBA = (color: string) => {
  const rgbaPattern = /rgba?\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*(0|1|0?\.\d+))?\s*\)/;
  const rgba = color.match(rgbaPattern);
  if (rgba && rgba[1] && rgba[2] && rgba[3]) {
    return rgba;
  }
};

const isCalculatedColorVal = (theme: IThemeType | undefined, mode: 'light_mode' | 'dark_mode', color: string) => {
  const pattern = /rgb\(from\s+([\w\-(),\s]+)\s+r\s+g\s+b\s+\/\s+(\d*\.?\d+)\)/;
  const match = color.match(pattern);

  if (match) {
    return { value: getVariableValue(theme, mode, match[1]), opacity: parseFloat(match[2]) * 100 };
  }
};

const isColorMixInVal = (theme: IThemeType | undefined, mode: 'light_mode' | 'dark_mode', color: string) => {
  const colorMixRegex = /^color-mix\(\s*in\s*srgb\s*,\s*([^,]+)\s+(\d+)%\s*,\s*([^,]+)\s*(\d+%)?\s*\)$/;
  const match = color.match(colorMixRegex);

  if (match) {
    const color1 = match[1];
    const percentage1 = parseInt(match[2], 10);
    const color2 = match[3];
    const percentage2 = match[4] ? parseInt(match[4], 10) : 100 - percentage1;

    const rgb2 = color2 === 'white' ? [255, 255, 255] : color2 === 'black' ? [0, 0, 0] : undefined;

    const color1Value = getVariableValue(theme, mode, color1); // parse variable reference to color value
    const colorAndOpacity1 = getColorAndOpacity(theme, mode, color1Value);
    if (colorAndOpacity1.rgb) {
      const rgb1 = isRGBA(colorAndOpacity1.rgb);

      if (rgb2 && rgb1) {
        const p1 = percentage1 / 100;
        const p2 = percentage2 / 100;
        const r = Math.round(parseInt(rgb1[1]) * p1 + rgb2[0] * p2);
        const g = Math.round(parseInt(rgb1[2]) * p1 + rgb2[1] * p2);
        const b = Math.round(parseInt(rgb1[3]) * p1 + rgb2[2] * p2);
        const a = Math.round(colorAndOpacity1.opacity * p1 + percentage2);
        return { rgb: 'rgb(' + r + ', ' + g + ', ' + b + ')', opacity: a };
      }
    }
  }
};

export const RGBAToHexA = (color: string | undefined) => {
  if (!color) return '';
  if (isRGBA(color)) {
    const value = color
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .map((string) => parseFloat(string)) // Converts them to numbers
      .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
      .map((number) => number.toString(16)) // Converts numbers to hex
      .map((string) => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
      .join('')
      .toUpperCase();
    return '#' + value;
  }

  return color;
};

export const getColorAndOpacity = (
  theme: IThemeType | undefined,
  mode: 'light_mode' | 'dark_mode',
  color: string | undefined,
) => {
  if (!color) return {};

  if (color.startsWith('#')) {
    let fullHexColor = color;
    if (color.length === 4) {
      fullHexColor = `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
    }
    const r = parseInt(fullHexColor.slice(1, 3), 16),
      g = parseInt(fullHexColor.slice(3, 5), 16),
      b = parseInt(fullHexColor.slice(5, 7), 16),
      a = fullHexColor.length === 9 ? hexToAlpha(fullHexColor.slice(7, 9)) : 100;

    return { rgb: 'rgb(' + r + ', ' + g + ', ' + b + ')', opacity: a };
  }

  const rgba = isRGBA(color);
  if (rgba) {
    return {
      rgb: 'rgb(' + rgba[1] + ', ' + rgba[2] + ', ' + rgba[3] + ')',
      opacity: rgba[4] ? parseFloat(rgba[4]) * 100 : 100,
    };
  }

  const colorAndOpacity = isCalculatedColorVal(theme, mode, color);
  if (colorAndOpacity) {
    return { rgb: colorAndOpacity.value, opacity: colorAndOpacity.opacity };
  }

  const colorMixInVal = isColorMixInVal(theme, mode, color);
  if (colorMixInVal) {
    return { rgb: colorMixInVal.rgb, opacity: colorMixInVal.opacity };
  }

  return { rgb: color, opacity: 1 };
};

export const isValidColor = (color: string) => {
  const s = new Option().style;
  s.color = color;
  return s.color !== '';
};

export const handleColorInput = (color: string, opacity: number) => {
  if (isRGBA(color)) {
    const hexColor = RGBAToHexA(color);
    return hexColor.length === 9 ? hexColor : hexColor + alphaToHex(opacity);
  }

  if (color.startsWith('#')) {
    return color.length === 9 ? color : color + alphaToHex(opacity);
  }

  const hexColor = '#' + color;
  if (isValidColor('#' + color)) {
    return hexColor.length === 9 ? hexColor : hexColor + alphaToHex(opacity);
  }

  return color;
};

export const getDisplayValue: (
  theme: IThemeType | undefined,
  mode: 'light_mode' | 'dark_mode',
  type: EditableThemeField['type'],
  value: string | undefined,
  widget?: IThemeAnimatedWidgetType,
) => { value: string; opacity?: number } | undefined = (
  theme: IThemeType | undefined,
  mode: 'light_mode' | 'dark_mode',
  type: EditableThemeField['type'],
  value: string | undefined,
  widget?: IThemeAnimatedWidgetType,
) => {
  if (type === 'animation' && widget) {
    const animationType = getAnimationType(widget, theme?.themeV2_draft, mode);

    return { value: animationType };
  }

  // Animations handle their value differently and we dont want to check if theres a value for them since that's determined by the `anim` css var values
  if (!value) return;

  if (type === 'size') {
    if (value.endsWith('px')) return { value: value.slice(0, -2) };
  }

  if (type === 'color') {
    const isRgba = isRGBA(value);
    if (isRgba) {
      const hexVal = RGBAToHexA(value);
      if (isRgba[4]) {
        return { value: hexVal?.slice(1, 7), opacity: parseFloat(isRgba[4]) * 100 };
      }
      return { value: hexVal?.slice(1, 7), opacity: 100 };
    }

    const colorAndOpacity = isCalculatedColorVal(theme, mode, value);
    if (colorAndOpacity && colorAndOpacity.value) {
      return {
        value: getDisplayValue(theme, mode, type, colorAndOpacity.value)?.value ?? colorAndOpacity.value,
        opacity: colorAndOpacity.opacity,
      };
    }

    const colorMixInVal = isColorMixInVal(theme, mode, value);
    if (colorMixInVal) {
      return {
        value: getDisplayValue(theme, mode, type, colorMixInVal.rgb)?.value ?? colorMixInVal.rgb,
        opacity: colorMixInVal.opacity,
      };
    }

    if (value.startsWith('#')) {
      let fullHexColor = value;
      if (value.length === 4) {
        fullHexColor = `#${value[1]}${value[1]}${value[2]}${value[2]}${value[3]}${value[3]}`;
      }
      return fullHexColor.length === 9
        ? { value: value?.slice(1, 7), opacity: hexToAlpha(fullHexColor.slice(7, 9)) }
        : { value: value, opacity: 100 };
    }
  }

  return { value: value };
};

export const isANumber = (input: string) => {
  if (typeof input !== 'string') return false;
  return !isNaN(+input) && !isNaN(parseFloat(input));
};

export const isCSSVar = (value: string | undefined) => !!value && value.startsWith('--');
export const isCSSVarReference = (value: string | undefined) => !!value && value.startsWith('var');

// first take the mode's overrides, then the lightmode overrides, and finally the default val
export const getCSSVarValue = (theme: IThemeType | undefined, mode: 'light_mode' | 'dark_mode', cssVar: string) => {
  return (
    theme?.themeV2_draft?.[mode].var_overrides[cssVar] ??
    theme?.themeV2_draft?.light_mode.var_overrides[cssVar] ??
    theme?.themeV2_draft?.[mode].var_defaults[cssVar] ??
    DEFAULT_THEME?.[mode].var_defaults[cssVar]
  );
};

export const getVariableValue = (
  theme: IThemeType | undefined,
  mode: 'light_mode' | 'dark_mode',
  variable: string,
  parentSlug?: string,
  stateSlug?: string,
): string | undefined => {
  if (!!parentSlug && !!stateSlug) {
    // this is a var that can be edited from the component editor on a per-component basis.
    // check if there are any light / dark mode component overrides that we should use
    // if not, then get the variable value from var overrides / var defaults
    const value =
      stateSlug === 'default' || stateSlug === 'active'
        ? theme?.themeV2_draft?.[mode].component_overrides?.[parentSlug]?.[variable] ??
          theme?.themeV2_draft?.light_mode.component_overrides?.[parentSlug]?.[variable]
        : theme?.themeV2_draft?.[mode].component_overrides?.[parentSlug]?.[stateSlug]?.[variable] ??
          theme?.themeV2_draft?.light_mode.component_overrides?.[parentSlug]?.[stateSlug]?.[variable];

    return value === undefined || isCSSVarReference(value) ? getVariableValue(theme, mode, variable) : value;
  }
  // this means that our variable is a CSS var or a reference to one of those CSS vars.
  // we want to get the value of the css var (or nested css var) in this case
  // unless it is not one of our variables (ie, it is a CSS var from the customer's code)
  let value = isCSSVar(variable) ? getCSSVarValue(theme, mode, variable) : variable;

  // there might be nested references, so keep going til we get a real value
  while (isCSSVarReference(value?.toString())) {
    const refVariable = value?.toString().match(/\(([^)]+)\)/);
    if (!refVariable) {
      break;
    } else {
      const updatedVal = getCSSVarValue(theme, mode, refVariable?.[1]);
      if (updatedVal) {
        value = updatedVal;
      } else {
        break;
      }
    }
  }
  return value?.toString();
};

export const variableHasBeenUpdated = (
  draftTheme: IThemeV2Type | undefined,
  mode: 'light_mode' | 'dark_mode',
  variable: string | undefined,
  parentSlug?: string,
  stateSlug?: string,
) => {
  if (!variable) return false;

  if (!!parentSlug && !!stateSlug) {
    return stateSlug === 'default' || stateSlug === 'active'
      ? draftTheme?.[mode].component_overrides?.[parentSlug]?.[variable]
      : draftTheme?.[mode].component_overrides?.[parentSlug]?.[stateSlug]?.[variable];
  } else if (isCSSVar(variable)) {
    return !!draftTheme?.[mode].var_overrides.hasOwnProperty(variable);
  }
};

export const categoryHasBeenUpdated = (
  theme: IThemeType | undefined,
  mode: 'light_mode' | 'dark_mode',
  category: ThemeFieldGroup,
  parentSlug?: string,
  stateSlug?: string,
): boolean => {
  return category.children.some((child): boolean => {
    if ('variable' in child && child.variable) {
      return variableHasBeenUpdated(theme?.themeV2_draft, mode, child.variable, parentSlug, stateSlug);
    } else if ('type' in child && child.type === 'animation' && child.widget) {
      const animationVarPrefix = `--${child.widget}-`;
      const transitionDurationVar = `${animationVarPrefix}anim-transition-duration`;
      const transitionPropertyVar = `${animationVarPrefix}anim-transition-property`;
      const transitionTimingVar = `${animationVarPrefix}anim-transition-timing`;

      return (
        variableHasBeenUpdated(theme?.themeV2_draft, mode, transitionDurationVar, parentSlug, stateSlug) ||
        variableHasBeenUpdated(theme?.themeV2_draft, mode, transitionPropertyVar, parentSlug, stateSlug) ||
        variableHasBeenUpdated(theme?.themeV2_draft, mode, transitionTimingVar, parentSlug, stateSlug)
      );
    } else {
      // It's a ThemeFieldGroup, so we recursively check its children's variables
      return categoryHasBeenUpdated(theme, mode, child as ThemeFieldGroup);
    }
  });
};
