import React, { useEffect } from 'react';
import { useAsyncCallback } from './useAsyncCallback';

type AsyncFunctionResult<T> =
  | { loading: true; result: undefined }
  | { loading: false; result: T }
  | { error: any; result: undefined };

export function useCallAsyncFunction<T>(fn: () => Promise<T>): AsyncFunctionResult<T> {
  const [result, setResult] = React.useState<AsyncFunctionResult<T>>({ loading: true, result: undefined });

  const callback = useAsyncCallback(function* () {
    try {
      const result = yield fn();
      setResult({ loading: false, result });
    } catch (error) {
      setResult({ error, result: undefined });
    }
  });

  useEffect(() => {
    callback();
  }, []);

  return result;
}
