import React from 'react';
import styled from '@emotion/styled';

import { Trash04 } from '@commandbar/design-system/icons/react';

import { IAdditionalResource, IHelpHubSearchActionsType } from '@commandbar/internal/middleware/types';

import { useAppContext } from 'editor/src/AppStateContext';

import { Panel, CB_COLORS, Skeleton, SortableList, SubHeading } from '@commandbar/design-system/components/antd';
import { HelpHubLauncherSettings } from '../components/HelpHubLauncherSettings';
import { getColorsFromCommands } from '../utils/ColorUtils';

import useSupportCTA from '../useEditor/useAdditionalResources';
import { ActionEditor, ActionType } from '../ActionEditor';
import { Container, DragHandle } from './shared';
import Sender from '../../management/Sender';
import { useIsEditorOpen } from 'editor/src/hooks';
import AutoCompleteTextArea from '../components/AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';
import IconPicker from '../components/IconPicker/IconPicker';
import { CmdSwitch, CmdButton, CmdSelect, CmdLabel } from '@commandbar/design-system/cmd';
import TextCustomizationSettings from './TextCustomizationSettings';
import ThemeCustomizationSettings from '../components/ThemeCustomizationSettings';

enum PanelKey {
  GENERAL = 'general',
  CHAT = 'chat',
  LAUNCHER = 'launcher',
  ADDITIONAL_RESOURCES = 'additional_resources',
  STRINGS = 'strings',
  THEME = 'theme',
}

const possibleTypes: ActionType[] = [
  'click',
  'execute_command',
  'help_doc',
  'video',
  'link',
  'page',
  'open_chat',
  'open_copilot',
  'open_bar',
  'nudge',
  'questlist',
];

const AddLink = ({ addNew }: { addNew: () => Promise<void> }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <CmdButton
      disabled={loading}
      onClick={async () => {
        try {
          setLoading(true);
          await addNew();
        } finally {
          setLoading(false);
        }
      }}
    >
      Add Link
    </CmdButton>
  );
};

const DeleteButton = ({ del }: { del: () => Promise<void> }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <button
      disabled={loading}
      style={{
        border: '1px solid ' + CB_COLORS.neutral300,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: loading ? CB_COLORS.neutral300 : 'white',
        opacity: loading ? 0.5 : 1,
        flex: '0 0 40px',
        height: '100%',
      }}
      onClick={async () => {
        try {
          setLoading(true);
          await del();
        } finally {
          setLoading(false);
        }
      }}
    >
      <Trash04 color={CB_COLORS.neutral600} width={16} height={16} />
    </button>
  );
};

const AdditionalResource = ({
  additionalResource,
  update,
  del,
  context,
}: {
  additionalResource: Partial<IAdditionalResource>;
  update: (recipe: (draft: IAdditionalResource) => void) => void;
  del: () => Promise<void>;
  context: Record<string, any>;
}) => {
  const { commands } = useAppContext();
  const { icon = null, icon_color = null, label } = additionalResource;

  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));

  return (
    <div
      style={{
        minHeight: '48px',
        height: 'fit-content',
        gap: 8,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '8px 0px',
        padding: '8px',
        backgroundColor: CB_COLORS.neutral0,
        maxWidth: '100%',
      }}
    >
      <DragHandle />
      <IconPicker
        icon={icon}
        color={icon_color}
        colors={getColorsFromCommands(commands)}
        onIconSelect={(icon) =>
          update((draft) => {
            draft.icon = icon;
          })
        }
        onColorSelect={(icon_color) =>
          update((draft) => {
            draft.icon_color = icon_color;
          })
        }
      />

      <div style={{ flex: 4, minWidth: 0 }}>
        <AutoCompleteTextArea
          onChange={(label) =>
            update((draft) => {
              draft.label = label;
            })
          }
          value={label}
          small={true}
          placeholder={'Label'}
          options={[...contextKeys]}
        />
      </div>

      <div style={{ flex: 6, minWidth: 0 }}>
        <ActionEditor
          possibleTypes={possibleTypes}
          lightBorder
          action={additionalResource.action}
          onActionChange={(action) => {
            if (action.type === 'dismiss' || action.type === 'snooze') return;

            update((draft) => {
              if (action) {
                draft.action = action;
              }
            });
          }}
        />
      </div>
      <DeleteButton del={del} />
    </div>
  );
};

const HelpHubSettings = () => {
  const {
    organization,
    dispatch: {
      organization: { updateSetting },
    },
    isStudio,
    commandBarReady,
    flags,
  } = useAppContext();
  const isEditorOpen = useIsEditorOpen();

  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([]);
  const { supportCTA, additionalResources, actions: supportActions } = useSupportCTA();
  const { context } = useWindowInfo();
  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));

  React.useEffect(() => {
    if (commandBarReady && (isStudio || isEditorOpen)) {
      Sender.openHelpHub();
    }
  }, [isEditorOpen, commandBarReady]);

  return (
    <Container>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.GENERAL} header="General">
        <Title>
          Enable HelpHub{' '}
          <CmdSwitch
            checked={!!organization?.helphub_enabled}
            onCheckedChange={(value) => {
              updateSetting({ helphub_enabled: value });
            }}
          />
        </Title>
        {!!organization?.in_bar_doc_search && (
          <Title>
            Show articles and files in Spotlight{' '}
            <CmdSwitch
              checked={!!organization?.in_bar_doc_search}
              onCheckedChange={(value) => {
                updateSetting({ in_bar_doc_search: value });
              }}
            />
          </Title>
        )}
        <Title>
          Show Featured Item Cards{' '}
          <CmdSwitch
            checked={!!organization?.featured_item_cards}
            onCheckedChange={(value) => {
              updateSetting({ featured_item_cards: value });
            }}
          />
        </Title>
        <Title>
          <CmdLabel tooltip="Action to perform on Enter key press in HelpHub search input">
            Default search action
          </CmdLabel>
          <CmdSelect.Menu
            value={organization.helphub_search_action}
            onValueChange={(value: IHelpHubSearchActionsType) => {
              updateSetting({ helphub_search_action: value });
            }}
          >
            <CmdSelect.SelectTrigger className="w-[38.25%] text-base font-medium py-2">
              <CmdSelect.SelectValue className="px-8" />
            </CmdSelect.SelectTrigger>
            <CmdSelect.SelectContent>
              <CmdSelect.SelectItem value="search">Search through articles and files</CmdSelect.SelectItem>
              <CmdSelect.SelectItem value="ask">Ask Copilot</CmdSelect.SelectItem>
            </CmdSelect.SelectContent>
          </CmdSelect.Menu>
        </Title>
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.LAUNCHER} header="Launcher">
        <HelpHubLauncherSettings />
      </Panel>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        panelKey={PanelKey.ADDITIONAL_RESOURCES}
        header="Additional Resources"
      >
        <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between', alignItems: 'center' }}>
          <SubHeading>Links</SubHeading>
          {additionalResources && <AddLink addNew={supportActions.additionalResources.addNew} />}
        </div>
        {additionalResources ? (
          <SortableList
            style={{ margin: '-8px 0' }}
            nodes={additionalResources.map((item) => {
              return (
                <AdditionalResource
                  additionalResource={item}
                  del={async () => {
                    await supportActions.additionalResources.delete(item.id);
                  }}
                  update={(recipe) => {
                    supportActions.additionalResources.update(item, recipe);
                  }}
                  context={context}
                  key={item.id}
                />
              );
            })}
            onSort={(oldIndex, newIndex) => {
              supportActions.additionalResources.move(oldIndex, newIndex);
            }}
            useDragHandle
          />
        ) : (
          <Skeleton active />
        )}
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          <SubHeading>Support CTA</SubHeading>

          {supportCTA ? (
            <AutoCompleteTextArea
              onChange={(e) =>
                supportActions.supportCTA.update((draft) => {
                  draft.label = e;
                })
              }
              value={supportCTA.label}
              small={true}
              placeholder={'Label'}
              options={[...contextKeys]}
            />
          ) : (
            <Skeleton.Input active />
          )}

          {supportCTA ? (
            <ActionEditor
              showLabel
              possibleTypes={possibleTypes}
              lightBorder
              action={supportCTA.action}
              onActionChange={(action) => {
                if (action.type === 'dismiss' || action.type === 'snooze') return;

                supportActions.supportCTA.update((draft) => {
                  if (action) {
                    draft.action = action;
                  }
                });
              }}
            />
          ) : (
            <Skeleton.Input active />
          )}
        </div>
      </Panel>
      {flags['release-themes-v2'] && (
        <Panel
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          panelKey={PanelKey.STRINGS}
          header="Text customization"
        >
          <TextCustomizationSettings />
        </Panel>
      )}
      {flags['release-themes-v2'] && (
        <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.THEME} header="Theme">
          <ThemeCustomizationSettings
            themeUUID={organization.helphub_custom_theme}
            setThemeUUID={(themeUUID) => {
              updateSetting({ helphub_custom_theme: themeUUID });
            }}
          />
        </Panel>
      )}
    </Container>
  );
};

export default HelpHubSettings;

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 8px 0px;
`;
