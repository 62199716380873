import { useContext, createContext } from 'react';
import { IProfileType } from '@commandbar/internal/middleware/types';

export interface TeamMembersContextProps {
  loading: boolean;
  members: IProfileType[];
  removeMember: (member: IProfileType) => void;
  addMember: (email: string, role: IProfileType['role']) => void;
  changeRole: (email: string, role: IProfileType['role']) => void;
}

const TeamMembersContext = createContext<TeamMembersContextProps>({
  loading: false,
  members: [],
  removeMember: () => {
    return;
  },
  addMember: () => {
    return;
  },
  changeRole: () => {
    return;
  },
});

export const useTeamMembersContext = (): TeamMembersContextProps => useContext(TeamMembersContext);

export default TeamMembersContext;
