import * as t from 'io-ts';

import { read, decodeToPromise, listObject } from './generics';
import { HistoryEventV } from './historyEvent';
import * as axiosInstance from './network';

export { RecordSettingsV, RecordSettingsByContextKeyV } from './RecordSettingsV';
export { OrganizationV } from './OrganizationV';

export const ReleaseV = t.type({
  created_by: t.union([t.string, t.null]),
  created: t.string,
  history_event: HistoryEventV,
  id: t.number,
  notes: t.string,
  tags: t.array(t.string),
});

export const ReleaseStepV = t.type({
  created: t.string,
  created_by: t.union([t.string, t.null]),
  current: t.union([t.boolean, t.null]),
  env: t.string,
  id: t.number,
  next_env: t.union([t.null, t.string]),
  release: ReleaseV,
});

export const EnvReleaseInfoV = t.intersection([
  t.type({
    environment: t.string,
  }),
  t.partial({
    release_step: ReleaseStepV,
    release: ReleaseV,
  }),
]);

const ReleasesViewV = t.type({
  environments: t.array(EnvReleaseInfoV),
  latest_history_event: t.union([HistoryEventV, t.null]),
  latest_release: t.union([ReleaseV, t.null]),
});

const DiffV = t.unknown;

export class Releases {
  public static readView = read(ReleasesViewV, 'releases/view');
  public static createRelease = async (historyEventId: number, notes: string, tags: string[]) => {
    const result = await axiosInstance.post(
      `/releases/` + (historyEventId > 0 ? `?history_event_id=${historyEventId}` : ''),
      { notes, tags },
    );
    return await decodeToPromise(t.exact(ReleaseV), result.data);
  };
  public static readReleaseStepsForEnv = async (env: string) => {
    const result = await axiosInstance.get(`release_steps/?env=${encodeURIComponent(env)}`);

    return await decodeToPromise(t.array(t.exact(ReleaseStepV)), result.data);
  };
  public static rollback = async (releaseStepId: number) => {
    const result = await axiosInstance.post(`/release_steps/${releaseStepId}/rollback/`);
    return await decodeToPromise(t.exact(ReleaseStepV), result.data);
  };
  public static readDiff = async (fromId: string, toId: string) => {
    const result = await axiosInstance.get(
      `release_steps/diff/?a=${encodeURIComponent(toId)}` +
        (fromId !== 'history_event.-1' ? `&b=${encodeURIComponent(fromId)}` : ``),
    );

    return await decodeToPromise(DiffV, result.data);
  };
  public static promoteReleaseStep = async (releaseStepId: number) => {
    const result = await axiosInstance.post(`/release_steps/${releaseStepId}/promote/`);
    return await decodeToPromise(t.any, result.data);
  };

  public static promoteReleaseToFirstEnv = async (releaseId: number) => {
    const result = await axiosInstance.post(`/releases/${releaseId}/promote_to_first_env/`);
    return await decodeToPromise(t.exact(ReleaseV), result.data);
  };

  // fixme - add pagination support
  public static listReleases = listObject(ReleaseV, 'releases');
}
