'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import dayjs from 'dayjs';

import { CmdPopover, CmdPopoverContent, CmdPopoverSelectTrigger } from '../popover';
import { cn } from '../util';
import { CmdCalendar } from './calendar';
import { Calendar } from '@commandbar/design-system/icons/react';
import { CmdToggleButton } from '../button';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const getDateDifferenceInDays = (start: string, end: string): number => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  return endDate.diff(startDate, 'day');
};

const getInitialFilterKey = (timeFilterRange: DateFilter): string => {
  const daysDiff = getDateDifferenceInDays(timeFilterRange.start_date, timeFilterRange.end_date);
  switch (daysDiff) {
    case 0:
      return '1';
    case 7:
      return '7';
    case 30:
      return '30';
    case 90:
      return '90';
    default:
      return '0';
  }
};

export function CmdCalendarRangePicker({
  className,
  onDateChange,
  timeFilterRange,
}: React.HTMLAttributes<HTMLDivElement> & { onDateChange?: (date: DateRange) => void; timeFilterRange: DateFilter }) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: dayjs(timeFilterRange?.start_date).toDate() || dayjs().format('YYYY-MM-DD'),
    to: dayjs(timeFilterRange?.end_date).toDate() || dayjs().format('YYYY-MM-DD'),
  });

  const [filterKey, setFilterKey] = React.useState<string>(getInitialFilterKey(timeFilterRange));

  const handleFilterChange = (value: string): void => {
    setFilterKey(value);
    const today = new Date();
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    if (value === '1') {
      setDate({ from: startDate, to: startDate });
    } else {
      startDate.setDate(today.getDate() - Number(value));
      setDate({ from: startDate, to: today });
    }

    onDateChange &&
      onDateChange({
        from: startDate,
        to: today,
      });
  };

  const handleCalendarClick = (range: DateRange | undefined) => {
    setFilterKey('0');
    if (range) {
      setDate(
        (prevDate): DateRange => ({
          from: range.from,
          to: prevDate?.from === range?.from ? range?.to : undefined,
        }),
      );
      if (date?.from && date?.from === range?.from) {
        onDateChange?.(range);
      }
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <CmdPopover>
        <CmdPopoverSelectTrigger>
          <Calendar />
          {date?.from ? (
            date.to ? (
              dayjs(date.from).isSame(dayjs(date.to)) ? (
                <>{format(date.from, 'LLL dd, y')}</>
              ) : (
                <>
                  {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                </>
              )
            ) : (
              <>{format(date.from, 'LLL dd, y')} - To</>
            )
          ) : (
            <span>Pick a date</span>
          )}
        </CmdPopoverSelectTrigger>
        <CmdPopoverContent className="w-auto" align="start">
          <div className="flex">
            <CmdToggleButton
              activeKey={filterKey}
              onChange={handleFilterChange}
              tabs={[
                { label: 'Today', key: '1' },
                { label: 'Last 7 days', key: '7' },
                { label: 'Last 30 days', key: '30' },
                { label: 'Last 90 days', key: '90' },
              ]}
            />
            <CmdCalendar
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={handleCalendarClick}
              numberOfMonths={2}
            />
          </div>
        </CmdPopoverContent>
      </CmdPopover>
    </div>
  );
}
