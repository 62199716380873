import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { ConditionFieldInput } from '../ConditionFieldInput';
import { EventPropertyCondition as EventPropertyConditionType } from '@commandbar/internal/middleware/conditions';
import { ConditionProps } from '../../types';

export const EventPropertyCondition: React.FC<ConditionProps<EventPropertyConditionType>> = () => {
  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <ConditionFieldInput />
      <OperatorSelect />
      <ConditionValueInput />
    </Row>
  );
};
