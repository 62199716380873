import { PlusCircle, Lightning01, FaceIdSquare } from '@commandbar/design-system/icons/react';
import {
  DashboardCardBase,
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
} from '@commandbar/design-system/components/antd';
import { useIntegrationsContext } from '../util/IntegrationContext';
import { COPILOT_PARENT_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

export const HelpCenterCard = () => {
  const { helpDocIntegrations } = useIntegrationsContext();
  const integrated = helpDocIntegrations.filter(({ sources }) => sources && sources?.length > 0);

  return (
    <DashboardCardBase
      isNew={true}
      styles={{
        iconShadow:
          ' 0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(160, 87, 253, 0.16), inset 1.5px -1.5px 3px rgba(160, 87, 253, 0.25)',
        background: 'linear-gradient(99.43deg, #F4EBFF 6.02%, #FFFFFF 26.86%)',
      }}
      icon={<Lightning01 width={DASHBOARD_ICON_WIDTH} color="#A057FD" />}
      title="AI-powered Help"
      description="Add an AI-powered copilot and help center to your app."
      actions={[
        {
          icon: <PlusCircle width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: integrated.length > 0 ? 'Configure HelpHub' : 'Connect a Help Center',
          to: '/integrations',
        },
        {
          icon: <FaceIdSquare width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: 'Setup Copilot',
          to: `/editor${COPILOT_PARENT_ROUTE}`,
        },
      ]}
    />
  );
};
