import React, { CSSProperties, useEffect, useState } from 'react';
import { CmdSelect, CmdTooltip, CmdLabel, CmdButton, CmdInput } from '@commandbar/design-system/cmd';
import { EditableThemeAnimationField } from './editableFieldTrees';
import { CB_COLORS } from '@commandbar/design-system/colors';
import type { IThemeType, IThemeV2Type } from '@cb/types/entities/theme';
import { getCSSVarValue, getVariableValue, variableHasBeenUpdated } from './helpers';
import { ReverseLeft } from '@commandbar/design-system/icons/react';
import type { IThemeAnimatedWidgetType, IThemeAnimationType } from '@cb/types/entities/theme';
import {
  convertCSSDurationToMilliseconds,
  getAnimationVariables,
  getAnimationPresetVariables,
  getAnimationType,
} from '@commandbar/internal/client/themesV2/components/animations/helpers';

/**
 * This function is somewhat duplicated functionality from the internal/client/themesV2/components/animations/helpers
 * it's needed because the animation styles are treated/calculated differently in the context of editing an animation
 */
const getAnimationStyles = (
  widget: IThemeAnimatedWidgetType,
  theme?: IThemeType,
  mode: 'light_mode' | 'dark_mode' = 'light_mode',
) => {
  const animationType = getAnimationType(widget, theme?.themeV2_draft, mode);
  const vars = getAnimationVariables(widget);
  const presetVars = getAnimationPresetVariables(animationType);

  // Transform Origin's are smartly calculated later on based on a widget's position on the screen so we just use a bottom right default here
  const transformOrigin = animationType === 'scale' ? 'bottom right' : 'center';

  const baseAnimStyles = {
    transitionProperty: getVariableValue(theme, mode, `var(${presetVars.transitionProperty})`) as string,
    transitionTimingFunction: getVariableValue(theme, mode, `var(${presetVars.transitionTiming})`) as string,
    transform: getVariableValue(theme, mode, `var(${presetVars.transformInitial})`) as string,
    transitionDuration: getVariableValue(theme, mode, `var(${vars.transitionDuration})`) as string,
    transformOrigin,
  };

  return {
    initial: {
      ...baseAnimStyles,
      opacity: 0,
    },
    animated: {
      ...baseAnimStyles,
      opacity: 1,
      transform: getVariableValue(theme, mode, `var(${presetVars.transformAnimated})`) as string,
    },
  };
};

const AnimatedBox = ({
  theme,
  mode,
  field,
}: {
  theme?: IThemeType;
  mode: 'light_mode' | 'dark_mode';
  field: EditableThemeAnimationField;
}) => {
  const [isHover, setHover] = useState(false);
  const [isAnimating, setAnimating] = useState(false);
  const animStyles = getAnimationStyles(field.widget, theme, mode);

  useEffect(() => {
    if (isHover) {
      setTimeout(() => {
        setAnimating(true);
      }, 250);
    } else {
      setAnimating(false);
    }
  }, [isHover]);

  // The desired behavior is when we in the container of the box, reset the box to be invisible and play the animation, to do that we need to split out the animation transition css properties
  const styles: CSSProperties = { opacity: 1, transformOrigin: animStyles.animated.transformOrigin };

  // 1. when we hover, we reset the opacity to 0 and the initial transform
  if (isHover && !isAnimating) {
    styles.opacity = 0;
    styles.transform = animStyles.initial.transform;
  }

  // 2. Then once we're hovering, with an arbitrary delay, we apply the animated styles to register that this will now be transitioned/transformed
  if (isAnimating) {
    styles.transitionProperty = animStyles.animated.transitionProperty;
    styles.transitionDuration = animStyles.animated.transitionDuration;
    styles.transitionTimingFunction = animStyles.animated.transitionTimingFunction;
    styles.transform = animStyles.animated.transform;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f9f9f9',
        padding: '28px 0',
        borderRadius: '8px',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          width: '64px',
          height: '64px',
          border: '1px solid #B2B2B2',
          borderRadius: '4px',
          backgroundColor: CB_COLORS.white,
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)',
          ...styles,
        }}
      />
    </div>
  );
};

type AnimationEditorProps = {
  theme?: IThemeType;
  mode: 'light_mode' | 'dark_mode';
  field: EditableThemeAnimationField;
  onChange: (changes: Record<string, string>) => void;
  revertVariable: (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => IThemeV2Type;
  onBlur: () => void;
};

export const AnimationEditor = ({ onChange, field, theme, mode, revertVariable, onBlur }: AnimationEditorProps) => {
  const vars = getAnimationVariables(field.widget);
  const durationMsValue = convertCSSDurationToMilliseconds(getVariableValue(theme, mode, vars.transitionDuration), NaN);

  const current = {
    [vars.transitionProperty]: getVariableValue(theme, mode, vars.transitionProperty) as string,
    [vars.transitionTiming]: getVariableValue(theme, mode, vars.transitionTiming) as string,
    [vars.transitionDuration]: isNaN(durationMsValue) ? '' : durationMsValue.toString(),
  };

  const animationType = getAnimationType(field.widget, theme?.themeV2_draft, mode);

  const handleAnimationTypeChange = (animationType: IThemeAnimationType) => {
    const presetVars = getAnimationPresetVariables(animationType);
    const transitionProperty = getCSSVarValue(theme, mode, presetVars.transitionProperty) as string;
    const transitionTiming = getCSSVarValue(theme, mode, presetVars.transitionTiming) as string;

    // Intentionally don't spread the value since the css properties can differ when animation type changes
    onChange({
      [vars.transitionProperty]: transitionProperty,
      [vars.transitionTiming]: transitionTiming,
    });
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...current,
      [vars.transitionDuration]: `${e.target.value}ms`,
    });
  };

  const durationHasBeenUpdated = variableHasBeenUpdated(theme?.themeV2_draft, mode, vars.transitionDuration);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <AnimatedBox theme={theme} mode={mode} field={field} />
      <div
        style={{
          position: 'relative',
          display: 'grid',
          justifyContent: 'space-between',
          gridTemplateColumns: '50% 50%',
          gap: '8px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CmdTooltip message={field.label}>
            <CmdLabel
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
            >
              {field.label}
            </CmdLabel>
          </CmdTooltip>
          <CmdLabel tooltip={field.tooltip} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CmdSelect.Menu value={animationType} defaultValue="instant" onValueChange={handleAnimationTypeChange}>
            <CmdSelect.SelectTrigger className="w-full text-left py-0">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                  gap: '4px',
                }}
              >
                <CmdSelect.SelectValue />
              </div>
            </CmdSelect.SelectTrigger>
            <CmdSelect.SelectContent>
              <CmdSelect.SelectItem value="instant">Instant</CmdSelect.SelectItem>
              <CmdSelect.SelectItem value="fade">Fade</CmdSelect.SelectItem>
              <CmdSelect.SelectItem value="spring">Spring</CmdSelect.SelectItem>
              <CmdSelect.SelectItem value="scale">Scale</CmdSelect.SelectItem>
            </CmdSelect.SelectContent>
          </CmdSelect.Menu>
        </div>
        {animationType !== 'instant' && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CmdLabel
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', userSelect: 'none' }}
              >
                Duration
              </CmdLabel>
            </div>
            <CmdInput
              fullWidth
              suffixElement={
                durationHasBeenUpdated ? (
                  <CmdButton
                    style={{ height: '20px', padding: '4px' }}
                    variant="link"
                    icon={<ReverseLeft />}
                    onClick={() => revertVariable(vars.transitionDuration)}
                    tooltip="Reset"
                  />
                ) : (
                  <div style={{ width: '30px' }}></div>
                )
              }
              value={current[vars.transitionDuration]}
              onChange={handleDurationChange}
              onBlur={onBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
