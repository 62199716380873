import { cva } from 'class-variance-authority';

export const spinnerStyles = cva(['animate-spin'], {
  variants: {
    variant: {
      default: ['text-gray200', 'fill-gray700'],
      primary: ['text-blue600', 'fill-gray100'],
      link: ['text-gray600', 'fill-gray100'],
      destructive: ['text-red200', 'fill-red900'],
      inverse: ['text-contentInverse', 'fill-backgroundInverse'],
    },
    size: {
      xs: ['text-xs', 'h-[16px]'],
      sm: ['text-sm', 'h-xl'],
      default: ['w-4', 'h-4'],
      lg: ['h-[20px]'],
    },
    fullWidth: {
      true: 'w-full',
    },
    disabled: {
      true: [
        'bg-gray100',
        'text-gray400',
        'border border-solid border-gray200',
        'cursor-not-allowed',
        'hover:bg-gray100',
      ],
    },
    loading: {
      true: ['cursor-not-allowed'],
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});
