import React from 'react';

interface AgentAvatarProps {
  initials: string;
}

const AgentAvatar = ({ initials }: AgentAvatarProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: 'var(--form-control-radius, 6px)',
      padding: '6px 12px',
      backgroundColor: 'var(--base-accent)',
      color: 'var(--button-primary-content, #FFF)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px',
    }}
  >
    {initials}
  </div>
);

export default AgentAvatar;
