import * as t from 'io-ts';

import { DateFilter, CardDataV, BarChartDataV, AdminFilter } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsCopilotDataV = t.type({
  total_opens: CardDataV,
  opens_bar_chart: BarChartDataV,
  unique_users: CardDataV,
  success_rate: CardDataV,
});

export type AnalyticsCopilotData = t.TypeOf<typeof AnalyticsCopilotDataV>;

export type AnalyticsCopilotParams = DateFilter & AdminFilter;
export type CopilotDownloadTypes = 'overview' | 'history';

export class AnalyticsCopilot {
  public static read = (params: AnalyticsCopilotParams) => read(AnalyticsCopilotDataV, `analytics/v2/copilot`)(params);
  public static download = async (type: CopilotDownloadTypes, params: DateFilter): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`ml/chats/export/${type}/${queryString}`);
  };
}
