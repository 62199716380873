import * as t from 'io-ts';

import { DateFilter, TableDataV, CardDataV, AdminFilter } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsHelpHubDataV = t.type({
  helphub_opens: CardDataV,
  helphub_doc_opens: CardDataV,
  searches: TableDataV,
  articles: TableDataV,
});

export type AnalyticsHelpHubParams = DateFilter & AdminFilter;

export type AnalyticsHelpHubData = t.TypeOf<typeof AnalyticsHelpHubDataV>;
export type HelphubDownloadTypes = 'searches' | 'articles';

export class AnalyticsHelpHub {
  public static read = (params: AnalyticsHelpHubParams) => read(AnalyticsHelpHubDataV, `analytics/v2/helphub`)(params);
  public static download = async (table: HelphubDownloadTypes, params: DateFilter): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/helphub/export/${table}/${queryString}`);
  };
}
