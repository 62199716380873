import { GenericOption, OptionType, initOption, OptionCategoryMetadata } from './Option';
import { DetailPreviewType, IRecordSettings } from '@commandbar/internal/middleware/types';
import get from 'lodash/get';
import deepGet from '../../search/fuzzysort-fork/deepGet/deepGet';
import { CBStore } from 'shared/store/global-store';
import { getReservedField } from '../helpers';
import { validateOptionDisabled } from '../selectors';

export interface ParameterOption extends GenericOption<OptionType.Parameter> {
  parameter: any;
  tags: { key: string; label: string; value: string | string[] }[];
  searchOptions?: IRecordSettings;
  description: string | undefined;
  heading: string | undefined;
}

export const initParameterOption = (
  _: CBStore,
  label: string,
  parameter: any,
  searchOptions?: IRecordSettings,
  detailPreview?: DetailPreviewType | null,
): ParameterOption => {
  let value = label;
  if (typeof parameter === 'string') {
    value = parameter;
  } else if (!!parameter && parameter.hasOwnProperty('value')) {
    value = parameter?.['value'];
  }
  const o = initOption(_, OptionType.Parameter, label, value, undefined, detailPreview) as ParameterOption;
  o.parameter = parameter;
  o.tags = (searchOptions?.search_fields || [])
    .map((t) => {
      if (typeof t === 'string') {
        // "legacy" behavior

        // Split on '.'
        let result = t.split('.')[0];
        // remove snake_case
        result = result.replace('_', ' ');
        // remove camelCase
        result = result.replace(/([A-Z])/g, ' $1');

        const label = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

        return { key: t, label };
      }

      return t;
    })
    .map(({ key, label }) => ({
      key,
      label,
      // deepGet used to fetch nested objs, i.e., comments.text where comments is an array
      value: deepGet(parameter, key),
    }));

  o.searchOptions = searchOptions;
  o.description = searchOptions?.description_field && get(o.parameter, searchOptions.description_field);
  o.heading = getReservedField(o, '__heading');

  // Current behavior:
  //    ParameterOptions that ARE NOT RecordOptions are only ever rendered without grouping
  const category: OptionCategoryMetadata = {
    label: o.groupKey ?? '',
    contextKey: '',
    limit: searchOptions?.max_options_count ?? null,
    renderAs: 'list',
  };
  o.category = category;

  validateOptionDisabled(_, o);

  return o;
};
