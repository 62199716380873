/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';

import { ActionButton } from 'shared/components/ActionButton';
import * as Reporting from 'shared/services/analytics/Reporting';

import { IContinuationType } from '@commandbar/internal/middleware/types';
import { useChatState } from '../store/useChatState';
import { useStore } from 'shared/util/hooks/useStore';
import { convertInternalChatHistoryToExternal } from 'shared/sdk/search';
import { emptyGlobalStore } from 'shared/store/global-store';
import StyledContinuationQuestionsContainer from '@commandbar/internal/client/themesV2/components/copilot/StyledContinuationQuestionsContainer';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import { _setActiveChat } from '@commandbar/internal/client/symbols';
import { CommandBarSDK } from '@commandbar/internal/client/CommandBarSDK';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';

type ContinuationProps = {
  preview: boolean;
  isLoading: boolean;
  continuations: IContinuationType[];
  hidden?: boolean;
};

export const Continuations: React.FC<ContinuationProps> = (props) => {
  const chatState = useChatState();
  const { chatHistory, send, chatID } = chatState;
  const { preview, isLoading, continuations, hidden } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  const themeV2 = useThemeV2Context();

  return _.flags?.['release-themes-v2'] ? (
    <StyledContinuationQuestionsContainer style={{ ...(hidden && { visibility: 'hidden' }) }}>
      {continuations.slice(0, 2).map((continuation, index) => {
        if (!continuation) return null;
        return (
          <StyledSecondaryButton
            themeV2={themeV2}
            key={index}
            onClick={() => {
              if (isLoading || preview) return;

              Track.copilot.followUpClicked(chatID, chatState, continuation);
              send(continuation);
              (window.CommandBar as CommandBarSDK)[_setActiveChat](chatID);

              Reporting.helpHubContinuationClicked(
                { query: _.helpHub.query },
                convertInternalChatHistoryToExternal(chatHistory),
                continuation,
              );
            }}
            disabled={preview}
            textWrap
          >
            {continuation}
          </StyledSecondaryButton>
        );
      })}
    </StyledContinuationQuestionsContainer>
  ) : (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '0px',
        marginTop: '12px',
        alignItems: 'flex-start',
      }}
    >
      {continuations.slice(0, 2).map((continuation, index) => {
        if (!continuation) return null;

        return (
          <ActionButton
            preview={preview}
            variant="continuation"
            disablePreviewClick
            key={index}
            style={{ minWidth: 0, maxWidth: '100%' }}
            onClick={() => {
              if (isLoading || preview) return;

              Track.copilot.followUpClicked(chatID, chatState, continuation);
              send(continuation);
              (window.CommandBar as CommandBarSDK)[_setActiveChat](chatID);

              Reporting.helpHubContinuationClicked(
                { query: _.helpHub.query },
                convertInternalChatHistoryToExternal(chatHistory),
                continuation,
              );
            }}
          >
            {continuation}
          </ActionButton>
        );
      })}
    </div>
  );
};

export default Continuations;
