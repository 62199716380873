import { Step, SelectStep, MultiSelectStep, DashboardStep } from './step-utils';
import { RecordOption } from '../options/option-utils';

import { ICommandType, isTimeArgument, isContextArgument } from '@commandbar/internal/middleware/types';
import { CBStore } from 'shared/store/global-store';
import { StepType } from './step-utils/Step';
import { BLOCK_TYPE, IBlock } from '../../components/select/input/Block';
import { getStepBlock, getStepSelection } from './helpers';

export const currentStepAndIndex = (_: CBStore): { currentStep: Step | undefined; currentStepIndex: number } => {
  if (_.spotlight.steps.length === 0) {
    return { currentStep: undefined, currentStepIndex: -1 };
  }

  const currentStepIndex = _.spotlight.steps.findIndex((step: Step) => {
    return !step.completed;
  });

  return { currentStep: _.spotlight.steps[currentStepIndex], currentStepIndex };
};

export const selectLastStep = (_: CBStore) => {
  return _.spotlight.steps[_.spotlight.steps.length - 1];
};

export const getArgumentSelections = (_: CBStore): { [argName: string]: unknown } => {
  let obj = {};
  _.spotlight.steps.forEach((step: Step) => {
    if (
      step.type === StepType.Select ||
      step.type === StepType.MultiSelect ||
      step.type === StepType.TextInput ||
      step.type === StepType.LongTextInput
    ) {
      // @FIXME This doesn't account for multiple steps referencing the same argument name

      obj = {
        ...obj,
        ...getStepSelection(step),
      };
    }
  });

  return obj;
};

export const getActiveCommand = (_: CBStore): ICommandType | undefined => {
  if (_.spotlight.steps.length === 0) return undefined;
  const firstStep = _.spotlight.steps[0];
  if (firstStep.type === StepType.Base) return firstStep?.selected?.data;
  return undefined;
};

export const getActiveRecord = (_: CBStore): RecordOption | null => {
  if (_.spotlight.steps.length === 0) return null;

  for (const s of _.spotlight.steps) {
    if (s.type === StepType.Base && s.resource) {
      return s.resource;
    }
  }
  return null;
};

export const isSelectStep = (currentStep: Step | undefined): currentStep is SelectStep | MultiSelectStep => {
  return currentStep?.type === StepType.Select || currentStep?.type === StepType.MultiSelect;
};

export const isDashboardStep = (currentStep: Step | undefined): currentStep is DashboardStep => {
  return currentStep?.type === StepType.Dashboard;
};

export const isTimeStep = (currentStep: SelectStep | MultiSelectStep): boolean => {
  return isSelectStep(currentStep) && isTimeArgument(currentStep.argument);
};

// NOTE: The return type must not be `currentStep is SelectStep | MultiSelectStep`.
export const isContextFunctionStep = (currentStep: Step | undefined, _: CBStore): boolean => {
  if (isSelectStep(currentStep) && isContextArgument(currentStep.argument)) {
    return !!Object.keys(_.callbacks).find(
      (callbackKey: string) => callbackKey === `commandbar-initialvalue-${currentStep.argument.value}`,
    );
  }

  return false;
};

export const isFinalStep = (step: Step, steps: Step[], stepIndex: number): boolean => {
  if (step.type === StepType.Dashboard && ['__video__', '__html__'].includes(step.argument.userDefinedValue)) {
    if (stepIndex + 1 < steps.length) {
      const executeStep = steps[stepIndex + 1];
      if (executeStep.type === 'execute') {
        if (['video', 'helpdoc'].includes(executeStep.command.template.type)) {
          return true;
        }
      }
    }
  }

  return step.type === StepType.Execute;
};

export const getArgumentBlocks = (_: CBStore): IBlock[] => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(_);

  // @ts-expect-error: This error is wrong, we do filter out undefined
  const ret: IBlock[] = _.spotlight.steps
    .map((step: Step, index: number) => {
      if (isFinalStep(step, _.spotlight.steps, index)) {
        return undefined;
      }

      const text = getStepBlock(step, _);
      if (text === undefined) return undefined;
      let type = BLOCK_TYPE.SELECTED;
      if (index === currentStepIndex - 1 || currentStep?.type === StepType.Base) {
        type = BLOCK_TYPE.LAST_SELECTED;
      } else if (index === currentStepIndex) {
        type = BLOCK_TYPE.CURRENT_ARGUMENT;
      } else if (index > currentStepIndex) {
        type = BLOCK_TYPE.PLACEHOLDER;
      }

      return { text, type };
    })
    .filter((block) => !!block);

  return ret;
};
