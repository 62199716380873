import { Alert, Input, Form } from '@commandbar/design-system/components/antd';

import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

const IntercomScrape: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.INTERCOM_SCRAPE,
  name: 'Intercom Scrape',
  slug: 'intercom_scrape',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/intercom-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/intercom-icon.png',
  useDryRun: false,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message="Command AI will import articles visible by anonymous users by default. Articles visible to signed in users can be imported under advanced options."
    />
  ),
  route: '/intercom-scrape',
  internalOnly: true,

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain || ''}
          label="Domain"
          name="domain"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /(https:\/\/)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}/gi,
              message: `Please enter a valid URL (e.g., support.yourdomain.com).`,
            },
          ]}
          style={{ flex: 5 }}
          required
          tooltip={{
            title: 'i.e. https://support.your-co.com',
          }}
        >
          <Input style={{ width: '100%' }} placeholder="support.yourdomain.com" />
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      domain: data['domain'],
    };
  },
  requiredFields: ['domain'],
  fetchedFields: [],
};

export default IntercomScrape;
