import React from 'react';
import { CardBase } from './CardBase';
import { UserPlus01 } from '@commandbar/design-system/icons/react';
import { ICON_WIDTH } from './styles';
import { useHistory } from 'react-router-dom';

export const InviteTeamCard = () => {
  const history = useHistory();
  return (
    <CardBase
      title="Invite a team member"
      description="Invite someone on your team to help"
      /**
       * On react-router v6, we can use `useNavigate` instead of `useHistory`
       * In this case, we should be using a link instead of navigating programatically
       * But to be consistent with the other cards, I will use `useHistory` here
       */
      onClick={() => history.push('/team')}
      leftIcon={<UserPlus01 width={ICON_WIDTH} color="#4578FB" />}
      background="linear-gradient(96.46deg, rgba(69, 120, 251, 0.08) -5.42%, rgba(255, 255, 255, 0.41) 18.75%, #FFFFFF 24.12%)"
      iconShadow="0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(69, 120, 251, 0.16), inset 1.5px -1.5px 3px rgba(69, 120, 251, 0.25)"
    />
  );
};
