/*******************************************************************************/
/* Imports
/*******************************************************************************/

import _get from 'lodash/get';
import { currentStepAndIndex, isSelectStep } from './steps/selectors';
import { Option, CommandOption, RecordOption } from './options/option-utils';
import { ICommandType, IStepArgumentType, IRecordSettings } from '@commandbar/internal/middleware/types';

import { compareObjs } from '@commandbar/internal/middleware/utils';

import ClientSearch from './search/ClientSearch';
import { CBStore } from 'shared/store/global-store';
import { StepType } from './steps/step-utils/Step';
import { convertParameterToOption, getOptionUID } from './selectors';
import { getContextObjectLabel, getContextSettings } from './selectors';
import { initParameterOption } from './options/option-utils/ParameterOption';
import { initRecordOption } from './options/option-utils/RecordOption';
import { initUnfurledCommandOption, UnfurledCommandOption } from './options/option-utils/UnfurledCommandOption';
import { initCommandOption } from './options/option-utils/CommandOption';
import * as Command from '@commandbar/internal/middleware/command';
import { getCommands } from 'shared/store/global-selectors';

/*******************************************************************************/
/* Constants
/*******************************************************************************/

const MAX_UNFURL_LENGTH = 50000;

/******************************** Parameter  logic **********************************/

const _optionsFromKey = (_: CBStore, obj: any, key: string, argument: IStepArgumentType) => {
  let possibleValues;

  if (ClientSearch.isDefined(key, _)) {
    // If clientSearch is defined, don't throw an error if it can't be found in state
    possibleValues = ClientSearch.get(key, _) || [];
  } else {
    possibleValues = _get(obj, key);
  }

  if (possibleValues === undefined) {
    return [];
    // TMP: Turn off this error -- can be triggered if a command is active and the bar is shutdown / booted
    // throw new InternalError(
    //   `Trying to get value of ${key} for ${argument.userDefinedName} returned undefined.`,
    //   engine,
    // );
  }

  if (Array.isArray(possibleValues) && possibleValues.length > MAX_UNFURL_LENGTH) {
    possibleValues = possibleValues.slice(0, MAX_UNFURL_LENGTH);
  } else if (!Array.isArray(possibleValues)) {
    possibleValues = [possibleValues];
  }

  possibleValues = possibleValues.map((obj: any) => {
    return convertParameterToOption(_, obj, argument);
  });

  return possibleValues;
};

/******************************** Resource logic **********************************/
export const actionsForRecord = (recordKey: string, availableCommands: CommandOption[]) => {
  return availableCommands.filter((commandOption: CommandOption) => {
    return !!Command.isRecordAction(commandOption.command, recordKey);
  });
};

const _isResourceAvailableForCommand = (resource: RecordOption, command: CommandOption) => {
  // Does the command reference the resource?
  const arg = Object.keys(command.command.arguments).find(
    (arg: string) =>
      command.command.arguments[arg].type === 'context' &&
      command.command.arguments[arg].value === resource.category.contextKey,
  );

  return !!arg;
};

const getContextKeyValue = (key: string, _: CBStore): any => {
  let retVal;

  if (ClientSearch.isDefined(key, _)) {
    retVal = ClientSearch.get(key, _);
  } else {
    const context = _.context;
    if (context.hasOwnProperty(key)) {
      retVal = context[key];
    }
  }
  return Array.isArray(retVal) ? retVal : undefined;
};
const availableRecords = (_: CBStore, availableCommands: CommandOption[]): (RecordOption | UnfurledCommandOption)[] => {
  if (!_.organization) {
    return [];
  }

  const contextSettings = getContextSettings(_);

  // Get the searchable resource keys as defined on the Organization object (organization.resource_options)
  const recordKeys = Object.keys(contextSettings).filter((k: string) => {
    // Search is on, and resources are defined in context as an array
    return contextSettings[k].search && !!getContextKeyValue(k, _);
  });

  recordKeys.sort((a, b) =>
    compareObjs(
      { sort_key: contextSettings[a].sort_key || 0, id: 0 },
      { sort_key: contextSettings[b].sort_key || 0, id: 0 },
    ),
  );

  const toRet: (RecordOption | UnfurledCommandOption)[] = [];
  recordKeys.forEach((k: string) => {
    const commands = actionsForRecord(k, availableCommands);
    const searchOptions: IRecordSettings = contextSettings[k];

    const unfurl = !!searchOptions.unfurl;
    const track_recents = searchOptions.track_recents === undefined || searchOptions.track_recents;

    // this record key has no available commands associated with it
    // don't show any records for it
    if (commands.length === 0) {
      return;
    }

    getContextKeyValue(k, _).forEach((r: any) => {
      if (!k) return;
      const label = getContextObjectLabel(contextSettings, r, k);

      const resource = initRecordOption(_, r, label, k, searchOptions, commands);

      // Unfurl if it's an active object
      if (!unfurl) {
        toRet.push(resource);
        return;
      }

      // UNFURLING LOGIC: Get the avaialble commands for a resource and show them
      const commandsToUnfurl = commands.filter((command) => _isResourceAvailableForCommand(resource, command));
      commandsToUnfurl.forEach((c: CommandOption) => {
        toRet.push(initUnfurledCommandOption(_, c.command, c, resource));
      });
    });

    // FIXME RECENTS: look into whether we can avoid this inputText length check
    // add initial(!) resources from recents if (i) the settings is active for the category and (ii) they are not included already
    if (
      track_recents &&
      _.spotlight.inputText.length === 0 &&
      k &&
      _.endUserStore.data.recents &&
      _.endUserStore.data.recents.length > 0 &&
      !!_.callbacks[`commandbar-search-${k}`]
    ) {
      for (const recentRecord of _.endUserStore.data.recents) {
        if (recentRecord.type === 'record' && recentRecord.categoryKey === k) {
          const r = recentRecord.record;
          const label = getContextObjectLabel(contextSettings, r, k);

          const resource = initRecordOption(_, r, label, k, searchOptions, commands);
          const thisRecordOptionUID = getOptionUID(resource);
          const isAlreadyAvailable = toRet.some((o) => getOptionUID(o) === thisRecordOptionUID);

          if (!isAlreadyAvailable && thisRecordOptionUID) {
            toRet.push(resource);
          }
        }
      }
    }
  });

  return toRet;
};

/******************************** Commands logic **********************************/

export const availableCommands = (_: CBStore): CommandOption[] => {
  return getCommands(_)
    .filter((command: ICommandType) => {
      if (_.testMode) {
        return true;
      }
      return !['admin'].includes(command.template.type) && command.is_live;
    })
    .map((command: ICommandType) => {
      return initCommandOption(_, command);
    })
    .filter((commandOption: CommandOption) => {
      if (_.testMode) {
        return true;
      }

      return !commandOption.optionDisabled.isDisabled || commandOption.optionDisabled.reasonIsUserDefined;
    });
};

/******************************** Commands logic **********************************/

const available = (_: CBStore): Option[] => {
  const { currentStep } = currentStepAndIndex(_);

  if (currentStep === undefined) {
    return [];
  }

  /* typical starting point */
  if (currentStep.type === StepType.Base) {
    if (currentStep.resource !== null) {
      // Get all the commands that reference the active resource
      const recordActions = actionsForRecord(currentStep.resource.category.contextKey, availableCommands(_));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return recordActions.filter((c) => _isResourceAvailableForCommand(currentStep.resource!, c));
    } else {
      const commands = availableCommands(_).sort((a, b) => compareObjs(a.command, b.command));
      const recordOptions = availableRecords(_, commands);
      return [...commands, ...recordOptions];
    }
  }

  /* select parameters step */
  if (isSelectStep(currentStep)) {
    if (!currentStep.argument || !currentStep.argument.type) {
      return [];
    }

    const argument = currentStep.argument;

    switch (currentStep.argument.type) {
      case 'context':
        if (
          !!Object.keys(_.callbacks).find(
            (callbackKey: string) => callbackKey === `commandbar-initialvalue-${currentStep.argument.value}`,
          )
        ) {
          /**
           * Setting the options by async function is in CommandBar.tsx, just like 'function-generated'
           */
          return [];
        }
        const context = _.context;
        const options = _optionsFromKey(_, context, currentStep.argument.value, argument);
        return options;
      case 'provided':
        switch (currentStep.argument.value) {
          case 'time':
            return [];
          case 'text':
            return [];
          default:
            return [];
        }
      case 'set':
        const set = currentStep.argument.value;
        if (Array.isArray(set)) {
          return set.slice(0, MAX_UNFURL_LENGTH).map((obj: any) => {
            return convertParameterToOption(_, obj, currentStep.argument);
          });
        } else {
          throw new Error(`Set for ${currentStep.argument.userDefinedName} must be an array.`);
        }
      case 'video':
        return [];
      case 'html':
        return [];
    }
  }

  if (currentStep.type === StepType.LongTextInput || currentStep.type === StepType.Dashboard) return [];

  if (currentStep.type === StepType.TextInput) {
    const textString = currentStep.argument.userDefinedName;
    const textValue = currentStep.selected?.data || '';
    const textOption = initParameterOption(_, textString, textValue);
    return [textOption];
  }

  /* error: should never get here */
  throw new Error('Trying to calculate availability on invalid step.');
};

const Available = {
  available,
};

export default Available;
