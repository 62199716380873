import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { defaultStyles } from '@visx/tooltip';

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%d');
export const formatDate = (dateString: string) => {
  const date = parseDate(dateString);
  return date ? format(date) : '';
};

export const formatToolTip = (dateString: string) => {
  const formatToolTipDate = timeFormat('%B %d, %Y');

  const date = parseDate(dateString);
  return date ? formatToolTipDate(date) : '';
};

export type TooltipData = {
  bar: any;
  key: any;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  padding: '12px',
};

export type BarChart = { [key: string]: number };

export type NpsChart = {
  key: string;
  metric: number;
  percent_change: number;
  type: string;
};
