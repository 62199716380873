import React from 'react';
import styled from '@emotion/styled';
import { GoogleChrome } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { COMMANDBAR_CHROME_EXTENSION_URL } from '@commandbar/internal/client/ChromeExtension';

const Container = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  width: 250px;
  height: 45px;

  background: #ffffff;
  border: 1px solid rgba(10, 10, 15, 0.24);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 1px rgba(0, 0, 0, 0.08), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${CB_COLORS.neutral700};

  &:hover {
    background: ${CB_COLORS.neutral100};
    color: ${CB_COLORS.neutral900};
    border: 1px solid ${CB_COLORS.neutral500};
  }
`;

export const ChromeExtensionLink = () => {
  return (
    <Container href={COMMANDBAR_CHROME_EXTENSION_URL} target="_blank" rel="noreferrer">
      <GoogleChrome width={16} />
      Install Chrome Extension
    </Container>
  );
};
