import React from 'react';

import { ISlackType, requestToSlack } from '@commandbar/internal/client/slack';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { useTeamMembersContext } from '../components/util/TeamMembersContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { releaseConfetti } from '@commandbar/commandbar/shared/components/Confetti';
import { IProfileType } from '@commandbar/internal/middleware/types';
import { cmdToast } from '@commandbar/design-system/cmd';

export const useTeamMembers = (): {
  inviteTeammate: (args: { email: string }) => Promise<boolean>;
  inviteTeammateWithRole: (args: { email: string; role: string }) => Promise<boolean>;
  sendReferral: (email: string) => void;
} => {
  const { user } = useAuth();
  const { members, addMember } = useTeamMembersContext();

  const inviteTeammate = React.useCallback(
    async (args: { email: string }) => {
      if (members.find((member) => member.user === args.email)) {
        cmdToast.error(`${args.email} has already been invited`);
        return false;
      }

      const payload = {
        email: args.email,
      };

      return axiosInstance
        .post('/organizations/invite_teammate/', payload)
        .then((_response: any) => {
          cmdToast.success('Invited! Your teammates should get an email shortly.');
          addMember(args.email, 'admin');
          releaseConfetti();
          return true;
        })
        .catch((err: any) => {
          cmdToast.error(err ?? `Something went wrong inviting ${args.email}`);
          return false;
        });
    },
    [members, addMember],
  );

  const inviteTeammateWithRole = React.useCallback(
    async (args: { email: string; role: string }) => {
      if (members.find((member) => member.user === args.email)) {
        cmdToast.error(`${args.email} has already been invited`);
        return false;
      }

      const payload = {
        email: args.email,
        role: args.role,
      };

      return axiosInstance
        .post('/organizations/invite_teammate/', payload)
        .then((_response: any) => {
          cmdToast.success('Invited! Your teammates should get an email shortly.');
          addMember(args.email, args.role as IProfileType['role']);
          releaseConfetti();
          return true;
        })
        .catch((err: any) => {
          cmdToast.error(err ?? `Something went wrong inviting ${args.email}`);
          return false;
        });
    },
    [members, addMember],
  );

  const sendReferral = React.useCallback(
    (email: string) => {
      cmdToast.success("Thanks! We'll be in touch shortly.");
      const payload = {
        type: 'dashboard-usage' as ISlackType,
        email: email,
        message: `🎉 ${user?.email} referred ${email}`,
      };
      requestToSlack(payload);
    },
    [user],
  );

  return {
    inviteTeammate,
    inviteTeammateWithRole,
    sendReferral,
  };
};
