import { Key, useEffect, useState } from 'react';
import chunk from 'lodash/chunk';

import * as Organization from '@commandbar/internal/middleware/organization';

import { useIntegrationsContext } from '../../util/IntegrationContext';
import { Pagination } from '../../table/Footer';

import {
  isCommand,
  type IBatchOperation,
  type ICommandCategoryType,
  type IEditorCommandType,
  type IEditorCommandTypeLite,
  type IKeyword,
} from '@commandbar/internal/middleware/types';
import type { Source } from '../../integrations/shared/types';
import { onIntegrationError } from '../../integrations/shared/utils';
import { Exception } from '@sentry/react';
import { StatusType } from 'editor/src/editor/components/FilterSelect';
import { useAppContext } from 'editor/src/AppStateContext';
import { useParams } from 'react-router';
import { isKeyword } from './utils';
import { HelpDoc, isHelpDoc } from '../sources/HelpDocsTable';

export const useHelpDocs = ({ source }: { source?: Pick<Source, 'id' | 'slug' | 'commands_count'> | null }) => {
  const { commands } = useAppContext();

  const [helpDocs, setHelpDocs] = useState<HelpDoc[]>([]);

  useEffect(() => {
    const results = commands;

    // filter for only help doc commands that match the selected integration
    const commandsForIntegrationType = results.filter(
      (c): c is HelpDoc => isHelpDoc(c) && c.third_party_source === source?.slug,
    );

    /* If the help doc commands matching our source type contain any commands that have an integration id,
     * then we are looking at a specific integration, so filter for only commands that match the integration id.
     */
    if (commandsForIntegrationType.some(({ integration }) => !!integration)) {
      const commandsForIntegration = commandsForIntegrationType.filter((c) => c.integration?.id === source?.id);
      if (commandsForIntegration.length > 0) {
        setHelpDocs(commandsForIntegration);
      } else {
        const commandsWithoutIntegration = commandsForIntegrationType.filter((c) => c.integration === null);
        setHelpDocs(commandsWithoutIntegration);
      }
    } else if (commandsForIntegrationType.length > 0) {
      setHelpDocs(commandsForIntegrationType);
    }
  }, [source?.id, source?.slug, commands]);

  return {
    helpDocs,
  };
};

export const useLoadCategories = () => {
  const [categories, setCategories] = useState<ICommandCategoryType[]>([]);
  const { organizationId } = useIntegrationsContext();

  useEffect(() => {
    if (organizationId) {
      Organization.listCommandCategories(organizationId.toString()).then(setCategories);
    }
  }, [organizationId]);

  return categories;
};

interface CreateBatch {
  commands: IEditorCommandTypeLite[];
  selectedRowKeys: Key[];
  bulkCommandCategory: number;
  bulkCommandStatus: StatusType;
  deleteMode: boolean;
}

const createBatch = ({
  commands,
  selectedRowKeys,
  bulkCommandCategory,
  bulkCommandStatus,
  deleteMode,
}: CreateBatch): IBatchOperation[] => {
  const isLiveStatus =
    bulkCommandStatus === 'published' ? true : bulkCommandStatus === 'unpublished' ? false : undefined;
  const selectedCategoryId = bulkCommandCategory !== -1 ? bulkCommandCategory : undefined;

  return commands
    .filter((c) => selectedRowKeys.some((k) => k === c.id))
    .map((command) => ({
      op: deleteMode ? 'delete' : 'update',
      id: command.id,
      data: {
        category: selectedCategoryId ?? command.category,
        is_live: isLiveStatus ?? command.is_live,
      },
    }));
};

interface UseBulkEdit {
  commands: IEditorCommandTypeLite[];
  setIsLoading: (loading: boolean) => void;
  setSelectedRowKeys?: (keys: Key[]) => void;
}

export const useBulkEditCommands = ({ commands, setIsLoading, setSelectedRowKeys }: UseBulkEdit) => {
  const [bulkCommandCategory, setBulkCommandCategory] = useState(-1);
  const [bulkCommandStatus, setBulkCommandStatus] = useState<StatusType>('all');

  const params = useParams<{ page: string }>();
  const type = params.page;

  const {
    dispatch: {
      commands: { bulkUpdate },
      answers: { reload },
    },
  } = useAppContext();

  const resetState = () => {
    setBulkCommandStatus('all');
    setBulkCommandCategory(-1);
  };

  const handleBulkSubmit = async (selectedRowKeys: Key[], deleteMode = false) => {
    setIsLoading(true);

    const batch = createBatch({
      commands,
      selectedRowKeys,
      bulkCommandCategory,
      bulkCommandStatus,
      deleteMode,
    });
    const note = 'Bulk update of articles';

    try {
      await bulkUpdate(batch, note);
      if (type === 'answers') {
        await reload();
      }
      setSelectedRowKeys?.([]);
    } catch (err) {
      onIntegrationError(err as Exception);
    }

    resetState();

    setIsLoading(false);
  };

  return {
    bulkCommandCategory,
    setBulkCommandCategory,
    bulkCommandStatus,
    setBulkCommandStatus,
    handleBulkSubmit,
  };
};

const PAGE_SIZE = 11;

interface FilterHelpDocCommands<T> {
  commands: T[];
  searchFunction: (command: T) => boolean;
  selectedStatus: StatusType;
}

export const filterHelpDocCommands = <T extends IEditorCommandType | IEditorCommandTypeLite | IKeyword>({
  commands,
  searchFunction,
  selectedStatus,
}: FilterHelpDocCommands<T>) => {
  return commands
    .filter(searchFunction)
    .filter((command: IEditorCommandType | IEditorCommandTypeLite | IKeyword) => {
      const commandStatus = command.is_live ? 'published' : 'unpublished';
      return selectedStatus === 'all' || selectedStatus === commandStatus;
    })
    .map((command) => ({
      ...command,
      key: command.id,
    }));
};

interface UseDataCommandsAndPagination<T> {
  commands: T[];
  searchTerm: string;
  selectedStatus: StatusType;
}

export const contentSearchFunction = (
  searchTerm: string,
  command: IEditorCommandType | IEditorCommandTypeLite | IKeyword,
) => {
  if (searchTerm === '') {
    return true;
  }
  if (isCommand(command)) {
    return (
      (command.text && command.text.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
      (typeof command.template.value === 'string' &&
        command.template.value.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  } else if (isKeyword(command)) {
    return (
      command.keyword.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
      command.definition.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  }

  return false;
};

//TODO: make this more generic so we can use the pagination and search part also for sync history table and team members table
export const useDataCommandsAndPagination = <T extends IEditorCommandType | IEditorCommandTypeLite | IKeyword>({
  commands,
  searchTerm,
  selectedStatus,
}: UseDataCommandsAndPagination<T>) => {
  const [dataCommands, setDataCommands] = useState<T[][]>([[]]);
  const [pagination, setPagination] = useState<Pagination>({
    totalItems: 0,
    currentItems: 0,
    previousPage: null,
    nextPage: null,
    currentPage: 0,
  });

  useEffect(() => {
    const chunkedCommands = chunk(
      filterHelpDocCommands({
        commands,
        searchFunction: (command: T) => contentSearchFunction(searchTerm, command),
        selectedStatus,
      }),
      PAGE_SIZE,
    );
    setDataCommands(chunkedCommands);
  }, [commands, searchTerm, selectedStatus]);

  useEffect(() => {
    setPagination((_pagination) => ({ ..._pagination, currentPage: 0 }));
  }, [searchTerm, selectedStatus]);

  useEffect(() => {
    const previousPage =
      dataCommands[pagination.currentPage - 1]?.length > 0 && pagination.currentPage > 0
        ? pagination.currentPage - 1
        : null;
    const nextPage = dataCommands[pagination.currentPage + 1]?.length > 0 ? pagination.currentPage + 1 : null;

    const previousItems = dataCommands.slice(0, pagination.currentPage).reduce((acc, curr) => acc + curr?.length, 0);

    setPagination((_pagination) => ({
      ..._pagination,
      totalItems: dataCommands.reduce((acc, curr) => acc + curr?.length, 0),
      currentItems: previousItems + dataCommands[_pagination.currentPage]?.length,
      previousPage,
      nextPage,
    }));
  }, [dataCommands, pagination.currentPage]);

  return { dataCommands, pagination, setPagination };
};
