// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Users03 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M9.5 12a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0 3c-2.83 0-5.346 1.545-6.94 3.938-.35.525-.525.787-.505 1.122.016.26.187.58.395.738.268.202.636.202 1.372.202h11.356c.736 0 1.104 0 1.371-.202a1.11 1.11 0 0 0 .396-.738c.02-.335-.155-.597-.504-1.122C14.846 16.545 12.33 15 9.5 15Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18 15.837c1.456.731 2.704 1.905 3.615 3.373.18.29.27.436.302.637.063.409-.216.912-.597 1.073-.188.08-.398.08-.82.08M16 11.532a4.5 4.5 0 0 0 0-8.064M14 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM2.56 18.938C4.153 16.545 6.67 15 9.5 15s5.346 1.545 6.94 3.938c.35.525.525.787.505 1.122-.016.26-.187.58-.396.738-.267.202-.635.202-1.371.202H3.822c-.736 0-1.104 0-1.372-.202a1.109 1.109 0 0 1-.395-.738c-.02-.335.155-.597.504-1.122Z"
    />
  </svg>
);
export default Users03;
