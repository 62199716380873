import React from 'react';

import { MemoryRouter } from 'react-router-dom';
import Editor from './editor/Editor';
import { routerGetUserConfirmation } from './InAppWidget';

// NOTE: this is the main entrypoint for Studio's usage of the Editor component
//
// If there is any Studio-specific configuration or setup that needs to be done,
// it can be done in this component.

const DashboardWidget = (props: { initialRoute: string }) => {
  return (
    <MemoryRouter initialEntries={[props.initialRoute]} getUserConfirmation={routerGetUserConfirmation}>
      <div
        className="commandbar-editor-root"
        style={{ padding: 0, height: '100%', backgroundColor: 'white', border: '0px solid blue' }}
      >
        <div
          style={{
            backgroundColor: 'rgb(242, 242, 242)',
            borderRadius: 0,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Editor />
        </div>
      </div>
    </MemoryRouter>
  );
};

export default DashboardWidget;
