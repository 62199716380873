import React from 'react';

export const botAvatarDefaultImageSrc = 'https://cdn.commandbar.com/_images/copilot_avatar_commandai.png';

export const BotAvatar = () => {
  return (
    <img
      src={botAvatarDefaultImageSrc}
      style={{
        width: '40px',
        height: '40px',
        flexShrink: 0,
      }}
      alt=""
    />
  );
};

export default BotAvatar;
