import React from 'react';

import { type TriggerableEntity, isNudge as checkIsNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { Panel } from '.';
import AudienceTable from '../Audiences/AudienceTable';
import { Section } from './Section';

type ReviewProps = {
  dirty: TriggerableEntity;
};

export const Review = ({ dirty }: ReviewProps) => {
  const isNudge = checkIsNudge(dirty);

  return (
    <Section
      sectionKey={Panel.Review}
      heading="Review"
      subheading={`Who’s eligible to see this ${isNudge ? 'Nudge' : 'Checklist'}`}
    >
      <AudienceTable id={dirty.id} audience={dirty.audience} isNudge={isNudge} />
    </Section>
  );
};
