import React from 'react';
import styled from '@emotion/styled';
import { Check } from '@commandbar/design-system/icons/react';

const Checkbox = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: var(--layout-radius-button);
  border: 1px solid var(--form-control-border-color);
  background: var(--form-control-background);

  &:hover {
    border: 1px solid var(--form-control-border-color-hover);
  }
`;

const SelectedCheckbox = styled(Checkbox)`
  background: var(--button-primary-background);
  border: 1px solid var(--button-primary-background);

  &:hover {
    border: 1px solid var(--button-primary-background);
  }
`;

const Checkmark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--layout-padding);
  height: var(--layout-padding);
  color: var(--base-white);
`;

const StyledCheckboxIcon = ({ selected }: { selected: boolean }) => {
  return selected ? (
    <SelectedCheckbox>
      <Checkmark>
        <Check />
      </Checkmark>
    </SelectedCheckbox>
  ) : (
    <Checkbox />
  );
};

export default StyledCheckboxIcon;
