import { useCallback } from 'react';
import type { ActorRefFrom } from 'xstate';

import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import type {
  INudgeButtonAction,
  INudgeStepContentButtonBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import type NudgeMachine from '../store/nudgeMachine';
import { RenderMode } from './RenderNudge';
import { useSurveyResponse } from './SurveyResponseProvider';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

interface UseExecNudgeActionOptions {
  stepId: INudgeStepType['id'];
  renderMode: RenderMode;
  service?: ActorRefFrom<typeof NudgeMachine>;
  onMockAction?: (action: INudgeStepContentButtonBlockType['meta']) => void;
}

export const useExecNudgeAction = ({ stepId, renderMode, service, onMockAction }: UseExecNudgeActionOptions) => {
  const _ = useStore();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const { onExit } = useAnimatedWidget();

  const exec = useCallback(
    (action: INudgeButtonAction | null, meta: INudgeStepContentButtonBlockType['meta']) => {
      if (renderMode === RenderMode.MOCK && _.extension.recorder.enabled) {
        onMockAction?.(meta);
        return;
      }

      switch (action?.type) {
        case 'step_back': {
          service?.send({
            type: 'REGRESS',
            action,
          });
          break;
        }
        case 'dismiss': {
          /*
           * A dismiss via CTA action is handled differently from a dismiss via closing the nudge.
           * If dismissing via an action, we want the tour to end. If it's triggered again, it should start over.
           * https://linear.app/commandbar/issue/ENG-6307/using-dismiss-action-in-a-branched-nudge-tour-leaves-the-tour-in-an
           */
          service?.send({
            type: 'FINISH',
            action,
            surveyResponse,
          });
          break;
        }
        case 'snooze': {
          service?.send({
            type: 'SNOOZE',
            action,
          });
          break;
        }
        default: {
          service?.send({
            type: 'ADVANCE',
            button_type: meta?.button_type,
            cta: meta?.label,
            surveyResponse,
            action,
            ...(action?.type === 'go_to_step' && { step: action.value }),
          });
          break;
        }
      }
      setSurveyResponse(undefined);
    },
    [service, stepId, surveyResponse],
  );

  return (action: INudgeButtonAction | null, meta: INudgeStepContentButtonBlockType['meta']) => {
    onExit(() => exec(action, meta));
  };
};
