import { message } from 'antd';
import React, { MouseEventHandler, useState } from 'react';
import Select, { components, MultiValueProps, Props } from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEndHandler,
} from 'react-sortable-hoc';

const arrayMove = (arr: any, from: number, to: number) => {
  const slicedArray = arr.slice();
  slicedArray.splice(to < 0 ? arr.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
};

interface SortableMultiSelectProps {
  options: any;
  defaultValue?: any;
  maxSelectedOptions?: number;
  onSortEnd?: Function;
  onChange?: Function;
  isDisabled?: boolean;
  menuPortalTarget?: HTMLElement;
}

const SortableSelect = SortableContainer(Select) as React.ComponentClass<Props<any, true> & SortableContainerProps>;
const SortableMultiValue = SortableElement((props: MultiValueProps<any>) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableMultiValueLabel = SortableHandle((props: any) => <components.MultiValueLabel {...props} />);

export const SortableMultiSelect = (props: SortableMultiSelectProps) => {
  const [selected, setSelected] = useState<any>(props.defaultValue ?? []);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    props.onSortEnd?.(newValue);
  };

  const onChange = (selectedOptions: any) => {
    if (props.maxSelectedOptions && selectedOptions.length > props.maxSelectedOptions) {
      message.error(`You can only select a maximum of ${props.maxSelectedOptions} options.`);
      selectedOptions.splice(-1);
    }

    setSelected(selectedOptions);
    props.onChange?.(selectedOptions);
  };

  return (
    <SortableSelect
      isDisabled={props.isDisabled}
      useDragHandle
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={props.options}
      menuPortalTarget={props.menuPortalTarget}
      value={selected}
      onChange={onChange}
      components={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
      maxMenuHeight={100}
      menuPlacement={'auto'}
    />
  );
};
