import { cva } from 'class-variance-authority';

export const inputStyles = cva(
  [
    'bg-gray100',
    'h-xxl',
    'px-sm',
    'text-base',
    'border focus-visible-none border-solid rounded-button border-elementMid hover:border-elementTop focus-visible:outline-none',
    'focus-within:shadow-input focus-within:border-blue700',
    'shadow-sm',
    'text-contentTop',
  ],
  {
    variants: {
      disabled: {
        true: [
          'text-contentDisabled',
          'cursor-not-allowed',
          'bg-surfaceDisabled',
          'border-elementDisabled',
          'focus-within:shadow-none',
          'hover:border-elementDisabled',
        ],
      },
      fullWidth: {
        true: 'w-full',
      },
      error: {
        true: [
          'border-red700',
          'hover:border-red900',
          'focus-within:shadow-inputError focus-within:border-red700',
          'focus-visible:outline-none',
        ],
        false: '',
      },
      inline: {
        true: ['border-none', 'shadow-none', 'focus-within:shadow-none'],
        false: '',
      },
    },
    compoundVariants: [],
    defaultVariants: {},
  },
);

export const inputFixStyles = cva(['flex items-center text-gray600 bg-transparent h-fit'], {
  variants: {
    disabled: {
      true: ['text-contentDisabled', 'cursor-not-allowed', 'bg-transparent'],
    },
    interactive: {
      true: 'cursor-pointer',
    },
    hidden: {
      true: 'invisible',
    },
  },
  compoundVariants: [
    {
      interactive: true,
      disabled: true,
      class: 'cursor-not-allowed',
    },
  ],
  defaultVariants: {},
});
