import { CBStore } from 'shared/store/global-store';
import { CommandOption, Option, ParameterOption, RecordOption, UnfurledCommandOption } from './option-utils';
import { isRecordOption, isParameterOption, isCommandOption, isUnfurledCommandOption } from './helpers';
import * as SpotlightActions from '../actions';
import { getDefaultCommandForCurrentStep, selectStep, getSortedArgs } from '../selectors';
import React from 'react';
import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { assertNever } from 'shared/util/assertNever';
import { initBaseStep } from '../steps/step-utils/BaseStep';
import { currentStepAndIndex } from '../steps/selectors';
import { StepType } from '../steps/step-utils/Step';
import { Step } from '../steps/step-utils';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { initSelectStep } from '../steps/step-utils/SelectStep';
import { initMultiSelectStep } from '../steps/step-utils/MultiSelectStep';
import { initDashboardStep } from '../steps/step-utils/DashboardStep';
import { initParameterOption } from './option-utils/ParameterOption';
import { initExecuteStep } from '../steps/step-utils/ExecuteStep';
import { initTextInputStep } from '../steps/step-utils/TextInputStep';
import { initLongTextInputStep } from '../steps/step-utils/LongTextInputStep';
import { ExecutionEventSource } from '@commandbar/commandbar/shared/services/analytics/EventHandler';

export const chooseOption = (
  _: CBStore,
  o: Option,
  _updateSteps?: SpotlightActions.StepUpdater,
  executionEventSource?: ExecutionEventSource,
  triggeredByShortcut?: boolean,
  event?: React.KeyboardEvent | React.MouseEvent,
) => {
  const updater = _updateSteps ?? SpotlightActions.updateSteps.bind(null, _);
  if (isRecordOption(o)) {
    // auto selects the default option on a resource that can be power-selected.
    const recordOption = chooseRecordOption(_, o, updater);
    const defaultCommand = getDefaultCommandForCurrentStep(_);
    // execute default command unless Shift is held in which case we implicitly enter power-select mode
    if (defaultCommand && !event?.shiftKey) {
      const updateSteps = SpotlightActions.updateSteps.bind(null, _);
      chooseOption(_, defaultCommand, updateSteps, executionEventSource, undefined, event);
    }
    return recordOption;
  } else if (isCommandOption(o) || isUnfurledCommandOption(o)) {
    return chooseCommandOption(_, o, triggeredByShortcut, updater, executionEventSource, {
      openLinkInNewTab: !!event && getTriggerKey(event),
    });
  } else if (isParameterOption(o)) {
    return chooseParameterOption(_, o, updater);
  }

  return assertNever(o);
};

const chooseRecordOption = (_: CBStore, o: RecordOption, updateSteps: SpotlightActions.StepUpdater): undefined => {
  // Note: We could choose to store the "SelectedResource" in the .selected field for this step
  // (which would be more consistent with the rest of the design)
  // Currently, we choose to store it on a custom field on BaseStep (.resource)
  // This is so that we can avoid looping through the steps when calculating availability
  // and deciding whether there is an active resource.
  // A workstream to cache various state properties during each clock-tick would solve this
  // setSelected(selectedResource) // just because
  const newStep = initBaseStep(o);

  const steps = [..._.spotlight.steps, newStep];

  updateSteps(steps);
  return;
};

export const chooseParameterOption = (_: CBStore, o: ParameterOption, updateSteps: SpotlightActions.StepUpdater) => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(_);

  if (currentStep === undefined) {
    throw new Error('Invalid parameter step');
  }

  /**
   * SelectStep:
   *  - Add this parameter to {state.selected}
   *  - Fulfill immediately
   */
  switch (currentStep.type) {
    case StepType.Dashboard:
    case StepType.Select:
    case StepType.TextInput:
    case StepType.LongTextInput: {
      // @IMPROVEMENT: Can create a helper to loop through steps and edit one of them
      const steps = _.spotlight.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return selectStep(step, o);
        }
        return step;
      });

      updateSteps(steps);
      return;
    }
    case StepType.MultiSelect: {
      const steps = _.spotlight.steps.map((step: Step, index: number) => {
        if (currentStepIndex === index) {
          return selectStep(step, o);
        }
        return step;
      });

      // This is the only case where rebase and fulfill should _not_ occur.
      updateSteps(steps, false);
      return;
    }
    default:
      throw new Error('Unknown parameter step');
  }
};

export const chooseCommandOption = (
  _: CBStore,
  option: CommandOption | UnfurledCommandOption,
  triggeredByShortcut?: boolean,
  updateSteps?: SpotlightActions.StepUpdater,
  executionEventSource?: ExecutionEventSource,
  alternateBehavior?: { openLinkInNewTab?: boolean },
) => {
  const { currentStepIndex } = currentStepAndIndex(_);
  const { currentStep } = currentStepAndIndex(_);
  const activeRecord: RecordOption | null = (() => {
    if (currentStep?.type === StepType.Base && !!currentStep.resource) return currentStep.resource;
    if (isUnfurledCommandOption(option)) return option.resource;
    return null;
  })();

  // Transform command arguments into an array, sorted by order_key
  const sortedArgs = getSortedArgs(option);

  // Turn the arguments into Steps
  const argSteps = sortedArgs
    .map((arg: IStepArgumentType) => {
      switch (arg.type) {
        case 'set':
        case 'context':
          if (arg.input_type === 'multi-select') {
            return initMultiSelectStep(arg);
          } else {
            return initSelectStep(arg);
          }
        case 'provided':
          if (arg.value === 'time') return initSelectStep(arg);
          if (arg.value === 'text') {
            if (arg.input_type === 'longtext') return initLongTextInputStep(arg);
            else return initTextInputStep(arg);
          }
          throw new Error(`Invalid provided arg type`);
        case 'video':
        case 'html':
          return initDashboardStep(arg, option.command);
        default:
          throw new Error('Invalid arg type');
      }
    })
    .map((step: Step) => {
      let newStep = step;

      // If pre-selected value, pre-select it
      if (
        newStep.type === StepType.Select ||
        newStep.type === StepType.MultiSelect ||
        newStep.type === StepType.LongTextInput ||
        newStep.type === StepType.TextInput
      ) {
        newStep = SpotlightActions.preSelectIfApplicable(_, newStep, activeRecord ?? undefined);
      }

      // Pre-fill selected values in generated Steps with the active resource
      if (activeRecord) {
        // ParameterOption from context resource
        if (
          step.type === StepType.Select ||
          step.type === StepType.MultiSelect ||
          step.type === StepType.LongTextInput ||
          step.type === StepType.TextInput
        ) {
          if (step.argument !== undefined && step.argument.type === 'context') {
            if (step.argument.value === activeRecord.category.contextKey) {
              const _parameterOption = initParameterOption(
                _,
                activeRecord.label,
                activeRecord.parameter,
                undefined,
                activeRecord.detailPreview,
              );
              newStep = selectStep(step, _parameterOption);
              newStep.completed = true;
            }
          }
        }
      }

      return newStep;
    });

  const updatedSteps = _.spotlight.steps.map((step: Step, index: number) => {
    if (currentStepIndex === index) {
      return selectStep(step, option);
    }
    return step;
  });

  const steps = [
    ...updatedSteps,
    ...argSteps,
    initExecuteStep(
      option.command,
      triggeredByShortcut || false,
      executionEventSource || { type: 'bar' },
      alternateBehavior,
    ),
  ];

  if (updateSteps) updateSteps(steps);
};
