import React from 'react';
import { Table, Result } from '@commandbar/design-system/components/antd';

export type TChecksResult = { check: string; status: string }[];

export interface IProps {
  checksResult: TChecksResult;
}

export const ChecksRenderer = ({ checksResult }: IProps) => {
  const allPassed = checksResult.every((checkResult) => checkResult.status === 'Passed');

  const columns = [
    {
      title: 'Check',
      dataIndex: 'check',
      key: 'check',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <span
            style={{
              color: text === 'Passed' ? '#25C648' : '#FD5841',
            }}
          >
            {text}
          </span>
        );
      },
    },
  ];

  if (allPassed) {
    return <Result status="success" subTitle="All checks have passed" />;
  }

  return <Table columns={columns} dataSource={checksResult} pagination={false} />;
};
