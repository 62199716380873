import React from 'react';
import { useAppContext } from 'editor/src/AppStateContext';
import { NudgeType } from './NudgeList';
import NudgeDetail from './NudgeDetail';
import { useHistory, useParams } from 'react-router';
import { Container, Modal, Tooltip } from '@commandbar/design-system/components/antd';
import { AlertTriangle, InfoCircle } from '@commandbar/design-system/icons/react';
import { INudgeType } from '@commandbar/internal/middleware/types';
import * as staticNudgeTemplates from './nudge_templates';
import { getDisplayTitle } from '@commandbar/internal/util/nudges';
import Z from '@commandbar/internal/client/Z';

interface NudgeTemplateProps {
  type: NudgeType;
}

const NudgeTemplate = (props: NudgeTemplateProps) => {
  const params = useParams<{ templateId: string }>();

  const history = useHistory();
  const { dispatch, templates, organization } = useAppContext();

  const createFromTemplate = (templateData: any) => {
    const nudge: INudgeType = {
      ...staticNudgeTemplates.emptyNudge(props.type),
      ...templateData,
    };
    return nudge;
  };

  const activeNudge: INudgeType | null = React.useMemo(() => {
    const currentTemplate = templates.find((template) => template.id === Number(params.templateId));

    if (!currentTemplate) {
      return null;
    }

    return createFromTemplate(currentTemplate.data);
  }, [templates]);

  if (!activeNudge) {
    return null;
  }

  return (
    <Container>
      <div
        style={{
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#FFEDD5',
        }}
      >
        You are editing a template
        <Tooltip placement="auto" content="Changes made to this template won't affect existing nudges created from it.">
          <InfoCircle height={16} style={{ marginLeft: '8px', marginBottom: '-3px' }} />
        </Tooltip>
      </div>
      <NudgeDetail
        initialNudge={activeNudge}
        onClose={() => {
          history.goBack();
        }}
        onDelete={() => {
          Modal.confirm({
            zIndex: Z.Z_MODALS,
            icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
            title: `Are you sure? This template will be removed and no longer be available to members of ${organization.name}.`,
            async onOk() {
              dispatch.templates.delete(Number(params.templateId));
              history.goBack();
            },
          });
        }}
        onDuplicate={async (nudge) => {
          const template = await dispatch.templates.save({
            id: -1,
            type: props.type,
            data: { ...nudge, slug: `Copy of ${getDisplayTitle(nudge)}` },
          });
          return createFromTemplate(template.data);
        }}
        onSave={async (nudge) => {
          const template = await dispatch.templates.save({
            id: Number(params.templateId),
            type: nudge.type || 'announcement',
            data: { ...nudge },
          });
          return createFromTemplate(template.data);
        }}
        type={props.type}
      />
    </Container>
  );
};
export default NudgeTemplate;
