import React, { useEffect, useRef } from 'react';

import { INudgeType, IThemeV2Type } from '@commandbar/internal/middleware/types';
import { DEFAULT_THEME } from '@commandbar/internal/client/themesV2/defaults';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { useCommandBarContainer } from '../util/hooks/useCommandBarContainer';
import { useStore } from '../util/hooks/useStore';

const COMMANDBAR_WIDGET_CLASSNAME = 'cb-widget-ce80a7eb65'; // random string ensures uniqueness and no conflicts with classnames already on the page

const ThemeV2Context = React.createContext<{
  themeV2: IThemeV2Type;
  mode: 'light_mode' | 'dark_mode';
  generatedCSSClassname: string;
}>(undefined as unknown as any);

export const useThemeV2Context = () => React.useContext(ThemeV2Context);

let idCounter = 0;
export const BindThemeV2 = (
  props: React.PropsWithChildren<{
    themeV2: IThemeV2Type;
    mode?: 'light_mode' | 'dark_mode';
  }>,
) => {
  const parentContext = useThemeV2Context();
  const { root } = useCommandBarContainer();

  const mode = props.mode ?? parentContext.mode ?? 'light_mode';
  const { themeV2, children } = props;

  const generatedCSSClassnameRef = useRef<string | null>(null);
  let generatedCSSClassname = generatedCSSClassnameRef.current;
  if (!generatedCSSClassname) {
    generatedCSSClassnameRef.current = `${COMMANDBAR_WIDGET_CLASSNAME}-${idCounter++}`;
    generatedCSSClassname = generatedCSSClassnameRef.current;
  }

  // create a stylesheet for the theme and add to document
  useEffect(() => {
    const roots = new Set([document, root]);
    let sheet: CSSStyleSheet;
    try {
      sheet = new CSSStyleSheet();
    } catch (e) {
      // temporary workaround for older versions of Safari etc. which don't support this
      return;
    }

    const themeObject = {
      ...DEFAULT_THEME[mode].var_defaults,
      ...themeV2?.[mode].var_defaults,
      ...themeV2?.light_mode.var_overrides,
      ...themeV2?.[mode].var_overrides,
    };

    const cssVars = Object.entries(themeObject)
      .map(([key, value]) => {
        if (isStudioPreview() && key === '--font-font-family') {
          return `${key}: ${value}, ${DEFAULT_THEME.light_mode.var_defaults['--font-font-family']};`;
        }
        return `${key}: ${value};`;
      })
      .join('\n');
    sheet.insertRule(`.${generatedCSSClassname} { ${cssVars} }`);

    roots.forEach((r) => {
      // In older versions of Chrome, adoptedStyleSheets is a frozen array, so any inplace mutations (ie. .push()) throw an error
      // so we need to recreate the array with the new sheet, see https://web.dev/articles/constructable-stylesheets
      if (r) r.adoptedStyleSheets = [...(r.adoptedStyleSheets ?? []), sheet];
    });

    return () => {
      roots.forEach((r) => {
        if (r) r.adoptedStyleSheets = r.adoptedStyleSheets?.filter((s) => s !== sheet);
      });
    };
  }, [themeV2?.[mode].var_defaults, themeV2?.light_mode.var_overrides, themeV2?.[mode].var_overrides, mode, root]);

  return (
    <ThemeV2Context.Provider value={{ themeV2, mode, generatedCSSClassname }}>
      <div className={generatedCSSClassname} style={{ display: 'contents' }}>
        {children}
      </div>
    </ThemeV2Context.Provider>
  );
};

export const BindThemeV2ByUUID = (
  props: React.PropsWithChildren<{
    themeUUID: string | undefined | null;
    mode?: 'light_mode' | 'dark_mode';
  }>,
) => {
  const _ = useStore();
  const { themeUUID, mode, children } = props;

  // if we have an override set through the SDK we want to use that instead
  if (!themeUUID || _.themeV2Override) return <>{children}</>;

  const theme = _.themes.find((t) => t.uuid === themeUUID);
  if (theme)
    return (
      <BindThemeV2 themeV2={theme.themeV2} mode={mode}>
        {children}
      </BindThemeV2>
    );

  return <>{children}</>;
};

export const BindThemeV2ForNudge = (
  props: React.PropsWithChildren<{
    nudge: INudgeType;
  }>,
) => {
  const { organization } = useStore();

  const categoryThemeOverride = (() => {
    switch (props.nudge.type) {
      case 'announcement':
        return organization?.announcements_custom_theme;
      case 'product_tour':
        return organization?.product_tours_custom_theme;
      case 'survey':
        return organization?.surveys_custom_theme;
    }
  })();

  return (
    <BindThemeV2ByUUID themeUUID={props.nudge.custom_theme || categoryThemeOverride}>
      {props.children}
    </BindThemeV2ByUUID>
  );
};
