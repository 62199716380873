import Logger from '@commandbar/internal/util/Logger';

export const createFallbackHmac = () => {
  let retVal = '';

  for (let i = 0; i < 8; i++) {
    retVal += (Math.random() + 1).toString(36).substring(2, 10);
  }

  return retVal;
};

// Simply but high quality 53 bit hash function
// https://stackoverflow.com/a/52171480/2422744
const cyrb53 = function (str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const createClientPropsFingerprint = () => {
  return [
    navigator.userAgent,
    navigator.language,
    navigator.hardwareConcurrency,
    window.screen.width,
    window.screen.height,
    window.screen.colorDepth,
    window.screen.pixelDepth,
  ].join('|');
};

// basic canvas fingerprinting forked from https://github.com/Rajesh-Royal/Broprint.js
// File: https://github.com/Rajesh-Royal/Broprint.js/blob/b5bb0c4f2b055f987b09854c9fa7ef8f7e06e606/src/code/GenerateCanvasFingerprint.ts
// draw a canvas of given text and return its data uri
// different browser generates different dataUri based on their hardware configs
const createCanvasFingerprint = () => {
  const canvas = document.createElement('canvas');
  // If canvas is not supported simply return an empyt string and rely on basic client properties

  let ctx = null;

  try {
    ctx = canvas.getContext('2d');
  } catch (error) {
    return '';
  }
  if (!ctx) return '';

  // https://www.browserleaks.com/canvas#how-does-it-work
  const txt = 'CommandBar.com <canvas> 65@345876';

  ctx.textBaseline = 'top';
  ctx.font = "14px 'Arial'";
  ctx.textBaseline = 'alphabetic';
  ctx.fillStyle = '#f60';
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = '#069';
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
  ctx.fillText(txt, 4, 17);

  return canvas.toDataURL();
};

export const createFingerprint = () => {
  const canvasFingerprint = createCanvasFingerprint();
  const clientPropsFingerprint = createClientPropsFingerprint();

  const fingerprint = cyrb53(canvasFingerprint + clientPropsFingerprint);

  Logger.info('Anonymous user device fingerprint', fingerprint);

  return String(fingerprint);
};
