import { Key, useEffect, useState } from 'react';
import { LoadingState } from '../shared/styles';
import { Image05 } from '@commandbar/design-system/icons/react';
import { Col, Row, StatusBadge, Empty } from '@commandbar/design-system/components/antd';
import styled from '@emotion/styled';
import { Card, SectionContent } from '../../styles';
import FilterSelect, { StatusType } from 'editor/src/editor/components/FilterSelect';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import VideoEditDrawer from './VideoEditDrawer';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import * as Organization from '@commandbar/internal/middleware/organization';
import { CmdCheckbox, CmdSearchInput } from '@commandbar/design-system/cmd';
import BulkEditControls from '../shared/BulkEditControls';
import { useHistory } from 'react-router';
import { useAppContext } from 'editor/src/AppStateContext';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

interface VideosProps {
  showAddContent: boolean;
  setShowAddContent: (show: boolean) => void;
}

const Videos = ({ showAddContent, setShowAddContent }: VideosProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');
  const { organizationId } = useIntegrationsContext();
  const [activeVideo, setActiveVideo] = useState<IEditorCommandType>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [showBulkEditButton, setShowBulkEditButton] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const { commands, loading } = useAppContext();

  const videos = commands.filter((c) => c.template?.type === 'video');

  const filteredVideos = videos
    .filter((a) => a.text.toLowerCase().includes(searchText.toLowerCase()))
    .filter((a) => {
      const docStatus = a.is_live ? 'published' : 'unpublished';
      return selectedStatus === 'all' || selectedStatus === docStatus;
    });

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(history.location.search);
      const commandId = searchParams.get('id');

      if (commandId && organizationId && videos.length > 0) {
        try {
          const command = await Organization.getCommandById(organizationId.toString(), commandId);
          if (videos.find((v) => v.id === command.id)) {
            setActiveVideo(command);
            setShowAddContent(true);
          }
        } catch (e) {
          // ntd on invalid command ID
        }
      }
    };
    fetchData();
  }, [history.location.search, videos.length]);

  const renderBody = () => {
    if (isLoading || loading) return <LoadingState />;

    if (videos.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"You don't have any videos yet. Add one by clicking the 'Add Video' button above."}
        />
      );
    }

    return (
      <SectionContent>
        {filteredVideos.map((video) => (
          <VideoCard
            key={video.id}
            onClick={async () => {
              if (organizationId) {
                Organization.getCommandById(organizationId.toString(), video.id.toString()).then((matchingCommand) => {
                  history.replace({ search: `id=${matchingCommand.id}` });
                  setActiveVideo(matchingCommand);
                  setShowAddContent(true);
                });
              }
            }}
          >
            <VideoCardContent>
              <VideoImage>
                {video.thumbnail ? (
                  <img src={video.thumbnail?.src} alt={video.thumbnail?.file_name} width="auto" height="127.1px" />
                ) : (
                  <Image05 />
                )}

                {hasRequiredRole(user, 'editor') && (
                  <VideoCheckbox
                    key={`checkbox-${video.id}`}
                    id="checkbox"
                    visible={showBulkEditButton}
                    checked={selectedRowKeys.includes(video.id)}
                    onClick={(e) => {
                      if (selectedRowKeys.includes(video.id)) {
                        setSelectedRowKeys((selectedRowKeys) => selectedRowKeys.filter((k) => k !== video.id));
                        if (selectedRowKeys.length === 0) {
                          setShowBulkEditButton(false);
                        }
                      } else {
                        setShowBulkEditButton(true);
                        setSelectedRowKeys((selectedRowKeys) => [...selectedRowKeys, video.id]);
                      }
                      e.stopPropagation();
                    }}
                  />
                )}
              </VideoImage>
              <VideoCardSubcontent>
                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{video.text}</span>
                {video.is_live ? <StatusBadge color="green" /> : <StatusBadge color="orange" />}
              </VideoCardSubcontent>
            </VideoCardContent>
          </VideoCard>
        ))}
      </SectionContent>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', justifyContent: 'flex-end' }}>
        {showBulkEditButton ? (
          <BulkEditControls
            selectedRowKeys={selectedRowKeys}
            setShowBulkEditButton={setShowBulkEditButton}
            editableData={videos}
            setIsLoading={setIsLoading}
            setSelectedRowKeys={setSelectedRowKeys}
            allRowKeys={filteredVideos.map((v) => v.id)}
          />
        ) : (
          <>
            <Col>
              <FilterSelect selectedOption={selectedStatus} setSelectedOption={setSelectedStatus} />
            </Col>
            <Col>
              <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </Col>
          </>
        )}
      </Row>
      {renderBody()}
      <VideoEditDrawer
        organizationId={organizationId}
        open={showAddContent}
        onClose={() => {
          setShowAddContent(false);
          setActiveVideo(undefined);

          const searchParams = new URLSearchParams(history.location.search);
          searchParams.delete('id');
          history.replace(`${history.location.pathname}?${searchParams.toString()}`);
        }}
        activeVideo={activeVideo}
      />
    </>
  );
};

export default Videos;

const VideoCard = styled(Card)`
  width: 209px;
  height: fit-content;
  min-height: 0px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
    box-shadow: var(--shadow-elevation-medium);
    #checkbox {
      visibility: visible;
    }
  }
`;

const VideoCheckbox = styled(CmdCheckbox, {
  shouldForwardProp: (prop) => prop !== 'visible',
})<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  position: absolute;
  left: 16px;
  top: 16px;
`;

const VideoCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;
`;

const VideoCardSubcontent = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0px 4px;
`;

const VideoImage = styled.div`
  display: flex;
  height: 127.1px;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f3f4f5;
  overflow: hidden;
`;
