import { CmdButton } from '@commandbar/design-system/cmd';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import { ElementInformation } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import React, { useMemo } from 'react';
import { ReactComponent as RecordSelectorIcon } from '../../img/record_selector.svg';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Sender from 'editor/src/management/Sender';

export const ClickRecorder = (props: {
  onValueChange: (value: ElementInformation) => void;
  onStartClickRecorder?: (skipPrompt?: boolean) => void;
  children?: React.ReactNode;
}) => {
  const appContext = useAppContext();

  const id = useMemo(() => Math.random(), []);
  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;

    // no selectors mean the user cancelled the click recorder
    if (data.selectors.length > 0) {
      props.onValueChange(data.selectors[0]);
    }

    return respondSuccess();
  };

  usePortal({
    commandbar: {
      onClickRecorderComplete,
    },
  });

  return (
    <CmdButton
      onClick={(e) =>
        appContext.isStudio && props.onStartClickRecorder
          ? props.onStartClickRecorder(e.metaKey)
          : Sender.openClickRecorder(id, [], true)
      }
      icon={<RecordSelectorIcon />}
      id="record-command"
      variant="link"
    >
      {props.children}
    </CmdButton>
  );
};
