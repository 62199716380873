import { IBatchOperation, IEditorCommandType } from '@commandbar/internal/middleware/types';
import * as Command from '@commandbar/internal/middleware/command';

type createCommandProps = {
  title: string;
  url: string;
  organizationId: string;
  categoryID?: number;
  body?: string;
  urlOperation?: 'blank' | 'router' | 'self';
  sourceId?: number;
  thirdPartySource?: string;
};

export const createHelpDocCommand = async ({
  title,
  url,
  organizationId,
  categoryID,
  body,
  sourceId,
  thirdPartySource,
}: createCommandProps) => {
  const newCommand = Command.decodeEditorCommand({
    id: -1,
    organization: organizationId,
    text: title,
    content: body,
    is_live: true,
    template: { type: 'helpdoc', operation: 'blank', value: url || '' },
    category: categoryID,
    integration: sourceId ? { id: sourceId } : null,
    third_party_source: thirdPartySource ?? null,
  });

  return await Command.createWithoutThrottle(newCommand);
};

export const createHelpDocCommands = async (
  organizationId: string,
  categoryID: number,
  props: Omit<createCommandProps, 'organizationId'>[],
) => {
  const batch: IBatchOperation[] = [];

  for (const prop of props) {
    const _command: IEditorCommandType = Command.decodeEditorCommand({
      id: -1,
      organization: organizationId,
      text: prop.title,
      arguments: {},
      template: { type: 'link', value: prop.url, operation: 'blank' },
      tags: [],
      availability_rules: [],
      recommend_rules: [],
      confirm: '',
      shortcut: [],
      shortcut_mac: [],
      shortcut_win: [],
      hotkey_mac: '',
      hotkey_win: '',
      explanation: prop.body ?? '',
      is_live: true,
      category: categoryID || null,
      sort_key: null,
      icon: null,
      celebrate: null,
      recommend_sort_key: null,
    });
    batch.push({
      op: 'create',
      data: _command,
    });
  }

  const note = `Create ${props.length} help center command${props.length > 1 ? 's' : ''}`;

  const result = await Command.batch({ batch, note });

  return result;
};

export const createAlgoliaCommand = async ({
  title,
  url,
  organizationId,
  body,
  indexName,
  urlOperation = 'blank',
}: createCommandProps & { indexName: string }) => {
  const newCommand: IEditorCommandType = Command.decodeEditorCommand({
    id: -1,
    organization: organizationId,
    text: title,
    arguments: {
      [`record`]: {
        type: 'context',
        value: indexName,
        order_key: 1,
        label: `Select from the list below`,
        show_in_record_action_list: true,
        show_in_default_list: false,
      },
    },
    template: { type: 'link', value: url, operation: urlOperation, commandType: 'object', object: indexName },
    tags: [],
    availability_rules: [],
    recommend_rules: [],
    confirm: '',
    shortcut: [],
    shortcut_mac: [],
    shortcut_win: [],
    hotkey_mac: '',
    hotkey_win: '',
    explanation: body ?? '',
    heading: '',
    is_live: true,
    category: null,
    sort_key: null,
    icon: null,
    celebrate: null,
    recommend_sort_key: null,
    detail: null,
    content: null,
    show_preview: false,
  });

  return await Command.createWithoutThrottle(newCommand);
};
