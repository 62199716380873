import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { NamedRuleCondition as NamedRuleConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { builtInRules } from 'editor/src/editor/commands/RulesTab/builtInRulesDefintions';
import { ConditionProps } from '../../types';

export const NamedRuleReference: React.FC<ConditionProps<NamedRuleConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();
  const { organizationSettings, rules } = useAppContext();

  const rulesWithoutAudiences = rules.filter((r) => !r.is_audience);

  const rulesToSelect = organizationSettings?.silent_mode
    ? rulesWithoutAudiences
    : rulesWithoutAudiences.concat(builtInRules);

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />

      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.rule_id === -1 ? null : condition.rule_id}
          options={rulesToSelect.map((rule) => ({ value: rule.id, label: rule.name }))}
          onChange={(value) => {
            onConditionChange({ ...condition, rule_id: value as number });
          }}
          style={{ maxWidth: '100%', width: '100%' }}
          dropdownMatchSelectWidth={false}
          size="small"
          onBlur={() => setHasBlurred(true)}
        />
      </Col>
    </Row>
  );
};
