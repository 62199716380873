import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';
import { NudgeV } from './nudge';

export const ExperienceTemplateV = t.type(
  {
    id: t.number,
    type: t.union([t.literal('announcement'), t.literal('product_tour'), t.literal('survey')]),
    data: NudgeV,
  },
  'ExperienceTemplateV',
);

const defaults = {};

export class ExperienceTemplate {
  public static decode = (data: any) => decodeThrowing(ExperienceTemplateV, { ...defaults, ...data });

  public static create = createObject(ExperienceTemplateV, ExperienceTemplateV, 'templates');
  public static update = updateObject(ExperienceTemplateV, ExperienceTemplateV, 'templates');
  public static delete = deleteObject(ExperienceTemplateV, 'templates');
  public static list = listObject(ExperienceTemplateV, 'templates');
  public static read = readObject(ExperienceTemplateV, 'templates');
}
