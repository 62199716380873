/** @jsx jsx */
import React from 'react';
import { css, CSSObject, jsx, keyframes } from '@emotion/core';
import Block, { BLOCK_TYPE } from './Block';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { useStore } from 'shared/util/hooks/useStore';
import { currentStepAndIndex, getArgumentBlocks } from '../../../store/steps/selectors';
import { CBStore } from 'shared/store/global-store';
import { StepType } from '../../../store/steps/step-utils/Step';
import useOS from '@commandbar/internal/util/useOS';
import { submitDashboardStep, submitLongTextInput, submitMultiSelect } from '../../../store/actions';
import { useAction } from 'shared/util/hooks/useAction';
import StyledSpotlightContentHeader from '@commandbar/internal/client/themesV2/components/spotlight/StyledSpotlightContentHeader';

export enum BLOCK_POSITION {
  LEFT = 'left',
  TOP = 'top',
}

const moveBarRight = (offset: number) => {
  const bar = document.getElementById('commandbar-wrapper');
  if (bar) {
    const dialog = bar.querySelectorAll('.rc-dialog');
    if (dialog[0]) {
      // @ts-expect-error: FIXME Element type
      dialog[0].style.left = `${offset}px`;
    }
  }
};

const MIN_BLOCKS_TO_SHOW_LEFT = 3;

type StatusBarInstructions = {
  shortText: string;
  longText: string;
  onClick?: () => void;
  testId?: string;
};

const useStatusBarInstructions = (_: CBStore): StatusBarInstructions | undefined => {
  const { currentStep } = currentStepAndIndex(_);
  const { triggerKey } = useOS();

  const _submitLongTextInput = useAction((_) => submitLongTextInput(_, _.spotlight.inputText));
  const _submitMultiSelect = useAction(submitMultiSelect);

  const _submitDashboardStep = useAction(submitDashboardStep);

  switch (currentStep?.type) {
    case StepType.Base:
      if (currentStep.resource === null) {
        return undefined;
      } else {
        return {
          longText: 'Choose an action.',
          shortText: 'Choose an action.',
          testId: 'execution-intructions',
        };
      }
    case StepType.LongTextInput:
      return {
        longText: `Press ${triggerKey}+Enter or click here to submit`,
        shortText: 'Submit',
        onClick: _submitLongTextInput,
        testId: 'execution-instructions-text',
      };
    case StepType.MultiSelect:
      return {
        longText: `${triggerKey}+Enter or click here to submit`,
        shortText: 'Submit',
        onClick: _submitMultiSelect,
      };
    case StepType.TextInput:
      return {
        longText: `Type text and press Enter.`,
        shortText: 'Type text and press Enter',
      };
    case StepType.Dashboard:
      return {
        longText: `Enter or click here to continue`,
        shortText: 'Continue',
        onClick: _submitDashboardStep,
      };
    case StepType.Select:
    default:
      return undefined;
  }
};

const InstructionBlock = (props: { instructions: StatusBarInstructions | undefined; windowWidth: number }) => {
  const { instructions, windowWidth } = props;
  if (!instructions) return null;
  const instructionType: 'long' | 'short' | 'none' = windowWidth > 700 ? 'long' : windowWidth > 300 ? 'short' : 'none';
  if (instructionType === 'none') return null;
  const text = instructionType === 'long' ? instructions.longText : instructions.shortText;

  return (
    <div
      style={{
        minWidth: 0,
      }}
    >
      <div id={instructions.testId || 'execution-instructions'}>
        <Block
          key="instruction-text"
          content={{ text, type: instructions.onClick ? BLOCK_TYPE.CLICKABLE_INSTRUCTION : BLOCK_TYPE.INSTRUCTION }}
          position={BLOCK_POSITION.TOP}
          windowWidth={windowWidth}
          onClick={instructions.onClick}
        />
      </div>
    </div>
  );
};

const StatusBar = () => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();
  const content = getArgumentBlocks(_);
  const instructions = useStatusBarInstructions(_);
  const windowWidth = useWindowSize().width;

  const moveLeft = keyframes`
  0% {
    left: 0px;
  }

  100% {
    left: -110px;
  }
  `;

  const position: BLOCK_POSITION =
    windowWidth > 850 && content.length > MIN_BLOCKS_TO_SHOW_LEFT ? BLOCK_POSITION.LEFT : BLOCK_POSITION.TOP;

  React.useEffect(() => {
    if (position === BLOCK_POSITION.TOP) {
      moveBarRight(0);
    } else {
      moveBarRight(60);
    }
  }, [position]);

  if (!instructions && !(content.length > 0)) return <div />;

  const { currentStepIndex } = currentStepAndIndex(_);

  const showAnimation = currentStepIndex > 0 && _.spotlight.steps[currentStepIndex - 1].type === StepType.Base;

  const leftStyles: CSSObject = {
    flexDirection: 'column',
    position: 'absolute',
    left: -110,
    maxWidth: 100,
    zIndex: -1,
    overflow: 'visible',
    alignItems: 'flex-end',
    top: 0,
  };

  const topStyles: CSSObject = {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    maxWidth: '100%',
  };

  const animation = showAnimation
    ? css`
        animation: ${moveLeft} 0.4s;
        animation-fill-mode: forwards;
      `
    : null;

  const styles = ((): CSSObject => {
    switch (position) {
      case BLOCK_POSITION.LEFT:
        return leftStyles;
      case BLOCK_POSITION.TOP:
        return topStyles;
    }
  })();

  return _.flags?.['release-themes-v2'] ? (
    <StyledSpotlightContentHeader style={{ padding: '3px' }}>
      {position === BLOCK_POSITION.LEFT && <div />}
      <div
        css={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
          },
          animation,
        ]}
      >
        {content.map((block, i) => {
          if (content.length <= 2 && i > 0) return <div key={0} />;
          return <Block key={`block-${i}`} content={block} position={position} windowWidth={windowWidth} />;
        })}
      </div>
      <InstructionBlock instructions={instructions} windowWidth={windowWidth} />
    </StyledSpotlightContentHeader>
  ) : (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          paddingTop: theme.blocksContainer.paddingTop,
          paddingBottom: theme.blocksContainer.paddingBottom,
          paddingLeft: theme.blocksContainer.paddingLeft,
          paddingRight: theme.blocksContainer.paddingRight,
        }}
      >
        {position === BLOCK_POSITION.LEFT && <div />}
        <div
          css={[
            {
              display: 'flex',
              ...styles,
            },
            animation,
          ]}
        >
          {content.map((block, i) => {
            if (content.length <= 2 && i > 0) return <div key={0} />;
            return <Block key={`block-${i}`} content={block} position={position} windowWidth={windowWidth} />;
          })}
        </div>
        <InstructionBlock instructions={instructions} windowWidth={windowWidth} />
      </div>
    </div>
  );
};

export default StatusBar;
