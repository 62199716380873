import React from 'react';

const NoneStyleOverlay = () => {
  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          bottom: '0px',
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundImage:
            'linear-gradient(rgba(250, 250, 250, 0.3), rgba(250, 250, 250, 0.7), rgba(250, 250, 250, .9))',
        }}
      />
      <a
        href={`${process.env.REACT_APP_DASHBOARD_URL}/billing`}
        target="_blank"
        rel="noreferrer"
        style={{
          position: 'absolute',
          top: '10%',
          fontWeight: 650,
          fontSize: 20,
          left: 0,
          right: 0,
          textAlign: 'center',
          padding: 22,
          color: 'rgba(39, 85, 240, 1)',
        }}
      >
        Upgrade for access
      </a>
    </React.Fragment>
  );
};

export default NoneStyleOverlay;
