import {
  DetailPreviewObjectType,
  DetailPreviewType,
  OptionGroupRenderAsType,
} from '@commandbar/internal/middleware/types';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { CBStore } from 'shared/store/global-store';
import { interpolatePreview } from 'shared/util/Interpolate';

export type SearchMatch = {
  path: string;
  value: string;
  indices: number[][];
};

export enum OptionType {
  Command = 'command',
  Parameter = 'parameter',
}

export interface OptionCategoryMetadata {
  label: string;
  contextKey: string;
  limit: number | null;
  renderAs: OptionGroupRenderAsType;
}

export interface GenericOption<T extends OptionType> {
  type: T;
  label: string;
  value: string;
  optionDisabled: {
    isDisabled: boolean;
    isDisabledReason: string;
    reasonIsUserDefined: boolean;
  };
  searchMatches: SearchMatch[];
  searchScore: number | undefined;
  groupKey: string | undefined;
  category: OptionCategoryMetadata;
  detailPreview: DetailPreviewObjectType[] | undefined | null;
}

export interface GenericOptionMetadata<T extends OptionType>
  extends Pick<GenericOption<T>, 'type' | 'label' | 'value'> {
  contextKey?: string;
  uid?: string;
}

export const initOption = <T extends OptionType>(
  _: CBStore,
  type: T,
  label: string,
  value: string,
  _searchKeys?: string[],
  _detailPreview?: DetailPreviewType | null,
): GenericOption<T> => {
  // FLAG PERFORMANCE: Here we try to interpolate the details of all commands and records.
  // Interpolating into records isn't as common (since they aren't editor-defined).
  // So, if someone has a very large record-list and is experiencing slowness, then this might be related.
  // We try to fast-fail with lookaheads (e.g s.includes('{{')), but we could speed it up by simply not attempting to interpolate.
  const detailPreview = standardize(interpolatePreview(_detailPreview, _, true, false));

  const defaultCategoryMetadata: OptionCategoryMetadata = {
    label: '',
    contextKey: '',
    limit: null,
    renderAs: 'list',
  };

  return {
    type,
    label,
    value,
    detailPreview,
    optionDisabled: { isDisabled: false, isDisabledReason: '', reasonIsUserDefined: false },
    searchMatches: [],
    searchScore: undefined,
    groupKey: undefined,
    category: defaultCategoryMetadata,
  };
};
