import React from 'react';
import debounce from 'lodash/debounce';

import { useReportEvent } from '../../hooks/useEventReporting';
import { useAppContext } from 'editor/src/AppStateContext';

import type { IOrganizationSettingsType } from '@commandbar/internal/middleware/types';

const useNudgeSettings = () => {
  const { organization, dispatch } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const limit = React.useRef<IOrganizationSettingsType['nudge_rate_limit'] | null>(null);
  const period = React.useRef<IOrganizationSettingsType['nudge_rate_period'] | null>(null);

  React.useEffect(() => {
    (async () => {
      limit.current = organization?.nudge_rate_limit ?? null;
      period.current = organization?.nudge_rate_period ?? 'day';

      forceUpdate();
    })();
  }, []);

  const createOrUpdateLimitDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          nudge_rate_limit: limit.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('nudge rate limit updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${limit.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 500);
  }, [reportEvent, dispatch.organization.updateSetting]);

  const createOrUpdatePeriodDebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (!period.current) return;

        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;

        const newOrgSettings = {
          nudge_rate_period: period.current,
        } as IOrganizationSettingsType;

        await dispatch.organization.updateSetting(newOrgSettings);

        reportEvent('nudge rate limit period updated', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${period.current}`,
        });
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };

    return debounce(doSave, 500);
  }, [reportEvent, dispatch.organization.updateSetting]);

  return {
    limit: limit.current,
    period: period.current,

    actions: {
      limit: {
        update: (draft: IOrganizationSettingsType['nudge_rate_limit']): void => {
          limit.current = draft;
          forceUpdate();
          createOrUpdateLimitDebounced();
        },
      },
      period: {
        update: (draft: IOrganizationSettingsType['nudge_rate_period']): void => {
          period.current = draft;
          forceUpdate();
          createOrUpdatePeriodDebounced();
        },
      },
    },
  };
};

export default useNudgeSettings;
