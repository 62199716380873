import { CommandOption, Option } from './option-utils';
import { CBStore } from 'shared/store/global-store';
import { isCommandOptionValid } from './command-option-selectors';
import { OptionType } from './option-utils/Option';
import { compareOptionGroups, TabGroup } from './option-utils/OptionGroup';
import { assertNever } from 'shared/util/assertNever';
import { getTabKey } from '../selectors';
import { isRecordOption } from './helpers';
import { isAvailable } from './option-utils/OptionValidate';

export const isOptionExecutable = (_: CBStore, o: Option): { isExecutable: boolean; isExecutableReason: string } => {
  switch (o.type) {
    case OptionType.Command:
    case OptionType.Parameter:
      return { isExecutable: true, isExecutableReason: '' };
    default:
      return assertNever(o);
  }
};

export const isOptionValid = (_: CBStore, o: Option): { isValid: boolean; isValidReason: string } => {
  switch (o.type) {
    case 'command':
      return isCommandOptionValid(_, o as CommandOption);
    case 'parameter':
      return { isValid: true, isValidReason: '' };
    default:
      throw new Error('unreachable code');
  }
};

const selectTabProperties = (_: CBStore, groupKey: string) => {
  const tab = _.spotlight.tabs.find((t) => getTabKey(groupKey) === `${t.id}`);

  if (!tab) {
    return {};
  }

  return { icon: tab?.icon, header: tab?.header };
};

export const selectSearchTabs = (_: CBStore, includeHeaders?: boolean): TabGroup[] => {
  const { currentGroups } = _.spotlight;

  let retVal = currentGroups
    .filter((g) => !!g.searchTabEnabled)
    .sort(compareOptionGroups)
    .map((group) => {
      const tabGroup: TabGroup = { ...selectTabProperties(_, group.key), ...group };
      return tabGroup;
    });

  const minSortKeys: {
    [key: string]: number;
  } = {};

  if (!!includeHeaders) {
    const headerSortCompare = (a: TabGroup, b: TabGroup) => {
      const aHeader = a.header || '__no_header__';
      const bHeader = b.header || '__no_header__';

      if (!aHeader && !bHeader) {
        return compareOptionGroups(a, b);
      }
      if (!bHeader) {
        return -1;
      }
      if (!aHeader) {
        return 1;
      }

      if (aHeader === bHeader) {
        return compareOptionGroups(a, b);
      }

      // if headers are defined and unequal, but non of the tabs have a sort_key set, we need a tie breaker that make sure that tabs are still grouped together
      if (typeof minSortKeys[aHeader] === 'undefined' && typeof minSortKeys[bHeader] === 'undefined') {
        return aHeader.localeCompare(bHeader);
      }
      if (typeof minSortKeys[bHeader] === 'undefined') {
        return -1;
      }
      if (typeof minSortKeys[aHeader] === 'undefined') {
        return 1;
      }

      if (minSortKeys[aHeader] < minSortKeys[bHeader]) {
        return -1;
      } else if (minSortKeys[aHeader] > minSortKeys[bHeader]) {
        return 1;
      } else {
        return aHeader.localeCompare(bHeader);
      }
    };

    for (const group of retVal) {
      const headerkey = group.header || '__no_header__';
      if (group.sortKey !== null) {
        if (typeof minSortKeys[headerkey] === 'number') {
          minSortKeys[headerkey] = Math.min(group.sortKey, minSortKeys[headerkey]);
        } else {
          minSortKeys[headerkey] = group.sortKey;
        }
      }
    }

    retVal = retVal.sort(headerSortCompare);
  }

  return retVal;
};

/** WARNING: may have side effects, but is not supposed to! */
export const validateOptionAvailable = (
  _: CBStore,
  o: Option,
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  switch (o.type) {
    case 'parameter':
      if (!isRecordOption(o)) break;
      return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }

  return isAvailable(o, _);
};

/** WARNING: may have side effects, but is not supposed to! */
export const validateOptionDisabled = (_: CBStore, o: Option) => {
  const disabled = (msg: string, reasonIsUserDefined = false) => {
    return { isDisabled: true, isDisabledReason: msg, reasonIsUserDefined };
  };

  const { isValid, isValidReason } = isOptionValid(_, o);
  if (!isValid) {
    o.optionDisabled = disabled(isValidReason);
    return o.optionDisabled;
  }

  const { isExecutable, isExecutableReason } = isOptionExecutable(_, o);
  if (!isExecutable) {
    o.optionDisabled = disabled(isExecutableReason);
    return o.optionDisabled;
  }

  const { isAvailable, isAvailableReason, reasonIsUserDefined } = validateOptionAvailable(_, o);
  if (!isAvailable) {
    o.optionDisabled = disabled(isAvailableReason, reasonIsUserDefined);
    return o.optionDisabled;
  }

  return { isDisabled: false, isDisabledReason: '', reasonIsUserDefined: false };
};
