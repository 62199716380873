import React from 'react';
import { cn } from '../util';
import { inputFixStyles, inputStyles } from './styles';
import { CmdLabel, CmdTypography } from '../typography';
import { X } from '@commandbar/design-system/icons/react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  tooltip?: React.ReactElement | string;
  fullWidth?: boolean;
  prefixElement?: React.ReactElement | string;
  suffixElement?: React.ReactElement | string;
  clearable?: boolean;
  secondValue?: string | number | readonly string[] | undefined;
  onSecondValueChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  error?: boolean;
  errorMessage?: string;
  inline?: boolean;
}

const CmdInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      tooltip,
      fullWidth = false,
      prefixElement,
      suffixElement,
      clearable,
      onChange,
      secondValue,
      onSecondValueChange,
      disabled,
      error,
      errorMessage,
      inline,
      ...props
    },
    ref,
  ) => {
    const inputValue = (props.value as string) || '';
    const secondInputValue = secondValue || '';

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
    };

    const handleSecondInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onSecondValueChange && onSecondValueChange(e);
    };

    const clearInput = () => {
      onChange && onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    };

    return (
      <div className={cn('flex flex-col gap-xs', fullWidth && 'w-full')}>
        {label && (
          <CmdLabel disabled={disabled} tooltip={tooltip}>
            {label}
          </CmdLabel>
        )}
        <div
          className={cn(
            'flex border items-center rounded-md',
            fullWidth ? 'w-full' : 'w-fit',
            inputStyles({ disabled, fullWidth, error, inline }),
          )}
        >
          {prefixElement && <div className={cn(inputFixStyles({ disabled }), 'pr-xs')}>{prefixElement}</div>}
          <input
            className={cn(
              'flex-grow border-none bg-transparent focus-visible:outline-none truncate placeholder-baseContent',
              className,
              secondInputValue ? 'w-1/2' : 'w-full',
            )}
            ref={ref}
            disabled={disabled}
            {...props}
            value={inputValue}
            onChange={handleInputChange}
          />
          {secondInputValue && (
            <input
              className={cn(
                'flex-grow border-none bg-transparent focus-visible:outline-none truncate w-1/2',
                className,
              )}
              ref={ref}
              disabled={disabled}
              {...props}
              value={secondInputValue}
              onChange={handleSecondInputChange}
            />
          )}
          {clearable && (
            <div
              className={cn(inputFixStyles({ disabled, interactive: true, hidden: inputValue.length === 0 }))}
              onClick={clearInput}
            >
              <X />
            </div>
          )}
          {suffixElement && <div className={cn(inputFixStyles({ disabled, interactive: true }))}>{suffixElement}</div>}
        </div>
        {error && errorMessage && <CmdTypography.Body className="text-red800">{errorMessage}</CmdTypography.Body>}
      </div>
    );
  },
);

CmdInput.displayName = 'Input';

export { CmdInput };
