import React, { useCallback, useEffect } from 'react';

import { IAudienceType, IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { FormRow, Slider } from '@commandbar/design-system/components/antd';
import ToggleOrganizationSetting from '../../components/ToggleOrganizationSetting';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdDivider, CmdInput, CmdSwitch } from '@commandbar/design-system/cmd';
import debounce from 'lodash/debounce';
import { AudienceSelect } from '../../AudienceSelector';

interface IProps {
  organization: IOrganizationType;
  updateOrganizationProperty: (field: keyof IOrganizationType, newVal: any) => Promise<IOrganizationType>;
}

const SearchSettings = (props: IProps) => {
  const {
    dispatch: {
      organization: { updateSetting },
    },
    organization,
    rules,
    flags,
  } = useAppContext();

  const [askAiLabel, setAskAiLabel] = React.useState<string>(
    props.organization.spotlight_ask_copilot_label || `Ask ${organization?.copilot_name || 'Copilot'}`,
  );

  const debouncedUpdateSetting = useCallback(
    debounce(async (label: keyof IOrganizationSettingsType, value) => {
      await updateSetting({ [label]: value });
    }, 1200),
    [],
  );

  const onAskAiLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAskAiLabel(e.target.value);

    debouncedUpdateSetting('spotlight_ask_copilot_label', e.target.value);
  };

  // FIXME: Add search config to internals
  const MAX_VALUE = 100;
  const DEFAULT_VALUE = 100;
  const MIN_VALUE = 0;

  const validateValue = (value: number) => Math.max(Math.min(value, 100), MIN_VALUE);
  const getIntermediateValueInRange = (minValue: number, maxValue: number) => (factor: number) => {
    return Math.round((maxValue - minValue) * factor);
  };

  const getIntermediateByFactor = getIntermediateValueInRange(MIN_VALUE, MAX_VALUE);

  const onFuzzinessChange = (value: number | string | undefined) => {
    if (typeof value === 'number') {
      props.updateOrganizationProperty('search_fuzzy_threshold', validateValue(value));
    }
  };
  const getFuzzinessValue = (organization: IOrganizationType) => {
    return organization.search_fuzzy_threshold ? validateValue(organization.search_fuzzy_threshold) : DEFAULT_VALUE;
  };

  const marks = {
    [MIN_VALUE]: 'Exact match only',
    [getIntermediateByFactor(0.25)]: '25%',
    [getIntermediateByFactor(0.5)]: '50%',
    [getIntermediateByFactor(0.75)]: '75%',
    [MAX_VALUE]: 'Fuzzy',
  };

  const [audience, setAudience] = React.useState<IAudienceType | null>(
    props.organization.spotlight_ask_copilot_audience,
  );

  useEffect(() => {
    debouncedUpdateSetting('spotlight_ask_copilot_audience', audience);
  }, [audience]);

  return (
    <div>
      <FormRow
        title="Show Copilot Action"
        info="Show Copilot Action in Spotlight"
        input={
          <ToggleOrganizationSetting
            organization={props.organization}
            updateOrganizationSettingsProperty={(field, newVal) => updateSetting({ [field]: newVal })}
            setting="spotlight_ask_copilot_enabled"
          />
        }
      />
      <FormRow
        title="Copilot Action Label:"
        info="Customize the Copilot Action text shown in Spotlight"
        input={<CmdInput fullWidth value={askAiLabel} onChange={onAskAiLabelChange} />}
      />

      {flags['release-copilot-action-audience'] && (
        <>
          <FormRow
            title="Show only to specific users"
            info=""
            input={
              <CmdSwitch
                checked={audience !== null}
                onCheckedChange={(checked) => {
                  if (!checked) setAudience(null);
                  else setAudience({ type: 'all_users' });
                }}
              />
            }
          />
          {audience !== null && (
            <div style={{ marginLeft: 16 }}>
              <AudienceSelect rules={rules} audience={audience} onChange={(audience) => setAudience(audience)} />
            </div>
          )}
        </>
      )}
      <CmdDivider spacing="xl" />

      <FormRow
        gutter={[8, 48]}
        title="Fuzziness threshold"
        info={`Fuzziness threshold determines how strictly CommandBar's search algorithm treats typos in user queries.
      A threshold of 0% requires a perfect match between query and result. 
      A threshold of 100% is very permissive.
      A threshold of 15-25% is recommended, but you can experiment by changing this value and trying different searches.
      Note that CommandBar’s search algorithm isn’t used to filter records that have an attached search function.`}
        input={
          <Slider
            min={MIN_VALUE}
            max={MAX_VALUE}
            marks={marks}
            onAfterChange={onFuzzinessChange}
            defaultValue={getFuzzinessValue(props.organization)}
            style={{ top: '9px' }}
          />
        }
      />
    </div>
  );
};

export default SearchSettings;
