import React from 'react';
import RcTooltip from 'rc-tooltip';
import { useCommandBarContainer } from 'shared/util/hooks/useCommandBarContainer';
import Z from '@commandbar/internal/client/Z';
// import { useStore } from 'shared/util/hooks/useStore';
// import StyledTooltip from '@commandbar/internal/client/themesV2/components/shared/StyledTooltip';

interface CustomProps {
  disabled?: boolean;
  containerId?: string;
}

const Tooltip = (props: CustomProps & React.ComponentProps<typeof RcTooltip>) => {
  // const _ = useStore();
  const { root } = useCommandBarContainer();

  if (!!props.disabled) return props.children ?? null;
  if (!root) return props.children ?? null;
  const rcTooltipContainer = root.getElementById(props?.containerId ?? 'commandbar-rc-tooltip-container');
  if (!rcTooltipContainer) return props.children ?? null;

  // return _.flags?.['release-themes-v2'] ? (
  //   <StyledTooltip getTooltipContainer={() => rcTooltipContainer} {...props} />
  // ) : (
  //   <RcTooltip
  //     prefixCls="commandbar-tooltip"
  //     getTooltipContainer={() => rcTooltipContainer}
  //     overlayStyle={{ zIndex: Z.Z_INDEX_MAX }}
  //     {...props}
  //   />
  // );
  return (
    <RcTooltip
      prefixCls="commandbar-tooltip"
      getTooltipContainer={() => rcTooltipContainer}
      overlayStyle={{ zIndex: Z.Z_INDEX_MAX }}
      {...props}
    />
  );
};

export default Tooltip;
