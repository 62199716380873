import { SPECIAL_KEYS, orderedModifiers } from './hotkeyConstants';

const toArray = (v: string) => {
  if (typeof v !== 'string' || v === '') {
    return [];
  }
  // 'command+s o'
  let _v: Array<string> = v.split(' ');

  // _v = ['command+s', 'o']
  _v = _v.reduce((acc: Array<string>, val: string, idx: number): Array<string> => {
    if (idx === _v.length - 1) {
      return [...acc, val];
    }
    return [...acc, val, 'then'];
  }, []);

  // _v = ['command+s', 'then', 'o']
  _v = _v.reduce((acc: Array<string>, val: string) => {
    if (val.includes('+')) {
      return [...acc, ...val.split('+')];
    }
    return [...acc, val];
  }, []);

  // [_v = _v = 'command', 's', 'then', 'o']
  return _v;
};

const toString = (v: Array<string>, platform: 'mac' | 'win' = 'mac') => {
  if (v.length === 0) {
    return '';
  }

  let _v: string | string[] = v.map((k: string) => {
    if (k === 'command') return 'mod';
    if (k === 'option') return 'alt';
    if (k === 'ctrl' && platform === 'win') return 'mod';
    if (k === 'meta' && platform === 'mac') return 'mod';
    return k;
  });

  // _v = ['command', 's', 'then', 'o']
  _v = _v.join('+');

  // _v = 'command+s+then+o'
  _v = _v.split(/\+then\d?\+/);

  // _v =  ['command+s', 'o']
  _v = _v
    .map((combo: string | Array<string>) => {
      if (typeof combo === 'string') {
        return normalize(combo);
      } else {
        return combo.join('+');
      }
    })
    .join(' ');

  // 'command+s o'
  return _v;
};

const toPlatformSpecificString = (v: string, platform: 'mac' | 'win' = 'mac') => {
  if (v.length === 0) {
    return '';
  }

  if (platform === 'mac') {
    v = v.replace(/mod/g, 'command');
    v = v.replace(/meta/g, 'command');
    v = v.replace(/alt/g, 'option');
  } else {
    v = v.replace(/mod/g, 'ctrl');
  }

  return v;
};

const toModString = (v: string, platform: 'mac' | 'win' = 'mac') => {
  if (v.length === 0) {
    return '';
  }

  if (platform === 'mac') {
    v = v.replace(/command/g, 'mod');
    v = v.replace(/meta/g, 'mod');
    v = v.replace(/option/g, 'alt');
  } else {
    v = v.replace(/ctrl/g, 'mod');
  }

  return v;
};

const INVALID_ANCHORS = [' ', '+'];

const validate = (hotkey: string, allowUnknownKeys = false): boolean => {
  if (hotkey === null || hotkey === undefined || hotkey === '' || hotkey === ' ') return false;

  // Hotkeys may not start or end with these invalid anchors
  const validAnchors = INVALID_ANCHORS.every((anchor: string) => {
    if (hotkey.startsWith(anchor)) return false;
    if (hotkey.endsWith(anchor)) return false;
    return true;
  });
  if (!validAnchors) return false;

  // We do not allow hotkey sequences longer than 3
  if (hotkey.split(' ').length > 3) {
    return false;
  }

  const sequence = hotkey.split(' ').map((i: string) => {
    return i.split('+');
  });

  const validItems = sequence.every((i: string[]) => {
    // No sequence items may be empty
    if (i.length === 0) return false;
    // No sequence item may be only a modifier or random string
    if (i.length === 1 && isModifier(i[0])) return false;

    let numNonModifiers = 0;
    const modifiers: { [key: string]: number } = {};

    // At most one non-modifier key and no duplicate modifiers
    for (const key of i) {
      if (isModifier(key)) {
        modifiers[key] = (modifiers[key] || 0) + 1;
        if (modifiers[key] > 1) return false;
      } else {
        numNonModifiers++;
      }
    }

    if (numNonModifiers > 1) return false;

    if (!allowUnknownKeys) {
      const onlyKnownKeys = i.every((j: string) => {
        if (j.length === 1 || isModifier(j) || SPECIAL_KEYS.includes(j)) {
          return true;
        } else {
          return false;
        }
      });

      return onlyKnownKeys;
    }

    return true;
  });

  if (!validItems) return false;

  return true;
};

const validateEditorShortcutValues = (values: string): [boolean, string] => {
  if (values.length === 0) {
    return [true, 'ok'];
  }

  if (values.includes('then')) {
    return [false, 'use a space to separate shortcut sequence parts'];
  }

  if ((values.match(/\s/g) || []).length > 2) {
    return [false, 'at most 3 sequence parts are allowed'];
  }

  if (
    JSON.stringify(values) === JSON.stringify(['alt', 'shift']) ||
    JSON.stringify(values) === JSON.stringify(['ctrl', 'option'])
  ) {
    return [false, 'this is a browser protected accessibility shortcut'];
  }

  if (!validate(values)) {
    return [false, 'a shortcut must be a combination of distinct modifiers and at most one non-modifier key'];
  }

  return [true, 'ok'];
};

const isModifier = (key: string) => {
  return orderedModifiers.includes(key);
};

const sortKeys = (x: string, y: string) => {
  // Modifier keys come first
  if (isModifier(x) && !isModifier(y)) {
    return -1;
  } else if (!isModifier(x) && isModifier(y)) {
    return 1;
  }

  // There will be no duplicates
  return orderedModifiers.indexOf(x) - orderedModifiers.indexOf(y);
};

const normalizeSequence = (sequence: Array<Array<string> | string>) => {
  return sequence
    .map((combo: string | Array<string>) => {
      if (typeof combo === 'string') {
        return normalizeSequencePart(combo);
      }

      return combo.sort(sortKeys).join('+');
    })
    .join(' ');
};

const normalizeSequencePart = (shortcut: string) => {
  return shortcut.toLowerCase().split('+').sort(sortKeys).join('+');
};

const normalize = (shortcut: string) => {
  return normalizeSequence(shortcut.split(' '));
};

const translateToPlatformSymbol = (k: string, platform: 'mac' | 'win' = 'mac', isLastKey = false) => {
  if (platform === 'mac') {
    switch (k.toLowerCase()) {
      case 'then':
        return ' then ';
      case 'command':
      case 'meta':
      case 'mod':
        return '⌘';
      case 'option':
      case 'alt':
        return '⌥';
      case 'ctrl':
        return '^';
      case 'shift':
        return '⇧';
      case 'return':
        return 'Return';
      default:
        if (k.length > 1) {
          return k.toLowerCase();
        } else {
          return k.toUpperCase();
        }
    }
  } else {
    switch (k.toLowerCase()) {
      case 'then':
        return ' then ';
      case 'mod':
      case 'ctrl':
        return isLastKey ? 'Ctrl' : 'Ctrl+';
      case 'shift':
        return isLastKey ? 'Shift' : 'Shift+';
      case 'option':
      case 'alt':
        return isLastKey ? 'Alt' : 'Alt+';
      case 'meta':
        return '⊞';
      case 'return':
        return 'Return';
      default:
        if (k.length > 1) {
          return k.toLowerCase();
        } else {
          return k.toUpperCase();
        }
    }
  }
};

const mapHotkeysMacToWin = (v: string): string => {
  v = v.replace(/(command|meta)\+ctrl/g, 'ctrl+alt');
  v = v.replace(/(command|meta)/g, 'ctrl');
  v = v.replace(/option/g, 'alt');
  return v;
};

const mapHotkeysWinToMac = (v: string): string => {
  v = v.replace(/ctrl/g, 'command');
  v = v.replace(/meta/g, 'command');
  v = v.replace(/alt/g, 'option');
  return v;
};

// See https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
function escapeStringRegexp(commandHotkey: string) {
  return commandHotkey.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
}

const checkForConflicts = (hotkey1: string, hotkey2: string): boolean => {
  if (!hotkey1 || !hotkey2) {
    return false;
  }

  const isConflicting =
    new RegExp(`^${escapeStringRegexp(hotkey1)}($|\\s)`).test(hotkey2) ||
    new RegExp(`^${escapeStringRegexp(hotkey2)}($|\\s)`).test(hotkey1);

  return isConflicting;
};

const Hotkey = {
  toArray,
  toString,
  toPlatformSpecificString,
  toModString,
  translateToPlatformSymbol,
  validate,
  isModifier,
  normalize,
  normalizeSequence,
  validateEditorShortcutValues,
  mapHotkeysMacToWin,
  mapHotkeysWinToMac,
  checkForConflicts,
};

export default Hotkey;
