import {
  BookOpen01,
  CheckSquareBroken,
  Tool02,
  Users03,
  FaceIdSquare,
  ZapFast,
  Announcement02,
  MessageSmileSquare,
  Flag03,
  SearchSm,
  PaintPour,
  Folder,
  Link04,
} from '@commandbar/design-system/icons/react';

import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import type { Flags } from '@commandbar/internal/middleware/flags';
import { MenuItem } from './NavItem';
import { IUserType } from '@commandbar/internal/middleware/types';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

export const nudge_items: MenuItem[] = [
  {
    title: 'Tours',
    route: editorRoutes.PRODUCT_TOURS_ROUTE,
    Icon: Flag03,
  },
  {
    title: 'Announcements',
    route: editorRoutes.ANNOUNCEMENTS_ROUTE,
    Icon: Announcement02,
  },
  {
    title: 'Surveys',
    route: editorRoutes.SURVEYS_ROUTE,
    Icon: MessageSmileSquare,
  },
  {
    title: 'Checklists',
    route: editorRoutes.CHECKLIST_ROUTE,
    Icon: CheckSquareBroken,
  },
];

export const assist_items: MenuItem[] = [
  {
    title: 'Copilot',
    route: editorRoutes.COPILOT_PARENT_ROUTE,
    Icon: FaceIdSquare,
  },
  {
    title: 'HelpHub',
    route: editorRoutes.HELPHUB_ROUTE,
    Icon: BookOpen01,
  },
  {
    title: 'Spotlight',
    route: editorRoutes.SPOTLIGHT_ROUTE,
    Icon: SearchSm,
  },
];

export const content_items: MenuItem[] = [
  {
    title: 'Content ↗',
    Icon: Folder,
    handler: () => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/content/sources`, '_blank'),
  },
  {
    title: 'Pages',
    route: editorRoutes.PAGES_ROUTE,
    Icon: Link04,
  },
  {
    title: 'Actions',
    route: editorRoutes.ACTIONS_ROUTE,
    Icon: ZapFast,
  },
];

export const settings_items: (flags: Flags, user: IUserType | null) => MenuItem[] = (
  flags: Flags,
  user: IUserType | null,
) => [
  ...(hasRequiredRole(user, 'editor')
    ? [
        {
          title: 'Audiences ↗',
          Icon: Users03,
          handler: () => window.open(`${process.env.REACT_APP_DASHBOARD_URL}${editorRoutes.AUDIENCES_ROUTE}`, '_blank'),
        },
      ]
    : []),
  flags['release-themes-v2']
    ? {
        title: 'Themes ↗',
        Icon: PaintPour,
        handler: () =>
          window.open(`${process.env.REACT_APP_DASHBOARD_URL}/editor${editorRoutes.THEME_ROUTE}`, '_blank'),
      }
    : {
        title: 'Themes',
        route: editorRoutes.DESIGN_ROUTE,
        Icon: PaintPour,
      },
  {
    title: 'Tools',
    route: editorRoutes.TOOLS_ROUTE,
    Icon: Tool02,
  },
];
