import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { useIntegrationsContext } from '../../util/IntegrationContext';

import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { AlertHexagon, ClockRefresh, EyeOff, File05, Globe01 } from '@commandbar/design-system/icons/react';
import SettingsDropdown from '../shared/SettingsDropdown';

import type { Source } from '../../integrations/shared/types';
import { CmdButton, CmdTooltip } from '@commandbar/design-system/cmd';
import useSourceState from './useSourceState';
import SourceProcessingProgress from './SourceProcessingProgress';
import { getDaysSinceDate } from '@commandbar/internal/util/time';

export const DocumentCountPill = ({ count }: { count: number }) => (
  <Pill style={{ background: `${CB_COLORS.blue200}`, color: `${CB_COLORS.blue1000}` }}>
    <File05 />
    {count?.toLocaleString()} {count === 1 ? 'Article' : 'Articles'}
  </Pill>
);

export const Pills = ({ source }: { source: Source }) => {
  const daysSinceLastSync = getDaysSinceDate(source.recent_sync_date);

  return (
    <>
      <DocumentCountPill count={source.commands_count ?? 0} />

      {source.meta?.training_only && (
        <Pill style={{ background: '#FFEDD5', color: '#7C2D12' }}>
          <EyeOff />
          Training Only
        </Pill>
      )}

      <Pill style={{ background: '#EDE9FE', color: '#4C1D95' }}>
        <ClockRefresh />
        Last sync {`${daysSinceLastSync ? `${daysSinceLastSync}d ago` : 'today'}`}
      </Pill>

      {source.recent_sync_error && (
        <CmdTooltip message="The most recent sync did not complete successfully. Please contact Command AI support if you need help.">
          <Pill style={{ background: '#FFD6D6', color: '#7C2D12' }}>
            <AlertHexagon />
            Sync Error
          </Pill>
        </CmdTooltip>
      )}
    </>
  );
};

export const IntegrationIcon = ({ source }: { source: Source }) => {
  if (source.icon) {
    return (
      <Icon>
        <img alt={`${source.type}-icon`} src={source.icon} width={22} height={22} />
      </Icon>
    );
  }

  if (source.type === 'web') {
    return (
      <Icon style={{ background: CB_COLORS.blue200 }}>
        <Globe01 color={CB_COLORS.blue900} width={22} height={22} />
      </Icon>
    );
  }

  return (
    <Icon>
      <img alt={`${source.type}-icon`} src={source.iconURL} width={22} height={22} />
    </Icon>
  );
};

interface SourceCardContentProps {
  source: Source;
  title: string;
  setSelectedSource: Dispatch<SetStateAction<Source | undefined>>;
}

const SouceCardContent = ({ source, title, setSelectedSource }: SourceCardContentProps) => {
  const history = useHistory();
  const domain: string | null =
    source.meta?.domain ?? source.meta?.single_urls?.[0] ?? source.meta?.recursive_urls?.[0] ?? null;

  return (
    <>
      <Content onClick={() => history.push(`/content/sources/${source.id}`)}>
        <IconAndTitleContainer>
          <IntegrationIcon source={source} />

          <TitleContainer>
            <Title>{title}</Title>
            {domain && <Domain>{domain}</Domain>}
          </TitleContainer>
        </IconAndTitleContainer>

        <Actions>
          <CmdButton>View</CmdButton>
          <SettingsDropdown
            source={source}
            onSettingsClick={() => {
              setSelectedSource(source);
            }}
          />
        </Actions>
      </Content>
      <Meta>
        <Pills source={source} />
      </Meta>
    </>
  );
};

interface SourceCardProps {
  source: Source;
  setSelectedSource: Dispatch<SetStateAction<Source | undefined>>;
  selectedSource?: Source;
}

const SourceCard = ({ source, selectedSource, setSelectedSource }: SourceCardProps) => {
  const { refreshHelpDocIntegrations } = useIntegrationsContext();
  const { progress, numFetched, numProcessed, ping, operation } = useSourceState(
    String(source.id),
    refreshHelpDocIntegrations,
  );

  useEffect(() => {
    // if we close the SourceEditDrawer, ping the server to see if we're currently syncing
    if (!selectedSource) {
      ping();
    }
  }, [selectedSource]);

  const title = source.name;
  const webDomain = source.meta?.recursive_urls?.[0] ?? source.meta?.single_urls?.[0];

  return (
    <Card>
      {operation ? (
        <SourceProcessingProgress
          progress={progress}
          numFetched={numFetched}
          numProcessed={numProcessed}
          title={webDomain ? `${title} - ${webDomain}` : title}
          size="small"
          operation={operation}
        />
      ) : (
        <SouceCardContent source={source} title={title} setSelectedSource={setSelectedSource} />
      )}
    </Card>
  );
};

export default SourceCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${CB_COLORS.neutral300};
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.11);
`;

export const Pill = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #fff;
  color: ${CB_COLORS.neutral800};
  leading-trim: both;
  text-edge: cap;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
`;

export const Icon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${CB_COLORS.neutral300};
  background: #fff;
`;

const Content = styled.div`
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-self: stretch;

  &:hover {
    cursor: pointer;
  }
`;

const Meta = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-top: 1px solid ${CB_COLORS.neutral200};
  border-radius: 0 0 8px 8px;
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.span`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-weight: 600;
`;

const Domain = styled.span`
  color: ${CB_COLORS.neutral800};
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  width: 100%;
  max-width: 50vw;
  word-wrap: break-word;
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;
