import * as t from 'io-ts';
import { RecordSettingsByContextKeyV } from './RecordSettingsV';
import { LabeledActionV } from '@cb/types/entities/command/actions';
import { IOrganizationType } from './types';
import { HelpHubLauncherSettingsV, HelpHubSearchActionsV } from './helpHub';
import { ThemeV2V } from './theme';
import { CopilotPersonalityV } from './helpers/copilotPersonality';
import { AudienceV } from './helpers/audience';

const OrganizationBaseV = t.intersection(
  [
    t.type({
      id: t.union([t.number, t.string]),
      name: t.string,
      created: t.string,
    }),
    t.partial({}),
  ],
  'OrganizationBase',
);

const OrganizationAdditionalV = t.intersection([
  t.type({
    disabled: t.boolean,
    launcher_type: t.union([t.literal('minimal'), t.literal('alternate'), t.literal('prompt'), t.literal('none')]),
    launcher_position: t.union([
      t.literal('topRight'),
      t.literal('topLeft'),
      t.literal('bottomRight'),
      t.literal('bottomLeft'),
    ]),
    show_launcher_recommendations: t.boolean,
    recommendations_type: t.union([t.literal('None'), t.literal('Custom'), t.literal('Algorithm'), t.undefined]), // DEPRECATED
    launcher_offset_x: t.number,
    launcher_offset_y: t.number,
    base_url: t.string,
    theme: t.object,
    themeV2: ThemeV2V,
    icon: t.string,
    icon_suggest: t.string,
    icon_tutorial: t.string,
    icon_go_forward: t.string,
    logo_spotlight: t.union([t.string, t.null]),
    chat_system_prompt_modifier: t.string,
    helphub_chat_welcome_message: t.string,
    helphub_chat_avatar: t.string,
    resource_options: RecordSettingsByContextKeyV,
    should_show_onboarding: t.boolean,
    last_snippet_request: t.union([t.string, t.null]),
    last_snippet_request_in_production: t.union([t.string, t.null]),
    branding: t.string,
    custom_call_to_action: t.string, // DEPRECATED
    search_fuzzy_threshold: t.union([t.number, t.null]),
    show_skin_editor: t.boolean,
    allow_event_handlers: t.boolean,
    in_bar_feedback: t.boolean,
    summon_hotkey_override: t.union([t.string, t.null]),
    end_user_hotkeys: t.union([t.undefined, t.boolean]),
    force_end_user_identity_verification: t.boolean,
    /* @deprecated */
    force_end_user_identity_verification_for_helphub: t.boolean,
    end_user_shortcuts_enabled: t.boolean,
    end_user_recents_enabled: t.boolean,
    releases_available: t.boolean,
    releases_enabled: t.boolean,
    fallback_commands: t.array(t.number),
    event_attribute_block_list: t.array(t.string),
    tab_direction: t.union([t.literal('horizontal'), t.literal('vertical')]),
    recommended_tab_enabled: t.boolean,
    recents_tab_enabled: t.boolean,
    recommended_tab_instruction: t.string,
    recents_tab_instruction: t.string,
    hotload_help_docs: t.boolean,
    slash_filters_enabled: t.boolean,
    recents_sort_key: t.union([t.number, t.null]),
    recommended_sort_key: t.union([t.number, t.null]),
    experiences_sort_key: t.union([t.number, t.null]),
    show_suggested_synonyms: t.boolean,
    has_hotloaded_help_docs: t.boolean,
    bar_enabled: t.boolean,
    in_bar_doc_search: t.boolean,
    featured_item_cards: t.boolean,
    helphub_suggested_queries_enabled: t.boolean,
    helphub_continuations_enabled: t.boolean,
    helphub_enabled: t.boolean,
    helphub_chat_fallback_message: t.union([t.string, t.null]),
    helphub_chat_fallback_actions: t.array(LabeledActionV),
    helphub_search_action: HelpHubSearchActionsV,
    helphub_custom_theme: t.union([t.string, t.null]),

    product_tours_custom_theme: t.union([t.string, t.null]),
    announcements_custom_theme: t.union([t.string, t.null]),
    surveys_custom_theme: t.union([t.string, t.null]),
    checklists_custom_theme: t.union([t.string, t.null]),

    copilot_chat_history_enabled: t.boolean,
    copilot_negative_feedback_fallback_enabled: t.boolean,
    copilot_negative_feedback_fallback_message: t.union([t.string, t.null]),
    copilot_negative_feedback_fallback_actions: t.array(LabeledActionV),
    copilot_expand_read_more_by_default: t.boolean,
    helphub_manual_suggested_queries: t.array(t.string),
    copilot_name: t.string,
    copilot_avatar: t.string,
    copilot_avatar_v2: t.union([t.type({ src: t.string, file_name: t.string, size: t.string }), t.null]),
    copilot_personality: CopilotPersonalityV,
    nudge_rate_limit: t.union([t.null, t.number]),
    bar_hide_completed_nudges_questlists: t.boolean,
    nudge_rate_period: t.union([t.literal('day'), t.literal('week'), t.literal('session')]),
    share_link_param: t.string,
    installed_at: t.union([t.string, t.null]),
    integrations: t.partial({
      algolia: t.type({
        indexes: t.record(t.string, t.union([t.string, t.record(t.string, t.string)])),
        applicationID: t.union([t.string, t.undefined]),
        apiKey: t.union([t.string, t.undefined]),
      }),
      heap: t.type({
        segments: t.record(t.string, t.string),
        send_events_to_heap: t.boolean,
      }),
      amplitude: t.intersection([
        t.type({
          enabled: t.boolean,
        }),
        t.partial({
          cohorts: t.record(t.string, t.string),
          api_key: t.string,
        }),
      ]),
      logrocket: t.type({
        enabled: t.boolean,
      }),
      mixpanel: t.type({
        enabled: t.boolean,
      }),
      posthog: t.type({
        enabled: t.boolean,
      }),
      rudderstack: t.type({
        enabled: t.boolean,
      }),
      slack: t.type({
        channelId: t.string,
        configuration: t.type({
          analytic_events: t.array(t.string),
        }),
      }),
      hubspot: t.partial({
        send_events_to_hubspot: t.boolean,
        lists: t.record(t.string, t.string),
      }),
      sunshine: t.partial({
        enabled: t.boolean,
        app_id: t.string,
        key_id: t.string,
        secret_key: t.string,
        integration_id: t.string,
        webhook_shared_secret: t.string,
        switchboard_id: t.string,
      }),
      freshchat: t.partial({
        topic_name: t.union([t.string, t.null]),
        conversation_property_name: t.union([t.string, t.null]),
      }),
      intercom: t.partial({
        enabled: t.boolean,
        token: t.string,
      }),
    }),
    copilot_enabled: t.boolean,
    user_property_targeting: t.boolean,
    ab_test_conditions: t.boolean,
    spotlight_ask_copilot_enabled: t.boolean,
    spotlight_ask_copilot_label: t.union([t.string, t.null]),
    spotlight_custom_theme: t.union([t.string, t.null]),
    spotlight_ask_copilot_audience: t.union([AudienceV, t.null]),

    /** @deprecated Replaced by the `copilot_enabled` field */
    helphub_ai_enabled: t.boolean,
    /** @deprecated Removed entirely */
    copilot_experiences_enabled: t.boolean,
    /** @deprecated Replaced by a combination of `copilot_enabled` and `helphub_enabled` */
    helphub_chat_only_mode: t.boolean,
  }),
  HelpHubLauncherSettingsV,
]);

export const defaults: t.TypeOf<typeof OrganizationAdditionalV> = {
  allow_event_handlers: true,
  branding: '',
  base_url: '',
  custom_call_to_action: '',
  disabled: false,
  icon: 'iconic',
  icon_suggest: '',
  icon_tutorial: '',
  icon_go_forward: '',
  logo_spotlight: null,
  chat_system_prompt_modifier: '',
  helphub_chat_welcome_message: '',
  helphub_chat_avatar: '',
  in_bar_feedback: false,
  summon_hotkey_override: null,
  last_snippet_request: '',
  last_snippet_request_in_production: '',
  launcher_offset_x: 0,
  launcher_offset_y: 0,
  launcher_position: 'bottomRight',
  helphub_launcher_offset_x: 0,
  helphub_launcher_offset_y: 60,
  helphub_launcher_anchor: '',
  helphub_launcher_position: 'bottomRight',
  helphub_launcher_type: 'none',
  helphub_mobile_launcher_offset_x: 0,
  helphub_mobile_launcher_offset_y: 60,
  helphub_mobile_launcher_anchor: '',
  helphub_mobile_launcher_position: 'bottomRight',
  helphub_mobile_launcher_type: 'none',
  launcher_type: 'minimal',
  recommendations_type: undefined,
  resource_options: {},
  search_fuzzy_threshold: null,
  should_show_onboarding: false,
  show_launcher_recommendations: false,
  show_skin_editor: false,
  theme: {},
  themeV2: null,
  end_user_hotkeys: false,
  end_user_shortcuts_enabled: false,
  end_user_recents_enabled: false,
  force_end_user_identity_verification: false,
  force_end_user_identity_verification_for_helphub: false,
  releases_available: false,
  releases_enabled: false,
  fallback_commands: [],
  event_attribute_block_list: [],
  tab_direction: 'horizontal',
  recommended_tab_enabled: false,
  recents_tab_enabled: false,
  recommended_tab_instruction: '',
  recents_tab_instruction: '',
  hotload_help_docs: false,
  slash_filters_enabled: true,
  recents_sort_key: null,
  recommended_sort_key: null,
  experiences_sort_key: null,
  show_suggested_synonyms: false,
  has_hotloaded_help_docs: false,
  bar_enabled: true,
  helphub_enabled: false,
  helphub_suggested_queries_enabled: false,
  helphub_continuations_enabled: false,
  helphub_chat_fallback_message: '',
  helphub_chat_fallback_actions: [],
  helphub_manual_suggested_queries: [],
  helphub_search_action: 'search',
  copilot_name: 'Copilot',
  copilot_avatar: '',
  copilot_avatar_v2: null,
  copilot_personality: { template: 'professional' },
  copilot_chat_history_enabled: false,
  copilot_negative_feedback_fallback_enabled: false,
  copilot_negative_feedback_fallback_message: '',
  copilot_negative_feedback_fallback_actions: [],
  copilot_expand_read_more_by_default: false,
  nudge_rate_limit: null,
  bar_hide_completed_nudges_questlists: false,
  nudge_rate_period: 'day',
  in_bar_doc_search: false,
  featured_item_cards: true,
  installed_at: null,
  integrations: {},
  copilot_enabled: false,
  share_link_param: 'cb-eid',
  user_property_targeting: false,
  ab_test_conditions: false,
  spotlight_ask_copilot_enabled: true,
  spotlight_ask_copilot_label: null,
  helphub_custom_theme: null,

  product_tours_custom_theme: null,
  announcements_custom_theme: null,
  surveys_custom_theme: null,
  checklists_custom_theme: null,

  spotlight_custom_theme: null,
  spotlight_ask_copilot_audience: null,

  /** @deprecated Replaced by the `copilot_enabled` field */
  helphub_ai_enabled: false,
  /** @deprecated Removed entirely */
  copilot_experiences_enabled: false,
  /** @deprecated Replaced by a combination of `copilot_enabled` and `helphub_enabled` */
  helphub_chat_only_mode: false,
};

export const OrganizationV = t.intersection([OrganizationBaseV, OrganizationAdditionalV], 'Organization');

export const unknownOrganization: IOrganizationType = {
  id: '42424242',
  name: 'Unknown',
  created: '',
  ...defaults,
};
