import React, { useEffect, useState } from 'react';

import { Alert, Form } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import Logger from '@commandbar/internal/util/Logger';
import Spinner from '../../table/Spinner';
import IntercomOAuth from '../non-help-center/IntercomOAuth';
import { type HelpCenterIntegrationSpec, INTEGRATION_TYPE, type SourceConfigFormProps } from '../shared/types';

const IntercomForm = ({ editingSource, token }: SourceConfigFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgToken, setOrgToken] = useState<string>();

  useEffect(() => {
    if (editingSource?.organization) {
      setIsLoading(true);
      Organization.readInternal(editingSource?.organization)
        .then(({ integrations }) => {
          setOrgToken(integrations?.intercom.token);
        })
        .catch((e) => {
          Logger.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [editingSource?.organization]);

  if (isLoading) {
    return (
      <Spinner>
        <Loading01 />
      </Spinner>
    );
  }

  return (
    <Form.Item
      key={`${editingSource?.type}token`}
      validateTrigger="onSubmit"
      initialValue={orgToken ?? (token || editingSource?.meta?.token)}
      label="Intercom API Access"
      name="token"
      style={{ flex: 5 }}
      required
    >
      <IntercomOAuth
        token={orgToken ?? (token || editingSource?.meta?.token)}
        // TODO: Remove once everyone is on V2 Intercom App
        isV2Token={
          !!orgToken ?? (!!editingSource?.meta?.is_v2_token || (!!token && token !== editingSource?.meta?.token))
        }
        sourceId={editingSource?.id}
        isHelpDocsIntegration
      />
    </Form.Item>
  );
};

const Intercom: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.INTERCOM,
  name: 'Intercom',
  slug: 'intercom',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/intercom-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/intercom-icon.png',
  isDisabled: false,
  useDryRun: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={
        "In order to import Intercom help center docs, you'll have to first authorize Command AI to have read access to your help center."
      }
    />
  ),
  route: '/intercom',

  getForm: IntercomForm,

  getPayloadMeta(data, _, editingSource) {
    return {
      token: data['token'],
      // TODO: Remove once everyone is on V2 Intercom App
      is_v2_token: editingSource?.meta?.is_v2_token || data['token'] !== editingSource?.meta?.token,
    };
  },
  requiredFields: ['token'],
  fetchedFields: [],
};

export default Intercom;
