/** @jsx jsx */
import React, { useEffect } from 'react';
import { RgbaStringColorPicker } from 'react-colorful';

// Color converter https://github.com/omgovich/colord
import { colord, extend } from 'colord';
import namesPlugin from 'colord/plugins/names';
import { css, jsx } from '@emotion/core';
extend([namesPlugin]);

const style = css({
  '& .react-colorful__saturation': {
    borderRadius: '6px',
    borderBottom: '0px',
  },

  '& .react-colorful__hue': {
    borderRadius: '1000px',
    height: '8px',
  },

  '& .react-colorful__alpha': {
    borderRadius: '1000px',
    height: '8px',
  },

  '& .react-colorful__pointer ': {
    width: '16px',
    height: '16px',
  },

  '& .react-colorful__interactive:focus .react-colorful__pointer': {
    transform: 'translate(-50%,-50%)',
  },
});

export const ColorPicker = ({
  initialColor,
  position,
  onChange,
  onClose,
  ...rest
}: {
  initialColor: string;
  position: { top: number; left: number };
  onChange: (newColor: string) => void;
  onClose: () => void;
} & React.ComponentProps<typeof RgbaStringColorPicker>) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const initialColorRef = React.useRef<string>(
    initialColor.startsWith('rgba') ? initialColor : colord(initialColor).toRgbString(),
  );

  const handleOutsideClick = (e: MouseEvent) => {
    if (e.target instanceof Node && containerRef.current && containerRef.current.contains(e.target)) return;
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, { capture: true });
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, { capture: true });
    };
  }, []);

  return (
    <div style={{ position: 'absolute', top: position.top, left: position.left }} ref={containerRef}>
      <RgbaStringColorPicker
        css={style}
        style={{
          zIndex: 1,
          gap: '12px',
          background: 'white',
          border: '1px solid rgba(0, 0, 0, 0.16)',
          borderRadius: '12px',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 1px 8px 0px rgba(0, 0, 0, 0.16)',
          padding: '12px',
        }}
        color={initialColorRef.current}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};
