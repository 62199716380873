import { Input as AntInput } from 'antd';
import hoistNonReactStatics from 'hoist-non-react-statics';

import styled from '@emotion/styled';

// @ts-expect-error: FIXME ant
const Input: typeof AntInput = styled(AntInput)`
  &&& {
    padding-top: 6px;
    padding-bottom: 6px;

    .ant-input {
      font-size: 12px;
    }
  }
`;

hoistNonReactStatics(Input, AntInput);

export default Input;
export type { InputProps } from 'antd';
export type { TextAreaProps } from 'antd/lib/input/TextArea';
