import React from 'react';
import { Col, Input } from '@commandbar/design-system/components/antd';
import { useConditionEditor } from '../state/useConditionEditor';

export const ConditionFieldInput: React.FC = () => {
  const { condition, onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  if (!('field' in condition)) {
    throw new Error('Invalid condition type for ConditionFieldInput');
  }

  return (
    <Col flex="1 3 auto" style={{ overflow: 'hidden' }}>
      <Input
        disabled={disabled}
        value={condition.field}
        placeholder="value"
        style={{ height: '24px' }}
        onBlur={() => setHasBlurred(true)}
        onChange={(e) => {
          onConditionChange({
            ...condition,
            field: e.target.value.trim(),
          });
        }}
        size="small"
      />
    </Col>
  );
};
