// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Caret01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.1521 9.80749L8.42044 5.31666C8.31362 5.18811 8.10795 5.18811 8 5.31666L4.26833 9.80749C4.1297 9.97495 4.2547 10.2202 4.47855 10.2202H11.9419C12.1657 10.2202 12.2907 9.97495 12.1521 9.80749Z"
    />
  </svg>
);
export default Caret01;
