import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import React from 'react';
import Sender from '../management/Sender';
import { useAppContext } from '../AppStateContext';
import { useAsyncCallback } from '@commandbar/internal/util/useAsyncCallback';

const useWindowInfo = (dependencies: any[] = []) => {
  const [userCallbacks, setUserCallbacks] = React.useState<string[]>([]);
  const [callbacks, setCallbacks] = React.useState<string[]>([]);
  const [context, setContext] = React.useState<Record<string, any>>({});
  const [hasRouter, setHasRouter] = React.useState(true);
  const [programmaticCommands, setProgrammaticCommands] = React.useState<IEditorCommandType[]>([]);
  const [components, setComponents] = React.useState<{ key: string; name: string }[]>([]);
  const [trackedEvents, setTrackedEvents] = React.useState<string[]>([]);

  const { commands, isStudio } = useAppContext();

  const reload = useAsyncCallback(function* () {
    const { callbacks } = yield Sender.shareCallbacks();

    setCallbacks(callbacks);
    setUserCallbacks(
      callbacks.filter((c: string) => !c.startsWith('commandbar-') && !c.startsWith('__standalone-editor')),
    );

    const context = yield Sender.shareContext();
    setContext(context);

    const pCommands = yield Sender.shareProgrammaticCommands();
    setProgrammaticCommands(pCommands);

    /* HACK: The type returned by `yield` is `any` so we cast it here.
     *       Perhaps this will be fixed in a future TypeScript release, see
     *       https://github.com/microsoft/TypeScript/issues/43632 and
     *       https://github.com/microsoft/TypeScript/issues/32523 */
    const components = (yield Sender.shareComponentNamesByKey()) as Record<string, string>;
    Sender.shareComponentNamesByKey();

    const c = Object.entries(components).map(([key, name]) => ({ key, name }));
    setComponents([...c].sort((a, b) => a.name.localeCompare(b.name)));

    const { trackedEvents } = yield Sender.shareTrackedEvents();
    setTrackedEvents(trackedEvents);
  });

  const reloadRouter = () => {
    if (isStudio) {
      /** Standalone editor mocks the router, so we need to guess if the customer
       * has a router setup. Best guess is looking at other commands.
       */
      setHasRouter(commands.some((c) => c.is_live && c.template.type === 'link' && c.template.operation === 'router'));
    } else {
      setHasRouter(callbacks.includes('commandbar-router'));
    }
  };

  React.useEffect(() => {
    reloadRouter();
  }, [...dependencies, isStudio, commands, callbacks]);

  React.useEffect(() => {
    reload();
  }, dependencies);

  return {
    allCallbacks: callbacks,
    userCallbacks: userCallbacks,
    components,
    context: context ?? {},
    trackedEvents,
    programmaticCommands,
    hasRouter,
    setContext,
    reload,
  };
};

export default useWindowInfo;
