/** @jsx jsx */

import { ITheme } from '@commandbar/internal/client/theme';
import { getChromaColor } from '@commandbar/internal/util/chroma';
import { css, jsx, keyframes } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { CSSProperties } from 'react';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIndicator = (props: { isLoading: boolean; size: number; style?: CSSProperties }) => {
  const { theme }: { theme: ITheme } = useTheme();
  if (!props.isLoading) {
    return null;
  } else {
    return (
      <div
        data-testid="commandbar-loader"
        className="commandbar-loader"
        key="commandbar-loader"
        css={css`
          position: relative;
          border-width: 2px;
          border-style: solid;
          border-color: ${getChromaColor(theme.base.fontColor).alpha(0.2).css()};
          border-top-color: ${theme.base.fontColor};
          height: ${props.size - 2}px;
          width: ${props.size - 2}px;
          content: '';
          box-sizing: border-box;
          border-radius: 50%;
          animation: ${rotate} 0.6s linear infinite;
        `}
        style={{ ...props.style }}
      />
    );
  }
};

export default LoadingIndicator;
