import React, { ReactNode, useEffect, useState } from 'react';
import Z from '@commandbar/internal/client/Z';
import { BannerIds } from './constants';
import { isStudioPreview } from '@commandbar/internal/util/location';

type BannerState = {
  element: ReactNode;
  sticky: boolean;
  position: 'top' | 'bottom';
};

enum BannerAction {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  HIDE_ALL_MATCHING_PATTERN = 'HIDE_ALL_MATCHING_PATTERN',
}

type BannerContext = {
  state: Record<string, BannerState>;
  subscribers: Array<React.Dispatch<React.SetStateAction<Record<string, BannerState>>>>;
  subscribe: (notifySubscriber: React.Dispatch<React.SetStateAction<Record<string, BannerState>>>) => () => void;
};

const bannerContext: BannerContext = {
  state: {},
  subscribers: [],

  subscribe: (notifySubscriber) => {
    bannerContext.subscribers.push(notifySubscriber);
    return () => {
      bannerContext.subscribers = bannerContext.subscribers.filter((s) => s !== notifySubscriber);
    };
  },
};

type BannerStateReducerAction =
  | { type: BannerAction.SHOW; id: string; payload: BannerState }
  | { type: BannerAction.HIDE; id: string }
  | {
      type: BannerAction.HIDE_ALL_MATCHING_PATTERN;
      idPattern?: RegExp;
    };

const bannerStateReducer = (
  state: BannerContext['state'],
  action: BannerStateReducerAction,
): BannerContext['state'] => {
  switch (action.type) {
    case BannerAction.SHOW:
      return {
        ...state,
        [action.id]: action.payload,
      };
    case BannerAction.HIDE:
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    case BannerAction.HIDE_ALL_MATCHING_PATTERN:
      if (action.idPattern) {
        const newState = { ...state };
        for (const bannerId in newState) {
          if (bannerId.match(action.idPattern)) {
            delete newState[bannerId];
          }
        }

        return newState;
      }

      return {};
    default:
      return state;
  }
};

const bannerDispatch = (action: BannerStateReducerAction) => {
  const newState = bannerStateReducer(bannerContext.state, action);
  bannerContext.state = newState;
  bannerContext.subscribers.forEach((notifySubscriber) => notifySubscriber(newState));
};

export const showBanner = (id: string, payload: BannerState) => {
  bannerDispatch({ type: BannerAction.SHOW, id, payload });
};

export const hideAllMatchingBanners = (idPattern: RegExp) => {
  bannerDispatch({ type: BannerAction.HIDE_ALL_MATCHING_PATTERN, idPattern });
};

export const hideBanner = (id: string) => {
  const spacerElement = document.querySelector(`[data-spacer-for="${id}"]`);

  if (spacerElement) {
    spacerElement.remove();
  }

  bannerDispatch({ type: BannerAction.HIDE, id });
};

type BannerContainerProps = {
  children?: React.ReactNode;
};

export const BannerContainer: React.FC<BannerContainerProps> = ({ children }) => {
  const [state, setState] = useState(bannerContext.state);

  useEffect(() => {
    return bannerContext.subscribe(setState);
  }, []);

  return (
    <>
      {children}
      <div
        id={BannerIds.Sticky}
        style={{
          width: '100%',
          top: '0px',
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: Z.Z_NUDGE,
          height: '100%',
        }}
      >
        {Object.entries(state)
          .filter(([, bannerProps]) => bannerProps.sticky)
          .map(([id, bannerProps]) => (
            <div key={id} id={id}>
              {bannerProps.element}
            </div>
          ))}
      </div>
      <div
        id={`${BannerIds.Inline}-top`}
        style={{
          width: '100%',
          position: 'absolute',
          top: isStudioPreview() ? '50%' : '0',
          pointerEvents: 'none',
          zIndex: Z.Z_NUDGE,
        }}
      >
        {Object.entries(state)
          .filter(([, bannerProps]) => !bannerProps.sticky && bannerProps.position === 'top')
          .map(([id, bannerProps]) => (
            <div key={id} id={id}>
              {bannerProps.element}
            </div>
          ))}
      </div>
      <div
        id={`${BannerIds.Inline}-bottom`}
        style={{
          width: '100%',
          position: isStudioPreview() ? undefined : 'relative',
          pointerEvents: 'none',
          zIndex: Z.Z_NUDGE,
        }}
      >
        {Object.entries(state)
          .filter(([, bannerProps]) => !bannerProps.sticky && bannerProps.position === 'bottom')
          .map(([id, bannerProps]) => (
            <div key={id} id={id}>
              {bannerProps.element}
            </div>
          ))}
      </div>
    </>
  );
};
