import React from 'react';
import styled from '@emotion/styled';

import { Panel, Alert, Row, FormRow } from '@commandbar/design-system/components/antd';
import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';

import { useAppContext } from 'editor/src/AppStateContext';
import { useIsEditorOpen } from '../../../hooks';
import Sender from '../../../management/Sender';

import IconUpload from '../../components/IconUpload';
import ToggleOrganizationSetting from '../../components/ToggleOrganizationSetting';

import BarLauncherSettings from './BarLauncherSettings';
import PlaceholderList from './PlaceholderList';
import FallbackCommandsSettings from './FallbackCommands';
import SearchSettings from './SearchSettings';
import AirgapControls from './AirgapControls';
import TextCustomizationSettings from './TextCustomizationSettings';
import ThemeCustomizationSettings from '../../components/ThemeCustomizationSettings';

export enum PanelKey {
  LAUNCHER = 'launcher',
  PLACEHOLDERS = 'placeholders',
  FALLBACK_COMMANDS = 'fallback-commands',
  MISC = 'misc',
  SEARCH = 'search',
  END_USER = 'end-user',
  AIRGAP = 'airgap',
  STRINGS = 'strings',
  THEME = 'theme',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SpotlightSettings = () => {
  const {
    organization,
    dispatch: {
      organization: { updateSetting, update },
    },
    isStudio,
    commandBarReady,
    flags,
  } = useAppContext();
  const isEditorOpen = useIsEditorOpen();
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([]);

  const onUpload = (field: keyof IOrganizationType) => {
    return async (fileString: string) => {
      await updateOrganizationProperty(field, fileString);
    };
  };

  const onRemove = (field: keyof IOrganizationType) => {
    return async () => {
      await updateOrganizationProperty(field, '');
    };
  };

  const updateOrganizationProperty = async (
    field: keyof IOrganizationType,
    newVal: IOrganizationType[keyof IOrganizationType],
  ) => {
    const _organization = await update({ ...organization, [field]: newVal });
    return _organization;
  };

  React.useEffect(() => {
    if (commandBarReady && (isStudio || isEditorOpen)) {
      Sender.openBar();
    }
  }, [isEditorOpen, commandBarReady]);

  const isAirGapEnabled = () => {
    const ALWAYS_TREAT_AS_AIRGAPPED = ['44967ad4', '01836695', 'foocorp'];
    if (ALWAYS_TREAT_AS_AIRGAPPED.includes(organization.id.toString())) return true;
    return Sender.getConfiguration()
      .then(({ data: sdkConfiguration }) => {
        return !!sdkConfiguration.airgap;
      })
      .catch(() => false);
  };

  return (
    <Container>
      <Panel panelKey={PanelKey.LAUNCHER} activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Launcher">
        <BarLauncherSettings
          organization={organization}
          updateOrganizationProperty={updateOrganizationProperty}
          updateOrganizationSettingsProperty={(field: keyof IOrganizationSettingsType, newVal) =>
            updateSetting({ [field]: newVal })
          }
        />
      </Panel>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        header="Placeholder Text"
        panelKey={PanelKey.PLACEHOLDERS}
      >
        <PlaceholderList organization={organization} />
      </Panel>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        header="Fallbacks"
        panelKey={PanelKey.FALLBACK_COMMANDS}
      >
        <Alert
          style={{ marginBottom: 20 }}
          message="Using fallback pages or actions"
          description={
            <div>
              <p>
                Fallback pages or actions show up when a user types in text and doesn't see any matches. You can use it
                to nudge users to common actions or support.
              </p>
            </div>
          }
          type="info"
          showIcon
        />
        <FallbackCommandsSettings
          organization={organization}
          updateOrganizationSettingsProperty={(field, newVal) => updateSetting({ [field]: newVal })}
        />
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Misc" panelKey={PanelKey.MISC}>
        {flags['release-themes-v2'] && (
          <Row style={{ marginTop: '22px' }} justify="center">
            <IconUpload
              defaultIcon={organization.logo_spotlight ? organization.logo_spotlight : null}
              onUpload={onUpload('logo_spotlight')}
              onRemove={onRemove('logo_spotlight')}
              title={'Spotlight Logo'}
              value="logo_spotlight"
              svgOnly
            />
          </Row>
        )}
        <Row style={{ marginTop: '22px' }} justify="center">
          <IconUpload
            defaultIcon={organization.icon_go_forward === '' ? null : organization.icon_go_forward}
            onUpload={onUpload('icon_go_forward')}
            onRemove={onRemove('icon_go_forward')}
            title={`"Go Forward" Icon (default: arrow)`}
            value="icon_go_forward"
            svgOnly
          />
        </Row>
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Search" panelKey={PanelKey.SEARCH}>
        <SearchSettings organization={organization} updateOrganizationProperty={updateOrganizationProperty} />
      </Panel>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        header={<span>End-user preferences</span>}
        panelKey={PanelKey.END_USER}
      >
        <FormRow
          title="Custom shortcuts"
          input={
            <ToggleOrganizationSetting
              organization={organization}
              updateOrganizationSettingsProperty={(field, newVal) => updateSetting({ [field]: newVal })}
              setting="end_user_shortcuts_enabled"
              style={{ marginLeft: 15 }}
            />
          }
        />
        <FormRow
          title="Recents"
          info={`Enable this setting to show an end-user's recently used commands and records in a separate category on top. Once enabled, you can toggle this behavior for each command category and each record.`}
          input={
            <ToggleOrganizationSetting
              organization={organization}
              updateOrganizationSettingsProperty={(field, newVal) => updateSetting({ [field]: newVal })}
              setting="end_user_recents_enabled"
              style={{ marginLeft: 15 }}
            />
          }
        />
      </Panel>
      {isAirGapEnabled() && (
        <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Airgap mode" panelKey={PanelKey.AIRGAP}>
          <AirgapControls organization={organization} />
        </Panel>
      )}
      {flags['release-themes-v2'] && (
        <Panel
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          panelKey={PanelKey.STRINGS}
          header="Text customization"
        >
          <TextCustomizationSettings />
        </Panel>
      )}
      {flags['release-themes-v2'] && (
        <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.THEME} header="Theme">
          <ThemeCustomizationSettings
            themeUUID={organization.spotlight_custom_theme}
            setThemeUUID={(themeUUID) => {
              updateSetting({ spotlight_custom_theme: themeUUID });
            }}
          />
        </Panel>
      )}
    </Container>
  );
};

export default SpotlightSettings;
