import React, { type CSSProperties, type MouseEventHandler, forwardRef } from 'react';
import type { ActorRefFrom } from 'xstate';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { DraftFooter } from '@commandbar/commandbar/shared/components/admin-facing/DraftFooter';
import { useAction } from '@commandbar/commandbar/shared/util/hooks/useAction';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledCloseButtonContainer from '@commandbar/internal/client/themesV2/components/nudge/StyledCloseButtonContainer';
import StyledNudgeCloseButton from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeCloseButton';
import StyledNudgePopover from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgePopover';
import StyledNudgeStepCounter from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeStepCounter';
import StyledNudgeTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';
import type { INudgeModalStepType, INudgePopoverStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { dismissNudge } from '../../store/actions';
import type NudgeMachine from '../../store/nudgeMachine';
import { getNudgeService, isNudgeDismissible, isNudgeSnoozable } from '../../store/selectors';
import { Actions } from '../Actions';
import Media from '../ContentContainer';
import { LayoutTemplate } from '../LayoutTemplate';
import { RenderMode } from '../RenderNudge';
import { getFirstMarkdownBlock } from '../utils';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

interface LayoutProps {
  nudge: INudgeType;
  step: INudgePopoverStepType | INudgeModalStepType;
  renderMode: RenderMode;
  handleLinkClick: MouseEventHandler<HTMLElement>;
  stepIndex: number;
  styles: Record<string, CSSProperties>;
  className: string;
  service?: ActorRefFrom<typeof NudgeMachine>;
  stepCount?: string;
  editorAdjustmentStyle?: React.CSSProperties;
  center?: boolean;
}

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  (
    {
      nudge,
      step,
      renderMode,
      handleLinkClick,
      stepIndex,
      stepCount,
      styles,
      className,
      editorAdjustmentStyle,
      center,
    },
    ref,
  ) => {
    const _ = useStore();
    const themeV2 = useThemeV2Context();
    const nudgeService = renderMode !== RenderMode.MOCK ? getNudgeService(_, nudge.id) : undefined;
    const { onExit, animStyles } = useAnimatedWidget();

    const dismissCallback = useAction(dismissNudge);
    const dismiss = (nudge: INudgeType, renderMode: RenderMode) => {
      onExit(() => {
        dismissCallback(nudge, renderMode);
      });
    };

    const { isMobileDevice, mobileStyles } = useMobileExperience();

    const close = isNudgeDismissible(nudge) ? (
      <StyledCloseButtonContainer>
        <StyledNudgeCloseButton themeV2={themeV2} onClick={() => dismiss(nudge, renderMode)} />
      </StyledCloseButtonContainer>
    ) : null;

    const header = (
      <StyledNudgeTitleBlock
        title={step.title}
        content={getFirstMarkdownBlock(step.content)}
        dismissible={isNudgeDismissible(nudge)}
        handleContentLinkClick={handleLinkClick}
        draggable
      />
    );

    const media = (
      <Media
        step={step}
        stepIndex={stepIndex}
        service={nudgeService}
        markdownStyles={styles.content}
        primaryButtonStyles={styles.ctaButton}
        secondaryButtonStyles={styles.ctaSecondaryButton}
        snoozeButtonStyles={styles.snoozeButton}
        stepCountStyles={styles.stepCount}
        stepCount={stepCount}
        renderMode={renderMode}
        snoozable={isNudgeSnoozable(nudge)}
        snoozeLabel={nudge.snooze_label}
        snoozable_on_all_steps={nudge.snoozable_on_all_steps}
        handleContentLinkClick={handleLinkClick}
      />
    );

    const stepCounter = stepCount ? <StyledNudgeStepCounter stepCount={stepCount} /> : null;

    const actions = (
      <Actions
        step={step}
        stepIndex={stepIndex}
        renderMode={renderMode}
        snooze={{
          enabled: isNudgeSnoozable(nudge),
          label: nudge.snooze_label,
          enabledOnAllSteps: nudge.snoozable_on_all_steps,
          duration: nudge.snooze_duration,
        }}
        service={nudgeService}
        stepCount={stepCount}
      />
    );

    const footer =
      !nudge.is_live && renderMode === RenderMode.MOCK ? (
        <DraftFooter details={{ type: 'nudge', nudge, stepIndex }} />
      ) : null;

    const defaultContainerStyles = (): CSSProperties => {
      const width = (() => {
        if (step.form_factor.layout === 'horizontal') {
          return 'auto';
        }

        if (step.form_factor.type === 'modal') {
          return 'var(--modal-width)';
        }

        return undefined;
      })();

      return {
        ...(center && {
          top: '50vh',
          transform: 'translate(0, -50%)',
        }),
        ...editorAdjustmentStyle,
        width,
        maxWidth: step.form_factor.layout === 'horizontal' ? '100%' : undefined,
      };
    };

    return (
      <StyledNudgePopover
        organization={_.organization}
        survey={!!step.content.find((block) => block.type === 'survey_rating')}
        data-testid={`commandbar-popover-${nudge.id}-${String(step.id)}${
          renderMode === RenderMode.MOCK ? '-mock' : ''
        }`}
        key={step.id}
        ref={ref}
        className={className}
        style={{
          ...(isMobileDevice ? mobileStyles.nudges.popover.container : defaultContainerStyles()),
          ...animStyles,
        }}
      >
        <LayoutTemplate
          layout={isMobileDevice ? 'classic' : step.form_factor.layout}
          headerAndMarkdown={header}
          close={close}
          media={media}
          footer={footer}
          actions={actions}
          stepCounter={stepCounter}
          columnWidth={step.form_factor.type === 'modal' ? 'var(--modal-width)' : 'var(--popover-width)'}
        />
      </StyledNudgePopover>
    );
  },
);
