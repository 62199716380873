import React, { useState } from 'react';
import { Copy07, Edit03, Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { IAPI } from '@commandbar/internal/middleware/types';

import {
  FeatureAnnouncementCard,
  Table,
  SimplePanel,
  Row,
  Col,
  DropdownMenu,
  StatusBadge,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdButton, CmdSearchInput, CmdTag, cmdToast } from '@commandbar/design-system/cmd';
import { useHistory, useParams } from 'react-router';
import { COPILOT_APIS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import APIDetail from './APIDetail';
import { PaddingContainer } from '../Router';
import FilterSelect, { StatusType } from '../components/FilterSelect';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const APIs: React.FC = () => {
  const { APIs, dispatch } = useAppContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const searchParams = new URLSearchParams(history.location.search);

  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') ?? '1'));
  const [activeAPI, setActiveAPI] = useState<IAPI | undefined>(undefined);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');

  const updateActiveAPI = (api: IAPI | undefined) => {
    setActiveAPI(api);

    if (!!api) {
      const path = `${COPILOT_APIS_ROUTE}/${api.id}${history.location.search}`;
      if (history.location.pathname !== path) {
        history.push(path);
      }
    } else {
      history.replace(COPILOT_APIS_ROUTE + history.location.search);
    }
  };

  React.useEffect(() => {
    const id = Number(params.id);
    if (!id) {
      updateActiveAPI(undefined);
      return;
    } else {
      const API = APIs?.find((c) => c.id === id);
      if (API) {
        updateActiveAPI(API);
      }
    }
  }, [params.id, APIs, activeAPI]);

  const addNewAPI = async () => {
    const emptyAPI: IAPI = {
      id: -1,
      archived: false,
      is_live: false,
      title: 'New API',
      description: '',
      method: 'GET',
      url: '',
      headers: '',
      body: '',
      require_confirmation: false,
      confirm_cta: 'Confirm',
      cancel_cta: 'Cancel',
      parameters: [],
      workflows_only: false,
    };
    updateActiveAPI(emptyAPI);
  };

  const CreateButton = ({ addNew }: { addNew: () => void }) => {
    const [loading, setLoading] = useState(false);
    return (
      <CmdButton
        onClick={async () => {
          if (loading) return;
          setLoading(true);
          try {
            await addNew();
          } catch {
            cmdToast.error('Error creating new API');
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        icon={<Plus />}
        variant={'primary'}
      >
        New
      </CmdButton>
    );
  };

  const columns = [
    {
      title: 'API',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, API: IAPI) => {
        return API.title.length > 28 ? `${API.title.slice(0, 28)}...` : API.title;
      },
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, api: IAPI) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {api.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Published" />
          ) : (
            <StatusBadge color="orange" text="Unpublished" />
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, api: IAPI) => {
        const canEdit = hasRequiredRole(user, api.is_live ? 'editor' : 'contributor');

        const dropDownItems = [];

        if (canEdit) {
          dropDownItems.push({
            name: 'Edit',
            icon: <Edit03 />,
            onClick: () => updateActiveAPI(api),
          });
        }

        if (hasRequiredRole(user, 'contributor')) {
          dropDownItems.push({
            name: 'Duplicate',
            icon: <Copy07 />,
            onClick: () => {
              const newAPI = {
                ...api,
                id: -1,
                is_live: false,
              };
              updateActiveAPI(newAPI);
            },
          });

          if (canEdit) {
            dropDownItems.push({
              name: 'Delete',
              icon: <Trash04 />,
              onClick: () => dispatch.apis.delete(api.id),
            });
          }
        }

        return <DropdownMenu keyName="checklist-actions" items={dropDownItems} />;
      },
    },
  ];

  if (activeAPI) {
    return (
      <APIDetail
        key={activeAPI.id}
        initialAPI={activeAPI}
        onClose={() => {
          const createdNewAPI = activeAPI.id > -1;
          createdNewAPI || history.length <= 1
            ? history.replace(COPILOT_APIS_ROUTE + history.location.search)
            : history.goBack();
        }}
        onDelete={async (API: IAPI) => {
          await dispatch.apis.delete(API.id);
          history.goBack();
        }}
        onSave={async (API: IAPI) => {
          dispatch.apis.save(API).then((newAPI) => {
            setActiveAPI(newAPI);
          });
        }}
      />
    );
  } else {
    return (
      <PaddingContainer style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%', marginTop: -8 }}>
        <FeatureAnnouncementCard
          identifier="introducing_apis"
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              Introducing APIs<CmdTag variant="release">Beta</CmdTag>
            </div>
          }
          docsLink="https://www.commandbar.com/docs/copilot/personalization/apis/"
        >
          <span>APIs allow Copilot to take actions, get dynamic data, and more. APIs are currently in beta.</span>
        </FeatureAnnouncementCard>

        <SimplePanel>
          <Row style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}>
            <Col style={{ flex: 1 }}>
              <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </Col>
            <Col>
              <FilterSelect
                selectedOption={selectedStatus}
                setSelectedOption={setSelectedStatus}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Published', value: 'published' },
                  { label: 'Unpublished', value: 'unpublished' },
                ]}
              />
            </Col>
            {hasRequiredRole(user, 'contributor') && (
              <Col>
                <CreateButton addNew={addNewAPI} />
              </Col>
            )}
          </Row>

          <Table
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              current: currentPage,
              onChange: (page) => {
                history.replace(`${history.location.pathname}?page=${page}`);
                setCurrentPage(page);
              },
            }}
            rowClassName="editable-row"
            columns={columns}
            dataSource={APIs.filter((API) => API.title.toLowerCase().includes(searchText.toLowerCase())).filter(
              (API) => {
                if (selectedStatus === 'all') {
                  return true;
                } else if (selectedStatus === 'published') {
                  return API.is_live;
                } else {
                  return !API.is_live;
                }
              },
            )}
            onRow={(API: IAPI) => {
              return {
                onClick: (_e: React.MouseEvent) => {
                  updateActiveAPI(API);
                },
              };
            }}
          />
        </SimplePanel>
      </PaddingContainer>
    );
  }
};

export default APIs;
