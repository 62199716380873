import { CmdButton, CmdEmpty, CmdSheet, CmdTypography } from '@commandbar/design-system/cmd';
import { ArrowNarrowLeft, ArrowNarrowRight, X } from '@commandbar/design-system/icons/react';
import FilterDropdown, { useFilterDropdown } from './FilterDropdown';
import { useEffect, useState } from 'react';
import SurveyResponses from './SurveyResponses';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import {
  SurveyDetailsWithResponses,
  SurveyDetailsWithResponsesNudgeStep,
} from '@commandbar/internal/middleware/analytics/surveys';
import useSurveyDetails from './useSurveyDetails';

const NudgeDetailPanelTrigger = ({
  nudgeId,
  triggerDisplayValue,
  initilActiveIndex = 0,
  timeFilterRange,
  surveyDetails,
}: {
  nudgeId: string;
  triggerDisplayValue: string;
  initilActiveIndex?: number;
  timeFilterRange: DateFilter;
  surveyDetails?: SurveyDetailsWithResponses;
}) => {
  return (
    <div style={{ width: '100%' }}>
      <CmdSheet>
        <CmdSheet.Trigger>
          <CmdTypography.Body>{triggerDisplayValue}</CmdTypography.Body>
        </CmdSheet.Trigger>
        <CmdSheet.Content closeable={true} style={{ width: '560px', padding: 0, background: '#F9F9F9' }}>
          <NudgeDetailPanelContent
            nudgeId={nudgeId}
            initilActiveIndex={initilActiveIndex}
            timeFilterRange={timeFilterRange}
            triggerDisplayValue={triggerDisplayValue}
            initialSurveyDetails={surveyDetails}
          />
        </CmdSheet.Content>
      </CmdSheet>
    </div>
  );
};

const NudgeDetailPanelContent = ({
  nudgeId,
  initilActiveIndex = 0,
  timeFilterRange,
  triggerDisplayValue,
  initialSurveyDetails,
}: {
  nudgeId: string;
  initilActiveIndex?: number;
  timeFilterRange: DateFilter;
  triggerDisplayValue: string;
  initialSurveyDetails?: SurveyDetailsWithResponses;
}) => {
  const { filterValue, handleFilterChange } = useFilterDropdown();
  const [activeIndex, setActiveIndex] = useState<number>(initilActiveIndex);
  const [activeStep, setActiveStep] = useState<SurveyDetailsWithResponsesNudgeStep | undefined>(
    initialSurveyDetails?.nudge_steps[activeIndex],
  );

  const { surveyDetails, isLoading } = useSurveyDetails({
    nudgeId,
    listFilter: filterValue.value,
    timeFilterRange,
  });

  useEffect(() => {
    setActiveStep(surveyDetails?.nudge_steps[activeIndex]);
  }, [activeIndex, surveyDetails]);

  return (
    <>
      <CmdSheet.Header
        style={{
          display: 'flex',
          padding: '16px',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
          marginBottom: '2px',
          minHeight: '120px',
          flexDirection: 'column',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <CmdSheet.Close asChild>
            <CmdButton variant="link" icon={<X />} />
          </CmdSheet.Close>

          <div style={{ display: 'flex' }}>
            <CmdButton
              variant="link"
              icon={<ArrowNarrowLeft />}
              disabled={activeIndex === 0}
              onClick={() => setActiveIndex((activeIndex) => activeIndex - 1)}
            />
            <CmdButton
              variant="link"
              icon={<ArrowNarrowRight />}
              disabled={activeIndex + 1 === surveyDetails?.nudge_steps?.length}
              onClick={() => setActiveIndex((activeIndex) => activeIndex + 1)}
            />
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <CmdTypography.HelpText variant="secondary">Step {activeIndex + 1}</CmdTypography.HelpText>
          <CmdTypography.H3>{activeStep?.title || triggerDisplayValue}</CmdTypography.H3>
          <CmdTypography.Body>{activeStep?.body}</CmdTypography.Body>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CmdTypography.Body variant="secondary">{activeStep?.responses.length || 0} responses</CmdTypography.Body>
            <FilterDropdown handleFilterChange={handleFilterChange} filterValue={filterValue} />
          </div>
        </div>
      </CmdSheet.Header>

      <CmdSheet.Body style={{ paddingTop: '8px' }}>
        {isLoading ? (
          Array.from({ length: 6 }).map((_, index) => <CmdEmpty key={index} height={80} />)
        ) : !!activeStep?.responses ? (
          <SurveyResponses stepDetails={activeStep?.survey_details} responses={activeStep?.responses} />
        ) : (
          <CmdTypography.Body variant="secondary">No survey results</CmdTypography.Body>
        )}
      </CmdSheet.Body>
    </>
  );
};

export default NudgeDetailPanelTrigger;
