import React from 'react';
import styled from '@emotion/styled';
import { INudgeType } from '@commandbar/internal/middleware/types';
import NudgeDatePicker from './NudgeDatePicker';
import { Circle, Clock, Contrast01 } from '@commandbar/design-system/icons/react';

import { isInScheduledPeriod, isPastScheduledPeriod } from '@commandbar/internal/util/time';
import {
  OverlaySettingsSection,
  OverlaySettingsContainer,
  OverlayDisplayValue,
  OverlaySettingsOption,
} from '../components/styled';

import { Dropdown, CB_COLORS } from '@commandbar/design-system/components/antd';
import { DropdownDisplay, IconContainer } from './styled';
import { CaretDown } from '../helphub/shared';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CmdSwitch } from '@commandbar/design-system/cmd';
dayjs.extend(utc);
dayjs.extend(timezone);

const ScheduleDropdown = styled(Dropdown)`
  border-radius: 4px;
  border: 1px solid rgba(10, 10, 15, 0.24);
  background: #fff;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.07) inset, 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  width: 155px;
  height: 32px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
`;
const OverlaySettingsOptionStyled = styled(OverlaySettingsOption)<{ selected: boolean }>`
  background: ${(props) => (props.selected ? CB_COLORS.neutral0 : 'auto')};
  border-radius: ${(props) => (props.selected ? '4px 4px 0 0' : '0')};
`;

const TimezoneSubtext = styled.span`
  color: ${CB_COLORS.neutral600};
  font-size: 12px;
  font-weight: 400;
`;

const NudgeScheduleDropdown = ({
  dirty,
  setDirty,
  disabled,
}: {
  dirty: INudgeType;
  setDirty: (dirty: INudgeType) => void;
  disabled?: boolean;
}) => {
  const [isScheduleDropdownVisble, setIsScheduleDropdownVisible] = React.useState(false);
  const [isSchedulingStartDate, setIsSchedulingStartDate] = React.useState(dirty.scheduled_start_time !== null);
  const [isSchedulingEndDate, setIsSchedulingEndDate] = React.useState(dirty.scheduled_end_time !== null);
  const dirtyTZ = dayjs.tz.guess() ? dayjs.tz.guess().split('/').pop() : '';
  const localTZ = dirtyTZ?.replace('_', ' ');
  const isLive = dirty.is_live || isInScheduledPeriod(dirty);

  const onToggleScheduled = () => {
    const newDirty = {
      ...dirty,
      is_scheduled: !dirty.is_scheduled,
      scheduled_start_time: dirty.scheduled_start_time ?? null,
      scheduled_end_time: dirty.scheduled_end_time ?? null,
      is_live: false,
    };
    setDirty(newDirty);
  };

  return (
    <ScheduleDropdown
      disabled={disabled}
      trigger={['click']}
      onVisibleChange={(isVisible) => {
        setIsScheduleDropdownVisible(isVisible);
        if (!isVisible) {
          if (dirty.is_scheduled) {
            if (!dirty.scheduled_start_time) {
              setIsSchedulingStartDate(false);
            }
            if (!dirty.scheduled_end_time) {
              setIsSchedulingEndDate(false);
            }
          }
        }
      }}
      visible={isScheduleDropdownVisble}
      overlay={
        <OverlaySettingsContainer width={'320px'}>
          <OverlaySettingsOption
            onClick={() => {
              setDirty({ ...dirty, is_live: true, is_scheduled: false });
              setIsScheduleDropdownVisible(false);
            }}
          >
            <IconContainer>
              <Circle width={16} />
            </IconContainer>
            Published
          </OverlaySettingsOption>

          <OverlaySettingsOption
            onClick={() => {
              setDirty({ ...dirty, is_live: false, is_scheduled: false });
              setIsScheduleDropdownVisible(false);
            }}
          >
            <IconContainer>
              <Contrast01 width={16} />
            </IconContainer>
            Unpublished
          </OverlaySettingsOption>

          <OverlaySettingsOptionStyled
            onClick={(e) => {
              e.stopPropagation();
              onToggleScheduled();
            }}
            selected={dirty.is_scheduled}
          >
            <IconContainer>
              <Clock width={16} />
            </IconContainer>
            Scheduled...
          </OverlaySettingsOptionStyled>

          {dirty.is_scheduled && (
            <OverlaySettingsSection
              style={{
                background: `${CB_COLORS.neutral0}`,
                fontWeight: '500',
                flexDirection: 'column',
                gap: '12px',
                borderRadius: '0 0 4px 4px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>Start Date</div>
                  <CmdSwitch
                    checked={isSchedulingStartDate}
                    onCheckedChange={() => {
                      if (isSchedulingStartDate) {
                        setDirty({ ...dirty, scheduled_start_time: null });
                      }
                      setIsSchedulingStartDate((sched) => !sched);
                    }}
                  />
                </div>
                {isSchedulingStartDate && (
                  <NudgeDatePicker
                    updateNudge={(date: string | null) => setDirty({ ...dirty, scheduled_start_time: date })}
                    defaultValue={dirty.scheduled_start_time !== null ? dayjs(dirty.scheduled_start_time) : undefined}
                  />
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>End Date</div>
                  <CmdSwitch
                    checked={isSchedulingEndDate}
                    onCheckedChange={() => {
                      if (isSchedulingEndDate) {
                        setDirty({ ...dirty, scheduled_end_time: null });
                      }
                      setIsSchedulingEndDate((sched) => !sched);
                    }}
                  />
                </div>
                {isSchedulingEndDate && (
                  <NudgeDatePicker
                    updateNudge={(date: string | null) => setDirty({ ...dirty, scheduled_end_time: date })}
                    defaultValue={dirty.scheduled_end_time !== null ? dayjs(dirty.scheduled_end_time) : undefined}
                  />
                )}
              </div>

              <TimezoneSubtext>Scheduled in your current timezone{localTZ && ` (${localTZ})`}</TimezoneSubtext>
            </OverlaySettingsSection>
          )}
        </OverlaySettingsContainer>
      }
    >
      <OverlayDisplayValue>
        {isLive ? (
          <DropdownDisplay>
            <Circle width={16} style={{ color: '#0FB115' }} />
            Published
          </DropdownDisplay>
        ) : dirty.is_scheduled && !isPastScheduledPeriod(dirty) ? (
          <DropdownDisplay>
            <Clock width={16} style={{ color: '#0586FD' }} />
            Scheduled
          </DropdownDisplay>
        ) : (
          <DropdownDisplay>
            <Contrast01 width={16} style={{ color: '#FFA922' }} />
            Unpublished
          </DropdownDisplay>
        )}
        <IconContainer style={{ marginRight: 0 }}>
          <CaretDown />
        </IconContainer>
      </OverlayDisplayValue>
    </ScheduleDropdown>
  );
};

export default NudgeScheduleDropdown;
