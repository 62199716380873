import React from 'react';
import { StyledCollapse, StyledHeader, StyledPanel, StyledPanelChildren, StyledSubPanel } from './formStyles';
import { CB_COLORS } from './colors';

const CaretUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" fill="none" viewBox="0 0 8 4">
      <path
        fill={CB_COLORS.neutral600}
        d="M7.28 3.67 3.86.077a.28.28 0 0 0-.386 0L.054 3.67C-.075 3.804.04 4 .245 4h6.842c.205 0 .32-.196.192-.33Z"
      />
    </svg>
  );
};

const CaretDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" fill="none" viewBox="0 0 6 4">
      <path
        fill={CB_COLORS.neutral600}
        d="M.044.33 2.798 3.593a.2.2 0 0 0 .316 0L5.956.33C6.06.196 5.966 0 5.8 0H.2C.033 0-.06.196.044.33Z"
      />
    </svg>
  );
};

const PanelHeader = ({
  isActive,
  children,
  actions,
  sidepanel,
}: {
  isActive: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  sidepanel?: boolean;
}) => {
  return (
    <StyledHeader sidepanel={sidepanel}>
      {children}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          gap: '8px',
          height: '20px',
        }}
      >
        <div>{isActive ? <CaretUp /> : <CaretDown />}</div>
        <div
          onClick={(e) => e.stopPropagation() /* we don't want clicks on actions to close or open the panel */}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {actions}
        </div>
      </div>
    </StyledHeader>
  );
};

const Panel = <PanelKey extends string | number>({
  activeKeys,
  setActiveKeys,
  panelKey,
  header,
  subpanel = false,
  children,
  actions,
  accordion = false,
  sidepanel = false,
}: {
  activeKeys: PanelKey[];
  setActiveKeys: React.Dispatch<(prevState: PanelKey[]) => PanelKey[]>;
  panelKey: PanelKey;
  header: React.ReactNode;
  subpanel?: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  accordion?: boolean;
  sidepanel?: boolean;
}) => {
  const PanelComponent = subpanel ? StyledSubPanel : StyledPanel;
  return (
    <StyledCollapse
      style={{
        border: `${sidepanel ? 'none' : `1px solid ${CB_COLORS.neutral400}}`}`,
        borderRadius: `${sidepanel ? '0px' : `8px`}`,
      }}
      onChange={() => {
        if (accordion) {
          setActiveKeys((ks) => {
            if (ks.includes(panelKey)) {
              return [];
            } else {
              return [panelKey];
            }
          });
        } else {
          setActiveKeys((ks) => {
            if (ks.includes(panelKey)) {
              return ks.filter((k) => k !== panelKey);
            } else {
              return [...ks, panelKey];
            }
          });
        }
      }}
      activeKey={activeKeys}
      expandIcon={() => null}
      accordion={accordion}
      sidepanel={sidepanel}
    >
      <PanelComponent
        key={panelKey}
        header={
          <PanelHeader isActive={activeKeys.includes(panelKey)} actions={actions} sidepanel={sidepanel}>
            {header}
          </PanelHeader>
        }
      >
        <StyledPanelChildren sidepanel={sidepanel}>{children}</StyledPanelChildren>
      </PanelComponent>
    </StyledCollapse>
  );
};

export default Panel;
