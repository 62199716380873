import { useEffect, useState } from 'react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import * as Sentry from '@sentry/react';
import { AnalyticsChecklists, AnalyticsChecklistsData } from '@commandbar/internal/middleware/analytics/checklists';
import { useCSVDownload } from '../../useCSVDownload';
import { getChecklistDemoData } from '../../analyticDemoData';

const useAnalyticChecklist = ({ showUnpublished }: { showUnpublished: boolean }) => {
  const [data, setData] = useState<AnalyticsChecklistsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { isDemoData, timeFilterRange, includeAdminData } = useAnalyticsContext();

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'checklists',
    download: () =>
      AnalyticsChecklists.download({
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: String(!!showUnpublished),
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchChecklistData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsChecklists.read({
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      show_unpublished: showUnpublished.toString(),
      include_admin_data: includeAdminData.toString(),
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      setData(getChecklistDemoData());
      setIsLoading(false);
    } else {
      fetchChecklistData();
    }
  }, [timeFilterRange, showUnpublished, isDemoData, includeAdminData]);

  return {
    data,
    isLoading,
    error,
    fetchChecklistData,
    downloadCsv,
    isDownloading,
  };
};

export default useAnalyticChecklist;
