import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { IHelpDocsSyncType } from '@commandbar/internal/middleware/types';
import { Row, StatusBadge } from '@commandbar/design-system/components/antd';
import { HelpDocsSync } from '@commandbar/internal/middleware/helpDocsSync';
import FilterSelect, { StatusType } from 'editor/src/editor/components/FilterSelect';

import type { Source } from '../../integrations/shared/types';
import { ColumnTitle, StyledTable } from '../shared/styles';
import { Check, ClockRefresh } from '@commandbar/design-system/icons/react';
import Footer from 'commandbar.com/src/components/table/Footer';
import { useDataCommandsAndPagination } from '../shared/hooks';
import { LastUpdateRenderer } from '../shared/utils';
dayjs.extend(utc);

const columnsSyncs = [
  { title: 'Items Updated', dataIndex: 'count', key: 'count', ellipsis: true },
  {
    title: (
      <ColumnTitle>
        Status
        <Check />
      </ColumnTitle>
    ),
    dataIndex: 'status',
    key: 'status',
    width: '133px',
    render: (status: string) => (
      <span style={{ display: 'flex', alignItems: 'center', minWidth: 45 }}>
        {status === 'complete' ? (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Complete" />
        ) : status === 'running' ? (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="orange" text="Running" />
        ) : status === 'pending' ? (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="blue" text="Pending" />
        ) : (
          <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="red" text="Error" />
        )}
      </span>
    ),
  },
  {
    title: (
      <ColumnTitle>
        Last Sync
        <ClockRefresh />
      </ColumnTitle>
    ),
    dataIndex: 'started',
    key: 'started',
    width: '158px',
    render: (started: string) => LastUpdateRenderer(started),
  },
];

interface IProps {
  viewSelector: JSX.Element;
  source: Source;
  syncsUpdated: number;
}

const SyncHistoryTable = ({ viewSelector, source, syncsUpdated }: IProps) => {
  const [syncs, setSyncs] = useState<IHelpDocsSyncType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');

  const {
    dataCommands: chunkedSyncs,
    pagination,
    setPagination,
  } = useDataCommandsAndPagination({
    commands: syncs as any[], // TODO: fix typing here, useDataCommandsAndPagination currently works with all arrays as long as you don't need search
    searchTerm: '',
    selectedStatus,
  });

  useEffect(() => {
    let canceled = false;
    const pollSyncs = async () => {
      while (!canceled) {
        const results = await HelpDocsSync.list();
        setSyncs(
          // we need to add the is_live field in order to filter by selected status for pagination
          results.filter((sync) => sync.integration === source.id).map((sync) => ({ ...sync, is_live: sync.ended })),
        );
        await new Promise((resolve) => setTimeout(resolve, 300));
        setLoading(false);
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    };

    pollSyncs();

    return () => {
      canceled = true;
    };
  }, [syncsUpdated]);

  const getNextRunTime = (schedule: string) => {
    let dt = dayjs().utc();

    // Find the next 8AM UTC
    if (dt.hour() >= 8) {
      dt = dt.add(1, 'day');
    }
    dt = dt.hour(8).minute(0).second(0).millisecond(0);

    // Find the next Monday UTC
    if (schedule === 'weekly') {
      if (dt.day() === 0) {
        dt = dt.add(1, 'day');
      }
      if (dt.day() > 1) {
        dt = dt.add(8 - dt.day(), 'day');
      }
    }

    // Convert to local timezone and format.
    return dt.local().format('ddd, MMM D, YYYY, h:mm A');
  };

  const selectedIntegrationSchedule = source?.schedule;

  return (
    <>
      <StyledTable
        columns={columnsSyncs}
        loading={loading}
        dataSource={
          chunkedSyncs[pagination.currentPage] &&
          chunkedSyncs[pagination.currentPage].map((sync: IHelpDocsSyncType) => ({
            ...sync,
            started: sync.started ? dayjs(sync.started).format('ddd, MMM D, YYYY, h:mm A') : '',
            status: (() => {
              if (sync.error) return 'Error';
              if (sync.ended) return 'complete';
              if (sync.started) return 'running';
              return 'pending';
            })(),
            count: sync.commands?.length,
          }))
        }
        title={() => (
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            {viewSelector}
            <FilterSelect
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
              options={[
                { label: 'All', value: 'all' },
                { label: 'Complete', value: 'published' },
                { label: 'Running', value: 'unpublished' },
              ]}
            />
          </Row>
        )}
        footer={() => (
          <Footer
            pagination={pagination}
            setCurrentPage={(currentPage: any) => setPagination((p) => ({ ...p, currentPage }))}
          />
        )}
        pagination={false}
        //@ts-expect-error disabling because tslint is not recognizing this style prop
        noBulkSelect
      />
      {selectedIntegrationSchedule !== 'none' && (
        <h4 style={{ margin: '12px' }}>
          Next scheduled sync: {getNextRunTime(selectedIntegrationSchedule || 'daily')}
        </h4>
      )}
    </>
  );
};

export default SyncHistoryTable;
