import React, { useEffect, useState } from 'react';

import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import helpdocService from 'shared/services/services/helpdocService';
import { useStore } from 'shared/util/hooks/useStore';
import { KeywordsContainer } from '../../../shared/components/keywords/KeywordsContainer';

const PLACEHOLDER_IMAGE =
  'data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMSAxIj48cGF0aCBkPSJNMCAwaDF2MUgwIiBmaWxsPSIjODg4Ii8+PC9zdmc+';

const ZendeskDoc = ({ docHtml, commandId }: { docHtml: string; commandId: string | number }) => {
  const _ = useStore();
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    if (!_.organization?.id) return;

    const doc = document.implementation.createHTMLDocument();
    const div = doc.createElement('div');
    div.innerHTML = sanitizeHtml(docHtml);
    const imgTags = Array.from(div.querySelectorAll('img'));

    const imagesToLoad = [];

    for (const imgTag of imgTags) {
      const imgSrc = imgTag.getAttribute('src');
      if (!imgSrc) continue;

      if (imgTag.getAttribute('data-load-as-zd-article-attachment') === 'true') {
        imgTag.setAttribute('src', PLACEHOLDER_IMAGE);
        imgTag.setAttribute('data-src', imgSrc);
      }
      imagesToLoad.push(imgTag);
    }

    // Update the HTML content with placeholder images
    setHtmlContent(div.innerHTML);

    for (const imgTag of imagesToLoad) {
      const imgSrc = imgTag.getAttribute('data-src');
      if (imgSrc) {
        helpdocService.getZendeskHelpdocAttachment(_.organization.id, commandId, _.endUser, imgSrc).then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          imgTag.setAttribute('src', blobUrl);
          setHtmlContent(div.innerHTML); // Update state to trigger re-render
        });
      }
    }
  }, [_.organization?.id, commandId, _.endUser, docHtml]);

  //TODO: decouple KeywordsContainer from ZendeskDoc
  return <KeywordsContainer html={htmlContent} />;
};

export default ZendeskDoc;
