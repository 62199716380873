import { Condition } from '@commandbar/internal/middleware/conditions';
import { IRuleExpressionAnd, IRuleExpressionOr } from '@commandbar/internal/middleware/types';
import React, { useContext } from 'react';
import { ConditionTypeCategory } from '../categories';

type ConditionEditorContextValue = {
  condition: Condition;
  onConditionChange: (condition: Condition) => void;

  disabled: boolean;

  setHasBlurred: React.Dispatch<React.SetStateAction<boolean>>;

  categories: ConditionTypeCategory[];
};

const ConditionEditorContext = React.createContext<ConditionEditorContextValue>({
  condition: {
    type: 'context',
    operator: 'isDefined',
    value: '',
    field: '',
  },
  onConditionChange: () => {
    throw new Error('ConditionEditorContext is not provided');
  },

  disabled: false,

  setHasBlurred: () => {
    throw new Error('ConditionEditorContext is not provided');
  },

  categories: [],
});

type ConditionEditorProviderProps = {
  condition: Condition;
  onConditionChange: (condition: Condition) => void;

  setHasBlurred: React.Dispatch<React.SetStateAction<boolean>>;

  expr: IRuleExpressionAnd | IRuleExpressionOr;

  disabled: boolean;

  categories: ConditionTypeCategory[];
};

export const ConditionEditorProvider: React.FC<ConditionEditorProviderProps> = ({
  condition,
  onConditionChange,

  setHasBlurred,

  disabled,

  categories,

  children,
}) => {
  return (
    <ConditionEditorContext.Provider
      value={{
        condition,
        onConditionChange,
        setHasBlurred,
        categories,
        disabled,
      }}
    >
      {children}
    </ConditionEditorContext.Provider>
  );
};

export const useConditionEditor = () => {
  return useContext(ConditionEditorContext);
};
