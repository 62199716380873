export const NUDGE_ID = 'nudge';
export const STEP_ID = 'step';

export const NUDGE_TEMPLATE = 'template';
export const PAGE = 'page';
export const FROM = 'from';

export const NUDGE_TOUR_TEMPLATE_PARAM_VALUE = 'tour';
export const NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM_VALUE = 'highlight';
export const NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM_VALUE = 'announcement';
export const NUDGE_FEEDBACK_TEMPLATE_PARAM_VALUE = 'feedback';
export const NUDGE_RATING_TEMPLATE_PARAM_VALUE = 'rating';
export const NUDGE_NPS_TEMPLATE_PARAM_VALUE = 'nps';

export const NUDGE_TOUR_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_TOUR_TEMPLATE_PARAM_VALUE}`;
export const NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM_VALUE}`;
export const NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM_VALUE}`;
export const NUDGE_FEEDBACK_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_FEEDBACK_TEMPLATE_PARAM_VALUE}`;
export const NUDGE_RATING_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_RATING_TEMPLATE_PARAM_VALUE}`;
export const NUDGE_NPS_TEMPLATE_PARAM = `${NUDGE_TEMPLATE}=${NUDGE_NPS_TEMPLATE_PARAM_VALUE}`;
