import styled, { ComponentSelector } from '@emotion/styled';

import { CB_COLORS, Drawer, Row, Table } from '@commandbar/design-system/components/antd';
import Spinner from '../../table/Spinner';
import { Loading01 } from '@commandbar/design-system/icons/react';

export const StyledTable = styled(Table)<{ noBulkSelect?: boolean }>`
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  overflow: hidden;

  & .ant-spin-container,
  & .ant-spin-nested-loading {
    height: 100%;
  }

  & .ant-table {
    border-top: none;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    line-height: 14px !important;
  }

  & .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & .ant-table-cell {
    height: 40px;
  }

  & .ant-table-row.selected,
  .ant-table-row.selected .ant-table-cell-row-hover {
    background: #e6ecfe !important;
  }

  & .ant-table-cell {
    padding: 8px 16px;
    align-items: center;
    border-style: solid;
    border-width: 0.5px 1px 0.5px 0px;
    border-color: ${CB_COLORS.neutral300};
    color: ${CB_COLORS.neutral1000};
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  & .ant-table-cell.options {
    padding: 0;
  }

  & .ant-table-cell-row-hover {
    background: ${CB_COLORS.neutral0} !important;
    border-color: ${CB_COLORS.neutral300} !important;
  }

  & .ant-table-selection-column,
  th:first-child {
    padding: ${({ noBulkSelect }) => (noBulkSelect ? '9px' : '5px !important')};
    border-right: ${({ noBulkSelect }) => (noBulkSelect ? `0.5px sollid ${CB_COLORS.neutral300}` : 'none')};
  }

  & .ant-table-cell:last-child,
  .ant-table-thead:last-child {
    border-right: none;
  }

  & .ant-table-thead .ant-table-cell {
    padding: 9px;
    background: ${CB_COLORS.neutral0};
    color: ${CB_COLORS.neutral800};
    font-size: 14px;
    text-transform: none;

    &::before {
      background-color: transparent !important;
    }
  }

  & .ant-table-footer {
    padding: 0 !important;
    border-radius: 0 0 8px 8px;
  }

  & .ant-table-footer * {
    background: #ffffff !important;
  }

  & .ant-table-footer button {
    box-shadow: none;
    border: none;
  }

  & .ant-table-title {
    display: flex;
    justify-content: end;
    padding: 8px;
    border-radius: 8px 8px 0 0;
  }

  & a {
    color: ${CB_COLORS.neutral800} !important;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 14px !important;
  }

  & a:hover,
  & a:active {
    text-decoration: underline !important;
    color: #000 !important;
  }

  & td {
    color: ${CB_COLORS.neutral800};
    padding: 0px;
  }
` as unknown as typeof Table & ComponentSelector;

export const ButtonRow = styled(Row)`
  justify-content: flex-end;
  gap: 8px;
`;

export const FooterRow = styled(Row)`
  justify-content: space-between;
  gap: 8px;
`;

export const DrawerTitle = styled.div`
  font-size: 16px;
  line-height: 14px;
  color: ${CB_COLORS.neutral1000};
  font-weight: 500;
`;

export const DrawerSubtitle = styled.div`
  font-size: 14px;
  color: ${CB_COLORS.neutral800};
  font-weight: 400;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-close {
    position: absolute;
    right: 0;
  }
  .ant-drawer-header {
    padding: 24px;
  }

  .ant-drawer-content-wrapper {
    top: 10px;
    bottom: 10px;
    right: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  }

  .ant-drawer-content {
    border-radius: 8px;
  }
`;

export const StyledRadioGroup = styled.div`
  & .ant-radio-group {
    background: #f4f4f5;
    border-radius: 6px;
  }
  & .ant-radio-button-wrapper {
    color: #797c85;
    background: #f4f4f5;
    border: none;
    margin: 3px;
    border-radius: 4px;
  }

  & .ant-radio-button-wrapper::before {
    content: none;
  }

  .ant-radio-button-wrapper:focus {
    box-shadow: none;
    border: none;
  }

  & .ant-radio-button-wrapper-checked {
    background: #fff;
    color: #000;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  }
`;

export const RadioContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ColumnTitle = styled.span`
  display: flex;
  align-content: center;
  gap: 8px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 575px;
`;

export const LoadingState = () => (
  <LoadingContainer>
    <Spinner>
      <Loading01 />
    </Spinner>
  </LoadingContainer>
);
