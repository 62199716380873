import * as t from 'io-ts';

interface sortableObject {
  id: number | null | undefined;
  sort_key?: number | null | undefined;
}

export function compareObjs<T extends sortableObject>(a: T, b: T) {
  if (a.sort_key === b.sort_key) {
    if (a.id === b.id) return 0;
    if (b.id === null || b.id === undefined) return -1;
    if (a.id === null || a.id === undefined) return 1;
    return a.id - b.id; // lower ids first
  } else {
    if (b.sort_key === null || b.sort_key === undefined) return -1;
    if (a.sort_key === null || a.sort_key === undefined) return 1;
    return a.sort_key - b.sort_key; // lower sortKeys first
  }
}

export const maybePluralize = (count: number, singular: string, plural = `${singular}s`, includeNum = false) => {
  if (includeNum) return `${count} ${count !== 1 ? plural : singular}`;
  return `${count !== 1 ? plural : singular}`;
};

export const defineProcess = () => {
  // hack for this error: https://github.com/remarkjs/react-markdown/issues/339
  // we do not want to set if it is already defined since that will break node environments (CI)
  if (typeof (window as any).process === 'undefined') {
    (window as any).process = { cwd: () => '' };
  }
};

// h/t https://github.com/gcanti/io-ts/issues/300#issuecomment-1233601570

export function pick<P extends t.Props, K extends keyof P>(Model: t.TypeC<P>, keys: K[]): t.TypeC<Pick<P, K>> {
  const pickedProps = {} as Pick<P, K>;
  keys.forEach((key) => {
    pickedProps[key] = Model.props[key];
  });
  return t.type(pickedProps);
}

export function omit<P extends t.Props, K extends keyof P>(
  Model: t.TypeC<P>,
  keys: K[],
): t.TypeC<Pick<P, Exclude<keyof P, K>>> {
  const allKeys = Object.keys(Model.props) as K[];
  const keysToKeep = allKeys.filter((x) => !keys.includes(x)) as Exclude<typeof allKeys, typeof keys>;
  return pick(Model, keysToKeep);
}
