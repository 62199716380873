import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { IntentCondition as IntentConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { TagSelect } from 'editor/src/editor/components/TagSelect';

export const IntentCondition = () => {
  const context = useConditionEditor();

  const { onConditionChange, setHasBlurred } = context;
  const condition = context.condition as IntentConditionType;

  return (
    <Row style={{ display: 'flex', flexDirection: 'column', gap: '4px' }} align="middle" wrap={false}>
      <Row align="middle" wrap={false} style={{ width: '100%', gap: '4px' }}>
        <ConditionTypeSelect />
        <Col flex="1 0 auto" style={{ overflow: 'hidden' }}>
          <Select
            disabled={true}
            value={'in'}
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            onBlur={() => setHasBlurred(true)}
            size="small"
            options={[
              {
                value: 'in',
                label: 'includes',
              },
            ]}
          />
        </Col>
      </Row>

      <TagSelect
        key="search-history-select"
        value={condition.values}
        onChange={(newValues) => {
          onConditionChange({
            ...condition,
            values: newValues,
          });
        }}
        placeholder="Type a search term and press enter"
      />
    </Row>
  );
};
