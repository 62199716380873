/**
 * share link query params should look like this:
 * `/?${SHARE_LINK_PARAM}=${SHARE_LINK_NUDGE_ID}${nudgeId}`
 */
/**
 * These should be 1 character long to simplify the id extraction below
 */

import { RuleExpression, getConditions } from '../middleware/helpers/rules';
import { IOrganizationType } from '../middleware/types';
import { evaluateRuleExpression } from './RulesParser';

export type ShareLinkExperienceIdentifier = 'nudge' | 'questlist' | 'n' | 'q';

export enum ShareLinkType {
  NUDGE = 'n',
  QUESTLIST = 'q',
}

const isValidUrl = (sharePageUrl: string) => {
  if (!sharePageUrl) {
    return false;
  }

  let testUrl = sharePageUrl;
  if (testUrl.startsWith('/')) {
    testUrl = 'https://www.example.com' + testUrl;
  }
  if (!testUrl.startsWith('http')) {
    testUrl = 'https://' + testUrl;
  }

  try {
    new URL(testUrl);
  } catch (e) {
    return false;
  }

  /**
   * A valid url has the following format:
   * - a dot (for a domain)
   * -  OR localhost (optionally followed by :port#)
   */
  const urlRegex = /^(https?:\/\/)?(([\w-]+\.)+[\w]{2,}|localhost(:\d+)?)(\/\S*)?$/i;
  return urlRegex.test(testUrl);
};

export const urlMatchesTargeting = (sharePageUrl: string, pagetTargeting: RuleExpression) => {
  if (!isValidUrl(sharePageUrl)) {
    return false;
  }

  //cannot verify for interpolated URLs
  if (sharePageUrl.includes('{{')) {
    return true;
  }

  const conditions = getConditions(pagetTargeting);
  if (conditions.find((c) => c.type === 'element')) {
    return true;
  }

  let testUrl = sharePageUrl;
  if (testUrl.startsWith('/')) {
    testUrl = 'https://www.example.com' + testUrl;
  }
  if (!testUrl.startsWith('http')) {
    testUrl = 'https://' + testUrl;
  }

  const url = new URL(testUrl) as unknown as Location;

  const matchesTargeting =
    url &&
    !!evaluateRuleExpression(
      pagetTargeting,
      {},
      url as unknown as Location,
      {
        num_command_executions: 0,
        num_deadends: 0,
        num_opens: 0,
        num_sessions: 0,
        num_shortcut_command_executions: 0,
        first_seen_at: '',
        last_seen_at: '',
      },
      {},
      {},
      true,
      [],
      [],
      [],
      {},
    ).passed;

  return matchesTargeting;
};

const cannotVerifyTargetingMatch = (sharePageUrl: string, pageTargeting: RuleExpression) => {
  // If the share page is a relative path, domain targeting is compatible by definition and we can't verify the rest of the expression with reasonable effort
  if (sharePageUrl.startsWith('/')) {
    const conditions = getConditions(pageTargeting);
    if (conditions.find((c) => c.type === 'hostname')) {
      return true;
    }
  }
};

export const isValidStartPageUrl = (sharePageUrl: string, pageTargeting: RuleExpression | null) => {
  //cannot verify for interpolated URLs
  if (sharePageUrl.includes('{{')) {
    return { isValid: true, error: '' };
  }

  if (!isValidUrl(sharePageUrl)) {
    return { isValid: false, error: 'Not a valid absolute URL or relative path' };
  }

  if (pageTargeting && !cannotVerifyTargetingMatch(sharePageUrl, pageTargeting)) {
    if (!urlMatchesTargeting(sharePageUrl, pageTargeting)) {
      return { isValid: false, error: 'Targeting will prevent the experience from showing at this URL' };
    }
  }

  return { isValid: true, error: '' };
};

export const getShareLinkType = (type: ShareLinkExperienceIdentifier): ShareLinkType => {
  switch (type) {
    case 'questlist':
    case 'q':
      return ShareLinkType.QUESTLIST;
    case 'nudge':
    case 'n':
    default:
      return ShareLinkType.NUDGE;
  }
};

export const buildShareLink = (
  organization: IOrganizationType,
  startPage: string,
  type: ShareLinkExperienceIdentifier,
  id: string | number,
) => {
  let shareLink: string | null = null;
  const baseUrl = organization.base_url.startsWith('http') ? organization.base_url : `https://${organization.base_url}`;

  if (startPage) {
    if (startPage.startsWith('/')) {
      if (baseUrl) {
        let cleanBaseUrl = baseUrl;
        if (baseUrl.endsWith('/')) {
          cleanBaseUrl = baseUrl.slice(0, -1);
        }
        shareLink = cleanBaseUrl + startPage;
      }
    } else {
      shareLink = startPage;
    }
  } else {
    if (baseUrl) {
      shareLink = baseUrl;
    }
  }

  if (shareLink === null) {
    return null;
  }

  let url;
  try {
    url = new URL(shareLink);
  } catch (e) {
    return null;
  }

  const param = organization.share_link_param || 'cb-eid';

  const base = url.href.replace(url.hash, '').replace(url.search, '').replaceAll('%7B', '{').replaceAll('%7D', '}');
  const searchParams = new URLSearchParams(url.search);
  searchParams.delete(param);
  const baseSearchParams = searchParams.toString();

  const shareLinkParam = `${param}=${getShareLinkType(type)}m${id}`;
  return {
    url: `${base}?${baseSearchParams}${baseSearchParams ? '&' : ''}${shareLinkParam}${url.hash}`,
    param: shareLinkParam,
  };
};

/**
 * - Returns the id of the nudge or QL from the share link hash
 * - If the path does not include the necessary components of a share
 *  link, this function will return undefined
 */

export type DeconstructedShareLink =
  | {
      type: ShareLinkType;
      id: number;
      source: 'trigger' | 'share_modal';
    }
  | undefined;

export const deconstructShareLink = (param: string, searchString: string): DeconstructedShareLink => {
  const searchParams = new URLSearchParams(searchString);
  const shareLinkParam = searchParams.get(param);

  if (!shareLinkParam) return undefined;

  const type = getShareLinkType(shareLinkParam[0] as ShareLinkExperienceIdentifier);
  if (!type) return undefined;

  let source: 'trigger' | 'share_modal' = 'share_modal';

  const secondChar = shareLinkParam[1];
  const hasSource = isNaN(parseInt(secondChar));

  if (hasSource) {
    if (secondChar === 't') source = 'trigger';
  }

  return {
    type,
    id: Number(shareLinkParam.slice(hasSource ? 2 : 1)),
    source,
  };
};

export const onSamePage = (current: URL, sharePageUrl: URL) => {
  if (current.origin !== sharePageUrl.origin) return false;
  if (current.pathname !== sharePageUrl.pathname) return false;
  if (![...sharePageUrl.searchParams].every(([k, v]) => current.searchParams.get(k) === v)) return false;

  return true;
};

export const getAbsoluteSharePageUrl = (current: URL, sharePage: string) => {
  if (!sharePage) return null;

  let startPage = sharePage;
  if (startPage.startsWith('/')) {
    startPage = `${current.origin}${startPage}`;
  }
  if (!startPage.startsWith('http')) {
    startPage = `${current.protocol}//${startPage}`;
  }
  return startPage ? new URL(startPage) : null;
};
