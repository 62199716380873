import * as t from 'io-ts';

import { AdminFilter, CardDataV, DateFilter, FunnelDataV, PaginationFilter, TableDataV } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsProductToursDataV = t.type({
  active: CardDataV,
  sessions: CardDataV,
  completion_rate: CardDataV,
  table: TableDataV,
});

export type AnalyticsProductToursData = t.TypeOf<typeof AnalyticsProductToursDataV>;

const AnalyticsProductToursDetailDataV = t.type({
  sessions: CardDataV,
  completion_rate: CardDataV,
  snooze_rate: CardDataV,
  end_user_funnel: FunnelDataV,
  tourName: t.string,
  survey_table: t.union([TableDataV, t.undefined, t.null]),
  goal: t.union([CardDataV, t.undefined, t.null]),
});

const AnalyticsProductToursUsersDataV = t.type({
  views: TableDataV,
});

export type AnalyticsProductToursDetailData = t.TypeOf<typeof AnalyticsProductToursDetailDataV>;
export type AnalyticsProductToursUsersData = t.TypeOf<typeof AnalyticsProductToursUsersDataV>;

type ProductToursParams = {
  show_unpublished?: string;
} & DateFilter &
  AdminFilter;

export type ProductToursDetailParams = {
  show_unpublished?: string;
  goal_event_name?: string;
} & DateFilter &
  AdminFilter;

export type ProductToursUsersParams = {
  search_filter: string;
} & DateFilter &
  AdminFilter &
  PaginationFilter;

export class AnalyticsProductTours {
  public static read = (params: ProductToursParams) =>
    read(AnalyticsProductToursDataV, `analytics/v2/product-tours`)(params);

  public static readDetail = (id: string, params: ProductToursDetailParams) =>
    read(AnalyticsProductToursDetailDataV, `analytics/v2/product-tours/${id}`)(params);

  public static readUsers = (id: string, params: ProductToursUsersParams) =>
    read(AnalyticsProductToursUsersDataV, `analytics/v2/product-tours/${id}/users`)(params);

  public static download = async (params: ProductToursParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/product-tours/export/${queryString}`);
  };
}
