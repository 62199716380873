import { CmdDropdown } from '@commandbar/design-system/cmd';
import { SwitchVertical01 } from '@commandbar/design-system/icons/react';
import { useState } from 'react';

type Filter = {
  displayName: string;
  value: string;
};

const filter_options = [
  {
    displayName: 'Newest first',
    value: 'newest_first',
  },
  {
    displayName: 'Oldest first',
    value: 'oldest_first',
  },
];

interface FilterDropdownProps {
  handleFilterChange: (value: Filter) => void;
  filterValue: Filter;
}

export const useFilterDropdown = () => {
  const [filterValue, handleFilterChange] = useState<Filter>(filter_options[0]);

  return {
    filterValue,
    handleFilterChange,
  };
};

export const FilterDropdown = ({ handleFilterChange, filterValue }: FilterDropdownProps) => {
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.SelectTrigger>
        <SwitchVertical01 /> {filterValue.displayName}
      </CmdDropdown.SelectTrigger>
      <CmdDropdown.Content>
        <CmdDropdown.RadioGroup
          value={filterValue.value}
          onValueChange={(v) => handleFilterChange(filter_options.find((op) => op.value === v) || filter_options[0])}
        >
          {filter_options.map((option) => (
            <CmdDropdown.RadioItem key={option.value} value={option.value}>
              {option.displayName}
            </CmdDropdown.RadioItem>
          ))}
        </CmdDropdown.RadioGroup>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

export default FilterDropdown;
