import { SVGProps, useEffect, useState } from 'react';
import { INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import Page from '@commandbar/design-system/components/antd/Page';
import {
  CmdAvatar,
  CmdColumnDef,
  CmdDataTable,
  CmdRow,
  CmdSortableColumn,
  CmdSortingState,
  CmdTooltip,
} from '@commandbar/design-system/cmd';
import { EndUserAdmin, EndUserTableData, IEndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import UserDetailDrawer from './UserDetailDrawer';
import { PAGE_SIZE } from '../analytics/dashboards/copilot/state';
import { debounce } from 'lodash';
import React from 'react';
import dayjs from 'dayjs';
import { timeAgoFormat } from '../analytics/utils';

export type ExperienceRow = {
  title: string;
  link: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

export interface SelectedRule extends INamedRule {
  numEntitiesUsingThisRule: number;
  entitiesUsingThisRule: ExperienceRow[];
}

const UsersPage = () => {
  const [page, setPage] = useState<number>(1);

  const [data, setData] = useState<EndUserTableData | null>(null);
  const [sorting, setSorting] = useState<CmdSortingState>([]);
  const [searchFilter, _setSearchFilter] = useState<string>('');

  const setSearchFilter = React.useCallback(
    debounce((value: string) => {
      _setSearchFilter(value);
    }, 500),
    [],
  );

  const [currentEnduser, setCurrentEnduser] = useState<IEndUserAdmin | null>(null);

  const userColumns: CmdColumnDef<IEndUserAdmin, string | number>[] = [
    {
      accessorKey: 'slug',
      header: 'User id',
      enableGlobalFilter: true,
      onClick: (row: CmdRow<IEndUserAdmin>) => {
        setCurrentEnduser(row.original);
      },

      cell: ({ row }) => {
        const record = row.original;
        return (
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {' '}
            <CmdAvatar size="sm" variant="initials">
              {record.slug[0].toUpperCase()}
            </CmdAvatar>
            {record.slug}
          </span>
        );
      },
    },
    {
      accessorKey: 'num_sessions',
      header: ({ column }) => <CmdSortableColumn column={column} title="Sessions" />,
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'last_seen_at',
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen" />,
      enableGlobalFilter: true,
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{timeAgoFormat(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  useEffect(() => {
    EndUserAdmin.list({
      ...(!!sorting[0]?.id && {
        sort_column: `analytics__${sorting[0]?.id}`,
        sort_order: sorting[0]?.desc ? 'desc' : 'asc',
      }),
      query: searchFilter,
      page: page.toString(),
      page_size: '10',
    }).then((data) => {
      setData(data);
    });
  }, [page, sorting, searchFilter]);

  if (!data) {
    return null;
  }

  return (
    <Page disableVerticalContentPadding whiteBg={false} title="Users">
      <UserDetailDrawer
        endUser={currentEnduser}
        onOpenChange={(value) => {
          if (!value) setCurrentEnduser(null);
        }}
      />

      <CmdDataTable
        searchPlaceholder="Search by User ID"
        manualSorting
        manualFiltering
        manualPagination
        showPagination
        onGlobalFilterChange={(value: string) => {
          setSearchFilter(value);
        }}
        data={data.users}
        onPaginationChange={(updater) => {
          if (typeof updater === 'function') {
            const newState = updater({
              pageSize: PAGE_SIZE,
              pageIndex: page,
            });
            setPage(newState.pageIndex);
          }
        }}
        state={{
          sorting,
          pagination: {
            pageIndex: page,
            pageSize: PAGE_SIZE,
          },
          globalFilter: searchFilter,
        }}
        onSortingChange={(state) => {
          setSorting(state);
        }}
        columns={userColumns}
        rowCount={data?.totalRows}
        pageCount={Math.ceil(data.totalRows / PAGE_SIZE)}
      />
    </Page>
  );
};

export default UsersPage;
