/*******************************************************************************/
/* Imports
/*******************************************************************************/

import * as t from 'io-ts';

/*******************************************************************************/
/* Argument definitions
/*******************************************************************************/

export const ConditionOperatorV = t.union([
  t.literal('=='),
  t.literal('!='),
  t.literal('isTruthy'),
  t.literal('isFalsy'),
]);

export const ConditionV = t.type({
  field: t.string,
  operator: ConditionOperatorV,
  value: t.union([t.string, t.undefined]),
});

export const ContextArgumentV = t.intersection([
  t.type({
    type: t.literal('context'),
    value: t.string,
    order_key: t.number,
  }),
  t.partial({
    label: t.string,
    chosen: t.union([t.string, t.number]),
    selected: t.array(t.any),
    input_type: t.string,
    preselected_key: t.string,
    label_field: t.string,
    loaded: t.array(t.any),
    allow_create: t.boolean,
    allow_create_label: t.string,
    show_in_record_action_list: t.boolean,
    show_in_default_list: t.boolean,
    auto_choose: t.boolean,
    is_private: t.boolean,
  }), // FIXME: this should allow any primitive type
]);

export const SetArgumentV = t.intersection([
  t.type({
    type: t.literal('set'),
    value: t.union([t.array(t.string), t.array(t.number), t.array(t.UnknownRecord)]),
    order_key: t.number,
  }),
  t.partial({
    label: t.string,
    chosen: t.union([t.string, t.number]),
    selected: t.array(t.any),
    input_type: t.string,
    preselected_key: t.string,
    label_field: t.string,
    loaded: t.array(t.any),
    allow_create: t.boolean,
    allow_create_label: t.string,
    auto_choose: t.boolean,
    is_private: t.boolean,
  }),
]);

export const DashboardV = t.intersection([
  t.type({
    source: t.string,
  }),
  t.partial({
    title: t.string,
    description: t.string,
    url: t.string,
  }),
]);

export const DashboardArgumentV = t.intersection([
  t.type({
    type: t.union([t.literal('video'), t.literal('html')]),
    value: DashboardV,
    order_key: t.number,
  }),
  t.partial({
    label: t.string,
    chosen: t.union([t.string, t.number]),
    selected: t.array(t.any),
    input_type: t.string,
    preselected_key: t.string,
    label_field: t.string,
    loaded: t.array(t.any),
    allow_create: t.boolean,
    allow_create_label: t.string,
    is_private: t.boolean,
    auto_choose: t.boolean,
  }),
]);

export const DynamicArgumentV = t.intersection([
  t.type({
    type: t.literal('provided'), //FIXME: update to dynamic
    value: t.union([t.literal('text'), t.literal('time')]),
    order_key: t.number,
  }),
  t.partial({
    label: t.string,
    chosen: t.union([t.string, t.number]),
    selected: t.array(t.any),
    input_type: t.string,
    preselected_key: t.string,
    label_field: t.string,
    loaded: t.array(t.any),
    dateTimeArgumentTypeId: t.number,
    allow_create: t.boolean,
    allow_create_label: t.string,
    auto_choose: t.boolean,
    is_private: t.boolean,
  }),
]);

export const ArgumentTypeV = t.union([ContextArgumentV, SetArgumentV, DynamicArgumentV, DashboardArgumentV]);

export const ArgumentMapV = t.record(t.string, ArgumentTypeV);

export const StepArgumentTypeV = t.intersection([
  t.type({
    userDefinedName: t.string, // Name to show to end users
    userDefinedValue: t.string, // Value to pass to callbacks
  }),
  ArgumentTypeV,
]);
