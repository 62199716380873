import React from 'react';

import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';
import { Input, Form, CB_COLORS } from '@commandbar/design-system/components/antd';
import { Eye, EyeOff } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';

const Anchor = styled.a`
  text-decoration: underline;
  color: ${CB_COLORS.neutral900};
  font-size: 14px;

  &:hover {
    color: ${CB_COLORS.neutral900};
  }
`;
const Gitbook: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.GITBOOK,
  name: 'Gitbook',
  slug: 'gitbook',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/gitbook-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/gitbook-icon.webp',
  isDisabled: false,
  useDryRun: true,
  description: 'Automatically sync articles with Command AI.',
  guidance: <span />,
  route: '/gitbook',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          extra={
            <Anchor href="https://app.gitbook.com/account/developer" target="_blank">
              Find your API key here ↗
            </Anchor>
          }
          key={editingSource?.type + 'api_key'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          required={true}
          label={'API Key'}
          name={'api_key'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your API Key. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%', marginBottom: '4px' }}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          required={true}
          label={'Gitbook Domain'}
          name={'domain'}
          rules={[
            { required: true, message: 'The domain is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.*$/gi,
              message: `Please enter a valid URL (e.g., your-company.gitbook.io). Don't include 'http' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} placeholder="your-company.gitbook.io" />
        </Form.Item>
        <Form.Item
          key={'space'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.space}
          required={true}
          label={'Space ID'}
          name={'space'}
          rules={[{ required: true, message: 'Your space id is a required field.' }]}
          style={{ flex: 5 }}
          tooltip={
            <>
              Login to GitBook and navigate to the Space you want to sync. Copy the Space ID from your browser URL,
              i.e.: {'https://app.gitbook.com/s/<your-space-id>'}
            </>
          }
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      api_key: data['api_key'],
      domain: data['domain'],
      space: data['space'],
    };
  },
  requiredFields: ['domain', 'api_key', 'space'],
  fetchedFields: [],
};

export default Gitbook;
