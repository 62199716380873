import React from 'react';

import queryString from 'query-string';

import { FormLayout2 } from './FormLayout';
import { useHistory } from 'react-router';
import { Input, Form } from '@commandbar/design-system/components/antd';
import Auth from '@commandbar/internal/client/authentication';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { CmdButton } from '@commandbar/design-system/cmd';
import { Link } from 'react-router-dom';

const VerifyEmail = () => {
  const { checkAuth } = useAuth();

  const [success, setSuccess] = React.useState(false);
  const [resent, setResent] = React.useState(false);
  const [error, setError] = React.useState('');
  const [form] = Form.useForm();
  const history = useHistory();
  const [emailValidateType, setEmailValidateType] = React.useState('onBlur');

  const checkToken = async () => {
    const token = queryString.parse(window.location.search)?.token;
    if (!!token) {
      try {
        await Auth.verifyEmail(token.toString());
        await checkAuth();

        setSuccess(true);
      } catch (e) {
        setError(JSON.stringify(e));
      }
    }
    history.replace('/verify');
  };
  React.useEffect(() => {
    checkToken();
  }, []);

  const onFinish = async (values: any) => {
    try {
      await Auth.sendVerificationEmail(values.email);
      setResent(true);
    } catch (e) {
      setError(JSON.stringify(e));
    }
  };

  let content = null;
  if (success) {
    content = <div>Your email was successfully verified!</div>;
  } else {
    content = (
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="Work email"
          name="email"
          id="email"
          validateTrigger={emailValidateType}
          rules={[{ type: 'email', max: 30, message: 'Invalid Email Address' }]}
        >
          <Input
            onBlur={() => setEmailValidateType('onChange')}
            placeholder="you@company.com"
            type="email"
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={resent}>
            {resent ? 'Resent' : 'Resend Verification Email'}
          </CmdButton>
        </Form.Item>
      </Form>
    );
  }

  return (
    <FormLayout2 h1="Verifying Your Email" h2="" error={error}>
      <br />
      <div style={{ textAlign: 'center' }}>
        {content}
        <br />
        <Link to="/login"> Sign in here</Link>
      </div>
    </FormLayout2>
  );
};

export default VerifyEmail;
