import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps } from 'class-variance-authority';

import { cn, extendRadixComponent } from '../util';
import { labelStyles } from './styles';
import { InfoCircle, AlertTriangle } from '@commandbar/design-system/icons/react';
import { CmdTooltip } from '../tooltip';

type CmdLabelProps = VariantProps<typeof labelStyles> & {
  disabled?: boolean;
  tooltip?: React.ReactElement | string;
  tooltipIconType?: 'info' | 'warning';
};

const CmdLabel = extendRadixComponent<typeof LabelPrimitive.Root, CmdLabelProps>(
  ({ className, disabled = false, tooltip, tooltipIconType = 'info', ...props }, ref) => {
    const triggerRef = React.useRef(null);
    const TooltipIcon = tooltipIconType === 'info' ? InfoCircle : AlertTriangle;

    return (
      <div className={cn('gap-sm flex items-center')}>
        <LabelPrimitive.Root ref={ref} className={cn(labelStyles({ disabled }), className)} {...props} />
        {tooltip && (
          <CmdTooltip.Provider delayDuration={400}>
            <CmdTooltip.Root>
              <CmdTooltip.Trigger
                ref={triggerRef}
                onClick={(e) => {
                  e.preventDefault();
                }}
                asChild
              >
                <span className={cn('flex py-xs')}>
                  <TooltipIcon className={cn(labelStyles({ disabled }), 'text-contentBase')} />
                </span>
              </CmdTooltip.Trigger>
              <CmdTooltip.Content
                sideOffset={1}
                onPointerDownOutside={(event) => {
                  /* eslint-disable commandbar/no-event-target */
                  if (event.target === triggerRef.current) {
                    event.preventDefault();
                  }
                }}
              >
                {tooltip}
              </CmdTooltip.Content>
            </CmdTooltip.Root>
          </CmdTooltip.Provider>
        )}
      </div>
    );
  },
);

CmdLabel.displayName = 'CmdLabel';

export { CmdLabel };
export type { CmdLabelProps };
