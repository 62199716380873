import { IChecklist, ICommandTypeLite, INudgeType, IRecommendationSet } from '@commandbar/internal/middleware/types';
import { CBStore } from 'shared/store/global-store';
import { runBooleanExpression } from './helpers';
import { trackDecideError } from './helpers';
import { isProgrammatic } from '@commandbar/internal/middleware/command';
import { isStudioPreview } from '@commandbar/internal/util/location';

export const passesAudienceConditions = (
  _: CBStore,
  experienceType: 'nudges' | 'checklists' | 'recommendation_sets' | 'commands',
  experience: IChecklist | INudgeType | IRecommendationSet | ICommandTypeLite,
): boolean => {
  if (isStudioPreview()) {
    return true;
  }

  let useRemoteDecideResult = _.decide?.user_property_targeting;

  if (experienceType === 'commands') {
    const remoteDecideResult = _.decide?.[experienceType]?.[experience.id];
    const commandIsInDecide = typeof remoteDecideResult !== 'undefined' && remoteDecideResult !== null;

    if ((!!_.decide && !commandIsInDecide) || isProgrammatic(experience as ICommandTypeLite)) {
      useRemoteDecideResult = false;
    }
  }

  // XXX: io-ts will fill in 'undefined' if the property is not present.
  // Once we roll this out completely and no longer send the flag, we should default to the remote decide result
  if (_.decide && typeof _.decide.user_property_targeting === 'undefined') {
    useRemoteDecideResult = true;
  }

  const localAudienceResult = passesLocalConditions(_, experience);
  const remoteAudienceResult = passesDecide(_, localAudienceResult, experienceType, experience);

  let audienceResult = localAudienceResult;

  if (useRemoteDecideResult) {
    audienceResult = remoteAudienceResult;
  }

  return audienceResult;
};

export const passesLocalConditions = (
  _: CBStore,
  experience: IChecklist | INudgeType | IRecommendationSet | ICommandTypeLite,
): boolean => {
  let audience = experience.audience;
  if ('template' in experience && experience.template.type === 'helpdoc' && experience.integration) {
    // If the experience is a help doc command with an integration, use the integration's audience
    audience = experience.integration.audience;
  }

  if (audience) {
    switch (audience.type) {
      case 'all_users':
        return true;
      case 'rule_expression':
        return runBooleanExpression(audience.expression, _, '').passed;
      default:
        return false;
    }
  }

  return true;
};

export const passesDecide = (
  _: CBStore,
  localResult: boolean,
  experienceType: 'checklists' | 'nudges' | 'recommendation_sets' | 'commands',
  experience: IChecklist | INudgeType | IRecommendationSet | ICommandTypeLite,
): boolean => {
  const remoteDecideResult = _.decide?.[experienceType]?.[experience.id];

  if (typeof remoteDecideResult === 'undefined' || remoteDecideResult === null) {
    return false;
  }

  if (typeof remoteDecideResult === 'boolean') {
    return remoteDecideResult;
  } else if (typeof remoteDecideResult === 'object') {
    return localResult;
  }

  trackDecideError(_, experience, new Error('remoteDecideResult is not a boolean or object'));

  return false;
};
