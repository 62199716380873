import React from 'react';

import { InputContainer, Select, StyledLabel } from '@commandbar/design-system/components/antd';
import type { INamedRule } from '@commandbar/internal/middleware/helpers/rules';
import type { IAudienceType } from '@commandbar/internal/middleware/types';
import AudienceSize from './audiences/AudienceSize';
import { BorderedContainer, StyledSelect } from './components/styled';
import ConditionGroupEditor from './conditions/ConditionGroupEditor';
import { CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO } from './conditions/categories';

import { ReactComponent as CaretDown } from '../img/caret_down.svg';

type AudienceSelectOption = { special: 'all' } | { special: 'custom' } | { rule_id: string };

interface AudienceSelectProps {
  audience: IAudienceType;
  rules: INamedRule[];
  onChange: (audience: IAudienceType) => void;
  disabled?: boolean;
  hasCustomAudience?: boolean;
}

export const AudienceSelect = ({
  audience,
  rules,
  onChange,
  disabled = false,
  hasCustomAudience = true,
}: AudienceSelectProps) => {
  const audienceSelectValue = JSON.stringify(
    (() => {
      if (audience.type === 'all_users') return { special: 'all' };
      if (audience.type === 'rule_expression') return { special: 'custom' };
      return { rule_id: audience.rule_reference.rule_id };
    })(),
  );

  const audiences = [...rules.filter((rule) => rule.is_audience)].sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      <StyledSelect
        disabled={disabled}
        suffixIcon={<CaretDown />}
        showSearch
        optionLabelProp="label"
        filterOption={(input, option) =>
          typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
        }
        value={audienceSelectValue}
        onChange={(newValue) => {
          const option: AudienceSelectOption = JSON.parse(newValue);

          if ('special' in option) {
            if (option.special === 'all') {
              return onChange({ type: 'all_users' });
            }
            if (option.special === 'custom') {
              return onChange({ type: 'rule_expression', expression: { type: 'AND', exprs: [] } });
            }
          } else {
            onChange({
              type: 'named_rule_reference',
              rule_reference: {
                type: 'named_rule',
                rule_id: option.rule_id,
              },
            });
          }
        }}
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option label="All Users" value={JSON.stringify({ special: 'all' })}>
          All Users
        </Select.Option>
        {audiences.length > 0 && (
          <Select.OptGroup label={'Audiences'}>
            {audiences.map((rule) => (
              <Select.Option label={rule.name} key={rule.id} value={JSON.stringify({ rule_id: rule.id })}>
                {rule.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}

        {hasCustomAudience && (
          <Select.Option label={'Custom'} value={JSON.stringify({ special: 'custom' })}>
            Custom...
          </Select.Option>
        )}
      </StyledSelect>
      {audience.type === 'rule_expression' && (
        <InputContainer style={{ marginTop: 8 }}>
          <div style={{ width: '100%', margin: '12px 0' }}>
            <AudienceSize expr={audience.expression} />
          </div>

          <StyledLabel>Conditions</StyledLabel>

          <BorderedContainer>
            <ConditionGroupEditor
              disabled={disabled}
              onChange={(_expr) => {
                onChange({ type: 'rule_expression', expression: _expr });
              }}
              expr={audience.expression}
              includeCategories={CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO}
              excludeConditionTypes={['named_rule']}
            />
          </BorderedContainer>
        </InputContainer>
      )}
    </>
  );
};
