import React from 'react';
import styled from '@emotion/styled';
import * as S from './styles';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { ArrowRight } from '@commandbar/design-system/icons/react';
import { ICON_WIDTH } from './styles';

const Container = styled.div<{ background?: string }>`
  display: grid;
  grid-template-columns: 1fr 425px 1fr;
  width: 572px;
  height: 112px;
  background: ${(props) => (props.background ? props.background : '#fff')};
  border: 1px solid ${CB_COLORS.neutral500};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 24px 32px;
  box-sizing: border-box;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    border: 1px solid rgba(10, 10, 15, 0.64);
  }

  &:hover .card-right-icon {
    opacity: 1;
  }
`;

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 4px;

  color: #000000;
`;

const Description = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${CB_COLORS.neutral700};
`;

const LeftIconContainer = styled.div<{ shadow?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 48px;
  height: 48px;

  background: #ffffff;
  box-shadow: ${(props) => (props.shadow ? props.shadow : 'none')};
  border-radius: 50%;
`;

const DescriptionCol = styled(S.Col)`
  align-items: flex-start;
  padding-left: 16px;
`;

const RightIconCol = styled(S.Col)`
  opacity: 0;
  transition: 0.2s all;
`;

interface IModalCard {
  background?: string;
  iconShadow?: string;
  leftIcon: React.ReactNode;
  rightIcon?: React.ReactNode;
  title: string;
  description: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}
export const CardBase = ({ background, iconShadow, leftIcon, rightIcon, title, description, onClick }: IModalCard) => {
  return (
    <Container onClick={onClick} background={background}>
      <S.Col>
        <LeftIconContainer shadow={iconShadow}>{leftIcon}</LeftIconContainer>
      </S.Col>
      <DescriptionCol>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DescriptionCol>
      <RightIconCol className="card-right-icon">
        {rightIcon ? rightIcon : <ArrowRight width={ICON_WIDTH} color={CB_COLORS.neutral500} />}
      </RightIconCol>
    </Container>
  );
};
