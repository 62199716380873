import { FaceIdSquare } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(244, 235, 255) 0%, rgb(255, 255, 255) 100%);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid;
  border-color: #0a0a0f3d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 24px 32px 32px;
  gap: 16px;

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    color: #51515b;
    font-size: 14px;
    font-weight: 400;
  }
`;

const CopilotLogo = styled.div`
  align-items: center;
  background-color: #ffffff;
  border-radius: 150px;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 48px;
  color: #a057fd;
  box-shadow: 1.5px -1.5px 3px 0px rgba(160, 87, 253, 0.25) inset, 0px 1.5px 3px 0px rgba(160, 87, 253, 0.16),
    0px 5px 8px 0px rgba(8, 1, 28, 0.08);

  svg {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CTAList = styled.ul`
  padding-left: 0;
  margin: 0;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  gap: 6px;

  li span {
    margin-left: 8px;
  }

  li::marker {
    vertical-align: middle;
    margin-right: 8px;
    content: url('data:image/svg+xml;charset=UTF-8,<svg width="16" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="check" opacity="0.5"><path id="Icon" d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
  }
`;

export const LaunchCTA = () => {
  return (
    <Container>
      <CopilotLogo>
        <FaceIdSquare />
      </CopilotLogo>
      <Content>
        <div>
          <h3>Copilot</h3>
          <p>AI-powered chat in your app</p>
        </div>

        <CTAList>
          <li>
            <span>Natural language answers</span>
          </li>
          <li>
            <span>Smart suggestions (like tours)</span>
          </li>
          <li>
            <span>Seamless handoff to human support</span>
          </li>
        </CTAList>
      </Content>
    </Container>
  );
};

export default LaunchCTA;
