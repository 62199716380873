import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../util';

const statusDotVariants = cva('h-sm w-sm border border-solid border-[1px] rounded-full', {
  variants: {
    variant: {
      default: 'border-blue800 bg-blue700 shadow-[0_0_5px_rgba(56,104,255,1)]',
      success: 'border-green800 bg-green600 shadow-[0_0_5px_rgba(34,128,0,1)]',
      warning: 'border-yellow700 bg-yellow600 shadow-[0_0_5px_rgba(234,179,8,1)]',
      error: 'border-red800 bg-red700 shadow-[0_0_5px_rgba(255,0,0,1)]',
      info: 'border-gray400 bg-gray200 shadow-[0_0_5px_rgba(128,128,128,1)]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof statusDotVariants> {}

function CmdStatusDot({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(statusDotVariants({ variant }), 'inline-block', className)} {...props} />;
}

export { CmdStatusDot, statusDotVariants };
