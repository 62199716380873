import { cva } from 'class-variance-authority';

export const radioStyles = cva(
  [
    'h-lg w-lg rounded-full border border-solid relative',
    'hover:border-gray500',
    'focus:border-blue700 focus:shadow-input',
    'data-[state=checked]:bg-blue700 data-[state=checked]:border-blue900',
    'data-[state=unchecked]:bg-gray100 data-[state=unchecked]:border-gray400',
    'gap-sm',
  ],
  {
    variants: {
      disabled: {
        true: [
          'bg-gray200 border-gray300',
          'cursor-not-allowed',
          'hover:border-gray300',
          '[>&svg]:fill-gray600 text-gray600',
          'data-[state=checked]:bg-gray200',
          'data-[state=checked]:border-gray300',
        ],
      },
    },
    compoundVariants: [],
    defaultVariants: {},
  },
);
