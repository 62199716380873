import styled from '@emotion/styled';

import { Menu, Dropdown, Modal } from '@commandbar/design-system/components/antd';
import { DotsVertical, PencilLine, Trash04 } from '@commandbar/design-system/icons/react';
import { useReportEvent } from '../../../hooks/useEventReporting';

import { CmdDivider } from '@commandbar/design-system/cmd';
import { IKeyword } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

export const DropdownMenu = styled(Menu)`
  display: flex;
  width: 207px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
`;

export const DropdownMenuItem = styled(Menu.Item)`
  width: 100%;
  padding: 8px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;

  & .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }
`;

interface KeywordSettingsDropdownProps {
  keyword: IKeyword;
  openEditDrawer: (id: number) => void;
}

const ContentSettingsDropdown = ({ keyword, openEditDrawer }: KeywordSettingsDropdownProps) => {
  const { reportEvent } = useReportEvent();

  const {
    dispatch: { keywords },
  } = useAppContext();

  const { user } = useAuth();

  if (!hasRequiredRole(user, 'contributor')) {
    return null;
  }

  const canDelete = hasRequiredRole(user, keyword.is_live ? 'editor' : 'contributor');

  return (
    <Dropdown
      overlay={
        <DropdownMenu>
          <DropdownMenuItem
            key="edit"
            onClick={() => {
              openEditDrawer(keyword.id);
            }}
          >
            <PencilLine />
            Edit
          </DropdownMenuItem>
          {canDelete && (
            <>
              <CmdDivider spacing="sm" />
              <DropdownMenuItem
                disabled={!canDelete}
                key="delete"
                onClick={() => {
                  Modal.confirm({
                    title: 'This will permanently delete this keyword. Are you sure you want to proceed?',
                    onOk: async () => {
                      await keywords.delete(keyword.id);

                      reportEvent('keyword deleted', {
                        segment: true,
                        highlight: true,
                        slack: true,
                        payloadMessage: `${keyword.id}`,
                        eventProps: {
                          id: keyword.id,
                        },
                      });
                    },
                    cancelText: 'Cancel',
                  });
                }}
              >
                <Trash04 />
                Delete
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenu>
      }
      trigger={['click']}
      placement="bottomLeft"
    >
      <DotsVertical onClick={(e) => e.stopPropagation()} />
    </Dropdown>
  );
};

export default ContentSettingsDropdown;
