import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps } from 'class-variance-authority';
import { buttonStyles } from './styles';
import Spinner from '../spinner/spinner';
import { cn } from '../util';
import { Link } from 'react-router-dom';
import { CmdTooltip } from '../tooltip';
type BaseButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'>;
type VariantButtonProps = VariantProps<typeof buttonStyles>;

export type ButtonProps = BaseButtonProps &
  VariantButtonProps & {
    asChild?: boolean;
    className?: string;
    icon?: React.ReactElement;
    to?: string;
    href?: string;
    tooltip?: string;
    target?: React.HTMLAttributeAnchorTarget;
  };

const CmdButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className: customClassName = '',
    variant,
    size,
    modifier,
    disabled = false,
    fullWidth = false,
    loading = false,
    asChild = false,
    icon,
    to,
    href,
    type = 'button',
    tooltip,
    target,
    onClick,
    ...otherProps
  } = props;

  const Comp = asChild ? Slot : 'button';
  const className = cn(
    buttonStyles({
      variant,
      size,
      modifier,
      fullWidth,
      disabled,
      loading,
      iconOnly: !!icon && variant === 'link' && !props.children,
    }),
    customClassName,
  );

  // Abstract away the button content so we can support React Router Links and External Links
  const buttonContent = (
    <>
      {!loading && <>{icon}</>}
      {loading && <Spinner variant={variant} size={size} className={customClassName} />}
      {props.children}
    </>
  );

  const ConditionalTooltip: React.FC = ({ children }) =>
    tooltip ? (
      <CmdTooltip.Provider>
        <CmdTooltip.Root>
          <CmdTooltip.Trigger asChild>{children}</CmdTooltip.Trigger>
          <CmdTooltip.Content>{tooltip}</CmdTooltip.Content>
        </CmdTooltip.Root>
      </CmdTooltip.Provider>
    ) : (
      <>{children}</>
    );

  // Support React Router Links
  if (to) {
    return (
      <ConditionalTooltip>
        <Link to={to} target={target} className={className}>
          {buttonContent}
        </Link>
      </ConditionalTooltip>
    );
  }

  // Support External Links
  if (href) {
    return (
      <ConditionalTooltip>
        <a
          href={href}
          target={target}
          className={cn(buttonStyles({ variant, size, modifier, fullWidth, disabled, loading }), customClassName)}
        >
          {buttonContent}
        </a>
      </ConditionalTooltip>
    );
  }

  // Returns a regular button
  return (
    <ConditionalTooltip>
      <Comp
        type={type}
        disabled={!!disabled || !!loading}
        className={className}
        ref={!href && !to ? ref : undefined}
        onClick={disabled || loading ? undefined : onClick}
        {...otherProps}
      >
        {buttonContent}
      </Comp>
    </ConditionalTooltip>
  );
});

CmdButton.displayName = 'CmdButton';

export { CmdButton, buttonStyles };
