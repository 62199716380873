import { GenericStep, IStepSelectionParameter, StepType, initStep } from './Step';
import { IStepArgumentType } from '@commandbar/internal/middleware/types';

export interface SelectStep extends GenericStep<StepType.Select> {
  argument: IStepArgumentType;
  selected: IStepSelectionParameter | null;
}

export const initSelectStep = (argument: IStepArgumentType): SelectStep => {
  const s = initStep(StepType.Select) as SelectStep;
  s.argument = argument;
  s.selected = null;
  return s;
};
