import { ICommandType } from '@commandbar/internal/middleware/types';
import { RecordOption, CommandOption } from '.';
import { CBStore } from 'shared/store/global-store';
import { initParameterOption, ParameterOption } from './ParameterOption';

export interface UnfurledCommandOption extends ParameterOption {
  resource: RecordOption;
  command: ICommandType;
  unfurled: true;
}

export const initUnfurledCommandOption = (
  _: CBStore,
  command: ICommandType,
  parent: CommandOption,
  resource: RecordOption,
): UnfurledCommandOption => {
  const o = initParameterOption(_, parent.label, resource.parameter, resource.searchOptions) as UnfurledCommandOption;
  o.resource = resource;
  o.command = command;
  o.unfurled = true;
  o.label = `${command.text} > ${resource.label}`;
  return o;
};
