import * as t from 'io-ts';
import {
  createObject,
  decodeThrowing,
  decodeToPromise,
  deleteObject,
  listObject,
  readObject,
  updateObject,
} from './generics';

import { ActionV } from '@cb/types/entities/command/actions';
import { AudienceV } from './helpers/audience';
import {
  CommandGoal,
  ConditionsMetGoal,
  CTAClickedGoal,
  ElementClickedGoal,
  PageVisitedGoal,
  EventTrackedGoal,
} from './helpers/goals';
import { PushTriggerV } from './helpers/pushTrigger';

import { RuleExpressionV } from './helpers/rules';
import * as axiosInstance from './network';

const ChecklistItemBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      title: t.string,
      goal: t.union([
        CommandGoal,
        PageVisitedGoal,
        ElementClickedGoal,
        ConditionsMetGoal,
        CTAClickedGoal,
        EventTrackedGoal,
      ]),
      action: ActionV,
      celebrate: t.boolean,
    }),
    t.partial({
      description: t.string,
      cta: t.string,
      celebration: t.union([t.literal('confetti'), t.literal('coin')]),
    }),
  ],
  'ChecklistItemBase',
);
type IChecklistItemBase = t.TypeOf<typeof ChecklistItemBaseV>;

const ChecklistItemAdditionalV = t.type(
  {
    skippable: t.boolean,
  },
  'ChecklistItemAdditional',
);
type IChecklistItemAdditional = t.TypeOf<typeof ChecklistItemAdditionalV>;

export const ChecklistItemV = t.intersection([ChecklistItemAdditionalV, ChecklistItemBaseV], 'ChecklistItem');
type IChecklistItem = t.TypeOf<typeof ChecklistItemV>;

export const ChecklistBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      title: t.string,
      show_expression: RuleExpressionV,
      trigger: PushTriggerV,
      items: t.array(ChecklistItemV),
    }),
    t.partial({
      description: t.string,
      is_live: t.boolean,
      audience: t.union([AudienceV, t.null]),
    }),
  ],
  'ChecklistBase',
);

const ChecklistAdditionalV = t.type(
  {
    celebrate: t.boolean,
    template_source: t.string,
    position: t.union([t.literal('bottomRight'), t.literal('bottomLeft')]),
    skippable: t.boolean,
    dismissible: t.boolean,
    open_by_default: t.boolean,
    share_page_url_or_path: t.string,
    show_in_spotlight_search: t.boolean,
    show_in_helphub_search: t.boolean,
    copilot_suggest: t.boolean,
    copilot_cta_label: t.string,
    copilot_description: t.string,
    editor_tags: t.array(t.string),
    custom_theme: t.union([t.string, t.null, t.undefined]),
  },
  'ChecklistAdditional',
);

const defaults: t.TypeOf<typeof ChecklistAdditionalV> = {
  celebrate: false,
  position: 'bottomRight',
  open_by_default: true,
  skippable: true,
  dismissible: false,
  template_source: 'none',
  share_page_url_or_path: '',
  show_in_spotlight_search: false,
  show_in_helphub_search: false,
  copilot_suggest: false,
  copilot_cta_label: '',
  copilot_description: '',
  editor_tags: [],
  custom_theme: null,
};

export const ChecklistV = t.intersection([ChecklistBaseV, ChecklistAdditionalV], 'Checklist');

const itemDefaults: IChecklistItemAdditional = {
  skippable: false,
};

export const ResetResponse = t.type({
  did_reset: t.boolean,
});

export class Checklist {
  public static decode = (data: any) => {
    const items = data.items.map(
      (item: IChecklistItemBase & Partial<IChecklistItemAdditional>): IChecklistItem => ({
        ...itemDefaults,
        ...item,
      }),
    );
    return decodeThrowing(ChecklistV, { ...defaults, ...data, items });
  };

  public static create = createObject(ChecklistV, ChecklistV, 'checklists');
  public static update = updateObject(ChecklistV, ChecklistV, 'checklists');
  public static delete = deleteObject(ChecklistV, 'checklists');
  public static list = listObject(ChecklistV, 'checklists');
  public static read = readObject(ChecklistV, 'checklists');

  public static resetInteractions = async (endUserSlug: string, checklistId: number) => {
    const result = await axiosInstance.patch('checklists/reset_for_end_user/', {
      end_user_slug: endUserSlug,
      checklist_id: checklistId,
    });

    return await decodeToPromise(t.exact(ResetResponse), result.data);
  };
}
