import React from 'react';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as searchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { Pin01, FaceSmile, Stars02, Map01 } from '@commandbar/design-system/icons/react';
import {
  DashboardCardBase,
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
} from '@commandbar/design-system/components/antd';

export const ProductTourCard = () => {
  return (
    <DashboardCardBase
      styles={{
        iconShadow:
          '0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(69, 120, 251, 0.16), inset 1.5px -1.5px 3px rgba(69, 120, 251, 0.25);',
        background: 'linear-gradient(99.43deg, #E9EFFF 6.02%, #FFFFFF 26.86%)',
      }}
      icon={<Map01 width={DASHBOARD_ICON_WIDTH} color="#4578FB" />}
      title="Create a Tour"
      description="Welcome users with targeted tours"
      actions={[
        {
          icon: <Pin01 width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: 'Tour',
          to: `/editor${editorRoutes.PRODUCT_TOURS_ROUTE}?${searchParams.NUDGE_TOUR_TEMPLATE_PARAM}`,
        },
        {
          icon: <FaceSmile width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: 'Announcement',
          to: `/editor${editorRoutes.ANNOUNCEMENTS_ROUTE}?${searchParams.NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM}`,
        },
        {
          icon: <Stars02 width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: 'Highlight',
          to: `/editor${editorRoutes.PRODUCT_TOURS_ROUTE}?${searchParams.NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM}`,
        },
      ]}
    />
  );
};
