/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useMemo } from 'react';

import 'rc-drawer/assets/index.css';

import Editor from './editor/Editor';

import useEditor from './editor/useEditor';
import useHotkey from '@commandbar/internal/util/useHotkey';
import Sender from './management/Sender';

import { Modal } from '@commandbar/design-system/components/antd';

import type { IUserType } from '@commandbar/internal/middleware/types';
import { AlertCircle } from '@commandbar/design-system/icons/react';
import { AppStateContext, useAppContext } from './AppStateContext';
import { LoadingLogo } from '@commandbar/internal/client/LoadingLogo';

/*******************************************************************************/
/* Render
/*******************************************************************************/

export const routerGetUserConfirmation = (_message: string, callback: (ok: boolean) => void) => {
  Modal.confirm({
    title: 'You have unsaved changes.',
    icon: <AlertCircle height={24} width={24} className="anticon anticon-exclamation-circle" />,
    content: 'Leaving will discard these changes.',
    onOk() {
      callback(true);
    },
    onCancel() {
      callback(false);
    },
    okText: 'Discard changes',
    cancelText: 'Keep editing',
  });
};

const AppStateContextProvider = ({ user }: { user: IUserType }) => {
  const editorState = useEditor(user);

  const dispatch = editorState ? editorState.dispatch : null,
    state = editorState ? editorState.state : null;

  const providerValue = useMemo(
    () =>
      dispatch && state
        ? {
            ...state,
            dispatch,
            isStudio: false,
            commandBarReady: true,
          }
        : null,
    [dispatch, state],
  );

  if (!providerValue) {
    return <LoadingLogo />;
  }

  return (
    <AppStateContext.Provider value={providerValue}>
      <InAppWidget />
    </AppStateContext.Provider>
  );
};

const InAppWidget = () => {
  const { isStudio } = useAppContext();
  useHotkey('k', Sender.onCMDK);

  return (
    <div
      className="commandbar-editor-root"
      style={{ padding: 0, height: '100%', backgroundColor: 'white', border: '0px solid blue' }}
    >
      <div
        style={{
          backgroundColor: 'rgb(242, 242, 242)',
          borderRadius: isStudio ? 0 : 12,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Editor />
      </div>
    </div>
  );
};

export default AppStateContextProvider;
