import styled from '@emotion/styled';

import Auth from '@commandbar/internal/client/authentication';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { reportErrorToUser } from 'editor/src/editor/utils/ErrorReporting';
import { cmdToast } from '@commandbar/design-system/cmd';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #ffffff;

  background: linear-gradient(
    108.44deg,
    rgba(233, 220, 255, 0.57) 4.38%,
    #ffffff 57.09%,
    #ffeedb 85.67%,
    #ffffff 94.06%
  );
`;

const Hero = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(https://staticassets.commandbar.com/site-assets/verify-sent/grid.png),
    url(https://staticassets.commandbar.com/site-assets/verify-sent/dot-background-left.png),
    url(https://staticassets.commandbar.com/site-assets/verify-sent/dot-background-right.png);
  background-position: bottom 4% left 24%, left, right;
  background-repeat: no-repeat;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #b4b4b4;
  background: #ffffff;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  z-index: 1;
`;

const LogoContainer = styled.div`
  display: flex;
  padding: 48px;
  border-right: 1px solid rgba(180, 180, 180, 0.15);
`;

const Logo = styled.div`
  position: relative;
  display: flex;
  padding: 16px;
  isolation: isolate;
  width: 72px;
  height: 72px;
  background: linear-gradient(137.59deg, #ffffff 8.59%, #fdfeff 55.21%, #f3f9ff 68.71%, #fdfeff 86.49%);
  border: 0.5px solid #cfb5e3;
  box-shadow: 0px 1px 2px rgba(97, 66, 148, 0.37), 0px 0px 44px rgba(253, 205, 36, 0.51);
  border-radius: 8px;
`;

const CopyContainer = styled.div`
  display: flex;
  padding: 48px 40px;
`;

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckEmail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.h1`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

const Subtitle = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
`;

const EmailLogo = () => (
  <>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.12"
        d="M17.0933 22.2772L3.33325 13.3331L17.0933 4.3891C18.1454 3.70525 18.6714 3.36332 19.2382 3.23029C19.7392 3.11269 20.2606 3.11269 20.7616 3.23029C21.3284 3.36332 21.8545 3.70525 22.9065 4.3891L36.6666 13.3331L22.9065 22.2772C21.8545 22.961 21.3284 23.3029 20.7616 23.436C20.2606 23.5536 19.7392 23.5536 19.2382 23.436C18.6714 23.3029 18.1454 22.961 17.0933 22.2772Z"
        fill="#8C4BF5"
      />
      <path
        d="M22.9065 4.3891L35.4532 12.5444C35.8966 12.8326 36.1182 12.9767 36.2788 13.1689C36.421 13.339 36.5278 13.5358 36.593 13.7477C36.6666 13.9871 36.6666 14.2515 36.6666 14.7803V26.9998C36.6666 29.8001 36.6666 31.2002 36.1216 32.2698C35.6423 33.2106 34.8773 33.9755 33.9365 34.4548C32.867 34.9998 31.4668 34.9998 28.6666 34.9998H11.3333C8.53299 34.9998 7.13286 34.9998 6.0633 34.4548C5.12249 33.9755 4.35759 33.2106 3.87822 32.2698C3.33325 31.2002 3.33325 29.8001 3.33325 26.9998V14.7803C3.33325 14.2515 3.33325 13.9871 3.40687 13.7477C3.47204 13.5358 3.57883 13.339 3.721 13.1689C3.8816 12.9767 4.10327 12.8326 4.54662 12.5444L17.0933 4.3891M22.9065 4.3891C21.8545 3.70525 21.3284 3.36332 20.7616 3.23029C20.2606 3.11269 19.7392 3.11269 19.2382 3.23029C18.6714 3.36332 18.1454 3.70525 17.0933 4.3891M22.9065 4.3891L33.2268 11.0973C34.3732 11.8425 34.9464 12.215 35.145 12.6875C35.3185 13.1005 35.3185 13.5658 35.145 13.9787C34.9464 14.4512 34.3732 14.8238 33.2268 15.569L22.9065 22.2772C21.8545 22.961 21.3284 23.3029 20.7616 23.436C20.2606 23.5536 19.7392 23.5536 19.2382 23.436C18.6714 23.3029 18.1454 22.961 17.0933 22.2772L6.77302 15.569C5.62661 14.8238 5.0534 14.4512 4.85488 13.9787C4.68138 13.5658 4.68138 13.1005 4.85488 12.6875C5.0534 12.215 5.62661 11.8425 6.77302 11.0973L17.0933 4.3891M35.8333 31.6665L24.7619 21.6665M15.238 21.6665L4.16659 31.6665"
        stroke="#361271"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    <svg
      style={{
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '40px',
        top: '37px',
      }}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_26_98)">
        <rect x="4" y="4" width="20" height="20" rx="10" fill="url(#paint0_linear_26_98)" shapeRendering="crispEdges" />
        <g clip-path="url(#clip0_26_98)">
          <path
            d="M13.0001 14.5C13.2148 14.787 13.4888 15.0246 13.8034 15.1965C14.118 15.3683 14.4659 15.4705 14.8234 15.4961C15.181 15.5218 15.5399 15.4702 15.8758 15.3449C16.2117 15.2196 16.5167 15.0235 16.7701 14.77L18.2701 13.27C18.7255 12.7985 18.9775 12.167 18.9718 11.5115C18.9661 10.856 18.7032 10.229 18.2396 9.76544C17.7761 9.30192 17.1491 9.03899 16.4936 9.0333C15.8381 9.0276 15.2066 9.27959 14.7351 9.73498L13.8751 10.59M15.0001 13.5C14.7854 13.2129 14.5114 12.9754 14.1968 12.8035C13.8822 12.6316 13.5343 12.5294 13.1768 12.5038C12.8192 12.4782 12.4603 12.5298 12.1244 12.6551C11.7885 12.7804 11.4835 12.9764 11.2301 13.23L9.7301 14.73C9.2747 15.2015 9.02272 15.833 9.02841 16.4885C9.03411 17.144 9.29703 17.771 9.76055 18.2345C10.2241 18.698 10.8511 18.961 11.5066 18.9667C12.1621 18.9724 12.7936 18.7204 13.2651 18.265L14.1201 17.41"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_26_98"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_26_98" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_26_98" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_26_98" x1="8.5" y1="7" x2="19.5" y2="21" gradientUnits="userSpaceOnUse">
          <stop stop-color="#8C4BF5" />
          <stop offset="0.596761" stopColor="#B285FA" />
          <stop offset="0.757785" stopColor="#985EF7" />
        </linearGradient>
        <clipPath id="clip0_26_98">
          <rect width="12" height="12" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  </>
);

interface VerifyEmailSentProps {
  email?: string;
}

const VerifyEmailSent = ({ email }: VerifyEmailSentProps) => {
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  email = email || params.get('email') || undefined;

  useEffect(() => {
    if (!email) {
      history.replace('/verify');
    }
  }, []);

  const resend = () => {
    if (email) {
      Auth.sendVerificationEmail(email)
        .then(() => {
          cmdToast.success(`Verification email successfully sent to ${email}`);
        })
        .catch((err) => {
          reportErrorToUser(err);
        });
    } else {
      history.replace('/verify');
    }
  };

  return (
    <Container>
      <Hero>
        <ContentBox>
          <LogoContainer>
            <Logo>
              <EmailLogo />
            </Logo>
          </LogoContainer>

          <CopyContainer>
            <Copy>
              <>
                <CheckEmail>
                  <Title>Check your email</Title>
                  <Subtitle>We've sent you a link to login</Subtitle>
                </CheckEmail>

                <div>
                  <span>Didn't receive the email? </span>
                  <button
                    onClick={resend}
                    style={{
                      all: 'unset',
                      color: CB_COLORS.primary,
                      fontWeight: 600,
                      cursor: 'pointer',
                    }}
                  >
                    Resend
                  </button>
                </div>
              </>
            </Copy>
          </CopyContainer>
        </ContentBox>
      </Hero>
    </Container>
  );
};

export default VerifyEmailSent;
