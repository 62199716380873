import React, { type HTMLProps } from 'react';
import styled from '@emotion/styled';

const NudgeActionBar = styled.div<{ isBanner: boolean; stepCount: boolean }>`
  display: flex;
  align-items: center;
  padding: 0px var(--layout-padding) var(--layout-padding);
  gap: var(--layout-gap);

  padding: ${({ stepCount, isBanner }) =>
    stepCount || isBanner ? 0 : '0px var(--layout-padding) var(--layout-padding)'};
  width: ${({ stepCount, isBanner }) => (stepCount || isBanner ? 'auto' : '100%')};

  ${({ isBanner }) =>
    isBanner &&
    `
    @media (max-width: 375px) {
      padding: 0px;
      width: 100%;
    }
  `}
`;

const StyledNudgeActionBar = ({
  children,
  isBanner,
  stepCount,
  ...props
}: {
  children: React.ReactNode;
  isBanner?: boolean | undefined;
  stepCount?: string | undefined;
} & HTMLProps<HTMLDivElement>) => (
  <NudgeActionBar isBanner={!!isBanner} {...props} stepCount={!!stepCount}>
    {children}
  </NudgeActionBar>
);

export default StyledNudgeActionBar;
