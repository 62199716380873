/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useCallback } from 'react';

import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import Checkbox from './Checkbox';
import Radio from './Radio';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledNudgeCheckboxOption from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeCheckboxOption';
import StyledNudgeRadioOption from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRadioOption';
import StyledFormFieldBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledFormFieldBlock';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useSurveyResponse } from '../SurveyResponseProvider';

const ListBlock = ({ isMultiSelect, options }: { isMultiSelect: boolean; options: string[] }) => {
  const { theme } = useTheme();

  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const values =
    surveyResponse && Array.isArray(surveyResponse.value)
      ? surveyResponse.value
      : surveyResponse && surveyResponse.value
      ? [surveyResponse.value as string]
      : [];

  const handleClick = useCallback(
    (value) => {
      if (isMultiSelect) {
        const alreadySelected = values.includes(value);
        const updatedValues = alreadySelected ? values.filter((item) => item !== value) : [...values, value];

        setSurveyResponse(updatedValues.length > 0 ? { type: 'string', value: updatedValues } : undefined);
      } else {
        setSurveyResponse({ type: 'string', value });
      }
    },
    [isMultiSelect, values, setSurveyResponse],
  );

  return _.flags?.['release-themes-v2'] ? (
    <StyledFormFieldBlock>
      {isMultiSelect
        ? options.map((option) => (
            <StyledNudgeCheckboxOption
              themeV2={themeV2}
              key={option}
              selected={values.includes(option)}
              onClick={() => handleClick(option)}
            >
              {option}
            </StyledNudgeCheckboxOption>
          ))
        : options.map((option) => (
            <StyledNudgeRadioOption
              themeV2={themeV2}
              key={option}
              selected={values.includes(option)}
              onClick={() => handleClick(option)}
            >
              {option}
            </StyledNudgeRadioOption>
          ))}
    </StyledFormFieldBlock>
  ) : (
    <ul
      style={{
        maxHeight: '240px',
        overflowY: 'auto',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
        padding: '0',
        margin: '0',
      }}
    >
      {options.map((option) => (
        <li key={option}>
          <button
            role={isMultiSelect ? 'checkbox' : 'radio'}
            aria-checked={values.includes(option)}
            style={{
              textAlign: 'left',
              display: 'flex',
              width: '100%',
              minHeight: '40px',
              height: 'auto',
              padding: '8px 16px',

              alignItems: 'center',
              gap: '8px',
              fontWeight: '600',
              fontSize: '16px',
              borderRadius: '4px',
              border: values.includes(option) ? theme.nudgeOptionList.selectionBorder : theme.nudgeOptionList.border,
              color: values.includes(option) ? theme.nudgeOptionList.selectionColor : theme.nudgeOptionList.color,
              background: values.includes(option)
                ? theme.nudgeOptionList.selectionBackground
                : theme.nudgeOptionList.background,
              cursor: 'pointer',
              boxShadow: values.includes(option)
                ? theme.nudgeOptionList.selectionBoxShadow
                : theme.nudgeOptionList.boxShadow,
            }}
            onClick={() => handleClick(option)}
          >
            {isMultiSelect ? (
              <Checkbox checked={values.includes(option)} />
            ) : (
              <Radio checked={values.includes(option)} />
            )}
            {option}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ListBlock;
