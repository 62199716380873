/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Check } from '@commandbar/design-system/icons/react';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';

const checkboxStyle = css`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:checked + span {
    border: 1px solid #000;
    background-color: #fff;
  }

  &:checked + span::after {
    content: '';
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #000;
  }
`;

const Checkbox = ({ checked }: { checked: boolean }) => {
  const { theme }: { theme: ITheme } = useTheme();

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <input type="checkbox" css={checkboxStyle} checked={checked} readOnly />
      <span
        style={{
          position: 'relative',
          width: '16px',
          height: '16px',
          backgroundColor: theme.nudgeOptionListCheckbox.backgroundColor,
          border: checked ? theme.nudgeOptionListCheckbox.borderSelected : theme.nudgeOptionListCheckbox.border,
          borderRadius: '6px',
          display: 'flex',
        }}
      >
        {checked && <Check color={theme.nudgeOptionListCheckbox.check} />}
      </span>
    </div>
  );
};

export default Checkbox;
