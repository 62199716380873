import React from 'react';
import { CmdButton } from '@commandbar/design-system/cmd';

type AsyncClickButtonProps = Omit<React.ComponentProps<typeof CmdButton>, 'onClick'> & {
  onClick: () => Promise<void>;
  onError?: (e: any) => void;
};

/*
 * A Button that displays a loading spinner while the async onClick function is running
 */
export const AsyncClickButton = (props: AsyncClickButtonProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

  const unmounted = React.useRef(false);
  React.useEffect(() => {
    return () => {
      unmounted.current = true;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const onClick = async () => {
    // Set a timeout to update the loading state after 250 milliseconds
    const id = setTimeout(() => {
      if (!unmounted.current) setLoading(true);
    }, 250);
    setTimeoutId(id);

    try {
      await props.onClick();
    } catch (e) {
      if (props.onError) props.onError(e);
      else throw e;
    } finally {
      if (timeoutId) clearTimeout(timeoutId);
      if (!unmounted.current) setLoading(false);
    }
  };

  return <CmdButton {...props} onClick={onClick} loading={loading} />;
};
