import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CmdButton, CmdInput, CmdLabel, CmdTypography, cmdToast } from '@commandbar/design-system/cmd';
import { Form, Layout, Page as StyledPage, Typography } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import Spinner from 'commandbar.com/src/components/table/Spinner';
import { useAppContext } from 'editor/src/AppStateContext';
import { DocumentationLink } from '../thin-analytics/styles';

const Body = () => {
  const history = useHistory();
  const { organization } = useAppContext();
  const [integrations, setIntegrations] = useState<IOrganizationType['integrations']>();
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  useEffect(() => {
    setIsLoadingForm(true);
    Organization.readInternal(organization.id.toString())
      .then((org) => {
        setIntegrations(org.integrations);
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  }, [organization.id]);

  const handleFinish = useCallback(
    async ({ conversation_property_name }: { conversation_property_name: string }) => {
      try {
        await Organization.updateInternal({
          id: organization.id.toString(),
          integrations: {
            ...integrations,
            freshchat: {
              ...integrations?.freshchat,
              conversation_property_name,
            },
          },
        });
        cmdToast.success('Successfully enabled Freshchat!');
        history.push('/integrations');
      } catch (err) {
        Logger.error(err);
        cmdToast.error('Something went wrong');
      }
    },
    [history.push, integrations, organization.id],
  );

  if (isLoadingForm) {
    return (
      <Spinner>
        <Loading01 />
      </Spinner>
    );
  }

  return (
    <>
      <Typography.Title level={4}>Enable Freshchat Handoff</Typography.Title>

      <Form
        name="config"
        onFinish={handleFinish}
        autoComplete="off"
        initialValues={{ conversation_property_name: integrations?.freshchat?.conversation_property_name }}
        layout="vertical"
        style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
      >
        <Form.Item
          label={<CmdLabel>Conversation Property Name</CmdLabel>}
          name="conversation_property_name"
          help={
            <CmdTypography.HelpText>
              Check out{' '}
              <DocumentationLink
                href="https://www.commandbar.com/docs/integrations/cs-support/freshchat-integration/#configuring-the-freshchat-handoff-integration-in-freshchat"
                target="_blank"
                rel="noreferrer"
              >
                our docs
              </DocumentationLink>{' '}
              for instructions on how to use this within Freshchat.
            </CmdTypography.HelpText>
          }
        >
          <CmdInput fullWidth required />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CmdButton to="/integrations">Go Back</CmdButton>
          <Form.Item>
            <CmdButton variant="primary" type="submit">
              Done
            </CmdButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

const Page = () => (
  <StyledPage section="Integrations" title="Freshchat" description="Handoff Copilot chats to Freshchat agents.">
    <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Body />
        </div>
      </div>
    </Layout.Content>
  </StyledPage>
);

export default Page;
