import React from 'react';
import styled from '@emotion/styled';
import useResizeObserver from 'use-resize-observer';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { CB_COLORS } from './colors';

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-shrink: 0;
  background-color: #ffffff;
  border: 1px solid #cecece;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 260px;
  width: 38.25%;
  background-color: ${CB_COLORS.neutral100};
`;

const Image = styled.img`
  height: auto;
  width: 100%;
`;

const InfoContainer = styled.div<{ parentWidth: number; hasImg: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid #cecece;

  padding: ${(props) => (props.parentWidth > 585 ? '25px 16px' : props.hasImg ? '11px 8px' : '12px 16px')};
  box-sizing: border-box;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  stroke: ${CB_COLORS.neutral600};
  align-self: flex-end;
  cursor: pointer;
`;

const Title = styled.h1`
  vertical-align: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: ${CB_COLORS.neutral1000};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  color: ${CB_COLORS.neutral700};
  line-height: 17px;
  font-weight: 500;
  font-size: 14px;

  a {
    color: ${CB_COLORS.neutral700};
  }
`;

interface IFeatureGraphicAlert {
  /**
   * Only supports images at the moment
   */
  img?: { src: string; alt: string };
  title: React.ReactNode;
  children: React.ReactNode;
  docsLink?: string;
  identifier: string;
}

const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L11 1M1 1L11 11" stroke="#7A7A85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const FeatureAnnouncementCard = ({ img, title, children, docsLink, identifier }: IFeatureGraphicAlert) => {
  const LOCALSTORAGE_KEY = `feature-announcement-${identifier}}`;
  const [visible, setVisible] = React.useState<boolean>(() => !LocalStorage.get(LOCALSTORAGE_KEY, false));
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const onClose = () => {
    setVisible(false);
    LocalStorage.set(LOCALSTORAGE_KEY, true);
  };

  if (!visible) return null;
  return (
    <Container ref={ref}>
      {img && (
        <ImageContainer>
          <Image src={img.src} alt={img.alt} />
        </ImageContainer>
      )}
      <InfoContainer parentWidth={width} hasImg={!!img}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Title>{title}</Title>
        <Description>
          {children}
          {docsLink && (
            <a
              href={docsLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: '8px 0', textDecoration: 'underline' }}
            >
              Learn more
            </a>
          )}
        </Description>
      </InfoContainer>
    </Container>
  );
};

export default FeatureAnnouncementCard;
