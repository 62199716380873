import { useCallback, useEffect, useState } from 'react';

import {
  AnalyticsSpotlight,
  AnalyticsSpotlightData,
  SpotlightDownloadTypes,
} from '@commandbar/internal/middleware/analytics/spotlight';

import * as Sentry from '@sentry/react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { useCSVDownload } from '../../useCSVDownload';
import { getSpotlightDemoData } from '../../analyticDemoData';

const useAnalyticSpotlight = () => {
  const [data, setData] = useState<AnalyticsSpotlightData>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const [downloadType, setDownloadType] = useState<SpotlightDownloadTypes | undefined>();

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: `helphub-${downloadType}`,
    download: () =>
      AnalyticsSpotlight.download(downloadType || 'pages', {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
      }),
  });

  const handleDownloadCsv = useCallback((type: SpotlightDownloadTypes) => {
    setDownloadType(type);
  }, []);

  useEffect(() => {
    if (downloadType) {
      downloadCsv();
    }
  }, [downloadType]);

  const fetchSpotlightData = () => {
    setIsLoading(true);

    AnalyticsSpotlight.read({ ...timeFilterRange, include_admin_data: includeAdminData.toString() })
      .then((res) => setData(res))
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      setData(getSpotlightDemoData(timeFilterRange));
      setIsLoading(false);
    } else {
      fetchSpotlightData();
    }
  }, [timeFilterRange, isDemoData, includeAdminData]);

  return {
    isLoading,
    data,
    fetchSpotlightData,
    error,
    downloadCsv,
    isDownloading,
    handleDownloadCsv,
  };
};

export default useAnalyticSpotlight;
