import React from 'react';

import { CmdTooltip } from '@commandbar/design-system/cmd';
import { InputContainer, Select, StyledLabel } from '@commandbar/design-system/components/antd';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { StyledSelect } from '../../styled';
import { TooltippedLabel } from '../styled';

import { ReactComponent as CaretDown } from '../../../../img/caret_down.svg';

interface LimitEditorProps {
  triggerType: INudgeType['trigger']['type'];
  frequencyLimit: INudgeType['frequency_limit'];
  onFrequencyLimitChange: (limit: INudgeType['frequency_limit']) => void;
}

export const LimitEditor = ({ triggerType, frequencyLimit, onFrequencyLimitChange }: LimitEditorProps) => (
  <InputContainer>
    <TooltippedLabel>
      <StyledLabel>Limit</StyledLabel>

      {triggerType === 'scheduled' && frequencyLimit === 'until_interaction' && (
        <CmdTooltip.Provider>
          <CmdTooltip.Root>
            <CmdTooltip.Trigger style={{ padding: 0 }}>
              <div style={{ display: 'flex' }}>
                <InfoCircle color="#797C85" />
              </div>
            </CmdTooltip.Trigger>

            <CmdTooltip.Content>
              Continue to trigger this nudge until a user completes or dismisses it
            </CmdTooltip.Content>
          </CmdTooltip.Root>
        </CmdTooltip.Provider>
      )}
    </TooltippedLabel>

    <StyledSelect
      value={frequencyLimit}
      style={{ width: '100%' }}
      onChange={(e) => {
        onFrequencyLimitChange(e);
      }}
      suffixIcon={<CaretDown />}
    >
      <Select.Option value="once_per_user" disabled={triggerType === 'scheduled'}>
        Once per user
      </Select.Option>
      <Select.Option value="once_per_session" disabled={triggerType === 'scheduled'}>
        Once per session
      </Select.Option>
      {triggerType !== 'when_element_appears' && <Select.Option value="no_limit">No limit</Select.Option>}
      <Select.Option value="until_interaction">Until completed or dismissed</Select.Option>
    </StyledSelect>
  </InputContainer>
);
