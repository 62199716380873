import React from 'react';
import useResizeObserver from 'use-resize-observer';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { XClose } from '@commandbar/design-system/icons/react';
import { cva, type VariantProps } from 'class-variance-authority';

// Define styles using CVA
const cardStyles = cva(
  'w-full flex flex-shrink-0 border border-gray400 shadow-md rounded-md relative overflow-hidden',
  {
    variants: {
      variant: {
        default: ['bg-gray100', 'text-gray600'],
        blue: ['bg-blueGradient', 'text-gray100'],
      },
      isTitle: {
        true: 'mb-2 text-gray1000',
      },
      isChildren: {
        true: 'flex flex-col text-gray700',
      },
    },
    compoundVariants: [
      {
        isTitle: true,
        variant: 'blue',
        class: 'text-white shadow-none bg-none',
      },
      {
        isChildren: true,
        variant: 'blue',
        class: 'text-white shadow-none bg-none',
      },
    ],
    defaultVariants: {
      variant: 'default',
    },
  },
);

type CmdFeatureAnnouncementCardProps = VariantProps<typeof cardStyles> & {
  img?: { src: string; alt: string };
  title: React.ReactNode;
  children: React.ReactNode;
  docsLink?: string;
  identifier: string;
};

const CmdFeatureAnnouncementCard = ({
  img,
  title,
  children,
  docsLink,
  identifier,
  variant,
}: CmdFeatureAnnouncementCardProps) => {
  const LOCALSTORAGE_KEY = `feature-announcement-${identifier}}`;
  const [visible, setVisible] = React.useState<boolean>(() => !LocalStorage.get(LOCALSTORAGE_KEY, false));
  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>();

  const onClose = () => {
    setVisible(false);
    LocalStorage.set(LOCALSTORAGE_KEY, true);
  };

  const paddingValue = width > 585 ? 'p-lg' : img ? 'py-3 px-2' : 'py-3 px-4';

  if (!visible) return null;
  return (
    <div className={cardStyles({ variant })} ref={ref}>
      {img && (
        <div className="flex justify-center items-center max-w-[260px] w-[38.25%] bg-gray100">
          <img className="h-auto w-full" src={img.src} alt={img.alt} />
        </div>
      )}
      <div
        className={`${paddingValue} flex flex-col justify-center items-start border-l border-gray400 box-border flex-1`}
      >
        <div className="absolute top-4 right-4 cursor-pointer text-gray600" onClick={onClose}>
          <XClose />
        </div>
        <h1 className={cardStyles({ variant, isTitle: true })}>{title}</h1>
        <div className={cardStyles({ variant, isChildren: true })}>
          {children}
          {docsLink && (
            <a href={docsLink} target="_blank" rel="noopener noreferrer" className="py-2 underline">
              Learn more
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export { CmdFeatureAnnouncementCard };
