import { DATES_TO_SUGGEST, MONTHS, WEEKDAYS, WORDS_TO_AUTOCOMPLETE } from './constants';
import { getSuggestionsFromStringArray, parseDate, parseToSuggestionFormat } from './helpers';

export const completeSomeNumbers = (words: string[]) => {
  return words.map((item) => {
    if (item === '1s') {
      return '1st';
    }

    if (item === '2n') {
      return '2nd';
    }

    if (item === '3r') {
      return '3rd';
    }

    return item;
  });
};

export const completeMonth = (inputValue: string, formatId: number) => {
  const firstWord = inputValue.split(' ')[0];
  const secondWord = inputValue.split(' ')[1];

  if (secondWord !== '' && secondWord) {
    return parseToSuggestionFormat(parseDate(inputValue), formatId);
  }

  const datesSuggestions = DATES_TO_SUGGEST.map((date) => `${firstWord} ${date}`);
  return getSuggestionsFromStringArray(datesSuggestions, formatId);
};

export const capitalizeDay = (words: string[]) => {
  return words.map((word, i) => {
    if (!word) {
      return word;
    }

    if (word.length < 1) {
      return word;
    }

    // handles query "in a mon" and does not suggest "in a Monday"
    if (word.toLowerCase().startsWith('mon') && i === 2) {
      return word;
    }

    const day = WEEKDAYS.filter((day) => day.toLowerCase().startsWith(word.toLowerCase()))[0];
    return day || word;
  });
};

export const autoCompleteMonth = (words: string[]) => {
  return words.map((word, i) => {
    if (!word || word.length < 3) {
      return word;
    }

    // handles query "in a mon" and does not suggest "in a Monday"
    if (word.toLowerCase().startsWith('mon') && i === 2) {
      return word;
    }

    const month = MONTHS.filter((month) => month.toLowerCase().startsWith(word.toLowerCase()))[0];
    return month || word;
  });
};

export const autoCompleteWord = (words: string[]) => {
  return words.map((word) => {
    if (word.length < 2) {
      return word;
    }

    const autocompleted = WORDS_TO_AUTOCOMPLETE.find((completed) => completed.startsWith(word));

    return autocompleted || word;
  });
};

export const parseIfNumber = (words: string[]) => {
  return words.map((word) => {
    const index = word.indexOf('t');

    if (index === -1) {
      return word;
    }

    const number = word.slice(0, index);

    if (!number || isNaN(+number)) {
      return word;
    }

    return number;
  });
};
