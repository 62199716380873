import styled from '@emotion/styled';
import { Download04 } from '@commandbar/design-system/icons/react';
import { Link } from 'react-router-dom';
import { useAppContext } from 'editor/src/AppStateContext';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GradientLink = styled.div`
  background: linear-gradient(267.7deg, #7a4cf0 5.95%, #a064dd 14.19%, #6340ed 48.21%, #582ff1 95.66%);
  border: 1px solid #421fb5;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 2px 0px rgba(255, 255, 255, 0.09);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 40px;
  box-sizing: border-box;
  color: #fff;
  &:hover {
    background: linear-gradient(267.7deg, #7a4cf0 5.95%, #a064dd 14.19%, #6340ed 48.21%, #582ff1 95.66%);
    border: 1px solid #421fb5;
    color: #fff;
  }
`;

const Icon = styled(Download04)`
  margin-right: 4px;
  opacity: 0.5;
`;

const Text = styled.div`
  margin-left: 4px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.07);
`;
export const InstallLink = () => {
  const { organization } = useAppContext();

  if (!organization || !!organization?.installed_at) {
    return null;
  }
  return (
    <Container>
      <Link to="/installation-guide">
        <GradientLink>
          <Icon width={16} />
          <Text>Install to Publish</Text>
        </GradientLink>
      </Link>
    </Container>
  );
};
