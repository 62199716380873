import { CBEventProperties } from './schema';

const MAX_DEPTH = 3;
const MAX_ARRAY_LENGTH = 10;
const MAX_STRING_LENGTH = 100;
const MAX_OBJECT_KEYS = 50;

export const serializeObject = (obj: Record<string, any> | null | undefined): CBEventProperties | undefined => {
  return obj ? (_serialize(obj, 0) as CBEventProperties) : undefined;
};

const _serialize = (obj: any, depth: number): any => {
  if (depth > MAX_DEPTH) {
    return '...';
  }

  if (obj === null || obj === undefined) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.slice(0, MAX_ARRAY_LENGTH).map((item) => _serialize(item, depth + 1));
  }

  if (typeof obj === 'string') {
    return obj.slice(0, MAX_STRING_LENGTH);
  }
  if (typeof obj === 'object') {
    if (depth === MAX_DEPTH) {
      return '...';
    }

    const keys = Object.keys(obj).slice(0, MAX_OBJECT_KEYS);
    return keys.reduce((acc, key) => {
      acc[key] = _serialize(obj[key], depth + 1);
      return acc;
    }, {} as any);
  }

  return obj;
};
