import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--layout-gap);
`;

const StyledButtonGroup = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <Container {...props}>{children}</Container>
);

export default StyledButtonGroup;
