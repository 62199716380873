import * as t from 'io-ts';

import { DateFilter, TableDataV, StackedBarChartDataV, CardDataV, AdminFilter } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsSpotlightDataV = t.type({
  stacked_bar_chart_data: StackedBarChartDataV,
  page_clicks_table: TableDataV,
  action_clicks_table: TableDataV,
  opens_total: CardDataV,
  links_total: CardDataV,
  actions_total: CardDataV,
  copilot_queries_total: CardDataV,
  success_rate: CardDataV,
});

export type AnalyticsSpotlightData = t.TypeOf<typeof AnalyticsSpotlightDataV>;

export type AnalyticsSpotlightParams = DateFilter & AdminFilter;

export type SpotlightDownloadTypes = 'pages' | 'actions';

export class AnalyticsSpotlight {
  public static read = (params: AnalyticsSpotlightParams) =>
    read(AnalyticsSpotlightDataV, `analytics/v2/spotlight`)(params);
  public static download = async (table: SpotlightDownloadTypes, params: DateFilter): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/spotlight/export/${table}/${queryString}`);
  };
}
