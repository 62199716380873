import React from 'react';
import { CHECKLIST_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import * as searchParams from '@commandbar/internal/proxy-editor/questlist_search_params';
import { PlusCircle, CheckCircleBroken } from '@commandbar/design-system/icons/react';
import {
  DashboardCardBase,
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
} from '@commandbar/design-system/components/antd';

export const ChecklistCard = () => {
  return (
    <DashboardCardBase
      styles={{
        iconShadow:
          '0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(57, 174, 62, 0.16), inset 1.5px -1.5px 3px rgba(57, 174, 62, 0.25)',
        background: 'linear-gradient(99.43deg, #E7F5E8 6.02%, #FFFFFF 26.86%)',
      }}
      icon={<CheckCircleBroken width={DASHBOARD_ICON_WIDTH} color="#39AE3E" />}
      title="Create an Onboarding Checklist"
      description="Guide users to success with clear step-by-step instructions"
      actions={[
        {
          icon: <PlusCircle width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: 'Create a Checklist',
          to: `/editor${CHECKLIST_ROUTE}?${searchParams.QUESTLIST_EMPTY_TEMPLATE_PARAM}`,
        },
      ]}
    />
  );
};
