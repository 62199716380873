import { Alert as AntAlert } from 'antd';
import styled from '@emotion/styled';

const Alert: typeof AntAlert = styled(AntAlert)`
  &&& {
    padding: 16px;

    z-index: 2147483645;
    :not(.ant-alert-banner) {
      border-radius: 12px;
    }

    .ant-alert-icon {
      font-size: 15px;
    }

    .ant-alert-description {
      color: #3e4351;
    }

    .ant-alert {
      font-size: 14px !important;
    }

    .ant-alert-with-description .ant-alert-message {
      font-size: 16px !important;
      font-weight: 600;
    }

    .ant-alert-description > p {
      font-size: 16px;
      margin: 0px;
    }
  }
` as unknown as typeof AntAlert;

export default Alert;
