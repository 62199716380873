import * as t from 'io-ts';

import { listObject } from './generics';

export const EntityChangeV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      description: t.string,
    }),
    t.partial({ changed_by: t.string, created: t.string }),
  ],
  'EntityChange',
);

export const listEntityChanges = listObject(EntityChangeV, 'entity_changes');
