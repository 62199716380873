import React, { useEffect, useState } from 'react';
import { Alert, Spin, Input, Result, Form } from '@commandbar/design-system/components/antd';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useAlgoliaConfigContext } from './AlgoliaConfigProvider';
import { checkAreCredentialsValid, IAlgoliaConfig } from './utils';
import { CmdButton } from '@commandbar/design-system/cmd';

const { Item: FormItem, useForm } = Form;

interface ICredentialsStepProps {
  handleNext: Function;
  handlePrev: Function;
}

export const CredentialsStep = ({ handleNext }: ICredentialsStepProps) => {
  const [error, setError] = useState<string | null>(null);
  const { algoliaConfig, setAlgoliaConfig } = useAlgoliaConfigContext();
  const [cachedConfig, setCachedConfig] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const [form] = useForm();

  const onFinish = async (values: any) => {
    const [areCredentialsValid, error] = await checkAreCredentialsValid(values.applicationID, values.apiKey);

    if (!areCredentialsValid) {
      setError(error);
      return;
    }

    const { integrations } = await Organization.readInternal(algoliaConfig.orgID);
    await Organization.updateInternal({
      id: algoliaConfig.orgID,
      integrations: {
        ...integrations,
        algolia: {
          ...values,
        },
      },
    });
    setAlgoliaConfig((config: any) => ({
      ...config,
      ...values,
    }));
    handleNext();
  };

  useEffect(() => {
    Organization.readInternal(algoliaConfig.orgID).then(async ({ integrations }) => {
      const { algolia } = integrations;

      if (algolia && algolia.applicationID && algolia.apiKey) {
        const { applicationID, apiKey, indexes } = algolia;

        const areCredentialsValid = await checkAreCredentialsValid(applicationID as string, apiKey as string);

        if (areCredentialsValid) {
          setCachedConfig({
            applicationID,
            apiKey,
            indexes,
          });
        }
      }

      setLoading(false);
    });
  }, []);

  const handleUseCachedCredentials = (useExisting: boolean) => {
    if (useExisting) {
      setAlgoliaConfig((config: IAlgoliaConfig) => ({
        ...config,
        ...cachedConfig,
      }));

      handleNext();
      return;
    }

    setCachedConfig(null);
  };

  return (
    <div>
      <Spin spinning={loading} tip="Checking for previous configuration...">
        {cachedConfig ? (
          <Result
            title="We found previously configured Application ID and API Key"
            subTitle="Continue setup with existing credentials?"
            extra={[
              <CmdButton
                key="new"
                onClick={() => {
                  handleUseCachedCredentials(false);
                }}
              >
                Start over
              </CmdButton>,
              <CmdButton
                variant="primary"
                key="existing"
                onClick={() => {
                  handleUseCachedCredentials(true);
                }}
              >
                Use existing credentials
              </CmdButton>,
            ]}
          />
        ) : (
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 8 },
              md: { span: 6 },
            }}
            wrapperCol={{ span: 16 }}
            labelAlign="left"
            initialValues={{
              applicationID: algoliaConfig?.applicationID,
              apiKey: algoliaConfig?.apiKey,
            }}
          >
            {error && (
              <Alert
                style={{ margin: '20px 0' }}
                type="error"
                message={<span style={{ color: 'black' }}>{error}</span>}
              />
            )}
            <FormItem
              name="applicationID"
              label="Application ID"
              rules={[{ required: true, message: 'Application ID is required' }]}
            >
              <Input />
            </FormItem>
            <FormItem
              name="apiKey"
              label="API Key"
              rules={[{ required: true, message: 'API Key is required' }]}
              tooltip={
                <div>
                  Please provide an API Key with following ACL enabled: <code>search</code>, <code>browse</code>,{' '}
                  <code>listIndexes</code>. Click {` `}
                  <a
                    href="https://www.algolia.com/doc/guides/security/api-keys/"
                    style={{ color: '#c0c0c0', fontWeight: 'bold' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  <div>to learn more</div>
                </div>
              }
            >
              <Input />
            </FormItem>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
              <CmdButton variant="primary" type="submit">
                Next
              </CmdButton>
            </div>
          </Form>
        )}
      </Spin>
    </div>
  );
};
