import { CmdCard, CmdEmpty, CmdTypography } from '@commandbar/design-system/cmd';
import { ArrowRight } from '@commandbar/design-system/icons/react';
import { Link } from 'react-router-dom';
import { formatMetric } from '../utils';
import PercentChangeTag from './PercentChangeTag';

export type LinkStatCardProps = {
  title: string;
  tooltip?: string;
  metric?: number | null;
  percent_change?: number | null;
  icon: React.ReactElement;
  url?: string;
  isLoading?: boolean;
};

const LinkStatCard: React.FC<LinkStatCardProps> = ({ title, metric, percent_change, icon, url, isLoading }) => {
  return (
    <CmdCard style={{ width: '360px', padding: 0 }}>
      <CmdCard.Content style={{ padding: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '16px' }}>
          <div
            style={{
              padding: '8px',
              width: 'fit-content',
              background: '#000',
              color: '#fff',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {icon}
          </div>

          {isLoading ? (
            <CmdEmpty width={78} />
          ) : !!percent_change && percent_change !== 0 ? (
            <PercentChangeTag rate={percent_change} />
          ) : null}
        </div>

        <div style={{ gap: '8px' }}>
          <CmdTypography.H1 fontSize="xxxl">
            {isLoading ? <CmdEmpty height={50} /> : formatMetric(metric, 'number')}
          </CmdTypography.H1>
          <CmdTypography.Body variant="secondary">{title}</CmdTypography.Body>
        </div>
      </CmdCard.Content>
      <CmdCard.Footer divider={!!url} asAction={!!url}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            height: '48px',
            ...(!!url && { cursor: 'pointer' }),
          }}
        >
          {!!url && (
            <Link
              to={`/analytics/${url}`}
              style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%', height: '100%' }}
            >
              <CmdTypography.Body>View all</CmdTypography.Body>
              <ArrowRight />
            </Link>
          )}
        </div>
      </CmdCard.Footer>
    </CmdCard>
  );
};

export default LinkStatCard;
