import React from 'react';

import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import {
  BookClosed,
  HelpCircle,
  HelpSquare,
  InfoCircle,
  Lightbulb04,
  Lightning01,
} from '@commandbar/design-system/icons/react';
import type { IconMarkerTooltip } from '.';

export interface SvgIconProps {
  size: number;
  color: string;
}

const iconTypes: Record<IconMarkerTooltip['form_factor']['marker']['icon'], React.FC<SvgIconProps>> = {
  bookClosed: BookClosed,
  helpCircle: HelpCircle,
  helpSquare: HelpSquare,
  infoCircle: InfoCircle,
  lightBulb: Lightbulb04,
  lightning: Lightning01,
};

export const Icon = ({ type }: { type: keyof typeof iconTypes }) => {
  const { theme } = useTheme();

  const SvgIcon = iconTypes[type];
  return <SvgIcon color={theme.nudgeTooltip.markerIconColor} size={theme.nudgeTooltip.markerImageSize} />;
};
