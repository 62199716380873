import { Col, Select } from '@commandbar/design-system/components/antd';
import React from 'react';
import { camelCaseToRegular } from '../helpers';
import { availableOperatorTypes, pastTenseOperators } from '../operators';
import { useConditionEditor } from '../state/useConditionEditor';

export const OperatorSelect = () => {
  const { condition, onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  const options = availableOperatorTypes(condition).map((availableOperator) => {
    const isPastTense = [
      'nudge_interaction',
      'questlist_interaction',
      'video_interaction',
      'help_doc_interaction',
    ].includes(condition.type);
    const tensedOperator = isPastTense ? pastTenseOperators[availableOperator] ?? availableOperator : availableOperator;
    const displayValue = camelCaseToRegular(tensedOperator);

    return {
      label: displayValue,
      value: availableOperator,
    };
  });

  if (!('operator' in condition)) {
    throw new Error('OperatorSelect: condition does not have an operator');
  }

  return (
    <Col flex="1 0 auto" style={{ overflow: 'hidden' }}>
      <Select
        disabled={disabled}
        value={condition.operator}
        onChange={(operator) => onConditionChange({ ...condition, operator })}
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
        onBlur={() => setHasBlurred(true)}
        size="small"
        options={options}
      />
    </Col>
  );
};
