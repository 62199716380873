import { Layout } from '@commandbar/design-system/components/antd';
import styled from '@emotion/styled';

export const IntegrationContentLayout = styled(Layout.Content)`
  padding: 10px;
  flex-grow: 1;
  height: inherit;
  display: flex;
  justify-content: center;
`;

export const IntegrationDetailsSection = styled.section`
  display: flex;
  flex-direction: column;
  width: clamp(45ch, 100%, 75ch);
  gap: 12px;
`;

export const IntegrationFeaturesContainer = styled.article`
  display: flex;
  flex-direction: column;
  gap: 48px;

  h2 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 8px;
  }
`;

export const IntegrationInstructionsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const IntegrationActionsSection = styled.section`
  display: flex;
  gap: 8px;
`;

export const IntegrationHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  h2 {
    margin-bottom: 0;
  }
`;

export const DocumentationLink = styled.a`
  color: #2754ee;

  &:hover {
    color: #2d66c3;
  }
`;
