import { createContext, useContext } from 'react';
import { ICommandTableDispatch, ICommandTableState } from './editor/useEditor';

export type AppState = ICommandTableState & {
  dispatch: ICommandTableDispatch;
  isStudio: boolean;
};

export const AppStateContext = createContext<null | AppState>(null);

export const useAppContext = () => {
  const appStateContext = useContext(AppStateContext);
  if (!appStateContext) {
    throw new Error('useAppContext must be used within an <AppStateContext.Provider />');
  }
  return appStateContext;
};
