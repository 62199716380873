import * as t from 'io-ts';
import * as axiosInstance from './network';

import {
  createObject,
  deleteObject,
  listObject,
  readObject,
  updateObject,
  GenericBatchRequest,
  decodeToPromise,
} from './generics';

export const KeywordV = t.intersection(
  [
    t.type({
      id: t.number,
      keyword: t.string,
      definition: t.string,
      is_live: t.boolean,
    }),
    t.partial({ modified: t.string }),
  ],
  'Keyword',
);

export const BatchEditorKeywordResponseV = t.type({
  batch: t.array(KeywordV),
});

export class Keyword {
  public static list = listObject(KeywordV, 'keywords');
  public static read = readObject(KeywordV, 'keywords');
  public static update = updateObject(KeywordV, KeywordV, 'keywords');
  public static create = createObject(KeywordV, KeywordV, 'keywords');
  public static delete = deleteObject(KeywordV, 'keywords');
  public static batch = async (request: t.TypeOf<typeof GenericBatchRequest>) => {
    const result = await axiosInstance.post('/keywords/batch/', request);

    return await decodeToPromise(BatchEditorKeywordResponseV, result.data);
  };
}
