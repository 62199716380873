import React from 'react';
import { DotsVertical } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import { Menu as AntMenu, MenuProps, Dropdown } from 'antd';
import { CB_COLORS } from './colors';
import CB_FONTS from './fonts';
import { CmdButton } from '@commandbar/design-system/cmd';

const StyledMenu = styled(AntMenu)`
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(10, 10, 15, 0.15);
  padding: 0;
  overflow: hidden;
`;

const Menu = ({ theme, ...props }: MenuProps) => <StyledMenu {...props}></StyledMenu>;

const MenuItem = styled(AntMenu.Item)`
  border-bottom: 0.5px solid rgba(3, 3, 56, 0.1);
  min-height: 40px;
  font-size: 12px;
  font-family: ${CB_FONTS.inter};

  &:hover {
    background-color: ${CB_COLORS.blue0};
  }

  & .ant-dropdown-menu-item-icon {
    margin-right: 12px;
    font-size: 12px;
  }
`;

export type IDropdownMenuRow = {
  name: string | React.ReactElement;
  onClick: (e: any, item?: any) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
};

interface DropdownMenuProps {
  keyName: string;
  items: IDropdownMenuRow[];
  vertical?: boolean;
  children?: React.ReactNode;
  placement?: React.ComponentProps<typeof Dropdown>['placement'];
}

const DropdownMenu = ({ keyName, items, vertical, placement = 'bottomRight', children }: DropdownMenuProps) => {
  const menu = (
    <Menu
      items={items.map((item, index) => {
        return {
          key: `${keyName}-${index}`,
          //onClick: item.onClick,
          icon: item.icon,
          disabled: item.disabled,
          label: item.name,
          onClick: ({ domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();
            item.onClick(domEvent, item);
          },
        };
      })}
    />
  );

  return (
    <Dropdown placement={placement} overlay={menu} trigger={['click']}>
      {!!children ? (
        children
      ) : (
        <CmdButton
          variant="link"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          icon={<DotsVertical style={{ fontSize: 18, transform: vertical ? '' : 'rotate(90deg)' }} />}
        />
      )}
    </Dropdown>
  );
};

DropdownMenu.Menu = Menu;
DropdownMenu.Item = MenuItem;

export default DropdownMenu;
