import { Spin, Form } from '@commandbar/design-system/components/antd';
import { useHeapConfigContext } from './HeapConfigProvider';
import { useState, useEffect } from 'react';
import * as Organization from '@commandbar/internal/middleware/organization';
import { CmdButton, CmdSwitch } from '@commandbar/design-system/cmd';

interface HeapConfigurationStepProps {
  handleNext: Function;
  handlePrev: Function;
}

export const HeapConfigurationStep = ({ handleNext }: HeapConfigurationStepProps) => {
  const [loading, setLoading] = useState(true);
  const { heapConfig, setHeapConfig } = useHeapConfigContext();

  useEffect(() => {
    Organization.readInternal(heapConfig.orgID).then(async ({ integrations }) => {
      const { heap } = integrations;
      if (heap && heap?.send_events_to_heap) {
        const { send_events_to_heap } = heap;
        setHeapConfig({
          ...heapConfig,
          send_events_to_heap,
        });
      }
      setLoading(false);
    });
  }, []);

  const updateConfiguration = async () => {
    Organization.readInternal(heapConfig.orgID).then(async ({ integrations }) => {
      await Organization.updateInternal({
        id: heapConfig.orgID,
        integrations: {
          ...integrations,
          heap: {
            send_events_to_heap: !heapConfig.send_events_to_heap,
          },
        },
      });
    });
    setHeapConfig({ ...heapConfig, send_events_to_heap: !heapConfig.send_events_to_heap });
  };

  return (
    <Spin spinning={loading} tip="Checking for previous configuration...">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Form.Item
          validateTrigger="onSubmit"
          initialValue={!!heapConfig.send_events_to_heap}
          key="send_events_to_heap"
          label="Send Command AI events to Heap"
          name="send_events_to_heap"
          help={
            'Use Heap as a data destination. Measure the impact of your Bar, Nudges, Checklists, and more - all within Heap.'
          }
        >
          <CmdSwitch
            offLabel="Do not send"
            onLabel="Send"
            checked={!!heapConfig.send_events_to_heap}
            onCheckedChange={updateConfiguration}
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CmdButton variant="primary" type="submit" onClick={() => handleNext()}>
            Next
          </CmdButton>
        </div>
      </div>
    </Spin>
  );
};

export default HeapConfigurationStep;
