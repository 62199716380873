import { IEditorCommandTypeLite, INudgeType, IChecklist } from '@commandbar/internal/middleware/types';

interface EditorTagsInput {
  commands?: IEditorCommandTypeLite[];
  nudges?: INudgeType[];
  checklists?: IChecklist[];
}

const collectEditorTags = ({ commands = [], nudges = [], checklists = [] }: EditorTagsInput) => {
  const allTags = [
    ...commands.flatMap((command) => command.editor_tags),
    ...nudges.flatMap((nudge) => nudge.editor_tags),
    ...checklists.flatMap((checklist) => checklist.editor_tags),
  ];

  const tagCounts: Record<string, number> = {};
  allTags.forEach((tag) => {
    tagCounts[tag] = (tagCounts[tag] || 0) + 1;
  });

  return Object.keys(tagCounts).sort((a, b) => tagCounts[b] - tagCounts[a]);
};

export default collectEditorTags;
