import React from 'react';

import * as AdditionalResource from '@commandbar/internal/middleware/additionalResource';
import { useAppContext } from 'editor/src/AppStateContext';
import { IAdditionalResource } from '@commandbar/internal/middleware/types';
import debounce from 'lodash/debounce';
import produce from 'immer';
import { useReportEvent } from '../../hooks/useEventReporting';
import Sender from '../../management/Sender';
import { partition } from 'lodash';
import { cmdToast } from '@commandbar/design-system/cmd';

const useAdditionalResources = () => {
  const { organization } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const supportCTA = React.useRef<IAdditionalResource | null>(null);
  const additionalResources = React.useRef<IAdditionalResource[] | null>(null);

  // populate supportCTA and additionalResources from backend API
  React.useEffect(() => {
    (async () => {
      // partition on show_as_primary_cta using lodash partition
      const [supportCTAs_, additionalResources_] = partition(
        await AdditionalResource.list(),
        (r) => r.show_as_primary_cta,
      );

      if (supportCTAs_[0]) {
        supportCTA.current = supportCTAs_[0];
      } else {
        // if no Support CTA in DB, use default values
        supportCTA.current = {
          id: -1,
          organization: organization.id.toString(),
          widget: 'helphub',
          sort_key: 0,
          label: '',
          icon: 'link',
          icon_color: null,
          action: { type: 'link', value: '', operation: 'blank' },
          show_as_primary_cta: true,
        };
      }
      additionalResources.current = additionalResources_;

      forceUpdate();
    })();
  }, [organization.id]);

  /** Additional Resources */

  const updatedAdditionalResourceDebounced = React.useMemo(() => {
    const state: Record<number, (r: IAdditionalResource) => void> = {};
    return (r: IAdditionalResource) => {
      const func =
        state[r.id] ??
        debounce(
          (r: IAdditionalResource) =>
            (async () => {
              try {
                await AdditionalResource.update(r);
                cmdToast.success('Updated additional resource');

                Sender.reload(['reloadOrganization']);

                const payloadMessage = `${r.label} (ID: ${r.id})`;

                reportEvent('additional resource edited', {
                  segment: true,
                  highlight: true,
                  slack: true,
                  payloadMessage: payloadMessage,
                });
              } catch (err) {
                console.log(err);
                cmdToast.error('Error updating additional resource');
              }
            })(),
          500,
        );

      state[r.id] = func;

      return func(r);
    };
  }, [reportEvent]);

  const moveAdditionalResource = async (oldIndex: number, newIndex: number) => {
    if (!additionalResources.current) return;

    const rs = additionalResources.current;

    const item: IAdditionalResource | undefined = rs[oldIndex];

    if (!item) return;

    // Remove the item being moved from the array, then add it back in the new location.
    // Then, re-set all the sort_keys based on the new indices.
    let newArray = [...rs.slice(0, oldIndex), ...rs.slice(oldIndex + 1)];
    newArray = [...newArray.slice(0, newIndex), item, ...newArray.slice(newIndex)];
    newArray = newArray.map((item, index) => ({ ...item, sort_key: index }));

    additionalResources.current = newArray;
    forceUpdate();

    try {
      await AdditionalResource.batch({
        note: 'Change order of Additional Resources',
        batch: newArray.map((item) => ({ op: 'update', id: item.id, data: { sort_key: item.sort_key } })),
      });
      cmdToast.success('Updated resource order');
      Sender.reload(['reloadOrganization']);
    } catch (err) {
      console.error(err);
      cmdToast.error('Error updating resource order');
    }
  };

  const updateAdditionalResource = (r: IAdditionalResource, recipe: (draft: IAdditionalResource) => void) => {
    if (!additionalResources.current) return;

    const updatedAdditionalResource = produce(r, recipe);

    updatedAdditionalResourceDebounced(updatedAdditionalResource);

    additionalResources.current = additionalResources.current.map((additionalResource: IAdditionalResource) =>
      additionalResource.id === r.id ? updatedAdditionalResource : additionalResource,
    );
    forceUpdate();

    return r;
  };

  const deleteAdditionalResource = async (toDeleteID: number) => {
    if (!additionalResources.current) return;

    const deleted = additionalResources.current.find((r) => r.id === toDeleteID);

    try {
      await AdditionalResource.del(toDeleteID);
      cmdToast.success('Deleted additional resource');

      Sender.reload(['reloadOrganization']);

      additionalResources.current = additionalResources.current.filter((r) => r.id !== toDeleteID);
      forceUpdate();

      const payloadMessage = `${deleted?.label} (ID: ${toDeleteID})`;

      reportEvent('additional resource deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
      });
    } catch (err) {
      console.error(err);
      cmdToast.error('Error deleting additional resource');
    }
  };

  const addNewAdditionalResource = async () => {
    if (!organization) return;
    if (!additionalResources.current) return;
    const lastAdditionalResource = additionalResources.current[additionalResources.current.length - 1];

    try {
      const newItem = await AdditionalResource.create({
        id: -1,
        organization: organization.id.toString(),
        widget: 'helphub',
        sort_key: lastAdditionalResource ? lastAdditionalResource.sort_key + 1 : 0,
        label: '',
        icon: 'link',
        icon_color: null,
        action: { type: 'link', value: '', operation: 'blank' },
        show_as_primary_cta: false,
      });
      cmdToast.success('Created additional resource');
      Sender.reload(['reloadOrganization']);

      additionalResources.current = [...additionalResources.current, newItem];
      forceUpdate();
    } catch (err) {
      console.error(err);
      cmdToast.error('Error creating additional resource');
    }
  };

  /**
   * Mini state machine to handle serializing saves.
   *
   * If `save` is called when a create/update is in progress,
   * it will wait for the current operation to finish.
   *
   * This way, if the item is being created, we will wait for
   * the create to finish before updating the item (with the
   * appropriate ID).
   */
  const createOrUpdateSupportCTADebounced = React.useMemo(() => {
    let saving = false;
    let check = false;
    const doSave = async () => {
      try {
        if (!supportCTA.current) return;

        if (saving) {
          // wait for save to finish
          check = true;
          return;
        }

        saving = true;
        if (supportCTA.current.id < 0) {
          // create
          supportCTA.current = await AdditionalResource.create(supportCTA.current);
          const payloadMessage = `${supportCTA.current.label} (ID: ${supportCTA.current.id})`;
          reportEvent('support cta created', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: payloadMessage,
          });
        } else {
          supportCTA.current = await AdditionalResource.update(supportCTA.current);
          const payloadMessage = `${supportCTA.current.label} (ID: ${supportCTA.current.id})`;
          reportEvent('support cta edited', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: payloadMessage,
          });
        }
        cmdToast.success('Support CTA updated');
        Sender.reload(['reloadOrganization']);
      } finally {
        saving = false;
        if (check) {
          check = false;
          doSave();
        }
      }
    };
    return debounce(doSave, 500);
  }, [reportEvent]);

  return {
    supportCTA: supportCTA.current,
    additionalResources: additionalResources.current,

    actions: {
      additionalResources: {
        move: moveAdditionalResource,
        update: updateAdditionalResource,
        delete: deleteAdditionalResource,
        addNew: addNewAdditionalResource,
      },
      supportCTA: {
        update: (recipe: (draft: IAdditionalResource) => void): void => {
          supportCTA.current = produce(supportCTA.current, recipe);
          forceUpdate();
          createOrUpdateSupportCTADebounced();
        },
      },
    },
  };
};

export default useAdditionalResources;
