/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/core';
import React, { CSSProperties, ReactNode, Ref, RefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { ReactComponent as CaretIcon } from 'shared/img/caret.svg';

import useTheme from 'shared/util/hooks/useTheme';
import Z from '@commandbar/internal/client/Z';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledFormFieldBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledFormFieldBlock';
import StyledSelect, { OptionWithReset } from '@commandbar/internal/client/themesV2/components/shared/StyledSelect';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useSurveyResponse } from '../SurveyResponseProvider';

interface DropdownProps {
  style: CSSProperties;
  children: (firstOptionRef: Ref<HTMLLIElement>) => ReactNode;
  activeOption?: string;
}

const DropdownMenu = forwardRef<HTMLUListElement, DropdownProps>(({ activeOption, style, children }, dropdownRef) => {
  const { theme } = useTheme();
  const firstOptionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const originalActiveElement = document.activeElement;

    // Focus the first option when the component mounts (dropdown menu opens)
    if (firstOptionRef.current) firstOptionRef.current.focus();

    return () => {
      // Return focus to the original element when the component unmounts (dropdown menu closes)
      if (originalActiveElement instanceof HTMLElement) originalActiveElement.focus();
    };
  }, []);

  return (
    <ul
      ref={dropdownRef}
      tabIndex={-1}
      // the options are interactive due to the onClick handler and tabIndex
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="listbox"
      aria-activedescendant={activeOption}
      style={{
        display: 'flex',
        position: 'absolute',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: '16px',
        maxHeight: '200px',
        overflowY: 'auto',
        width: 'calc(100% - 30px)',
        zIndex: Z.Z_INDEX_MAX,
        boxShadow: theme.nudgeOptionListDropdownOption.boxShadow,
        border: theme.nudgeOptionListDropdownOption.border,
        background: theme.nudgeOptionListDropdownOption.background,
        color: theme.nudgeOptionListDropdownOption.color,
        ...style,
      }}
    >
      {children(firstOptionRef)}
    </ul>
  );
});

const useDropdownPosition = (
  buttonRef: RefObject<HTMLButtonElement>,
  dropdownRef: RefObject<HTMLUListElement>,
  isDropdownOpen: boolean,
) => {
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');

  useEffect(() => {
    const buttonElement = buttonRef.current;
    const dropdownElement = dropdownRef.current;

    if (!(isDropdownOpen && buttonElement && dropdownElement)) return;

    const buttonRect = buttonElement.getBoundingClientRect();
    const dropdownHeight = dropdownElement.offsetHeight;
    const spaceBelow = window.innerHeight - buttonRect?.bottom;

    setDropdownPosition(spaceBelow < dropdownHeight ? 'top' : 'bottom');
  }, [buttonRef, dropdownRef, isDropdownOpen]);

  return dropdownPosition;
};

interface ListDropdownProps {
  options: string[];
}

export const ListDropdownBlock = ({ options }: ListDropdownProps) => {
  const { theme } = useTheme();
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const selectRef = useRef<HTMLSelectElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const selectedOption =
    typeof surveyResponse?.value === 'string' && options.includes(surveyResponse.value) ? surveyResponse.value : '';

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const dropdownPosition = useDropdownPosition(buttonRef, dropdownRef, isDropdownOpen);

  return _.flags?.['release-themes-v2'] ? (
    <StyledFormFieldBlock>
      <StyledSelect
        onChange={(e) => setSurveyResponse({ type: 'string', value: (e.target as HTMLSelectElement).value })}
        value={selectedOption}
        themeV2={themeV2}
        ref={selectRef}
      >
        <option value="">Select...</option>
        {options.map((option) => (
          <OptionWithReset value={option}>{option}</OptionWithReset>
        ))}
      </StyledSelect>
    </StyledFormFieldBlock>
  ) : (
    <>
      <button
        type="button"
        ref={buttonRef}
        onClick={toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
        style={{
          display: 'flex',
          height: '40px',
          padding: '8px 12px',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '4px',
          fontWeight: '500',
          fontSize: '16px',
          width: '100%',
          ...theme.nudgeOptionListDropdown,
        }}
      >
        {selectedOption || 'Select option...'}
        <CaretIcon />
      </button>

      {isDropdownOpen && (
        <DropdownMenu
          ref={dropdownRef}
          activeOption={selectedOption}
          style={{
            marginTop: dropdownPosition === 'top' ? undefined : '8px',
            bottom: dropdownPosition === 'top' ? '104px' : undefined,
            top: dropdownPosition === 'bottom' ? '88px' : undefined,
            color: theme.nudgeOptionListDropdownOption.color,
          }}
        >
          {(firstOptionRef) =>
            options.map((option, index) => (
              <li
                ref={index === 0 ? firstOptionRef : null}
                id={option}
                // this element is interactive due to the onClick handler and tabIndex
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="option"
                aria-selected={selectedOption === option}
                key={option}
                tabIndex={0}
                onClick={() => {
                  setSurveyResponse({ type: 'string', value: option });
                  toggleDropdown();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    setSurveyResponse({ type: 'string', value: option });
                    toggleDropdown();
                  }
                }}
                css={{
                  display: 'flex',
                  height: '40px',
                  padding: '8px',
                  alignItems: 'center',
                  gap: '8px',
                  width: '100%',
                  '&:hover': {
                    background: theme.nudgeOptionListDropdownOption.backgroundHover,
                  },
                }}
              >
                {option}
              </li>
            ))
          }
        </DropdownMenu>
      )}
    </>
  );
};
