import { useContext, createContext } from 'react';
import { default_filter } from './components/CustomTimeRange';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';

export interface AnalyticsContextProps {
  isDemoData: boolean;
  setIsDemoData: (val: boolean) => void;
  includeAdminData: boolean;
  setIncludeAdminData: (val: boolean) => void;
  handleCustomFilterChange: (value: DateFilter) => void;
  timeFilterRange: DateFilter;
  setFilterRange: (val: DateFilter) => void;
}

const emptyFunction = () => {
  throw new Error('Context value has not been initialized');
};

const AnalyticsContext = createContext<AnalyticsContextProps>({
  isDemoData: false,
  setIsDemoData: emptyFunction,
  includeAdminData: false,
  setIncludeAdminData: emptyFunction,
  handleCustomFilterChange: emptyFunction,
  timeFilterRange: default_filter,
  setFilterRange: emptyFunction,
});

export const useAnalyticsContext = (): AnalyticsContextProps => useContext(AnalyticsContext);

export default AnalyticsContext;
