import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { isValidEditorPage } from '@commandbar/internal/proxy-editor/editor_routes';

interface IDeepLinkProps {
  // Page to navigate to. Must be a valid APP_ROUTE
  to: string;
  action?: 'push' | 'replace';
  // User defined state to register with the navigation
  state?: Record<string, any>;
  from?: {
    pathname: string;
    search: string;
  };
  // Callback to execute when the navigation is complete
  done?: Function;
}

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      deeplink: ({ to, state, done, action = 'push' }: IDeepLinkProps) => {
        // If route provided is invalid, immediately return
        if (!isValidEditorPage(to)) return;
        history[action]({
          pathname: to,
          state,
        });
        done?.();
      },
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      } as { [key: string]: string },
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
};

export default useRouter;
