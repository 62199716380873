import React from 'react';
import styled from '@emotion/styled';

import { Page } from '@commandbar/design-system/components/antd';
import { mediaQueries } from '../..//util/mediaQueries';
import { SpotlightCard } from './SpotlightCard';
import { ProductTourCard } from './ProductTourCard';
import { SurveyCard } from './SurveyCard';
import { ChecklistCard } from './ChecklistCard';
import { HelpCenterCard } from './HelpCenterCard';
import { IUserType } from '@commandbar/internal/middleware/types';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px;

  div:nth-child(1),
  div:nth-child(2) {
    grid-column: span 3;
  }

  div:nth-child(n + 2) {
    grid-column: span 3;
  }

  div:last-child {
    grid-column: span 6;
  }

  @media (max-width: 1385px) {
    grid-template-columns: 1fr 1fr;

    div:nth-child(1),
    div:nth-child(2) {
      grid-column: auto;
    }

    div:nth-child(n + 3) {
      grid-column: auto;
    }

    div:last-child {
      grid-column: 1 / -1;
    }
  }

  @media ${mediaQueries.tablet} {
    grid-gap: 16px;
    grid-template-columns: 1fr;

    div:last-child {
      grid-column: auto;
    }
  }
`;

const Dashboard = ({ user }: { user: IUserType }) => {
  return (
    <Page title="Get Started with Command AI" section="Quickstart Guide">
      <CardsContainer>
        <HelpCenterCard />
        <ProductTourCard />
        <ChecklistCard />
        <SurveyCard />
        <SpotlightCard user={user} />
      </CardsContainer>
    </Page>
  );
};

export default Dashboard;
