import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { useLocation } from 'react-router-dom';

import SourceConfigForm from './SourceConfigForm';

import type { IntegrationSpec } from '../../../integrations/shared/types';
import { Layout } from '@commandbar/design-system/components/antd';
import { DrawerSubtitle, DrawerTitle, StyledDrawer } from '../../shared/styles';
import { useAppContext } from 'editor/src/AppStateContext';

const SourceEditDrawer = ({
  integration,
  setIntegration,
  sourceId,
  onCancel,
  onComplete,
  open,
}: {
  integration: IntegrationSpec | undefined;
  setIntegration: (integ: IntegrationSpec | undefined) => void;
  sourceId?: number;
  onCancel: () => void;
  onComplete: () => void;
  open: boolean;
}) => {
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token') ?? '';
  const { organizationSettings } = useAppContext();

  return (
    <StyledSourceDrawer
      width="500px"
      placement="right"
      onClose={onCancel}
      open={open}
      closable={false}
      maskStyle={{ color: '#000000', opacity: '0.2' }}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LogoImage>
            <Image src={integration?.iconURL} integrationType={integration?.slug} />
          </LogoImage>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            <DrawerTitle>{integration?.name}</DrawerTitle>
            <DrawerSubtitle>{integration?.description}</DrawerSubtitle>
          </div>
        </div>
      }
    >
      <Layout.Content style={{ flexGrow: 1, height: 'calc(100% - 72px)', overflow: 'auto' }}>
        <div style={{ background: '#fff', height: '100%' }}>
          <SourceConfigForm
            key={'configure-step-' + integration?.slug}
            setSelectedIntegration={setIntegration}
            selectedIntegration={integration}
            token={token}
            allowedHelpSyncSchedule={organizationSettings?.help_center_sync}
            onCancel={onCancel}
            onComplete={onComplete}
            sourceId={sourceId}
          />
        </div>
      </Layout.Content>
    </StyledSourceDrawer>
  );
};

const Image = styled.img<{ integrationType?: string }>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
`;

const LogoImage = styled.div`
  width: 40px;
  height: 40px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral400};
  border-radius: 8px;
`;

const StyledSourceDrawer = styled(StyledDrawer)`
  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-drawer-header {
    padding: 12px 12px 12px 16px;
  }
`;

export default SourceEditDrawer;
