import { Tag } from '@commandbar/design-system/components/antd';
import React from 'react';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { IEditorCommandType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { reportErrorToUser } from '../../utils/ErrorReporting';

type Props = {
  organization: IOrganizationType;
  command: IEditorCommandType;
  onSelect: (synonym: string) => void;
  onDeny: (synonym: string) => void;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getSuggestions = async (text: IEditorCommandType['text'], type: IEditorCommandType['template']['type']) => {
  try {
    const response = await axiosInstance.post(
      'commands/suggest_tags/',
      JSON.stringify({
        text,
        type,
      }),
    );
    return response.data as string[];
  } catch (err) {
    reportErrorToUser(err);
    return undefined;
  }
};

export const SynonymSuggestions = (props: Props) => {
  const { command } = props;

  const [suggestions, setSuggestions] = React.useState<string[] | undefined>(undefined);
  const [filteredSuggestions, setFilteredSuggestions] = React.useState<string[]>([]);

  /**
   * Filter suggestions according to the current denylist
   */
  React.useEffect(() => {
    if (!suggestions) return;

    let _filteredSuggestedTags = suggestions;

    // Filter out any tags on the deny list
    if (command.generated_tags?.deny) {
      _filteredSuggestedTags = _filteredSuggestedTags.filter((t: string) => !command.generated_tags.deny?.includes(t));
    }

    // Filter out duplicates
    _filteredSuggestedTags = _filteredSuggestedTags.filter(
      (t: string) => !(command.tags.includes(t) || command.text.toLocaleLowerCase().includes(t.toLocaleLowerCase())),
    );

    // Return at most 6 tags
    _filteredSuggestedTags = _filteredSuggestedTags.slice(0, 6);
    setFilteredSuggestions(_filteredSuggestedTags);
  }, [suggestions, command.tags, command.generated_tags.deny]);

  React.useEffect(() => {
    if (!command.text || !command.template.type) return;

    let canceled = false;

    (async () => {
      await sleep(1000);
      if (canceled) return;
      const suggestions = await getSuggestions(command.text, command.template.type);
      if (canceled) return;
      setSuggestions(suggestions);
    })();

    return () => {
      canceled = true;
    };
  }, [command.text, command.template.type]);

  if (!filteredSuggestions || filteredSuggestions.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        margin: '-10px 56px 20px 25.8%',
        gap: '5px',
        alignItems: 'center',
        maxHeight: '55px',
        overflow: 'hidden',
      }}
    >
      <div style={{ opacity: 0.5, fontSize: 11 }}>Suggestions:</div>
      {filteredSuggestions.map((tag: string) => (
        <Tag
          key={tag}
          color={'geekblue'}
          style={{ cursor: 'pointer' }}
          closable={true}
          onClose={(_) => {
            props.onDeny(tag);
          }}
          onClick={(_) => {
            props.onSelect(tag);
          }}
        >
          {tag}
        </Tag>
      ))}
    </div>
  );
};
