import { RefObject, useEffect } from 'react';

// NOTE: this does not cover all possible focusable items. Use on a case-by-case basis.
// See https://github.com/focus-trap/tabbable for a more complete solution.
const FOCUSABLE_ELEMENT_SELECTOR = `
  button:not([disabled]),
  [href]:not([tabindex="-1"]),
  input:not([disabled]):not([type="hidden"]),
  select:not([disabled]),
  textarea:not([disabled]),
  [tabindex]:not([tabindex="-1"]),
  audio[controls]:not([tabindex="-1"]),
  video[controls]:not([tabindex="-1"]),
  summary:not([tabindex="-1"]),
  details:not([tabindex="-1"]),
  [contenteditable]:not([tabindex="-1"])
`;

// INFO: only HTMLElement and SVGElement have the focus method
const isHTMLOrSVGElement = (element: Element): element is HTMLElement | SVGElement =>
  element instanceof HTMLElement || element instanceof SVGElement;

interface Options {
  shouldStealFocus?: boolean;
  onEnterKeyDown?: () => void;
}

export const useFocusTrap = (
  elementRef: RefObject<HTMLElement> | null,
  options: Options = { shouldStealFocus: false },
) => {
  /**
   * Steal focus from the previously focused element when the component mounts.
   * Restore focus to the previously focused element when the component unmounts.
   */
  useEffect(() => {
    const element = elementRef?.current;
    if (!element || !options.shouldStealFocus) return;

    const originalActiveElement = document.activeElement;
    element.focus();

    return () => {
      if (originalActiveElement && isHTMLOrSVGElement(originalActiveElement)) {
        originalActiveElement.focus();
      } else {
        document.body.focus();
      }
    };
  }, [elementRef, options.shouldStealFocus]);

  /**
   * Trap focus within the component.
   * When the user tabs in the component, focus will be set to the first focusable element.
   * When the user shift-tabs in the component, focus will be set to the last focusable element.
   */
  useEffect(() => {
    const element = elementRef?.current;
    if (!element) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      const focusableElements = element.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR);

      if (document.activeElement === element && e.key === 'Enter') {
        options.onEnterKeyDown?.();
        e.preventDefault();
      }

      if (e.key === 'Tab') {
        const firstElement = focusableElements[0] ?? element;
        const lastElement = focusableElements[focusableElements.length - 1] ?? element;

        // tabbing backwards
        if (e.shiftKey) {
          if (document.activeElement === firstElement && isHTMLOrSVGElement(lastElement)) {
            lastElement.focus();
            e.preventDefault();
          }
        }
        // tabbing forwards
        else {
          if (document.activeElement === lastElement && isHTMLOrSVGElement(firstElement)) {
            firstElement.focus();
            e.preventDefault();
          }
        }
      }
    };

    element.addEventListener('keydown', handleKeyDown);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
    };
  }, [elementRef]);
};
