import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { AnalyticsHelpHub } from '@commandbar/internal/middleware/analytics/helphub';
import { CardData, TableData } from '@commandbar/internal/middleware/analytics/common';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { useCSVDownload } from '../../useCSVDownload';
import { getHelpHubDemoData } from '../../analyticDemoData';

const useAnalyticHelpHub = () => {
  const [helpHubOpens, setHelpHubOpens] = useState<CardData>({
    type: 'card',
    metric: 0,
    percent_change: null,
  });
  const [helpHubDocOpens, setHelpHubDocOpens] = useState<CardData>({
    type: 'card',
    metric: 0,
    percent_change: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [searches, setSearches] = useState<TableData>();
  const [articles, setArticles] = useState<TableData>();
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { downloadCsv: downloadCsvArticles, isDownloading: isDownloadingArticles } = useCSVDownload({
    name: `helphub-articles`,
    download: () =>
      AnalyticsHelpHub.download('articles', {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
      }),
  });

  const { downloadCsv: downloadCsvSearches, isDownloading: isDownloadingSearches } = useCSVDownload({
    name: `helphub-searches`,
    download: () =>
      AnalyticsHelpHub.download('searches', {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
      }),
  });

  const fetchHelpHubData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsHelpHub.read({ ...timeFilterRange, include_admin_data: includeAdminData.toString() })
      .then((res) => {
        setHelpHubDocOpens(res.helphub_doc_opens);
        setHelpHubOpens(res.helphub_opens);
        setArticles(res.articles);
        setSearches(res.searches);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      const res = getHelpHubDemoData();
      if (res) {
        setHelpHubDocOpens(res.helphub_doc_opens);
        setHelpHubOpens(res.helphub_opens);
        setArticles(res.articles);
        setSearches(res.searches);
      }
      setIsLoading(false);
    } else {
      fetchHelpHubData();
    }
  }, [timeFilterRange, isDemoData, includeAdminData]);

  return {
    helpHubOpens,
    isLoading,
    helpHubDocOpens,
    articles,
    searches,
    fetchHelpHubData,
    error,
    downloadCsvArticles,
    downloadCsvSearches,
    isDemoData,
    isDownloading: isDownloadingArticles || isDownloadingSearches,
  };
};

export default useAnalyticHelpHub;
