import { _dispose, _disposed } from '../client/symbols';

export type PublicDisposable = { dispose: VoidFunction; _disposed?: boolean };
export type PrivateDisposable = { [_dispose]: VoidFunction; [_disposed]?: boolean };

export type Disposable = undefined | PublicDisposable | PrivateDisposable;

const isPublicDisposable = (x: unknown): x is PublicDisposable =>
  x !== undefined && (x as PublicDisposable).dispose !== undefined;
const isPrivateDisposable = (x: unknown): x is PrivateDisposable =>
  x !== undefined && (x as PrivateDisposable)[_dispose] !== undefined;

export const isDisposable = (x: unknown): x is Disposable =>
  x === undefined || isPublicDisposable(x) || isPrivateDisposable(x);

/**
 * Determines if the provided value is "disposed". A value is considered "disposed" if it is an object with the
 * _disposed property or symbol symbol set to true, or if the actual value is undefined.
 */
export const isDisposed = (x: unknown): boolean =>
  x === undefined ||
  (isPublicDisposable(x) && x._disposed === true) ||
  (isPrivateDisposable(x) && x[_disposed] === true);

export function dispose(x: Disposable): void {
  if (x === undefined) return;
  if (isPrivateDisposable(x)) {
    x[_dispose]();
  } else {
    x.dispose();
  }
}
