import { IOrganizationType } from '@commandbar/internal/middleware/types';

interface IProps<T, U> {
  organization: IOrganizationType | undefined;
  orgIds: string[];
  activeState: T;
  defaultState: U;
}

function featureFlag<T, U>({ organization, orgIds, activeState, defaultState }: IProps<T, U>): T | U {
  if (!!organization && orgIds.includes(organization.id.toString())) {
    return activeState;
  }

  return defaultState;
}

export default featureFlag;
