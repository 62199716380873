import * as t from 'io-ts';

import { createObject, read } from '../generics';

export type HubSpotListsResponse = t.TypeOf<typeof HubSpotListsV>;

const HubSpotListsV = t.type({
  lists: t.array(
    t.type({
      label: t.string,
      value: t.string,
    }),
  ),
});

const HubSpotContactV = t.partial({
  send_events_to_hubspot: t.boolean,
  lists: t.record(t.string, t.string),
});

export class HubSpotIntegration {
  public static readLists = read(HubSpotListsV, `integrations/hubspot/lists`);
  public static syncContacts = createObject(HubSpotContactV, HubSpotContactV, `integrations/hubspot/sync`);
}
