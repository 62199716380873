import React from 'react';
import dayjs from 'dayjs';
import { ILog } from './types';
import { Description, Time } from './styled';
import ConfigProvider from 'antd/es/config-provider';
import Empty from 'antd/es/empty';
import { Table, Tooltip } from '@commandbar/design-system/components/antd';
import { CmdTag } from '@commandbar/design-system/cmd';

interface IProps {
  logs: ILog[];
  setActiveRow: (row: ILog) => void;
}

const SDKLogTable = ({ logs, setActiveRow }: IProps) => {
  const trimJSON = (json: any) => {
    const isContextBig = (context: any) => {
      const MAX_CONTEXT_SIZE = 2500;
      return JSON.stringify(context).length > MAX_CONTEXT_SIZE;
    };

    if (isContextBig(json)) return { error: 'Arguments too large to render.' };
    return json;
  };

  const getKeyOfInterest = (log: ILog) => {
    const jsonArgs = trimJSON(log.args);
    if (Array.isArray(jsonArgs)) {
      return jsonArgs.length > 0 && typeof jsonArgs[0] === 'string' ? jsonArgs[0] : null;
    }
    return null;
  };

  const columns = [
    {
      title: 'Time',
      dataIndex: 'time',
      render: (time: any) => <Time key={`time-${time}`}>{dayjs(time).local().format('HH:mm:ss')}</Time>,
    },
    {
      title: 'Action',
      dataIndex: 'name',
      render: (name: string) => <CmdTag variant="info">{name}</CmdTag>,
    },
    {
      title: 'Params',
      dataIndex: 'args',
      render: (args: any, _record: any) => {
        const keyOfInterest = getKeyOfInterest(_record);
        return (
          !!keyOfInterest && (
            <Tooltip content={keyOfInterest} placement="top">
              <Description>{keyOfInterest}</Description>
            </Tooltip>
          )
        );
      },
    },
  ];

  // TODO Soham: Make cursor: pointer on hovering over row
  return (
    <ConfigProvider renderEmpty={() => <Empty description="Reload to see SDK calls" />}>
      <Table
        style={{
          cursor: 'pointer',
        }}
        onRow={(record) => {
          return {
            onClick: () => setActiveRow(record),
          };
        }}
        pagination={false}
        dataSource={logs}
        columns={columns}
      />
    </ConfigProvider>
  );
};

export default SDKLogTable;
