import { useParams } from 'react-router-dom';

import { CmdBreadcrumb } from '@commandbar/design-system/cmd';
import { AnalyticsProductTours } from '@commandbar/internal/middleware/analytics/productTours';
import { useAppContext } from 'editor/src/AppStateContext';
import { getProductTourUsersDemoData } from '../../analyticDemoData';
import FunnelChart from '../../components/FunnelChart';
import { GoalCard } from '../../components/GoalCard';
import Layout from '../../components/Layout';
import StatCard from '../../components/StatCard';
import { useGoalEventName } from '../../useGoalEventName';
import SurveyResponseTable from '../SurveyResponseTable';
import { ViewsTable } from '../ViewsTable';
import { useAnalyticProductTourDetails } from './useAnalyticProductTourDetails';

const AnalyticsProductToursDetailDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const { flags } = useAppContext();
  const [goalEventName, setGoalEventName] = useGoalEventName('tours', id);

  const { isLoading, data, error, fetchData, surveyDetails } = useAnalyticProductTourDetails({
    id,
    goalEventName,
  });

  return (
    <Layout
      subtitle={<CmdBreadcrumb items={[{ label: 'Tours', to: '/analytics/product-tours' }]} />}
      title={data?.tourName || 'Tour'}
      titleLink={`/editor/product-tours/${id}`}
      disableDemoData={true}
    >
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <StatCard
            title="Views"
            subtitle="Total product tour views"
            format="number"
            metric={data?.sessions?.metric}
            rate={data?.sessions?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Completion rate"
            subtitle="Avg completion rate for this product tour"
            format="percentage"
            metric={data?.completion_rate?.metric}
            rate={data?.completion_rate?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Snooze rate"
            subtitle="Avg snooze rate for this product tour"
            format="percentage"
            metric={data?.snooze_rate?.metric}
            rate={data?.snooze_rate?.percent_change}
            isLoading={isLoading}
          />
          {flags['release-nudge-goals'] && (
            <GoalCard
              title={goalEventName ? 'Goal rate' : 'Set a goal'}
              subtitle={goalEventName ? 'Avg. event completion rate' : 'Measure the impact of this product tour.'}
              metric={data?.goal?.metric}
              rate={data?.goal?.percent_change}
              eventName={goalEventName}
              isLoading={isLoading}
              onGoalEventChange={setGoalEventName}
            />
          )}
        </div>

        <FunnelChart
          title="Performance"
          subtitle="Discover how each step of your product tour has been performing"
          chartHeight={400}
          data={data?.end_user_funnel}
          isLoading={isLoading}
        />

        {!!data?.survey_table && (
          <SurveyResponseTable
            isLoading={isLoading}
            error={error}
            fetchData={fetchData}
            surveyDetails={surveyDetails}
            rows={data?.survey_table?.rows || []}
          />
        )}

        <ViewsTable id={id} readUsers={AnalyticsProductTours.readUsers} getDemoData={getProductTourUsersDemoData} />
      </div>
    </Layout>
  );
};

export default AnalyticsProductToursDetailDashboard;
