import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const formatRelativeTime = (timestamp: string | number | null | undefined) => {
  if (
    !timestamp ||
    typeof timestamp === 'number' ||
    timestamp.startsWith('1970') /* e.g. 1970-01-01T00:00:00.000000Z */
  ) {
    return '-';
  }

  // If the timestamp is less than 30 days old, show relative time
  if (dayjs().diff(timestamp, 'days') < 30) {
    return dayjs(timestamp).fromNow();
  } else {
    return dayjs(timestamp).locale('en').format('MMM D, YYYY');
  }
};

export const formatNumber = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) {
    return '—';
  } else if (typeof value === 'string') {
    // Check for commas in case toLocaleString() has already been called
    if (value.includes(',')) {
      value = parseFloat(value.replace(',', ''));
    } else {
      value = parseFloat(value);
    }
  }

  if (isNaN(value)) {
    return '—';
  }

  return value.toLocaleString();
};

export const formatPercentage = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) {
    return '—';
  } else if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    return '—';
  }

  if (value === 0) {
    return '0%';
  }

  return `${(value * 100).toFixed(1)}%`;
};

export const formatMetric = (metric: number | string | null | undefined, format: 'number' | 'percentage' | 'raw') => {
  if (metric === null || metric === undefined) {
    return '-';
  }

  if (format === 'number') {
    return formatNumber(metric);
  }

  if (format === 'percentage') {
    return formatPercentage(metric);
  }

  return metric;
};

export const formatPageUrl = (value: string): string => {
  try {
    const url = new URL(value);
    return url?.pathname ?? '';
  } catch (error) {
    return '';
  }
};
