import React from 'react';
import queryString from 'query-string';
import { PasswordStrengthBar } from '@commandbar/design-system/components/PasswordStrengthBar';

import FormLayout from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';

import { Input, Form } from '@commandbar/design-system/components/antd';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

import { CmdButton, CmdDivider, CmdInput, CmdLabel, cmdToast } from '@commandbar/design-system/cmd';
import { useHistory } from 'react-router-dom';
import { GoogleBrand } from '@commandbar/design-system/icons/react';

const translateErrorMessage = (err: any) => {
  if (!err) return 'Something went wrong';

  if (err.hasOwnProperty('email') && err['email'].length > 0) {
    if ((err['email'] as Array<unknown>).includes('This field must be unique')) {
      return 'A user with this email already exists';
    } else {
      return err['email'][0];
    }
  }

  if (err.hasOwnProperty('password') && err['password'].length > 0) {
    return (
      <div>
        {err['password'].map((e: any) => (
          <div key={e}>{e}</div>
        ))}
      </div>
    );
  }
  return 'Something went wrong';
};

const AcceptInvite = () => {
  const history = useHistory();
  const { acceptInvite } = useAuth();

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [form] = Form.useForm();

  const sendSegmentEvents = async (values: any) => {
    window.analytics
      .identify(form.getFieldValue('email'), {
        organization: '',
        email: form.getFieldValue('email'),
      })
      .then(() => {
        window.analytics.track('Invite accepted', {
          category: 'signup form',
          label: 'Form',
          obj: values,
          email: form.getFieldValue('email'),
        });
      });
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);

      sendSegmentEvents(values);

      const { is_active } = (await acceptInvite(values)) || {};

      if (is_active) {
        cmdToast.success('Successfully accepted invite! Redirecting to login...');
        setTimeout(() => {
          history.push('/login');
        }, 2000);
      } else {
        cmdToast.success('Successfully accepted invite! Please verify your email.');
        setTimeout(() => {
          history.push('/verify/sent');
        }, 2000);
      }
    } catch (err) {
      setError(translateErrorMessage(err));
    }
    setLoading(false);
  };

  const signInWithGoogle = async () => {
    setLoading(true);

    const authorization_url = await Auth.google({
      redirect_uri: `${window.location.origin}/dashboard`,
      is_signup: undefined,
    });

    if (!authorization_url) {
      setError('Something went wrong');
    } else {
      window.open(authorization_url, '_self');
    }
    setLoading(false);
  };

  return (
    <FormLayout h1="Create your profile" h2="" error={error}>
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ email: queryString.parse(window.location.search)?.email, first_name: '', last_name: '' }}
        id="accept-invite-form"
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <div style={{ flexGrow: 1 }}>
            <Form.Item
              style={{ marginBottom: '12px' }}
              label={<CmdLabel>First name</CmdLabel>}
              name="first_name"
              id="first_name"
              rules={[
                { required: true },
                { type: 'string', max: 20, min: 2 },
                { pattern: new RegExp(/^[a-z ,.'-]+$/i), message: 'Numbers and Special Characters not allowed' },
              ]}
            >
              <CmdInput fullWidth placeholder="First name" />
            </Form.Item>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Form.Item
              style={{ marginBottom: '12px' }}
              label={<CmdLabel>Last name</CmdLabel>}
              name="last_name"
              validateTrigger=""
              id="last_name"
              rules={[
                { required: true },
                { type: 'string', max: 20, min: 2 },
                { pattern: new RegExp(/^[a-z ,.'-]+$/i), message: 'Numbers and Special Characters not allowed' },
              ]}
            >
              <CmdInput fullWidth placeholder="Last name" />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          style={{ marginBottom: '12px' }}
          label={<CmdLabel>Work email</CmdLabel>}
          name="email"
          id="email"
          rules={[{ type: 'email', max: 30, min: 3, message: 'Invalid Email Address' }]}
          required
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item style={{ marginBottom: '12px' }} label={<CmdLabel>Password</CmdLabel>} name="password" required>
          <Input.Password
            placeholder="Enter your password"
            autoComplete="new-password"
            onChange={(e) => {
              setPassword(e.target.value);
              form.setFieldsValue({ password: e.target.value });
            }}
          />
          {password ? (
            <PasswordStrengthBar password={password} minLength={12} scoreWordStyle={{ fontSize: 10 }} />
          ) : (
            <div style={{ height: '28px' }} />
          )}
        </Form.Item>

        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={loading} loading={loading}>
            Accept invite
          </CmdButton>
        </Form.Item>
      </Form>
      <CmdDivider spacing="sm" orientation="center">
        OR
      </CmdDivider>
      <CmdButton fullWidth onClick={signInWithGoogle}>
        <GoogleBrand />
        Sign in with Google
      </CmdButton>
      <div style={{ padding: '24px 12% 0px', overflowWrap: 'break-word', color: '#8e8e8e', textAlign: 'center' }}>
        By using Command AI, you are agreeing to our <a href="https://commandbar.com/terms">Terms of Service</a> and{' '}
        <a href="https://commandbar.com/privacy">Privacy Policy</a>
      </div>
    </FormLayout>
  );
};

export default AcceptInvite;
