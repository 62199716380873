import { useContext, createContext } from 'react';
import { Source, IntegrationSpec } from '../integrations/shared/types';
import { PROCESSING_OPERATION } from '../content/sources/SourceProcessingProgress';

export interface IntegrationsContextProps {
  organizationId: string | number | undefined;
  helpDocIntegrations: IntegrationSpec[];
  refreshHelpDocIntegrations: () => Promise<IntegrationSpec[]>;
  hasHelpDocIntegrations: boolean;
  loadingSources: Map<Source['id'], PROCESSING_OPERATION>;
  addLoadingSource: (sources: Source['id'], operation?: PROCESSING_OPERATION) => void;
  removeLoadingSource: (sources: Source['id']) => void;
}

const IntegrationsContext = createContext<IntegrationsContextProps>({
  organizationId: undefined,
  helpDocIntegrations: [],
  refreshHelpDocIntegrations: async () => {
    return [];
  },
  hasHelpDocIntegrations: false,
  loadingSources: new Map(),
  addLoadingSource: () => {
    return new Map();
  },
  removeLoadingSource: () => {
    return new Map();
  },
});

export const useIntegrationsContext = (): IntegrationsContextProps => useContext(IntegrationsContext);

export default IntegrationsContext;
