import { useState } from 'react';
import { formatPercentage, timeAgoFormat } from '../../utils';

import { CmdColumnDef, CmdSortableColumn, CmdSwitch, CmdTooltip } from '@commandbar/design-system/cmd';
import { TableRowData } from '@commandbar/internal/middleware/analytics/common';
import StatCard from '../../components/StatCard';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import Layout from '../../components/Layout';
import { StatusBadge } from '@commandbar/design-system/components/antd';
import ActionDropdown from '../../components/ActionDropdown';
import { PencilLine, Plus } from '@commandbar/design-system/icons/react';
import useAnalyticChecklist from './useAnalyticChecklist';
import dayjs from 'dayjs';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as checklistSearchParams from '@commandbar/internal/proxy-editor/questlist_search_params';
import { AnalyticsPage, getAnalyticsDashboardRoute } from 'commandbar.com/src/components/Home';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const ChecklistsDashboard = () => {
  const [showUnpublished, setShowUnpublished] = useState<boolean>(false);
  const { user } = useAuth();

  const { data, isLoading, error, fetchChecklistData, downloadCsv, isDownloading } = useAnalyticChecklist({
    showUnpublished,
  });

  const columns: CmdColumnDef<TableRowData, string>[] = [
    {
      accessorKey: 'title',
      header: 'Checklist',
      enableGlobalFilter: true,
      cell: (r) => {
        const checklistId = r.row.original.checklist_id;
        const isLive = r.row.original.is_live;

        return (
          <Link to={`/analytics/checklists/${checklistId}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {showUnpublished && <StatusBadge color={isLive ? 'green' : 'orange'} />}
              {r?.getValue() || 'Checklist'}
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: 'total_items',
      header: ({ column }) => <CmdSortableColumn column={column} title="Items" />,
    },
    {
      accessorKey: 'total_views',
      header: ({ column }) => <CmdSortableColumn column={column} title="Views" />,
    },
    {
      accessorKey: 'completion_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Completion rate" />,
      cell: ({ getValue }) => formatPercentage(getValue()),
    },
    {
      accessorKey: 'last_shown',
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen" />,
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{timeAgoFormat(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => (
        <ActionDropdown
          options={[
            {
              label: 'Edit',
              icon: <PencilLine />,
              url: `/editor/checklists/${row.original.checklist_id}`,
            },
          ]}
        />
      ),
    });
  }

  return (
    <Layout title="Checklists" disableDemoData={isLoading} onDownloadCSV={downloadCsv} isDownloading={isDownloading}>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <StatCard
            title="Active"
            subtitle="Total live checklists"
            format="number"
            metric={data?.active?.metric}
            isLoading={isLoading}
          />
          <StatCard
            title="Completion rate"
            subtitle="Avg completion rate for all checklists started"
            format="percentage"
            metric={data?.completion_rate?.metric}
            rate={data?.completion_rate?.percent_change}
            isLoading={isLoading}
          />
        </div>
        <div>
          <Table
            columns={columns}
            isLoading={isLoading}
            data={data?.table.rows || []}
            empty={{
              error: !!error,
              refetchData: fetchChecklistData,
              additionalActions: [
                {
                  label: 'Create a checklist',
                  icon: <Plus />,
                  to: `/editor${editorRoutes.CHECKLIST_ROUTE}/new?${
                    checklistSearchParams.FROM
                  }=${getAnalyticsDashboardRoute(AnalyticsPage.CHECKLISTS)}`,
                },
              ],
            }}
            toolBarChildren={
              <div>
                <CmdSwitch
                  checked={showUnpublished}
                  onCheckedChange={() => setShowUnpublished(!showUnpublished)}
                  onLabel="Show unpublished, but previously viewed Checklists"
                  offLabel="Show unpublished, but previously viewed Checklists"
                  tooltip="Checklists that are (a) unpublished; (b) have at least one view."
                />
              </div>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default ChecklistsDashboard;
