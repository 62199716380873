import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { nanoid } from 'nanoid';
import { Tooltip } from '@commandbar/design-system/components/antd';

import type { RequestType, Action } from '@cb/types/entities/command/actions';
import type { ITemplate, ITemplateOptions } from '@cb/types/entities/command/template';
import type { IEditorCommandType } from '@commandbar/internal/middleware/types';

import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import DetailLayout from './DetailLayout';
import * as Command from '@commandbar/internal/middleware/command';

import VisualCommandEditor from './VisualCommandEditor/VisualCommandEditor';
import { getNewSortkey } from '../../components/SortableTable';
import Logger from '@commandbar/internal/util/Logger';
import Sender from '../../../management/Sender';
import { argumentTypes, IOptionType } from './arguments/argumentUtils';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { commandCanBeSuggestedInCopilot, deleteCommandWithWarnings } from '../utils';

import produce from 'immer';
import { useAppContext } from 'editor/src/AppStateContext';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import { PreviewButton } from '../../components/PreviewButton';
import { ACTIONS_ROUTE, PAGES_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { CmdButton, CmdSwitch, CmdTag, cmdToast } from '@commandbar/design-system/cmd';
import { Trash04 } from '@commandbar/design-system/icons/react';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';
import { capitalize } from 'lodash';
import { getCommandType } from '../../PagesOrActions';
import { isConditionGroupValid } from '../../conditions/validate';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';

export interface ICommandDetailDispatch {
  argument: {
    new: (nameToAdd: string, order_key: number) => void;
    updateName: (oldName: string, newName: string) => void;
    updateValue: (argName: string, newValue: any) => void;
    delete: (nameToDelete: string) => void;
    reorder: (oldIndex: number, newIndex: number) => void;
  };
  template: {
    updateType: (newType: ITemplate['type']) => void;
    updateValue: (e: string | RequestType | string[] | Action, index?: number) => void;
    updateOptions: (option: ITemplateOptions) => void;
    addClickElem: () => void;
    deleteClickElem: (valueIndexToDelete: number) => void;
  };
  recordAction: {
    showInDefaultList: () => void;
    hideInDefaultList: () => void;
  };
  save: () => void;
  updateCommand: (recipe: (draft: IEditorCommandType) => void) => void;
}

interface CommandDetailProps {
  initialCommand: IEditorCommandType;
  onClose: () => void;
  onDelete: () => void;
}

const CommandDetail = ({ initialCommand, onClose, onDelete }: CommandDetailProps) => {
  const { dispatch, commands, organization, hasUnsavedChangesRef } = useAppContext();
  const { hasRouter } = useWindowInfo();
  const history = useHistory();
  const appState = useAppContext();
  const { user } = useAuth();

  const [dirty, setDirty] = React.useState<IEditorCommandType>(initialCommand);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  const beforeUnloadListener = (e: any) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = '';
      cmdToast.warning('You have unsaved command changes.');
    }
  };

  useModS(() => saveCommand());

  const updateCommand = (recipe: (draft: IEditorCommandType) => void) => {
    setDirty((currentCommand) =>
      produce(currentCommand, (draft) => {
        recipe(draft);
      }),
    );
    setIsDirty(true);
  };

  const isShownInDefaultList = (): boolean => {
    return Command.showInDefaultList(dirty);
  };

  const isRecordAction = (): boolean => {
    return Command.isRecordAction(dirty);
  };

  const setDefaultCommandForRecord = (option: string, defaultCommandId?: number) => {
    const updatedOrganization = {
      ...organization,
      resource_options: {
        ...organization.resource_options,
        [option]: { ...organization.resource_options[option], default_command_id: defaultCommandId },
      },
    };

    return dispatch.organization.update(updatedOrganization);
  };

  const getCommandsForResource = (resourceKey?: string) => {
    return resourceKey ? commands.filter((command) => command.template.object === resourceKey) : [];
  };

  const openCommand = () => {
    Sender.setTestMode(true);
    Sender.openBar(dirty.text);
  };

  const turnOffShowInDefaultList = () => {
    if (!isShownInDefaultList()) return;

    updateCommand((draft) => {
      let contextArg;
      for (const arg of Object.values(draft.arguments)) {
        if (
          arg.type === 'context' &&
          !!(arg?.show_in_record_action_list ?? true) &&
          !!(arg?.show_in_default_list ?? true)
        ) {
          contextArg = arg;
        }
      }

      if (contextArg) {
        // wipe fields not relevant to record action
        draft.category = null;
        draft.availability_rules = [];
        draft.recommend_rules = [];
        draft.shortcut = [];
        draft.shortcut_mac = [];
        draft.shortcut_win = [];
        draft.hotkey_mac = '';
        draft.hotkey_win = '';

        contextArg.show_in_default_list = false;
      }

      draft.show_preview = false;
    });
  };

  const turnOnShowInDefaultList = () => {
    if (isShownInDefaultList()) return;

    updateCommand((draft) => {
      let contextArg;

      for (const arg of Object.values(draft.arguments)) {
        if (
          arg.type === 'context' &&
          !!(arg?.show_in_record_action_list ?? true) &&
          !(arg?.show_in_default_list ?? true)
        ) {
          contextArg = arg;
        }
      }

      if (contextArg) {
        contextArg.show_in_default_list = true;
      }
    });
  };

  const saveCommand = async () => {
    setIsSaving(true);

    try {
      // Make any necessary modifications to the command before saving
      const newCommand = produce(dirty, (draft: IEditorCommandType) => {
        // If command is of type video, automagically append a video argument
        if (draft.template.type === 'video') {
          const videoSource = draft.template.value;

          draft.arguments['__video__'] = {
            is_private: true,
            type: 'video',
            order_key: Object.keys(draft.arguments).length
              ? Math.max(...Object.values(draft.arguments).map((a) => a.order_key), 0) + 1
              : 0,
            value: {
              source: videoSource,
              title: draft.text,
              description: draft.explanation,
            },
          };
        }

        if (Array.isArray(draft.content)) {
          draft.content = draft.content.map((content) => {
            if (typeof content === 'string') {
              return sanitizeHtml(content);
            }

            if (content.type === 'html') {
              return sanitizeHtml(content.value);
            }

            return content;
          });
        } else if (typeof draft.content === 'string') {
          draft.content = sanitizeHtml(draft.content);
        } else if (draft.content?.type === 'html') {
          draft.content.value = sanitizeHtml(draft.content.value);
        }

        if (Array.isArray(draft.detail)) {
          draft.detail = draft.detail.map((detail) => {
            if (typeof detail === 'string') {
              return detail;
            }

            if (detail.type === 'html') {
              return sanitizeHtml(detail.value);
            }

            return detail;
          });
        } else if (typeof draft.detail === 'string') {
          draft.detail = sanitizeHtml(draft.detail);
        } else if (draft.detail?.type === 'html') {
          draft.detail.value = sanitizeHtml(draft.detail.value);
        }

        for (const arg of Object.values(draft.arguments)) {
          if (arg.type === 'html') {
            arg.value.source = sanitizeHtml(arg.value.source);
          }
        }

        // Set Spotlight/HH search defaults for new commands
        if (draft.id === -1) {
          if (draft.template.type === 'video') {
            draft.show_in_spotlight_search = true;
            draft.show_in_helphub_search = true;
          } else if (draft.template.type === 'helpdoc') {
            draft.show_in_spotlight_search = organization.in_bar_doc_search;
            draft.show_in_helphub_search = true;
          } else {
            draft.show_in_spotlight_search = true;
            draft.show_in_helphub_search = false;
          }
        }
      });

      const res = await dispatch.commands.save(newCommand);

      // Only update the history if we're creating a new command
      if (newCommand.id === -1) {
        const type = getCommandType(res);
        if (type === 'page') {
          history.replace(`${PAGES_ROUTE}/${res.id}`);
        } else {
          history.replace(`${ACTIONS_ROUTE}/${res.id}`);
        }
      }

      const recordKey = res.template.object;

      if (!!recordKey) {
        const commandsForResource = getCommandsForResource(recordKey);
        const isCurrentCommandFirstForRecord = commandsForResource.length === 1;

        // If the current command is the first command for a record, make it the default
        if (isCurrentCommandFirstForRecord) {
          await setDefaultCommandForRecord(recordKey, res.id);
        }
      }
      setIsDirty(false);

      return res;
    } catch (err) {
      Logger.red('Save Error: ', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCommandDeleteClick = async () => {
    await deleteCommandWithWarnings({
      commandToDelete: initialCommand,
      onCommandDelete: onDelete,
      appState: appState,
    });
  };

  const onCommandTypeChange = (nextType: ITemplate['type']) => {
    updateCommand((draft) => {
      if (draft.template.type === 'video' && nextType !== 'video') {
        delete draft.arguments.__video__;
      }

      if (draft.template.type === 'helpdoc' && nextType !== 'helpdoc') {
        delete draft.arguments.__html__;
      }

      switch (nextType) {
        case 'clickBySelector':
        case 'clickByXpath':
        case 'click':
          draft.template.type = nextType;
          draft.template.value = [];
          break;
        case 'link':
          const defaultLinkType = hasRouter ? 'router' : 'blank';

          draft.template.type = nextType;
          draft.template.value = '';
          draft.template.operation = defaultLinkType;
          break;
        case 'request':
          draft.template.type = nextType;
          draft.template.value = {
            url: '',
            method: 'get',
          };

          break;
        case 'trigger':
          draft.template.type = nextType;
          draft.template.value = {
            type: 'no_action',
          };

          break;
        case 'helpdoc':
        case 'video':
          draft.show_in_helphub_search = true;
          break;
        default:
          draft.show_in_helphub_search = false;
          draft.template.type = nextType;
          draft.template.value = '';
          break;
      }
    });
  };

  const validateCommand = () => {
    const {
      text,
      arguments: args,
      hotkey_mac,
      hotkey_win,
      availability_expression,
      recommend_expression,
      always_recommend,
      category,
    } = dirty;
    const isEveryArgumentValid = Object.keys(args).find((arg) => arg.length === 0) === undefined;

    const isEveryArgumentTypeValid = Object.keys(args).every((arg) => {
      const { type } = args[arg];
      return ['context', 'provided', 'set', 'html', 'video'].includes(type);
    });

    const [isEveryMacShortcutValid] = Hotkey.validateEditorShortcutValues(hotkey_mac);
    const [isEveryWinShortcutValid] = Hotkey.validateEditorShortcutValues(hotkey_win);
    const isEveryShortcutValid = isEveryMacShortcutValid && isEveryWinShortcutValid;

    const isEveryAvailabilityRuleValid = isConditionGroupValid(availability_expression);
    const isAlwaysRecommend = always_recommend;
    const isEveryRecommendationRuleValid = isAlwaysRecommend || isConditionGroupValid(recommend_expression);
    const isEveryRuleValid = isEveryAvailabilityRuleValid && isEveryRecommendationRuleValid;

    const hasCategoryIfShownInDefault = isShownInDefaultList() ? category !== null : true;

    return (
      !!text &&
      isEveryArgumentValid &&
      isEveryArgumentTypeValid &&
      isEveryShortcutValid &&
      isEveryRuleValid &&
      hasCategoryIfShownInDefault
    );
  };
  const checkCanSaveCommand = (): boolean => {
    if (isSaving) {
      return false;
    }

    const isValidAndDirty = (validateCommand() && isDirty) || dirty.id === -1;

    return isValidAndDirty;
  };

  const canSave = checkCanSaveCommand();

  useEffect(() => {
    hasUnsavedChangesRef.current = canSave;
  }, [canSave]);

  const updateArgument = (arg: string, val: any, replaceValue?: boolean) => {
    updateCommand((draft) => {
      const newArgs = replaceValue ? { ...val } : { ...draft.arguments[arg], ...val };
      draft.arguments[arg] = newArgs;
    });
  };

  const updateArgumentName = (oldName: string, newName: string) => {
    if (oldName !== newName) {
      updateCommand((draft) => {
        draft.arguments[newName] = draft.arguments[oldName];
        delete draft.arguments[oldName];
      });
    }
  };

  const updateArgumentOrder = (oldIndex: number, newIndex: number) => {
    updateCommand((draft) => {
      const newArgs = Object.keys(draft.arguments).reduce<Record<string, any>>((acc, cur) => {
        const item = draft.arguments[cur];
        acc[cur] = {
          ...item,
          order_key: getNewSortkey(item.order_key, oldIndex, newIndex),
        };

        return acc;
      }, {});

      draft.arguments = newArgs;
    });
  };

  const addArgument = (toAdd: string, order_key: number) => {
    const defaultPrompt = argumentTypes.find((option: IOptionType) => option.key === 'text')?.defaultPrompt;
    const defaultArgType = { type: 'provided' as any, value: 'text', order_key, label: defaultPrompt, id: nanoid() };

    updateCommand((draft) => {
      draft.arguments[toAdd] = defaultArgType;
    });
  };

  const deleteArgument = (toDelete: string) => {
    updateCommand((draft) => {
      delete draft.arguments[toDelete];
    });
  };

  const onClickElemListAdd = () => {
    updateCommand((draft) => {
      if (Array.isArray(draft.template.value)) {
        draft.template.value.push('');
      }
    });
  };

  const onClickElemListDelete = (index: number) => {
    updateCommand((draft) => {
      if (Array.isArray(draft.template.value)) {
        draft.template.value.splice(index, 1);
      }
    });
  };

  const onTemplateValueChange = (e: string | RequestType | string[] | Action, index?: number) => {
    updateCommand((draft) => {
      if (typeof e === 'object') {
        draft.template.value = e;
      } else {
        if (index === undefined) {
          draft.template.value = e.trim();
        } else {
          if (Array.isArray(draft.template.value)) {
            // FIXME: clean up draft.templateValue
            draft.template.value.splice(index, 1, e);
          }
        }
      }
    });
  };

  const onTemplateOptionsChange = (option: ITemplateOptions) => {
    updateCommand((draft) => {
      Object.entries(option).forEach(([key, value]) => {
        // @ts-expect-error: FIXME types
        draft.template[key] = value;
      });
    });
  };

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, dirty.is_live ? 'editor' : 'contributor');

  const disabledTooltip = () => {
    if (!dirty.text || (dirty.category === null && !isRecordAction())) {
      return 'Commands must have a Title and Category before saving.';
    }

    if (dirty.copilot_suggest && !dirty.copilot_description) {
      return 'Command is enabled for suggestion in copilot but has no description. The description is required to help Copilot make better suggestions.';
    }

    if (dirty.copilot_suggest && !commandCanBeSuggestedInCopilot(dirty.arguments)) {
      return 'Only text, date, and set arguments are allowed if a command is suggested in copilot.';
    }

    if (dirty.template.type === 'link' && !dirty.template.value) {
      return 'Pages must have a URL before saving.';
    }

    if (!isAllowedToSave) {
      return 'You do not have permissions to perform this action';
    }

    return '';
  };

  const updateCommandState: ICommandDetailDispatch = {
    argument: {
      new: addArgument,
      updateName: updateArgumentName,
      updateValue: updateArgument,
      delete: deleteArgument,
      reorder: updateArgumentOrder,
    },
    template: {
      updateType: onCommandTypeChange,
      updateValue: onTemplateValueChange,
      updateOptions: onTemplateOptionsChange,
      addClickElem: onClickElemListAdd,
      deleteClickElem: onClickElemListDelete,
    },
    updateCommand: updateCommand,
    save: saveCommand,
    recordAction: {
      showInDefaultList: turnOnShowInDefaultList,
      hideInDefaultList: turnOffShowInDefaultList,
    },
  };

  const rightActions = [
    ...(dirty.third_party_source != null
      ? [
          <Tooltip
            key={'right-tooltip-third-party-source'}
            content={'Command imported via ' + capitalize(dirty.third_party_source)}
          >
            <CmdTag variant="info"> {dirty.third_party_source} </CmdTag>
          </Tooltip>,
        ]
      : []),
    <CmdButton
      key={`right-button-${dirty.id}`}
      disabled={dirty.id < 0 || !isAllowedToSave}
      onClick={handleCommandDeleteClick}
      style={{ color: 'rgba(0, 0, 0, 0.5)' }}
      icon={<Trash04 />}
      variant="link"
    />,
    <Tooltip
      key={`right-tooltip-status-${dirty.id}`}
      content={<span>You do not have permissions to perform this action</span>}
      showIf={!isAllowedToPublish}
    >
      <CmdSwitch
        checked={dirty.is_live}
        onCheckedChange={(is_live: boolean) =>
          updateCommand((draft) => {
            draft.is_live = is_live;
          })
        }
        onLabel="Live"
        offLabel="Draft"
        disabled={!validateCommand() || isSaving || !isAllowedToPublish}
      />
    </Tooltip>,
    ...(isShownInDefaultList()
      ? [
          <PreviewButton
            onClick={() => {
              Sender.hideEditor();
              openCommand();
            }}
            enableClickInStudio
          />,
        ]
      : []),
    <Tooltip
      key={`right-tooltip-save-${dirty.id}`}
      content={disabledTooltip()}
      hideOnClick={true}
      placement="bottom"
      showIf={!!disabledTooltip()}
    >
      <CmdButton
        key={`right-button-save-${dirty.id}`}
        onClick={() => saveCommand()}
        variant="primary"
        disabled={!canSave || !!disabledTooltip()}
        style={{
          ...(!!disabledTooltip() && {
            border: '1px solid rgb(185, 28, 28)',
            boxShadow: '0px 0px 0px 2px rgba(185, 28, 28, .3)',
          }),
        }}
      >
        Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
      </CmdButton>
    </Tooltip>,
  ];

  return (
    <>
      <div
        style={{
          background: '#F2F2F2',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <DetailLayout
          isRecordAction={isRecordAction()}
          showInDefaultList={isShownInDefaultList()}
          onGoBack={onClose}
          actions={rightActions}
          content={
            <VisualCommandEditor
              appState={appState}
              command={dirty}
              isDirty={isDirty}
              updateCommandState={updateCommandState}
              organization={organization}
            />
          }
          title={dirty.text}
          type={dirty.template.type}
        />
      </div>
    </>
  );
};

export default CommandDetail;
