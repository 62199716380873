import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme as _useTheme } from 'emotion-theming';

export default function useTheme() {
  return _useTheme<{ theme: ITheme }>();
}

/**
 * Can be used for margin, padding and css fields that follow a similar shorthand pater
 * Example:
 *  Input --> margin: 12px 4px
 *  Output --> { top: 12px, right: 4px, bottom: 12px, left: 4px }
 */
type TGetMargins = {
  top: string;
  right: string;
  bottom: string;
  left: string;
};
export function getMargins(css: string): TGetMargins {
  const margins = css.split(' ');
  switch (margins.length) {
    case 1: {
      return {
        top: margins[0],
        right: margins[0],
        bottom: margins[0],
        left: margins[0],
      };
    }
    case 2: {
      return {
        top: margins[0],
        right: margins[1],
        bottom: margins[0],
        left: margins[1],
      };
    }
    case 3: {
      return {
        top: margins[0],
        right: margins[1],
        bottom: margins[2],
        left: margins[1],
      };
    }
    case 4: {
      return {
        top: margins[0],
        right: margins[1],
        bottom: margins[2],
        left: margins[3],
      };
    }
    default: {
      return {
        top: '',
        right: '',
        bottom: '',
        left: '',
      };
    }
  }
}
