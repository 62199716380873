import { cva } from 'class-variance-authority';

export const breadcrumbStyles = cva(['list-none', 'flex', 'fit-content', 'p-0', 'm-0'], {
  variants: {
    listView: {
      true: ['flex-col', 'gap-4'],
    },
  },
});

export const breadcrumbItemStyles = cva(['font-medium', 'text-base', 'hover:text-gray800', 'cursor-pointer'], {
  variants: {
    variant: {
      default: ['text-contentBase'],
      active: ['text-gray1000'],
    },
  },
});
