import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { SurveyResponseCondition as SurveyResponseConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Input, Row, Select, Tooltip } from '@commandbar/design-system/components/antd';
import { getDisplayTitle } from '@commandbar/internal/util/nudges';
import { ElementNotFoundIcon } from 'editor/src/editor/nudges/NudgeContentForm/Header';
import { hasSurveyBlock, isSurveyBlock, SurveyBlock } from 'editor/src/editor/nudges/utils';
import { CaretDown } from 'editor/src/editor/helphub/shared';
import { SurveyType } from '@commandbar/internal/middleware/helpers/rules';
import { OperatorType } from '../../types';
import { getSurveyValues } from 'editor/src/editor/nudges/NudgeContentForm/CTAForm';
import { camelCaseToRegular } from '../../helpers';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'editor/src/AppStateContext';

export const SurveyResponseCondition = () => {
  const context = useConditionEditor();
  const { nudges } = useAppContext();
  const params = useParams<{ nudgeId?: string }>();

  const { onConditionChange, disabled, setHasBlurred } = context;
  const condition = context.condition as SurveyResponseConditionType;

  const nudge = nudges.find((nudge) => nudge.id === condition.nudge_id);
  const nudgeStep = nudge?.steps.find((step) => step.id === condition.nudge_step_id);

  const nudgeSteps = nudge?.steps
    .map((step, i) => ({
      ...step,
      index: i,
    }))
    .filter((step) => {
      return step.content.some(isSurveyBlock);
    })
    .map((step) => ({
      label: `${step.title} (step ${step.index + 1})`,
      value: step.id,
    }));

  const operators = () => {
    let options = [];

    switch (condition.response_type) {
      case 'text':
        options = ['includes', 'doesNotInclude'];
        break;
      case 'list':
        options = ['is', 'isNot'];
        break;
      case 'rating':
        options = ['is', 'isNot', 'isGreaterThan', 'isLessThan'];
        break;
      default:
        throw new Error('Invalid response type');
    }

    return options.map((operator) => ({
      label: camelCaseToRegular(operator),
      value: operator,
    }));
  };

  let error = '';
  if (condition.nudge_id !== -1) {
    if (!nudge) {
      error = 'The selected Nudge does no longer exists.';
    } else if (condition.value === 'dismissed' && !nudge.dismissible) {
      error = 'The selected Nudge is not dismissable';
    } else if (!nudge.is_live) {
      error = 'The selected Nudge is not currently live.';
    }
  }
  const renderValueInput = () => {
    switch (condition.response_type) {
      case 'text':
        return (
          <Col flex="1 0 33%">
            <Input
              disabled={disabled}
              value={condition.value ?? ''}
              placeholder="value"
              style={{ width: '100%', height: '24px' }}
              onBlur={() => setHasBlurred(true)}
              onChange={(e) =>
                onConditionChange({
                  ...condition,
                  value: e.target.value.trim(),
                })
              }
              size="small"
            />
          </Col>
        );
      case 'list':
      case 'rating': {
        if (nudge) {
          const surveyBlock = nudgeStep?.content.find(isSurveyBlock);

          const surveyValues = getSurveyValues(surveyBlock).map(({ label, value }) => ({
            label,
            value: value.toString(),
          }));

          return (
            <Col style={{ overflow: 'hidden' }} flex="1 0 33%">
              <Select
                suffixIcon={<CaretDown />}
                dropdownMatchSelectWidth={false}
                value={condition.value}
                options={surveyValues}
                onChange={(value) => onConditionChange({ ...condition, value })}
                style={{ width: '100%' }}
                size="small"
              />
            </Col>
          );
        }
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Row align="middle" wrap={false} style={{ width: '100%', gap: '4px' }}>
        <ConditionTypeSelect />

        <Col flex="1 1 auto" style={{ overflow: 'hidden' }}>
          <Select
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
            value={nudge?.id as number | undefined}
            options={nudges
              .filter((n) => n.id !== params.nudgeId)
              .filter(hasSurveyBlock)
              .map((n) => ({
                label: getDisplayTitle(n),
                value: n.id,
              }))}
            onChange={(nudge_id) => {
              onConditionChange({
                ...condition,
                nudge_id,
              });
            }}
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<CaretDown />}
            placeholder="Select a nudge..."
            optionLabelProp="label"
          />
        </Col>

        {error && (
          <Tooltip showIf={!!error} placement="bottom" content={error}>
            <Row align="middle">
              <ElementNotFoundIcon />
            </Row>
          </Tooltip>
        )}
      </Row>
      <Row style={{ width: '100%', gap: '4px' }} align="middle" wrap={false}>
        <Col flex="1 1 33%" style={{ overflow: 'hidden' }}>
          <Select
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
            value={nudge && nudgeStep ? nudgeStep.id : undefined}
            options={nudgeSteps}
            onChange={(nudge_step_id) => {
              if (nudge) {
                const nudgeStep = nudge.steps.find((step) => step.id === nudge_step_id);

                const selectedSurveyBlock = nudgeStep?.content.find((block): block is SurveyBlock =>
                  block.type.startsWith('survey'),
                );

                if (selectedSurveyBlock) {
                  const getSelectedSurveyType = (
                    surveyBlock: SurveyBlock,
                  ): { response_type: SurveyType; response_operator: OperatorType } => {
                    switch (surveyBlock.type) {
                      case 'survey_text_short':
                      case 'survey_text':
                        return { response_type: 'text', response_operator: 'includes' };
                      case 'survey_rating':
                        return { response_type: 'rating', response_operator: 'is' };
                      case 'survey_list':
                        return { response_type: 'list', response_operator: 'is' };
                    }
                  };

                  onConditionChange({
                    ...condition,
                    ...getSelectedSurveyType(selectedSurveyBlock),
                    value: '',
                    nudge_step_id,
                  });
                }
              }
            }}
            showSearch
            filterOption={(input, option) =>
              typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
            }
            suffixIcon={<CaretDown />}
            placeholder="Select a step..."
            optionLabelProp="label"
          />
        </Col>

        <Col flex="1 0 33%" style={{ overflow: 'hidden' }}>
          <Select
            disabled={disabled}
            value={condition.response_operator}
            onChange={(operator) => onConditionChange({ ...condition, response_operator: operator })}
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            onBlur={() => setHasBlurred(true)}
            size="small"
            options={operators()}
          />
        </Col>

        {renderValueInput()}
      </Row>
    </div>
  );
};
