import React from 'react';
import { OptionProps } from 'react-select';
import ActionTypeSelectorOption from './ActionTypeSelectorOption';
import { SelectorOption } from '../ActionTypeSelector';

const OptionWrapper = (props: OptionProps<SelectorOption, false>) => {
  const {
    data,
    innerProps: { onClick },
    isSelected,
  } = props;

  const { label, description, icon, color } = data as SelectorOption;

  return (
    <ActionTypeSelectorOption
      label={label}
      description={description}
      icon={icon}
      color={color}
      selected={isSelected}
      onClick={onClick}
    />
  );
};

export default OptionWrapper;
