/** @jsx jsx */
/** @jsxFrag */
import { jsx, keyframes } from '@emotion/core';
import React, { useCallback, type FC, type MouseEventHandler, useMemo, type CSSProperties } from 'react';

import { botAvatarDefaultImageSrc } from '@commandbar/commandbar/products/copilot/components/BotAvatar';
import { type Coordinates2D, roundByDPR } from '@commandbar/commandbar/products/nudges/components/utils';
import Icon from '@commandbar/commandbar/shared/components/Icon';
import { BindThemeV2ForNudge, useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import { DotsHorizontal, XClose } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';
import StyledCloseButtonContainer from '@commandbar/internal/client/themesV2/components/nudge/StyledCloseButtonContainer';
import StyledNudgePin from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgePin';
import StyledNudgeStepCounter from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeStepCounter';
import StyledNudgeTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';
import StyledTertiaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledTertiaryIconButton';
import type {
  INudgeCursorStepType,
  INudgeStepContentMarkdownBlockType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import { getStyles } from '..';
import { useLinkClickHandler } from '../../../hooks/useLinkClickHandler';
import { getNudgeService, isNudgeSnoozable } from '../../../store/selectors';
import { Actions } from '../../Actions';
import Media from '../../ContentContainer';
import { LayoutTemplate } from '../../LayoutTemplate';
import { RenderMode } from '../../RenderNudge';
import { SurveyResponseProvider } from '../../SurveyResponseProvider';
import { TestID } from './constants';
import { generatePathAround } from './helpers';
import {
  CURSOR_INSET,
  CursorMachineContext,
  CursorMachineContextProvider,
  MIN_MARGIN,
  POP_IN_DURATION,
  SQUIRREL_DURATION,
  TRACING_DURATION,
  TRANSLATE_DURATION,
  stateSelectors,
} from './stateMachine';

import { ReactComponent as ArrowIcon } from './arrow.svg';

/************************************
 * Arrow
 ************************************/

const ARROW_WIDTH = 56;

const trace = keyframes`
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
`;

const Arrow = () => {
  const placement = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.placement);
  const isTracingTargetElement = CursorMachineContext.useSelector(stateSelectors.isTracingTargetElement);
  const isShowingPin = CursorMachineContext.useSelector(stateSelectors.isShowingPin);
  const [x, y] = CursorMachineContext.useSelector(({ context }) => context.cursor.position.arrow.coordinates);

  return (
    <ArrowIcon
      style={{
        transition: isShowingPin ? `transform ${SQUIRREL_DURATION}ms cubic-bezier(0.78, -0.28, 0.07, 1)` : undefined,
        transform:
          placement === 'right-start'
            ? `translate(${roundByDPR(x)}px, ${roundByDPR(y)}px)`
            : `rotate(90deg) translate(${roundByDPR(x)}px, ${roundByDPR(y)}px)`,
        offsetRotate: '0deg',
      }}
      css={
        isTracingTargetElement
          ? {
              offsetPath: `path("${generatePathAround(
                50,
                30,
                placement === 'right-start' ? 'counter-clockwise' : 'clockwise',
              )}")`,
              transformOrigin: placement === 'right-start' ? '0px 0px' : '-2px 58px',
              animation: `${trace} ${TRACING_DURATION}ms forwards cubic-bezier(0.45, 0, 0.55, 1)`,
            }
          : undefined
      }
    />
  );
};

/************************************
 * Body
 ************************************/

const popOut = keyframes`
  from {
    scale: 1;
    opacity: 100%;
  }
  to {
    scale: 0.8;
    opacity: 20%;
  }
`;

const ringBorder = keyframes`
  0% {
    scale: 1;
    opacity: 1;
  }
  75%, 100% {
    scale: 1.25;
    opacity: 0;
  }
`;

const Partial = () => {
  const _ = useStore();
  const isPoppingIn = CursorMachineContext.useSelector(stateSelectors.isPoppingIn);
  const isFadingOut = CursorMachineContext.useSelector(stateSelectors.isSwappingBody);
  const placement = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.placement);

  return (
    <div
      style={{
        position: 'relative',
        gridRowStart: 1,
        gridColumnStart: 1,
        display: 'inline-flex',
        padding: 'var(--card-padding, 12px)',
        alignItems: 'center',
        gap: 'var(--card-gap, 8px)',
        borderRadius:
          placement === 'right-start'
            ? '0px var(--card-radius, 8px) var(--card-radius, 8px) var(--card-radius, 8px)'
            : 'var(--card-radius, 8px) 0px var(--card-radius, 8px) var(--card-radius, 8px)',
        border: '1px solid var(--border-primary, #E2E2E2)',
        background: 'var(--background-primary, #FFF)',
        boxShadow: '0px 4px 16px 0px shadow',
        justifySelf: placement === 'right-start' ? undefined : 'flex-end',
        transformOrigin: 'top left',
      }}
      css={{
        animation: isFadingOut ? `${popOut} 200ms forwards cubic-bezier(.42, 0, .59, 1.33)` : undefined,

        '&::before': {
          content: '""',
          width: '100%',
          height: '100%',
          zIndex: -1,
          background: 'var(--base-accent)',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0,
          borderRadius:
            placement === 'right-start'
              ? '0px var(--card-radius, 8px) var(--card-radius, 8px) var(--card-radius, 8px)'
              : 'var(--card-radius, 8px) 0px var(--card-radius, 8px) var(--card-radius, 8px)',
          animation: isPoppingIn ? `${ringBorder} 200ms forwards cubic-bezier(0, 0, 0.2, 1)` : undefined,
        },
      }}
    >
      <Icon
        icon={_.organization?.copilot_avatar_v2?.src || _.organization?.copilot_avatar || botAvatarDefaultImageSrc}
        style={{
          width: '32px',
          height: '32px',
        }}
      />

      <DotsHorizontal width="24px" height="24px" color="#7D7C78" />
    </div>
  );
};

interface CloseProps {
  closeStep: () => void;
}

const Close: FC<CloseProps> = ({ closeStep }) => {
  const themeV2 = useThemeV2Context();

  return (
    <StyledCloseButtonContainer>
      <StyledTertiaryIconButton themeV2={themeV2} data-testid={TestID.Close} aria-label="close" onClick={closeStep}>
        <XClose />
      </StyledTertiaryIconButton>
    </StyledCloseButtonContainer>
  );
};

interface HeaderProps {
  title: string;
  handleLinkClick: MouseEventHandler<HTMLElement>;
  content?: string;
}

const Header: FC<HeaderProps> = ({ title, content, handleLinkClick }) => (
  <StyledNudgeTitleBlock title={title} content={content} handleContentLinkClick={handleLinkClick} dismissible />
);

interface ContentWrapperProps {
  style: CSSProperties;
}

const ContentThemeWrapper: FC<ContentWrapperProps> = ({ children, style }) => {
  const _ = useStore();

  if (_.flags?.['release-themes-v2']) {
    return <>{children}</>;
  }

  return <div style={style}>{children}</div>;
};

interface ContentProps {
  nudge: INudgeType;
  step: INudgeCursorStepType;
  handleLinkClick: MouseEventHandler<HTMLElement>;
}

const Content: FC<ContentProps> = ({ nudge, step, handleLinkClick }) => {
  const _ = useStore();
  const { theme } = useTheme();
  const { isMobileDevice } = useMobileExperience();
  const styles = getStyles(
    theme,
    !isMobileDevice && step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
  );
  const nudgeService = getNudgeService(_, nudge.id);
  const stepIndex = nudgeService?.getSnapshot()?.context.stepIndex ?? 0;

  const stepCount =
    nudge.show_step_counter && stepIndex !== undefined && nudge.steps.length > 1
      ? `${stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  return (
    <ContentThemeWrapper
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: styles.body.padding,
        gap: '8px',
        marginTop: '-22px',
      }}
    >
      <Media
        step={step}
        service={nudgeService}
        markdownStyles={styles.content}
        primaryButtonStyles={styles.ctaButton}
        secondaryButtonStyles={styles.ctaSecondaryButton}
        snoozeButtonStyles={styles.snoozeButton}
        stepCountStyles={styles.stepCount}
        stepCount={stepCount}
        renderMode={RenderMode.DEFAULT}
        snoozable={isNudgeSnoozable(nudge)}
        snoozeLabel={nudge.snooze_label}
        handleContentLinkClick={handleLinkClick}
        stepIndex={stepIndex}
        snoozable_on_all_steps={nudge.snoozable_on_all_steps}
      />
    </ContentThemeWrapper>
  );
};

const popIn = keyframes`
  from {
    scale: 0.8;
    opacity: 40%;
  }
  to {
    scale: 1;
    opacity: 100%;
  }
`;

type FullProps = { nudge: INudgeType; step: INudgeCursorStepType };

const Full: FC<FullProps> = ({ nudge, step }) => {
  const _ = useStore();
  const { send } = CursorMachineContext.useActorRef();
  const closeStep = useCallback(() => {
    send('DESTROY');
  }, [send]);
  const handleLinkClick = useLinkClickHandler(closeStep);
  const placement = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.placement);
  const { isMobileDevice } = useMobileExperience();
  const nudgeService = getNudgeService(_, nudge.id);
  const stepIndex = nudgeService?.getSnapshot()?.context.stepIndex ?? 0;

  const stepCount =
    nudge.show_step_counter && stepIndex !== undefined && nudge.steps.length > 1
      ? `${stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  const close = <Close closeStep={closeStep} />;
  const header = (
    <Header
      title={step.title}
      content={
        step.content.find(
          (block): block is INudgeStepContentMarkdownBlockType =>
            !!_.flags?.['release-themes-v2'] && block.type === 'markdown',
        )?.meta.value
      }
      handleLinkClick={handleLinkClick}
    />
  );
  const media = <Content nudge={nudge} step={step} handleLinkClick={handleLinkClick} />;
  const actions = _.flags?.['release-themes-v2'] ? (
    <Actions
      step={step}
      renderMode={RenderMode.DEFAULT}
      snooze={{
        enabled: isNudgeSnoozable(nudge),
        label: nudge.snooze_label,
        enabledOnAllSteps: nudge.snoozable_on_all_steps,
        duration: nudge.snooze_duration,
      }}
      service={nudgeService}
      stepCount={stepCount}
      stepIndex={stepIndex}
    />
  ) : null;
  const stepCounter = stepCount ? <StyledNudgeStepCounter stepCount={stepCount} /> : null;

  return (
    <StyledNudgePin
      organization={_.organization}
      survey={!!step.content.find((block) => block.type === 'survey_rating')}
      data-testid={TestID.Content}
      aria-labelledby="commandbar-nudge-title"
      tabIndex={-1}
      style={{
        gridRowStart: 1,
        gridColumnStart: 1,
        borderTopLeftRadius: placement === 'right-start' ? 0 : undefined,
        borderTopRightRadius: placement === 'left-start' ? 0 : undefined,
        zIndex: Z.Z_HELPHUB + 1,
        transformOrigin: 'top left',
      }}
      css={{
        animation: `${popIn} 200ms forwards cubic-bezier(.42, 0, .59, 1.33)`,
      }}
    >
      <SurveyResponseProvider service={nudgeService} step={step}>
        <LayoutTemplate
          layout={isMobileDevice ? 'classic' : step.form_factor.layout}
          headerAndMarkdown={header}
          close={close}
          media={media}
          footer={null}
          stepCounter={stepCounter}
          actions={actions}
          columnWidth="var(--popover-width)"
        />
      </SurveyResponseProvider>
    </StyledNudgePin>
  );
};

type BodyProps = FullProps;

const Body: FC<BodyProps> = ({ nudge, step }) => {
  const isShowingPartial = CursorMachineContext.useSelector(stateSelectors.isShowingPartialBody);
  const isRenderingFull = CursorMachineContext.useSelector(stateSelectors.isRenderingFullBody);
  const placement = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.placement);

  return (
    <div
      style={{
        display: 'grid',
        position: 'relative',
        placeItems: 'start',
        top: '36px',
        marginLeft: placement === 'right-start' ? '-18px' : undefined,
        marginRight: placement === 'right-start' ? undefined : '-18px',
        width: 'var(--popover-width)',
      }}
    >
      {isShowingPartial && <Partial />}
      {isRenderingFull && <Full nudge={nudge} step={step} />}
    </div>
  );
};

/************************************
 * Main
 ************************************/

const Container: FC = ({ children }) => {
  const hasComputedPlacement = CursorMachineContext.useSelector(stateSelectors.hasComputedPlacement);
  const isMovingToTargetElement = CursorMachineContext.useSelector(stateSelectors.isMovingToTargetElement);
  const isHidingPin = CursorMachineContext.useSelector(stateSelectors.isHidingPin);
  const [x, y] = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.coordinates);
  const placement = CursorMachineContext.useSelector(({ context }) => context.cursor.position.container.placement);
  const ref = CursorMachineContext.useSelector(({ context }) => context.cursor.ref);

  const calcVisibility = () => {
    if (isHidingPin) {
      return 'hidden';
    }

    if (hasComputedPlacement) {
      return 'visible';
    }

    return 'hidden';
  };

  const cursorWidth = useMemo(() => ref.current?.getBoundingClientRect().width, [ref.current?.getBoundingClientRect]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: placement === 'right-start' ? 'row' : 'row-reverse',
        visibility: calcVisibility(),
        transform: `translate(${roundByDPR(x)}px, ${roundByDPR(y)}px)`,
        transition: isMovingToTargetElement
          ? `transform ${TRANSLATE_DURATION}ms cubic-bezier(0.78, -0.28, 0.07, 1)`
          : undefined,
        transformOrigin: `${
          placement === 'right-start' ? x : x + (cursorWidth ?? 0) + MIN_MARGIN + CURSOR_INSET + ARROW_WIDTH
        }px ${y}px`,
      }}
      css={{
        animation: hasComputedPlacement
          ? `${popIn} ${POP_IN_DURATION}ms forwards cubic-bezier(.42, 0, .59, 1.33)`
          : undefined,
      }}
    >
      {children}
    </div>
  );
};

interface CursorProps {
  nudge: INudgeType;
  step: INudgeCursorStepType;
  handleDestroy: () => void;
  sourceCoordinates: Coordinates2D;
  onTargetElementClick: (event: Event) => void;
}

export const Cursor: FC<CursorProps> = ({ nudge, step, handleDestroy, sourceCoordinates, onTargetElementClick }) => (
  <CursorMachineContextProvider
    anchor={step.form_factor.anchor_selector || step.form_factor.anchor}
    offset={step.form_factor.offset}
    startCoordinates={sourceCoordinates}
    handleDestroy={handleDestroy}
    shouldShowMask={step.form_factor.is_showing_mask}
    onTargetElementClick={onTargetElementClick}
  >
    <BindThemeV2ForNudge nudge={nudge}>
      <Container>
        <Arrow />
        <Body nudge={nudge} step={step} />
      </Container>
    </BindThemeV2ForNudge>
  </CursorMachineContextProvider>
);
