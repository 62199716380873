// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Pin02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13.103 3.646c.312-.727.468-1.091.72-1.256a1 1 0 0 1 .747-.144c.295.06.575.34 1.135.9l5.143 5.142c.56.56.84.84.9 1.135a1 1 0 0 1-.144.748c-.166.252-.53.408-1.257.72l-2.495 1.068c-.105.046-.158.068-.208.097a1.019 1.019 0 0 0-.126.085 2.1 2.1 0 0 0-.167.158l-1.56 1.56c-.128.128-.191.191-.242.264a.997.997 0 0 0-.11.207c-.033.082-.05.17-.086.347l-.733 3.665c-.19.952-.286 1.428-.537 1.65a1 1 0 0 1-.8.243c-.333-.046-.676-.39-1.363-1.076l-7.086-7.085c-.687-.687-1.03-1.03-1.076-1.363A1 1 0 0 1 4 9.91c.223-.251.7-.346 1.652-.537l3.664-.733c.177-.035.265-.053.347-.085a1 1 0 0 0 .207-.11 2.1 2.1 0 0 0 .264-.242l1.56-1.561a2.09 2.09 0 0 0 .158-.167.96.96 0 0 0 .085-.126c.029-.05.051-.102.097-.208l1.069-2.495Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.377 15.616 2.72 21.273m8.974-14.631-1.56 1.56a2.1 2.1 0 0 1-.264.242 1 1 0 0 1-.207.111c-.082.032-.17.05-.347.085l-3.665.733c-.952.19-1.428.286-1.65.537a1 1 0 0 0-.243.8c.046.333.39.677 1.076 1.363l7.086 7.086c.686.687 1.03 1.03 1.362 1.076a1 1 0 0 0 .801-.242c.251-.223.346-.7.537-1.651l.733-3.665a2.11 2.11 0 0 1 .085-.347.997.997 0 0 1 .11-.207c.051-.072.115-.136.242-.263l1.561-1.561c.082-.082.122-.122.167-.158.04-.031.082-.06.126-.085.05-.029.103-.051.208-.097l2.495-1.069c.727-.312 1.091-.467 1.256-.72a1 1 0 0 0 .144-.747c-.06-.295-.34-.575-.9-1.135l-5.142-5.143c-.56-.56-.84-.84-1.135-.9a1 1 0 0 0-.748.145c-.252.165-.407.529-.72 1.256l-1.068 2.495a2.096 2.096 0 0 1-.097.208.998.998 0 0 1-.085.126 2.065 2.065 0 0 1-.158.167Z"
    />
  </svg>
);
export default Pin02;
