/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Star01 } from '@commandbar/design-system/icons/react';
import { useState } from 'react';
import type { INudgeContentSurveyRatingBlockType } from '@commandbar/internal/middleware/types';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledNudgeRatingEmojis from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingEmojis';
import StyledNudgeRatingStars from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingStars';
import StyledNudgeRatingNumbers from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingNumbers';
import StyledNudgeRatingLabel from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingLabel';
import StyledFormFieldBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledFormFieldBlock';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useSurveyResponse } from '../SurveyResponseProvider';

const StarRatingInputBlock = ({ ratingBlock }: { ratingBlock: INudgeContentSurveyRatingBlockType['meta'] }) => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const [currentHovered, setcurrentHovered] = useState<number>(-1);
  const currentRating = Number(surveyResponse?.value) ?? -1;

  const inputAddition = (i: number, hoverMarked: boolean, selectedMarked: boolean) => {
    switch (ratingBlock.type) {
      case 'stars':
        return (
          <Star01
            width={24}
            height={24}
            color={hoverMarked || selectedMarked ? '#DD9E23' : undefined}
            css={{
              opacity: hoverMarked || selectedMarked ? undefined : 0.25,
            }}
          />
        );
      case 'emojis':
        const zeroBasedIndex = i - 1;
        return (
          <div
            style={{
              lineHeight: '26px',
              fontSize: hoverMarked || selectedMarked ? '26px' : '24px',
              textShadow: hoverMarked || selectedMarked ? '0px 4px 7px rgba(0, 0, 0, 0.35)' : undefined,
              transition: `all ${theme.main.transitionTime} ease-in-out`,
              width: '26px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {ratingBlock.emojis.length > zeroBasedIndex ? ratingBlock.emojis[zeroBasedIndex] : ' '}
          </div>
        );
      case 'numbers':
        return (
          <span
            style={{
              color: hoverMarked || selectedMarked ? '#000000' : theme.surveyRatingBox.color,
              fontSize: '14px',
              fontWeight: '600px',
            }}
          >
            {i}
          </span>
        );
      case 'nps':
        return (
          <span
            style={{
              color: hoverMarked || selectedMarked ? '#000000' : theme.surveyRatingBox.color,
              fontSize: '14px',
              fontWeight: '600px',
            }}
          >
            {i}
          </span>
        );
    }
  };

  const setRating = (rating: number) => {
    if (rating === currentRating) {
      setSurveyResponse(undefined);
    } else {
      setSurveyResponse({
        type: ratingBlock.type === 'nps' ? 'nps' : 'number',
        value: rating,
        max: ratingBlock.options,
        emoji:
          ratingBlock.type === 'emojis'
            ? ratingBlock.emojis[rating - 1]
            : ratingBlock.type === 'stars'
            ? '⭐'
            : undefined,
      });
    }
  };

  return _.flags?.['release-themes-v2'] ? (
    <StyledFormFieldBlock>
      {ratingBlock.type === 'emojis' ? (
        <StyledNudgeRatingEmojis
          themeV2={themeV2}
          numOptions={ratingBlock.options}
          selected={(index: number) => index === currentRating}
          onClick={(index: number) => setRating(index)}
          emojis={ratingBlock.emojis}
        />
      ) : ratingBlock.type === 'stars' ? (
        <StyledNudgeRatingStars
          themeV2={themeV2}
          numOptions={ratingBlock.options}
          selected={(index: number) => index <= currentRating}
          onClick={(index: number) => setRating(index)}
        />
      ) : ratingBlock.type === 'nps' ? (
        <StyledNudgeRatingNumbers
          themeV2={themeV2}
          numOptions={ratingBlock.options + 1}
          selected={(index: number) => index === currentRating}
          onClick={(index: number) => setRating(index)}
        />
      ) : (
        <StyledNudgeRatingNumbers
          themeV2={themeV2}
          numOptions={ratingBlock.options + 1}
          selected={(index: number) => index === currentRating}
          onClick={(index: number) => setRating(index)}
        />
      )}
      <StyledNudgeRatingLabel left={ratingBlock.lower_label} right={ratingBlock.upper_label} />
    </StyledFormFieldBlock>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '8px',
          flex: 'none',
          order: 0,
          flexGrow: 1,
          alignSelf: 'stretch',
          flexWrap: isMobileDevice() ? 'wrap' : 'nowrap',
        }}
      >
        {[
          ...Array(
            ratingBlock.type === 'nps' || ratingBlock.type === 'numbers'
              ? ratingBlock.options + 1
              : ratingBlock.options,
          ),
        ].map((_, i) => {
          /* INFO: For nps, we want to start at 0, but for stars and emojis, we want to start at 1.
           * This is to make sure that if you choose a nps of options in the editor, then you will see the correct
           * nps depending on the type.
           * For example: 5 nps options should show 6 options (0 - 5)
           * 5 number or emoji options should show 5 options */
          const index = ratingBlock.type === 'nps' || ratingBlock.type === 'numbers' ? i : i + 1;

          const hoverMarked = (ratingBlock.type === 'stars' && index <= currentHovered) || index === currentHovered;
          const selectedMarked = (ratingBlock.type === 'stars' && index <= currentRating) || index === currentRating;

          return (
            <button
              data-testid={`rating-input-${index}`}
              aria-pressed={selectedMarked}
              aria-label={`Rate ${i + 1} out of ${ratingBlock.options}`}
              onMouseEnter={() => setcurrentHovered(index)}
              onMouseLeave={() => setcurrentHovered(-1)}
              onClick={() => setRating(index)}
              key={index}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px',
                gap: '6px',

                height: '40px',
                width: ratingBlock.type !== 'emojis' ? '40px' : 'auto',

                background:
                  hoverMarked || selectedMarked
                    ? theme.surveyRatingBox.selectionBackGround
                    : theme.surveyRatingBox.background,
                border:
                  hoverMarked || selectedMarked ? theme.surveyRatingBox.selectionBorder : theme.surveyRatingBox.border,
                boxShadow:
                  hoverMarked && !selectedMarked
                    ? theme.surveyRatingBox.boxShadow
                    : selectedMarked
                    ? theme.surveyRatingBox.selectionBoxShadow
                    : theme.surveyRatingBox.boxShadow,
                borderRadius: theme.surveyRatingBox.borderRadius,
                flex: 'none',
                order: 0,
                flexGrow: 1,
              }}
            >
              {inputAddition(index, hoverMarked, selectedMarked)}
            </button>
          );
        })}
      </div>
      <div
        style={{
          display: ratingBlock.lower_label || ratingBlock.upper_label ? 'flex' : 'none',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '0px',
          order: 1,
          flexGrow: 0,
          width: '100%',
        }}
      >
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
            color: ' #A2A2A9',
          }}
        >
          {ratingBlock.lower_label}
        </span>
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
            color: ' #A2A2A9',
          }}
        >
          {ratingBlock.upper_label}
        </span>
      </div>
    </div>
  );
};

export default StarRatingInputBlock;
