import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

export const Radio = styled.div`
  min-height: 32px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e6e6e8 !important;
`;

const RadioItemContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.selected ? 'rgba(39, 85, 240, 0.1)' : '#fff')};
  border-color: #f0f0f0;
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  cursor: pointer;

  &:last-child {
    border-right-width: 0;
  }

  & span {
    color: ${(props) => (props.selected ? CB_COLORS.primary : 'rgba(0, 0, 0, 0.85)')};
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 6px 8px;
    text-align: center;
  }
`;

export const RadioItem = (props: { label: string; selected: boolean; onClick(): void }) => {
  const { label, selected, onClick } = props;

  return (
    <RadioItemContainer selected={selected} onClick={onClick}>
      <span>{label}</span>
    </RadioItemContainer>
  );
};
