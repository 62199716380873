import { cva } from 'class-variance-authority';

export const switchStyles = cva(
  [
    'rounded-button',
    'peer',
    'inline-flex',
    'items-center',
    'h-lg w-xxl',
    'items-center',
    'rounded-full',
    'border',
    'border-solid',
    'border-gray400',
    'p-0',
    'hover:data-[state=unchecked]:border-gray500',
    'peer shrink-0 cursor-pointer items-center shadow-sm focus-visible:outline-none data-[state=checked]:bg-blue700 data-[state=checked]:border-blue900 data-[state=unchecked]:bg-gray200',
  ],
  {
    variants: {
      variant: {
        default: ['cursor-pointer', 'focus:shadow-input focus:border-blue700'],
      },
      disabled: {
        true: [
          'border shadow-sm data-[state=checked]:bg-surfaceDisabled data-[state=checked]:border-elementDisabled',
          'cursor-not-allowed',
        ],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
