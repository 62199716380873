import { useEffect, useState } from 'react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import {
  AnalyticsAnnouncements,
  AnalyticsAnnouncementsData,
} from '@commandbar/internal/middleware/analytics/announcements';
import * as Sentry from '@sentry/react';
import { useCSVDownload } from '../../useCSVDownload';
import { getAnnouncementsDemoData } from '../../analyticDemoData';

const useAnalyticAnnouncements = ({ showUnpublished }: { showUnpublished: boolean }) => {
  const [data, setData] = useState<AnalyticsAnnouncementsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();
  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'announcements',
    download: () =>
      AnalyticsAnnouncements.download({
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: String(!!showUnpublished),
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchAnnouncementData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsAnnouncements.read({
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      show_unpublished: showUnpublished.toString(),
      include_admin_data: includeAdminData.toString(),
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      setData(getAnnouncementsDemoData());
      setIsLoading(false);
    } else {
      fetchAnnouncementData();
    }
  }, [timeFilterRange, showUnpublished, isDemoData, includeAdminData]);

  return {
    data,
    isLoading,
    error,
    fetchAnnouncementData,
    downloadCsv,
    isDownloading,
  };
};

export default useAnalyticAnnouncements;
