/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* External imports */
import React from 'react';
import {
  Modal,
  Row,
  Col,
  Checkbox,
  Slider,
  Input,
  Heading,
  Tooltip,
  Select,
} from '@commandbar/design-system/components/antd';

/* Internal imports */
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import useAllSlashFilterKeywords from '../../../hooks/useAllSlashFilterKeywords';
import { getColorsFromCommands } from '../../utils/ColorUtils';
import slugify from '@commandbar/internal/util/slugify';
import SlashFilterCollisions from '../../components/SlashFilterCollisions';
import IconPicker from '../../components/IconPicker/IconPicker';
import { CmdSwitch, CmdButton, CmdTag } from '@commandbar/design-system/cmd';
import { Eye, EyeOff, InfoCircle } from '@commandbar/design-system/icons/react';
import TextAreaWithInterpolation from '../../components/TextAreaWithInterpolation';

/*******************************************************************************/
/* Interface
/*******************************************************************************/

interface IProps {
  activeCategory: ICommandCategoryType;
  onClose: () => void;
}

/*******************************************************************************/
/* Component
/*******************************************************************************/

const FormField = ({
  title,
  description,
  children,
}: {
  title: string | React.ReactElement;
  description?: string;
  children?: React.ReactNode;
}) => (
  <Row gutter={24} style={{ marginBottom: 28, padding: '0px 15px' }} align="middle">
    <Col span={12}>
      <div style={{ fontWeight: 500, fontSize: 14 }}>{title}</div>
      <div style={{ fontSize: 11, opacity: 0.8 }}>{description}</div>
    </Col>
    <Col span={12}>{children}</Col>
  </Row>
);

const CategorySettings = ({ activeCategory, onClose }: IProps) => {
  const {
    dispatch: {
      categories: { save },
    },
    organization,
    commands,
  } = useAppContext();
  const [newCategory, setNewCategory] = React.useState<ICommandCategoryType>(activeCategory);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const allSlashFilters = useAllSlashFilterKeywords(activeCategory.id);

  React.useEffect(() => {
    setNewCategory(activeCategory);
    setIsDirty(false);
  }, [activeCategory]);

  const _old_onFieldChange = (field: string, value: any) => {
    setNewCategory((cat) => ({ ...cat, [field]: value }));
    setIsDirty(true);
  };

  const onFieldChange = (partialCategory: Partial<ICommandCategoryType>) => {
    setNewCategory((cat) => ({ ...cat, ...partialCategory }));
    setIsDirty(true);
  };

  const modalRef = React.useRef<HTMLDivElement>(null);

  return (
    <Modal
      visible
      closable={false}
      onCancel={onClose}
      footer={null}
      style={{ top: 30, maxWidth: 700 }}
      width="90%"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }} ref={modalRef}>
          <span
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >{`Category settings: ${activeCategory.name}`}</span>
          <CmdButton
            onClick={() => {
              save(newCategory);
              onClose();
            }}
          >
            Save
          </CmdButton>
        </div>
      }
    >
      <div>
        <div style={{ height: 20 }} />
        <FormField title="Category name" description="The title of this category shown in the bar.">
          <Row align="middle" gutter={12}>
            <Col span={22}>
              <TextAreaWithInterpolation
                onChange={(name) => {
                  onFieldChange({ name });
                }}
                value={newCategory['name']}
                container={modalRef.current}
              />
            </Col>

            <Col span={1}>
              <Tooltip content="Your category can reference context." placement="bottom">
                <InfoCircle />
              </Tooltip>
            </Col>
          </Row>
        </FormField>
        <FormField title="Icon">
          <Row align="middle" gutter={[12, 0]}>
            <Col span={22}>
              <IconPicker
                color={newCategory.icon_color || null}
                icon={newCategory.icon || null}
                clearable={true}
                colors={getColorsFromCommands(commands)}
                onIconSelect={(icon: string) => onFieldChange({ icon })}
                onColorSelect={(icon_color: string) => onFieldChange({ icon_color })}
              />
            </Col>

            <Col span={1}>
              <Tooltip
                content="Selecting an icon sets the icon for all pages and actions within this category."
                placement="bottom"
              >
                <InfoCircle />
              </Tooltip>
            </Col>
          </Row>
        </FormField>
        <FormField
          title="Show/hide category before search"
          description="Hiding categories before search means that they will only show up during search but not in the default list when the bar is opened."
        >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CmdButton
              variant="link"
              icon={newCategory.setting_hide_before_search ? <EyeOff /> : <Eye />}
              style={{ color: 'rgba(24, 144, 255)', fontSize: '20px', boxShadow: 'none' }}
              onClick={() => onFieldChange({ setting_hide_before_search: !newCategory.setting_hide_before_search })}
            />
          </div>
        </FormField>
        <FormField
          title="Pin to bottom"
          description="Pinning a category to the bottom puts it below all other categories."
        >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              checked={newCategory.setting_pin_to_bottom}
              onChange={(e: any) => onFieldChange({ setting_pin_to_bottom: e.target.checked })}
            />
          </div>
        </FormField>
        <FormField
          title="Option list limit"
          description="Limit on the amount of options that can be shown in the category."
        >
          {(() => {
            const { setting_max_options_count } = newCategory;
            const noLimit = setting_max_options_count === null || setting_max_options_count === undefined;

            const onChange = (value: number | string | undefined) => {
              if (typeof value === 'number') {
                const newValue = value > 10 ? null : value;
                onFieldChange({ setting_max_options_count: newValue });
              }
            };

            return (
              <Row align="middle" gutter={25}>
                <Col span={12}>
                  <Slider
                    min={1}
                    max={11}
                    onChange={onChange}
                    value={noLimit ? 11 : (setting_max_options_count as number)}
                  />
                </Col>
                <Col>
                  <CmdTag>{noLimit ? 'No limit' : `${setting_max_options_count} row limit`}</CmdTag>
                </Col>
              </Row>
            );
          })()}
        </FormField>

        <FormField title="Slash filter keyword" description="Lets users type /keyword to filter from this category.">
          {(() => {
            const { slash_filter_enabled, slash_filter_keyword, name } = newCategory;
            const defaultKeyword = slugify(name);

            return (
              <Row align="middle" gutter={12}>
                <Col span={22}>
                  <Input
                    onChange={(e) => {
                      onFieldChange({ slash_filter_keyword: slugify(e.target.value) });
                    }}
                    placeholder={`[Default] ${defaultKeyword}`}
                    value={slash_filter_keyword || ''}
                    disabled={!slash_filter_enabled}
                  />
                </Col>
                <Col span={1}>
                  <SlashFilterCollisions
                    allFilters={allSlashFilters}
                    currentFilter={slash_filter_keyword || defaultKeyword}
                  />
                </Col>
              </Row>
            );
          })()}
        </FormField>

        {organization?.end_user_recents_enabled && (
          <FormField title="Include in Recents" description="Show pages and actions from this category in Recents.">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                checked={newCategory.track_recents}
                onChange={(e) => onFieldChange({ track_recents: e.target.checked })}
              />
            </div>
          </FormField>
        )}

        <div style={{ marginTop: 20 }}>
          <Heading text={'Major category'} />
        </div>

        <FormField
          title="Major category"
          description="Allow user to filter from this category via a tab below the input."
        >
          <CmdSwitch
            checked={newCategory.search_tab_enabled}
            onCheckedChange={(checked) => {
              onFieldChange({ search_tab_enabled: checked });
            }}
            offLabel="Off"
            onLabel="On"
            disabled={false}
          />
        </FormField>

        <FormField
          title="Major category name"
          description="Custom category name to be shown in the tab below the input (defaults to category name)."
        >
          {(() => {
            const isInputDisabled = !newCategory.search_tab_enabled;
            return (
              <Tooltip
                content={'To edit this setting, first make the category a major category.'}
                disabled={!isInputDisabled}
                placement="bottom"
              >
                <Input
                  value={newCategory.search_tab_name || ''}
                  placeholder={`[Default] ${newCategory.name}`}
                  onChange={(e) => {
                    onFieldChange({ search_tab_name: e.target.value });
                  }}
                  disabled={isInputDisabled}
                />
              </Tooltip>
            );
          })()}
        </FormField>

        <FormField
          title="Major category placeholder"
          description="Custom placeholder to be shown when the user filters for this major category."
        >
          {(() => {
            const isInputDisabled = !newCategory.search_tab_enabled;

            return (
              <Tooltip
                content={'To edit this setting, first make the category a major category.'}
                disabled={!isInputDisabled}
                placement="bottom"
              >
                <Input
                  value={newCategory.search_tab_instruction || ''}
                  placeholder={`Type to search`}
                  onChange={(e) => {
                    onFieldChange({ search_tab_instruction: e.target.value });
                  }}
                  disabled={isInputDisabled}
                />
              </Tooltip>
            );
          })()}
        </FormField>

        <FormField title="Render as" description="Render options as a list or a grid.">
          {(() => {
            const isInputDisabled = !newCategory.search_tab_enabled;

            return (
              <Tooltip
                content={'To edit this setting, first make the category a major category.'}
                disabled={!isInputDisabled}
                placement="bottom"
              >
                <Select
                  onChange={(render_as) => {
                    onFieldChange({ render_as });
                  }}
                  value={newCategory.render_as ?? 'list'}
                  disabled={isInputDisabled}
                >
                  <Select.Option value="list">list</Select.Option>
                  <Select.Option value="grid">grid</Select.Option>
                </Select>
              </Tooltip>
            );
          })()}
        </FormField>

        {newCategory.render_as === 'grid' && (
          <FormField title="Image">
            {(() => {
              const isInputDisabled = !newCategory.search_tab_enabled;

              return (
                <Row align="middle" gutter={[12, 0]}>
                  <Col span={22}>
                    <IconPicker
                      icon={newCategory.image || null}
                      color={newCategory.image_color || null}
                      clearable={true}
                      colors={getColorsFromCommands(commands)}
                      onIconSelect={(image) => onFieldChange({ image })}
                      onColorSelect={(image_color) => onFieldChange({ image_color })}
                      disabled={isInputDisabled}
                    />
                  </Col>

                  <Col span={1}>
                    <Tooltip
                      content={
                        isInputDisabled
                          ? 'To edit this setting, first make the category a major category.'
                          : 'Selecting an image sets the grid item image for all commands within this category.'
                      }
                      placement="bottom"
                    >
                      <InfoCircle />
                    </Tooltip>
                  </Col>
                </Row>
              );
            })()}
          </FormField>
        )}
      </div>
    </Modal>
  );
};

export default CategorySettings;
