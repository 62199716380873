import type { ICommandType, IHelpDocHitType } from '@commandbar/internal/middleware/types';
import type { IEndUserType } from '@cb/types/entities/endUser';

import { CBStore, HelpHubDoc } from 'shared/store/global-store';
import { commandToHelpHubDoc } from 'products/helphub/service-selectors';
import { HelpDocsSearch } from '@commandbar/internal/middleware/helpDocsSearch';
import { getLocalCommandOrHelpdoc } from 'shared/store/global-selectors';
import * as Organization from '@commandbar/internal/middleware/organization';
import { getBaseURL, getFetchHeaders } from '@commandbar/internal/middleware/network';

class HelpdocService {
  cache = new Map<string, IHelpDocHitType>();
  cacheLimit = 100;

  getCacheKey(docId: string | number, query: string | undefined) {
    return `${docId}${query || ''}`;
  }

  addToCache(commandId: string | number, hit: IHelpDocHitType, query?: string) {
    const cacheKey = this.getCacheKey(commandId, query);
    if (this.cache.size >= this.cacheLimit) {
      // Delete the oldest cache entry if we're at the limit
      this.cache.delete(this.cache.keys().next().value);
    }
    this.cache.set(cacheKey, hit);
  }

  async getHelpdocHit(
    orgId: string | number | undefined,
    commandId: string | number,
    endUser: IEndUserType | null | undefined,
    query?: string,
    ignoreCache?: boolean,
  ): Promise<IHelpDocHitType | null> {
    if (!orgId) {
      return null;
    }

    if (!ignoreCache) {
      // Return cached result if it exists
      const cacheKey = this.getCacheKey(commandId, query);
      if (this.cache.has(cacheKey)) {
        return this.cache.get(cacheKey) || null;
      }
    }

    const response = await HelpDocsSearch.search(
      orgId,
      {
        query,
        doc_id: commandId,
        expand_rules: true,
      },
      {
        headers: {
          ...(!!endUser?.hmac && { 'X-USER-AUTHORIZATION': endUser?.hmac }),
          ...(!!endUser?.slug && { 'X-USER-ID': endUser?.slug }),
        },
      },
    );

    const searchResults = response.data;

    if (searchResults && searchResults.length > 0) {
      this.addToCache(commandId, searchResults[0].hit, query);
      return searchResults[0].hit;
    }

    return null;
  }

  async getHelpdocCommand(_: CBStore, commandId: string | number): Promise<ICommandType | null> {
    const command = getLocalCommandOrHelpdoc(_, commandId);
    if (command?.template.type === 'helpdoc') {
      return command;
    }

    const hit = await this.getHelpdocHit(_.organization?.id, commandId, _.endUser);

    if (hit) {
      return hit.command;
    }

    return null;
  }

  async getHelpdoc(
    _: CBStore,
    commandId: string | number,
    query?: string,
    ignoreCache = false,
  ): Promise<HelpHubDoc | null> {
    if (!query && !ignoreCache) {
      const command = getLocalCommandOrHelpdoc(_, commandId);
      if (command?.template.type === 'helpdoc' && command?.template.doc_type !== 'answer') {
        return commandToHelpHubDoc(command);
      }
    }

    const hit = await this.getHelpdocHit(_.organization?.id, commandId, _.endUser, query, ignoreCache);

    if (hit) {
      return commandToHelpHubDoc(hit.command, hit);
    }

    return null;
  }

  async getCommandOrHelpdoc(_: CBStore, commandId: string | number): Promise<ICommandType | null> {
    const command = getLocalCommandOrHelpdoc(_, commandId);
    if (command) {
      return command;
    }

    const hit = await this.getHelpdocHit(_.organization?.id, commandId, _.endUser);

    if (hit) {
      return hit.command;
    }

    return null;
  }

  // FIXME: We allow getting HelpDocs by ID/URL, which is an issue with private helpdocs that have public or guessable URLs
  async getHelpdocByUrl(orgId: string | number, url: string): Promise<HelpHubDoc | null> {
    const command = await Organization.getCommandByUrl(`${orgId}`, url);

    if (command) {
      this.addToCache(command.id, { command: command, excerpt: '', score: [0] });

      return commandToHelpHubDoc(command);
    }

    return null;
  }

  async getZendeskHelpdocAttachment(
    orgId: string | number,
    commandId: string | number,
    endUser: IEndUserType | null | undefined,
    contentUrl: string,
  ): Promise<Blob> {
    const { hmac, slug } = endUser || {};
    const baseURL = getBaseURL();
    const result = await fetch(
      `${baseURL}/organizations/${orgId}/commands/${commandId}/zendesk_article_attachment/?content_url=${encodeURIComponent(
        contentUrl,
      )}`,
      {
        method: 'GET',
        headers: {
          ...getFetchHeaders(),
          ...(hmac && { 'X-USER-AUTHORIZATION': hmac }),
          ...(slug && { 'X-USER-ID': slug }),
        },
      },
    );

    return await result.blob();
  }
}

const helpdocService = new HelpdocService();
export default helpdocService;
