import type { Action } from '@cb/types/entities/command/actions';
import type { ICommandType } from '@commandbar/internal/middleware/types';
import { CBStore } from '../global-store';
import { snapshot } from 'valtio';
import { SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';
import { getSentry } from '@commandbar/internal/util/sentry';
import Logger from '@commandbar/internal/util/Logger';
import helpdocService from 'shared/services/services/helpdocService';
import { passesAudienceConditions } from 'shared/services/targeting/audience';
import { getNudgeById } from 'products/nudges/service-selectors';
import { isCommandAvailable } from 'shared/services/targeting/command-targeting';
import { getChecklistById } from '@commandbar/commandbar/products/checklists/service-selectors';

const runAndReportFailure = (
  f: (args: any, context: any, meta: any) => any,
  args: any,
  context: any,
  command: ICommandType,
) => {
  const commandMeta = {
    source: {
      type: 'action', // Callbacks can currently only be called from Actions
      metadata: command.template.metadata,
    },
  };

  try {
    return f(args, context, commandMeta);
  } catch (e) {
    getSentry()?.captureException(e, {
      captureContext: {
        contexts: {
          command,
        },
      },
    });

    // REVIEW: This might cause a duplicate error report?
    throw e;
  }
};

export const runRequestCommandAndReportFailure = async (
  request: any,
  command: ICommandType,
  onSuccess?: any,
  onError?: any,
) => {
  try {
    const response = await request();

    if (response.ok) {
      if (onSuccess) {
        onSuccess(response);
      }
    }

    if (!response.ok) {
      if (onError) {
        onError(response);
      }

      Logger.error(
        JSON.stringify({
          statusCode: response.status,
          command,
        }),
      );
    }
  } catch (e) {
    getSentry()?.captureException(e, {
      captureContext: {
        contexts: {
          command,
        },
      },
    });
  }
};

// Never use eval()!
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
export const dangerouslyRunJS = (script: string, _args: any, _context: any) => {
  const executable = '"use strict";return ( function(args, context){' + script + '})';
  // eslint-disable-next-line no-new-func
  return Function(executable)()(_args, _context);
};

export const selectContextForExternalUse = (_: CBStore, includeLegacyKeys = true) => {
  const context = snapshot(_.context);
  const records = snapshot(_.spotlight.records);

  const recordsWithLegacyKeys = includeLegacyKeys
    ? Object.fromEntries(Object.entries(records).map(([key, value]) => [`commandbar-search-${key}`, value.records]))
    : {};

  // preserve compatibility with old context format
  return Object.fromEntries(
    Object.entries({
      ...context,
      ...recordsWithLegacyKeys,
    }).filter(([key]) => !key.startsWith(SDK_INTERNAL_PREFIX)),
  );
};

export const executeCallback = (
  _: CBStore,
  selections: { [argName: string]: any },
  command: ICommandType,
  callback: (...args: unknown[]) => unknown,
) => {
  const context = selectContextForExternalUse(_, false);

  // VA: FIXME: This feature can be unshippable
  if (command.confirm) {
    if (window.confirm(command.confirm)) {
      runAndReportFailure(callback, selections, context, command);
    }
  } else {
    runAndReportFailure(callback, selections, context, command);
  }
};

export const actionIsAvailable = async (_: CBStore, action: Action) => {
  // Filter commands by availability and audience, and checklists and nudges
  // by audience conditions only. Other actions are typically available.

  // Don't allow any experience in WebViews except help docs or links
  if (
    window._cbIsWebView &&
    action?.type !== 'execute_command' &&
    action?.type !== 'link' &&
    action?.type !== 'open_chat'
  ) {
    return false;
  }

  if (action?.type === 'execute_command' && action.meta.command === '') {
    return false;
  }

  switch (action?.type) {
    case 'execute_command':
      const command = await helpdocService.getCommandOrHelpdoc(_, action.meta.command);

      const commandPassed = command && command.is_live && passesAudienceConditions(_, 'commands', command);

      if (command?.template.type === 'helpdoc') {
        return commandPassed;
      } else if (window._cbIsWebView) {
        return false;
      }

      // For non-helpdocs, also consider availability
      return command && commandPassed && isCommandAvailable(command, _).isAvailable;
    case 'questlist':
      const questlist = getChecklistById(_, action.value);
      return questlist && questlist.is_live && passesAudienceConditions(_, 'checklists', questlist);
    case 'nudge':
      const nudge = getNudgeById(_, action.value);
      return nudge && !nudge.archived && nudge.is_live && passesAudienceConditions(_, 'nudges', nudge);
    case 'open_bar':
      return _.organization?.bar_enabled;
    case 'open_helphub':
      return _.organization?.helphub_enabled;
    case 'open_copilot':
      return _.organization?.copilot_enabled;
    default:
      return true;
  }
};
