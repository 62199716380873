import { FC } from 'react';
import { Alert, Input, Form, Select, Checkbox } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';
import { filterDryRunData } from '../shared/utils';
import { FormItemHorizontal } from '../shared/styles';

const Wordpress: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.WORDPRESS,
  name: 'Wordpress',
  slug: 'wordpress',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/wordpress-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/wordpress-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Sync posts with CommandBar.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Wordpress posts, you'll have to first enter your domain."}
    />
  ),
  route: '/wordpress',

  getForm({ showAdvanced, dryRunData, editingSource }: SourceConfigFormProps) {
    const FormComponent: FC = () => {
      return (
        <>
          <Form.Item
            key={'domain'}
            validateTrigger="onChange"
            initialValue={editingSource?.meta?.domain}
            required={true}
            label={'Domain'}
            name={'domain'}
            rules={[
              { required: true, message: 'This is a required field.' },
              {
                pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.*$/gi,
                message: `Please enter a valid URL (e.g., yourdomain.com). Don't include 'https' prefixes or trailing slashes. `,
              },
            ]}
            style={{ flex: 5 }}
          >
            <Input style={{ width: '100%' }} placeholder="yourdomain.com" />
          </Form.Item>

          <FormItemHorizontal
            key={editingSource?.type + 'show_advanced'}
            label="Filter by tags"
            name="show_advanced"
            valuePropName="checked"
            rules={[{ required: false }]}
            initialValue={!!editingSource?.meta?.tags?.length}
          >
            <Checkbox
              defaultChecked={!!editingSource?.meta?.tags?.length}
              value={!!editingSource?.meta?.tags?.length}
            />
          </FormItemHorizontal>

          {!!showAdvanced && (
            <Form.Item
              key={editingSource?.type + 'tags'}
              validateTrigger="onSubmit"
              initialValue={editingSource?.id ? dryRunData : []}
              label="Tags"
              name="tags"
              style={{ flex: 5 }}
              required={true}
              rules={[{ required: true, message: 'This is a required field.' }]}
            >
              <Select
                size="large"
                mode="multiple"
                style={{ width: '100%' }}
                options={dryRunData}
                maxTagCount="responsive"
                allowClear
                filterOption={(input, option) => !!option?.label?.toLowerCase().includes(input.toLowerCase())}
                labelInValue
              />
            </Form.Item>
          )}
        </>
      );
    };

    return <FormComponent />;
  },

  getInitialDryRunData(sourceId) {
    return this.sources?.find((i) => i.id === sourceId)?.meta?.tags;
  },

  getDryRunData(result) {
    const data = JSON.parse(result.data).tags;
    const tags = data.map((tag: { name: string; id: string | number }) => ({
      label: tag.name,
      value: Number(tag.id),
    }));
    tags.push({ label: 'Untagged', value: -1 }); // 'Untagged' option means pull articles without tags
    return tags;
  },

  getPayloadMeta(data, dryRunData, editingSource) {
    const meta = this.requiredFields.reduce((a, v) => ({ ...a, [v]: data[v] }), {});
    const bonusMeta = {
      tags: data['tags'] ? filterDryRunData(data['tags'], dryRunData) : [],
    };
    return { ...editingSource?.meta, ...meta, ...bonusMeta };
  },

  requiredFields: ['domain'],
  fetchedFields: ['tags'],
};

export default Wordpress;
