import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { Page, CB_COLORS, FormRow } from '@commandbar/design-system/components/antd';
import { useIntegrationsContext } from './util/IntegrationContext';
import { ArrowNarrowRight } from '@commandbar/design-system/icons/react';
import { CmdInput, CmdSwitch, cmdToast } from '@commandbar/design-system/cmd';
import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { isValidURL } from '@commandbar/internal/util/location';
import { useAppContext } from 'editor/src/AppStateContext';

const Configure = () => {
  const {
    organization,
    organizationSettings,
    dispatch: {
      organization: { updateSetting, update },
    },
  } = useAppContext();
  const { hasHelpDocIntegrations } = useIntegrationsContext();

  const [baseUrl, setBaseUrl] = useState(organization.base_url);

  const canEditAnalyticsControls =
    !!organization && (organization.branding !== 'branded' || organization.event_attribute_block_list.length > 0);

  const onChangeEventAttribute = (key: string, checked: boolean) => {
    const eventAttributeBlockList = organization.event_attribute_block_list || [];
    const newEventAttributeBlockList = checked
      ? [...eventAttributeBlockList, key]
      : eventAttributeBlockList.filter((att) => att !== key);

    updateSetting({
      event_attribute_block_list: newEventAttributeBlockList,
    });
  };

  const debouncedUpdate = useCallback(
    debounce(async (org: IOrganizationType) => {
      try {
        await update(org);
      } catch (e) {
        cmdToast.error('Failed to update domain. Please make sure to enter a valid URL.');
      }
    }, 1200),
    [],
  );

  return (
    <Page section="Settings" title="Configure">
      <SettingRow>
        <div>
          <Title>Spotlight</Title>
          <Subtitle>Make everything in your app searchable</Subtitle>
          <a href="/editor/spotlight">
            <LinkOut>Customize →</LinkOut>
          </a>
        </div>
        <CmdSwitch
          checked={organization.bar_enabled}
          onCheckedChange={(value) => {
            updateSetting({
              bar_enabled: value,
            });
          }}
          onLabel="Enabled"
          offLabel="Disabled"
        />
      </SettingRow>
      <Divider />
      <SettingRow secondary={!hasHelpDocIntegrations}>
        <div>
          <Title>HelpHub</Title>
          <Subtitle>Show your help center content in your app</Subtitle>
          {hasHelpDocIntegrations ? (
            <Link to="/integrations#widgets">
              <LinkOut>Customize →</LinkOut>
            </Link>
          ) : (
            <IntegrationButton to="/integrations">
              Connect a Help Center <ArrowNarrowRight height={16} />
            </IntegrationButton>
          )}
        </div>
        <CmdSwitch
          checked={organization.helphub_enabled}
          onCheckedChange={(value) => {
            updateSetting({
              helphub_enabled: value,
            });
          }}
          onLabel="Enabled"
          offLabel="Disabled"
        />
      </SettingRow>
      <Divider />
      <SettingRow>
        <div>
          <Title>Copilot</Title>
          <Subtitle>Enable AI-powered chat in your app</Subtitle>
          <a href="/editor/copilot">
            <LinkOut>Customize →</LinkOut>
          </a>
        </div>
        <CmdSwitch
          checked={organization.copilot_enabled}
          onCheckedChange={(value) => {
            updateSetting({
              copilot_enabled: value,
            });
          }}
          onLabel="Enabled"
          offLabel="Disabled"
        />
      </SettingRow>

      <Divider />

      <SettingRow>
        <div>
          <Title>Domain</Title>
          <Subtitle>The primary URL where CommandBar is installed</Subtitle>
        </div>
        <div>
          <CmdInput
            style={{ minWidth: '270px' }}
            value={baseUrl}
            placeholder="https://app.example.com"
            onChange={(e) => {
              setBaseUrl(e.target.value);
              debouncedUpdate({
                ...organization,
                base_url: e.target.value,
              });
            }}
          />
          {baseUrl && !isValidURL(baseUrl, true) && (
            <span
              style={{
                color: 'rgb(199, 58, 39)',
                fontSize: '12px',
              }}
            >
              valid URL required, e.g. app.example.com
            </span>
          )}
        </div>
      </SettingRow>

      {organizationSettings?.releases_available && (
        <>
          <Divider />
          <SettingRow>
            <div>
              <Title>Releases</Title>
              <Subtitle>
                Enable Releases to help your team test the changes they make before making those changes available to
                your customers
              </Subtitle>
              <a
                href="https://www.commandbar.com/docs/platform/account-management/releases/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkOut>Configure your integration to use Releases →</LinkOut>
              </a>
            </div>
            <CmdSwitch
              checked={organization.releases_enabled}
              onCheckedChange={(value) => {
                updateSetting({
                  releases_enabled: value,
                });
              }}
              onLabel="Enabled"
              offLabel="Disabled"
            />
          </SettingRow>
        </>
      )}
      {!organizationSettings?.silent_mode && (
        <>
          <Divider />
          <SettingRow style={{ flexDirection: 'column', alignItems: 'start', gap: '16px' }}>
            <div style={{ flexDirection: 'row' }}>
              <Title>Analytics</Title>
              <Subtitle>These settings control different types of data sent to CommandBar's analytics service</Subtitle>
              {!canEditAnalyticsControls && (
                <a href="https://www.commandbar.com/pricing" target="_blank" rel="noopener noreferrer">
                  <LinkOut>Upgrade your organization to access this feature →</LinkOut>
                </a>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8', alignItems: 'stretch', width: '100%' }}>
              <FormRow
                key="user_inputs_and_deadends"
                title="User inputs and deadends"
                input={
                  <CmdSwitch
                    checked={organization.event_attribute_block_list.includes('user_inputs_and_deadends')}
                    onCheckedChange={(checked: boolean) => onChangeEventAttribute('user_inputs_and_deadends', checked)}
                    disabled={!canEditAnalyticsControls}
                  />
                }
                info="When enabled, user inputs will not be reported to Command AI analytics."
                interactive
                align="stretch"
              />
              <FormRow
                key="commands"
                title="Commands"
                input={
                  <CmdSwitch
                    checked={organization.event_attribute_block_list.includes('commands')}
                    onCheckedChange={(checked: boolean) => onChangeEventAttribute('commands', checked)}
                    disabled={!canEditAnalyticsControls}
                  />
                }
                info={
                  <span>When enabled, action and page executions will not be reported to CommandBar analytics.</span>
                }
                interactive
                align="stretch"
              />
              <FormRow
                key="urls"
                title="URLs"
                input={
                  <CmdSwitch
                    checked={organization.event_attribute_block_list.includes('urls')}
                    onCheckedChange={(checked: boolean) => onChangeEventAttribute('urls', checked)}
                    disabled={!canEditAnalyticsControls}
                  />
                }
                info="When enabled, users' current URLs will not be reported to Command AI analytics."
                interactive
                align="stretch"
              />
              <FormRow
                key="records"
                title="Records"
                input={
                  <CmdSwitch
                    checked={organization.event_attribute_block_list.includes('records')}
                    onCheckedChange={(checked: boolean) => onChangeEventAttribute('records', checked)}
                    disabled={!canEditAnalyticsControls}
                  />
                }
                info={
                  <span>
                    When enabled,{' '}
                    <a
                      href="https://www.commandbar.com/docs/category/records/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'underline' }}
                    >
                      records
                    </a>{' '}
                    will not be reported to CommandBar analytics.
                  </span>
                }
                interactive
                align="stretch"
              />
            </div>
          </SettingRow>
        </>
      )}
    </Page>
  );
};

export default Configure;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const LinkOut = styled(Subtitle)`
  text-decoration-line: underline;
  margin-top: 16px;
  cursor: pointer;
  color: black;
`;

const IntegrationButton = styled(Link)`
  cursor: pointer;
  padding: 8px 16px;
  gap: 8px;
  width: fit-content;
  height: 40px;
  background: #ffffff;
  border: 1px solid ${CB_COLORS.neutral400};
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${CB_COLORS.neutral700};
  margin-top: 16px;
`;

const SettingRow = styled.div<{ secondary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background: ${({ secondary }) => (secondary ? CB_COLORS.blue100 : CB_COLORS.neutral0)};
  border-radius: 8px;
`;

const Divider = styled.div`
  margin-bottom: 24px;
`;
