import GaugeChart from './GaugeChart';
import Heatmap, { Interaction } from './Heatmap';
import Layout from '../../components/Layout';
import useAnalyticsStrikes from './useAnalyticsStrikes';
import { CmdColumnDef, CmdFeatureAnnouncementCard, CmdSortableColumn, CmdSwitch } from '@commandbar/design-system/cmd';
import { useLocation } from 'react-router';
import { formatNumber, formatPercentage } from '../../utils';
import Table from '../../components/Table';

import { Progress, StatusBadge } from '@commandbar/design-system/components/antd';
import { Flag03, MessageSmileSquare, Announcement02 } from '@commandbar/design-system/icons/react';
import { useState } from 'react';

export const typeIcons = {
  product_tour: (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Flag03 color="#797C85" /> <span>Tour</span>
    </div>
  ),
  survey: (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <MessageSmileSquare color="#797C85" /> <span>Survey</span>
    </div>
  ),
  announcement: (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Announcement02 color="#797C85" /> <span>Announcement</span>
    </div>
  ),
};

const Strikes = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const threshold = params.has('threshold') ? parseInt(params.get('threshold') as string) : undefined;
  const [showUnpublished, setShowUnpublished] = useState<boolean>(false);

  const { data, isLoading, fetchData, downloadCsv, isDownloading, error } = useAnalyticsStrikes({
    threshold,
    showUnpublished,
  });

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'nudge_slug',
      header: 'Nudge',
      cell: (r) => {
        const isLive = r.row.original.is_live;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {showUnpublished && <StatusBadge color={isLive ? 'green' : 'orange'} />}
            {r?.getValue() || 'Announcement'}
          </div>
        );
      },
    },
    {
      accessorKey: 'nudge_type',
      header: 'Type',
      cell: ({ cell }) => typeIcons[cell.getValue() as 'product_tour' | 'survey' | 'announcement'],
    },
    {
      accessorKey: 'total_sessions',
      header: 'Total Sessions',
      cell: ({ cell }) => formatNumber(cell.getValue()),
    },
    {
      accessorKey: 'strike_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Strike Rate" />,
      cell: ({ cell }) => (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <span style={{ display: 'inline-block', width: '50%' }}>{formatPercentage(cell.getValue())}</span>
          <Progress
            style={{ width: '50%' }}
            percent={cell.getValue() * 100}
            size="small"
            showInfo={false}
            status="exception"
          />
        </div>
      ),
    },
  ];

  return (
    <Layout title="Strikes" disableDemoData={isLoading} onDownloadCSV={downloadCsv} isDownloading={isDownloading}>
      <CmdFeatureAnnouncementCard
        variant="blue"
        title="Introducing Strikes"
        identifier="strikes"
        children="Strikes are Nudges that users instantly close or dismiss. Use this dashboard to visualize which Nudges are most annoying to your users."
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          margin: '16px 0',
        }}
      >
        <GaugeChart metric={data?.strike_rate?.metric} />
        <Heatmap data={(data?.heatmap.rows as Interaction[]) || []} style={{ flex: 2 }} />
      </div>

      <Table
        columns={columns}
        data={data?.table.rows || []}
        isLoading={isLoading}
        empty={{
          error: !!error,
          refetchData: fetchData,
          additionalActions: [],
        }}
        toolBarChildren={
          <div>
            <CmdSwitch
              checked={showUnpublished}
              onCheckedChange={() => setShowUnpublished(!showUnpublished)}
              onLabel="Show unpublished, but previously viewed Nudges"
              offLabel="Show unpublished, but previously viewed Nudges"
              tooltip="Nudges that are (a) unpublished; (b) have at least one view."
            />
          </div>
        }
      />
    </Layout>
  );
};

export default Strikes;
