import React from 'react';
import styled from '@emotion/styled';
import { VideoRecorder } from '@commandbar/design-system/icons/react';
import { isValidURL } from '@commandbar/internal/util/location';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--layout-padding) var(--layout-padding) var(--layout-padding);
`;

const TopBlockVideoContainer = styled(VideoContainer)`
  padding: 0;
  border-radius: var(--layout-radius-card) var(--layout-radius-card) 0 0;
  overflow: hidden;
`;

const FullHeightVideoContainer = styled(VideoContainer)`
  height: 100%;
  padding: 0;
  border-radius: 0 var(--layout-radius-card) var(--layout-radius-card) 0;
  overflow: hidden;
`;

const StyledVideo = styled.video`
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 100%;
`;

const FullHeightVideo = styled(StyledVideo)`
  height: 100%;
`;

const EmbeddedVideo = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;

  & > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
`;

const FullHeightEmbeddedVideo = styled(EmbeddedVideo)`
  height: 100%;
`;

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f7f4ff 0%, #d5caff 100%);
`;

const containerComponents = {
  'top-block': TopBlockVideoContainer,
  'full-height': FullHeightVideoContainer,
  default: VideoContainer,
};

const StyledNudgeVideo = ({
  type,
  src,
  getPlayer,
  testMode,
  variant = 'default',
}: {
  type: 'pin' | 'popover' | 'modal' | 'tooltip' | 'helphub' | 'banner';
  src: string;
  getPlayer: (url: string, autoPlay?: boolean) => string | undefined;
  testMode: boolean;
  variant?: 'default' | 'top-block' | 'full-height';
}) => {
  const isEmbeddablePreview = !isValidURL(src) || !!getPlayer(src);

  const _html = React.useMemo(() => {
    const source = getPlayer(src, false) || src;
    return sanitizeHtml(source);
  }, [src]);

  const Container = containerComponents[variant];

  const getPlaceholderContainerHeight = () => {
    if (variant === 'full-height') {
      return '300px';
    }

    if (type === 'modal') {
      return '220px';
    }

    return '168px';
  };

  const Embed = variant === 'full-height' ? FullHeightEmbeddedVideo : EmbeddedVideo;
  const Video = variant === 'full-height' ? FullHeightVideo : StyledVideo;

  return src ? (
    <Container
      data-testid="commandbar-video"
      style={{
        padding: type === 'helphub' ? 0 : undefined,
      }}
    >
      {isEmbeddablePreview ? (
        !_html && testMode ? (
          <Embed>
            <h2>Unsupported</h2>
          </Embed>
        ) : (
          <Embed dangerouslySetInnerHTML={{ __html: _html }} />
        )
      ) : (
        <Video controls>
          <source src={src} type="video/mp4"></source>
          <track src="captions_en.vtt" kind="captions" label="english_captions"></track>
          Your browser does not support HTML video.
        </Video>
      )}
    </Container>
  ) : (
    <Container
      data-testid="commandbar-video"
      style={{
        height: getPlaceholderContainerHeight(),
        padding: type === 'helphub' ? 0 : undefined,
      }}
    >
      <Placeholder data-testid="commandbar-nudge-video-placeholder">
        <VideoRecorder width="24px" height="24px" />
      </Placeholder>
    </Container>
  );
};

export default StyledNudgeVideo;
