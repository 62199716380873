import type { CSSProperties } from 'react';
import type { FunnelData } from '@commandbar/internal/middleware/analytics/common';
import { CmdCard, CmdFunnelChart, CmdTypography } from '@commandbar/design-system/cmd';

type FunnelChartProps = {
  title: string;
  subtitle: string;
  data: FunnelData | undefined;
  chartHeight: number;
  isLoading: boolean;
  className?: string;
  style?: CSSProperties;
};

const FunnelChart: React.FC<FunnelChartProps> = ({
  title,
  subtitle,
  data,
  isLoading,
  className,
  chartHeight,
  style,
}) => {
  return (
    <CmdCard
      className={className}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
      }}
    >
      <CmdCard.Header>
        <CmdTypography.H3 variant="primary" fontWeight="medium">
          {title}
        </CmdTypography.H3>
        <CmdTypography.HelpText variant="secondary">{subtitle}</CmdTypography.HelpText>
      </CmdCard.Header>
      <CmdCard.Content style={{ height: `${chartHeight}px` }}>
        <CmdFunnelChart data={data} isLoading={isLoading} />
      </CmdCard.Content>
    </CmdCard>
  );
};

export default FunnelChart;
