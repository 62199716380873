import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PaddingContainer } from '@commandbar/design-system/components/antd/styledComponents';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as checklistSearchParams from '@commandbar/internal/proxy-editor/questlist_search_params';
import { useAppContext } from 'editor/src/AppStateContext';
import WidgetTemplate from '../components/templates/WidgetTemplate';
import { disableSearchIfNotExperienceSearch } from '../utils/nudge';
import * as templates from './checklist_templates';
import { template_cards } from './templates';

export const NewChecklistMenu = () => {
  const { flags } = useAppContext();
  const history = useHistory();
  const location = useLocation();

  return (
    <PaddingContainer>
      <WidgetTemplate
        type="checklist"
        header="New Checklist"
        staticTemplates={template_cards}
        onBack={() => {
          const queryParams = new URLSearchParams(location.search);
          const from = queryParams.get(checklistSearchParams.FROM);

          if (from) {
            // INFO: we can link to the template page from outside the editor router (eg: /analytics)
            // In that case, we will not have a history in our own router and will need to utilize the window
            // location to go back to that original page
            window.location.assign(from);
          } else {
            history.goBack();
          }
        }}
        createFromTemplate={(checklist) => {
          const newChecklist = disableSearchIfNotExperienceSearch(checklist ?? templates.emptyChecklist, flags);
          history.push(`${editorRoutes.CHECKLIST_ROUTE}/${newChecklist.id}`, newChecklist);
        }}
      />
    </PaddingContainer>
  );
};
