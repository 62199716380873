import { CSSProperties } from 'react';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

export const getDropdownStyles = (): Record<string, CSSProperties> => ({
  dropdownMenu: {
    background: '#FFFFFF',
    border: `1px solid ${CB_COLORS.neutral500}`,
    boxShadow: '0px 16px 40px rgba(10, 10, 15, 0.24)',
    borderRadius: '4px',
    fontSize: '8px',
    color: CB_COLORS.neutral700,
    cursor: 'pointer',
  },
  dropdown: {
    background: CB_COLORS.neutral0,
    color: CB_COLORS.neutral700,
  },
  base: {
    fontWeight: '600',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  activeDropdown: {
    background: CB_COLORS.blue100,
    color: CB_COLORS.blue900,
  },
  hoverDropdown: {
    background: '#F2F5FE',
    color: CB_COLORS.neutral900,
  },
});
