/* React imports */
import React from 'react';

import { Modal, Typography } from '@commandbar/design-system/components/antd';
import { isCompoundExpression } from '@commandbar/internal/middleware/helpers/rules';
import { IEditorCommandType, IRuleExpression } from '@commandbar/internal/middleware/types';
import { useNeedsToUpgradeFoobarPackage } from '../../../../pre-auth/compatibility';
import { CmdSwitch, CmdTag } from '@commandbar/design-system/cmd';
import { AlertCircle, Lock01 } from '@commandbar/design-system/icons/react';
import { CONDITION_TYPE_CATEGORIES_FOR_LEGACY_AVAILABILITY } from 'editor/src/editor/conditions/categories';
import ConditionGroupEditor from 'editor/src/editor/conditions/ConditionGroupEditor';
import { isConditionGroupValid } from 'editor/src/editor/conditions/validate';

export const RecommendationPanel = (props: {
  command: IEditorCommandType;
  onChange: (expr: IRuleExpression) => void;
  onChangeAlwaysRecommend: (isAlwaysRecommended: boolean) => void;
}) => {
  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.3.17');

  const expr = props.command.recommend_expression;
  const alwaysRecommend = props.command.always_recommend;

  // NOTE: for recommendation rules, an empty compound expression is changed to "false"
  // because the default is to NOT recommend the command
  const _onChange = (newExpr: IRuleExpression) => {
    if (isCompoundExpression(newExpr) && newExpr.exprs.length === 0) {
      return props.onChange({ type: 'LITERAL', value: false });
    }

    props.onChange(newExpr);
  };

  const handleAlwaysRecommendSwitchChange = async (checked: boolean) => {
    if (checked && isCompoundExpression(expr) && expr.exprs.length > 0) {
      const confirmed = await showConfirmationModal();
      if (confirmed) {
        const isEveryRecommendationRuleValid = isConditionGroupValid(expr);

        if (!isEveryRecommendationRuleValid) {
          // if any of the recommendation rules are invalid, we clear the rules
          _onChange({ type: 'AND', exprs: [] });
        }
        props.onChangeAlwaysRecommend(true);
      }
    } else {
      props.onChangeAlwaysRecommend(checked);
    }
  };

  const showConfirmationModal = () =>
    new Promise((resolve) => {
      Modal.confirm({
        title: 'Turning this on will disable your current rules. Are you sure?',
        icon: <AlertCircle className="anticon anticon-exclamation-circle" height={24} width={24} />,
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });

  return (
    <div>
      {needsToUpgrade && (
        <div style={{ marginBottom: '1rem' }}>
          <CmdTag prefixElement={<Lock01 />} variant="failure">
            Upgrade `@commandbar/foobar` package to v0.3.18 or greater to use this feature.
          </CmdTag>
        </div>
      )}
      <div>
        <Typography.Text strong>Always recommend</Typography.Text>
        <CmdSwitch
          checked={alwaysRecommend}
          disabled={needsToUpgrade}
          onCheckedChange={handleAlwaysRecommendSwitchChange}
        />
      </div>
      <div style={{ fontSize: 12, marginTop: 12 }}>
        <ConditionGroupEditor
          expr={expr}
          disabled={alwaysRecommend || needsToUpgrade}
          onChange={_onChange}
          includeCategories={CONDITION_TYPE_CATEGORIES_FOR_LEGACY_AVAILABILITY}
          excludeConditionTypes={['user_property', 'audience']}
        />
        {/* <div style={{ fontSize: 12, padding: '0px 20px' }}>
            {rules.map((rule, index) => (
              <div key={`${index}`}>
                <div>
                  <Typography.Text>
                    <pre>{JSON.stringify(rule, null, 2)}</pre>
                  </Typography.Text>
                </div>
              </div>
            ))}
          </div> */}
      </div>
    </div>
  );
};
