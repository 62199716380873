import * as t from 'io-ts';
import * as axiosInstance from './network';
import { createObject, readObject, deleteObject, updateObject, listObject, read, decodeToPromise } from './generics';
import { AudienceV } from './helpers/audience';

export const HelpDocsIntegrationV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      type: t.string,
      schedule: t.string,
      meta: t.union([t.record(t.string, t.any), t.null]),
      audience: t.union([AudienceV, t.null]),
      helphub_view_article_button_hidden: t.union([t.boolean, t.undefined, t.null]),
    }),
    t.partial({
      title: t.string,
      icon: t.string,
      commands_count: t.number,
      recent_sync_date: t.union([t.string, t.null]),
      recent_sync_error: t.boolean,
    }),
  ],
  'HelpDocsIntegrationV',
);

const SyncStatusV = t.intersection([
  t.type({
    running: t.boolean,
    numFetched: t.number,
    numProcessed: t.number,
  }),
  t.partial({
    deleting: t.boolean,
  }),
]);

export class HelpDocsIntegration {
  public static create = createObject(HelpDocsIntegrationV, HelpDocsIntegrationV, 'integrations/helpdocs');
  public static read = readObject(HelpDocsIntegrationV, 'integrations/helpdocs');
  public static list = listObject(HelpDocsIntegrationV, 'integrations/helpdocs');
  public static update = updateObject(HelpDocsIntegrationV, HelpDocsIntegrationV, 'integrations/helpdocs');
  public static delete = deleteObject(HelpDocsIntegrationV, 'integrations/helpdocs');

  /** Get the status of the latest currently running sync for an integration */
  public static readSyncStatus = (integrationId: string) =>
    read(
      SyncStatusV,
      `integrations/sync/running`,
    )({
      integration_id: integrationId,
    });
}

const IntercomCreateConversationPayloadV = t.type({
  message_id: t.string,
  intercom_user_type: t.union([t.literal('visitor'), t.literal('user')]),
  intercom_user_id: t.union([t.string, t.undefined]),
});

const IntercomCreateConversationResponseV = t.partial({
  conversation_id: t.string,
});

export class IntercomIntegration {
  public static createConversation = async (payload: t.TypeOf<typeof IntercomCreateConversationPayloadV>) => {
    const result = await axiosInstance.post(`integrations/intercom/create_conversation/`, payload);

    return await decodeToPromise(IntercomCreateConversationResponseV, result.data);
  };
}
