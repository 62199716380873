import React from 'react';

import styled from '@emotion/styled';
import {
  ICommandCategoryType,
  IRecordSettingsByContextKey,
  IRecordSettings,
} from '@commandbar/internal/middleware/types';
import * as OrganizationSettings from '@commandbar/internal/middleware/organizationSettings';
import { SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';

import { matchAll } from '../../utils/fp';

import {
  Heading,
  SortableList,
  Tooltip,
  Alert,
  Space,
  Spin,
  Typography,
  Panel,
  FeatureAnnouncementCard,
  Row,
} from '@commandbar/design-system/components/antd';
import { getNewSortkey } from '../../components/SortableTable';
import { ContextSettingsModal } from '../../context/contextSettings/ContextSettingsModal';
import { useContextPartition } from '../../context/contextSettings/useContextPartition';
import { CmdButton, CmdDivider, CmdTag } from '@commandbar/design-system/cmd';
import CommandList from '../CommandsTab/CommandList';
import { EyeOff, Menu05, Settings03 } from '@commandbar/design-system/icons/react';
import CategorySettings from '../CommandsTab/CategorySettings';
import { useAppContext } from 'editor/src/AppStateContext';

type MergedCategoryTypes = 'COMMAND' | 'RECORD' | 'SYNTHETIC';

export enum PanelKey {
  CATEGORIES = 'categories',
  SORT = 'sort',
}

interface IMergedCategoriesSortData {
  id: number | string | null | undefined;
  commandCategory?: ICommandCategoryType;
  resourceSettings?: IRecordSettings;
  name: string | undefined;
  type: MergedCategoryTypes;
  sort_key: number;
  pinnedToBottom: boolean;
  shownInEmptyState: boolean;
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Categories = () => {
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([]);
  const [activeCategory, setActiveCategory] = React.useState<ICommandCategoryType | undefined>(undefined);
  const { commands, organization } = useAppContext();

  const handleCategoryChange = (command: ICommandCategoryType) => {
    setActiveCategory(command);
  };

  return (
    <>
      <FeatureAnnouncementCard identifier="bar-categories2" title={<Row align="middle">Using categories</Row>}>
        <span>
          Categories are groups of pages and actions. You can define the order of them in the Sort Order section.
          <br />
          <span style={{ fontWeight: 600 }}>Before a user enters a query</span>, categories will appear in the order
          below.
          <br />
          <span style={{ fontWeight: 600 }}>After a user enters a query,</span> the order below is used as a tie-breaker
          for categories that share the same search score. A {`category's`} search score is the highest search score of
          any of that {`category's`} matches.
          <br />
        </span>
      </FeatureAnnouncementCard>
      <Panel panelKey={PanelKey.CATEGORIES} activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Categories">
        <CommandList
          handleCategoryChange={handleCategoryChange}
          // if an organization has in_bar_doc_search turned off, do not show helpdocs
          commands={commands.filter((command) => command.template.type !== 'helpdoc' || organization.in_bar_doc_search)}
          type="page"
        />
      </Panel>
      <Panel panelKey={PanelKey.SORT} activeKeys={activeKeys} setActiveKeys={setActiveKeys} header="Sort Order">
        <MergedCategoriesList handleCategoryChange={handleCategoryChange} />
      </Panel>

      {activeCategory && (
        <CategorySettings
          activeCategory={activeCategory}
          onClose={() => {
            setActiveCategory(undefined);
          }}
        />
      )}
    </>
  );
};

const Container = styled.div`
  height: 40px;
  border-radius: 4px;
  padding: 4px, 12px, 4px, 4px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-grow: 1;
  cursor: grab;
  border: 1px solid #e6e6e8;
  margin: 4px 0px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition-property: box-shadow, transform;
  transition-duration: 0.3s;
  user-select: none;
`;

const HoverableContainer = styled(Container)`
  &:hover {
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
    transform: scale(1.02);
  }
`;

const DisabledContainer = styled(Container)`
  opacity: 0.75;
  cursor: not-allowed;
`;

const mergeCategories = (
  commandCategories: ICommandCategoryType[],
  resourceSettings: IRecordSettingsByContextKey,
  syntheticCategories: IMergedCategoriesSortData[],
): IMergedCategoriesSortData[] => {
  const commandCategorySortData: IMergedCategoriesSortData[] = commandCategories.map((c) => ({
    id: c.id,
    commandCategory: c,
    name: c.name,
    type: 'COMMAND',
    sort_key: c.sort_key != null ? c.sort_key : Number.POSITIVE_INFINITY,
    pinnedToBottom: c.setting_pin_to_bottom,
    shownInEmptyState: !c.setting_hide_before_search,
  }));
  const recordCategorySortData: IMergedCategoriesSortData[] = Object.entries(resourceSettings)
    .filter(([_key, settings]) => {
      return !!settings.search && !_key.startsWith(SDK_INTERNAL_PREFIX); // only quickfindable record categories matter for ordering)
    })
    .map(([key, settings]) => ({
      id: key,
      resourceSettings: settings,
      name: settings.name ? settings.name : capitalize(key),
      type: 'RECORD',
      sort_key: settings.sort_key != null ? settings.sort_key : Number.POSITIVE_INFINITY,
      pinnedToBottom: !!settings.setting_pin_to_bottom,
      shownInEmptyState: !!settings.showResources,
    }));
  const merged = [...commandCategorySortData, ...recordCategorySortData, ...syntheticCategories].sort(
    (a, b) => a.sort_key - b.sort_key,
  );

  for (let i = 0; i < merged.length; i++) {
    // Reissue sort_keys to merge indexes between Record and Command Category.
    merged[i].sort_key = i;
  }
  return merged;
};

const isPinnedToBottom = (c: IMergedCategoriesSortData) => !!c.pinnedToBottom;

interface MergedCategoriesListProps {
  handleCategoryChange: (command: ICommandCategoryType) => void;
}

const MergedCategoriesList = ({ handleCategoryChange }: MergedCategoriesListProps) => {
  const { dispatch, organization, categories: commandCategories } = useAppContext();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [modifiedCategories, setModifiedCategories] = React.useState<IMergedCategoriesSortData[] | null>(null);
  const { recordsByKey } = useContextPartition();

  const experiencesCategoryKey = `RECORD_CATEGORY-${SDK_INTERNAL_PREFIX}experiences`;

  const recentscat: IMergedCategoriesSortData = {
    id: 'RECENTS-',
    name: 'Recents',
    type: 'SYNTHETIC',
    sort_key: organization.recents_sort_key === null ? -2 : organization.recents_sort_key,
    pinnedToBottom: false,
    shownInEmptyState: true,
  };
  const recommendedcat: IMergedCategoriesSortData = {
    id: 'RECOMMENDED-',
    name: 'Recommended',
    type: 'SYNTHETIC',
    sort_key: organization.recommended_sort_key === null ? -3 : organization.recommended_sort_key,
    pinnedToBottom: false,
    shownInEmptyState: true,
  };
  const experiencesCat: IMergedCategoriesSortData = {
    id: experiencesCategoryKey,
    name: 'Experiences',
    type: 'SYNTHETIC',
    sort_key: organization.experiences_sort_key === null ? -1 : organization.experiences_sort_key,
    pinnedToBottom: false,
    shownInEmptyState: false,
  };

  const allCategories = mergeCategories(commandCategories, organization.resource_options, [
    recentscat,
    recommendedcat,
    experiencesCat,
  ]).filter((c) => (c.type === 'RECORD' ? c.id && c.id in recordsByKey : true));

  const categories = modifiedCategories || allCategories.filter((v) => !isPinnedToBottom(v));

  const pinnedCategories = allCategories.filter(isPinnedToBottom);

  const saveCommandCategoryIfChanged = async (categories: IMergedCategoriesSortData[]) => {
    const sortKeyMapping: { [id: number]: number } = {};

    categories.forEach((category) => {
      const commandCategory = category.commandCategory;
      if (commandCategory && commandCategory.sort_key !== category.sort_key) {
        sortKeyMapping[commandCategory.id] = category.sort_key;
      }
    });

    if (Object.keys(sortKeyMapping).length === 0) {
      return;
    }

    await dispatch.categories.updateSortKeys(sortKeyMapping);
  };

  const saveSyntheticCategoriesIfChanged = async (categories: IMergedCategoriesSortData[]) => {
    const recentsCategory = categories.find((cat) => cat.id === 'RECENTS-');

    if (
      !!recentsCategory &&
      recentsCategory.sort_key !== organization.recents_sort_key &&
      recentsCategory.sort_key >= 0
    ) {
      await OrganizationSettings.update({
        recents_sort_key: recentsCategory.sort_key,
      });
    }

    const recommendedCategory = categories.find((cat) => cat.id === 'RECOMMENDED-');

    if (
      !!recommendedCategory &&
      recommendedCategory.sort_key !== organization.recommended_sort_key &&
      recommendedCategory.sort_key >= 0
    ) {
      await OrganizationSettings.update({
        recommended_sort_key: recommendedCategory.sort_key,
      });
    }

    const experiencesCategory = categories.find((cat) => cat.id === experiencesCategoryKey);

    if (
      !!experiencesCategory &&
      experiencesCategory.sort_key !== organization.experiences_sort_key &&
      experiencesCategory.sort_key >= 0
    ) {
      await OrganizationSettings.update({
        experiences_sort_key: experiencesCategory.sort_key,
      });
    }
  };

  const saveRecordCategoriesIfChanged = async (recordCategories: IMergedCategoriesSortData[]) => {
    let resourceOptions = organization.resource_options;

    for (const category of recordCategories) {
      if (!category.id || typeof category.id !== 'string') continue;

      const contextKey = category.id;

      if (resourceOptions[contextKey] && resourceOptions[contextKey].sort_key !== category.sort_key) {
        resourceOptions = {
          ...resourceOptions,
          [category.id]: { ...resourceOptions[contextKey], sort_key: category.sort_key },
        };
      }
    }
    if (resourceOptions !== organization.resource_options) {
      await dispatch.organization.update({
        ...organization,
        resource_options: resourceOptions,
      });
    }
  };

  const onSave = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const records: IMergedCategoriesSortData[] = [];
      const commandCategories: IMergedCategoriesSortData[] = [];
      const syntheticCategories: IMergedCategoriesSortData[] = [];

      matchAll(categories, (c) => c.type, {
        RECORD: (category) => {
          records.push(category);
        },
        COMMAND: (category) => {
          commandCategories.push(category);
        },
        SYNTHETIC: (category) => {
          syntheticCategories.push(category);
        },
      });

      // A bit hacky here how to resolve Promises to update all entities
      await saveSyntheticCategoriesIfChanged(syntheticCategories);
      await saveRecordCategoriesIfChanged(records);
      await saveCommandCategoryIfChanged(commandCategories);
    } catch (e) {
      setError(String(e));
    } finally {
      setIsLoading(false);
    }

    setModifiedCategories(null);
    setIsDirty(false);
    setIsLoading(false);
  };

  const onReorder = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newCategories = categories.map((category: IMergedCategoriesSortData, currentIndex: number) => {
      const newSortKey = getNewSortkey(currentIndex, oldIndexOfMovedObj, newIndexOfMovedObj);

      return { ...category, sort_key: newSortKey };
    });
    setIsDirty(true);
    setModifiedCategories(newCategories);
  };

  return (
    <div>
      {error && (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Error saving changes:
              <Typography.Text code>{error.slice(0, 100)}</Typography.Text>
            </span>
          }
          type="error"
        />
      )}
      <Heading
        text="Categories order"
        style={{ marginTop: 0 }}
        rightActions={[
          <div>
            <CmdButton key="save" onClick={onSave} disabled={!isDirty}>
              Save
            </CmdButton>
          </div>,
        ]}
      />
      <Spin spinning={isLoading}>
        <SortableList
          nodes={categories
            .sort((a, b) => a.sort_key - b.sort_key)
            .map((c) => (
              <CategoryRow key={c.id} data={c} isSortable={true} handleCategoryChange={handleCategoryChange} />
            ))}
          onSort={onReorder}
        />
      </Spin>

      {pinnedCategories.length > 0 && (
        <>
          <CmdDivider orientation="start">
            <span style={{ fontSize: 12 }}>Pinned to bottom</span>
          </CmdDivider>
          {pinnedCategories.map((c) => (
            <CategoryRow key={c.id} data={c} isSortable={false} handleCategoryChange={handleCategoryChange} />
          ))}
        </>
      )}
    </div>
  );
};

const CategoryRow = (props: {
  data: IMergedCategoriesSortData;
  isSortable: boolean;
  handleCategoryChange: (command: ICommandCategoryType) => void;
}) => {
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const { recordsByKey } = useContextPartition();
  //  const linkedCommandHelper = useLinkedCommand();

  const { data, isSortable, handleCategoryChange } = props;

  const Container = isSortable ? HoverableContainer : DisabledContainer;

  const hiddenInDefaultTooltip = (
    <Tooltip content={'This category is only visible after a user starts searching.'}>
      <EyeOff />
    </Tooltip>
  );

  const canChangeSettings = (data.type === 'RECORD' && data.id != null) || data.commandCategory;

  return (
    <>
      <Container>
        <Space align="center">
          {isSortable ? <Menu05 style={{ paddingTop: 3 }} /> : <div style={{ width: 12 }} />}
          {data.name}
          {<CmdTag variant="info">{capitalize(data.type.toLowerCase())}</CmdTag>}
          {!data.shownInEmptyState ? hiddenInDefaultTooltip : null}
        </Space>
        {canChangeSettings && (
          <Tooltip content="Edit settings">
            <CmdButton
              icon={<Settings03 />}
              variant="link"
              onClick={(e) => {
                if (data.commandCategory) {
                  handleCategoryChange(data.commandCategory);
                  return;
                }
                setShowSettings(true);
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )}
      </Container>
      {showSettings &&
        (data.type === 'RECORD' && data.id != null ? (
          <ContextSettingsModal onClose={() => setShowSettings(false)} data={recordsByKey[data.id]} isRecord={true} />
        ) : null)}
    </>
  );
};

export default Categories;
