import React, { useCallback, useEffect } from 'react';

import { CB_COLORS, Form, Input, Skeleton, SubHeading } from '@commandbar/design-system/components/antd';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import { ActionEditor } from '../ActionEditor';
import { TagSelect } from '../components/TagSelect';
import { StyledTextArea } from '../helphub/shared';
import useChatSettings from '../useEditor/useChatSettings';
import debounce from 'lodash/debounce';
import { useAppContext } from 'editor/src/AppStateContext';
import { IOrganizationSettingsType } from '@commandbar/internal/middleware/types';
import { CmdSwitch } from '@commandbar/design-system/cmd';
import { useReportEvent } from 'editor/src/hooks/useEventReporting';
import styled from '@emotion/styled';

const InlineRow = styled(Form.Item)`
  margin: 8px 0 0 0;
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-flow: initial;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: 0;
  }
  .ant-form-item-control {
    flex-grow: 0;
  }
`;

const InteractionSettings = () => {
  const {
    fallbackAction,
    fallbackMessage,
    welcomeMessage,
    suggestedSearches,
    negativeFeedbackMessage,
    negativeFeedbackAction,
    actions: chatActions,
  } = useChatSettings();

  const { reportEvent } = useReportEvent();

  const reportChangedEvent = useCallback(
    (field: keyof IOrganizationSettingsType, value: any) => {
      reportEvent(`${field.replaceAll('_', ' ')} changed`, {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: `${value}`,
      });
    },
    [reportEvent],
  );

  const {
    organization,
    dispatch: {
      organization: { updateSetting },
    },
    flags,
  } = useAppContext();

  const debouncedUpdateSetting = useCallback(
    debounce(async (label: keyof IOrganizationSettingsType, value) => {
      await updateSetting({ [label]: value });
    }, 1200),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSetting.cancel();
    };
  }, [debouncedUpdateSetting]);

  const negativeFallbackContent = () => {
    const enabledSwitch = (
      <InlineRow
        colon={false}
        name="copilot_negative_feedback_fallback_enabled"
        label="Negative Feedback Fallback Message"
        tooltip={{
          icon: <InfoCircle height={16} />,
          title: 'Show fallback on negative feedback',
        }}
      >
        <CmdSwitch
          checked={!!organization?.copilot_negative_feedback_fallback_enabled}
          disabled={!organization?.copilot_enabled}
          onCheckedChange={(value) => {
            reportChangedEvent('copilot_negative_feedback_fallback_enabled', value);
            updateSetting({ copilot_negative_feedback_fallback_enabled: value });
          }}
        />
      </InlineRow>
    );

    if (!organization?.copilot_negative_feedback_fallback_enabled) {
      return enabledSwitch;
    }

    return (
      <div
        style={{
          background: '#F9F9F9',
          borderRadius: '6px',
          padding: '0px 8px 8px 8px',
          marginTop: '8px',
          display: 'flex',
          gap: 8,
          flexDirection: 'column',
        }}
      >
        {enabledSwitch}
        <StyledTextArea
          rows={2}
          value={negativeFeedbackMessage ?? ''}
          onChange={(e) => chatActions.negativeFeedbackMessage.update(e.target.value)}
          placeholder="I couldn’t find an answer to your question. Do you want to try rephrasing it?"
          style={{
            border: `1px solid ${CB_COLORS.neutral300}`,
          }}
        />
        <SubHeading>CTA</SubHeading>
        <Input
          value={negativeFeedbackAction?.cta ?? ''}
          onChange={(e) =>
            chatActions.negativeFeedbackAction.update((draft) => {
              draft.cta = e.target.value;
            })
          }
          style={{ height: '32px', fontSize: 14, border: `1px solid ${CB_COLORS.neutral300}` }}
          placeholder="Label"
          size="small"
        />
        <ActionEditor
          possibleTypes={[
            'click',
            'execute_command',
            'help_doc',
            'video',
            'link',
            'page',
            'open_chat',
            'chat_handoff',
            'open_helphub',
            'open_bar',
            'nudge',
            'questlist',
          ]}
          lightBorder
          action={negativeFeedbackAction?.action}
          onActionChange={(action) => {
            if (action.type === 'dismiss' || action.type === 'snooze') return;

            chatActions.negativeFeedbackAction.update((draft) => {
              if (action) {
                draft.action = action;
              }
            });
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
        <SubHeading style={{ marginTop: '8px' }}>Welcome Message</SubHeading>
        <StyledTextArea
          rows={2}
          name="prompt"
          value={welcomeMessage}
          onChange={(e) => chatActions.welcomeMessage.update(e.target.value)}
          placeholder="Hey there! What can we help with?"
          style={{
            border: `1px solid ${CB_COLORS.neutral300}`,
          }}
        />
      </div>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
        <SubHeading style={{ marginTop: '8px' }}>Suggested queries</SubHeading>
        <TagSelect
          key="suggested-searches-select"
          value={suggestedSearches}
          onChange={chatActions.suggestedSearches.update}
          placeholder="Type a question and press enter"
        />
      </div>

      {!flags?.['release-copilot-fallbacks-v-2'] && (
        <>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <SubHeading style={{ marginTop: '8px' }}>General Fallback Message</SubHeading>
            <StyledTextArea
              rows={2}
              name="prompt"
              value={fallbackMessage}
              onChange={(e) => chatActions.fallbackMessage.update(e.target.value)}
              placeholder="I couldn’t find an answer to your question. Do you want to try rephrasing it?"
              style={{
                border: `1px solid ${CB_COLORS.neutral300}`,
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
            <SubHeading>CTA</SubHeading>
            {fallbackAction ? (
              <Input
                value={fallbackAction.cta ?? ''}
                onChange={(e) =>
                  chatActions.fallbackAction.update((draft) => {
                    draft.cta = e.target.value;
                  })
                }
                style={{ height: '32px', fontSize: 14, border: `1px solid ${CB_COLORS.neutral300}` }}
                placeholder="Label"
                size="small"
              />
            ) : (
              <Skeleton.Input active />
            )}
            {fallbackAction ? (
              <ActionEditor
                possibleTypes={[
                  'click',
                  'execute_command',
                  'help_doc',
                  'video',
                  'link',
                  'page',
                  'open_chat',
                  'chat_handoff',
                  'open_helphub',
                  'open_bar',
                  'nudge',
                  'questlist',
                ]}
                lightBorder
                action={fallbackAction.action}
                onActionChange={(action) => {
                  if (action.type === 'dismiss' || action.type === 'snooze') return;

                  chatActions.fallbackAction.update((draft) => {
                    if (action) {
                      draft.action = action;
                    }
                  });
                }}
              />
            ) : (
              <Skeleton.Input active />
            )}
            {negativeFallbackContent()}
          </div>
        </>
      )}
    </div>
  );
};

export default InteractionSettings;
