import { Tabs as AntTabs } from 'antd';
import styled from '@emotion/styled';

import { CB_COLORS } from './colors';

export const TabsBase = styled(AntTabs, { shouldForwardProp: (prop) => prop !== 'isStudio' })`
  && .ant-tabs-nav-wrap {
    padding: 0;
  }

  && .ant-tabs-content-holder {
    background-color: transparent;
  }

  && .ant-tabs-content {
    position: relative;
    height: 100%;
  }

  .ant-tabs-nav::before {
    border-bottom: ${(props: { isStudio?: boolean }) => (props?.isStudio ? '1px solid #e5e4e7' : '')};
  }

  .ant-tabs-tab {
    border: ${(props: { isStudio?: boolean }) => (props?.isStudio ? '1px solid transparent !important' : '')};
    transition: ${(props: { isStudio?: boolean }) =>
      props?.isStudio ? 'border-bottom 0s !important' : 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'};
    padding: 12px 16px;
    margin: 0px;
    margin-left: 4px;
    background: transparent;
  }

  .ant-tabs-tab-active {
    border: ${(props: { isStudio?: boolean }) => (props?.isStudio ? '1px solid #e5e4e7 !important' : '')};
    border-bottom: ${(props: { isStudio?: boolean }) => (props?.isStudio ? 'none !important' : '')};
  }
`;

export const Tabs = styled(TabsBase)`
  .ant-tabs-tab {
    color: ${CB_COLORS.neutral600};
    text-shadow: none !important;
  }

  && .ant-tabs-tab-active {
    background: ${CB_COLORS.neutral0};
    color: #000;
    text-shadow: none !important;
  }

  .ant-tabs-tab-btn {
    transition: none;
    text-shadow: none !important;
  }
`;

export const TabPane = styled(AntTabs.TabPane)`
  position: absolute;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${CB_COLORS.neutral0};
`;
