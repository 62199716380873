import React from 'react';

import { IEntityChangeType, IHistoryEventType } from '@commandbar/internal/middleware/types';
import { listEntityChanges } from '@commandbar/internal/middleware/entityChanges';

import {
  Skeleton,
  List,
  Avatar,
  Space,
  Empty,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
  Page,
  Alert,
} from '@commandbar/design-system/components/antd';

import dayjs from 'dayjs';
import chroma from 'chroma-js';
import { useReportEvent } from '../hooks/useEventReporting';
import { CmdButton, CmdDivider } from '@commandbar/design-system/cmd';
import { User01 } from '@commandbar/design-system/icons/react';
import { HistoryEvent } from '@commandbar/internal/middleware/historyEvent';
import { useAppContext } from 'editor/src/AppStateContext';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const History = ({ useNewEntityChangesEndpoint }: { useNewEntityChangesEndpoint: boolean }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<(IEntityChangeType | IHistoryEventType)[]>([]);
  const { flags } = useAppContext();

  const { reportEvent } = useReportEvent();

  React.useEffect(() => {
    let canceled = false;

    reportEvent('history tab visited', {
      segment: true,
      highlight: true,
      slack: true,
    });

    (async () => {
      setLoading(true);
      try {
        const items = useNewEntityChangesEndpoint ? await listEntityChanges() : await HistoryEvent.list();
        if (!canceled) setItems(items);
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    })();

    return () => {
      canceled = true;
    };
  }, [useNewEntityChangesEndpoint]);

  const userColors: any = {};
  let count = 10;
  const listData = items.map((item) => {
    // Use chroma.brewer.Set3 colors to give diff avatars diff colors
    let avatarColor = '#cccccc';
    const { changed_by } = item;
    if (count < 0) {
      avatarColor = '#cccccc';
    } else if (changed_by !== undefined) {
      if (userColors.hasOwnProperty(changed_by)) {
        avatarColor = userColors[changed_by];
      } else {
        avatarColor = chroma.brewer.Set3[count];
        userColors[changed_by] = avatarColor;
        count = count - 1;
      }
    }

    const timestamp = dayjs(item.created).format('LLL');
    return {
      ...item,
      item,
      stamp: `${item.changed_by || 'CommandBar Update'} on ${timestamp}`,
      avatarColor,
    };
  });

  const content = loading ? (
    <Skeleton active={true} />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FeatureAnnouncementCard identifier="history" title={<Row align="middle">Viewing history</Row>}>
        <span>You can view changes made to your configuration below.</span>
      </FeatureAnnouncementCard>
      {flags['show-history-events-missing-banner'] !== '' ? (
        <Alert
          banner
          type={'warning'}
          style={{ padding: '8px 16px' }}
          message={`We are upgrading our version control system. As part of this upgrade, changes made before ${flags['show-history-events-missing-banner']} will not be visible here. Please reach out to support@commandbar.com if this presents an issue.`}
        />
      ) : null}
      <SimplePanel>
        <List
          itemLayout="vertical"
          size="small"
          locale={{
            emptyText: (
              <Empty
                description={
                  <span style={{ color: 'rgba(0,0,0,0.6)' }}>
                    This tab will catalog all changes made to your CommandBar config. Try creating a command and then
                    return to this tab.
                  </span>
                }
              />
            ),
          }}
          pagination={{
            defaultPageSize: 10,
          }}
          dataSource={listData}
          renderItem={(item) => {
            return (
              <List.Item key={`history-${item.id}`} actions={[]} style={{ border: '0px', padding: '0px' }}>
                <div style={{ flexGrow: 1 }}>
                  {'sha1' in item && (
                    <CmdDivider orientation="end">
                      <Space>
                        <CmdButton size="sm" disabled={true}>
                          {item.sha1.substring(0, 7)}
                        </CmdButton>
                      </Space>
                    </CmdDivider>
                  )}
                </div>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: item.avatarColor,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      size="small"
                      icon={<User01 />}
                    />
                  }
                  title={item.description}
                  description={item.stamp}
                />
              </List.Item>
            );
          }}
        />
      </SimplePanel>
    </div>
  );

  return (
    <Page section="Settings" title="History">
      {content}
    </Page>
  );
};

export default History;
