/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React, { useEffect } from 'react';
import {
  IArgumentType,
  IArgumentMap,
  isTimeArgument,
  IRecordSettingsByContextKey,
} from '@commandbar/internal/middleware/types';

import {
  IOptionType,
  argumentTypes,
  validateArgValue,
  parseValue,
  argValue,
  selectTypes,
  ARGUMENT_TYPE,
  isArgumentASelect,
  isPromptTheDefault,
} from '../../argumentUtils';
import { ValidatedJSONEditor } from '../../../../../components/JSONEditor';
import { DateTimeArguments } from './DateTimeArguments';

import { FormRow, Input, ValidatedInput, Checkbox, ReactSelect } from '@commandbar/design-system/components/antd';

import { components, ControlProps, OptionProps } from 'react-select';
import { Radio, RadioItem } from './Radio';
import { ContextKeysSelect } from '../../ContextKeysSelect';
import PrettyCodeEditor from 'editor/src/editor/components/PrettyCodeEditor';
import { CmdDivider } from '@commandbar/design-system/cmd';

interface IOptionProps {
  argumentName: string;
  data: IArgumentType;
  type: IOptionType | undefined;
  onUpdate: (arg: string, val: any) => void;
  arguments: IArgumentMap;
  // FIXME: make these non optional props
  recordSettings?: IRecordSettingsByContextKey;
}

const isOptionType = (option: IOptionType | readonly IOptionType[] | null | undefined): option is IOptionType =>
  !!(option && !Array.isArray(option));

const ArgumentTypeOption = (props: OptionProps<IOptionType, false>) => {
  return (
    <div style={{ display: 'flex' }}>
      <components.Option {...props}>
        <span style={{ marginRight: 12, display: 'flex' }}>{props.data.icon}</span>
        {props.children}
      </components.Option>
    </div>
  );
};

const ArgumentTypeControl = (props: ControlProps<IOptionType, false>) => {
  const { children, ...rest } = props;

  const icon = isOptionType(props.selectProps.value) ? props.selectProps.value.icon : null;

  return (
    <components.Control {...rest}>
      <span style={{ marginLeft: 12, display: 'flex' }}>{icon}</span>
      {children}
    </components.Control>
  );
};

/****** Type: What type are the argument options? */

export const ArgumentOptionType = (props: IOptionProps) => {
  const { type } = props;
  const [showSelectOpts, setShowSelectOpts] = React.useState<boolean>(isArgumentASelect(props.type));

  useEffect(() => {
    setShowSelectOpts(isArgumentASelect(props.type));
  }, [props.type]);

  const currentPrompt = props.data.label;
  const changePromptToDefault = !props.data.label || isPromptTheDefault(currentPrompt);

  const onTypeSelect = (selected: IOptionType) => {
    if (selected.type === ARGUMENT_TYPE.PROVIDED) {
      // provided values
      props.onUpdate(props.argumentName, {
        type: 'provided',
        value: selected.key,
        label: changePromptToDefault ? selected.defaultPrompt : currentPrompt,
        input_type: undefined,
        preselected_key: undefined,
        label_field: undefined,
      });

      if (selected.key === 'time') {
        handleDateTimeArgumentOptionClick(1);
      }

      setShowSelectOpts(false);
    } else if (selected.type === ARGUMENT_TYPE.CUSTOM_SELECT) {
      // custom select values
      props.onUpdate(props.argumentName, {
        type: selected.key,
        value: selected.default,
        label: changePromptToDefault ? selected.defaultPrompt : currentPrompt,
        input_type: undefined,
        preselected_key: undefined,
        label_field: undefined,
      });
    } else if (selected.type === ARGUMENT_TYPE.META_DESCRIPTION) {
      setShowSelectOpts(true);

      props.onUpdate(props.argumentName, {
        type: 'context',
        value: '',
        label: changePromptToDefault ? selected.defaultPrompt : currentPrompt,
        input_type: undefined,
        preselected_key: undefined,
        label_field: undefined,
        show_in_record_action_list: false,
        show_in_default_list: true,
      });
    } else if (selected.type === ARGUMENT_TYPE.DASHBOARD) {
      props.onUpdate(props.argumentName, {
        type: selected.key,
        value: {
          title: '',
          description: '',
          source: '',
        },
        label: selected.defaultPrompt,
        input_type: undefined,
        preselected_key: undefined,
        label_field: undefined,
        show_in_record_action_list: false,
        show_in_default_list: true,
      });
    }
  };

  const handleDateTimeArgumentOptionClick = (id: number) => {
    const defaultPrompts: Record<number, string> = {
      1: 'Enter a date or time, like Friday at 4pm',
      2: 'Enter a date, like next Friday',
      3: 'Enter a time, like 4pm',
    };

    props.onUpdate(props.argumentName, {
      type: 'provided',
      value: 'time',
      dateTimeArgumentTypeId: id,
      label: defaultPrompts[id],
      input_type: undefined,
      preselected_key: undefined,
      label_field: undefined,
    });
  };

  const isOptionSelected = (option: IOptionType) => {
    if (option.type === ARGUMENT_TYPE.META_DESCRIPTION) {
      return showSelectOpts;
    }

    return option.key === props.type?.key;
  };

  const selectedOption = argumentTypes.find((option) => isOptionSelected(option));

  return (
    <div>
      <FormRow
        title="Argument type"
        input={
          <ReactSelect<IOptionType>
            defaultValue={selectedOption}
            options={argumentTypes}
            onChange={(value) => {
              if (value) {
                onTypeSelect(value);
              }
            }}
            isOptionSelected={isOptionSelected}
            components={{ Option: ArgumentTypeOption, Control: ArgumentTypeControl }}
            isSearchable={false}
          />
        }
      />

      {showSelectOpts && (
        <>
          <CmdDivider />
          <Radio style={{ marginTop: '12px' }}>
            {selectTypes.map((opt: any) => (
              <RadioItem
                key={opt.key}
                label={opt.label}
                selected={isOptionSelected(opt)}
                onClick={() => onTypeSelect(opt)}
              />
            ))}
          </Radio>
        </>
      )}

      {type?.key === 'time' && (
        <>
          <CmdDivider spacing="md" />
          <div style={{ marginTop: 16 }}>
            <DateTimeArguments
              selectedTypeId={isTimeArgument(props.data) ? props.data.dateTimeArgumentTypeId : undefined}
              handleOptionClick={handleDateTimeArgumentOptionClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const ArgumentOptionTypeValue = (props: IOptionProps) => {
  const value = argValue(props.argumentName, props.arguments);

  let content;

  if (props.type === undefined || !props.type?.hasValue) {
    content = null;
  } else if (props.type.key === 'set') {
    content = (
      <ValidatedJSONEditor
        contextID={0}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        json={JSON.parse(value!)}
        onChange={(e: any) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          props.onUpdate(props.argumentName, { value: parseValue(props.type!, e.json) });
        }}
        readOnly={false}
        height={150}
      />
    );
  } else if (props.type.key === 'context') {
    content = (
      <ContextKeysSelect
        value={value}
        onChange={(value) => props.onUpdate(props.argumentName, { value, type: 'context' })}
      />
    );
  } else {
    content = (
      <ValidatedInput
        value={value as any}
        placeholder={props.type?.placeholder}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        validator={(newVal: string) => validateArgValue(props.type!, newVal)}
        onChange={(e: any) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          props.onUpdate(props.argumentName, { value: parseValue(props.type!, e.target.value) });
        }}
        prefix={props.type?.prefix}
      />
    );
  }

  /**** Value: What is the value of the type? */
  return (
    <div style={{ marginTop: 10 }}>
      <FormRow title={props.type?.valuePrompt || ''} input={content} />
    </div>
  );
};

export const ArgumentOptionLabel = (props: IOptionProps) => {
  if (props.type?.key === 'text') return null;
  if (props.type?.key === 'time') return null;

  const isSetInContextTab = props.type?.key === 'context';
  const value = argValue(props.argumentName, props.arguments);
  const recordSettingsLabelField =
    isSetInContextTab && typeof value === 'string' ? props.recordSettings?.[value]?.label_field : undefined;

  return (
    <FormRow
      gutter={[8, 24]}
      title="Option label key"
      info={`By default, CommandBar will use the 'label' field of an object when displaying values to the user. ${
        isSetInContextTab
          ? "To change this field, edit this key's settings in Arguments or Records Tab"
          : 'You can use a different key by specifying it here.'
      }`}
      input={
        <Input
          disabled={isSetInContextTab}
          value={isSetInContextTab ? recordSettingsLabelField : props.data.label_field}
          placeholder="Argument field, e.g., 'name'"
          onChange={(e) => props.onUpdate(props.argumentName, { label_field: e.target.value || undefined })}
        />
      }
    />
  );
};

/* Is there a context key that points to the current value? */
export const ArgumentOptionPreselectedKey = (props: IOptionProps) => {
  return (
    <div>
      <FormRow
        gutter={[8, 24]}
        title="Pre-select"
        info={`Does this argument already have a value? If so, we'll ${
          props.type?.key === 'text'
            ? 'show this value as a default.'
            : 'show the current value with a check next to it.'
        } If the given value does not match a context key, we will try to use your input directly.`}
        input={
          <Input
            value={props.data.preselected_key}
            placeholder="Key or value"
            onChange={(e) => props.onUpdate(props.argumentName, { preselected_key: e.target.value || undefined })}
          />
        }
      />
    </div>
  );
};

export const ArgumentOptionVideoTypeValue = (props: IOptionProps) => {
  if (props.type?.key !== 'video') return null;

  const value = props.data.value as Record<string, any>;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdate(props.argumentName, { value: { ...value, [e.target.name]: e.target.value } });
  };

  return (
    <div>
      <FormRow
        gutter={[8, 24]}
        title="Url/HTML Snippet"
        input={
          <ValidatedInput
            name="source"
            validator={(newVal: string) => validateArgValue(props.type as IOptionType, newVal)}
            value={value.source}
            placeholder=""
            onChange={onChange}
          />
        }
      />
      <FormRow
        gutter={[8, 24]}
        title="Title"
        input={<Input name="title" value={value.title} placeholder="Title for the video" onChange={onChange} />}
      />
      <FormRow
        gutter={[8, 24]}
        title="Description"
        input={
          <Input
            name="description"
            value={value.description}
            placeholder="Description of the video"
            onChange={onChange}
          />
        }
      />
    </div>
  );
};

export const ArgumentOptionHtmlTypeValue = (props: IOptionProps) => {
  if (props.type?.key !== 'html') return null;

  const onValueChange = (code: string) => {
    props.onUpdate(props.argumentName, {
      value: {
        source: code,
      },
    });
  };

  return (
    <div>
      <FormRow
        gutter={[8, 24]}
        title="HTML Content"
        input={
          <PrettyCodeEditor
            value={props.data.type === 'html' ? props.data.value.source : ''}
            language="jsx"
            placeholder={'<p>I am a placeholder, edit here!</p>'}
            onValueChange={(code: string) => {
              onValueChange(code);
            }}
          />
        }
      />
    </div>
  );
};

/* Short or long input argument? */
export const ArgumentOptionTextInputType = (props: IOptionProps) => {
  const argumentType = props.type?.key;
  if (argumentType !== 'text') return null;

  return (
    <FormRow
      gutter={[8, 24]}
      title="Choose type"
      info="Should the user form be a default input or a textarea?"
      input={
        <Radio>
          <RadioItem
            label="Default"
            selected={!props.data.input_type || props.data.input_type === 'default'}
            onClick={() => props.onUpdate(props.argumentName, { input_type: 'default' })}
          />
          <RadioItem
            label="Long"
            selected={props.data.input_type === 'longtext'}
            onClick={() =>
              props.onUpdate(props.argumentName, {
                input_type: 'longtext',
              })
            }
          />
        </Radio>
      }
    />
  );
};

/* How do we show the multiselect / single select arguments */
export const ArgumentOptionMultiSelect = (props: IOptionProps) => {
  const argumentType = props.type?.key;
  if (argumentType === 'text' || argumentType === 'time') return null;

  return (
    <FormRow
      gutter={[8, 24]}
      title="Select type"
      info="Can a user select one or multiple options?"
      input={
        <Radio>
          <RadioItem
            label="Single"
            selected={!props.data.input_type}
            onClick={() => props.onUpdate(props.argumentName, { input_type: undefined })}
          />
          <RadioItem
            label="Multiple"
            selected={!!props.data.input_type}
            onClick={() =>
              props.onUpdate(props.argumentName, {
                input_type: 'multi-select',
              })
            }
          />
        </Radio>
      }
    />
  );
};

/* What type is the user input? Default is a simple select (input_type) === undefined */
export const ArgumentOptionAllowCreate = (props: IOptionProps) => {
  const argumentType = props.type?.key;
  if (argumentType === 'text' || argumentType === 'time') return null;
  return (
    <>
      <FormRow
        gutter={[8, 24]}
        title="Allow create?"
        info="Allow a user to specify a string that doesn't match any of the options."
        input={
          <div style={{ display: 'flex' }}>
            <Checkbox
              defaultChecked={!!props.data.allow_create}
              onChange={(e: any) => {
                props.onUpdate(props.argumentName, { allow_create: e.target.checked, allow_create_label: '' });
              }}
            />
          </div>
        }
      />
      {props.data.allow_create && (
        <FormRow
          gutter={[8, 24]}
          title="Create new <label>"
          info="If this value is 'user', then the Create option will read 'Create new user:'"
          input={
            <div style={{ display: 'flex' }}>
              <Input
                value={props.data.allow_create_label}
                placeholder=""
                onChange={(e: any) => {
                  props.onUpdate(props.argumentName, { allow_create_label: e.target.value });
                }}
              />
            </div>
          }
        />
      )}
    </>
  );
};

export const ArgumentOptionAutoChoose = (props: IOptionProps) => {
  const argumentType = props.type?.key;
  if (argumentType === 'text' || argumentType === 'time') return null;
  return (
    <FormRow
      gutter={[8, 24]}
      title="Auto choose?"
      info="If there is only one option available in the list, it is chosen automatically and this argument step is skipped. If the command has only one argument and that argument is auto chosen, the command will be automatically executed when selected. Otherwise, the user will be taken to the next argument after selecting."
      input={
        <div style={{ display: 'flex' }}>
          <Checkbox
            disabled={props.data.allow_create}
            defaultChecked={!!props.data.auto_choose}
            onChange={(e: any) => {
              props.onUpdate(props.argumentName, { auto_choose: e.target.checked, auto_choose_label: '' });
            }}
          />
        </div>
      }
    />
  );
};
