import React from 'react';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

// conditionally apply Tailwind CSS classes, making it easier to handle dynamic class names
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// helper to use radix primitives with addition custom props
export function extendRadixComponent<C extends React.ElementType, P>(
  Component: React.ForwardRefRenderFunction<React.ElementRef<C>, React.ComponentPropsWithoutRef<C> & P>,
) {
  return React.forwardRef<React.ElementRef<C>, React.ComponentPropsWithoutRef<C> & P>(Component);
}
