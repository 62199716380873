import { useHeapConfigContext } from './HeapConfigProvider';
import { Buffer } from 'buffer';
import { CmdButton } from '@commandbar/design-system/cmd';

export const HeapSyncStep = () => {
  const { heapConfig } = useHeapConfigContext();
  let apiUrl = process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
  if (process.env.REACT_APP_API_URL?.includes('localhost:8000')) {
    apiUrl = 'http://localhost:8000';
  }
  const isLocalDev = process.env.REACT_APP_API_URL?.includes('localhost:8000');
  const clientId = isLocalDev ? 'ffe57b96-3414-4fd8-9d21-e377114ecf54' : process.env.REACT_APP_HEAP_CLIENT_ID;
  const state = Buffer.from(JSON.stringify({ uuid: `${heapConfig.orgID}`, ...heapConfig })).toString('base64');
  const authorizationUri = `https://heapanalytics.com/api/partner/oauth/authorize?client_id=${clientId}&redirect_uri=${apiUrl}/integrations/heap/oauth/&state=${state}&response_type=code&scope=segment`;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <p>
        Click below to sync with Heap. This will allow Heap segments to be used within the CommandBar editor. Once
        synced, segments can be selected on the Heap website.
      </p>
      <CmdButton size="lg" onClick={() => (window.location.href = authorizationUri)}>
        Sync with Heap
      </CmdButton>
    </div>
  );
};

export default HeapSyncStep;
