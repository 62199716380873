/** @jsx jsx */
import React from 'react';
import ReactDOM from 'react-dom';
import Z from '@commandbar/internal/client/Z';
import { css, jsx } from '@emotion/core';

const styles = {
  mask: css({
    zIndex: Z.Z_INDEX_MAX,
    border: '2px solid #4242CB',
    background: 'rgba(127, 127, 235, 0.2)',
    position: 'absolute',
    pointerEvents: 'none',
    boxSizing: 'border-box',
    borderRadius: '2px',
  }),
  label: css({
    position: 'absolute',
    bottom: '100%',
    right: -2,
    border: '2px solid #4242CB',
    padding: '4px 8px',
    background: '#4242CB',
    color: '#fff',
    borderRadius: '4px 4px 0px 0px',
    width: 'max-content',
  }),
};

interface Props {
  top: number;
  left: number;
  width: number;
  height: number;
  hidden: boolean;
  label: string;
}

const Mask = ({ top, left, width, height, hidden = true, label = '' }: Props) => {
  const content = (
    <React.Fragment>
      <div
        data-testid="commandbar-styles-mask"
        css={styles.mask}
        style={{
          top: `0px`,
          left: `0px`,
          width: `${width}px`,
          height: `${height}px`,
          transition: 'opacity 300ms linear',
          transform: `translate(${left}px, ${top}px)`,
          opacity: hidden ? 0 : 1,
        }}
      >
        <div css={styles.label} style={{ display: hidden ? 'none' : 'block' }}>
          {label}
        </div>
      </div>
    </React.Fragment>
  );

  return ReactDOM.createPortal(content, document.body);
};

export default Mask;
