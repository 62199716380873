import * as React from 'react';

import * as Organization from '@commandbar/internal/middleware/organization';
import { IContextType, IUserContext } from '@commandbar/internal/middleware/types';

import Sender from '../management/Sender';
import { EditorRouter } from './Router';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAppContext } from '../AppStateContext';
import { Modal } from '@commandbar/design-system/components/antd';
import { AlertTriangle } from '@commandbar/design-system/icons/react';
import { CmdToaster } from '@commandbar/design-system/cmd';

const Editor = () => {
  const { organization, isStudio } = useAppContext();

  const setCommandBarContext = (context: IUserContext | string | any[], meta?: any) => {
    Sender.setContext(context, meta);
  };

  const checkClientOrgMismatch = () => {
    Sender.getOrganizationID().then((data: any) => {
      if (organization && organization.id !== data?.data) {
        Modal.confirm({
          icon: <AlertTriangle height={24} width={24} className="anticon anticon-warning" />,
          title: `
            Your CommandBar account doesn't match the CommandBar loaded on this page. Open your app and try 
            logging into the Editor from there to edit your CommandBar.
          `,
        });
      }
    });
  };

  React.useEffect(() => {
    if (!organization) {
      return;
    }

    const setContext = (loadedContexts: IContextType[]) => {
      const cachedContext = LocalStorage.get('customcontext', '') as string;

      if (!cachedContext) {
        return;
      }

      const contextToSet = loadedContexts.find((item: IContextType) => {
        return item.id === JSON.parse(cachedContext).id;
      });

      if (!contextToSet || !contextToSet.value) {
        return;
      }

      setCommandBarContext(contextToSet.value, {
        useCustom: true,
        customID: cachedContext,
      });
    };

    const loadContexts = async (id: string) => {
      const c = await Organization.listContexts(id);
      setContext(c);
    };

    loadContexts(organization.id.toString());

    if (!isStudio) {
      checkClientOrgMismatch();
    }
  }, [organization]);

  return (
    <div id="commandbar-editor-main" style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
      <CmdToaster />
      <EditorRouter />
    </div>
  );
};

export default Editor;
