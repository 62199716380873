/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { MouseEventHandler, type CSSProperties, type Key, type ReactElement } from 'react';
import type { ActorRefFrom } from 'xstate';

import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import {
  type INudgeButtonAction,
  type INudgeStepContentButtonBlockType,
  type INudgeStepType,
  isSurveyBlock,
} from '@commandbar/internal/middleware/types';
import { getSentry } from '@commandbar/internal/util/sentry';
import { useStore } from 'shared/util/hooks/useStore';
import NudgeMachine from '../store/nudgeMachine';
import { determineAction } from '../store/selectors';
import { CTATooltip } from './CTAButton';
import Content from './Content';
import { RenderMode } from './RenderNudge';
import { useExecNudgeAction } from './hooks';
import { useSurveyResponse } from './SurveyResponseProvider';

enum ContentBlockSortKeys {
  markdown = 1,
  image = 2,
  video = 3,
  survey_text = 4,
  survey_rating = 5,
  survey_list = 6,
  survey_text_short = 7,
  help_doc_command = 8,
  button = 9,
}

interface CTAWithTooltipProps {
  id: Key;
  children: (action: INudgeButtonAction | null, isDisabled: boolean, disabledStyles: CSSProperties) => ReactElement;
  block: INudgeStepContentButtonBlockType;
  step: INudgeStepType;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
}

export const CTAWithTooltip = ({ id, block, step, children, style, childrenStyle }: CTAWithTooltipProps) => {
  const _ = useStore();

  const { surveyResponse } = useSurveyResponse();
  const action = determineAction(_, step, {
    button_type: block.meta?.button_type,
    surveyResponse,
  });

  const willGoBack: boolean = action?.type === 'step_back';
  const willSnooze: boolean = block.meta?.button_type === 'snooze' || action?.type === 'snooze';
  const surveyBlock = step.content.find(isSurveyBlock);
  const isSurveyResponseRequired = !!surveyBlock?.meta.validation?.required?.value;
  const hasIncompleteSurveyBlock: boolean = !!surveyBlock && !surveyResponse?.value;

  const isDisabled: boolean = isSurveyResponseRequired && hasIncompleteSurveyBlock && !willGoBack && !willSnooze;
  const message = surveyBlock?.meta.validation?.required?.message;

  const disabledStyles: CSSProperties = {
    ...(message
      ? // we are showing a tooltip so we'll let the tooltip trigger handle on hover events
        { pointerEvents: 'none' }
      : // we are not showing a tooltip, but the button is still disabled
        { cursor: 'not-allowed' }),
  };

  return (
    <CTATooltip
      id={id}
      useTooltip={isDisabled}
      message={message ?? undefined}
      style={style}
      childrenStyle={childrenStyle}
    >
      {children(action, isDisabled, disabledStyles)}
    </CTATooltip>
  );
};

interface ContentContainerProps {
  styleOverrides?: CSSProperties;
  step: INudgeStepType;
  stepIndex: number;
  service?: ActorRefFrom<typeof NudgeMachine>;
  markdownStyles: CSSProperties;
  primaryButtonStyles: CSSProperties;
  secondaryButtonStyles: CSSProperties;
  snoozeButtonStyles: CSSProperties;
  stepCountStyles: CSSProperties;
  renderMode: RenderMode;
  stepCount?: string;
  snoozable: boolean;
  snoozable_on_all_steps: boolean;
  snoozeLabel: string;
  onMockAction?: (action: INudgeStepContentButtonBlockType['meta']) => void;
  handleContentLinkClick?: MouseEventHandler;
}

const ContentContainer = ({
  service,
  styleOverrides,
  step,
  stepIndex,
  markdownStyles,
  primaryButtonStyles,
  secondaryButtonStyles,
  snoozeButtonStyles,
  stepCountStyles,
  stepCount,
  snoozable,
  snoozable_on_all_steps,
  snoozeLabel,
  renderMode,
  onMockAction,
  handleContentLinkClick,
}: ContentContainerProps) => {
  const _ = useStore();
  const { theme } = useTheme();

  const execNudgeAction = useExecNudgeAction({
    stepId: step.id,
    renderMode,
    service,
    onMockAction,
  });

  const lastBlock = step.content[step.content.length - 1];

  const ctas = step.content
    .filter((block): block is INudgeStepContentButtonBlockType => block.type === 'button')
    .sort((_a, b) => (b.type === 'button' && b.meta?.button_type === 'secondary' ? 1 : -1));
  let contentItems = step.content;
  if (ctas.length) {
    contentItems = step.content.filter((block) => block.type !== 'button');
  }
  if (snoozable && (snoozable_on_all_steps || stepIndex === 0)) {
    ctas.unshift({
      type: 'button',
      sort_key: -1,
      meta: {
        button_type: 'snooze',
        label: snoozeLabel,
        action: {
          type: 'snooze',
        },
      },
    });
  }

  try {
    contentItems = contentItems.sort((a, b) => (ContentBlockSortKeys[a.type] > ContentBlockSortKeys[b.type] ? 1 : -1));
  } catch (e) {
    getSentry()?.captureException(e);
  }

  return (
    <React.Fragment>
      {contentItems.map((block, idx) => (
        <Content
          key={idx}
          type={step.form_factor.type}
          contentBlock={block}
          service={service}
          helpDocStyles={{
            color: theme.nudgeModal.helpDocPreviewColor,
            background: theme.nudgeModal.helpDocPreviewBackground,
            mixBlendMode: theme.nudgeModal.helpDocPreviewMixBlendMode as CSSProperties['mixBlendMode'],
          }}
          buttonStyles={primaryButtonStyles}
          styleOverrides={styleOverrides}
          markdownStyles={markdownStyles}
          step={step}
          handleContentLinkClick={handleContentLinkClick}
        />
      ))}
      {_.flags?.['release-themes-v2'] ? null : ctas.length ? ( // INFO: Actions are rendered separately from the ContentContainer in themes_v2
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {stepCount && (
            <div data-testid="commandbar-nudge-step-counter" style={stepCountStyles}>
              {stepCount}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              gap: '8px',
              justifyContent: stepCount || ctas.length === 1 ? 'flex-end' : 'center',
              width: stepCount ? 'calc(100% - 36px)' : '100%',
              alignItems: 'center',
              ...(stepCount && {
                display: 'inline-flex',
              }),
            }}
          >
            {ctas.map((block, idx) => {
              if (block?.meta?.label) {
                const buttonTypeStyles: Record<'primary' | 'secondary' | 'snooze', CSSProperties> = {
                  primary: primaryButtonStyles,
                  secondary: secondaryButtonStyles,
                  snooze: snoozeButtonStyles,
                };

                return (
                  <CTAWithTooltip
                    key={idx}
                    id={idx}
                    block={block}
                    step={step}
                    style={{
                      top: 0,
                      height: 'auto',
                      border: 'none',
                      flex: stepCount ? undefined : '1 1 0',
                      ...(stepCount ? { width: 'auto' } : { width: '100%' }),
                      maxWidth: `calc(100% / ${ctas.length} - 8px *  ${ctas.length - 1} / ${ctas.length})`,
                    }}
                    childrenStyle={{
                      maxWidth: `calc(100% / ${ctas.length} - 8px *  ${ctas.length - 1} / ${ctas.length})`,
                      flex: stepCount ? undefined : '1 1 0',
                    }}
                  >
                    {(action, isDisabled, disabledStyles) => (
                      <button
                        type="button"
                        style={{
                          width: '100%',
                          border: 'none',
                          cursor: 'pointer',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          ...buttonTypeStyles[block.meta?.button_type ?? 'primary'],
                          ...(isDisabled && disabledStyles),
                        }}
                        disabled={isDisabled}
                        onClick={() => {
                          execNudgeAction(action, block.meta);
                        }}
                      >
                        <span
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {block.meta?.label}
                        </span>
                      </button>
                    )}
                  </CTAWithTooltip>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : (
        !(lastBlock?.type === 'button' && lastBlock.meta?.label) &&
        stepCount &&
        !ctas.length && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
            <div style={stepCountStyles}>{stepCount}</div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

export default ContentContainer;
