import {
  INudgeContentSurveyRatingBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeStepContentSurveyTextShortBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import React from 'react';
import { SurveyRatingInputBlock } from './SurveyRatingInputBlock';
import { SurveyTextInputBlockForm } from './SurveyTextInputBlockForm';
import { SurveyTextInputShortBlockForm } from './SurveyTextShortInputBlockForm';

interface IMediaBlocks {
  step: INudgeStepType;
  onBlockChange: (block: INudgeStepContentBlockType, resetLogicBlocks?: boolean) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
  nudgeHasNPSBlock?: boolean;
}

export const SurveyBlocks = ({ step, onBlockChange, onBlockDelete, nudgeHasNPSBlock }: IMediaBlocks) => {
  const textInputBlocks = step.content.filter((content) => content.type === 'survey_text');
  const textInputShortBlocks = step.content.filter((content) => content.type === 'survey_text_short');
  const ratingBlocks = step.content.filter((content) => content.type === 'survey_rating');
  const activeStepHasNPS =
    step.content.filter((content) => content.type === 'survey_rating' && content.meta.type === 'nps').length > 0;

  if (!!textInputBlocks.length) {
    return (
      <React.Fragment>
        {textInputBlocks.map((textInputBlock, i) => (
          <SurveyTextInputBlockForm
            key={`${textInputBlock?.sort_key}-${i}`}
            block={textInputBlock as INudgeStepContentSurveyTextBlockType}
            onBlockChange={onBlockChange}
            onBlockDelete={onBlockDelete}
          />
        ))}
      </React.Fragment>
    );
  }

  if (!!textInputShortBlocks.length) {
    return (
      <React.Fragment>
        {textInputShortBlocks.map((textInputShortBlock, i) => (
          <SurveyTextInputShortBlockForm
            key={`${textInputShortBlock?.sort_key}-${i}`}
            block={textInputShortBlock as INudgeStepContentSurveyTextShortBlockType}
            onBlockChange={onBlockChange}
            onBlockDelete={onBlockDelete}
          />
        ))}
      </React.Fragment>
    );
  }

  if (!!ratingBlocks.length) {
    return (
      <React.Fragment>
        {ratingBlocks.map((ratingBlock, i) => (
          <SurveyRatingInputBlock
            key={`${ratingBlock?.sort_key}-${i}`}
            block={ratingBlock as INudgeContentSurveyRatingBlockType}
            onBlockChange={onBlockChange}
            onBlockDelete={onBlockDelete}
            nudgeHasNPSBlock={nudgeHasNPSBlock && !activeStepHasNPS}
          />
        ))}
      </React.Fragment>
    );
  }

  return null;
};
