import * as t from 'io-ts';
import * as axiosInstance from './network';
import { decodeToPromise } from './generics';
import { CommandV } from './command';
import { IOrganizationType, ISearchHelpDocsPayloadType, ISearchHelpDocsResponseType } from './types';

export const FilterV = t.union([t.partial({ labels: t.array(t.string) }), t.null]);

export const HelpDocHitV = t.type({
  score: t.array(t.number),
  command: CommandV,
  excerpt: t.string,
});

export const SearchHelpDocsResponseV = t.type({
  data: t.array(t.type({ hit: HelpDocHitV })),
});

export const SearchHelpDocsPayloadV = t.partial({
  query: t.string,
  include_additional_docs: t.union([t.boolean, t.undefined]),
  expand_rules: t.union([t.boolean, t.undefined]),
  doc_id: t.union([t.number, t.string, t.undefined]),
  filter: FilterV,
});

export class HelpDocsSearch {
  public static search = async (
    orgUID: IOrganizationType['id'],
    payload: ISearchHelpDocsPayloadType,
    options?: axiosInstance.FetchOptions,
  ) => {
    const serializedPayload = Object.entries(payload)
      .filter(([_key, value]) => Boolean(value))
      .map(([key, value]) => {
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }

        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');

    const result = await axiosInstance.get<ISearchHelpDocsResponseType>(
      `search/helpexplorer/${orgUID}/?${serializedPayload}`,
      {
        ...options,
      },
    );

    return await decodeToPromise(t.exact(SearchHelpDocsResponseV), result.data);
  };
}
