import { mergeTheme, IBaseVars, IPartialTheme, ITheme } from '../theme';
import generateBaseTheme from './base';

// for now, Modal base theme is the same as the base
const generateModalBaseTheme = (baseVars: IBaseVars): ITheme => {
  const modalBase: IPartialTheme = {
    bar: {
      width: '675px',
    },
  };

  return mergeTheme(modalBase, generateBaseTheme(baseVars));
};

export default generateModalBaseTheme;
