import React, { type ComponentProps, type KeyboardEvent, useCallback, useRef } from 'react';

import { CmdTextarea } from '../cmd';

type WrappedText<T extends string> = `${T}${string}${T}`;

enum KeyCommands {
  Bold = 'b',
  Italicize = 'i',
}

enum CommandSymbols {
  Bold = '**',
  Italicize = '_',
}

const surround = <T extends string>(text: string, symbol: T): WrappedText<T> => `${symbol}${text}${symbol}`;

type WrapCommand<T extends string = CommandSymbols> = (
  text: string,
  start: number,
  end: number,
) => { wrappedText: `${string}${WrappedText<T>}${string}`; offset: number };

type CreateTextWrapper = <T extends CommandSymbols>(symbol: T) => WrapCommand<T>;
const createTextWrapper: CreateTextWrapper = (symbol) => (text, start, end) => {
  const beforeSelection = text.substring(0, start);
  const selection = text.slice(start, end);
  const afterSelection = text.substring(end);
  const wrapped = surround(selection, symbol);

  return { wrappedText: `${beforeSelection}${wrapped}${afterSelection}`, offset: symbol.length };
};

const commands = {
  [KeyCommands.Bold]: createTextWrapper(CommandSymbols.Bold),
  [KeyCommands.Italicize]: createTextWrapper(CommandSymbols.Italicize),
};

const isCommandKey = (key: string): key is keyof typeof commands => key in commands;

type MarkdownEditorProps = Omit<ComponentProps<typeof CmdTextarea>, 'onChange'> & {
  onChange?: (value: string) => void;
};

const MarkdownEditor = ({ onChange, ...props }: MarkdownEditorProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const markdownShortcutHandler = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!(e.metaKey || e.ctrlKey)) return;
      const { current: textarea } = ref;
      if (!(textarea && isCommandKey(e.key))) return;

      const { selectionStart, selectionEnd, value } = textarea;
      const { wrappedText, offset } = commands[e.key](value, selectionStart, selectionEnd);
      textarea.value = wrappedText;
      onChange?.(wrappedText);

      // Move the cursor to the end of the new text after applying the command
      // If the selection is empty, move the cursor between the symbols for immediate editing
      const adjustment = selectionStart === selectionEnd ? offset : offset * 2;
      textarea.selectionEnd = selectionEnd + adjustment;
    },
    [onChange],
  );

  return (
    <CmdTextarea
      ref={ref}
      tooltip="Markdown is supported"
      {...props}
      onKeyDown={markdownShortcutHandler}
      onChange={({ target }) => {
        onChange?.(target.value);
      }}
    />
  );
};

export default MarkdownEditor;
