import React from 'react';

import { Col, Row, Input, Radio, Checkbox, Select, Form } from '@commandbar/design-system/components/antd';
import { FormLayout2 } from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { CmdButton } from '@commandbar/design-system/cmd';

const Setup = () => {
  const { logout, user, setUser } = useAuth();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const windowSize = useWindowSize();

  const sendSegmentEvents = async (values: any) => {
    window.analytics
      .identify(user?.email, {
        organization: user?.organization,
        email: user?.email,
      })
      .then(() => {
        window.analytics.track('Organization created', {
          category: 'signup form',
          label: 'Form',
          obj: values,
          email: user?.email,
        });
      });
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const data = await Auth.setupOrg(values);
      sendSegmentEvents(values);

      setTimeout(() => setUser(data), 1500);
    } catch (err) {
      setError(JSON.stringify(err));
    }
    setLoading(false);
  };

  return (
    <FormLayout2
      h1="Tell us a bit more about you"
      h2="We’ll use this information to give you the best dashboard experience possible."
      error={error}
      isTall={windowSize.height < 1023}
    >
      <br />
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" id="setup-form">
        <Form.Item name="job_title" label="What is your position?">
          <Select placeholder="Choose a position">
            <Select.Option value="Product Manager">Product Manager</Select.Option>
            <Select.Option value="Engineer">Engineer</Select.Option>
            <Select.Option value="Designer">Designer</Select.Option>
            <Select.Option value="Customer success/support">Customer success/support</Select.Option>
            <Select.Option value="Founder/CEO">Founder/CEO</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="usecase" label="What brings you here?">
          <Radio.Group>
            <Radio value="work">Try Command AI for my company</Radio>
            <Radio value="personal">Personal use (e.g. personal site, side project)</Radio>
          </Radio.Group>
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item
            label="Project/company name"
            name="organization_name"
            id="organization_name"
            rules={[
              { required: true, message: 'Company name is required' },
              { type: 'string', max: 30 },
              { pattern: /^(\w|\s)+$/, message: 'Company name should not contain any special characters' },
            ]}
          >
            <Input placeholder="Foo Corp" />
          </Form.Item>
          <div style={{ width: '8px' }} />
        </div>
        <Form.Item
          name="domain"
          label="App URL"
          tooltip="This is where Command AI will be live"
          rules={[
            { required: true, max: 50, message: 'App URL is required' },
            {
              pattern: /^(https?:\/\/)?(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\/?$/,
              message: 'Invalid app URL',
            },
          ]}
        >
          <Input placeholder="https://foocorp.com" />
        </Form.Item>
        <Form.Item name="signup_reason" label="What interests you the most?">
          <Checkbox.Group>
            <Row>
              <Col span={32}>
                <Checkbox
                  value="onboard"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Help onboard new users
                </Checkbox>
              </Col>
              <Col span={32}>
                <Checkbox
                  value="tickets"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Decrease support tickets
                </Checkbox>
              </Col>
              <Col span={32}>
                <Checkbox
                  value="actions"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Enable quick actions in my UI
                </Checkbox>
              </Col>
              <Col span={32}>
                <Checkbox
                  value="features"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Increase feature adoption
                </Checkbox>
              </Col>
              <Col span={32}>
                <Checkbox
                  value="search"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Enhance way finding/global search
                </Checkbox>
              </Col>
              <Col span={32}>
                <Checkbox
                  value="productivity"
                  style={{
                    lineHeight: '32px',
                  }}
                >
                  Improve productivity with shortcuts
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={loading} loading={loading}>
            Let's go
          </CmdButton>
        </Form.Item>
      </Form>
      <br />
      <CmdButton fullWidth onClick={logout}>
        Sign out
      </CmdButton>
    </FormLayout2>
  );
};

export default Setup;
