// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const AngryRage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_8340_8625)">
      <path
        fill="#DC2626"
        d="M8 14.6666c3.6819 0 6.6666-2.9848 6.6666-6.6667 0-3.6819-2.9847-6.6666-6.6666-6.6666-3.682 0-6.6667 2.9847-6.6667 6.6666 0 3.6819 2.9848 6.6667 6.6667 6.6667Z"
        opacity={0.12}
      />
      <path
        stroke="#DC2626"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.3333 10.6666s1-1.3334 2.6667-1.3334 2.6666 1.3334 2.6666 1.3334M10 7.3332h.0066M6 7.3332h.0066m2.66-.6666 2-1.3334M7.3333 6.6666l-2-1.3334M14.6666 8c0 3.6819-2.9847 6.6667-6.6666 6.6667-3.682 0-6.6667-2.9848-6.6667-6.6667 0-3.682 2.9848-6.6667 6.6667-6.6667 3.6819 0 6.6666 2.9847 6.6666 6.6666Zm-4.3333-.6667a.3333.3333 0 1 1-.6666.0001.3333.3333 0 0 1 .6666 0Zm-4 0a.3333.3333 0 1 1-.6667 0 .3333.3333 0 0 1 .6667 0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8340_8625">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default AngryRage;
