/*******************************************************************************/
/* Imports
/*******************************************************************************/

import { createObject, updateObject, deleteObject, decodeThrowing, createObjectWithDecoder } from './generics';
import { defaults, NamedRuleV } from './helpers/rules';
import * as t from 'io-ts';
import * as axiosInstance from './network';

import { decodeToPromise } from './generics';
import { IRuleExpression } from './types';

export type AudienceUser = {
  end_user: string;
  eligible: boolean;
  action: string | null | undefined;
};

export type AudienceUserParams = {
  expr: IRuleExpression | null;
  is_nudge: boolean;
  id: string | number;
};

const AudienceUserV = t.type({
  end_user: t.string,
  eligible: t.boolean,
  action: t.union([t.string, t.undefined, t.null]),
});

const AudienceUsersV = t.type({
  users: t.array(AudienceUserV),
});

export class Rule {
  public static decode = (data: any) => decodeThrowing(NamedRuleV, { ...defaults, ...data });

  public static create = createObjectWithDecoder(Rule.decode, NamedRuleV, 'rules', { replace_existing_uses: 'false' });
  public static createFromExistingCondition = createObject(NamedRuleV, NamedRuleV, 'rules', {
    replace_existing_uses: 'true',
  });
  public static update = updateObject(NamedRuleV, NamedRuleV, 'rules');
  public static delete = deleteObject(NamedRuleV, 'rules');

  public static readAudienceDetails = async (params: AudienceUserParams) => {
    const result = await axiosInstance.post('rules/user_details/', params);
    return await decodeToPromise(t.exact(AudienceUsersV), result.data);
  };
}
