import type { Condition } from '@commandbar/internal/middleware/conditions';
import {
  type IAvailabilityRule,
  type OperatorType,
  audienceIntegrationOperatorTypes,
  booleanOperatorTypes,
  elementOperatorTypes,
  equalityOperatorTypes,
  listOperatorTypes,
  numericOperatorTypes,
  operatorTypes,
  legacyListOperatorTypes,
} from './types';

const unavailableOperatorTypes: { [type in Condition['type']]: OperatorType[] } = {
  user_property: [...elementOperatorTypes],
  event_property: [...elementOperatorTypes, ...listOperatorTypes],
  url: [...numericOperatorTypes, ...booleanOperatorTypes, ...elementOperatorTypes, ...listOperatorTypes],
  hostname: [...numericOperatorTypes, ...booleanOperatorTypes, ...elementOperatorTypes, ...listOperatorTypes],
  context: [...elementOperatorTypes, ...listOperatorTypes],
  always: [],
  element: operatorTypes.filter((o) => !elementOperatorTypes.includes(o)),
  named_rule: [],
  executions: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  shortcuts: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  last_seen: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  first_seen: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  sessions: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  opens: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  deadends: operatorTypes.filter((o) => !numericOperatorTypes.includes(o)),
  heap: operatorTypes.filter((o) => !audienceIntegrationOperatorTypes.includes(o)),
  hubspot: operatorTypes.filter((o) => !audienceIntegrationOperatorTypes.includes(o)),
  amplitude: operatorTypes.filter((o) => !audienceIntegrationOperatorTypes.includes(o)),
  nudge_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  questlist_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  browser: operatorTypes.filter((o) => !legacyListOperatorTypes.includes(o)),
  language: operatorTypes.filter((o) => !legacyListOperatorTypes.includes(o)),
  os: operatorTypes.filter((o) => !legacyListOperatorTypes.includes(o)),
  device_type: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  ab_test: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  intent: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  survey_response: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  help_doc_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  video_interaction: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
  audience: operatorTypes.filter((o) => !equalityOperatorTypes.includes(o)),
};

export const availableOperatorTypes = (condition: Condition) =>
  operatorTypes.filter((operatorType) => !unavailableOperatorTypes[condition.type].includes(operatorType));

export const negativeOperators: { [key in IAvailabilityRule['operator']]: string } = {
  is: 'is not',
  isNot: 'is',
  isTrue: 'is false',
  isFalse: 'is true',
  isTruthy: 'is falsy',
  isFalsy: 'is truthy',
  startsWith: 'does not start with',
  endsWith: 'does not end with',
  includes: 'does not include',
  doesNotInclude: 'includes',
  matchesRegex: 'does not match regex',
  isGreaterThan: 'is less than',
  isLessThan: 'is greater than',
  isDefined: 'is not defined',
  isNotDefined: 'is defined',
  classnameOnPage: 'classname',
  idOnPage: 'id',
  selectorOnPage: 'selector',
  classnameNotOnPage: 'classname not',
  idNotOnPage: 'id not',
  selectorNotOnPage: 'selector not',
  isInList: 'is not in list',
  isNotInList: 'is in list',
};

export const pastTenseOperators: Partial<Record<IAvailabilityRule['operator'], string>> = {
  is: 'was',
  isNot: 'wasNot',
};

export const negativePastTenseOperators: Partial<Record<IAvailabilityRule['operator'], string>> = {
  is: 'was not',
  isNot: 'was',
};

export const invertAvailabilityRuleOperator = (operator: IAvailabilityRule['operator'], isPastTense = false) =>
  isPastTense ? pastTenseOperators[operator] ?? negativeOperators[operator] : negativeOperators[operator];
