import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';

import React from 'react';
import Home, { ANALYTICS_BASE_ROUTE } from './components/Home';
import Editor from './components/editor/Editor';
import Setup from './auth/Setup';

import ResetPasswordBanner from './components/ResetPasswordBanner';
import LoginWithSSO from './auth/LoginWithSSO';
import Login from './auth/Login';
import Signup from './auth/Signup';
import VerifyEmailSent from './auth/VerifyEmailSent';
import VerifyEmail from './auth/VerifyEmail';
import AcceptInvite from './auth/AcceptInvite';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import HijackUserBanner from 'commandbar.com/src/components/HijackUserBanner';
import TeamMembersContextProvider from './components/TeamMembersContextProvider';
import {
  AUDIENCES_ROUTE,
  COPILOT_SETTINGS_ROUTE,
  HELPHUB_SETTINGS_ROUTE,
  RELEASES_ROUTE,
  SPOTLIGHT_SETTINGS_ROUTE,
} from '@commandbar/internal/proxy-editor/editor_routes';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { IProfileType } from '@commandbar/internal/middleware/types';

declare global {
  interface Window {
    analytics: any;
  }
}

export function getEditorPath(path: string) {
  const match = path.match(/^\/editor\/(.+)/);
  return match ? match[1] : null;
}

const usePageViews = () => {
  const location = useLocation();
  React.useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
};

const roleRestrictedPages = new Map<string, IProfileType['role']>([
  ['/home', 'contributor'],
  ['/integrations', 'editor'],
  ['/team', 'admin'],
  ['/billing', 'admin'],
  ['/configure', 'admin'],
  ['/identity-verification', 'admin'],
  ['/history', 'admin'],
  [`/editor${SPOTLIGHT_SETTINGS_ROUTE}`, 'editor'],
  [`/editor${HELPHUB_SETTINGS_ROUTE}`, 'editor'],
  [`/editor${COPILOT_SETTINGS_ROUTE}`, 'editor'],
  [`/editor${AUDIENCES_ROUTE}`, 'editor'],
  [`/editor${RELEASES_ROUTE}`, 'editor'],
  ['/integrations', 'editor'],
]);

export function getRoleRestrictionForPath(path: string) {
  const restriction = roleRestrictedPages.get(path);
  return restriction;
}

const Router: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const handleEditorRouteUpdated: EventListener = (e) => {
    const customEvent = e as CustomEvent<any>;
    const detail = customEvent.detail as any;

    if (detail.action === 'POP') {
      history.goBack();
    }

    if (
      !detail.path ||
      `${history.location.pathname}${history.location.search}${history.location.hash}` === `/editor${detail.path}`
    ) {
      return;
    }

    let route = detail.path;
    if (!route.startsWith('/content') && !route.startsWith('/editor')) {
      route = `/editor${detail.path}`;
    }

    if (detail.action === 'PUSH') {
      history.push(route);
    } else if (detail.action === 'POP' || detail.action === 'REPLACE') {
      history.replace(route);
    }
  };

  React.useEffect(() => {
    document.addEventListener('editorRouteUpdated', handleEditorRouteUpdated);

    return () => {
      document.removeEventListener('editorRouteUpdated', handleEditorRouteUpdated);
    };
  }, []);

  usePageViews();

  if (!hasRequiredRole(user, roleRestrictedPages.get(history.location.pathname))) {
    if (user?.profile.role === 'viewer') {
      return (
        <Switch>
          <Route>
            <Redirect to={ANALYTICS_BASE_ROUTE} />
          </Route>
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route>
            <Redirect to="/home" />
          </Route>
        </Switch>
      );
    }
  }

  if (!!user && user.is_active && user.organization !== 'none') {
    return (
      <Switch>
        <Route path="/editor/editor-bar-preview">
          <Editor user={user} />
        </Route>
        <Route path="/">
          <TeamMembersContextProvider user={user}>
            <ResetPasswordBanner user={user} />
            <HijackUserBanner user={user} />
            <Home user={user} />
          </TeamMembersContextProvider>
        </Route>
      </Switch>
    );
  }

  if (!!user && !user.is_active) {
    return (
      <Switch>
        <Route path="/verify/sent" exact>
          <VerifyEmailSent email={user.email} />
        </Route>
        <Route path="/verify" exact>
          <VerifyEmail />
        </Route>
        <Route path="/">
          <Redirect
            to={{
              pathname: '/verify/sent',
            }}
          />
        </Route>
      </Switch>
    );
  }

  if (!!user && user.organization === 'none') {
    return (
      <Switch>
        <Route path="/setup" exact>
          <Setup />
        </Route>
        <Route path="/">
          <Redirect
            to={{
              pathname: '/setup',
            }}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/invitation/accept" exact>
        <AcceptInvite />
      </Route>
      <Route path="/verify" exact>
        <VerifyEmail />
      </Route>
      <Route path="/verify/sent" exact>
        <VerifyEmailSent email={user?.email} />
      </Route>
      <Route path="/login/sso" exact>
        <LoginWithSSO />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/create-account" exact>
        <Signup />
      </Route>
      <Route
        path="/"
        render={({ location }) => {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }}
      />
    </Switch>
  );
};

export default Router;
