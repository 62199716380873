/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { CmdColumnDef, CmdDataTable, CmdTooltip } from '@commandbar/design-system/cmd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { timeAgoFormat } from '../../utils';
import * as client from '@commandbar/internal/middleware/network';

const fadeInBackgroundAnimation = keyframes`
  from {
    background-color: #e3f6ff;
  }
  to {
    background-color: #ffffff;
  }
`;

type Event = {
  id: string;
  name: string;
  organization: string;
  end_user: string;
  url: string;
  created_timestamp: string;
};

const columns: CmdColumnDef<any, any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'end_user',
    header: 'End User ID',
  },
  {
    accessorKey: 'page_url',
    header: 'URL',
  },
  {
    accessorKey: 'created_timestamp',
    header: 'Time',
    cell: ({ cell }) => (
      <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
        <span>{timeAgoFormat(cell.getValue())}</span>
      </CmdTooltip>
    ),
  },
];

export const LiveEventsTable = () => {
  const [liveEvents, setLiveEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchLiveEvents = async () => {
      const { data } = await client.get('/analytics/events/?limit=25');

      const newEvents: Event[] = data.map((event: string[]) => {
        return {
          id: event[0],
          name: event[1],
          organization: event[2],
          end_user: event[3],
          page_url: event[4],
          created_timestamp: event[5],
        };
      });

      setLiveEvents(newEvents);
      setLoading(false);
    };

    setInterval(fetchLiveEvents, 3000);
  }, []);

  return (
    <div
      css={
        !loading
          ? css`
              & tbody > tr {
                animation: ${fadeInBackgroundAnimation} 1.2s ease-in-out forwards;
              }
            `
          : null
      }
    >
      <CmdDataTable
        showPagination={false}
        hasGlobalSearch={false}
        data={liveEvents}
        columns={columns}
        isLoading={loading}
        autoResetPageIndex={false}
        getRowId={(row) => row.id}
        manualPagination
        state={{
          pagination: {
            pageIndex: 0,
            pageSize: 100,
          },
        }}
        pageCount={1}
        rowCount={liveEvents.length}
      />
    </div>
  );
};
