import React, { ReactNode } from 'react';
import { CmdSwitch } from '@commandbar/design-system/cmd';
import { SubHeading, Subheader } from '@commandbar/design-system/components/antd';
import ThemeSelect from './ThemeSelect';
import { OverlaySettingsSection } from './styled';

const ThemeCustomizationSettings = ({
  themeUUID,
  setThemeUUID = () => null,
  disabled,
  note,
}: {
  themeUUID: string | null;
  setThemeUUID?: (themeUUID: string | null) => void;
  disabled?: boolean;
  note?: ReactNode;
}) => {
  const [checked, setChecked] = React.useState(themeUUID !== null);

  return (
    <div>
      <OverlaySettingsSection>
        <SubHeading>Custom theme{note && <Subheader>{note}</Subheader>}</SubHeading>
        <CmdSwitch
          checked={checked}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (!checked) {
              setThemeUUID(null);
            }
            setChecked(checked);
          }}
        />
      </OverlaySettingsSection>
      <OverlaySettingsSection>
        {checked && <ThemeSelect disabled={disabled} value={themeUUID} onChange={setThemeUUID} style={{ flex: 1 }} />}
      </OverlaySettingsSection>
    </div>
  );
};

export default ThemeCustomizationSettings;
