import { Key, useCallback, useState } from 'react';
import { ColumnTitle, StyledTable } from './styles';
import { Row, Empty } from '@commandbar/design-system/components/antd';
import FilterSelect, { StatusType } from 'editor/src/editor/components/FilterSelect';

import { ColumnsType } from '@commandbar/design-system/node_modules/antd/lib/table';
import { contentSearchFunction, filterHelpDocCommands, useDataCommandsAndPagination } from './hooks';
import Footer from '../../table/Footer';
import { IEditorCommandType, IEditorCommandTypeLite, IKeyword } from '@commandbar/internal/middleware/types';
import { Check, ClockRefresh } from '@commandbar/design-system/icons/react';
import { StatusRenderer, LastUpdateRenderer } from './utils';
import BulkEditControls from './BulkEditControls';
import { useHistory } from 'react-router';
import { RowSelectMethod } from 'antd/lib/table/interface';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { CmdSearchInput } from '@commandbar/design-system/cmd';

interface ContentTableProps<T> {
  viewSelector?: JSX.Element;
  columns: ColumnsType<T>;
  data: T[];
  dataMappingFunction: (data: T[]) => T[];
  emptyText: string;
  setIsLoading: (loading: boolean) => void;
  openEditDrawer?: (id: number) => void;
}

const ContentTable = <T extends IEditorCommandType | IEditorCommandTypeLite | IKeyword>({
  viewSelector,
  columns,
  data,
  dataMappingFunction,
  emptyText,
  setIsLoading,
  openEditDrawer,
}: ContentTableProps<T>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [showBulkEditButton, setShowBulkEditButton] = useState(false);
  const { user } = useAuth();
  const {
    dataCommands: chunkedData,
    pagination,
    setPagination,
  } = useDataCommandsAndPagination({
    commands: data,
    searchTerm,
    selectedStatus,
  });

  const history = useHistory();

  const onRowSelectionChange = useCallback(
    (newSelectedRowKeys: Key[], _: T[], info: { type: RowSelectMethod }) => {
      if (info.type === 'all') {
        if (newSelectedRowKeys.length === 0) {
          setSelectedRowKeys([]);
          setShowBulkEditButton(false);
        } else {
          setSelectedRowKeys(
            filterHelpDocCommands({
              commands: data,
              searchFunction: (command: T) => contentSearchFunction(searchTerm, command),
              selectedStatus,
            }).map((d) => d.id),
          );
          setShowBulkEditButton(true);
        }
      } else {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length > 0) {
          setShowBulkEditButton(true);
        } else {
          setShowBulkEditButton(false);
        }
      }
    },
    [setSelectedRowKeys, setShowBulkEditButton, data, searchTerm, selectedStatus],
  );

  const updatedColumns = columns.concat([
    {
      title: (
        <ColumnTitle>
          Status
          <Check />
        </ColumnTitle>
      ),
      dataIndex: 'is_live',
      key: 'is_live',
      width: '133px',
      render: StatusRenderer,
    },
    {
      title: (
        <ColumnTitle>
          Last Update
          <ClockRefresh />
        </ColumnTitle>
      ),
      dataIndex: 'modified',
      key: 'modified',
      width: '158px',
      render: (modified: string, command: T) => LastUpdateRenderer(modified, command, openEditDrawer),
    },
  ]);

  return (
    <StyledTable
      rowClassName="editable-row"
      columns={updatedColumns}
      dataSource={chunkedData[pagination.currentPage] && dataMappingFunction(chunkedData[pagination.currentPage])}
      rowSelection={
        hasRequiredRole(user, 'editor')
          ? {
              type: 'checkbox',
              selectedRowKeys: selectedRowKeys,
              onChange: onRowSelectionChange,
            }
          : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            history.replace({ search: `id=${record.id}` });
            openEditDrawer?.(record.id);
          },
        };
      }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />,
      }}
      title={() =>
        showBulkEditButton ? (
          <BulkEditControls
            selectedRowKeys={selectedRowKeys}
            setShowBulkEditButton={setShowBulkEditButton}
            editableData={data}
            setIsLoading={setIsLoading}
          />
        ) : (
          <Row
            style={{
              alignItems: 'center',
              gap: '8px',
              justifyContent: `${viewSelector ? 'space-between' : 'end'}`,
              width: '100%',
            }}
          >
            {viewSelector && viewSelector}
            <div style={{ display: 'flex', gap: '8px' }}>
              <FilterSelect selectedOption={selectedStatus} setSelectedOption={setSelectedStatus} />
              <CmdSearchInput
                style={{ minWidth: '150px' }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </Row>
        )
      }
      footer={() => (
        <Footer
          pagination={pagination}
          setCurrentPage={(currentPage) => setPagination((p) => ({ ...p, currentPage }))}
        />
      )}
      pagination={false}
    />
  );
};

export default ContentTable;
