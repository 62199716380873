import styled from '@emotion/styled';
import { timeAgoFormat } from '../../utils';
import { FEEDBACK, useChatTable, ChatTableItem, SORT_METHOD, PAGE_SIZE } from './state';

import {
  CmdCard,
  CmdDataTable,
  CmdDropdown,
  CmdColumnDef,
  CmdSortableColumn,
  CmdSortingState,
  CmdRow,
  CmdTypography,
  CmdTableNoResultCta,
  CmdTag,
} from '@commandbar/design-system/cmd';
import {
  DotsVertical,
  Link04,
  PlusCircle,
  ThumbsDown,
  ThumbsUp,
  BookmarkCheck,
} from '@commandbar/design-system/icons/react';
import { Preview } from './Preview';
import Filters from './Filters';
import { useEffect, useState } from 'react';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { copyChatLink, createAnswer } from './util';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';

const DoubleThumbs = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

type ChatRowActionsProps = {
  row: CmdRow<ChatTableItem>;
  currentPage: number;
};

const ChatRowActions: React.FC<ChatRowActionsProps> = ({ row, currentPage }) => {
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <DotsVertical />
      </CmdDropdown.Trigger>
      <CmdDropdown.Content>
        <CmdDropdown.Item
          key="copy-link"
          onClick={async () => {
            const chatId = row.original.id;

            if (!chatId) {
              throw new Error('Chat id is not available');
            }

            copyChatLink(chatId, currentPage);
          }}
        >
          <Link04 /> Copy Link
        </CmdDropdown.Item>
        <CmdDropdown.Item
          key="create-answer"
          onClick={() => {
            const chatId = row.original.id;

            if (!chatId) {
              throw new Error('Chat id is not available');
            }

            createAnswer(chatId);
          }}
        >
          {row.original.answer ? (
            <>
              <BookmarkCheck /> View Answer
            </>
          ) : (
            <>
              <PlusCircle /> Create Answer
            </>
          )}
        </CmdDropdown.Item>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

type ChatsTableProps = {
  timeFilterRange: DateFilter;
};

const ChatsTable: React.FC<ChatsTableProps> = ({ timeFilterRange }) => {
  const [sorting, setSorting] = useState<CmdSortingState>([]);
  const { isDemoData } = useAnalyticsContext();
  const { user } = useAuth();

  const {
    dispatch: {
      tags: { reload: reloadTags },
    },
  } = useAppContext();

  const [
    {
      selectedChat,
      isLoading: isTableLoading,
      chatTableData,
      answerFilter,
      feedbackFilter,
      suggestionFilter,
      tagFilter,
      userFilter,
      searchFilter,
      pagination: { totalItems, currentPage },
      handleFetchChatAnalytics,
      closeChat,
    },
    dispatch,
  ] = useChatTable({
    timeFilterRange,
  });

  useEffect(() => {
    if (isDemoData) {
      dispatch({ type: 'SET_READY' });
    }
  }, [isTableLoading, isDemoData]);

  useEffect(() => {
    const createdSorting = sorting.find((sort) => sort.id === 'created');

    if (createdSorting) {
      dispatch({ type: 'SET_SORT_METHOD', payload: createdSorting.desc ? SORT_METHOD.NEWEST : SORT_METHOD.OLDEST });
    }
  }, [sorting]);

  const columns: CmdColumnDef<ChatTableItem, any>[] = [
    {
      header: 'Chat topic',
      accessorKey: 'chat',
      enableGlobalFilter: true,
      cell: ({ cell, row }) => (
        <div
          role="button"
          onClick={() => dispatch({ type: 'SELECT_CHAT', payload: row.original.id || null })}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <CmdTypography.Body
            variant="primary"
            style={{
              display: 'inline-block',
              maxWidth: '600px',
            }}
          >
            {cell.renderValue()}
          </CmdTypography.Body>
        </div>
      ),
    },
    {
      header: 'AI Answer',
      accessorKey: 'aiAnswerType',
      enableGlobalFilter: false,
      cell: ({ cell }) => <span style={{ textTransform: 'capitalize' }}>{cell.renderValue()}</span>,
    },
    {
      header: 'Feedback',
      accessorKey: 'feedback',
      enableGlobalFilter: false,
      cell: ({ cell }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {(() => {
              switch (cell.getValue()) {
                case FEEDBACK.POSITIVE:
                  return <ThumbsUp />;
                case FEEDBACK.NEGATIVE:
                  return <ThumbsDown />;
                case FEEDBACK.BOTH:
                  return (
                    <DoubleThumbs>
                      <ThumbsUp />
                      <ThumbsDown />
                    </DoubleThumbs>
                  );
                case FEEDBACK.UNRATED:
                default:
                  return <span>-</span>;
              }
            })()}
          </div>
        );
      },
    },
    {
      header: 'Tags',
      accessorKey: 'editor_tags',
      enableGlobalFilter: false,
      cell: ({ cell }) => {
        const value: string[] = cell.getValue();

        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
            {value.map((tag) => (
              <CmdTag key={tag}>{tag}</CmdTag>
            ))}
          </div>
        );
      },
    },
    {
      header: 'User ID',
      accessorKey: 'end_user',
      enableGlobalFilter: true,
      cell: ({ cell }) => cell.renderValue(),
    },
    {
      accessorKey: 'created',
      enableGlobalFilter: false,
      cell: ({ cell }) => timeAgoFormat(cell.getValue() as string),
      header: ({ column }) => <CmdSortableColumn column={column} title="Date" />,
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      header: '',
      accessorKey: 'options',
      enableGlobalFilter: false,
      width: 40,
      cell: ({ row }) => {
        if (!isDemoData) {
          return <ChatRowActions row={row} currentPage={currentPage} />;
        }
        return null;
      },
    });
  }

  return (
    <>
      <CmdCard style={{ padding: '0' }}>
        <CmdCard.Content style={{ padding: '0' }}>
          <CmdDataTable
            isLoading={isTableLoading}
            columns={columns}
            data={chatTableData}
            enableRowSelection
            manualSorting
            manualPagination
            manualFiltering
            renderFallbackValue={`-`}
            state={{
              sorting,
              pagination: {
                pageIndex: currentPage,
                pageSize: PAGE_SIZE,
              },
              globalFilter: searchFilter,
              rowSelection: {
                [selectedChat?.id || '']: true,
              },
            }}
            getRowId={(row) => row.id || ''}
            onSortingChange={setSorting}
            onPaginationChange={(updater) => {
              if (typeof updater === 'function') {
                const newState = updater({
                  pageSize: PAGE_SIZE,
                  pageIndex: currentPage,
                });

                dispatch({ type: 'SET_CURRENT_PAGE', payload: newState.pageIndex });
              } else {
                dispatch({ type: 'SET_CURRENT_PAGE', payload: updater.pageIndex });
              }
            }}
            pageCount={Math.ceil(totalItems / PAGE_SIZE)}
            rowCount={totalItems}
            onGlobalFilterChange={(value) => {
              dispatch({ type: 'FILTER_BY_TERM', payload: value });
            }}
            shouldDebounceFilter={false}
            emptyResultChildren={<CmdTableNoResultCta onClick={handleFetchChatAnalytics} />}
            toolBarChildren={
              <Filters
                answerFilter={answerFilter}
                setAnswerFilter={(payload) => dispatch({ type: 'FILTER_BY_ANSWER', payload })}
                feedbackFilter={feedbackFilter}
                setFeedbackFilter={(payload) => dispatch({ type: 'FILTER_BY_FEEDBACK', payload })}
                suggestionFilter={suggestionFilter}
                setSuggestionFilter={(payload) => dispatch({ type: 'FILTER_BY_SUGGESTION', payload })}
                tagFilter={tagFilter}
                setTagFilter={(payload) => {
                  dispatch({ type: 'FILTER_BY_TAG', payload });
                }}
                userFilter={userFilter}
                setUserFilter={(payload) => dispatch({ type: 'FILTER_BY_USER', payload })}
              />
            }
          />
        </CmdCard.Content>
      </CmdCard>

      <Preview
        selectedChat={selectedChat}
        setTags={(tags: string[]) => {
          dispatch({
            type: 'SET_CHAT_TAGS',
            payload: {
              chat_id: selectedChat?.id as string,
              tags,
              callback: reloadTags,
            },
          });
          setTimeout(() => {
            reloadTags();
          }, 200);
        }}
        closeChat={closeChat}
        createAnswer={createAnswer}
        copyChatLink={(chatId) => copyChatLink(chatId, currentPage)}
        previousChat={() => {
          dispatch({
            type: 'SELECT_PREVIOUS_CHAT',
          });
        }}
        nextChat={() => {
          dispatch({
            type: 'SELECT_NEXT_CHAT',
          });
        }}
      />
    </>
  );
};

export default ChatsTable;
