import React from 'react';
import ReactSelect, { OptionTypeBase, Props } from 'react-select';
import { selectStyles } from './styles';

export const Select = <T extends OptionTypeBase>(props: Props<T>) => {
  const { components, ...rest } = props;
  return (
    <ReactSelect<T>
      styles={selectStyles}
      components={{
        IndicatorSeparator: () => null,
        ...components,
      }}
      {...rest}
    />
  );
};
