import { getContentType } from '../middleware/command';
import type {
  ICommandType,
  ICommandTypeLite,
  IEditorCommandType,
  IEditorCommandTypeLite,
  INudgeType,
} from '../middleware/types';

export const COMMANDS_ROUTE = '/commands';
export const PAGES_ROUTE = '/pages';
export const ACTIONS_ROUTE = '/actions';
export const BAR_ROUTE = '/bar';
export const RELEASES_ROUTE = '/releases';
export const PRODUCT_TOURS_ROUTE = '/product-tours';
export const ANNOUNCEMENTS_ROUTE = '/announcements';
export const SURVEYS_ROUTE = '/surveys';
export const CHECKLIST_ROUTE = '/checklists';
export const AUDIENCES_ROUTE = '/audiences';

export const HELPHUB_ROUTE = '/help-hub';
export const HELPHUB_SETTINGS_ROUTE = '/help-hub/settings';

export const SPOTLIGHT_ROUTE = '/spotlight';
export const SPOTLIGHT_RECORDS_ROUTE = '/spotlight/records';
export const SPOTLIGHT_RECOMMENDATIONS_ROUTE = '/spotlight/recommendations';
export const SPOTLIGHT_SETTINGS_ROUTE = '/spotlight/settings';
export const SPOTLIGHT_RULES_ROUTE = '/spotlight/rules';
export const SPOTLIGHT_ARGUMENTS_ROUTE = '/spotlight/arguments';

export const COPILOT_PARENT_ROUTE = '/copilot';
export const COPILOT_APIS_ROUTE = '/copilot/apis';
export const COPILOT_WORKFLOWS_ROUTE = '/copilot/workflows';
export const COPILOT_SETTINGS_ROUTE = '/copilot/settings';
export const COPILOT_XRAY_ROUTE = '/copilot/x-ray';

export const DESIGN_ROUTE = '/design';
export const THEME_ROUTE = '/themes';

export const TOOLS_ROUTE = '/tools';

export const ALL_EDITOR_ROUTES = [
  PAGES_ROUTE,
  ACTIONS_ROUTE,
  RELEASES_ROUTE,
  PRODUCT_TOURS_ROUTE,
  ANNOUNCEMENTS_ROUTE,
  SURVEYS_ROUTE,
  CHECKLIST_ROUTE,
  AUDIENCES_ROUTE,
  TOOLS_ROUTE,
  DESIGN_ROUTE,
  COPILOT_PARENT_ROUTE,
  COPILOT_SETTINGS_ROUTE,
  SPOTLIGHT_ROUTE,
  SPOTLIGHT_RECORDS_ROUTE,
  SPOTLIGHT_RECOMMENDATIONS_ROUTE,
  SPOTLIGHT_SETTINGS_ROUTE,
  SPOTLIGHT_RULES_ROUTE,
  SPOTLIGHT_ARGUMENTS_ROUTE,
] as const;

export const widgetRoutes = [
  HELPHUB_ROUTE,
  SPOTLIGHT_ROUTE,
  PRODUCT_TOURS_ROUTE,
  ANNOUNCEMENTS_ROUTE,
  SURVEYS_ROUTE,
  CHECKLIST_ROUTE,
];

type EDITOR_ROUTE = (typeof ALL_EDITOR_ROUTES)[number];

export const isValidEditorPage = (page: string | null): page is EDITOR_ROUTE => {
  if (!page) return false;
  return !!ALL_EDITOR_ROUTES.find((route: string) => page.includes(route));
};

export const DEFAULT_EDITOR_ROUTE = PRODUCT_TOURS_ROUTE;

export const getNudgeRoute = ({ type }: { type: INudgeType['type'] }) => {
  switch (type) {
    case 'survey':
      return SURVEYS_ROUTE;
    case 'product_tour':
      return PRODUCT_TOURS_ROUTE;
    case 'announcement':
    default:
      return ANNOUNCEMENTS_ROUTE;
  }
};

export const getCommandsRoute = (
  command: ICommandType | ICommandTypeLite | IEditorCommandType | IEditorCommandTypeLite,
) => {
  switch (command.template.type) {
    case 'link':
      return `${PAGES_ROUTE}/${command.id}`;
    case 'helpdoc':
    case 'video':
      const type = getContentType(command);
      switch (type) {
        case 'source':
          return `/content/sources/${command.integration?.id}/?id=${command.id}`;
        case 'answer':
        case 'file':
        case 'video':
        default:
          return `/content/${type}s/?id=${command.id}`;
      }

    default:
      return `${ACTIONS_ROUTE}/${command.id}`;
  }
};
