import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cn, extendRadixComponent } from '../util';
import { Placeholder } from '@commandbar/design-system/icons/react';
import { CmdLabel, CmdLabelProps } from '../typography/label';
import { radioStyles } from './styles';

interface CmdRadioGroupInterface
  extends React.ForwardRefExoticComponent<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & React.RefAttributes<HTMLDivElement>
  > {
  Item: typeof CmdRadioGroupItem;
  Label: typeof CmdLabel;
}

type CmdRadioGroupProps = {
  disabled?: boolean;
  defaultValue?: string | null;
};

const RadioGroupDisabledContext = React.createContext(false);

const CmdRadioGroupBase = extendRadixComponent<typeof RadioGroupPrimitive.Root, CmdRadioGroupProps>(
  ({ className, disabled = false, defaultValue, ...props }, ref) => {
    return (
      <RadioGroupDisabledContext.Provider value={disabled}>
        <RadioGroupPrimitive.Root
          className={cn('grid gap-2', className)}
          defaultValue={defaultValue}
          {...props}
          ref={ref}
        />
      </RadioGroupDisabledContext.Provider>
    );
  },
);
CmdRadioGroupBase.displayName = 'CmdRadioGroup';

type CmdRadioGroupItemProps = {
  disabled?: boolean;
  label?: string | React.ReactNode;
} & CmdLabelProps;

const CmdRadioGroupItem = extendRadixComponent<typeof RadioGroupPrimitive.Item, CmdRadioGroupItemProps>(
  ({ className, disabled, label, tooltip, ...props }, ref) => {
    const groupDisabled = React.useContext(RadioGroupDisabledContext);
    const isDisabled = disabled || groupDisabled;

    return (
      <CmdLabel disabled={isDisabled} tooltip={tooltip}>
        <div className="flex items-center gap-2">
          <RadioGroupPrimitive.Item
            ref={ref}
            className={cn(radioStyles({ disabled: isDisabled }), className)}
            disabled={isDisabled}
            {...props}
          >
            <RadioGroupPrimitive.Indicator className="flex items-center justify-center h-[6px] w-[6px] absolute top-[4px] left-[4px]">
              <Placeholder
                className={cn({
                  'fill-gray600 text-gray600': isDisabled,
                  'fill-gray100 text-gray100': !isDisabled,
                })}
              />
            </RadioGroupPrimitive.Indicator>
          </RadioGroupPrimitive.Item>
          {label}
        </div>
      </CmdLabel>
    );
  },
);

CmdRadioGroupItem.displayName = 'CmdRadioGroupItem';

const CmdRadioGroup = CmdRadioGroupBase as CmdRadioGroupInterface;
CmdRadioGroup.Item = CmdRadioGroupItem;

export { CmdRadioGroup };
