import React from 'react';
import styled from '@emotion/styled';
import { keyframes, Keyframes } from '@emotion/core';

import { CB_COLORS, DashedBorderWrapper } from '@commandbar/design-system/components/antd';

import { ReactComponent as AddNudgeStepIcon } from '../../img/nudge_step.svg';
import { ReactComponent as ModalSvg } from '../../img/nudges/modal.svg';
import { ReactComponent as PopoverSvg } from '../../img/nudges/popover.svg';
import { ReactComponent as PinSvg } from '../../img/nudges/pin.svg';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';

const bounceIn = keyframes`
  0% { transform: translate3d(0, 0, 0) }
  52% { transform: translate3d(0, -38%, 0) }
  55% { transform: translate3d(0, -41%, 0) }
  100% { transform: translate3d(0, -38%, 0) }
`;
const bounceOut = keyframes`
  0% { transform: translate3d(0, -38%, 0) }
  52% { transform: translate3d(0, 0, 0) }
  55% { transform: translate3d(0, 3%, 0) }
  100% { transform: translate3d(0, 0, 0) }`;

// TODO: make sure this still works in `esbuild` based builds
const AnimationContainer = styled.div<{ animationName: Keyframes | string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0px;

  position: absolute;
  top: -50%;
  transform: translate3d(0, 0, 0);
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;

  animation-name: ${(props) => props.animationName};
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  color: ${CB_COLORS.neutral1000};
  background: ${CB_COLORS.white};
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  user-select: none;
`;

const AddStepButton = styled(Button)`
  margin-bottom: 46px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const TypeButton = styled(Button)`
  text-transform: capitalize;
  margin-right: 8px;
  width: 130px;

  &:last-child {
    margin-right: 0;
  }
`;

type FormFactorType = INudgeStepType['form_factor']['type'];

type FormFactorTypeButton = {
  label: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  type: FormFactorType;
};

const TYPE_BUTTONS: FormFactorTypeButton[] = [
  {
    label: 'modal',
    Icon: ModalSvg,
    type: 'modal',
  },
  {
    label: 'popover',
    Icon: PopoverSvg,
    type: 'popover',
  },
  {
    label: 'pin',
    Icon: PinSvg,
    type: 'pin',
  },
];

interface IAddNudgeButton {
  onClick: (type: FormFactorType) => void;
}

export const AddNudgeButton = ({ onClick }: IAddNudgeButton) => {
  const [isFormActive, setIsFormActive] = React.useState<boolean | undefined>(undefined);

  const activeKeyframes: Keyframes | string = (() => {
    switch (isFormActive) {
      case undefined:
        return 'none';
      case true:
        return bounceIn;
      case false:
        return bounceOut;
    }
  })();
  return (
    <DashedBorderWrapper>
      <AnimationContainer
        animationName={activeKeyframes}
        onMouseEnter={() => setIsFormActive(true)}
        onMouseLeave={() => setIsFormActive(false)}
      >
        <AddStepButton onClick={() => setIsFormActive(true)}>
          <AddNudgeStepIcon />
          Add Step
        </AddStepButton>
        <FormContainer>
          {TYPE_BUTTONS.map(({ label, Icon, type }) => (
            <TypeButton
              key={type}
              onClick={() => {
                onClick(type);
                setIsFormActive(false);
              }}
            >
              <Icon />
              {label}
            </TypeButton>
          ))}
        </FormContainer>
      </AnimationContainer>
    </DashedBorderWrapper>
  );
};
