import React from 'react';
import { cn } from '../util';
import { dividerStyles } from './styles';
import { type VariantProps } from 'class-variance-authority';
import { CmdSeparator } from './separator';

type VariantDividerProps = VariantProps<typeof dividerStyles>;

export type DividerProps = VariantDividerProps & {
  content?: string | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type?: 'vertical' | 'horizontal';
};

export const CmdDivider = ({
  orientation,
  spacing,
  content,
  children,
  className,
  style,
  type,
}: React.PropsWithChildren<DividerProps>) => {
  if (type === 'vertical') {
    return <CmdSeparator orientation="vertical" style={style} className={className} />;
  }
  if (content || children) {
    return (
      <div className={cn(['flex items-center'], className)} style={style}>
        <span className={cn(dividerStyles({ spacing, orientation, side: 'left' }))}></span>
        <span className="px-4 whitespace-nowrap text-base">{content ?? children}</span>
        <span className={cn(dividerStyles({ spacing, orientation, side: 'right' }))}></span>
      </div>
    );
  }

  return <div className={cn(dividerStyles({ spacing, orientation }), className)} style={style}></div>;
};
