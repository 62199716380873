import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import { Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { ConditionProps } from '../../types';
import { ElementCondition, HostnameCondition, URLCondition } from '@commandbar/internal/middleware/conditions';
import { ElementInformation } from '@commandbar/internal/middleware/types';
import { ClickRecorder } from 'editor/src/editor/components/ClickRecorder';

type Props = ConditionProps<URLCondition | HostnameCondition | ElementCondition> & {
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
  path?: string;
};
export const PageCondition: React.FC<Props> = ({ condition, onStartClickRecorder, path }) => {
  const { onConditionChange } = useConditionEditor();

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <ConditionValueInput
        suffix={
          condition.operator === 'selectorOnPage' || condition.operator === 'selectorNotOnPage' ? (
            <ClickRecorder
              onValueChange={(value: ElementInformation) =>
                onConditionChange({
                  ...condition,
                  value: value.selector,
                })
              }
              onStartClickRecorder={onStartClickRecorder?.bind(this, `${path}.value`)}
            />
          ) : null
        }
      />
    </Row>
  );
};
