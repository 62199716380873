import * as React from 'react';
import { IOrganizationSettingsType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { CmdSwitch } from '@commandbar/design-system/cmd';

type SupportedSettings = Pick<
  IOrganizationSettingsType,
  | 'releases_enabled'
  | 'end_user_shortcuts_enabled'
  | 'end_user_recents_enabled'
  | 'force_end_user_identity_verification'
  | 'releases_available'
  | 'in_bar_feedback'
  | 'allow_event_handlers'
  | 'spotlight_ask_copilot_enabled'
>;

interface IProps {
  organization: IOrganizationType;
  updateOrganizationSettingsProperty: (
    field: keyof SupportedSettings,
    newVal: any,
  ) => Promise<IOrganizationSettingsType>;
  setting: keyof SupportedSettings;
  style?: React.CSSProperties;
}

const ToggleOrganizationSetting = (props: IProps) => {
  const [value, setValue] = React.useState(props.organization[props.setting] ?? false);

  const onChange = async (checked: boolean) => {
    setValue(checked);

    const _settings = await props.updateOrganizationSettingsProperty(props.setting, checked);

    // Toggle the switch eagerly, but flip it back if the request fails
    if (_settings?.[props.setting] !== checked) {
      setValue(!checked);
    }
  };

  return <CmdSwitch style={props.style ?? {}} checked={value} onCheckedChange={onChange} />;
};

export default ToggleOrganizationSetting;
