import React, { Fragment } from 'react';

import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';
import styled from '@emotion/styled';

const styles = {
  default: {
    minHeight: 80,
    fontFamily: '"Dank Mono", "Fira Code", monospace',
    fontSize: 12,
    border: '1px solid rgba(0,0,0,0.1)',
    borderRadius: '4px',
    paddingBottom: '18px',
    ...theme.plain,
    backgroundColor: 'white',
  },
};

type Props = {
  placeholder: string;
  value: any;
  onValueChange: (code: string) => void;
  language: Language;
};

/** h/t https://stackoverflow.com/questions/33448213/how-to-reset-background-color-of-selection
 *
 * Change the color and background color of the "selection" pseudo element back to system defaults.
 *
 * This is necessary because the default selection background is the Ant Design primary color, which
 * for us is a very dark gray. The text color of the select *should* be white, which would look fine
 * on the dark gray background, but because of the way the syntax highlighting works in
 * `react-simple-code-editor`, setting the text color of the selection via CSS doesn't work, so you
 * end up with dark text on a dark background.
 *
 * before: https://share.commandbar.com/M98jxmPM
 * after: https://share.commandbar.com/r1XYRRxF
 **/
const EditorContainer = styled.div`
  max-height: 240px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  & textarea::selection {
    color: highlighttext;
    background: highlight;
  }
`;

const PrettyCodeEditor = (props: Props) => {
  const highlight = (_code: string) => (
    <Highlight {...defaultProps} theme={theme} code={props.value} language={props.language}>
      {({ tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  );

  return (
    <EditorContainer>
      <Editor
        value={props.value}
        onValueChange={props.onValueChange}
        placeholder={props.placeholder}
        highlight={highlight}
        padding={10}
        insertSpaces={true}
        style={styles.default}
      />
    </EditorContainer>
  );
};
export default PrettyCodeEditor;
