import React from 'react';
import Sender from '../../management/Sender';

/**
 * Show a notification if there are many commandbar.com calls at once
 */
const API_CALLS_POLLING_DELAY = 10 * 1000;
const API_CALLS_EXCESS = 100;

const getLogs = () => {
  return Sender.shareLogs().then((data: any) => {
    return data.logs;
  });
};

const hideSDKCall = (method: string) => {
  return method.startsWith('share');
};

let timerId: NodeJS.Timeout;
const useSDKUsageMonitoring = () => {
  const [apiCallsExceeded, setApiCallsExceeded] = React.useState<boolean>(false);
  const monitorAPICalls = (callback: Function) => {
    // Recursive set timeout used here instead of setInterval to obtain more control and extendability of callback function
    timerId = setTimeout(async function handleLogs() {
      const logs = (await getLogs()) || [];
      if (!callback(logs.filter((log: any) => !hideSDKCall(log.name)).length))
        timerId = setTimeout(handleLogs, API_CALLS_POLLING_DELAY);
    }, API_CALLS_POLLING_DELAY);
  };

  React.useEffect(() => {
    // Launch logs monitoring
    let previousAPICallsAmount = 0;

    monitorAPICalls((currentLogsAmount: number) => {
      const isLimitExceeded = currentLogsAmount - previousAPICallsAmount > API_CALLS_EXCESS;
      setApiCallsExceeded(isLimitExceeded);
      previousAPICallsAmount = currentLogsAmount;

      return isLimitExceeded;
    });

    return () => timerId && clearTimeout(timerId);
  }, []);

  return { apiCallsExceeded };
};

export default useSDKUsageMonitoring;
