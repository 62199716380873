import { IPartialTheme } from '@commandbar/internal/client/theme';

const CLASSIC_THEME_LAYOUT: IPartialTheme = {};

const MODERN_THEME_LAYOUT: IPartialTheme = {
  main: {
    borderRadius: '8px',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 10px 15px',
  },
  bar: {
    top: '10%',
    width: '675px',
    menuHeight: '350px',
  },
  option: {
    minHeight: '46px',
    marginLeft: '10px',
    marginRight: '10px',
    borderRadius: '6px',
    breadcrumbsMarginBottom: '-3px',
    paddingLeft: '10px',
  },
  optionSelected: {
    borderHighlight: 'transparent',
  },
  categoryHeader: {
    background: 'transparent',
    paddingLeft: '25px',
    paddingRight: '0px',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderTop: '1px solid transparent',
    fontSize: '13px',
  },
  keyboardShortcuts: {
    borderRadius: '4px',
    background: 'transparent',
  },
  blocks: {
    border: 'none',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  blocksContainer: {
    paddingTop: '18px',
    paddingBottom: '0px',
    paddingLeft: '18px',
    paddingRight: '18px',
  },
  footer: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '22px',
    paddingRight: '22px',
  },
  input: {
    paddingLeft: '23px',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  optionList: {
    paddingTop: '4px',
    paddingBottom: '4px',
    spaceBetweenCategories: '10px',
  },
  searchTabsContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  searchTab: {
    borderRadius: '8px',
  },
};

export const PREBUILT_SKIN_LAYOUTS = {
  modern: {
    description: 'Clean, transparent headers, more spacing, and rounded corners',
    theme: MODERN_THEME_LAYOUT,
  },
  traditional: {
    description: 'Classic UX',
    theme: CLASSIC_THEME_LAYOUT,
  },
};

export type TSkinLayout = keyof typeof PREBUILT_SKIN_LAYOUTS | null;
