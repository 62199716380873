import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { CmdButton } from '@commandbar/design-system/cmd';
import { ISegmentWebhookIntegrationProps } from './SegmentWebhookIntegrationPage';
import { useAppContext } from 'editor/src/AppStateContext';
import { segmentWebhookDetailsDisplay } from './SegmentWebhookEntryStep';

interface SegmentWebhookSharedSecretStepProps {
  handleNext: Function;
  handlePrev: Function;
}

export const SegmentWebhookSharedSecretStep = ({
  handlePrev,
  segmentWebhookConfig,
}: SegmentWebhookSharedSecretStepProps & ISegmentWebhookIntegrationProps) => {
  const { organization } = useAppContext();

  const apiURL = process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
  const webhookEndpoint = `${apiURL}/api/${organization.id}/segment/`;

  if (!segmentWebhookConfig) {
    handlePrev();
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <span>
        The webhook is now active and ready to use. You can now finish the setup within Segment by setting the shared
        secret and adding mappings. The latter is also where you configure the webhook URL. <br />
        The CommandBar Webhook is designed to work with a <b>maximum batch size of 1000</b>. Make sure to set this exact
        value in Segment. If the batch size is too small or too large, CommandBar might have to drop some events for
        performance reasons.{' '}
        <a
          style={{ color: CB_COLORS.primary }}
          href="https://segment.com/docs/connections/destinations/catalog/actions-webhook/"
        >
          Learn more in Segment's documentation.
        </a>
      </span>

      {segmentWebhookDetailsDisplay(webhookEndpoint, segmentWebhookConfig.shared_secret)}

      <CmdButton variant="primary" onClick={() => handlePrev()}>
        Go Back
      </CmdButton>
    </div>
  );
};
