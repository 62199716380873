import { useState, useEffect, useRef } from 'react';
import { useAppContext } from 'editor/src/AppStateContext';
import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import { PROCESSING_OPERATION } from './SourceProcessingProgress';

// We have no idea how long the fetch will take, so we just estimate it to be 120 seconds
const ESTIMATED_FETCH_TIME_S = 120;
// The progress bar should be no more than 65% complete when fetching finishes
const FETCH_PROGRESS_PERCENTAGE = 65;
// We check the whether the sync is still running every second
const SERVER_UPDATE_INTERVAL_MS = 1000;

export type SourceState = {
  progress: number;
  numFetched: number;
  numProcessed: number;
  progressAtProcessing?: number;
  operation?: PROCESSING_OPERATION;
};

const initialState: SourceState = {
  progress: 0,
  numFetched: 0,
  numProcessed: 0,
};

const useSourceState = (integrationId: string, reload?: () => void) => {
  const [sourceState, setSourceState] = useState<SourceState>(initialState);
  const progressAtProcessing = useRef<number>(0);
  const [ping, setPing] = useState<boolean>(false);
  const needsReload = useRef<boolean>(false);

  const { loadingSources } = useIntegrationsContext();

  const {
    dispatch: { commands },
  } = useAppContext();

  useEffect(() => {
    const updateWebSync = async () => {
      if (integrationId === 'undefined') return;

      let currentRunning = false;
      let currentDeleting = false;
      let currentNumFetched = initialState.numFetched;
      let currentNumProcessed = initialState.numProcessed;

      try {
        const { running, numFetched, numProcessed, deleting } = await HelpDocsIntegration.readSyncStatus(integrationId);
        currentRunning = running;
        currentDeleting = deleting || false;
        currentNumFetched = numFetched;
        currentNumProcessed = numProcessed;
      } catch (error) {
        // ntd on integration not found - loop will exit naturally and reload if needed
        // can happen after integration was deleted
      }

      let progress;

      if (currentDeleting) {
        needsReload.current = true;

        setSourceState({
          progress: 0,
          numFetched: 0,
          numProcessed: 0,
          operation: PROCESSING_OPERATION.DELETING,
        });
        return;
      } else if (currentRunning) {
        needsReload.current = true;
        if (currentNumFetched) {
          if (sourceState.numFetched === 0) {
            progressAtProcessing.current = sourceState.progress;
          }

          const progressRemaining = 100 - progressAtProcessing.current;
          const progressPerItem = progressRemaining / currentNumFetched;

          progress = Math.min(progressAtProcessing.current + progressPerItem * currentNumProcessed, 100);
        } else {
          progress = Math.min(
            sourceState.progress + FETCH_PROGRESS_PERCENTAGE / ESTIMATED_FETCH_TIME_S,
            FETCH_PROGRESS_PERCENTAGE,
          );
        }
      } else {
        progress = 100;
      }

      if (progress >= 100) {
        setSourceState(initialState);
        clearInterval(serverIntervalId);
        if (needsReload.current) {
          needsReload.current = false;

          reload?.();
          commands.reload();
        }
      } else {
        setSourceState({
          progress,
          numFetched: currentNumFetched,
          numProcessed: currentNumProcessed,
          progressAtProcessing: progressAtProcessing.current,
          operation: !!progress || !!currentNumFetched ? PROCESSING_OPERATION.FETCHING : PROCESSING_OPERATION.SYNCING,
        });
      }
    };

    if (loadingSources.size > 0) {
      needsReload.current = true;
    }

    const serverIntervalId = setInterval(updateWebSync, SERVER_UPDATE_INTERVAL_MS);

    return () => {
      clearInterval(serverIntervalId);
    };
  }, [loadingSources, integrationId, reload, ping]);

  return {
    numProcessed: sourceState.numProcessed,
    numFetched: sourceState.numFetched,
    progress: sourceState.progress,
    ping: () => setPing((x) => !x),
    operation: sourceState.operation,
  };
};

export default useSourceState;
