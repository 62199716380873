import React, { useEffect, useState } from 'react';
import CommandDetail from './CommmandDetail/CommandDetail';
import { useHistory, useParams } from 'react-router';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { Skeleton } from '@commandbar/design-system/components/antd';
import DetailLayout from './CommmandDetail/DetailLayout';
import * as Command from '@commandbar/internal/middleware/command';

export const ActiveCommand = ({
  activeCommand,
  setActiveCommand,
}: {
  activeCommand?: IEditorCommandType;
  setActiveCommand?: (command: IEditorCommandType | undefined) => void;
}) => {
  const history = useHistory();
  const params = useParams<{ commandId?: string }>();
  const [command, setCommand] = useState<IEditorCommandType | undefined>(activeCommand);

  useEffect(() => {
    const updateCommand = async () => {
      const commandId = params.commandId ? parseInt(params.commandId) : undefined;
      if (!!commandId && commandId > -1) {
        Command.get(commandId.toString()).then((command) => setCommand(command));
      } else if (commandId === undefined) {
        setCommand(undefined);
      }
    };
    updateCommand();
  }, [params.commandId]);

  const getUpperEditorLevel = () => {
    const { pathname } = history.location;
    // Split the pathname into segments, filter out empty segments in case of trailing slashes
    const segments = pathname.split('/').filter(Boolean);
    // Remove the last segment
    segments.pop();
    // If we were on a legacy edit page (ex: /helphub/edit/1234), we should remove the last two segments
    if (segments[-1] === 'edit') {
      segments.pop();
    }
    // Construct the new path
    return `/${segments.join('/')}`;
  };

  const onBack = () => {
    const newCommand = activeCommand?.id === -1;
    setActiveCommand?.(undefined);
    newCommand || history.length <= 1 ? history.replace(getUpperEditorLevel()) : history.goBack();
  };

  return command ? (
    <CommandDetail
      /* NOTE: `key` is needed so that the state of the Editor (individual tab dirty states, etc.) is reset when Command under edit changes */
      key={command.id}
      initialCommand={command}
      onClose={onBack}
      onDelete={onBack}
    />
  ) : (
    <DetailLayout
      actions={[<Skeleton.Button active />]}
      content={<Skeleton active style={{ margin: 16 }} />}
      title={<Skeleton.Input active />}
      subTitle={<Skeleton.Input active />}
      onGoBack={onBack}
      isRecordAction={false}
      showInDefaultList={true} // default to true, will be updated if not once loaded
    ></DetailLayout>
  );
};
