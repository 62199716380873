import { CBStore } from 'shared/store/global-store';
import { ICommandType, IOrganizationType } from '@commandbar/internal/middleware/types';
import { IEndUserType } from '@cb/types/entities/endUser';
import { currentStepAndIndex, isSelectStep, getArgumentSelections } from '../steps/selectors';
import { selectInitialValueCallbacks } from '../selectors';
import * as GlobalActions from 'shared/store/global-actions';
import Logger from '@commandbar/internal/util/Logger';
import { hasInitialValueFnDefined } from '../options/option-utils/OptionValidate';
import isEqual from 'lodash/isEqual';
import { queryExperiences, queryHelpDocCommands } from 'shared/sdk/search';
import { getSentry } from '@commandbar/internal/util/sentry';
import { isHelpHubWidgetAvailable } from 'products/helphub/service-selectors';
import { MultiSelectStep, SelectStep } from '../steps/step-utils';
import { StepType } from '../steps/step-utils/Step';

const requestInstanceKey = 'commandbar-hotload-commands';

function DebounceWithCancellation<T>(
  fn: (abort: AbortController) => (...args: any[]) => Promise<T>,
  timeout: number,
): (...args: any[]) => Promise<T> {
  let timeoutId: any = null;
  let abortLastPromise: AbortController | null = null;
  return (...args: Parameters<ReturnType<typeof fn>>) => {
    if (timeoutId) {
      abortLastPromise?.abort();
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    return new Promise<T>((resolve, reject) => {
      timeoutId = setTimeout(() => {
        abortLastPromise = new AbortController();
        fn(abortLastPromise)(...args).then(
          (value) => {
            if (!abortLastPromise?.signal.aborted) resolve(value);
          },
          (error) => {
            if (!abortLastPromise?.signal.aborted) reject(error);
          },
        );
      }, timeout);
    });
  };
}

// "default commands" are shown when the input text is empty
let defaultCommands: ICommandType[] | null = null;

const getHotloadedHelpdocCommands = DebounceWithCancellation(
  (abort: AbortController) =>
    async (organization: IOrganizationType, input: string, endUser: IEndUserType | null | undefined) => {
      try {
        return {
          commands: await queryHelpDocCommands(
            organization.id,
            input,
            endUser,
            null /* filter only applies to the HelpHub widget */,
            abort,
          ),
        };
      } catch (e: any) {
        if (abort.signal.aborted) {
          getSentry()?.captureException(e);
        }
      }
      return null;
    },
  150,
);

const getHotloadedHelpdocExperiences = DebounceWithCancellation(
  (abort: AbortController) => async (_: CBStore, input: string) => {
    try {
      return await queryExperiences(_, input, 'spotlight', ['helpdoc'], undefined, abort);
    } catch (e: any) {
      if (abort.signal.aborted) {
        getSentry()?.captureException(e);
      }
    }
    return null;
  },
  150,
);

export const updateHotloadedHelpdocCommands = async (_: CBStore) => {
  if (!_.organization?.has_hotloaded_help_docs || !_.organization?.in_bar_doc_search) {
    return;
  }

  const setLoading = (isLoading: boolean) => {
    _.spotlight.loadingByKey[requestInstanceKey] = isLoading;
  };

  // NOTE: going to try commenting this out -- a better experience if we leave existing results in place while fetching new ones
  // NOTE2: DO NOT COMMENT THIS OUT; if you do, extremely poor performance when typing quickly due to excessive React rerendering of the options list (I think)
  _.hotloadedCommands = [];

  const inputText = _.spotlight.inputText;

  if (_.spotlight.visible) {
    setLoading(true);
  }

  // if we've previously fetched default commands, use them (and re-fetch in case they've been updated)
  if (inputText === '' && defaultCommands !== null) {
    _.hotloadedCommands = defaultCommands;
  }

  try {
    let commands: ICommandType[];
    if (_.flags?.['release-search-experiences-in-spotlight']) {
      const result = await getHotloadedHelpdocExperiences(_, inputText);
      if (!result) return;
      commands = result.filter((hit) => hit.type === 'helpdoc').map((hit) => hit.experience) as ICommandType[];
    } else {
      const result = await getHotloadedHelpdocCommands(_.organization, inputText, _?.endUser);
      if (!result) return;
      commands = result.commands;
    }

    if (commands && Array.isArray(commands)) {
      _.hotloadedCommands = commands.map((command, idx) => {
        let modifiedArguments = command.arguments;
        if (
          command.template.type === 'helpdoc' &&
          isHelpHubWidgetAvailable(_) &&
          _.organization?.copilot_enabled === true &&
          _.organization?.helphub_enabled !== true
        ) {
          // remove __html__ special argument so that help doc will open in HelpHub rather than Dashboard
          const { __html__, ...rest } = command.arguments;
          modifiedArguments = rest;
        }

        return {
          ...command,
          arguments: modifiedArguments,
          sort_key: idx, // sort by order returned by Elastic Search -- NOT the order dictated by the user in the Editor
          isAsync: true /* this causes the command to bypass filtering and always be displayed */,
        };
      });

      // store "default commands" if the input text is empty for future use
      if (inputText === '') defaultCommands = [..._.hotloadedCommands];
    }
  } finally {
    setLoading(false);
  }
};

export const callAllLoaders = (_: CBStore) => {
  // Note: we fetch initial values on open & close so that async initial values show up immediately when the bar opens
  // FIXME: One improvement here could be to only call it on first load & open
  return Promise.all(selectInitialValueCallbacks(_).map((k) => callLoader(_, k)));
};

const callLoader = async (_: CBStore, callbackKey: string) => {
  const contextKey = callbackKey.split('commandbar-initialvalue-')[1];
  try {
    const results = await _.callbacks[callbackKey]();
    const prevResults = _.context[contextKey];
    if (prevResults !== results && !isEqual(prevResults, results))
      GlobalActions.addContext(_, { [contextKey]: results });
  } catch (e) {
    getSentry()?.captureException(e);
    Logger.error(`Function loader caused an error. Key: ${contextKey}`);
  }
};

// FIXME: Handler to update preselected values for steps when preselected values are async
// This is a quick solution, we should probably just handle all preselection in the same place
// Doesn't need to be done when commands are chosen
export const handleAsyncPreselect = async (_: CBStore) => {
  const { currentStep } = currentStepAndIndex(_);
  if (!isSelectStep(currentStep)) return;
  if (!!currentStep?.argument?.preselected_key) {
    if (hasInitialValueFnDefined(currentStep?.argument?.preselected_key, _)) {
      // Abstract this out
      const fnName = `commandbar-initialvalue-${currentStep?.argument?.preselected_key}`;
      const asyncFn = _.callbacks[fnName];
      const chosenValues = getArgumentSelections(_);
      const results = await asyncFn(chosenValues);
      setStepSelectionsDuringStep(currentStep, results);
    }
  }
};

// This method is used when we want to set selections _during_ the Step (e.g. async preselect loaders)
const setStepSelectionsDuringStep = (s: SelectStep | MultiSelectStep, selection: unknown[] | unknown) => {
  if (Array.isArray(selection)) {
    if (s.type === StepType.Select) return;
  } else {
    if (s.type !== StepType.Select) selection = [selection];
  }
  if (s.type !== StepType.Select) selection = (selection as unknown[]).slice(0);
  s.selected = {
    type: 'parameter',
    category: null,
    data: selection,
  };
};
