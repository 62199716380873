// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Settings02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.975 20.686 9.39 19.37a2.426 2.426 0 0 0-2.474-1.429l-1.43.152a2.214 2.214 0 0 1-2.023-3.502l.847-1.163a2.429 2.429 0 0 0 0-2.858l-.847-1.163a2.211 2.211 0 0 1 2.023-3.502l1.434.152a2.433 2.433 0 0 0 1.47-.312 2.426 2.426 0 0 0 1-1.117l.585-1.315a2.212 2.212 0 0 1 4.044 0l.589 1.315c.207.468.557.86 1 1.117.445.256.96.365 1.47.312l1.43-.152a2.212 2.212 0 0 1 2.022 3.502l-.847 1.163A2.43 2.43 0 0 0 19.22 12a2.428 2.428 0 0 0 .46 1.434l.847 1.164a2.212 2.212 0 0 1-2.023 3.502l-1.43-.152a2.426 2.426 0 0 0-2.47 1.423l-.584 1.315a2.213 2.213 0 0 1-4.044 0ZM15.1 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      clipRule="evenodd"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m9.395 19.371.585 1.315a2.212 2.212 0 0 0 4.044 0l.584-1.315a2.426 2.426 0 0 1 2.47-1.423l1.43.152a2.212 2.212 0 0 0 2.023-3.502l-.847-1.164a2.428 2.428 0 0 1-.46-1.434c0-.513.163-1.014.464-1.429l.847-1.163a2.211 2.211 0 0 0-2.022-3.502l-1.43.152a2.433 2.433 0 0 1-1.47-.312 2.426 2.426 0 0 1-1-1.117l-.589-1.315a2.212 2.212 0 0 0-4.044 0L9.395 4.63c-.207.468-.557.86-1 1.117-.445.256-.96.365-1.47.312l-1.434-.152a2.212 2.212 0 0 0-2.023 3.502l.847 1.163a2.43 2.43 0 0 1 0 2.858l-.847 1.163a2.21 2.21 0 0 0 .786 3.273c.382.195.811.274 1.237.23l1.43-.153a2.434 2.434 0 0 1 2.474 1.43Z"
    />
  </svg>
);
export default Settings02;
