/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

import useTheme from 'shared/util/hooks/useTheme';
import Z from '@commandbar/internal/client/Z';

import { ReactComponent as CloseIcon } from './close_icon.svg';

interface CloseButtonProps {
  onInteraction: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
}

const CloseButton = ({ onInteraction }: CloseButtonProps) => {
  const { theme } = useTheme();

  return (
    <button
      type="button"
      data-testid="nudge-step-close-button"
      aria-label="Close"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        onInteraction(e);
      }}
      style={{
        // unstyled button
        display: 'flex',
        background: 'none',
        border: 'none',
        padding: 0,
        margin: 0,
        color: 'inherit',
        font: 'inherit',
        cursor: 'pointer',
        textAlign: 'inherit',
        zIndex: Z.Z_COMMANDBAR - 1,
      }}
    >
      <CloseIcon
        css={{
          width: '12px',
          height: '12px',
          cursor: 'pointer',
          transition: `opacity ${theme.main.transitionTime} ease-in`,
          flexShrink: 0,
          alignSelf: 'flex-start',
          color: theme.nudges.closeButtonColor,

          '&:hover': {
            color: theme.nudges.closeButtonHoverColor,
          },
        }}
      />
    </button>
  );
};

export default CloseButton;
