import React from 'react';
import { Tooltip } from '@commandbar/design-system/components/antd';
import { AlertTriangle } from '@commandbar/design-system/icons/react';

interface ISlashFilterCollisionProps {
  allFilters: string[];
  currentFilter: string;
}

const SlashFilterCollisions = React.memo((props: ISlashFilterCollisionProps) => {
  const collisions =
    !props.currentFilter || props.currentFilter.length === 0
      ? []
      : props.allFilters.filter((c) => {
          if (!props.currentFilter) {
            return false;
          }

          return props.currentFilter.startsWith(c) || c.startsWith(props.currentFilter);
        });

  if (collisions.length) {
    const collisionText = (
      <div>
        <div>
          This keyword collides with {collisions.length} other slash filter keyword{collisions.length === 1 ? '' : 's'}:
        </div>
        <ul>
          {collisions.map((c) => {
            return <li key={c}>{c}</li>;
          })}
        </ul>
      </div>
    );

    return (
      <Tooltip content={collisionText}>
        <AlertTriangle color="red" style={{ paddingLeft: '10px' }} />
      </Tooltip>
    );
  }
  return <div />;
});

export default SlashFilterCollisions;
