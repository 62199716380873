import * as t from 'io-ts';
import { RuleExpressionV } from './rules';
import { IChecklist, INudgeType } from '../types';

export const PushTriggerV = t.union([
  t.type({ type: t.literal('when_conditions_pass') }),
  /** DEPRECATED: no longer selectable as trigger type  */
  t.type({ type: t.literal('when_page_reached'), meta: t.type({ url: t.string }) }),
  t.type({ type: t.literal('on_command_execution'), meta: t.type({ command: t.string }) }),
  t.type({
    type: t.literal('on_event'),
    meta: t.type({
      event: t.string,
      condition_group: t.union([RuleExpressionV, t.null, t.undefined]),
    }),
  }),
  t.type({ type: t.literal('when_element_appears'), meta: t.type({ selector: t.string }) }),
  t.type({ type: t.literal('on_user_confusion') }),
  t.type({ type: t.literal('on_rage_click') }),
  t.type({ type: t.literal('smart_delay') }),
  t.type({
    type: t.literal('after_time'),
    meta: t.type({ unit: t.union([t.literal('minute'), t.literal('second')]), value: t.number }),
  }),
  /**
   * Note: this trigger does not have to be set for share linking to work.
   * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
   */
  t.type({ type: t.literal('when_share_link_viewed') }),
  t.type({
    type: t.literal('scheduled'),
    meta: t.type({ interval: t.union([t.literal('day'), t.literal('week'), t.literal('month')]), value: t.number }),
  }),
]);

export type TriggerableEntity = INudgeType | IChecklist;

export enum TriggerableEntityType {
  NUDGE = 'nudge',
  CHECKLIST = 'checklist',
}

export const isNudge = (triggerable: TriggerableEntity): triggerable is INudgeType => 'frequency_limit' in triggerable;

export type TriggerableEntityId = `${TriggerableEntityType}-${INudgeType['id'] | IChecklist['id']}`;

export const generateTriggerableEntityId = (triggerableEntity: TriggerableEntity): TriggerableEntityId =>
  `${isNudge(triggerableEntity) ? TriggerableEntityType.NUDGE : TriggerableEntityType.CHECKLIST}-${
    triggerableEntity.id
  }`;
