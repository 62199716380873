import { useEffect, useState } from 'react';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import { INudgeType } from '@commandbar/internal/middleware/types';
import { BannerIds } from './constants';
import { RenderMode } from '../RenderNudge';

type BannerProps = {
  renderMode: RenderMode;
  nudge: INudgeType;
  stepIndex: number;
  stepCount?: string;
  preventRepositionOnEditorOpen?: boolean;
};

const useInjectPortalRoot = ({ nudge, stepIndex }: BannerProps) => {
  const step = getNudgeStep(nudge, stepIndex);
  const [portalRootElement, setPortalRootElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (step?.form_factor.type === 'banner' && step.form_factor.placement !== 'overlay') {
      const render = () => {
        if (step?.form_factor.type === 'banner') {
          const styles: React.CSSProperties = {
            width: '100%',
            height: 'auto',
            position: step.form_factor?.placement === 'sticky' ? 'sticky' : 'relative',
            ...(step.form_factor?.placement === 'sticky' && {
              [step.form_factor.position === 'top' ? 'top' : 'bottom']: 0,
            }),
          };

          const isTop = step.form_factor.position === 'top';
          const targetElem = document.body;
          const insertPosition = isTop ? 'afterbegin' : 'beforeend';

          if (targetElem) {
            const bannerElement = document.createElement('div');
            bannerElement.id = BannerIds.Space;
            Object.assign(bannerElement.style, styles);

            document.body.insertAdjacentElement(insertPosition, bannerElement);
            setPortalRootElement(bannerElement);
          }
        }
      };

      const cleanup = () => {
        const bannerToRemove = document.getElementById(BannerIds.Space);
        if (bannerToRemove) {
          bannerToRemove.parentNode?.removeChild(bannerToRemove);
        }
      };

      render();
      return cleanup;
    }
  }, [nudge, stepIndex]);

  return {
    portalRootElement,
  };
};

export default useInjectPortalRoot;
