/** @jsx jsx  */
import { jsx } from '@emotion/core';

const AskAIButton = ({ onClick, small = false }: { onClick?: () => void; small?: boolean }) => {
  return (
    <button
      onClick={onClick}
      css={{
        width: small ? 80 : 120,
        height: small ? 33 : 50,
        cursor: 'pointer',
        background: `conic-gradient(
          from 109deg at 48.43% 50%,
          #0a0f1f 43.12499910593033deg,
          #272b56 103.12500357627869deg,
          #0a0f1f 191.8141007423401deg,
          #0a0f1f 309.375deg,
          #262a54 343.30342054367065deg
        )`,
        borderRadius: 13,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        transition: 'all 0.05s cubic-bezier(0.85, 0, 0.15, 1)',

        ...(onClick !== undefined
          ? {
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 4px 21px',
              },
            }
          : null),

        span: {
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: small ? 12 : 18,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          zIndex: 2,
          background: 'linear-gradient(180deg, #fff 0%, #ddc5fb 100%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      }}
    >
      <span>Ask&nbsp;AI</span>
    </button>
  );
};

export default AskAIButton;
