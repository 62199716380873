// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const SpacingWidth02 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21V3M3 21V3m3.5 9h11m0 3V9m-11 6V9"
    />
  </svg>
);
export default SpacingWidth02;
