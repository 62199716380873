import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  position: absolute;
  display: flex;
  top: calc(max(var(--layout-padding) - 8px, 0px));
  right: calc(max(var(--layout-padding) - 8px, 0px));
  max-height: 32px;
  max-width: 32px;
  border-radius: var(--layout-radius-button);
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const StyledCloseButtonContainer = ({ children }: { children: React.ReactNode }) => <Container>{children}</Container>;

export default StyledCloseButtonContainer;
