import sanitizer from 'sanitize-html';
import createDOMPurify from 'dompurify';

const DEFAULT_ADD_ATTR = [
  'onclick',
  'id',
  'class',
  'style',
  'allow',
  'allowfullscreen',
  'frameborder',
  'height',
  'src',
  'title',
  'width',
  'target',
];

const DEFAULT_ADD_TAGS = ['iframe'];

let sanitizeHtml: (html: string, allowTags?: string[]) => string;

if (!!process.env.SALESFORCE_BUILD) {
  sanitizeHtml = (html, allowTags) =>
    sanitizer(html, {
      allowedAttributes: {
        ...sanitizer.defaults.allowedAttributes,
        '*': DEFAULT_ADD_ATTR,
      },
      parseStyleAttributes: false,
      allowedTags: sanitizer.defaults.allowedTags.concat(allowTags?.length ? allowTags : DEFAULT_ADD_TAGS),
    });
} else {
  const DOMPurify = createDOMPurify();

  DOMPurify.addHook('uponSanitizeAttribute', (_: any, data: any) => {
    if (data.attrName === 'onclick') {
      const script = data.attrValue;

      if (!/^window\.CommandBar\.execute\(.{1,50}\)$/.test(script)) {
        return (data.attrValue = '');
      }
    }
  });

  sanitizeHtml = (html, allowTags) =>
    DOMPurify.sanitize(html, {
      ADD_TAGS: allowTags ? allowTags : DEFAULT_ADD_TAGS,
      ADD_ATTR: DEFAULT_ADD_ATTR,
      FORCE_BODY: true,
    });
}

export default sanitizeHtml;
