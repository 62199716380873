/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import StyledIconContainer from './StyledIconContainer';
import { ChevronLeft } from '@commandbar/design-system/icons/react';
import ButtonWithReset from './ButtonWithReset';

const styles: CSSObject = {
  display: 'flex',
  width: 'var(--form-control-height)',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  color: 'var(--button-secondary-content)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--button-secondary-border-color)',
  background: 'var(--button-secondary-background)',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  userSelect: 'none',

  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-semibold)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    borderColor: 'var(--button-secondary-border-color-hover)',
    background: 'var(--button-secondary-background-hover)',
    color: 'var(--button-secondary-content-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    background: 'var(--button-secondary-background-hover)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: 'var(--button-secondary-border-color)',
    background: 'var(--button-secondary-background)',
    color: 'var(--button-secondary-content)',
  },
};

const slug = 'secondaryIconButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledSecondaryIconButton = ({
  children,
  themeV2,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset css={[overrides, css(styles)]} {...props}>
      <StyledIconContainer>{children}</StyledIconContainer>
    </ButtonWithReset>
  );
};

StyledSecondaryIconButton.styles = styles;
StyledSecondaryIconButton.slug = slug;
StyledSecondaryIconButton.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledSecondaryIconButton themeV2={{ themeV2: themeV2, mode: mode }}>
    <ChevronLeft />
  </StyledSecondaryIconButton>
);

export default StyledSecondaryIconButton;
