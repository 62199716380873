import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { SelectProps, Select } from '@commandbar/design-system/components/antd';
import {
  FrameworkInstructions,
  noCodeFrameworkKeys,
  TNoCodeFrameworkKey,
} from '../FrameworkInstructions/FrameworkInstructions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const selectStyles: React.CSSProperties = {
  width: '100%',
  height: '40px !important',
  marginBottom: '32px',
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '14px',
};

type TInstructionsOption = { label: string; value: TNoCodeFrameworkKey };
const options: SelectProps<any, TInstructionsOption>['options'] = [
  {
    label: 'Webflow',
    value: 'webflow',
  },
  {
    label: 'Google Tag Manager',
    value: 'googleTagManager',
  },
  {
    label: 'WordPress',
    value: 'wordpress',
  },
  {
    label: 'Square Space',
    value: 'squarespace',
  },
  {
    label: 'Wix',
    value: 'wix',
  },
];

const ACTIVE_KEY_NAME = 'instructions';
export const NoCodeInstructions = ({ organizationUid }: { organizationUid: IOrganizationType['id'] }) => {
  const defaultKey: TNoCodeFrameworkKey = 'webflow';
  const history = useHistory();
  const [activeKey, setActiveKey] = React.useState<TNoCodeFrameworkKey>(() => {
    const params = new URLSearchParams(history.location.search);
    const defaultPathname = `${history.location.pathname}?${ACTIVE_KEY_NAME}=${defaultKey}`;
    const key = params.get(ACTIVE_KEY_NAME);
    if (!key) {
      history.replace(defaultPathname);
      return defaultKey;
    }
    const validInstructionsKey = noCodeFrameworkKeys.find((validKey) => validKey === key);
    if (validInstructionsKey) {
      return validInstructionsKey;
    } else {
      history.replace(defaultPathname);
      return defaultKey;
    }
  });

  const onChange = (key: TNoCodeFrameworkKey) => {
    setActiveKey(key);
    history.replace(`${history.location.pathname}?${ACTIVE_KEY_NAME}=${key}`);
  };

  return (
    <Container>
      <Select options={options} defaultValue={activeKey} onChange={onChange} style={selectStyles} />
      <FrameworkInstructions organizationUid={organizationUid} frameworkKey={activeKey} />
    </Container>
  );
};
