import { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 36px;
`;

export const List = styled.ol`
  width: 100%;
`;
export const ListItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 32px;

  &:first-child {
    margin-top: 0;
  }

  color: #000000;
`;

export const SubList = styled.ul`
  padding-left: 0;
`;

export const SubListItem = styled.li`
  list-style-type: disc;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: ${CB_COLORS.neutral700};
`;

export const Paragraph = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: ${CB_COLORS.neutral700};
`;

export const StepBody = styled.div`
  display: flex;
  flex-direction: column;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: -18px;

  color: ${CB_COLORS.neutral700};
`;

export const SubStepBody = styled(StepBody)`
  margin-top: 8px;
`;

export const StepTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;

  color: #000000;
`;

export const InlineCode = styled.span`
  background-color: ${CB_COLORS.neutral100};
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 5px;

  color: ${CB_COLORS.neutral700};
`;

export const selectStyles: CSSProperties = {
  width: '100%',
  height: '40px !important',
  marginBottom: '32px',
  fontSize: '14px',
  fontWeight: '500',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '14px',
};

export const Anchor = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: ${CB_COLORS.neutral700};
  padding-bottom: 2px;
  border-bottom: solid 1px ${CB_COLORS.neutral700};
`;
