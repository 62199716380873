const colors = {
  mainPurple: '#2755f0',
  mainPurpleLight: 'rgba(39, 85, 240, 0.05)',
  mainPurpleLightest: 'rgba(39, 85, 240, 0.03);',

  white: '#ffffff',

  grey: 'rgba(0, 0, 0, 0.7)',
  lightGrey: '#f3f3f3',

  mainContainerBorder: '#0000001a',
};

type Color = keyof typeof colors;

export const getColor = (color: Color) => {
  const selectedColor = colors[color];

  if (!selectedColor) {
    throw new Error('Wrong color');
  }

  return selectedColor;
};

// standard Figma box shadow
export const standardBoxShadow = '0px 16px 24px rgba(36, 35, 41, 0.1), 0px 2px 4px rgba(36, 35, 41, 0.1)';
// shadow to show something hovering on top
export const rightInsetShadow = '-2px 0px 4px 0px #0000000f inset';
