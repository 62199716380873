import * as t from 'io-ts';

import { read } from '../generics';
import { type AdminFilter, CardDataV, type DateFilter } from './common';

const AnalyticsOverviewDataV = t.type({
  nudge_engagements: CardDataV,
  checklist_engagements: CardDataV,
  survey_responses: CardDataV,
  helphub_engagements: CardDataV,
  copilot_opens: CardDataV,
  spotlight_opens: CardDataV,
});

export type AnalyticsOverviewData = t.TypeOf<typeof AnalyticsOverviewDataV>;

export class AnalyticsOverview {
  public static read = (options: DateFilter & AdminFilter) =>
    read(AnalyticsOverviewDataV, `analytics/v2/overview`)(options);
}
