import React from 'react';
import useOS from '@commandbar/internal/util/useOS';
import { IUserType } from '@commandbar/internal/middleware/types';

const ResetPasswordBanner = (props: { user: IUserType }) => {
  const { triggerKey } = useOS();

  if (!props.user?.has_updated_password) {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '0px',
          padding: '15px 40px',
          width: '100%',
          backgroundColor: '#ffe58f',
          zIndex: 1000,
          opacity: 0.6,
          textAlign: 'center',
        }}
      >
        <div style={{ opacity: 1, color: 'black' }}>
          {`Please reset your password! `}
          <span style={{ fontWeight: 700 }}>{`${triggerKey}+k ==> "Reset password" 🎉`}</span>
        </div>
      </div>
    );
  }
  return null;
};

export default ResetPasswordBanner;
