/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { IThemeType, IThemeV2Type, WidgetTableauSelection } from '@commandbar/internal/middleware/types';

import { hasUnpublishedChanges, Theme } from '@commandbar/internal/middleware/theme';
import { THEME_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { CmdButton, CmdButtonTabs, CmdInput, CmdLabel, CmdTag, CmdTooltip } from '@commandbar/design-system/cmd';
import { reportErrorToUser } from '../utils/ErrorReporting';
import {
  CheckCircle,
  Globe01,
  Moon02,
  ReverseLeft,
  Rocket01,
  Rows01,
  Sun,
} from '@commandbar/design-system/icons/react';
import { isCSSVar, variableHasBeenUpdated } from './helpers';
import styled from '@emotion/styled';
import { LoadingSave, ThemeDropdown } from './shared';
import { Skeleton } from '@commandbar/design-system/components/antd';
import { cloneDeep, debounce } from 'lodash';
import Sender from 'editor/src/management/Sender';
import { useAppContext } from 'editor/src/AppStateContext';
import { editableVarsTree, editableComponentStylesTree, ThemeFieldGroup } from './editableFieldTrees';
import ThemeComponentEditor, { ThemeAccordion } from './ThemeComponentEditor';
import Z from '@commandbar/internal/client/Z';
import { DEFAULT_THEME } from '@commandbar/internal/client/themesV2/defaults';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import TableauWidgetSelector from 'editor/src/components/TableauWidgetSelector';
import { googleFonts } from '../utils/google-fonts';

const IconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
`;

type Tabs = 'base' | 'components';

type ThemeDetailParams = { themeId: string; tab?: string };

const BindThemeVars = ({
  themeV2,
  mode,
  children,
}: {
  themeV2: IThemeV2Type;
  mode: 'dark_mode' | 'light_mode';
  children: any;
}) => {
  const themeObject = {
    ...DEFAULT_THEME?.[mode].var_defaults,
    ...themeV2?.[mode].var_defaults,
    ...themeV2?.light_mode.var_overrides,
    ...themeV2?.[mode].var_overrides,
  };

  const cssVars = Object.entries(themeObject)
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n');
  const stylesheet = `:root { ${cssVars} }`;

  return (
    <React.Fragment>
      <style>{stylesheet}</style>
      {children}
    </React.Fragment>
  );
};

const ThemeDetailContainer = (/* themeId from params */) => {
  const [theme, setTheme] = useState<IThemeType | undefined>(undefined);
  const [themes, setThemes] = React.useState<IThemeType[] | undefined>(undefined);
  const [state, setState] = React.useState<'loading' | 'done' | 'error'>('loading');

  const refreshThemes = async () => {
    const themes = await Theme.list();
    setThemes(themes);
  };

  const params = useParams<ThemeDetailParams>();

  useEffect(() => {
    let canceled = false;

    refreshThemes()
      .then(() => Theme.read(params.themeId))
      .then((theme) => {
        if (canceled) return;
        setTheme(theme);
        setState('done');
      })
      .catch(() => {
        if (canceled) return;
        setState('error');
      });

    return () => {
      canceled = true;
    };
  }, [params]);

  if (state === 'loading')
    return (
      <div style={{ padding: '24px', background: '#f2f2f2', height: '100%' }}>
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  if (state === 'error' || !theme || !themes)
    return (
      <div style={{ padding: '24px', background: '#f2f2f2', height: '100%' }}>
        Something went wrong; please try reloading the page or reach out to support@commandbar.com if this persists.
      </div>
    );

  return <ThemeDetail key={theme.id} initialTheme={theme} themes={themes} refreshThemes={refreshThemes} />;
};

const ThemeDetail = ({
  initialTheme,
  themes,
}: {
  initialTheme: IThemeType;
  themes: IThemeType[];
  refreshThemes: () => void;
}) => {
  const history = useHistory();

  const {
    organizationSettings,
    commandBarReady,
    flags,
    dispatch: {
      themes: { save },
    },
  } = useAppContext();

  const [mode, setMode] = useState<'light_mode' | 'dark_mode'>('light_mode');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [theme, setTheme] = useState<IThemeType>(initialTheme);
  const [themeName, setThemeName] = useState(theme.name);
  const [currTab, setCurrTab] = useState<Tabs>('base');
  const params = useParams<ThemeDetailParams>();
  const [widgetSelection, setWidgetSelection] = useState<WidgetTableauSelection>({ type: 'all', id: '' });

  const { user } = useAuth();

  const canPublish = hasRequiredRole(user, 'editor');

  const loadExternalFonts = () => {
    /**
     * Attempt to:
     * 1. Extract any custom font family variables from the current theme
     * 2. Split the string on commas and pull the first variable
     * 3. Remove any quotation marks and trim
     */
    const extractedFontFamily = theme.themeV2_draft?.[mode].var_overrides['--font-font-family']
      ?.toString()
      ?.split(',')[0]
      ?.replace(/['"]/g, '')
      ?.trim();

    if (extractedFontFamily) {
      // Fetch the editor iFrame and bomb out if it doesn't exist
      const iframe = document.getElementById('commandbar-editor-bar-preview') as HTMLIFrameElement | null;
      const iframeDocument = iframe?.contentDocument;
      if (!iframeDocument) {
        return;
      }

      // Check if this font family is a known Google font
      const googleFont = googleFonts[extractedFontFamily];
      if (googleFont) {
        // Iterate each of the returned font files (various weights) and load them on the editor document
        Object.entries(googleFont.files).forEach(([fontWeight, fontUrl]) => {
          const myFont = new FontFace(extractedFontFamily, `url(${fontUrl})`, { weight: fontWeight });
          myFont.load().then((loadedFont) => iframeDocument.fonts.add(loadedFont));
        });
      }
    }
  };

  useEffect(() => {
    if (params.tab && ['base', 'components'].includes(params.tab) && organizationSettings?.skins_field_set === 'pro') {
      setCurrTab(params.tab as Tabs);
    } else {
      setCurrTab('base');
    }
  }, [params.tab]);

  // update to the currently active theme when previewing
  useEffect(() => {
    if (!commandBarReady) return;

    Sender.setThemeV2(theme.themeV2_draft, mode);

    loadExternalFonts(); // Load any new external (Google) fonts on theme update
  }, [theme, mode, commandBarReady, params.tab]);

  const showWidgetTableauAndSetParams = (show: boolean, widgetSelection: WidgetTableauSelection) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete('widgetType');
    searchParams.delete('widgetId');
    searchParams.delete('nudgeStep');
    if (widgetSelection.type !== 'all') {
      searchParams.set('widgetType', widgetSelection.type);
      searchParams.set('widgetId', `${widgetSelection.id}`);
      if (widgetSelection.type === 'nudge' && widgetSelection.nudgeStepIndex !== undefined) {
        searchParams.set('nudgeStep', `${widgetSelection.nudgeStepIndex}`);
      }
    }
    history.replace(`${history.location.pathname}?${searchParams.toString()}`);

    setWidgetSelection(widgetSelection);
    Sender.showWidgetTableau(show, widgetSelection);
  };

  // show all widgets when previewing
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const type = searchParams.get('widgetType') || '';
    const id = searchParams.get('widgetId') || '';
    const nudgeStepIndexString = searchParams.get('nudgeStep') || undefined;
    const nudgeStepIndex = nudgeStepIndexString ? parseInt(nudgeStepIndexString) : undefined;
    let _widgetSelection: typeof widgetSelection = { type: 'all', id: '' };
    if (['nudge', 'copilot', 'spotlight', 'helphub', 'checklist'].includes(type)) {
      _widgetSelection = { type: type as WidgetTableauSelection['type'], id, nudgeStepIndex };
    }
    setWidgetSelection(_widgetSelection);

    if (!commandBarReady) return;

    Sender.showWidgetTableau(true, _widgetSelection);

    return () => {
      Sender.showWidgetTableau(false, { type: 'all', id: '' });
    };
  }, [commandBarReady]);

  // on unmount, remove theme override
  useEffect(() => {
    return () => {
      Sender.unsetThemeV2();
    };
  }, []);

  const revertVariable = (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ): IThemeV2Type => {
    if (variable && theme && variableHasBeenUpdated(theme.themeV2_draft, mode, variable, parentSlug, stateSlug)) {
      const updatedDraftTheme = cloneDeep(currentDraftTheme ?? theme.themeV2_draft);

      if (!!parentSlug && !!stateSlug) {
        stateSlug === 'default' || stateSlug === 'active'
          ? delete updatedDraftTheme?.[mode].component_overrides?.[parentSlug]?.[variable]
          : delete updatedDraftTheme?.[mode].component_overrides?.[parentSlug]?.[stateSlug]?.[variable];
      } else if (isCSSVar(variable)) {
        delete updatedDraftTheme?.[mode].var_overrides[variable];
      }

      updateTheme({ ...theme, themeV2_draft: updatedDraftTheme });
      return updatedDraftTheme;
    }
    return currentDraftTheme;
  };

  const revertAllVariables = (
    category: ThemeFieldGroup,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => {
    let updatedTheme: IThemeV2Type | undefined = currentDraftTheme ?? theme.themeV2_draft;
    category.children.forEach((child) => {
      if ('type' in child && child.type === 'animation' && child.widget) {
        const transitionPropertyVar = `--${child.widget}-anim-transition-property`;
        const transitionTimingVar = `--${child.widget}-anim-transition-timing`;
        const transitionDuration = `--${child.widget}-anim-transition-duration`;

        [transitionPropertyVar, transitionTimingVar, transitionDuration].forEach((cssVar) => {
          updatedTheme = revertVariable(cssVar, parentSlug, stateSlug, updatedTheme);
        });
      } else if ('variable' in child && child.variable) {
        updatedTheme = revertVariable(child.variable, parentSlug, stateSlug, updatedTheme);
      } else {
        // It's a ThemeFieldGroup, so we recursively revert its children's variables
        updatedTheme = revertAllVariables(child as ThemeFieldGroup, parentSlug, stateSlug, updatedTheme);
      }
    });
    return updatedTheme;
  };

  const handleUpdateName = (newName: string) => {
    if (!theme) return;

    const isBlankName = !newName.replace(/\s/g, '').length;

    const updatedTheme = {
      ...theme,
      name: isBlankName ? themeName : newName,
    };

    if (!isBlankName) setThemeName(newName);

    updateTheme(updatedTheme);
  };

  const handleSave = (overrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => {
    let updatedTheme = { ...theme };
    if (!theme || !theme.themeV2_draft) return;

    // in this case, this a component variable value we want to override
    if (!!parentSlug && !!stateSlug) {
      updatedTheme = {
        ...theme,
        themeV2_draft: {
          ...theme.themeV2_draft,
          [mode]: {
            ...theme.themeV2_draft[mode],
            component_overrides: {
              ...theme.themeV2_draft[mode].component_overrides,
              [parentSlug]: {
                ...theme.themeV2_draft[mode].component_overrides[parentSlug],
                ...(stateSlug === 'default' || stateSlug === 'active'
                  ? overrides
                  : {
                      [stateSlug]: {
                        ...theme.themeV2_draft[mode].component_overrides[parentSlug]?.[stateSlug],
                        ...overrides,
                      },
                    }),
              },
            },
          },
        },
      };
    } else {
      updatedTheme = {
        ...theme,
        themeV2_draft: {
          ...theme.themeV2_draft,
          [mode]: {
            ...theme.themeV2_draft[mode],
            var_overrides: {
              ...theme.themeV2_draft[mode].var_overrides,
              ...overrides,
            },
          },
        },
      };
    }

    updateTheme(updatedTheme);
  };

  const handlePublish = async () => {
    if (!theme) return;

    try {
      setIsSaving(true);

      const updatedTheme = {
        ...theme,
        themeV2: theme.themeV2_draft,
      };
      setTheme(updatedTheme);
      await save(updatedTheme);
    } catch (err) {
      reportErrorToUser(err);
    } finally {
      setIsSaving(false);
    }
  };

  const debouncedUpdateTheme = useMemo(
    () =>
      debounce(async (newTheme) => {
        setIsSaving(true);
        try {
          await save(newTheme);

          setHasUnsavedChanges(false);
        } catch (err) {
          reportErrorToUser(err);
        } finally {
          setIsSaving(false);
        }
      }, 800),
    [],
  );

  const updateTheme = (newTheme: IThemeType) => {
    setHasUnsavedChanges(true);
    if (!hasRequiredRole(user, 'contributor')) {
      return;
    }

    setTheme(newTheme);
    debouncedUpdateTheme(newTheme);
  };

  const nameWidth = theme.name ? 10 + 6.5 * theme.name.length : 80;

  const _editableVarsTree = !!flags['release-widget-animations']
    ? editableVarsTree
    : editableVarsTree.filter((category) => category.slug !== 'animations');

  return (
    <div>
      {currTab === 'base' && (
        <TableauWidgetSelector
          widgetSelection={widgetSelection}
          setWidgetSelection={(widgetTableauSelection) => {
            showWidgetTableauAndSetParams(true, widgetTableauSelection);
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          height: 48,
          padding: '8px 16px',
          display: 'flex',
          gap: '8px',
          background: '#ffffff',
          zIndex: Z.Z_EDITOR_OVERLAY,
        }}
      >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'start', gap: '8px', alignItems: 'center' }}>
          <IconContainer style={{ cursor: 'pointer' }} onClick={() => history.replace(THEME_ROUTE)}>
            <ReverseLeft />
          </IconContainer>
          {isEditingName ? (
            <CmdInput
              style={{ minWidth: '80px', width: `${nameWidth}px`, maxWidth: '280px' }}
              autoFocus
              onBlur={(e) => {
                setIsEditingName(false);
                handleUpdateName(e.target.value);
              }}
              onChange={(e) => {
                if (theme) {
                  setTheme({ ...theme, name: e.target.value });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
              value={theme.name}
            />
          ) : (
            <CmdLabel
              style={{
                fontWeight: 600,
                minWidth: '80px',
                maxWidth: '280px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              onClick={() => setIsEditingName(true)}
            >
              {theme.name}
            </CmdLabel>
          )}

          {theme.default && <CmdTag>Default</CmdTag>}
          <IconContainer>
            {hasUnsavedChanges ? (
              <LoadingSave />
            ) : (
              <CmdTooltip message="Changes saved">
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CheckCircle color="#797C85" />
                </span>
              </CmdTooltip>
            )}
          </IconContainer>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <CmdButtonTabs
            disabled={organizationSettings?.skins_field_set !== 'pro'}
            variant="group"
            key={currTab}
            activeKey={currTab}
            onChange={(e) => {
              if (e === 'base' || e === 'components') {
                history.replace(`${THEME_ROUTE}/${theme.id}/${e}`);
                setCurrTab(e);
              }
            }}
            tabs={[
              {
                label: (
                  <CmdLabel
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Globe01 />
                    Base
                  </CmdLabel>
                ),
                key: 'base',
              },
              {
                label:
                  organizationSettings?.skins_field_set === 'pro' ? (
                    <CmdLabel
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        gap: '8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Rows01 />
                      Components
                    </CmdLabel>
                  ) : (
                    <CmdTooltip message="Upgrade to access">
                      <CmdLabel
                        style={{
                          cursor: 'not-allowed',
                          display: 'flex',
                          gap: '8px',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Rows01 />
                        Components
                      </CmdLabel>
                    </CmdTooltip>
                  ),
                key: 'components',
              },
            ]}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', gap: '8px', alignItems: 'center' }}>
          <CmdButtonTabs
            variant="group"
            activeKey={mode}
            onChange={(e) => setMode(e as 'light_mode' | 'dark_mode')}
            tabs={[
              {
                label: <Sun />,
                key: 'light_mode',
              },
              {
                label: <Moon02 />,
                key: 'dark_mode',
              },
            ]}
          />
          <CmdButton
            variant="primary"
            icon={<Rocket01 />}
            onClick={() => handlePublish()}
            disabled={!hasUnpublishedChanges(theme) || isSaving || !canPublish}
          >
            Publish
          </CmdButton>
          <ThemeDropdown
            theme={theme}
            themes={themes}
            onSetDefault={() => setTheme((theme) => ({ ...theme, default: true }))}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: currTab === 'base' ? '0px 12px 12px' : '0px',
          gap: '12px',
          background: '#ffffff',
        }}
      >
        {currTab === 'base' ? (
          _editableVarsTree.map((category) => (
            <ThemeAccordion
              key={category.label + '-' + theme.slug}
              theme={theme}
              mode={mode}
              category={category}
              revertVariable={revertVariable}
              revertAllVariables={revertAllVariables}
              handleSave={handleSave}
              defaultValue="Brand"
            />
          ))
        ) : (
          <BindThemeVars themeV2={theme.themeV2_draft} mode={mode}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                background: '#F9F9F9',
                padding: '12px 16px',
                gap: '16px',
                width: '100%',
              }}
            >
              {editableComponentStylesTree.map((component) => (
                <ThemeComponentEditor
                  key={component.label + '-' + theme.slug}
                  theme={theme}
                  mode={mode}
                  component={component}
                  revertVariable={revertVariable}
                  revertAllVariables={revertAllVariables}
                  handleSave={handleSave}
                />
              ))}
            </div>
          </BindThemeVars>
        )}
      </div>
    </div>
  );
};

export default ThemeDetailContainer;
