import { useEffect, Dispatch, SetStateAction, useState, MouseEvent } from 'react';

import { Link04 } from '@commandbar/design-system/icons/react';

import { useIntegrationsContext } from '../../util/IntegrationContext';
import { ColumnTitle, LoadingState } from '../shared/styles';

import type { Source } from '../../integrations/shared/types';
import { type IEditorCommandType, type IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import ContentTable from '../shared/ContentTable';
import * as Organization from '@commandbar/internal/middleware/organization';
import FileEditDrawer from '../files/FileEditDrawer';
import { useHelpDocs } from '../shared/hooks';
import { useHistory } from 'react-router';
import useSourceState from './useSourceState';
import SourceProcessingProgress from './SourceProcessingProgress';
import { ColumnsType } from 'antd/lib/table';

interface HelpDocsTableProps {
  viewSelector?: JSX.Element;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  source: Source;
  syncsUpdated?: number;
  isEditingHelpDoc: boolean;
  setIsEditingHelpDoc: Dispatch<SetStateAction<boolean>>;
}

export type HelpDoc = IEditorCommandTypeLite & { template: { type: 'helpdoc' } };
export const isHelpDoc = (command: IEditorCommandTypeLite): command is HelpDoc => command.template.type === 'helpdoc';

const HelpDocsTable = ({
  viewSelector,
  source,
  syncsUpdated,
  isLoading,
  setIsLoading,
  isEditingHelpDoc,
  setIsEditingHelpDoc,
}: HelpDocsTableProps) => {
  const { refreshHelpDocIntegrations, organizationId } = useIntegrationsContext();
  const { progress, numFetched, numProcessed, ping, operation } = useSourceState(
    String(source?.id),
    refreshHelpDocIntegrations,
  );
  const [activeHelpDoc, setActiveHelpDoc] = useState<IEditorCommandType>();
  const [showAddContent, setShowAddContent] = useState(false);
  const { helpDocs } = useHelpDocs({ source });
  const history = useHistory();

  useEffect(() => {
    ping();
  }, [syncsUpdated]);

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(history.location.search);
      const commandId = searchParams.get('id');

      if (commandId && organizationId && helpDocs.length > 0) {
        try {
          const command = await Organization.getCommandById(organizationId.toString(), commandId);
          if (helpDocs.find((h) => h.id === command.id)) {
            setActiveHelpDoc(command);
            setShowAddContent(true);
            setIsEditingHelpDoc(true);
          }
        } catch (e) {
          // ntd on invalid command ID
        }
      }
    };

    fetchData();
  }, [history.location.search, helpDocs.length]);

  const columns: ColumnsType<HelpDoc> = [
    {
      title: 'Title',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
    },
    ...(source
      ? [
          {
            title: (
              <ColumnTitle>
                Source
                <Link04 />
              </ColumnTitle>
            ),
            dataIndex: ['template', 'value'],
            ellipsis: true,
            onCell: (record: HelpDoc) => {
              return {
                onClick: (e: MouseEvent) => {
                  e.stopPropagation();
                  window.open(record.template.value, '_blank');
                },
              };
            },
          },
        ]
      : []),
  ];

  if (isLoading) return <LoadingState />;

  if (operation) {
    return (
      <SourceProcessingProgress
        progress={progress}
        numFetched={numFetched}
        numProcessed={numProcessed}
        size="large"
        operation={operation}
      />
    );
  }

  return (
    <>
      <ContentTable
        viewSelector={viewSelector}
        columns={columns}
        data={helpDocs.sort((a, b) => ((a.modified || '') < (b.modified || '') ? 1 : -1))}
        emptyText="No articles yet, click the 'Sync Content' button above to run a sync now!"
        dataMappingFunction={(helpDocs) =>
          helpDocs.map((h) => {
            return {
              ...h,
            };
          })
        }
        setIsLoading={setIsLoading}
        openEditDrawer={async (id: number) => {
          if (organizationId) {
            Organization.getCommandById(organizationId.toString(), id.toString()).then((matchingCommand) => {
              setActiveHelpDoc(matchingCommand);
              setIsEditingHelpDoc(true);
              setShowAddContent(true);
            });
          }
        }}
      />
      <FileEditDrawer
        organizationId={organizationId}
        open={showAddContent}
        onClose={() => {
          setShowAddContent(false);
          setIsEditingHelpDoc(false);

          const searchParams = new URLSearchParams(history.location.search);
          searchParams.delete('id');
          history.replace(`${history.location.pathname}?${searchParams.toString()}`);
        }}
        activeFile={activeHelpDoc}
        isEditingHelpDoc={isEditingHelpDoc}
      />
    </>
  );
};

export default HelpDocsTable;
