import isEqual from 'lodash/isEqual';
import type { subscribe } from 'valtio';

export type Ops = Parameters<Parameters<typeof subscribe>[1]>[0];
export type Op = Ops[0];
export type OpType = Op[0];

/**
 * Use this function to help determine why a subscription function was invoked.
 *
 * This is particularly useful to avoid infinite loops when subscribing to an object and modifying a _subkey_ of that
 * object. Keep in mind that the path provided to the subscriber function is _relative_ to the object that's being
 * subscribed to.
 *
 * const _ = proxy({ bar: {}, foo: { a: 0, b: 0, sum: 0 }});
 * subscribe(_.foo, (ops) => {
 *   if (hasOp('set', ['sum'], ops)) return;
 *   _.foo.sum = _.foo.a + _.foo.b;
 * });
 * _.foo.a = 5;
 * _.foo.b = 1;
 * expect(_.foo.sum).toBe(6);
 */
export const hasOp = (opType: OpType, path: string[], ops: Ops) => {
  return ops.some((op) => op[0] === opType && isEqual(op[1], path));
};

export const findOp = (opType: OpType, path: string[], ops: Ops) => {
  return ops.find((op) => op[0] === opType && isEqual(op[1], path));
};

export const getPrevValue = <T>(op?: Op): T | undefined => {
  if (op === undefined) return undefined;
  if (op[0] === 'set') return op[3] as T;
  if (op[0] === 'delete') return op[2] as T;
};

export const hasSetOp = hasOp.bind(null, 'set');

export const hasMatchingOp = (dependencies: (string | symbol)[][] | '*', ops: Ops) =>
  dependencies === '*' ||
  ops.some(([_, updatePath]) =>
    dependencies.some((dep) => {
      const len = Math.min(dep.length, updatePath.length);
      for (let i = 0; i < len; i++) {
        if (dep[i] !== '*' && dep[i] !== updatePath[i]) {
          return false;
        }
      }
      return true;
    }),
  );
