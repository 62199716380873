import React from 'react';
import * as Organization from '@commandbar/internal/middleware/organization';
import * as Command from '@commandbar/internal/middleware/command';
import { SPOTLIGHT_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { PlusCircle, SearchMd } from '@commandbar/design-system/icons/react';
import {
  DASHBOARD_ACTION_ICON_WIDTH,
  DASHBOARD_ICON_WIDTH,
  DashboardCardBase,
} from '@commandbar/design-system/components/antd/DashboardCardBase';
import { IUserType } from '@commandbar/internal/middleware/types';

export const SpotlightCard = ({ user }: { user: IUserType }) => {
  const [hasCommands, setHasCommands] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchCommands = async () => {
      try {
        const commands = await Organization.listCommands(user.organization);
        setHasCommands(commands.filter((c) => !Command.isHelpSyncCommand(c)).length > 0);
      } catch (e) {
        /** do nothing */
      }
    };

    fetchCommands();
  }, [user.organization]);

  return (
    <DashboardCardBase
      styles={{
        iconShadow:
          ' 0px 5px 8px rgba(8, 1, 28, 0.08), 0px 1.5px 3px rgba(253, 87, 157, 0.16), inset 1.5px -1.5px 3px rgba(253, 87, 157, 0.25)',
        background: 'linear-gradient(99.43deg, #FFEBF3 6.02%, #FFFFFF 26.86%);',
      }}
      icon={<SearchMd width={DASHBOARD_ICON_WIDTH} color="#FD579D" />}
      title="Set up Spotlight"
      description="Make everything in your product searchable"
      actions={[
        {
          icon: <PlusCircle width={DASHBOARD_ACTION_ICON_WIDTH} />,
          label: hasCommands ? 'Configure Spotlight' : 'Set up Spotlight',
          to: `/editor${SPOTLIGHT_ROUTE}`,
        },
      ]}
    />
  );
};
