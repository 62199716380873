import React from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { ArrowLeft, ClockStopwatch } from '@commandbar/design-system/icons/react';
import { CodeInstructions } from './CodeInstructions';
import { NoCodeInstructions } from './NoCodeInstructions';
import { MainView } from './MainView';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { CmdTag } from '@commandbar/design-system/cmd';

export * from './InstallLink';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const InnerContainer = styled.div`
  margin-top: 48px;
  width: 100%;
  max-width: 572px;
  background-color: #fff;
`;

const BackContainer = styled.div`
  height: 32px;
  margin-bottom: 16px;
`;
const Back = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;

  width: 67px;
  height: 32px;

  background: #ffffff;
  border: 1px solid rgba(10, 10, 15, 0.24);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 2px 1px rgba(0, 0, 0, 0.08), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;

  cursor: pointer;

  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};

  &:hover {
    background: ${CB_COLORS.neutral100};
    color: ${CB_COLORS.neutral900};
    border: 1px solid ${CB_COLORS.neutral500};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  gap: 8px;
`;

const TitleText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;

  color: #000000;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstallGuide = () => {
  return (
    <Container>
      <InnerContainer>
        <Header>
          <BackContainer></BackContainer>
          <Title>
            <TitleText>Install CommandBar</TitleText>
            <CmdTag variant="info" prefixElement={<ClockStopwatch />}>
              {'10 minutes'.toUpperCase()}
            </CmdTag>
          </Title>
        </Header>
        <Body>
          <MainView />
        </Body>
      </InnerContainer>
    </Container>
  );
};

export const WebAppInstallGuide = ({ organizationUid }: { organizationUid: IOrganizationType['id'] }) => {
  const history = useHistory();

  return (
    <Container>
      <InnerContainer>
        <Header>
          <BackContainer>
            <Back onClick={() => history.push('/installation-guide')}>
              <ArrowLeft width={8} /> Back
            </Back>
          </BackContainer>
          <Title>
            <TitleText>Install CommandBar</TitleText>
            <CmdTag variant="info" prefixElement={<ClockStopwatch />}>
              {'10 minutes'.toUpperCase()}
            </CmdTag>
          </Title>
        </Header>
        <Body>
          <CodeInstructions organizationUid={organizationUid} />
        </Body>
      </InnerContainer>
    </Container>
  );
};

export const NoCodeInstallGuide = ({ organizationUid }: { organizationUid: IOrganizationType['id'] }) => {
  const history = useHistory();

  return (
    <Container>
      <InnerContainer>
        <Header>
          <BackContainer>
            <Back onClick={() => history.push('/installation-guide')}>
              <ArrowLeft width={8} /> Back
            </Back>
          </BackContainer>
          <Title>
            <TitleText>Install CommandBar</TitleText>
            <CmdTag variant="info" prefixElement={<ClockStopwatch />}>
              {'10 minutes'.toUpperCase()}
            </CmdTag>
          </Title>
        </Header>
        <Body>
          <NoCodeInstructions organizationUid={organizationUid} />
        </Body>
      </InnerContainer>
    </Container>
  );
};
