import { cva } from 'class-variance-authority';

export const checkboxStyles = cva(
  [
    'h-4 w-4 p-0 shrink-0 rounded-button border border-solid border-elementMid',
    'hover:border-elementTop',
    'focus:border-blue700 focus:shadow-input focus:rounded-sm',
    'data-[state=checked]:bg-blue700 data-[state=checked]:border-elementBase',
    'data-[state=unchecked]:bg-surfaceBase data-[state=unchecked]:border-elementMid',
    'cursor-pointer',
  ],
  {
    variants: {
      disabled: {
        true: [
          'bg-surfaceDisabled border-elementMid',
          'cursor-not-allowed',
          'hover:border-elementMid',
          '[>&svg]:fill-gray600 text-gray600',
          'data-[state=checked]:bg-surfaceTop',
          'data-[state=checked]:border-elementDisabled',
        ],
      },
      error: {
        true: [
          'bg-surfaceBase border-red800',
          'hover:border-red900',
          'focus:border-red900 focus:shadow-inputError focus:rounded-sm',
          '[>&svg]:fill-gray600 text-gray600',
          'data-[state=checked]:bg-red700',
          'data-[state=checked]:border-elementBase',
        ],
      },
    },
    compoundVariants: [],
    defaultVariants: {},
  },
);
