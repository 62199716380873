// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const MessageCircle01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21 11.5a8.5 8.5 0 0 1-11.555 7.934c-.173-.066-.26-.1-.329-.116a.901.901 0 0 0-.186-.024 2.313 2.313 0 0 0-.303.021l-5.121.53c-.489.05-.733.075-.877-.013a.5.5 0 0 1-.233-.35c-.026-.166.09-.382.324-.814l1.635-3.027c.135-.25.202-.374.233-.494a.899.899 0 0 0 .028-.326c-.01-.123-.064-.283-.173-.604A8.5 8.5 0 1 1 21 11.5Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 11.5a8.5 8.5 0 0 1-11.555 7.934c-.173-.066-.26-.1-.329-.116a.901.901 0 0 0-.186-.024 2.313 2.313 0 0 0-.303.021l-5.121.53c-.489.05-.733.075-.877-.013a.5.5 0 0 1-.233-.35c-.026-.166.09-.382.324-.814l1.635-3.027c.135-.25.202-.374.233-.494a.899.899 0 0 0 .028-.326c-.01-.123-.064-.283-.173-.604A8.5 8.5 0 1 1 21 11.5Z"
    />
  </svg>
);
export default MessageCircle01;
