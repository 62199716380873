/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/core';
import React, { type CSSProperties, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

import { BindThemeV2ForNudge, useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@commandbar/commandbar/shared/components/popover';
import type { PopoverService } from '@commandbar/commandbar/shared/components/popover/state';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import { ToastElementId } from '@commandbar/commandbar/shared/util/hooks/useToast';
import { toPx } from '@commandbar/internal/client/theme';
import StyledNudgeBeacon from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBeacon';
import type { INudgeTooltipStepType, INudgeType } from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';
import { DraftFooter } from 'shared/components/admin-facing/DraftFooter';
import { useLinkClickHandler } from '../../hooks/useLinkClickHandler';
import { getNudgeService, isNudgeSnoozable } from '../../store/selectors';
import ContentContainer from '../ContentContainer';
import { RenderMode } from '../RenderNudge';
import { ReactComponent as CloseIcon } from '../close_icon.svg';
import { Layout } from './Layout';
import { TestID } from './constants';
import { Icon } from './icons';
import { TooltipMachineContext, TooltipMachineContextProvider, stateSelectors } from './state';
import useStyles from './styles';
import {
  useAnimatedWidget,
  AnimatedWidget,
} from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';
import { SurveyResponseProvider } from '../SurveyResponseProvider';

/************************************
 * Body Components
 ************************************/

interface HeaderProps {
  title: INudgeTooltipStepType['title'];
}

const Header = ({ title }: HeaderProps) => {
  const styles = useStyles();

  return (
    <div css={styles.header}>
      <span aria-level={3} css={styles.title} id="commandbar-nudge-title" role="heading">
        {title}
      </span>
      <PopoverClose data-testid={TestID.Close}>
        <CloseIcon css={styles.closeButton} />
      </PopoverClose>
    </div>
  );
};

/************************************
 * Markers
 ************************************/

const BeaconMarker = () => {
  const styles = useStyles();

  return (
    <div css={styles.beaconOuter}>
      <div css={styles.beaconHoverTarget} />
      <div css={styles.beaconInner} />
    </div>
  );
};

type ImageMarkerTooltip = INudgeTooltipStepType & { form_factor: { marker: { type: 'image' } } };

interface ImageProps {
  source: ImageMarkerTooltip['form_factor']['marker']['source'];
}

const ImageMarker = ({ source }: ImageProps) => {
  const styles = useStyles();
  const isSvg = source.startsWith('<svg');

  return (
    <div css={styles.imageHoverTarget}>
      {isSvg ? (
        <div css={styles.image} dangerouslySetInnerHTML={{ __html: source }} />
      ) : (
        <img alt="Tooltip marker" css={styles.image} src={source} />
      )}
    </div>
  );
};

export type IconMarkerTooltip = INudgeTooltipStepType & { form_factor: { marker: { type: 'icon' } } };

interface IconProps {
  type: IconMarkerTooltip['form_factor']['marker']['icon'];
}

const IconMarker = ({ type }: IconProps) => {
  const styles = useStyles();

  return (
    <div css={styles.iconHoverTarget}>
      <Icon type={type} />
    </div>
  );
};

interface MarkerProps {
  marker: INudgeTooltipStepType['form_factor']['marker'];
}

const Marker = ({ marker }: MarkerProps) => {
  const { flags } = useStore();
  const themeV2 = useThemeV2Context();

  switch (marker.type) {
    case 'image': {
      if (marker.source) {
        return <ImageMarker source={marker.source} />;
      }

      return null;
    }

    case 'icon':
      return <IconMarker type={marker.icon} />;
    case 'beacon':
      return flags?.['release-themes-v2'] ? <StyledNudgeBeacon themeV2={themeV2} /> : <BeaconMarker />;
    default:
      return null;
  }
};

/************************************
 * Trigger
 ************************************/

interface TriggerProps {
  step: INudgeTooltipStepType;
}

const Trigger = ({ step }: TriggerProps) => {
  const editorWidth = Number(LocalStorage.get('width', '770'));
  const isMarkerPositioned = TooltipMachineContext.useSelector(stateSelectors.isMarkerPositioned);

  const centeredTriggerStyles: CSSProperties = {
    visibility: isMarkerPositioned ? 'visible' : 'hidden',
    ...(isStudioPreview()
      ? {
          top: 'calc(40vh - 51px)',
          right: 'calc(-50vw + 24px)',
        }
      : {
          top: '40vh',
          left: `calc((100vw - ${editorWidth}px) / 2)`,
          transform: 'translate(-50%, -50%)',
        }),
  };

  const top = TooltipMachineContext.useSelector(({ context }) => context.marker.position?.y);
  const left = TooltipMachineContext.useSelector(({ context }) => context.marker.position?.x);

  const floatingTriggerStyles: CSSProperties = {
    visibility: isMarkerPositioned ? 'visible' : 'hidden',
    left,
    top,
  };

  const inlinedTriggerStyles: CSSProperties = {
    visibility: 'visible',
  };

  const isMarkerInlined = TooltipMachineContext.useSelector(stateSelectors.isMarkerInlined);
  const isCentered = TooltipMachineContext.useSelector(stateSelectors.isMarkerCentered);

  const getTriggerStyles = (): CSSProperties => {
    if (isMarkerInlined) {
      return inlinedTriggerStyles;
    }

    if (isCentered) {
      return centeredTriggerStyles;
    }

    return floatingTriggerStyles;
  };

  const ref = TooltipMachineContext.useSelector(({ context }) => context.marker.ref);

  return (
    <PopoverTrigger
      ref={ref}
      data-testid={TestID.Marker}
      style={{
        ...getTriggerStyles(),
      }}
    >
      <Marker marker={step.form_factor.marker} />
    </PopoverTrigger>
  );
};

/************************************
 * Main
 ************************************/

interface TooltipComponentProps {
  nudge: INudgeType;
  step: INudgeTooltipStepType;
  popoverService: PopoverService;
}

const TooltipComponent = ({ nudge, step, popoverService }: TooltipComponentProps) => {
  const { theme } = useTheme();

  const styles = useStyles();
  const { onExit } = useAnimatedWidget();

  const _ = useStore();
  const renderMode = TooltipMachineContext.useSelector(({ context }) => context.renderMode);
  const nudgeService = renderMode !== RenderMode.MOCK ? getNudgeService(_, nudge.id) : undefined;
  const stepIndex = nudgeService?.getSnapshot()?.context.stepIndex ?? 0;

  const close = useCallback(
    () => onExit(() => nudgeService?.getSnapshot()?.context.popoverService?.send('CLOSE')),
    [nudgeService?.getSnapshot, onExit],
  );
  const handleLinkClick = useLinkClickHandler(close);

  const stepCount =
    nudge.show_step_counter && stepIndex !== undefined && nudge.steps.length > 1
      ? `${stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  const { isMobileDevice } = useMobileExperience();
  const shouldUseAutoWidth =
    !isMobileDevice && step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10);

  const isMarkerInlined = TooltipMachineContext.useSelector(stateSelectors.isMarkerInlined);

  useEffect(() => {
    nudgeService?.send({ type: 'ASSIGN_POPOVER_SERVICE', service: popoverService });
  }, [popoverService, nudgeService]);

  useEffect(() => {
    // INFO: if editing a tooltip, make sure it's open so changes can be seen
    if (renderMode === RenderMode.MOCK) {
      popoverService.send('OPEN');
    }
  }, [popoverService.send, renderMode, step]);

  return (
    <>
      <Trigger step={step} />

      <PopoverContent
        css={!_.flags?.['release-themes-v2'] && styles.container}
        data-testid={TestID.Content}
        describedBy="commandbar-nudge-tooltip-content"
        labelledBy="commandbar-nudge-title"
        style={{
          ...(!_.flags?.['release-themes-v2'] && {
            width: shouldUseAutoWidth ? 'auto' : theme.nudgeTooltip.width,
          }),
        }}
        portalToId={isMarkerInlined ? ToastElementId.TOAST_CONTAINER : undefined}
        shouldStealFocus={renderMode !== RenderMode.MOCK}
      >
        <SurveyResponseProvider service={nudgeService} step={step}>
          {_.flags?.['release-themes-v2'] ? (
            <Layout
              nudge={nudge}
              step={step}
              renderMode={renderMode}
              stepCount={stepCount}
              stepIndex={stepIndex}
              handleLinkClick={handleLinkClick}
            />
          ) : (
            <div css={styles.body}>
              <Header title={step.title} />
              <ContentContainer
                markdownStyles={styles.content as CSSProperties}
                primaryButtonStyles={styles.ctaButton as CSSProperties}
                renderMode={renderMode}
                secondaryButtonStyles={styles.ctaSecondaryButton as CSSProperties}
                service={nudgeService}
                snoozable={isNudgeSnoozable(nudge)}
                snoozeButtonStyles={styles.snoozeButton as CSSProperties}
                snoozeLabel={nudge.snooze_label}
                step={step}
                stepCount={stepCount}
                stepCountStyles={styles.stepCount as CSSProperties}
                handleContentLinkClick={handleLinkClick}
                stepIndex={stepIndex}
                snoozable_on_all_steps={nudge.snoozable_on_all_steps}
              />
              {!nudge.is_live && renderMode === RenderMode.MOCK && (
                <DraftFooter details={{ type: 'nudge', nudge, stepIndex }} />
              )}
            </div>
          )}
        </SurveyResponseProvider>
      </PopoverContent>
    </>
  );
};

interface TooltipProviderProps {
  nudge: INudgeType;
  step: INudgeTooltipStepType;
  forceOpen?: boolean;
  autoPlacement?: boolean;
}

const TooltipProvider = ({ nudge, step, forceOpen = false, autoPlacement = true }: TooltipProviderProps) => {
  const renderMode = TooltipMachineContext.useSelector(({ context }) => context.renderMode);
  const isIdle = TooltipMachineContext.useSelector(stateSelectors.isIdle);
  const isMarkerInlined = TooltipMachineContext.useSelector(stateSelectors.isMarkerInlined);
  const wrapperElement = TooltipMachineContext.useSelector(({ context }) => context.inlineWrapperElement);
  const offset = TooltipMachineContext.useSelector(({ context }) => context.marker.offset);
  const { generatedCSSClassname } = useThemeV2Context();

  if (isIdle) {
    return null;
  }

  const [offsetX, offsetY] = [calcOffsetFromInput(offset.x), calcOffsetFromInput(offset.y)];
  const popoverStyle: CSSProperties = isMarkerInlined
    ? {
        display: 'flex',
        position: 'relative',
        [step.form_factor.marker.positioning.position === 'inline_left' ? 'marginRight' : 'marginLeft']: toPx(offsetX),
        marginTop: toPx(offsetY),
        zIndex: 'auto',
      }
    : {};

  const Comp = (
    <Popover
      data-testid={`commandbar-tooltip-${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`}
      forceOpen={forceOpen}
      defaultOpen={renderMode === RenderMode.MOCK}
      triggerType={step.form_factor.show_on}
      style={popoverStyle}
      className={generatedCSSClassname}
      autoPlacement={autoPlacement}
    >
      {(popoverService) => <TooltipComponent nudge={nudge} step={step} popoverService={popoverService} />}
    </Popover>
  );

  return isMarkerInlined && wrapperElement ? createPortal(Comp, wrapperElement) : Comp;
};

interface TooltipProps {
  nudge: INudgeType;
  step: INudgeTooltipStepType;
  renderMode: RenderMode;
  handleDestroy?: () => void;
  forceOpen?: boolean;
  anchorOverride?: string;
  autoPlacement?: boolean;
}

export const Tooltip = ({
  nudge,
  step,
  renderMode,
  forceOpen,
  anchorOverride,
  handleDestroy,
  autoPlacement,
}: TooltipProps) => (
  <BindThemeV2ForNudge nudge={nudge}>
    <AnimatedWidget widget="tooltip" keepMounted isOpenByDefault={false}>
      <TooltipMachineContextProvider
        offset={step.form_factor.marker.positioning.offset}
        renderMode={renderMode}
        alignment={step.form_factor.marker.positioning.position}
        anchor={anchorOverride ?? step.form_factor.anchor_selector ?? step.form_factor.anchor}
        handleDestroy={handleDestroy}
      >
        <TooltipProvider autoPlacement={autoPlacement} forceOpen={forceOpen} nudge={nudge} step={step} />
      </TooltipMachineContextProvider>
    </AnimatedWidget>
  </BindThemeV2ForNudge>
);
