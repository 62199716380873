import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { TNudgeOffset } from '../metadata';
import * as S from './styles';
import { INudgeStepType } from '@commandbar/internal/middleware/types';
import { CmdInput } from '@commandbar/design-system/cmd';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const Label = styled.div`
  color: ${CB_COLORS.neutral500};
`;

interface IPositionOffsetForm {
  onChange: (offset: TNudgeOffset) => void;
  x: string;
  y: string;
  type: INudgeStepType['form_factor']['type'];
}

export const PositionOffsetForm = ({ x, y, onChange, type }: IPositionOffsetForm) => {
  const preventEventBubbling = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Container onClick={preventEventBubbling}>
      <S.SectionHeader>{`${type === 'tooltip' ? 'Marker' : ''} Offset`}</S.SectionHeader>
      <S.Row style={{ gap: '8px' }}>
        <CmdInput
          prefixElement={<Label>X</Label>}
          suffixElement={<Label>px</Label>}
          type="number"
          placeholder="0"
          value={calcOffsetFromInput(x)}
          onChange={(e) => {
            preventEventBubbling(e);
            onChange({
              x: e.target.value,
              y,
            });
          }}
          className="w-full"
        />
        <CmdInput
          prefixElement={<Label>Y</Label>}
          suffixElement={<Label>px</Label>}
          type="number"
          placeholder="0"
          value={calcOffsetFromInput(y)}
          onChange={(e) => {
            preventEventBubbling(e);
            onChange({
              y: e.target.value,
              x,
            });
          }}
          className="w-full"
        />
      </S.Row>
    </Container>
  );
};
