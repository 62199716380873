import React from 'react';
import { useHistory } from 'react-router-dom';

import { PaddingContainer } from '@commandbar/design-system/components/antd/styledComponents';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { useAppContext } from 'editor/src/AppStateContext';
import WidgetTemplate from '../components/templates/WidgetTemplate';
import { disableSearchIfNotExperienceSearch } from '../utils/nudge';
import { nudgeTypeDisplay } from './NudgeList';
import * as templates from './nudge_templates';
import { getTemplateCards } from './templates';

export const useCreateFromTemplate = (
  type: NonNullable<INudgeType['type']>,
  options = { rebaseHistoryToNudgeRoute: false },
) => {
  const { flags, organization } = useAppContext();
  const history = useHistory();

  return (nudge?: INudgeType) => {
    if (!organization) {
      return;
    }

    const newNudge = disableSearchIfNotExperienceSearch(nudge ?? templates.emptyNudge(type), flags);
    if (options.rebaseHistoryToNudgeRoute) {
      history.replace(`${getNudgeRoute(newNudge)}`);
    }
    history.push(`${getNudgeRoute(newNudge)}/${newNudge.id}`, newNudge);
  };
};

interface NewNudgeMenuProps {
  type: NonNullable<INudgeType['type']>;
}

export const NewNudgeMenu = ({ type }: NewNudgeMenuProps) => {
  const history = useHistory();
  const createFromTemplate = useCreateFromTemplate(type);

  return (
    <PaddingContainer>
      <WidgetTemplate
        type={type}
        staticTemplates={getTemplateCards(type)}
        header={`New ${nudgeTypeDisplay(type)}`}
        onBack={() => {
          const queryParams = new URLSearchParams(window.location.search);
          const from = queryParams.get(nudgeSearchParams.FROM);

          if (from) {
            // INFO: we can link to the template page from outside the editor router (eg: /analytics)
            // In that case, we will not have a history in our own router and will need to utilize the window
            // location to go back to that original page
            window.location.assign(from);
          } else {
            history.goBack();
          }
        }}
        createFromTemplate={createFromTemplate}
      />
    </PaddingContainer>
  );
};
