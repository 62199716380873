import React from 'react';
import TextCustomizationInput from '../TextCustomizationInput';
import styled from '@emotion/styled';

const SettingsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const TextCustomizationSettings = () => {
  return (
    <SettingsBlock>
      <TextCustomizationInput msgid="helphub.title" label="Title" />
      <TextCustomizationInput msgid="helphub.search-input-placeholder" label="Search input placeholder text" />
      <TextCustomizationInput msgid="helphub.ask-ai-button-label" label="Ask AI button label" />
      <TextCustomizationInput msgid="helphub.helphub-search-button-label" label="Search button label" />
      <TextCustomizationInput msgid="helphub.featured-content-label" label="Featured content label" />
      <TextCustomizationInput msgid="helphub.additional-resources-label" label="Additional resources label" />
      <TextCustomizationInput msgid="helphub.view-article-button-label" label="View article button label" />
    </SettingsBlock>
  );
};

export default TextCustomizationSettings;
