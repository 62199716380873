import { useCallback } from 'react';

import Logger from '@commandbar/internal/util/Logger';
import { HelpDocsSync } from '@commandbar/internal/middleware/helpDocsSync';

import { useIntegrationsContext } from '../../util/IntegrationContext';
import { onIntegrationError } from '../../integrations/shared/utils';

import type { IntegrationSpec } from '../../integrations/shared/types';
import { Exception } from '@sentry/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { cmdToast } from '@commandbar/design-system/cmd';

export const useRunSync = (
  isInitialSync: boolean,
  selectedIntegration: IntegrationSpec | undefined,
  sourceId: number | undefined,
) => {
  const { addLoadingSource, removeLoadingSource } = useIntegrationsContext();
  const { organizationSettings } = useAppContext();

  const runSync = useCallback(() => {
    if (!isInitialSync && organizationSettings?.help_center_sync === 'upload-once') {
      return;
    }
    cmdToast.info('Running sync: this may take a few minutes.');
    if (selectedIntegration?.sources) {
      const newSourceId = sourceId ?? selectedIntegration.sources[selectedIntegration.sources.length - 1].id;
      addLoadingSource(newSourceId);

      HelpDocsSync.create({
        id: -1, // filler value.
        integration: newSourceId,
        scheduled: false,
      }).catch((err) => {
        Logger.error(err);
        onIntegrationError(err as Exception, selectedIntegration.slug);
      });
      removeLoadingSource(newSourceId);
    } else {
      onIntegrationError('Run sync failed: no sources', selectedIntegration?.slug);
    }
  }, [sourceId, selectedIntegration]);

  return runSync;
};
