import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';

const Modal = styled.div`
  position: fixed;
  display: flex;
  width: var(--helphub-width);
  height: var(--helphub-height);
  max-height: 100vh;
  right: 12px;
  bottom: 12px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  border-radius: var(--layout-radius-card);
  border: 1px solid var(--border-primary);
  background: var(--background-primary);

  box-shadow: var(--layout-shadow) var(--layout-shadow-color);

  z-index: ${Z.Z_HELPHUB};
`;

type AssistanceModalProps = {
  modalRef: React.MutableRefObject<HTMLDivElement | null>;
  parentCallbackRef?: (value: React.SetStateAction<HTMLDivElement | null>) => void;
};

const StyledAssistanceModal = ({
  children,
  modalRef,
  parentCallbackRef,
  ...props
}: AssistanceModalProps & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Modal
      ref={(node) => {
        modalRef.current = node;
        if (parentCallbackRef) parentCallbackRef(node);
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default StyledAssistanceModal;
