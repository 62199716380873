/*******************************************************************************/
/* Imports
/*******************************************************************************/

import * as t from 'io-ts';

import {
  createObject,
  updateObject,
  deleteObject,
  decodeThrowing,
  decodeToPromise,
  GenericBatchRequest,
} from './generics';
import * as axiosInstance from './network';
import { OptionGroupRenderAsV } from './helpers/optionGroup';

const CommandCategoryBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.union([t.number, t.string]),
      name: t.string,
    }),
    t.partial({}),
  ],
  'CommandCategoryBase',
);

const CommandCategoryAdditionalV = t.type(
  {
    sort_key: t.union([t.number, t.null]),
    icon: t.union([t.string, t.null]),
    icon_color: t.union([t.string, t.null]),
    image_color: t.union([t.string, t.null]),
    image: t.union([t.string, t.null]),
    render_as: OptionGroupRenderAsV,
    setting_hide_before_search: t.boolean,
    setting_max_options_count: t.union([t.number, t.null]),
    setting_pin_to_bottom: t.boolean,
    search_tab_enabled: t.boolean,
    search_tab_name: t.string,
    slash_filter_enabled: t.boolean,
    slash_filter_keyword: t.union([t.string, t.null]),
    search_tab_instruction: t.string,
    track_recents: t.boolean,
  },
  'CommandCategoryAdditional',
);

const defaults: t.TypeOf<typeof CommandCategoryAdditionalV> = {
  sort_key: null,
  icon: null,
  icon_color: null,
  image_color: null,
  image: null,
  render_as: 'list',
  setting_hide_before_search: false,
  setting_max_options_count: null,
  setting_pin_to_bottom: false,
  search_tab_enabled: false,
  search_tab_name: '',
  slash_filter_enabled: true,
  slash_filter_keyword: null,
  search_tab_instruction: '',
  track_recents: false,
};

const CommandCategoryConfigAdditionalV = t.partial({
  contains_hotloaded_commands: t.boolean,
});

export const CommandCategoryV = t.intersection(
  [CommandCategoryBaseV, CommandCategoryAdditionalV, CommandCategoryConfigAdditionalV],
  'CommandCategory',
);

export const BatchEditorCategoryResponseV = t.type({
  batch: t.array(CommandCategoryV),
});

export class CommandCategory {
  public static create = createObject(CommandCategoryV, CommandCategoryV, 'categories');
  public static update = updateObject(CommandCategoryV, CommandCategoryV, 'categories');
  public static delete = deleteObject(CommandCategoryV, 'categories');

  public static decode = (data: any) => decodeThrowing(CommandCategoryV, { ...defaults, ...data });

  public static batch = async (request: t.TypeOf<typeof GenericBatchRequest>) => {
    const result = await axiosInstance.post('categories/batch/', request);

    return await decodeToPromise(BatchEditorCategoryResponseV, result.data);
  };
}
