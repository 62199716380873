import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { cn } from '../util';
import { switchStyles } from './styles';
import { CmdLabel } from '../typography';

const CmdSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    onLabel?: React.ReactNode;
    offLabel?: React.ReactNode;
    tooltip?: React.ReactElement | string;
    onChange?: (checked: boolean) => void;
  }
>(({ className, disabled = false, onLabel, offLabel, tooltip, ...props }, ref) => (
  <div className="flex items-center gap-sm">
    <SwitchPrimitives.Root
      className={cn(switchStyles({ disabled: disabled }), className)}
      disabled={disabled}
      {...props}
      ref={ref}
      onCheckedChange={props.onCheckedChange ?? props.onChange}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'h-[14px] w-[14px] rounded-full bg-gray100 shadow-sm transform transition-transform data-[state=checked]:translate-x-lg data-[state=unchecked]:translate-x-[0px]',
          { 'bg-contentDisabled': disabled, 'cursor-not-allowed': disabled },
        )}
      />
    </SwitchPrimitives.Root>
    {onLabel || offLabel ? (
      <CmdLabel disabled={disabled} tooltip={tooltip}>
        {props.checked ? onLabel : offLabel}
      </CmdLabel>
    ) : null}
  </div>
));

CmdSwitch.displayName = 'CmdSwitch';

export { CmdSwitch };
