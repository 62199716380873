import { initDashboardStep } from './step-utils/DashboardStep';
import { Step, DashboardStep } from './step-utils';

export const getDashboardStepBreadcrumb = (s: DashboardStep): string | undefined => {
  if (!!s.argument.label) {
    return s.argument.label;
  }

  if (s.argument.type === 'video') {
    return 'Watch Video';
  }
  if (s.argument.type === 'html') {
    return 'Read Document';
  }

  return s.argument.label || 'Watch Video';
};

export const selectDashboardStep = (s: DashboardStep): Step => {
  const newStep = initDashboardStep(s.argument);
  newStep.completed = s.completed;
  return newStep;
};
