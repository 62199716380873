/** @jsx jsx  */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { emptyGlobalStore } from 'shared/store/global-store';
import { useStore } from 'shared/util/hooks/useStore';
import { AnimationTransition, builtinKeyframes } from 'shared/util/hooks/useDelayUnmount';
import {
  IAIMessageType,
  IChecklist,
  ICommandType,
  IEditorCommandType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import * as GlobalActions from 'shared/store/global-actions';
import * as HelpHubServiceActions from 'products/helphub/service-actions';
import { useAction } from 'shared/util/hooks/useAction';
import { useStyles } from 'products/helphub/components/useStyles';

import { MessageActions } from './MessageActions';
import FeedbackButtons from './FeedbackButtons';
import { Continuations } from '../Continuations';
import { BotMessageText } from './BotMessageText';
import { ExperienceButton } from './ExperienceButton';
import ActionButton from 'shared/components/ActionButton';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { isAction } from '@cb/types/entities/command/actions';
import { GlowBorder } from 'products/helphub/components/GlowBorder';
import { useChatState } from '../../store/useChatState';
import StyledChatContent from '@commandbar/internal/client/themesV2/components/copilot/StyledChatContent';
import { Check } from '@commandbar/design-system/icons/react';
import StyledButtonGroup from '@commandbar/internal/client/themesV2/components/shared/StyledButtonGroup';
import StyledChatActionCard from '@commandbar/internal/client/themesV2/components/copilot/StyledChatActionCard';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import { getSentry } from '@commandbar/internal/util/sentry';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import { Checklist } from '@commandbar/internal/middleware/checklist';
import * as Command from '@commandbar/internal/middleware/command';
import { getNudgeById } from '../../../nudges/service-selectors';
import { passesAudienceConditions } from '@commandbar/commandbar/shared/services/targeting/audience';
import { getChecklistById } from '../../../checklists/service-selectors';
import { getCommandById } from '@commandbar/commandbar/shared/store/global-selectors';
import { isCommandAvailable } from '@commandbar/commandbar/shared/services/targeting/command-targeting';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import MessageExtra from './MessageExtra';
import BotMessageFooter from './BotMessageFooter';
import StyledChatMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledChatMessage';

export type BotMessageProps = {
  preview: boolean;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  showContinuations: boolean;
  previousContinuations: Set<string>;
  message: IAIMessageType;
  isInitialBotMessage?: boolean;
  sendChat: (message: string) => void;
  isChatHistoryPreview: boolean;
  isFinishedStreaming: boolean;
  onFinishStreaming: () => void;
  previewOverrides?: { useThemeV2: boolean };
};

export const BotMessage: React.FC<BotMessageProps> = (props) => {
  const {
    preview,
    isFirstMessage,
    isLastMessage,
    message,
    isInitialBotMessage,
    previousContinuations,
    isChatHistoryPreview,
    isFinishedStreaming,
    onFinishStreaming,
    previewOverrides,
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();

  const aiAnswerValue = message.value;

  const styles = useStyles(preview);
  const themeV2 = useThemeV2Context();
  const { isCollectingArgumentValues, abortArgumentCollection } = useChatState();

  const executeAction = useAction(GlobalActions.executeAction);
  const toggleHelpHubVisible = useAction(HelpHubServiceActions.toggleHelpHubVisible);

  const isLoading = message.incomplete;
  const searchSuggestions = _.helpHub.searchSuggestions;
  const defaultInitialContinuations = !searchSuggestions.loading ? searchSuggestions.continuations : [];
  const continuations = isInitialBotMessage
    ? defaultInitialContinuations
    : (message.continuations || []).filter((continuation) => !previousContinuations.has(continuation));

  const unmounted = React.useRef(false);
  const disabled = preview || isChatHistoryPreview;

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const isAnswerEmpty = React.useMemo(() => {
    const strippedAnswer =
      aiAnswerValue.answer
        ?.replace(/<\/?[^>]+(>|$)/g, '') // empty HTML tags
        .replace(/[\n\r]/g, '') // newlines
        .trim() || '';
    return strippedAnswer.length === 0;
  }, [aiAnswerValue.answer]);

  const experiences = aiAnswerValue.experiences || null;

  const [showableExperience, setShowableExperience] = React.useState<
    | { type: 'nudge'; nudge: INudgeType; description?: string }
    | { type: 'checklist'; checklist: IChecklist; description?: string }
    | { type: 'command'; command: ICommandType | IEditorCommandType; description?: string }
    | null
  >(null);

  React.useEffect(() => {
    if (!experiences?.length) return;
    const experience = experiences[0];

    // don't show experience if it's the same as a next step that is shown as part of <MessageActions>
    if (experience?.type === 'command') {
      if (
        aiAnswerValue.doc?.command.next_steps.some((nextStep) => {
          let commandId: string | null = null;

          if (isAction(nextStep)) {
            if (nextStep.action.type === 'execute_command') commandId = nextStep.action.meta.command;
          } else {
            commandId = `${nextStep}`;
          }

          return commandId === `${experience.value}`;
        })
      ) {
        return;
      }
    } else if (experience?.type === 'nudge') {
      if (
        aiAnswerValue.doc?.command.next_steps.some((nextStep) => {
          if (isAction(nextStep) && nextStep.action.type === 'nudge' && nextStep.action.value === experience.value) {
            return true;
          } else {
            return false;
          }
        })
      ) {
        return;
      }
    } else if (experience?.type === 'questlist') {
      if (
        aiAnswerValue.doc?.command.next_steps.some((nextStep) => {
          if (
            isAction(nextStep) &&
            nextStep.action.type === 'questlist' &&
            nextStep.action.value === experience.value
          ) {
            return true;
          } else {
            return false;
          }
        })
      ) {
        return;
      }
    }

    try {
      if (disabled) {
        switch (experience.type) {
          case 'nudge':
            Nudge.read(experience.value.toString()).then((nudge) =>
              setShowableExperience({ type: 'nudge', nudge, description: experience.description }),
            );
            break;
          case 'questlist':
            Checklist.read(experience.value.toString()).then((checklist) =>
              setShowableExperience({ type: 'checklist', checklist, description: experience.description }),
            );
            break;
          case 'command':
            Command.get(experience.value.toString()).then((command) =>
              setShowableExperience({ type: 'command', command, description: experience.description }),
            );
            break;
        }
      } else {
        switch (experience.type) {
          case 'nudge':
            const nudge = getNudgeById(_, experience.value);
            if (nudge && passesAudienceConditions(_, 'nudges', nudge)) {
              setShowableExperience({ type: 'nudge', nudge, description: experience.description });
            }
            break;
          case 'questlist':
            const checklist = getChecklistById(_, experience.value);
            if (checklist && passesAudienceConditions(_, 'checklists', checklist)) {
              setShowableExperience({ type: 'checklist', checklist, description: experience.description });
            }
            break;
          case 'command':
            const command = getCommandById(_, experience.value);
            if (command && isCommandAvailable(command, _).isAvailable) {
              setShowableExperience({ type: 'command', command, description: experience.description });
            }
            break;
        }
      }
    } catch (e) {
      getSentry()?.captureException(e);
    }
  }, [aiAnswerValue.doc?.command.next_steps, experiences, isLoading]);

  const showExperiencesOnly = message?.no_answer && (isLoading || !!showableExperience || message.extras.length > 0);

  const showDocBasedAnswer = !isAnswerEmpty && !showExperiencesOnly;

  // Check if any extras have a blank action
  const hasReplyExtra = message.extras.some((extra) => !extra.action);

  const isArgumentCollectionFinalStep = !!message.value.copilot_argument_values;
  const commandToExecute = message.value.copilot_command;
  const showFeedbackButtons = !isFirstMessage && !isLoading && !hasReplyExtra && isFinishedStreaming;
  const nextSteps = message.value.source_docs.map((doc) => doc?.command.next_steps).flatMap((next_steps) => next_steps);
  return (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'] ? (
    <StyledChatContent isLoading={isLoading || !isFinishedStreaming} isBotMessage>
      {/* this is the pulsing "..." */}
      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={isLoading && !showDocBasedAnswer && !showableExperience}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            padding: '8px 0px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              css={css`
                animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

                @keyframes pulse {
                  0%,
                  100% {
                    opacity: 1;
                  }
                  50% {
                    opacity: 0.5;
                  }
                }
              `}
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: CB_COLORS.neutral600,
                animationDelay: `${index * 0.25}s`,
              }}
            />
          ))}
        </div>
      </AnimationTransition>

      <StyledChatMessage
        isLoading={isLoading || !isFinishedStreaming}
        isBotMessage
        timestamp={isLoading || !isFinishedStreaming ? undefined : message.modified}
        isMounted={showDocBasedAnswer}
      >
        <GlowBorder showGlowBorder={isArgumentCollectionFinalStep}>
          <AnimationTransition
            style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}
            isMounted={showDocBasedAnswer}
          >
            <BotMessageText
              preview={preview}
              isLoading={isLoading}
              isFinishedStreaming={isFinishedStreaming}
              onFinishStreaming={onFinishStreaming}
              answer={aiAnswerValue}
              id={message.uuid ?? ''}
              previewOverrides={previewOverrides}
            />
          </AnimationTransition>
          <AnimationTransition
            style={{ width: '100%', display: 'flex' }}
            isMounted={(message.value.source_docs.length > 0 && showDocBasedAnswer && isFinishedStreaming) || preview}
          >
            <BotMessageFooter preview={preview} message={message} />
          </AnimationTransition>
          {showFeedbackButtons && (
            <FeedbackButtons
              preview={preview}
              isChatHistoryPreview={isChatHistoryPreview}
              message={message}
              previewOverrides={previewOverrides}
            />
          )}
        </GlowBorder>
      </StyledChatMessage>

      <AnimationTransition
        style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={
          !isLoading &&
          (message.fallback_labeled_actions.length > 0 || message.extras.length > 0 || nextSteps.length > 0)
        }
      >
        {!isLoading && isFinishedStreaming && (
          <MessageActions
            preview={preview}
            isChatHistoryPreview={isChatHistoryPreview}
            aiMessage={message}
            fallbackActions={message.fallback_labeled_actions}
            previewOverrides={previewOverrides}
            nextSteps={nextSteps}
          />
        )}
        {message.extras.length > 0 && !isLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            {message.extras.map((extra, idx) => {
              // Don't show extras that are already in next steps
              const extraInNextSteps = nextSteps.some((step) => isAction(step) && step.action === extra.action);
              if (extraInNextSteps) {
                return null;
              }

              return (
                <MessageExtra
                  key={idx}
                  idx={idx}
                  extra={extra}
                  totalExtras={message.extras.length}
                  isLastMessage={isLastMessage}
                  executeAction={executeAction}
                  sendChat={props.sendChat}
                  preview={preview}
                  isChatHistoryPreview={isChatHistoryPreview}
                  previewOverrides={previewOverrides}
                />
              );
            })}
          </div>
        )}
      </AnimationTransition>

      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={!!showableExperience}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginTop: showDocBasedAnswer && showableExperience?.description ? '12px' : '0px',
          marginBottom: showableExperience?.description ? '0px' : '12px',
        }}
      >
        {!!showableExperience &&
          !isLoading &&
          isFinishedStreaming &&
          (showableExperience.description ? (
            <StyledChatActionCard>
              {showableExperience.description}
              <div style={{ width: '100%' }}>
                <ExperienceButton
                  preview={preview}
                  showableExperience={showableExperience}
                  previewOverrides={previewOverrides}
                  disabled={isChatHistoryPreview}
                />
              </div>
            </StyledChatActionCard>
          ) : (
            <ExperienceButton
              preview={preview}
              showableExperience={showableExperience}
              previewOverrides={previewOverrides}
              disabled={isChatHistoryPreview}
            />
          ))}
      </AnimationTransition>

      {props.showContinuations && isFinishedStreaming && isCollectingArgumentValues && (
        <StyledButtonGroup style={{ width: '100%' }}>
          {!!commandToExecute && (
            <StyledPrimaryButton
              themeV2={themeV2}
              disabled={disabled}
              onClick={(e) => {
                executeAction(
                  { type: 'execute_command', meta: { command: commandToExecute.id.toString() } },
                  e,
                  message.value.copilot_argument_values || {},
                );
                toggleHelpHubVisible();
              }}
              prefixIcon={<Check />}
            >
              Confirm
            </StyledPrimaryButton>
          )}
          <StyledSecondaryButton
            themeV2={themeV2}
            onClick={() => {
              abortArgumentCollection();
            }}
          >
            Nevermind
          </StyledSecondaryButton>
        </StyledButtonGroup>
      )}
    </StyledChatContent>
  ) : (
    <div
      style={{
        maxWidth: '100%',
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        ...(isLoading && { minHeight: 200 }),
      }}
    >
      <div css={[styles.feedBackButtonsParent[preview ? 'preview' : 'regular']]}>
        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={isLoading && !showDocBasedAnswer}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', padding: '8px 0px' }}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                css={css`
                  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

                  @keyframes pulse {
                    0%,
                    100% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0.5;
                    }
                  }
                `}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: CB_COLORS.neutral600,
                  animationDelay: `${index * 0.25}s`,
                }}
              />
            ))}
          </div>
        </AnimationTransition>

        <GlowBorder showGlowBorder={isArgumentCollectionFinalStep}>
          <AnimationTransition
            style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={showDocBasedAnswer || message.fallback_labeled_actions.length > 0 || message.extras.length > 0}
          >
            {showDocBasedAnswer && (
              <BotMessageText
                preview={preview}
                isLoading={isLoading}
                isFinishedStreaming={isFinishedStreaming}
                onFinishStreaming={onFinishStreaming}
                answer={aiAnswerValue}
                id={message.uuid ?? ''}
              />
            )}
            {!isLoading && (
              <MessageActions
                preview={preview}
                aiMessage={message}
                fallbackActions={message.fallback_labeled_actions}
                isChatHistoryPreview={isChatHistoryPreview}
                nextSteps={nextSteps}
              />
            )}
            {!isLoading && message.extras.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  marginTop: showDocBasedAnswer ? '12px' : '0px',
                }}
              >
                {message.extras.map((extra, idx) => {
                  // Don't show extras that are already in next steps
                  const extraInNextSteps = nextSteps.some((step) => isAction(step) && step.action === extra.action);

                  if (extraInNextSteps) {
                    return null;
                  }

                  return (
                    <MessageExtra
                      key={idx}
                      idx={idx}
                      extra={extra}
                      totalExtras={message.extras.length}
                      isLastMessage={isLastMessage}
                      executeAction={executeAction}
                      sendChat={props.sendChat}
                      preview={preview}
                      isChatHistoryPreview={isChatHistoryPreview}
                      previewOverrides={previewOverrides}
                    />
                  );
                })}
              </div>
            )}
          </AnimationTransition>

          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={!!showableExperience}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: showDocBasedAnswer ? '12px' : '0px',
            }}
          >
            {showableExperience && !isLoading && (
              <React.Fragment>
                <GlowBorder showGlowBorder={!isArgumentCollectionFinalStep && !!showableExperience}>
                  <p style={{ margin: '0px' }}>{showableExperience.description}</p>
                  <div
                    style={{
                      alignItems: 'flex-start',
                    }}
                  >
                    <ExperienceButton
                      preview={preview}
                      showableExperience={showableExperience}
                      disabled={isChatHistoryPreview}
                    />
                  </div>
                </GlowBorder>
              </React.Fragment>
            )}
          </AnimationTransition>

          {props.showContinuations && isCollectingArgumentValues ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                padding: '0px',
                alignItems: 'flex-start',
                marginTop: '12px',
              }}
            >
              {!!commandToExecute && (
                <ActionButton
                  preview={preview}
                  disabled={disabled}
                  style={{ marginTop: '9px', width: '50%' }}
                  onClick={(e) => {
                    executeAction(
                      { type: 'execute_command', meta: { command: commandToExecute.id.toString() } },
                      e,
                      message.value.copilot_argument_values || {},
                    );
                    toggleHelpHubVisible();
                  }}
                  icon="Check"
                >
                  Confirm
                </ActionButton>
              )}
              <ActionButton
                preview={preview}
                disabled={disabled}
                style={{ marginTop: '9px', width: '50%' }}
                variant="secondary"
                onClick={() => {
                  abortArgumentCollection();
                }}
              >
                Nevermind
              </ActionButton>
            </div>
          ) : null}
          <AnimationTransition
            style={{ width: '100%', marginTop: '12px' }}
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={(message.value.source_docs.length > 0 && showDocBasedAnswer) || preview}
          >
            <BotMessageFooter preview={preview} message={message} />
          </AnimationTransition>

          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
            isMounted={
              !isChatHistoryPreview &&
              ((continuations && continuations.length > 0 && props.showContinuations) || preview)
            }
          >
            <Continuations preview={preview} continuations={continuations || []} isLoading={isLoading} />
          </AnimationTransition>
        </GlowBorder>
        {!isFirstMessage && !isLoading && !hasReplyExtra && (
          <FeedbackButtons preview={preview} isChatHistoryPreview={isChatHistoryPreview} message={message} />
        )}
      </div>
    </div>
  );
};

export default BotMessage;
