import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useIntegrationsContext } from '../../util/IntegrationContext';

import SourceEditDrawer from './edit-drawer/SourceEditDrawer';
import SourceCard from './Card';

import { IntegrationSpec, type Source } from '../../integrations/shared/types';
import AddSourceFlow from './edit-drawer/AddSourceFlow';
import { Empty } from '@commandbar/design-system/components/antd';
import { useHistory } from 'react-router';
import { LoadingState } from '../shared/styles';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
`;

interface SourcesProps {
  showAddContent: boolean;
  setShowAddContent: (show: boolean) => void;
}

const Sources = ({ showAddContent, setShowAddContent }: SourcesProps) => {
  const history = useHistory();
  const { helpDocIntegrations, refreshHelpDocIntegrations } = useIntegrationsContext();
  // Keeping track of currentIntegration and editingSource separately because we want to be able to
  // enable the SourceEditDrawer when adding a source from OAuth flow
  const [currentIntegration, setCurrentIntegration] = useState<IntegrationSpec>();
  const [editingSource, setEditingSource] = useState<Source>();
  const [isLoading, setIsLoading] = useState(false);

  const reloadInterations = useCallback(() => {
    setIsLoading(true);

    refreshHelpDocIntegrations().finally(() => {
      setIsLoading(false);
    });
  }, [refreshHelpDocIntegrations, setIsLoading]);

  useEffect(() => {
    reloadInterations();
  }, [reloadInterations]);

  const sources: Array<Source> = useMemo(
    () =>
      helpDocIntegrations
        .reduce<Array<Source>>(
          (acc, spec) => [
            ...acc,
            ...(spec.sources || []).map((source) => ({
              ...source,
              ...spec,
            })),
          ],
          [],
        )
        .sort((a, b) => a.id - b.id),
    [helpDocIntegrations],
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sourceId = query.get('source_id');
    if (sourceId && sources.find((s) => s.id === Number(sourceId))) {
      const editingSource = sources.find((s) => s.id === Number(sourceId));
      if (editingSource) {
        setEditingSource(editingSource);
        setCurrentIntegration(helpDocIntegrations.find((spec) => spec.slug === editingSource.slug));
      }
    }
  }, [sources.length]);

  useEffect(() => {
    if (helpDocIntegrations.length) {
      const query = new URLSearchParams(window.location.search);
      const token = query.get('token');
      const slug = query.get('integration');
      const sourceId = query.get('source_id');
      // if we have a token and the integration is set show the add content flow
      // we also check if there is no source id because if there is a source id we want to show the edit flow
      if (token && slug && !sourceId) {
        setCurrentIntegration(helpDocIntegrations.find((spec) => spec.slug === slug));
      }
    }
  }, [helpDocIntegrations.length]);

  const renderBody = () => {
    if (isLoading) return <LoadingState />;

    if (!sources.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            "Add an existing help center or website to power in-app help and AI answers by clicking the 'Add Source' button above."
          }
        />
      );
    }

    return (
      <Body>
        {sources.map((source) => (
          <SourceCard
            key={source.id}
            source={source}
            selectedSource={editingSource}
            setSelectedSource={setEditingSource}
          />
        ))}
      </Body>
    );
  };

  return (
    <>
      {renderBody()}
      {showAddContent && <AddSourceFlow onClose={() => setShowAddContent(false)} />}
      {(editingSource || currentIntegration) && (
        <SourceEditDrawer
          integration={currentIntegration || editingSource}
          setIntegration={(integ) => {
            setEditingSource(undefined);
            setCurrentIntegration(integ);
          }}
          onCancel={() => {
            setEditingSource(undefined);
            setCurrentIntegration(undefined);
            history.replace({ search: '' });
          }}
          onComplete={() => {
            setEditingSource(undefined);
            setCurrentIntegration(undefined);
            history.replace({ search: '' });
            refreshHelpDocIntegrations();
          }}
          sourceId={editingSource?.id}
          open={!!editingSource || !!currentIntegration}
        />
      )}
    </>
  );
};

export default Sources;
