import React from 'react';
import styled from '@emotion/styled';
import { AlertHexagon } from '@commandbar/design-system/icons/react';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;

  width: 100%;

  background: #fff2d8;
  border-radius: 4px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background: #ffe2aa;
  border-radius: 100px;

  div {
  }
`;

const Content = styled.div`
  text-align: left;
  flex: 1;
  color: #583900;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const WarningBannerBase = ({ children }: { children: React.ReactNode }) => (
  <Container>
    <IconContainer>
      <AlertHexagon width={13} color="#583900" />{' '}
    </IconContainer>
    <Content>{children}</Content>
  </Container>
);
