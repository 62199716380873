import * as React from 'react';

import { cn } from '../util';

// Define the types for all subcomponents as static properties
type CmdCardComponent = React.ForwardRefExoticComponent<
  React.HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>
> & {
  Header: typeof CardHeader;
  Title: typeof CardTitle;
  Description: typeof CardDescription;
  Content: typeof CardContent;
  Footer: typeof CardFooter;
};

const CmdCard = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'rounded-md border-solid border-[1px] border-gray200 bg-gray100 text-card-foreground shadow-sm p-xl',
        className,
      )}
      {...props}
    />
  ),
) as CmdCardComponent;
CmdCard.displayName = 'CmdCard';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex flex-col', className)} {...props} />,
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('text-2xl font-semibold leading-none tracking-tight', className)} {...props} />
  ),
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <p ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />
  ),
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn(className)} {...props} />,
);
CardContent.displayName = 'CardContent';

interface CardFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  divider?: boolean;
  asAction?: boolean;
}

const CardFooter = React.forwardRef<HTMLDivElement, CardFooterProps>(
  ({ className, divider = false, asAction = false, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex items-center px-lg pt-0',
        { 'border-solid border-t-[1px] border-x-0 border-b-0 border-gray200': divider },
        { 'hover:bg-elementBase hover:rounded-bl-button hover:rounded-br-button': asAction },
        className,
      )}
      {...props}
    />
  ),
);
CardFooter.displayName = 'CardFooter';

CmdCard.Header = CardHeader;
CmdCard.Footer = CardFooter;
CmdCard.Title = CardTitle;
CmdCard.Description = CardDescription;
CmdCard.Content = CardContent;

export { CmdCard };
