import { UserProperties } from '@commandbar/internal/client/CommandBarClientSDK';
import type { IDecideResponseType } from '@cb/types/entities/endUser';
import { getBrowser, getDeviceType, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import { getEnvOrVersion } from '../../sdk/config';

import * as fetchClient from '@commandbar/internal/middleware/network';
import { decodeToPromise } from '@commandbar/internal/middleware/generics';
import { DecideResponseV } from '@cb/types/entities/endUser';
import { getSDK } from '@commandbar/internal/client/globals';
import { _analytics } from '@commandbar/internal/client/symbols';
import { getSentry } from '@commandbar/internal/util/sentry';

export const addDeviceProperties = (properties: UserProperties): UserProperties => {
  return {
    ...properties,
    $device: getDeviceType(),
    $browser: getBrowser(),
    $os: getOperatingSystem(),
    $language: navigator.language,
  };
};

export const decide = async (
  orgId: string | number,
  distinctId: string | null | undefined,
  options: {
    properties?: UserProperties;
    envOverride: { env: string } | { version: string } | null;
    env: string | null;
    version?: string;
    is_admin?: boolean;
  },
): Promise<IDecideResponseType | null> => {
  try {
    // TODO: Create tests to ensure these are included
    const completeProperties = addDeviceProperties(options.properties || {});

    const envOrVersion = getEnvOrVersion({
      envOverride: options.envOverride,
      env: options.env,
      version: options.version,
    });

    let query = '';
    if (!localStorage.getItem('commandbar.takeover')) {
      if ('env' in envOrVersion) {
        query = `?env=${envOrVersion.env}`;
      } else if ('version' in envOrVersion) {
        query = `?version=${envOrVersion.version}`;
      }
    }

    const res = await fetchClient.post(
      `/decide/${query}`,
      {
        organization_id: orgId,
        distinct_id: distinctId,
        properties: completeProperties,
        is_admin: !!options.is_admin,
      },
      {
        credentials: 'omit',
      },
    );

    const { data } = res;

    const result = await decodeToPromise(DecideResponseV, data);

    getSDK()?.[_analytics]?.setDecide(result);

    return result;
  } catch (error) {
    getSentry()?.captureException(error);
    return null;
  }
};
