import React from 'react';
import { CheckCircle } from '@commandbar/design-system/icons/react';
import { Buffer } from 'buffer';
import { useAppContext } from 'editor/src/AppStateContext';

interface IProps {
  token?: string | null | undefined;
  children?: React.ReactElement;
}

const SlackOAuth = (props: IProps) => {
  const { organization } = useAppContext();

  const organizationId = String(organization?.id);
  const clientID = process.env.REACT_APP_SLACK_CLIENT_ID;
  let apiURL = process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
  if (process.env.REACT_APP_API_URL?.includes('localhost:8000')) {
    apiURL = 'http://localhost:8000';
  }
  const redirectUri = encodeURIComponent(`${apiURL}/integrations/slack/`);
  const state = Buffer.from(JSON.stringify({ uuid: `${organizationId}` })).toString('base64');
  const authUri = `https://slack.com/oauth/v2/authorize?scope=channels:join,chat:write,incoming-webhook&user_scope=channels:write&client_id=${clientID}&redirect_uri=${redirectUri}&state=${state}`;
  if (!!props.token) {
    return <CheckCircle height={16} color={'green'} />;
  } else {
    return (
      <div style={{ width: '100%' }} onClick={() => window.location.replace(authUri)}>
        {props.children}
      </div>
    );
  }
};

export default SlackOAuth;
