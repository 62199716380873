import Layout from '../../components/Layout';
import ActionClicksTable from './ActionClicksTable';
import PageClicksTable from './PageClicksTable';
import StackedBarChart from './StackedBarChart';
import useAnalyticSpotlight from './useAnalyticSpotlight';

const AnalyticsSpotlightDashboard = () => {
  const { data, isLoading, fetchSpotlightData, error, handleDownloadCsv, isDownloading } = useAnalyticSpotlight();

  return (
    <Layout
      title="Spotlight"
      disableDemoData={isLoading}
      isDownloading={isDownloading}
      onDownloadCSV={[
        {
          label: 'Actions',
          action: () => handleDownloadCsv('actions'),
        },
        {
          label: 'Pages',
          action: () => handleDownloadCsv('pages'),
        },
      ]}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', width: '100%' }}>
          <StackedBarChart data={data} isLoading={isLoading} />

          <div style={{ display: 'flex', gap: '16px', flexFlow: 'wrap' }}>
            <div style={{ flex: 1 }}>
              <PageClicksTable
                pageClicksData={data?.page_clicks_table}
                isLoading={isLoading}
                fetchSpotlightData={fetchSpotlightData}
                error={!!error}
              />
            </div>

            <div style={{ flex: 1 }}>
              <ActionClicksTable
                actionClicksData={data?.action_clicks_table}
                isLoading={isLoading}
                fetchSpotlightData={fetchSpotlightData}
                error={!!error}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsSpotlightDashboard;
