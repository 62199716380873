import { IEditorCommandType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import { useAppContext } from 'editor/src/AppStateContext';
import { freshCommand } from '../useEditor';
import { useRouter } from '../../hooks';
import useWindowInfo from '../../hooks/useWindowInfo';
import { getCommandRoute } from '../PagesOrActions';

const useLinkedCommand = () => {
  const { deeplink } = useRouter();
  const { hasRouter } = useWindowInfo();

  const { organization } = useAppContext();

  const openExistingLinkedCommand = (command: IEditorCommandTypeLite) => {
    deeplink({
      to: `${getCommandRoute(command)}/${command.id}`,
    });
  };

  const openNewLinkedCommand = (command: IEditorCommandType) => {
    deeplink({
      to: `${getCommandRoute(command)}/${command.id}`,
    });
  };

  const createLinkedCommand = (contextKey: string): IEditorCommandType => ({
    ...freshCommand(organization, undefined),
    arguments: {
      record: {
        type: 'context',
        value: contextKey,
        order_key: 1,
        label: 'Select from the list below',
        show_in_record_action_list: true,
        show_in_default_list: false,
      },
    },
    template: {
      type: 'link',
      value: '',
      operation: hasRouter ? 'router' : 'blank',
      commandType: 'object',
      object: contextKey,
    },
  });

  return { createLinkedCommand, openExistingLinkedCommand, openNewLinkedCommand };
};

export default useLinkedCommand;
