/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';

const radioStyle = css`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;

const Radio = ({ checked }: { checked: boolean }) => {
  const { theme }: { theme: ITheme } = useTheme();

  const afterStyle = (theme: ITheme) => css`
    position: relative;
    width: 16px;
    height: 16px;
    background: ${theme.nudgeOptionListRadio.backgroundColor};
    border: ${theme.nudgeOptionListRadio.border};
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      background: ${theme.nudgeOptionListRadio.check};
      border-radius: 50%;
      transform: translate(-50%, -50%);
      display: ${checked ? 'block' : 'none'};
    }
  `;

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <input type="radio" css={{ ...radioStyle }} checked={checked} readOnly />
      <span
        css={afterStyle(theme)}
        style={{
          border: checked ? theme.nudgeOptionListRadio.borderSelected : theme.nudgeOptionListRadio.border,
        }}
      ></span>
    </div>
  );
};

export default Radio;
