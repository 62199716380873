// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Caret = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      fill="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M0.0581149 0.912761L3.78978 5.40359C3.8966 5.53214 4.10227 5.53214 4.21022 5.40359L7.94189 0.912761C8.08052 0.745298 7.95552 0.500001 7.73167 0.500001L0.268334 0.5C0.0444793 0.5 -0.0805154 0.745298 0.0581149 0.912761Z"
    />
  </svg>
);
export default Caret;
