import * as Command from '@commandbar/internal/middleware/command';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import type { ICommandType } from '@commandbar/internal/middleware/types';
import type { IEndUserStoreData } from '@cb/types/entities/endUser';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Reporting from 'shared/services/analytics/Reporting';
import * as SpotlightServiceSelectors from 'products/spotlight/service-selectors';
import {
  CommandWithTimestamp,
  emptyEndUserStoreState,
  EndUserStore,
  getStoreKey,
  initLocalEndUserState,
  LocalStorageKey,
  RecordWithTimestamp,
} from './state';
import { getCommands, selectEndUserEndpoint } from 'shared/store/global-selectors';
import { isRemoteEnduserStoreEnabled } from './selectors';
import { createFallbackHmac } from './helpers';
import isEqual from 'lodash/isEqual';
import { CBStore } from 'shared/store/global-store';
import { getSentry } from '@commandbar/internal/util/sentry';
import Logger from '@commandbar/internal/util/Logger';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import { isSilentMode } from 'shared/services/analytics/helpers';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import mergeWith from 'lodash/mergeWith';

export const syncEndUserStore = (
  _: CBStore,
  store: 'hotkeys' | 'checklist_interactions' | 'nudges_interactions',
  remotePreferences: IEndUserStoreData,
) => {
  const remote = !!remotePreferences?.[store] ? remotePreferences[store] : {};
  const local = !!_.endUserStore.data[store] ? _.endUserStore.data[store] : {};
  let merged: { [key: string]: any[] } = {};

  // interactions are {} after admin reset from dashboard, need to propagate the reset to local instead of overwriting remote with outdated local interactions
  if (store === 'checklist_interactions' || store === 'nudges_interactions') {
    merged = mergeWith({}, local, remote, (_objValue, srcValue, key) => {
      if (!isNaN(Number(key)) && isObject(srcValue) && isEmpty(srcValue)) {
        return {};
      }
    });
  } else {
    merged = merge({}, local, remote);
  }

  if (Object.keys(merged).length === 0) {
    clearFromEndUserStore(_, store);
  } else if (!isEqual(local, merged)) {
    setToEndUserStore(_, store, merged);
  }

  if (!isEqual(remote, merged)) {
    patchRemoteData(_, merged, store);
  }
};

export const refreshEndUserData = async (_: CBStore) => {
  initFromLocal(_);

  if (isRemoteEnduserStoreEnabled(_) && !_.airgap && _.endUser) {
    const remotePreferences = emptyEndUserStoreState().data;

    const endpoint = selectEndUserEndpoint(_);

    if (!!endpoint) {
      try {
        const endUserResponse = await axiosInstance.get(endpoint, {
          headers: {
            'X-USER-AUTHORIZATION': !!_.endUser.hmac ? _.endUser.hmac : createFallbackHmac(),
          },
          credentials: 'omit',
        });

        Object.assign(remotePreferences, endUserResponse.data);

        if (!!_.organization?.end_user_shortcuts_enabled) {
          syncEndUserStore(_, 'hotkeys', remotePreferences);
        }

        syncEndUserStore(_, 'checklist_interactions', remotePreferences);
        syncEndUserStore(_, 'nudges_interactions', remotePreferences);

        setToEndUserStore(_, 'analytics', remotePreferences?.['analytics']);
        setToEndUserStore(_, 'properties', remotePreferences?.['properties']);

        setVerified(_, !!_?.endUser?.hmac);
      } catch (error) {
        getSentry()?.captureException(error);
        Logger.warn('Failed to load end user data', error);
        setVerified(_, false);
      } finally {
        dispatchCustomEvent('commandbar-remote-ready', {});
        _.endUserStore.hasRemoteLoaded = true;
        _.nudgeManager?.send('END_USER_STORE_LOADED');
      }
    }
  } else {
    dispatchCustomEvent('commandbar-remote-ready', {});
    _.endUserStore.hasRemoteLoaded = true;
    _.nudgeManager?.send('END_USER_STORE_LOADED');
  }
};

export function setToEndUserStore<T extends LocalStorageKey>(_: CBStore, field: T, value: EndUserStore['data'][T]) {
  const storekey = getStoreKey(field, _.endUser);
  LocalStorage.set(storekey, JSON.stringify(value));
  _.endUserStore.data[field] = value;
}

export function setVerified(_: CBStore, isVerified: boolean) {
  _.endUserStore.verified = isVerified;
}

export function clearFromEndUserStore(_: CBStore, field: LocalStorageKey) {
  const storekey = getStoreKey(field, _.endUser);
  LocalStorage.remove(storekey);

  _.endUserStore.data[field] = emptyEndUserStoreState().data[field];
}

export const storeRecentsOnExecute = (_: CBStore, executeStep: SpotlightServiceSelectors.ExecuteStep) => {
  if (!_.organization?.end_user_recents_enabled) return;

  const activeRecord = SpotlightServiceSelectors.getActiveRecord(_);
  if (activeRecord) {
    storeRecordOptionAsRecent(_, activeRecord);
  } else {
    storeCommandAsRecent(_, executeStep.command);
  }
};

const storeAsRecent = (_: CBStore, recent: CommandWithTimestamp | RecordWithTimestamp) => {
  const filtered = _.endUserStore.data.recents.filter(({ optionUID: oUID }) => oUID !== recent.optionUID);

  // Sort by most recent first
  const updated = prependToFixedLengthArray(filtered, recent, 20);

  setToEndUserStore(_, 'recents', updated);
};

/** Fixme: For consistency w/ records, should pass in CommandOption here as well instead of command */
const storeCommandAsRecent = (_: CBStore, command: ICommandType) => {
  const commandWithTS: CommandWithTimestamp = {
    type: 'command',
    command,
    timestamp: Date.now(),
    optionUID: Command.commandUID(command),
  };

  storeAsRecent(_, commandWithTS);
};

const prependToFixedLengthArray = <T>(input: T[], item: T, length = 20): T[] => {
  const output = [item].concat(input);
  if (output.length > length) {
    output.splice(length);
  }
  return output;
};

const storeRecordOptionAsRecent = (_: CBStore, option: SpotlightServiceSelectors.RecordOption) => {
  const { uid, contextKey } = SpotlightServiceSelectors.getOptionMetadata(option);
  if (!uid || !contextKey) return;

  const recordWithTS: RecordWithTimestamp = {
    type: 'record',
    record: option.parameter,
    categoryKey: contextKey,
    timestamp: Date.now(),
    optionUID: uid,
  };

  storeAsRecent(_, recordWithTS);
};

export const patchRemoteData = async <T extends 'checklist_interactions' | 'nudges_interactions' | 'hotkeys'>(
  _: CBStore,
  updated: IEndUserStoreData[T],
  store: T,
) => {
  if (!_.endUser || !isRemoteEnduserStoreEnabled(_)) return;

  const endpoint = selectEndUserEndpoint(_);

  if (!!endpoint) {
    axiosInstance
      .patch(
        endpoint,
        { [store]: updated },
        {
          credentials: 'omit',
          headers: {
            'X-USER-AUTHORIZATION': !!_.endUser.hmac ? _.endUser.hmac : createFallbackHmac(),
          },
        },
      )
      .catch((error) => {
        getSentry()?.captureException(error);
        Logger.error('Failed to update end user data', error);
      });
  }
};

export const updateEndUserStore = <T extends 'checklist_interactions' | 'nudges_interactions' | 'hotkeys'>(
  _: CBStore,
  records: IEndUserStoreData[T],
  store: T,
) => {
  let updated = _.endUserStore.data[store];

  for (const [key, val] of Object.entries(records)) {
    // Reset to default if val is undefined
    if (val === undefined) {
      const { [key]: k, ...rest } = updated;
      updated = rest;
    } else {
      updated = {
        ...updated,
        [key]: val,
      };
    }
  }

  setToEndUserStore(_, store, updated);

  patchRemoteData(_, updated, store);

  return updated;
};

export const updateEndUserHotkey = (_: CBStore, key: string, val: any) => {
  if (!!_.organization?.end_user_shortcuts_enabled) {
    const store = 'hotkeys';
    const updated = updateEndUserStore(_, { [key]: val }, store);

    const updatedCommand = getCommands(_).find((command) => Command.commandUID(command) === key);
    if (!!updatedCommand) {
      const defaultShortcut = ['windows', 'linux'].includes(_.platform)
        ? updatedCommand?.hotkey_win
        : updatedCommand?.hotkey_mac;
      const oldShortcut = _.endUserStore.data.hotkeys[key] || updatedCommand?.hotkey_win;
      Reporting.changedShortcut(updatedCommand, oldShortcut, val, defaultShortcut || '');
    }

    return updated;
  }
};

export const initFromLocal = (_: CBStore) => {
  const enabledKeys: LocalStorageKey[] = [];
  if (!!_.organization?.end_user_recents_enabled) {
    enabledKeys.push('recents');
  }

  if (!!_.organization?.end_user_shortcuts_enabled) {
    enabledKeys.push('hotkeys');
  }

  if (!isSilentMode()) {
    enabledKeys.push('analytics');
  }

  enabledKeys.push('checklist_interactions');
  enabledKeys.push('nudges_interactions');
  enabledKeys.push('properties');

  _.endUserStore = initLocalEndUserState(enabledKeys, _.endUser);
};
