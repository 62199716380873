import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import * as S from './styles';
import { CodeInstructionsCard } from './CodeInstructionsCard';
import { ShareInstructionsCard } from './ShareInstructionsCard';
import { InviteTeamCard } from './InviteTeamCard';
import { NoCodeInstructionsCard } from './NoCodeInstructionsCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardsCol = styled(S.Col)`
  margin-top: 24px;
  align-content: flex-start;
  gap: 16px;
`;

export const Description = styled.div`
  padding-right: 55px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: ${CB_COLORS.neutral900};
`;

export const MainView = () => {
  return (
    <Container>
      <Description>
        Before you can publish any of your work and make it visible to your users you'll need to add CommandBar to your
        app or website.
      </Description>
      <CardsCol>
        <CodeInstructionsCard />
        <NoCodeInstructionsCard />
        <InviteTeamCard />
        <ShareInstructionsCard />
      </CardsCol>
    </Container>
  );
};
