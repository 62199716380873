import { type MutableRefObject, type RefCallback, useMemo } from 'react';

type ReactRef<T> = RefCallback<T> | MutableRefObject<T>;

function assignRef<T>(ref: ReactRef<T> | null | undefined, value: T): void {
  if (ref == null) return;

  if (typeof ref === 'function') {
    ref(value);
    return;
  }

  ref.current = value;
}

function mergeRefs<T>(...refs: Array<ReactRef<T> | null | undefined>): RefCallback<T> {
  return (node) => {
    for (const ref of refs) {
      assignRef(ref, node);
    }
  };
}

export function useMergeRefs<T>(...refs: Array<ReactRef<T> | null | undefined>): RefCallback<T> {
  return useMemo(() => mergeRefs(...refs), refs);
}
