import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '../util';
import { tooltipStyles } from './style';

const CmdTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, side = 'top', sideOffset = 2, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    side={side}
    sideOffset={sideOffset}
    className={cn(tooltipStyles(), className)}
    {...props}
  />
));
CmdTooltipContent.displayName = 'CmdTooltipContent';

const CmdTooltipTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Trigger
    ref={ref}
    className={cn('bg-transparent border-none height-fit width-fit cursor-auto', className)}
    {...props}
  />
));
CmdTooltipTrigger.displayName = 'CmdTooltipTrigger';

type CmdTooltipProps = {
  message?: React.ReactNode;
  delayDuration?: number;
  showIf?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
} & React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>;

const CmdTooltip = ({
  message,
  children,
  delayDuration,
  showIf,
  position = 'top',
  asChild = true,
  ...contentProps
}: CmdTooltipProps) => {
  if (message && (showIf === undefined || showIf)) {
    return (
      <TooltipPrimitive.Provider delayDuration={delayDuration}>
        <TooltipPrimitive.Root>
          <TooltipPrimitive.Trigger className="inline-block" asChild={asChild}>
            <div>{children}</div>
          </TooltipPrimitive.Trigger>
          <CmdTooltipContent side={position} {...contentProps}>
            {message}
          </CmdTooltipContent>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  }

  return <>{children}</>;
};

CmdTooltip.Provider = TooltipPrimitive.Provider;
CmdTooltip.Trigger = CmdTooltipTrigger;
CmdTooltip.Content = CmdTooltipContent;
CmdTooltip.Root = TooltipPrimitive.Root;

export { CmdTooltip };
