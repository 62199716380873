import React from 'react';
import styled from '@emotion/styled';
import {
  ArrowLeft,
  Check,
  CheckSquareBroken,
  CornerUpRight,
  InfoCircle,
  NotificationBox,
  Settings04,
  Target04,
  Trash04,
  ZapFast,
  BookOpen01,
  FaceIdSquare,
  SearchSm,
  Bookmark,
  CursorBox,
  Link04,
  MessageSquare01,
  Play,
  MessageTextSquare01,
  ArrowUpRight,
  ClockSnooze,
  Link05,
  Settings03,
} from '@commandbar/design-system/icons/react';
import { ReactComponent as CaretDown } from '../img/caret_down.svg';

import { CmdButton, CmdDropdown, CmdInput, CmdLabel, CmdSelect } from '@commandbar/design-system/cmd';
import { Select, Tooltip, StyledLabel, CB_COLORS, StatusBadge } from '@commandbar/design-system/components/antd';
import * as S from './nudges/styled';
import { NudgeDropdown } from './nudges/NudgeContentForm/NudgeDropdown';
import Sender from '../management/Sender';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Z from '@commandbar/internal/client/Z';

import type {
  Action,
  ChatHandoffActionType,
  CommandAction,
  CommandActionMetaType,
  OpenChatActionType,
} from '@cb/types/entities/command/actions';

import type {
  IChecklist,
  IEditorCommandTypeLite,
  INudgeType,
  IOrganizationType,
} from '@commandbar/internal/middleware/types';
import {
  OverlayCheckIconContainer,
  OverlayDisplayValue,
  OverlaySettingsOption,
  StyledExperienceSelect,
} from './components/styled';
import { nudgeStepTitleDisplay, nudgeTitleDisplay } from './nudges/NudgeList';
import { ElementNotFoundIcon } from './nudges/NudgeContentForm/Header';
import { useAppContext } from '../AppStateContext';
import useWindowInfo from '../hooks/useWindowInfo';
import { transformIfJSPath } from '@commandbar/internal/util/dom';
import { handoffNameToOrgKey } from '@cb/types/entities/command/actions';
import { useHistory } from 'react-router';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import { CommandSelect } from './CommandSelect';

const SuffixContainer = styled.div`
  & .ant-dropdown-trigger {
    padding: 0;
  }
`;

const RouterOption = styled(OverlaySettingsOption)`
  border-top: 1px solid ${CB_COLORS.neutral300};
`;

const RouterTooltip = styled(Tooltip)`
  width: 204px !important;
  padding: 8px !important;
  border-radius: 4px !important;
  background: #000000 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
`;

const openChatOptions: { value: OpenChatActionType; title: string }[] = [
  { value: 'intercom', title: 'Intercom' },
  { value: 'helpscout', title: 'Helpscout' },
  { value: 'freshdesk', title: 'Freshdesk' },
  { value: 'freshchat', title: 'Freshchat' },
  { value: 'gist', title: 'Gist' },
  { value: 'crisp', title: 'Crisp' },
  { value: 'zendesk', title: 'Zendesk' },
  { value: 'liveChat', title: 'LiveChat' },
  { value: 'olark', title: 'Olark' },
  { value: 'hubspot', title: 'HubSpot' },
  { value: 'drift', title: 'Drift' },
  { value: 'pylon', title: 'Pylon' },
];

const chatHandoffOptions: { value: ChatHandoffActionType; title: string }[] = [
  { value: 'intercom', title: 'Intercom' },
  { value: 'freshchat', title: 'Freshchat' },
  { value: 'zendesk', title: 'Zendesk' },
];

export const actionTypeOptions = {
  link: {
    dropdownLabel: 'Visit link',
    selectionLabel: <Link04 />,
  },
  click: {
    dropdownLabel: 'Click element',
    selectionLabel: <CursorBox />,
  },
  nudge: {
    dropdownLabel: 'Show nudge',
    selectionLabel: <NotificationBox />,
  },
  questlist: {
    dropdownLabel: 'Show checklist',
    selectionLabel: <CheckSquareBroken />,
  },
  help_doc: {
    dropdownLabel: 'Show article or file',
    selectionLabel: <BookOpen01 />,
  },
  video: {
    dropdownLabel: 'Play video',
    selectionLabel: <Play />,
  },
  execute_command: {
    dropdownLabel: 'Trigger action',
    selectionLabel: <ZapFast />,
  },
  page: {
    dropdownLabel: 'Visit page',
    selectionLabel: <Link05 />,
  },
  open_copilot: {
    dropdownLabel: 'Open Copilot',
    selectionLabel: <FaceIdSquare />,
  },
  open_helphub: {
    dropdownLabel: 'Open HelpHub',
    selectionLabel: <Bookmark />,
  },
  open_bar: {
    dropdownLabel: 'Open Spotlight',
    selectionLabel: <SearchSm />,
  },
  open_chat: {
    dropdownLabel: 'Open third-party chat',
    selectionLabel: <MessageSquare01 />,
  },
  chat_handoff: {
    dropdownLabel: 'Chat handoff',
    selectionLabel: <MessageTextSquare01 />,
  },
  step_back: {
    dropdownLabel: 'Go back',
    selectionLabel: <ArrowLeft />,
  },
  go_to_step: {
    dropdownLabel: 'Go to step',
    selectionLabel: <CornerUpRight />,
  },
  snooze: {
    dropdownLabel: 'Snooze',
    selectionLabel: <ClockSnooze />,
  },
  dismiss: {
    dropdownLabel: 'Complete',
    selectionLabel: <Check />,
  },
};

export type ActionType =
  | 'link'
  | 'click'
  | 'clickBySelector'
  | 'clickByXpath'
  | 'execute_command'
  | 'help_doc'
  | 'page'
  | 'video'
  | 'open_chat'
  | 'chat_handoff'
  | 'dismiss'
  | 'nudge'
  | 'questlist'
  | 'step_back'
  | 'go_to_step'
  | 'snooze'
  | 'open_bar'
  | 'open_helphub'
  | 'open_copilot';

const NavigationActions: ActionType[] = ['link', 'click'];
const NudgesActions: ActionType[] = ['nudge', 'questlist', 'open_helphub', 'open_bar'];
const ExecuteNudgeActions: ActionType[] = ['step_back', 'go_to_step', 'dismiss', 'snooze'];
const CopilotActions: ActionType[] = ['open_copilot'];
const BuildingBlocksActions: ActionType[] = ['help_doc', 'video', 'page', 'execute_command'];
const MiscActions: ActionType[] = ['open_chat', 'chat_handoff'];

type ActionPartial =
  | { type: 'link'; operation: 'router' | 'self' | 'blank'; value: string }
  | { type: 'click'; value: string }
  | { type: 'execute_command'; meta: { command: string; type?: 'action' | 'link' | 'helpdoc' | 'video' } }
  | { type: 'open_bar' }
  | { type: 'open_helphub' }
  | { type: 'open_copilot' }
  | { type: 'open_chat'; meta: { type: OpenChatActionType } }
  | { type: 'chat_handoff'; meta: { type: ChatHandoffActionType } }
  | { type: 'step_back' }
  | { type: 'dismiss' }
  | { type: 'nudge'; value: number }
  | { type: 'questlist'; value: number }
  | { type: 'go_to_step'; value: number }
  | { type: 'snooze'; interval: 'hour' | 'day' | 'week'; value: number }
  | { type: 'no_action' };

const getCommandActionMetaType = (action: CommandAction, command?: IEditorCommandTypeLite): CommandActionMetaType => {
  if (action?.meta.type === 'helpdoc' || command?.template.type === 'helpdoc') {
    return 'helpdoc';
  } else if (action?.meta.type === 'video' || command?.template.type === 'video') {
    return 'video';
  } else if (action?.meta.type === 'link' || command?.template.type === 'link') {
    return 'link';
  } else {
    return 'action';
  }
};

const CaretIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ISelectActionType {
  onActionChange: (action: Action) => void;
  action?: Action;
  possibleTypes?: ActionType[];
  commandActionType?: CommandActionMetaType;
}

export const SelectActionType = ({ action, onActionChange, possibleTypes, commandActionType }: ISelectActionType) => {
  const { hasRouter } = useWindowInfo();
  const { organization } = useAppContext();
  const hasAction = (actionType: ActionType | 'no_action' | undefined): actionType is ActionType =>
    !!actionType && actionType !== 'no_action';

  const actionType = action?.type;
  const allowedTypes = possibleTypes ?? (Object.keys(actionTypeOptions) as ActionType[]);
  const actionObjects = Object.entries(actionTypeOptions) as Array<
    [keyof typeof actionTypeOptions, (typeof actionTypeOptions)[keyof typeof actionTypeOptions]]
  >;

  const getActionOptionObjects = (actions: ActionType[]) => {
    const hasEnabledHandoffIntegrations = Object.values(handoffNameToOrgKey).some(
      (integration) => integration in organization.integrations,
    );

    const allowedActions = actions.filter(
      (a) =>
        allowedTypes.includes(a) &&
        (a !== 'open_copilot' || organization.copilot_enabled) &&
        (a !== 'open_helphub' || organization.helphub_enabled) &&
        (a !== 'open_bar' || organization.bar_enabled) &&
        (a !== 'chat_handoff' || hasEnabledHandoffIntegrations),
    );
    return actionObjects
      .filter(([value]) => allowedActions.includes(value))
      .map(([value, { dropdownLabel }]) => ({ value, label: dropdownLabel }));
  };

  let selectionLabel;
  if (commandActionType === 'helpdoc') {
    selectionLabel = actionTypeOptions['help_doc'].selectionLabel;
  } else if (commandActionType === 'link') {
    selectionLabel = actionTypeOptions['page'].selectionLabel;
  } else if (commandActionType === 'video') {
    selectionLabel = actionTypeOptions['video'].selectionLabel;
  } else if (actionType === 'click' || actionType === 'clickByXpath' || actionType === 'clickBySelector') {
    selectionLabel = actionTypeOptions['click'].selectionLabel;
  } else if (hasAction(actionType)) {
    selectionLabel = actionTypeOptions[actionType].selectionLabel;
  }

  const enabledNudgeActions = getActionOptionObjects(ExecuteNudgeActions);
  const enabledCopilotActions = getActionOptionObjects(CopilotActions);

  const actions = [
    { heading: 'Navigation', options: getActionOptionObjects(NavigationActions) },
    { heading: 'Nudges', options: getActionOptionObjects(NudgesActions) },
    ...(!!enabledNudgeActions.length ? [{ heading: 'Nudge actions', options: enabledNudgeActions }] : []),
    ...(!!enabledCopilotActions.length ? [{ heading: 'Copilot', options: enabledCopilotActions }] : []),
    { heading: 'Building blocks', options: getActionOptionObjects(BuildingBlocksActions) },
    { heading: 'Other', options: getActionOptionObjects(MiscActions) },
  ];

  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger>
        <OverlayDisplayValue gap="4px" borderRadius="6px 0 0 6px" shadow>
          {selectionLabel}
          <CaretIconContainer>
            <CaretDown />
          </CaretIconContainer>
        </OverlayDisplayValue>
      </CmdDropdown.Trigger>

      <CmdDropdown.Content side="right" style={{ width: '214px' }}>
        {actions.map(({ heading, options }, index) => (
          <CmdDropdown.Group key={heading}>
            {index > 0 && <CmdDropdown.Separator />}
            <CmdDropdown.Label className="text-contentBase text-sm">{heading}</CmdDropdown.Label>

            {options.map(({ label, value }) => (
              <CmdDropdown.Item
                key={label}
                onClick={() => {
                  const emptyActionPartial = ((): ActionPartial => {
                    switch (value) {
                      case 'link':
                        return { type: 'link', operation: hasRouter ? 'router' : 'self', value: '' };
                      case 'click':
                        return { type: 'click', value: '' };
                      case 'execute_command':
                        return { type: 'execute_command', meta: { command: '', type: 'action' } };
                      case 'help_doc':
                        return { type: 'execute_command', meta: { command: '', type: 'helpdoc' } };
                      case 'video':
                        return { type: 'execute_command', meta: { command: '', type: 'video' } };
                      case 'page':
                        return { type: 'execute_command', meta: { command: '', type: 'link' } };
                      case 'open_bar':
                        return { type: 'open_bar' };
                      case 'open_chat':
                        return { type: 'open_chat', meta: { type: '' } };
                      case 'chat_handoff':
                        return { type: 'chat_handoff', meta: { type: '' } };
                      case 'open_helphub':
                        return { type: 'open_helphub' };
                      case 'open_copilot':
                        return { type: 'open_copilot' };
                      case 'dismiss':
                        return { type: 'dismiss' };
                      case 'snooze':
                        return { type: 'snooze', interval: 'day', value: 3 };
                      case 'step_back':
                        return { type: 'step_back' };
                      case 'nudge':
                        return { type: 'nudge', value: -1 };
                      case 'go_to_step':
                        return { type: 'go_to_step', value: -1 };
                      case 'questlist':
                        return { type: 'questlist', value: -1 };
                      default:
                        return { type: 'no_action' };
                    }
                  })();
                  onActionChange(emptyActionPartial);
                }}
              >
                {label}
              </CmdDropdown.Item>
            ))}
          </CmdDropdown.Group>
        ))}
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

interface ActionValuePickerProps {
  onActionChange: (action: Action) => void;
  action?: Action;
}

const SelectChatType = ({ action, onActionChange }: ActionValuePickerProps) => (
  <StyledExperienceSelect
    suffixIcon={<CaretDown />}
    placeholder="Select chat..."
    defaultValue={action?.type === 'open_chat' && action?.meta?.type ? action.meta.type : null}
    onChange={(e) => {
      onActionChange({ type: 'open_chat', meta: { type: e } });
    }}
  >
    {openChatOptions.map(({ value, title }) => (
      <Select.Option key={value} value={value}>
        {title}
      </Select.Option>
    ))}
  </StyledExperienceSelect>
);

const getEnabledChatHandoffs = (organization: IOrganizationType): Array<ChatHandoffActionType> => {
  const _enabledChatHandoffs: Array<ChatHandoffActionType> = [];
  const { integrations } = organization;
  if (integrations?.freshchat) {
    _enabledChatHandoffs.push('freshchat');
  }
  if (integrations?.intercom) {
    _enabledChatHandoffs.push('intercom');
  }
  if (integrations?.sunshine) {
    _enabledChatHandoffs.push('zendesk');
  }

  return _enabledChatHandoffs;
};

const SelectChatHandoffType = ({ action, onActionChange }: ActionValuePickerProps) => {
  const { organization } = useAppContext();
  const enabledIntegrations = getEnabledChatHandoffs(organization);

  return (
    <StyledExperienceSelect
      suffixIcon={<CaretDown />}
      placeholder="Select chat..."
      defaultValue={action?.type === 'chat_handoff' && action?.meta?.type ? action.meta.type : null}
      onChange={(e) => {
        onActionChange({ type: 'chat_handoff', meta: { type: e } });
      }}
    >
      {chatHandoffOptions
        .filter(({ value }) => enabledIntegrations.includes(value))
        .map(({ value, title }) => (
          <Select.Option key={value} value={value}>
            {title}
          </Select.Option>
        ))}
    </StyledExperienceSelect>
  );
};

const SelectNudge = ({
  action,
  onActionChange,
  excludeFromSelect,
  nudges,
}: ActionValuePickerProps & { excludeFromSelect?: INudgeType; nudges: INudgeType[] }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const history = useHistory();

  if (action?.type !== 'nudge') {
    return null;
  }
  const nudge = nudges.find((n) => n.id === action?.value);

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '32px', minWidth: '0px' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledExperienceSelect
        suffixIcon={<CaretDown />}
        placeholder="Select nudge..."
        value={nudge ? action.value : null}
        onChange={(e) => {
          if (!e || typeof e !== 'number') {
            return;
          }
          onActionChange({ type: 'nudge', value: e });
        }}
        showSearch
        filterOption={(input, option) =>
          !!option?.label && (option.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      >
        {nudges
          .filter((n) => n.id !== excludeFromSelect?.id)
          .map((n) => {
            const label = nudgeTitleDisplay(n);
            return (
              <Select.Option label={label} key={n.id} value={n.id}>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {n.is_live ? (
                    <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" />
                  ) : (
                    <StatusBadge color="orange" />
                  )}{' '}
                  {label}
                </div>
              </Select.Option>
            );
          })}
      </StyledExperienceSelect>
      {isHovered && nudge && (
        <CmdButton
          size={'sm'}
          className="px-sm"
          style={{
            position: 'absolute',
            right: '24px',
            top: '4px',
          }}
          variant="default"
          onClick={(e) => {
            e.preventDefault();

            let route = `${getNudgeRoute(nudge)}/${action.value}`;

            if (e.metaKey || e.ctrlKey) {
              window.open(`${process.env.REACT_APP_DASHBOARD_URL}/editor${route}`, '_blank');
            } else {
              // if pathname starts with content, we are in dashboard code and need to add /editor to the route
              if (history.location.pathname.startsWith('/content')) {
                route = `/editor${route}`;
              }
              history.push(route);
            }
          }}
        >
          Open <ArrowUpRight />
        </CmdButton>
      )}
    </div>
  );
};

const SelectNudgeStep = ({
  action,
  onActionChange,
  nudge,
  stepIndex,
}: ActionValuePickerProps & { nudge?: INudgeType; stepIndex?: number }) => {
  const steps = nudge?.steps ?? [];
  return (
    <StyledExperienceSelect
      suffixIcon={<CaretDown />}
      placeholder="Select step..."
      defaultValue={action?.type === 'go_to_step' && action.value >= 0 ? action.value : null}
      onChange={(e) => {
        onActionChange({ type: 'go_to_step', value: e });
      }}
      showSearch
      filterOption={(input, option) =>
        !!option?.children && (option.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {steps.map((n, i) => (
        <Select.Option key={i} value={i} disabled={stepIndex === i}>
          {nudgeStepTitleDisplay(n, i)}
        </Select.Option>
      ))}
    </StyledExperienceSelect>
  );
};

const SelectChecklist = ({
  action,
  onActionChange,
  checklists,
}: ActionValuePickerProps & { checklists: IChecklist[] }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const history = useHistory();

  if (action?.type !== 'questlist') {
    return null;
  }
  const checklist = checklists.find((n) => n.id === action?.value);

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '32px', minWidth: '0px' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledExperienceSelect
        suffixIcon={<CaretDown />}
        placeholder="Select checklist..."
        value={checklist ? action.value : null}
        onChange={(e) => {
          if (!e || typeof e !== 'number') {
            return;
          }
          onActionChange({ type: 'questlist', value: e });
        }}
        showSearch
        filterOption={(input, option) =>
          !!option?.label && (option.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      >
        {checklists.map((n, i) => (
          <Select.Option label={n.title} key={i} value={n.id}>
            <div
              style={{
                display: 'flex',
                gap: '8px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {n.is_live ? (
                <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" />
              ) : (
                <StatusBadge color="orange" />
              )}{' '}
              {n.title}
            </div>
          </Select.Option>
        ))}
      </StyledExperienceSelect>
      {isHovered && checklist && (
        <CmdButton
          size={'sm'}
          className="px-sm"
          style={{
            position: 'absolute',
            right: '24px',
            top: '4px',
          }}
          variant="default"
          onClick={(e) => {
            e.preventDefault();

            let route = `/checklists/${action.value}`;

            if (e.metaKey || e.ctrlKey) {
              window.open(`${process.env.REACT_APP_DASHBOARD_URL}/editor${route}`, '_blank');
            } else {
              if (history.location.pathname.startsWith('/content')) {
                route = `/editor${route}`;
              }

              history.push(route);
            }
          }}
        >
          Open <ArrowUpRight />
        </CmdButton>
      )}
    </div>
  );
};

const ClickInput = ({ action, onActionChange }: ActionValuePickerProps) => {
  const { isStudio } = useAppContext();
  const id = React.useMemo(() => Math.random(), []);
  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;

    onActionChange({ type: 'click', value: data.selectors[0].selector });
    return respondSuccess();
  };

  usePortal({
    commandbar: {
      onClickRecorderComplete,
    },
  });

  if (action?.type !== 'click') {
    return null;
  }

  return (
    <S.SuffixInput
      value={action.value}
      onChange={(e) => {
        onActionChange({ type: 'click', value: transformIfJSPath(e.target.value) });
      }}
      suffix={
        isStudio ? (
          <Tooltip placement="auto" content="Install in your app to use the click recorder">
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Target04 />
            </div>
          </Tooltip>
        ) : (
          <button
            style={{ all: 'unset', cursor: 'pointer', position: 'relative', top: '3px' }}
            onClick={() => Sender.openClickRecorder(id, [], true)}
          >
            <Target04 />
          </button>
        )
      }
      placeholder="Pick element..."
    />
  );
};

const DummyInput = styled.div`
  flex: 1 1 0%;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  background: white;
  border-left: 1px solid ${CB_COLORS.neutral300};
  border-radius: 0 6px 6px 0;
  height: 32px;
`;

const ComboInput = ({ action, onActionChange }: ActionValuePickerProps) => {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  if (action?.type === 'link') {
    return (
      <div style={{ display: 'flex', position: 'relative', alignItems: 'center', width: '100%' }}>
        <S.SuffixInput
          value={action.value}
          onChange={(e) => {
            onActionChange({
              ...action,
              value: e.target.value,
            });
          }}
          placeholder="/home or https://somesite.com/path"
        />

        <SuffixContainer style={{ position: 'absolute', right: '8px', pointerEvents: 'auto' }}>
          <NudgeDropdown
            visible={isOverlayVisible}
            onVisibleChange={(visible) => setIsOverlayVisible(visible)}
            overlayWidth="auto"
            customOverlay={
              <>
                {(
                  [
                    { label: 'Open in same tab', value: 'self' },
                    { label: 'Open in a new tab', value: 'blank' },
                  ] as const
                ).map((option, index) => (
                  <OverlaySettingsOption
                    key={`${option.label}${index}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (action.type === 'link') {
                        onActionChange({ type: 'link', operation: option.value, value: action.value });
                      }
                      setIsOverlayVisible(false);
                    }}
                  >
                    <OverlayCheckIconContainer
                      visible={
                        action?.type === 'link' &&
                        ((action.operation === 'router' && option.value === 'self') ||
                          action.operation === option.value)
                      }
                    >
                      <Check />
                    </OverlayCheckIconContainer>
                    {option.label}
                  </OverlaySettingsOption>
                ))}
                <RouterOption
                  key="Use router"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (action.type === 'link') {
                      if (action.operation === 'router') {
                        onActionChange({ type: 'link', operation: 'self', value: action.value });
                      } else {
                        onActionChange({ type: 'link', operation: 'router', value: action.value });
                      }
                    }
                  }}
                >
                  <OverlayCheckIconContainer
                    visible={(action.type === 'link' ? action.operation || 'blank' : 'blank') === 'router'}
                  >
                    <Check />
                  </OverlayCheckIconContainer>
                  Use router
                  <RouterTooltip
                    content="Supply a router function to allow page changes without a reload."
                    arrow={false}
                    placement="bottom"
                  >
                    <InfoCircle
                      width={12.5}
                      height={12.5}
                      color={CB_COLORS.neutral1000}
                      style={{ transform: 'translate(6px, 2px)' }}
                    />
                  </RouterTooltip>
                </RouterOption>
              </>
            }
            caret={false}
          >
            <Settings04 />
          </NudgeDropdown>
        </SuffixContainer>
      </div>
    );
  }

  if (action?.type === 'click') {
    return <ClickInput action={action} onActionChange={onActionChange} />;
  }

  return null;
};

interface ActionSelectorProps {
  onActionChange: (action: Action) => void;
  action?: Action;
  label?: string;
  lightBorder?: boolean;
  possibleTypes?: ActionType[];
  nudge?: INudgeType;
  stepIndex?: number;
}

const ActionSelector = ({
  action,
  onActionChange,
  lightBorder,
  possibleTypes,
  nudge,
  stepIndex,
}: ActionSelectorProps) => {
  const { commands, isStudio, checklists, nudges } = useAppContext();
  const command = commands.find((c) => action?.type === 'execute_command' && c.id.toString() === action?.meta.command);
  const commandId = command?.id;
  const commandActionType = action?.type === 'execute_command' ? getCommandActionMetaType(action, command) : undefined;
  React.useEffect(() => {
    if (action?.type !== 'click') return;

    // either an empty list or an empty string
    if (action.value.length === 0) {
      setSelectorState('ok');
    } else {
      const value = typeof action.value === 'string' ? action.value : action.value[0];
      Sender.checkIfSelectorValid(value).then((result) => {
        if (result.elementFound && result.elementVisible) {
          setSelectorState('ok');
        } else if (result.elementFound && !result.elementVisible) {
          setSelectorState('invisible');
        } else {
          setSelectorState('not_found');
        }
      });
    }
  }, [action]);

  const [selectorState, setSelectorState] = React.useState<'ok' | 'invisible' | 'not_found'>('ok');

  let warning: string | null = null;

  switch (action?.type) {
    case 'execute_command':
      if (command && !command.is_live) {
        warning = `This ${commandActionType} is unpublished. Please publish it to use it here.`;
      }
      break;
    case 'click':
      if (selectorState !== 'ok' && !isStudio) {
        warning =
          selectorState === 'invisible'
            ? 'Target element found on this page, but is hidden'
            : 'Target element not found on this page';
      }
      break;
    case 'nudge':
      const selectedNudge = nudges.find((n) => n.id === action.value);
      if (selectedNudge && !selectedNudge?.is_live) {
        warning = 'This nudge is unpublished. Publish it before using it here.';
      }
      break;
    case 'questlist':
      const checklist = checklists.find((c) => c.id === action.value);
      if (checklist && !checklist?.is_live) {
        warning = 'This checklist is unpublished. Publish it before using it here.';
      }
      break;
  }

  const renderActionInput = () => {
    switch (action?.type) {
      case 'execute_command': {
        if (commandActionType === 'helpdoc') {
          return (
            <CommandSelect
              value={commandId}
              type={'help doc'}
              commandFilter={(c) => c.template.type === 'helpdoc' && !c.training_only}
              placeholder="Select article or file"
              onChange={(e) => {
                onActionChange({ type: 'execute_command', meta: { command: e.toString() } });
              }}
              commands={commands}
            />
          );
        }
        if (commandActionType === 'video') {
          return (
            <CommandSelect
              value={commandId}
              type={'video'}
              commandFilter={(c) => c.template.type === 'video'}
              placeholder="Select video..."
              onChange={(e) => {
                onActionChange({ type: 'execute_command', meta: { command: e.toString() } });
              }}
              commands={commands}
            />
          );
        }
        if (commandActionType === 'link') {
          return (
            <CommandSelect
              value={commandId}
              type={'page'}
              commandFilter={(c) => c.template.type === 'link'}
              placeholder="Select page..."
              onChange={(e) => {
                onActionChange({ type: 'execute_command', meta: { command: e.toString() } });
              }}
              commands={commands}
            />
          );
        }
        return (
          <CommandSelect
            value={commandId}
            type={'action'}
            commandFilter={(c) => !['helpdoc', 'video', 'link'].includes(c.template.type)}
            onChange={(e) => {
              onActionChange({ type: 'execute_command', meta: { command: e.toString() } });
            }}
            commands={commands}
          />
        );
      }
      case 'open_chat':
        return <SelectChatType action={action} onActionChange={onActionChange} />;
      case 'chat_handoff':
        return <SelectChatHandoffType action={action} onActionChange={onActionChange} />;
      case 'open_helphub':
        return (
          <DummyInput>
            <span>Open HelpHub</span>
          </DummyInput>
        );
      case 'open_copilot':
        return (
          <S.SuffixInput
            value={action.query}
            onChange={(e) => {
              onActionChange({
                ...action,
                query: e.target.value,
              });
            }}
            placeholder="(Optional) Type a query"
          />
        );
      case 'open_bar':
        return (
          <DummyInput>
            <span>Open Spotlight</span>
          </DummyInput>
        );
      case 'nudge':
        return (
          <SelectNudge nudges={nudges} action={action} onActionChange={onActionChange} excludeFromSelect={nudge} />
        );
      case 'go_to_step':
        return <SelectNudgeStep action={action} onActionChange={onActionChange} nudge={nudge} stepIndex={stepIndex} />;
      case 'questlist':
        return <SelectChecklist checklists={checklists} action={action} onActionChange={onActionChange} />;
      case 'step_back':
        return (
          <DummyInput>
            <span>Back</span>
          </DummyInput>
        );
      case 'dismiss':
        return (
          <DummyInput>
            <span>Complete</span>
          </DummyInput>
        );
      case 'snooze':
        return (
          <DummyInput>
            <span>Snooze</span>
          </DummyInput>
        );
      default:
        return <ComboInput action={action} onActionChange={onActionChange} />;
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'baseline', width: '100%', gap: '8px' }}>
      <S.CombinationInput lightBorder={lightBorder} style={{ minHeight: '32px' }}>
        <SelectActionType
          action={action}
          onActionChange={onActionChange}
          possibleTypes={possibleTypes}
          commandActionType={commandActionType}
        />
        {renderActionInput()}
      </S.CombinationInput>

      {warning && (
        <Tooltip placement="bottom" content={warning}>
          <ElementNotFoundIcon />
        </Tooltip>
      )}
    </div>
  );
};

interface IActionEditor {
  onActionChange: (action: Action) => void;
  action?: Action;
  showLabel?: boolean;
  lightBorder?: boolean;
  possibleTypes?: ActionType[];
  onDelete?: () => void;
  nudge?: INudgeType;
  stepIndex?: number;
}

export const SectionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

export const ActionEditor = ({
  action,
  onActionChange,
  showLabel = false,
  lightBorder = false,
  possibleTypes,
  onDelete,
  nudge,
  stepIndex,
}: IActionEditor) => {
  const Container = showLabel ? SectionContainer : React.Fragment;
  return (
    <Container>
      {showLabel && (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <StyledLabel>On button click...</StyledLabel>
          {(onDelete || action?.type === 'snooze') && (
            <div style={{ display: 'flex', gap: '4px' }}>
              {onDelete && (
                <S.MenuIconContainer
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete();
                  }}
                >
                  <Trash04 opacity={0.5} />
                </S.MenuIconContainer>
              )}
              {action?.type === 'snooze' && (
                <CmdDropdown.Menu>
                  <CmdDropdown.Trigger>
                    <S.MenuIconContainer>
                      <Settings03 opacity={0.5} />
                    </S.MenuIconContainer>
                  </CmdDropdown.Trigger>

                  <CmdDropdown.Content
                    align="end"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '8px 12px 12px',
                      gap: '4px',
                      width: '214px',
                    }}
                  >
                    <CmdLabel style={{ padding: 0 }}>Snooze duration</CmdLabel>
                    <div style={{ display: 'flex', gap: '4px' }}>
                      <div style={{ width: '86px' }}>
                        <CmdInput
                          type="number"
                          min={1}
                          value={action.value ?? 3}
                          onKeyDown={(e) => {
                            // Prevent entering '.' or ',' which are used for decimals
                            if (e.key === '.' || e.key === ',') {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            const value = Number.parseInt(e.target.value);
                            onActionChange({
                              ...action,
                              value: Number.isNaN(value) ? 1 : value,
                            });
                          }}
                          fullWidth
                        />
                      </div>

                      <CmdSelect.Menu
                        value={action.interval ?? 'day'}
                        onValueChange={(e: 'hour' | 'day' | 'week') => {
                          onActionChange({
                            ...action,
                            interval: e,
                          });
                        }}
                      >
                        <CmdSelect.SelectTrigger style={{ width: '100%', height: '32px' }}>
                          <CmdSelect.SelectValue />
                        </CmdSelect.SelectTrigger>

                        <CmdSelect.SelectContent style={{ zIndex: Z.Z_INDEX_MAX }}>
                          <CmdSelect.SelectItem value="hour">Hours</CmdSelect.SelectItem>
                          <CmdSelect.SelectItem value="day">Days</CmdSelect.SelectItem>
                          <CmdSelect.SelectItem value="week">Week</CmdSelect.SelectItem>
                        </CmdSelect.SelectContent>
                      </CmdSelect.Menu>
                    </div>
                  </CmdDropdown.Content>
                </CmdDropdown.Menu>
              )}
            </div>
          )}
        </div>
      )}
      <ActionSelector
        action={action}
        onActionChange={onActionChange}
        lightBorder={lightBorder}
        possibleTypes={possibleTypes}
        nudge={nudge}
        stepIndex={stepIndex}
      />

      {/* <Tooltip content={'This doc is not published'}>
        <AiFillWarning />
      </Tooltip> */}
    </Container>
  );
};
