import React from 'react';
import { CB_COLORS, Upload, UploadProps } from '@commandbar/design-system/components/antd';

import { DefaultView } from './DefaultView';
import { UploadingView } from './UploadingView';
import type { RcFile } from 'antd/lib/upload/';

export type { RcFile } from 'antd/lib/upload';

const { Dragger } = Upload;

interface Props extends UploadProps {
  beforeUpload: (file: RcFile, FileList: RcFile[]) => boolean;
  supportedFilesHelperText?: string;
  loading?: boolean;
}

const DragUpload = ({ supportedFilesHelperText, ...props }: Props) => {
  const [isHoveredWhileDragging, setIsHoveredWhileDragging] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(!!props.loading);
  const passedValidation = React.useRef(false);

  React.useEffect(() => {
    setIsUploading(!!props.loading);
  }, [props.loading]);

  return (
    <Dragger
      style={{
        width: '100%',
        borderRadius: '4px',
        background: isHoveredWhileDragging ? CB_COLORS.blue100 : CB_COLORS.white,
        border: `1px dashed ${isHoveredWhileDragging ? CB_COLORS.blue700 : CB_COLORS.neutral400}`,
        transition: 'border - background 0.3s',
      }}
      {...props}
      beforeUpload={(...args) => {
        if (props.beforeUpload) {
          passedValidation.current = !!props.beforeUpload(...args);

          if (passedValidation.current) {
            setIsUploading(true);
          }
        }
      }}
      onChange={(info) => {
        if (passedValidation.current) {
          if (props.onChange) {
            props.onChange(info);
          }
          if (info.file.status === 'done' || info.file.status === 'error') {
            setIsUploading(false);
          }
        }
      }}
      showUploadList={false}
    >
      <div
        id="drag-box"
        onDragEnter={() => setIsHoveredWhileDragging(true)}
        onDragLeave={() => setIsHoveredWhileDragging(false)}
        onMouseLeave={() => setIsHoveredWhileDragging(false)}
        style={{
          height: '100px',
        }}
      >
        {isUploading ? (
          <UploadingView />
        ) : (
          <DefaultView
            supportedFilesHelperText={supportedFilesHelperText}
            isHoveredWhileDragging={isHoveredWhileDragging}
          />
        )}
      </div>
    </Dragger>
  );
};

export default DragUpload;
