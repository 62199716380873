import { useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  CmdSheet,
  CmdButton,
  CmdInput,
  CmdSelect,
  CmdTextarea,
  CmdLabel,
  CmdSwitch,
  CmdTypography,
  cmdToast,
} from '@commandbar/design-system/cmd';

import { X } from '@commandbar/design-system/icons/react';
import { IUserProperty } from '@commandbar/internal/middleware/userProperty';
import { propertyTypesDisplay } from './PropertiesPage';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import isEqual from 'lodash/isEqual';

const PropertyDetailDrawer = ({
  property,
  onSave,
  close,
}: {
  property: IUserProperty;
  onSave: (newProperty: IUserProperty) => Promise<void>;
  close: () => void;
}) => {
  const { user } = useAuth();
  const hasEditPermissions = hasRequiredRole(user, 'contributor');

  const [localProperty, setLocalProperty] = useState<IUserProperty>(property);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasUnsavedChanges = !isEqual(localProperty, property);

  const updateLocalProperty = (updates: Partial<Omit<IUserProperty, 'id'>>) => {
    setLocalProperty((property) => ({
      ...property,
      ...updates,
    }));
  };

  const handleUserPropertySave = async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      await onSave(localProperty);
      cmdToast.success('Property updated');
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      cmdToast.error('Failed to update property');
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <CmdSheet.Content
      style={{ width: '560px', right: '12px' }}
      onInteractOutside={(event) => event.preventDefault()}
      closeable={false}
      overlay={true}
    >
      <CmdSheet.Header>
        <CmdSheet.Title>View Property</CmdSheet.Title>
        <CmdSheet.Close asChild disabled={isLoading}>
          <CmdButton variant="link" icon={<X />} />
        </CmdSheet.Close>
      </CmdSheet.Header>
      <CmdSheet.Body>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <CmdInput
            label="Property"
            tooltip="This is the property name sent to Command AI by your app and can’t be edited."
            fullWidth
            disabled
            value={localProperty.name}
          />

          <div>
            <CmdLabel tooltip="Data type helps the conditions builder to read this data when building conditions.">
              Type
            </CmdLabel>
            <CmdSelect.Menu
              value={localProperty.type}
              onValueChange={(value) =>
                updateLocalProperty({
                  type: value as IUserProperty['type'],
                })
              }
            >
              <CmdSelect.SelectTrigger style={{ width: '100%', marginTop: '4px' }}>
                <CmdSelect.SelectValue />
              </CmdSelect.SelectTrigger>
              <CmdSelect.SelectContent position="popper" side="bottom">
                {Object.entries(propertyTypesDisplay).map(([name, [display, Icon]]) => (
                  <CmdSelect.SelectItem key={name} value={name}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Icon /> <CmdTypography.Body variant="contentMid">{display}</CmdTypography.Body>
                    </div>
                  </CmdSelect.SelectItem>
                ))}
              </CmdSelect.SelectContent>
            </CmdSelect.Menu>
          </div>

          <CmdTextarea
            fullWidth
            label="Type"
            value={localProperty.description || ''}
            onChange={(event) =>
              updateLocalProperty({
                description: event.target.value,
              })
            }
            className="w-full"
            placeholder="Describe what this property is..."
          />

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <CmdLabel tooltip="Hide this property from menus in the Command AI dashboard.">Hidden</CmdLabel>
            <CmdSwitch
              checked={localProperty.hidden}
              onCheckedChange={(checked) =>
                updateLocalProperty({
                  hidden: checked,
                })
              }
            />
          </div>
        </div>
      </CmdSheet.Body>

      <CmdSheet.Footer divider={true}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '8px' }}>
            <CmdSheet.Cancel />
            <CmdButton
              variant="primary"
              disabled={!hasEditPermissions || isLoading || !hasUnsavedChanges}
              onClick={() => handleUserPropertySave()}
            >
              Save changes
            </CmdButton>
          </div>
        </div>
      </CmdSheet.Footer>
    </CmdSheet.Content>
  );
};

export default PropertyDetailDrawer;
