import { IAPI } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import _ from 'lodash';
import React from 'react';
import {
  Tooltip,
  Input,
  Modal,
  Header,
  PaddingContainerSM,
  StyledCollapse,
  StyledHeader,
  StyledPanel,
  PaddingContainer,
  Form,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';

import {
  Trash04,
  FlipBackward,
  AlertTriangle,
  InfoCircle,
  Edit03,
  Plus,
  DotsVertical,
} from '@commandbar/design-system/icons/react';
import {
  CmdButton,
  CmdButtonTabs,
  CmdInput,
  CmdLabel,
  CmdSwitch,
  CmdTextarea,
  CmdDropdown,
  cmdToast,
} from '@commandbar/design-system/cmd';
import { ScrollContainer } from '../Router';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { ReactComponent as CaretUp } from '../../img/caret_up.svg';
import { ReactComponent as CaretDown } from '../../img/caret_down.svg';
import AutoCompleteTextArea from '../components/AutoCompleteTextArea/AutoCompleteTextArea';
import { useReportEvent } from 'editor/src/hooks/useEventReporting';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';

const APIHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  padding: 16px;
`;

const NameInputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  position: relative;
  padding: 5px 8px;
  border-radius: 5px;

  .icon {
    position: absolute;
    right: 8px;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  &:focus-within {
    background-color: unset;
    outline: 1px solid #2046c6;
    box-shadow: 0px 0px 0px 3px rgb(39 84 238 / 16%);

    input {
      padding-right: 0px !important;
      width: 250px;
    }

    .icon {
      display: none;
      color: inherit;
    }
  }
`;

const NameInput = styled(Input, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: unset;
  padding-right: 18px !important;
  min-height: 0 !important;
  height: unset !important;
  font-size: 16px;
  line-height: 18px !important;
  color: ${CB_COLORS.neutral1000};
  border: 1px solid ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};
  resize: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  z-index: 1;
  width: 180px;
`;

const InlineRow = styled(Form.Item)`
  margin: 8px 0 0 0;
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-flow: initial;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: 0;
  }
  .ant-form-item-control {
    flex-grow: 0;
  }
`;

interface APIDetailProps {
  initialAPI: IAPI;
  onClose: () => void;
  onDelete: (API: IAPI) => void;
  onSave: (API: IAPI) => Promise<void>;
}

enum Panel {
  DETAILS,
  REQUEST,
}

const getErrors = (dirty: IAPI, isAllowedToSave: boolean) => {
  const allErrors: string[] = [];
  if (!dirty.title.trim().length || !dirty.title.trim().length || dirty.title.trim() === 'New API') {
    allErrors.push('Title is required.');
  }
  if (dirty.title.toLowerCase().trim() === 'exit') {
    allErrors.push('Title is a reserved value - choose something different.');
  }
  if (
    !dirty.url.trim().length ||
    (!dirty.url.trim().startsWith('http://') &&
      !dirty.url.trim().startsWith('https://') &&
      !dirty.url.trim().startsWith('{{'))
  ) {
    allErrors.push('Valid URL is required.');
  }
  if (!dirty.description.trim().length) {
    allErrors.push('Description is required.');
  }
  if (dirty.parameters.some((p) => !p.name.trim().length)) {
    allErrors.push('All parameters must have a key.');
  }
  if (dirty.parameters.some((p) => !p.description.trim().length)) {
    allErrors.push('All parameters must have a description.');
  }
  const keys = dirty.parameters.map((p) => p.name);
  if (keys.filter((key, index) => keys.indexOf(key) !== index).length) {
    allErrors.push('All parameters must have a unique key.');
  }
  if (dirty.require_confirmation && !dirty.confirm_cta.trim().length) {
    allErrors.push('Confirmation CTA is required.');
  }
  if (dirty.require_confirmation && !dirty.cancel_cta.trim().length) {
    allErrors.push('Cancel CTA is required.');
  }

  if (!isAllowedToSave) {
    allErrors.push('You do not have permission to save this API');
  }

  const stringParams = dirty.parameters.filter((p) => p.type === 'string').map((p) => p.name);
  const numberParams = dirty.parameters.filter((p) => p.type === 'number').map((p) => p.name);
  const booleanParams = dirty.parameters.filter((p) => p.type === 'boolean').map((p) => p.name);

  if (dirty.body.length) {
    let testBody = dirty.body;
    for (const booleanParam of booleanParams) {
      testBody = testBody.replace(new RegExp(`{{${booleanParam}}}`, 'g'), 'true');
    }
    for (const numberParam of numberParams) {
      testBody = testBody.replace(new RegExp(`{{${numberParam}}}`, 'g'), '1');
    }
    for (const stringParam of stringParams) {
      testBody = testBody.replace(new RegExp(`{{${stringParam}}}`, 'g'), 'string');
    }

    try {
      JSON.parse(testBody);
    } catch {
      allErrors.push('Body is not valid JSON.');
    }
  }

  if (dirty.headers.length) {
    let testHeaders = dirty.headers;
    for (const booleanParam of booleanParams) {
      testHeaders = testHeaders.replace(new RegExp(`{{${booleanParam}}}`, 'g'), 'true');
    }
    for (const numberParam of numberParams) {
      testHeaders = testHeaders.replace(new RegExp(`{{${numberParam}}}`, 'g'), '1');
    }
    for (const stringParam of stringParams) {
      testHeaders = testHeaders.replace(new RegExp(`{{${stringParam}}}`, 'g'), 'string');
    }

    try {
      JSON.parse(testHeaders);
    } catch {
      allErrors.push('Headers are not valid JSON.');
    }
  }

  const allKeys: string[] = dirty.parameters.map((p) => p.name);

  // Check that parameters keys used in URL are defined
  const urlKeys: string[] = dirty.url.match(/{{[^}]+}}/g) || [];
  let missingUrlKeys = urlKeys.filter((key) => !keys.includes(key.slice(2, -2)));
  missingUrlKeys = missingUrlKeys.filter((key) => !key.startsWith('{{user_properties.'));
  if (missingUrlKeys.length) {
    allErrors.push(`URL uses undefined parameters: ${missingUrlKeys.join(', ')}`);
  }

  // Check that parameters keys used in body are defined
  const bodyKeys: string[] = dirty.body.match(/{{[^}]+}}/g) || [];
  let missingBodyKeys = bodyKeys.filter((key) => !keys.includes(key.slice(2, -2)));
  missingBodyKeys = missingBodyKeys.filter((key) => !key.startsWith('{{user_properties.'));
  if (missingBodyKeys.length) {
    allErrors.push(`Body uses undefined parameters: ${missingBodyKeys.join(', ')}`);
  }

  // Check that parameters keys used in headers are defined
  const headerKeys: string[] = dirty.headers.match(/{{[^}]+}}/g) || [];
  let missingHeaderKeys = headerKeys.filter((key) => !keys.includes(key.slice(2, -2)));
  missingHeaderKeys = missingHeaderKeys.filter((key) => !key.startsWith('{{user_properties.'));
  if (missingHeaderKeys.length) {
    allErrors.push(`Headers use undefined parameters: ${missingHeaderKeys.join(', ')}`);
  }

  // Check that all parameters are used in URL, body, or header
  const unusedKeys: string[] = allKeys.filter(
    (key) => !urlKeys.includes(`{{${key}}}`) && !bodyKeys.includes(`{{${key}}}`) && !headerKeys.includes(`{{${key}}}`),
  );
  if (unusedKeys.length) {
    allErrors.push(`Unused parameters: ${unusedKeys.join(', ')}`);
  }

  if (dirty.description.length > 1000) {
    // We can't increase this bc GPT has a 1024 character limit for tool descriptions
    allErrors.push('Description must be 1000 characters or less.');
  }

  return allErrors;
};

const APIDetail = (props: APIDetailProps) => {
  const { onClose, initialAPI, onDelete, onSave } = props;
  const { hasUnsavedChangesRef } = useAppContext();

  const [dirty, setDirty] = React.useState(() => _.cloneDeep(initialAPI));

  const { user } = useAuth();

  const [isSaving, setIsSaving] = React.useState(false);
  const [activeKeys, setActiveKeys] = React.useState<Panel[]>([Panel.DETAILS, Panel.REQUEST]);
  const [activeTab, setActiveTab] = React.useState('parameters');
  const { reportEvent } = useReportEvent();

  const isNewAPI = initialAPI.id < 0;
  const isDirty = !_.isEqual(initialAPI, dirty) || isNewAPI;

  React.useEffect(() => {
    hasUnsavedChangesRef.current = isDirty;
  }, [isDirty]);

  const saveAPI = async (API: IAPI) => {
    setIsSaving(true);
    try {
      await onSave(API);
    } catch {
      cmdToast.error('Error saving API');
    } finally {
      setIsSaving(false);
    }
  };

  useModS(() => saveAPI(dirty));

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, props.initialAPI.is_live ? 'editor' : 'contributor');

  const allErrors = getErrors(dirty, isAllowedToSave);

  return (
    <ScrollContainer>
      <APIHeader>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <CmdButton onClick={onClose}>
            <FlipBackward />
          </CmdButton>
          <NameInputContainer>
            <NameInput
              value={dirty.title}
              onChange={(e) => setDirty({ ...dirty, title: e.target.value })}
              bordered={false}
              spellCheck={false}
              placeholder="title"
            />
            <Edit03 className="icon" />
          </NameInputContainer>
        </div>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Tooltip content={"You don't have permission to save this API"} showIf={!isAllowedToPublish} placement="top">
            <CmdSwitch
              checked={!!dirty.is_live}
              onCheckedChange={async (e: boolean) => {
                setDirty({ ...dirty, is_live: e });

                if (e) {
                  reportEvent('API published', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: dirty.title,
                  });
                }
              }}
              onLabel="Live"
              offLabel="Draft"
              disabled={!isAllowedToPublish}
            />
          </Tooltip>

          {dirty.id !== -1 && isAllowedToSave ? (
            <CmdButton
              variant="link"
              onClick={() => {
                Modal.confirm({
                  icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                  title: `Are you sure you'd like to delete '${dirty.title}'?`,
                  async onOk() {
                    try {
                      onDelete(initialAPI);
                    } catch {
                      cmdToast.error('Error deleting API');
                    }
                  },
                });
              }}
              icon={<Trash04 />}
            />
          ) : (
            <div style={{ width: 12 }} />
          )}
          <Tooltip showIf={allErrors.length > 0} content={allErrors.join('\n')} placement="left">
            <CmdButton
              onClick={() => saveAPI(dirty)}
              disabled={!isDirty || isSaving || allErrors.length > 0}
              variant={'primary'}
              style={{
                ...(allErrors.length > 0 && {
                  border: '1px solid rgb(185, 28, 28)',
                  boxShadow: '0px 0px 0px 2px rgba(185, 28, 28, .3)',
                }),
              }}
            >
              Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
            </CmdButton>
          </Tooltip>
        </div>
      </APIHeader>

      <PaddingContainer>
        <StyledCollapse
          onChange={() => {
            if (activeKeys.includes(Panel.DETAILS)) {
              setActiveKeys(activeKeys.filter((key) => key !== Panel.DETAILS));
            } else {
              setActiveKeys([...activeKeys, Panel.DETAILS]);
            }
          }}
          activeKey={activeKeys}
          expandIcon={() => null}
        >
          <StyledPanel
            key={Panel.DETAILS}
            header={
              <StyledHeader>
                <Header>
                  <span>Details</span>
                  {activeKeys.includes(Panel.DETAILS) ? (
                    <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                  ) : (
                    <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                  )}
                </Header>
              </StyledHeader>
            }
          >
            <PaddingContainerSM style={{ display: 'flex', gap: 0, flexDirection: 'column' }}>
              <div style={{ display: 'flex', gap: 4, flexDirection: 'column', marginTop: -8 }}>
                <CmdLabel
                  tooltip={`Copilot will use this description (along with the API's name and parameters) to determine when to use the API. Clearly what the API does — we recommend 1-2 sentences.`}
                >
                  API Description
                </CmdLabel>
                <CmdTextarea
                  name="prompt"
                  value={dirty.description}
                  onChange={(e) => setDirty({ ...dirty, description: e.target.value })}
                  placeholder='Clearly describe your API, e.g. "Check if a given domain name is available for purchase."'
                  fullWidth
                  style={{
                    border: `1px solid ${CB_COLORS.neutral300}`,
                    height: '64px',
                    resize: 'vertical',
                  }}
                />
              </div>
              <InlineRow
                style={{ marginTop: 16 }}
                colon={false}
                label="Require confirmation"
                name="require_confirmation"
                tooltip={{
                  icon: <InfoCircle height={16} />,
                  title: 'When enabled, Copilot will always ask the user for confirmation before using this API.',
                }}
              >
                <CmdSwitch
                  checked={dirty.require_confirmation}
                  onCheckedChange={(value) => {
                    setDirty({ ...dirty, require_confirmation: value });
                  }}
                />
              </InlineRow>
              {dirty.require_confirmation && (
                <div style={{ display: 'flex', gap: 16, flexDirection: 'row', marginTop: 16, marginBottom: 16 }}>
                  <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
                    <CmdLabel tooltip="The text displayed in the confirmation button.">Confirm CTA</CmdLabel>
                    <CmdInput
                      value={dirty.confirm_cta}
                      onChange={(e) => setDirty({ ...dirty, confirm_cta: e.target.value })}
                      placeholder="Confirm"
                      maxLength={20}
                      fullWidth
                    />
                  </div>
                  <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
                    <CmdLabel tooltip="The text displayed in the cancel button.">Cancel CTA</CmdLabel>
                    <CmdInput
                      value={dirty.cancel_cta}
                      onChange={(e) => setDirty({ ...dirty, cancel_cta: e.target.value })}
                      placeholder="Cancel"
                      maxLength={20}
                      fullWidth
                    />
                  </div>
                </div>
              )}
              <InlineRow
                colon={false}
                label="Only allow in Workflows"
                name="workflows_only"
                tooltip={{
                  icon: <InfoCircle height={16} />,
                  title: 'When enabled, Copilot will only call this API from Workflows that reference it.',
                }}
              >
                <CmdSwitch
                  checked={dirty.workflows_only}
                  onCheckedChange={(value) => {
                    setDirty({ ...dirty, workflows_only: value });
                  }}
                />
              </InlineRow>
            </PaddingContainerSM>
          </StyledPanel>
        </StyledCollapse>
        <StyledCollapse
          onChange={() => {
            if (activeKeys.includes(Panel.REQUEST)) {
              setActiveKeys(activeKeys.filter((key) => key !== Panel.REQUEST));
            } else {
              setActiveKeys([...activeKeys, Panel.REQUEST]);
            }
          }}
          activeKey={activeKeys}
          expandIcon={() => null}
        >
          <StyledPanel
            key={Panel.REQUEST}
            header={
              <StyledHeader>
                <Header>
                  <span>Request</span>
                  {activeKeys.includes(Panel.REQUEST) ? (
                    <CaretUp style={{ color: CB_COLORS.neutral600 }} />
                  ) : (
                    <CaretDown style={{ color: CB_COLORS.neutral600 }} />
                  )}
                </Header>
              </StyledHeader>
            }
          >
            <PaddingContainerSM>
              <div style={{ display: 'flex', gap: 16, flexDirection: 'row', marginTop: -8 }}>
                <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
                  <CmdLabel>Method</CmdLabel>
                  <CmdDropdown.Menu>
                    <CmdDropdown.SelectTrigger>{dirty.method}</CmdDropdown.SelectTrigger>
                    <CmdDropdown.Content>
                      <CmdDropdown.RadioGroup
                        value={dirty.method}
                        onValueChange={(v) => setDirty({ ...dirty, method: v as IAPI['method'] })}
                      >
                        <CmdDropdown.RadioItem key="GET" value="GET">
                          GET
                        </CmdDropdown.RadioItem>
                        <CmdDropdown.RadioItem key="POST" value="POST">
                          POST
                        </CmdDropdown.RadioItem>
                        <CmdDropdown.RadioItem key="PUT" value="PUT">
                          PUT
                        </CmdDropdown.RadioItem>
                        <CmdDropdown.RadioItem key="PATCH" value="PATCH">
                          PATCH
                        </CmdDropdown.RadioItem>
                        <CmdDropdown.RadioItem key="DELETE" value="DELETE">
                          DELETE
                        </CmdDropdown.RadioItem>
                      </CmdDropdown.RadioGroup>
                    </CmdDropdown.Content>
                  </CmdDropdown.Menu>
                </div>
                <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 3 }}>
                  <CmdLabel tooltip="The URL Copilot will send the request to. Interpolate paramets into this with e.g. {{userId}}.">
                    Endpoint
                  </CmdLabel>
                  <div style={{ marginTop: -2 }}>
                    <AutoCompleteTextArea
                      small={true}
                      placeholder="https://api.example.com/endpoint?query={{paramKey}}"
                      value={dirty.url}
                      onChange={(s) => setDirty({ ...dirty, url: s })}
                      options={dirty.parameters.map((p) => ({ value: p.name, addOn: `` }))}
                    />
                  </div>
                </div>
              </div>
              <div>
                <CmdButtonTabs
                  variant="group"
                  activeKey={activeTab}
                  onChange={(t) => setActiveTab(t)}
                  tabs={[
                    { label: 'Parameters', key: 'parameters' },
                    { label: 'Body', key: 'body' },
                    { label: 'Headers', key: 'headers' },
                  ]}
                />
                <div style={{ marginTop: 16 }}>
                  {activeTab === 'parameters' && (
                    <>
                      {dirty.parameters.length > 0 && (
                        <div style={{ display: 'flex', gap: 8, flexDirection: 'row', marginBottom: 8 }}>
                          <div style={{ flex: '0 0 95px' }}>
                            {' '}
                            <CmdLabel>Type</CmdLabel>
                          </div>
                          <div style={{ flex: '1 1 calc((100% - 125px) * 0.4)' }}>
                            {' '}
                            <CmdLabel>Key</CmdLabel>
                          </div>
                          <div style={{ flex: '1 1 calc((100% - 125px) * 0.6)' }}>
                            <CmdLabel>Description</CmdLabel>
                          </div>
                          <div style={{ flex: '0 0 30px' }} />
                        </div>
                      )}

                      {dirty.parameters.map((parameter, index) => (
                        <div key={index} style={{ display: 'flex', gap: 8, flexDirection: 'row', marginTop: 8 }}>
                          <div style={{ flex: '0 0 95px' }}>
                            {' '}
                            <CmdDropdown.Menu>
                              <CmdDropdown.SelectTrigger>
                                {parameter.type.charAt(0).toUpperCase() + parameter.type.slice(1)}
                              </CmdDropdown.SelectTrigger>
                              <CmdDropdown.Content>
                                <CmdDropdown.RadioGroup
                                  value={parameter.type}
                                  onValueChange={(v) => {
                                    const newParameters = _.cloneDeep(dirty.parameters);
                                    newParameters[index].type = v as IAPI['parameters'][0]['type'];
                                    setDirty({ ...dirty, parameters: newParameters });
                                  }}
                                >
                                  <CmdDropdown.RadioItem key="string" value="string">
                                    String
                                  </CmdDropdown.RadioItem>
                                  <CmdDropdown.RadioItem key="number" value="number">
                                    Number
                                  </CmdDropdown.RadioItem>
                                  <CmdDropdown.RadioItem key="boolean" value="boolean">
                                    Boolean
                                  </CmdDropdown.RadioItem>
                                </CmdDropdown.RadioGroup>
                              </CmdDropdown.Content>
                            </CmdDropdown.Menu>
                          </div>
                          <div style={{ flex: '1 1 calc((100% - 125px) * 0.4)' }}>
                            {' '}
                            <CmdInput
                              value={parameter.name}
                              onChange={(e) => {
                                const newParameters = _.cloneDeep(dirty.parameters);
                                newParameters[index].name = e.target.value;
                                setDirty({ ...dirty, parameters: newParameters });
                              }}
                              placeholder="Key"
                              fullWidth
                            />
                          </div>
                          <div style={{ flex: '1 1 calc((100% - 125px) * 0.6)' }}>
                            {' '}
                            <CmdInput
                              value={parameter.description}
                              onChange={(e) => {
                                const newParameters = _.cloneDeep(dirty.parameters);
                                newParameters[index].description = e.target.value;
                                setDirty({ ...dirty, parameters: newParameters });
                              }}
                              placeholder="Description"
                              fullWidth
                            />
                          </div>
                          <div style={{ flex: '0 0 30px' }}>
                            <CmdDropdown.Menu>
                              <CmdDropdown.SelectTrigger asSplitButton hideArrow>
                                <DotsVertical style={{ marginLeft: -4, marginRight: -4 }} />
                              </CmdDropdown.SelectTrigger>
                              <CmdDropdown.Content style={{ width: '140px', marginLeft: '-104px' }}>
                                <CmdDropdown.RadioGroup>
                                  <CmdDropdown.Item>
                                    Required
                                    <CmdDropdown.ItemAction>
                                      <CmdSwitch
                                        checked={parameter.required}
                                        onCheckedChange={(e) => {
                                          const newParameters = _.cloneDeep(dirty.parameters);
                                          newParameters[index].required = e;
                                          setDirty({ ...dirty, parameters: newParameters });
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </CmdDropdown.ItemAction>
                                  </CmdDropdown.Item>
                                  <CmdDropdown.Separator />
                                  <CmdDropdown.Item
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const newParameters = _.cloneDeep(dirty.parameters);
                                      newParameters.splice(index, 1);
                                      setDirty({ ...dirty, parameters: newParameters });
                                    }}
                                  >
                                    <Trash04 /> Delete
                                  </CmdDropdown.Item>
                                </CmdDropdown.RadioGroup>
                              </CmdDropdown.Content>
                            </CmdDropdown.Menu>
                          </div>
                        </div>
                      ))}
                      <div
                        style={
                          dirty.parameters.length
                            ? { marginTop: 16 }
                            : {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: 80,
                                background: CB_COLORS.neutral0,
                                borderRadius: 8,
                              }
                        }
                      >
                        <CmdButton
                          icon={<Plus />}
                          onClick={() => {
                            const newParameters = _.cloneDeep(dirty.parameters);
                            newParameters.push({
                              archived: false,
                              name: '',
                              type: 'string',
                              description: '',
                              required: true,
                            });
                            setDirty({ ...dirty, parameters: newParameters });
                          }}
                        >
                          Add parameter
                        </CmdButton>
                      </div>
                    </>
                  )}
                  {activeTab === 'body' && (
                    <div>
                      <AutoCompleteTextArea
                        placeholder="{}"
                        value={dirty.body}
                        onChange={(s) => {
                          try {
                            setDirty({ ...dirty, body: s });
                          } catch {
                            // Ignore
                          }
                        }}
                        options={dirty.parameters.map((p) => ({ value: p.name, addOn: `` }))}
                        codeEditor
                      />
                    </div>
                  )}
                  {activeTab === 'headers' && (
                    <div>
                      <AutoCompleteTextArea
                        placeholder="{}"
                        value={dirty.headers}
                        onChange={(s) => {
                          try {
                            setDirty({ ...dirty, headers: s });
                          } catch {
                            // Ignore
                          }
                        }}
                        options={dirty.parameters.map((p) => ({ value: p.name, addOn: `` }))}
                        codeEditor
                      />
                    </div>
                  )}
                </div>
              </div>
            </PaddingContainerSM>
          </StyledPanel>
        </StyledCollapse>
      </PaddingContainer>
    </ScrollContainer>
  );
};

export default APIDetail;
