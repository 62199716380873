import React from 'react';
import styled from '@emotion/styled';
import { cmdToast } from '@commandbar/design-system/cmd';

const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 100%;
  height: 62px;

  background: #080c1c;
  border-radius: 0px 4px 4px 4px;
`;

const Code = styled.div`
  font-family: 'Monaco';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: -0.02em;

  color: #ffc979;
`;

const Copy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 8px;

  width: 60px;
  height: 30px;

  background: #393d49;
  border-radius: 4px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #ffffff;
  cursor: pointer;
`;

const COPY_SUCCESS_MESSAGE = 'Code copied to clipboard.';
const COPY_ERROR_MESSAGE = 'Failed to copy code to clipboard.';
export const CodeLine = ({ code }: { code: string }) => {
  const onCopyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code);
      cmdToast.info(COPY_SUCCESS_MESSAGE);
    } catch (e) {
      cmdToast.error(COPY_ERROR_MESSAGE);
    }
  };

  return (
    <CodeContainer>
      <Code>{code}</Code>
      <Copy onClick={onCopyCodeToClipboard}>Copy</Copy>
    </CodeContainer>
  );
};
