import React, { useEffect, useState } from 'react';
import { Layout, Steps, Typography, Page } from '@commandbar/design-system/components/antd';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { useAppContext } from 'editor/src/AppStateContext';
import * as Organization from '@commandbar/internal/middleware/organization';
import { SegmentWebhookEntryStep } from './SegmentWebhookEntryStep';
import { SegmentWebhookSharedSecretStep } from './SegmentWebhookSharedSecretStep';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Initialize',
    stepHeading: 'Initialize the webhook destination',
    component: SegmentWebhookEntryStep,
  },
  {
    title: 'Finish',
    stepHeading: 'Finish the setup.',
    component: SegmentWebhookSharedSecretStep,
  },
];

export interface ISegmentWebhookIntegrationProps {
  setSegmentWebhookConfig: React.Dispatch<React.SetStateAction<ISegmentWebhookConfig | undefined>>;
  segmentWebhookConfig: ISegmentWebhookConfig | undefined;
  loading: boolean;
}

const SegmentWebhookSetupSteps = (props: ISegmentWebhookIntegrationProps) => {
  const stepMarker = (dot: any) => <>{dot}</>;
  const [stepIdx, setStepIdx] = useState<number>(0);

  const { stepHeading, component: CurrentStepComponent } = STEPS[stepIdx];

  const { reportEvent } = useReportEvent();

  const handlePrev = () => {
    setStepIdx((stepIdx) => Math.max(stepIdx - 1, 0));
    reportEvent('segment-webhook integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Prev Step',
    });
  };

  const handleNext = () => {
    setStepIdx((stepIdx) => Math.min(stepIdx + 1, STEPS.length - 1));
    reportEvent('segment-webhook integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Next Step',
    });
  };

  return (
    <div>
      <Steps current={stepIdx} progressDot={stepMarker}>
        {STEPS.map((e: any, idx: number) => (
          <Step key={`step-${idx}`} title={e.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Typography.Title level={4}>
            {stepIdx + 1}. {stepHeading}
          </Typography.Title>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <CurrentStepComponent handlePrev={handlePrev} handleNext={handleNext} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ISegmentWebhookConfig {
  shared_secret: string;
}

const SegmentWebhookIntegrationPage = () => {
  const { organization } = useAppContext();

  const { reportEvent } = useReportEvent();
  const [loading, setLoading] = useState(false);

  const [segmentWebhookConfig, setSegmentWebhookConfig] = useState<ISegmentWebhookConfig | undefined>(undefined);

  useEffect(() => {
    reportEvent('segment-webhook integration visited', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    Organization.readInternal(`${organization.id}`).then(async ({ integrations }) => {
      const { segment_webhook } = integrations;

      if (segment_webhook && segment_webhook.shared_secret) {
        setSegmentWebhookConfig({ shared_secret: segment_webhook.shared_secret });
      }

      setLoading(false);
    });
  }, []);

  return (
    <Page
      section="Integrations"
      title="Segment Webhook"
      description="Send data from Segment to Command AI server-side."
    >
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        <SegmentWebhookSetupSteps
          loading={loading}
          segmentWebhookConfig={segmentWebhookConfig}
          setSegmentWebhookConfig={setSegmentWebhookConfig}
        />
      </Layout.Content>
    </Page>
  );
};

export default SegmentWebhookIntegrationPage;
