import chroma from 'chroma-js';
import { IBaseVars, IPartialTheme, ITheme, mergeTheme, toPx } from '../theme';
import generateBaseTheme from './base';

const generateInlineBaseTheme = (baseVars: IBaseVars): ITheme => {
  const inactiveFontColor = chroma.mix(baseVars.background, baseVars.fontColor, 0.95);
  const inactiveBackgroundColor = chroma.mix(baseVars.background, baseVars.fontColor, 0.05).alpha(1); // alpha(1) so it's not translucent
  const menuBorderColor = chroma.mix(baseVars.background, baseVars.fontColor, 0.2);
  const inputBorderColor = chroma.mix(baseVars.background, baseVars.fontColor, 0.4);

  const inlineBase: IPartialTheme = {
    main: {
      boxShadow: 'none', // remove the shadow
      background: 'rgba(255,255,255,0)', // make the background transparent so the space between input & menu is visible
    },
    input: {
      paddingTop: '10px', // smaller padding
      paddingBottom: '10px', // smaller padding
      fontSize: toPx(baseVars.fontSize), // smaller input
      background: baseVars.background.css(), // add a background to input now that the main background is transparent
      borderBottom: `1px solid ${inputBorderColor}`,
      border: `1px solid ${inputBorderColor}`,
      borderRadius: '4px',
      paddingLeft: '14px', // reduced left padding
      paddingRight: '14px', // reduce right padding to match left
      // add inactive colors when the menu is closed
      backgroundInactive: inactiveBackgroundColor.css(),
      colorInactive: inactiveFontColor.css(),
      borderBottomInactive: `1px solid ${inputBorderColor}`,
    },
    placeholder: {
      fontSize: toPx(baseVars.fontSize), // smaller placeholder
    },
    option: {
      descriptionMarginTop: '-1px', // looks better with more space than default
      minHeight: '37px', // smaller rows
    },
    menu: {
      marginTop: '4px', // space between input and menu
      border: `1px solid ${menuBorderColor}`,
      borderRadius: '4px',
      background: baseVars.background.css(), // add a background to menu now that main background is transparent
    },
    textSearchMatch: {
      textUnderlinePosition: 'under', // looks better with more space than defualt
    },
    logo: {
      size: '14px',
      spaceBetweenLogoAndText: '9px',
    },
    categoryHeader: {
      background: chroma.mix(baseVars.background, baseVars.fontColor, 0.05).css(),
    },
  };

  return mergeTheme(inlineBase, generateBaseTheme(baseVars));
};

export default generateInlineBaseTheme;
