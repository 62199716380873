import styled from '@emotion/styled';
import React from 'react';

const NudgeStepCount = styled.div`
  color: var(--content-secondary);
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);
`;

const StyledNudgeStepCounter = ({ stepCount }: { stepCount: string }) => (
  <NudgeStepCount data-testid="commandbar-nudge-step-counter">{stepCount}</NudgeStepCount>
);

export default StyledNudgeStepCounter;
