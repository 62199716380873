import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { AudienceCondition as AudienceConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { ConditionProps } from '../../types';

export const AudienceCondition: React.FC<ConditionProps<AudienceConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();
  const { rules } = useAppContext();

  const audiences = rules.filter((r) => r.is_audience);

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />

      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.audience_id === -1 ? null : condition.audience_id}
          options={audiences.map((audience) => ({ value: audience.id, label: audience.name }))}
          onChange={(value) => {
            onConditionChange({ ...condition, audience_id: value as number });
          }}
          style={{ maxWidth: '100%', width: '100%' }}
          dropdownMatchSelectWidth={false}
          size="small"
          onBlur={() => setHasBlurred(true)}
        />
      </Col>
    </Row>
  );
};
