import React, { ReactNode } from 'react';
import { DetailsHeader } from '../../components/DetailsHeader';

interface IProps {
  onGoBack: (() => void) | undefined;
  actions: ReactNode[];
  content: ReactNode;
  title: ReactNode;
  subTitle?: ReactNode;
  isRecordAction: boolean;
  showInDefaultList: boolean;
  type?: string;
}

const DetailLayout = (props: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'white',
        gap: props.showInDefaultList ? 16 : 0,
      }}
    >
      <DetailsHeader
        subTitle={props.subTitle ?? (props.isRecordAction || props.type !== 'link' ? 'Action details' : 'Page details')}
        title={props.title}
        titlePlaceholder={props.isRecordAction || props.type !== 'link' ? 'New action...' : 'New page...'}
        onBackPress={props.onGoBack}
        actions={props.actions}
      />

      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

export default DetailLayout;
