/** @jsx jsx  */
import React, { ReactElement, useCallback, useRef } from 'react';
import { css, jsx } from '@emotion/core';
import chroma from 'chroma-js';

import { useAction } from 'shared/util/hooks/useAction';
import { useStyles } from './useStyles';
import useHasScroll from 'shared/util/hooks/useHasScroll';
import { useStore } from 'shared/util/hooks/useStore';

import * as Reporting from 'shared/services/analytics/Reporting';
import Logger from '@commandbar/internal/util/Logger';
import { EngagementTracking } from 'shared/components/EngagementTracking';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { removeDeepLinkParams } from '../store/helpers';

import { ArrowUpRight, Check, ChevronLeft, Link04 } from '@commandbar/design-system/icons/react';
import VideoPreview from 'shared/components/video/VideoContainer';

import { HelpHubDoc } from 'shared/store/global-store';
import type { ICommandType } from '@commandbar/internal/middleware/types';
import type { LabeledAction } from '@cb/types/entities/command/actions';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import { isAction } from '@cb/types/entities/command/actions';
import * as GlobalActions from 'shared/store/global-actions';
import * as HelpHubActions from '../store/actions';
import { getSentry } from '@commandbar/internal/util/sentry';
import helpdocService from 'shared/services/services/helpdocService';
import { useRouter, useParams } from 'shared/util/router';
import LoadingIndicator from 'shared/components/LoadingIndicator';
import { useMobileExperience } from 'shared/util/hooks/useMobileExperience';
import { isStudioPreview } from '@commandbar/internal/util/location';
import ZendeskDoc from './ZendeskDoc';
import useTheme from '@commandbar/commandbar/shared/util/hooks/useTheme';
import StyledHelpHub from '@commandbar/internal/client/themesV2/components/helphub/StyledHelpHub';
import StyledHeader from '@commandbar/internal/client/themesV2/components/helphub/StyledHeader';
import StyledDoc from '@commandbar/internal/client/themesV2/components/helphub/StyledDoc';
import StyledDocTitle from '@commandbar/internal/client/themesV2/components/helphub/StyledDocTitle';
import StyledDocContent from '@commandbar/internal/client/themesV2/components/helphub/StyledDocContent';
import StyledButtonGroup from '@commandbar/internal/client/themesV2/components/shared/StyledButtonGroup';
import StyledNudgeVideo from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeVideo';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import StyledSecondaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryIconButton';
import getPlayer from '@commandbar/commandbar/shared/components/video/players';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';

import { interpolate } from '@commandbar/commandbar/shared/util/Interpolate';
import { KeywordsContainer } from '../../../shared/components/keywords/KeywordsContainer';

export enum DEEP_LINK_PARAMS {
  COMMAND_ID = 'helphub_id',
  QUERY = 'helphub_query',
}

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    getSentry()?.captureException(err);
    Logger.error('Failed to copy text to clipboard:', err);
  }
};

const generateDeepLink = (commandID: number | string, query: string | null) => {
  const currentUrl = new URL(window.location.href);

  const urlParams = currentUrl.searchParams;
  urlParams.set(DEEP_LINK_PARAMS.COMMAND_ID, encodeURIComponent(commandID));
  urlParams.set(DEEP_LINK_PARAMS.QUERY, encodeURIComponent(query || ''));

  return currentUrl.toString();
};

export const isHelpDocWithLink = (
  template: ICommandType['template'],
): template is ICommandType['template'] & { type: 'helpdoc' } => !!template.value && template.type === 'helpdoc';

const Doc = () => {
  const { docId } = useParams<{ docId: string }>();
  const { back, navigate } = useRouter();

  const _ = useStore();
  const query = _.helpHub.query;
  const styles = useStyles();
  const { isStudioMobilePreview } = useMobileExperience();
  const [doc, setDoc] = React.useState<HelpHubDoc | null>(null);

  const hasScrolledCurrentDocRef = useRef(false);

  const [startTime] = React.useState<number>(Date.now());
  const [showCheckIconCopyLink, setShowCheckIconCopyLink] = React.useState(false);

  const docTemplate = doc?.command.template;
  const nextSteps = doc?.command.next_steps || [];

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { hasScrolled } = useHasScroll(scrollRef);
  const themeV2 = useThemeV2Context();

  const resetHelpHubScrollSettings = useAction((_) => {
    _.helpHub.hubDocHeading = null;
  });

  const setHelpHubMinimized = useAction(HelpHubActions.setHelpHubMinimized);

  const executeAction = useAction(
    (_, action: LabeledAction['action'], e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      let shouldMinimizeHelpHub = true;
      if (action.type === 'execute_command') {
        const command = _.commands.find((c) => c.id === parseInt(action.meta.command));
        if (command?.template.type === 'helpdoc') {
          shouldMinimizeHelpHub = false;
        }
      }
      if (shouldMinimizeHelpHub) {
        setHelpHubMinimized(true);
      }
      GlobalActions.executeAction(_, action, e);
    },
  );

  React.useEffect(() => {
    if (_.organization && docId) {
      hasScrolledCurrentDocRef.current = false;
      helpdocService.getHelpdoc(_, docId, query ?? undefined).then((doc) => {
        setDoc(doc);
      });
    }
  }, [docId]);

  const scrollToFirstMark = (container: HTMLElement): boolean => {
    // Find the first <mark> tag inside the container
    const firstMark = container.querySelector('mark');

    if (firstMark) {
      // Scroll the container to the first mark element
      firstMark.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    updateScrollPosition();
  }, [_.helpHub.hubDocHeading]);

  const updateScrollPosition = () => {
    let scrolled = false;

    const performScroll = () => {
      if (scrollRef.current) {
        if (_.helpHub.hubDocHeading) {
          scrolled = findAndScrollToHeaderBasedOnHeading(_.helpHub.hubDocHeading || '');
        } else if (!hasScrolledCurrentDocRef.current) {
          scrolled = scrollToFirstMark(scrollRef.current);
        }

        if (scrolled) {
          resetHelpHubScrollSettings();
          hasScrolledCurrentDocRef.current = true;
        }
      }
    };

    setTimeout(() => {
      performScroll();
    }, 150);
  };

  const setScrollRef = useCallback((node: HTMLDivElement) => {
    scrollRef.current = node;
    updateScrollPosition();
  }, []);

  const command = doc?.command;

  const docEventData = {
    query: query ?? undefined,
    command: command?.id,
    third_party_id: command?.third_party_id,
    url: command?.template.type === 'helpdoc' ? command.template.value : undefined,
    title: command?.text,
  };

  React.useEffect(() => {
    if (hasScrolled) {
      Reporting.helpHubDocEngagement({ ...docEventData }, 'scrolled');
      Track.content.scrolled(_.helpHub);
    }
  }, [hasScrolled]);

  React.useEffect(() => {
    if (doc) {
      Reporting.helpHubDocOpened({ ...docEventData });
      Track.content.viewed(_.helpHub);

      if (scrollRef.current) {
        const h1Element = scrollRef.current.querySelector('h1');
        if (h1Element?.innerText === doc.command.text) {
          h1Element.style.display = 'none';
        }
      }
    }
  }, [doc]);

  React.useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        back();
        removeDeepLinkParams();
      }
    };
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const scrollToXPath = (xpath: string) => {
    if (scrollRef.current && xpath.length > 1) {
      const element = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null,
      ).singleNodeValue;
      if (element) {
        (element as HTMLElement).innerHTML = `<mark>${element.textContent}</mark>`;
        (element as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'center' });
        return true;
      }
    }
    return false;
  };

  const findAndScrollToHeaderBasedOnAnchor = (anchor: string) => {
    if (anchor.length > 1) {
      const xpath = `//*[(self::h1 or self::h2 or self::h3 or self::h4 or self::h5 or self::h6 or self::a) and (translate(text(), 'ABCDEFGHIJKLMNOPQRSTUVWXYZ ', 'abcdefghijklmnopqrstuvwxyz-')='${anchor}' or @id='${anchor}')]`;
      return scrollToXPath(xpath);
    }
    return false;
  };
  const findAndScrollToHeaderBasedOnHeading = (heading: string) => {
    if (heading.length > 1) {
      const xpath = `//*[self::h1 or self::h2 or self::h3 or self::h4 or self::h5 or self::h6][text()='${heading}']`;

      return scrollToXPath(xpath);
    }
    return false;
  };

  const anchorClickHandler = (event: Event) => {
    Reporting.helpHubDocEngagement({ ...docEventData }, 'clicked_link');
    if ((event as PointerEvent).metaKey || (event as PointerEvent).ctrlKey || !_.organization?.id) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();

    /* eslint-disable commandbar/no-event-target */
    const fullLink = (event?.currentTarget as HTMLLinkElement)?.getAttribute('href');
    const [link, anchor]: string[] = fullLink ? fullLink.split('#') : [];

    Track.content.linkClicked(_.helpHub, fullLink || '');

    if (!link) {
      if (!!anchor) {
        return findAndScrollToHeaderBasedOnAnchor(anchor);
      }
      return;
    }

    helpdocService
      .getHelpdocByUrl(_.organization.id, link)
      .then((hubDoc) => {
        if (hubDoc) {
          navigate({
            page: 'docs',
            params: {
              docId: hubDoc.command.id,
            },
          });

          setTimeout(() => {
            if (anchor && findAndScrollToHeaderBasedOnAnchor(anchor)) {
            } else {
              scrollRef.current?.scrollTo(0, 0);
            }
          }, 600);
        } else {
          window.open(link);
        }
      })
      .catch(() => {
        window.open(link);
      });
  };

  const videoPlayHandler = () => {
    Reporting.helpHubDocEngagement({ ...docEventData }, 'watched_video');
    Track.content.playClicked(_.helpHub);
  };

  const openArticleLink = (value: string) => {
    if (isStudioPreview()) {
      window.open(value, '_blank');
    } else {
      switch (doc?.command.template.operation) {
        case 'self':
          return window.open(value, '_self');
        case 'blank':
        case 'router':
        default:
          return window.open(value, '_blank');
      }
    }
  };

  const renderContent = (content: HelpHubDoc['command']['content']) => {
    const standardized = standardize(content);
    if (!standardized) return null;
    return standardized.map((contentBlock, index) => {
      switch (contentBlock.type) {
        case 'html':
          return _.flags?.['release-themes-v2'] ? (
            <StyledDocContent key={index}>
              {_.flags?.['release-authed-zendesk-image-loading'] &&
              !process.env.SALESFORCE_BUILD &&
              command?.third_party_source === 'zendesk' &&
              command.third_party_id ? (
                <ZendeskDoc docHtml={contentBlock.value} commandId={command.id} />
              ) : (
                <KeywordsContainer html={sanitizeHtml(contentBlock.value)} />
              )}
            </StyledDocContent>
          ) : (
            <StyledHtml key={index}>
              {_.flags?.['release-authed-zendesk-image-loading'] &&
              !process.env.SALESFORCE_BUILD &&
              command?.third_party_source === 'zendesk' &&
              command.third_party_id ? (
                <ZendeskDoc docHtml={contentBlock.value} commandId={command.id} />
              ) : (
                <KeywordsContainer html={sanitizeHtml(contentBlock.value)} />
              )}
            </StyledHtml>
          );
        default:
          /** Video, react, markdown, plaintext, component unimplemented */
          throw new Error('Invalid content type');
      }
    });
  };

  // TODO: add a loading skeleton -- otherwise there's just a blank page with a loading spinner while the doc is loading
  return doc && docTemplate ? (
    <EngagementTracking anchorClickHandler={anchorClickHandler} videoPlayHandler={videoPlayHandler}>
      {_.flags?.['release-themes-v2'] ? (
        <StyledHelpHub>
          <StyledHeader>
            <div>
              <StyledSecondaryButton
                themeV2={themeV2}
                data-testid="helphub-go-back"
                onClick={() => {
                  Reporting.helpHubDocClosed({ ...docEventData, time_shown: (Date.now() - startTime) / 1000 });
                  Track.content.closed(_.helpHub);
                  removeDeepLinkParams();
                  back();
                }}
                prefixIcon={<ChevronLeft />}
              >
                Back
              </StyledSecondaryButton>
            </div>

            <StyledButtonGroup>
              <StyledSecondaryIconButton
                themeV2={themeV2}
                disabled={isStudioPreview()}
                onClick={async () => {
                  try {
                    const href = generateDeepLink(doc.command.id, query);
                    await copyToClipboard(href);
                    Track.helphub.docLinkCopied(_.helpHub, href);

                    setShowCheckIconCopyLink(true);
                    setTimeout(() => {
                      setShowCheckIconCopyLink(false);
                    }, 2000);
                  } catch (e) {
                    getSentry()?.captureException(e);
                  }
                }}
              >
                {showCheckIconCopyLink ? <Check /> : <Link04 />}
              </StyledSecondaryIconButton>
              {isHelpDocWithLink(docTemplate) && !doc.command.integration?.helphub_view_article_button_hidden && (
                <StyledSecondaryButton
                  themeV2={themeV2}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(docTemplate.value, '_blank');
                    }
                    openArticleLink(docTemplate.value);
                  }}
                  suffixIcon={<ArrowUpRight height={14} style={{ flexShrink: '0' }} />}
                >
                  {interpolate(_.localizedMessages['helphub.view-article-button-label'], _, true, false)}
                </StyledSecondaryButton>
              )}
            </StyledButtonGroup>
          </StyledHeader>
          <StyledDoc className="commandbar-helphub-article" ref={setScrollRef}>
            <StyledDocTitle data-testid="helphub-article-title">{doc.command.text}</StyledDocTitle>

            {doc.type === 'video' && doc.command.template.type === 'video' ? (
              <div style={{ width: '100%' }}>
                <div>{doc.excerpt}</div>
                <StyledNudgeVideo
                  src={doc.command.template.value}
                  type="helphub"
                  getPlayer={getPlayer}
                  testMode={_.testMode}
                />
              </div>
            ) : (
              renderContent(doc.command.content)
            )}

            {nextSteps.length > 0 && (
              <StyledButtonGroup>
                {nextSteps.map(
                  (nextStep, index) =>
                    isAction(nextStep) &&
                    !!nextStep.cta && (
                      <StyledPrimaryButton
                        themeV2={themeV2}
                        key={index}
                        onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                          executeAction(nextStep.action, e);
                        }}
                      >
                        {nextStep.cta}
                      </StyledPrimaryButton>
                    ),
                )}
              </StyledButtonGroup>
            )}
          </StyledDoc>
        </StyledHelpHub>
      ) : (
        <div css={styles.docContainer}>
          <div css={styles.docHeader}>
            <button
              data-testid="helphub-go-back"
              css={{ ...styles.docHeaderButton, width: '72px' }}
              onClick={() => {
                Reporting.helpHubDocClosed({ ...docEventData, time_shown: (Date.now() - startTime) / 1000 });
                Track.content.closed(_.helpHub);
                removeDeepLinkParams();
                back();
              }}
            >
              <ChevronLeft height={14} />
              <span>Back</span>
            </button>

            <div css={styles.docHeaderRight}>
              <button
                type="button"
                disabled={isStudioPreview()}
                css={{
                  ...styles.docHeaderButton,
                  padding: isStudioMobilePreview ? '0px' : '8px',
                  width: '40px',
                  ':disabled': { cursor: 'not-allowed' },
                }}
                onClick={async () => {
                  try {
                    const href = generateDeepLink(doc.command.id, query);
                    await copyToClipboard(href);
                    Track.helphub.docLinkCopied(_.helpHub, href);

                    setShowCheckIconCopyLink(true);
                    setTimeout(() => {
                      setShowCheckIconCopyLink(false);
                    }, 2000);
                  } catch (e) {
                    getSentry()?.captureException(e);
                  }
                }}
              >
                {showCheckIconCopyLink ? <Check height={16} /> : <Link04 height={16} />}
              </button>

              {isHelpDocWithLink(docTemplate) && !doc.command.integration?.helphub_view_article_button_hidden && (
                <button
                  css={{
                    ...styles.docHeaderButton,
                    width: 'auto',
                    maxWidth: '176px',
                    padding: isStudioMobilePreview ? '0px 12px 0px 8px' : styles.docHeaderButton.padding,
                    ':disabled': { cursor: 'not-allowed' },
                  }}
                  onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                      window.open(docTemplate.value, '_blank');
                    }
                    openArticleLink(docTemplate.value);
                  }}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {interpolate(styles.strings.docLinkText, _, true, false)}
                  </span>
                  <ArrowUpRight height={14} style={{ flexShrink: '0' }} />
                </button>
              )}
            </div>
          </div>
          <div
            style={{ height: 'calc(100% - 66px)', overflowY: 'auto', paddingBottom: '50px' }}
            className="commandbar-helphub-article"
            ref={setScrollRef}
          >
            <div data-testid="helphub-article-title" css={styles.docTitle}>
              {doc.command.text}
            </div>

            {doc.type === 'video' && doc.command.template.type === 'video' && (
              <div css={styles.docExcerpt}>{doc.excerpt}</div>
            )}
            <div css={styles.docContent}>
              {doc.type === 'video' && doc.command.template.type === 'video' ? (
                <VideoPreview
                  source={doc.command.template.value}
                  containerStyles={{ height: 'auto', margin: '8px 0', padding: 0 }}
                  autoPlay={false}
                />
              ) : (
                renderContent(doc.command.content)
              )}

              {nextSteps.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '0px',
                    alignItems: 'flex-start',
                    marginTop: '8px',
                  }}
                >
                  {nextSteps.map(
                    (nextStep, index) =>
                      isAction(nextStep) &&
                      !!nextStep.cta && (
                        <button
                          key={index}
                          onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                            executeAction(nextStep.action, e);
                          }}
                          style={{
                            width: '100%',
                            border: 'none',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            background: '#0A0A0F',
                            color: '#FFFFFF',
                            borderRadius: '4px',
                            padding: '11px 12px',
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '4px',
                          }}
                        >
                          {nextStep.cta}
                        </button>
                      ),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </EngagementTracking>
  ) : (
    <div css={styles.docContainer} style={{ display: 'flex', flexDirection: 'column' }}>
      <div css={styles.docHeader}>
        <button
          data-testid="helphub-go-back"
          css={{ ...styles.docHeaderButton, width: '72px' }}
          onClick={() => {
            Reporting.helpHubDocClosed({ ...docEventData, time_shown: (Date.now() - startTime) / 1000 });
            Track.content.closed(_.helpHub);
            removeDeepLinkParams();
            back();
          }}
        >
          <ChevronLeft height={14} />
          <span>Back</span>
        </button>
      </div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingIndicator isLoading size={30} />
      </div>
    </div>
  );
};

const StyledHtml = ({ children }: { children: ReactElement }) => {
  const { theme } = useTheme();
  const styles = useStyles();

  return (
    <div
      css={css`
        img {
          display: block;
          max-width: 100%;
          height: auto;
          box-shadow: ${theme.main.boxShadow};
          border: 1px solid ${theme.main.borderColors};
          margin: 1em auto;
        }
        mark {
          padding: 0;
          transition: background-color ${theme.main.transitionTime} ease;
          color: ${styles.highlightMark.color};
          border-radius: ${styles.highlightMark.borderRadius};
          background-color: ${styles.highlightMark.backgroundColor};
        }
        p:empty {
          display: none;
        }
        ol,
        ul {
          padding-inline-start: 0.5rem;
          margin-block-end: 1em;
          margin-block-start: 0.4em;
        }
        li {
          text-align: left;
          padding-left: 0.5em;
          margin-left: 1em;
        }
        a {
          color: ${theme.helpHubDocItem.linkColor};
          text-decoration: underline;
        }
        a:visited {
          color: ${theme.helpHubDocItem.visitedLinkColor};
        }
        p {
          margin-block-end: 1em;
        }
        strong {
          font-weight: 600;
        }
        h1 {
          font-weight: ${theme.helpHubDocItem.contentHeadingOneFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingOneFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingOneLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingOneColor || theme.helpHubDocItem.titleColor};
          margin-block-end: 0.5em;
          text-align: left;
        }
        h2 {
          font-weight: ${theme.helpHubDocItem.contentHeadingTwoFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingTwoFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingTwoLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingTwoColor || theme.helpHubDocItem.titleColor};

          margin-block-start: 1em;
          text-align: left;
          margin: 0;
        }
        h3 {
          font-weight: ${theme.helpHubDocItem.contentHeadingThreeFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingThreeFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingThreeLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingThreeColor || theme.helpHubDocItem.titleColor};

          margin-block-start: 1.7em;
          text-align: left;
        }
        h4 {
          font-weight: ${theme.helpHubDocItem.contentHeadingFourFontWeight};
          font-size: ${theme.helpHubDocItem.contentHeadingFourFontSize};
          line-height: ${theme.helpHubDocItem.contentHeadingFourLineHeight};
          color: ${theme.helpHubDocItem.contentHeadingFourColor || theme.helpHubDocItem.titleColor};

          margin-block-start: 1.7em;
          text-align: left;
        }
        iframe {
          position: relative !important;
          max-width: 100%;
          width: 100% !important;
          height: auto;
          aspect-ratio: 16 / 9;
        }
        video {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
        table {
          border-collapse: collapse;
        }
        thead {
          p {
            margin-block-start: 0.2em;
            margin-block-end: 0.2em;
          }
        }
        td {
          border: 1px solid ${theme.main.borderColors};
          padding: 0px 5px;
        }
        tbody {
          vertical-align: top;
          p {
            margin-block-start: 0.1em;
            margin-block-end: 0.1em;
          }
        }
        div {
          margin-block-end: 0.8em;
        }

        pre {
          white-space: pre;
        }

        button {
          all: unset;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          white-space: nowrap;
          vertical-align: middle;
          outline: transparent solid 2px;
          outline-offset: 2px;
          line-height: 1.2;
          border-radius: 0.375em;
          font-weight: 500;
          transition-property: background-color, color, transform;
          transition-duration: ${theme.main.transitionTime};
          height: 2em;
          min-width: 2em;
          font-size: 0.875em;
          padding-inline-start: 0.75em;
          padding-inline-end: 0.75em;
          background-color: ${theme.helpHubDocItem.contentButtonBackgroundColor};
          color: ${theme.helpHubDocItem.contentButtonColor};
          &:hover {
            background-color: ${chroma.valid(theme.helpHubDocItem.contentButtonBackgroundColor)
              ? chroma(theme.helpHubDocItem.contentButtonBackgroundColor).darken(0.25).css()
              : theme.helpHubDocItem.contentButtonBackgroundColor};
          }
        }
      `}
    >
      {children}
    </div>
  );
};

export default Doc;
