import { useState } from 'react';
import { useLocation } from 'react-router';
import AnalyticsContext from './AnalyticsContext';

import { useTimeFilter } from './components/CustomTimeRange';

const AnalyticsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const [isDemoData, setIsDemoData] = useState<boolean>(location.search.includes('demo=true'));
  const [includeAdminData, setIncludeAdminData] = useState(false);

  const { handleCustomFilterChange, timeFilterRange, setFilterRange } = useTimeFilter();

  const contextValue = {
    isDemoData,
    setIsDemoData,
    includeAdminData,
    setIncludeAdminData,
    handleCustomFilterChange,
    timeFilterRange,
    setFilterRange,
  };

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContextProvider;
