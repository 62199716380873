import { Alert, Input, Form, Select } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';
import { Code } from '../../blocks';
import { CmdSwitch } from '@commandbar/design-system/cmd';
import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const Zendesk: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.ZENDESK,
  name: 'Zendesk',
  slug: 'zendesk',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/zendesk-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/zendesk-icon-dark.svg',
  useDryRun: true,

  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message="Command AI will import articles visible by anonymous users by default. Articles visible to signed in users can be imported under advanced options."
    />
  ),
  route: '/zendesk',

  getForm({ showAdvanced, editingSource, organization, dryRunData }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'domain'}
          initialValue={
            editingSource?.meta?.domain
              ? `${editingSource?.meta?.domain}${
                  editingSource?.meta?.locale ? `/hc/${editingSource?.meta?.locale}` : ''
                }`
              : undefined
          }
          label="Domain"
          name="domain"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /(https:\/\/)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}/gi,
              message: `Please enter a valid URL (e.g., yourdomain.zendesk.com).`,
            },
          ]}
          style={{ flex: 5 }}
          required
          tooltip={{
            title: `Unless you enabled the host-mapping feature in Zendesk, use yourdomain.zendesk.com`,
          }}
        >
          <Input style={{ width: '100%' }} placeholder="yourdomain.zendesk.com" />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'categories'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.id ? dryRunData : []}
          label="Filter by Categories"
          name="categories"
          style={{ flex: 5 }}
        >
          <Select
            size="large"
            mode="multiple"
            style={{ width: '100%' }}
            options={dryRunData}
            maxTagCount="responsive"
            allowClear
            filterOption={(input, option) => !!option?.label?.toLowerCase().includes(input.toLowerCase())}
            labelInValue
          />
        </Form.Item>
        <Form.Item
          name="show_advanced"
          key="show_advanced"
          label="Sync content requiring authentication"
          initialValue={!!(editingSource?.meta?.email && editingSource?.meta?.api_key)}
          style={{ flex: 5 }}
        >
          <CmdSwitch
            defaultChecked={!!(editingSource?.meta?.email && editingSource?.meta?.api_key)}
            checked={showAdvanced}
          />
        </Form.Item>

        {showAdvanced && (
          <>
            <Form.Item
              key={editingSource?.type + 'email'}
              validateTrigger="onChange"
              initialValue={editingSource?.meta?.email}
              label="Admin email"
              name="email"
              rules={[
                { required: true, message: 'This is a required field.' },
                {
                  message: `Please enter your admin email.`,
                  type: 'email',
                },
              ]}
              style={{ flex: 5 }}
              required
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              key={editingSource?.type + 'api_key'}
              validateTrigger="onChange"
              initialValue={editingSource?.meta?.api_key}
              label="API Key"
              name="api_key"
              rules={[
                { required: true, message: 'This is a required field.' },
                {
                  message: `Please enter your API Key.`,
                },
              ]}
              style={{ flex: 5 }}
              required
            >
              <Input.Password
                iconRender={(visible) => (visible ? <Eye /> : <EyeOff />)}
                style={{ width: '100%' }}
                autoComplete="new-password"
              />
            </Form.Item>

            {organization.in_bar_doc_search && (
              <>
                <p>
                  Docs in Spotlight will not be available to users by default. An availability condition based on{' '}
                  <code style={{ fontSize: '0.75rem' }}>user_segment_id</code> is added to each article.
                </p>
                <p>
                  To make docs available to users in Spotlight, add 'zendesk_user_segments' to metadata in CommandBar.
                </p>
                <span style={{ display: 'block', marginBottom: '1rem' }}>
                  Example: making docs available to user who is part of 2 user segments.
                </span>
                <Code content="window.CommandBar.addMetadata('zendesk_user_segments', ['123', '987'])" />
              </>
            )}
          </>
        )}
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      domain: data['domain'],
      email: data['email'] ?? undefined,
      api_key: data['api_key'] ?? undefined,
      categories: data['categories'] ?? [],
    };
  },
  getInitialDryRunData(this, sourceId) {
    return this.sources?.find((i) => i.id === sourceId)?.meta?.categories;
  },
  getDryRunData(data) {
    return (JSON.parse(data.data)['categories'] ?? []).map((category: { name: string; id: number }) => ({
      label: category.name,
      value: category.id,
      key: category.id,
    }));
  },
  requiredFields: ['domain'],
  fetchedFields: ['categories'],
};

export default Zendesk;
