import { createContext, useContext } from 'react';

type CommandBarContainer = {
  container: HTMLElement;
  root: Document | ShadowRoot;
};

export const CommandBarContainerContext = createContext<CommandBarContainer>({} as any);

export const useCommandBarContainer = () => {
  return useContext(CommandBarContainerContext);
};
