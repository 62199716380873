import { useMemo } from 'react';
import { Buffer } from 'buffer';
import { CmdButton } from '@commandbar/design-system/cmd';

const LOCAL_DEV_URL = 'http://localhost:8000';
const HUBSPOT_OAUTH_URL = 'https://app.hubspot.com/oauth/authorize';

let apiUrl = process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
if (process.env.REACT_APP_API_URL?.includes('localhost:8000')) {
  apiUrl = 'http://localhost:8000';
}
const isLocalDev = apiUrl.includes(LOCAL_DEV_URL);
const hubSpotClientId = isLocalDev ? '29023b70-b1ae-48bd-9c3b-b2014f68c18b' : process.env.REACT_APP_HUBSPOT_CLIENT_ID;

const getOAuthUrl = (organizationId: string) => {
  if (!hubSpotClientId) return null;

  const oauthURL = new URL(HUBSPOT_OAUTH_URL);

  oauthURL.searchParams.set('client_id', hubSpotClientId);
  oauthURL.searchParams.set('state', Buffer.from(JSON.stringify({ uuid: organizationId })).toString('base64'));
  oauthURL.searchParams.set('redirect_uri', `${apiUrl}/integrations/hubspot/oauth/`);
  oauthURL.searchParams.set('scope', 'crm.objects.contacts.read crm.lists.read');

  return oauthURL.href;
};

interface HubSpotConnectionStepProps {
  organizationId: string;
}

const HubSpotConnectionStep = ({ organizationId }: HubSpotConnectionStepProps) => {
  const href = useMemo(() => getOAuthUrl(organizationId), [organizationId]);

  if (!href) return null;

  return (
    <CmdButton disabled={!href} href={href} variant="primary" fullWidth>
      Connect
    </CmdButton>
  );
};

export default HubSpotConnectionStep;
