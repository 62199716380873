import * as t from 'io-ts';

import { decodeToPromise } from '../generics';
import * as axiosInstance from '../network';

const ActivateResponseV = t.intersection([
  t.type({
    api_key: t.string,
  }),
  t.partial({
    enabled: t.boolean,
    cohorts: t.record(t.string, t.string),
  }),
]);

type ActivateResponse = t.TypeOf<typeof ActivateResponseV> & unknown;

const activate = async (): Promise<ActivateResponse> => {
  const { data } = await axiosInstance.post<ActivateResponse>('integrations/amplitude/activate/');
  return decodeToPromise(t.exact(ActivateResponseV), data);
};

const Amplitude = {
  activate,
};

export default Amplitude;
