import React from 'react';
import { Alert, CB_COLORS, Space } from '@commandbar/design-system/components/antd';
import styled from '@emotion/styled';
import { mediaQueries } from '../util/mediaQueries';
import login_background_pattern from '../image/auth/login_background_pattern.svg?url';
import { ReactComponent as Logo } from '../image/auth/logo.svg';
import { CmdTypography } from '@commandbar/design-system/cmd/typography';

const FormLayout = (props: any) => {
  return (
    <div
      style={{
        width: '100vw',
        minHeight: '100vh',
        margin: '0px',
        backgroundColor: 'rgba(21, 21, 21, 0.03)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: '448px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <Logo style={{ zIndex: '1' }} />
          <span style={{ fontSize: '18px', fontWeight: 500 }}>CommandBar</span>
        </div>
        <div
          style={{
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 1px 8px 0px rgba(0, 0, 0, 0.16)',
            width: '100%',
            margin: '0px',
            padding: '24px',
            borderRadius: '4px',
            backgroundColor: 'white',
            border: '1px solid #dedede',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <CmdTypography.H1 fontSize="lg" fontWeight="medium">
              {props.h1}
            </CmdTypography.H1>
            <CmdTypography.H2 fontSize="base" fontWeight="medium">
              {props.h2}
            </CmdTypography.H2>
          </div>
          {!!props.error && <Alert message={props.error} type="error" />}
          {props.children}
        </div>
      </div>
    </div>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0px;
  display: flex;
  background-color: rgba(21, 21, 21, 0.03);
`;

const LeftPane = styled.div`
  flex: 0 0 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: linear-gradient(214.92deg, #ffffff 3.36%, #ffffff 9.11%, #f4f8ff 29.03%, #ffffff 46.03%, #ffffff 98.37%);

  @media ${mediaQueries.mobile} {
    flex: 1 1 100%;
  }
`;

const RightPaneGraphicArea = styled.div`
  flex: 1 0 auto;
  height: 100vh;
  background: linear-gradient(
    108.44deg,
    rgba(233, 220, 255, 0.57) 4.38%,
    #ffffff 57.09%,
    #ffeedb 85.67%,
    #ffffff 94.06%
  );
  position: relative;
  overflow: hidden;

  @media ${mediaQueries.mobile} {
    display: none;
  }
`;

const GraphicBackground = styled.div`
  background: url(${login_background_pattern});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top left;
  position: absolute;
`;

const GraphicFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 7.5%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${CB_COLORS.neutral1000};
  font-family: Inter;
  font-size: 96px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -3.84px;
`;

const Subtitle = styled.div`
  color: ${CB_COLORS.neutral1000};
  leading-trim: both;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SubtitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
`;

const NewTagPill = styled.div`
  display: flex;
  height: 24px;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
  background: #000;
  mix-blend-mode: multiply;
`;

const NewTagText = styled.span`
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

const WidgetsImgContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const WidgetsImg = styled.img`
  // Use filter to avoid exporting png w/ drop shadow and blowing up file size
  filter: drop-shadow(0px 16px 40px rgba(10, 10, 15, 0.24));
  width: 92.5%;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
`;

const LogoPulseRing1 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid ${CB_COLORS.black};
  opacity: 0.75;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ping;
  transform-origin: center;

  @keyframes ping {
    75%,
    to {
      /* Controls size of pulse: */
      // transform: scale(6);
      width: 140px;
      height: 140px;
      opacity: 0;
    }
  }
`;

const LogoPulseRing2 = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: ${CB_COLORS.black};
  opacity: 0.5;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ping;
  transform-origin: center;

  @keyframes ping {
    75%,
    to {
      /* Controls size of pulse: */
      // transform: scale(6);
      width: 128px;
      height: 128px;
      opacity: 0;
    }
  }
`;

export const FormLayout2 = (props: any) => {
  return (
    <Container>
      {/* sorry, this is a hack to support Setup.tsx */}
      <LeftPane style={{ justifyContent: props?.isTall ? 'flex-start' : 'center', overflowY: 'auto' }}>
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <LogoContainer>
            <Logo style={{ zIndex: '1' }} />
            <LogoPulseRing1></LogoPulseRing1>
            <LogoPulseRing2></LogoPulseRing2>
          </LogoContainer>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 900, fontSize: '24px' }}>{props.h1}</div>
            <div>{props.h2}</div>
          </div>
          {!!props.error && <Alert message={props.error} type="error" />}
          {props.children}
        </Space>
      </LeftPane>
      <RightPaneGraphicArea>
        <GraphicBackground>
          <GraphicFlex>
            <TextContainer>
              <SubtitleContainer>
                <NewTagPill>
                  <NewTagText>NEW</NewTagText>
                </NewTagPill>
                <Subtitle>Introducing</Subtitle>
              </SubtitleContainer>
              <Title>Copilot</Title>
            </TextContainer>
            <WidgetsImgContainer>
              <WidgetsImg src="https://cdn.commandbar.com/_images/cobrowse-preview-commandai.svg" />
            </WidgetsImgContainer>
          </GraphicFlex>
        </GraphicBackground>
      </RightPaneGraphicArea>
    </Container>
  );
};

export default FormLayout;
