import { CmdTag, CmdTypography, CmdTooltip, CmdSwitch, CmdButton, CmdDropdown } from '@commandbar/design-system/cmd';
import { useAnalyticsContext } from '../AnalyticsContext';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { Download01, LinkExternal02 } from '@commandbar/design-system/icons/react';
import CustomTimeRange from './CustomTimeRange';

type DownloadDropDown = {
  label: string;
  action: () => void;
};

type LayoutProps = {
  title: string | React.ReactChild;
  subtitle?: string | React.ReactChild;
  children: React.ReactNode;
  disableDemoData?: boolean;
  onDownloadCSV?: (() => void) | DownloadDropDown[];
  isDownloading?: boolean;
  titleLink?: string;
};

export const Beta = () => (
  <CmdTooltip.Provider>
    <CmdTooltip.Root>
      <CmdTooltip.Trigger>
        <CmdTag variant="release">Beta</CmdTag>
      </CmdTooltip.Trigger>
      <CmdTooltip.Content>
        Just a heads-up that Analytics is still in Beta, so you might see some tweaks here and there. 🤓 If you run into
        any glitches or have ideas on how to make it better, holler at us!
      </CmdTooltip.Content>
    </CmdTooltip.Root>
  </CmdTooltip.Provider>
);

const Layout = ({
  title,
  subtitle,
  children,
  disableDemoData = false,
  onDownloadCSV,
  isDownloading,
  titleLink,
}: LayoutProps) => {
  const {
    isDemoData,
    setIsDemoData,
    includeAdminData,
    setIncludeAdminData,
    handleCustomFilterChange,
    timeFilterRange,
  } = useAnalyticsContext();
  const history = useHistory();

  useEffect(() => {
    if (isDemoData) {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set('demo', 'true');
      history.push(`${history.location.pathname}?${searchParams}`);
    }
  }, [isDemoData]);

  useEffect(() => {
    const { search } = history.location;
    if (search.includes('demo=true')) {
      setIsDemoData(true);
    }
  }, [history]);

  return (
    <div style={{ width: '100%', background: '#F9F9F9', paddingBottom: '24px', height: '100%', overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', padding: '24px', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!!subtitle && <div style={{ cursor: 'pointer' }}>{subtitle}</div>}
            <CmdTypography.H2 variant="primary" fontWeight="medium">
              {title}
              <div style={{ marginLeft: '8px', display: 'inline-flex', gap: '8px', alignItems: 'center' }}>
                {titleLink && (
                  <CmdButton style={{ display: 'inline' }} icon={<LinkExternal02 />} variant="link" to={titleLink} />
                )}
                <Beta />
              </div>
            </CmdTypography.H2>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center', flexShrink: 0 }}>
          <CmdSwitch
            disabled={disableDemoData}
            checked={isDemoData}
            onLabel="Demo data"
            offLabel="Demo data"
            onCheckedChange={(val) => {
              setIsDemoData(val);
              if (!val) {
                history.replace(`${history.location.pathname}`);
              }
            }}
          />
          <CmdSwitch
            checked={includeAdminData}
            onLabel="Admin data"
            offLabel="Admin data"
            onCheckedChange={setIncludeAdminData}
          />
          <CustomTimeRange timeFilterRange={timeFilterRange} handleCustomFilterChange={handleCustomFilterChange} />

          {!onDownloadCSV ? null : Array.isArray(onDownloadCSV) ? (
            <CmdDropdown.Menu>
              <CmdDropdown.Trigger disabled={isDemoData}>
                <CmdButton disabled={isDemoData} loading={isDownloading} icon={<Download01 />} />
              </CmdDropdown.Trigger>
              <CmdDropdown.Content className="w-56">
                {onDownloadCSV?.map(({ label, action }) => (
                  <CmdDropdown.Item key={label} onClick={action}>
                    {label}
                  </CmdDropdown.Item>
                ))}
              </CmdDropdown.Content>
            </CmdDropdown.Menu>
          ) : (
            <CmdButton disabled={isDemoData} loading={isDownloading} icon={<Download01 />} onClick={onDownloadCSV} />
          )}
        </div>
      </div>
      <div style={{ padding: '0 24px' }}>{children}</div>
    </div>
  );
};

export default Layout;
