import * as t from 'io-ts';

import { listObject } from './generics';

export const ProfileV = t.type({
  user: t.string,
  organization: t.string,
  is_active: t.boolean,
  role: t.union([t.literal('admin'), t.literal('editor'), t.literal('contributor'), t.literal('viewer')]),
  first_name: t.string,
  last_name: t.string,
  email: t.string,
});

export class Profile {
  public static list = listObject(ProfileV, 'profiles');
}
