import React, { SVGProps } from 'react';
import { IOrganizationSettingsType } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import {
  BookOpen01,
  GraduationHat02,
  BookClosed,
  Bookmark,
  MessageCircle01,
} from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import AskAIButton from '@commandbar/design-system/components/AskAIButton';

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SelectedContainer = styled.div<{ checked: boolean }>`
  padding: 8px;
  border: 1px solid transparent;
  ${({ checked }) =>
    checked &&
    `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: ${CB_COLORS.blue0};
  border: 1px solid ${CB_COLORS.blue600};
  border-radius: 8px;
`}
`;

const RadioButtonContainer = styled.div<{ checked: boolean }>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid ${CB_COLORS.neutral300};
  background-color: ${CB_COLORS.white};
  ${(props) =>
    props.checked &&
    `
    border-color: ${CB_COLORS.neutral500};
    color: ${CB_COLORS.neutral800};
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  `}

  transition: 0.3s all;
`;

type THelpHubType = IOrganizationSettingsType['helphub_launcher_type'];

type THelpHubOptionType = {
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  type: THelpHubType;
};
const types: THelpHubOptionType[] = [
  {
    Icon: BookOpen01,
    type: 'bookOpen',
  },
  {
    Icon: GraduationHat02,
    type: 'graduationHat',
  },
  {
    Icon: BookClosed,
    type: 'bookClosed',
  },
  {
    Icon: Bookmark,
    type: 'bookmark',
  },
  {
    Icon: MessageCircle01,
    type: 'chatCircle',
  },
];

interface ILauncherTypeOptions {
  onSetType: (launcherType: THelpHubType) => void;
  value: THelpHubType;
}

export const LauncherTypeOptions = ({ onSetType, value }: ILauncherTypeOptions) => {
  return (
    <RadioGroup>
      {types.map(({ Icon, type }) => (
        <SelectedContainer key={type} checked={type === value}>
          <RadioButtonContainer
            checked={type === value}
            onClick={() => {
              onSetType(type);
            }}
          >
            <Icon color={type === value ? CB_COLORS.neutral1000 : CB_COLORS.neutral600} height={20} />
          </RadioButtonContainer>
        </SelectedContainer>
      ))}
      <SelectedContainer onClick={() => onSetType('askAI')} checked={'askAI' === value}>
        <AskAIButton small />
      </SelectedContainer>
    </RadioGroup>
  );
};
