import {
  EDITOR_MOBILE_PREVIEW_SCREEN_HEIGHT,
  EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH,
  isMobileDevice as _isMobileDevice,
} from '@commandbar/internal/util/operatingSystem';
import { useStore } from './useStore';
import useTheme from './useTheme';
import { ITheme } from '@commandbar/internal/client/theme';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { useEffect, useRef } from 'react';
import Z from '@commandbar/internal/client/Z';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { emptyGlobalStore } from '../../store/global-store';

export type NativeCallbacks = {
  onFallbackAction?: (messageJSON: string) => void;
};

export const getNativeCallback = (): NativeCallbacks['onFallbackAction'] => {
  if (window.webkit?.messageHandlers?.commandbar__onFallbackAction?.postMessage) {
    return window.webkit.messageHandlers.commandbar__onFallbackAction.postMessage.bind(
      window.webkit.messageHandlers.commandbar__onFallbackAction,
    );
  } else if (window.CommandBarAndroidInterface?.commandbar__onFallbackAction) {
    return window.CommandBarAndroidInterface.commandbar__onFallbackAction.bind(window.CommandBarAndroidInterface);
  }

  return undefined;
};

/**
 * Hook for basic tracking of various mobile experience features
 *
 * isStudioMobilePreview: Is the user on the standalone editor and in mobile preview mode
 * isMobileDevice: Is the user on a mobile device or is the user in mobile preview mode
 * studioMobilePreviewWrapper: The HTML element that wraps the command bar on mobile, this is only used for the preview device on the standalone editor.
 *                          When the mobile preview is enabled, we will portal various containers (ie. Nudges, Checklists, etc) to this view
 * @returns
 */
export const useMobileExperience = (preview?: boolean) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = !preview ? useStore() : emptyGlobalStore();
  const editorPreviewDevice = store.editorPreviewDevice;
  const isStudioMobilePreview = editorPreviewDevice === 'mobile' && isStudioPreview();
  const studioMobilePreviewWrapper = useRef<HTMLElement | null>(
    document.getElementById('commandbar-mobile-preview-wrapper'),
  );

  const { theme }: { theme?: ITheme } = useTheme();
  const themeV2Context = useThemeV2Context();
  const isMobileDevice = _isMobileDevice();

  useEffect(() => {
    if (!isStudioMobilePreview) return;

    studioMobilePreviewWrapper.current = document.getElementById('commandbar-mobile-preview-wrapper');
    studioMobilePreviewWrapper.current?.classList.add(themeV2Context.generatedCSSClassname);
  }, [isStudioMobilePreview]);

  const mobileStyles = {
    checklists: {
      container: {
        fontFamily: theme?.questlists.fontFamily,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        margin: '16px',
        width: 'auto',
        background: theme?.questlists.bodyBackground,
        boxShadow: theme?.questlists.boxShadow,
        borderRadius: theme?.questlists.borderRadius,
        bottom: '0px',
        right: '0px',
        left: '0px',
        overflow: 'hidden',
        zIndex: Z.Z_CHECKLIST,
        position: isStudioMobilePreview ? 'absolute' : 'fixed',
      } as React.CSSProperties,
    },
    nudges: {
      modal: {
        contentContainer: {
          width: '100%',
          height: '100%',
        },
        mask: {
          backgroundColor: theme?.nudgeModal.maskBackgroundColor,
          zIndex: Z.Z_EDITOR - 2,
          width: '100%',
          height: '100%',
          maxWidth: EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH,
          maxHeight: EDITOR_MOBILE_PREVIEW_SCREEN_HEIGHT,
          position: 'absolute',
        },
        wrap: {
          width: '100%',
          height: '100%',
          maxWidth: EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH,
          maxHeight: EDITOR_MOBILE_PREVIEW_SCREEN_HEIGHT,
          position: 'absolute',
        },
        container: {
          width: '100%',
          height: theme?.nudgeModal.height,
          margin: '16px',
          borderRadius: theme?.nudgeModal.borderRadius,
          boxShadow: theme?.nudgeModal.boxShadow,
          backgroundColor: theme?.nudgeModal.background,
          fontFamily: theme?.nudgeModal.fontFamily,
          color: theme?.nudgeModal.color,
        },
      },
      pin: {
        container: {
          position: 'absolute',
          display: 'flex',
          gap: '16px',
          pointerEvents: 'none',
          zIndex: Z.Z_COMMANDBAR - 3,
        } as React.CSSProperties,
      },
      tooltip: {
        container: {
          position: 'absolute',
          display: 'flex',
          gap: '16px',
          pointerEvents: 'none',
          zIndex: Z.Z_COMMANDBAR - 3,
        },
      },
      popover: {
        container: {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: theme?.nudges.background,
          borderRadius: theme?.nudges.borderRadius,
          color: theme?.nudges.color,
          zIndex: Z.Z_COMMANDBAR - 2,
          boxShadow: theme?.nudges.boxShadow,
          fontFamily: theme?.nudges.fontFamily,
          transition: 'opacity 0.15s ease-in-out',
          pointerEvents: 'all',
          position: 'relative',
        } as React.CSSProperties,
      },
      banner: {
        container: {
          position: 'absolute',
          display: 'flex',
          gap: '16px',
          pointerEvents: 'none',
          zIndex: Z.Z_COMMANDBAR - 3,
        } as React.CSSProperties,
        body: {
          flexDirection: 'column',
          marginRight: 'var(--layout-padding)',
          justifyContent: 'space-between',
        } as React.CSSProperties,
        closeButtonContainer: {
          position: 'absolute',
          display: 'flex',
          top: 'calc(max(var(--layout-padding) - 4px, 0px))',
          right: 'calc(max(var(--layout-padding) - 12px, 0px))',
          maxHeight: '32px',
          maxWidth: '32px',
          borderRadius: 'var(--layout-radius-button)',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        } as React.CSSProperties,
        spaceContainer: {
          display: 'none',
        } as React.CSSProperties,
        title: {
          flexDirection: 'column',
          fontSize: 'var(--font-size-large)',
          lineHeight: 'var(--font-line-height-snug)',
          alignSelf: 'flex-start',
        } as React.CSSProperties,
        actionBar: {
          padding: '0px',
          width: '100%',
        } as React.CSSProperties,
        stickyContainer: {
          width: '100%',
          top: '0px',
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: Z.Z_NUDGE,
          height: '100%',
        } as React.CSSProperties,
        inlineContainer: {
          width: '100%',
          zIndex: Z.Z_NUDGE,
        } as React.CSSProperties,
      },
    },
    toast: {
      modal: { bottom: '0px' },
      container: { position: 'relative', width: '100%', height: '100%' } as React.CSSProperties,
      contentContainer: {
        position: 'absolute',
        zIndex: Z.Z_INDEX_MAX,
        pointerEvents: 'none',
      } as React.CSSProperties,
    },
  };

  return {
    isMobileDevice: isMobileDevice || isStudioMobilePreview,
    isStudioMobilePreview,
    studioMobilePreviewWrapper: studioMobilePreviewWrapper.current,
    mobileStyles,
  };
};
