import * as Command from '@commandbar/internal/middleware/command';
import { useCallback } from 'react';
import useAnalyticHelpHub from './useAnalyticHelpHub';
import { useHistory } from 'react-router-dom';

import { CmdColumnDef, CmdSortableColumn, CmdTypography, cmdToast } from '@commandbar/design-system/cmd';
import StatCard from '../../components/StatCard';
import { PencilLine } from '@commandbar/design-system/icons/react';
import ActionDropdown from '../../components/ActionDropdown';
import Table from '../../components/Table';
import Layout from '../../components/Layout';
import Logger from '@commandbar/internal/util/Logger';
import type { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const getCommandType = (command: IEditorCommandType): string => {
  if (command.template.type === 'helpdoc') {
    return command.template.doc_type === 'answer' ? 'answers' : command.third_party_source ? 'sources' : 'files';
  }
  if (command.template.type === 'video') {
    return 'videos';
  }
  return 'sources';
};

const fetchCommandViewUrl = async (commandId: IEditorCommandType['id']) => {
  const command = await Command.get(commandId.toString());
  const type = getCommandType(command);
  const integrationId = command.integration?.id;

  return integrationId ? `/content/${type}/${integrationId}?id=${command.id}` : `/content/${type}?id=${command.id}`;
};

const searchesColumns: CmdColumnDef<any, any>[] = [
  {
    accessorKey: 'query',
    header: 'Search Query',
    enableGlobalFilter: true,
  },
  {
    accessorKey: 'search_count',
    header: ({ column }) => <CmdSortableColumn column={column} title="Searches" />,
    enableSorting: true,
  },
];

const AnalyticsHelpHubDashboard = () => {
  const {
    searches,
    articles,
    isLoading,
    helpHubDocOpens,
    helpHubOpens,
    error,
    fetchHelpHubData,
    downloadCsvArticles,
    downloadCsvSearches,
    isDownloading,
    isDemoData,
  } = useAnalyticHelpHub();

  const history = useHistory();
  const { user } = useAuth();

  const navigateToCommandView = useCallback(
    async (id: IEditorCommandType['id']) => {
      try {
        const url = await fetchCommandViewUrl(id);
        history.push(url);
      } catch (e) {
        Logger.error('Command view does not exist', e);
        cmdToast.error('This article has been deleted.');
      }
    },
    [history],
  );

  const articleColumns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'commandText',
      header: 'Action',
      enableGlobalFilter: true,
      cell: (table) =>
        isDemoData || table.row.original.archived ? (
          <div>{table.cell.renderValue()}</div>
        ) : (
          <button
            type="button"
            style={{
              // unstyled
              background: 'none',
              border: 'none',
              padding: 0,
              margin: 0,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToCommandView(table.row.original.id);
            }}
          >
            {table.cell.renderValue()}
          </button>
        ),
    },
    {
      accessorKey: 'views',
      header: ({ column }) => <CmdSortableColumn column={column} title="Views" />,
      enableSorting: true,
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    articleColumns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => {
        if (row.original.archived || isDemoData) {
          return null;
        }
        return (
          <ActionDropdown
            options={[
              {
                label: 'Edit',
                icon: <PencilLine />,
                onClick: () => {
                  navigateToCommandView(row.original.id);
                },
              },
            ]}
          />
        );
      },
    });
  }

  return (
    <Layout
      title="HelpHub"
      disableDemoData={isLoading}
      isDownloading={isDownloading}
      onDownloadCSV={[
        {
          label: 'Articles',
          action: downloadCsvArticles,
        },
        {
          label: 'Searches',
          action: downloadCsvSearches,
        },
      ]}
    >
      <>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center', flexWrap: 'wrap' }}>
          <StatCard
            title="Opens"
            subtitle="Opened by a user"
            format="number"
            metric={helpHubOpens?.metric}
            rate={helpHubOpens?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Article Views"
            subtitle="Total times articles have been viewed"
            format="number"
            metric={helpHubDocOpens?.metric}
            rate={helpHubDocOpens?.percent_change}
            isLoading={isLoading}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          <div style={{ minWidth: '365px', flex: '1 1 0%', padding: '24px 0 8px 0' }}>
            <Table
              columns={articleColumns}
              data={articles?.rows || []}
              isLoading={isLoading}
              empty={{
                error: !!error,
                refetchData: fetchHelpHubData,
              }}
              toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Article Views</CmdTypography.Body>}
            />
          </div>
          <div style={{ minWidth: '365px', flex: '1 1 0%', padding: '24px 0 8px 0' }}>
            <Table
              columns={searchesColumns}
              data={searches?.rows || []}
              isLoading={isLoading}
              empty={{
                error: !!error,
                refetchData: fetchHelpHubData,
              }}
              toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Search</CmdTypography.Body>}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default AnalyticsHelpHubDashboard;
