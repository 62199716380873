import React from 'react';
import { Card } from '@commandbar/design-system/components/antd';
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';

const EmptyCategoryActionCard = (props: { category: ICommandCategoryType | undefined }) => {
  const { category } = props;

  return (
    <Card style={{ border: 0, textAlign: 'center', maxWidth: '375px' }}>
      <div>
        <h4 style={{ fontWeight: 600, marginBottom: '4px', lineHeight: '16px' }}>
          Add a Page or Action to {category?.name}
        </h4>
        <p style={{ fontWeight: 500, fontSize: '10px', marginBottom: 0, opacity: '0.5', lineHeight: '14px' }}>
          Add a Page to allow your users to effortlessly get around your app, or add an Action to help them get things
          done.
        </p>
      </div>
    </Card>
  );
};

export default EmptyCategoryActionCard;
