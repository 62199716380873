import type { INudgeStepType } from '@commandbar/internal/middleware/types';

export type TNudgeOffset = {
  x: string;
  y: string;
};

export type FormFactorType = INudgeStepType['form_factor']['type'];
export type PopoverPosition = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'center';
export type BannerPosition = 'top' | 'bottom';

export type FormFactorSelectInput = {
  type: 'select';
  options: { label: string; value: PopoverPosition; disabled: boolean }[];
};

export type BannerFormFactorSelectInput = {
  type: 'select';
  options: { label: string; value: BannerPosition; disabled: boolean }[];
};

type FormFactorTargetInput = {
  type: 'target';
};

export type FormFactorInputMetadata = FormFactorSelectInput | FormFactorTargetInput | BannerFormFactorSelectInput;

export const POSITION_LABEL: Record<PopoverPosition, string> = {
  'top-left': 'Top Left',
  'top-right': 'Top Right',
  'bottom-left': 'Bottom Left',
  'bottom-right': 'Bottom Right',
  center: 'Center',
};

export const BANNER_POSITION_LABEL: Record<BannerPosition, string> = {
  bottom: 'Bottom',
  top: 'Top',
};

export const FORM_FACTOR_INPUT_META: Record<FormFactorType, FormFactorInputMetadata> = {
  modal: {
    type: 'select',
    options: [
      {
        label: POSITION_LABEL['top-right'],
        value: 'top-right',
        disabled: true,
      },
      {
        label: POSITION_LABEL['bottom-right'],
        value: 'bottom-right',
        disabled: true,
      },
      {
        label: POSITION_LABEL['bottom-left'],
        value: 'bottom-left',
        disabled: true,
      },
      {
        label: POSITION_LABEL['top-left'],
        value: 'top-left',
        disabled: true,
      },
      {
        label: POSITION_LABEL['center'],
        value: 'center',
        disabled: false,
      },
    ],
  },
  banner: {
    type: 'select',
    options: [
      {
        label: BANNER_POSITION_LABEL['top'],
        value: 'top',
        disabled: false,
      },
      {
        label: BANNER_POSITION_LABEL['bottom'],
        value: 'bottom',
        disabled: false,
      },
    ],
  },
  popover: {
    type: 'select',
    options: [
      {
        label: POSITION_LABEL['top-right'],
        value: 'top-right',
        disabled: false,
      },
      {
        label: POSITION_LABEL['bottom-right'],
        value: 'bottom-right',
        disabled: false,
      },
      {
        label: POSITION_LABEL['bottom-left'],
        value: 'bottom-left',
        disabled: false,
      },
      {
        label: POSITION_LABEL['top-left'],
        value: 'top-left',
        disabled: false,
      },
      {
        label: POSITION_LABEL['center'],
        value: 'center',
        disabled: false,
      },
    ],
  },
  pin: {
    type: 'target',
  },
  tooltip: {
    type: 'target',
  },
};
