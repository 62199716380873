import { type Placement, autoPlacement, autoUpdate, computePosition, offset, shift } from '@floating-ui/dom';
import { createActorContext } from '@xstate/react';
import React, {
  type ComponentProps,
  type ReactElement,
  type RefObject,
  createContext,
  useContext,
  useRef,
} from 'react';
import { type ActorRefFrom, type StateFrom, assign, createMachine } from 'xstate';

export type TriggerType = 'hover' | 'click';

type PopoverEvents =
  | { type: 'POSITION'; x: number; y: number }
  | { type: 'OPEN' }
  | { type: 'CLOSE' }
  | { type: 'PLACE'; placement: Placement }
  | { type: 'UPDATE_TRIGGER_DIMENSIONS'; dimensions: DOMRect };

export interface PopoverContextType {
  content: {
    ref: RefObject<HTMLDivElement>;
    position: {
      x: number;
      y: number;
    } | null;
    offset: number;
    placement: Placement;
    shouldRunAnimation: boolean;
  };
  trigger: {
    ref: RefObject<HTMLButtonElement>;
    dimensions: DOMRect | null;
  };
}

const hoverPopoverMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAkD2A3MAnABABVQAcNsBiPAeQGUBJAFRooDkBtABgF1FRjYBLAC59UAO24gAHogC0AZgBMAOgCcADlUBGWbLYBWDevnzlAGhABPGRt0A2RfIW75e5RvmrjsgL5ezaTLgExAHkADIAggDCAKLsXEggvILCYglSCPJuirpsqgrKTsoA7AAs7maWCNLWdg7yTi5uHsreviD+2PhEJFikAKp4ACLhdNEA+nQASjQA4jPRk2ODNACy0Uy0zFRx4klCouLpJTZs9mxsGkXyJUWatzYVVrb2js66ru6ePn49XcHYigAxgAbVCwSCkCh4dY7BJ7FKHRAlDR2S6XNiuFGFeSPDJORQ2eQ2Yo2MrKNg2PLfdq-II9IGg8EQcjUeiMVicXZg5IHNKIDR6ezEwmUkraEpsBS45F2Nhlc7yIoaVzKEq6akdQLdAIMsGQRR8ETJACGwJZtAYzFhPG5+1SoHS0iMilUbEyYspuhKJVUPt0uN0+hUvquvusRTYRXVbU1f3pRDAIkUAAseoaoKRIqFqLFOXDbQi+QhkUp3jYcrYBSrLgGyopzkrNCVlCcNAYNbTtQCE0nUAAzPuQ6Ec+I2-h2xEIQN2XSyfSyGy3X2K-0WRAnVT166XX03AyZDsBOM6nuKfuD62JAu8h1I9T2VT6ZtFFvKymyXHhl25W4UuXOeofDaERUAgOBxFjOkAi5cdC1vKoI2-d0F0fb1fVUXFpDrJVjncXQ8gjZR6hsQ9OiggEQT1CAYJ5e1JHXexbDVPDZDFWxTDXBBXEUW5ZyVIoFy9Ipy1IrV-iwXUmQNI0hFNGiJyLL1GNJJxH1YudiU-F8H1nUlJUfBwPFE48KMZfUsDAQgwGNAR5Lg+iEEuIpFB0VR3ycIwihfB5OMuZQdNkV13i9EKimM8iJJ7Oybwc1j-PJNsWjbDFlRrTjAtkFzZA0b1CgxQlVHCrtIqspNUwCdNoroo5IwJNwtApKtUqKXENy3ZFbm9Rt2xjTtxLPUqzwHKrJyY7JZHJMV9LKKNayUBsDBylsLh6n4jwigbE0UPtUCwQFIBGosJuchLlWyprXBaziblOPIctkKMiJaZQgK8IA */
  id: 'Hover Popover',

  initial: 'closed',

  states: {
    closed: {
      on: {
        OPEN: {
          target: 'open',
        },

        POSITION: {
          target: 'open.forced',
          cond: 'isForcedOpen',
        },
      },

      states: {
        initial: {
          on: {
            POSITION: {
              target: '#Hover Popover.open',
              cond: 'isDefaultOpen',
            },
          },
        },
        repeat: {},
      },

      initial: 'initial',
    },

    open: {
      initial: 'hovering',

      states: {
        hovering: {
          on: {
            CLOSE: {
              target: 'off',
            },
          },
        },

        off: {
          on: {
            OPEN: {
              target: 'hovering',
            },
          },

          always: {
            target: '#Hover Popover.closed.repeat',
            actions: 'updateShouldRunAnimation',
          },
        },

        forced: {
          type: 'final',
        },
      },
    },
  },

  invoke: [
    {
      src: 'trackTrigger',
      id: 'trackTrigger',
    },
  ],

  on: {
    POSITION: {
      actions: ['updatePosition'],
    },
    PLACE: {
      actions: ['updatePlacement'],
    },
    UPDATE_TRIGGER_DIMENSIONS: {
      actions: ['updateTriggerDimensions'],
    },
  },

  schema: {
    events: {} as PopoverEvents,
    context: {} as PopoverContextType,
  },

  predictableActionArguments: true,
  tsTypes: {} as import('./state.typegen').Typegen0,
});

const clickPopoverMachine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QGEA2BLAxgawAQAUB7AB0IDcwAnAYnwHkBlASQBUm6A5AbQAYBdRKFKx0AF3SEAdoJAAPRAGYATADoAjGuUAWHgA5dAdgO6tagDQgAnogC0SgKw8VATntqlH584UL7OgwC+ARZoWHhEpBQ0+AAyAILIAKK8AkggwmIS0mnyCA7OKgZaRgBsPFoKavZeBhbWCHaOLm4eSl4+fjyBwSChOAQk5FTUAKr4ACJxLIkA+iwASkwA4kuJ8zPjTACyiRzMnAwpMhniUjK5WiUFV0ruul48JWq6dbYOTq7unt6+-kEhGH6ESGlBUmFQhFgkGodHwuyOaROWXOiCUCi06gMV2KahKbXsDhKrwQlxKKhKeOcWmcmmcj2p9n+vUB4UGUTBEKhEFojFY7G4-GOkMyZxyiEMKg8V30z3cXWKxL8ZJpBiUBlx9nsPjxTL6rMiVA5kMgKnQkkyAENUDzmGxOAihMLTtlQBcaeTqZoSj4ugpdBTiRLDH7nCVTLo2mHdSyBgbQSQwJJTRBUGBqMgYoxkoLEU7kWKEApnAYVL66WpXEWtFpiZUS1pdAoDMXm-Yw6ro2FYyCVABXYgQC3iSRQXAW80AWyHWVwsFEQ7TDvSedFrsUyhctz8WoqvhK9mJmnsKi01QjWiUFUujyUQR6kkIEDgMj13aiQpEzpRhYKUucMt0OUiiKYkbE0DF3CeT0nkvGlbx6V9gXZcFjQgD8RRdOREDUC8VF+HgeE+KlvWUYknCbP1jAMM8FApMN4IBLskMNFCuVNc1xCtdCvwLCsMXRJ5ix4PiSKUQMS2DQxiPaPEtE7IE2RYzkTUoMBiDAIduPzNcEArcjOkItxiJ8MSrGwtsXFoptpO8WT5P1HsE0wpFVywwt0Tw4x7HuAitVDXxa2bdRKUqBtHh4Al7LfQ0nOTVMtNci5CMxJUdAiotvQPMySQpclKU9It6VcKLmPjdSkwAM0IShMEgBLMNyP1VCbXRvLpdL-Ky+ptzy1VQwrVUShKxTQX7Qdh1Hcd0CnZ1Z3nUQwHq78fAUE8vBKQxdHKZwlHuYlLycPQIypfRtr0Rk7yAA */
  id: 'Click Popover',

  initial: 'closed',

  invoke: [
    {
      src: 'trackTrigger',
      id: 'trackTrigger',
    },
  ],

  states: {
    closed: {
      on: {
        OPEN: 'open',

        POSITION: {
          target: 'open.forced',
          cond: 'isForcedOpen',
        },
      },

      states: {
        initial: {
          on: {
            POSITION: {
              target: '#Click Popover.open',
              cond: 'isDefaultOpen',
            },
          },
        },
        repeat: {},
      },

      initial: 'initial',
    },

    open: {
      states: {
        idle: {
          on: {
            CLOSE: '#Click Popover.updating animation state',
          },
        },
        forced: {
          type: 'final',
        },
      },

      initial: 'idle',
    },

    'updating animation state': {
      always: {
        target: 'closed.repeat',
        actions: 'updateShouldRunAnimation',
      },
    },
  },

  on: {
    POSITION: {
      actions: ['updatePosition'],
    },
    PLACE: {
      actions: ['updatePlacement'],
    },
    UPDATE_TRIGGER_DIMENSIONS: {
      actions: ['updateTriggerDimensions'],
    },
  },

  schema: {
    events: {} as PopoverEvents,
    context: {} as PopoverContextType,
  },

  predictableActionArguments: true,
  tsTypes: {} as import('./state.typegen').Typegen1,
});

export const HoverMachineContext = createActorContext(hoverPopoverMachine);
export const ClickMachineContext = createActorContext(clickPopoverMachine);

const PopoverContext = createContext<{ triggerType: TriggerType }>({ triggerType: 'hover' });
export const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export type PopoverService = ActorRefFrom<typeof hoverPopoverMachine | typeof clickPopoverMachine>;
export type PopoverState = StateFrom<typeof hoverPopoverMachine | typeof clickPopoverMachine>;
export type UsePopoverSelector = (typeof HoverMachineContext | typeof ClickMachineContext)['useSelector'];

interface ServiceProviderProps {
  children: (service: PopoverService, useSelector: UsePopoverSelector) => ReactElement;
}

const ServiceProvider = ({ children }: ServiceProviderProps) => {
  const { triggerType } = usePopoverContext();
  const MachineContext = triggerType === 'hover' ? HoverMachineContext : ClickMachineContext;
  const service = MachineContext.useActorRef();
  const useSelector = MachineContext.useSelector;

  return children(service, useSelector);
};

interface PopoverMachineContextProviderProps {
  children: (service: PopoverService, useSelector: UsePopoverSelector) => ReactElement;
  offset: number;
  triggerType: TriggerType;
  placement: Placement;
  autoPlacement: boolean;
  forceOpen: boolean;
  defaultOpen: boolean;
}

export const PopoverContextProvider = ({
  children,
  offset: _offset,
  triggerType,
  placement,
  autoPlacement: _autoPlacement,
  forceOpen,
  defaultOpen,
}: PopoverMachineContextProviderProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const options: ComponentProps<typeof HoverMachineContext.Provider>['options'] = {
    guards: {
      isForcedOpen: () => forceOpen,
      isDefaultOpen: () => defaultOpen,
    },
    context: {
      trigger: {
        ref: triggerRef,
        dimensions: null,
      },
      content: {
        ref: contentRef,
        position: null,
        offset: _offset,
        placement,
        shouldRunAnimation: !(defaultOpen || forceOpen),
      },
    },
    actions: {
      updatePosition: assign({
        content: ({ content }, { x, y }) => ({
          ...content,
          position: {
            x,
            y,
          },
        }),
      }),
      updatePlacement: assign({
        content: ({ content }, { placement }) => ({
          ...content,
          placement,
        }),
      }),
      updateTriggerDimensions: assign({
        trigger: ({ trigger }, { dimensions }) => ({
          ...trigger,
          dimensions,
        }),
      }),
      updateShouldRunAnimation: assign({
        content: ({ content }, _event) => ({
          ...content,
          shouldRunAnimation: true,
        }),
      }),
    },
    services: {
      trackTrigger:
        ({ trigger, content }) =>
        (sendBack) => {
          const triggerElement = trigger.ref.current;
          const contentElement = content.ref.current;

          if (triggerElement && contentElement) {
            const updatePosition = () => {
              computePosition(triggerElement, contentElement, {
                placement,
                middleware: [
                  offset({
                    mainAxis: _offset,
                  }),
                  ...(_autoPlacement
                    ? [
                        shift({ padding: _offset }),
                        autoPlacement({
                          crossAxis: true,
                          allowedPlacements: ['top', 'bottom'],
                        }),
                      ]
                    : []),
                ],
              }).then(({ y, x, placement }) => {
                sendBack({ type: 'PLACE', placement });
                sendBack({ type: 'POSITION', x, y });
              });
            };

            const cleanupContent = autoUpdate(triggerElement, contentElement, updatePosition);

            return () => {
              cleanupContent();
            };
          }
        },
    },
  };

  const MachineContext = triggerType === 'hover' ? HoverMachineContext : ClickMachineContext;

  return (
    <PopoverContext.Provider value={{ triggerType }}>
      <MachineContext.Provider options={options}>
        <ServiceProvider>{children}</ServiceProvider>
      </MachineContext.Provider>
    </PopoverContext.Provider>
  );
};

export const isHoverPopoverService = (state: StateFrom<any>): state is StateFrom<typeof hoverPopoverMachine> =>
  state.machine?.id === 'Hover Popover';

export const isClickPopoverService = (state: StateFrom<any>): state is StateFrom<typeof clickPopoverMachine> =>
  state.machine?.id === 'Click Popover';
