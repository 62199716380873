import { autoUpdate, computePosition, hide, offset } from '@floating-ui/dom';
import { createActorContext } from '@xstate/react';
import React, { type ReactNode, type RefObject, useRef } from 'react';
import { type StateFrom, assign, createMachine } from 'xstate';

import type { ElementInformation, INudgeTooltipStepType } from '@commandbar/internal/middleware/types';
import { getElement } from '@commandbar/internal/util/dom';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { RenderMode } from '../RenderNudge';
import { TestID } from './constants';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';

type TooltipEvents =
  | { type: 'SHOW' }
  | { type: 'HIDE' }
  | { type: 'POSITION'; position: { x: number; y: number } }
  | { type: 'ASSIGN_WRAPPER'; inlineWrapperElement: HTMLDivElement }
  | { type: 'DESTROY' };

interface TooltipContext {
  marker: {
    ref: RefObject<HTMLButtonElement>;
    position: {
      x: number;
      y: number;
    } | null;
    offset: INudgeTooltipStepType['form_factor']['marker']['positioning']['offset'];
    alignment: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'];
  };
  renderMode: RenderMode;
  anchor: string | ElementInformation;
  inlineWrapperElement?: HTMLDivElement;
}

const tooltipMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBUD2qA2AXAlgBwDocIMwBiAbQAYBdRUPVWHXVAO3pAA9EAWANgK8AjAFYA7AA5hkqgGYq-YbwCcAGhABPRDLkFFcgEz9Row1UmHZkgL42NaTLkLFSlYXSQhGzVhy88CLwi+rzi4nK8hsLiKqJy-BraCNGSBHLCRqJUxuZyKnYO6Nj4RCTkFIaeDEwsOOycgfwqvPrCKh2SzUYqkrxJiKnpmYbZufIF9iCOJYQAhmwAxgAWqABOOGxQZAAKAPIAygCSyEd7AHLU1d61fo2ICeIEZjIxEpLZcqL9WogAtLECOJ2nFgYpeBJCtNis4CAsVutNlACLBVgB3NhkAASRwAIgBRK6cHx1BoBRDiExCKyScKifj8Kg5SQDBB-elA3hyfICcGQqYzWHw1YbLYEZbECBgTEHLF7ADqRK8JLu5IQ-CsnJM-EklmMKisrOEghawVM2T6OSi4ihgtKmwwmyRZTcAEEDscAOLnAD68oASq6djt8f6lTVfPV-KBAvTREIqDIzHJxFQIkZWQI9HE5JIDSnzNFeHYpmxUFL4F47Xhibco-c2cJnn1Uu1uh8LKy-oYpJzuSpKSmGd9RLaYfbyrXI2SYzoDQRh1JMgk4gafslhMaCIZghrjYm1wkx05SotpVgwGtIFPSdHuDoOZJueJgqoMu1RCzfggMnp9VypDMDp+BtAVx3mJYRSRG9VVnBBPyoLV6V1Qx9UNb8-naJsd37QwOgyHVizAk8IIRUVkVRVAMRg+s1UpJsqFUFolyUYI5CNE1X3NCxeByb5j1mOFIMRMUJQgKU7xVWi4I1FR0l6FRhDTJTZEMLsORffteX4CFQKKEiiDYR02Gg5U6xne8gkYhdpHCTIxF6YQ1O-HohHiWkJAyLlzFHYjBIdJ0xVcMAaIswIIUMGyYmBDJREc5zkl4LoCBUBQwgZFNGL6fgBNhAKTKCozNmvMzpzvQJYki75Uh08RjBA9c+A+GyqDMJLnw1OQSxsIA */
    id: 'Tooltip',
    initial: 'idle',
    states: {
      idle: {
        always: [
          {
            target: 'centered',
            cond: 'shouldCenter',
            description:
              'This is the case when we are editing a step in the studio or with the extension and the target element is unavailable.',
          },
          {
            target: 'inlining',
            cond: 'isInlineAligned',
          },
          'anchoring',
        ],
      },

      centered: {
        type: 'final',
      },

      anchoring: {
        initial: 'hidden',

        invoke: [
          {
            src: 'trackTargetElement',
            id: 'trackTargetElement',
          },
        ],

        states: {
          shown: {
            on: {
              HIDE: {
                target: '#Tooltip.anchoring.hidden',
              },
            },
          },

          hidden: {
            on: {
              SHOW: 'shown',
            },
          },
        },

        on: {
          POSITION: {
            actions: ['updatePosition'],
          },
        },
      },

      inlining: {
        invoke: {
          src: 'wrapTargetElement',
          id: 'wrapTargetElement',
        },

        states: {
          idle: {
            on: {
              ASSIGN_WRAPPER: {
                target: 'inlined',
                actions: ['assignInlineWrapper'],
              },
            },
          },

          inlined: {
            type: 'final',
          },
        },

        initial: 'idle',
      },
    },
    on: {
      DESTROY: {
        actions: ['removeTooltip'],
      },
    },
    schema: {
      events: {} as TooltipEvents,
      context: {} as TooltipContext,
    },
    predictableActionArguments: true,
    tsTypes: {} as import('./state.typegen').Typegen0,
  },
  {
    actions: {
      updatePosition: assign({
        marker: ({ marker }, { position }) => ({
          ...marker,
          position,
        }),
      }),
      assignInlineWrapper: assign({
        inlineWrapperElement: (_, { inlineWrapperElement }) => inlineWrapperElement,
      }),
    },
  },
);

enum States {
  IS_IDLE = 'isIdle',
  IS_MARKER_ANCHORED = 'isMarkerAnchored',
  IS_MARKER_INLINED = 'isMarkerInlined',
  IS_MARKER_CENTERED = 'isMarkerCentered',
  IS_MARKER_POSITIONED = 'isMarkerPositioned',
}

export const stateSelectors: Record<States, (state: StateFrom<typeof tooltipMachine>) => boolean> = {
  isIdle: ({ matches }) => matches('idle'),
  isMarkerAnchored: ({ matches }) => matches('anchoring.shown'),
  isMarkerInlined: ({ matches, context }) => matches('inlining.inlined') && !!context.inlineWrapperElement,
  isMarkerCentered: ({ matches }) => matches('centered'),
  isMarkerPositioned: ({ matches }) => matches('anchoring.shown') || matches('centered'),
};

export const TooltipMachineContext = createActorContext(tooltipMachine);

interface TooltipMachineContextProviderProps {
  children: ReactNode;
  renderMode: RenderMode;
  offset: INudgeTooltipStepType['form_factor']['marker']['positioning']['offset'];
  alignment: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'];
  anchor: string | ElementInformation;
  handleDestroy?: () => void;
}

export const TooltipMachineContextProvider = ({
  children,
  renderMode,
  offset: _offset,
  alignment,
  anchor,
  handleDestroy,
}: TooltipMachineContextProviderProps) => {
  const { showWidgetTableau } = useStore();
  const markerRef = useRef<HTMLButtonElement>(null);

  return (
    <TooltipMachineContext.Provider
      options={{
        guards: {
          shouldCenter: ({ anchor }) =>
            (!showWidgetTableau && isStudioPreview()) || (renderMode === RenderMode.MOCK && !getElement(anchor)),
          isInlineAligned: () => alignment === 'inline_left' || alignment === 'inline_right',
        },
        context: {
          marker: {
            ref: markerRef,
            position: null,
            offset: _offset,
            alignment,
          },
          renderMode,
          anchor,
        },
        services: {
          trackTargetElement:
            ({ anchor, marker }) =>
            (sendBack) => {
              const targetElement = getElement(anchor);

              const markerElement = marker.ref.current;
              if (targetElement && markerElement) {
                const { offset: _offset, alignment } = marker;

                const updatePosition = () => {
                  computePosition(targetElement, markerElement, {
                    placement: alignment === 'left' ? 'left' : 'right',
                    strategy: 'fixed',
                    middleware: [
                      offset({
                        mainAxis: calcOffsetFromInput(_offset.x),
                        crossAxis: calcOffsetFromInput(_offset.y),
                      }),
                      hide({
                        strategy: 'escaped',
                        padding: {
                          top: calcOffsetFromInput(_offset?.y ?? '0'),
                          bottom: -calcOffsetFromInput(_offset?.y ?? '0'),
                          ...(alignment === 'left'
                            ? {
                                right: calcOffsetFromInput(_offset?.x ?? '0'),
                                left: -calcOffsetFromInput(_offset?.x ?? '0'),
                              }
                            : {
                                right: -calcOffsetFromInput(_offset?.x ?? '0'),
                                left: calcOffsetFromInput(_offset?.x ?? '0'),
                              }),
                        },
                      }),
                    ],
                  }).then(({ y, x, middlewareData }) => {
                    sendBack({ type: 'POSITION', position: { x, y } });

                    if (middlewareData.hide?.escaped) {
                      sendBack('HIDE');
                    } else {
                      sendBack('SHOW');
                    }
                  });
                };
                const cleanupMarker = autoUpdate(targetElement, markerElement, updatePosition);

                const cleanup = () => {
                  cleanupMarker();
                  observer.disconnect();
                  sendBack('DESTROY');
                };

                const observer = new MutationObserver((mutationsList) => {
                  for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                      for (const removedNode of mutation.removedNodes) {
                        if (removedNode === targetElement || removedNode.contains(targetElement)) {
                          cleanup();
                        }
                      }
                    }
                  }
                });

                observer.observe(document.documentElement, { childList: true, subtree: true });

                return () => {
                  cleanup();
                };
              }
            },
          wrapTargetElement:
            ({ anchor, marker }) =>
            (sendBack) => {
              const targetElement = getElement(anchor);

              if (!targetElement) {
                return;
              }

              const inlineWrapperElement = document.createElement('div');
              inlineWrapperElement.setAttribute('data-testid', TestID.Wrapper);
              inlineWrapperElement.style.position = 'relative';
              inlineWrapperElement.style.display = 'inline-flex';
              inlineWrapperElement.style.alignItems = 'baseline';
              inlineWrapperElement.style.width = 'fit-content';
              inlineWrapperElement.style.flexDirection = marker.alignment === 'inline_right' ? 'row' : 'row-reverse';

              targetElement.parentNode?.insertBefore(inlineWrapperElement, targetElement);
              targetElement.parentNode?.removeChild(targetElement);
              inlineWrapperElement.appendChild(targetElement);

              sendBack({ type: 'ASSIGN_WRAPPER', inlineWrapperElement });

              const cleanup = () => {
                observer.disconnect();

                const parentOfWrapper = inlineWrapperElement.parentNode;
                if (document.contains(parentOfWrapper)) {
                  parentOfWrapper?.insertBefore(targetElement, inlineWrapperElement);
                  parentOfWrapper?.removeChild(inlineWrapperElement);
                }

                sendBack('DESTROY');
              };

              const observer = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                  if (mutation.type === 'childList') {
                    for (const removedNode of mutation.removedNodes) {
                      if (removedNode === targetElement || removedNode.contains(targetElement)) {
                        cleanup();
                      }
                    }
                  }
                }
              });

              observer.observe(document.documentElement, { childList: true, subtree: true });

              return () => {
                cleanup();
              };
            },
        },
        actions: {
          removeTooltip: handleDestroy,
        },
      }}
    >
      {children}
    </TooltipMachineContext.Provider>
  );
};
