// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" opacity={0.12} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.42 12.713c-.136-.215-.205-.323-.243-.49a1.173 1.173 0 0 1 0-.446c.038-.167.107-.274.243-.49C3.545 9.505 6.895 5 12 5s8.455 4.505 9.58 6.287c.137.215.205.323.243.49.028.125.028.322 0 .446-.038.167-.107.274-.243.49C20.455 14.495 17.105 19 12 19s-8.455-4.505-9.58-6.287Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
    />
  </svg>
);
export default Eye;
