import React, { type ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { Slot } from '../../components/Slot';
import { useFocusTrap } from './useFocusTrap';
import { useMobileExperience } from './useMobileExperience';

export enum ToastElementId {
  TOAST_CONTAINER = 'commandbar-toast-container',
}

type ToastState =
  | {
      element: React.ReactNode;
      position?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'center' | 'top-center';
      duration?: number;
      onEscapeKeyDown?: () => void;
      onEnterKeyDown?: () => void;
      shouldStealFocus?: boolean;
      asChild?: false;
    }
  | {
      element: React.ReactNode;
      asChild: true;
    };

enum ToastAction {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  HIDE_ALL_MATCHING_PATTERN = 'HIDE_ALL_MATCHING_PATTERN',
}

type ToastContext = {
  state: Record<string, ToastState>;
  subscribers: Array<React.Dispatch<React.SetStateAction<Record<string, ToastState>>>>;

  subscribe: (notifySubscriber: React.Dispatch<React.SetStateAction<Record<string, ToastState>>>) => () => void;
};

const toastContext: ToastContext = {
  state: {},
  subscribers: [],

  subscribe: (notifySubscriber) => {
    toastContext.subscribers.push(notifySubscriber);
    return () => {
      toastContext.subscribers = toastContext.subscribers.filter((s) => s !== notifySubscriber);
    };
  },
};

type ToastStateReducerAction =
  | {
      type: ToastAction.SHOW;
      id: string;
      payload: ToastState;
    }
  | {
      type: ToastAction.HIDE;
      id: string;
    }
  | {
      type: ToastAction.HIDE_ALL_MATCHING_PATTERN;
      idPattern?: RegExp;
    };

const toastStateReducer = (state: ToastContext['state'], action: ToastStateReducerAction): ToastContext['state'] => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        [action.id]: action.payload,
      };
    case 'HIDE':
      const newState = { ...state };
      delete newState[action.id];
      return newState;
    case 'HIDE_ALL_MATCHING_PATTERN': {
      if (action.idPattern) {
        const newState = { ...state };
        for (const toastId in newState) {
          if (toastId.match(action.idPattern)) {
            delete newState[toastId];
          }
        }

        return newState;
      }

      return {};
    }
    default:
      return state;
  }
};

const toastDispatch = (action: ToastStateReducerAction) => {
  const newState = toastStateReducer(toastContext.state, action);
  toastContext.state = newState;
  toastContext.subscribers.forEach((notifySubscriber) => notifySubscriber(newState));
};

export const showToast = (id: string, payload: ToastState) => {
  toastDispatch({ type: ToastAction.SHOW, id, payload });
};

export const hideToast = (id: string) => {
  toastDispatch({ type: ToastAction.HIDE, id });
};

export const hideAllMatchingToasts = (idPattern: RegExp) => {
  toastDispatch({ type: ToastAction.HIDE_ALL_MATCHING_PATTERN, idPattern });
};

export const showMessage = (id: string, message: ReactNode, duration = 3000) => {
  showToast(id, {
    element: (
      <Toast id={id} position="top-center">
        <div
          style={{
            pointerEvents: 'auto',
            padding: '8px 12px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 12px',
            borderRadius: '8px',
            border: '0.5px solid rgb(223, 223, 226)',
            alignItems: 'center',
            color: 'rgb(66, 66, 77)',
            fontSize: '14px',
            fontWeight: 500,
            wordBreak: 'break-all',
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        >
          {message}
        </div>
      </Toast>
    ),
    position: 'top-center',
  });

  setTimeout(() => {
    hideToast(id);
  }, duration);
};

type ToastProps = {
  id: string;
  position?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'center' | 'top-center';
  // TODO: duration currently isn't used but will most likely be implemented sometime in the future
  duration?: number;
  onEscapeKeyDown?: () => void;
  onEnterKeyDown?: () => void;
  children: React.ReactNode;
  shouldStealFocus?: boolean;
};

const Toast = ({ position, children, onEscapeKeyDown, onEnterKeyDown, shouldStealFocus }: ToastProps) => {
  const { isMobileDevice, mobileStyles } = useMobileExperience();
  const dialogRef = React.useRef<HTMLDivElement>(null);

  useFocusTrap(dialogRef, { shouldStealFocus, onEnterKeyDown });

  const positionStyles = {
    'top-left': { top: 0, left: 0, right: 0, justifyContent: 'flex-start' },
    'top-right': { top: 0, right: 0, left: 0, justifyContent: 'flex-end' },
    'bottom-left': { bottom: 0, left: 0, right: 0, justifyContent: 'flex-start' },
    'bottom-right': { bottom: 0, right: 0, left: 0, justifyContent: 'flex-end' },
    center: {
      justifyContent: 'center',
    },
    'top-center': {
      top: 0,
      left: 0,
      right: 0,
      justifyContent: 'center',
    },
  };

  return (
    <div
      data-testid="commandbar-toast"
      style={{
        width: '100%',
        display: 'flex',
        position: 'absolute',
        transition: 'all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s',
        ...(position && (isMobileDevice ? mobileStyles.toast.modal : positionStyles[position])),
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        ref={dialogRef}
        aria-labelledby="commandbar-nudge-title"
        aria-live="polite"
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            onEscapeKeyDown?.();
          }
        }}
      >
        {children}
      </div>
    </div>
  );
};

type ToastContainerProps = {
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
};

export const ToastContainer: React.FC<ToastContainerProps> = ({ children, containerStyle }) => {
  const [state, setState] = React.useState(toastContext.state);
  const { isStudioMobilePreview, studioMobilePreviewWrapper, mobileStyles } = useMobileExperience();

  React.useEffect(() => {
    return toastContext.subscribe(setState);
  }, []);

  const content = (
    <div
      id="commandbar-nudge-container"
      style={isStudioMobilePreview ? mobileStyles.toast.container : { position: 'relative' }}
    >
      {children}
      <div
        id={ToastElementId.TOAST_CONTAINER}
        style={
          isStudioMobilePreview
            ? mobileStyles.toast.contentContainer
            : {
                position: 'fixed',
                inset: '16px',
                pointerEvents: 'none',
                ...containerStyle,
              }
        }
      >
        {Object.entries(state).map(([id, toastProps]) => {
          if (toastProps.asChild) {
            return (
              <Slot key={id} data-testid="commandbar-toast" id={id}>
                {toastProps.element}
              </Slot>
            );
          }

          const { element, position, duration, onEscapeKeyDown, onEnterKeyDown, shouldStealFocus } = toastProps;
          return (
            <Toast
              key={id}
              duration={duration}
              id={id}
              position={position}
              shouldStealFocus={shouldStealFocus}
              onEnterKeyDown={onEnterKeyDown}
              onEscapeKeyDown={onEscapeKeyDown}
            >
              {element}
            </Toast>
          );
        })}
      </div>
    </div>
  );
  if (isStudioMobilePreview && studioMobilePreviewWrapper) {
    return ReactDOM.createPortal(content, studioMobilePreviewWrapper);
  }

  return content;
};
