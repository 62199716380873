import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { CmdButton } from '@commandbar/design-system/cmd';
import Spinner from '@commandbar/design-system/cmd/spinner/spinner';
import { Form } from '@commandbar/design-system/components/antd';
import { getBaseURL } from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import Logger from '@commandbar/internal/util/Logger';
import { useAppContext } from 'editor/src/AppStateContext';
import { type HelpCenterIntegrationSpec, INTEGRATION_TYPE, type SourceConfigFormProps } from '../shared/types';

const ZohoForm = ({ editingSource }: SourceConfigFormProps) => {
  const { organization } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    Organization.readInternal(organization.id as string)
      .then(({ integrations }) => {
        const expiresAt = integrations?.zoho.expires_at;

        if (expiresAt) {
          const now = new Date().getTime();
          const expiry = new Date(expiresAt).getTime();

          if (expiry < now) {
            setIsExpired(true);
          }
        } else {
          setIsExpired(true);
        }
      })
      .catch((e) => {
        Logger.error(e);
        setIsExpired(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [organization]);

  const oauthAuthenticate = async () => {
    try {
      const state = {
        org_id: organization.id,
        success_url: window.location.href,
      };

      const baseUrl = getBaseURL();

      const params = new URLSearchParams({
        scope: 'Desk.articles.READ',
        client_id: process.env.REACT_APP_ZOHO_CLIENT_ID as string,
        response_type: 'code',
        access_type: 'offline',
        redirect_uri: `${baseUrl}/integrations/zoho/oauth/callback/`,
        state: JSON.stringify(state),
      });

      const url = `https://accounts.zoho.com/oauth/v2/auth?${params.toString()}`;
      window.location.href = url;
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Form.Item
      key={`${editingSource?.type}token`}
      validateTrigger="onSubmit"
      initialValue={isExpired ? null : 'valid-token'}
      label="Zoho API Access"
      name="token-is-valid"
      style={{ flex: 5 }}
      required
    >
      {isExpired ? (
        <CmdButton onClick={oauthAuthenticate}>Authenticate with Zoho</CmdButton>
      ) : (
        <CmdButton onClick={oauthAuthenticate}>Refresh access</CmdButton>
      )}
    </Form.Item>
  );
};

const Zoho: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.ZOHO,
  name: 'Zoho Desk',
  slug: 'zoho',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/zoho-desk-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/zoho-desk-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: null,
  route: '/zoho',

  getForm: (props) => <ZohoForm {...props} />,

  getPayloadMeta(_, __) {
    return {};
  },

  requiredFields: [''],
  fetchedFields: [''],
};

export default Zoho;
