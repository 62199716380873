import React from 'react';
import styled from '@emotion/styled';

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--content-secondary);

  /* label/sm/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);
`;

const StyledNudgeRatingLabel = ({ left, right }: { left: string; right: string }) => {
  return (
    <Label>
      <span>{left}</span>
      <span>{right}</span>
    </Label>
  );
};

export default StyledNudgeRatingLabel;
