import React from 'react';
import styled from '@emotion/styled';

// NOTE: max-height and overflow-y from https://github.com/tryfoobar/monobar/pull/5516
const FormFieldBlock = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-gap);
  align-self: stretch;
  margin-top: calc(-1 * var(--form-control-focus-ring-width));
  padding: var(--form-control-focus-ring-width) var(--layout-padding) var(--layout-padding);
  max-height: 240px;
  overflow-y: auto;

  &:focus {
    box-shadow: 0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color);
  }

  &:focus-visible {
    outline: none;
  }
`;

const StyledFormFieldBlock = ({ children }: { children: React.ReactNode }) => {
  return <FormFieldBlock>{children}</FormFieldBlock>;
};

export default StyledFormFieldBlock;
