import React from 'react';
import { Image01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

interface IDefaultView {
  isHoveredWhileDragging: boolean;
  supportedFilesHelperText?: string;
}

export const DefaultView = ({ supportedFilesHelperText, isHoveredWhileDragging }: IDefaultView) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      gap: '4px',
      // prevents children from triggering drag events
      pointerEvents: 'none',
      userSelect: 'none',
      height: '100%',
    }}
  >
    <Image01
      style={{
        width: 28,
        marginBottom: 5,
        color: isHoveredWhileDragging ? CB_COLORS.blue700 : '#000',
        transition: 'color 0.3s',
      }}
    />
    <div
      style={{
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
      }}
    >
      <span>Drag image file here or </span>
      <span
        style={{
          color: CB_COLORS.blue700,
          textDecoration: 'underline',
          textDecorationColor: isHoveredWhileDragging ? CB_COLORS.blue700 : 'transparent',
          transition: '0.3s',
        }}
      >
        browse
      </span>
    </div>
    <span
      style={{
        fontSize: '14px',
        lineHeight: '17px',
        color: isHoveredWhileDragging ? CB_COLORS.blue900 : CB_COLORS.neutral500,
        transition: 'color 0.3s',
      }}
    >
      {supportedFilesHelperText}
    </span>
  </div>
);
