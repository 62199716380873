import React, { type FC, type ReactNode } from 'react';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';

export interface LayoutComponents {
  close: ReactNode;
  headerAndMarkdown: ReactNode;
  media: ReactNode;
  actions: ReactNode;
  footer: ReactNode;
  stepCounter: ReactNode;
}

const Classic: FC<LayoutComponents & { maxContentWidth: string }> = ({
  close,
  headerAndMarkdown,
  media,
  actions,
  footer,
  stepCounter,
  maxContentWidth,
}) => (
  <>
    <div style={{ maxWidth: maxContentWidth }}>{headerAndMarkdown}</div>
    {close}
    {media}
    {stepCounter ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0px var(--layout-padding) var(--layout-padding)',
        }}
      >
        {stepCounter}
        {actions}
      </div>
    ) : (
      actions
    )}
    {footer}
  </>
);

interface HorizontalProps extends LayoutComponents {
  columnWidth: string;
}

const Horizontal: FC<HorizontalProps> = ({
  close,
  headerAndMarkdown,
  media,
  actions,
  footer,
  stepCounter,
  columnWidth,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: columnWidth }}>
        {headerAndMarkdown}
        <div style={{ zIndex: 1 }}>{close}</div>
        {stepCounter ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '0px var(--layout-padding) var(--layout-padding)',
            }}
          >
            {stepCounter}
            {actions}
          </div>
        ) : (
          actions
        )}
      </div>

      <div style={{ width: columnWidth }}>{media}</div>
    </div>
    {footer}
  </div>
);

const Vertical: FC<LayoutComponents> = ({ close, headerAndMarkdown, media, actions, footer, stepCounter }) => (
  <>
    <div style={{ zIndex: 1 }}>{close}</div>
    {media}
    {headerAndMarkdown}
    {stepCounter ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0px var(--layout-padding) var(--layout-padding)',
        }}
      >
        {stepCounter}
        {actions}
      </div>
    ) : (
      actions
    )}
    {footer}
  </>
);

interface LayoutTemplateProps extends LayoutComponents {
  layout: INudgeStepType['form_factor']['layout'];
  columnWidth: string;
}

export const LayoutTemplate: FC<LayoutTemplateProps> = ({ layout, columnWidth, ...components }) => {
  switch (layout) {
    case 'vertical':
      return <Vertical {...components} />;
    case 'horizontal':
      return <Horizontal columnWidth={columnWidth} {...components} />;
    case 'classic':
    default:
      return <Classic maxContentWidth={columnWidth} {...components} />;
  }
};
