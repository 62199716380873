import { useEffect, useMemo, useState } from 'react';
import { LoadingState } from '../shared/styles';
import ContentTable from '../shared/ContentTable';
import FileEditDrawer from './FileEditDrawer';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useHistory } from 'react-router';
import { useAppContext } from 'editor/src/AppStateContext';

interface FilesProps {
  showAddContent: boolean;
  setShowAddContent: (show: boolean) => void;
}

const Files = ({ showAddContent, setShowAddContent }: FilesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { organizationId } = useIntegrationsContext();
  const [activeFile, setActiveFile] = useState<IEditorCommandType>();
  const history = useHistory();

  const { commands } = useAppContext();

  const files = useMemo(
    () => commands.filter((c) => c.template?.type === 'helpdoc' && !c.third_party_source),
    [commands],
  );

  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(history.location.search);
      const commandId = searchParams.get('id');

      if (commandId && organizationId && files.length > 0) {
        try {
          const command = await Organization.getCommandById(organizationId.toString(), commandId);
          if (files.find((f) => f.id === command.id)) {
            setActiveFile(command);
            setShowAddContent(true);
          }
        } catch (e) {
          // ntd on invalid command ID
        }
      }
    };
    fetchData();
  }, [history.location.search, files.length]);

  const columns = [
    {
      title: <div>Title</div>,
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
  ];

  if (isLoading) return <LoadingState />;

  return (
    <>
      <ContentTable
        columns={columns}
        data={files}
        dataMappingFunction={(data) =>
          data.map((h) => {
            return {
              ...h,
              title: h.text,
            };
          })
        }
        emptyText="You don't have any files yet. Add one by clicking the 'Add File' button above."
        setIsLoading={setIsLoading}
        openEditDrawer={async (id: number) => {
          if (organizationId) {
            const matchingCommand = await Organization.getCommandById(organizationId.toString(), id.toString());
            setActiveFile(matchingCommand);
            setShowAddContent(true);
          }
        }}
      />
      <FileEditDrawer
        organizationId={organizationId}
        open={showAddContent}
        onClose={() => {
          setShowAddContent(false);
          setActiveFile(undefined);

          const searchParams = new URLSearchParams(history.location.search);
          searchParams.delete('id');
          history.replace(`${history.location.pathname}?${searchParams.toString()}`);
        }}
        activeFile={activeFile}
      />
    </>
  );
};

export default Files;
