import React from 'react';
import styled from '@emotion/styled';

const Card = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: var(--layout-radius-card);
  border: 1px solid var(--base-accent);
  background: var(--background-secondary);
`;

const StyledChatActionCard = ({ children }: { children: React.ReactNode }) => {
  return <Card>{children}</Card>;
};

export default StyledChatActionCard;
