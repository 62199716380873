import React from 'react';
import styled from '@emotion/styled';

import { CmdDropdown, CmdTooltip } from '@commandbar/design-system/cmd';
import { CB_COLORS, Tooltip } from '@commandbar/design-system/components/antd';
import {
  Image01,
  VideoRecorder,
  TextInput,
  Star01,
  BookOpen02,
  Plus,
  CursorBox,
  BookClosed,
  Menu02,
  List,
} from '@commandbar/design-system/icons/react';

import type {
  INudgeContentSurveyRatingBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeStepContentImageBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeStepContentSurveyTextShortBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepType,
  INudgeContentListBlock,
} from '@commandbar/internal/middleware/types';
import type { FormType } from './index';
import { findButtonBlocks } from '../utils';

const FORM_TYPE_OPTIONS_OPTIONS: IFooterOption<FormType>[] = [
  {
    type: 'survey_text',
    label: 'Long Answer',
    Icon: Menu02,
  },
  {
    type: 'survey_text_short',
    label: 'Short Answer',
    Icon: TextInput,
  },
  {
    type: 'survey_rating',
    label: 'Rating',
    Icon: Star01,
  },
  {
    type: 'image',
    label: 'Image',
    Icon: Image01,
  },
  {
    type: 'video',
    label: 'Video',
    Icon: VideoRecorder,
  },
  {
    type: 'help_doc_command',
    label: 'Article or file',
    Icon: BookOpen02,
  },
];

const FooterButton = styled.button`
  border-radius: 100px;
  margin: 16px;
  border: 1px solid ${CB_COLORS.neutral500};
  background: ${CB_COLORS.white};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  cursor: pointer;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  &:hover {
    background-color: transparent;
    color: ${CB_COLORS.neutral1000};
  }
`;

interface IFooterOption<T> {
  type: T;
  label: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface IFooter {
  step: INudgeStepType;
  onStepChange: (step: INudgeStepType) => void;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onCtaToggle: () => void;
}

export const Footer = ({ step, onStepChange, onBlockChange, onCtaToggle }: IFooter) => {
  const stepHasMaxButtons = findButtonBlocks(step.content).length === 2;
  const stepHasContentBlock =
    !!step.content.find((block) => ['image', 'video', 'help_doc_command'].includes(block.type)) ||
    !!step.content.find((block) => block.type.startsWith('survey'));

  const isClassicLayout = !step.form_factor.layout || step.form_factor.layout === 'classic';
  const disableContentBlocks = (stepHasContentBlock && isClassicLayout) || step.form_factor.type === 'banner';

  const [blocksSessionState, setBlocksSessionState] = React.useState<{
    image: INudgeStepContentImageBlockType['meta'];
    video: INudgeStepContentVideoBlockType['meta'];
    help_doc_command: INudgeStepContentHelpDocBlockType['meta'];
    survey_text: INudgeStepContentSurveyTextBlockType['meta'];
    survey_text_short: INudgeStepContentSurveyTextShortBlockType['meta'];
    survey_rating: INudgeContentSurveyRatingBlockType['meta'];
    survey_list: INudgeContentListBlock['meta'];
  }>(
    FORM_TYPE_OPTIONS_OPTIONS.reduce(
      (acc, curr) =>
        step.content.find((block) => block.type === curr.type)
          ? { ...acc, [curr.type]: step.content.find((block) => block.type === curr.type)?.meta }
          : acc,
      {
        image: {
          src: '',
          file_name: '',
          size: '',
        },
        video: {
          type: 'url',
          src: '',
        },
        help_doc_command: {
          command: '',
        },
        survey_text: {
          prompt: '',
        },
        survey_text_short: {
          prompt: '',
        },
        survey_rating: {
          type: 'stars',
          lower_label: '',
          upper_label: '',
          options: 5,
        },
        survey_list: {
          options: [],
          list_type: 'single',
          display_type: 'list',
        },
      },
    ),
  );

  React.useEffect(() => {
    setBlocksSessionState((blocks) => step.content.reduce((acc, curr) => ({ ...acc, [curr.type]: curr.meta }), blocks));
  }, [step.content]);

  const onFormTypeSelect = (type: FormType) => {
    const defaultKey = step.content.length - 1;

    setTimeout(() => {
      if (step.content.find((block) => block.type === type)) {
        onStepChange({
          ...step,
          content: step.content.filter((block) => block.type === 'button' || block.type !== type),
        });
      } else {
        switch (type) {
          case 'image':
            onBlockChange({
              type: 'image',
              sort_key: defaultKey,
              meta: blocksSessionState['image'],
            });
            break;
          case 'video':
            onBlockChange({
              type: 'video',
              sort_key: defaultKey,
              meta: blocksSessionState['video'],
            });
            break;
          case 'help_doc_command':
            onBlockChange({
              type: 'help_doc_command',
              sort_key: defaultKey,
              meta: blocksSessionState['help_doc_command'],
            });
            break;
          case 'survey_text': {
            onBlockChange({
              type: 'survey_text',
              sort_key: defaultKey,
              meta: { ...blocksSessionState.survey_text, validation: { required: { value: false, message: null } } },
            });
            break;
          }
          case 'survey_text_short': {
            onBlockChange({
              type: 'survey_text_short',
              sort_key: defaultKey,
              meta: {
                ...blocksSessionState.survey_text_short,
                validation: { required: { value: false, message: null } },
              },
            });
            break;
          }
          case 'survey_rating': {
            onBlockChange({
              type: 'survey_rating',
              sort_key: defaultKey,
              meta: {
                ...blocksSessionState.survey_rating,
                validation: { required: { value: false, message: null } },
              },
            });
            break;
          }
          case 'survey_list': {
            onBlockChange({
              type: 'survey_list',
              sort_key: -1,
              meta: {
                ...blocksSessionState.survey_list,
                validation: { required: { value: false, message: null } },
              },
            });
            break;
          }
        }
      }
    }, 300);
  };

  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger>
        <FooterButton>
          <Plus height={16} />
        </FooterButton>
      </CmdDropdown.Trigger>
      <CmdDropdown.Content side="top">
        <CmdDropdown.Item onClick={onCtaToggle} disabled={stepHasMaxButtons}>
          <CursorBox height={16} opacity="0.5" /> Button
        </CmdDropdown.Item>

        <CmdTooltip
          key="tooltip"
          side="right"
          showIf={disableContentBlocks}
          message={
            stepHasContentBlock
              ? 'Only one input or media block can be added'
              : 'Banners cannot have input or media blocks'
          }
        >
          <>
            <Tooltip
              key="tooltip"
              placement="right"
              showIf={!isClassicLayout}
              content="Input blocks can’t be used with this layout"
            >
              <CmdDropdown.Group>
                <CmdDropdown.Label className="text-contentBase text-sm">Inputs</CmdDropdown.Label>

                <CmdDropdown.Item
                  onClick={() => {
                    onFormTypeSelect('survey_rating');
                  }}
                  disabled={disableContentBlocks || !isClassicLayout}
                >
                  <Star01 height={16} opacity="0.5" /> Rating
                </CmdDropdown.Item>
                <CmdDropdown.Item
                  disabled={disableContentBlocks || !isClassicLayout}
                  onClick={() => {
                    onFormTypeSelect('survey_text');
                  }}
                >
                  <Menu02 height={16} opacity="0.5" /> Long Answer
                </CmdDropdown.Item>
                <CmdDropdown.Item
                  disabled={disableContentBlocks || !isClassicLayout}
                  onClick={() => {
                    onFormTypeSelect('survey_text_short');
                  }}
                >
                  <TextInput height={16} opacity="0.5" /> Short Answer
                </CmdDropdown.Item>

                <CmdDropdown.Item
                  disabled={disableContentBlocks || !isClassicLayout}
                  onClick={() => {
                    onFormTypeSelect('survey_list');
                  }}
                >
                  <List height={16} opacity="0.5" /> List
                </CmdDropdown.Item>
              </CmdDropdown.Group>
            </Tooltip>

            <CmdDropdown.Group>
              <CmdDropdown.Label className="text-contentBase text-sm">Media</CmdDropdown.Label>

              <CmdDropdown.Item
                disabled={disableContentBlocks}
                onClick={() => {
                  onFormTypeSelect('image');
                }}
              >
                <Image01 height={16} opacity="0.5" /> Image
              </CmdDropdown.Item>
              <CmdDropdown.Item
                disabled={disableContentBlocks}
                onClick={() => {
                  onFormTypeSelect('video');
                }}
              >
                <VideoRecorder height={16} opacity="0.5" /> Video
              </CmdDropdown.Item>
              <CmdDropdown.Item
                disabled={disableContentBlocks}
                onClick={() => {
                  onFormTypeSelect('help_doc_command');
                }}
              >
                <BookClosed height={16} opacity="0.5" /> Article or file
              </CmdDropdown.Item>
            </CmdDropdown.Group>
          </>
        </CmdTooltip>
      </CmdDropdown.Content>{' '}
    </CmdDropdown.Menu>
  );
};
