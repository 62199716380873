import React from 'react';
import { _updateEditorRoute } from '@commandbar/internal/client/symbols';
import { TUpdateEditorRouteDetails } from '@commandbar/internal/util/dispatchCustomEvent';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { isEditorOpen } from 'shared/store/util/editorUtils';
import { useStore } from '../../util/hooks/useStore';
import { StopClickRecorderMessage, StopPreviewMessage } from '@commandbar/internal/client/extension/messages';
import { isAnchorableStep } from '@commandbar/internal/middleware/types';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',

    width: '100%',
    height: '24px',

    background: '#fffcf2',
    borderTop: '1px solid #f0ede3',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',

    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',

    color: '#413303',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {
    width: '8px',
    height: '8px',
    marginRight: '4px',
    background: '#edbe19',
    border: '1px solid #b18f15',
    borderRadius: '50%',
    boxShadow: '0px 0px 4px #edbe19',
  },
  underlinedText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export const DraftFooter = ({ details }: { details: TUpdateEditorRouteDetails }) => {
  const _ = useStore();

  const onEditClick = () => {
    if (details.type === 'nudge') {
      if (_.extension.recorder.enabled && _.extension.recorder.experience) {
        const step = details.nudge.steps[details.stepIndex];
        if (isAnchorableStep(step)) {
          // Tell the Recorder content script to tell the background to stop the recorder
          StopClickRecorderMessage.sendToPage({ value: step.form_factor.anchor });
          return;
        }
      } else if (_.extension.preview.enabled) {
        // Tell the Preview content script to tell the background to stop the preview
        StopPreviewMessage.sendToPage({ nudge: details.nudge, currentStepIndex: details.stepIndex });
        return;
      }
    }

    // @ts-expect-error private SDK method
    window.CommandBar[_updateEditorRoute](details);

    if (!isEditorOpen()) {
      window.CommandBar.openEditor();
    }
  };
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.indicator} />
        <div>Draft (only visible to you)</div>
      </div>
      {!isStudioPreview() && (
        <button
          style={{
            ...styles.underlinedText,
            // unstyle button
            background: 'none',
            border: 'none',
            padding: '0',
            font: 'inherit',
            cursor: 'pointer',
          }}
          onClick={onEditClick}
        >
          Edit↗
        </button>
      )}
    </div>
  );
};
