import React, { useEffect } from 'react';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { Space, Input, Form } from '@commandbar/design-system/components/antd';
import { FormLayout2 } from './FormLayout';
import Auth from '@commandbar/internal/client/authentication';
import useWindowSize from '@commandbar/internal/util/useWindowSize';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { PasswordStrengthBar } from '@commandbar/design-system/components/PasswordStrengthBar';
import { CmdButton, CmdDivider } from '@commandbar/design-system/cmd';

const duplicateEmailErrorMessage = 'A user with this email already exists.';

const translateErrorMessage = (err: any) => {
  if (!err) return 'Something went wrong';

  if (err.hasOwnProperty('email') && err['email'].length > 0) {
    if ((err['email'] as Array<unknown>).includes('This field must be unique')) {
      return duplicateEmailErrorMessage;
    } else {
      return err['email'][0];
    }
  }

  if (err.hasOwnProperty('password') && err['password'].length > 0) {
    return (
      <div>
        {err['password'].map((e: any) => (
          <div key={e}>{e}</div>
        ))}
      </div>
    );
  }

  if (err.hasOwnProperty('message')) {
    return err.message;
  }

  return 'Something went wrong';
};

const Signup = () => {
  const { register } = useAuth();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [password, setPassword] = React.useState('');
  const [form] = Form.useForm();
  const [emailValidateType, setEmailValidateType] = React.useState('onBlur');

  const windowSize = useWindowSize();

  const { appsumo_activation_email } = queryString.parse(window.location.search);

  const sendSegmentEvents = async (eventType: string) => {
    window.analytics.identify(form.getFieldValue('email'), {
      organization: '',
      email: form.getFieldValue('email'),
    });
    window.analytics.track('user signed up', {
      category: 'signup form',
      label: 'Form',
      type: eventType,
      obj: form.getFieldsValue(['email', 'first_name', 'last_name']),
      email: form.getFieldValue('email'),
    });
  };

  const onFinish = async (values: any) => {
    try {
      setLoading(true);

      form.validateFields();
      await register(values);

      sendSegmentEvents('Normal signup');
      history.push('/verify/sent?email=' + encodeURIComponent(form.getFieldValue('email')));
    } catch (err) {
      setError(translateErrorMessage(err));
    }
    setLoading(false);
  };

  const signUpWithGoogle = async () => {
    setLoading(true);

    const authorization_url = await Auth.google({
      redirect_uri: window.location.origin,
      is_signup: true,
    });

    if (!authorization_url) {
      setError('Something went wrong');
    } else {
      sendSegmentEvents('Google signup');
      window.open(authorization_url, '_self');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (error === duplicateEmailErrorMessage) {
      history.push('/verify/sent?email=' + encodeURIComponent(form.getFieldValue('email')));
    }
  }, [error]);

  const getCookieValue = (cookieName: string) => {
    return (
      document.cookie
        ?.split('; ')
        .find((row) => row.startsWith(cookieName + '='))
        ?.split('=')[1] || ''
    );
  };

  useEffect(() => {
    if (appsumo_activation_email) {
      form.setFieldsValue({ email: appsumo_activation_email });
      form.setFieldsValue({ org_name: `${appsumo_activation_email}'s organization` });
    } else {
      form.setFieldsValue({ email: getCookieValue('getStartedEmail') });
    }
    form.setFieldsValue({ first_name: getCookieValue('getStartedFirstName') });
    form.setFieldsValue({ last_name: getCookieValue('getStartedLastName') });
  }, []);

  return (
    <FormLayout2
      h1="Let's go!"
      h2={
        <span>
          {appsumo_activation_email ? 'Already have an account for this email? ' : 'Already have an account? '}
          <Link to="/login">Sign in</Link>
        </span>
      }
      error={error}
      isTall={windowSize.height < 790}
    >
      <br />
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off" id="signup-form">
        <Space align="baseline" style={{ justifyContent: 'space-between', width: '100%' }}>
          <Form.Item
            label="First name"
            name="first_name"
            id="first_name"
            rules={[
              { type: 'string', max: 20 },
              { pattern: new RegExp(/^[a-z ,.'-]+$/i), message: 'Numbers and Special Characters not allowed' },
            ]}
          >
            {''}
            {/* FIXME: Not sure why but AntD does not pick form value unless we do form.setFieldsValue */}
            <Input
              defaultValue={getCookieValue('getStartedFirstName')}
              placeholder="First name"
              onChange={(e) => {
                form.setFieldsValue({ first_name: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="last_name"
            id="last_name"
            rules={[
              { type: 'string', max: 20 },
              { pattern: new RegExp(/^[a-z ,.'-]+$/i), message: 'Numbers and Special Characters not allowed' },
            ]}
          >
            {''}
            {/* FIXME: Not sure why but AntD does not pick form value unless we do form.setFieldsValue */}
            <Input
              defaultValue={getCookieValue('getStartedLastName')}
              placeholder="Last name"
              onChange={(e) => form.setFieldsValue({ last_name: e.target.value })}
            />
          </Form.Item>
        </Space>

        <Form.Item
          label={!!appsumo_activation_email ? 'AppSumo license email ' : 'Work email'}
          name="email"
          id="email"
          validateTrigger={emailValidateType}
          rules={[{ type: 'email', max: 30, message: 'Invalid Email Address', required: true }]}
        >
          <Input
            onBlur={() => setEmailValidateType('onChange')}
            placeholder="you@company.com"
            type="email"
            autoComplete="email"
            disabled={!!appsumo_activation_email}
          />
        </Form.Item>

        {appsumo_activation_email && (
          <Form.Item label="Organization name" name="org_name" id="org_name" rules={[{ type: 'string', max: 255 }]}>
            {''}
            {/* FIXME: Not sure why but AntD does not pick form value unless we do form.setFieldsValue */}
            <Input
              placeholder="Organization name"
              onChange={(e) => form.setFieldsValue({ org_name: e.target.value })}
              defaultValue={`${appsumo_activation_email}'s organization`}
            />
          </Form.Item>
        )}

        <Form.Item label="Password" name="password" required>
          <Input.Password
            placeholder="Enter your password"
            autoComplete="new-password"
            onChange={(e) => {
              setPassword(e.target.value);
              form.setFieldsValue({ password: e.target.value });
            }}
          />
          {password ? (
            <PasswordStrengthBar password={password} minLength={12} scoreWordStyle={{ fontSize: 10 }} />
          ) : (
            <div style={{ height: '28px' }} />
          )}
        </Form.Item>

        <Form.Item style={{ margin: '0px' }}>
          <CmdButton fullWidth variant="primary" type="submit" disabled={loading} loading={loading}>
            Sign up
          </CmdButton>
        </Form.Item>
      </Form>
      <CmdDivider spacing="sm" orientation="center">
        OR
      </CmdDivider>
      <CmdButton fullWidth variant="default" onClick={signUpWithGoogle}>
        Sign up with Google
      </CmdButton>
      <div style={{ padding: '24px 12% 0px', overflowWrap: 'break-word', color: '#8e8e8e', textAlign: 'center' }}>
        By using Command AI, you are agreeing to our <a href="https://commandbar.com/terms">Terms of Service</a> and{' '}
        <a href="https://commandbar.com/privacy">Privacy Policy</a>
      </div>
    </FormLayout2>
  );
};

export default Signup;
