import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  HeaderGroup,
  Header,
  Cell,
  Row,
  FilterFn,
  PaginationState,
  RowSelectionState,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

import { CmdTable } from '../table';

import { CmdPagination } from './pagination';
import { CmdTableToolBar } from './toolbar';
import { CmdCard } from '../../cards';
import { CmdEmpty } from '../../empty';
import { CmdTableNoData } from './noData';
import { CmdTableNoSearchResult } from './noSearchResult';

type CmdRow<TData> = Row<TData>;
type CmdColumnDef<TData, TValue> = ColumnDef<TData, TValue> & {
  width?: number;
  onClick?: (data: CmdRow<TData>) => void;
};

export type CmdSortingState = SortingState;
export type CmdPaginationState = PaginationState;
export type CmdRowSelectionState = RowSelectionState;

interface DataTableProps<TData, TValue> {
  columns: CmdColumnDef<TData, TValue>[];
  data: TData[];
  hasGlobalSearch?: boolean;
  shouldDebounceFilter?: boolean;
  toolBarChildren?: React.ReactChild;
  emptyResultChildren?: React.ReactChild;
  searchResultChildren?: React.ReactChild;
  isLoading?: boolean;
  rowCount?: number;
  rowStyle?: React.CSSProperties;
  showPagination?: boolean;
  searchPlaceholder?: string;
}

type UseReactTableProps<TData> = Parameters<typeof useReactTable<TData>>[0];

type CmdDataTableProps<TData, TValue> = DataTableProps<TData, TValue> & Partial<UseReactTableProps<TData>>;

const CmdDataTable = <TData, TValue>({
  columns,
  data,
  toolBarChildren,
  emptyResultChildren,
  searchResultChildren,
  hasGlobalSearch,
  shouldDebounceFilter,
  isLoading,
  rowCount,
  rowStyle,
  searchPlaceholder,
  showPagination = true,
  ...useReactTableProps
}: CmdDataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const fuzzyFilter: FilterFn<TData> = (
    row: Row<TData>,
    columnId: string,
    filterValue: string,
    addMeta: (meta: any) => void,
  ) => {
    const itemRank = rankItem(row.getValue(columnId), filterValue);
    addMeta(itemRank);
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      ...useReactTableProps.state,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...useReactTableProps,
  });

  const renderBody = () => {
    if (isLoading) {
      return Array.from({ length: data.length || 10 }).map((_, index) => (
        <CmdTable.Row key={index} className="h-[40px]">
          {columns.map((_, index) => (
            <CmdTable.Cell key={index}>
              <CmdEmpty />
            </CmdTable.Cell>
          ))}
        </CmdTable.Row>
      ));
    }

    if (data.length > 0 && table.getRowModel().rows?.length > 0) {
      return table.getRowModel().rows.map((row: Row<TData>) => (
        <CmdTable.Row
          key={row.id}
          data-state={row.getIsSelected() && 'selected'}
          style={rowStyle}
          className={`h-[40px] ${row.getIsSelected() ? 'bg-blue100' : ''}`}
        >
          {row.getVisibleCells().map((cell: Cell<TData, TValue>, index) => {
            const columnDef = cell.column.columnDef as CmdColumnDef<TData, TData>;
            return (
              <CmdTable.Cell
                onClick={() => !!columnDef.onClick && columnDef.onClick(row)}
                key={index}
                className={!!columnDef.onClick ? 'cursor-pointer' : ''}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </CmdTable.Cell>
            );
          })}
        </CmdTable.Row>
      ));
    }

    // Indicates there is a search but no matching results
    if (data.length > 0 && !table.getRowModel().rows?.length) {
      return (
        <tr>
          <td>
            <div className="min-h-[400px]">
              <CmdTableNoSearchResult>{searchResultChildren}</CmdTableNoSearchResult>
            </div>
          </td>
        </tr>
      );
    }

    // No data
    return (
      <tr>
        <td>
          <div className="min-h-[400px]">
            <CmdTableNoData>{emptyResultChildren}</CmdTableNoData>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <CmdCard className="w-full px-0 py-0 relative">
      <div>
        <div className="p-sm">
          <CmdTableToolBar
            setGlobalFilter={table.setGlobalFilter}
            globalFilter={table.getState().globalFilter}
            showGlobalSearch={hasGlobalSearch}
            shouldDebounceFilter={shouldDebounceFilter}
            searchPlaceholder={searchPlaceholder}
          >
            {toolBarChildren}
          </CmdTableToolBar>
        </div>
      </div>
      <div className="rounded-md border">
        <CmdTable>
          <CmdTable.Header className="h-[40px]">
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<TData>) => (
              <CmdTable.Row key={headerGroup.id}>
                {headerGroup.headers.map((header: Header<TData, unknown>) => {
                  return (
                    <CmdTable.Head
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: (header.column.columnDef as CmdColumnDef<TData, TData>).width }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </CmdTable.Head>
                  );
                })}
              </CmdTable.Row>
            ))}
          </CmdTable.Header>
          <CmdTable.Body>{renderBody()}</CmdTable.Body>
        </CmdTable>
      </div>

      {!isLoading && showPagination && (
        <CmdCard.Footer>
          <CmdPagination table={table} rowCount={rowCount} />
        </CmdCard.Footer>
      )}
    </CmdCard>
  );
};

export { CmdDataTable };
export type { CmdColumnDef, CmdRow, CmdDataTableProps };
