import { cva } from 'class-variance-authority';

export const tagStyles = cva(
  [
    'flex',
    'items-center',
    'gap-xs',
    'rounded-button',
    'px-xs',
    'h-fit',
    'whitespace-nowrap',
    'text-sm',
    'font-medium',
    'w-fit',
  ],
  {
    variants: {
      variant: {
        default: ['bg-surfaceMid', 'border-gray300', 'border', 'border-solid', 'text-gray800'],
        success: ['bg-green100', 'text-green800', 'border-none'],
        failure: ['bg-red100', 'text-red800', 'border-none'],
        warning: ['bg-orange100', 'text-orange800', 'border-none'],
        info: ['bg-blue100', 'text-blue800', 'border-none'],
        release: ['bg-darkBlueGradient', 'text-gray100', 'border border-solid border-[1px] border-elementBase'],
      },
      disabled: {
        true: ['cursor-not-allowed'],
      },
      asButton: {
        true: 'cursor-pointer',
      },
    },
    compoundVariants: [
      {
        asButton: true,
        variant: 'default',
        className: [
          'enabled:hover:bg-surfaceGradient',
          'focus:shadow-input',
          'focus:border-blue700',
          'focus-visible:outline-none',
        ],
      },
      {
        asButton: true,
        variant: 'success',
        className: 'enabled:hover:bg-green400',
      },
      {
        asButton: true,
        variant: 'failure',
        className: 'enabled:hover:bg-red400',
      },
      {
        asButton: true,
        variant: 'info',
        className: 'enabled:hover:bg-blue400',
      },
    ],
  },
);
