import React from 'react';
import styled from '@emotion/styled';

const Keyword = styled.span`
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background: rgb(from var(--button-primary-background) r g b / .10);
  border-color: rgb(from var(--button-primary-border-color) r g b / .10);
  cursor: default;

  &:hover {
    color: var(--content-primary);
    border: 1px solid var(--border-primary-hover);
    background: rgb(from var(--button-primary-background-hover)) r g b / .10);
    border-color: rgb(from var(--button-primary-border-color-hover) r g b / .10);
  }

  &:focus {
    background: rgb(from var(--button-primary-background-hover)) r g b / .10);
    border-color: rgb(from var(--button-primary-border-color-hover) r g b / .10);
    color: var(--content-primary);
    border: 1px solid var(--border-primary-hover);
    box-shadow: 0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color);
  }
`;

const StyledKeyword = React.forwardRef<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>>(
  ({ children, ...props }, ref) => {
    return (
      <Keyword ref={ref} {...props}>
        {children}
      </Keyword>
    );
  },
);

export default StyledKeyword;
