/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';

const styles: CSSObject = {
  display: 'flex',
  width: '100%',
  overflow: 'visible',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: 'var(--layout-gap)',
  color: 'color-mix(in srgb, var(--base-accent) 75%, black)',
  minWidth: 0,

  /* paragraph/lg */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-large)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-snug)',

  '&:hover #chat-feedback-buttons': {
    opacity: '1',
  },

  '&:hover #chat-message-timestamp': {
    opacity: '1',
  },
};

const loadingStyles: CSSObject = {
  minHeight: '200px',
};

type Props = {
  isLoading?: boolean;
  isBotMessage?: boolean;
};

const StyledChatContent = React.forwardRef<HTMLDivElement, Props & React.HTMLAttributes<HTMLDivElement>>(
  ({ isLoading, isBotMessage, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        css={[css(styles), isLoading && css(loadingStyles)].filter(Boolean)}
        style={{ ...(isBotMessage && { color: 'var(--content-primary)', alignItems: 'flex-start' }) }}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export default StyledChatContent;
