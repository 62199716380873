import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { HelpDocInteractionCondition as HelpDocInteractionConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select, Tooltip } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ElementNotFoundIcon } from 'editor/src/editor/nudges/NudgeContentForm/Header';

export const HelpDocInteractionCondition = () => {
  const context = useConditionEditor();
  const { commands } = useAppContext();
  // const params = useParams<{ nudgeId?: string }>();

  const { onConditionChange, disabled, setHasBlurred } = context;
  const condition = context.condition as HelpDocInteractionConditionType;

  const helpDoc = commands.find((c) => c.id === Number(condition.help_doc_id));

  let error = '';
  if (condition.help_doc_id !== -1) {
    if (!helpDoc) {
      error = 'The selected HelpDoc does no longer exists.';
    } else if (!helpDoc.is_live) {
      error = 'The selected Nudge is not currently live.';
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Row align="middle" wrap={false} style={{ width: '100%', gap: '4px' }}>
        <ConditionTypeSelect />

        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={condition.help_doc_id !== -1 ? condition.help_doc_id : undefined}
            options={commands
              .filter((command) => command.template.type === 'helpdoc')
              .map((command) => ({
                label: command.text,
                value: command.id,
              }))}
            onChange={(value) => {
              onConditionChange({ ...condition, help_doc_id: value });
            }}
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
            onBlur={() => setHasBlurred(true)}
          />
        </Col>

        {error && (
          <Tooltip showIf={!!error} placement="bottom" content={error}>
            <Row align="middle">
              <ElementNotFoundIcon />
            </Row>
          </Tooltip>
        )}
      </Row>
      <Row style={{ width: '100%', gap: '4px' }} align="middle" wrap={false}>
        <OperatorSelect />

        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={condition.interaction_type ?? ''}
            options={[{ label: 'Viewed', value: 'viewed' }]}
            placeholder="value"
            style={{ width: '100%', height: '24px' }}
            onBlur={() => setHasBlurred(true)}
            onChange={(value) =>
              onConditionChange({
                ...condition,
                interaction_type: value,
              })
            }
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};
