import { createMachine, assign, spawn, send, type ActorRefFrom, type Interpreter } from 'xstate';
import { pure, raise } from 'xstate/lib/actions';
import isEqual from 'lodash/isEqual';

import { getAllNudges, getNudgeById, getSmartNudges, passesMaxRenderedNudges, SmartNudgesContext } from './selectors';
import { cleanupElementAppearNudgeTriggers, setDebugToolBarVisibility } from './actions';
import NudgeMachine from './nudgeMachine';
import { closeEditorIfOpen, openEditorIfLoaded } from 'shared/store/util/editorUtils';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import type { CBStore } from 'shared/store/global-store';
import {
  removeRageTrigger,
  removeSmartDelayTrigger,
  removeSmartListeners,
  removeUserConfusionTrigger,
} from './smartActions';
import { Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import { removeTimedTriggers, type TriggerSource } from 'shared/store/global-actions';
import { TriggerableEntityType } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { isStudioPreview } from '@commandbar/internal/util/location';

/**
 * Overrides for TriggerEvents that can be used to bypass certain conditions.
 */
export interface TriggerEventOverrides {
  /**
   * If true, the trigger will be forwarded to the nudge machine even if the user is an admin.
   * Normally, nudges can only be triggered as a non-admin or in simulate mode.
   * This allows admins to see nudges while using the application.
   */
  admin?: boolean;
  /**
   * If true, the trigger will ignore a global nudge limit.
   * Example: If only three nudges should be shown a day, we can use this to ignore that limit.
   */
  globalLimit?: boolean;
  /**
   * If true, the trigger will ignore the maximum number of nudges that can be rendered at a time.
   */
  maxRenderedNudges?: boolean;
  /**
   * If true, the trigger will ignore the frequency limits of the nudge.
   * Example: If a nudge should only be shown once a day, we can use this to ignore that limit.
   */
  frequency?: boolean;
  /**
   * If true, the trigger will ignore the audience conditions of the nudge.
   */
  audience?: boolean;
  /**
   * If true, the trigger will ignore the snooze check.
   */
  snoozed?: boolean;
  /**
   * If true, the trigger will ignore the page conditions of the nudge.
   */
  page?: boolean;
  /**
   * If true, we will skip checking whether a pinned element is on the page.
   */
  pinnedElement?: boolean;
  /**
   * If true, we will skip checking whether a CTA command is available.
   */
  ctaAction?: boolean;
  /**
   * If true, we will not save any progress to the EUS.
   */
  saveProgress?: boolean;
  /**
   * If true, we will not check whether the nudge is live.
   */
  status?: boolean;
  /**
   * Manually set the step index of the nudge to trigger.
   */
  stepIndex?: number;
  /**
   * If true, we will not check whether the conditions have changed since the last time the nudge was triggered.
   */
  flip?: boolean;
  /**
   * If true, we will not check for nudges that have a limit (i.e. !== no_limit) whether the conditions have changed since the last time the nudge was triggered.
   */
  flipLimit?: boolean;
  /**
   * If true, this is a simulate mode only trigger.
   */
  simulateMode?: boolean;
  /**
   * If true, we will bypass checking that the trigger matches the nudge's trigger. Useful for force
   * triggering from the simulation toolbar.
   */
  triggerMatch?: boolean;
}

export type TriggerEvent = {
  type: 'TRIGGER';
  trigger: INudgeType['trigger'];
  nudgeId?: INudgeType['id'];
  nudge?: INudgeType;
  overrides?: TriggerEventOverrides;
  properties?: Metadata;
  source?: TriggerSource;
};

export type NudgeManagerEvents =
  | TriggerEvent
  | { type: 'END_USER_STORE_LOADED' }
  | { type: 'START_DEBUG'; nudge: INudgeType }
  | { type: 'CLEANUP_SMART_NUDGES'; nudge: INudgeType }
  | { type: 'STOP_DEBUG'; reopenEditor?: boolean }
  | { type: 'REFRESH_SIMULATED_NUDGE'; nudge: INudgeType }
  | { type: 'REFRESH_FROM_CONFIG'; nudges: INudgeType[] }
  | { type: 'EXIT_RENDER_LOOP' };

type NudgeManagerContext = {
  nudgeMachines: Map<string, ActorRefFrom<typeof NudgeMachine>>;
  debugMode: {
    originalNudge: INudgeType | null;
    currentNudge: INudgeType | null;
  };
  triggerEventQueue: Array<TriggerEvent>;
  triggerEvent: TriggerEvent | null;
  smartNudges: SmartNudgesContext;
};

export type NudgeManagerService = Interpreter<
  NudgeManagerContext,
  any,
  NudgeManagerEvents,
  { value: any; context: NudgeManagerContext },
  any
>;

const NudgeManagerMachine = (_: CBStore, nudges: Array<INudgeType>) => {
  const nudgeManagerContext: NudgeManagerContext = {
    nudgeMachines: new Map(),
    debugMode: {
      originalNudge: null,
      currentNudge: null,
    },
    triggerEventQueue: [],
    triggerEvent: null,
    smartNudges: getSmartNudges(nudges),
  };

  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QDkCu0wAICyBDAdrjAE4DEAygCoDyACgPoAiAogEICqA4gNoAMAuolAAHAPawAlgBcJo-EJAAPRAEYVAZgAcAOl4B2PQE51hgEx7TANgCslvQBoQAT0Q2dp3upW9Lmw715rUwAWAF9QxzQMHAIiMDIqAEEAJUomNi4+QSQQMUkZOQVlBDVTFV1gy0t1IPVTestTRxcEYPNddXVg4KMLNqNrcMj0GBjCEm1EgHdcaQl8KEx2WHjMcilRYjBSSmSASU5OZmSshTy5wpzi62Dy73VqtRMaz2bVS2DtQxVGsrrgzQmXxDEBRUZ4cbxSYzOYLJYrYhrDZbUjMZCMejscjHehUajJZj0AAy1ESLEYpxy5wK8iuiFMmms2luFk0Fj0P3MlTeCFM1iZKn5ml4hkqHL0gRBYKwELixG0ADFNjNiBB5otKMQJFASLBSABhInMRLIdgMcjYFJpU2MI7kSkicQXWmgYqGD7aUyGb0qYWaNR6fk8lR6SxfIHWTTmf3Wfx6KUjGWxCZK4gqtVwzXa3WkAkKgnkAAS9Hz1Gw9H11GQCoODtyTppRVU9y+XusKnqwUM7e+PMMmk+Dy0dhstn0CeispTytwqvVmCzOvisG0RIksCkYHw6p2+0Oxzr1NkLqUiAB5Q8Ud4jN4lRFenUPNjYa6gostmsXVvE-ByahqfTedF11bRgPiHdDwbY8mwQNkmW6dQAhuONqmCYMvB0DlEJsNotECSwfyTSF5QA2cMw1LUl2IFd9QACzAABjABrec8EUTBki3CB4kgUhIPyaC6VaSpdG+QJ6m+LRBWDFRvg6YIbjsUpjDCCJQUTMY5UVGc50zSiQLoxiWLhNiOK4niID4lRskdATLldRAB3KWxhUFENLB8TRNBkuTPAUsVlK6QjNOnNMyKA-Tl20QzmPnZZ4j4gQzig+zT1abttF9TQ7EZGMByDZxEFFPRtFsMxfH0UM2QItTpRC-8dPIhdIuo6L6NiuF4rIbhrOSuyT2KJzSoqtzQ087zCoQYrhvK4UDF8UNwjU-BRG4+Acjqqd4j650YNMB5tA+awH0MIwNBjJpJolbQTF9Mdsq7B8VGCrb5WmWYZE6hEkU2MAdsbISvDDEM1GMIJOQfNDJrfbQ-Duuo3C9cwXr-EjGoi7Nl3+wSHN5AdDoUk6zqk6weXUIxtEDBScq8wM2RR4jtLC3SKMx1q1w3Ld1Wx1Lim6MMvVuEcu14bwCpaMVPRuWx-I8iVAwZrTSJZ5q2ZXMCtQWHmBrPSxDAJ31yd8FyH2DL11EpjROR6AdvUV0LAL0tW2qM1jcHYzj8G4rYIG1mDOlMAnjpMYn-WqYNPED7pAz0YV+S0Qx7Ya5mmo1mj2uMxYur9wG6iDonJJjHk9AU3QjH9AYEJsJbQiAA */
      id: 'Nudge Manager',

      context: nudgeManagerContext,

      states: {
        'Awaiting User Store': {
          on: {
            TRIGGER: {
              actions: 'enqueueTrigger',
              target: 'Awaiting User Store',
              internal: true,
              description: "Only triggers that match any of the spawned nudge services' triggers will be enqueued.",
            },

            END_USER_STORE_LOADED: {
              target: 'Forwarding Triggers',
              actions: 'spawnNudgeMachines',
            },
          },
        },

        'Forwarding Triggers': {
          on: {
            CLEANUP_SMART_NUDGES: {
              target: '.',
              actions: ['updateSmartNudges', 'removeUnusedSmartListeners', 'updateTriggerableSelectors'],
            },
            REFRESH_FROM_CONFIG: {
              target: 'Forwarding Triggers',
              actions: 'refreshNudgeMachinesFromConfig',
            },
            REFRESH_SIMULATED_NUDGE: {
              actions: 'refreshSimulatedNudge',
            },
          },

          states: {
            Listening: {
              on: {
                TRIGGER: {
                  target: 'Checking User',
                  actions: ['setTriggerEvent', 'setDebuggingNudge'],
                },
              },

              entry: 'dequeueTrigger',
            },

            Triggering: {
              entry: 'forwardTrigger',
              always: 'Listening',
            },

            'Checking Max Rendered': {
              always: [
                {
                  target: 'Triggering',
                  cond: 'passesMaxNudgesRendered',
                },
                {
                  target: 'Listening',
                },
              ],

              description: `We only ever want to show MAX_RENDERED_NUDGES at a time. If we're already at that limit, abandon the trigger event.`,
            },

            'Checking User': {
              always: [
                {
                  target: 'Checking Max Rendered',
                  cond: 'passesUser',
                },
                'Listening',
              ],

              description: `By default, we will only forward triggers for non-admins. This prevents nudges from popping up while using the application while logged into the editor.

This can be bypassed by setting the debug or admin overrride on a trigger.`,
            },
          },

          initial: 'Listening',
        },
      },

      schema: { events: {} as NudgeManagerEvents, context: {} as NudgeManagerContext },
      predictableActionArguments: true,
      preserveActionOrder: true,
      tsTypes: {} as import('./nudgeManagerMachine.typegen').Typegen0,
      initial: 'Awaiting User Store',

      on: {
        STOP_DEBUG: {
          target: '.Forwarding Triggers',
          actions: [
            'closeNudges',
            'closeDebugToolbar',
            'unsetDebuggingNudge',
            'unsetTriggerEvent',
            'cleanupSmartNudges',
            'stopNudgeMachines',
            'spawnNudgeMachines',
            'reOpenEditor',
          ],
        },

        START_DEBUG: {
          target: '.Forwarding Triggers',
          actions: [
            'setDebuggingNudge',
            'setOriginalDebuggingNudge',
            'stopNudgeMachines',
            'initNudgeMachinesForDebug',
            'closeEditor',
            'showDebugToolbar',
          ],
        },
      },
    },
    {
      actions: {
        refreshSimulatedNudge: assign({
          debugMode: (_, { nudge }) => ({
            originalNudge: nudge,
            currentNudge: nudge,
          }),
        }),
        spawnNudgeMachines: assign((context) => ({
          ...context,
          nudgeMachines: new Map(
            nudges.map((nudge) => [nudge.id.toString(), spawn(NudgeMachine(_, nudge), String(nudge.id))]),
          ),
        })),

        refreshNudgeMachinesFromConfig: pure((context, event) => {
          const refreshedNudgeIds = event.nudges.map((nudge) => nudge.id.toString());
          const currentNudgeIds = Array.from(context.nudgeMachines.keys());

          if (isEqual(refreshedNudgeIds, currentNudgeIds)) {
            return Array.from(context.nudgeMachines.values()).map((machine) => {
              const nudge = event.nudges.find((nudge) => nudge.id === machine.getSnapshot()?.context.nudge.id);
              return send({ type: 'REFRESH_NUDGE', nudge }, { to: machine });
            });
          }

          const newNudgeMachines = new Map(
            event.nudges.map((nudge) => [nudge.id.toString(), spawn(NudgeMachine(_, nudge), String(nudge.id))]),
          );

          for (const nudgeMachine of context.nudgeMachines.values()) {
            nudgeMachine.stop?.();
          }

          return assign((context, _event) => ({
            ...context,
            nudgeMachines: newNudgeMachines,
          }));
        }),

        stopNudgeMachines: ({ nudgeMachines }, { type }) => {
          for (const nudgeMachine of nudgeMachines.values()) {
            if (
              type === 'START_DEBUG' &&
              nudgeMachine.getSnapshot()?.context.nudge.steps?.[0].form_factor.type === 'tooltip'
            ) {
              continue;
            }
            nudgeMachine.stop?.();
          }
        },

        initNudgeMachinesForDebug: assign((context, _event) => {
          const currentNudges = getAllNudges(_);

          const getUpdatedNudges = () => {
            const { originalNudge: debugNudge } = context.debugMode;

            // If there is no debug nudge, return the current list of nudges as is
            if (!debugNudge) {
              return currentNudges;
            }

            // If the debug nudge is already in the current list of nudges, replace it in the list
            // If it is not in the list, add it to the list
            const updatedNudges = currentNudges.some((nudge) => nudge.id === debugNudge.id)
              ? currentNudges.map((nudge) => (nudge.id === debugNudge.id ? debugNudge : nudge))
              : [...currentNudges, debugNudge];

            return updatedNudges;
          };

          const updatedNudges = getUpdatedNudges();
          return {
            ...context,
            nudgeMachines: new Map(
              updatedNudges.map((nudge) => [nudge.id.toString(), spawn(NudgeMachine(_, nudge), String(nudge.id))]),
            ),
          };
        }),

        enqueueTrigger: assign({
          // share link nudges should be prioritized in the queue
          triggerEventQueue: ({ triggerEventQueue }, triggerEvent) =>
            triggerEvent.trigger.type === 'when_share_link_viewed'
              ? [triggerEvent, ...triggerEventQueue]
              : [...triggerEventQueue, triggerEvent],
          triggerEvent: ({ triggerEventQueue }) => triggerEventQueue[0],
        }),

        dequeueTrigger: pure((context) => {
          const { triggerEventQueue } = context;
          const triggerEvent = triggerEventQueue.shift();
          if (triggerEvent) {
            return [raise(triggerEvent)];
          }

          return [];
        }),

        setTriggerEvent: assign({ triggerEvent: (_context, triggerEvent) => triggerEvent }),

        forwardTrigger: pure(({ triggerEvent, nudgeMachines, debugMode }, event) => {
          if (debugMode.currentNudge && triggerEvent) {
            const debugEvent: TriggerEvent = {
              ...triggerEvent,
              overrides: {
                ...triggerEvent?.overrides,
                status: true,
                simulateMode: true,
              },
            };
            return Array.from(nudgeMachines.values()).map((machine) => send(debugEvent, { to: machine }));
          }

          return Array.from(nudgeMachines.values()).map((machine) => send(triggerEvent ?? event, { to: machine }));
        }),

        closeNudges: ({ nudgeMachines }) => {
          for (const nudgeMachine of nudgeMachines.values()) {
            nudgeMachine.send('CLOSE');
          }
        },

        showDebugToolbar: (_context, _event) => {
          setDebugToolBarVisibility(_, true);
        },

        closeDebugToolbar: (_context, _event) => {
          setDebugToolBarVisibility(_, false);
        },

        updateSmartNudges: assign((context, event) => {
          const { nudge } = event;
          return {
            smartNudges: {
              rageClick: context.smartNudges.rageClick.filter((n) => n?.id !== nudge?.id),
              smartDelay: context.smartNudges.smartDelay.filter((n) => n?.id !== nudge?.id),
              userConfusion: context.smartNudges.userConfusion.filter((n) => n?.id !== nudge?.id),
            },
          };
        }),

        updateTriggerableSelectors: (_context, { nudge }) => {
          const { trigger } = nudge;
          if (trigger.type === 'when_element_appears') {
            const matchedSelectorIndex = _.triggerableSelectors.findIndex(
              (selector) => selector === trigger.meta.selector,
            );
            _.triggerableSelectors = [
              ..._.triggerableSelectors.slice(0, matchedSelectorIndex),
              ..._.triggerableSelectors.slice(matchedSelectorIndex + 1),
            ];
          }
        },

        removeUnusedSmartListeners: (_context, _event) => {
          if (!_context.smartNudges.rageClick.length) {
            removeRageTrigger(_);
          } else if (!_context.smartNudges.smartDelay.length) {
            removeSmartDelayTrigger(_);
          } else if (!_context.smartNudges.userConfusion.length) {
            removeUserConfusionTrigger(_);
          }
        },

        cleanupSmartNudges: (_context, _event) => {
          removeSmartListeners(_);
          removeTimedTriggers(_, TriggerableEntityType.NUDGE);
          cleanupElementAppearNudgeTriggers(_);
        },

        closeEditor: (_context, _event) => {
          closeEditorIfOpen();
        },

        reOpenEditor: (_context, { reopenEditor }) => {
          if (reopenEditor) openEditorIfLoaded();
        },

        // this gets set when simulate mode is engaged
        setOriginalDebuggingNudge: assign({
          debugMode: ({ debugMode }, { nudge }) => ({
            ...debugMode,
            originalNudge: debugMode?.originalNudge ? debugMode.originalNudge : nudge,
          }),
        }),
        // this gets set when simulate mode is engaged and when triggering a new nudge within simulate mode
        setDebuggingNudge: assign({
          debugMode: ({ debugMode }, event) => {
            if (event.type === 'START_DEBUG') {
              return {
                ...debugMode,
                currentNudge: event.nudge,
              };
            }

            if (debugMode.currentNudge && event.nudgeId) {
              return {
                ...debugMode,
                currentNudge: getNudgeById(_, event.nudgeId) ?? debugMode.currentNudge,
              };
            }

            return debugMode;
          },
        }),
        // This gets set when simulate mode is disengaged.
        // When resetting simulate mode, the editor stays closed and we want to reset the current nudge to the original nudge.
        // When ending simulate mode entirely, we reopen the editor. In this case, we should also clear the original nudge.
        unsetDebuggingNudge: assign({
          debugMode: ({ debugMode }, { reopenEditor }) => ({
            currentNudge: null,
            originalNudge: reopenEditor ? null : debugMode?.originalNudge,
          }),
        }),
        unsetTriggerEvent: assign({
          triggerEvent: (_context, _event) => null,
        }),
      },
      guards: {
        passesUser: () => !isStudioPreview(),
        passesMaxNudgesRendered: ({ triggerEvent }) =>
          triggerEvent?.overrides?.maxRenderedNudges || passesMaxRenderedNudges(_),
      },
    },
  );
};

export default NudgeManagerMachine;
