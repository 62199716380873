import fuzzysort from 'fuzzysort';
import { DEFAULT_DATE_ONLY_SUGGESTIONS, DEFAULT_SUGGESTIONS, SearchParams, Suggestion } from './constants';
import {
  handleAtSuggestions,
  handleInSuggestions,
  handleNumberSuggestion,
  handleOnSuggestions,
  handleThisNextSuggestions,
} from './handlers';
import {
  getDefaultSuggestions,
  getSpecialUnitsSuggestions,
  getSuggestionsFromStringArray,
  getWordByIndex,
  hasSpecialUnits,
  isMonth,
  parseDate,
  parseToSuggestionFormat,
  suggestAt,
} from './helpers';
import {
  autoCompleteMonth,
  autoCompleteWord,
  capitalizeDay,
  completeMonth,
  parseIfNumber,
  completeSomeNumbers,
} from './modifiers';
import dayjs from 'dayjs';

export const DATE_AND_TIME_FORMAT_ID = 1;
export const DATE_ONLY_FORMAT_ID = 2;
export const TIME_ONLY_FORMAT_ID = 3;

const DEFAULT_DAY_SUGGESTIONS = ['today', 'tomorrow', 'next Monday', 'next week'];

function isNormalInteger(str: string) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

const getParsedString = (input: string) => {
  const handlers = [autoCompleteWord, autoCompleteMonth, parseIfNumber, capitalizeDay, completeSomeNumbers];

  return handlers
    .reduce((acc, cur) => {
      return cur(acc);
    }, input.split(' '))
    .join(' ');
};

const suggestionHandlers: Record<
  string,
  | ((
      formatId: number,
      beginning: string,
      secondWord: string,
      thirdWord: string,
      completeString: string,
    ) => Suggestion[] | undefined)
  | undefined
> = {
  in: handleInSuggestions,
  on: handleOnSuggestions,
  this: handleThisNextSuggestions,
  next: handleThisNextSuggestions,
  at: handleAtSuggestions,
};

const FUZZY_TARGET = [
  'this day',
  'this month',
  'this weekend',
  'next day',
  'next month',
  'last day',
  'last month',
  'last Monday',
  'last week',
  'last weekend',
  'today',
  'tomorrow',
  'on Monday',
  'on Tuesday',
  'on Wednesday',
  'on Thursday',
  'on Friday',
  'on Saturday',
  'on Sunday',
  'weekend',
  'week',
  'at 3pm',
  'at 8pm',
];

const getFuzzyDefaultSuggestions = (query: string, formatId: number) => {
  const fuzyResult = fuzzysort.go(query, FUZZY_TARGET).map(({ target }) => target);
  return getSuggestionsFromStringArray(fuzyResult, formatId);
};

const dateAndTime = (inputValue: string, specificType: number) => {
  const parsedInput = getParsedString(inputValue);

  if (!parsedInput) {
    return getDefaultSuggestions(DEFAULT_SUGGESTIONS, specificType);
  }

  const filtered = DEFAULT_SUGGESTIONS.filter((suggestion) =>
    suggestion.toLowerCase().startsWith(parsedInput.toLowerCase()),
  );

  if (filtered.length) {
    return getSuggestionsFromStringArray(filtered, specificType);
  }

  const beginning = getWordByIndex(parsedInput, 0);
  const secondWord = getWordByIndex(parsedInput, 1);
  const thirdWord = getWordByIndex(parsedInput, 2);

  if (isNormalInteger(beginning) && !secondWord) {
    return handleNumberSuggestion(beginning, specificType);
  }

  const handleSuggestions = suggestionHandlers[beginning];

  if (handleSuggestions) {
    const handledSuggestions = handleSuggestions(
      DATE_AND_TIME_FORMAT_ID,
      beginning,
      secondWord,
      thirdWord,
      parsedInput,
    );

    if (handledSuggestions?.length) {
      return handledSuggestions;
    }

    const parsedSuggestions = parseToSuggestionFormat(parseDate(parsedInput), specificType, parsedInput);

    if (parsedSuggestions.length) {
      return parsedSuggestions;
    }

    return getFuzzyDefaultSuggestions(inputValue, specificType);
  }

  if (hasSpecialUnits(parsedInput)) {
    const specialUnitsSuggestion = getSpecialUnitsSuggestions(parsedInput);

    if (specialUnitsSuggestion.length) {
      return specialUnitsSuggestion;
    }

    return getFuzzyDefaultSuggestions(inputValue, specificType);
  }

  const words = parsedInput.split(' ');
  const indexOfAt = words.map((item) => item.toLowerCase()).indexOf('at');

  if (indexOfAt !== -1 && indexOfAt !== 0 && words.length - indexOfAt < 3) {
    const lastWord = words[words.length - 1];
    if (lastWord && lastWord !== 'at') {
      const suggestions = parseToSuggestionFormat(parseDate(parsedInput), specificType, parsedInput);
      return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, specificType);
    }

    const suggestions = getSuggestionsFromStringArray(suggestAt(words), specificType);
    return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, specificType);
  }

  if (isMonth(parsedInput)) {
    const monthSuggestions = completeMonth(parsedInput, specificType);
    return monthSuggestions.length ? monthSuggestions : getFuzzyDefaultSuggestions(inputValue, specificType);
  }

  const suggestions = parseToSuggestionFormat(parseDate(parsedInput), specificType, parsedInput);
  return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, specificType);
};

const dayOnly = (inputValue: string, _: number) => {
  const parsedInput = getParsedString(inputValue);

  if (!parsedInput) {
    return getDefaultSuggestions(DEFAULT_DAY_SUGGESTIONS, DATE_ONLY_FORMAT_ID);
  }

  const filtered = DEFAULT_DATE_ONLY_SUGGESTIONS.filter((suggestion) =>
    suggestion.toLowerCase().startsWith(parsedInput.toLowerCase()),
  );

  if (filtered.length) {
    return getSuggestionsFromStringArray(filtered, DATE_ONLY_FORMAT_ID);
  }

  const beginning = getWordByIndex(parsedInput, 0);
  const secondWord = getWordByIndex(parsedInput, 1);
  const thirdWord = getWordByIndex(parsedInput, 2);

  if (isNormalInteger(beginning) && !secondWord) {
    const numberSuggestions = handleNumberSuggestion(beginning, DATE_ONLY_FORMAT_ID);

    if (numberSuggestions.length) {
      return numberSuggestions;
    }

    return getFuzzyDefaultSuggestions(inputValue, DATE_AND_TIME_FORMAT_ID);
  }

  const handleSuggestions = suggestionHandlers[beginning];

  if (handleSuggestions) {
    const handledSuggestions = handleSuggestions(DATE_ONLY_FORMAT_ID, beginning, secondWord, thirdWord, parsedInput);

    if (handledSuggestions?.length) {
      return handledSuggestions;
    }

    const parsedSuggestions = parseToSuggestionFormat(parseDate(parsedInput), DATE_ONLY_FORMAT_ID, parsedInput);

    if (parsedSuggestions.length) {
      return parsedSuggestions;
    }

    return getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
  }

  if (hasSpecialUnits(parsedInput)) {
    const specialUnitsSuggestion = getSpecialUnitsSuggestions(parsedInput);

    if (specialUnitsSuggestion.length) {
      return specialUnitsSuggestion;
    }

    return getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
  }

  const words = parsedInput.split(' ');
  const indexOfAt = words.map((item) => item.toLowerCase()).indexOf('at');

  if (indexOfAt !== -1 && indexOfAt !== 0 && words.length - indexOfAt < 3) {
    const lastWord = words[words.length - 1];
    if (lastWord && lastWord !== 'at') {
      const suggestions = parseToSuggestionFormat(parseDate(parsedInput), DATE_ONLY_FORMAT_ID, parsedInput);
      return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
    }

    const suggestions = getSuggestionsFromStringArray(suggestAt(words), DATE_ONLY_FORMAT_ID);
    return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
  }

  if (isMonth(parsedInput)) {
    const monthSuggestions = completeMonth(parsedInput, DATE_ONLY_FORMAT_ID);
    return monthSuggestions.length ? monthSuggestions : getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
  }

  const suggestions = parseToSuggestionFormat(parseDate(parsedInput), DATE_ONLY_FORMAT_ID, parsedInput);
  return suggestions.length ? suggestions : getFuzzyDefaultSuggestions(inputValue, DATE_ONLY_FORMAT_ID);
};

const parsers: Record<number, (value: string, specificType: number) => any[]> = {
  [DATE_AND_TIME_FORMAT_ID]: dateAndTime,
  [DATE_ONLY_FORMAT_ID]: dayOnly,
  [TIME_ONLY_FORMAT_ID]: dateAndTime,
};

const search = (params: SearchParams) => {
  const {
    inputValue = '', // this string to parsed
    type,
  } = params;

  const parserInstance = parsers[type];

  if (!parserInstance) {
    return dateAndTime(inputValue, DATE_AND_TIME_FORMAT_ID);
  }

  return parserInstance(inputValue, type);
};

const display = (date: dayjs.Dayjs, specificType: number, upperCase = true) => {
  // return
  switch (specificType) {
    case DATE_AND_TIME_FORMAT_ID: {
      return upperCase
        ? date.format('ddd, MMM D, YYYY, h:mm A').toUpperCase()
        : date.format('ddd, MMM D, YYYY, h:mm A');
    }

    case DATE_ONLY_FORMAT_ID: {
      return upperCase ? date.format('ddd, MMM D, YYYY').toUpperCase() : date.format('ddd, MMM D, YYYY');
    }
    case TIME_ONLY_FORMAT_ID: {
      return upperCase ? date.format('h:mm A').toUpperCase() : date.format('h:mm A');
    }

    default: {
      return upperCase ? date.format('ddd, MMM D, YYYY').toUpperCase() : date.format('ddd, MMM D, YYYY');
    }
  }
};

const clientReturnValue = (date: dayjs.Dayjs) => {
  return date.toDate();
};

const DateTime = {
  search,
  display,
  clientReturnValue,
};

export default DateTime;
