import React from 'react';
import { Radio, RadioItem } from './Radio';

interface DateTimeArgumentsProps {
  selectedTypeId: number | undefined;
  handleOptionClick: (id: number) => void;
}

const options = [
  {
    id: 1,
    label: 'Date and Time',
    description: 'Example: Thursday at 3pm',
  },
  {
    id: 2,
    label: 'Date',
    description: 'Example: Thursday',
  },
  {
    id: 3,
    label: 'Time',
    description: 'Example: 3pm',
  },
];

export const DateTimeArguments = (props: DateTimeArgumentsProps) => {
  const { selectedTypeId, handleOptionClick: onOptionClick } = props;

  return (
    <Radio>
      {options.map((option) => (
        <RadioItem
          key={option.id}
          label={option.label}
          selected={option.id === selectedTypeId}
          onClick={() => onOptionClick(option.id)}
        />
      ))}
    </Radio>
  );
};
