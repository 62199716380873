/** @jsx jsx  */
import { jsx } from '@emotion/core';
import React from 'react';
import { ThumbsDown, ThumbsUp } from '@commandbar/design-system/icons/react';
import { IAIMessageType } from '@commandbar/internal/middleware/types';
import { useAction } from 'shared/util/hooks/useAction';
import * as HelpHubServiceActions from 'products/helphub/service-actions';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { useChatState } from '../../store/useChatState';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledButtonGroup from '@commandbar/internal/client/themesV2/components/shared/StyledButtonGroup';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import StyledToggleButton from '@commandbar/internal/client/themesV2/components/shared/StyledToggleButton';

type FeedbackButtonProps = {
  preview: boolean;
  message: IAIMessageType;
  isChatHistoryPreview: boolean;
  previewOverrides?: { useThemeV2: boolean };
};

export const FeedbackButtons: React.FC<FeedbackButtonProps> = ({
  preview,
  message,
  isChatHistoryPreview,
  previewOverrides,
}) => {
  const [givenFeedback, setGivenFeedback] = React.useState<1 | -1 | 0 | null>(message.feedback?.rating || null);
  const markFeedbackGiven = useAction(HelpHubServiceActions.setFeedbackGiven);
  const _ =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    !preview ? useStore() : emptyGlobalStore();

  const chatState = useChatState();
  const { chatID, negativeFeedback, isCollectingArgumentValues } = chatState;
  const themeV2 = useThemeV2Context();

  const disabled = preview || isChatHistoryPreview;

  const styles: React.CSSProperties = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
    gap: '16px',
    width: '40px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid rgba(10, 10, 15, 0.24)',
    borderRadius: '4px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    cursor: !disabled ? 'pointer' : 'default',
  };

  const feedbackGivenStyles: React.CSSProperties = {
    ...styles,
    border: '1px solid #dd9d23',
    background: '#fff9ef',
    boxShadow: '0px 0px 0px 3px #dd9d2230',
    color: '#dd9d23',
  };

  const giveFeedback = async (feedback: 1 | -1) => {
    if (feedback === givenFeedback) {
      setGivenFeedback(null);
    } else {
      setGivenFeedback(feedback);
      Track.copilot.chatMessageFeedback(chatID, message.uuid ?? '', chatState, feedback);

      const result = await markFeedbackGiven(message.value, feedback, isCollectingArgumentValues);

      if (result) {
        negativeFeedback(result);
      }
    }
  };

  return (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'] ? (
    <StyledButtonGroup
      id="chat-feedback-buttons"
      css={{
        zIndex: 1,
        position: 'absolute',
        right: 0,
        gap: '4px',
        borderRadius: 'var(--layout-radius-button)',
        border: '1px solid var(--border-primary)',
        background: 'var(--background-primary)',
        bottom: 'calc(-1/2 * var(--form-control-height))',
        opacity: givenFeedback !== null || isMobileDevice() ? 1 : 0,
        '&:hover': { opacity: '1' },
      }}
    >
      <StyledToggleButton
        themeV2={themeV2}
        isSelected={givenFeedback === 1}
        disabled={disabled}
        onClick={() => giveFeedback(1)}
        style={{ width: 'var(--form-control-height)', borderRadius: 'calc(var(--layout-radius-button) - 1px)' }}
      >
        <ThumbsUp />
      </StyledToggleButton>
      <StyledToggleButton
        themeV2={themeV2}
        isSelected={givenFeedback === -1}
        disabled={disabled}
        onClick={() => giveFeedback(-1)}
        style={{ width: 'var(--form-control-height)', borderRadius: 'calc(var(--layout-radius-button) - 1px)' }}
      >
        <ThumbsDown />
      </StyledToggleButton>
    </StyledButtonGroup>
  ) : (
    <div
      className="chat-feedback"
      style={{
        display: 'flex',
        gap: '8px',
        marginTop: '12px',
        // Override hover styles when feedback has been given or when on mobile since there's no hover on mobile
        opacity: givenFeedback !== null || isMobileDevice() ? 1 : undefined,
      }}
    >
      <button
        disabled={disabled}
        tabIndex={0}
        style={givenFeedback === 1 ? feedbackGivenStyles : styles}
        onClick={() => giveFeedback(1)}
      >
        <ThumbsUp height={16} width={16} />
      </button>

      <button
        disabled={disabled}
        tabIndex={0}
        style={givenFeedback === -1 ? feedbackGivenStyles : styles}
        onClick={() => giveFeedback(-1)}
      >
        <ThumbsDown height={16} width={16} />
      </button>
    </div>
  );
};

export default FeedbackButtons;
