import React, { useState } from 'react';
import * as axiosInstance from '@commandbar/internal/middleware/network';

import { requestToSlack, ISlackType } from '@commandbar/internal/client/slack';
import dayjs from 'dayjs';

import { Alert, AlertProps, Row, Modal } from '@commandbar/design-system/components/antd';
import { pricing_plan_defaults } from './billing/pricing-plans';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import Z from '@commandbar/internal/client/Z';
import { useAppContext } from 'editor/src/AppStateContext';

interface IBannerAlert {
  message: AlertProps['message'];
  type: AlertProps['type'];
  showAlert: boolean;
}

const SilentModeButton = () => {
  const [clicked, setClicked] = useState<boolean>(false);

  const { user } = useAuth();
  const { organization } = useAppContext();

  const onClick = () => {
    Modal.confirm({
      title: 'Confirm: Turn off silent mode',
      content: (
        <span>
          {`When silent mode is turned off, analytics events will be captured automatically and displayed here in your dashboard. This
          change can take up to 3 hours to take effect.`}
        </span>
      ),
      onOk() {
        setClicked(true);
        const payload = {
          type: 'dashboard-usage' as ISlackType,
          email: user?.email,
          message: `🔊 ${user?.email} requested to turn off silent mode for ${organization?.name}`,
        };
        cmdToast.success('Silent mode will be turned off.');
        requestToSlack(payload);
      },
      okText: 'Turn off',
    });
  };

  return (
    <CmdButton disabled={clicked} onClick={onClick}>
      {clicked ? 'Turning off...' : 'Turn off'}
    </CmdButton>
  );
};

const DashboardBanner = () => {
  // XXX: For now, I'm disabling this banner until we work through the MAU limit issue
  // const { result: numMAUs } = { result: 0 };
  const { organizationSettings, flags } = useAppContext();
  const [freeTrialEndDate, setFreeTrialEndDate] = useState<dayjs.Dayjs | null>(null);
  const [tier, setTier] = useState<string | undefined>('Starter');

  const silentModeEnabled = organizationSettings?.silent_mode;

  // const MAULimit = organizationSettings?.end_user_limit;
  // const exceededMAULimit = React.useMemo(() => {
  //   if (MAULimit === 0 || MAULimit === undefined) return false; // 0 indicated unlimited
  //   const BUFFER_PCT = 0.05;
  //   return (numMAUs as number) > MAULimit * (1 + BUFFER_PCT);
  // }, [MAULimit, numMAUs]);

  // Days since account creation
  const now = new Date();

  React.useEffect(() => {
    const determineTrialState = async () => {
      try {
        const { data: billingProfile } = await axiosInstance.get('billing/profile/');
        if (!billingProfile.on_free_trial || !billingProfile.free_trial_end_date) {
          setFreeTrialEndDate(null);
          return;
        }
        const endDate = dayjs(billingProfile.free_trial_end_date);
        const isFreeTrialOver = dayjs(now).isAfter(endDate) || dayjs(now).isSame(endDate);
        setFreeTrialEndDate(isFreeTrialOver ? null : endDate);
        setTier(
          pricing_plan_defaults.find((plan) => billingProfile.name.replace(/Free trial/, '').includes(plan.uid))?.title,
        );
      } catch (err) {
        return;
      }
    };

    determineTrialState();
  }, []);

  const alerts: IBannerAlert[] = [
    {
      message: `You've exceeded your MAU limit this month. Please contact the CommandBar team to upgrade your plan`,
      type: 'warning' as AlertProps['type'],
      showAlert: false, // !!exceededMAULimit,
    },
    {
      message: (
        <Row justify="space-between" align="middle">
          <div>You turned on silent mode, so no analytics events are being captured.</div>
          <SilentModeButton />
        </Row>
      ),
      type: 'info' as AlertProps['type'],
      showAlert: !!silentModeEnabled,
    },
    // FIXME: add back after demo
    {
      message: "You're on the dev env. Data last refreshed from prod on May 1.",
      type: 'warning' as AlertProps['type'],
      showAlert: axiosInstance.getBaseURL().includes('api-dev.commandbar.com') && !!flags['show-dev-env-banner'],
    },
    ...(!!freeTrialEndDate
      ? [
          {
            message: `Your free trial of ${tier} ends in ${Math.abs(
              dayjs(now).diff(freeTrialEndDate, 'days'),
            )} day(s) on ${freeTrialEndDate?.format(
              'MMMM DD, YYYY',
            )} and you'll no longer be able to access the Command AI dashboard.`,
            type: 'info' as AlertProps['type'],
            showAlert: !!freeTrialEndDate,
          },
        ]
      : []),
    {
      message: flags['show-incident-banner'],
      type: 'warning' as AlertProps['type'],
      showAlert: !!flags['show-incident-banner'],
    },
  ].filter((msg) => msg.showAlert);

  if (alerts.length === 0) return <div />;

  return (
    <div id="dashboard-alerts-banner">
      {alerts.map((msg, idx) => (
        <Alert
          key={idx}
          banner
          type={msg.type}
          message={msg.message}
          style={{ padding: '15px 50px', zIndex: Z.Z_EDITOR_BANNER }}
        />
      ))}
    </div>
  );
};

export default DashboardBanner;
