/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { merge } from 'lodash';

import Sender from '../../management/Sender';
import BasicStyleEditor from './skinEditors/BasicStyleEditor';
import { Input, Space, Skeleton, Alert, FormRow, Tooltip } from '@commandbar/design-system/components/antd';
import { useReportEvent } from '../../hooks/useEventReporting';
import _ from 'lodash';
import { FormFactor } from '@commandbar/internal/client/CommandBarClientSDK';
import { ISkinType } from '@commandbar/internal/middleware/types';
import NoneStyleOverlay from './skinEditors/NoneStyleOverlay';
import ProStyleEditor from './skinEditors/ProStyleEditor';
import { Skin } from '@commandbar/internal/middleware/skin';
import { IPartialTheme } from '@commandbar/internal/client/theme';
import { AdvancedStylesButton, Header } from './skinEditors/styled-components';
import { ChevronRight, Lock01 } from '@commandbar/design-system/icons/react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { DetailsHeader } from '../components/DetailsHeader';
import { ScrollContainer } from '../debugger/styled';
import { PaddingContainer } from '../Router';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { CmdButton, CmdDivider, cmdToast } from '@commandbar/design-system/cmd';
import { reportErrorToUser } from '../utils/ErrorReporting';
import { useAppContext } from 'editor/src/AppStateContext';
import { useModS } from '@commandbar/internal/hooks/useModS';

type TSkinUpdate = Pick<ISkinType, 'logo' | 'chat_avatar' | 'skin'>;

interface IProps {
  skin: ISkinType;
  tier: string;
  showAdvancedStyles: boolean;
  onUpdate: (skin: ISkinType) => void;
  previewSkin: (skin: string | TSkinUpdate) => void;
  allSkins: ISkinType[];
}

const SkinDetail = (props: IProps) => {
  const defaultSkin = props.allSkins.find((skin: ISkinType) => skin.default);
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { hasUnsavedChangesRef } = useAppContext();

  const [activeSkin, setActiveSkin] = React.useState<TSkinUpdate>(() => ({
    logo: props.skin.logo ?? '',
    chat_avatar: props.skin.chat_avatar ?? '',
    skin: _.clone(props.skin.skin),
  }));

  useEffect(() => {
    Sender.setTheme(activeSkin);
  }, [activeSkin]);

  // on unmount, "reset" theme to the default
  useEffect(() => {
    return () => {
      Sender.setTheme('reset');
      if (defaultSkin) Sender.setTheme(defaultSkin.slug);
    };
  }, []);

  const [formFactor, setFormFactor] = React.useState<FormFactor | undefined>(undefined);
  const { reportEvent } = useReportEvent();
  const [name, setName] = React.useState<string>(props.skin.name);
  const [slug, setSlug] = React.useState<string>(props.skin.slug);

  const getFormFactor = async () => {
    const timeout = new Promise((resolve) => setTimeout(() => resolve(null), 500 /*ms*/));
    const instanceAttributes = await Promise.race([timeout, Sender.instanceAttributes()]);
    setFormFactor(instanceAttributes?.formFactor || { type: 'modal' });
  };

  React.useEffect(() => {
    getFormFactor();
  }, []);

  const updateSkinMeta: () => Promise<string> = async () => {
    let currentSkinSlug = props.skin.slug;

    if (props.skin.name !== name || props.skin.slug !== slug) {
      if (props.allSkins.find((skin) => skin.slug === slug && skin.id !== props.skin.id)) {
        throw new Error(`A skin with the slug ${slug} already exists.`);
      }
      const skin = await Skin.updateMetadata(props.skin.slug, { name, slug });
      props.onUpdate(skin);
      currentSkinSlug = slug;
    }

    return currentSkinSlug;
  };

  const handleSave = async () => {
    if (!!props.skin.frozen || !hasChanged()) {
      return;
    }

    if (!props.showAdvancedStyles && props.tier === 'none') return;

    const skinUpdate = activeSkin;

    try {
      const slug = await updateSkinMeta();
      const skin = await Skin.update({ ...skinUpdate, slug });
      cmdToast.success('Updated!');

      reportEvent('skin edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });

      props.onUpdate(skin);
    } catch (err) {
      reportErrorToUser(err);
      return;
    }
  };

  useModS(handleSave);

  const unsavedChanges = !_.isEqual(activeSkin, {
    skin: props.skin?.skin,
    logo: props.skin.logo ?? '',
    chat_avatar: props.skin.chat_avatar ?? '',
  });

  useEffect(() => {
    hasUnsavedChangesRef.current = unsavedChanges;
  }, [unsavedChanges]);

  if (formFactor === undefined) return null;

  const isBasicTier = props.tier === 'basic';

  const hasChanged = () => {
    const newSkin = { skin: activeSkin.skin, name, slug, logo: activeSkin.logo, chat_avatar: activeSkin.chat_avatar };
    const oldSkin = {
      skin: props.skin.skin,
      logo: props.skin.logo ?? '',
      chat_avatar: props.skin.chat_avatar ?? '',
      slug: props.skin.slug,
      name: props.skin.name,
    };
    return !_.isEqual(newSkin, oldSkin);
  };

  const updateSkinTheme = (theme: IPartialTheme) => {
    const updatedTheme = merge({}, activeSkin.skin, theme);
    try {
      const updatedSkin = { ...activeSkin, skin: updatedTheme };
      setActiveSkin(updatedSkin);

      props.previewSkin(updatedSkin);

      return updatedSkin;
    } catch (err) {
      reportErrorToUser(err);
      return;
    }
  };

  const resetTheme = (theme: IPartialTheme) => {
    try {
      const updatedSkin = { ...activeSkin, skin: theme };
      setActiveSkin(updatedSkin);
      props.previewSkin(updatedSkin);
      return updatedSkin;
    } catch (err) {
      reportErrorToUser(err);
      return;
    }
  };

  const updateLogo = async (svgString: string) => {
    setActiveSkin({ ...activeSkin, logo: svgString });
  };

  if (props.showAdvancedStyles) {
    return (
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden', gap: '16px' }}>
        <DetailsHeader
          subTitle="Edit Skin / Advanced styles"
          title={props.skin.name}
          onBackPress={() => {
            history.goBack();
          }}
          actions={[
            <CmdButton variant="primary" onClick={handleSave} disabled={!!props.skin.frozen || !hasChanged()}>
              Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
            </CmdButton>,
          ]}
        ></DetailsHeader>
        <ScrollContainer>
          <ProStyleEditor
            theme={activeSkin.skin}
            formFactor={formFactor}
            updateTheme={updateSkinTheme}
            isBasicTier={isBasicTier}
          />
        </ScrollContainer>
      </div>
    );
  }

  return (
    <>
      <DetailsHeader
        subTitle="Edit skin"
        title={props.skin.name}
        onBackPress={() => {
          history.goBack();
        }}
        actions={[
          <Space>
            <CmdButton
              variant="primary"
              onClick={handleSave}
              disabled={props.tier === 'none' || !!props.skin.frozen || !hasChanged()}
            >
              Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
            </CmdButton>
          </Space>,
        ]}
      />
      <ScrollContainer>
        <PaddingContainer>
          {props.skin.frozen && <SkinFrozenAlert />}
          <Skeleton active loading={props.tier === undefined} paragraph={{ rows: 7 }}>
            <div style={{ position: 'relative' }}>
              <Header>Details</Header>
              <div style={{ padding: '0px 8px' }}>
                <FormRow title="Name:" input={<Input value={name} onChange={(e) => setName(e.target.value)} />} />
                <FormRow title="Slug:" input={<Input value={slug} onChange={(e) => setSlug(e.target.value)} />} />
                <FormRow title="Form factor:" input={<pre>{formFactor.type}</pre>} />
              </div>

              {formFactor.type !== 'inline' && <div></div>}
              <div>
                <CmdDivider spacing="md" />
                <BasicStyleEditor
                  theme={activeSkin.skin}
                  updateTheme={updateSkinTheme}
                  resetTheme={resetTheme}
                  formFactor={formFactor}
                  isBasicTier={isBasicTier}
                  updateLogo={updateLogo}
                  logo={activeSkin.logo}
                />
              </div>

              {props.tier === 'none' && <NoneStyleOverlay />}
              <Tooltip
                showIf={props.tier !== 'pro'}
                content={
                  <span>
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={`${process.env.REACT_APP_DASHBOARD_URL}/billing`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Upgrade your plan
                    </a>{' '}
                    for access.
                  </span>
                }
                interactive
              >
                <AdvancedStylesButton
                  onClick={() => {
                    if (props.tier !== 'pro') return;
                    history.push(generatePath(routeMatch.path, { ...routeMatch.params, advanced: 'advanced' }));
                  }}
                  disabled={props.tier !== 'pro'}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {props.tier !== 'pro' && <Lock01 />}
                    <span>Advanced styles</span>
                  </div>
                  <ChevronRight />
                </AdvancedStylesButton>
              </Tooltip>
            </div>
          </Skeleton>
        </PaddingContainer>
      </ScrollContainer>
    </>
  );
};

const SkinFrozenAlert = () => {
  return (
    <Alert
      type="warning"
      style={{ marginBottom: 16, marginTop: -16 }}
      message={
        <span>
          Based on the plan you're on, this skin can't be saved or used in production.
          <br /> To edit this skin,
          <a
            href={`${process.env.REACT_APP_DASHBOARD_URL}/billing`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#1890ff', marginLeft: 2 }}
          >
            upgrade your plan
          </a>
          .
        </span>
      }
    />
  );
};

export default SkinDetail;
