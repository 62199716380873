import React, { useMemo, useRef } from 'react';
import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
import { GridRows } from '@visx/grid';
import useResizeObserver from './useResizeObserver';
import { formatDate, BarChart, TooltipData, tooltipStyles, formatToolTip } from './utils';
import { Loading } from './loading';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { GraphDetailsStatCard } from './GraphDetailsStatCard';

const verticalMargin = 120;
const horizontalMargin = 40;

export type BarsProps = {
  chartData?: BarChart;
  isLoading?: boolean;
};

const minYScaleMax = 10;

const CmdBarGraph = ({ chartData = {}, isLoading }: BarsProps) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ scroll: true });
  const tooltipTimeout = useRef<number>();

  const resizeRef = useRef<HTMLDivElement>(null);
  const { width: parentWidth, height: parentHeight } = useResizeObserver<HTMLDivElement>(resizeRef);

  const chartDataEntries = useMemo(() => Object.entries(chartData), [chartData]);
  const xMax = parentWidth - horizontalMargin;
  const yMax = parentHeight - verticalMargin;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: chartDataEntries.map(([date, _]) => date),
        padding: 0.4,
      }),
    [xMax, chartDataEntries],
  );

  const dataMax = Math.max(...chartDataEntries.map(([_, count]) => count));

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, dataMax < minYScaleMax ? minYScaleMax : dataMax],
      }),
    [yMax, chartDataEntries],
  );

  if (isLoading) return <Loading />;

  return (
    <div ref={resizeRef} style={{ width: '100%', height: '100%' }}>
      <div ref={containerRef}>
        <svg width={parentWidth} height={parentHeight}>
          <rect width={parentWidth} height={parentHeight} fill="none" rx={14} />
          <Group top={verticalMargin / 2} left={40}>
            <GridRows scale={yScale} width={xMax} height={yMax} stroke="rgba(0, 0, 0, 0.16)" numTicks={4} />
            <AxisLeft
              scale={yScale}
              hideAxisLine
              hideTicks
              tickFormat={(value) => `${Number(value).toLocaleString()}`}
              numTicks={4}
              tickLabelProps={{
                fill: '#797C85',
                fontSize: 12,
                textAnchor: 'middle',
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            />

            {chartDataEntries.map(([date, count], index) => {
              const barWidth = xScale.bandwidth();
              const barHeight = yMax - yScale(count);
              const barX = xScale(date);
              const barY = yMax - barHeight;

              if (!barX) {
                return null;
              }

              return (
                <BarRounded
                  key={`bar-${date}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill="#4A70F2"
                  radius={4}
                  top
                  onMouseLeave={() => {
                    tooltipTimeout.current = window.setTimeout(() => {
                      hideTooltip();
                    }, 300);
                  }}
                  onMouseMove={(event) => {
                    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);

                    // HACK: magic numbers are used to position tooltip with same offset as in the stackedBarChart
                    // Tooltip positioning is calculated differently and these figures make up the diff
                    const offsetLeft = 110;
                    const offsetTop = 68;

                    const left = barX + barWidth / 2 - offsetLeft;
                    const top = event.clientY - verticalMargin * 2 + offsetTop;

                    showTooltip({
                      tooltipData: {
                        key: `bar-${date}`,
                        index,
                        width: barWidth,
                        height: barHeight,
                        x: barX,
                        y: barY,
                        color: '#4A70F2',
                        bar: {
                          x: barX,
                          y: barY,
                          data: {
                            date,
                            count,
                          },
                        },
                      },
                      tooltipTop: top,
                      tooltipLeft: left,
                    });
                  }}
                />
              );
            })}
          </Group>

          <AxisBottom
            top={yMax + 60}
            left={horizontalMargin}
            scale={xScale}
            tickFormat={formatDate}
            hideTicks
            hideAxisLine
            numTicks={5}
            hideZero
            tickLabelProps={{
              fill: '#797C85',
              fontSize: 12,
              textAnchor: 'middle',
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          />
        </svg>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop && tooltipTop + 10}
            left={tooltipLeft && tooltipLeft + 125}
            style={{
              ...tooltipStyles,
              padding: 0,
              background: 'transparent',
              border: 'none',
              boxShadow: 'none',
              width: 200,
            }}
          >
            <GraphDetailsStatCard
              subtitle={formatToolTip(tooltipData.bar.data.date)}
              metric={tooltipData.bar.data.count.toLocaleString()}
              title="Opens"
            />
          </TooltipInPortal>
        )}
      </div>
    </div>
  );
};

export { CmdBarGraph };
