import { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Alert, CB_COLORS, Radio, Row, Page, Typography } from '@commandbar/design-system/components/antd';

import SourceEditDrawer from './edit-drawer/SourceEditDrawer';
import { RadioContent, StyledRadioGroup } from '../shared/styles';

import type { IntegrationSpec, Source } from '../../integrations/shared/types';
import styled from '@emotion/styled';
import { useRunSync } from './useRunSync';
import { CmdButton } from '@commandbar/design-system/cmd';
import { ClockRefresh, FlipBackward, List, RefreshCw02 } from '@commandbar/design-system/icons/react';
import { IntegrationIcon, Pills } from './Card';
import SettingsDropdown from '../shared/SettingsDropdown';
import HelpDocsTable from './HelpDocsTable';
import SyncHistoryTable from './SyncHistoryTable';
import SourceIntegrationList from '../../integrations/help-center/active-help-center-integrations';
import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import { useAppContext } from 'editor/src/AppStateContext';
import useSourceState from './useSourceState';
import { PROCESSING_OPERATION } from './SourceProcessingProgress';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

type View = 'content' | 'syncs';

const SourceDetailSelector = ({ view, setView }: { view: View; setView: (view: View) => void }) => (
  <Row justify="space-between" align="middle">
    <StyledRadioGroup>
      <Radio.Group value={view} onChange={(e) => setView(e.target.value)} optionType="button" size="middle">
        <Radio value="content">
          <RadioContent>
            <List />
            <RadioTitle>Content</RadioTitle>
          </RadioContent>
        </Radio>
        <Radio value="syncs">
          <RadioContent>
            <ClockRefresh />
            <RadioTitle>Sync History</RadioTitle>
          </RadioContent>
        </Radio>
      </Radio.Group>
    </StyledRadioGroup>
  </Row>
);

const SourceDetailPage = () => {
  const [integrations, setIntegrations] = useState<IntegrationSpec[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationSpec>();
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const sourceId = parseInt(id, 10);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [syncsUpdated, setSyncsUpdated] = useState(0);
  const [view, setView] = useState<View>('content');
  const [isEditingHelpDoc, setIsEditingHelpDoc] = useState(false);
  const { user } = useAuth();

  const { organizationSettings, loading, commands, flags } = useAppContext();

  const source: Source | null = useMemo(() => {
    const helpDocsIntegration = selectedIntegration?.sources?.find((i) => i.id === sourceId);

    if (!helpDocsIntegration || !selectedIntegration) return null;

    return {
      ...helpDocsIntegration,
      ...selectedIntegration,
    };
  }, [selectedIntegration, sourceId, refresh]);

  useEffect(() => {
    HelpDocsIntegration.list().then((raw) => {
      let merged = SourceIntegrationList.map((integration) => {
        integration.sources = raw.filter((i) => i.type === integration.slug);
        return integration;
      });

      // TODO(pjhul): Remove this flag check once the Zoho integration is released
      if (!flags?.['release-zoho-work-drive-integration']) {
        merged = merged.filter((integration) => integration.slug !== 'zoho');
      }

      setIntegrations(merged);
      // HACK: this is used to force the source to update if commands are added or deleted
      // Refreshing includes re-fetching the source and including the updated commands_count
      setRefresh((r) => !r);
    });
  }, [commands.length]);

  useEffect(() => {
    setSelectedIntegration(integrations.find(({ sources }) => sources?.find(({ id }) => id === sourceId)));
  }, [integrations, sourceId]);

  const { progress, operation } = useSourceState(String(source?.id));
  const runSync = useRunSync(false, source || undefined, source?.id);

  const baseDomain: string | null =
    source?.meta?.domain ?? source?.meta?.single_urls?.[0] ?? source?.meta?.recursive_urls?.[0] ?? null;
  const domain = baseDomain?.startsWith('http') ? baseDomain : `https://${baseDomain}`;

  return (
    <Page
      style={{ width: '100%', display: 'flex', flexDirection: 'column', background: '#F3F4F5' }}
      headerRowChildren={
        source && (
          <>
            {organizationSettings?.help_center_sync === 'upload-once' && (
              <div style={{ marginBottom: 24 }}>
                <Alert
                  type="warning"
                  message="Your plan only allows for a single sync. To perform continuous syncing as your help center changes,
              visit your billing dashboard."
                />
              </div>
            )}
            <CmdButton icon={<FlipBackward />} onClick={() => history.goBack()}>
              Back
            </CmdButton>
            <Header>
              <HeaderLeft>
                <IntegrationIcon source={source} />
                <TitleBlock>
                  <Title>{source.name}</Title>
                  {baseDomain && (
                    <Domain href={domain} target="_blank">
                      {baseDomain} <Arrow>↗</Arrow>
                    </Domain>
                  )}
                  <PillsContainer>
                    <Pills source={source} />
                  </PillsContainer>
                </TitleBlock>
              </HeaderLeft>

              <HeaderRight>
                <CmdButton
                  variant="primary"
                  disabled={
                    organizationSettings?.help_center_sync === 'upload-once' ||
                    !!progress ||
                    operation === PROCESSING_OPERATION.DELETING ||
                    !hasRequiredRole(user, 'editor')
                  }
                  onClick={() => {
                    runSync();
                    setSyncsUpdated((syncsUpdated) => syncsUpdated + 1);
                  }}
                >
                  <RefreshCw02 />
                  Sync Content
                </CmdButton>

                <SettingsDropdown
                  source={source}
                  onSettingsClick={() => setOpenEditDrawer?.(true)}
                  onDelete={() => history.push('/content/sources')}
                  refresh={() => setRefresh((r) => !r)}
                />
              </HeaderRight>
            </Header>
          </>
        )
      }
    >
      {localStorage.getItem('has_migrated_help_sync') === 'false' && (
        <Alert message="Please resync your help centers" type="info" style={{ marginBottom: '10px' }}></Alert>
      )}

      {source &&
        (view === 'content' ? (
          <HelpDocsTable
            viewSelector={<SourceDetailSelector view={view} setView={setView} />}
            source={source}
            syncsUpdated={syncsUpdated}
            isLoading={isLoading || loading}
            setIsLoading={setIsLoading}
            isEditingHelpDoc={isEditingHelpDoc}
            setIsEditingHelpDoc={setIsEditingHelpDoc}
          />
        ) : (
          <SyncHistoryTable
            viewSelector={<SourceDetailSelector view={view} setView={setView} />}
            source={source}
            syncsUpdated={syncsUpdated}
          />
        ))}
      <SourceEditDrawer
        integration={selectedIntegration}
        setIntegration={setSelectedIntegration}
        onCancel={() => setOpenEditDrawer(false)}
        onComplete={() => setOpenEditDrawer(false)}
        sourceId={sourceId}
        open={!!selectedIntegration && openEditDrawer && !isEditingHelpDoc}
      />
    </Page>
  );
};

export default SourceDetailPage;

const Domain = styled.a`
  color: ${CB_COLORS.neutral600};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
`;

const Arrow = styled.span`
  font-weight: 400;
`;

const RadioTitle = styled.span`
  font-weight: 500;
`;

export const Header = styled.div`
  display: flex;
  padding-top: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const HeaderLeft = styled.div`
  display: flex;
  gap: 8px;
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 8px;
  align-self: end;
`;

export const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PillsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled(Typography.Title)`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 !important;
`;
