import Logger from '@commandbar/internal/util/Logger';
import React, { useContext, useRef, useState } from 'react';

export type HistoryItem = { page: string; params: Record<string, any> };
export type HistoryItemWithId = HistoryItem & { id: number | string };

export type NavigateOptions = { replace?: boolean };

type Router = {
  history: HistoryItemWithId[];

  navigate: (item: HistoryItem, options?: NavigateOptions) => void;
  back: () => void;
};

const useMemoryRouter = (initialEntries: HistoryItem[], onNavigate?: (item: HistoryItem) => void): Router => {
  const [state, setState] = useState<{ history: HistoryItemWithId[]; nextId: number }>({
    history: initialEntries.map((item, idx) => ({ ...item, id: idx })),
    nextId: initialEntries.length,
  });

  // ensure initialEntries does not change
  const _initialEntries = useRef(initialEntries);
  if (_initialEntries.current !== initialEntries) {
    Logger.warn("MemoryRouter: Changes to `initialEntries` after initial mount won't have any effect");
  }

  const navigate = (item: HistoryItem, options?: NavigateOptions) => {
    onNavigate?.(item);

    if (options?.replace) pop();
    push(item);
  };

  const push = (item: HistoryItem) => {
    setState(({ history, nextId }) => ({ history: [{ ...item, id: nextId }, ...history], nextId: nextId + 1 }));
  };

  const pop = () => {
    setState(({ history, ...rest }) => ({ history: history.slice(1), ...rest }));
  };

  return { history: state.history, navigate, back: pop };
};

export const useRouter = (): Router => useContext(RouterContext);
export const useParams = <T,>() => useContext(ParamsContext) as T;

const RouterContext = React.createContext<Router>(undefined as unknown as Router);
const ParamsContext = React.createContext<any>(undefined as unknown as any);

export const MemoryRouter = ({
  initialEntries,
  onNavigate,
  children,
}: {
  initialEntries: HistoryItem[];
  onNavigate?: (item: HistoryItem) => void;
  children: React.ReactNode;
}) => {
  const router = useMemoryRouter(initialEntries, onNavigate);

  return (
    <>
      <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
    </>
  );
};

export const Routes = ({ pages }: { pages: { page: string; element: React.ElementType }[] }) => {
  const { history } = useContext(RouterContext);

  // history is a stack, so first item is the current page
  const renderedPages = history.map((item, idx) => {
    // look thru paths and find the first match
    const p = pages.find((page) => item.page === page.page);

    if (!p) return null;
    const Element = p.element;

    // only display the page for the first item in `history`, but keep all of them mounted
    return (
      <div key={item.id} style={idx === 0 ? { height: '100%', width: '100%' } : { display: 'none' }}>
        <ParamsContext.Provider value={item.params}>
          <Element />
        </ParamsContext.Provider>
      </div>
    );
  });

  return <>{renderedPages}</>;
};
