// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Link05 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17 17h-1.222a.778.778 0 0 1-.778-.778V7.778c0-.43.348-.778.778-.778H17a5 5 0 0 1 0 10Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 12h10m-9 5H7A5 5 0 0 1 7 7h2m6.778 10H17a5 5 0 0 0 0-10h-1.222a.778.778 0 0 0-.778.778v8.444c0 .43.348.778.778.778Z"
    />
  </svg>
);
export default Link05;
