import { ReactComponent as FeatureExplorationSVG } from '../../img/feature.svg';
import { ReactComponent as SAASOnboardingSVG } from '../../img/saas.svg';
import { ReactComponent as NewAdminUser } from '../../img/new_admin_user.svg';
import { ReactComponent as NewFeature } from '../../img/new_feature.svg';

import { saasOnboarding, featureExploration, newAdminUser, newFeatureLaunch } from './checklist_templates';
import { Template } from '../components/templates/templates';
import { IChecklist } from '@commandbar/internal/middleware/types';

export const template_cards: Template<IChecklist>[] = [
  {
    title: 'SaaS Onboarding',
    svg: SAASOnboardingSVG,
    category: 'saas_onboarding',
    template: saasOnboarding,
  },
  {
    title: 'Feature Exploration',
    svg: FeatureExplorationSVG,
    category: 'feature_exploration',
    template: featureExploration,
  },
  {
    title: 'New Feature Launch',
    svg: NewAdminUser,
    category: 'new_feature_launch',
    template: newFeatureLaunch,
  },
  {
    title: 'New Admin User',
    svg: NewFeature,
    category: 'new_admin_user',
    template: newAdminUser,
  },
];
