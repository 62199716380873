import { partition } from 'lodash';
import React from 'react';

import { RecommendationSet } from '@commandbar/internal/middleware/recommendationSet';
import { IRecommendationSet } from '@commandbar/internal/middleware/types';

import Sender from '../../management/Sender';
import { useReportEvent } from '../../hooks/useEventReporting';
import { useAppContext } from 'editor/src/AppStateContext';

const useRecommendationSets = () => {
  const { organization, commands } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const DEFAULT_RECOMMENDATION_SET: IRecommendationSet = React.useMemo(
    () => ({
      id: -1,
      widget: 'helphub',
      name: 'Recommendation Set',
      is_live: false,
      default: true,
      show_expression: { type: 'LITERAL', value: true },
      audience: { type: 'all_users' },
      actions: commands
        .filter((c) => c.template.type === 'helpdoc')
        .slice(0, 5)
        .map((c) => ({
          action: {
            type: 'execute_command',
            meta: { command: c.id.toString() },
          },
          cta: c.name || '',
        })),
    }),
    [commands],
  );

  const [defaultRecommendationSet, setDefaultRecommendationSet] = React.useState<IRecommendationSet | null>(null);
  const [recommendationSets, setRecommendationSets] = React.useState<IRecommendationSet[] | null>(null);

  // populate supportCTA and additionalResources from backend API
  React.useEffect(() => {
    (async () => {
      // partition on show_as_primary_cta using lodash partition
      const [defaultRecommendationSets_, recommendationSets_] = partition(
        await RecommendationSet.list(),
        (r) => r.default,
      );

      if (defaultRecommendationSets_[0]) {
        setDefaultRecommendationSet(defaultRecommendationSets_[0]);
      } else {
        // if no Support CTA in DB, use default values
        setDefaultRecommendationSet(DEFAULT_RECOMMENDATION_SET);
      }
      setRecommendationSets(recommendationSets_);

      forceUpdate();
    })();
  }, [organization]);

  const updateDefaultRecommendationSet = async (newDefaultRecommendationSet: IRecommendationSet) => {
    if (!newDefaultRecommendationSet) return;

    setDefaultRecommendationSet(newDefaultRecommendationSet);
    forceUpdate();

    if (newDefaultRecommendationSet.id < 0) {
      // create
      setDefaultRecommendationSet(await RecommendationSet.create(newDefaultRecommendationSet));
      const payloadMessage = `Default (ID: ${newDefaultRecommendationSet.id})`;
      reportEvent('recommendation set created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
      });
      forceUpdate();
    } else {
      await RecommendationSet.update(newDefaultRecommendationSet);
      const payloadMessage = `Default (ID: ${newDefaultRecommendationSet.id})`;
      reportEvent('recommendation set edited', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: payloadMessage,
      });
      forceUpdate();
    }

    Sender.reload(['reloadOrganization']);
  };

  const update = async (updatedRecommendationSet: IRecommendationSet) => {
    if (!recommendationSets) return;

    await RecommendationSet.update(updatedRecommendationSet);
    setRecommendationSets(
      recommendationSets.map((s) => (s.id === updatedRecommendationSet.id ? updatedRecommendationSet : s)),
    );
    forceUpdate();

    Sender.reload(['reloadOrganization']);

    const payloadMessage = `${updatedRecommendationSet.default ? 'Default' : updatedRecommendationSet.name} (ID: ${
      updatedRecommendationSet.id
    })`;

    reportEvent('recommendation set edited', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: payloadMessage,
    });
  };

  const del = async (id: number) => {
    if (!recommendationSets) return;

    const deleted = recommendationSets.find((s) => s.id === id);

    if (!deleted) return;
    if (deleted.default) return;

    await RecommendationSet.delete(id);
    setRecommendationSets((prev) => prev?.filter((s) => s.id !== id) ?? null);
    forceUpdate();

    Sender.reload(['reloadOrganization']);

    const payloadMessage = `${deleted.name} (ID: ${id})`;

    reportEvent('recommendation set deleted', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: payloadMessage,
    });
  };

  const addNew = async (recommendationSet?: IRecommendationSet): Promise<IRecommendationSet> => {
    if (!recommendationSets) throw new Error('recommendationSets does not exist');

    if (!recommendationSet) {
      recommendationSet = {
        ...DEFAULT_RECOMMENDATION_SET,
        default: false,
        actions: [],
        name: 'Recommendation Set',
      };
    }

    const newItem = await RecommendationSet.create(recommendationSet);
    Sender.reload(['reloadOrganization']);

    setRecommendationSets([...recommendationSets, newItem]);
    forceUpdate();

    reportEvent('recommendation set created', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: `${newItem.name} (ID: ${newItem.id})`,
    });

    return newItem;
  };

  return {
    defaultRecommendationSet: defaultRecommendationSet,
    recommendationSets: recommendationSets,
    actions: {
      recommendationSets: {
        update,
        del,
        addNew,
      },
      defaultRecommendationSet: {
        update: updateDefaultRecommendationSet,
      },
    },
  };
};

export default useRecommendationSets;
