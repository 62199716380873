/** @jsx jsx  */

/** v1 copilot only, not used in v2 or v3 */

import React from 'react';
import { jsx } from '@emotion/core';
import ActionButton from 'shared/components/ActionButton';
import type { LabeledAction } from '@cb/types/entities/command/actions';
import type { IChecklist, ICommandType, IEditorCommandType, INudgeType } from '@commandbar/internal/middleware/types';
import { useStore } from 'shared/util/hooks/useStore';
import { emptyGlobalStore } from 'shared/store/global-store';
import * as GlobalActions from 'shared/store/global-actions';
import { getCommandById } from 'shared/store/global-selectors';
import * as HelpHubServiceActions from 'products/helphub/service-actions';

import { useAction } from 'shared/util/hooks/useAction';
import { ACTIONS_ROUTE, PAGES_ROUTE, getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import { useChatState } from '../../store/useChatState';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import type { TriggerSource } from 'shared/store/global-actions';
import { isCursorStep } from '../../../nudges/service-selectors';

export type ExperienceButtonButtonProps = {
  preview: boolean;
  disabled?: boolean;
  showableExperience:
    | { type: 'nudge'; nudge: INudgeType }
    | { type: 'checklist'; checklist: IChecklist }
    | { type: 'command'; command: ICommandType | IEditorCommandType };
  previewOverrides?: { useThemeV2: boolean };
} & React.HTMLAttributes<HTMLButtonElement>;

export const ExperienceButton: React.FC<ExperienceButtonButtonProps> = ({
  preview,
  style,
  disabled,
  showableExperience,
  previewOverrides,
  ...rest
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  const chatState = useChatState();
  const { executeCommandWithArguments } = chatState;

  const setHelpHubMinimized = useAction(HelpHubServiceActions.setHelpHubMinimized);
  const activatePushExperience = useAction(GlobalActions.activatePushExperience);
  const toggleHelpHubVisible = useAction(HelpHubServiceActions.toggleHelpHubVisible);
  const themeV2 = useThemeV2Context();

  const useThemesV2 = (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'];

  const executeAction = useAction(
    (_, action: LabeledAction['action'], e?: React.MouseEvent<HTMLElement, MouseEvent>, args?: Record<string, any>) => {
      // might need to collect arguments
      if (action.type === 'execute_command' && args === undefined) {
        const command = getCommandById(_, action.meta.command);
        if (command && Object.keys(command.arguments).length > 0) {
          executeCommandWithArguments(command);
          return;
        }
      }

      GlobalActions.executeAction(_, action, e, args);
      setHelpHubMinimized(true);
    },
  );

  if (showableExperience.type === 'nudge') {
    return useThemesV2 ? (
      <StyledPrimaryButton
        themeV2={themeV2}
        onClick={(e) => {
          if (preview) {
            window.open(`/editor${getNudgeRoute(showableExperience.nudge)}/${showableExperience.nudge.id}`, '_blank');
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            // INFO: cursor steps will animate in and minimize HelpHub
            if (!isCursorStep(showableExperience.nudge, showableExperience.nudge.steps[0])) {
              toggleHelpHubVisible();
            }
            const x = e?.clientX;
            const y = e?.clientY;
            const source: TriggerSource | undefined =
              x && y
                ? {
                    experience: 'copilot',
                    position: [x, y],
                  }
                : undefined;
            activatePushExperience(showableExperience.nudge, 'nudge', source);
          }
        }}
        textWrap
        {...rest}
      >
        {showableExperience.nudge.copilot_cta_label || showableExperience.nudge.steps[0].title}
      </StyledPrimaryButton>
    ) : (
      <ActionButton
        preview={preview}
        style={{ marginTop: '9px', width: '100%' }}
        onClick={(e) => {
          if (preview) {
            window.open(`/editor${getNudgeRoute(showableExperience.nudge)}/${showableExperience.nudge.id}`, '_blank');
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            // INFO: cursor steps will animate in and minimize HelpHub
            if (!isCursorStep(showableExperience.nudge, showableExperience.nudge.steps[0])) {
              toggleHelpHubVisible();
            }
            const x = e?.clientX;
            const y = e?.clientY;
            const source: TriggerSource | undefined =
              x && y
                ? {
                    experience: 'copilot',
                    position: [x, y],
                  }
                : undefined;
            activatePushExperience(showableExperience.nudge, 'nudge', source);
          }
        }}
        {...rest}
      >
        {showableExperience.nudge.copilot_cta_label || showableExperience.nudge.steps[0].title}
      </ActionButton>
    );
  } else if (showableExperience.type === 'checklist') {
    return useThemesV2 ? (
      <StyledPrimaryButton
        themeV2={themeV2}
        onClick={() => {
          if (preview) {
            window.open(`/editor/checklists/${showableExperience.checklist.id}`, '_blank');
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            toggleHelpHubVisible();
            activatePushExperience(showableExperience.checklist, 'questlist');
          }
        }}
        textWrap
        {...rest}
      >
        {showableExperience.checklist.copilot_cta_label || showableExperience.checklist.title}
      </StyledPrimaryButton>
    ) : (
      <ActionButton
        preview={preview}
        style={{ marginTop: '9px', width: '100%' }}
        onClick={() => {
          if (preview) {
            window.open(`/editor/checklists/${showableExperience.checklist.id}`, '_blank');
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            toggleHelpHubVisible();
            activatePushExperience(showableExperience.checklist, 'questlist');
          }
        }}
        {...rest}
      >
        {showableExperience.checklist.copilot_cta_label || showableExperience.checklist.title}
      </ActionButton>
    );
  } else if (showableExperience.type === 'command') {
    return useThemesV2 ? (
      <StyledPrimaryButton
        themeV2={themeV2}
        onClick={(e) => {
          if (preview) {
            window.open(
              `${process.env.REACT_APP_DASHBOARD_URL}/editor${
                showableExperience.command.template.type === 'link' ? PAGES_ROUTE : ACTIONS_ROUTE
              }/${showableExperience.command.id}`,
              '_blank',
            );
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            executeAction({ type: 'execute_command', meta: { command: showableExperience.command.id.toString() } }, e);
          }
        }}
        textWrap
        {...rest}
      >
        {showableExperience.command.copilot_cta_label || showableExperience.command.text}
      </StyledPrimaryButton>
    ) : (
      <ActionButton
        preview={preview}
        style={{ marginTop: '9px', width: '100%' }}
        onClick={(e) => {
          if (preview) {
            window.open(
              `${process.env.REACT_APP_DASHBOARD_URL}/editor${
                showableExperience.command.template.type === 'link' ? PAGES_ROUTE : ACTIONS_ROUTE
              }/${showableExperience.command.id}`,
              '_blank',
            );
          } else {
            Track.copilot.experienceClicked(chatState.chatID, chatState, showableExperience);
            executeAction({ type: 'execute_command', meta: { command: showableExperience.command.id.toString() } }, e);
          }
        }}
        {...rest}
      >
        {showableExperience.command.copilot_cta_label || showableExperience.command.text}
      </ActionButton>
    );
  }

  return null;
};

export default ExperienceButton;
