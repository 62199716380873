import * as t from 'io-ts';

import { AdminFilter, CardDataV, DateFilter, FunnelDataV, PaginationFilter, TableDataV } from './common';
import { read, mkQueryString } from '../generics';

import * as axiosInstance from '../network';

import {
  NudgeContentSurveyTextBlockV,
  NudgeStepContentSurveyTextShortBlockTypeV,
  NudgeContentSurveyRatingBlockV,
  NudgeContentListBlockV,
} from '../nudge';

const AnalyticsSurveysDataV = t.type({
  active: CardDataV,
  responses: CardDataV,
  completion_rate: CardDataV,
  table: TableDataV,
});

export type AnalyticsSurveysData = t.TypeOf<typeof AnalyticsSurveysDataV>;

/* Survey Details with Responses */
const SurveyDetailV = t.union([
  NudgeContentSurveyTextBlockV,
  NudgeStepContentSurveyTextShortBlockTypeV,
  NudgeContentSurveyRatingBlockV,
  NudgeContentListBlockV,
  t.null,
]);

const SurveyResponseV = t.type({
  value: t.union([t.string, t.number]),
  created_timestamp: t.string,
  end_user: t.union([t.string, t.number, t.undefined, t.null]),
});

const NudgeStepV = t.type({
  id: t.number,
  survey_details: SurveyDetailV,
  title: t.string,
  body: t.string,
  responses: t.array(SurveyResponseV),
});

export type SurveyDetailsWithResponses = t.TypeOf<typeof SurveyDetailsWithResponsesV>;
export type SurveyDetailsWithResponsesNudgeStep = t.TypeOf<typeof NudgeStepV>;
export type SurveyDetailsWithResponsesNudgeStepResponses = t.TypeOf<typeof SurveyResponseV>;
export type SurveyDetailsWithResponsesNudgeStepSurveyDetail = t.TypeOf<typeof SurveyDetailV>;

const SurveyDetailsWithResponsesV = t.type({
  nudge_id: t.number,
  nudge_steps: t.array(NudgeStepV),
});

const NPSChartDataV = t.type({
  key: t.union([t.literal('promoters'), t.literal('passives'), t.literal('detractors')]),
  type: t.literal('card'),
  metric: t.number,
  percent_change: t.number,
  percent: t.number,
});
export type NPSChartData = t.TypeOf<typeof NPSChartV>;

const ChartDataV = t.type({
  type: t.literal('chart'),
  chartType: t.literal('horizontal_chart'),
  data: t.array(NPSChartDataV),
});

const NPSChartV = t.union([ChartDataV, t.undefined, t.null]);

const AnalyticsSurveysDetailDataV = t.type({
  total_responses: CardDataV,
  total_views: CardDataV,
  completion_rate: CardDataV,
  nps: t.union([CardDataV, t.undefined, t.null]),
  nps_chart: NPSChartV,
  end_user_funnel: FunnelDataV,
  table: TableDataV,
  goal: t.union([CardDataV, t.undefined, t.null]),
});

const AnalyticsSurveysUsersDataV = t.type({
  views: TableDataV,
});

export type AnalyticsSurveysDetailData = t.TypeOf<typeof AnalyticsSurveysDetailDataV>;
export type AnalyticsSurveysUsersData = t.TypeOf<typeof AnalyticsSurveysUsersDataV>;

type SurveysParams = {
  show_unpublished: string;
} & DateFilter &
  AdminFilter;

type SurveysDetailParams = {
  show_unpublished: string;
  goal_event_name?: string;
} & DateFilter &
  AdminFilter;

type SurveysDetailResponsesParams = {
  start_date: string;
  end_date: string;
  sort_order: string;
} & AdminFilter;
type SurveysUsersParams = { search_filter: string } & DateFilter & AdminFilter & PaginationFilter;

export class AnalyticsSurveys {
  public static read = (params: SurveysParams) => read(AnalyticsSurveysDataV, `analytics/v2/surveys`)(params);
  public static download = async (params: SurveysParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/surveys/export/${queryString}`);
  };
  public static readDetail = (id: string, params: SurveysDetailParams) =>
    read(AnalyticsSurveysDetailDataV, `analytics/v2/surveys/${id}`)(params);

  public static readUsers = (id: string, params: SurveysUsersParams) =>
    read(AnalyticsSurveysUsersDataV, `analytics/v2/surveys/${id}/users`)(params);

  public static downloadDetail = async (id: string, params: SurveysParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/surveys/${id}/export/${queryString}`);
  };

  public static readDetailResponses = (id: string, params: SurveysDetailResponsesParams) =>
    read(SurveyDetailsWithResponsesV, `analytics/v2/surveys/${id}/responses`)(params);

  public static downloadDetailResponses = async (id: string, params: SurveysParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/surveys/${id}/responses/export/${queryString}`);
  };
}
