import * as S from '../styled';

import type {
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextShortBlockType,
} from '@commandbar/internal/middleware/types';
import React from 'react';
import { Settings03, Trash04 } from '@commandbar/design-system/icons/react';
import { Input, Dropdown } from '@commandbar/design-system/components/antd';
import { SurveyValidation } from './SurveyValidation';

interface IShortInput {
  block: INudgeStepContentSurveyTextShortBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

export const SurveyTextInputShortBlockForm = ({ block, onBlockChange, onBlockDelete }: IShortInput) => {
  const [isDropdownVisible, setisDropdownVisible] = React.useState(false);

  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.SectionContainer>
          <S.TitleRow>
            <S.StyledLabel htmlFor="prompt">Short Answer</S.StyledLabel>
            <div style={{ display: 'flex', gap: '4px' }}>
              <S.MenuIconContainer
                onClick={() => {
                  onBlockDelete(block);
                }}
              >
                <Trash04 opacity={0.5} />
              </S.MenuIconContainer>
              <Dropdown
                visible={isDropdownVisible}
                onVisibleChange={(visible) => setisDropdownVisible(visible)}
                placement="topRight"
                overlay={
                  <S.OverlayContainer width="210px">
                    <SurveyValidation
                      initialValue={{
                        value: block?.meta?.validation?.required?.value ?? false,
                        message: block?.meta?.validation?.required?.value
                          ? block?.meta?.validation?.required.message
                          : 'Add a response to continue',
                      }}
                      onChange={(validation) => {
                        onBlockChange({
                          ...block,
                          meta: {
                            ...block.meta,
                            validation,
                          },
                        });
                      }}
                      wrapOption={(option) => <S.DropDownOption>{option}</S.DropDownOption>}
                    />
                  </S.OverlayContainer>
                }
              >
                <S.MenuIconContainer>
                  <Settings03 height={16} opacity="0.5" />
                </S.MenuIconContainer>
              </Dropdown>
            </div>
          </S.TitleRow>
          <Input
            name="prompt"
            value={block.meta.prompt}
            onChange={(e) => {
              onBlockChange({
                ...block,
                meta: {
                  ...block.meta,
                  prompt: e.target.value,
                },
              });
            }}
            placeholder="Your placeholder text..."
          />
        </S.SectionContainer>
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
