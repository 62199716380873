import { CmdColumnDef, CmdSortableColumn, CmdTypography } from '@commandbar/design-system/cmd';
import { PencilLine } from '@commandbar/design-system/icons/react';
import { TableData } from '@commandbar/internal/middleware/analytics/common';
import ActionDropdown from '../../components/ActionDropdown';
import Table from '../../components/Table';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const PageClicksTable = ({
  pageClicksData,
  isLoading,
  fetchSpotlightData,
  error,
}: {
  pageClicksData?: TableData;
  isLoading: boolean;
  error: boolean;
  fetchSpotlightData: () => void;
}) => {
  const { user } = useAuth();

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'commandtext',
      header: 'Page',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'occurrence_count',
      header: ({ column }) => <CmdSortableColumn column={column} title="Clicks" />,

      enableSorting: true,
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => {
        const options =
          !!Number(row.original.command) && Number(row.original?.archived) !== 1
            ? [
                {
                  label: 'Edit',
                  icon: <PencilLine />,
                  url: `/editor/pages/${row.original.command}`,
                },
              ]
            : [];
        return <ActionDropdown options={options} />;
      },
    });
  }

  return (
    <Table
      data={pageClicksData?.rows || []}
      columns={columns}
      isLoading={isLoading}
      empty={{
        error,
        refetchData: fetchSpotlightData,
      }}
      toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Pages</CmdTypography.Body>}
    />
  );
};

export default PageClicksTable;
