import { useState, useEffect, RefObject, DependencyList } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

const useResizeObserver = <T extends HTMLElement>(ref: RefObject<T>, deps?: DependencyList): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

  useEffect(() => {
    const target = ref.current;
    if (!target) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions((dim) => {
          // Don't trigger a re-render unless one of the dimensions has changed
          if (dim.width !== entry.contentRect.width || dim.height !== entry.contentRect.height) {
            return {
              width: entry.contentRect.width,
              height: entry.contentRect.height,
            };
          } else {
            return dim;
          }
        });
      });
    });

    resizeObserver.observe(target);

    return () => {
      if (target) {
        resizeObserver.unobserve(target);
      }
    };
  }, deps);

  return dimensions;
};

export default useResizeObserver;
