import React from 'react';

import { Skeleton, Page } from '@commandbar/design-system/components/antd';

import { IBillingProfile, IUsage } from '@cb/types/entities/internal/billing';

import * as axiosInstance from '@commandbar/internal/middleware/network';
import { ISlackType, requestToSlack } from '@commandbar/internal/client/slack';
import { CmdButton, CmdCard, CmdDivider } from '@commandbar/design-system/cmd';
import { reportErrorToUser } from 'editor/src/editor/utils/ErrorReporting';
import { useAppContext } from 'editor/src/AppStateContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { Billing as BillingType } from '@commandbar/internal/middleware/billing';
import { pricing_plan_defaults } from './pricing-plans';

/*******************************************************************************************/

// Individual row component for plan details
const PlanDetailRow = ({ label, value }: { label: string; value: string | number }) => {
  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 500,
    width: '100%',
  };

  return (
    <div style={rowStyle}>
      <span
        style={{
          color: '#797C85',
          flex: 1,
        }}
      >
        {label}
      </span>
      <span style={{ flex: 1, textAlign: 'left' }}>{value}</span>
    </div>
  );
};

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function Billing() {
  const [billingProfile, setBillingProfile] = React.useState<IBillingProfile | undefined>(undefined);
  const [usage, setUsage] = React.useState<IUsage | null>(null);
  const pricingPlanDefaults = pricing_plan_defaults.find((plan) => plan.uid === billingProfile?.tier);

  const { organization } = useAppContext();
  const { user } = useAuth();
  const planDetails: Record<string, string | number> = {
    'Monthly Active Users (MAUs)': usage?.end_user_limit || 'Unlimited',
    'Editor Seats': usage?.user_seat_limit || 'Unlimited',
    'AI Responses': usage?.helphub_message_limit || 'Unlimited',
    'Tours, Announcements, and Surveys': usage?.nudges_live_limit || 'Unlimited',
    Checklists: usage?.questlists_live_limit || 'Unlimited',
    'Pages, Actions and Videos': usage?.commands_live_limit || 'Unlimited',
    Sources: pricingPlanDefaults?.sources || 'Custom',
    Docs: usage?.helphub_docs_limit || 'Unlimited',
    Themes: usage?.skins_limit || 'Unlimited',
    'Help Center Sync': capitalize(usage?.help_center_sync || 'Unlimited'),
    'Remove "Powered By" Branding': usage?.branding === 'whitelabeled' ? 'Yes' : 'No',
  };

  React.useEffect(() => {
    async function loadUsage() {
      try {
        await BillingType.readUsage().then((resp) => setUsage(resp));
      } catch (err) {}
    }
    loadUsage();
  }, []);

  React.useEffect(() => {
    async function loadBillingProfile() {
      try {
        await BillingType.readProfile().then((resp) => setBillingProfile(resp));
      } catch (err) {
        setBillingProfile(undefined);
      }
    }
    loadBillingProfile();
  }, []);

  const reportToSlack = (message: string) => {
    const payload = {
      type: 'notifs_self_serve' as ISlackType,
      email: user?.email,
      message: `${organization?.name}: ${message}`,
    };
    requestToSlack(payload);
  };

  const goToCheckoutForExistingStripeCustomer = async () => {
    if (!billingProfile) {
      return;
    }
    reportToSlack('Visit to Manage Billing');

    try {
      const response = await axiosInstance.get('billing/checkout/');
      const data = response.data;

      if (!!data?.url) {
        window.open(data.url, '_blank');
      }
    } catch (err) {
      reportToSlack(`Visit to Manage Billing Error: ${(err as any)?.message}`);
      reportErrorToUser(err);
    }
  };

  if (!billingProfile || !usage) {
    return (
      <div style={{ width: '100%', padding: '80px 15%' }}>
        <Skeleton />
      </div>
    );
  }

  const contactSales = () => {
    reportToSlack('Clicked Contact Sales on Billing Page');
    window?.open('https://forms.default.com/7658', '_blank');
  };

  return (
    <Page
      whiteBg={false}
      headerChildren={
        <CmdButton disabled={!billingProfile.customer_id} onClick={goToCheckoutForExistingStripeCustomer}>
          Manage Billing ↗
        </CmdButton>
      }
      title="Billing"
      description={`See plan details for ${organization.name}`}
      disableVerticalContentPadding
    >
      <CmdCard>
        <h3 style={{ fontWeight: 600, fontSize: '14px' }}>{pricingPlanDefaults?.title || 'Custom'} Plan</h3>
        {Object.entries(planDetails).map(([key, value]) => (
          <PlanDetailRow key={key} label={key} value={value} />
        ))}
        <CmdDivider spacing={'xl'} />
        {/* //TODO: enable this once we know where to get this data */}
        {/* {!billingProfile.on_free_trial && (
          <React.Fragment>
            <h3 style={{ fontWeight: 600, fontSize: '14px' }}>Billing</h3>
            <PlanDetailRow label="Billing period" value={billingInfo.billingPeriod} />
            <PlanDetailRow label="Next Billing Date" value={billingInfo.nextBillingDate} />
            <CmdDivider spacing={'xl'} />
          </React.Fragment>
        )} */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <h3 style={{ fontWeight: 600, fontSize: '14px' }}>Have any questions</h3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
            }}
          >
            <CmdButton onClick={() => window.open('https://www.commandbar.com/pricing/', '_blank')}>
              See all Plans
            </CmdButton>

            <CmdButton onClick={contactSales} variant={'primary'}>
              Contact Sales
            </CmdButton>
          </div>
        </div>
      </CmdCard>
    </Page>
  );
}
