import { AutoComplete } from '@commandbar/design-system/components/antd';
import { IChecklist, INudgeType } from '@commandbar/internal/middleware/types';
import _ from 'lodash';
import React, { type ComponentProps } from 'react';

import { Description, TextContainer } from './ActionTypeSelector/components/styled';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';

type Props = {
  onChange: (eventname: string) => void;
  initialEventName: string;
  checklists: IChecklist[];
  nudges: INudgeType[];
  size?: ComponentProps<typeof AutoComplete>['size'];
};

export const EventAutoComplete = ({ onChange, initialEventName, checklists, nudges, size = 'small' }: Props) => {
  const { trackedEvents } = useWindowInfo();
  const allRreferencedEvents: string[] = [];

  for (const checklist of checklists) {
    if (checklist.trigger.type === 'on_event' && !checklist.trigger.meta.event.startsWith('commandbar-')) {
      allRreferencedEvents.push(checklist.trigger.meta.event);
    }

    for (const item of checklist.items) {
      if (item.goal.type === 'event_tracked' && !item.goal.event.startsWith('commandbar-')) {
        allRreferencedEvents.push(item.goal.event);
      }
    }
  }

  for (const nudge of nudges) {
    if (nudge.trigger.type === 'on_event' && !nudge.trigger.meta.event.startsWith('commandbar-')) {
      allRreferencedEvents.push(nudge.trigger.meta.event);
    }
  }

  const referencedEvents = _.uniq(allRreferencedEvents);

  return (
    <AutoComplete
      size={size}
      style={{ width: '100%' }}
      onChange={onChange}
      options={[
        {
          label: 'Custom events tracked this session',
          options:
            trackedEvents.length > 0
              ? trackedEvents.map((c) => ({ key: `tracked-${c}`, label: c, value: c }))
              : [
                  {
                    key: 'tracked-no-events',
                    label: (
                      <span
                        style={{
                          opacity: 0.6,
                          marginRight: 5,
                          fontSize: 10,
                        }}
                      >
                        No events tracked yet
                      </span>
                    ),
                    value: '',
                  },
                ],
        },
        {
          label: 'Custom events already referenced by nudges or questlists',
          options:
            referencedEvents.length > 0
              ? referencedEvents.map((c) => ({ key: `referenced-${c}`, label: c, value: c }))
              : [
                  {
                    key: 'refernced-no-event-triggers',
                    label: (
                      <span
                        style={{
                          opacity: 0.6,
                          marginRight: 5,
                          fontSize: 10,
                        }}
                      >
                        No event triggers used yet
                      </span>
                    ),
                    value: '',
                  },
                ],
        },
        {
          label: 'Built-in events',
          options: [
            {
              value: 'commandbar-opened',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Bar opened</div>
                  <Description>
                    Name: <code>commandbar-opened</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-closed',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Bar closed</div>
                  <Description>
                    Name: <code>commandbar-closed</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-abandoned_search',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Deadend in the bar</div>
                  <Description>
                    Name: <code>commandbar-abandoned_search</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-command_suggestion',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a user suggests a command</div>
                  <Description>
                    Name: <code>commandbar-command_suggestion</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-command_execution',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>On command execution</div>
                  <Description>
                    Name: <code>commandbar-command_execution</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-no_results_for_query',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a search in the bar returns no results</div>
                  <Description>
                    Name: <code>commandbar-no_results_for_query</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-nudge_shown',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Nudge shown</div>
                  <Description>
                    Name: <code>commandbar-nudge_shown</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-nudge_clicked',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Nudge clicked</div>
                  <Description>
                    Name: <code>commandbar-nudge_clicked</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-preview_shown',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>Preview shown</div>
                  <Description>
                    Name: <code>commandbar-preview_shown</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-shortcut_edited',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>When a user assigns or edits a shortcut</div>
                  <Description>
                    Name: <code>commandbar-shortcut_edited</code>
                  </Description>
                </TextContainer>
              ),
            },
            {
              value: 'commandbar-client_error',
              label: (
                <TextContainer>
                  <div style={{ display: 'inline-block' }}>An error encountered by CommandBar</div>
                  <Description>
                    Name: <code>commandbar-client_error</code>
                  </Description>
                </TextContainer>
              ),
            },
          ],
        },
      ]}
      value={initialEventName}
      filterOption
    />
  );
};
