import { CmdDivider } from '@commandbar/design-system/cmd';
import {
  Database01,
  BookOpen01,
  Users01,
  FaceIdSquare,
  CreditCard02,
  Settings03,
  Folder,
  GitBranch01,
  CheckSquareBroken,
  Users03,
  Package,
  Announcement02,
  MessageSmileSquare,
  Flag03,
  Link04,
  ZapFast,
  SearchSm,
  PaintPour,
  Pin01,
  HelpCircle,
  BarChart12,
  Dataflow04,
} from '@commandbar/design-system/icons/react';
import { Flags } from '@commandbar/internal/middleware/flags';
import {
  ANNOUNCEMENTS_ROUTE,
  CHECKLIST_ROUTE,
  COPILOT_PARENT_ROUTE,
  HELPHUB_ROUTE,
  PRODUCT_TOURS_ROUTE,
  SPOTLIGHT_ROUTE,
  SURVEYS_ROUTE,
} from '@commandbar/internal/proxy-editor/editor_routes';
import { AnalyticsPage, getAnalyticsDashboardRoute } from '../Home';
import { IUserType } from '@commandbar/internal/middleware/types';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

export interface MenuItemLink {
  route: string;
  icon?: React.JSXElementConstructor<any>;
  title: string;
  type: 'link';
  meta?: {
    onClick?: () => void;
    className?: string;
  };
}

export interface MenuItemNode {
  type: 'node';
  node: React.ReactNode;
}

export interface MenuItemGroup {
  route?: string;
  icon?: React.JSXElementConstructor<any>;
  title: string;
  children: MenuItemLink[];
  type: 'group';
  meta?: {
    onClick?: () => void;
    className?: string;
  };
}

export const analyticsItems = (): MenuItem[] => [
  {
    title: 'Analytics',
    type: 'group',
    icon: BarChart12,
    route: '',
    children: Object.values(AnalyticsPage).map((title) => ({
      title,
      route: getAnalyticsDashboardRoute(title),
      type: 'link' as const,
    })),
  },
];
export type MenuItem = MenuItemLink | MenuItemGroup | MenuItemNode;

export const getMenuItems = (flags: Flags, user: IUserType | null): MenuItem[] => [
  ...(hasRequiredRole(user, 'contributor')
    ? ([
        {
          route: '/home',
          icon: Pin01,
          title: 'Get Started',
          type: 'link',
        },
      ] as MenuItem[])
    : []),
  {
    title: 'Nudge',
    type: 'group',
    children: [
      {
        route: '/editor' + PRODUCT_TOURS_ROUTE,
        icon: Flag03,
        title: 'Tours',
        type: 'link',
      },
      {
        route: '/editor' + ANNOUNCEMENTS_ROUTE,
        icon: Announcement02,
        title: 'Announcements',
        type: 'link',
      },
      {
        route: '/editor' + SURVEYS_ROUTE,
        icon: MessageSmileSquare,
        title: 'Surveys',
        type: 'link',
      },
      {
        route: '/editor' + CHECKLIST_ROUTE,
        icon: CheckSquareBroken,
        title: 'Checklists',
        type: 'link',
      },
    ],
  },
  {
    node: <CmdDivider spacing="xs" />,
    type: 'node',
  },
  {
    title: 'Assist',
    type: 'group',
    children: [
      {
        route: '/editor' + COPILOT_PARENT_ROUTE,
        icon: FaceIdSquare,
        title: 'Copilot',
        type: 'link',
      },
      {
        route: '/editor' + HELPHUB_ROUTE,
        icon: BookOpen01,
        title: 'HelpHub',
        type: 'link',
      },
      {
        route: '/editor' + SPOTLIGHT_ROUTE,
        icon: SearchSm,
        title: 'Spotlight',
        type: 'link',
      },
    ],
  },
  {
    node: <CmdDivider spacing="xs" />,
    type: 'node',
  },
  {
    route: '/content',
    icon: Folder,
    title: 'Content',
    type: 'link',
  },
  {
    route: '/editor/pages',
    icon: Link04,
    title: 'Pages',
    type: 'link',
  },
  {
    route: '/editor/actions',
    icon: ZapFast,
    title: 'Actions',
    type: 'link',
  },
  {
    node: <CmdDivider spacing="xs" />,
    type: 'node',
  },
  ...(flags['release-analytics-v2'] ? analyticsItems() : []),

  ...(hasRequiredRole(user, 'editor')
    ? ([
        {
          route: '/audiences',
          icon: Users03,
          title: 'Audiences',
          type: 'link',
        },
      ] as MenuItem[])
    : []),
  // hide for now until interaction history is ready
  // {
  //   route: '/users',
  //   icon: UserSquare,
  //   title: 'Users',
  //   type: 'link',
  // },
  ...(flags['release-themes-v2']
    ? ([
        {
          route: '/editor/themes',
          icon: PaintPour,
          title: 'Themes',
          type: 'link',
        },
      ] as MenuItem[])
    : ([
        {
          route: '/editor/design',
          icon: PaintPour,
          title: 'Themes',
          type: 'link',
        },
      ] as MenuItem[])),

  ...(hasRequiredRole(user, 'editor')
    ? ([
        {
          route: '/integrations',
          icon: Database01,
          title: 'Integrations',
          type: 'link',
        },
        {
          route: '/editor/releases',
          icon: Package,
          title: 'Releases',
          type: 'link',
        },
      ] as MenuItem[])
    : []),
];

export const footerItems: MenuItem[] = [
  {
    node: <CmdDivider spacing="xs" />,
    type: 'node',
  },
  {
    route: '',
    icon: HelpCircle,
    title: 'Support',
    meta: {
      onClick: () => window.CommandBar.toggleHelpHub(),
    },
    type: 'link',
  },
];

export const getSettingsSubItems = (flags: Flags, _user: IUserType | null): MenuItemLink[] => [
  {
    route: '/team',
    icon: Users01,
    title: 'Team',
    type: 'link',
  },
  {
    route: '/billing',
    icon: CreditCard02,
    title: 'Billing',
    type: 'link',
  },
  {
    route: '/configure',
    icon: Settings03,
    title: 'Configure',
    type: 'link',
  },
  {
    route: '/identity-verification',
    icon: FaceIdSquare,
    title: 'Identity Verification',
    type: 'link',
  },
  ...((flags?.['release-user-property-management']
    ? [
        {
          route: '/properties',
          icon: Dataflow04,
          title: 'User Properties',
          type: 'link',
        },
      ]
    : []) as MenuItemLink[]),
  {
    route: '/history',
    icon: GitBranch01,
    title: 'History',
    type: 'link',
  },
];
