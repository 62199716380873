import * as t from 'io-ts';

import { decodeThrowing, listObject } from './generics';

export const TagV = t.type({
  name: t.string,
});

const defaults = {};

export type ITag = t.TypeOf<typeof TagV>;

export class Tag {
  public static decode = (data: any) => decodeThrowing(TagV, { ...defaults, ...data });

  public static list = listObject(TagV, 'tags');
}
