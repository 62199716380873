/** @jsx jsx */
import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { TextInputStep } from '.';
import { StepType, GenericStep } from './Step';
import { initTextInputStep } from './TextInputStep';

export type LongTextInputStep = Omit<TextInputStep, 'type'> & GenericStep<StepType.LongTextInput>;

export const initLongTextInputStep = (argument: IStepArgumentType): LongTextInputStep => {
  const s = initTextInputStep(argument) as unknown as LongTextInputStep;
  s.type = StepType.LongTextInput;
  return s;
};
