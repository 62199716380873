import React, { useState } from 'react';

import { Alert } from '@commandbar/design-system/components/antd';
import { IEditorCommandTypeLite, INudgeType, IRuleExpression } from '@commandbar/internal/middleware/types';

import Sender from 'editor/src/management/Sender';
import { useAppContext } from 'editor/src/AppStateContext';
import { NudgeType } from '../nudges/NudgeList';

const AnalyticRuleAlert = ({ type, data }: { type: 'page' | 'action' | NudgeType; data: any }) => {
  const { organization, organizationSettings, isStudio } = useAppContext();

  const [hasAnalyticsRules, setHasAnalyticsRules] = useState(false);
  const [isAnalyticsDisabled, setIsAnalyticsDisabled] = useState(false);

  const end_user_analytics_keys = [
    'executions',
    'shortcuts',
    'last_seen',
    'first_seen',
    'sessions',
    'opens',
    'deadends',
  ];

  const detectAnalyticsRules = (expr: IRuleExpression): void => {
    switch (expr.type) {
      case 'AND':
      case 'OR': {
        expr.exprs.map((e) => detectAnalyticsRules(e));
        return;
      }
      case 'LITERAL': {
        return;
      }
      case 'CONDITION': {
        if (end_user_analytics_keys.some((e) => e === expr.condition.type)) {
          setHasAnalyticsRules(true);
        }
        return;
      }
    }
  };

  const command = (item: IEditorCommandTypeLite) => {
    const availability_expression = item.availability_expression;
    const recommend_expression = item.recommend_expression;
    detectAnalyticsRules(availability_expression);
    detectAnalyticsRules(recommend_expression);
    return true;
  };

  const nudge = (item: INudgeType) => {
    const availability_expression = item.show_expression;
    detectAnalyticsRules(availability_expression);

    return true;
  };

  React.useEffect(() => {
    setHasAnalyticsRules(false);
    data.every((item: any) => {
      if (type === 'action' || type === 'page') {
        return command(item);
      } else {
        return nudge(item);
      }
    });
  }, [data]);

  Sender.isUserVerified().then((isEndUserVerified) => {
    setIsAnalyticsDisabled(
      !!organizationSettings?.silent_mode ||
        (!isStudio && !isEndUserVerified && !!organization?.force_end_user_identity_verification),
    );
  });

  const alertMessage = !!organizationSettings?.silent_mode
    ? `Some of your ${type.replace(
        /_/g,
        ' ',
      )}s are using conditions based on end-user analytics data, but this data is not available when using silent mode. Please review the Who targeting of your ${type.replace(
        /_/g,
        ' ',
      )}s for specific details.`
    : `Some of your ${type.replace(
        /_/g,
        ' ',
      )}s are using conditions that are based on end-user analytics data, but will not trigger due to a mis-configuration with identity verification. Please review your identity verification configuration or reach out to support for help.`;

  if (hasAnalyticsRules && isAnalyticsDisabled) {
    return <Alert message={alertMessage} type="warning" showIcon closable style={{ marginBottom: 10 }} />;
  }

  return null;
};

export default AnalyticRuleAlert;
