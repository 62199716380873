import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px var(--layout-padding);
  gap: var(--layout-gap);
`;

const StyledContinuationQuestionsContainer = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <Container {...props}>{children}</Container>;
};

export default StyledContinuationQuestionsContainer;
