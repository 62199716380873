import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../util';
import { breadcrumbItemStyles } from './style';

export type ItemType = {
  children?: string | React.ReactNode;
  href?: string;
  to?: string;
  onClick?: () => void;
  variant?: 'default' | 'active';
  label?: React.ReactNode;
  className?: string;
  icon?: JSX.Element;
};

export const BreadCrumbItem = ({ children, href, to, variant = 'default', ...otherProps }: ItemType) => {
  if (to) {
    return (
      <Link to={to} className={cn(breadcrumbItemStyles({ variant }))} {...otherProps}>
        {children}
      </Link>
    );
  } else if (href) {
    return (
      <a href={href} className={cn(breadcrumbItemStyles({ variant }))} {...otherProps}>
        {children}
      </a>
    );
  } else {
    return (
      <span className={cn(breadcrumbItemStyles({ variant }))} {...otherProps}>
        {children}
      </span>
    );
  }
};
