import React from 'react';
import Auth from '../client/authentication';
import * as axiosInstance from '../middleware/network';
import { IUserType } from '../middleware/types';
import * as Sentry from '@sentry/react';
import { isInsideIFrame } from '../util/iframe';
import { useAuthorizationCode } from './useAuthorizationCode';
import { useHistory } from 'react-router';

type AuthContextValue = {
  user: IUserType | null;
  /** @deprecated Avoid setting the user directly if possible, use login/logout instead */
  setUser: React.Dispatch<React.SetStateAction<IUserType | null>>;

  isLoading: boolean;

  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;

  checkAuth: () => Promise<void>;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;

  register: (value: Record<string, string>) => Promise<void>;
  acceptInvite: (values: Record<string, string>) => Promise<{ is_active: boolean } | undefined>;
};

const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);

type AuthProviderProps = {
  isEditor?: boolean;
  loadingElement?: React.ReactNode;

  onUserChange?: (user: IUserType | null) => void;
  onLogout?: () => void;

  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  isEditor,
  loadingElement,
  onUserChange,
  onLogout,
  children,
}) => {
  const history = useHistory();

  const [user, setUser] = React.useState<IUserType | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const { code, state, finalRedirect, error: oauthError } = useAuthorizationCode();

  const inIFrame = React.useMemo(isInsideIFrame, []);

  React.useEffect(() => {
    if (!code) {
      return;
    }

    // Check whether we're in the editor page on a new tab during iFrame editor sign-in
    if (isEditor && !inIFrame) {
      window.opener.postMessage({
        type: 'CB_EDITOR_AUTH',
        payload: {
          code,
          state,
        },
      });

      setTimeout(() => {
        window.close();
      }, 500);
    } else {
      // If we're not, it means we're on the main site so we can just authorize directly
      Auth.authorize(code, state).then((result) => {
        if (result.success) {
          checkAuth();
        } else {
          setError(result.error);
        }
      });

      if (finalRedirect) {
        setTimeout(() => {
          history.replace(finalRedirect);
        }, 500);
      } else if (oauthError) {
        Sentry.captureMessage(`Error authenticating editor: ${oauthError}`);

        setTimeout(() => {
          if (!window.location.href.includes('localhost')) {
            alert("Something went wrong... We'll redirect you back to commandbar.com");
            window.open(`${process.env.REACT_APP_DASHBOARD_URL}/login?error=ErrorAuthenticatingEditor`, '_self');
          }
        }, 500);
      }
    }
  }, [isEditor, code]);

  React.useEffect(() => {
    if (isEditor && inIFrame) {
      const handleAuthMessage = async (event: MessageEvent) => {
        if (event?.data?.type !== 'CB_EDITOR_AUTH') {
          return;
        }

        // Verify event origin is the same as the current window
        if (event.origin !== window.location.origin) {
          Sentry.captureMessage(`Received message from unexpected origin: ${event.origin}`);
          return;
        }

        if (event.data.type === 'CB_EDITOR_AUTH') {
          const { code, state } = event.data.payload;

          await Auth.authorize(code, state ?? undefined);

          await checkAuth();
        }
      };

      window.addEventListener('message', handleAuthMessage);

      return () => {
        window.removeEventListener('message', handleAuthMessage);
      };
    }
  }, [isEditor]);

  React.useEffect(() => {
    checkAuth()
      .then(() => {
        // noop
      })
      .finally(() => {
        // 1s delay to avoid flashing
        setTimeout(() => setIsLoading(false), 1000);
      });
  }, []);

  React.useEffect(() => {
    if (onUserChange) {
      onUserChange(user);
    }
  }, [user]);

  const login = async (username: string, password: string): Promise<boolean> => {
    try {
      const res = await Auth.basic(username, password);

      if (!res.success) {
        setError(res.error ?? 'Invalid Email or Password');
      }

      return res.success;
    } catch (err: any) {
      Sentry.captureException(err);
      setError(err.toString());

      return false;
    } finally {
      await checkAuth();
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.post('/auth/logout/', undefined, {
        credentials: 'include',
      });
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setUser(null);

      if (onLogout) {
        onLogout();
      }
    }
  };

  const checkAuth = async () => {
    try {
      const res = await Auth.user();

      if (!res.success) {
        if (res.error !== 'Invalid.') {
          Sentry.captureMessage(`Error checking auth: ${res.error}`);
        }
        Sentry.setUser(null);

        setUser(null);
      } else {
        Sentry.setUser({ user: res.user });
        setUser(res.user);
      }
    } catch (err) {
      Sentry.captureException(err);
      setUser(null);
    }
  };

  const register = async (value: Record<string, string>): Promise<void> => {
    try {
      await Auth.register(value);
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  const acceptInvite = async (values: Record<string, string>): Promise<{ is_active: boolean } | undefined> => {
    try {
      return await Auth.acceptInvite(values);
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  };

  const contextValue = React.useMemo<AuthContextValue>(
    () => ({ user, setUser, isLoading, error, setError, checkAuth, login, logout, register, acceptInvite }),
    [user, setUser, isLoading, error, setError, checkAuth, login, logout, register, acceptInvite],
  );

  // XXX: This loading screen should only be shown on the initial load
  return <AuthContext.Provider value={contextValue}>{isLoading ? loadingElement : children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
