import { CmdDropdown } from '@commandbar/design-system/cmd';
import { DotsVertical } from '@commandbar/design-system/icons/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

type Actions = {
  url?: string;
  icon?: React.ReactElement;
  label: string;
  onClick?: () => void;
};

const ActionDropdown = ({ options }: { options: Actions[] }) => {
  const history = useHistory();
  if (options.length < 1) {
    return null;
  }
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger style={{ display: 'flex' }}>
        <DotsVertical />
      </CmdDropdown.Trigger>
      <CmdDropdown.Content>
        {options.map((option) => (
          <CmdDropdown.Item
            key={option.label}
            onClick={
              !!option.onClick ? option.onClick : option?.url ? () => history.replace(option?.url || '') : () => null
            }
          >
            <>
              {option.icon} {option.label}
            </>
          </CmdDropdown.Item>
        ))}
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

export default ActionDropdown;
