import { IStepArgumentType } from '@commandbar/internal/middleware/types';
import { isValid } from './option-utils/OptionValidate';
import { runBooleanExpression, runBooleanConditions } from 'shared/services/targeting/helpers';
import { CommandOption, UnfurledCommandOption } from './option-utils';
import { CBStore } from 'shared/store/global-store';
import { getCategoryField } from '../selectors';

const hex_regex = /^[0-9a-fA-F]{3,8}$/;

export const getSortedArgs = (option: CommandOption | UnfurledCommandOption) => {
  return Object.keys(option.command.arguments)
    .map((argName: string) => {
      return {
        ...option.command.arguments[argName],
        userDefinedName: option.command.arguments[argName].label || argName,
        userDefinedValue: argName,
      };
    })
    .sort((a: IStepArgumentType, b: IStepArgumentType) => {
      return a.order_key - b.order_key;
    });
};

export const isCommandOptionValid = (_: CBStore, o: CommandOption): { isValid: boolean; isValidReason: string } => {
  return isValid(o, _);
};

const isHexColor = (color: string): boolean => hex_regex.test(color);
const getdeprecatedColor = (icon: string | null): string | null =>
  icon?.includes('#') && !!isHexColor(icon.split('#')[1]) ? icon?.split('#')[1] : null;

// default to icon_color, but check for deprecated color in icon string
export const getCommandIcon = (_: CBStore, o: CommandOption): { icon: string | null; icon_color: string | null } => {
  const icon = o.command.icon;
  const icon_color = o.command.icon_color ? o.command.icon_color : getdeprecatedColor(icon);

  return { icon, icon_color };
};

export const getCategoryIcon = (_: CBStore, o: CommandOption): { icon: string | null; icon_color: string | null } => {
  if (o.command.category) {
    const icon = getCategoryField(_, o.command.category, 'icon');
    const icon_color = getCategoryField(_, o.command.category, 'icon_color');
    return { icon, icon_color: icon_color ? icon_color : getdeprecatedColor(icon) };
  }
  return { icon: null, icon_color: null };
};

export const getCategoryImage = (
  _: CBStore,
  o: CommandOption,
): { image: string | null; image_color: string | null } => {
  if (o.command.category) {
    const image = getCategoryField(_, o.command.category, 'image');
    const image_color = getCategoryField(_, o.command.category, 'image_color');
    return { image, image_color: image_color ? image_color : getdeprecatedColor(image) };
  }
  return { image: null, image_color: null };
};

export const getCommandImage = (_: CBStore, o: CommandOption): { image: string | null; image_color: string | null } => {
  const image = o.command.image;
  const image_color = o.command.image_color;
  return { image, image_color: image_color ? image_color : getdeprecatedColor(image) };
};

export const isCommandOptionRecommended = (_: CBStore, o: CommandOption): boolean => {
  if (_.organization?.recommendations_type === 'None') return false;

  if (o.command.recommend_expression) {
    if (o.command.always_recommend) return true;

    // NEW CODE
    const { passed } = runBooleanExpression(o.command.recommend_expression, _, 'Recommendations:');
    return passed;
  } else {
    // DEPRECATED -- remove when all commands have availability_expression
    if (!o.command.recommend_rules || o.command.recommend_rules.length === 0) return false;
    const { passed } = runBooleanConditions(o.command.recommend_rules, _, 'Recommendations:');
    return passed;
  }
};
