import React from 'react';

import {
  ClickRecorderContainer,
  Input,
  InputContainer,
  MediaBlockStyledInput,
  StyledLabel,
  Tooltip,
} from '@commandbar/design-system/components/antd';
import { AlertTriangle } from '@commandbar/design-system/icons/react';
import type { TriggerableEntity } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { transformIfJSPath } from '@commandbar/internal/util/dom';
import { useAppContext } from 'editor/src/AppStateContext';
import { CommandSelect } from '../../../CommandSelect';
import { SELECTOR_WARNING_TEXT, isSelectorFragile } from '../../../commands/CommmandDetail/ActionDetailPanel';
import { EventAutoComplete } from '../../../commands/CommmandDetail/EventAutoComplete';
import ConditionGroupEditor from '../../../conditions/ConditionGroupEditor';
import { ClickRecorder } from '../../ClickRecorder';
import { StyledSelect } from '../../styled';
import { StyledEventAutoComplete } from '../styled';

import { ReactComponent as CaretDown } from '../../../../img/caret_down.svg';

type AfterTimeTrigger = TriggerableEntity['trigger'] & { type: 'after_time' };

interface AfterTimeOptionsProps {
  trigger: AfterTimeTrigger;
  onValueChange: (value: AfterTimeTrigger['meta']['value']) => void;
  onUnitChange: (unit: AfterTimeTrigger['meta']['unit']) => void;
}

const AfterTimeOptions = ({ trigger, onUnitChange, onValueChange }: AfterTimeOptionsProps) => (
  <InputContainer>
    <StyledLabel>Time</StyledLabel>
    <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
      <Input
        type="number"
        min={1}
        style={{ border: '1px solid #A2A2A9' }}
        placeholder="Time value"
        onKeyDown={(e) => e.stopPropagation()}
        value={trigger.meta.value}
        onChange={(e) => {
          onValueChange(Number(e.target.value));
        }}
      />
      <StyledSelect
        value={trigger.meta.unit}
        style={{ width: '100%' }}
        onChange={(e) => {
          onUnitChange(e);
        }}
        suffixIcon={<CaretDown />}
        placeholder="Select a unit..."
        optionLabelProp="label"
        options={[
          {
            label: 'Seconds',
            value: 'second',
          },
          {
            label: 'Minutes',
            value: 'minute',
          },
        ]}
      />
    </div>
  </InputContainer>
);

type ScheduledTrigger = TriggerableEntity['trigger'] & { type: 'scheduled' };

interface ScheduledOptionsProps {
  trigger: ScheduledTrigger;
  onValueChange: (value: ScheduledTrigger['meta']['value']) => void;
  onIntervalChange: (unit: ScheduledTrigger['meta']['interval']) => void;
}

const ScheduledOptions = ({ trigger, onIntervalChange, onValueChange }: ScheduledOptionsProps) => (
  <InputContainer>
    <StyledLabel>Every</StyledLabel>
    <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
      <Input
        type="number"
        min={1}
        style={{ border: '1px solid #A2A2A9' }}
        placeholder="Value"
        onKeyDown={(e) => e.stopPropagation()}
        value={trigger.meta.value}
        onChange={(e) => {
          onValueChange(Number(e.target.value));
        }}
      />
      <StyledSelect
        value={trigger.meta.interval}
        style={{ width: '100%' }}
        onChange={(e) => {
          onIntervalChange(e);
        }}
        suffixIcon={<CaretDown />}
        placeholder="Select a unit..."
        optionLabelProp="label"
        options={[
          {
            label: 'Days',
            value: 'day',
          },
          {
            label: 'Weeks',
            value: 'week',
          },
          {
            label: 'Months',
            value: 'month',
          },
        ]}
      />
    </div>
  </InputContainer>
);

type ActionTrigger = TriggerableEntity['trigger'] & { type: 'on_command_execution' };

interface ActionOptionsProps {
  trigger: ActionTrigger;
  onCommandChange: (value: ActionTrigger['meta']['command']) => void;
}

const ActionOptions = ({ trigger, onCommandChange }: ActionOptionsProps) => {
  const { commands } = useAppContext();

  return (
    <InputContainer>
      <StyledLabel>Action</StyledLabel>

      <CommandSelect
        value={
          commands.find((c) => c.id.toString() === trigger.meta.command)
            ? Number.parseFloat(trigger.meta.command)
            : undefined
        }
        type={'action'}
        commandFilter={(c) => !['helpdoc', 'video', 'link'].includes(c.template.type)}
        onChange={(e) => {
          onCommandChange(e.toString());
        }}
        commands={commands}
        standalone
      />
    </InputContainer>
  );
};

type ElementAppearsTrigger = TriggerableEntity['trigger'] & { type: 'when_element_appears' };

interface ElementAppearsOptionsProps {
  trigger: ElementAppearsTrigger;
  onSelectorChange: (value: ElementAppearsTrigger['meta']['selector']) => void;
}

const ElementAppearsOptions = ({ trigger, onSelectorChange }: ElementAppearsOptionsProps) => (
  <InputContainer>
    <StyledLabel>Element</StyledLabel>
    <ClickRecorderContainer>
      <Input
        style={{ border: '1px solid #A2A2A9' }}
        placeholder="CSS selector or XPath"
        onKeyDown={(e) => e.stopPropagation()}
        value={trigger.meta.selector}
        onChange={(e) => {
          onSelectorChange(transformIfJSPath(e.target.value));
        }}
        suffix={
          <ClickRecorder
            onValueChange={(value) => {
              onSelectorChange(value.selector);
            }}
          />
        }
      />
      {isSelectorFragile(trigger.meta.selector) && (
        <Tooltip content={SELECTOR_WARNING_TEXT}>
          <AlertTriangle color="rgb(216, 150, 20)" style={{ marginLeft: '8px' }} />
        </Tooltip>
      )}
    </ClickRecorderContainer>
  </InputContainer>
);

type EventTrigger = TriggerableEntity['trigger'] & { type: 'on_event' };

interface EventOptionsProps {
  trigger: EventTrigger;
  onEventChange: (value: EventTrigger['meta']['event']) => void;
  onConditionGroupChange: (value: EventTrigger['meta']['condition_group']) => void;
}

const EventOptions = ({ trigger, onEventChange, onConditionGroupChange }: EventOptionsProps) => {
  const { nudges, checklists } = useAppContext();

  return (
    <>
      <InputContainer>
        <StyledLabel>Event name</StyledLabel>
        <StyledEventAutoComplete>
          <EventAutoComplete
            onChange={(e) => {
              onEventChange(e);
            }}
            initialEventName={trigger.meta.event || ''}
            checklists={checklists}
            nudges={nudges}
          />
        </StyledEventAutoComplete>
      </InputContainer>

      <InputContainer>
        <StyledLabel>Event properties</StyledLabel>
        <ConditionGroupEditor
          onChange={(expr) => {
            onConditionGroupChange(expr);
          }}
          expr={
            trigger.meta.condition_group || {
              type: 'AND',
              exprs: [],
            }
          }
          includeCategories={['Event']}
        />
      </InputContainer>
    </>
  );
};

type PageReachedTrigger = TriggerableEntity['trigger'] & { type: 'when_page_reached' };

interface PagedReachedOptionsProps {
  trigger: PageReachedTrigger;
  onUrlChange: (value: PageReachedTrigger['meta']['url']) => void;
}

const PageReachedOptions = ({ trigger, onUrlChange }: PagedReachedOptionsProps) => (
  <InputContainer>
    <StyledLabel htmlFor="trigger_page_url">Page URL Contains</StyledLabel>
    <MediaBlockStyledInput
      name="trigger_page_url"
      value={trigger.meta.url}
      onChange={(e) => {
        onUrlChange(e.target.value);
      }}
      placeholder="e.g. /users/123/detail"
    />
  </InputContainer>
);

interface TriggerOptionsProps {
  trigger: TriggerableEntity['trigger'];
  handleTriggerChange: (changes: TriggerableEntity['trigger']) => void;
}

export const TriggerOptions = ({ trigger, handleTriggerChange }: TriggerOptionsProps) => {
  switch (trigger.type) {
    case 'after_time':
      return (
        <AfterTimeOptions
          trigger={trigger}
          onUnitChange={(unit) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, unit },
            });
          }}
          onValueChange={(value) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, value },
            });
          }}
        />
      );
    case 'scheduled':
      return (
        <ScheduledOptions
          trigger={trigger}
          onIntervalChange={(interval) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, interval },
            });
          }}
          onValueChange={(value) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, value },
            });
          }}
        />
      );
    case 'on_command_execution':
      return (
        <ActionOptions
          trigger={trigger}
          onCommandChange={(command) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, command },
            });
          }}
        />
      );
    case 'when_element_appears':
      return (
        <ElementAppearsOptions
          trigger={trigger}
          onSelectorChange={(selector) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, selector },
            });
          }}
        />
      );
    case 'on_event':
      return (
        <EventOptions
          trigger={trigger}
          onEventChange={(event) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, event },
            });
          }}
          onConditionGroupChange={(conditionGroup) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, condition_group: conditionGroup },
            });
          }}
        />
      );
    case 'when_page_reached':
      return (
        <PageReachedOptions
          trigger={trigger}
          onUrlChange={(url) => {
            handleTriggerChange({
              ...trigger,
              meta: { ...trigger.meta, url },
            });
          }}
        />
      );
    default:
      return null;
  }
};
