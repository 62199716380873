import { Children, type HTMLAttributes, type ReactNode, cloneElement, forwardRef, isValidElement } from 'react';

interface SlotProps {
  children?: ReactNode;
}

export const Slot = forwardRef<HTMLElement, SlotProps & HTMLAttributes<HTMLElement>>(({ children, ...props }, ref) => {
  if (isValidElement(children)) {
    return cloneElement(children, {
      ...props,
      ...children.props,
      ref,
      style: {
        ...props.style,
        ...children.props.style,
      },
    });
  }

  if (Children.count(children) > 1) {
    Children.only(null);
  }

  return null;
});
