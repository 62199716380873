import * as Command from '@commandbar/internal/middleware/command';
import * as Organization from '@commandbar/internal/middleware/organization';
import { getAPIPermissions } from './algolia-api';

export interface IAlgoliaConfig {
  orgID: string;
  applicationID?: string;
  apiKey?: string;
  indexes: Record<string, any>;
}

export const checkAreCredentialsValid = async (
  applicationID: string,
  apiKey: string,
): Promise<[boolean, string | null]> => {
  try {
    const REQUIRED_ALGOLIA_ACL = ['search', 'browse', 'listIndexes'];
    const { validity, acl } = await getAPIPermissions(applicationID, apiKey);
    if (validity !== 0 || !REQUIRED_ALGOLIA_ACL.every((p) => acl.includes(p))) {
      return [
        false,
        'Invalid API key permissions. Please ensure that your API key has following permissions enabled: browse, search, listIndexes',
      ];
    }

    return [true, null];
  } catch (err) {
    return [false, 'Invalid Application-ID or API key'];
  }
};

const deleteRecord = async (orgID: string, record: string) => {
  const organization = await Organization.read(orgID);

  const { [record]: remove, ...newResourceOptions } = organization.resource_options;

  await Organization.update({
    ...organization,
    resource_options: {
      ...newResourceOptions,
    },
  });
};

export const deleteRecordAndRecordActions = async (orgID: string, record: string) => {
  try {
    const recordActions = await Organization.listCommands(orgID).then((commands) =>
      commands.filter((c) => Command.isRecordAction(c, record)),
    );

    await Promise.all(recordActions.map((c) => Command.deleteWithoutThrottle(c.id, { integration: 'true' })));

    await deleteRecord(orgID, record);
  } catch (err) {
    console.log(err);
  }
};

export const getLinkedRecords = async (orgID: string) => {
  const commands = await Organization.listCommands(orgID);

  const linkedRecords = [];
  for (const command of commands) {
    for (const { type, value } of Object.values(command.arguments)) {
      if (type === 'context') {
        linkedRecords.push(value);
      }
    }
  }

  return linkedRecords;
};
