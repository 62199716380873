import css from '@emotion/css';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';

const useComponentOverrides = (slug: string, themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const lightModeOverrides: Record<string, any> = themeV2.themeV2?.light_mode?.component_overrides?.[slug] ?? {};
  const darkModeOverrides: Record<string, any> = themeV2.themeV2?.dark_mode?.component_overrides?.[slug] ?? {};

  return {
    overrides: [css(lightModeOverrides), themeV2.mode === 'dark_mode' && css(darkModeOverrides)].filter(Boolean),
    // TODO: this is hopefully temporary --
    // we're using this to get specific overrides for a component
    lightModeOverridesObject: lightModeOverrides,
    darkModeOverridesObject: darkModeOverrides,
  };
};

export default useComponentOverrides;
