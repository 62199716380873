import { CmdButton } from '@commandbar/design-system/cmd';
import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import { ElementInformation } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import React, { useMemo } from 'react';
import { ReactComponent as RecordSelectorIcon } from '../../img/record_selector.svg';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import Sender from 'editor/src/management/Sender';

export const ClickRecorder = (props: {
  onValueChange: (value: ElementInformation) => void;
  onStartClickRecorder?: (skipPrompt?: boolean) => void;
  children?: React.ReactNode;
}) => {
  const appContext = useAppContext();

  const id = useMemo(() => Math.random(), []);
  const onClickRecorderComplete: ReceiverFunctionType = ({ data }) => {
    /*
     * onClickRecorderComplete will be called indiscriminately, so we need to check if the id matches
     * the id of the current instance of the ClickRecorder.
     */
    if (data.id !== id) return;

    // no selectors mean the user cancelled the click recorder
    if (data.selectors.length > 0) {
      if (appContext.flags['release-click-recorder-backwards-compat']) {
        // NOTE: JL 2024-09-27
        // Before https://github.com/tryfoobar/monobar/pull/7311, data.selectors was an array of strings.
        // After, it became an array of ElementInformation
        // Since old @commandbar/foobar versions are out there, which send an array of strings, we need to handle both cases.
        // --
        if (typeof data.selectors[0] === 'string') {
          props.onValueChange({ selector: data.selectors[0], tag: '', attributes: {}, text: '' });
        } else {
          props.onValueChange(data.selectors[0]);
        }
      } else {
        props.onValueChange(data.selectors[0]);
      }
    }

    return respondSuccess();
  };

  usePortal({
    commandbar: {
      onClickRecorderComplete,
    },
  });

  return (
    <CmdButton
      onClick={(e) =>
        appContext.isStudio && props.onStartClickRecorder
          ? props.onStartClickRecorder(e.metaKey)
          : Sender.openClickRecorder(id, [], true)
      }
      icon={<RecordSelectorIcon />}
      id="record-command"
      variant="link"
    >
      {props.children}
    </CmdButton>
  );
};
