import React from 'react';
import { motion } from 'framer-motion';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

type LoadingProps = {
  height?: number | string;
  width?: number | string;
};

export const LoadingLogoAnimation = ({ height = 32, width = 'auto' }: LoadingProps) => {
  return (
    <motion.svg viewBox="0 0 143 107" fill="none" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <motion.path
        animate={{
          pathLength: [0, 1, 0],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
        }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.899 39.5453C103.954 39.7475 103.834 39.956 103.632 40.0104L86.1936 44.6925C85.9652 44.7538 85.7305 44.6179 85.6665 44.3902C83.5431 36.8359 78.8457 30.2598 72.3837 25.8094C65.8041 21.278 57.8268 19.2479 49.8868 20.0841C41.9468 20.9204 34.5651 24.5681 29.0694 30.3713C23.5738 36.1744 20.3248 43.7521 19.907 51.7413C19.4892 59.7305 21.9298 67.607 26.79 73.954C31.6502 80.301 38.611 84.702 46.4203 86.3653C54.2296 88.0286 62.3749 86.8452 69.3912 83.0278C76.282 79.2787 81.6396 73.2307 84.5394 65.9403C84.6268 65.7204 84.8745 65.6098 85.0953 65.6947L101.905 72.1605C101.915 72.1645 101.925 72.1689 101.935 72.1735L141.691 87.466C141.912 87.5509 142.022 87.7986 141.938 88.0193L135.538 104.725C135.454 104.945 135.206 105.055 134.986 104.971L106.691 94.0869L103.104 92.7072C100.513 91.7104 97.6566 90.5801 95.7102 88.4886C95.1776 87.9162 94.5821 87.1664 94.1063 86.5406C89.8095 91.857 84.4838 96.3223 78.3908 99.6373C67.4279 105.602 54.7009 107.451 42.4989 104.852C30.2968 102.253 19.4207 95.3767 11.8266 85.4595C4.23254 75.5423 0.418978 63.2353 1.07185 50.7522C1.72473 38.269 6.80119 26.4289 15.3882 17.3615C23.9751 8.29406 35.509 2.59445 47.9153 1.28782C60.3216 -0.018816 72.786 3.15327 83.0667 10.2335C93.2431 17.242 100.619 27.6246 103.899 39.5453Z"
        fill="none"
        stroke={CB_COLORS.primary}
        strokeWidth="2"
      />
    </motion.svg>
  );
};
