import React from 'react';
import { BreadCrumbItem, ItemType } from './breadcrumbItem';
import { ChevronRight } from '@commandbar/design-system/icons/react';
import { cn } from '../util';
import { breadcrumbStyles } from './style';

interface CmdBreadcrumbProps {
  className?: string;
  items: ItemType[];
  listView?: boolean;
}

export const CmdBreadcrumb = ({ items, className, listView = false }: CmdBreadcrumbProps) => {
  return (
    <ul className={cn(breadcrumbStyles({ listView }), className)}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <li className="flex my-auto">
            <BreadCrumbItem
              variant={index === items.length - 1 ? 'active' : undefined}
              to={item.to}
              href={item.href}
              onClick={item.onClick}
              label={item.label}
              className={item.className}
              icon={item.icon}
            >
              {item.icon ? (
                <span className="flex items-center gap-xs">
                  {item.icon} {item.label}
                </span>
              ) : (
                item.label
              )}
            </BreadCrumbItem>
          </li>
          {index < items.length - 1 && !listView && (
            <li className="flex my-auto">
              <ChevronRight color="#797C85" />
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
