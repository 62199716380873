import React from 'react';
import { CmdToast, CmdToastClose, CmdToastProvider, CmdToastViewport } from './index';
import { useCmdToast } from './useCmdToast';
import { CmdLabel } from '../typography';
import { CmdDivider } from '../divider';
import { AlertCircle, Check, InfoCircle, InfoHexagon } from '@commandbar/design-system/icons/react';

export function CmdToaster() {
  const { toasts } = useCmdToast();

  return (
    <CmdToastProvider>
      {toasts.map(function ({ id, title, description, action, variant, dismissable = true, ...props }) {
        return (
          <CmdToast key={id} {...props}>
            <div className="flex justify-center items-center w-full pl-lg h-full">
              <div className="flex">
                {variant === 'danger' ? (
                  <AlertCircle className="text-red500" />
                ) : variant === 'warning' ? (
                  <InfoHexagon className="text-orange500" />
                ) : variant === 'success' ? (
                  <Check className="text-green500" />
                ) : (
                  <InfoCircle className="text-blue500 " />
                )}
              </div>

              {title && <CmdLabel className="text-contentInverse pl-sm pr-lg">{title}</CmdLabel>}

              {(!!action || dismissable) && <CmdDivider type="vertical" className="bg-contentMid" />}
              {!!action ? <div className="p-xs">{action}</div> : dismissable ? <CmdToastClose /> : null}
            </div>
          </CmdToast>
        );
      })}
      <CmdToastViewport />
    </CmdToastProvider>
  );
}
