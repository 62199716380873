/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Clock } from '@commandbar/design-system/icons/react';
import { getTimeSinceDate } from '@commandbar/internal/util/time';

const Container = styled.div`
  position: absolute;
  top: calc(-1 * var(--layout-padding));
  right: var(--layout-padding);
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: var(--layout-radius-button);
  border: 1px solid var(--border-primary);
  background: var(--background-primary);
  padding: 0px var(--form-control-padding-x);
  min-height: 24px;
  white-space: nowrap;

  color: var(--content-secondary);

  /* label/sm/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);

  opacity: 0;
`;

type Props = {
  time: string;
};

const StyledTimestamp = ({ time }: Props) => {
  const timeAgo = getTimeSinceDate(time);

  return time ? (
    <Container id="chat-message-timestamp" css={{ '&:hover': { opacity: '1' } }}>
      <Clock height="12px" width="12px" fill="var(--background-primary)" />
      {timeAgo}
    </Container>
  ) : null;
};

export default StyledTimestamp;
