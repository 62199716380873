import { Col, Select } from '@commandbar/design-system/components/antd';
import React from 'react';
import { Description, TextContainer } from '../../commands/CommmandDetail/ActionTypeSelector/components/styled';
import { defaultConditions } from '../categories';
import { useConditionEditor } from '../state/useConditionEditor';

export const ConditionTypeSelect: React.FC = () => {
  const { condition, categories, onConditionChange, disabled } = useConditionEditor();

  let conditionType = condition.type;

  // XXX: Checklist and Nudge interactions have been combined into a single type _only_ within the condition editor.
  // This is a quick and dirty way to ensure that 'Nudge Interaction' is always displayed as the condition type.
  if (condition.type === 'questlist_interaction') {
    conditionType = 'nudge_interaction';
  }

  return (
    <Col
      flex={
        ['url', 'element', 'context', 'user_property', 'event_property', 'ab_test'].includes(condition.type)
          ? '1 0 auto'
          : '1 3 auto'
      }
    >
      <Select
        disabled={disabled}
        value={conditionType}
        onChange={(value) => {
          onConditionChange(defaultConditions[value]);
        }}
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
        size="small"
      >
        {categories.map(({ category, options }) => (
          <Select.OptGroup label={category} key={category}>
            {options.map((option) => {
              return (
                <Select.Option value={option.value} key={`condition-${option.value}`} disabled={option.disabled}>
                  <TextContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {option.icon}
                      <span>{option.label}</span>
                    </div>
                    {option.disabled ? (
                      <Description>{option.disabledDescription}</Description>
                    ) : (
                      <Description>{option.description}</Description>
                    )}
                  </TextContainer>
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        ))}
      </Select>
    </Col>
  );
};
