import React from 'react';
import styled from '@emotion/styled';
import { CodeLine } from './CodeLine';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  width: 100%;
  display: flex;
`;

const Tab = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 64px;
  height: 36px;

  color: #fff;
  background: ${(props) => (props.active ? '#080C1C' : '#393D49')};
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
`;

type TTab = {
  key: string;
  label: string;
  code: string;
};

const tabs: TTab[] = [
  {
    key: 'npm',
    label: 'NPM',
    code: '$ npm install commandbar --save',
  },
  {
    key: 'yarn',
    label: 'Yarn',
    code: '$ yarn add commandbar --dev',
  },
];

export const InstallPackageCode = () => {
  const [activeTab, setActiveTab] = React.useState<TTab>(tabs[0]);

  return (
    <Container>
      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab.key} onClick={() => setActiveTab(tab)} active={activeTab.key === tab.key}>
            {tab.label}
          </Tab>
        ))}
      </Tabs>
      <CodeLine code={activeTab.code} />
    </Container>
  );
};
