import { useHistory } from 'react-router-dom';

import { CmdButton, CmdInput, CmdLabel, cmdToast } from '@commandbar/design-system/cmd';
import { Copy02 } from '@commandbar/design-system/icons/react';
import Logger from '@commandbar/internal/util/Logger';
import { useAppContext } from 'editor/src/AppStateContext';
import type { StepProps } from './ActivationPage';

interface DetailsDisplayProps {
  apiKey: string;
}

const DetailsDisplay = ({ apiKey }: DetailsDisplayProps) => {
  const { organization } = useAppContext();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
      <CmdLabel>Organization Id</CmdLabel>
      <CmdInput
        fullWidth
        suffixElement={
          <CmdButton
            size="sm"
            style={{ padding: '0px' }}
            variant="link"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(organization.id.toString());
                cmdToast.success('URL copied to clipboard!');
              } catch (e) {
                Logger.error(e);
                cmdToast.error('Unable to copy URL to clipboard. Please try again');
              }
            }}
          >
            <Copy02 />
          </CmdButton>
        }
        value={organization.id.toString()}
      />
      <CmdLabel style={{ marginTop: '8px' }}>API Key</CmdLabel>

      <CmdInput
        fullWidth
        suffixElement={
          <CmdButton
            size="sm"
            style={{ padding: '0px' }}
            variant="link"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(apiKey);
                cmdToast.success('API key copied to clipboard!');
              } catch (e) {
                Logger.error(e);
                cmdToast.error('Unable to copy secret to clipboard. Please try again');
              }
            }}
          >
            <Copy02 />
          </CmdButton>
        }
        value={apiKey}
      />
    </div>
  );
};

export const APIKeyStep = ({ handlePrev, amplitudeConfig }: StepProps) => {
  const history = useHistory();

  if (!amplitudeConfig.api_key) {
    handlePrev();
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <span>This will allow Amplitude cohorts to be used as audiences within the CommandBar editor.</span>
      <ul>
        <li>
          In Amplitude Data, click <strong>Catalog</strong> and select the <strong>Destinations</strong> tab.
        </li>
        <li>
          In the Cohort section, click <strong>CommandBar</strong>.
        </li>
        <li>
          Click <strong>Add another destination</strong>.
        </li>
        <li>
          Copy and paste the <strong>Org Id</strong> and <strong>API key</strong> into <strong>Amplitude</strong>.
        </li>
        <li>Map the Amplitude User ID field to the CommandBar User ID field</li>
        <li>Save when finished.</li>
      </ul>

      <DetailsDisplay apiKey={amplitudeConfig.api_key} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CmdButton variant="primary" onClick={handlePrev}>
          Go Back
        </CmdButton>
        <CmdButton
          variant="primary"
          onClick={() => {
            history.push('/integrations');
          }}
        >
          Done
        </CmdButton>
      </div>
    </div>
  );
};
