/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */

export const MacModifiers = [
  {
    label: 'Command (⌘)',
    value: 'mod',
  },
  {
    label: 'Option (⌥)',
    value: 'alt',
  },
  {
    label: 'Control (⌃)',
    value: 'ctrl',
  },
  {
    label: 'Shift (⇧)',
    value: 'shift',
  },
];

export const WinModifiers = [
  {
    label: 'Control (Ctrl)',
    value: 'mod',
  },
  {
    label: 'Alt (Alt)',
    value: 'alt',
  },
  {
    label: 'Windows (⊞)',
    value: 'meta',
  },
  {
    label: 'Shift (⇧)',
    value: 'shift',
  },
];

const control = [
  {
    label: 'THEN',
    value: 'then',
  },
  {
    label: 'THEN',
    value: 'then1',
  },
  {
    label: 'THEN',
    value: 'then2',
  },
];

const special = [
  { label: 'Backspace (⌫)', value: 'backspace' },
  { label: 'Tab (⇥)', value: 'tab' },
  { label: 'Enter / Return (⏎)', value: 'enter' },
  { label: 'Caps lock (⇪)', value: 'capslock' },
  { label: 'Escape (⎋)', value: 'esc' },
  { label: 'Space', value: 'space' },
  { label: 'Page Up', value: 'pageup' },
  { label: 'Page Down', value: 'pagedown' },
  { label: 'End', value: 'end' },
  { label: 'Home', value: 'home' },
  { label: 'Left arrow (←)', value: 'left' },
  { label: 'Up arrow (↑)', value: 'up' },
  { label: 'Right arrow (→)', value: 'right' },
  { label: 'Down arrow (↓)', value: 'down' },
  { label: 'Insert', value: 'ins' },
  { label: 'Delete (DEL)', value: 'del' },
  { label: 'Plus (+)', value: 'plus' },
];

const keys = [
  { label: 'a', value: 'a' },
  { label: 'b', value: 'b' },
  { label: 'c', value: 'c' },
  { label: 'd', value: 'd' },
  { label: 'e', value: 'e' },
  { label: 'f', value: 'f' },
  { label: 'g', value: 'g' },
  { label: 'h', value: 'h' },
  { label: 'i', value: 'i' },
  { label: 'j', value: 'j' },
  { label: 'k', value: 'k' },
  { label: 'l', value: 'l' },
  { label: 'm', value: 'm' },
  { label: 'n', value: 'n' },
  { label: 'o', value: 'o' },
  { label: 'p', value: 'p' },
  { label: 'q', value: 'q' },
  { label: 'r', value: 'r' },
  { label: 's', value: 's' },
  { label: 't', value: 't' },
  { label: 'u', value: 'u' },
  { label: 'v', value: 'v' },
  { label: 'w', value: 'w' },
  { label: 'x', value: 'x' },
  { label: 'y', value: 'y' },
  { label: 'z', value: 'z' },
  { label: '!', value: '!' },
  { label: '#', value: '#' },
  { label: '$', value: '$' },
  { label: '%', value: '%' },
  { label: '&', value: '&' },
  { label: '(', value: '(' },
  { label: ')', value: ')' },
  { label: '*', value: '*' },
  { label: ',', value: ',' },
  { label: '-', value: '-' },
  { label: '.', value: '.' },
  { label: '/', value: '/' },
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: ':', value: ':' },
  { label: ';', value: ';' },
  { label: '<', value: '<' },
  { label: '=', value: '=' },
  { label: '>', value: '>' },
  { label: '?', value: '?' },
  { label: '@', value: '@' },
  { label: '[', value: '[' },
  { label: '\\', value: '\\' },
  { label: ']', value: ']' },
  { label: '^', value: '^' },
  { label: '_', value: '_' },
  { label: '`', value: '`' },
  { label: '{', value: '{' },
  { label: '|', value: '|' },
  { label: '}', value: '}' },
  { label: '~', value: '~' },
];

export const MacOptions = {
  keys,
  modifiers: MacModifiers,
  control,
  special,
};

export const WinOptions = {
  keys,
  modifiers: WinModifiers,
  control,
  special,
};

export const orderedModifiers = ['meta', 'mod', 'cmd', 'command', 'ctrl', 'alt', 'option', 'shift'];

export const SPECIAL_KEYS = [
  'backspace',
  'tab',
  'enter',
  'return',
  'capslock',
  'esc',
  'escape',
  'space',
  'pageup',
  'pagedown',
  'end',
  'home',
  'left',
  'up',
  'right',
  'down',
  'ins',
  'del',
  'plus',
  'f1',
  'f2',
  'f3',
  'f4',
  'f5',
  'f6',
  'f7',
  'f8',
  'f9',
  'f10',
  'f11',
  'f12',
];
