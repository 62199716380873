import { CallbackMap, Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import { CBStore } from './global-store';
import Analytics from 'shared/services/analytics/Analytics';
import {
  IChecklist,
  ICommandType,
  INudgeType,
  IOrganizationType,
  IRecordSettings,
  IRecordSettingsByContextKey,
  ISkinType,
  ITabType,
  IUserContext,
} from '@commandbar/internal/middleware/types';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { doNotTrackContext } from 'shared/store/util/doNotTrack';
import * as SpotlightServiceActions from 'products/spotlight/service-actions';
import * as SpotlightServiceSelectors from 'products/spotlight/service-selectors';
import * as ChecklistServiceActions from 'products/checklists/service-actions';
import * as NudgeServiceActions from 'products/nudges/service-actions';
import * as NudgeServiceSelectors from 'products/nudges/service-selectors';

import { ITheme } from '@commandbar/internal/client/theme';
import { interpolate } from 'shared/util/Interpolate';
import { getRenderingServices } from 'products/nudges/service-selectors';

import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { getSDK } from '@commandbar/internal/client/globals';
import { isStudioPreview } from '@commandbar/internal/util/location';
import {
  ShareLinkExperienceIdentifier,
  getShareLinkType,
  getAbsoluteSharePageUrl,
  urlMatchesTargeting,
} from '@commandbar/internal/client/share_links';
import { _eventSubscriptions, _loadEditor } from '@commandbar/internal/client/symbols';
import { isEditorBeingSummoned } from 'shared/sdk/utils';
import { checkForEditor, clickEditorHandle, isEditorOpen } from 'shared/store/util/editorUtils';

import type { EventData, EventType, ExecutionEventSource } from 'shared/services/analytics/EventHandler';
import { passesPageConditions, passesPinnedElement } from 'products/nudges/service-selectors';
import { checklistPassesPageConditions } from '@commandbar/commandbar/products/checklists/service-selectors';
import { ShareExtensionStatusMessage } from '@commandbar/internal/client/extension/messages';
import { PREVIEW_ELEMENT_ID } from '@commandbar/internal/client/extension/preview';
import { RECORDER_ELEMENT_ID } from '@commandbar/internal/client/extension/recorder';
import type { Coordinates2D } from '@commandbar/commandbar/products/nudges/components/utils';
import Logger from '@commandbar/internal/util/Logger';

export type TriggerSource = { experience: ExecutionEventSource['type']; position: Coordinates2D };

export * from './executables/executable-actions';

export const addCallbacks = (_: CBStore, callbacks: CallbackMap) => {
  const callbacksAfterAdd = { ..._.callbacks, ...callbacks };
  if (isEqual(_.callbacks, callbacksAfterAdd)) return;

  _.callbacks = callbacksAfterAdd;
};

export const addCommand = (_: CBStore, command: ICommandType) => {
  // **Replace** existing command if there's already a command with this name in this category
  _.programmaticCommands = _.programmaticCommands.filter(
    (c) => !(c.name === command.name && c.category === command.category),
  );
  _.programmaticCommands.push(command);
};

export const addMockCommand = (_: CBStore, command: ICommandType) => {
  removeMockCommand(_, command);
  _.mockCommands.push(command);
};

export const removeMockCommand = (_: CBStore, command: ICommandType) => {
  _.mockCommands = _.mockCommands.filter((c) => c.id !== command.id);
};

export const addContext = (_: CBStore, context: IUserContext) => {
  // Register all context keys as opaque references
  doNotTrackContext(context);
  Object.assign(_.context, context);
};

/** FIXME: executeCommand shouldn't depend on the spotlight steps state */
export const executeCommand = (
  _: CBStore,
  command: ICommandType,
  onSuccess?: () => void,
  onFail?: () => void,
  executionEventSource: ExecutionEventSource = { type: 'bar' },
  e?: React.MouseEvent,
  ignoreSpotlightAvailability?: boolean,
) => {
  const pseudoState = {
    ..._,
    spotlight: { ..._.spotlight, steps: [SpotlightServiceSelectors.initBaseStep(null)] },
  };

  const commandOption = SpotlightServiceSelectors.initCommandOption(pseudoState, command);
  const { isDisabled, isDisabledReason } = commandOption.optionDisabled;

  if (!isDisabled || (ignoreSpotlightAvailability && isDisabledReason === 'Not available in Spotlight search')) {
    SpotlightServiceActions.chooseCommandOption(
      _,
      commandOption,
      true,
      SpotlightServiceActions.updateSteps.bind(null, _),
      executionEventSource,
      {
        openLinkInNewTab: !!e && getTriggerKey(e),
      },
    );
    onSuccess && onSuccess();
  } else {
    onFail && onFail();
  }

  return isDisabled;
};

export const removeCallback = (_: CBStore, toRemove: string) => {
  if (!(toRemove in _.callbacks)) return;

  delete _.callbacks[toRemove];
};

export const removeCommand = (_: CBStore, name: string) => {
  _.programmaticCommands = _.programmaticCommands.filter((c) => c.name !== name);
};

export const removeContext = (_: CBStore, toRemove: string) => {
  delete _.context[toRemove];
  delete _.localContextSettings[toRemove];
};

export const setBaseTheme = (
  _: CBStore,
  theme: string | Pick<ISkinType, 'logo' | 'skin' | 'chat_avatar'>,
  themeSource: string,
  color?: string,
) => {
  _.baseTheme = theme;
  _.themeSource = themeSource;
  _.primaryColor = color;
};

/** Fixme: move to spotlight */
export const setTabs = (_: CBStore, tabs: ITabType[]) => {
  _.spotlight.tabs = tabs;
};

export const setCommands = (_: CBStore, commands: ICommandType[]) => {
  // NOTE: we may not need to cons up a new array here -- I just kept it this way for "backwards compatibility" -JL
  _.commands = [...commands];
  _.commandsLoaded = true;
};

export const setContext = (_: CBStore, context: IUserContext) => {
  // If the context is equal, don't make changes
  if (isEqual(_.context, context)) return;
  doNotTrackContext(context);
  _.context = context;
};

export const setContextState = (
  _: CBStore,
  callbacks?: CallbackMap,
  context?: Record<string, unknown>,
  contextSettings?: IRecordSettingsByContextKey,
) => {
  if (context) doNotTrackContext(context);
  const _contextAfterAdd = !!context ? { ..._.context, ...context } : _.context;
  const _callbacksAfterAdd = !!callbacks ? { ..._.callbacks, ...callbacks } : _.callbacks;

  // broke with this change https://github.com/tryfoobar/monobar/pull/702/files#r801167536
  // move this to a subscription

  if (
    isEqual(_.context, _contextAfterAdd) &&
    isEqual(_.callbacks, _callbacksAfterAdd) &&
    isEqual(_.localContextSettings, contextSettings)
  ) {
    return;
  }

  // FIXME: the following can be replaced with this faster implementation once async rendering bugs are dealt with:
  // _.context = ref(_contextAfterAdd);
  // _.callbacks = _callbacksAfterAdd;
  // _.hackContextSettings = _hackContextSettings;

  _.context = _contextAfterAdd;
  _.callbacks = _callbacksAfterAdd;
  if (contextSettings) {
    _.localContextSettings = merge({}, _.localContextSettings, contextSettings);
  }
  //_.hackContextSettings = _hackContextSettings;
};

export const setLocalContextSettings = (_: CBStore, key: string, settings: IRecordSettings) => {
  const requiredSettings = { search: false };
  _.localContextSettings[key] = { ...requiredSettings, ...settings };
};

export const updateLocalContextSetting = (
  _: CBStore,
  key: string,
  setting: keyof IRecordSettings,
  value: IRecordSettings[keyof IRecordSettings],
) => {
  const currentSettings = _.localContextSettings[key] || {};
  _.localContextSettings[key] = { ...currentSettings, [setting]: value };
};

export const setIsAdmin = (_: CBStore, value: boolean) => {
  if (value) Analytics.identifyAsAdmin();
  _.isAdmin = value;
};

export const setTheme = (_: CBStore, theme?: ITheme) => {
  _.theme = theme;
};

export const setThemeMode = (_: CBStore, mode: 'light_mode' | 'dark_mode' | 'auto') => {
  if (['light_mode', 'dark_mode', 'auto'].includes(mode)) _.themeV2Mode = mode;
};

export const setOrganization = (_: CBStore, organization: IOrganizationType, themeSource: string) => {
  _.organization = organization;
  _.logo = organization.icon ?? '';
  _.themeSource = themeSource;
  _.serverContextSettings = organization.resource_options;
};

export const setTestMode = (_: CBStore, value: boolean) => {
  if (value) {
    LocalStorage.set('testMode', '1');
  } else {
    LocalStorage.remove('testMode');
  }

  _.testMode = value;
};

export const trackEvent = (_: CBStore, key: string, type: 'commandbar' | 'app', properties: Metadata = {}) => {
  if (type === 'commandbar' && !key.startsWith('commandbar-')) {
    key = `commandbar-${key}`;
  }

  if (type === 'app') {
    _.trackedAppEvents.add(key);
  }

  NudgeServiceActions.sendIndirectTrigger(
    _,
    { type: 'on_event', meta: { event: key, condition_group: undefined } },
    undefined,
    properties,
  );
  ChecklistServiceActions.triggerChecklists(
    _,
    { type: 'on_event', meta: { event: key, condition_group: undefined } },
    properties,
  );
  ChecklistServiceActions.updateChecklistItemEventGoals(_, key, properties);
};

const arePathsSame = (startPageURL: URL, currentURL: URL): boolean => {
  if (startPageURL.hash) {
    return startPageURL.pathname + startPageURL.hash === currentURL.pathname + currentURL.hash;
  }

  return startPageURL.pathname === currentURL.pathname;
};

export const activatePushExperience = (
  _: CBStore,
  experience: INudgeType | IChecklist,
  type: ShareLinkExperienceIdentifier,
  source?: TriggerSource,
) => {
  if (isStudioPreview()) {
    if (type === 'nudge') {
      _.callbacks['__standalone-editor-cb_nudge_triggered']((experience as INudgeType).steps[0].title);
    } else {
      _.callbacks['__standalone-editor-cb_questlist_triggered']((experience as IChecklist).title);
    }

    return;
  }

  //trigger nudges and questlists via share link
  //http://localhost:3000/?cb-eid=q1
  const paramValue = `${getShareLinkType(type)}t${experience.id}`;
  const currentURL = new URL(_.location.href);

  let interpolatedSharePageUrlOrPath = null;

  try {
    interpolatedSharePageUrlOrPath = interpolate(experience.share_page_url_or_path, _, true, false, true);
  } catch (error) {
    Logger.warn('Failed to interpolate share page URL or path', error);
    return;
  }

  const startPageURL = getAbsoluteSharePageUrl(currentURL, interpolatedSharePageUrlOrPath);

  const shouldRedirectToStartPage = () => {
    if (!startPageURL) {
      return false;
    }

    if (arePathsSame(startPageURL, currentURL)) {
      return false;
    }

    if (!urlMatchesTargeting(startPageURL.toString(), experience.show_expression)) {
      return false; // no sense in redirecting if the new URL doesn't match either
    }

    if (['nudge', 'n'].includes(type)) {
      const nudge = experience as INudgeType;
      const showExpressionPasses = passesPageConditions(_, nudge);
      const anchorFound = passesPinnedElement(nudge, 0);

      return !showExpressionPasses || !anchorFound;
    } else {
      return !checklistPassesPageConditions(_, experience as IChecklist);
    }
  };

  if (!startPageURL || !shouldRedirectToStartPage()) {
    if (['nudge', 'n'].includes(type)) {
      const nudge = experience as INudgeType;
      const servicesInRenderLoop = getRenderingServices(_);

      let isNudgeCurrentlyRendering = false;
      // INFO: if we're triggering a nudge from a user action, we want to close all other nudges
      for (const service of servicesInRenderLoop) {
        const currentNudge = service.getSnapshot()?.context.nudge;
        const currentNudgeId = currentNudge?.id;

        if (currentNudgeId === nudge.id) {
          // if we are already rendering the triggered nudge, we don't want to close it
          isNudgeCurrentlyRendering = true;
          break;
        }

        if (!NudgeServiceSelectors.isTooltipNudge(currentNudge)) {
          // CLOSE closes a nudge without reporting or saving interactions
          service.send('CLOSE');
        }
      }

      // we don't need to retrigger a nudge if we're already rendering it
      if (!isNudgeCurrentlyRendering) {
        NudgeServiceActions.forceTriggerSingleNudge(_, nudge, { maxRenderedNudges: true }, source);
      }

      NudgeServiceActions.forceTriggerSingleNudge(
        _,
        nudge,
        {
          // allow admins to trigger draft nudges
          status: _.isAdmin,
          // allow admins to trigger nudge outside simulate mode
          admin: true,
          // don't check audience conditions
          audience: true,
          // don't check frequency
          frequency: true,
          // don't check that conditions have changed since last trigger
          flip: true,
          // don't check global limit
          globalLimit: true,
          // always start tours at first step even if they've been seen before
          stepIndex: 0,
          maxRenderedNudges: true,
        },
        source,
      );
    } else {
      ChecklistServiceActions.forceTriggerChecklist(_, experience as IChecklist);
    }
  } else {
    startPageURL.searchParams.set('cb-eid', paramValue);
    const relativeUrl = startPageURL.pathname + startPageURL.search + startPageURL.hash;

    const routerFunc = _.callbacks['commandbar-router'];
    if (routerFunc) {
      routerFunc(relativeUrl);
    } else {
      // using the relative path means that we'll automatically use the current domain
      window.location.href = relativeUrl;
    }
  }
};

export const addBuiltInCallbacks = (_: CBStore) => {
  addCallbacks(_, {
    'commandbar-builtin-shortcuts': () =>
      (_.spotlight.showKeyboardShortcutCheatsheet = !_.spotlight.showKeyboardShortcutCheatsheet),
    'commandbar-noop': function (args: any) {
      alert(`This is a demo command!\n\nYou entered:\n\n${JSON.stringify(args, null, 2)}`);
    },
  });
};

export const addBuiltInEventSubscriptions = (_: CBStore) => {
  const sdk = getSDK();
  if (!!sdk[_eventSubscriptions] && sdk[_eventSubscriptions] !== undefined) {
    const symbol = Symbol('commandbar-event-tracker');

    sdk[_eventSubscriptions]?.set(symbol, [
      (name: EventType, data: EventData) => {
        trackEvent(_, name, 'commandbar', data as unknown as Metadata);
      },
      undefined,
    ]);
  }
};

export const openEditor = (_: CBStore) => {
  // When open Editor gets called we want to close the preview and recorder that might have been added by the extension
  if (_.extension.preview.enabled || _.extension.recorder.enabled) {
    _.extension.preview = { enabled: false };
    _.extension.recorder = { enabled: false };

    document.getElementById(PREVIEW_ELEMENT_ID)?.remove();
    document.getElementById(RECORDER_ELEMENT_ID)?.remove();

    ShareExtensionStatusMessage.sendToPage({ preview: false, recorder: false });
  }

  if (checkForEditor()) {
    clickEditorHandle();
    SpotlightServiceActions.setVisible(_, false);
    return;
  }

  // Poll for the editor element, close the bar once found
  const interval = setInterval(() => {
    const foundEditor = checkForEditor();
    if (!!foundEditor) {
      clearInterval(interval);

      setTimeout(() => {
        if (!isEditorOpen()) {
          clickEditorHandle();
        }
        SpotlightServiceActions.setDashboard(_, undefined);
        SpotlightServiceActions.setVisible(_, false);
      }, 500);
    }
  }, 500);

  // While waiting for the editor, play an animation
  SpotlightServiceActions.setDashboard(_, 'editor-loading');

  // If the editor doesn't show up within MAX_WAIT_TIME, then show an error message
  const MAX_WAIT_TIME = 6000; // ms
  setTimeout(() => {
    if (!checkForEditor()) {
      clearInterval(interval);
      SpotlightServiceActions.setDashboard(_, 'editor-timeout');
    }
  }, MAX_WAIT_TIME);

  getSDK()[_loadEditor]();
};

export const tryOpenEditor = (_: CBStore): boolean => {
  if (isEditorBeingSummoned(_.spotlight.inputText)) {
    openEditor(_);
    return true;
  }

  return false;
};

export const setEnvOverride = (_: CBStore, envOverride: { env: string } | { version: string }) => {
  LocalStorage.set('envOverride', JSON.stringify(envOverride));
  _.envOverride = envOverride;
};

export const clearEnvOverride = (_: CBStore) => {
  LocalStorage.remove('envOverride');
  _.envOverride = null;
};
