import * as t from 'io-ts';

import { createObject, deleteObject, listObject, readObject, updateObject } from './generics';

export const PlaceholderV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      slug: t.string,
      text: t.string,
    }),
    t.partial({}),
  ],
  'Placeholder',
);

export class Placeholder {
  public static create = createObject(PlaceholderV, PlaceholderV, 'placeholders');
  public static update = updateObject(PlaceholderV, PlaceholderV, 'placeholders');
  public static delete = deleteObject(PlaceholderV, 'placeholders');
  public static list = listObject(PlaceholderV, 'placeholders');
  public static read = readObject(PlaceholderV, 'placeholders');
}
