import React from 'react';
import { useHistory } from 'react-router';

import { Plus } from '@commandbar/design-system/icons/react';

import { CmdButton, CmdCard, CmdStatusDot, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { Page } from '@commandbar/design-system/components/antd';

import { hasUnpublishedChanges } from '@commandbar/internal/middleware/theme';
import { IThemeType } from '@commandbar/internal/middleware/types';
import { THEME_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';

import { ColorSwatch, ThemeDropdown } from './shared';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';

const ThemeList = () => {
  const history = useHistory();
  const { user } = useAuth();

  const {
    themes,
    dispatch: {
      themes: { save },
    },
  } = useAppContext();

  const setActiveTheme = (theme: IThemeType) => {
    history.push(`${THEME_ROUTE}/${theme.id}/base`);
  };

  const createTheme = async () => {
    const theme = await save({});
    if (theme) {
      setActiveTheme(theme);
    }
  };

  const ThemeCard = ({ theme }: { theme: IThemeType }) => {
    return (
      <CmdCard
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          ...(!theme.default && { height: '56px' }),
        }}
        onClick={() => history.push(`${THEME_ROUTE}/${theme.id}/base`)}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <CmdTypography.H3 fontSize="base" fontWeight="semi-bold">
            {theme.name}
            {hasUnpublishedChanges(theme) && (
              <CmdTooltip message="Theme has unpublished changes">
                <CmdStatusDot style={{ marginLeft: '8px' }} variant="warning" />
              </CmdTooltip>
            )}
          </CmdTypography.H3>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '-4px' }}>
            <ColorSwatch
              large
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--base-accent'] ??
                theme.themeV2_draft?.light_mode.var_defaults['--base-accent']
              }
            />
            <ColorSwatch
              large
              overlap
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--background-primary'] ||
                theme.themeV2_draft?.light_mode.var_defaults['--background-primary']
              }
            />
            <ColorSwatch
              large
              overlap
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--content-primary'] ||
                theme.themeV2_draft?.light_mode.var_defaults['--content-primary']
              }
            />
          </div>
          {hasRequiredRole(user, 'contributor') && <ThemeDropdown theme={theme} themes={themes} />}
        </div>
      </CmdCard>
    );
  };

  const defaultTheme = themes.find((theme) => theme.default);
  const otherThemes = themes.filter((theme) => !theme.default && !theme.archived);

  return (
    <Page
      style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', background: '#F9F9F9' }}
      title="Themes"
      headerChildren={
        hasRequiredRole(user, 'contributor') && (
          <CmdButton variant="primary" icon={<Plus />} onClick={createTheme}>
            New theme
          </CmdButton>
        )
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {defaultTheme && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <CmdTypography.H3 variant="contentMid" fontSize="base" fontWeight="medium">
                Default theme
              </CmdTypography.H3>
              <CmdTypography.Body variant="secondary" fontSize="sm" fontWeight="medium">
                Applied to all Nudge and Assist experiences by default
              </CmdTypography.Body>
            </div>
            <ThemeCard key={`theme-${defaultTheme.slug}`} theme={defaultTheme} />
          </div>
        )}
        {!!otherThemes.length && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <CmdTypography.H3 variant="contentMid" fontSize="base" fontWeight="medium">
              Other themes
            </CmdTypography.H3>
            {otherThemes.map((theme) => (
              <ThemeCard key={`theme-${theme.slug}`} theme={theme} />
            ))}
          </div>
        )}
      </div>
    </Page>
  );
};

export default ThemeList;
