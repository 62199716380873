import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Prompt, Redirect, Route, Switch, useHistory, useLocation } from 'react-router';

import { ReceiverFunctionType } from '@commandbar/internal/client/Portal';
import usePortal, { respondSuccess } from '@commandbar/internal/client/usePortal';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';

import {
  FeatureAnnouncementCard,
  Row,
  Skeleton,
  CB_COLORS,
  Alert,
  Modal,
} from '@commandbar/design-system/components/antd';

import Commands from './Commands';
import PagesOrActions from './PagesOrActions';
import {
  DebugChecks,
  DebugUserProperties,
  DebugMetadata,
  DebuggerView,
  DebugMetadataSimulator,
  DebugSDKLogs,
} from './Debugger';
import Releases from './Releases';
import LogoutHeader from './components/LogoutHeader';

import NudgeList from './nudges/NudgeList';
import { useAppContext } from '../AppStateContext';
import ChecklistList from './checklists/ChecklistList';
import VisualStyleEditor from './VisualStyleEditor';
import HelpHubRecommendations from './helphub/HelpHubRecommendations';
import CopilotEditor from './copilot/CopilotEditor';
import Navbar from '../components/Navbar';
import StyleToolbar from '../components/StyleToolbar';
import Sender from '../management/Sender';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { DEBUG_NUDGE_PARAM, NUDGE_STEP_PARAM } from '@commandbar/internal/util/location';
import { TUpdateEditorRouteDetails, dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import HijackUserBanner from 'commandbar.com/src/components/HijackUserBanner';
import { LogOut01, User01 } from '@commandbar/design-system/icons/react';
import { CmdTypography, CmdButton } from '@commandbar/design-system/cmd';
import { useCallAsyncFunction } from '@commandbar/internal/util/useCallAsyncFunction';
import { Billing } from '@commandbar/internal/middleware/billing';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';

import NudgeTemplate from './nudges/NudgeTemplate';
import { NewNudgeMenu } from './nudges/New';
import { NewChecklistMenu } from './checklists/New';
import ThemeDetail from './themes/ThemeDetail';
import ThemeList from './themes/ThemeList';
import { useReportEvent } from '../hooks/useEventReporting';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

export const ScrollContainer = styled.div`
  overflow: auto;
  background: ${CB_COLORS.neutral0};
  height: 100%;
`;

export const PaddingContainer = styled.div`
  padding: 24px;
`;

interface ContainerProps {
  isStudio?: boolean;
}

const Container = styled.div<ContainerProps>`
  background: #ffffff;
  display: flex;
  min-width: 0;
  flex: 1;
  flex-direction: column;
`;

const ModalFooterContainer = styled.div`
  display: flex;
  gap: 14px;
`;

export const EditorRouter = () => {
  const [isFetchingHostURL, setIsFetchingHostURL] = useState(true);
  const [showUserVerificationError, setShowUserVerificationError] = React.useState(false);
  const [activeCommand, setActiveCommand] = React.useState<IEditorCommandType | undefined>(undefined);
  const history = useHistory();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { reportEvent } = useReportEvent();
  const { organization, loading, isStudio, nudges, hasUnsavedChangesRef } = useAppContext();
  const currentPathRef = React.useRef(location.pathname);

  const isLoading = isFetchingHostURL || loading;
  const billingProfile = useCallAsyncFunction(() => Billing.readProfile()).result || null;

  const activeExtensionPath = LocalStorage.get('activeExtensionPath', editorRoutes.DEFAULT_EDITOR_ROUTE);
  const extensionPath = activeExtensionPath === '/' ? editorRoutes.DEFAULT_EDITOR_ROUTE : activeExtensionPath;

  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname === '/blank') {
        if (action === 'POP') {
          dispatchCustomEvent('editorRouteUpdated', {
            path: '',
            action,
          });
        }
        return;
      }

      if (location.pathname !== currentPathRef.current) {
        currentPathRef.current = location.pathname;
        hasUnsavedChangesRef.current = false;
      }

      LocalStorage.set('activeExtensionPath', location.pathname);

      dispatchCustomEvent('editorRouteUpdated', {
        path: `${location.pathname}${location.search}${location.hash}`,
        action,
      });
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const handleRouterChange: ReceiverFunctionType = ({ data }) => {
    const type: 'push' | 'replace' = data?.type;
    const newPath = data?.selectedRoute;
    const currentPath = history.location.pathname;

    if (newPath) {
      // if only the search params are changing, then we should use replace to avoid creating a history event
      const useReplace = currentPath === new URL(newPath, window.location.href).pathname || type === 'replace';

      if (useReplace) {
        history.replace(newPath);
      } else {
        history.push(newPath);
      }
    }

    return respondSuccess();
  };

  const onLogout = () => {
    reportEvent('logged out', { segment: true, highlight: true });
    logout();
  };

  usePortal({
    commandbar: {
      onRouteChange: handleRouterChange,
    },
  });

  const handleEditorRoute: EventListener = (e) => {
    const customEvent = e as CustomEvent<any>;
    const detail = customEvent.detail as any;

    if (detail.type === 'route' && history.location.pathname !== detail.route_replacement) {
      if (detail.action === 'PUSH') {
        history.push(detail.route_replacement);
      } else if (detail.action === 'POP' || detail.action === 'REPLACE') {
        history.replace(detail.route_replacement);
      }
    }
  };

  // this is triggered elsewhere via window.CommandBar[_updateEditorRoute](details: TUpdateEditorRouteDetails)
  // which includes either details of a nudge or a questlist. In between, in sdk.tsx, we use create and
  // dispatch a custome event which gets listened for here as "updateEditorRoute"
  const handleUpdateEditorRouteFromCommandBar: EventListener = (e) => {
    const customEvent = e as CustomEvent<TUpdateEditorRouteDetails>;
    const detail = customEvent.detail as TUpdateEditorRouteDetails;

    if (detail.type === 'nudge') {
      history.push(
        `${editorRoutes.getNudgeRoute(detail.nudge)}/${detail.nudge.id}?${NUDGE_STEP_PARAM}=${detail.stepIndex}`,
      );
    } else if (detail.type === 'checklist') {
      history.push(`${editorRoutes.CHECKLIST_ROUTE}/${detail.checklistId}`);
    } else if (detail.type === 'route') {
      handleEditorRoute(e);
    }
  };

  React.useEffect(() => {
    window.addEventListener('updateEditorRoute', handleUpdateEditorRouteFromCommandBar);
    return () => {
      window.removeEventListener('updateEditorRoute', handleUpdateEditorRouteFromCommandBar);
    };
  }, []);

  React.useEffect(() => {
    if (isStudio) {
      document.addEventListener('updateEditorRoute', handleEditorRoute);

      return () => {
        document.removeEventListener('updateEditorRoute', handleEditorRoute);
      };
    }
  }, []);

  useEffect(() => {
    Sender.getHostUrl(isStudio)
      .then((result: any) => {
        const url = new URL(result.url);

        // if simulating a nudge in a new tab, redirect to that nudge's editor page
        if (url.searchParams.has(DEBUG_NUDGE_PARAM)) {
          const nudgeId = url.searchParams.get(DEBUG_NUDGE_PARAM);
          const nudge = nudges.find((n) => String(n.id) === String(nudgeId));
          if (nudge) {
            history.replace(`${editorRoutes.getNudgeRoute(nudge)}/${nudgeId}`);
          }
        }

        // Get page name from the editor_path param in query string
        const getEditorPage = (editorPathParam: string | null) => {
          // Routes can have the format of `?editor_path=<pageName>/<id>` so we want to strip out tokens after pageName if they exist
          return editorPathParam && editorPathParam.includes('/')
            ? editorPathParam.slice(0, editorPathParam.indexOf('/'))
            : editorPathParam;
        };

        // Parse route on component's mount
        // Syntax: url?editor_path=<tab-key>
        const { searchParams } = url;
        const editorPathParam = searchParams.get('editor_path');
        const editorPage = getEditorPage(editorPathParam);

        if (
          editorRoutes.isValidEditorPage(editorPage) &&
          searchParams.toString() !== new URLSearchParams(location.search).toString()
        ) {
          history.replace({
            pathname: editorPage,
            search: searchParams.toString(),
          });
        }
      })
      .finally(() => {
        setIsFetchingHostURL(false);
      });
  }, [isStudio, nudges]);

  useEffect(() => {
    Sender.isUserVerified().then((isEndUserVerified) =>
      setShowUserVerificationError(
        !isStudio && !isEndUserVerified && !!organization?.force_end_user_identity_verification,
      ),
    );
  }, []);

  // close all editing nudges when navigating away from a nudge page
  useEffect(() => {
    const handleRouteFromNudge = () => {
      if (
        !(
          location.pathname.includes(editorRoutes.SURVEYS_ROUTE) ||
          location.pathname.includes(editorRoutes.ANNOUNCEMENTS_ROUTE) ||
          location.pathname.includes(editorRoutes.PRODUCT_TOURS_ROUTE)
        )
      ) {
        Sender.closeNudgeMocks();
      }
    };

    handleRouteFromNudge();

    return () => {
      handleRouteFromNudge();
    };
  }, [location.pathname]);

  let content;
  if (isLoading) {
    content = (
      <div style={{ padding: 24, width: '100%' }}>
        <Skeleton active={true} paragraph={{ rows: 7 }} />
      </div>
    );
  } else {
    content = (
      <Container isStudio={isStudio}>
        <Prompt
          message={(_, action) => {
            if (action === 'REPLACE') {
              return true; // need to allow replace so we can change nudge steps from simulate toolbar without prompting. We could make this more specific if needed.
            }

            if (hasUnsavedChangesRef.current) {
              return '';
            } else {
              return true;
            }
          }}
          when={hasRequiredRole(user, 'contributor')}
        />
        {showUserVerificationError && (
          <Alert
            type="warning"
            message={
              <>
                <span>
                  Identity verification is not configured properly.&nbsp;
                  <a
                    style={{ textDecoration: 'underline' }}
                    href={`${process.env.REACT_APP_DASHBOARD_URL}/identity-verification`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </a>
                </span>
              </>
            }
            style={{
              padding: '8px 10px',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              border: 'none',
            }}
            showIcon
          />
        )}

        <Switch>
          {!isStudio && (
            <Route exact path="/">
              <Redirect to={`${extensionPath}`} />
            </Route>
          )}
          {<Route exact path="/blank"></Route>}

          <Route
            path={editorRoutes.SPOTLIGHT_ROUTE}
            render={(props) => <Commands {...props} setActiveCommand={setActiveCommand} />}
          />
          <Route path={`${editorRoutes.PAGES_ROUTE}/:commandId?`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <PagesOrActions type="page" setActiveCommand={setActiveCommand} activeCommand={activeCommand} />
            </div>
          </Route>
          <Route path={`${editorRoutes.ACTIONS_ROUTE}/:commandId?`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <PagesOrActions type="action" setActiveCommand={setActiveCommand} activeCommand={activeCommand} />
            </div>
          </Route>
          <Route path={`${editorRoutes.ANNOUNCEMENTS_ROUTE}/template/:templateId?`}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeTemplate type="announcement" />
            </div>
          </Route>
          <Route path={`${editorRoutes.PRODUCT_TOURS_ROUTE}/template/:templateId?`}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeTemplate type="product_tour" />
            </div>
          </Route>
          <Route path={`${editorRoutes.SURVEYS_ROUTE}/new`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NewNudgeMenu type="survey" />
            </div>
          </Route>
          <Route path={`${editorRoutes.SURVEYS_ROUTE}/template/:templateId?`}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeTemplate type="survey" />
            </div>
          </Route>
          <Route path={`${editorRoutes.PRODUCT_TOURS_ROUTE}/new`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NewNudgeMenu type="product_tour" />
            </div>
          </Route>
          <Route path={`${editorRoutes.PRODUCT_TOURS_ROUTE}/:nudgeId?`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeList type="product_tour" />
            </div>
          </Route>
          <Route path={`${editorRoutes.SURVEYS_ROUTE}/:nudgeId?`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeList type="survey" />
            </div>
          </Route>
          <Route path={`${editorRoutes.ANNOUNCEMENTS_ROUTE}/new`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NewNudgeMenu type="announcement" />
            </div>
          </Route>
          <Route path={`${editorRoutes.ANNOUNCEMENTS_ROUTE}/:nudgeId?`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NudgeList type="announcement" />
            </div>
          </Route>
          <Route path={`${editorRoutes.HELPHUB_ROUTE}/:page?`} component={HelpHubRecommendations} />
          <Route path={editorRoutes.COPILOT_PARENT_ROUTE} component={CopilotEditor} />
          <Route path={`${editorRoutes.CHECKLIST_ROUTE}/new`}>
            <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
              <LogoutHeader />
              <NewChecklistMenu />
            </div>
          </Route>
          <Route path={`${editorRoutes.CHECKLIST_ROUTE}/:checklistId?`}>
            <LogoutHeader />
            <ChecklistList />
          </Route>
          <Route exact path={editorRoutes.RELEASES_ROUTE}>
            <LogoutHeader />
            <ScrollContainer>
              <PaddingContainer>
                <Releases />
              </PaddingContainer>
            </ScrollContainer>
          </Route>
          <Route exact path={editorRoutes.AUDIENCES_ROUTE}>
            <LogoutHeader />
          </Route>
          <Route path={editorRoutes.DESIGN_ROUTE}>
            <LogoutHeader />
            <VisualStyleEditor />
          </Route>
          <Route path={editorRoutes.THEME_ROUTE}>
            <LogoutHeader />
            <Switch>
              <Route path={`${editorRoutes.THEME_ROUTE}/:themeId/:tab`}>
                <ThemeDetail />
              </Route>
              <Route>
                <ThemeList />
              </Route>
            </Switch>
          </Route>
          <Route path={'/tools'}>
            <>
              <LogoutHeader />
              <ScrollContainer>
                <PaddingContainer style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                  <FeatureAnnouncementCard
                    identifier="debugger"
                    title={<Row align="middle">Using debugging tools</Row>}
                    docsLink="https://www.commandbar.com/docs/developer/debugging-tools/"
                  >
                    <span>Inspect and debug your configuration with our built-in tools.</span>
                  </FeatureAnnouncementCard>
                  <Switch>
                    <Route exact path={editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.CHECKS}>
                      <DebugChecks />
                    </Route>
                    <Route exact path={editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.USER_PROPERTIES_INSPECTOR}>
                      <DebugUserProperties />
                    </Route>
                    <Route
                      exact
                      path={[
                        editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.METADATA_INSPECTOR,
                        editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.CONTEXT_INSPECTOR,
                      ]}
                    >
                      <DebugMetadata />
                    </Route>
                    <Route
                      exact
                      path={[
                        editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.METADATA_SIMULATOR,
                        editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.CONTEXT_SIMULATOR,
                      ]}
                    >
                      <DebugMetadataSimulator />
                    </Route>
                    <Route exact path={editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.SDK_LOGS}>
                      <DebugSDKLogs />
                    </Route>
                    <Route path={editorRoutes.TOOLS_ROUTE}>
                      <Redirect to={editorRoutes.TOOLS_ROUTE + '/' + DebuggerView.SDK_LOGS} />
                    </Route>
                  </Switch>
                </PaddingContainer>
              </ScrollContainer>
            </>
          </Route>
        </Switch>
      </Container>
    );
  }

  return (
    <>
      {!isStudio && <HijackUserBanner user={user} inExtension />}
      {!isStudio && (
        <Navbar
          isEditorOpen={true}
          activeRoute={location.pathname}
          onRouteChange={(route) => {
            history.push(route);
          }}
          isEditorLoggedIn={true}
          organization={organization}
        />
      )}
      {isStudio && <StyleToolbar />}
      {content}
      {billingProfile && (
        <Modal
          open={organization.disabled}
          closable={false}
          footer={
            <ModalFooterContainer>
              <div style={{ flex: 2 }}>
                <CmdButton
                  href={`https://commandbar.chilipiper.com/book/trial-queue-by-ownership?company=${organization.name}&Email=${user?.email}`}
                  fullWidth
                  variant="primary"
                  icon={<User01 width={16} height={16} />}
                >
                  Chat with the CommandBar Team
                </CmdButton>
              </div>
              <div style={{ flex: 1 }}>
                <CmdButton
                  fullWidth
                  variant="default"
                  type="button"
                  onClick={onLogout}
                  icon={<LogOut01 width={16} height={16} />}
                >
                  Logout
                </CmdButton>
              </div>
            </ModalFooterContainer>
          }
        >
          <div>
            <CmdTypography variant="primary" fontSize="lg" fontWeight="medium">
              Your trial of the {billingProfile.tier} plan has ended
            </CmdTypography>
            <CmdTypography variant="secondary">Your access to CommandBar has now been disabled.</CmdTypography>
            <CmdTypography variant="secondary">Don't worry though - your work is safe.</CmdTypography>
          </div>
        </Modal>
      )}
    </>
  );
};
