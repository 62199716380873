import React from 'react';
import debounce from 'lodash/debounce';
import produce from 'immer';

import { useReportEvent } from '../../hooks/useEventReporting';
import { useAppContext } from 'editor/src/AppStateContext';

import type {
  ICopilotPersonalityType,
  IOrganizationSettingsType,
  ICopilotFallback,
} from '@commandbar/internal/middleware/types';
import { Image } from '../components/DragUpload/ImageUploader';
import { IReportEventOptions } from '@commandbar/internal/util/eventReporting';
import { CopilotFallback } from '@commandbar/internal/middleware/copilotFallbacks';
import { cmdToast } from '@commandbar/design-system/cmd';

const useChatSettings = () => {
  const { organization, dispatch } = useAppContext();
  const { reportEvent } = useReportEvent();

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const defaultPersonality: ICopilotPersonalityType = { template: 'professional' };

  const promptModifier = React.useRef<IOrganizationSettingsType['chat_system_prompt_modifier']>('');
  const welcomeMessage = React.useRef<IOrganizationSettingsType['helphub_chat_welcome_message']>('');
  const fallbackMessage = React.useRef<IOrganizationSettingsType['helphub_chat_fallback_message']>('');
  const fallbackAction = React.useRef<IOrganizationSettingsType['helphub_chat_fallback_actions'][number] | null>(null);
  const suggestedSearches = React.useRef<IOrganizationSettingsType['helphub_manual_suggested_queries'] | null>(null);
  const copilotName = React.useRef<IOrganizationSettingsType['copilot_name']>('');
  const copilotAvatar = React.useRef<IOrganizationSettingsType['copilot_avatar']>('');
  const copilotAvatarV2 = React.useRef<IOrganizationSettingsType['copilot_avatar_v2']>(null);
  const copilotPersonality = React.useRef<IOrganizationSettingsType['copilot_personality']>(defaultPersonality);
  const negativeFeedbackMessage =
    React.useRef<IOrganizationSettingsType['copilot_negative_feedback_fallback_message']>('');
  const negativeFeedbackAction = React.useRef<
    IOrganizationSettingsType['copilot_negative_feedback_fallback_actions'][number] | null
  >(null);

  const copilotFallbacksRef = React.useRef<ICopilotFallback[]>([]);

  const setCopilotFallbacks = React.useCallback(
    (newFallbacks: ICopilotFallback[] | ((prev: ICopilotFallback[]) => ICopilotFallback[])) => {
      if (typeof newFallbacks === 'function') {
        copilotFallbacksRef.current = newFallbacks(copilotFallbacksRef.current);
      } else {
        copilotFallbacksRef.current = newFallbacks;
      }
      forceUpdate();
    },
    [],
  );

  React.useEffect(() => {
    const savedAction = organization?.helphub_chat_fallback_actions?.[0];
    const savedNegativeFeedbackAction = organization?.copilot_negative_feedback_fallback_actions?.[0];

    (async () => {
      promptModifier.current = organization?.chat_system_prompt_modifier ?? '';
      welcomeMessage.current = organization?.helphub_chat_welcome_message ?? '';
      fallbackMessage.current = organization?.helphub_chat_fallback_message ?? '';
      negativeFeedbackMessage.current = organization?.copilot_negative_feedback_fallback_message ?? '';
      suggestedSearches.current = organization?.helphub_manual_suggested_queries ?? [];
      copilotName.current = organization?.copilot_name.trim() ?? 'Copilot';
      copilotAvatar.current = organization?.copilot_avatar;
      copilotAvatarV2.current = organization?.copilot_avatar_v2;
      copilotPersonality.current = organization?.copilot_personality ?? defaultPersonality;

      fallbackAction.current = {
        cta: savedAction?.cta ?? '',
        action: savedAction?.action ?? {
          type: 'link',
          value: '',
          operation: 'blank',
        },
      };
      negativeFeedbackAction.current = {
        cta: savedNegativeFeedbackAction?.cta ?? '',
        action: savedNegativeFeedbackAction?.action ?? {
          type: 'link',
          value: '',
          operation: 'blank',
        },
      };

      forceUpdate();
    })();

    const loadCopilotFallbacks = async () => {
      if (organization?.id) {
        const fallbacks = await CopilotFallback.list();
        setCopilotFallbacks(fallbacks);
      }
    };
    loadCopilotFallbacks();
  }, []);

  const createOrUpdateDebounced = React.useMemo(() => {
    let saving = false;
    let pendingUpdates: Partial<IOrganizationSettingsType> = {};

    const doSave = async (eventName: string, eventData?: IReportEventOptions) => {
      try {
        if (saving) {
          return;
        }

        saving = true;

        const updatesToApply = { ...pendingUpdates };
        pendingUpdates = {};

        await dispatch.organization.updateSetting(updatesToApply);

        Object.keys(updatesToApply).forEach((setting) => {
          reportEvent(eventName, {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: `${updatesToApply[setting as keyof IOrganizationSettingsType]}`,
            ...eventData,
          });
        });
      } finally {
        saving = false;
        // If there are more pending updates, run doSave again
        if (Object.keys(pendingUpdates).length > 0) {
          doSave(eventName, eventData);
        }
      }
    };

    return (
      setting: keyof IOrganizationSettingsType,
      value: IOrganizationSettingsType[typeof setting],
      eventName: string,
      eventData?: IReportEventOptions,
    ) => {
      // accumulate updates to be saved
      pendingUpdates[setting] = value as any;
      debounce(() => doSave(eventName, eventData), 2700)();
    };
  }, [reportEvent, dispatch.organization.updateSetting]);

  const updateFallbackDebounced = React.useMemo(() => {
    const doUpdate = async (fallback: ICopilotFallback) => {
      try {
        const savedFallback = await CopilotFallback.update(
          fallback,
          () => {
            return;
          },
          console.error,
        );
        reportEvent('fallback edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${savedFallback.title} (ID: ${savedFallback.id})`,
          eventProps: { name: `${savedFallback.title} (ID: ${savedFallback.id})` },
        });
        cmdToast.success('Fallback updated');
      } catch (error) {
        console.error('Error updating fallback:', error);
        // Optionally, revert the local change if the server update fails
        setCopilotFallbacks((prev) => prev.map((f) => (f.id === fallback.id ? { ...fallback, ...f } : f)));
      }
    };

    return debounce(doUpdate, 2700);
  }, [reportEvent]);

  const addCopilotFallback = React.useCallback(
    async (fallback: Omit<ICopilotFallback, 'id'>) => {
      try {
        const savedFallback = await CopilotFallback.create(
          fallback as ICopilotFallback,
          () => {
            return;
          },
          console.error,
        );
        setCopilotFallbacks((prev) => [...prev, savedFallback]);
        reportEvent('fallback created', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${savedFallback.title} (ID: ${savedFallback.id})`,
          eventProps: { name: `${savedFallback.title} (ID: ${savedFallback.id})` },
        });
        return savedFallback;
      } catch (error) {
        console.error('Error creating fallback:', error);
        throw error;
      }
    },
    [setCopilotFallbacks, reportEvent],
  );

  const deleteCopilotFallback = async (toDeleteID: number) => {
    await CopilotFallback.delete(
      toDeleteID,
      undefined,
      () => {
        return;
      },
      (err: string) => console.error(err), // fixme make a toast
    );
    setCopilotFallbacks((oldList) => oldList.filter((v) => v.id !== toDeleteID));

    const deletedObj = copilotFallbacksRef.current.find((v) => v.id === toDeleteID);
    const payloadMessage = `${deletedObj?.title} (ID: ${toDeleteID})`;

    reportEvent('fallback deleted', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: payloadMessage,
      eventProps: {
        name: payloadMessage,
      },
    });
  };

  const updatePersonalitySettings = async (
    name: string,
    avatar: string,
    avatarV2: Image | null,
    personality: ICopilotPersonalityType,
    chatSystemPromptModifier: string,
  ) => {
    copilotName.current = name.trim() || 'Copilot';
    copilotAvatar.current = avatar;
    copilotAvatarV2.current = avatarV2;
    copilotPersonality.current = personality || defaultPersonality;
    promptModifier.current = chatSystemPromptModifier;

    forceUpdate();

    const newOrgSettings = {
      copilot_name: copilotName.current,
      copilot_avatar: copilotAvatar.current,
      copilot_avatar_v2: copilotAvatarV2.current,
      copilot_personality: copilotPersonality.current,
      chat_system_prompt_modifier: promptModifier.current,
    } as IOrganizationSettingsType;

    await dispatch.organization.updateSetting(newOrgSettings);
  };

  return {
    welcomeMessage: welcomeMessage.current,
    fallbackMessage: fallbackMessage.current,
    fallbackAction: fallbackAction.current,
    suggestedSearches: suggestedSearches.current,
    negativeFeedbackMessage: negativeFeedbackMessage.current,
    negativeFeedbackAction: negativeFeedbackAction.current,
    copilotName: copilotName.current,
    copilotAvatar: copilotAvatar.current,
    copilotAvatarV2: copilotAvatarV2.current,
    copilotPersonality: copilotPersonality.current,
    promptModifier: promptModifier.current,
    updatePersonalitySettings,
    copilotFallbacks: copilotFallbacksRef.current,

    actions: {
      welcomeMessage: {
        update: (draft: IOrganizationSettingsType['helphub_chat_welcome_message']): void => {
          welcomeMessage.current = draft;
          forceUpdate();
          createOrUpdateDebounced('helphub_chat_welcome_message', draft, 'chat welcome message updated');
        },
      },
      fallbackMessage: {
        update: (draft: IOrganizationSettingsType['helphub_chat_fallback_message']): void => {
          fallbackMessage.current = draft;
          forceUpdate();
          createOrUpdateDebounced('helphub_chat_fallback_message', draft, 'chat fallback message updated');
        },
      },
      fallbackAction: {
        update: (recipe: (draft: IOrganizationSettingsType['helphub_chat_fallback_actions'][number]) => void): void => {
          fallbackAction.current = produce(
            fallbackAction.current,
            recipe,
          ) as IOrganizationSettingsType['helphub_chat_fallback_actions'][number];

          forceUpdate();
          createOrUpdateDebounced(
            'helphub_chat_fallback_actions',
            [fallbackAction.current],
            'chat fallback action updated',
            {
              payloadMessage: `${fallbackAction.current.cta}`,
            },
          );
        },
      },
      suggestedSearches: {
        update: (draft: IOrganizationSettingsType['helphub_manual_suggested_queries']): void => {
          suggestedSearches.current = draft;
          forceUpdate();
          createOrUpdateDebounced('helphub_manual_suggested_queries', draft, 'chat suggested searches updated');
        },
      },
      negativeFeedbackMessage: {
        update: (draft: IOrganizationSettingsType['copilot_negative_feedback_fallback_message']): void => {
          negativeFeedbackMessage.current = draft;
          forceUpdate();
          createOrUpdateDebounced(
            'copilot_negative_feedback_fallback_message',
            draft,
            'chat negative feedback message updated',
          );
        },
      },
      negativeFeedbackAction: {
        update: (
          recipe: (draft: IOrganizationSettingsType['copilot_negative_feedback_fallback_actions'][number]) => void,
        ): void => {
          negativeFeedbackAction.current = produce(
            negativeFeedbackAction.current,
            recipe,
          ) as IOrganizationSettingsType['copilot_negative_feedback_fallback_actions'][number];

          forceUpdate();
          createOrUpdateDebounced(
            'copilot_negative_feedback_fallback_actions',
            [negativeFeedbackAction.current],
            'chat negative feedback action updated',
            {
              payloadMessage: `${negativeFeedbackAction.current.cta}`,
            },
          );
        },
      },
      copilotFallbacks: {
        update: (fallback: ICopilotFallback) => {
          setCopilotFallbacks(
            produce((draft) => {
              const index = draft.findIndex((f) => f.id === fallback.id);
              if (index !== -1) {
                draft[index] = fallback;
              }
            }),
          );
          updateFallbackDebounced(fallback);
        },

        delete: deleteCopilotFallback,
        create: addCopilotFallback,
      },
    },
  };
};

export default useChatSettings;
