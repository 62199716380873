import React from 'react';
import Portal, { FRAME_TYPE, ReceiverFunctionType } from './Portal';
import Logger from '../util/Logger';

export const respondSuccess = (response?: any): Promise<[boolean, any]> => {
  return Promise.resolve([true, response]);
};

export const respondError = (error: string): Promise<[boolean, any]> => {
  return Promise.resolve([false, { error }]);
};

const usePortal = (receiver: Partial<Record<FRAME_TYPE, Record<string, ReceiverFunctionType>>>) => {
  const messageHandler = async (e: MessageEvent<any>) => {
    const { isValid, sender, data } = Portal.preMessageHandler(e);

    if (!isValid) {
      Logger.info(`Invalid portal message: ${e}`, e);
      return;
    }
    if (!sender) {
      Logger.info(`Invalid portal sender: ${e}`);
      return;
    }

    if (!data) {
      Logger.info(`Invalid portal data: ${e}`);
      return;
    }

    if (receiver.hasOwnProperty(sender) && receiver[sender]?.hasOwnProperty(data.action)) {
      try {
        const result = await receiver[sender]?.[data.action]({
          e,
          data,
        });

        if (result) {
          const [status, response] = result;
          Portal.response(e, status, response);
        } else {
          Portal.response(e, false);
        }
      } catch (err) {
        Logger.error(err);
        Portal.response(e, false);
      }
    }
    return;
  };

  React.useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [receiver]);

  return;
};

export default usePortal;
