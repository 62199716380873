import { getTriggerKey } from '@commandbar/internal/util/operatingSystem';
import { useEffect } from 'react';

/**
 * Custom React hook that triggers a callback function when the user presses Ctrl/Cmd + S.
 *
 * @param {Function} onModS - The callback function to be executed on Ctrl/Cmd + S.
 */
export const useModS = (onModS: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const triggerKey = getTriggerKey(event);
      if (triggerKey && event.key === 's') {
        event.preventDefault();
        onModS();
      }
    };

    // Adding the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onModS]); // Dependency array to rebind the event if the callback changes
};
