import * as t from 'io-ts';

import { createObject, listObject } from './generics';

export const HelpDocSyncLogMessageV = t.type({ ts: t.string, type: t.string, message: t.array(t.string) });

export const HelpDocsSyncV = t.intersection(
  [
    t.type({
      id: t.number,
      integration: t.number,
      scheduled: t.boolean,
    }),
    t.partial({
      commands: t.array(t.number),
      started: t.union([t.string, t.null]),
      ended: t.union([t.string, t.null]),
      error: t.union([t.string, t.null]),
      log: t.array(t.union([t.array(t.any), HelpDocSyncLogMessageV])),
    }),
  ],
  'HelpDocsSync',
);

export class HelpDocsSync {
  public static create = createObject(HelpDocsSyncV, HelpDocsSyncV, 'integrations/syncs');
  public static list = listObject(HelpDocsSyncV, 'integrations/syncs');
}
