import { useEffect, useState } from 'react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { AnalyticsStrikes, AnalyticsStrikesData } from '@commandbar/internal/middleware/analytics/strikes';
import * as Sentry from '@sentry/react';
import { useCSVDownload } from '../../useCSVDownload';
import { getStrikesDemoData } from '../../analyticDemoData';

type UseAnalyticsStrikesOptions = {
  threshold?: number;
  showUnpublished?: boolean;
};

const useAnalyticsStrikes = ({ threshold, showUnpublished }: UseAnalyticsStrikesOptions) => {
  const [data, setData] = useState<AnalyticsStrikesData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();
  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'strikes',
    download: () =>
      AnalyticsStrikes.download({
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        include_admin_data: includeAdminData.toString(),
        show_unpublished: String(!!showUnpublished),
      }),
  });

  const fetchData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsStrikes.read({
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
      show_unpublished: String(!!showUnpublished),
      ...(threshold && { threshold: threshold.toString() }),
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      setData(getStrikesDemoData());
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [timeFilterRange, isDemoData, showUnpublished, includeAdminData]);

  return {
    data,
    isLoading,
    error,
    fetchData,
    downloadCsv,
    isDownloading,
  };
};

export default useAnalyticsStrikes;
