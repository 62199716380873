/** @jsx jsx  */
import React from 'react';
import { jsx, css, keyframes } from '@emotion/core';
import {
  Link01,
  Lightning01,
  CheckSquareBroken,
  NotificationBox,
  ArrowNarrowRight,
  Check,
} from '@commandbar/design-system/icons/react';
import { useStyles } from 'products/helphub/components/useStyles';

type ActionButtonVariant = 'primary' | 'secondary' | 'continuation';

const icons = {
  Link01,
  Lightning01,
  CheckSquareBroken,
  NotificationBox,
  ArrowNarrowRight,
  Check,
} as const;

export type ActionButtonProps = {
  preview?: boolean;
  variant?: ActionButtonVariant;
  children: React.ReactNode;
  icon?: keyof typeof icons;
  disabled?: boolean;
  disablePreviewClick?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

const slide = keyframes`
0% {
  background-position: 100% 0%;
}
100% {
  background-position: -200% 0%;
}
`;

export const ActionButton: React.FC<ActionButtonProps> = ({
  preview = false,
  variant = 'primary',
  children,
  icon,
  style,
  disabled,
  disablePreviewClick = false,
  ...rest
}) => {
  const styles = useStyles(preview);

  const Icon = icon ? icons[icon] : null;

  return (
    <button
      disabled={disabled || (preview && disablePreviewClick)}
      css={[
        styles.actionButtons,
        styles.actionButtons[disabled ? 'disabled' : variant],
        preview && disablePreviewClick && `cursor: default;`,
        variant === 'primary' &&
          css`
            animation: ${slide} 3s linear infinite;
          `,
      ]}
      style={style}
      {...rest}
    >
      <div
        style={{
          maxWidth: '100%',
          fontSize: '14px',
          fontWeight: 500,
          letterSpacing: 0,
          lineHeight: '16px',
          position: 'relative',
          textAlign: 'left',
          color: styles.actionButtons[variant].color,
        }}
      >
        {children}
      </div>

      {Icon && <Icon width={14} height={14} style={{ color: 'currentcolor' }} />}
    </button>
  );
};

export default ActionButton;
