/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IAIMessageType, ICommandType, ISourceDocType } from '@commandbar/internal/middleware/types';
import { useStore } from 'shared/util/hooks/useStore';
import { emptyGlobalStore } from 'shared/store/global-store';
import StyledInlineButton from '@commandbar/internal/client/themesV2/components/shared/StyledInlineButton';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import StyledIconContainer from '@commandbar/internal/client/themesV2/components/shared/StyledIconContainer';
import { ArrowUpRight, ChevronDown, ChevronUp } from '@commandbar/design-system/icons/react';
import { useRouter } from '@commandbar/commandbar/shared/util/router';
import { useAction } from '@commandbar/commandbar/shared/util/hooks/useAction';
import { getContentType } from '@commandbar/internal/middleware/command';
import { isCopilotOnlyMode } from 'products/helphub/service-selectors';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import { useChatState } from '../../store/useChatState';

type SourceProps = {
  preview: boolean;
  message: IAIMessageType;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-gap);
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: var(--layout-gap);
  justify-content: space-between;
  align-items: center;
`;

const LinksContainer = styled.div`
  display: flex;
  padding: var(--layout-padding);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--layout-radius-card);
  background: var(--background-primary);
`;

const Link = styled.button`
  display: flex;
  max-width: 100%;
  width: fit-content;
  height: var(--form-control-height);
  align-items: center;
  gap: 2px;
  border-radius: var(--layout-radius-button);
  border: none;
  background: none;
  cursor: pointer;

  color: var(--content-link);

  /* label/base/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);

  &:hover {
    color: var(--content-link-hover);
  }

  &:focus {
    box-shadow: 0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color);
  }

  &:focus-visible {
    outline: none;
  }
`;

const LinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: underline;
`;

export const BotMessageFooter: React.FC<SourceProps> = (props) => {
  const { preview, message } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/no-empty-function
  const navigate = !preview ? useRouter()?.navigate : () => {};
  const themeV2 = useThemeV2Context();
  const chatState = useChatState();

  const [expanded, setExpanded] = React.useState(_.organization?.copilot_expand_read_more_by_default);

  const getURL = (source: ISourceDocType) => {
    const createHashAnchor = (heading: string) => {
      return heading
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, '-') // Replace spaces with dashes
        .replace(/[^\w-]+/g, ''); // Remove non-alphanumeric characters except dashes
    };

    const url = source.command.template.type === 'helpdoc' ? source.command.template.value : undefined;
    if (url && source.passage_heading) {
      return `${url}#${createHashAnchor(source.passage_heading)}`;
    }
    return url;
  };

  const setHubDocHeading = useAction((_, heading: string | null) => {
    _.helpHub.hubDocHeading = heading;
  });

  const handleSourceClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, source: ISourceDocType) => {
    const type = getContentType(source.command);
    if (preview) {
      if (type) {
        let contentEditUrl = '';

        switch (type) {
          case 'source':
            contentEditUrl = `/content/sources/${source.command.integration?.id}/?id=${source.command.id}`;
            break;
          case 'answer':
          case 'file':
          case 'video':
            contentEditUrl = `/content/${type}s/?id=${source.command.id}`;
            break;
          default:
            break;
        }

        window.open(contentEditUrl, '_blank');
      }

      return;
    }

    const url = getURL(source);

    Track.copilot.sourceClicked(chatState.chatID, message.uuid || '', url || '');

    if ((isCopilotOnlyMode(_) && !isStudioPreview()) || !_.organization?.helphub_enabled) {
      // TODO: consolidate this further to maybe execute command directly, but some logic required to make it work with the updated url that includes the anchor of the source passage
      if (url) {
        if (source.command.template.operation === 'router' && _.callbacks['commandbar-router']) {
          _.callbacks['commandbar-router'](source.command.template.value);
        } else {
          window.open(url, '_blank');
        }
      } else
        switch (type) {
          case 'answer':
          case 'file':
          case 'video':
            navigate({ page: 'docs', params: { docId: source.command.id } });
            break;
          default:
            break;
        }
      return;
    }

    if ((e.metaKey || e.ctrlKey) && url) {
      window.open(url, '_blank');
    } else {
      navigate({ page: 'docs', params: { docId: source.command.id } });

      if (source.passage_heading && source.passage_heading !== source.command.text) {
        setHubDocHeading(source.passage_heading);
      }
    }
  };

  let usedCommands: ICommandType['id'][] = [];
  const filteredSources = message.value.source_docs.filter((source) => {
    if (usedCommands.includes(source.command.id)) {
      return false;
    }
    usedCommands = usedCommands.concat(source.command.id);
    return !source.command.training_only && !source.command.integration?.helphub_view_article_button_hidden;
  });

  return filteredSources.length === 0 ? null : (
    <Container>
      <ButtonContainer>
        <StyledInlineButton themeV2={themeV2} onClick={() => setExpanded((expanded) => !expanded)}>
          Read more ({filteredSources.length}){expanded ? <ChevronUp /> : <ChevronDown />}
        </StyledInlineButton>
      </ButtonContainer>
      {expanded && filteredSources.length > 0 && (
        <LinksContainer>
          {filteredSources.map((source) => (
            <Link key={source.command.id} onClick={(e) => handleSourceClick(e, source)}>
              <LinkText>{source.command.text}</LinkText>
              <StyledIconContainer>
                <ArrowUpRight />
              </StyledIconContainer>
            </Link>
          ))}
        </LinksContainer>
      )}
    </Container>
  );
};

export default BotMessageFooter;
