import CodeMirror from 'codemirror';

const checkBeginsWithPrefix = (selection: string, prefix: string): boolean => {
  return selection.slice(0, prefix.length) === prefix;
};

const checkEndsWithSuffix = (selection: string, suffix: string): boolean => {
  return selection.slice(-suffix.length) === suffix;
};

const checkShouldUndoFormatting = (selection: string, prefix: string, suffix: string): boolean => {
  return checkBeginsWithPrefix(selection, prefix) && checkEndsWithSuffix(selection, suffix);
};

const undoFormatting = (selection: string, prefix: string, suffix: string): string => {
  return selection.slice(prefix.length, selection.length - suffix.length);
};

const applyPrefixAndSuffix = (selection: string, prefix: string, suffix: string): string => {
  if (checkShouldUndoFormatting(selection, prefix, suffix)) {
    return undoFormatting(selection, prefix, suffix);
  }
  return `${prefix}${selection}${suffix}`;
};

export type TFormattingType = 'bold' | 'italic';

export const applyFormatting = (editor: CodeMirror.Editor, type: TFormattingType): void => {
  const doc = editor.getDoc();
  const selection = doc.getSelection();

  if (selection.length === 0) return;

  const formattedSelection = ((): string => {
    switch (type) {
      case 'bold': {
        return applyPrefixAndSuffix(selection, '**', '**');
      }
      case 'italic': {
        return applyPrefixAndSuffix(selection, '_', '_');
      }
      default: {
        return selection;
      }
    }
  })();

  doc.replaceSelection(formattedSelection);
};
