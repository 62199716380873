import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { ExtensionStore, UpdateNudgeStepPayload } from '@commandbar/internal/client/extension/shared';
import { StartClickRecorderPayload, StopClickRecorderPayload } from '@commandbar/internal/client/extension/recorder';
import { StartPreviewPayload, StopPreviewPayload } from '@commandbar/internal/client/extension/preview';
import { ExtensionMessage } from './message';

// Recorder Messagges, entry/exit points for the recorder
const StartClickRecorderMessage = new ExtensionMessage<StartClickRecorderPayload>('START_CLICK_RECORDER');
const StopClickRecorderMessage = new ExtensionMessage<StopClickRecorderPayload>('STOP_CLICK_RECORDER');

// Preview Messages, entry/exit points for preview
const StartPreviewMessage = new ExtensionMessage<StartPreviewPayload>('START_PREVIEW');
const StopPreviewMessage = new ExtensionMessage<StopPreviewPayload>('STOP_PREVIEW');

// Shared Messages for signaling intermediary state updates
const UpdateNudgeStepMessage = new ExtensionMessage<UpdateNudgeStepPayload>('UPDATE_NUDGE_STEP');

// General Extension Messages
const GetExtensionStateMessage = new ExtensionMessage<void, ExtensionStore>('GET_EXTENSION_STATE');
const GetVersionMessage = new ExtensionMessage<void, string>('GET_VERSION');
const BootCommandBar = new ExtensionMessage<{ orgId: string; jsUrl: string; cssUrl: string }>('BOOT_COMMANDBAR');
const ShareExtensionStatusMessage = new ExtensionMessage<{ preview: boolean; recorder: boolean }>('EXTENSION_STATUS');
const ShareStudioAuthMessage = new ExtensionMessage<{ token: string; organizationId: IOrganizationType['id'] } | null>(
  'SHARE_STUDIO_AUTH',
);

const SetDefaultOrganizationIdMessage = new ExtensionMessage<{ defaultOrganizationId: string }>(
  'SET_DEFAULT_ORGANIZATION_ID',
);

// TODO: Figure out good generics for the Forwarding/Receiving middleware
const ForwardToIsolatedWorldMessage = new ExtensionMessage<{ message: string; data: any }, void>(
  'FORWARD_TO_ISOLATED_WORLD',
);

export type { ExtensionMessageListener } from './message';
export {
  ForwardToIsolatedWorldMessage,
  GetExtensionStateMessage,
  GetVersionMessage,
  BootCommandBar,
  ShareExtensionStatusMessage,
  ShareStudioAuthMessage,
  StartClickRecorderMessage,
  StartPreviewMessage,
  StopClickRecorderMessage,
  StopPreviewMessage,
  UpdateNudgeStepMessage,
  SetDefaultOrganizationIdMessage,
};
