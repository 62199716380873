import React from 'react';

interface CmdEmptyGraphProps {
  width?: number | string;
  height?: number | string;
  className?: string;
}

export const CmdEmptyGraph = ({ height, width, className }: CmdEmptyGraphProps) => {
  return (
    <div
      className={`flex items-end justify-center gap-1 px-2 ${className}`}
      style={{
        width,
        height,
        borderBottom: '1px solid #D0D1D4',
      }}
    >
      <span
        className="bg-gray200 rounded-t-sm animate-transform-height-one w-full h-full"
        style={{
          background: 'linear-gradient(180deg, rgba(186, 187, 192, 0.20) 0%, #D0D1D4 100%)',
        }}
      />
      <span
        className="bg-gray200 rounded-t-sm animate-transform-height-two w-full h-full"
        style={{
          background: 'linear-gradient(180deg, rgba(142, 145, 152, 0.20) 0%, #8E9198 100%)',
        }}
      />
      <span
        className="bg-gray200 rounded-t-sm animate-transform-height-three w-full h-full"
        style={{
          background: 'linear-gradient(180deg, rgba(142, 145, 152, 0.20) 0%, #BABBC0 100%)',
        }}
      />
    </div>
  );
};
