import { useState, useEffect, useCallback } from 'react';

import {
  HubSpotIntegration,
  HubSpotListsResponse,
} from '@commandbar/internal/middleware/integrations/hubSpotIntegration';
import { Form, Select } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';
import Logger from '@commandbar/internal/util/Logger';

import Spinner from '../../../table/Spinner';

import type { HubSpotContext } from './HubSpotIntegrationPage';
import { CmdSwitch, CmdButton, cmdToast } from '@commandbar/design-system/cmd';

const useFetchHubSpotLists = (listsFromConfig: HubSpotContext['lists']) => {
  const [isLoading, setIsLoading] = useState(false);
  const [lists, setLists] = useState<{
    all: HubSpotListsResponse['lists'];
    preSelected: HubSpotListsResponse['lists'];
  }>();

  useEffect(() => {
    setIsLoading(true);
    HubSpotIntegration.readLists().then(({ lists }) => {
      setLists({ all: lists, preSelected: lists.filter(({ value }) => Object.keys(listsFromConfig)?.includes(value)) });
      setIsLoading(false);
    });
  }, [listsFromConfig]);

  return { isLoading, allLists: lists?.all, preSelectedLists: lists?.preSelected };
};

const useHandleFormSubmit = (handleNext: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(
    ({ send_events_to_hubspot, lists }: { send_events_to_hubspot: boolean; lists: HubSpotListsResponse['lists'] }) => {
      setIsSubmitting(true);
      HubSpotIntegration.syncContacts({
        send_events_to_hubspot,
        lists: lists.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {}),
      })
        .then(() => {
          cmdToast.success('HubSpot integration configured successfully.');
          handleNext();
        })
        .catch((err) => {
          Logger.error(err);
          cmdToast.error('Failed to configure HubSpot integration.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [handleNext],
  );

  return { isSubmitting, onSubmit };
};

interface HubSpotConfigurationStepProps {
  handleNext: () => void;
  context: HubSpotContext;
}

export const HubSpotConfigurationStep = ({ handleNext, context }: HubSpotConfigurationStepProps) => {
  const { isLoading, allLists, preSelectedLists } = useFetchHubSpotLists(context.lists);
  const { isSubmitting, onSubmit } = useHandleFormSubmit(handleNext);
  const [form] = Form.useForm<{
    send_events_to_hubspot: boolean;
    lists: HubSpotListsResponse['lists'];
  }>();

  if (isLoading) {
    return (
      <Spinner>
        <Loading01 width={32} />
      </Spinner>
    );
  }

  return (
    <Form
      id="configureHubSpot"
      key="configureHubSpot"
      layout="vertical"
      name="createIntegration"
      form={form}
      onFinish={onSubmit}
      size="middle"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
      }}
    >
      <Form.Item
        validateTrigger="onSubmit"
        initialValue={context.send_events_to_hubspot}
        key="send_events_to_hubspot"
        label="Send Command AI events to HubSpot"
        name="send_events_to_hubspot"
        help="Use HubSpot as a data destination. Measure the impact of your Bar, Nudges, Checklists, and more - all within HubSpot."
      >
        <CmdSwitch offLabel="Do not send" onLabel="Send" defaultChecked={context.send_events_to_hubspot} />
      </Form.Item>

      <Form.Item
        key="lists"
        validateTrigger="onSubmit"
        label="HubSpot lists to sync"
        name="lists"
        initialValue={preSelectedLists}
        help="Target experiences to users linked to specific lists in HubSpot."
      >
        <Select
          size="large"
          mode="multiple"
          options={allLists}
          optionFilterProp="label"
          maxTagCount="responsive"
          placeholder="Select lists"
          labelInValue
          allowClear
        />
      </Form.Item>

      <CmdButton
        variant="primary"
        type="submit"
        form="configureHubSpot"
        loading={isSubmitting}
        style={{ alignSelf: 'flex-end' }}
      >
        Configure
      </CmdButton>
    </Form>
  );
};

export default HubSpotConfigurationStep;
