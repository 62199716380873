export type ISlackType =
  | 'waitlist-subscription'
  | 'documentation-usage'
  | 'documentation-rating'
  | 'editor_changes'
  | 'quickwizard-usage'
  | 'notifs_self_serve'
  | 'quickwizard-setup-search-usage'
  | 'quickwizard-setup-search-completed'
  | 'quickwizard-bulk-create-navigation-usage'
  | 'quickwizard-bulk-create-navigation-completed'
  | 'quickwizard-setup-router-usage'
  | 'quickwizard-setup-router-completed'
  | 'quickwizard-add-placeholder-usage'
  | 'quickwizard-add-placeholder-completed'
  | 'history-usage'
  | 'history-revert-usage';

interface PayloadType {
  type: ISlackType;
}
async function logToSlack<T extends PayloadType>(payload: T) {
  await fetch(`${process.env.REACT_APP_API_URL}/log/`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export async function requestToSlack<T extends PayloadType>(payload: T) {
  await fetch(`${process.env.REACT_APP_API_URL}/log/`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ ...payload, type: 'dashboard-usage' }),
  }).then((r) => console.log(r));
}

export default logToSlack;
