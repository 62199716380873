import React from 'react';

import { INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import { CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO } from '../conditions/categories';
import ConditionGroupEditor from '../conditions/ConditionGroupEditor';
import AudienceSize from './AudienceSize';
import { CmdInput } from '@commandbar/design-system/cmd';

export const EditingRule = ({
  initialRule,
  onRuleChange,
  allowAddingAndRemovingRules = true,
}: {
  initialRule: INamedRule;
  onSave: (rule: INamedRule) => Promise<void>;
  onRuleChange?: (rule: INamedRule) => void;
  isDuplicateName: (rule: INamedRule) => boolean;
  allowAddingAndRemovingRules?: boolean;
}) => {
  const [rule, setRule] = React.useState<INamedRule>(initialRule);

  React.useEffect(() => {
    onRuleChange && onRuleChange(rule);
  }, [rule, onRuleChange]);

  const setName = (name: string) => setRule((rule) => rule && { ...rule, name });

  return (
    <div>
      <div style={{ display: 'flex', columnGap: 8 }}>
        <CmdInput
          autoFocus
          fullWidth
          label="Audience name"
          placeholder='"Trial users", "Admins"'
          value={rule.name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div style={{ margin: '12px 0' }}>
        <AudienceSize expr={rule.expression} />
      </div>

      <ConditionGroupEditor
        onChange={(expr) => {
          setRule((rule) => ({ ...rule, expression: expr }));
        }}
        expr={rule.expression}
        disabled={!allowAddingAndRemovingRules}
        includeCategories={CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO}
        excludeConditionTypes={['audience', 'named_rule']}
      />
    </div>
  );
};

export default EditingRule;
