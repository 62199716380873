import React, { useState, useEffect } from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { VariantProps } from 'class-variance-authority';
import { cn } from '../util';
import { toggleVariants } from './styles';

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root ref={ref} className={cn('flex items-center justify-center gap-1', className)} {...props}>
    {children}
  </ToggleGroupPrimitive.Root>
));

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> & VariantProps<typeof toggleVariants>
>(({ className, children, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Item ref={ref} className={cn(toggleVariants(), className)} {...props}>
      {children}
    </ToggleGroupPrimitive.Item>
  );
});

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;
interface ITabs {
  label: React.ReactNode;
  key: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface CmdToggleButtonProps<T = string> {
  onChange?: (value: string) => void;
  disabled?: boolean;
  tabs: ITabs[];
  defaultActiveKey?: T;
  activeKey?: T;
}

export const CmdToggleButton = ({
  defaultActiveKey,
  onChange,
  tabs,
  disabled = false,
  activeKey,
}: CmdToggleButtonProps) => {
  const [internalActiveKey, setInternalActiveKey] = useState<string | undefined>(defaultActiveKey || tabs[0].key);

  useEffect(() => {
    if (activeKey !== undefined) {
      setInternalActiveKey(activeKey);
    }
  }, [activeKey]);

  const handleValueChange = (value: string) => {
    if (value === internalActiveKey || value === '') return;

    if (activeKey === undefined) {
      setInternalActiveKey(value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <ToggleGroup
      type="single"
      disabled={disabled}
      value={activeKey !== undefined ? activeKey : internalActiveKey}
      onValueChange={handleValueChange}
      className="flex flex-col gap-xxs justify-start"
    >
      {tabs.map((tab) => (
        <ToggleGroupItem key={tab.key} value={tab.key} onClick={tab.onClick}>
          {tab.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
