import React from 'react';

import { isValidStartPageUrl } from '@commandbar/internal/client/share_links';
import { type TriggerableEntity, isNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { type INudgeType, isAnchorableStep } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { AudienceSelector, ExpressionSelector } from './Audience';
import { PageTargeting } from './Page';
import { Review } from './Review';
import { Trigger } from './Trigger';
import { TargetingContainer } from './styled';

export enum Panel {
  Audience = 0,
  PageTargeting = 1,
  Trigger = 2,
  Review = 3,
}

export const hasAnchoredStep = (dirty: TriggerableEntity): dirty is INudgeType =>
  isNudge(dirty) && dirty.steps.some(isAnchorableStep);

type Props<T> = {
  dirty: T;
  handleOnChange: (changes: T) => void;
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
};

export const Targeting = <T extends TriggerableEntity>({ dirty, handleOnChange, onStartClickRecorder }: Props<T>) => {
  const { flags } = useAppContext();
  const startPageUrlError = dirty.share_page_url_or_path
    ? isValidStartPageUrl(dirty.share_page_url_or_path, dirty.audience ? dirty.show_expression : null) // do not evaluate on page targeting if pre-audience nudge because the page targeting condition could also include other condition types
    : { isValid: true, error: '' };

  return (
    <TargetingContainer>
      {dirty.audience ? (
        <AudienceSelector
          audience={dirty.audience}
          handleAudienceChange={(audience) => {
            handleOnChange({ ...dirty, audience });
          }}
        />
      ) : (
        <ExpressionSelector
          showExpression={dirty.show_expression}
          handleShowExpressionChange={(expr) => {
            handleOnChange({ ...dirty, show_expression: expr });
          }}
          onStartClickRecorder={onStartClickRecorder}
          anchorableSteps={isNudge(dirty) ? dirty.steps.filter(isAnchorableStep) : undefined}
        />
      )}

      {dirty.audience && (
        <PageTargeting
          dirty={dirty}
          handleOnChange={handleOnChange}
          onStartClickRecorder={onStartClickRecorder}
          startPageUrlError={startPageUrlError}
        />
      )}

      <Trigger dirty={dirty} handleOnChange={handleOnChange} />

      {flags['release-audience-refinement'] && <Review dirty={dirty} />}
    </TargetingContainer>
  );
};
