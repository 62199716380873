import React from 'react';
import styled, { ComponentSelector } from '@emotion/styled';
import { Table } from '@commandbar/design-system/components/antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragHandle } from 'react-icons/md';

const StyledTable = styled(Table)`
  .sortable-table-drag-handle {
    visibility: hidden;
  }

  tr:hover .sortable-table-drag-handle {
    visibility: visible;
    font-size: 10px;
  }

  td {
    border-bottom: none !important;
  }

  td:nth-of-type(2) {
    padding-left: 0;
  }
` as unknown as typeof Table & ComponentSelector; // h/t https://github.com/emotion-js/emotion/issues/2342#issuecomment-817267997

const DragHandle = SortableHandle(() => (
  <MdDragHandle className="sortable-table-drag-handle" style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const Container = SortableContainer((props: any) => <div {...props} />);

const DragableBodyRow = ({ index, className, style, ...restProps }: any) => {
  return <SortableItem index={restProps['data-row-key']} {...restProps} />;
};

type IProps<T extends object> = {
  onSort: (oldIndex: number, newIndex: number) => void;
} & React.ComponentProps<typeof Table<T>> &
  Required<Pick<React.ComponentProps<typeof Table<T>>, 'columns' | 'dataSource'>>;

const SortableTable = <T extends object>({ dataSource, columns, onSort, ...tableProps }: IProps<T>) => {
  const onSortEnd = React.useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        onSort(oldIndex, newIndex);
      }
    },
    [onSort],
  );

  // If the datasource has more than one item, add a sortable column
  const columnsWithSort = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => (dataSource.length > 1 ? <DragHandle /> : <div />),
    },
    ...columns,
  ];

  return (
    <Container
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd} /*helperContainer={() => container}    TODO--reenable this */
    >
      <StyledTable<T>
        pagination={false}
        dataSource={dataSource}
        columns={columnsWithSort}
        rowKey="index"
        components={{
          body: {
            row: DragableBodyRow,
          },
        }}
        {...tableProps}
      />
    </Container>
  );
};

export default SortableTable;

/************************************* SORTABLE TABLE UTILS ***********************************/
export const getNewSortkey = (currentSortKey: number, oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
  const direction: 'up' | 'down' = newIndexOfMovedObj > oldIndexOfMovedObj ? 'down' : 'up';
  if (currentSortKey === oldIndexOfMovedObj) return newIndexOfMovedObj; // item moved
  const isBetween =
    (currentSortKey <= newIndexOfMovedObj && currentSortKey > oldIndexOfMovedObj) ||
    (currentSortKey >= newIndexOfMovedObj && currentSortKey < oldIndexOfMovedObj);
  if (!isBetween) return currentSortKey; // not impacted by the change

  switch (direction) {
    case 'up':
      // an item moved ahead of this item. Move this item down;
      return currentSortKey + 1;
    case 'down':
      return currentSortKey - 1;
  }
};
