import styled from '@emotion/styled';
import React from 'react';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { BookOpen01, BookOpen02 } from '@commandbar/design-system/icons/react';
import Icon from '@commandbar/internal/client/Icon';
import type { ICommandType } from '@commandbar/internal/middleware/types';
import StyledHelpDocCard from '../shared/StyledHelpDocCard';

const StyledDocumentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--layout-padding) var(--layout-padding) var(--layout-padding);
`;

const TopBlockDocumentContainer = styled(StyledDocumentContainer)`
  padding: 0px;
`;

const FullHeightDocumentContainer = styled(StyledDocumentContainer)`
  padding: 0px;
`;

const StyledPlaceholder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f7f4ff 0%, #d5caff 100%);
  border-radius: var(--layout-radius-card);
`;

const TopBlockPlaceholder = styled(StyledPlaceholder)`
  border-radius: var(--layout-radius-card) var(--layout-radius-card) 0 0;
`;

const FullHeightPlaceholder = styled(StyledPlaceholder)`
  border-radius: 0 var(--layout-radius-card) var(--layout-radius-card) 0;
`;

const components = {
  'top-block': {
    DocumentContainer: TopBlockDocumentContainer,
    Placeholder: TopBlockPlaceholder,
  },
  'full-height': {
    DocumentContainer: FullHeightDocumentContainer,
    Placeholder: FullHeightPlaceholder,
  },
  default: {
    DocumentContainer: StyledDocumentContainer,
    Placeholder: StyledPlaceholder,
  },
};

const StyledNudgeDocument = ({
  type,
  document,
  onClick,
  variant = 'default',
}: {
  type: 'pin' | 'popover' | 'modal' | 'tooltip' | 'banner';
  document: ICommandType | undefined;
  onClick: () => void;
  variant?: 'default' | 'top-block' | 'full-height';
}) => {
  const { DocumentContainer, Placeholder } = components[variant];
  const themeV2 = useThemeV2Context();

  const getContainerHeight = () => {
    if (variant === 'full-height') {
      return '100%';
    }

    if (type === 'modal') {
      return '220px';
    }

    return '168px';
  };

  const DocumentIcon = document?.icon ? (
    <Icon
      icon={document.icon}
      style={{
        ...(document?.icon_color && { color: document.icon_color }),
        // reset featured icon color to white if it is currently black
        ...(document.icon_color === '#000000' && { color: 'var(--base-white)' }),
      }}
    />
  ) : (
    <BookOpen01 />
  );

  return document?.is_live ? (
    <DocumentContainer style={{ height: getContainerHeight() }} data-testid="commandbar-nudge-help-doc">
      <StyledHelpDocCard
        type="helpdoc"
        icon={DocumentIcon}
        backgroundImage={document.thumbnail?.src}
        label={document.thumbnail ? '' : document.text}
        themeV2={themeV2}
        onClick={onClick}
        loading={false}
        style={{ maxWidth: 'unset' }}
      />
    </DocumentContainer>
  ) : (
    <DocumentContainer style={{ height: getContainerHeight() }}>
      <Placeholder data-testid="commandbar-nudge-document-placeholder">
        <BookOpen02 width="24px" height="24px" />
      </Placeholder>
    </DocumentContainer>
  );
};

export default StyledNudgeDocument;
