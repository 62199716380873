import React from 'react';

import Sender from '../../../management/Sender';
import { Placeholder } from '@commandbar/internal/middleware/placeholder';
import { IPlaceholderType } from '@commandbar/internal/middleware/types';
import { reportErrorToUser } from '../../utils/ErrorReporting';

export function str_to_slug(str: string) {
  // to lower case and replace spaces with '-'
  str = str.toLowerCase().replace(/\s+/g, '-');
  return str.substring(0, 63);
}

const usePlaceholders = (organizationId: string) => {
  const [placeholders, setPlaceholders] = React.useState<IPlaceholderType[]>([]);

  const fetchPlaceholders = async () => {
    const _placeholders = await Placeholder.list();
    setPlaceholders(_placeholders);
    return _placeholders;
  };

  React.useEffect(() => {
    fetchPlaceholders();
  }, []);

  const createPlaceholder = async (text: string) => {
    try {
      const placeholder = await Placeholder.create({
        id: -1, // filler value.
        organization: organizationId,
        slug: str_to_slug(text),
        text: text,
      });
      setPlaceholders((prevState) => [...prevState, placeholder]);
      fetchPlaceholders();
    } catch (err) {
      reportErrorToUser(err);
    }
    Sender.reload(['reloadPlaceholders']);
  };

  const deletePlaceholder = async (id: number) => {
    try {
      await Placeholder.delete(id);
      fetchPlaceholders();
    } catch (err) {
      reportErrorToUser(err);
    }
    Sender.reload(['reloadPlaceholders']);
  };

  return { placeholders, createPlaceholder, deletePlaceholder };
};

export default usePlaceholders;
