import React, { useEffect, useState } from 'react';
import { Modal, Select } from '@commandbar/design-system/components/antd';
import * as Command from '@commandbar/internal/middleware/command';

import type {
  ICommandCategoryType,
  IEditorCommandType,
  IEditorCommandTypeLite,
  IOrganizationType,
} from '@commandbar/internal/middleware/types';
import { CmdDivider } from '@commandbar/design-system/cmd';
import { AlertCircle } from '@commandbar/design-system/icons/react';
import { AppState } from 'editor/src/AppStateContext';

export const isDefaultCommand = (command: IEditorCommandTypeLite, organization: IOrganizationType) => {
  return Object.values(organization.resource_options).some(
    (options) => options.default_command_id?.toString() === Command.commandUID(command),
  );
};

export const getCommandsForRecord = (resourceKey: string | undefined, commands: IEditorCommandTypeLite[]) => {
  return resourceKey ? commands.filter((command) => command.template.object === resourceKey) : [];
};

export const setDefaultCommandForRecord = (
  resourceKey: string,
  defaultCommandId: number | undefined,
  organization: IOrganizationType,
  updateOrganization: (org: IOrganizationType) => Promise<IOrganizationType>,
) => {
  const updatedOrganization = {
    ...organization,
    resource_options: {
      ...organization.resource_options,
      [resourceKey]: { ...organization.resource_options[resourceKey], default_command_id: defaultCommandId },
    },
  };

  return updateOrganization(updatedOrganization);
};

export const setQuickFindForRecord = (
  resourceKey: string,
  isQuickFindEnabled: boolean,
  organization: IOrganizationType,
  updateOrganization: (org: IOrganizationType) => Promise<IOrganizationType>,
) => {
  const updatedOrganization = {
    ...organization,
    resource_options: {
      ...organization.resource_options,
      [resourceKey]: { ...organization.resource_options[resourceKey], search: isQuickFindEnabled },
    },
  };

  return updateOrganization(updatedOrganization);
};

export const getCategoryField = <T extends keyof ICommandCategoryType>(
  categories: AppState['categories'],
  categoryId: number | null,
  field: T,
) => {
  if (categoryId == null) {
    return null;
  }

  const category = categories.find((obj) => obj.id === categoryId);
  if (category && category[field]) {
    return category[field];
  }

  return null;
};

export const deleteCommandWithWarnings = async ({
  commandToDelete,
  onCommandDelete,
  appState,
}: {
  commandToDelete: IEditorCommandTypeLite;
  onCommandDelete?: () => void;
  appState: AppState;
}) => {
  const { organization, commands, dispatch } = appState;
  const userFacingLabel = getReportingLabelForCommand(commandToDelete);
  if (!organization) throw new Error(`Can't delete ${userFacingLabel} without organization`);

  const recordKey = commandToDelete.template.object;

  const isDefault = isDefaultCommand(commandToDelete, organization);
  const otherRecordCommands = getCommandsForRecord(recordKey, commands).filter(
    (command) => command.id !== commandToDelete.id,
  );

  const recordHasOtherCommands = otherRecordCommands.length > 0;

  // Decide if a user needs to choose a new default command
  const promptToChooseNewDefault = isDefault && recordHasOtherCommands;

  let newDefaultCommandId: number | undefined = undefined;
  const setNewDefaultCommandId = (value: number | undefined) => {
    newDefaultCommandId = value;
  };

  const deleteCommand = async () => {
    await dispatch.commands.delete(commandToDelete);

    if (!!recordKey) {
      if (isDefault) {
        // Change default if deleted command is the default
        await setDefaultCommandForRecord(recordKey, newDefaultCommandId, organization, dispatch.organization.update);
      }
    }
  };

  const modalOkText = promptToChooseNewDefault ? 'Delete and set new default' : 'Delete';

  Modal.confirm({
    title: `Are you sure you want to delete this ${userFacingLabel}?`,
    icon: <AlertCircle className="anticon anticon-exclamation-circle" height={24} width={24} />,
    content: (
      <DeleteCommandWarnings
        recordKey={recordKey}
        label={userFacingLabel}
        otherRecordCommands={otherRecordCommands}
        promptToChooseNewDefault={promptToChooseNewDefault}
        onNewDefaultCommandIdChange={setNewDefaultCommandId}
      />
    ),
    okText: modalOkText,
    onOk: () => {
      return new Promise<void>((resolve, _reject) => {
        deleteCommand();
        resolve();
      })
        .then(() => {
          if (onCommandDelete) {
            onCommandDelete();
          }
        })
        .catch(() => console.log('Something went wrong'));
    },
  });
};

export const DeleteCommandWarnings = (props: {
  recordKey: string | undefined;
  otherRecordCommands: IEditorCommandTypeLite[];
  promptToChooseNewDefault: boolean;
  onNewDefaultCommandIdChange: (value: number | undefined) => void;
  label: string;
}) => {
  const { otherRecordCommands, promptToChooseNewDefault, onNewDefaultCommandIdChange, label } = props;

  const [newDefaultCommandId, setNewDefaultCommandId] = useState<number | undefined>(
    promptToChooseNewDefault ? otherRecordCommands[0]?.id : undefined,
  );

  useEffect(() => {
    if (onNewDefaultCommandIdChange) {
      onNewDefaultCommandIdChange(newDefaultCommandId);
    }
  }, [newDefaultCommandId]);

  return (
    <>
      <span>{`This action can't be undone. Usage information about this ${label} will also be deleted.`}</span>
      {promptToChooseNewDefault && (
        <div>
          <CmdDivider spacing="md" />
          <span>This command is selected as a default. Please select another one</span>
          <Select
            defaultValue={otherRecordCommands[0].id}
            style={{ width: '100%', marginTop: 6 }}
            onChange={(value) => setNewDefaultCommandId(value)}
          >
            {otherRecordCommands.map((command) => (
              <Select.Option key={command.id} value={command.id}>
                {command.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
    </>
  );
};

// "Experience Chain" only support text, date, and set arguments
export const commandCanBeSuggestedInCopilot = (commandArguments: IEditorCommandType['arguments']) => {
  if (Object.keys(commandArguments).length === 0) return true;
  const allArgumentsAreSupported = Object.keys(commandArguments).every((argKey) => {
    const arg = commandArguments[argKey];
    return ['provided', 'set'].includes(arg.type);
  });
  return allArgumentsAreSupported;
};

export const getReportingLabelForCommand = (command: IEditorCommandTypeLite) => {
  const isAnswer = command.template.type === 'helpdoc' && command.template.doc_type === 'answer';
  const isRecordAction = Command.isRecordAction(command);
  const isShownInDefaultComamndsList = Command.showInDefaultList(command);
  let reportingLabel: 'action' | 'record action' | 'video' | 'answer' | 'file' | 'page' | 'doc' = 'action';
  if (isRecordAction && !isShownInDefaultComamndsList) {
    reportingLabel = 'record action';
  } else if (command.template.type === 'video') {
    reportingLabel = 'video';
  } else if (isAnswer) {
    reportingLabel = 'answer';
  } else if (command.template.type === 'helpdoc' && !command.third_party_source) {
    reportingLabel = 'file';
  } else if (command.template.type === 'helpdoc') {
    reportingLabel = 'doc';
  } else if (command.template.type === 'link') {
    reportingLabel = 'page';
  }
  const upperCaseLabel = reportingLabel.charAt(0).toUpperCase() + reportingLabel.slice(1);

  return upperCaseLabel;
};
