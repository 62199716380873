import React from 'react';
import { handleStyleElementClick } from './helpers';
import { SkeletonProps } from './types';

const SearchView = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      width="470"
      height="300"
      viewBox="0 0 470 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
    >
      <g filter="url(#filter0_d_91_1835)">
        <g clipPath="url(#clip0_91_1835)">
          <rect x="26" y="19" width="418.257" height="248.618" rx="8.71369" fill="white" />
          <rect x="25.5643" y="18.5643" width="419.129" height="56.639" fill="white" />
          <rect x="43.4277" y="35" width="24.0036" height="24" rx="12" fill="#E7E7E9" />
          <rect x="79.4316" y="38.1702" width="139.419" height="17.4274" rx="3.48548" fill="#E7E7E9" />
          <rect x="26" y="73.8962" width="522.822" height="0.435685" fill="black" fillOpacity="0.01" />
          <rect x="25.5643" y="18.5643" width="419.129" height="56.639" stroke="#E7E7E9" strokeWidth="0.871369" />
          <rect x="39.9424" y="81.7386" width="38.3402" height="20.9129" rx="10.4564" fill="#4242CB" />
          <rect x="50.3984" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="white" />
          <rect x="92.2236" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="134.05" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="175.875" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="217.701" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect width="418.257" height="20.9129" transform="translate(26 109.622)" fill="#EDEDEE" />
          <rect x="42.5566" y="116.593" width="69.7095" height="6.97095" rx="1.74274" fill="#CDCDD0" />
          <g clipPath="url(#clip1_91_1835)">
            <rect width="418.257" height="46.46" transform="translate(26 130.535)" fill="#F0F0FF" />
            <rect width="3.49" height="46" transform="translate(26.1289 131)" fill="#4242CB" />
            <rect x="43.585" y="148.892" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
            <rect
              id="bar-styles-textSearchMatch"
              x="67.8262"
              y="140.992"
              width="104.564"
              height="13.9419"
              rx="3.48548"
              fill="#DFDFE2"
            />
            <rect
              id="bar-styles-searchableFieldMatch"
              x="68.1289"
              y="158.934"
              width="29"
              height="8"
              rx="2"
              fill="#DFDFE2"
            />
            <rect x="70.1289" y="161" width="25" height="4" rx="0.871369" fill="#7A7A85" />
            <rect x="99.1289" y="159" width="219" height="8" rx="2" fill="#DFDFE2" />
            <line x1="70.1289" y1="155.5" x2="112.129" y2="155.5" stroke="#4242CB" />
            <rect x="395.129" y="147" width="13.9419" height="13.9419" rx="3.48548" fill="#DFDFE2" />
            <rect x="413.071" y="147" width="13.9419" height="13.9419" rx="3.48548" fill="#DFDFE2" />
          </g>
          <rect x="43.585" y="189.352" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
          <rect x="67.8262" y="187.452" width="104.564" height="13.9419" rx="3.48548" fill="#E7E7E9" />
          <g clipPath="url(#clip2_91_1835)">
            <rect width="65" height="14" transform="translate(347.129 187.605)" fill="#E7E7E9" />
            <rect width="14" height="14" transform="translate(413.129 187.605)" fill="#E7E7E9" />
          </g>
          <rect x="43.585" y="224.207" width="10.4564" height="10.4564" rx="5.22822" fill="#F7F7F8" />
          <rect x="67.8262" y="222.307" width="104.564" height="13.9419" rx="3.48548" fill="#F7F7F8" />
          <rect x="412.889" y="222.307" width="13.9419" height="13.9419" rx="3.48548" fill="#F7F7F8" />
          <rect width="418.257" height="20.9129" transform="translate(26 246.705)" fill="#EDEDEE" />
          <rect x="414.889" y="251.605" width="12" height="12" rx="3.48548" fill="white" />
        </g>
        <rect
          x="25.5643"
          y="18.5643"
          width="419.129"
          height="249.489"
          rx="9.14938"
          stroke="#EDEDEE"
          strokeWidth="0.871369"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_91_1835"
          x="0.730566"
          y="0.701215"
          width="468.797"
          height="299.157"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97095" />
          <feGaussianBlur stdDeviation="12.1992" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.06 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_91_1835" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_91_1835" result="shape" />
        </filter>
        <clipPath id="clip0_91_1835">
          <rect x="26" y="19" width="418.257" height="248.618" rx="8.71369" fill="white" />
        </clipPath>
        <clipPath id="clip1_91_1835">
          <rect width="418.257" height="46.46" fill="white" transform="translate(26 130.535)" />
        </clipPath>
        <clipPath id="clip2_91_1835">
          <rect x="347.129" y="187.605" width="80" height="14" rx="3.49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchView;
