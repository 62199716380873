import { getSentry } from '@commandbar/internal/util/sentry';
import { getYoutubePlayer, getLoomPlayer, getVimeoPlayer, getVidyardPlayer } from './videoPlayer';

const getPlayer = (url: string, autoPlay?: boolean) => {
  try {
    const { hostname } = new URL(url);

    switch (hostname) {
      case 'www.youtube.com': {
        if (url.includes('shorts')) {
          url = url.replace('shorts/', 'watch?v=');
        }
        return getYoutubePlayer(url, autoPlay);
      }
      case 'youtu.be': {
        url = `https://www.youtube.com/watch?v=${url.split('/').pop()}`;
        return getYoutubePlayer(url, autoPlay);
      }
      case 'www.loom.com': {
        return getLoomPlayer(url);
      }
      case 'vimeo.com': {
        return getVimeoPlayer(url, autoPlay);
      }
      case 'player.vimeo.com': {
        url = `https://vimeo.com/${url.split('/').pop()}`;
        return getVimeoPlayer(url, autoPlay);
      }
      case 'share.vidyard.com':
      case 'play.vidyard.com':
      case 'video.vidyard.com': {
        return getVidyardPlayer(url);
      }
      default: {
        return undefined;
      }
    }
  } catch (err) {
    getSentry()?.captureException(err);
    return undefined;
  }
};

export default getPlayer;
