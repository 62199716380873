import { Alert, Input, Form } from '@commandbar/design-system/components/antd';
import { type HelpCenterIntegrationSpec, type SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const Guru: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.GURU,
  name: 'Guru',
  slug: 'guru',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/guru-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/guru.svg',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Guru cards, you'll have to first find your Guru API Key."}
    />
  ),
  route: '/guru',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={`${editingSource?.type}email`}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.email}
          label="Admin email"
          name="email"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: 'Please enter your admin email.',
              type: 'email',
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          key={`${editingSource?.type}api_key`}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          label="API Key"
          name="api_key"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: 'Please enter your API Key. ',
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      email: data.email,
      api_key: data.api_key,
    };
  },
  requiredFields: ['email', 'api_key'],
  fetchedFields: [],
};

export default Guru;
