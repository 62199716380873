import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { XClose } from '@commandbar/design-system/icons/react';
import StyledSecondaryIconButton from '../shared/StyledSecondaryIconButton';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';

const InsetContainer = styled.div`
  position: absolute;
  left: calc(var(--form-control-padding-x) + var(--layout-padding));
  top: calc(min(var(--form-control-height) / 2, var(--form-control-padding-x)) + var(--layout-padding));
`;

const StyledImage = styled.img`
  border-radius: var(--layout-radius-button);
  height: 48px;
  width: 48px;
`;

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  attachment: string | undefined;
  onDelete: () => void;
};

const StyledCopilotAttachment = forwardRef<HTMLDivElement, Props>(({ themeV2, attachment, onDelete }, ref) =>
  attachment ? (
    <InsetContainer ref={ref}>
      <div style={{ position: 'relative' }}>
        <StyledImage src={attachment} />
        <StyledSecondaryIconButton
          themeV2={themeV2}
          style={{
            position: 'absolute',
            right: '-4px',
            top: '-4px',
            width: '20px',
            height: '20px',
            borderRadius: '100px',
          }}
          onClick={onDelete}
        >
          <XClose height="12px" width="12px" />
        </StyledSecondaryIconButton>
      </div>
    </InsetContainer>
  ) : null,
);

export default StyledCopilotAttachment;
