import React from 'react';
import { Typography } from '@commandbar/design-system/components/antd';
import { getConditions, isCompoundExpression } from '@commandbar/internal/middleware/helpers/rules';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { IAvailabilityDependency } from './types';

export const getListOfAvailabilityDependencies = (command: IEditorCommandType): IAvailabilityDependency[] => {
  const dependencies: IAvailabilityDependency[] = [];

  // Click commands
  if (['click', 'clickBySelector', 'clickByXpath'].includes(command.template.type)) {
    if (Array.isArray(command.template.value)) {
      for (const elem in command.template.value) {
        dependencies.push({
          type: 'element',
          field: elem,
          operator: 'exists on the page',
          message: (
            <span>
              Element {<Typography.Text code>{elem}</Typography.Text>} must be present on the DOM for the command to be
              available.
            </span>
          ),
        });
      }
    }
  }

  if (command.template.type === 'callback') {
    dependencies.push({
      type: 'callback',
      field: String(command.template.value),
      operator: 'is defined',
      message: (
        <span>
          Callback {<Typography.Text code>{command.template.value}</Typography.Text>} must be provided for the command
          to be available.
        </span>
      ),
    });
  }

  for (const argName in command.arguments) {
    const argConfig = command.arguments[argName];

    if (argConfig?.type === 'context') {
      dependencies.push({
        type: 'context',
        field: argConfig?.value,
        operator: 'is an array',
        message: (
          <span>
            Context value {<Typography.Text code>{argConfig?.value}</Typography.Text>} must be defined and be a valid
            array of records for this command to be available.
          </span>
        ),
      });
    }
  }
  return dependencies;
};

export const camelCaseToRegular = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');

  return (result.charAt(0) + result.slice(1)).toLowerCase();
};

export const trimQuotes = (str: string) => str.replace(/(^["']|["']$)/gm, '');

export const countAvailabilityConditionsAndDependencies = (command: IEditorCommandType) => {
  const { availability_expression } = command;
  const alwaysAvailable =
    (isCompoundExpression(availability_expression) && availability_expression.exprs.length === 0) ||
    (availability_expression.type === 'LITERAL' && availability_expression.value === true);

  const dependencies = getListOfAvailabilityDependencies(command);

  return {
    alwaysAvailable: dependencies.length <= 0 && alwaysAvailable,
    dependencies,
    count: dependencies.length + getConditions(availability_expression).length,
  };
};

export const countRecommendationConditions = (command: IEditorCommandType) => {
  const { recommend_expression, always_recommend } = command;
  const conditions = getConditions(recommend_expression);

  const neverRecommend =
    !always_recommend && recommend_expression.type === 'LITERAL' && recommend_expression.value === false;

  const alwaysRecommend =
    always_recommend ||
    (recommend_expression.type === 'LITERAL' && recommend_expression.value) ||
    (isCompoundExpression(recommend_expression) && recommend_expression.exprs.length === 0);

  return {
    neverRecommend,
    alwaysRecommend,
    count: conditions.length,
  };
};
