import React from 'react';
import { Alert, Input, Form, Select } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';
import { filterDryRunData } from '../shared/utils';

const Helpscout: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.HELPSCOUT,

  name: 'Helpscout',
  slug: 'helpscout',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/helpscout.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/helpscout-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Helpscout docs, you'll have to first find your Helpscout API Key."}
    />
  ),
  route: '/helpscout',

  getForm({ dryRunData, editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'token'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          required={true}
          label={'API Key'}
          name={'token'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your API Key. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          key={editingSource?.type + 'collections'}
          validateTrigger="onSubmit"
          initialValue={dryRunData}
          label="Collections"
          name="collections"
          rules={[{ required: true, message: 'This is a required field.' }]}
          style={{ flex: 5 }}
          required
        >
          <Select
            size="large"
            mode="multiple"
            style={{ width: '100%' }}
            options={dryRunData}
            defaultValue={dryRunData}
            maxTagCount="responsive"
            allowClear
            labelInValue
          />
        </Form.Item>
      </>
    );
  },

  // Since we converted the collection data to a list of strings before passing it in as payload meta,
  // we need to convert it back to a list of objects to handle it on the frontend.
  // Not ideal, but necessary to work with pre-existing integrations.
  getInitialDryRunData(this, sourceId) {
    const data = this.sources?.find((i) => i.id === sourceId)?.meta?.collections;
    return data?.map((collection: string) => {
      return {
        label: collection,
        value: collection,
        key: collection,
      };
    });
  },

  getDryRunData(result) {
    return JSON.parse(result.data).collections.map((collection: string) => {
      return {
        label: collection,
        value: collection,
        key: collection,
      };
    });
  },

  getPayloadMeta(data, dryRunData) {
    const meta = this.requiredFields.reduce((a, v) => ({ ...a, [v]: data[v] }), {});
    const bonusMeta =
      dryRunData && dryRunData.length > 0
        ? {
            collections: filterDryRunData(data['collections'], dryRunData)?.map((collection) => collection.value),
          }
        : {};
    return { ...meta, ...bonusMeta };
  },
  requiredFields: ['token'],
  fetchedFields: ['collections'],
};

export default Helpscout;
