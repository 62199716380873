import React from 'react';

interface EmptyProps {
  width?: number | string;
  height?: number | string;
  className?: string;
}

export const CmdEmpty = ({ width = '100%', height = 16, className = '' }: EmptyProps) => {
  return (
    <div
      className={`bg-gray200 animate-custom-pulse rounded-xs ${className}`}
      style={{
        width,
        height,
        background: 'linear-gradient(-90deg, #E7E8EA 0%, #F3F4F5 50%, #E7E8EA 100%)',
        backgroundSize: '400% 400%',
      }}
    ></div>
  );
};
