import * as t from 'io-ts';

const CopilotPersonalityTemplateV = t.type({
  template: t.union([
    t.literal('professional'),
    t.literal('friendly'),
    t.literal('confident'),
    t.literal('serious'),
    t.literal('empathetic'),
  ]),
});

export const CopilotPersonalityAdjectiveV = t.union([
  t.literal('abrasive'),
  t.literal('adventurous'),
  t.literal('affable'),
  t.literal('arrogant'),
  t.literal('assertive'),
  t.literal('assured'),
  t.literal('belligerent'),
  t.literal('brave'),
  t.literal('cheerful'),
  t.literal('compassionate'),
  t.literal('condescending'),
  t.literal('courteous'),
  t.literal('creative'),
  t.literal('cynical'),
  t.literal('decisive'),
  t.literal('detached'),
  t.literal('diligent'),
  t.literal('eloquent'),
  t.literal('empathetic'),
  t.literal('evasive'),
  t.literal('formal'),
  t.literal('frivolous'),
  t.literal('garrulous'),
  t.literal('generous'),
  t.literal('impulsive'),
  t.literal('innovative'),
  t.literal('intense'),
  t.literal('judgmental'),
  t.literal('jovial'),
  t.literal('manipulative'),
  t.literal('obstinate'),
  t.literal('optimistic'),
  t.literal('perceptive'),
  t.literal('pessimistic'),
  t.literal('quarrelsome'),
  t.literal('respectful'),
  t.literal('resourceful'),
  t.literal('sarcastic'),
  t.literal('sincere'),
  t.literal('sociable'),
  t.literal('solemn'),
  t.literal('supportive'),
  t.literal('tactful'),
  t.literal('thoughtful'),
  t.literal('understanding'),
  t.literal('unreliable'),
  t.literal('vain'),
  t.literal('vivacious'),
  t.literal('warm'),
  t.literal('withdrawn'),
  t.literal('witty'),
  t.literal('zealous'),
]);

export const CopilotPersonalityResponseLengthV = t.union([
  t.literal('short'),
  t.literal('conversational'),
  t.literal('long'),
]);

export const CopilotPersonalityResponseFormatV = t.union([
  t.literal('lists'),
  t.literal('paragraphs'),
  t.literal('mixed'),
]);

const CopilotPersonalityCustomV = t.type({
  template: t.literal('custom'),
  adjectives: t.array(CopilotPersonalityAdjectiveV),
  response_length: CopilotPersonalityResponseLengthV,
  response_format: CopilotPersonalityResponseFormatV,
});

export const CopilotPersonalityV = t.union([CopilotPersonalityTemplateV, CopilotPersonalityCustomV]);
