import {
  Announcement02,
  ArrowUpRight,
  BookOpen01,
  ClockRewind,
  FaceIdSquare,
  Flag03,
  MessageSmileSquare,
  SearchMd,
  SearchSm,
} from '@commandbar/design-system/icons/react';
import { EndUserAdmin, IEndUserAdmin, IEndUserEvent } from '@commandbar/internal/middleware/endUserAdmin';
import React from 'react';
import { SimpleNonPaginatedTable } from 'editor/src/editor/components/SimplePaginatedTable';
import { formatRelativeTime } from '../analytics/utils';
import { CaretUp } from 'editor/src/editor/helphub/shared';
import { CaretDown } from 'editor/src/editor/helphub/shared';
import { CmdButton, CmdSearchInput, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { Spin } from '@commandbar/design-system/components/antd';
import CheckSquareBroken from '@commandbar/internal/assets/CheckSquareBroken';
import { getCommandsRoute, getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import { useHistory } from 'react-router';
import { useAppContext } from 'editor/src/AppStateContext';
import dayjs from 'dayjs';

const LoadingSpinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
      <Spin spinning={true} tip="Loading history events..." />
    </div>
  );
};
const ChildEventLine = (props: { description: string; date: string }) => {
  const { description, date } = props;

  return (
    <div
      className="event-line"
      style={{
        color: '#3F424A',
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '29px',
        gap: '16px',
        height: '32px',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span
        style={{
          color: '#797C85',
          position: 'relative',
          zIndex: 1,
          fontSize: '24px',
        }}
      >
        •
      </span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <span
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '70%',
          }}
        >
          {description}
        </span>

        <CmdTooltip message={dayjs(date).format('MMM DD, YYYY, hh:mmA')}>
          <span style={{ color: '#797C85' }}>{formatRelativeTime(date)}</span>
        </CmdTooltip>
      </div>
      <style>{`
        .event-line {
          position: relative;
        }
        .event-line::before {
          content: '';
          position: absolute;
          left: 35px;
          top: 16px;
          bottom: -16px;
          width: 1px;
          border-left: 1px dashed #797C85;
          z-index: 0;
        }
        .event-line:last-child::before {
          display: none;
        }
      `}</style>
    </div>
  );
};

const DateColumn = (props: { event: IEndUserEvent }) => {
  const history = useHistory();

  const { commands } = useAppContext();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        color: '#797C85',
      }}
    >
      <CmdTooltip message={dayjs(props.event.timestamp).format('MMM DD, YYYY, hh:mmA')}>
        {formatRelativeTime(props.event.timestamp)}
      </CmdTooltip>

      {(props.event.meta?.nudge_id || props.event.meta?.checklist_id || props.event.meta?.doc_id) && (
        <div className="hover-button">
          <CmdButton
            size={'sm'}
            className="px-xs py-xs"
            variant="default"
            onClick={(e) => {
              e.preventDefault();

              let route = null;

              if (props.event.widget === 'checklist' && props.event.meta?.checklist_id) {
                route = `/editor/checklists/${props.event.meta.checklist_id}`;
              } else if (props.event.widget === 'helphub' && props.event.meta?.doc_id) {
                const command = commands.find((command) => `${command.id}` === `${props.event.meta?.doc_id}`);
                if (command) {
                  route = getCommandsRoute(command);
                }
              } else if (!!props.event.meta?.nudge_id) {
                const categoryRoute = getNudgeRoute({ type: props.event.widget as any });
                route = `/editor${categoryRoute}/${props.event.meta?.nudge_id}`;
              }
              if (route) {
                history.push(route);
              }
            }}
          >
            <ArrowUpRight />
          </CmdButton>
        </div>
      )}
    </div>
  );
};

const ChildEventsList = ({ events }: { events: any[] }) => {
  return (
    <div style={{ width: '100%', minWidth: '0px', display: 'flex', flexDirection: 'column' }}>
      {events.map((event, index) => (
        <ChildEventLine key={index} description={event.name} date={event.timestamp} />
      ))}
    </div>
  );
};

const InteractionHistory = ({ endUser }: { endUser: IEndUserAdmin | null }) => {
  const [events, setEvents] = React.useState<IEndUserEvent[] | null>(null);

  const [searchFilter, setSearchFilter] = React.useState('');

  const filteredEvents =
    searchFilter === ''
      ? events
      : events?.filter((event) => {
          const lowerCaseSearchFilter = searchFilter.toLowerCase();

          return (
            event.widget.toLowerCase() === lowerCaseSearchFilter ||
            event.name.toLowerCase().includes(lowerCaseSearchFilter) ||
            event.meta?.inputtext?.toLowerCase().includes(lowerCaseSearchFilter) ||
            event.meta?.doc?.toLowerCase().includes(lowerCaseSearchFilter) ||
            event.meta?.question?.toLowerCase().includes(lowerCaseSearchFilter) ||
            event.meta?.events?.some((e: any) => e.name.toLowerCase().includes(lowerCaseSearchFilter))
          );
        });

  React.useEffect(() => {
    if (!endUser) return;

    EndUserAdmin.interactionHistory(endUser.slug).then(({ events }) => {
      setEvents(events);
    });
  }, []);

  if (!endUser) return null;

  const renderExpansion = (record: IEndUserEvent) => {
    if (record.widget === 'spotlight') {
      return <ChildEventLine description={`Searched "${record.meta.inputtext}"`} date={record.timestamp} />;
    } else if (record.widget === 'helphub' && record.name === 'search') {
      return <ChildEventLine description={`Searched "${record.meta.inputtext}"`} date={record.timestamp} />;
    } else if (record.widget === 'helphub' && record.name === 'doc_opened') {
      return <ChildEventLine description={`Viewed "${record.meta.doc}"`} date={record.timestamp} />;
    } else if (['checklist', 'product_tour', 'announcement', 'survey'].includes(record.widget)) {
      return <ChildEventsList events={record.meta?.events} />;
    } else if (record.widget === 'copilot') {
      return <ChildEventLine description={`Asked "${record.meta.question}"`} date={record.timestamp} />;
    }
  };

  if (!filteredEvents) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '100%' }}>
      {!events || events.length === 0 ? (
        <div
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'stretch',
            height: 'calc(100vh - 136px)',
            border: '1px solid #EBEBEB',
            borderRadius: '8px',
          }}
        >
          <ClockRewind height={32} width={32} style={{ marginBottom: '4px' }} />
          <CmdTypography.Body variant="primary">No history yet</CmdTypography.Body>
          <CmdTypography.Body style={{ width: '188px', textAlign: 'center' }} variant="secondary">
            This user hasn't interacted with any experiences.
          </CmdTypography.Body>
        </div>
      ) : (
        <React.Fragment>
          <CmdSearchInput
            placeholder={'Search history events'}
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
            fullWidth
            clearable
            prefixElement={<SearchMd />}
          />

          <div style={{ position: 'relative', borderRadius: '8px', border: '1px solid #EBEBEB' }}>
            <span
              style={{
                position: 'absolute',
                top: '4px',
                left: '8px',
                zIndex: 10,
                fontSize: '14px',
                fontWeight: 600,
                color: '#7A7A85',
              }}
            >
              Events ({filteredEvents.length})
            </span>
            <SimpleNonPaginatedTable
              rowKey={'id'}
              expandable={{
                expandRowByClick: true,
                expandedRowRender: renderExpansion,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <CmdButton style={{ padding: '4px' }} size={'sm'} variant="link" onClick={(e) => onExpand(record, e)}>
                    {expanded ? <CaretUp /> : <CaretDown />}
                  </CmdButton>
                ),
              }}
              locale={{ emptyText: 'No events yet' }}
              columns={[
                {
                  title: '',
                  align: 'left',
                  render: (_value: any, { widget }) => {
                    let icon = null;
                    if (widget === 'spotlight') {
                      icon = <SearchSm />;
                    } else if (widget === 'helphub') {
                      icon = <BookOpen01 />;
                    } else if (widget === 'copilot') {
                      icon = <FaceIdSquare />;
                    } else if (widget === 'checklist') {
                      icon = <CheckSquareBroken />;
                    } else if (widget === 'product_tour') {
                      icon = <Flag03 />;
                    } else if (widget === 'announcement') {
                      icon = <Announcement02 />;
                    } else if (widget === 'survey') {
                      icon = <MessageSmileSquare />;
                    } else if (widget === 'copilot') {
                      icon = <FaceIdSquare />;
                    }

                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '4px',
                          borderRadius: '4px',
                          background: '#F0F0F0',
                          width: '24px',
                        }}
                      >
                        {icon}
                      </div>
                    );
                  },
                  width: 32,
                },
                {
                  title: (
                    <div style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', left: '-100px' }}>Event</span>
                    </div>
                  ),
                  dataIndex: 'name',
                  ellipsis: true,
                  align: 'left',

                  width: '80%',
                  render: (_value: any, { widget, meta, name }) => {
                    let val = '';

                    switch (widget) {
                      case 'spotlight':
                        val = meta?.inputtext;
                        break;
                      case 'product_tour':
                      case 'announcement':
                      case 'survey':
                        val = name;
                        break;
                      case 'checklist':
                        val = name;
                        break;
                      case 'helphub':
                        if (name === 'search') {
                          val = meta?.inputtext;
                        } else if (name === 'doc_opened') {
                          val = meta?.doc;
                        }
                        break;
                      case 'copilot':
                        val = meta?.question;
                        break;
                      default:
                        break;
                    }

                    return (
                      <span
                        style={{
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {val}
                      </span>
                    );
                  },
                },
                {
                  width: '70%',
                  title: (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        flexDirection: 'row',
                        gap: '4px',
                        alignItems: 'center',
                        color: '#797C85',
                      }}
                    >
                      Date
                    </div>
                  ),
                  dataIndex: 'timestamp',
                  ellipsis: true,
                  align: 'right',
                  render: (_value: any, event) => <DateColumn event={event} />,
                },
              ]}
              dataSource={filteredEvents}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default InteractionHistory;
