import { useEffect, useState } from 'react';
import { useSegmentConfigContext } from './SegmentConfigProvider';
import { IOrganizationType, IUserType } from '@commandbar/internal/middleware/types';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { CmdButton } from '@commandbar/design-system/cmd';

interface ISegmentTestStepProps {
  organization: IOrganizationType;
  user: IUserType;
  handlePrev: Function;
}

export const SegmentTestStep = ({ organization: _unusedOrg, user: _unusedUser, handlePrev }: ISegmentTestStepProps) => {
  const [sent, setSent] = useState(0);
  const { segmentConfig } = useSegmentConfigContext();
  const { reportEvent } = useReportEvent();

  useEffect(() => {
    reportEvent('segment integration set up completed', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, []);

  const checkAreCredentialsValid = async (): Promise<[boolean, string | null]> => {
    try {
      const data = {
        anonymousId: null,
        userId: 'segment-integration-test-user',
        traits: {
          isAdmin: true,
        },
      };

      const username = segmentConfig.writeKey;
      const password = '';

      await fetch('https://api.segment.io/v1/identify', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ' + window.btoa(username + ':' + password),
        },
        body: JSON.stringify(data),
      });

      reportEvent('segment integration interacted with', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: 'Test Event Sent',
      });

      setSent(sent + 1);

      return [true, null];
    } catch (err) {
      return [false, 'Invalid Application-ID or API key'];
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <span>
        That's it, the integration is set up. If the provided write key is valid, CommandBar will now act as a Segment
        source. To test the integration, use the button below to send an <code>identify</code> event to Segment.
      </span>

      <CmdButton onClick={checkAreCredentialsValid}>
        {sent > 0 ? 'Send another test event' : 'Send test event'}
      </CmdButton>

      {sent > 0 ? (
        <span>
          Sent {sent} test event{sent > 1 ? 's' : ''}. Check the source debugger within Segment to see if the event
          arrived.
        </span>
      ) : (
        <span>
          Did not send a test event yet. Click the button above to trigger a test event. If you can see the event in
          Segment, you have configured the integration successfully.
        </span>
      )}
      <CmdButton variant="primary" onClick={() => handlePrev()}>
        Go Back
      </CmdButton>
    </div>
  );
};
