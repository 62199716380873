/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react';
import { css, jsx } from '@emotion/core';

import { NavItem } from './NavItem';
import { getColor } from './styles';

import { Download04 } from '@commandbar/design-system/icons/react';

import * as Organization from '@commandbar/internal/middleware/organization';
import { nudge_items, assist_items, content_items, settings_items } from './tabs';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { Panel } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const styles = {
  itemContainer: css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: calc(100% - 50px);
    justify-content: space-between;
  `,

  sectionContainer: css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
  `,

  installCBLinkContainer: css`
    margin: 8px;
  `,

  installCBLink: css`
    background: linear-gradient(267.7deg, #7a4cf0 5.95%, #a064dd 14.19%, #6340ed 48.21%, #582ff1 95.66%);
    border: 1px solid #421fb5;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16), inset 0px 2px 0px rgba(255, 255, 255, 0.09);
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 30px;

    &:active,
    &:hover,
    &:focus {
      color: #ffffff;
    }
  `,

  mainContainer: css`
    border: 0.5px solid ${getColor('mainContainerBorder')};
    display: flex;
    flex-direction: column;
    background: ${getColor('white')};
    position: relative;
    height: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    width: 180px;
  `,

  dashboardLink: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px 8px;
    gap: 8px;
    justify-content: start;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    background: none;
    border: none;
    border-radius: 4px;
    color: #51515c;
    cursor: pointer;
    line-height: 14px;
    padding: 8px;
    height: 32px;
    margin: 4px 8px;
    &:hover {
      background: ${getColor('mainPurpleLight')};
      color: ${getColor('mainPurple')};
    }
  `,
  divider: css`
    height: 1px;
    background: #dfdfe2;
    border-radius: 1px;
  `,
};

enum PanelKey {
  NUDGE = 'nudge',
  ASSIST = 'assist',
}

type Props = {
  isEditorOpen: boolean;
  activeRoute: string;
  onRouteChange: (route: string) => void;
  isEditorLoggedIn: boolean;
  organization: IOrganizationType | null;
};

const Navbar = (props: Props) => {
  const [hasInstalled, setHasInstalled] = React.useState(true);
  const { isEditorOpen, activeRoute, organization, onRouteChange } = props;
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([PanelKey.NUDGE, PanelKey.ASSIST]);
  const { user } = useAuth();

  const { flags } = useAppContext();

  React.useEffect(() => {
    if (!!organization?.id) {
      Organization.read(`${organization.id}`).then((organization) => {
        setHasInstalled(!!organization?.installed_at);
      });
    }
  }, [organization?.id]);

  const handleItemsClick = (e: React.MouseEvent) => {
    if (isEditorOpen) {
      e.stopPropagation();
    }
  };

  return (
    <div id="editor_nav" data-custom-type="editor_nav">
      <div css={styles.mainContainer}>
        <div data-custom-type="nav_items_container" onClick={handleItemsClick} css={styles.itemContainer}>
          <div>
            {!hasInstalled && (
              <>
                <div css={styles.installCBLinkContainer}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_DASHBOARD_URL}/installation-guide`}
                    css={styles.installCBLink}
                  >
                    <Download04 height={14} />
                    Install to Publish
                  </a>
                </div>
                <div css={styles.divider}></div>
              </>
            )}

            <Panel
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              panelKey={PanelKey.NUDGE}
              header="Nudge"
              sidepanel
            >
              {nudge_items.map((item) => (
                <NavItem
                  key={`nav-item-${item.title}`}
                  onClick={() => item.route && onRouteChange(item.route)}
                  active={!!item.route && activeRoute.startsWith(item.route)}
                  item={item}
                  badge={false}
                />
              ))}
            </Panel>

            <div css={styles.divider}></div>
            <Panel
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              panelKey={PanelKey.ASSIST}
              header="Assist"
              sidepanel
            >
              {assist_items.map((item) => (
                <NavItem
                  key={`nav-item-${item.title}`}
                  onClick={() => item.route && onRouteChange(item.route)}
                  active={!!item.route && activeRoute.startsWith(item.route)}
                  item={item}
                  badge={false}
                />
              ))}
            </Panel>

            <div css={styles.divider}></div>
            <div css={styles.sectionContainer}>
              {content_items.map((item) =>
                item.title === 'Content' ? (
                  <NavItem
                    key={`nav-item-${item.title}`}
                    onClick={() => item.handler && item.handler()}
                    active={false}
                    item={item}
                    badge={false}
                  />
                ) : (
                  <NavItem
                    key={`nav-item-${item.title}`}
                    onClick={() => item.route && onRouteChange(item.route)}
                    active={!!item.route && activeRoute.includes(item.route)}
                    item={item}
                    badge={false}
                  />
                ),
              )}
            </div>

            <div css={styles.divider}></div>
            <div css={styles.sectionContainer}>
              {settings_items(flags, user).map((item) => (
                <NavItem
                  key={`nav-item-${item.title}`}
                  onClick={() => item.route && onRouteChange(item.route)}
                  active={!!item.route && activeRoute.includes(item.route)}
                  item={item}
                  badge={false}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
