import * as React from 'react';

import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const Drawer = (props: AntdDrawerProps) => {
  return <AntdDrawer {...props} getContainer={container} />;
};

export default Drawer;
