import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { useCSVDownload } from '../../useCSVDownload';
import {
  AnalyticsProductTours,
  AnalyticsProductToursData,
} from '@commandbar/internal/middleware/analytics/productTours';
import { getProductTourDemoData } from '../../analyticDemoData';

const useAnalyticProductTour = ({ showUnpublished }: { showUnpublished: boolean }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();
  const [data, setData] = useState<AnalyticsProductToursData | null>(null);

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'product_tour',
    download: () =>
      AnalyticsProductTours.download({
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: String(showUnpublished),
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchProductData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsProductTours.read({
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      show_unpublished: showUnpublished.toString(),
      include_admin_data: includeAdminData.toString(),
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isDemoData) {
      setData(getProductTourDemoData());
      setIsLoading(false);
    } else {
      fetchProductData();
    }
  }, [timeFilterRange, showUnpublished, isDemoData, includeAdminData]);

  return {
    isLoading,
    fetchProductData,
    data,
    error,
    downloadCsv,
    isDownloading,
  };
};

export default useAnalyticProductTour;
