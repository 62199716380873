export const timeAgoFormat = (dateParam: string | number) => {
  if (!dateParam || typeof dateParam === 'number' || dateParam === '1970-01-01T00:00:00') {
    return '-';
  }

  const date = new Date(dateParam);
  const now = new Date();

  const seconds = Math.round((now.getTime() - date.getTime()) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const months = Math.round(days / 30.44);
  const years = Math.round(days / 365.25);

  if (seconds < 60) {
    return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else if (hours < 2) {
    return '1 hour ago';
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 30) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (months < 12) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (months >= 12) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  }

  return `—`;
};

export const formatNumber = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) {
    return '—';
  } else if (typeof value === 'string') {
    // Check for commas in case toLocaleString() has already been called
    if (value.includes(',')) {
      value = parseFloat(value.replace(',', ''));
    } else {
      value = parseFloat(value);
    }
  }

  if (isNaN(value)) {
    return '—';
  }

  return value.toLocaleString();
};

export const formatPercentage = (value: string | number | null | undefined) => {
  if (value === null || value === undefined) {
    return '—';
  } else if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (isNaN(value)) {
    return '—';
  }

  if (value === 0) {
    return '0%';
  }

  return `${(value * 100).toFixed(1)}%`;
};

export const formatMetric = (metric: number | string | null | undefined, format: 'number' | 'percentage' | 'raw') => {
  if (metric === null || metric === undefined) {
    return '-';
  }

  if (format === 'number') {
    return formatNumber(metric);
  }

  if (format === 'percentage') {
    return formatPercentage(metric);
  }

  return metric;
};

export const formatPageUrl = (value: string): string => {
  try {
    const url = new URL(value);
    return url?.pathname ?? '';
  } catch (error) {
    return '';
  }
};
