// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const MessageQuestionCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M21 11.5a8.5 8.5 0 0 1-11.555 7.934c-.173-.066-.26-.1-.329-.116a.901.901 0 0 0-.186-.024 2.313 2.313 0 0 0-.303.021l-5.121.53c-.489.05-.733.075-.877-.013a.5.5 0 0 1-.233-.35c-.026-.166.09-.382.324-.814l1.635-3.027c.135-.25.202-.374.233-.494a.899.899 0 0 0 .028-.326c-.01-.123-.064-.283-.173-.604A8.5 8.5 0 1 1 21 11.5Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.5 9.002a2.248 2.248 0 0 1 4.37.75c0 1.498-2.25 2.248-2.25 2.248m.029 3h.01m-.16 5a8.5 8.5 0 1 0-8.057-5.783c.109.32.163.481.173.604a.899.899 0 0 1-.028.326c-.03.12-.098.245-.233.494L2.72 18.668c-.234.432-.35.648-.324.815a.5.5 0 0 0 .233.35c.144.087.388.062.877.011l5.12-.529c.156-.016.233-.024.304-.021.07.002.119.009.186.024.07.016.156.05.33.116A8.478 8.478 0 0 0 12.5 20Z"
    />
  </svg>
);
export default MessageQuestionCircle;
