/** @jsx jsx */
import React from 'react';
import { jsx, css, keyframes } from '@emotion/core';
import Bowser from 'bowser';

import semverValid from 'semver/functions/valid';
import satisfies from 'semver/functions/satisfies';

import { Alert, Tooltip } from '@commandbar/design-system/components/antd';
import Sender from '../management/Sender';
import { AlertTriangle } from '@commandbar/design-system/icons/react';

export const thirdPartyCookiesEnabled = () => {
  try {
    localStorage.getItem('hello');
    return true;
  } catch (err) {
    return false;
  }
};

export const ThirdPartyCookiesWarning = () => {
  return (
    <div style={{ margin: '20px', padding: '20px' }}>
      <div>
        In order to use all the CommandBar Editor functionality, third party cookies must be enabled in your browser.
      </div>
      <br />
      <div>
        You may enable third party cookies from your browser settings (Security & Privacy) or by whitelisting
        [*.]commandbar.com.
      </div>
      <br />
      <div>Please contact support@commandbar.com with any questions.</div>
    </div>
  );
};

export const checkIfValidBrowser = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.satisfies({
    chrome: '>80',
    // FIXME: add firefox / opera?
  });
};

export const InvalidBrowserAlert = () => {
  return (
    <Alert
      type="warning"
      showIcon
      message={
        <div style={{ marginLeft: 5 }}>
          While the CommandBar is optimized and tested against every major browser , the CommandBar Editor is optimized
          for Chrome. If you experience any issues, please switch to the latest version of Chrome.
        </div>
      }
    />
  );
};

const shake = keyframes`
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
`;

/* Returns "true" if the `@commandbar/foobar` package doesn't satisfy the minimum version.
 * If minimum version is not specified, the latest version of the `@commandbar/foobar`
 * package is used as the minimum version.
 */
export const useNeedsToUpgradeFoobarPackage = (minimumVersion?: string | undefined) => {
  const [currentVersion, setCurrentVersion] = React.useState<string | null>(null);
  const [latestFoobarVersion, setLatestFoobarVersion] = React.useState<string | null>(null);

  React.useEffect(() => {
    (async () => {
      const {
        data: { foobarVersion },
      } = await Sender.getConfiguration();

      if (!foobarVersion) return;

      setCurrentVersion(foobarVersion);
    })();
  }, []);

  React.useEffect(() => {
    if (minimumVersion === undefined) return;

    (async () => {
      try {
        const res = await fetch(`https://api.npms.io/v2/package/${encodeURIComponent('@commandbar/foobar')}`);
        const json = await res.json();
        const latest = json.collected.metadata.version;

        if (semverValid(latest)) {
          setLatestFoobarVersion(latest);
        }
      } catch (err) {}
    })();
  }, []);
  const range = minimumVersion || latestFoobarVersion;

  if (!currentVersion || !range) return false;
  if (satisfies(currentVersion, range)) return false;

  return true;
};

export const OldFoobarVersionWarning = ({ latestVersion }: { latestVersion: string }) => {
  return (
    <Tooltip
      placement="bottom"
      interactive
      content={
        <span>
          {`
            @commandbar/foobar v${latestVersion} is out. Please upgrade your npm package to
            get access to latest features and updates.
          `}
        </span>
      }
    >
      <span
        css={css`
          display: inline-block;
          margin-left: 5px;
          padding: 5px;
          background-color: #fffbe6;
          animation: ${shake} 3s ease infinite;
          cursor: pointer;
        `}
        onClick={() => window.open('https://www.npmjs.com/package/@commandbar/foobar', '_blank')}
      >{`v${latestVersion} is out 🎉`}</span>
    </Tooltip>
  );
};

export const InvalidBrowserWarning = () => {
  return (
    <Tooltip
      placement="bottom"
      interactive
      content={
        <span style={{ color: '#14141480' }}>
          While the CommandBar is optimized and tested against every major browser, the CommandBar Editor is optimized
          for Chrome. If you experience any issues, please switch to the latest version of Chrome.
        </span>
      }
    >
      <AlertTriangle color="orange" style={{ marginLeft: 8 }} />
    </Tooltip>
  );
};
