import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

import { Layout, Page, Steps, Typography } from '@commandbar/design-system/components/antd';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import { useAppContext } from 'editor/src/AppStateContext';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { APIKeyStep } from './APIKeyStep';
import { ConfigurationStep } from './ConfigurationStep';
import { cmdToast } from '@commandbar/design-system/cmd';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Configure',
    stepHeading: 'Send events to Amplitude',
    component: ConfigurationStep,
  },
  {
    title: 'Sync Cohorts',
    stepHeading: 'Use Amplitude cohorts as audiences',
    component: APIKeyStep,
  },
];

export interface IntegrationProps {
  setAmplitudeConfig: Dispatch<SetStateAction<NonNullable<AmplitudeConfig>>>;
  amplitudeConfig: NonNullable<AmplitudeConfig>;
  loading: boolean;
}

export type StepProps = IntegrationProps & {
  handleNext: () => void;
  handlePrev: () => void;
};

const AmplitudeSetupSteps = (props: IntegrationProps) => {
  const [stepIdx, setStepIdx] = useState(0);
  const stepMarker = (dot: unknown) => <>{dot}</>;

  const { stepHeading, component: CurrentStepComponent } = STEPS[stepIdx];

  const { reportEvent } = useReportEvent();

  const handlePrev = () => {
    setStepIdx((stepIdx) => Math.max(stepIdx - 1, 0));
    reportEvent('amplitude integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Prev Step',
    });
  };

  const handleNext = () => {
    setStepIdx((stepIdx) => Math.min(stepIdx + 1, STEPS.length - 1));
    reportEvent('amplitude integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Next Step',
    });
  };

  return (
    <div>
      <Steps current={stepIdx} progressDot={stepMarker}>
        {STEPS.map(({ title }) => (
          <Step key={title} title={title} />
        ))}
      </Steps>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Typography.Title level={4}>
            {stepIdx + 1}. {stepHeading}
          </Typography.Title>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <CurrentStepComponent handlePrev={handlePrev} handleNext={handleNext} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export type AmplitudeConfig = IOrganizationType['integrations']['amplitude'];

const initialConfig = {
  enabled: false,
} satisfies AmplitudeConfig;

const AmplitudeIntegrationPage = () => {
  const { organization } = useAppContext();
  const { reportEvent } = useReportEvent();
  const [loading, setLoading] = useState(false);

  const [amplitudeConfig, setAmplitudeConfig] = useState<NonNullable<AmplitudeConfig>>(initialConfig);

  useEffect(() => {
    reportEvent('amplitude integration visited', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, [reportEvent]);

  useEffect(() => {
    setLoading(true);
    Organization.readInternal(organization.id.toString())
      .then(({ integrations }) => {
        setAmplitudeConfig((integrations.amplitude as AmplitudeConfig) ?? initialConfig);
      })
      .catch((e) => {
        Logger.error(e);
        cmdToast.error('Failed to fetch api key');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organization.id]);

  return (
    <Page section="Integrations" title="Amplitude" description="Send data from Amplitude to Command AI server-side.">
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        <AmplitudeSetupSteps
          loading={loading}
          amplitudeConfig={amplitudeConfig}
          setAmplitudeConfig={setAmplitudeConfig}
        />
      </Layout.Content>
    </Page>
  );
};

export default AmplitudeIntegrationPage;
