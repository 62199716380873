import * as t from 'io-ts';

import {
  ChatHandoffActionV,
  ClickAction,
  ClickActionNew,
  CommandActionV,
  DismissAction,
  GoToNudgeStepActionV,
  LinkAction,
  NudgeActionV,
  OpenBarAction,
  OpenChatActionV,
  OpenCopilotAction,
  OpenHelpHubAction,
  QuestlistActionV,
  SnoozeAction,
  SnoozeInterval,
  SnoozeValue,
  StepBackAction,
} from '@cb/types/entities/command/actions';
import {
  createObject,
  decodeThrowing,
  decodeToPromise,
  deleteObject,
  listObject,
  readObject,
  updateObject,
} from './generics';
import { AudienceV } from './helpers/audience';
import { FrequencyLimitV } from './helpers/frequencyLimit';
import { PushTriggerV } from './helpers/pushTrigger';
import { RuleExpressionV } from './helpers/rules';
import * as axiosInstance from './network';

export const NudgeContentMarkdownBlockV = t.type({
  type: t.literal('markdown'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.type({ value: t.string }),
});

export const NudgeContentImageBlockV = t.type({
  type: t.literal('image'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.type({ src: t.string, file_name: t.string, size: t.string }),
});

export const NudgeContentVideoBlockV = t.type({
  type: t.literal('video'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.union([
    t.type({ type: t.literal('url'), src: t.string }),
    t.type({ type: t.literal('command'), command: t.string }),
  ]),
});

const Required = t.union([
  t.type({
    value: t.literal(true),
    message: t.string,
  }),
  t.type({
    value: t.literal(false),
    message: t.null,
  }),
]);

const SurveyValidation = t.partial({
  validation: t.partial({
    required: Required,
  }),
});

export const NudgeContentListBlockV = t.type({
  type: t.literal('survey_list'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.intersection([
    t.type({
      options: t.array(t.string),
      list_type: t.union([t.literal('single'), t.literal('multiple')]),
      display_type: t.union([t.literal('dropdown'), t.literal('list'), t.literal('grid')]),
    }),
    SurveyValidation,
  ]),
});

export const NudgeContentHelpDocBlockV = t.type({
  type: t.literal('help_doc_command'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.type({ command: t.string }),
});

export const NudgeButtonActionV = t.union([
  CommandActionV,
  t.type({ type: t.literal('no_action') }),
  ClickActionNew,
  ClickAction,
  LinkAction,
  OpenChatActionV,
  ChatHandoffActionV,
  DismissAction,
  SnoozeAction,
  QuestlistActionV,
  StepBackAction,
  NudgeActionV,
  GoToNudgeStepActionV,
  OpenBarAction,
  OpenHelpHubAction,
  OpenCopilotAction,
]);

export const NudgeConditionalActionV = t.type({
  operator: t.union([t.literal('eq'), t.literal('neq'), t.literal('gt'), t.literal('lt')]),
  operand: t.union([t.string, t.number]),
  action: NudgeButtonActionV,
});

export const NudgeContentButtonBlockV = t.type({
  type: t.literal('button'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.union([
    t.partial({
      label: t.string,
      action: NudgeButtonActionV,
      button_type: t.union([t.literal('primary'), t.literal('secondary'), t.literal('snooze')], undefined),
      conditional_actions: t.array(NudgeConditionalActionV),
    }),
    t.null,
  ]),
});

export const NudgeContentSurveyTextBlockV = t.type({
  type: t.literal('survey_text'),
  meta: t.intersection([t.type({ prompt: t.string }), SurveyValidation]),
  sort_key: t.union([t.number, t.undefined]),
});

export const NudgeStepContentSurveyTextShortBlockTypeV = t.type({
  type: t.literal('survey_text_short'),
  meta: t.intersection([t.type({ prompt: t.string }), SurveyValidation]),
  sort_key: t.union([t.number, t.undefined]),
});

export const NudgeContentSurveyRatingBlockV = t.type({
  type: t.literal('survey_rating'),
  sort_key: t.union([t.number, t.undefined]),
  meta: t.intersection([
    t.union([
      t.type({
        type: t.literal('emojis'),
        lower_label: t.string,
        upper_label: t.string,
        options: t.number,
        emojis: t.array(t.string),
      }),
      t.type({
        type: t.literal('numbers'),
        lower_label: t.string,
        upper_label: t.string,
        options: t.number,
      }),
      t.type({
        type: t.literal('stars'),
        lower_label: t.string,
        upper_label: t.string,
        options: t.number,
      }),
      t.type({
        type: t.literal('nps'),
        lower_label: t.string,
        upper_label: t.string,
        options: t.number,
      }),
    ]),
    SurveyValidation,
  ]),
});

export const NudgeContentBlockV = t.union([
  NudgeContentMarkdownBlockV,
  NudgeContentImageBlockV,
  NudgeContentVideoBlockV,
  NudgeContentHelpDocBlockV,
  NudgeContentButtonBlockV,
  NudgeContentSurveyTextBlockV,
  NudgeStepContentSurveyTextShortBlockTypeV,
  NudgeContentSurveyRatingBlockV,
  NudgeContentListBlockV,
]);

const NudgeStepBaseV = t.type({
  id: t.number,
  title: t.string,
  content: t.array(NudgeContentBlockV),
  is_live: t.boolean,
  preview_url: t.union([t.string, t.null, t.undefined]),
});

const NudgeStepLayoutV = t.union([t.literal('classic'), t.literal('horizontal'), t.literal('vertical')]);

export const ElementSelectorV = t.type({
  selector: t.string,
  text: t.string,
  tag: t.string,
  attributes: t.record(t.string, t.string),
});

const NudgeStepAdditionalV = t.intersection(
  [
    t.type({
      form_factor: t.union([
        t.intersection([
          t.type({
            type: t.literal('modal'),
          }),
          t.partial({
            layout: NudgeStepLayoutV,
          }),
        ]),
        t.intersection([
          t.type({
            type: t.literal('popover'),
            position: t.union([
              t.literal('top-left'),
              t.literal('top-right'),
              t.literal('bottom-right'),
              t.literal('bottom-left'),
              t.literal('center'),
            ]),
          }),
          t.partial({
            layout: NudgeStepLayoutV,
          }),
        ]),

        t.intersection([
          t.type({
            type: t.literal('banner'),
            position: t.union([t.literal('top'), t.literal('bottom')]),
            placement: t.union([t.literal('default'), t.literal('overlay'), t.literal('sticky')]),
          }),
          t.partial({
            layout: t.literal('classic'),
          }),
        ]),

        t.intersection([
          t.type({
            type: t.literal('pin'),
            anchor: t.string,
          }),
          t.partial({
            anchor_selector: ElementSelectorV,
            is_open_by_default: t.boolean,
            is_showing_mask: t.boolean,
            advance_trigger: t.string,
            offset: t.type({
              x: t.string,
              y: t.string,
            }),
            position: t.union([
              t.literal('auto'),
              t.literal('top'),
              t.literal('bottom'),
              t.literal('left'),
              t.literal('right'),
            ]),
            alignment: t.union([
              t.literal('center'),
              t.literal('top'),
              t.literal('bottom'),
              t.literal('left'),
              t.literal('right'),
            ]),
            copilot_intro: t.boolean,
            layout: NudgeStepLayoutV,
          }),
        ]),
        t.intersection([
          t.type({
            type: t.literal('tooltip'),
            anchor: t.string,
            show_on: t.union([t.literal('hover'), t.literal('click')]),
            marker: t.intersection([
              t.union([
                t.type({
                  type: t.literal('beacon'),
                }),
                t.type({
                  type: t.literal('icon'),
                  icon: t.union([
                    t.literal('helpCircle'),
                    t.literal('helpSquare'),
                    t.literal('infoCircle'),
                    t.literal('bookClosed'),
                    t.literal('lightBulb'),
                    t.literal('lightning'),
                  ]),
                }),
                t.type({
                  type: t.literal('image'),
                  source: t.string,
                }),
              ]),
              t.type({
                positioning: t.type({
                  position: t.union([
                    t.literal('left'),
                    t.literal('right'),
                    t.literal('inline_left'),
                    t.literal('inline_right'),
                  ]),
                  offset: t.type({
                    x: t.string,
                    y: t.string,
                  }),
                }),
              }),
            ]),
          }),
          t.partial({
            layout: NudgeStepLayoutV,
            anchor_selector: ElementSelectorV,
          }),
        ]),
      ]),
    }),
    t.partial({
      has_survey_response: t.boolean,
    }),
  ],
  'NudgeStepAdditional',
);

const stepDefaults: t.TypeOf<typeof NudgeStepAdditionalV> = {
  form_factor: {
    type: 'popover',
    position: 'top-right',
  },
};

export const NudgeStepV = t.intersection([NudgeStepBaseV, NudgeStepAdditionalV], 'Nudge');

const NudgeAdditionalV = t.type(
  {
    template_source: t.string,
    show_step_counter: t.boolean,
    dismissible: t.boolean,
    snoozable: t.boolean,
    snoozable_on_all_steps: t.boolean,
    share_page_url_or_path: t.string,
    copilot_suggest: t.boolean,
    show_in_spotlight_search: t.boolean,
    show_in_helphub_search: t.boolean,
    copilot_cta_label: t.string,
    copilot_description: t.string,
    is_scheduled: t.boolean,
    scheduled_start_time: t.union([t.string, t.null, t.undefined]),
    scheduled_end_time: t.union([t.string, t.null, t.undefined]),
    snooze_label: t.string,
    snooze_duration: t.type({
      interval: SnoozeInterval,
      value: SnoozeValue,
    }),
    type: t.union([t.literal('announcement'), t.literal('product_tour'), t.literal('survey'), t.null]),
    editor_tags: t.array(t.string),
    preview_url: t.union([t.string, t.null, t.undefined]),
    custom_theme: t.union([t.string, t.null, t.undefined]),
  },
  'NudgeAdditional',
);

const defaults: t.TypeOf<typeof NudgeAdditionalV> = {
  template_source: 'none',
  show_step_counter: false,
  dismissible: true,
  snoozable: false,
  snoozable_on_all_steps: true,
  share_page_url_or_path: '',
  show_in_spotlight_search: false,
  show_in_helphub_search: false,
  copilot_suggest: false,
  copilot_cta_label: '',
  copilot_description: '',
  is_scheduled: false,
  scheduled_start_time: null,
  scheduled_end_time: null,
  snooze_label: 'Snooze',
  snooze_duration: {
    interval: 'day',
    value: 3,
  },
  type: 'announcement',
  editor_tags: [],
  preview_url: null,
  custom_theme: null,
};

const NudgeBaseV = t.intersection(
  [
    t.type({
      slug: t.string,
      id: t.union([t.number, t.string]),
      organization: t.string,
      show_expression: RuleExpressionV,
      trigger: PushTriggerV,
      frequency_limit: FrequencyLimitV,
      steps: t.array(NudgeStepV),
      is_live: t.boolean,
    }),
    t.partial({
      old_nudge_id: t.union([t.number, t.null]),
      archived: t.boolean,
      audience: t.union([AudienceV, t.null]),
    }),
  ],
  'NudgeBase',
);

export const NudgeV = t.intersection([NudgeBaseV, NudgeAdditionalV], 'Nudge');

export const OldNudgeBaseV = t.intersection(
  [
    t.type({
      id: t.number,
      organization: t.string,
      slug: t.string,
      content: t.string,
      show_expression: RuleExpressionV,
      trigger: t.union([
        t.type({ type: t.literal('when_conditions_pass') }),
        t.type({ type: t.literal('on_command_execution'), meta: t.type({ command: t.string }) }),
        t.type({
          type: t.literal('on_event'),
          meta: t.type({ event: t.string, condition_group: t.union([RuleExpressionV, t.null, t.undefined]) }),
        }),
        /**
         * Note: this trigger does not have to be set for share linking to work.
         * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
         */
        t.type({ type: t.literal('when_share_link_viewed') }),
      ]),
      on_select: t.union([t.type({ type: t.literal('no_action') }), CommandActionV]),
      timeout_ms: t.union([t.number, t.null]),
      frequency_limit: t.union([
        t.literal('no_limit'),
        t.literal('once_per_session'),
        t.literal('once_per_user'),
        t.literal('until_interaction'),
      ]),
    }),
    t.partial({
      title: t.string,
      html: t.string,
      cta: t.string,
      is_live: t.boolean,
      media: t.union([
        t.type({ type: t.literal('image'), meta: t.type({ src: t.string, file_name: t.string, size: t.string }) }),
        t.type({ type: t.literal('help_doc_command'), meta: t.type({ command: t.string }) }),
        t.null,
      ]),
      is_html_override: t.boolean,
      call_custom_handler: t.boolean,
    }),
  ],
  'OldNudgeBase',
);

export const OldNudgeAdditionalV = t.type(
  {
    form_factor: t.union([
      t.type({
        type: t.literal('modal'),
      }),
      t.type({
        type: t.literal('popover'),
        position: t.union([
          t.literal('top-left'),
          t.literal('top-right'),
          t.literal('bottom-right'),
          t.literal('bottom-left'),
          t.literal('center'),
        ]),
      }),
      t.type({
        type: t.literal('pin'),
        anchor: t.string,
      }),
    ]),
    template_source: t.string,
  },
  'OldNudgeAdditional',
);

export const OldNudgeV = t.intersection([OldNudgeBaseV, OldNudgeAdditionalV], 'OldNudge');

export const NudgeResetResponse = t.type({
  did_reset: t.boolean,
});

export class Nudge {
  public static decode = (data: any) => decodeThrowing(NudgeV, { ...defaults, steps: [stepDefaults], ...data });

  public static create = createObject(NudgeV, NudgeV, 'nudges');
  public static update = updateObject(NudgeV, NudgeV, 'nudges');
  public static delete = deleteObject(NudgeV, 'nudges');
  public static list = listObject(NudgeV, 'nudges');
  public static read = readObject(NudgeV, 'nudges');
  /**
   * Returns true if the nudge is new (not yet saved to the backend)
   */
  public static isNew = (nudge: t.TypeOf<typeof NudgeV>) => {
    if (typeof nudge.id === 'number') {
      return nudge.id < 0;
    }
    return false;
  };

  public static resetNudgeInteractions = async (endUserSlug: string, nudgeId: number) => {
    const result = await axiosInstance.patch('nudges/reset_nudge_for_end_user/', {
      end_user_slug: endUserSlug,
      nudge_id: nudgeId,
    });

    return await decodeToPromise(t.exact(NudgeResetResponse), result.data);
  };
}
