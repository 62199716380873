import { useReportEvent } from '../../../hooks/useEventReporting';
import { Card, Header, Content, IntegrationTitle, IntegrationDescription, Tag } from '../../styles';
import { CheckCircleBroken } from '@commandbar/design-system/icons/react';

import type { IntegrationSpec } from '../shared/types';

const IntegrationCard = ({
  integration,
  isActive,
  children,
}: {
  integration: Pick<IntegrationSpec, 'name' | 'slug' | 'imgURL' | 'description' | 'internalOnly'>;
  isActive: boolean;
  children: React.ReactNode;
}) => {
  const { reportEvent } = useReportEvent();
  const { name, slug, imgURL, description, internalOnly } = integration;

  return (
    <Card
      style={!!internalOnly ? { background: '#C0EEFF' } : {}}
      key={slug}
      onClick={() => {
        reportEvent('integration card clicked', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: slug,
          eventProps: {
            name: slug,
          },
        });
      }}
    >
      <Header active={isActive}>
        <img className={`integration-logo ${name.toLowerCase()}`} alt="integration-logo" src={imgURL} />
        {!!internalOnly && 'INTERNAL'}
        {isActive && (
          <Tag>
            <CheckCircleBroken color="#176832" height="16px" />
          </Tag>
        )}
      </Header>
      <Content>
        <IntegrationTitle>{name}</IntegrationTitle>
        <IntegrationDescription>{description}</IntegrationDescription>
      </Content>
      {children}
    </Card>
  );
};

export default IntegrationCard;
