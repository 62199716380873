import React, { useState } from 'react';
import { BaseEmoji, Picker } from 'emoji-mart';
import { CirclePicker } from 'react-color';
import { union } from 'lodash';

import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';

import { ICONS } from '@commandbar/internal/client/Icon';

import {
  CB_COLORS,
  Col,
  Icon,
  Input,
  Popover,
  Row,
  TabPane,
  Tooltip,
  TabsBase,
  Collapse,
  Space,
} from '@commandbar/design-system/components/antd';
import IconUpload from '../IconUpload';

// needed for emoji-mart customization
import 'emoji-mart/css/emoji-mart.css';
import './IconPicker.css';
import emojiBackgroundFileName from '../../../img/emojis-32px.png';
import { CmdButton } from '@commandbar/design-system/cmd';
import { Caret, Trash04, XClose } from '@commandbar/design-system/icons/react';

const HEX_REGEX = /^[0-9a-fA-F]{3,8}$/;

const COLOR_OPTIONS = [
  '#000000d9',
  '#ffffff66',
  '#f44336',
  '#e91e63',
  '#aeb1dd',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

const IconPicker = (props: {
  icon: string | null;
  color: string | null;
  colors: string[];

  onIconSelect: (icon: string) => void;
  onColorSelect: (color: string) => void;
  //  close: () => void;

  disabled?: boolean;
  clearable?: boolean;

  //children: ReactNode;
}) => {
  // Support old icons stored in this format `${icon_name}#{color}`
  const { icon, color } = (() => {
    if (props.icon && props.icon.includes('#')) {
      const [icon, color] = props.icon.split('#');
      if (HEX_REGEX.test(color)) {
        return { icon, color };
      }
    }

    return { icon: props.icon, color: !props.color ? COLOR_OPTIONS[0] : props.color };
  })();

  const onColorSelect = (_newColor: string) => {
    // if the color is the "default"; store as empty string
    const newColor = _newColor === COLOR_OPTIONS[0] ? '' : _newColor;

    props.onColorSelect(newColor);
  };

  const onClear = () => {
    props.onIconSelect('');
    props.onColorSelect('');
    close();
  };

  const {
    colors,

    onIconSelect,
    //close,

    //children,
  } = props;

  const [search, setSearch] = useState('');
  const [visbile, setVisible] = useState(false);

  const close = () => setVisible(false);

  // close() on ESC keypress
  React.useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, [close]);

  // An icon can be a URL, image, emoji, or icon name
  const { image, url: _url } = ((): { image?: string; url?: string } => {
    if (!icon) return {};

    if (icon.startsWith('https://')) {
      return { url: icon };
    }

    if (icon.startsWith('http://')) {
      return { url: icon };
    }

    if (icon.startsWith('data:image') || icon.startsWith('<svg')) {
      return { image: icon };
    }

    return {};
  })();

  const [url, setUrl] = useState(_url);
  React.useEffect(() => {
    setUrl(_url);
  }, [_url]);

  const onEmojiSelect = (e: BaseEmoji) => {
    onIconSelect(e.native);
    close();
  };

  const antIcons = (
    <div
      style={{
        maxWidth: 300,
        maxHeight: 250,
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
      }}
    >
      {Object.keys(ICONS)
        .filter((icon) => (search ? icon.includes(search.toLowerCase()) : true))
        .map((iconKey) => {
          const IconComponent = ICONS[iconKey];
          if (IconComponent) {
            return (
              <Tooltip content={iconKey} key={iconKey}>
                <span className="default-icon__wrapper">
                  <CmdButton
                    icon={<IconComponent style={{ fontSize: 20, color }} />}
                    style={{
                      margin: '3px 2px',
                      lineHeight: '14px',
                      border: 'none',
                      boxShadow: 'none',
                      background: '#fff',
                      backgroundColor: icon === iconKey ? 'rgb(242, 242, 242)' : '',
                    }}
                    onClick={() => {
                      onIconSelect(iconKey);
                      close();
                    }}
                  />
                </span>
              </Tooltip>
            );
          }
          return null;
        })}
    </div>
  );

  const iconButton = (
    <div
      style={{
        width: 56,
        height: 32,
        border: '1px solid ' + CB_COLORS.neutral300,
        borderRadius: 4,
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: 'white',
        flexShrink: 0,
      }}
    >
      {icon ? (
        <Icon icon={icon} useDefaultSVGColor size={'14px'} style={{ ...(color && { color }) }} />
      ) : (
        <div style={{ width: 14, height: 14, background: CB_COLORS.neutral100 }} />
      )}
      {!props.disabled && <CaretDown style={{ color: CB_COLORS.neutral600 }} />}
    </div>
  );

  if (props.disabled) {
    return iconButton;
  }

  return (
    <Popover
      placement="bottomRight"
      trigger={'click'}
      visible={visbile}
      onVisibleChange={setVisible}
      overlayStyle={{ width: 400 }}
      content={
        <div style={{ display: 'flex', flexDirection: 'column', height: 428 }}>
          <TabsBase
            destroyInactiveTabPane={true}
            tabBarExtraContent={
              <Space size="middle" align="center">
                {props.clearable && props.icon && (
                  <Tooltip content="Remove Icon" placement="left">
                    <CmdButton icon={<Trash04 />} onClick={onClear} variant="link" />
                  </Tooltip>
                )}
                <span style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => close()}>
                  <XClose />
                </span>
              </Space>
            }
          >
            <TabPane tab="Icons" key="Icons" style={{ padding: '0px 15px', height: '100%', overflowY: 'auto' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Input
                  value={search}
                  suffix={
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 20 20" opacity="0.5">
                      <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                    </svg>
                  }
                  placeholder={'Search'}
                  onChange={(e) => setSearch(e.target.value)}
                  style={{ margin: '0 0 15px' }}
                />
              </div>
              <Collapse
                bordered={false}
                defaultActiveKey={[]}
                ghost={true}
                expandIcon={({ isActive }) => {
                  return (
                    <Caret
                      style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(270deg)', top: '30%' }}
                      viewBox="-4 0 16 6"
                    />
                  );
                }}
                expandIconPosition="end"
              >
                <Collapse.Panel
                  key={'solo'}
                  style={{ border: 'none', margin: '0 auto' }}
                  header={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: '#F2F2F2',
                        padding: '0px 16px',
                        height: 35,
                        borderRadius: 8,
                        marginBottom: 5,
                      }}
                    >
                      Color:{' '}
                      <span
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: color,
                          margin: '0 auto 0 5px',
                        }}
                      />
                    </span>
                  }
                >
                  <div
                    style={{
                      padding: '10px 0 0',
                      margin: '0 -15px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <CirclePicker
                      color={color}
                      onChange={(c) => {
                        onColorSelect(c.hex);
                      }}
                      width={'100%'}
                      colors={union(COLOR_OPTIONS, colors)}
                    />
                  </div>
                  <Row
                    gutter={8}
                    justify="start"
                    align="middle"
                    style={{ marginTop: 15, marginBottom: 8, maxWidth: 300 }}
                  >
                    <Col span={8}>Custom color:</Col>
                    <Col span={16}>
                      <Input onChange={(e) => onColorSelect(e.target.value)} placeholder={'Hex value, e.g., #FFFFFF'} />
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
              <div style={{ display: 'flex', justifyContent: 'center' }}>{antIcons}</div>
            </TabPane>
            <TabPane tab="Emojis" key="Emojis" style={{ padding: '0px 15px', overflowY: 'auto' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Picker
                  style={{ width: '100%' }}
                  autoFocus={true}
                  showSkinTones={false}
                  showPreview={false}
                  emojiSize={20}
                  onSelect={onEmojiSelect}
                  backgroundImageFn={() => emojiBackgroundFileName}
                />
              </div>
            </TabPane>
            <TabPane tab="URL" key="URL" style={{ padding: '0px 15px', height: '100%', overflowY: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Input
                  value={url}
                  placeholder={'https://...'}
                  onChange={(e) => {
                    setUrl(e.target.value);
                    try {
                      new URL(e.target.value);
                    } catch (e) {
                      return;
                    }
                    onIconSelect(e.target.value);
                  }}
                />
                <div
                  style={{
                    border: '1px solid #cacaca',
                    margin: '10px',
                    flex: 1,
                    width: '100%',
                    ...(url && {
                      backgroundImage: `url("${encodeURI(url)}")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                    }),
                  }}
                />
              </div>
            </TabPane>
            <TabPane tab="Image" key="Image" style={{ padding: '0px 15px', overflowY: 'auto' }}>
              <div style={{ textAlign: 'center', padding: '10px' }}>
                <IconUpload
                  defaultIcon={image ?? null}
                  onUpload={(icon) => {
                    onIconSelect(icon);
                    close();
                  }}
                  onRemove={() => onIconSelect('')}
                />
              </div>
            </TabPane>
          </TabsBase>
        </div>
      }
    >
      {iconButton}
    </Popover>
  );
};

export default IconPicker;
