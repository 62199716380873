import { useEffect, useState } from 'react';
import { Spin, Result } from '@commandbar/design-system/components/antd';
import { createAlgoliaCommand } from '../../../util/create-command-template';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useAlgoliaConfigContext } from './AlgoliaConfigProvider';
import { IUserType } from '@commandbar/internal/middleware/types';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { CmdButton } from '@commandbar/design-system/cmd';

interface IFinalizingStepProps {
  organization: IOrganizationType;
  user: IUserType;
}

export const FinalizingStep = ({ organization: _unusedOrg, user: _unusedUser }: IFinalizingStepProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { reportEvent } = useReportEvent();
  const {
    algoliaConfig: { indexes, orgID, applicationID, apiKey },
  } = useAlgoliaConfigContext();

  useEffect(() => {
    const createRecordActions = async () => {
      try {
        await Promise.all(
          Object.entries(indexes).map(
            ([indexName, { __cbConfig }]) =>
              !!__cbConfig &&
              createAlgoliaCommand({
                organizationId: orgID,
                title: `Search ${indexName}`,
                url: __cbConfig.url,
                urlOperation: __cbConfig.behaviour,
                indexName,
              }),
          ),
        );
      } catch (err) {
        console.error(err);
        setError('An error occured. The CommandBar team has been notified.');
      }
    };

    const configureRecordSettings = async () => {
      const organization = await Organization.read(orgID);
      const resourceOptions: IOrganizationType['resource_options'] = {};
      for (const [indexName, { __cbConfig }] of Object.entries(indexes)) {
        if (!!__cbConfig) {
          resourceOptions[indexName] = {
            search: true,
            label_field: __cbConfig.label,
            description_field: __cbConfig.description || '',
            search_fields: __cbConfig.fields || [],
          };
        }
      }

      try {
        await Organization.update({
          ...organization,
          resource_options: {
            ...organization.resource_options,
            ...resourceOptions,
          },
        });

        const recordIndexes: { [x: string]: string } = {};

        Object.keys(indexes).forEach(function (key) {
          recordIndexes[key] = indexes[key]?.__cbConfig?.icon || '';
        });

        const { integrations } = await Organization.readInternal(orgID);

        await Organization.updateInternal({
          id: orgID,
          integrations: {
            ...integrations,
            algolia: {
              applicationID: applicationID as string,
              apiKey: apiKey as string,
              indexes: recordIndexes,
            },
          },
        });

        setTimeout(() => setLoading(false), 2000);
      } catch (err) {
        console.error(err);
        setError('An error occured. The CommandBar team has been notified.');
      }
    };

    createRecordActions().then(() => {
      return configureRecordSettings().then(() => {
        setLoading(false);
        reportEvent('integration created', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: 'Algolia',
          eventProps: {
            name: 'Algolia',
          },
        });
      });
    });
  }, []);

  const LoadingSpinner = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin spinning={true} tip="Configuring Algolia integration..." />
      </div>
    );
  };

  const SuccessMessage = () => {
    return (
      <div>
        <p style={{ fontSize: '16px' }}>To test your integration, go to the Editor and start searching.</p>
        <div>
          <a href="/editor/spotlight/records">
            <CmdButton variant="primary" size="lg">
              Open Editor
            </CmdButton>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div>{loading ? <LoadingSpinner /> : error ? <Result status="error" subTitle={error} /> : <SuccessMessage />}</div>
  );
};
