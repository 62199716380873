import React from 'react';
import { handleStyleElementClick } from './helpers';
import { SkeletonProps } from './types';

const ArgumentViewSkeleton = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="470"
      height="225"
      viewBox="0 0 470 225"
      fill="none"
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
    >
      <g filter="url(#filter0_d_91_2993)">
        <g clipPath="url(#clip0_91_2993)">
          <rect x="25.8711" y="19" width="418.257" height="173.643" rx="8.71369" fill="white" />
          <rect x="25.4354" y="18.5643" width="419.129" height="73.2697" fill="white" />
          <rect
            width="83.2531"
            height="14.971"
            transform="translate(41.8711 27)"
            fill="white"
            id="bar-styles-blocksContainer"
          />
          <rect id="bar-styles-blocks" x="41.8711" y="27" width="25.4274" height="14.971" rx="4" fill="#DBDBFF" />
          <rect x="45.8711" y="31" width="17.4274" height="6.97095" rx="0.871369" fill="#4242CB" />
          <rect x="74.7842" y="31" width="17.4274" height="6.97095" rx="0.871369" fill="#CDCDD0" />
          <rect x="103.697" y="31" width="17.4274" height="6.97095" rx="0.871369" fill="#CDCDD0" />
          <rect
            id="bar-styles-instruction"
            x="288.452"
            y="28.4855"
            width="139.419"
            height="12"
            rx="3.48548"
            fill="#E7E7E9"
          />
          <rect x="25.4354" y="18.5643" width="419.129" height="73.2697" stroke="#E7E7E9" strokeWidth="0.871369" />
          <rect width="418.257" height="20.9129" transform="translate(25.8711 86.253)" fill="#EDEDEE" />
          <rect x="42.4277" y="93.224" width="69.7095" height="6.97095" rx="1.74274" fill="#CDCDD0" />
          <rect x="43.4561" y="124.378" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
          <rect x="67.6973" y="122.477" width="104.564" height="13.9419" rx="3.48548" fill="#E7E7E9" />
          <rect x="412.76" y="122.477" width="13.9419" height="13.9419" rx="3.48548" fill="#E7E7E9" />
          <rect x="43.4561" y="159.232" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
          <rect x="67.6973" y="157.332" width="104.564" height="13.9419" rx="3.48548" fill="#E7E7E9" />
          <rect x="412.76" y="157.332" width="13.9419" height="13.9419" rx="3.48548" fill="#E7E7E9" />
        </g>
        <rect
          x="25.4354"
          y="18.5643"
          width="419.129"
          height="174.515"
          rx="9.14938"
          stroke="#EDEDEE"
          strokeWidth="0.871369"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_91_2993"
          x="0.60166"
          y="0.701276"
          width="468.797"
          height="224.183"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97095" />
          <feGaussianBlur stdDeviation="12.1992" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.06 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_91_2993" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_91_2993" result="shape" />
        </filter>
        <clipPath id="clip0_91_2993">
          <rect x="25.8711" y="19" width="418.257" height="173.643" rx="8.71369" fill="white" />
        </clipPath>
        <clipPath id="clip1_91_2993">
          <rect width="418.257" height="34.8548" fill="white" transform="translate(25.8711 147.166)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArgumentViewSkeleton;
