import * as React from 'react';
import * as ToastPrimitives from '@radix-ui/react-toast';
import { type VariantProps } from 'class-variance-authority';
import { cn } from '../util';
import { X } from '@commandbar/design-system/icons/react';
import { toastVariants } from './styles';
import { CmdButton } from '../button';

const CmdToastProvider = ToastPrimitives.Provider;

const CmdToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'fixed top-0 left-0 z-max h-full w-full p-lg justify-center flex items-end pointer-events-none',
      className,
    )}
    {...props}
  />
));
CmdToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const CmdToast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastVariants({ variant }), className)} {...props} />;
});
CmdToast.displayName = ToastPrimitives.Root.displayName;

const CmdToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action ref={ref} className={cn('bg-transparent border-none p-xs', className)} {...props}>
    {props.children}
  </ToastPrimitives.Action>
));
CmdToastAction.displayName = ToastPrimitives.Action.displayName;

const CmdToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close ref={ref} {...props} className={cn('bg-transparent border-none p-0 m-xs', className)}>
    <CmdButton icon={<X />} variant="inverse" />
  </ToastPrimitives.Close>
));
CmdToastClose.displayName = ToastPrimitives.Close.displayName;

const CmdToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn('text-sm font-semibold', className)} {...props} />
));
CmdToastTitle.displayName = ToastPrimitives.Title.displayName;

const CmdToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn('text-sm opacity-90', className)} {...props} />
));
CmdToastDescription.displayName = ToastPrimitives.Description.displayName;

type CmdToastProps = React.ComponentPropsWithoutRef<typeof CmdToast>;

type CmdToastActionElement = React.ReactElement<typeof CmdToastAction>;

export {
  type CmdToastProps,
  type CmdToastActionElement,
  CmdToastProvider,
  CmdToastViewport,
  CmdToast,
  CmdToastTitle,
  CmdToastDescription,
  CmdToastClose,
  CmdToastAction,
};
