import React from 'react';
import { Table } from '@tanstack/react-table';
import { CmdButton } from '../../button';
import { ChevronLeft, ChevronRight } from '@commandbar/design-system/icons/react';
import { CmdTypography } from '../../typography';

interface CmdPaginationProps<TData> {
  table: Table<TData>;
  rowCount?: number;
}

export const CmdPagination = <TData,>(props: CmdPaginationProps<TData>) => {
  const { table, rowCount = table.getFilteredRowModel().rows.length } = props;

  const paginated_item_count = (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize;

  return (
    <div className="flex items-center justify-between py-sm px-0 w-full">
      <div className="flex items-center space-sm">
        <CmdTypography.Body variant="contentMid">
          {Math.min(paginated_item_count, rowCount)} of {rowCount}
        </CmdTypography.Body>
      </div>
      <div className="flex items-center space-sm">
        <CmdButton variant="link" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          <ChevronLeft /> Previous
        </CmdButton>
        <CmdButton variant="link" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
          Next
          <ChevronRight />
        </CmdButton>
      </div>
    </div>
  );
};
