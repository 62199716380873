import {
  INudgeStepType,
  INudgeStepContentButtonBlockType,
  INudgeStepContentMarkdownBlockType,
  INudgeStepContentImageBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentSurveyTextBlockType,
  INudgeContentSurveyRatingBlockType,
  INudgeContentListBlock,
  INudgeStepContentSurveyTextShortBlockType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import sanitizeHtml from '@commandbar/internal/util/sanitizeHtml';
import dayjs from 'dayjs';
import { ICommandTableState } from '../useEditor';
import * as Command from '@commandbar/internal/middleware/command';

const mediaTypes = ['image', 'video', 'help_doc_command'];

export const isMediaBlock = (
  block: INudgeStepContentBlockType,
): block is INudgeStepContentImageBlockType | INudgeStepContentVideoBlockType | INudgeStepContentHelpDocBlockType =>
  mediaTypes.includes(block.type);

export type SurveyBlock =
  | INudgeStepContentSurveyTextBlockType
  | INudgeContentSurveyRatingBlockType
  | INudgeContentListBlock
  | INudgeStepContentSurveyTextShortBlockType;

export const isSurveyBlock = (block: INudgeStepContentBlockType): block is SurveyBlock =>
  block.type.startsWith('survey');

export const hasSurveyBlock = (nudge: INudgeType) => nudge.steps.some((step) => step.content.some(isSurveyBlock));

export const findSurveyBlocks = (
  content: INudgeStepType['content'],
): Array<
  | INudgeContentSurveyRatingBlockType
  | INudgeStepContentSurveyTextBlockType
  | INudgeStepContentSurveyTextShortBlockType
  | INudgeContentListBlock
> =>
  content.filter(
    (
      block,
    ): block is
      | INudgeContentSurveyRatingBlockType
      | INudgeStepContentSurveyTextBlockType
      | INudgeStepContentSurveyTextShortBlockType
      | INudgeContentListBlock =>
      block.type === 'survey_text' ||
      block.type === 'survey_text_short' ||
      block.type === 'survey_rating' ||
      block.type === 'survey_list',
  );

export const findMarkdownBlocks = (content: INudgeStepType['content']): INudgeStepContentMarkdownBlockType[] =>
  content.filter((block): block is INudgeStepContentMarkdownBlockType => block.type === 'markdown');

export const findImageBlocks = (content: INudgeStepType['content']): INudgeStepContentImageBlockType[] =>
  content.filter((block): block is INudgeStepContentImageBlockType => block.type === 'image');

export const findVideoBlocks = (content: INudgeStepType['content']): INudgeStepContentVideoBlockType[] =>
  content.filter((block): block is INudgeStepContentVideoBlockType => block.type === 'video');

export const findHelpDocBlocks = (content: INudgeStepType['content']): INudgeStepContentHelpDocBlockType[] =>
  content.filter((block): block is INudgeStepContentHelpDocBlockType => block.type === 'help_doc_command');

export const findButtonBlocks = (content: INudgeStepType['content']): INudgeStepContentButtonBlockType[] =>
  content.filter((block): block is INudgeStepContentButtonBlockType => block.type === 'button');

export const findLogicInputBlocks = (
  content: INudgeStepType['content'],
): (INudgeContentListBlock | INudgeContentSurveyRatingBlockType)[] =>
  content.filter(
    (
      block,
    ): block is (INudgeContentListBlock & { meta: { list_type: 'single' } }) | INudgeContentSurveyRatingBlockType =>
      (block.type === 'survey_list' && block.meta.list_type === 'single') || block.type === 'survey_rating',
  );

export const isNudgeLive = (nudge: INudgeType): boolean =>
  nudge.is_live &&
  (!nudge.scheduled_start_time || dayjs(nudge.scheduled_start_time).isBefore(dayjs())) &&
  (!nudge.scheduled_end_time || dayjs(nudge.scheduled_end_time).isAfter(dayjs()));

export const isNudgeReferencingStaleCommand = (
  nudge: INudgeType,
  commands: ICommandTableState['commands'],
  buttons?: INudgeStepContentButtonBlockType[],
): boolean => {
  if (
    !!buttons?.length &&
    buttons?.every(
      (button) =>
        button?.meta?.action?.type === 'execute_command' &&
        button?.meta?.action.meta.command &&
        !commands.find(
          (c) =>
            button?.meta?.action?.type === 'execute_command' &&
            Command.commandUID(c) === button?.meta?.action.meta.command,
        ),
    )
  ) {
    // If the nudge references a command that no longer exists, reset the field and show an error
    return true;
  }

  return (
    nudge.trigger.type === 'on_command_execution' &&
    !!nudge.trigger.meta.command &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Disabling because tslint is not recognising the meta field on the nudge
    !commands.find((c) => Command.commandUID(c) === nudge.trigger.meta.command)
  );
};

export const getSanitizedNudge = (n: INudgeType) => ({
  ...n,
  steps: n.steps.map((step) => ({
    ...step,
    content: step.content.map((content) =>
      content.type === 'markdown'
        ? {
            ...content,
            meta: {
              ...content.meta,
              value: sanitizeHtml(content.meta.value),
            },
          }
        : content,
    ),
  })),
});
