/* React imports */
import React, { Fragment } from 'react';

import { Typography } from '@commandbar/design-system/components/antd';

import { AvailabilityDependency } from 'editor/src/editor/conditions/components/AvailabilityDependency';
import { countAvailabilityConditionsAndDependencies } from 'editor/src/editor/conditions/helpers';
import { RulePrefix } from 'editor/src/editor/conditions/components/styled';
import { IEditorCommandType, IRuleExpression } from '@commandbar/internal/middleware/types';
import ConditionGroupEditor from 'editor/src/editor/conditions/ConditionGroupEditor';
import ReasonsEditor from './components/ReasonsEditor';
import { useNeedsToUpgradeFoobarPackage } from '../../../../pre-auth/compatibility';
import { CmdDivider, CmdTag } from '@commandbar/design-system/cmd';
import { Lock01 } from '@commandbar/design-system/icons/react';
import { CONDITION_TYPE_CATEGORIES_FOR_LEGACY_AVAILABILITY } from 'editor/src/editor/conditions/categories';

export const AvailabilityPanel = (props: {
  command: IEditorCommandType;
  onChange: (expr: IRuleExpression) => void;
}) => {
  const {
    command: { availability_expression },
    onChange,
  } = props;
  const { alwaysAvailable, dependencies } = countAvailabilityConditionsAndDependencies(props.command);
  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.3.17');

  return (
    <div>
      {needsToUpgrade && (
        <div style={{ marginBottom: '1rem' }}>
          <CmdTag prefixElement={<Lock01 />} variant="failure">
            Upgrade `@commandbar/foobar` package to v0.3.18 or greater to use this feature.
          </CmdTag>
        </div>
      )}
      <div style={{ marginBottom: 8 }}>
        {alwaysAvailable ? (
          <>
            <Typography.Text strong>Always available</Typography.Text>
            <Typography style={{ fontSize: 12 }}>
              Use conditions to define when this command should show in the search results.
            </Typography>
          </>
        ) : (
          <Typography.Text strong>Available when:</Typography.Text>
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        {dependencies.map(({ type, field, operator, message }, index) => {
          return (
            <Fragment key={`${type}${index}`}>
              <AvailabilityDependency
                type={type}
                field={field}
                operator={operator}
                message={message}
                prefixText={index !== 0 ? 'AND' : undefined}
              />
            </Fragment>
          );
        })}
      </div>
      {dependencies.length > 0 && (
        <div style={{ marginBottom: 16 }}>
          <CmdDivider spacing="sm">
            <RulePrefix>AND</RulePrefix>
          </CmdDivider>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
        <ConditionGroupEditor
          disabled={needsToUpgrade}
          onChange={onChange}
          expr={availability_expression}
          includeCategories={CONDITION_TYPE_CATEGORIES_FOR_LEGACY_AVAILABILITY}
          excludeConditionTypes={['user_property', 'audience']}
        />
        <ReasonsEditor disabled={needsToUpgrade} onChange={onChange} expr={availability_expression} />
      </div>

      {/* For testing purposes
         <div style={{ fontSize: 12, padding: '0px 20px' }}>
          {parsedRules.map((rule, index) => (
            <Typography key={`${rule}${index}`}>{rule}</Typography>
          ))}
        </div> */}
    </div>
  );
};
