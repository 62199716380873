import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: var(--layout-padding);
  gap: var(--layout-gap);
`;

const StyledBotChatMessageContainer = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <Container {...props}>{children}</Container>;
};

export default StyledBotChatMessageContainer;
