import * as React from 'react';
import { Input } from '.';

interface IProps extends React.ComponentProps<typeof Input> {
  validator?: (text: string) => { result: boolean; reason: string };
}

const ValidatedInput = (props: IProps) => {
  const [error, setError] = React.useState('');
  const [value, setValue] = React.useState(props.value || props.defaultValue);

  React.useEffect(() => {
    setValue(props.value || props.defaultValue);
  }, [props.value, props.defaultValue]);

  const onChange = (e: any) => {
    setValue(e.target.value);
    if (props.validator) {
      const validatorResult = props.validator(e.target.value);
      if (!validatorResult.result) {
        setError(validatorResult.reason);
        return;
      } else {
        setError('');
      }
    }
    if (props.onChange) props.onChange(e);
  };

  const { validator, ...textAreaProps } = props;
  return (
    <div style={{ flexGrow: 1 }}>
      <Input
        {...textAreaProps}
        onChange={onChange}
        value={value}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      />
      <div style={{ color: '#a61d24', fontSize: 11, fontWeight: 400 }}>{error}</div>
    </div>
  );
};

export default ValidatedInput;
