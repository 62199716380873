import React from 'react';
import { PREBUILT_SKIN_LAYOUTS, TSkinLayout } from './prebuiltSkins';
import { Tooltip, Modal } from '@commandbar/design-system/components/antd';
import { IPartialTheme } from '@commandbar/internal/client/theme';
import { Card, CardDescription, CardRowContainer, CardTitle } from './skinEditors/styled-components';

interface IProps {
  resetTheme: (theme: IPartialTheme) => void;
  isBasicTier: boolean;
}

const ConditionalTooltip = (props: any) => {
  const { condition, children, ...tooltipProps } = props;

  if (!condition) {
    return children;
  }

  return <Tooltip {...tooltipProps}>{children}</Tooltip>;
};

export const PrebuiltSkinSelector = (props: IProps) => {
  const handleLayoutChange = (_layout: TSkinLayout, layoutStyles: any) => {
    Modal.confirm({
      title: 'Warning: Starting with a template will delete any existing customizations.',
      onOk: async () => {
        await props.resetTheme(layoutStyles);
      },
      cancelText: 'Cancel',
    });
  };

  return (
    <CardRowContainer>
      {Object.entries(PREBUILT_SKIN_LAYOUTS).map(([layout, { description, theme: layoutStyles }], idx: number) => {
        return (
          <ConditionalTooltip
            key={idx}
            condition={layout === 'custom'}
            content={
              props.isBasicTier ? `Upgrade your plan to change this` : `Edit the fields below to customize the layout`
            }
          >
            <Card onClick={() => handleLayoutChange(layout as TSkinLayout, layoutStyles)}>
              <CardTitle>{layout}</CardTitle>
              <CardDescription>{description}</CardDescription>
            </Card>
          </ConditionalTooltip>
        );
      })}
    </CardRowContainer>
  );
};
