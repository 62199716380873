import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';
import { Input, Form, Select } from '@commandbar/design-system/components/antd';
import { filterDryRunData } from '../shared/utils';

const Archbee: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.ARCHBEE,
  name: 'Archbee',
  slug: 'archbee',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/archbee-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/archbee-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: null,
  route: '/archbee',

  getForm({ dryRunData, editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + '-hostname'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.hostname}
          required={true}
          label="Hostname of your Archbee site"
          name="hostname"
          tooltip={{
            title: 'The hostname of your Archbee site (e.g., docs.example.com)',
          }}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /^.*$/,
              message: `Please enter a valid hostname (e.g., docs.example.com). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} placeholder="docs.example.com" />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + '-doc_space_ids'}
          validateTrigger="onChange"
          initialValue={dryRunData}
          label="DocSpace(s) to sync"
          tooltip={{
            title:
              'Space Links are required in order to sync Archbee docs with CommandBar. You can find Space Links in your Archbee Space settings.',
          }}
          name="doc_space_ids"
          rules={[{ required: true, message: 'This is a required field.' }]}
          style={{ flex: 5 }}
          required
        >
          <Select
            size="large"
            mode="multiple"
            style={{ width: '100%' }}
            options={dryRunData}
            defaultValue={dryRunData}
            maxTagCount="responsive"
            allowClear
            labelInValue
          />
        </Form.Item>
      </>
    );
  },

  getInitialDryRunData(this, sourceId) {
    return this.sources?.find((i) => i.id === sourceId)?.meta?.doc_space_ids;
  },

  getDryRunData(result) {
    const data = JSON.parse(result.data).doc_spaces.map((docSpace: { label: string; docSpaceId: string }) => ({
      label: docSpace.label,
      value: docSpace.docSpaceId,
      key: docSpace.docSpaceId,
    }));
    return data;
  },

  getPayloadMeta(data, dryRunData) {
    const meta = this.requiredFields.reduce((a, v) => ({ ...a, [v]: data[v] }), {});
    const bonusMeta =
      dryRunData && dryRunData.length > 0 ? { doc_space_ids: filterDryRunData(data['doc_space_ids'], dryRunData) } : {};
    return { ...meta, ...bonusMeta };
  },

  requiredFields: ['hostname'],
  fetchedFields: ['doc_space_ids'],
};

export default Archbee;
