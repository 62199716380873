import React, { useState, useEffect } from 'react';

import Z from '@commandbar/internal/client/Z';

import useTheme from './useTheme';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

let currentElement: Element | null = null;
let updateMaskContainer: (() => void) | null = null;

export const showMask = (targetEl: Element) => {
  currentElement = targetEl;
  updateMaskContainer?.();
};

export const hideMask = () => {
  currentElement = null;
  updateMaskContainer?.();
};

interface MaskProps {
  element: Element | null;
}

const Mask = ({ element }: MaskProps) => {
  const { theme } = useTheme();

  const { animStyles } = useAnimatedWidget();

  const targetElRect = element?.getBoundingClientRect();
  const targetElBorderRadius = element ? getComputedStyle(element).borderRadius : '';

  return (
    <>
      {targetElRect && (
        <div
          style={{
            zIndex: Z.Z_COMMANDBAR - 5,
            position: 'absolute',
            top: '0',
            left: '0',
          }}
        >
          <div
            data-testid="commandbar-nudge-mask"
            style={{
              width: `${document.documentElement.scrollWidth}px`,
              height: `${document.documentElement.scrollHeight}px`,
              clipPath: `polygon(0% 0%, 0% 100%,
                ${targetElRect.left + window.scrollX}px 100%,
                ${targetElRect.left + window.scrollX}px ${targetElRect.top + window.scrollY}px,
                ${targetElRect.left + window.scrollX + targetElRect.width}px ${targetElRect.top + window.scrollY}px,
                ${targetElRect.left + window.scrollX + targetElRect.width}px ${
                targetElRect.top + window.scrollY + targetElRect.height
              }px,
                0% ${targetElRect.top + window.scrollY + targetElRect.height}px,
                0% 100%, 100% 100%, 100% 0%)`,
              position: 'absolute',
            }}
          />
          <div
            data-testid="commandbar-nudge-mask-inner"
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              inset: `${targetElRect.top + window.scrollY}px ${targetElRect.left + window.scrollX}px`,
              width: `${targetElRect.width}px`,
              height: `${targetElRect.height}px`,
              padding: '4px',
              borderRadius: `${targetElBorderRadius}`,
              boxShadow: `0 0 0 ${Math.max(
                document.documentElement.scrollHeight,
                document.documentElement.scrollWidth,
              )}px ${theme.nudgeModal.maskBackgroundColor}`,
              overflow: 'hidden',
              // When the animation is not instant, we need to apply a similar timing function/opacity fade to the mask, but specifically
              // ignore the transform or any other transition properties that are not opacity since the mask should not animate other than fade

              opacity: animStyles.opacity,
              transitionProperty: 'opacity',
              transitionDuration: animStyles.transitionDuration,
              transitionTimingFunction: animStyles.transitionTimingFunction,
            }}
          />
        </div>
      )}
    </>
  );
};

export const MaskContainer = () => {
  const [element, setElement] = useState(currentElement);

  useEffect(() => {
    updateMaskContainer = () => setElement(currentElement);

    return () => {
      updateMaskContainer = null;
    };
  }, []);

  return <>{element && <Mask element={currentElement} />}</>;
};
