import type {
  AnalyticsAnnouncementsData,
  AnalyticsAnnouncementsDetailData,
  AnalyticsAnnouncementsUsersData,
} from '@commandbar/internal/middleware/analytics/announcements';
import type {
  AnalyticsChecklistsData,
  AnalyticsChecklistsDetailData,
  AnalyticsChecklistsUsersData,
} from '@commandbar/internal/middleware/analytics/checklists';
import type { CardData, DateFilter } from '@commandbar/internal/middleware/analytics/common';
import type { AnalyticsCopilotData } from '@commandbar/internal/middleware/analytics/copilot';
import type { AnalyticsDeadendsData } from '@commandbar/internal/middleware/analytics/deadends';
import type { AnalyticsHelpHubData } from '@commandbar/internal/middleware/analytics/helphub';
import type { AnalyticsOverviewData } from '@commandbar/internal/middleware/analytics/overview';
import type {
  AnalyticsProductToursData,
  AnalyticsProductToursDetailData,
  AnalyticsProductToursUsersData,
} from '@commandbar/internal/middleware/analytics/productTours';
import type { AnalyticsSpotlightData } from '@commandbar/internal/middleware/analytics/spotlight';
import type { AnalyticsStrikesData } from '@commandbar/internal/middleware/analytics/strikes';
import type {
  AnalyticsSurveysData,
  AnalyticsSurveysDetailData,
  AnalyticsSurveysUsersData,
  SurveyDetailsWithResponses,
} from '@commandbar/internal/middleware/analytics/surveys';
import type { Interaction } from './dashboards/strikes/Heatmap';

export const randomPercentChange = () => (Math.random() * (0.5 - 0.0001) + 0.0001) * (Math.random() < 0.7 ? 1 : -1);
export const randomPercent = () => Math.random() * (0.1 - 0.0001) + 0.0001;
export const randomMetric = () => Math.floor(Math.random() * 500) + 1;
export const randomDate = () => {
  const now = new Date();
  const threeDaysAgo = new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000);
  return new Date(threeDaysAgo.getTime() + Math.random() * (now.getTime() - threeDaysAgo.getTime())).toISOString();
};
export const randomEndUserId = () => Math.floor(Math.random() * 1000000);

const generateCard = (): CardData => ({
  type: 'card',
  metric: randomMetric(),
  percent_change: randomPercentChange(),
});

const generatePercentCard = (): CardData => ({
  type: 'card',
  metric: randomPercent(),
  percent_change: randomPercentChange(),
});

export const getOverviewDemoData = (): AnalyticsOverviewData => ({
  nudge_engagements: generateCard(),
  checklist_engagements: generateCard(),
  survey_responses: generateCard(),
  helphub_engagements: generateCard(),
  copilot_opens: generateCard(),
  spotlight_opens: generateCard(),
});

export const getAnnouncementsDemoData = (): AnalyticsAnnouncementsData => {
  return {
    active: generateCard(),
    views: generateCard(),
    snooze_rate: generatePercentCard(),
    table: {
      type: 'table',
      columnIds: ['nudge_slug', 'total_views', 'snooze_rate', 'last_shown'],
      rows: [
        {
          nudge_slug: 'New feature',
          total_views: randomMetric(),
          snooze_rate: randomPercent(),
          last_shown: randomDate(),
        },
        {
          nudge_slug: 'Upcoming webinar',
          total_views: randomMetric(),
          snooze_rate: randomPercent(),
          last_shown: randomDate(),
        },
      ],
      meta: undefined,
    },
  };
};

export const getAnnouncementsDetailsDemoData = (): AnalyticsAnnouncementsDetailData => {
  return {
    sessions: generateCard(),
    completion_rate: generatePercentCard(),
    snooze_rate: generatePercentCard(),
    survey_table: null,
    end_user_funnel: {
      type: 'funnel',
      steps: [
        {
          label: 'Announcement Viewed',
          count: randomMetric(),
        },
        {
          label: 'Engaged with Content',
          count: randomMetric(),
        },
        {
          label: 'Followed Call to Action',
          count: randomMetric(),
        },
        {
          label: 'Feedback Given',
          count: randomMetric(),
        },
      ],
    },
    announcementName: 'Demo Announcement Detail',
    goal: generateCard(),
  };
};

export const getAnnouncementsUsersDemoData = (): AnalyticsAnnouncementsUsersData => ({
  views: {
    type: 'table',
    meta: undefined,
    columnIds: ['end_user', 'event_name', 'timestamp'],
    rows: generateViewsData(),
  },
});

export const getChecklistDemoData = (): AnalyticsChecklistsData => {
  return {
    active: generateCard(),
    completion_rate: generatePercentCard(),
    table: {
      type: 'table',
      columnIds: ['title', 'total_items', 'total_views', 'completion_rate', 'last_shown'],
      rows: [
        {
          title: 'Onboarding',
          total_items: 4,
          total_views: randomMetric(),
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          checklist_id: 'onboarding',
        },
        {
          title: 'Advanced setup',
          total_items: 7,
          total_views: randomMetric(),
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          checklist_id: 'advanced_setup',
        },
      ],
      meta: undefined,
    },
  };
};

export const getChecklistDetailsDemoData = (): AnalyticsChecklistsDetailData => {
  return {
    completion_rate: generatePercentCard(),
    views: generateCard(),
    end_user_funnel: {
      type: 'funnel',
      steps: [
        {
          label: 'Setup 2FA',
          count: randomMetric(),
        },
        {
          label: 'Connect your app',
          count: randomMetric(),
        },
        {
          label: 'Create a report',
          count: randomMetric(),
        },
        {
          label: 'Share a report',
          count: randomMetric(),
        },
      ],
    },
    checklistName: 'Demo Checklist',
    table: {
      type: 'table',
      columnIds: ['title', 'total_completions', 'completion_rate'],
      rows: [
        {
          title: 'Onboarding',
          total_completions: randomMetric(),
          completion_rate: randomPercent(),
        },
        {
          title: 'Team setup',
          total_completions: randomMetric(),
          completion_rate: randomPercent(),
        },
        {
          title: 'Vendor setup',
          total_completions: randomMetric(),
          completion_rate: randomPercent(),
        },
      ],
      meta: undefined,
    },
  };
};

export const getChecklistUsersDemoData = (): AnalyticsChecklistsUsersData => ({
  views: {
    type: 'table',
    columnIds: ['end_user', 'action', 'created_timestamp'],
    rows: [
      {
        end_user: 'dilly@commandbar.com',
        event_name: 'Viewed',
        created_timestamp: '2024-05-20T10:58:38.623874Z',
      },
      {
        end_user: 'dilly@commandbar.com',
        event_name: 'Completed',
        created_timestamp: '2024-05-20T10:59:38.623874Z',
      },
    ],
    meta: undefined,
  },
});

export const getSpotlightDemoData = (timeFilterRange: DateFilter): AnalyticsSpotlightData => {
  let currentDate = new Date(timeFilterRange.start_date);
  const end = new Date(timeFilterRange.end_date);

  const entries = [];

  while (currentDate <= end) {
    const formattedDate = currentDate.toISOString().split('T')[0];

    entries.push({
      date: formattedDate,
      inaction: randomMetric(),
      action_clicks_count: randomMetric(),
      link_clicked_count: randomMetric(),
    });

    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return {
    stacked_bar_chart_data: {
      type: 'chart',
      chartType: 'stacked_bar',
      data: entries,
    },
    page_clicks_table: {
      type: 'table',
      columnIds: ['commandtext', 'occurrence_count'],
      rows: [
        { commandtext: 'Dashboard Overview', occurrence_count: randomMetric() },
        { commandtext: 'Employee Management', occurrence_count: randomMetric() },
        { commandtext: 'Payroll Scheduling', occurrence_count: randomMetric() },
        { commandtext: 'Automated Tax Filings', occurrence_count: randomMetric() },
        { commandtext: 'Benefits Setup', occurrence_count: randomMetric() },
        { commandtext: 'Time Off Tracking', occurrence_count: randomMetric() },
        { commandtext: 'Payroll Analytics', occurrence_count: randomMetric() },
        { commandtext: 'User Permissions', occurrence_count: randomMetric() },
        { commandtext: 'Integration Hub', occurrence_count: randomMetric() },
        { commandtext: 'Support Resources', occurrence_count: randomMetric() },
      ],
      meta: undefined,
    },
    action_clicks_table: {
      type: 'table',
      columnIds: ['commandtext', 'occurrence_count'],
      rows: [
        { commandtext: 'Automate Taxes', occurrence_count: randomMetric() },
        { commandtext: 'Manage Profiles', occurrence_count: randomMetric() },
        { commandtext: 'Direct Deposit', occurrence_count: randomMetric() },
        { commandtext: 'Track Time Off', occurrence_count: randomMetric() },
        { commandtext: 'Generate Reports', occurrence_count: randomMetric() },
        { commandtext: 'Integrate Software', occurrence_count: randomMetric() },
        { commandtext: 'Customize Schedules', occurrence_count: randomMetric() },
        { commandtext: 'Secure Access', occurrence_count: randomMetric() },
        { commandtext: 'Self-Service Options', occurrence_count: randomMetric() },
      ],
      meta: undefined,
    },
    opens_total: generateCard(),
    links_total: generateCard(),
    actions_total: generateCard(),
    copilot_queries_total: generateCard(),
    success_rate: generatePercentCard(),
  };
};

export const getHelpHubDemoData = (): AnalyticsHelpHubData => {
  return {
    helphub_opens: generateCard(),
    helphub_doc_opens: generateCard(),
    searches: {
      type: 'table',
      columnIds: ['query', 'search_count'],
      rows: [
        { query: 'Payroll software features', search_count: randomMetric() },
        { query: 'Employee payment processing', search_count: randomMetric() },
        { query: 'Automated tax calculation', search_count: randomMetric() },
        { query: 'Payroll compliance regulations', search_count: randomMetric() },
        { query: 'Direct deposit setup', search_count: randomMetric() },
        { query: 'Payroll reporting tools', search_count: randomMetric() },
        { query: 'Time tracking integration', search_count: randomMetric() },
        { query: 'Employee self-service portal', search_count: randomMetric() },
        { query: 'Payroll mobile app', search_count: randomMetric() },
        { query: 'Payroll software pricing', search_count: randomMetric() },
        { query: 'Customizable pay schedules', search_count: randomMetric() },
        { query: 'Secure payroll solutions', search_count: randomMetric() },
        { query: 'Payroll fraud prevention', search_count: randomMetric() },
        { query: 'Multi-country payroll support', search_count: randomMetric() },
        { query: 'Payroll system scalability', search_count: randomMetric() },
      ],
      meta: undefined,
    },
    articles: {
      type: 'table',
      columnIds: ['commandtext', 'views'],
      rows: [
        { commandText: "Understanding Your Payroll Software's Key Features", views: randomMetric() },
        { commandText: 'Streamlining Employee Payment Processes: A Comprehensive Guide', views: randomMetric() },
        { commandText: 'How to Automate Tax Calculations in Your Payroll System', views: randomMetric() },
        { commandText: 'Navigating Payroll Compliance: Regulations You Need to Know', views: randomMetric() },
        { commandText: 'Setting Up Direct Deposit: A Step-by-Step Guide for Employers', views: randomMetric() },
        { commandText: 'Leveraging Payroll Reporting Tools for Better Financial Insights', views: randomMetric() },
        { commandText: 'Integrating Time Tracking with Your Payroll Software', views: randomMetric() },
        { commandText: 'Empowering Employees: How to Use Your Self-Service Portal', views: randomMetric() },
        { commandText: "Stay Connected: How to Use Your Payroll Software's Mobile App", views: randomMetric() },
        { commandText: 'Comparing Payroll Software Pricing: What You Need to Consider', views: randomMetric() },
        { commandText: 'How to Customize Pay Schedules to Fit Your Business Needs', views: randomMetric() },
        { commandText: 'Ensuring Your Payroll Solutions Are Secure: Best Practices', views: randomMetric() },
        { commandText: 'Preventing Payroll Fraud: Strategies for Every Business', views: randomMetric() },
        { commandText: 'Managing Multi-Country Payroll: Challenges and Solutions', views: randomMetric() },
        { commandText: 'Scaling Your Payroll System: Strategies for Growing Businesses', views: randomMetric() },
      ],
      meta: undefined,
    },
  };
};

export const getProductTourDemoData = (): AnalyticsProductToursData => {
  return {
    active: generateCard(),
    sessions: generateCard(),
    completion_rate: generatePercentCard(),
    table: {
      type: 'table',
      columnIds: ['nudge_slug', 'total_steps', 'completion_rate', 'last_shown'],
      rows: [
        {
          nudge_slug: 'Quick Start Guide',
          total_sessions: 125,
          total_steps: 4,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Mastering Payroll Basics',
          total_sessions: 30,
          total_steps: 3,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Advanced Features Tour',
          total_sessions: 108,
          total_steps: 4,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Tax Management Guide',
          total_sessions: 47,
          total_steps: 2,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Empower with Self-Service',
          total_sessions: 15,
          total_steps: 3,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Time Tracking How-To',
          total_sessions: 60,
          total_steps: 4,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Compliance and Reporting',
          total_sessions: 75,
          total_steps: 2,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Direct Deposit Setup',
          total_sessions: 15,
          total_steps: 2,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Customizing Your Payroll',
          total_sessions: 15,
          total_steps: 2,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
        {
          nudge_slug: 'Payroll Security Insights',
          total_sessions: 80,
          total_steps: 4,
          completion_rate: randomPercent(),
          last_shown: randomDate(),
          nudge_id: 'demo_product_tour',
        },
      ],
      meta: undefined,
    },
  };
};

export const getProductTourDetailsDemoData = (): AnalyticsProductToursDetailData => {
  return {
    sessions: generateCard(),
    completion_rate: generatePercentCard(),
    snooze_rate: generatePercentCard(),
    end_user_funnel: {
      type: 'funnel',
      steps: [
        {
          label: 'Welcome',
          count: randomMetric(),
        },
        {
          label: 'Payroll overview',
          count: randomMetric(),
        },
        {
          label: 'Adding vendors',
          count: randomMetric(),
        },
        {
          label: 'Continuing exploring',
          count: randomMetric(),
        },
      ],
    },
    tourName: 'Demo Product Tour Detail',
    survey_table: null,
    goal: generateCard(),
  };
};

export const getProductTourUsersDemoData = (): AnalyticsProductToursUsersData => ({
  views: {
    type: 'table',
    meta: undefined,
    columnIds: ['end_user', 'event_name', 'timestamp'],
    rows: generateViewsData(),
  },
});

export const getSurveyDemoData = (): AnalyticsSurveysData => {
  return {
    active: generateCard(),
    responses: generateCard(),
    completion_rate: generatePercentCard(),
    table: {
      type: 'table',
      meta: undefined,
      columnIds: ['nudge_slug', 'nudge_id', 'total_responses', 'completion_rate', 'last_shown'],
      rows: [
        {
          nudge_slug: 'How was your onboarding?',
          nudge_id: 'demo_survey_data',
          total_responses: randomMetric(),
          completion_rate: randomPercent(),
          last_shown: '2024-06-03T10:58:38.623874Z',
        },
        {
          nudge_slug: 'NPS Q2 Survey',
          nudge_id: 'demo_survey_data_nps',
          total_responses: 490,
          completion_rate: 0.48,
          last_shown: '2024-06-01T10:58:38.623874Z',
        },
        {
          nudge_slug: 'New feature feedback',
          nudge_id: 'demo_survey_data',
          total_responses: randomMetric(),
          completion_rate: randomPercent(),
          last_shown: '2024-05-23T10:58:38.623874Z',
        },
        {
          nudge_slug: 'Banking provider',
          nudge_id: 'demo_survey_data',
          total_responses: randomMetric(),
          completion_rate: randomPercent(),
          last_shown: '2024-05-04T10:58:38.623874Z',
        },
      ],
    },
  };
};

export const getSurveyDetailsDemoData = (showNps: boolean): AnalyticsSurveysDetailData => {
  return showNps ? getSurveyDetailsNpsDemoData() : getSurveyDetailsSurveyDemoData();
};

export const getSurveyUsersDemoData = (showNps: boolean): AnalyticsSurveysUsersData =>
  showNps ? getSurveyUsersNpsDemoData() : getSurveyUsersSurveyDemoData();

export const getSurveyDetailsSurveyDemoData = (): AnalyticsSurveysDetailData => {
  return {
    total_responses: generateCard(),
    total_views: generateCard(),
    completion_rate: generatePercentCard(),
    end_user_funnel: {
      type: 'funnel',
      steps: [
        {
          label: 'Welcome',
          count: 7,
        },
        {
          label: 'Your suggestions',
          count: randomMetric(),
        },
      ],
    },
    table: {
      type: 'table',
      meta: undefined,
      columnIds: ['step_title', 'id', 'responses', 'response_rate'],
      rows: [
        {
          step_title: 'How was your onboarding?',
          id: 'demo_survey_data',
          responses: 7,
          response_rate: randomPercent(),
        },
        {
          step_title: 'Your suggestions',
          id: 'demo_survey_data',
          responses: 3,
          response_rate: randomPercent(),
        },
      ],
    },
    nps: undefined,
    nps_chart: undefined,
    goal: generateCard(),
  };
};

export const getSurveyUsersSurveyDemoData = (): AnalyticsSurveysUsersData => ({
  views: {
    type: 'table',
    meta: undefined,
    columnIds: ['end_user', 'event_name', 'timestamp'],
    rows: generateViewsData(),
  },
});

export const getSurveyDetailsNpsDemoData = (): AnalyticsSurveysDetailData => {
  return {
    total_responses: {
      metric: 490,
      percent_change: randomPercentChange(),
      type: 'card',
    },
    total_views: {
      metric: 500,
      percent_change: randomPercentChange(),
      type: 'card',
    },
    completion_rate: {
      metric: 0.48,
      percent_change: randomPercentChange(),
      type: 'card',
    },
    end_user_funnel: {
      type: 'funnel',
      steps: [
        {
          label: 'Step 1',
          count: 490,
        },
        {
          label: 'Step 2',
          count: 112,
        },
      ],
    },
    table: {
      type: 'table',
      meta: undefined,
      columnIds: ['step_title', 'id', 'responses', 'response_rate'],
      rows: [
        {
          step_title: 'Can you rate your recent experience?',
          id: 'demo_survey_data',
          responses: 10,
          response_rate: randomPercent(),
        },
        {
          step_title: 'Any suggestions for improvements?',
          id: 'demo_survey_data',
          responses: 4,
          response_rate: randomPercent(),
        },
      ],
    },
    nps: {
      metric: 41,
      percent_change: randomPercentChange(),
      type: 'card',
    },
    nps_chart: {
      type: 'chart',
      chartType: 'horizontal_chart',
      data: [
        { key: 'detractors', type: 'card', metric: 64, percent_change: randomPercentChange(), percent: 0.13 },
        { key: 'passives', type: 'card', metric: 161, percent_change: randomPercentChange(), percent: 0.33 },
        { key: 'promoters', type: 'card', metric: 265, percent_change: randomPercentChange(), percent: 0.54 },
      ],
    },
    goal: generateCard(),
  };
};

export const getSurveyUsersNpsDemoData = (): AnalyticsSurveysUsersData => ({
  views: {
    type: 'table',
    meta: undefined,
    columnIds: ['end_user', 'event_name', 'timestamp'],
    rows: generateViewsData(),
  },
});

export const getCopilotDemoData = (timeFilterRange: DateFilter): AnalyticsCopilotData => {
  let currentDate = new Date(timeFilterRange.start_date);
  const end = new Date(timeFilterRange.end_date);

  const entries: { [key: string]: number } = {};

  while (currentDate <= end) {
    const formattedDate = currentDate.toISOString().split('T')[0];

    entries[formattedDate] = randomMetric();

    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return {
    total_opens: generateCard(),
    unique_users: generateCard(),
    success_rate: {
      type: 'card',
      metric: Math.random() * (1 - 0.8) + 0.8,
      percent_change: randomPercentChange(),
    },
    opens_bar_chart: {
      type: 'chart',
      chartType: 'bar',
      data: entries,
    },
  };
};

export const getSurveyDetailResponseDemoData = (showNPS: boolean): SurveyDetailsWithResponses => {
  return showNPS ? getSurveyDetailResponseNPSDemoData() : getSurveyDetailResponseSurveyDemoData();
};

export const getSurveyDetailResponseSurveyDemoData = (): SurveyDetailsWithResponses => {
  return {
    nudge_id: -1,
    nudge_steps: [
      {
        id: -1,
        title: 'How was your onboarding?',
        body: '',
        survey_details: {
          meta: {
            type: 'emojis',
            emojis: ['🙁', '🙂', '🤩'],
            options: 3,
            lower_label: 'Not great',
            upper_label: 'GOAT onboarding',
          },
          type: 'survey_rating',
          sort_key: 1,
        },
        responses: [
          {
            created_timestamp: '2024-05-21T18:27:15.650894Z',
            end_user: 'murphy@commandbar.com',
            value: 2,
          },
          {
            end_user: 'dilly@commandbar.com',
            created_timestamp: '2024-05-20T10:58:38.623874Z',
            value: 3,
          },
          {
            end_user: 'andy@commandbar.com',
            created_timestamp: '2024-05-17T12:41:36.341145Z',

            value: 3,
          },
          {
            end_user: 'alfredo@commandbar.com',
            created_timestamp: '2024-05-15T11:13:26.664877Z',
            value: 3,
          },
          {
            end_user: 'figgy@commandbar.com',
            created_timestamp: '2024-05-14T11:37:00.499872Z',
            value: 1,
          },
          {
            end_user: 'ozzy@commandbar.com',
            created_timestamp: '2024-05-13T18:10:24.976555Z',
            value: 2,
          },
          {
            end_user: 'kiwi@commandbar.com',
            created_timestamp: '2024-05-11T18:37:42.235421Z',
            value: 3,
          },
        ],
      },

      {
        id: -2,
        title: 'Your suggestions',
        body: 'How can we improve?',
        survey_details: {
          meta: {
            prompt: 'better helpdocs',
          },
          type: 'survey_text',
          sort_key: 1,
        },
        responses: [
          {
            created_timestamp: '2024-05-21T18:27:15.650894Z',
            end_user: 'murphy@commandbar.com',
            value: 'need better shortcuts',
          },
          {
            end_user: 'dilly@commandbar.com',
            created_timestamp: '2024-05-20T10:58:38.623874Z',
            value: 'no notes, everything was awesome',
          },
          {
            end_user: 'andy@commandbar.com',
            created_timestamp: '2024-05-17T12:41:36.341145Z',
            value: 'it was easy to navigate to my settings',
          },
        ],
      },
    ],
  };
};

export const getSurveyDetailResponseNPSDemoData = (): SurveyDetailsWithResponses => {
  return {
    nudge_id: -1,
    nudge_steps: [
      {
        id: -1,
        title: 'Can you rate your recent experience?',
        body: '',
        survey_details: {
          meta: {
            type: 'nps',
            options: 10,
            lower_label: 'Not great',
            upper_label: 'GOAT',
          },
          type: 'survey_rating',
          sort_key: 1,
        },
        responses: [
          {
            created_timestamp: '2024-05-21T18:27:15.650894Z',
            end_user: 'john.doe@commandbar.com',
            value: 8,
          },
          {
            end_user: 'sara.klein@commandbar.com',
            created_timestamp: '2024-05-20T10:58:38.623874Z',
            value: 6,
          },
          {
            end_user: 'mike123@commandbar.com',
            created_timestamp: '2024-05-17T12:41:36.341145Z',
            value: 9,
          },

          {
            end_user: 'emily.james@commandbar.com',
            created_timestamp: '2024-05-15T11:13:26.664877Z',
            value: 7,
          },
          {
            end_user: 'chris.wallace@commandbar.com',
            created_timestamp: '2024-05-14T11:37:00.499872Z',
            value: 10,
          },
          {
            end_user: 'lisa.wright@commandbar.com',
            created_timestamp: '2024-05-13T18:10:24.976555Z',
            value: 5,
          },
          {
            end_user: 'tom.black@commandbar.com',
            created_timestamp: '2024-05-11T18:37:42.235421Z',
            value: 3,
          },
          {
            end_user: 'nancy.smith@commandbar.com',
            created_timestamp: '2024-05-11T18:37:42.235421Z',
            value: 9,
          },
          {
            end_user: 'alex.jordan@commandbar.com',
            created_timestamp: '2024-05-11T18:37:42.235421Z',
            value: 4,
          },
          {
            end_user: 'rebecca.moore@commandbar.com',
            created_timestamp: '2024-05-11T18:37:42.235421Z',
            value: 7,
          },
        ],
      },

      {
        id: -2,
        title: 'Any suggestions for improvement?',
        body: 'How can we improve?',
        survey_details: {
          meta: {
            prompt: 'better helpdocs',
          },
          type: 'survey_text',
          sort_key: 1,
        },
        responses: [
          {
            created_timestamp: '2024-05-21T18:27:15.650894Z',
            end_user: 'sara.klein@commandbar.com',
            value: 'Useful features but the setup process was more complicated than expected.',
          },
          {
            end_user: 'mike123@commandbar.com',
            created_timestamp: '2024-05-20T10:58:38.623874Z',
            value: 'Excellent tool! Saves a lot of time and reduces errors in payroll management.',
          },
          {
            end_user: 'emily.james@commandbar.com',
            created_timestamp: '2024-05-17T12:41:36.341145Z',
            value: 'Good functionality, though could use more customization options.',
          },
          {
            end_user: 'chris.wallace@commandbar.com',
            created_timestamp: '2024-05-17T12:23:36.341145Z',
            value: 'Absolutely love PaySoft! It has transformed how we do our payroll.',
          },
        ],
      },
    ],
  };
};

export const getDeadendsDemoData = (): AnalyticsDeadendsData => {
  return {
    table: {
      type: 'table',
      columnIds: ['id', 'name', 'type', 'end_user', 'created_timestamp', 'page', 'source', 'query', 'result_type'],
      rows: [
        {
          id: '',
          name: '',
          type: '',
          end_user: 'hello@commandbar.com',
          created_timestamp: randomDate(),
          page: 'https://www.commandbar.com/home',
          source: 'copilot',
          query: 'Where can i see amplitude events after integration',
          result_type: 'No result',
        },
        {
          id: '',
          name: '',
          type: '',
          end_user: 'hello@commandbar.com',
          created_timestamp: randomDate(),
          page: 'https://www.commandbar.com/dashboard/integrations',
          source: 'copilot',
          query: 'What integrations are available?',
          result_type: 'Unhelpful result',
        },
        {
          id: '',
          name: '',
          type: '',
          end_user: 'hello@commandbar.com',
          created_timestamp: randomDate(),
          page: 'https://www.commandbar.com/dashboard/integrations',
          source: 'bar',
          query: 'toggle lightmode',
          result_type: 'No result',
        },
        {
          id: '',
          name: '',
          type: '',
          end_user: 'hello@commandbar.com',
          created_timestamp: randomDate(),
          page: 'https://www.commandbar.com/dashboard/integrations',
          source: 'helphub',
          query: 'Theme settings',
          result_type: 'Unhelpful result',
        },
      ],
      meta: undefined,
    },
  };
};

const nudgeSlugs = [
  'Lets take a tour or your dashboard',
  'How would you rate Foobar?',
  'Say hello to your new library 📚',
];

export const getStrikesDemoData = (): AnalyticsStrikesData => ({
  strike_rate: generatePercentCard(),
  heatmap: {
    type: 'table',
    meta: undefined,
    columnIds: ['is_strike', 'end_user', 'timestamp'],
    rows: new Array(28 * 6).fill(0).map<Interaction>((_, i) => {
      const rand = Math.random();

      return {
        nudge_id: 1,
        nudge_slug: nudgeSlugs[Math.floor(Math.random() * nudgeSlugs.length)],
        nudge_type: 'product_tour',
        num_strikes: rand > 0.1 ? 0 : 1,
        num_completed: rand > 0.3 ? 0 : 1,
        num_dismissed: Math.floor(rand * 5),
        end_user: randomEndUserId().toString(),
        first_seen: new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * (28 - Math.floor(i / 6))).toISOString(),
      };
    }),
  },
  table: {
    type: 'table',
    columnIds: ['nudge_id', 'nudge_slug', 'nudge_type', 'total_sessions', 'total_strikes', 'strike_rate', 'last_shown'],
    rows: [
      {
        nudge_id: 'nudge_id',
        nudge_slug: 'Lets take a tour or your dashboard',
        nudge_type: 'product_tour',
        total_sessions: randomMetric(),
        total_strikes: randomMetric(),
        strike_rate: randomPercent(),
        last_shown: randomDate(),
      },
      {
        nudge_id: 'nudge_id',
        nudge_slug: 'How would you rate Foobar?',
        nudge_type: 'survey',
        total_sessions: randomMetric(),
        total_strikes: randomMetric(),
        strike_rate: randomPercent(),
        last_shown: randomDate(),
      },
      {
        nudge_id: 'nudge_id',
        nudge_slug: 'Say hello to your new library 📚',
        nudge_type: 'announcement',
        total_sessions: randomMetric(),
        total_strikes: randomMetric(),
        strike_rate: randomPercent(),
        last_shown: randomDate(),
      },
    ],
    meta: undefined,
  },
});

const randomEmail = () => `test_${Math.floor(Math.random() * 10)}@commandbar.com`;
const randomEvent = () => ['Dismissed', 'Completed', 'Striked', 'Viewed'][Math.floor(Math.random() * 4)];

const generateViewsData = () =>
  Array.from({ length: 20 }, () => ({ end_user: randomEmail(), event_name: randomEvent(), timestamp: randomDate() }));
