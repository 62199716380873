import chroma, { Color } from 'chroma-js';
import { FormFactor } from './CommandBarClientSDK';
import generateBaseTheme from './themes/base';
import generateInlineBaseTheme from './themes/inline';
import generateModalBaseTheme from './themes/modal';

export type IBaseVars = {
  primary: Color;
  background: Color;
  fontColor: Color;
  fontFamily: string;
  fontSize: number;
};

export const DEFAULTS = {
  primary: 'rgb(174, 177, 221)',
  background: 'rgba(255,255,255, 1.0)',
  fontColor: 'rgba(0,0,0,0.85)',
  fontFamily: 'Avenir Next, proxima-nova, sans-serif',
  fontSize: '14px',
};

export const DEFAULTS_LIGHT = DEFAULTS;

export const DEFAULTS_DARK = {
  primary: 'rgb(174, 177, 221)',
  background: 'rgba(36, 35, 41, 1)',
  fontColor: 'rgba(255,255,255,0.85)',
  fontFamily: 'Avenir Next, proxima-nova, sans-serif',
  fontSize: '14px',
};

export type ITheme = ReturnType<typeof generateBaseTheme>;

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type IPartialTheme = DeepPartial<ITheme>;

const getDefaultColor = (attribute: 'primary' | 'background' | 'fontColor') => {
  return (userValue: string | null | undefined): Color => {
    const _default: string = DEFAULTS[attribute];
    let _color = chroma(_default);

    if (!!userValue) {
      try {
        _color = chroma(userValue);
      } catch {}
    }

    return _color;
  };
};

export const toPx = (n: number): string => {
  return `${n}px`;
};

const getThemeBaseVars = (userTheme: IPartialTheme): IBaseVars => {
  return {
    primary: getDefaultColor('primary')(userTheme.base?.primary),
    background: getDefaultColor('background')(userTheme.base?.background),
    fontColor: getDefaultColor('fontColor')(userTheme.base?.fontColor),
    fontFamily: userTheme.base?.fontFamily || DEFAULTS.fontFamily,
    fontSize: parseInt(userTheme.base?.fontSize || DEFAULTS.fontSize, 10),
  };
};

export const generateBaseThemeFromUserTheme = (
  userTheme: IPartialTheme,
  barFormFactor: FormFactor = { type: 'modal' },
): ITheme => {
  const baseVars = getThemeBaseVars(userTheme);

  switch (barFormFactor.type) {
    case 'modal':
      return generateModalBaseTheme(baseVars);
    case 'inline':
      return generateInlineBaseTheme(baseVars);
  }
};

export const generateTheme = (userTheme: IPartialTheme, barFormFactor: FormFactor = { type: 'modal' }): ITheme => {
  const baseTheme = generateBaseThemeFromUserTheme(userTheme, barFormFactor);
  // FIXME: Can use lodash.mergeWith to add a validator function to throw an error if there are mismatched types
  return mergeTheme(userTheme, baseTheme);
};

export const mergeTheme = (userTheme: IPartialTheme, baseTheme: ITheme) => {
  const baseThemeCopy: ITheme = JSON.parse(JSON.stringify(baseTheme));
  Object.keys(baseThemeCopy).forEach((category: string) => {
    // @ts-expect-error: FIXME theme types
    Object.keys(baseThemeCopy[category]).forEach((attribute: string) => {
      // @ts-expect-error: FIXME theme types
      const userValue = userTheme?.[category]?.[attribute];
      // overwrite value with userTheme
      if (!!userValue) {
        // @ts-expect-error: FIXME theme types
        baseThemeCopy[category][attribute] = userValue;
      }
    });
  });
  return baseThemeCopy;
};
