import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import {
  AnalyticsChecklists,
  type AnalyticsChecklistsDetailData,
} from '@commandbar/internal/middleware/analytics/checklists';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { getChecklistDetailsDemoData } from '../../analyticDemoData';
import { useCSVDownload } from '../../useCSVDownload';

export const useAnalyticChecklistDetails = ({ id }: { id: string }) => {
  const [data, setData] = useState<AnalyticsChecklistsDetailData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'checklists_details',
    download: () =>
      AnalyticsChecklists.downloadDetail(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        include_admin_data: includeAdminData.toString(),
        show_unpublished: 'false',
      }),
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    AnalyticsChecklists.readDetail(id, {
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, includeAdminData, timeFilterRange]);

  useEffect(() => {
    if (isDemoData) {
      setData(getChecklistDetailsDemoData());
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [fetchData, isDemoData]);

  return {
    data,
    isLoading,
    error,
    fetchData,
    downloadCsv,
    isDownloading,
  };
};
