import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { CmdLabel } from '@commandbar/design-system/cmd';
import { CB_COLORS, Checkbox, Col, Form, Row, Select } from '@commandbar/design-system/components/antd';
import * as Organization from '@commandbar/internal/middleware/organization';
import type {
  ICommandCategoryType,
  IHelpDocsIntegrationType,
  IOrganizationSettingsType,
} from '@commandbar/internal/middleware/types';
import { FormItemHorizontal } from 'commandbar.com/src/components/integrations/shared/styles';
import type { IntegrationSpec } from 'commandbar.com/src/components/integrations/shared/types';
import { useAppContext } from 'editor/src/AppStateContext';

interface IProps {
  organizationID: string | undefined;
  allowedHelpSyncSchedule?: IOrganizationSettingsType['help_center_sync'];
  form: any;
  editingSource: IHelpDocsIntegrationType | undefined;
  type: IntegrationSpec['slug'];
}

const SourceSettingsForm = (props: IProps) => {
  const history = useHistory();
  const { organization } = useAppContext();
  const default_live = Form.useWatch('default_live', props.form);
  const default_category = Form.useWatch('default_category', props.form);
  const [categories, setCategories] = React.useState<ICommandCategoryType[]>([]);

  const loadCategories = async () => {
    if (props.organizationID) {
      const c = await Organization.listCommandCategories(props.organizationID);
      setCategories(c);
    }
  };

  React.useEffect(() => {
    loadCategories();

    // If there is a token in the query params then the user is either updating an existing OAuth integration or creating a new one
    const params = new URLSearchParams(history.location.search);
    if (!params.get('token')) {
      history.replace({ search: '' });
    }
  }, []);

  if (!categories.length) {
    return null;
  }

  return (
    <div style={{ marginBottom: '40px' }}>
      <Form.Item
        key="schedule"
        name="schedule"
        label="Sync frequency"
        initialValue={props.editingSource?.schedule || 'none'}
        rules={[{ required: true, message: 'Select a sync schedule.' }]}
        tooltip={{
          title: `If set to daily or weekly, an automated sync with ${props.editingSource?.meta?.name} will be run at
                    that frequency. You will still be able to manually trigger sync even if a schedule is selected.`,
        }}
      >
        <Select size="large" disabled={props.allowedHelpSyncSchedule === 'upload-once'}>
          <Select.Option value="none">Manual</Select.Option>
          {props.type !== 'web_v2' && (
            <Select.Option value="daily" disabled={props.allowedHelpSyncSchedule !== 'automatic'}>
              Daily
            </Select.Option>
          )}
          <Select.Option value="weekly" disabled={props.allowedHelpSyncSchedule !== 'automatic'}>
            Weekly
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        validateTrigger="onSubmit"
        required={true}
        initialValue={props.editingSource?.meta ? props.editingSource?.meta?.default_live : true}
        key={'default_live'}
        label={'Default visibility'}
        help={
          !!props.editingSource && props.editingSource?.meta?.default_live !== default_live
            ? 'The new default status will only be applied to new commands'
            : undefined
        }
        name={'default_live'}
        tooltip={{
          title: 'Live content will be visible immediately',
        }}
      >
        <Select size="large">
          <Select.Option value={true}>Live</Select.Option>
          <Select.Option value={false}>Draft</Select.Option>
        </Select>
      </Form.Item>
      {organization.in_bar_doc_search && (
        <Form.Item
          validateTrigger="onSubmit"
          required={true}
          initialValue={
            props.editingSource?.meta?.default_category ||
            categories.find((c) => c.name === 'Help')?.id ||
            categories[0]?.id
          }
          key={'default_category'}
          label={'Category'}
          help={
            !!props.editingSource && props.editingSource?.meta?.default_category !== default_category
              ? 'The new default category will only be applied to new commands'
              : undefined
          }
          name={'default_category'}
          rules={[{ required: true, message: 'Select a default category.' }]}
          tooltip={{
            title: 'Default category for newly added articles',
          }}
        >
          <Select size="large" style={{ width: '100%' }}>
            {categories.map((category) => (
              <Select.Option key={category.name} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {props.allowedHelpSyncSchedule === 'manual' && (
        <div style={{ fontSize: 12, color: `rgb(133, 139, 154)`, marginTop: '3px' }}>
          Your plan only allows for manual syncs. To automatically sync your help center on a schedule, visit your
          billing dashboard.
        </div>
      )}
      {props.allowedHelpSyncSchedule === 'upload-once' && (
        <div style={{ fontSize: 12, color: `rgb(133, 139, 154)`, marginTop: '3px' }}>
          Your plan only allows for a single sync. To perform continuous syncing as your help center changes, visit your
          billing dashboard.
        </div>
      )}

      {props.editingSource?.meta?.show_preview && (
        <Form.Item
          validateTrigger="onSubmit"
          required={true}
          initialValue={props.editingSource?.meta ? props.editingSource?.meta?.show_preview : false}
          key={'show_preview'}
          label={'Show Previews'}
          name={'show_preview'}
          style={{ marginBottom: 0 }}
          tooltip={{ title: 'If set to "Show" a preview of the help article will be shown in the Bar' }}
        >
          <SettingsPreviewSwitch
            off={{
              text: 'No previews',
              url: 'https://staticassets.commandbar.com/site-assets/integrations/configure/no-preview.svg',
            }}
            on={{
              text: 'Show previews on hover',
              url: 'https://staticassets.commandbar.com/site-assets/integrations/configure/preview.svg',
            }}
          />
        </Form.Item>
      )}

      <FormItemHorizontal
        key="training_only"
        name="training_only"
        valuePropName="checked"
        initialValue={!!props.editingSource?.meta?.training_only}
        label={
          <CmdLabel tooltip="When enabled, this file will only be used to improve AI generated Copilot answers and won't be shown in search results.">
            Use for training only
          </CmdLabel>
        }
      >
        <Checkbox />
      </FormItemHorizontal>
    </div>
  );
};

export default SourceSettingsForm;

const PreviewImage = styled.img<{ active: boolean }>`
  width: 175px;
  height: auto;
  filter: ${(props) => (props.active ? 'undefined' : 'grayscale(90%)')};
  opacity: ${(props) => (props.active ? 'undefined' : '0.7')};
  border: 2px solid ${(props) => (props.active ? CB_COLORS.primary : 'transparent')};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  border-radius: 12px;
  transition: all 0.2s ease-out;
`;

const PreviewImageContainer = styled.div<{ active: boolean }>`
  text-align: center;
  color: ${CB_COLORS.neutral500};
  margin-top: 5px;
  font-weight: ${(props) => (props.active ? '500' : '400')};
  transition: all 0.2s ease-out;
  color: ${(props) => (props.active ? CB_COLORS.primary : CB_COLORS.neutral500)};
`;

const SettingsPreviewSwitch = (props: {
  off: { url: string; text: string };
  on: { url: string; text: string };
  value?: boolean;
  onChange?: (value: boolean) => void;
}) => {
  return (
    <Row style={{ marginBottom: 20, marginTop: 10 }}>
      <Col style={{ paddingLeft: 10 }}>
        <PreviewImage active={!props.value} onClick={() => props.onChange?.(false)} src={props.off.url} />
        <PreviewImageContainer active={!props.value}>{props.off.text}</PreviewImageContainer>
      </Col>
      <Col style={{ paddingLeft: 20 }}>
        <PreviewImage active={!!props.value} onClick={() => props.onChange?.(true)} src={props.on.url} />
        <PreviewImageContainer active={!!props.value}>{props.on.text}</PreviewImageContainer>
      </Col>
    </Row>
  );
};
