import React from 'react';
import { Tooltip } from '@commandbar/design-system/components/antd';
import { AlertTriangle } from '@commandbar/design-system/icons/react';

interface IFIELD_PROPERTIES {
  [key: string]: {
    message: string;
    categories: string[];
  };
}

export const FIELD_PROPERTIES: IFIELD_PROPERTIES = {
  width: {
    message: 'Width is constrained in the preview',
    categories: ['helpHub'],
  },
  height: {
    message: 'Height is constrained in the preview',
    categories: ['helpHub'],
  },
};

export const hasEditorMessage = (category: string, attribute: string, getter: any) => {
  const attributeChecks = FIELD_PROPERTIES[attribute];

  if (attributeChecks && attributeChecks.categories.includes(category)) {
    const { message } = attributeChecks;

    const hasError = getter(category, attribute);

    return { hasError: hasError, message };
  }

  return {
    hasError: false,
    message: '',
  };
};

export const EditorWarningTooltip = ({ message }: { message: string }) => {
  return (
    <Tooltip content={<>{message}</>} interactive={true}>
      <AlertTriangle height={16} width={16} color="grey" />
    </Tooltip>
  );
};
