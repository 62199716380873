import Logger from '../util/Logger';
export type FRAME_TYPE = 'commandbar' | 'editor' | 'proxy';

interface IReceiverParameters {
  e: any;
  data: any;
}

export type ReceiverFunctionType = (params: IReceiverParameters) => Promise<[boolean, any]> | undefined;

// see https://github.com/tryfoobar/monobar/blob/labs/internal/src/util/location.ts#L1-L2
const allowedOriginRegexes = [
  // allow `.` for subdomains like <branch>.dashboard.commandbar.com
  /^https:\/\/[a-zA-Z0-9-.]+\.commandbar\.com/,
  /^https:\/\/[a-zA-Z0-9-.]+\.commandbar\.xyz/,
  /https:\/\/\d+--commandbar-com-branch\.netlify\.app/,
];
const matchSubstrings = (str: string, substrings: string[]): boolean => {
  const regexpStr = `^.*(${substrings.join('|')}).*$`;
  const regexp = new RegExp(regexpStr, 'g');
  return !!str.match(regexp);
};

const evalSource = (url: string): FRAME_TYPE | null => {
  const commandBarSources = ['localhost:3001', 'localhost:5001', 'experiences.commandbar.com', 'commandbar.xyz:3001'];
  const editorSources = [
    'localhost:3003',
    'localhost:4000',
    'commandbar.xyz:3003',
    'commandbar.xyz:4000',
    'localhost:5003',
    'editor.commandbar.com',
    'dashboard.commandbar.com',
    'proxy.commandbar.com',
    `frames-editor-prod.commandbar.com`, // to remove
    `frames-editor-labs.commandbar.com`, // to remove
    `frames-editor-dev.commandbar.com`, // to remove
    `app.commandbar.com`,
    `labs.commandbar.com`,
    'dev.commandbar.com',
  ];

  if (matchSubstrings(url, commandBarSources)) {
    return 'commandbar';
  }

  if (matchSubstrings(url, editorSources) || allowedOriginRegexes.some((regexp) => regexp.test(url))) {
    return 'editor';
  }

  // Fallback
  if (['CommandBar', 'Proxy'].includes(process.env.REACT_APP_FRAME_NAME ?? '')) {
    return 'proxy';
  }

  // Fallback
  if (['editor'].includes(process.env.REACT_APP_FRAME_NAME ?? '')) {
    return 'editor';
  }

  return null;
};

const hashCode = (str: string) => {
  return str.split('').reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
};

/**
 * Send messages to and from iframes
 */
const openChannel = (frame?: FRAME_TYPE) => {
  let target: Window | null = null;
  let targetOrigin: string;
  let targetID: string;
  let child: boolean;

  if (frame === undefined) {
    // We are in a child
    targetID = 'commandbar-commandbar';
    targetOrigin = document.referrer;
    target = window.parent;
    child = true;

    // Whenever navigating from https to http, referrer URLs are stripped
    // So when we are testing locally, we need to allow
    // https://stackoverflow.com/a/19455529/4717829
    if (window.location.origin.startsWith('http://localhost')) {
      targetOrigin = '*';
    }

    if (!targetOrigin) {
      // FIXME document.referrer doesnt register for NovaChat / Beeper
      targetOrigin = '*';
    }

    // TODO -- clean this up and be more explicit about how to connect to the hosted instance
    // `window.parent` was the same as `window` -- this means we are NOT in an iframe with a parent
    // We might be in the Studio, so look for the iframe with ID 'commandbar-editor-bar-preview'
    if (target === window) {
      const commandbarEditorBarPreviewIFrame = document.getElementById(
        'commandbar-editor-bar-preview',
      ) as HTMLIFrameElement;
      if (commandbarEditorBarPreviewIFrame) {
        targetOrigin = commandbarEditorBarPreviewIFrame.src;
        target = commandbarEditorBarPreviewIFrame.contentWindow;
      }
    }
  } else {
    // We are in a parent
    targetID = `commandbar-${frame}`;
    const frameNode = document.getElementById(targetID) as HTMLIFrameElement;
    targetOrigin = frameNode.src;
    target = frameNode.contentWindow;
    child = false;
  }

  if (!target || !targetOrigin) {
    Logger.error('iframe not loaded yet...', frame);
    return (_msg: any) => Promise.resolve();
  }

  const _target = target;
  return (msg: any) =>
    new Promise((resolve: any, reject: any) => {
      const uniqueID = hashCode(JSON.stringify(msg));

      Logger.portalsend(uniqueID, JSON.stringify(msg));
      const channel = new MessageChannel();

      channel.port1.onmessage = (props: any) => {
        channel.port1.close();
        const data = JSON.parse(props.data);
        if (data.error) {
          Logger.portalreceive(uniqueID, props.data);

          reject(data.error);
        } else {
          Logger.portalreceive(uniqueID, props.data);

          resolve(data);
        }
      };

      const payload = serializeData({
        ...msg,
        target: targetID,
        host: child ? undefined : window.location.href,
      });

      // Sometimes document.referrer misses the http protocol (e.g. getplan.co vs https://getplan.co)
      if (targetOrigin !== '*' && !targetOrigin.includes('://')) {
        if (!targetOrigin.includes('localhost')) {
          console.log('');
          // Sentry.captureException('postMessage targetOrigin updated', {
          //   contexts: { portal: { targetOrigin, payload } },
          // });
        }
        targetOrigin = `https://${targetOrigin}`;
      }

      // REFACTOR Figure out how to avoid this https://cmd-k.slack.com/archives/C012YKDT9KM/p1605486109003600
      // I think it was just because I was using a common password "asdf"
      _target.postMessage(payload, targetOrigin, [channel.port2]);
    });
};

/**
 * Serialize payloads
 */
const serializeData = (msg: any) => {
  let ret;

  try {
    ret = JSON.stringify(msg);
  } catch (err) {
    Logger.warn('Invalid message type', err);
    ret = `${msg}`;
  }

  return ret;
};

/**
 * Standard response payloads
 */
const response = (event: any, success: boolean, result?: { [key: string]: any }) => {
  const payload = JSON.stringify({
    success,
    ...(result ?? {}),
  });

  event.ports[0].postMessage(payload);
};

/**
 * Message security
 */
const isMessageValid = (e: any): boolean => {
  const allowedStaticOrigins = [
    'localhost:3000', // Base
    'localhost:3001', // CommandBar
    'localhost:3003', // Editor
    'localhost:4000', // app.cb.com
    'localhost:5000', // Base
    'localhost:5001', // CommandBar
    'localhost:5002', // Proxy
    'localhost:5003', // Editor
    `frames-editor-prod.commandbar.com`, // to remove
    `frames-editor-labs.commandbar.com`, // to remove
    `frames-editor-dev.commandbar.com`, // to remove
  ];

  const MESSAGE_SOURCES_TO_BE_IGNORED = [
    'react-devtools-content-script',
    'react-devtools-bridge',
    'AUGURY_INSPECTED_APPLICATION',
  ];
  const shouldLogWarning = (messageSource: string) => {
    return MESSAGE_SOURCES_TO_BE_IGNORED.every((source) => messageSource !== source);
  };

  const isValidOrigin =
    matchSubstrings(e.origin, allowedStaticOrigins) || allowedOriginRegexes.some((regex) => regex.test(e.origin));

  if (process.env.REACT_APP_FRAME_NAME === 'Proxy') {
    if (!isValidOrigin) {
      return false;
    }
  }

  if (!e.data) {
    Logger.error('Message Error: packet empty');
    return false;
  }

  let data;
  try {
    data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
  } catch (err) {
    return false;
  }

  const isValidTarget = data.target === `commandbar-${process.env.REACT_APP_FRAME_NAME?.toLowerCase()}`;
  if (!isValidTarget) {
    if (shouldLogWarning(data.source || data.messageSource)) {
      Logger.red('Either you sent this to the wrong target or its not coming from Command Bar', data);
    }
    return false;
  }

  const isFromMessageChannel = e.ports && e.ports.length === 0;
  if (isFromMessageChannel) {
    Logger.error(data);
    throw new Error('We are only accepting MessageChannel requests for now...');
  }

  return true;
};

const preMessageHandler = (e: any) => {
  let data: any;
  try {
    if (!!e.data) {
      data = typeof e.data === 'object' ? e.data : JSON.parse(e.data);
    } else {
      data = '';
    }
  } catch (err) {
    data = '';
  }

  return {
    isValid: isMessageValid(e),
    sender: (data?.sender as FRAME_TYPE) ?? evalSource(e.origin),
    data,
  };
};

const Portal = {
  response,
  openChannel,
  isMessageValid,
  evalSource,
  preMessageHandler,
};

export default Portal;
