import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  animation-name: ${spin};
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Spinner;
