/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { CSSObject, jsx } from '@emotion/core';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';

// default styles that are editable in the the theme editor
export const styles: CSSObject = {
  display: 'flex',
  width: 'var(--popover-width)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  pointerEvents: 'all',

  maxWidth: 'var(--tooltip-max-width)',
  position: 'relative',

  borderRadius: 'var(--layout-radius-card)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--border-primary)',
  background: 'var(--background-primary)',

  boxShadow: 'var(--layout-shadow) var(--layout-shadow-color)',
};

const surveyStyles: CSSObject = {
  minWidth: 'var(--popover-width)',
  width: 'unset',
  maxWidth: 'unset',
};

const keywordStyles: CSSObject = {
  width: 'unset',
};

// extra styles that an org cannot edit
const Tooltip = styled.div`
  z-index: ${Z.Z_NUDGE};
`;

type StyledTooltipProps = {
  variant: 'survey' | 'keyword' | 'default';
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledTooltip = React.forwardRef<HTMLDivElement, StyledTooltipProps & React.HTMLAttributes<HTMLDivElement>>(
  ({ children, title, variant, themeV2, ...props }, ref) => {
    return (
      <Tooltip
        ref={ref}
        css={[styles, variant === 'survey' && surveyStyles, variant === 'keyword' && keywordStyles].filter(Boolean)}
        {...props}
      >
        {children}
      </Tooltip>
    );
  },
);

export default StyledTooltip;
