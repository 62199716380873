import { isStudioPreview } from '@commandbar/internal/util/location';
import { CBStore } from 'shared/store/global-store';
import { EndUserStore } from './state';

export const commandUID = (command: number | string | undefined) => {
  if (!!command) {
    return `${command}`;
  }
  return '';
};

export const recentUIDs = (recents: EndUserStore['data']['recents']): string[] => {
  return Array.from(new Set(recents.map((r) => r.optionUID)));
};

export const isRemoteEnduserStoreEnabled = (_: CBStore) => {
  const canDoNonVerified = !_.organization?.force_end_user_identity_verification;

  return !!_.endUser && !isStudioPreview() && (!!_.endUser.hmac || canDoNonVerified);
};
