import * as t from 'io-ts';

import { AdminFilter, CardDataV, DateFilter, FunnelDataV, PaginationFilter, TableDataV } from './common';
import { read, mkQueryString } from '../generics';

import * as axiosInstance from '../network';
const AnalyticsChecklistsDataV = t.type({
  active: CardDataV,
  completion_rate: CardDataV,
  table: TableDataV,
});

export type AnalyticsChecklistsData = t.TypeOf<typeof AnalyticsChecklistsDataV>;

const AnalyticsChecklistsDetailDataV = t.type({
  completion_rate: CardDataV,
  views: CardDataV,
  end_user_funnel: FunnelDataV,
  table: TableDataV,
  checklistName: t.string,
});

const AnalyticsChecklistsUsersDataV = t.type({ views: TableDataV });

export type AnalyticsChecklistsDetailData = t.TypeOf<typeof AnalyticsChecklistsDetailDataV>;
export type AnalyticsChecklistsUsersData = t.TypeOf<typeof AnalyticsChecklistsUsersDataV>;

type AnalyticsChecklistsParams = {
  show_unpublished: string;
} & DateFilter &
  AdminFilter;

type AnalyticsChecklistsDetailParams = DateFilter & AdminFilter;
type AnalyticsChecklistsUsersParams = { search_filter: string } & DateFilter & AdminFilter & PaginationFilter;

export class AnalyticsChecklists {
  public static read = (params: AnalyticsChecklistsParams) =>
    read(AnalyticsChecklistsDataV, `analytics/v2/checklists`)(params);

  public static readDetail = (id: string, params: AnalyticsChecklistsDetailParams) =>
    read(AnalyticsChecklistsDetailDataV, `analytics/v2/checklists/${id}`)(params);

  public static readUsers = (id: string, params: AnalyticsChecklistsUsersParams) =>
    read(AnalyticsChecklistsUsersDataV, `analytics/v2/checklists/${id}/users`)(params);

  public static download = async (params: AnalyticsChecklistsParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/checklists/export/${queryString}`);
  };

  public static downloadDetail = async (id: string, params: AnalyticsChecklistsParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/checklists/${id}/export/${queryString}`);
  };
}
