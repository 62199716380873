import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import { Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { ReactComponent as CaretDown } from '../img/caret_down.svg';

import * as S from './nudges/styled';

import type {
  INudgeButtonConditionalAction,
  INudgeStepContentBlockType,
  INudgeStepContentButtonBlockType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import { ActionEditor, ActionType } from './ActionEditor';
import { CmdButton } from '@commandbar/design-system/cmd';

const ConditionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
`;

const ConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuIconContainerWithStyle = styled(S.MenuIconContainer)`
  align-self: flex-end;
`;

const Rule = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 8px;
`;

const IfRow = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const IfLabel = styled.span`
  display: flex;
  width: 51px;
  height: 32px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #d8e0ff;
  color: #2046c6;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

const ThenRow = styled.div`
  display: flex;
  padding: 0;
  align-items: center;
  gap: 5px;
`;

const ThenLabel = styled(IfLabel)`
  background: #ccedac;
  color: #2c5009;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

const HorizontalRule = styled.hr`
  width: 100%;
  margin: 4px 0px;
  height: 1px;
  background: #e7e8ea;
  border: none;
`;

interface OperandSelectorProps {
  surveyValues: { label: string; value: string | number }[];
  condition: INudgeButtonConditionalAction;
  handleOperandChange: (value: string | number) => void;
}

const OperandSelector = ({ surveyValues, condition, handleOperandChange }: OperandSelectorProps) => (
  <S.StyledSelect
    suffixIcon={<CaretDown />}
    value={condition.operand}
    options={surveyValues}
    onChange={handleOperandChange}
    style={{ flexGrow: 1 }}
  />
);

interface OperatorSelectorProps {
  surveyType: 'survey_list' | 'survey_rating';
  condition: INudgeButtonConditionalAction;
  handleOperatorChange: (value: 'eq' | 'neq' | 'gt' | 'lt') => void;
}

const OperatorSelector = ({ surveyType, condition, handleOperatorChange }: OperatorSelectorProps) => (
  <S.StyledSelect
    dropdownStyle={{ minWidth: 120 }}
    suffixIcon={<CaretDown />}
    value={condition.operator}
    options={
      surveyType === 'survey_list'
        ? [
            { label: 'is', value: 'eq' },
            { label: 'is not', value: 'neq' },
          ]
        : [
            { label: 'is', value: 'eq' },
            { label: 'is not', value: 'neq' },
            { label: 'is greater than', value: 'gt' },
            { label: 'is less than', value: 'lt' },
          ]
    }
    onChange={handleOperatorChange}
  />
);

const ConditionTypeSelector = ({ surveyType }: { surveyType: 'survey_list' | 'survey_rating' }) => (
  <S.StyledSelect
    suffixIcon={<CaretDown />}
    value={surveyType === 'survey_list' ? 'list' : 'rating'}
    options={[
      { label: 'List', value: 'list' },
      { label: 'Rating', value: 'rating' },
    ]}
    disabled
  />
);

interface ConditionEditorProps {
  index: number;
  condition: INudgeButtonConditionalAction;
  surveyValues: Array<{ label: string; value: string | number }>;
  block: INudgeStepContentButtonBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  possibleTypes?: ActionType[];
  surveyType: 'survey_list' | 'survey_rating';
  nudge: INudgeType;
  stepIndex: number;
}

const ConditionEditor = ({
  index,
  condition,
  surveyValues,
  block,
  onBlockChange,
  possibleTypes,
  surveyType,
  nudge,
  stepIndex,
}: ConditionEditorProps) => {
  const currentConditions = useMemo(() => block.meta?.conditional_actions || [], [block.meta?.conditional_actions]);
  const conditionToUpdate = currentConditions?.[index];
  const action = conditionToUpdate?.action;

  const handleOperatorChange = useCallback(
    (value: 'eq' | 'neq' | 'gt' | 'lt') => {
      const updatedConditions = currentConditions.map((c, i) => (i === index ? { ...c, operator: value } : c));

      onBlockChange({
        ...block,
        meta: {
          ...block.meta,
          conditional_actions: updatedConditions,
        },
      });
    },
    [block, currentConditions, index, onBlockChange],
  );

  const handleOperandChange = useCallback(
    (value: string | number) => {
      const updatedConditions = currentConditions.map((c, i) => (i === index ? { ...c, operand: value } : c));

      onBlockChange({
        ...block,
        meta: {
          ...block.meta,
          conditional_actions: updatedConditions,
        },
      });
    },
    [block, currentConditions, index, onBlockChange],
  );

  const handleActionChange = useCallback(
    (action: INudgeButtonConditionalAction['action']) => {
      const updatedConditions = currentConditions.map((c, i) => (i === index ? { ...c, action } : c));

      onBlockChange({
        ...block,
        meta: {
          ...block.meta,
          conditional_actions: updatedConditions,
        },
      });
    },
    [block, currentConditions, index, onBlockChange],
  );

  return (
    <Rule>
      <IfRow>
        <IfLabel>If</IfLabel>
        <ConditionTypeSelector surveyType={surveyType} />
        <OperatorSelector surveyType={surveyType} condition={condition} handleOperatorChange={handleOperatorChange} />
        <OperandSelector surveyValues={surveyValues} condition={condition} handleOperandChange={handleOperandChange} />
      </IfRow>

      <ThenRow>
        <ThenLabel>Then</ThenLabel>

        <ActionEditor
          onActionChange={handleActionChange}
          action={action}
          possibleTypes={possibleTypes}
          nudge={nudge}
          stepIndex={stepIndex}
        />
      </ThenRow>
    </Rule>
  );
};

interface ConditionProps {
  index: number;
  condition: INudgeButtonConditionalAction;
  block: INudgeStepContentButtonBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  surveyValues: Array<{ label: string; value: string | number }>;
  surveyType: 'survey_list' | 'survey_rating';
  possibleTypes?: ActionType[];
  nudge: INudgeType;
  stepIndex: number;
}

const Condition = ({
  index,
  condition,
  block,
  onBlockChange,
  surveyValues,
  surveyType,
  possibleTypes,
  nudge,
  stepIndex,
}: ConditionProps) => {
  const handleDeleteCondition = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onBlockChange({
        ...block,
        meta: {
          ...block.meta,
          conditional_actions: block.meta?.conditional_actions?.filter((_, i) => i !== index),
        },
      });
    },
    [block, index, onBlockChange],
  );

  return (
    <ConditionContainer key={index}>
      <ConditionHeader>
        <S.StyledLabel>Condition</S.StyledLabel>
        <MenuIconContainerWithStyle onClick={handleDeleteCondition}>
          <Trash04 width={16} opacity={0.5} />
        </MenuIconContainerWithStyle>
      </ConditionHeader>

      <ConditionEditor
        index={index}
        condition={condition}
        block={block}
        onBlockChange={onBlockChange}
        surveyValues={surveyValues}
        surveyType={surveyType}
        possibleTypes={possibleTypes}
        nudge={nudge}
        stepIndex={stepIndex}
      />
    </ConditionContainer>
  );
};

interface LogicEditorProps {
  possibleTypes?: ActionType[];
  block: INudgeStepContentButtonBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  surveyValues: Array<{ label: string; value: string | number }>;
  surveyType: 'survey_list' | 'survey_rating';
  nudge: INudgeType;
  stepIndex: number;
}

const LogicEditor = ({
  possibleTypes,
  block,
  onBlockChange,
  surveyValues,
  surveyType,
  nudge,
  stepIndex,
}: LogicEditorProps) => {
  const handleAddAction = useCallback(() => {
    onBlockChange({
      type: 'button',
      sort_key: block.sort_key,
      meta: {
        ...block.meta,
        conditional_actions: [
          ...(block.meta?.conditional_actions || []),
          {
            action: { type: 'link', value: '', operation: 'self' },
            operator: 'eq',
            operand: '',
          },
        ],
      },
    });
  }, [block.meta, block.sort_key, onBlockChange]);

  return (
    <S.SectionContainer>
      <S.InputContainer>
        {block.meta?.conditional_actions?.map((condition, index) => (
          <Condition
            key={index}
            index={index}
            condition={condition}
            block={block}
            onBlockChange={onBlockChange}
            surveyValues={surveyValues}
            surveyType={surveyType}
            possibleTypes={possibleTypes}
            nudge={nudge}
            stepIndex={stepIndex}
          />
        ))}

        <HorizontalRule />

        <CmdButton icon={<Plus />} onClick={handleAddAction}>
          Add condition
        </CmdButton>
      </S.InputContainer>
    </S.SectionContainer>
  );
};

export default LogicEditor;
