import { Alert, Skeleton, Page, Select } from '@commandbar/design-system/components/antd';

import * as axiosInstance from '@commandbar/internal/middleware/network';
import { useReportEvent } from '../hooks/useEventReporting';
import { CmdButton, CmdDropdown, CmdTag, CmdTypography, cmdToast } from '@commandbar/design-system/cmd';
import { useAppContext } from 'editor/src/AppStateContext';
import { UserPlus01, X } from '@commandbar/design-system/icons/react';
import { useTeamMembersContext } from './util/TeamMembersContext';
import { useCallAsyncFunction } from '@commandbar/internal/util/useCallAsyncFunction';
import TeamMembersTable from './content/sources/TeamMembersTable';
import styled from '@emotion/styled';
import { Modal } from '@commandbar/design-system/components/antd';
import { XClose } from '@commandbar/design-system/icons/react';
import { CaretDown } from 'editor/src/editor/helphub/shared';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { useRef } from 'react';
import { capitalize } from 'lodash';
import { IProfileType } from '@commandbar/internal/middleware/types';
import { reportErrorToUser } from 'editor/src/editor/utils/ErrorReporting';

const InviteModal = styled(Modal)`
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  & .ant-modal-header {
    padding: 16px;
  }
  & .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CustomSelect = styled(Select)`
  & .ant-select-selector {
    min-height: 80px; /* Adjust based on needed space */
    align-items: start;
    padding: 8px 8px;
  }
  & .ant-select-selection-overflow {
    gap: 4px;
  }

  & .ant-select-selection-placeholder {
    white-space: pre-wrap !important; /* Allows for manual line breaks in the placeholder */
    max-width: 90%; /* Prevents text overflow */
    transform: unset;
    transition: unset;
    font-size: 14px;
    top: 8px;
  }

  & svg:hover {
    cursor: pointer;
  }
`;

const isValidEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const synTagRender = (cust_props: CustomTagProps) => {
  const { label, onClose } = cust_props;
  const email = typeof label === 'string' ? label : '';

  const isValid = isValidEmail(email);

  return (
    <CmdTag
      variant={isValid ? 'default' : 'failure'}
      suffixElement={<X className={isValid ? 'text-gray600' : 'text-red800'} onClick={() => onClose()} />}
    >
      {label}
    </CmdTag>
  );
};

const ROLE_DESCRIPTION_MAPPING: Record<IProfileType['role'], string> = {
  admin: 'Edit organization settings, create and publish content',
  editor: 'Create and publish content',
  contributor: 'Create content',
  viewer: 'View content and analytics',
};

interface SuccessResponse {
  success: string;
  errors: Record<string, any>; // Use any or a more specific type if you know the structure of errors
}

const TeamMembersPage = () => {
  const { organizationSettings, organization } = useAppContext();

  const dropdownParentRef = useRef<HTMLDivElement>(null);

  const [inviteEmails, setInviteEmails] = React.useState<string[]>([]);
  const [inviteModalIsOpen, setInviteModalIsOpen] = React.useState<boolean>(false);

  const [currentInviteeRole, setCurrentInviteeRole] = React.useState<IProfileType['role']>('editor');
  const [width, setWidth] = React.useState();
  const { loading, members, addMember } = useTeamMembersContext();

  const { reportEvent } = useReportEvent();

  const { result } = useCallAsyncFunction(async () => {
    const res = await axiosInstance.get<{ sso_domain: string | null }>('/organizations/sso_domain/');
    return res.data;
  });

  const inviteNewTeammates = async (emails: string[], role: IProfileType['role']) => {
    const payload = {
      emails: emails,
      role: role,
    };

    try {
      const response: any = await axiosInstance.post('/organizations/invite_teammate/', payload);
      // Created: Handle success message
      const data = response.data as SuccessResponse;
      cmdToast.success(`Success: ${data.success}`);

      for (const email of emails) {
        if (!data.errors[email]) {
          addMember(email, role);
        }
      }

      if (data.errors && Object.keys(data.errors).length > 0) {
        for (const [key, value] of Object.entries(data.errors)) {
          cmdToast.error(`Failed to invite ${key}: ${value}`);
        }
      }
    } catch (error) {
      reportErrorToUser(error);
    }
  };

  React.useEffect(() => {
    const handleResize = (entries: any) => {
      for (const entry of entries) {
        setWidth(entry.contentRect.width + 32);
      }
    };

    if (dropdownParentRef.current) {
      const observer = new ResizeObserver(handleResize);
      observer.observe(dropdownParentRef.current);
      return () => observer.disconnect();
    }
  }, [inviteModalIsOpen]); //

  React.useEffect(() => {
    if (inviteModalIsOpen) {
    }
  }, [inviteModalIsOpen]);

  const isSSO = !!result?.sso_domain;
  const atMaxSeatLimit = organizationSettings?.user_seat_limit
    ? members.length >= organizationSettings?.user_seat_limit
    : false;

  return (
    <Page
      disableVerticalContentPadding
      whiteBg={false}
      title="Members"
      headerChildren={
        <CmdButton
          variant="primary"
          onClick={() => {
            setInviteModalIsOpen((previous) => !previous);
          }}
          disabled={isSSO || atMaxSeatLimit}
        >
          <UserPlus01 />
          Invite
        </CmdButton>
      }
      description={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            Manage members of your teams and their roles in {organization.name}
          </span>
        </div>
      }
    >
      {loading || !organization ? (
        <Skeleton active />
      ) : (
        <>
          {atMaxSeatLimit && (
            <Alert
              style={{ marginBottom: '16px' }}
              type="warning"
              showIcon
              message={
                <span>
                  Your team is currently at the maximum number of seats in your plan. Check your{' '}
                  <a href="/billing">billing portal</a> to upgrade.
                </span>
              }
            />
          )}
          {isSSO && !atMaxSeatLimit && (
            <Alert
              style={{ marginBottom: '16px' }}
              type="info"
              showIcon
              message={
                <span>
                  Your team is managed by SSO. To add or remove teammates, please contact your organization's SSO
                  administrator to provision access for your teammate via your SSO provider. Your teammate can then log
                  in via your organization's SSO provider.
                </span>
              }
            />
          )}
          <TeamMembersTable />
        </>
      )}

      <InviteModal
        open={inviteModalIsOpen}
        closable={true}
        title={`Invite to ${organization.name}`}
        footer={null}
        onCancel={() => setInviteModalIsOpen(false)}
        closeIcon={<CmdButton variant="link" size="sm" icon={<XClose />} />}
        // onCancel={() => setShareLinkModalOpen(false)}
        style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}
      >
        <CustomSelect
          mode="tags"
          style={{ width: '100%' }}
          tagRender={synTagRender}
          value={inviteEmails.map((email) => ({ label: email, value: email }))}
          placeholder="Enter an email addess and press Enter. You can enter multiple emails."
          onChange={(tags: any) => {
            setInviteEmails(tags);
          }}
          dropdownStyle={{ display: 'none' }}
        />

        <CmdDropdown.Menu>
          <CmdDropdown.Trigger style={{ width: '100%' }}>
            <div
              ref={dropdownParentRef}
              id="dropdown-parent"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'left',
                background: 'white',
                border: '1px solid rgba(0, 0, 0, 0.08)',
                borderRadius: '8px',
                padding: '8px 16px',
                width: '100%',
                flexGrow: 1,
                cursor: 'pointer',
                outline: 'none',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <CmdTypography.Body fontWeight="semi-bold">{capitalize(currentInviteeRole)}</CmdTypography.Body>
                <CmdTypography.Body fontSize="sm" fontWeight="medium" variant="secondary">
                  {ROLE_DESCRIPTION_MAPPING[currentInviteeRole]}
                </CmdTypography.Body>
              </div>
              <CaretDown />
            </div>
          </CmdDropdown.Trigger>
          <CmdDropdown.Content
            style={{
              width: width ? `${width}px` : undefined,
            }}
            container={dropdownParentRef.current as HTMLElement}
          >
            <CmdDropdown.RadioGroup
              value={currentInviteeRole}
              onValueChange={(value) => setCurrentInviteeRole(value as IProfileType['role'])}
            >
              <CmdDropdown.RadioItem key={'admin'} value={'admin'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    width: '100%',
                    padding: '4px',
                  }}
                >
                  <CmdTypography.Body fontWeight="semi-bold">{'Admin'}</CmdTypography.Body>
                  <CmdTypography.Body fontSize="sm" fontWeight="medium" variant="secondary">
                    {'Edit organization settings, create and publish user-facing experiences'}
                  </CmdTypography.Body>
                </div>
              </CmdDropdown.RadioItem>
              <CmdDropdown.RadioItem key={'editor'} value={'editor'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    width: '100%',
                    padding: '4px',
                  }}
                >
                  <CmdTypography.Body fontWeight="semi-bold">{'Editor'}</CmdTypography.Body>
                  <CmdTypography.Body fontSize="sm" fontWeight="medium" variant="secondary">
                    {'Create and publish user-facing experiences'}
                  </CmdTypography.Body>
                </div>
              </CmdDropdown.RadioItem>
              <CmdDropdown.RadioItem key={'contributor'} value={'contributor'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    width: '100%',
                    padding: '4px',
                  }}
                >
                  <CmdTypography.Body fontWeight="semi-bold">{'Contributor'}</CmdTypography.Body>
                  <CmdTypography.Body fontSize="sm" fontWeight="medium" variant="secondary">
                    {'Create user-facing experiences'}
                  </CmdTypography.Body>
                </div>
              </CmdDropdown.RadioItem>
              <CmdDropdown.RadioItem key={'viewer'} value={'viewer'}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    width: '100%',
                    padding: '4px',
                  }}
                >
                  <CmdTypography.Body fontWeight="semi-bold">{'Viewer'}</CmdTypography.Body>
                  <CmdTypography.Body fontSize="sm" fontWeight="medium" variant="secondary">
                    {'View content and analytics'}
                  </CmdTypography.Body>
                </div>
              </CmdDropdown.RadioItem>
            </CmdDropdown.RadioGroup>
          </CmdDropdown.Content>
        </CmdDropdown.Menu>

        <div style={{ display: 'flex', justifyContent: 'right', flexDirection: 'row' }}>
          <CmdButton
            variant="primary"
            onClick={() => {
              inviteNewTeammates(inviteEmails, currentInviteeRole);
              setInviteEmails([]);

              reportEvent('invites sent', {
                segment: true,
                highlight: true,
                slack: true,
                payloadMessage: 'teammates invited',
              });
              setInviteModalIsOpen(false);
            }}
          >
            Invite
          </CmdButton>
        </div>
      </InviteModal>
    </Page>
  );
};

export default TeamMembersPage;
