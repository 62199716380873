import { createContext, useContext } from 'react';
import { ISegmentConfig } from './utils';

interface createContextContext {
  segmentConfig: ISegmentConfig;
  setSegmentConfig: Function;
}

export const SegmentConfigContext = createContext<createContextContext>({
  segmentConfig: {
    orgID: '',
    writeKey: '',
    endpoint: 'https://api.segment.io',
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSegmentConfig: () => {},
});
export const SegmentConfigProvider = SegmentConfigContext.Provider;
export const useSegmentConfigContext = () => useContext(SegmentConfigContext);
