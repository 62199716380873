import debounce from 'lodash/debounce';

import { hasRageTrigger, hasUserConfusionTrigger, hasSmartDelayTrigger, getDebuggedNudge } from './selectors';

import type { CBStore } from 'shared/store/global-store';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { sendIndirectTrigger, sendDirectedTrigger } from './actions';

/* Smart triggers */
export const triggerSmartNudge = (_: CBStore, trigger: INudgeType['trigger']) => {
  const debuggedNudge = getDebuggedNudge(_);
  // If a nudge is being simulated, only trigger that nudge
  if (debuggedNudge) {
    sendDirectedTrigger(_, debuggedNudge, trigger);
  } else if (!_.activeChecklist) {
    sendIndirectTrigger(_, trigger);
  }
};

export const setupAndTriggerSmartTriggers = (_: CBStore, nudges: Array<INudgeType>) => {
  if (hasRageTrigger(_, nudges)) {
    setupRageClickTrigger(_);
  }
  if (hasUserConfusionTrigger(_, nudges)) {
    setupUserConfusionTrigger(_);
  }
  if (hasSmartDelayTrigger(_, nudges)) {
    setupSmartDelayTrigger(_);
  }
};

const setupUserConfusionTrigger = (_: CBStore): ((event: MouseEvent) => void) => {
  const noStrokesWindowMs = 1000;
  const requiredStrokes = 10;
  const velocityMin = 10;
  let strokeCount = 0;
  let lastX = -1;
  let lastY = -1;
  let lastTimestamp = -1;
  removeUserConfusionTrigger(_);
  const handleUserConfusion = () => {
    if (strokeCount >= requiredStrokes) {
      triggerSmartNudge(_, { type: 'on_user_confusion' });
    }
    strokeCount = 0;
  };

  const debouncedHandler = debounce(handleUserConfusion, noStrokesWindowMs);

  const listenForUserConfusion = (event: MouseEvent) => {
    const { clientX, clientY, timeStamp } = event;

    if (lastX !== -1 && lastY !== -1 && lastTimestamp !== -1) {
      const dx = Math.abs(clientX - lastX);
      const dy = Math.abs(clientY - lastY);
      const distance = Math.sqrt(dx ** 2 + dy ** 2);
      const velocity = distance / (timeStamp - lastTimestamp);

      if (velocity > velocityMin) {
        strokeCount += 1;
        debouncedHandler();
      }
    }

    lastX = clientX;
    lastY = clientY;
    lastTimestamp = timeStamp;
  };

  document.body.addEventListener('mousemove', listenForUserConfusion);
  _.nudgeEventListeners.on_user_confusion = listenForUserConfusion;

  return listenForUserConfusion;
};

const setupSmartDelayTrigger = (_: CBStore): (() => void) => {
  const inactiveLimitMs = 5000;
  const noActivityTriggerWindowMs = 60000;
  let pastNoTriggerWindow = false;
  removeSmartDelayTrigger(_);

  const handleUserInactive = () => {
    if (!!pastNoTriggerWindow) {
      triggerSmartNudge(_, { type: 'smart_delay' });
    }
  };

  setTimeout(() => (pastNoTriggerWindow = true), noActivityTriggerWindowMs);
  const debouncedHandler = debounce(handleUserInactive, inactiveLimitMs);

  document.body.addEventListener('mousemove', debouncedHandler);
  document.body.addEventListener('keydown', debouncedHandler);

  _.nudgeEventListeners.smart_delay = debouncedHandler;

  return debouncedHandler;
};

const setupRageClickTrigger = (_: CBStore) => {
  const rageClicks = 3;
  const noRageClicksTriggerWindowMs = 500;
  let clickCount = 0;
  removeRageTrigger(_);

  const checkRageAndResetCount = () => {
    if (clickCount >= rageClicks) {
      triggerSmartNudge(_, { type: 'on_rage_click' });
    }
    clickCount = 0;
  };

  const debouncedHandler = debounce(checkRageAndResetCount, noRageClicksTriggerWindowMs);

  const listener = () => {
    clickCount += 1;
    debouncedHandler();
  };

  document.body.addEventListener('click', listener);

  _.nudgeEventListeners.on_rage_click = listener;

  return listener;
};

/* Remove smart trigger helpers */
export const removeRageTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.on_rage_click;
  if (listener) document.body.removeEventListener('click', listener);
};

export const removeUserConfusionTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.on_user_confusion;
  if (listener) document.body.removeEventListener('mousemove', listener);
};

export const removeSmartDelayTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.smart_delay;
  if (listener) {
    document.body.removeEventListener('click', listener);
    document.body.removeEventListener('keydown', listener);
  }
};

export const removeSmartListeners = (_: CBStore) => {
  removeRageTrigger(_);
  removeSmartDelayTrigger(_);
  removeUserConfusionTrigger(_);
};
