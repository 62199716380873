import React, { useEffect, useState } from 'react';
import { Container } from '../helphub/shared';
import { CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import { LoadingSave } from '../themes/shared';
import styled from '@emotion/styled';
import { ChatDebugPanel } from './ChatDebugPanel';
import { IMessageType } from '@commandbar/internal/middleware/types';
import { isEqual } from 'lodash';
import Sender from 'editor/src/management/Sender';
import { Chat } from '@commandbar/internal/middleware/chat';

const EmptyStateContainer = styled.div`
  display: flex;
  padding: 56px 0px;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

const XRay = () => {
  const [chat, setChat] = useState<{
    id: string;
    modified: string;
    messages: IMessageType[];
  }>();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const activeChat = await Sender.getActiveChat().then((resp) => {
          if (resp.chatId) {
            return Chat.get(resp.chatId);
          }
          return null;
        });

        if (activeChat === null) {
          setChat(undefined);
        } else if (!isEqual(chat?.messages, activeChat.messages)) {
          setChat(activeChat);
        }
      } catch {
        // old chat id (ie, chat not found) means chat isn't active
        setChat(undefined);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [chat]);

  return (
    <Container>
      {chat ? (
        <ChatDebugPanel selectedChat={chat} usingXray />
      ) : (
        <CmdCard>
          <EmptyStateContainer>
            <LoadingSave />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
              <CmdTypography>Waiting for chat to begin</CmdTypography>
              <CmdTypography variant="secondary" fontSize="sm">
                Ask Copilot a question to get started
              </CmdTypography>
            </div>
          </EmptyStateContainer>
        </CmdCard>
      )}
    </Container>
  );
};

export default XRay;
