import React from 'react';
import TextCustomizationInput from '../TextCustomizationInput';
import styled from '@emotion/styled';

const SettingsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const TextCustomizationSettings = () => {
  return (
    <SettingsBlock>
      <TextCustomizationInput msgid="copilot.title" label="Title" />
      <TextCustomizationInput msgid="copilot.chat-label" label="Chat title text" />
      <TextCustomizationInput msgid="copilot.chat-input-placeholder" label="Chat input placeholder text" />
    </SettingsBlock>
  );
};

export default TextCustomizationSettings;
