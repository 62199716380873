import { useEffect } from 'react';

const useWindowEventListener = (event: string, callback: (e: Event) => void, dependencies: any[] = []) => {
  useEffect(() => {
    window.addEventListener(event, callback);

    return () => window.removeEventListener(event, callback);
  }, dependencies); // Only run on mount and unmount
};

export default useWindowEventListener;
