/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import _startCase from 'lodash/startCase';
import { AccessibilityWarningTooltip, isAccessible as checkIsAccessible } from '../accessibility';
import { hasEditorMessage, EditorWarningTooltip } from '../skinEditors/skinErrors';
import {
  CB_COLORS,
  Col,
  Input,
  Popover,
  Row,
  Radio,
  Select,
  Space,
  Tooltip,
  SimplePanel,
  PaddingContainer,
} from '@commandbar/design-system/components/antd';
import { maybePluralize } from '@commandbar/internal/middleware/utils';
import { generateBaseThemeFromUserTheme, IPartialTheme, ITheme } from '@commandbar/internal/client/theme';
import { FormFactor } from '@commandbar/internal/client/CommandBarClientSDK';
import Mask from './Mask';
import { ProEditorTabs, Widget } from './pro/SectionsMapping';
import _ from 'lodash';
import { FilterFunnel01, FlipBackward } from '@commandbar/design-system/icons/react';
import { Card, Header, Tabs } from './styled-components';
import { findNearestStyleElement } from './pro/Skeletons/helpers';
import styled from '@emotion/styled';
import Sender from '../../../management/Sender';
import { useAppContext } from 'editor/src/AppStateContext';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router';

import type { INudgeStepType } from '@commandbar/internal/middleware/types';
import { CmdTag } from '@commandbar/design-system/cmd';

const getElementName = (str: string) => {
  return _.startCase(str).replace('Help Hub', 'HelpHub').replace('Questlist', 'Checklist');
};

const getElementKey = (str: string | null) => {
  return str && str.replace('checklist', 'questlist');
};

type IEditableThemeAttribute = {
  element: keyof ITheme;
  attribute: string;
  placeholder: string;
  attributeLabel: string;
  description?: string;
};

interface EditorBoundaries {
  [key: string]: number;
}

const EditStyleForm = (props: {
  templateTheme: ITheme;
  activeTheme: IPartialTheme;
  disabled: boolean;
  onThemeAttributeChange: (attribute: IEditableThemeAttribute, value: string) => void;
  activeElem: keyof ITheme | null;
}) => {
  const { templateTheme, activeTheme, activeElem, disabled } = props;
  const isValidThemeAttribute = (category: string, attribute: string) => _.has(templateTheme, [category, attribute]);
  const currentValue = (category: string, attribute: string) => _.get(activeTheme, [category, attribute], null);
  const getEditorBoundaries = (): EditorBoundaries => {
    const element = window.document.getElementById('commandbar-editor');
    //160 is the Editor Nav Menu width
    return { width: element ? element.offsetWidth + 160 : 0, height: element ? element.offsetHeight : 0 };
  };

  if (!activeElem) return null;

  const attributes = Object.entries(templateTheme[activeElem])
    .map(([attributeName, defaultValue]) => ({
      element: activeElem,
      attribute: attributeName,
      attributeLabel: _startCase(attributeName),
      placeholder: defaultValue.toString(),
    }))
    .filter((style) => isValidThemeAttribute(style.element, style.attribute));

  const hasBeenEdited = (attribute: IEditableThemeAttribute) => !!currentValue(attribute.element, attribute.attribute);
  const numEditedFields = attributes.filter((attribute) => hasBeenEdited(attribute)).length;
  const editTag = numEditedFields ? (
    <CmdTag>{maybePluralize(numEditedFields, 'style', 'styles', true)} edited</CmdTag>
  ) : null;

  return (
    <div>
      <Header>
        <Space>
          {getElementName(activeElem)}
          {editTag}
        </Space>
      </Header>
      {attributes.map((attr) => {
        const { contrastRatio, isAccessible, conflictingProperty } = checkIsAccessible(
          attr.element,
          attr.attribute,
          (category: string, attribute: string) => {
            // We have to assert type as any to avoid strict checking on templateTheme defined by our d.ts file
            return currentValue(category, attribute) || (templateTheme as any)[category]?.[attribute];
          },
        );

        const { hasError, message } = hasEditorMessage(
          attr.element,
          attr.attribute,
          (category: string, attribute: string) => {
            const currVal = currentValue(category, attribute);
            const maxValue = getEditorBoundaries();
            return parseFloat(currVal) > maxValue[attribute];
          },
        );

        const value = currentValue(attr.element, attr.attribute);

        return (
          <Row
            key={`${attr.element}-${attr.attribute}`}
            gutter={8}
            style={{ marginBottom: 8 }}
            justify="start"
            align="middle"
          >
            <Col span={7}>{attr.attributeLabel}:</Col>
            <Col span={16}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flexGrow: 1 }}>
                  <Input
                    placeholder={attr.placeholder}
                    defaultValue={value}
                    value={value}
                    onBlur={(e) => {
                      if (!disabled) {
                        props.onThemeAttributeChange(attr, e.target.value);
                      }
                    }}
                    onChange={(e) => {
                      if (!disabled) {
                        props.onThemeAttributeChange(attr, e.target.value);
                      }
                    }}
                    onPressEnter={(e: any) => e.target.blur()}
                    disabled={disabled}
                  />
                </span>
                <span style={{ width: 16, height: 16, marginLeft: 24 }}>
                  {hasBeenEdited(attr) && (
                    <Tooltip content="Revert to default">
                      <FlipBackward
                        width={16}
                        height={16}
                        onClick={() => {
                          if (!disabled) {
                            props.onThemeAttributeChange(attr, '');
                          }
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                </span>
                <span style={{ width: 16, height: 16, marginLeft: 24, marginRight: 16 }}>
                  {!isAccessible && (
                    <AccessibilityWarningTooltip
                      contrastRatio={contrastRatio}
                      conflictingProperty={conflictingProperty as string} // conflictingProperty in this case can never be null
                    />
                  )}
                  {hasError && <EditorWarningTooltip message={message} />}
                </span>
              </span>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

const SkeletonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '8px',
  paddingInline: '8px',
  width: '100%',
});

const baseFormFactors: Record<string, INudgeStepType['form_factor']> = {
  nudges: {
    type: 'popover',
    position: 'center',
  },
  nudgePin: {
    type: 'pin',
    anchor: '',
    advance_trigger: '',
  },
  nudgeModal: {
    type: 'modal',
  },
  nudgeTooltip: {
    type: 'tooltip',
    show_on: 'hover',
    anchor: '',
    marker: {
      type: 'beacon',
      positioning: {
        position: 'right',
        offset: {
          x: '0px',
          y: '0px',
        },
      },
    },
  },
};

const ProStyleEditor = (props: {
  theme: IPartialTheme;
  formFactor: FormFactor;
  isBasicTier: boolean;
  updateTheme: (theme: IPartialTheme) => void;
}) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const activeTab = useParams<{ widget?: Widget }>().widget ?? 'bar';
  const setActiveTab = (widget: Widget) => {
    history.replace(generatePath(routeMatch.path, { ...routeMatch.params, widget: widget }));
  };

  const [activeSubsection, setActiveSubsection] = React.useState<string | null>(null);

  const [activeElem, setActiveElem] = useState<keyof ITheme | null>(null);
  const [hoveredElem, setHoveredElem] = useState<keyof ITheme | null>(null);

  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [showMask, setShowMask] = useState(false);
  const { nudges, checklists, commandBarReady } = useAppContext();

  React.useEffect(() => {
    const [nudge] = nudges;
    if (!commandBarReady) {
      return;
    }
    if (activeElem && ['nudges', 'nudgeModal', 'nudgePin', 'nudgeTooltip'].includes(activeElem)) {
      Sender.closeNudgeMocks();
      if (nudges.length) {
        const [step] = nudge.steps;

        Sender.showNudgeStepMock(
          {
            ...nudge,
            steps: [{ ...step, form_factor: baseFormFactors[activeElem] }],
          },
          0,
          { forceOpen: true },
        );
      }
    }
    return;
  }, [activeElem]);

  React.useEffect(() => {
    if (!commandBarReady) {
      return;
    }

    const [nudge] = nudges;

    switch (activeTab) {
      case 'bar': {
        Sender.closeHelpHub();
        Sender.closeNudgeMocks();
        Sender.stopChecklistPreview();

        Sender.openBar();
        break;
      }
      case 'nudges': {
        if (nudges.length) {
          const [step] = nudge.steps;

          Sender.showNudgeStepMock(
            {
              ...nudge,
              steps: [{ ...step, form_factor: baseFormFactors[activeElem ?? 'nudges'] }],
            },
            0,
            { forceOpen: true },
          );
        }
        break;
      }
      case 'checklists': {
        Sender.closeBar();
        Sender.closeHelpHub();
        Sender.closeNudgeMocks();
        if (checklists.length) {
          Sender.previewChecklist(checklists[0], false);
        }
        break;
      }
      case 'help-hub': {
        Sender.closeBar();
        Sender.closeNudgeMocks();
        Sender.stopChecklistPreview();

        Sender.openHelpHub();
        break;
      }
    }

    return () => {
      // reset all previews
      Sender.closeBar();
      Sender.closeHelpHub();
      Sender.closeNudgeMocks();
      Sender.stopChecklistPreview();
    };
  }, [activeTab, commandBarReady]);

  const moveMaskOverElement = (element: HTMLElement) => {
    const { top, left, width, height } = element.getBoundingClientRect();

    setShowMask(true);
    setPosition({
      width,
      height,
      top: window.scrollY + top,
      left: window.scrollX + left,
    });
  };

  const onMouseOverElement = (e: { target: HTMLElement | null }) => {
    const el = findNearestStyleElement(e.target);

    if (!el?.id || !el?.id.startsWith('bar-styles-')) {
      setShowMask(false);
      setHoveredElem(null);
      return;
    }

    moveMaskOverElement(el);
    setHoveredElem(el.id.split('-')[2] as keyof ITheme);
  };

  const onMouseOutElement = (e: { target: HTMLElement | null }, unsetHoveredElem = false) => {
    const el = findNearestStyleElement(e.target);

    if (!el?.id || !el?.id.startsWith('bar-styles-')) {
      setShowMask(false);
      setHoveredElem(null);
      return;
    }

    unsetHoveredElem && setHoveredElem(null);
    setShowMask(false);
  };

  const onThemeAttributeChange = (style: IEditableThemeAttribute, e: string) => {
    return props.updateTheme({ [style.element]: { [style.attribute]: e } });
  };

  const templateTheme = generateBaseThemeFromUserTheme(props.theme, props.formFactor);

  return (
    <div>
      <Tabs
        type="card"
        defaultActiveKey={activeTab}
        destroyInactiveTabPane={true}
        tabBarStyle={{
          paddingLeft: '16px',
        }}
        onChange={(t) => {
          setActiveElem(null);
          setActiveTab(t as Widget);
          setActiveSubsection(null);
        }}
        activeKey={activeTab}
        items={Object.entries(ProEditorTabs).map(([widget, sections]) => {
          const hasMultiple = Object.keys(sections).length > 1;
          const isWidgetActive = widget === activeTab;
          const sectionKey = (isWidgetActive && activeSubsection) || Object.keys(sections)[0];
          const Skeleton = sections[sectionKey]?.skeleton || React.Fragment;
          const thisSectionElements = sections[sectionKey]?.elements;
          return {
            label: getElementName(widget),
            key: widget,
            children: (
              <PaddingContainer>
                {hasMultiple && (
                  <div>
                    <Select
                      value={sectionKey}
                      options={Object.entries(sections).map(([key, info]) => ({ label: info.name, value: key }))}
                      onChange={(key) => {
                        setActiveSubsection(key);
                        setActiveElem(null);
                      }}
                      style={{ minWidth: 200 }}
                    />
                  </div>
                )}
                <SimplePanel>
                  <SkeletonWrapper>
                    <Skeleton
                      setActiveCategory={(value: string | null) => {
                        if (value) {
                          // Scroll the element into view when clicking on the skeleton
                          const container = document.getElementById('bar-settings-elements-container');
                          const element = document.getElementById(`bar-settings-${value}`);

                          if (container && element) {
                            container.scrollTop = element.offsetTop - container.offsetTop;
                          }
                        }
                        setActiveElem(getElementKey(value) as keyof ITheme | null);
                      }}
                      onMouseOutElement={onMouseOutElement}
                      onMouseOverElement={onMouseOverElement}
                    />
                    <div css={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                      <ElementsList
                        allElements={Object.keys(templateTheme) as (keyof ITheme)[]}
                        activeSectionElements={thisSectionElements}
                        activeElem={activeElem}
                        hoveredElem={hoveredElem}
                        onClickElement={setActiveElem}
                        onMouseOverElement={onMouseOverElement}
                        onMouseOutElement={onMouseOutElement}
                      />
                    </div>
                  </SkeletonWrapper>
                </SimplePanel>

                {activeElem && (
                  <SimplePanel>
                    <div style={{ padding: '16px 24px' }}>
                      <EditStyleForm
                        activeElem={activeElem}
                        templateTheme={templateTheme}
                        activeTheme={props.theme}
                        disabled={props.isBasicTier}
                        onThemeAttributeChange={onThemeAttributeChange}
                      />
                    </div>
                  </SimplePanel>
                )}
              </PaddingContainer>
            ),
          };
        })}
      />
      <Mask hidden={!showMask} label={getElementName(hoveredElem as string)} {...position} />
    </div>
  );
};

const ElementsList = (props: {
  allElements: (keyof ITheme)[];
  activeSectionElements: (keyof ITheme)[];
  activeElem: keyof ITheme | null;
  hoveredElem: keyof ITheme | null;
  onMouseOverElement: (e: { target: HTMLElement | null }) => void;
  onMouseOutElement: (e: { target: HTMLElement | null }, unsetHoveredElem?: boolean) => void;
  onClickElement: (elem: keyof ITheme) => void;
}) => {
  const {
    allElements,
    activeElem,
    hoveredElem,
    activeSectionElements,
    onClickElement,
    onMouseOutElement,
    onMouseOverElement,
  } = props;

  const [filter, setFilter] = useState<'all' | 'this_section'>('this_section');

  const filteredElems = (filter === 'this_section' ? activeSectionElements : allElements).sort();
  return (
    <Card
      style={{
        height: 'fit-content',
        boxShadow: 'none',
        paddingBottom: 0,
        padding: '0px',
        width: 150,
        maxWidth: 125,
        marginTop: '50px',
        gap: '0px',
      }}
    >
      <Header
        style={{
          fontSize: 12,
          marginBottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '4px 8px',
          borderBottom: `1px solid ${CB_COLORS.neutral300}`,
        }}
      >
        Elements{' '}
        <Popover
          content={
            <Radio.Group value={filter} onChange={(e) => setFilter(e.target.value)}>
              <Space direction="vertical">
                <Radio value="all">All</Radio>
                <Radio value="this_section">This section only</Radio>
              </Space>
            </Radio.Group>
          }
          trigger="click"
          placement="left"
        >
          <FilterFunnel01 width={10} height={10} />
        </Popover>
      </Header>

      <div
        id="bar-settings-elements-container"
        style={{
          scrollBehavior: 'smooth',
          maxHeight: '200px',
          width: '100%',
          overflowY: 'scroll',
          padding: '0px 8px',
        }}
      >
        {filteredElems.map((elem) => (
          <div
            id={`bar-settings-${elem}`}
            key={elem}
            css={{
              margin: '2px 0px',
              color: elem === activeElem ? CB_COLORS.primary : '#9ea3ae',
              cursor: 'pointer',
              '&:hover': {
                color: 'hsla(226, 87%, 55%, 1)',
              },
              ...(elem === hoveredElem && {
                color: 'hsla(226, 87%, 55%, 1)',
              }),
              transition: 'color 0.2s ease-in-out',
              fontSize: '11px',
            }}
            onClick={() => onClickElement(elem)}
            onMouseOver={() => onMouseOverElement({ target: document.getElementById(`bar-styles-${elem}`) })}
            onMouseOut={() => onMouseOutElement({ target: document.getElementById(`bar-styles-${elem}`) }, true)}
          >
            {getElementName(elem)}
          </div>
        ))}
      </div>
    </Card>
  );
};

export default ProStyleEditor;
