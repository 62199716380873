export const isStudioPreview = () => document.getElementById('commandbar-is-studio-preview') !== null;

export const isHelpHubMarketingSiteURL = (hostURL: string) => {
  const HELPHUB_MARKETING_SITE_URL = 'https://helphub.commandbar.com';
  const COPILOT_MARKETING_SITE_URL = 'https://copilot.commandbar.com';
  const DEV_STANDALONE_EDITOR_URL = 'http://localhost:5001';

  return (
    hostURL.startsWith(DEV_STANDALONE_EDITOR_URL) ||
    hostURL.startsWith(HELPHUB_MARKETING_SITE_URL) ||
    hostURL.startsWith(COPILOT_MARKETING_SITE_URL)
  );
};

export const isHelpHubMarketingSite = (() => {
  if (typeof window.location.href !== 'string') {
    return false;
  }
  return isHelpHubMarketingSiteURL(window.location.href);
})();

export const isValidURL = (str: string, protocolOptional = false) => {
  try {
    if (protocolOptional && !str.includes('://') && str.includes('.')) {
      str = 'https://' + str;
    }
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (err) {
    return false;
  }
};

export const DEBUG_NUDGE_PARAM = 'debug_nudge';
export const NUDGE_STEP_PARAM = 'nudge_step';
