import React from 'react';
import { ITheme } from '@commandbar/internal/client/theme';
import NoResultsViewSkeleton from './Skeletons/NoResultsView';
import ArgumentViewSkeleton from './Skeletons/ArgumentView';
import ListViewSkeleton from './Skeletons/ListView';
import SearchViewSkeleton from './Skeletons/SearchView';
import QuestlistViewSkeleton from './Skeletons/QuestlistView';
import NudgeViewSkeleton from './Skeletons/NudgeView';
import HelpHubViewSkeleton from './Skeletons/HelpHubView';
import { SkeletonProps } from './Skeletons/types';

export type Widget = 'bar' | 'nudges' | 'checklists' | 'help-hub';

type ThemeSection = {
  name: string;
  description?: string;
  skeleton: React.FunctionComponent<SkeletonProps>;
  elements: Array<keyof ITheme>;
};

export const ProEditorTabs: Record<Widget, Record<string, ThemeSection>> = {
  bar: {
    default: {
      name: 'Default state',
      skeleton: ListViewSkeleton,
      elements: [
        'base',
        'main',
        'bar',
        'optionList',
        'placeholder',
        'logo',
        'input',
        'option',
        'optionSelected',
        'optionDisabled',
        'optionDisabledSelected',
        'footer',
        'searchTab',
        'searchTabsContainer',
        'searchTabDescription',
        'categoryHeader',
        'keyboardShortcuts',
        'menu',
      ],
    },
    search: {
      name: 'Search state',
      skeleton: SearchViewSkeleton,
      elements: ['textSearchMatch', 'searchableFieldMatch'],
    },
    argument: {
      name: 'Argument state',
      skeleton: ArgumentViewSkeleton,
      elements: ['blocks', 'blocksContainer', 'instruction'],
    },
    no_results: {
      name: 'No results state',
      skeleton: NoResultsViewSkeleton,
      elements: ['noResults'],
    },
    other: {
      name: 'Other',
      skeleton: () => <div />,
      elements: [
        'launcher',
        'dashboard',
        'previewPopover',
        'navPane',
        'nextStepCommands',
        'detailPreview',
        // TODO: We might want to separate grids as a separate category
        'menuGridContainer',
        'menuGridItemCard',
        'menuGridItemCardSelected',
        'menuGridOption',
        'menuGridOptionSelected',
        'menuGridOptionDisabled',
        'menuGridOptionDisabledSelected',
      ],
    },
  },
  nudges: {
    default: {
      name: '',
      skeleton: NudgeViewSkeleton,
      elements: ['nudges', 'nudgeModal', 'nudgePin', 'nudgeTooltip'],
    },
  },
  checklists: {
    default: {
      name: '',
      skeleton: QuestlistViewSkeleton,
      elements: ['questlists', 'questlistItem'],
    },
  },
  'help-hub': {
    default: {
      name: '',
      skeleton: HelpHubViewSkeleton,
      elements: [
        'helpHub',
        'helpHubInput',
        'helpHubGridItem',
        'helpHubListItem',
        'helpHubInstantAnswerItem',
        // TODO: update the skeleton so this shows a HH article
        'helpHubDocItem',
        'helpHubAdditionalResources',
      ],
    },
  },
};
