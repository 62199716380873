import { INudgeStepType } from '@commandbar/internal/middleware/types';

type X = number;
type Y = number;

export type Coordinates2D = [X, Y];

export const roundByDPR = (value: number): number => {
  const dpr = window.devicePixelRatio || 1;
  return Math.round(value * dpr) / dpr;
};

export const getFirstMarkdownBlock = (content: INudgeStepType['content']) =>
  content
    .map((block) => (block.type === 'markdown' && block.meta.value ? block.meta.value : undefined))
    .filter((block) => !!block)
    .pop();
