import type { ColumnDef } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';

import { CmdBreadcrumb, CmdSortableColumn } from '@commandbar/design-system/cmd';
import { AnalyticsChecklists } from '@commandbar/internal/middleware/analytics/checklists';
import type { TableRowData } from '@commandbar/internal/middleware/analytics/common';
import { getChecklistUsersDemoData } from '../../analyticDemoData';
import FunnelChart from '../../components/FunnelChart';
import Layout from '../../components/Layout';
import StatCard from '../../components/StatCard';
import Table from '../../components/Table';
import { formatPercentage } from '../../utils';
import { ViewsTable } from '../ViewsTable';
import { useAnalyticChecklistDetails } from './useAnalyticChecklistDetails';

const columns: ColumnDef<TableRowData, string | number>[] = [
  {
    accessorKey: 'title',
    header: 'Checklist item',
    enableGlobalFilter: true,
  },
  {
    accessorKey: 'total_completions',
    header: ({ column }) => <CmdSortableColumn column={column} title="Completed" />,
  },
  {
    accessorKey: 'completion_rate',
    header: ({ column }) => <CmdSortableColumn column={column} title="Completion rate" />,
    cell: ({ getValue }) => formatPercentage(getValue()),
  },
];

const AnalyticsChecklistsDetailDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const { data, fetchData, isLoading, error, downloadCsv, isDownloading } = useAnalyticChecklistDetails({ id });

  return (
    <Layout
      disableDemoData={true}
      isDownloading={isDownloading}
      onDownloadCSV={downloadCsv}
      title={data?.checklistName || 'Checklist'}
      titleLink={`/editor/checklists/${id}`}
      subtitle={<CmdBreadcrumb items={[{ label: 'Checklists', to: '/analytics/checklists' }]} />}
    >
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            gap: '16px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              flex: 2,
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            <StatCard
              title="Completion rate"
              subtitle="Avg completion rate for checklist"
              format="percentage"
              metric={data?.completion_rate?.metric}
              rate={data?.completion_rate?.percent_change}
              isLoading={isLoading}
            />
            <StatCard
              title="Seen"
              subtitle="Users who have seen your checklist"
              format="number"
              metric={data?.views?.metric}
              rate={data?.views?.percent_change}
              isLoading={isLoading}
            />
          </div>
          <FunnelChart
            title="Performance"
            subtitle="Discover which steps are your top completed steps"
            chartHeight={350}
            data={data?.end_user_funnel}
            isLoading={isLoading}
            style={{ flex: 5 }}
          />
        </div>
        <Table
          columns={columns}
          data={data?.table.rows || []}
          isLoading={isLoading}
          empty={{
            error,
            refetchData: fetchData,
          }}
        />

        <ViewsTable id={id} readUsers={AnalyticsChecklists.readUsers} getDemoData={getChecklistUsersDemoData} />
      </div>
    </Layout>
  );
};

export default AnalyticsChecklistsDetailDashboard;
