import { HelpCircle } from '@commandbar/design-system/icons/react';
import * as React from 'react';
import { CmdTooltip } from '../tooltip';

import { cn } from '../util';

interface TableComponents {
  Header: typeof TableHeader;
  Body: typeof TableBody;
  Footer: typeof TableFooter;
  Row: typeof TableRow;
  Head: typeof TableHead;
  Cell: typeof TableCell;
  Caption: typeof TableCaption;
}

const TableComponent = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, style, ...props }, ref) => (
    <div className="relative w-full overflow-auto" style={style}>
      <table
        ref={ref}
        className={cn('w-full caption-bottom text-sm border-collapse bg-surfaceBase', className)}
        {...props}
      />
    </div>
  ),
);
TableComponent.displayName = 'CmdTable';

const CmdTable: React.ForwardRefExoticComponent<
  React.HTMLAttributes<HTMLTableElement> & React.RefAttributes<HTMLTableElement>
> &
  TableComponents = TableComponent as any;

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <thead ref={ref} className={cn('bg-surfaceMid', className)} {...props} />,
);

TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={cn('[&_tr:last-child]:border-0', className)} {...props} />
  ),
);
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot ref={ref} className={cn('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)} {...props} />
  ),
);
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn('border-b border-elementBase hover:bg-surfaceMid transition-colors', className)}
      {...props}
    />
  ),
);
TableRow.displayName = 'TableRow';

interface CmdTableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  tooltip?: string;
}

const TableHead = React.forwardRef<HTMLTableCellElement, CmdTableHeadProps>(({ className, tooltip, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'h-[40px] py-sm px-md text-base text-left font-medium text-gray800 border-solid border-t border-l border-b border-elementBase border-r-0 last:border-r items-center',
      className,
    )}
    {...props}
  >
    <div className="flex items-center	gap-xs">
      {props.children}
      {tooltip && (
        <CmdTooltip.Provider>
          <CmdTooltip.Root>
            <CmdTooltip.Trigger asChild>
              <span className={cn('flex py-xs')}>
                <HelpCircle className="text-gray800" />
              </span>
            </CmdTooltip.Trigger>
            <CmdTooltip.Content sideOffset={1}>{tooltip}</CmdTooltip.Content>
          </CmdTooltip.Root>
        </CmdTooltip.Provider>
      )}
    </div>
  </th>
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        'text-contentMid h-[40px] py-sm px-md text-base text-left border-solid border-t border-l border-b border-elementBase border-r-0 last:border-r overflow-hidden text-ellipsis whitespace-nowrap',
        className,
      )}
      {...props}
    />
  ),
);
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
  ),
);
TableCaption.displayName = 'TableCaption';

CmdTable.Header = TableHeader;
CmdTable.Body = TableBody;
CmdTable.Footer = TableFooter;
CmdTable.Row = TableRow;
CmdTable.Head = TableHead;
CmdTable.Cell = TableCell;
CmdTable.Caption = TableCaption;

export { CmdTable };
export type { CmdTableHeadProps };
