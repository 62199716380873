import { Alert, Col, Input, Row, Form, Checkbox } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const VanillaForums: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.VANILLA_FORUMS,
  name: 'Vanilla Forums',
  slug: 'vanilla_forums',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/vanilla-forums-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/vanilla-icon.webp',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Vanilla Forums articles, you'll have to first find your Vanilla Forums API Key."}
    />
  ),
  route: '/vanilla-forums',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          label="Vanilla Forums Domain"
          name="domain"
          rules={[
            { required: true, message: 'The domain is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.*$/gi,
              message: `Please enter a valid URL (e.g., your-company.vanillacommunities.com). Don't include 'http' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input style={{ width: '100%' }} placeholder="your-company.vanillacommunities.com" />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'api_key'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          label="API Key"
          name="api_key"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your API Key. `,
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + 'content_types'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.content_types}
          label="Content Types"
          name="content_types"
        >
          <Checkbox.Group name="content_types">
            <Row>
              <Col span={12}>
                <Checkbox value="articles">Articles</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="discussions">Discussions</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      api_key: data['api_key'],
      domain: data['domain'],
      content_types: data['content_types'],
    };
  },
  requiredFields: ['domain', 'api_key', 'content_types'],
  fetchedFields: [],
};

export default VanillaForums;
