import React from 'react';

import { CB_COLORS, Select, StatusBadge } from '@commandbar/design-system/components/antd';
import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import { CmdButton } from '@commandbar/design-system/cmd';
import { getCommandsRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import ArrowUpRight from '@commandbar/design-system/icons/react/ArrowUpRight';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import { useAppContext } from 'editor/src/AppStateContext';
import { useHistory } from 'react-router';
import { StyledExperienceSelect } from './components/styled';

import { ReactComponent as CaretDown } from '../img/caret_down.svg';

interface ICommandSelect {
  value: number | null | undefined;
  onChange: (value: number) => void;
  placeholder?: string;
  commandFilter?: (command: IEditorCommandTypeLite) => boolean;
  type?: 'video' | 'help doc' | 'action' | 'page';
  commands: IEditorCommandTypeLite[];
  standalone?: boolean;
}

export const CommandSelect = ({
  value,
  onChange,
  placeholder = 'Select action...',
  commandFilter = Boolean,
  type,
  commands,
  standalone,
}: ICommandSelect) => {
  const { isStudio } = useAppContext();
  const history = useHistory();

  const [isHovered, setIsHovered] = React.useState(false);

  const command = commands.find((n) => n.id === value);

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '32px', minWidth: '0px' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledExperienceSelect
        value={value}
        onChange={onChange}
        suffixIcon={<CaretDown />}
        filterOption={(input, option) =>
          !!option?.label && (option.label as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
        placeholder={placeholder}
        showSearch
        notFoundContent={`No ${type ? type : 'command'}s found`}
        style={{ ...(standalone && { borderRadius: '6px', border: `1px solid ${CB_COLORS.neutral400}` }) }}
      >
        {commands.filter(commandFilter).map((c, i) => (
          <Select.Option label={c.text} key={i} value={c.id}>
            <div
              style={{
                display: 'flex',
                gap: '8px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {c.is_live ? (
                <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" />
              ) : (
                <StatusBadge color="orange" />
              )}{' '}
              {c.text}
            </div>
          </Select.Option>
        ))}
      </StyledExperienceSelect>
      {isHovered && command && (
        <CmdButton
          size={'sm'}
          className="px-sm"
          style={{
            position: 'absolute',
            right: '24px',
            top: '4px',
          }}
          variant="default"
          onClick={(e) => {
            e.preventDefault();
            const route = getCommandsRoute(command);

            const isContent = route.startsWith('/content');

            const dashboardRoute = isContent
              ? `${process.env.REACT_APP_DASHBOARD_URL}${route}`
              : `${process.env.REACT_APP_DASHBOARD_URL}/editor${route}`;

            if (e.metaKey || e.ctrlKey) {
              window.open(dashboardRoute, '_blank');
            } else {
              if (isStudio) {
                dispatchCustomEvent('editorRouteUpdated', {
                  path: route,
                  action: 'PUSH',
                });
              } else {
                if (isContent) {
                  window.open(dashboardRoute, '_blank');
                } else {
                  history.push(route);
                }
              }
            }
          }}
        >
          Open <ArrowUpRight />
        </CmdButton>
      )}
    </div>
  );
};
