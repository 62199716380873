/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* react imports */
import React, { useEffect } from 'react';

/* internal imports */
import { CB_COLORS, Layout } from '@commandbar/design-system/components/antd';
import '../style/Menu.css';
import useMediaQuery from '../util/useMediaQuery';
import ProfileMenuButton from './nav/ProfileMenuButton';
import MainMenu from './nav/MainMenu';
import { footerItems, MenuItem } from './nav/menu_items';

import { LayoutLeft, SearchSm } from '@commandbar/design-system/icons/react';
import { InstallLink } from './InstallGuide';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';

/* deconstruction */
const { Sider } = Layout;

/*******************************************************************************/

type SideBarProps = {
  menuItems: MenuItem[];
};

const Sidebar: React.FC<SideBarProps> = ({ menuItems }) => {
  const isSmallScreen = useMediaQuery('mobile');
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(isSmallScreen);

  useEffect(() => {
    setIsCollapsed(isSmallScreen);
  }, [isSmallScreen]);

  return (
    <div
      id="main-nav-menu"
      style={{
        width: '220px',
        height: '100%',
        overflow: 'visible',
        background: CB_COLORS.neutral0,
      }}
    >
      {isSmallScreen && (
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          style={{
            cursor: 'pointer',
            background: '#FFFFFF',
            border: `1px solid ${CB_COLORS.neutral400}`,
            boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.05)',
            borderRadius: '0px 8px 8px 0px',
            width: '48px',
            height: '48px',

            position: 'absolute',
            display: 'flex',
            top: '50%',
            zIndex: 1000,
            left: isCollapsed ? '0' : '220px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LayoutLeft height={24} width={24} color={CB_COLORS.neutral700} />
        </div>
      )}
      {!isCollapsed && (
        <Sider
          width={'220px'}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            borderRight: '1px solid #DADADA',
            /* FIXME: change to a Z.Sidebar_Nav */
            /* magic number `999` chosen to be one less than antd mask */
            zIndex: 999,
          }}
          theme="light"
        >
          <div
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                padding: '8px',
                borderBottom: `1px solid ${CB_COLORS.neutral300}`,
              }}
            >
              <div
                style={{
                  padding: '8px',
                  height: '32px',
                  background: '#FFFFFF',
                  border: `1px solid ${CB_COLORS.neutral300}`,
                  boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
                  borderRadius: '6px',
                  color: CB_COLORS.neutral500,
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  gap: '4px',
                  flex: '1 1 0',
                }}
                onClick={() => {
                  window.CommandBar.open();
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SearchSm height={16} width={16} />
                  <span style={{ paddingLeft: '8px' }}>Find anything</span>
                </div>
                <span>{osControlKey('K')}</span>
              </div>
            </div>

            <MainMenu items={menuItems} isCollapsed={isCollapsed} />

            {!isSmallScreen && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '8px',
                }}
              >
                <InstallLink />
              </div>
            )}

            <div style={{ marginTop: 'auto' }}>
              <MainMenu items={footerItems} isCollapsed={isCollapsed} />
              <ProfileMenuButton isCollapsed={isCollapsed} />
            </div>
          </div>
        </Sider>
      )}
    </div>
  );
};

export default Sidebar;
