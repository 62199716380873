import { useState } from 'react';
import ContentTable from '../shared/ContentTable';
import { useAppContext } from 'editor/src/AppStateContext';
import KeywordEditDrawer from './KeywordEditDrawer';
import { IKeyword } from '@commandbar/internal/middleware/types';
import { useHistory } from 'react-router';
import { LoadingState } from '../shared/styles';

interface AnswersProps {
  showAddContent: boolean;
  setShowAddContent: (show: boolean) => void;
}

const Keywords = ({ showAddContent, setShowAddContent }: AnswersProps) => {
  const { keywords, loading } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [activeKeyword, setActiveKeyword] = useState<IKeyword>();
  const history = useHistory();

  const columns = [
    {
      title: <div>Keyword</div>,
      dataIndex: 'keyword',
      key: 'keyword',
      ellipsis: true,
    },
    {
      title: <div>Definition</div>,
      dataIndex: 'definition',
      key: 'definition',
      ellipsis: true,
    },
  ];

  if (isLoading || loading) return <LoadingState />;

  return (
    <>
      <ContentTable
        columns={columns}
        data={keywords}
        dataMappingFunction={(data: any[]) =>
          data.map((h) => {
            return {
              ...h,
              title: h.text,
            };
          })
        }
        emptyText="You don't have any keywords yet. Add one by clicking the 'Add Keyword' button above."
        setIsLoading={setIsLoading}
        openEditDrawer={async (id: number) => {
          const matchingCommand = keywords.find((f) => f.id === id);
          setActiveKeyword(matchingCommand);
          setShowAddContent(true);
        }}
      />
      <KeywordEditDrawer
        open={showAddContent}
        onClose={() => {
          setShowAddContent(false);
          setActiveKeyword(undefined);

          const searchParams = new URLSearchParams(history.location.search);
          searchParams.delete('id');
          history.replace(`${history.location.pathname}?${searchParams.toString()}`);
        }}
        activeFile={activeKeyword}
      />
    </>
  );
};

export default Keywords;
