import React from 'react';

import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import type { AmplitudeCondition as AmplitudeConditionType } from '@commandbar/internal/middleware/conditions';
import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { ConditionProps } from '../../types';
import { ConditionTypeSelect } from '../ConditionTypeSelect';
import { OperatorSelect } from '../OperatorSelect';

export const AmplitudeCondition: React.FC<ConditionProps<AmplitudeConditionType>> = ({ condition }) => {
  const { onConditionChange, setHasBlurred } = useConditionEditor();
  const { organization } = useAppContext();

  const amplitudeCohort =
    !!condition.field && !organization?.integrations?.amplitude?.cohorts?.hasOwnProperty(condition.field)
      ? 'Not Available'
      : condition.field;

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />

      <Col flex="1 1 auto" style={{ overflow: 'hidden' }}>
        <Select
          value={amplitudeCohort}
          onChange={(value) => {
            onConditionChange({ ...condition, field: value });
          }}
          dropdownMatchSelectWidth={false}
          style={{ maxWidth: '100%', width: '100%' }}
          size="small"
          placeholder="Select a Amplitude Cohort"
          onBlur={() => setHasBlurred(true)}
        >
          {organization?.integrations?.amplitude?.cohorts
            ? Object.entries(organization?.integrations?.amplitude?.cohorts).map(([key, label]) => {
                return (
                  <Select.Option value={key} key={key}>
                    {label}
                  </Select.Option>
                );
              })
            : null}
        </Select>
      </Col>
      <OperatorSelect />
    </Row>
  );
};
