import type { INudgeType } from '../middleware/types';

export type TUpdateEditorRouteDetails =
  | { type: 'nudge'; nudge: INudgeType; stepIndex: number }
  | { type: 'checklist'; checklistId: string | number }
  | { type: 'route'; route_replacement: string; action?: string };

export function dispatchCustomEvent<T>(eventType: string, detail: T, canBubble = false, cancelable = false) {
  // Salesforce does not support `initCustomEvent` which is deprecated anyway, so we use the constructor instead.
  const ev: CustomEvent<T> = new CustomEvent(eventType, { detail, bubbles: canBubble, cancelable });
  document.dispatchEvent(ev);
  return ev;
}
