const fieldAccessExpression = (prefix: string, key: string) => {
  if (key.match(/^[a-zA-Z_$][a-zA-Z_$0-9]*$/)) {
    return `${prefix}.${key}`;
  } else {
    return `${prefix}['${key}']`;
  }
};

/**
 * Utility function to return all valid paths in a nested object
 *
 * A path here confirms to `lodash.get` syntax
 *
 * Eg: With prefix "context" and depth 2
 * {
 *    name: "Boogeyman",
 *    foo: "baz",
 *    "nested": { "works": { "to": "" } }
 * }
 *
 * returns [
 *  "context.name",
 *  "context.foo",
 *  "context.nested",
 *  "context.nested.works",
 * ]
 *
 * Eg: With prefix "context" and depth 2
 * {
 *    name: "Boogeyman",
 *    foo: "baz",
 *    a1: [1,2,3]
 * }
 *
 * returns [
 *  "context.name",
 *  "context.foo",
 *  "context.a1"
 * ]
 */
const getAllValidPathsOfObjectProps = (
  o: string | number | object | object[] | null | undefined,
  prefix: string,
  depth = 1,
): string[] => {
  if (o === null || o === undefined) return [];
  if (typeof o === 'string') return [];
  if (typeof o === 'number') return [];

  if (depth === 0) {
    return [];
  }

  if (Array.isArray(o)) {
    return [];
  }

  return Object.entries(o).flatMap(([k, v]) => {
    const _prefix = fieldAccessExpression(prefix, k);
    return [_prefix, ...getAllValidPathsOfObjectProps(v, _prefix, depth - 1)];
  });

  return [];
};

export default getAllValidPathsOfObjectProps;
