import React, { useState } from 'react';
import { CmdButton, CmdCard, CmdEmpty, CmdSelect, CmdTag, CmdTypography } from '@commandbar/design-system/cmd';
import { Edit02, Trash03 } from '@commandbar/design-system/icons/react';
import { formatMetric } from '../utils';
import PercentChangeTag from './PercentChangeTag';
import styled from '@emotion/styled';
import { EndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import * as Sentry from '@sentry/react';

type GoalCardProps = {
  title: string;
  subtitle: string;

  eventName?: string;
  metric: number | string | null | undefined;
  rate?: number | null;
  isLoading?: boolean;
  onGoalEventChange: (eventName?: string) => void;
};

const StyledCmdCard = styled(CmdCard)<{ animationDisabled: boolean }>`
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(98deg, #fff3 8.24%, #e6deff 76.09%, #def7ff 86.91%, #fff 97.68%);
    background-size: 200% 200%;
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ animationDisabled }) => (animationDisabled ? '0' : '1')};
    z-index: -1;

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    animation: ${({ animationDisabled }) => (animationDisabled ? 'none' : 'gradient 5s ease-in-out infinite')};
  }
`;

export const GoalCard = ({ title, subtitle, eventName, onGoalEventChange, metric, rate, isLoading }: GoalCardProps) => {
  const hasGoalSet = !!eventName;

  // This gets set when the user clicks the edit button after the goal has been initially set to change the goal
  // when we are actively editing, don't trigger an onGoalEventChange until the user clicks save
  const [editingEventName, setEditingEventName] = useState<string | undefined>(undefined);
  const [eventNames, setEventNames] = useState<string[]>([]);
  const isEditing = !!editingEventName;

  React.useEffect(() => {
    EndUserAdmin.customEvents()
      .then(({ names }) => {
        setEventNames(names);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, []);

  const onChange = (value: string) => {
    if (isEditing) {
      setEditingEventName(value);
    } else {
      onGoalEventChange(value);
    }
  };

  const onEditClick = () => {
    setEditingEventName(eventName);
  };

  const onDeleteClick = () => {
    onGoalEventChange(undefined);
  };

  const onSaveClick = () => {
    setEditingEventName(undefined);
    onGoalEventChange(editingEventName);
  };

  // Don't render loading state when the goal hasn't been set
  const renderSelect = !hasGoalSet || (!!isEditing && !isLoading);
  // Only show stat when the goal has been set and we aren't editing
  const renderStat = hasGoalSet && !isEditing && !isLoading;
  // Only show loading when the goal has been set
  const renderLoading = isLoading && hasGoalSet;
  // only show the buttons when we have a goal we aren't editing and we're not rendering loading
  const renderButtons = hasGoalSet && !isEditing && !renderLoading;

  return (
    <StyledCmdCard
      animationDisabled={hasGoalSet}
      style={{
        flex: '1',
        minHeight: '140px',
        minWidth: '182px',
      }}
    >
      <CmdCard.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          gap: '16px',
        }}
      >
        <div style={{ display: 'flex', gap: '16px', justifyContent: 'space-between' }}>
          <div>
            <CmdTypography.Body fontWeight="semi-bold">{title}</CmdTypography.Body>
            <CmdTypography.HelpText variant="secondary" fontWeight="medium">
              {subtitle}
            </CmdTypography.HelpText>
          </div>
          {renderButtons && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <CmdButton iconOnly icon={<Edit02 />} onClick={onEditClick} />
              <CmdButton iconOnly icon={<Trash03 />} onClick={onDeleteClick} />
            </div>
          )}
        </div>
        {renderSelect && (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <CmdSelect.Menu value={editingEventName || eventName} onValueChange={onChange}>
              {/** TODO: Check if a non-search/non-autocomplete input is ok or not */}
              <CmdSelect.SelectTrigger style={{ flex: 1 }}>
                <CmdSelect.SelectValue placeholder="Select an event..." />
              </CmdSelect.SelectTrigger>
              <CmdSelect.SelectContent>
                {eventNames.map((name) => (
                  <CmdSelect.SelectItem key={name} value={name}>
                    {name}
                  </CmdSelect.SelectItem>
                ))}
              </CmdSelect.SelectContent>
            </CmdSelect.Menu>
            {isEditing && (
              <CmdButton variant="primary" size="default" onClick={onSaveClick}>
                Save
              </CmdButton>
            )}
          </div>
        )}
        {renderStat && (
          <div>
            <CmdTag>
              <CmdTypography.Body fontWeight="medium" className="text-contentMid">
                {eventName}
              </CmdTypography.Body>
            </CmdTag>
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <div>
                <CmdTypography.H1 fontWeight="semi-bold">{formatMetric(metric, 'percentage')}</CmdTypography.H1>
              </div>
              {rate !== undefined && rate !== null && <PercentChangeTag rate={rate} />}
            </div>
          </div>
        )}
        {renderLoading && (
          <div
            style={{
              maxWidth: '187px',
            }}
          >
            <CmdEmpty />
          </div>
        )}
      </CmdCard.Content>
    </StyledCmdCard>
  );
};
