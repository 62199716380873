import React, { SVGProps, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  CmdButton,
  CmdColumnDef,
  CmdDataTable,
  CmdDropdown,
  CmdSortableColumn,
  CmdTooltip,
  CmdTypography,
  CmdSheet,
  CmdStatusDot,
  CmdRow,
  CmdSortingState,
} from '@commandbar/design-system/cmd';
import {
  Announcement02,
  BookClosed,
  CheckSquareBroken,
  Copy02,
  DotsVertical,
  Edit03,
  Flag03,
  MessageSmileSquare,
  Plus,
  SearchMd,
  Trash04,
} from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { timeAgoFormat } from '../analytics/utils';
import {
  IChecklist,
  ICopilotFallback,
  IEditorCommandTypeLite,
  INudgeType,
  IRecommendationSet,
} from '@commandbar/internal/middleware/types';
import useRecommendationSets from 'editor/src/editor/useEditor/useRecommendationSets';
import { INamedRule } from '@commandbar/internal/middleware/helpers/rules';
import DeleteModal from './DeleteModal';
import RuleDetailDrawer from './RuleDetailDrawer';
import { capitalize } from 'lodash';
import { ReactComponent as EmptyState } from '../../image/audience-empty-state.svg';
import { Source } from '../integrations/shared/types';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

import { useIntegrationsContext } from '../util/IntegrationContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import useChatSettings from 'editor/src/editor/useEditor/useChatSettings';

type FilterOption = 'all' | 'active' | 'inactive';

export type ExperienceRow = {
  title: string;
  link: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

export interface SelectedRule extends INamedRule {
  numEntitiesUsingThisRule: number;
  entitiesUsingThisRule: ExperienceRow[];
}

const AudiencePage = () => {
  const [statusFilter, setStatusFilter] = useState<FilterOption>('all');
  const [selectedRule, setSelectedRule] = useState<SelectedRule | undefined>(undefined);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAuth();

  const { rules, nudges, checklists, commands, dispatch } = useAppContext();
  const { copilotFallbacks } = useChatSettings();
  const { helpDocIntegrations } = useIntegrationsContext();
  const { recommendationSets } = useRecommendationSets();
  const [sorting, setSorting] = useState<CmdSortingState>([]);

  const sources: Array<Source> = useMemo(
    () =>
      helpDocIntegrations
        .reduce<Array<Source>>(
          (acc, spec) => [
            ...acc,
            ...(spec.sources || []).map((source) => ({
              ...source,
              ...spec,
            })),
          ],
          [],
        )
        .sort((a, b) => a.id - b.id),
    [helpDocIntegrations],
  );

  const audiences = useMemo(() => rules.filter((rule) => rule.is_audience), [rules]);

  const entitiesByNamedRuleId = useMemo(() => {
    const result: {
      [id: string]: {
        nudges: INudgeType[];
        checklists: IChecklist[];
        commands: IEditorCommandTypeLite[];
        recommendationSets: IRecommendationSet[];
        sources: Source[];
        fallbacks: ICopilotFallback[];
      };
    } = {};

    const addEntityToResult = (
      ruleId: string | number,
      type: 'nudges' | 'checklists' | 'commands' | 'recommendationSets' | 'sources' | 'fallbacks',
      entity: any,
    ) => {
      result[ruleId] ||= {
        nudges: [],
        checklists: [],
        commands: [],
        recommendationSets: [],
        sources: [],
        fallbacks: [],
      };
      result[ruleId][type].push(entity);
    };

    nudges.forEach((nudge) => {
      if (nudge.audience?.type === 'named_rule_reference') {
        addEntityToResult(nudge.audience.rule_reference.rule_id, 'nudges', nudge);
      }
    });

    checklists.forEach((checklist) => {
      if (checklist.audience?.type === 'named_rule_reference') {
        addEntityToResult(checklist.audience.rule_reference.rule_id, 'checklists', checklist);
      }
    });

    sources?.forEach((source) => {
      if (source.audience?.type === 'named_rule_reference') {
        addEntityToResult(source.audience.rule_reference.rule_id, 'sources', source);
      }
    });

    copilotFallbacks.forEach((fallback) => {
      if (fallback.audience?.type === 'named_rule_reference') {
        addEntityToResult(fallback.audience.rule_reference.rule_id, 'fallbacks', fallback);
      }
    });

    recommendationSets?.forEach((recommendation) => {
      if (recommendation?.audience?.type === 'named_rule_reference') {
        const ruleId = recommendation.audience.rule_reference.rule_id;
        const namedCommands =
          recommendation.actions?.flatMap((action) => {
            if (action.action.type === 'execute_command') {
              return commands.filter(
                (c) => action.action.type === 'execute_command' && action.action.meta.command === c.id.toString(),
              );
            }
            return [];
          }) || [];

        namedCommands.forEach((command) =>
          addEntityToResult(ruleId, 'commands', { ...command, recommendationSetID: recommendation.id }),
        );

        addEntityToResult(ruleId, 'recommendationSets', recommendation);

        if (namedCommands.length === 0) {
          result[ruleId] ||= {
            nudges: [],
            checklists: [],
            commands: [],
            recommendationSets: [],
            sources: [],
            fallbacks: [],
          };
          result[ruleId].commands = [];
        }
      }
    });

    return result;
  }, [nudges, checklists, recommendationSets, commands]);

  useEffect(() => {
    if (!isDelete && !isOpen) {
      setSelectedRule(undefined);
    }
  }, [isDelete]);

  const handleRemove = (rule: INamedRule) => {
    const entitiesUsingThisRule = entitiesByNamedRuleId[rule.id];
    const numEntitiesUsingThisRule = entitiesUsingThisRule ? Object.values(entitiesUsingThisRule).flat().length : 0;

    if (numEntitiesUsingThisRule > 0) {
      const entitiesUsingThisRuleDetails: ExperienceRow[] = [
        ...entitiesUsingThisRule.recommendationSets.map((r) => ({
          link: `editor/help-hub/${r.id}`,
          title: r.name,
          icon: SearchMd,
        })),
        ...entitiesUsingThisRule.sources.map((s) => ({
          link: `content/sources/${s.id}`,
          title:
            s.meta?.recursive_urls?.[0] || s.meta?.single_urls?.[0]
              ? `${s.name} - ${s.meta?.recursive_urls?.[0] ?? s.meta?.single_urls?.[0]}`
              : s.name,
          icon: BookClosed,
        })),
        ...entitiesUsingThisRule.nudges.map((n) => ({
          link: `editor/${n.type === 'product_tour' ? 'product-tours' : `${n.type}s`}/${n.id}`,
          title: n.slug || `Untitled ${n.type === 'product_tour' ? 'product tour' : n.type}`,
          icon: n.type === 'announcement' ? Announcement02 : n.type === 'product_tour' ? Flag03 : MessageSmileSquare,
        })),
        ...entitiesUsingThisRule.checklists.map((c) => ({
          link: `editor/checklists/${c.id}`,
          title: c.title || 'Untitled checklist',
          icon: CheckSquareBroken,
        })),
      ];

      setIsDelete(true);
      setSelectedRule({ ...rule, numEntitiesUsingThisRule, entitiesUsingThisRule: entitiesUsingThisRuleDetails });
    } else {
      dispatch.rules.removeRule(rule.id)();
      setIsDelete(false);
      setIsOpen(false);
    }
  };

  const onOpenChange = () => {
    if (!isOpen) setSelectedRule(undefined);
    setIsDelete(false);
  };

  const enhancedAudiences = React.useMemo(() => {
    return audiences.map((audience) => {
      const entitiesUsingThisRule = entitiesByNamedRuleId[audience.id];
      const numEntitiesUsingThisRule = entitiesUsingThisRule ? Object.values(entitiesUsingThisRule).flat().length : 0;
      const hasEntity = numEntitiesUsingThisRule > 0;

      return {
        ...audience,
        numEntitiesUsingThisRule,
        hasEntity,
      };
    });
  }, [audiences, entitiesByNamedRuleId]);

  const filteredAudiences = React.useMemo(() => {
    const filtered = enhancedAudiences.filter((audience) => {
      if (statusFilter === 'active' && audience.hasEntity) return true;
      else if (statusFilter === 'inactive' && !audience.hasEntity) return true;
      else if (statusFilter === 'all') return true;
      return false;
    });

    const createdSorting = sorting.find((sort) => sort.id === 'created');

    if (createdSorting !== undefined) {
      filtered.sort((a, b) => {
        const aDate: Date = a.created ? new Date(a.created) : new Date(Number.MIN_VALUE);
        const bDate: Date = b.created ? new Date(b.created) : new Date(Number.MIN_VALUE);
        return createdSorting.desc ? bDate.getTime() - aDate.getTime() : aDate.getTime() - bDate.getTime();
      });
    }

    return filtered;
  }, [enhancedAudiences, statusFilter, sorting]);

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'name',
      header: 'Audience',
      enableGlobalFilter: true,
      onClick: (row: CmdRow<SelectedRule>) => {
        setSelectedRule(row.original);
        setIsOpen(true);
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CmdStatusDot variant={row.original.hasEntity ? 'success' : 'warning'} />
          {row.original.hasEntity ? 'Active' : 'Inactive'}
        </div>
      ),
    },
    {
      accessorKey: 'created',
      header: ({ column }) => <CmdSortableColumn column={column} title="Created on" />,
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{timeAgoFormat(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => (
        <CmdDropdown.Menu>
          <CmdDropdown.Trigger style={{ display: 'flex' }}>
            <CmdButton icon={<DotsVertical />} variant="link" />
          </CmdDropdown.Trigger>
          <CmdDropdown.Content>
            <CmdDropdown.Item
              key="edit"
              onClick={() => {
                setSelectedRule(row.original);
                setIsOpen(true);
              }}
            >
              <>
                <Edit03 /> Edit
              </>
            </CmdDropdown.Item>
            <CmdDropdown.Item
              key="duplicate"
              onClick={() => dispatch.rules.addRule({ ...row.original, name: `${row.original.name} copy` }, false)}
            >
              <>
                <Copy02 /> Duplicate
              </>
            </CmdDropdown.Item>
            <CmdDropdown.Separator />
            <CmdDropdown.Item key="delete" onClick={() => handleRemove(row.original)}>
              <>
                <Trash04 /> Delete
              </>
            </CmdDropdown.Item>
          </CmdDropdown.Content>
        </CmdDropdown.Menu>
      ),
    });
  }

  return (
    <div style={{ width: '100%', background: '#F9F9F9', padding: '24px', height: '100%', overflow: 'auto' }}>
      {selectedRule && isDelete && <DeleteModal selectedRule={selectedRule} onOpenChange={onOpenChange} />}

      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '24px', justifyContent: 'space-between' }}>
        <CmdTypography.H2 variant="primary" fontWeight="medium">
          Audiences
        </CmdTypography.H2>

        <CmdSheet
          open={isOpen}
          onOpenChange={(value) => {
            setIsOpen(value);
            if (!value) setSelectedRule(undefined);
          }}
          modal={true}
        >
          {hasRequiredRole(user, 'contributor') && (
            <CmdSheet.Trigger>
              <CmdButton icon={<Plus />} variant="primary">
                Create Audience
              </CmdButton>
            </CmdSheet.Trigger>
          )}

          <CmdSheet.Content
            style={{ width: '560px', right: '12px' }}
            onInteractOutside={(event) => event.preventDefault()}
            closeable={false}
            overlay={true}
          >
            <RuleDetailDrawer setIsOpen={setIsOpen} editingRule={selectedRule} deleteRule={handleRemove} />
          </CmdSheet.Content>
        </CmdSheet>
      </div>

      <div>
        <CmdDataTable
          columns={columns}
          data={filteredAudiences}
          isLoading={false}
          emptyResultChildren={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
              <EmptyState />
              <CmdTypography.Body variant="secondary">No audiences found</CmdTypography.Body>
            </div>
          }
          onSortingChange={setSorting}
          manualSorting
          searchResultChildren={
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
              <EmptyState />
              <CmdTypography.Body variant="secondary">No audiences found</CmdTypography.Body>
            </div>
          }
          state={{
            rowSelection: {
              [selectedRule?.id || '']: true,
            },
            sorting,
          }}
          getRowId={(row) => row.id || ''}
          toolBarChildren={
            <CmdDropdown.Menu>
              <CmdDropdown.SelectTrigger style={{ minWidth: '150px' }}>
                Status: {capitalize(statusFilter)}
              </CmdDropdown.SelectTrigger>
              <CmdDropdown.Content style={{ minWidth: '150px' }}>
                <CmdDropdown.RadioGroup
                  value={statusFilter}
                  onValueChange={(value) => setStatusFilter(value as FilterOption)}
                >
                  <CmdDropdown.RadioItem value="all">All</CmdDropdown.RadioItem>
                  <CmdDropdown.RadioItem value="active">Active</CmdDropdown.RadioItem>
                  <CmdDropdown.RadioItem value="inactive">Inactive</CmdDropdown.RadioItem>
                </CmdDropdown.RadioGroup>
              </CmdDropdown.Content>
            </CmdDropdown.Menu>
          }
        />
      </div>
    </div>
  );
};

export default AudiencePage;
