import React from 'react';
import { IUserType } from '@commandbar/internal/middleware/types';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { CmdButton } from '@commandbar/design-system/cmd';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import * as Sentry from '@sentry/react';

interface Props {
  user: IUserType | null;
  inExtension?: boolean;
}

const HijackUserBanner = ({ user, inExtension = false }: Props) => {
  const [isOpen, setIsOpen] = React.useState(user?.is_hijacked);
  const [loading, setIsLoading] = React.useState(false);

  const onRelease = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.post(`/hijack/release/`);
      window.location.reload();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  if (!isOpen) return null;

  return (
    <Container inExtension={inExtension}>
      <Banner>
        {`❗ Impersonating ${user?.email}`}
        <CmdButton onClick={() => setIsOpen(false)}>Hide</CmdButton>
        <CmdButton loading={loading} onClick={onRelease}>
          Release
        </CmdButton>
      </Banner>
    </Container>
  );
};

export default HijackUserBanner;

const Container = styled.div<{ inExtension: boolean }>`
  position: fixed;
  pointer-events: none;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: ${({ inExtension }) => (inExtension ? '0' : '10px')};
  width: 100%;
  z-index: ${Z.Z_INDEX_MAX};
`;

const Banner = styled.div`
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;
  background-color: rgba(255, 229, 143, 0.8);
  border-radius: 4px 4px 0 0;
`;
