import React, { useEffect } from 'react';
import { Redirect, Route, Switch, generatePath, useParams, useRouteMatch } from 'react-router';

import { ISkinType } from '@commandbar/internal/middleware/types';
import { Skin } from '@commandbar/internal/middleware/skin';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';

import SkinList from './skins/SkinList';
import Sender from '../management/Sender';
import SkinDetail from './skins/SkinDetail';
import { Skeleton } from '@commandbar/design-system/components/antd';
import { useAppContext } from '../AppStateContext';

const previewSkin = async (skin: string | Pick<ISkinType, 'logo' | 'skin'>) => {
  Sender.setTheme('reset');
  Sender.setTheme(skin);
};

const SkinDetailContainer = ({
  tier,
  skins,
  refreshSkins,
}: {
  tier: string;
  skins: ISkinType[];
  refreshSkins: () => Promise<void>;
}) => {
  const routeMatch = useRouteMatch();
  const defaultSkin = skins.find((skin: ISkinType) => skin.default);
  const { skinId, advanced } = useParams<{ skinId?: string; advanced?: string }>();
  const showAdvancedStyles = advanced === 'advanced';
  if (!skinId) {
    return <Redirect to={`${editorRoutes.DESIGN_ROUTE}`} />;
  }

  if (skinId === '$default' && defaultSkin) {
    return <Redirect to={generatePath(routeMatch.path, { ...routeMatch.params, skinId: defaultSkin.id })} />;
  }

  const skin = skins.find((skin: ISkinType) => skin.id === skinId);
  if (!skin) {
    return <Redirect to={`${editorRoutes.DESIGN_ROUTE}`} />;
  }

  if (showAdvancedStyles && tier !== 'pro') {
    return <Redirect to={`${editorRoutes.DESIGN_ROUTE}/skins/${skinId}`} />;
  }

  return (
    <SkinDetail
      showAdvancedStyles={showAdvancedStyles}
      tier={tier}
      skin={skin}
      onUpdate={refreshSkins}
      previewSkin={previewSkin}
      allSkins={skins}
    />
  );
};

const VisualStyleEditor = () => {
  const [skins, setSkins] = React.useState<ISkinType[] | undefined>(undefined);
  const [isThemeSetProgrammatically, setIsThemeSetProgrammatically] = React.useState(false);
  const { organizationSettings, flags } = useAppContext();
  const tier = organizationSettings?.skins_field_set;
  const [state, setState] = React.useState<'loading' | 'done' | 'error'>('loading');

  const refreshSkins = async () => {
    const skins = await Skin.list();
    setSkins(skins);
  };

  useEffect(() => {
    Promise.all([refreshSkins()])
      .then(() => setState('done'))
      .catch(() => setState('error'));
  }, []);

  useEffect(() => {
    const handleProgrammaticTheme = async () => {
      const { data } = await Sender.getProgrammaticTheme();

      if (['light', 'dark'].includes(data as string)) {
        setIsThemeSetProgrammatically(true);
      }
    };

    handleProgrammaticTheme();
  }, []);

  if (flags['release-themes-v2']) return <Redirect to={`${editorRoutes.THEME_ROUTE}`} />;

  if (state === 'loading')
    return (
      <div style={{ padding: '24px', background: '#f2f2f2', height: '100%' }}>
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  if (state === 'error' || !tier || !skins) throw Error('Error loading skins');

  return (
    <Switch>
      <Route path={`${editorRoutes.DESIGN_ROUTE}/skins/:skinId/:advanced(advanced)?/:widget?`}>
        <SkinDetailContainer tier={tier} skins={skins} refreshSkins={refreshSkins} />
      </Route>
      <Route>
        <SkinList skins={skins} isThemeSetProgrammatically={isThemeSetProgrammatically} refreshSkins={refreshSkins} />
      </Route>
    </Switch>
  );
};

export default VisualStyleEditor;
