/** @jsx jsx  */
import { css, jsx, keyframes } from '@emotion/core';
import React from 'react';

export type GlowBorderProps = {
  showGlowBorder: boolean;
};

const rotate = keyframes`
	100% {
		transform: translate(-50%, -50%) rotate(1turn);
	}
}`;

const fadeInBorder = keyframes`
  from {
    background: white;
  }
  to {
    background: #7856ff
  }
`;

export const GlowBorder: React.FC<GlowBorderProps> = ({ showGlowBorder, children }) => {
  return (
    <React.Fragment>
      {showGlowBorder ? (
        <div
          css={css`
            position: relative;
            overflow: hidden;
            z-index: 0;
            padding: 12px;
            animation: ${fadeInBorder} 0.3s forwards 0.3s;
            border-radius: 11px;
            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              left: 1px;
              top: 1px;
              width: calc(100% - 2px);
              height: calc(100% - 2px);
              background: white;
              border-radius: 10px;
            }
            &::before {
              content: '';
              z-index: -2;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(0deg);
              position: absolute;
              width: 300%;
              height: 300%;
              background-repeat: no-repeat;
              background-position: 0 0;
              background-image: conic-gradient(rgba(0, 0, 0, 0), #e4ebff, rgba(0, 0, 0, 0) 25%);
              animation: ${rotate} 4s linear 0.5s infinite;
            }
          `}
        >
          {children}
        </div>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  );
};
