import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { AnalyticsOverviewData, AnalyticsOverview } from '@commandbar/internal/middleware/analytics/overview';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { getOverviewDemoData } from '../../analyticDemoData';

export type OverviewDashboardCard = {
  title: string;
  tooltip?: string;
  icon: JSX.Element;
  url?: string;
  type?: 'card';
};

const useAnalyticOverview = () => {
  const [data, setData] = useState<AnalyticsOverviewData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  useEffect(() => {
    if (isDemoData) {
      setData(getOverviewDemoData());
      setIsLoading(false);
    } else {
      setIsLoading(true);
      AnalyticsOverview.read({ ...timeFilterRange, include_admin_data: includeAdminData.toString() })
        .then(setData)
        .catch((e) => {
          Sentry.captureException(e);
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [timeFilterRange, isDemoData, includeAdminData]);

  return {
    data,
    isLoading,
  };
};

export default useAnalyticOverview;
