type HandleStyleElementClick = (id: string) => void;

export const findNearestStyleElement = (el: HTMLElement | null): HTMLElement | null => {
  if (!el) {
    return null;
  }

  if (!el?.id || !el?.id.startsWith('bar-styles-')) {
    if (el.tagName === 'svg') {
      // If we hit the top of the SVG, no point going further
      return null;
    }

    return findNearestStyleElement(el.parentElement);
  }

  return el;
};

export const handleStyleElementClick = (e: any, cb: HandleStyleElementClick) => {
  const styleElement = findNearestStyleElement(e.target);
  const id = styleElement?.id;

  if (!id) {
    return;
  }

  cb(id.split('-')[2]);
};
