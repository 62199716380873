import { useCallback, useEffect, useState } from 'react';
import { IProfileType, IUserType } from '@commandbar/internal/middleware/types';
import TeamMembersContext from './util/TeamMembersContext';
import { Profile } from '@commandbar/internal/middleware/profile';

const TeamMembersContextProvider = ({ user, children }: { user: IUserType; children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<IProfileType[]>([]);

  const fetchTeammates = useCallback(async () => {
    setLoading(true);
    const profiles: IProfileType[] = await Profile.list();
    setMembers(profiles);
    setLoading(false);
    return profiles;
  }, [user.id]);

  useEffect(() => {
    if (!user.id) return;
    fetchTeammates();
  }, [user.id]);

  const contextValue = {
    loading: loading,
    members: members,
    removeMember: (member: IProfileType) => {
      const _members = members.filter((m) => m.user !== member.user);
      setMembers(_members);
    },
    addMember: (email: string, role: IProfileType['role']) => {
      setMembers((members) => {
        const member: IProfileType = {
          user: email,
          is_active: false,
          organization: user.organization,
          role: role,
          email: email,
          first_name: '',
          last_name: '',
        };
        const _members = [...members, member];
        return _members;
      });
    },
    changeRole: (email: string, role: IProfileType['role']) => {
      setMembers((members) => {
        const memberToChange = members.find((m) => m.email === email);
        if (!memberToChange) return members;
        memberToChange.role = role;

        const _members = members.map((v: IProfileType) => (v.email === memberToChange.email ? memberToChange : v));
        return _members;
      });
    },
  };

  return <TeamMembersContext.Provider value={contextValue}>{children}</TeamMembersContext.Provider>;
};

export default TeamMembersContextProvider;
