import React, { useState, useEffect, useRef, useCallback, useContext, type ReactNode } from 'react';
import type { ActorRefFrom } from 'xstate';

import type { SurveyResponseEvent } from '@commandbar/commandbar/shared/services/analytics/EventHandler';
import type { INudgeStepType } from '@commandbar/internal/middleware/types';
import type NudgeMachine from '../store/nudgeMachine';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

interface SurveyResponseContextType {
  surveyResponse: SurveyResponseEvent['response'] | undefined;
  setSurveyResponse: (response: SurveyResponseEvent['response'] | undefined) => void;
}

const SurveyResponseContext = React.createContext<SurveyResponseContextType | undefined>(undefined);

interface SurveyResponseProviderProps {
  step: INudgeStepType;
  service: ActorRefFrom<typeof NudgeMachine> | undefined;
  children: ReactNode;
}

export const SurveyResponseProvider: React.FC<SurveyResponseProviderProps> = ({ step, service, children }) => {
  const nudgeServiceSnapshot = service?.getSnapshot()?.context;
  const { onExit } = useAnimatedWidget();
  const [surveyResponse, _setSurveyResponse] = useState<SurveyResponseEvent['response'] | undefined>(
    nudgeServiceSnapshot?.surveyResponses[step.id] || undefined,
  );

  const advanceTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const setSurveyResponse = useCallback(
    (response: SurveyResponseEvent['response'] | undefined) => {
      _setSurveyResponse(response);

      // auto advance to next step on rating survey response if the step does not have buttons
      if (
        !!response &&
        response !== surveyResponse &&
        response?.type !== 'string' &&
        !step.content.some((block) => block?.type === 'button')
      ) {
        advanceTimeoutId.current = setTimeout(() => {
          onExit(() => {
            service?.send({
              type: 'ADVANCE',
              surveyResponse: response,
            });
          });
        }, 200);
      }
    },
    [service?.id],
  );

  useEffect(() => {
    return () => {
      if (advanceTimeoutId.current) {
        clearTimeout(advanceTimeoutId.current);
      }
    };
  }, []);

  return (
    <SurveyResponseContext.Provider value={{ surveyResponse, setSurveyResponse }}>
      {children}
    </SurveyResponseContext.Provider>
  );
};

export const useSurveyResponse = () => {
  const context = useContext(SurveyResponseContext);
  if (!context) {
    throw new Error('useSurveyResponse must be used within a SurveyResponseProvider');
  }

  return context;
};
