import { Checkbox, Form, Select } from '@commandbar/design-system/components/antd';
import { CmdButton, CmdInput, CmdLabel, CmdTextarea, cmdToast } from '@commandbar/design-system/cmd';
import { ButtonRow, DrawerTitle, FooterRow, StyledDrawer } from '../shared/styles';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Upload01, XClose } from '@commandbar/design-system/icons/react';
import IconPicker from 'editor/src/editor/components/IconPicker/IconPicker';
import ImageUploader, { Image } from 'editor/src/editor/components/DragUpload/ImageUploader';
import Logger from '@commandbar/internal/util/Logger';
import debounce from 'lodash/debounce';
import * as Command from '@commandbar/internal/middleware/command';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { DrawerFooterDeleteButton, LegacyOptions, hasLegacyValue, isContentObject } from '../shared/utils';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { useAppContext } from 'editor/src/AppStateContext';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { FormItemHorizontal } from '../../integrations/shared/styles';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';

type createFileProps = {
  title: string;
  content: string;
  icon?: string;
  iconColor?: string;
  thumbnail: Image | null;
  trainingOnly: boolean;
  organizationId: string;
  isLive: boolean;
};

const FileEditDrawer = ({
  open,
  onClose,
  organizationId,
  activeFile,
  isEditingHelpDoc,
}: {
  open: boolean;
  onClose: () => void;
  organizationId: string | number | undefined;
  activeFile: IEditorCommandType | undefined;
  isEditingHelpDoc?: boolean;
}) => {
  const [form] = Form.useForm();
  const [icon, setIcon] = useState('document');
  const [iconColor, setIconColor] = useState('#000000');
  const [thumbnail, setThumbnail] = useState<Image | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [updates, setUpdates] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const { user } = useAuth();
  const trainingOnly = Form.useWatch('trainingOnly', form);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (!fileInputRef.current) return;

    // Reset the input value to allow uploading the same file multiple times
    fileInputRef.current.value = '';

    fileInputRef.current.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];

    if (file.size > 10 * 1024 * 1024) {
      cmdToast.error('File size should be 10 MB or less.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsUploading(true);
    try {
      const response = await axiosInstance.post('/upload_file/', formData);

      const result = await response.data;
      if (result.status !== 'success' && result.error) {
        cmdToast.error(result.error);
        return;
      }
      if (result.text && result.text.length > 0) {
        const title = form.getFieldValue('title') || file.name.split('.').slice(0, -1).join('.');
        form.setFieldsValue({ content: result.text, title: title, trainingOnly: true });
        checkIsValid(updates + 1);
      }
    } catch (error) {
      cmdToast.error('Something went wrong while uploading the file.');
      Logger.error('Error uploading file: ', error);
    } finally {
      setIsUploading(false);
    }
  };

  const {
    dispatch: {
      commands: { save },
    },
  } = useAppContext();

  const createFile = async ({
    title,
    content,
    icon,
    iconColor,
    thumbnail,
    organizationId,
    trainingOnly,
    isLive,
  }: createFileProps) => {
    const newCommand = Command.decodeEditorCommand({
      id: -1,
      organization: organizationId,
      text: title,
      content: content,
      is_live: isLive,
      template: { type: 'helpdoc', operation: 'blank', value: '' },
      training_only: trainingOnly,
      thumbnail: thumbnail,
      icon: icon ?? null,
      icon_color: iconColor ?? null,
      show_in_helphub_search: true,
      show_in_spotlight_search: false,
    });
    await save(newCommand);
  };

  const shouldProvidLegacyOptions = activeFile && hasLegacyValue(activeFile);

  useEffect(() => {
    if (activeFile) {
      form.setFieldsValue({
        isLive: activeFile.is_live,
        title: activeFile.text,
        content: activeFile.content && isContentObject(activeFile.content) ? activeFile.content[0].value : undefined,
        trainingOnly: activeFile.training_only,
      });
      activeFile.icon && setIcon(activeFile.icon);
      activeFile.icon_color && setIconColor(activeFile.icon_color);
      activeFile.thumbnail && setThumbnail(activeFile.thumbnail);
    } else {
      form.resetFields();
    }
  }, [activeFile]);

  const onDrawerClose = () => {
    setIsValid(false);
    setUpdates(0);
    form.resetFields();
    setIcon('document');
    setIconColor('#000000');
    setThumbnail(null);
    onClose();
  };

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, form.getFieldValue('isLive') ? 'editor' : 'contributor');

  const checkIsValid = (numUpdates: number, loading?: boolean) => {
    setIsValid(
      numUpdates > 0 &&
        isAllowedToSave &&
        !loading &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        (isEditingHelpDoc ||
          Object.values(form.getFieldsValue(['title', 'content'])).filter((val) => val === undefined || val === '')
            .length === 0),
    );
  };

  useEffect(() => {
    checkIsValid(updates);
  }, [updates]);

  useModS(() => {
    if (!isValid) return;

    onFinish({
      title: form.getFieldValue('title'),
      content: form.getFieldValue('content'),
      trainingOnly: form.getFieldValue('trainingOnly'),
      isLive: form.getFieldValue('isLive'),
    });
  });

  const onFinish = useCallback(
    async ({
      title,
      content,
      trainingOnly,
      isLive,
    }: {
      title: string;
      content: string;
      trainingOnly: boolean;
      isLive: boolean;
    }) => {
      if (!organizationId) return;

      setIsSaving(true);

      try {
        if (activeFile) {
          await save({
            ...activeFile,
            text: title,
            is_live: isLive,
            training_only: trainingOnly,
            thumbnail: thumbnail,
            icon: icon ?? null,
            icon_color: iconColor ?? null,
            ...(!isEditingHelpDoc && { content: content }),
          });
        } else {
          await createFile({
            organizationId: organizationId.toString(),
            title,
            content,
            icon,
            iconColor,
            thumbnail,
            trainingOnly,
            isLive,
          });
        }
      } catch (error) {
        cmdToast.error('Something went wrong while creating the file.');
        Logger.error('Error creating file: ', error);
      } finally {
        setIsSaving(false);
        onDrawerClose();
      }
    },
    [createFile, form, icon, iconColor, thumbnail],
  );

  if (!organizationId) return <></>;

  const FooterButtons = () => (
    <ButtonRow>
      <CmdButton variant={'link'} onClick={onDrawerClose}>
        Cancel
      </CmdButton>
      <CmdButton disabled={!isValid} loading={isSaving} variant="primary" type="submit" form="create-file">
        {activeFile ? 'Save Changes' : 'Create File'}
        <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
      </CmdButton>
    </ButtonRow>
  );

  return (
    <StyledDrawer
      key={'create-file-drawer' + activeFile ? activeFile?.id.toString() : '-1'}
      width="500px"
      placement="right"
      onClose={onDrawerClose}
      open={open}
      closeIcon={<XClose />}
      maskStyle={{ color: '#000000', opacity: '0.2' }}
      title={<DrawerTitle>{isEditingHelpDoc ? 'Edit Doc' : activeFile ? 'Edit File' : 'New File'}</DrawerTitle>}
      footer={
        activeFile ? (
          <FooterRow>
            <DrawerFooterDeleteButton
              disabled={!isAllowedToSave}
              activeCommand={activeFile}
              onDelete={() => {
                onDrawerClose();
              }}
            />
            <FooterButtons />
          </FooterRow>
        ) : (
          <FooterButtons />
        )
      }
    >
      <Form
        style={{ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
        layout="vertical"
        key={'create-file' + activeFile ? activeFile?.id.toString() : '-1'}
        name="create-file"
        form={form}
        onFinish={onFinish}
        onValuesChange={debounce(() => {
          setUpdates((updates) => updates + 1);
        }, 300)}
      >
        <div>
          <Form.Item
            key={'isLive'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Status</CmdLabel>}
            name={'isLive'}
            initialValue={activeFile?.is_live ?? true}
          >
            <Select disabled={!isAllowedToPublish}>
              <Select.Option key={'unpublished'} value={false}>
                Unpublished
              </Select.Option>
              <Select.Option key={'published'} value={true}>
                Published
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            key={'title'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Title</CmdLabel>}
            name={'title'}
            rules={[{ required: true, message: 'This is a required field.' }]}
          >
            <CmdInput fullWidth placeholder="File title..." disabled={isEditingHelpDoc} />
          </Form.Item>
          {isEditingHelpDoc && (
            <Form.Item
              label={<CmdLabel>Source URL</CmdLabel>}
              name={'url'}
              initialValue={activeFile?.template.value.toString()}
            >
              <CmdInput fullWidth disabled />
            </Form.Item>
          )}
          {!isEditingHelpDoc && (
            <>
              <Form.Item
                key={'content'}
                required={true}
                validateTrigger="onChange"
                label={
                  <CmdLabel
                    tooltip={
                      'Add text or upload a file. Supported file types: .pdf, .docx, .doc, and .txt. Size limit: 10MB.'
                    }
                  >
                    Content
                  </CmdLabel>
                }
                name={'content'}
                rules={[{ required: true, message: 'This is a required field.' }]}
              >
                <CmdTextarea
                  fullWidth
                  placeholder="File content. Accepts text or HTML."
                  style={{ minHeight: '128px' }}
                />
              </Form.Item>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.txt"
              />
              <CmdButton
                style={{ marginTop: -12, marginBottom: 12 }}
                variant="default"
                icon={<Upload01 />}
                onClick={handleUploadClick}
                fullWidth
                loading={isUploading}
              >
                Upload file - .pdf, .docx, or .txt
              </CmdButton>
            </>
          )}
          {!trainingOnly && (
            <>
              <Form.Item key={'icon'} validateTrigger="onChange" label={<CmdLabel>Icon</CmdLabel>}>
                <IconPicker
                  icon={icon}
                  color={iconColor}
                  colors={[]}
                  onIconSelect={(icon) => {
                    setIcon(icon);
                    setUpdates((updates) => updates + 1);
                  }}
                  onColorSelect={(icon_color) => {
                    setIconColor(icon_color);
                    setUpdates((updates) => updates + 1);
                  }}
                />
              </Form.Item>

              {isAllowedToSave && (
                <Form.Item
                  key={'thumbnail'}
                  label={
                    <CmdLabel tooltip="Thumbnails are displayed when files are included as part of a HelpHub recommendation set.">
                      Thumbnail
                    </CmdLabel>
                  }
                  style={{ marginBottom: 8 }}
                >
                  <ImageUploader
                    onUpload={(thumbnail) => {
                      setThumbnail(thumbnail);
                    }}
                    onDelete={() => {
                      setThumbnail(null);
                      setUpdates((updates) => updates + 1);
                    }}
                    thumbnail={thumbnail}
                    organizationId={organizationId}
                    setIsLoading={(loading, updated) => {
                      updated && setUpdates((updates) => updates + 1);
                      checkIsValid(updated ? updates + 1 : updates, loading);
                    }}
                  />
                </Form.Item>
              )}
            </>
          )}
          <FormItemHorizontal
            key={'trainingOnly'}
            name={'trainingOnly'}
            valuePropName="checked"
            label={
              <CmdLabel tooltip="When enabled, this file will only be used to improve AI generated Copilot answers and won't be shown in search results.">
                Use for training only
              </CmdLabel>
            }
          >
            <Checkbox />
          </FormItemHorizontal>
        </div>
        {shouldProvidLegacyOptions && <LegacyOptions id={activeFile?.id} />}
      </Form>
    </StyledDrawer>
  );
};

export default FileEditDrawer;
