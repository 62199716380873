// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const AlertTriangle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m2.39 18.098 8.225-14.206c.455-.785.682-1.178.979-1.31a1 1 0 0 1 .812 0c.297.132.524.525.979 1.31l8.225 14.206c.456.788.684 1.182.65 1.506a1 1 0 0 1-.406.705c-.263.191-.719.191-1.63.191H3.776c-.91 0-1.366 0-1.629-.191a1 1 0 0 1-.406-.705c-.034-.324.194-.718.65-1.506Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 9v4m0 4h.01M10.615 3.892 2.39 18.098c-.456.788-.684 1.182-.65 1.506a1 1 0 0 0 .406.705c.263.191.719.191 1.63.191h16.449c.91 0 1.366 0 1.629-.191a1 1 0 0 0 .406-.705c.034-.324-.194-.718-.65-1.506L13.385 3.892c-.455-.785-.682-1.178-.979-1.31a1 1 0 0 0-.812 0c-.297.132-.524.525-.979 1.31Z"
    />
  </svg>
);
export default AlertTriangle;
