import styled from '@emotion/styled';

import { CB_COLORS } from '@commandbar/design-system/components/antd';

export const TargetingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BorderedContainer = styled.div`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${CB_COLORS.neutral0};
  border-radius: 4px;
`;

export const StyledEventAutoComplete = styled.div`
  width: 100%;
  border: 1px solid ${CB_COLORS.neutral500};
  border-radius: 4px;

  & .ant-select-selector {
    border: none !important;
  }

  & .ant-select {
    height: 32px !important;
  }

  & .ant-select-selector {
    height: 32px !important;
  }

  & input {
    height: 32px !important;
  }
`;

export const TooltippedLabel = styled.div`
  display: flex;
  min-height: 16px;
  align-items: center;
  gap: 4px;
`;
