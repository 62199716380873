import React, { type CSSProperties, type ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CmdButton, CmdTooltip } from '@commandbar/design-system/cmd';
import { InputContainer, StyledLabel } from '@commandbar/design-system/components/antd';
import {
  AngryRage,
  ClockSnooze,
  Copy02,
  InfoCircle,
  MessageQuestionCircle,
} from '@commandbar/design-system/icons/react';
import type { Flags } from '@commandbar/internal/middleware/flags';
import { type TriggerableEntity, isNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { Panel } from '..';
import { ShareLinkModal } from '../../ShareLinkModal';
import { Section } from '../Section';
import { TooltippedLabel } from '../styled';
import { LimitEditor } from './LimitEditor';
import { TriggerOptions } from './Options';
import { TypeSelect } from './TypeSelect';

const getPluralizedScheduleInterval = ({
  interval,
  value,
}: (INudgeType & { trigger: { type: 'scheduled' } })['trigger']['meta']): string => {
  const shouldPluralize = value > 1;
  if (shouldPluralize) {
    return `${value} ${interval}s`;
  }

  return `${interval}`;
};

const verboseLimit = (trigger: INudgeType['frequency_limit']) => {
  switch (trigger) {
    case 'no_limit':
      return '& without limit';
    case 'once_per_session':
      return '& once per session';
    case 'once_per_user':
      return '& once per user';
    case 'until_interaction':
      return '& until completed or dismissed';
    default:
      break;
  }

  return '';
};

export const TRIGGER_CATEGORIES = {
  basic: 'Basic',
  smart: 'Smart',
  custom: 'Custom',
} as const;

export type TriggerCategory = keyof typeof TRIGGER_CATEGORIES;

type TriggerType = {
  category: TriggerCategory;
  label: string;
  description?: string;
  icon?: {
    component: ReactNode;
    border: CSSProperties['border'];
    background: CSSProperties['background'];
  };
  condition?: () => boolean;
};

export type TriggerTypes = Record<TriggerableEntity['trigger']['type'], TriggerType>;

export const TRIGGER_TYPES: TriggerTypes = {
  when_share_link_viewed: { category: 'basic', label: 'None' },
  when_conditions_pass: { category: 'basic', label: 'Immediately' },
  after_time: { category: 'basic', label: 'After time on page' },
  scheduled: { category: 'basic', label: 'Scheduled' },
  when_element_appears: { category: 'basic', label: 'When element appears' },
  when_page_reached: { category: 'basic', label: 'When page reached' },
  smart_delay: {
    category: 'smart',
    label: 'Smart delay',
    description: 'Show after a user has completed their task',
    icon: {
      component: <ClockSnooze color="#2754EE" />,
      border: '1px solid #D8E0FF',
      background: 'linear-gradient(180deg, #FFF 0%, #EAEFFF 100%)',
    },
  },
  on_rage_click: {
    category: 'smart',
    label: 'Rage click',
    description: 'Show when detecting rapid, successive clicks',
    icon: {
      component: <AngryRage color="#DC2626" />,
      border: '1px solid #FEE2E2',
      background: 'linear-gradient(180deg, #FFF 0%, #FFF2F2 100%)',
    },
  },
  on_user_confusion: {
    category: 'smart',
    label: 'User confusion',
    description: 'Upon detecting a confusion pattern from the user',
    icon: {
      component: <MessageQuestionCircle color="#F97316" />,
      border: '1px solid #FFEDD5',
      background: 'linear-gradient(180deg, #FFF 0%, #FFF5E9 100%)',
    },
  },
  on_event: { category: 'custom', label: 'On event tracked' },
  on_command_execution: { category: 'custom', label: 'On action executed' },
} as const;

export const allTriggers = Object.entries(TRIGGER_TYPES).map(([key, value]) => ({
  key,
  ...value,
}));

// INFO: smart triggers only applicable to nudges right now
const createSmartTriggerFilter =
  (entity: TriggerableEntity) =>
  (category: string): boolean =>
    category === 'smart' ? isNudge(entity) : true;

const createWhenElementAppearsFilter =
  (flags: Flags) =>
  ({ key }: (typeof allTriggers)[number]): boolean =>
    key === 'when_element_appears' ? flags['enable-when-element-appears-trigger'] : true;

// INFO: this trigger type is deprecated. It cannot be selected from the editor but should still be preserved
// on nudges where it's already set.
const createWhenPageReachedFilter =
  ({ trigger }: TriggerableEntity) =>
  ({ key }: (typeof allTriggers)[number]): boolean =>
    trigger.type === 'when_page_reached' ? true : key !== 'when_page_reached';

interface TriggerProps<T> {
  dirty: T;
  handleOnChange: (changes: T) => void;
}

export const Trigger = <T extends TriggerableEntity>({ dirty, handleOnChange }: TriggerProps<T>) => {
  const { commands, flags } = useAppContext();

  const [isShareLinkModalOpen, setIsShareLinkModalOpen] = useState(false);

  const location = useLocation();
  const isEditingTemplate = location.pathname.includes('template');

  const { trigger } = dirty;

  const verboseTrigger = useMemo(() => {
    switch (trigger.type) {
      case 'when_conditions_pass':
        return 'Immediately';
      case 'when_element_appears':
        return `${trigger.meta.selector} appears`;
      case 'on_command_execution': {
        const command = commands.find((command) => String(command.id) === trigger.meta.command);
        return `On action executed: ${command?.text || 'Not set'}`;
      }
      case 'on_event':
        return `On event tracked: ${trigger.meta.event || 'Not set'}`;
      case 'when_page_reached':
        return 'When a user visits a specific page';
      case 'when_share_link_viewed':
        return 'None';
      case 'on_user_confusion':
        return 'On user confusion';
      case 'on_rage_click':
        return 'On rage click';
      case 'smart_delay':
        return 'Smart delay';
      case 'after_time':
        return `After ${trigger.meta.value}${trigger.meta.unit === 'minute' ? 'm' : 's'}`;
      case 'scheduled':
        return `Every ${getPluralizedScheduleInterval(trigger.meta)}`;
      default:
        break;
    }

    return '';
  }, [commands, trigger]);

  const handleTriggerTypeChange = useCallback(
    (e: TriggerableEntity['trigger']['type']) => {
      switch (e) {
        case 'on_command_execution': {
          handleOnChange({ ...dirty, trigger: { type: e, meta: { command: '' } } });
          break;
        }
        case 'on_event': {
          handleOnChange({ ...dirty, trigger: { type: e, meta: { event: '' } } });
          break;
        }
        case 'when_share_link_viewed':
        case 'when_page_reached': {
          handleOnChange({ ...dirty, trigger: { type: e, meta: { url: '' } } });
          break;
        }
        case 'when_element_appears': {
          if (isNudge(dirty)) {
            handleOnChange({
              ...dirty,
              frequency_limit: dirty.frequency_limit === 'no_limit' ? 'until_interaction' : dirty.frequency_limit,
              trigger: { type: e, meta: { selector: '' } },
            });
          } else {
            handleOnChange({ ...dirty, trigger: { type: e, meta: { selector: '' } } });
          }
          break;
        }
        case 'after_time': {
          handleOnChange({ ...dirty, trigger: { type: e, meta: { unit: 'second', value: 10 } } });
          break;
        }
        case 'scheduled': {
          handleOnChange({ ...dirty, trigger: { type: e, meta: { interval: 'day', value: 14 } } });
          break;
        }
        default: {
          handleOnChange({ ...dirty, trigger: { type: e } });
          break;
        }
      }
    },
    [dirty, handleOnChange],
  );

  const smartTriggerFilter = createSmartTriggerFilter(dirty);
  const whenElementAppearsFilter = createWhenElementAppearsFilter(flags);
  const whenPageReachedFilter = createWhenPageReachedFilter(dirty);

  const showWhenLimit = dirty.trigger.type !== 'when_share_link_viewed';

  return (
    <Section
      sectionKey={Panel.Trigger}
      heading="When"
      subheading={
        <>
          {verboseTrigger}{' '}
          {isNudge(dirty) && dirty.trigger.type !== 'when_share_link_viewed' && verboseLimit(dirty.frequency_limit)}
        </>
      }
    >
      <ShareLinkModal shareableEntity={dirty} isOpen={isShareLinkModalOpen} setIsOpen={setIsShareLinkModalOpen} />
      <InputContainer>
        <TooltippedLabel>
          <StyledLabel>Trigger</StyledLabel>

          {dirty.trigger.type === 'scheduled' && (
            <CmdTooltip.Provider>
              <CmdTooltip.Root>
                <CmdTooltip.Trigger style={{ padding: 0 }}>
                  <div style={{ display: 'flex' }}>
                    <InfoCircle color="#797C85" />
                  </div>
                </CmdTooltip.Trigger>

                <CmdTooltip.Content>{`Trigger this Nudge every ${getPluralizedScheduleInterval(
                  dirty.trigger.meta,
                )}`}</CmdTooltip.Content>
              </CmdTooltip.Root>
            </CmdTooltip.Provider>
          )}
        </TooltippedLabel>

        <TypeSelect
          dirty={dirty}
          handleTriggerTypeChange={handleTriggerTypeChange}
          categoryFilter={smartTriggerFilter}
          triggerFilter={(trigger) => [whenElementAppearsFilter, whenPageReachedFilter].every((f) => f(trigger))}
        />
      </InputContainer>

      <TriggerOptions
        trigger={dirty.trigger}
        handleTriggerChange={(trigger) => {
          handleOnChange({
            ...dirty,
            trigger,
          });
        }}
      />

      {isNudge(dirty) && showWhenLimit && (
        <LimitEditor
          triggerType={dirty.trigger.type}
          frequencyLimit={dirty.frequency_limit}
          onFrequencyLimitChange={(limit) => {
            handleOnChange({
              ...dirty,
              frequency_limit: limit,
            });
          }}
        />
      )}

      <CmdButton
        variant="default"
        modifier="blueHover"
        size="sm"
        onClick={() => setIsShareLinkModalOpen(true)}
        disabled={Number(dirty.id) < 0 || isEditingTemplate}
        className="border-none bg-surfaceMid"
        title={dirty.id ? undefined : 'Save this experience to get a shareable link'}
      >
        Trigger this experience with a link
        <Copy02 />
      </CmdButton>
    </Section>
  );
};
