import * as t from 'io-ts';

import {
  createObject,
  decodeThrowing,
  decodeToPromise,
  deleteObject,
  listObject,
  readObject,
  update,
} from './generics';
import * as axiosInstance from './network';
import { IThemeType, WidgetTableauSelection } from '../middleware/types';

export type IThemeAnimatedWidgetType = 'modal' | 'popover' | 'tooltip' | 'spotlight' | 'checklist' | 'helphub';
export type IThemeAnimationType = 'instant' | 'fade' | 'spring' | 'scale';

export const ThemeModeV = t.type({
  var_defaults: t.record(t.string, t.union([t.string, t.number])),
  var_overrides: t.record(t.string, t.union([t.string, t.number])),
  component_overrides: t.record(t.string, t.any),
  mobile_overrides: t.record(t.string, t.any),
});

export const ThemeV2V = t.union([
  t.type({
    light_mode: ThemeModeV,
    dark_mode: ThemeModeV,
  }),
  t.null,
  t.undefined,
]);

export const ThemeV = t.type(
  {
    id: t.string,
    uuid: t.union([t.string, t.null, t.undefined]),
    slug: t.string,
    name: t.string,
    default: t.boolean,
    themeV2: ThemeV2V,
    themeV2_draft: ThemeV2V,
    organization: t.string,
    archived: t.boolean,
  },
  'Theme',
);

const defaults = {};

export const ThemePatchV = t.partial({
  id: t.string,
  slug: t.string,
  name: t.string,
  default: t.boolean,
  themeV2: ThemeV2V,
  themeV2_draft: ThemeV2V,
  organization: t.string,
  archived: t.boolean,
});

export const ThemeSlugReponseV = t.type({
  slug: t.string,
});

export class Theme {
  public static list = listObject(ThemeV, 'themes');
  public static read = readObject(ThemeV, 'themes');
  public static update = (theme: t.TypeOf<typeof ThemePatchV>) =>
    update(ThemeV, ThemePatchV, `themes/${theme.slug}`)(theme);
  public static create = createObject(ThemeV, ThemePatchV, 'themes');
  public static delete = deleteObject(ThemeV, 'themes');
  public static decode = (data: any) => decodeThrowing(ThemeV, { ...defaults, ...data });

  public static getDefaultThemeSlug = async (
    widgetType: WidgetTableauSelection['type'],
    widgetId?: number | string,
  ) => {
    let url = `themes/get_theme_slug_for_experience/?widgetType=${widgetType}`;
    if (widgetId !== undefined) {
      url += `&widgetId=${widgetId}`;
    }
    const result = await axiosInstance.get(url);
    return await decodeToPromise(t.exact(ThemeSlugReponseV), result.data);
  };
}

export const hasUnpublishedChanges = (theme: IThemeType): boolean => {
  return JSON.stringify(theme.themeV2) !== JSON.stringify(theme.themeV2_draft);
};
