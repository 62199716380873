import { get } from 'lodash';
import type { IExperienceTemplate, INudgePinStepType, INudgeStepType, INudgeType } from '../middleware/types';

export const getDisplayTitle = (nudge: INudgeType): string =>
  nudge.slug || nudge.steps?.[0]?.title || nudge.steps?.[0]?.form_factor.type || 'nudge';

export const getTemplateDisplayTitle = (nudge: IExperienceTemplate): string =>
  nudge.data.slug || nudge.data?.steps?.[0]?.title || nudge.data?.steps?.[0]?.form_factor.type || 'nudge';

export const duplicateNudge = (nudge: INudgeType, newSlug: string, props?: Partial<INudgeType>): INudgeType => {
  const newNudge: INudgeType = {
    ...nudge,
    id: -1,
    old_nudge_id: null,
    is_live: false,
    slug: newSlug,
    steps: [
      ...nudge.steps.map((step) => ({
        ...step,
        is_live: false,
        has_survey_response: false,
      })),
    ],
    ...props,
  };
  return newNudge;
};

const hasNumber = (str: string) => /\d/.test(str);
export const calcOffsetFromInput = (offset: string) => (hasNumber(offset) ? Number.parseInt(offset, 10) : 0);

export const getNudgeStep = (nudge: INudgeType, stepIndex: number) => get(nudge.steps, stepIndex, undefined);

export const isPinStep = (step?: INudgeStepType): step is INudgePinStepType => step?.form_factor.type === 'pin';
