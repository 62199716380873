import React, { type FC, useState, type ReactNode } from 'react';

import {
  CB_COLORS,
  Header,
  PaddingContainerSM,
  StyledCollapse,
  StyledHeader,
  StyledPanel,
  Subheader,
} from '@commandbar/design-system/components/antd';
import type { Panel } from '.';

import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';
import { ReactComponent as CaretUp } from '../../../img/caret_up.svg';

interface SectionProps {
  sectionKey: Panel;
  heading: string;
  subheading?: ReactNode;
  defaultExpanded?: boolean;
}

export const Section: FC<SectionProps> = ({ sectionKey, heading, subheading, defaultExpanded, children }) => {
  const [isExpanded, setIsExpanded] = useState(!!defaultExpanded);

  return (
    <StyledCollapse
      defaultActiveKey={defaultExpanded ? sectionKey : undefined}
      onChange={() => {
        setIsExpanded((e) => !e);
      }}
      expandIcon={() => null}
    >
      <StyledPanel
        key={sectionKey}
        header={
          <StyledHeader>
            <Header>
              <span>{heading}</span>
              {isExpanded ? (
                <CaretUp style={{ color: CB_COLORS.neutral600 }} />
              ) : (
                <CaretDown style={{ color: CB_COLORS.neutral600 }} />
              )}
            </Header>
            {subheading && !isExpanded && <Subheader>{subheading}</Subheader>}
          </StyledHeader>
        }
      >
        <PaddingContainerSM>{children}</PaddingContainerSM>
      </StyledPanel>
    </StyledCollapse>
  );
};
