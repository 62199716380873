/*******************************************************************************/
/* Imports
/*******************************************************************************/

import * as t from 'io-ts';
import { IEditorCommandType } from './types';

export const DetailPreviewObjectV = t.intersection([
  t.type({
    type: t.union([
      t.literal('plaintext'),
      t.literal('markdown'),
      t.literal('html'),
      t.literal('react'),
      t.literal('video'),
      t.literal('component'),
    ]),
    value: t.string,
  }),
  t.partial({ position: t.union([t.literal('inline'), t.literal('popover')]) }),
]);

export type DetailPreviewObjectType = t.TypeOf<typeof DetailPreviewObjectV> & unknown;

export const DetailPreviewV = t.union([
  t.string,
  DetailPreviewObjectV,
  t.array(t.union([DetailPreviewObjectV, t.string])),
]);
export type DetailPreviewType = t.TypeOf<typeof DetailPreviewV> & unknown;

export type DataRowMetadata = {
  type: 'command' | 'parameter';
  label: string;
  value: any;
  contextKey?: string;
  uid?: string;
  inputText?: string;
};

export const DEFAULT_DETAIL_PREVIEW_TYPE: DetailPreviewObjectType['type'] = 'html';

export const standardize = (item: undefined | null | DetailPreviewType): null | DetailPreviewObjectType[] => {
  if (!item) return null;

  if (Array.isArray(item) && item.length === 0) return null;

  if (Array.isArray(item)) {
    return item
      .filter((i) => {
        return !!i;
      })
      .map((i) => {
        return standardizeString(i);
      });
  }

  return [standardizeString(item)];
};

const standardizeString = (item: string | DetailPreviewObjectType): DetailPreviewObjectType => {
  if (typeof item === 'string') return { type: DEFAULT_DETAIL_PREVIEW_TYPE, value: item };

  return item;
};

export const getFirstDetail = (detail?: DetailPreviewType | null): DetailPreviewObjectType | null => {
  const _detail = standardize(detail);

  if (!_detail || _detail.length === 0) return null;

  return _detail[0];
};

/** `true` iff the help document's body should also be used as the 'preview' for this command */
export const shouldUseHelpDoc = (command: IEditorCommandType) => {
  const detail = getFirstDetail(command.detail);
  let argumentDetailValue = undefined;

  if (command.arguments['__html__']?.type === 'html') {
    argumentDetailValue = command.arguments['__html__'].value.source;
  }

  return command.template.type === 'helpdoc' && (detail ? detail.value === argumentDetailValue : true);
};
