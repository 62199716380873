import React from 'react';

import { ActionEditor } from '../../ActionEditor';
import * as S from '../styled';
import useWindowInfo from '../../../hooks/useWindowInfo';
import RouterWarning from '../../RouterWarning';

import type {
  INudgeContentSurveyRatingBlockType,
  INudgeStepContentBlockType,
  INudgeStepContentButtonBlockType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import AutoCompleteTextArea from '../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import { Route, Trash04, ZapFast } from '@commandbar/design-system/icons/react';
import LogicEditor from '../../LogicEditor';
import { SurveyBlock, findLogicInputBlocks } from '../utils';
import styled from '@emotion/styled';
import { CmdButton, CmdLabel } from '@commandbar/design-system/cmd';

interface AddActionButtonProps {
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  block: INudgeStepContentButtonBlockType;
}

const AddActionButton = ({ onBlockChange, block }: AddActionButtonProps) => (
  <CmdButton
    icon={<ZapFast height={16} opacity="0.5" color="#000" style={{ marginRight: '4px' }} />}
    onClick={() => {
      onBlockChange({
        type: 'button',
        sort_key: block.sort_key,
        meta: {
          ...block.meta,
          action: { type: 'link', value: '', operation: 'self' },
        },
      });
    }}
  >
    Add action
  </CmdButton>
);

interface AddLogicButtonProps {
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  block: INudgeStepContentButtonBlockType;
}

const AddLogicButton = ({ onBlockChange, block }: AddLogicButtonProps) => (
  <CmdButton
    icon={<Route height={16} opacity="0.5" color="#000" style={{ marginRight: '4px' }} />}
    onClick={() => {
      onBlockChange({
        type: 'button',
        sort_key: block.sort_key,
        meta: {
          ...block.meta,
          conditional_actions: [
            {
              action: { type: 'link', value: '', operation: 'self' },
              operator: 'eq',
              operand: '',
            },
          ],
        },
      });
    }}
  >
    Add conditional logic
  </CmdButton>
);

const AddButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

interface AddButtonsProps {
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  block: INudgeStepContentButtonBlockType;
  hasSurveyBlock: boolean;
}

const AddButtons = ({ onBlockChange, block, hasSurveyBlock }: AddButtonsProps) => (
  <AddButtonsContainer>
    <AddActionButton onBlockChange={onBlockChange} block={block} />
    {hasSurveyBlock && <AddLogicButton onBlockChange={onBlockChange} block={block} />}
  </AddButtonsContainer>
);

interface TitleRowProps {
  buttonBlock: INudgeStepContentButtonBlockType;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

const TitleRow = ({ buttonBlock, onBlockDelete }: TitleRowProps) => (
  <S.TitleRow>
    <CmdLabel htmlFor="label">
      {buttonBlock?.meta?.button_type === 'secondary' ? 'Secondary button' : 'Primary button'}
    </CmdLabel>
    <S.MenuIconContainer
      onClick={() => {
        onBlockDelete(buttonBlock);
      }}
    >
      <Trash04 opacity={0.5} />
    </S.MenuIconContainer>
  </S.TitleRow>
);

const getSurveyListValues = (options: string[]) =>
  options.map((value) => ({
    label: value,
    value,
  }));

const getSurveyRatingValues = (meta: INudgeContentSurveyRatingBlockType['meta']) => {
  if (meta.type === 'emojis' && meta.emojis) {
    return meta.emojis.map((emoji, i) => ({
      label: emoji,
      value: i + 1,
    }));
  }

  if (meta.type === 'stars') {
    const starOptions = [
      { label: '⭐', value: 1 },
      { label: '⭐⭐', value: 2 },
      { label: '⭐⭐⭐', value: 3 },
    ];

    if (meta.options === 3) {
      return starOptions;
    }

    if (meta.options === 5) {
      return starOptions.concat([
        { label: '⭐⭐⭐⭐', value: 4 },
        { label: '⭐⭐⭐⭐⭐', value: 5 },
      ]);
    }
  }

  if (meta.type === 'nps') {
    return Array(meta.options + 1)
      .fill(0)
      .map((_, i) => ({
        label: i.toString(),
        value: i,
      }));
  }

  if (meta.type === 'numbers' && typeof meta.options === 'number') {
    return Array(meta.options + 1)
      .fill(0)
      .map((_, i) => ({
        label: i.toString(),
        value: i,
      }));
  }

  return [];
};

export const getSurveyValues = (logicInputBlock?: ReturnType<typeof findLogicInputBlocks>[number] | SurveyBlock) => {
  if (logicInputBlock?.type === 'survey_list') {
    return getSurveyListValues(logicInputBlock.meta.options);
  }

  if (logicInputBlock?.type === 'survey_rating') {
    return getSurveyRatingValues(logicInputBlock?.meta);
  }

  return [];
};

interface CTAFormProps {
  buttonBlock: INudgeStepContentButtonBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
  nudge: INudgeType;
  stepIndex: number;
}

export const CTAForm = ({ buttonBlock, onBlockChange, onBlockDelete, nudge, stepIndex }: CTAFormProps) => {
  const hasAction = buttonBlock.meta?.action?.type !== 'no_action';
  const hasLogic = buttonBlock.meta?.conditional_actions && buttonBlock.meta.conditional_actions.length > 0;
  const step = nudge.steps[stepIndex];
  const logicInputBlock = findLogicInputBlocks(step.content)?.[0];

  const surveyValues = getSurveyValues(logicInputBlock);

  const { hasRouter, context } = useWindowInfo();
  const contextKeys = Object.keys(context).map((s) => ({
    value: `metadata.${s}`,
    addOn: 'Metadata',
  }));

  const showRouterWarning =
    (buttonBlock?.meta?.action?.type === 'link' && buttonBlock.meta?.action.operation === 'router' && !hasRouter) ||
    (buttonBlock.meta?.conditional_actions?.some(
      (conditionalAction) =>
        conditionalAction.action.type === 'link' && conditionalAction.action.operation === 'router',
    ) &&
      !hasRouter);

  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.SectionContainer>
          <TitleRow onBlockDelete={onBlockDelete} buttonBlock={buttonBlock} />
          <span style={{ flexGrow: 1, width: '100%' }}>
            <AutoCompleteTextArea
              onChange={(e) => {
                onBlockChange({
                  type: 'button',
                  sort_key: buttonBlock.sort_key,
                  meta: {
                    ...buttonBlock.meta,
                    label: e,
                  },
                });
              }}
              value={buttonBlock.meta?.label}
              placeholder={'Continue'}
              options={[...contextKeys]}
            />
          </span>
        </S.SectionContainer>

        {hasAction && (
          <ActionEditor
            showLabel
            nudge={nudge}
            stepIndex={stepIndex}
            action={buttonBlock.meta?.action}
            onDelete={() => {
              onBlockChange({
                ...buttonBlock,
                meta: {
                  ...buttonBlock.meta,
                  action: { type: 'no_action' },
                },
              });
            }}
            onActionChange={(action) => {
              onBlockChange({
                type: 'button',
                sort_key: buttonBlock.sort_key,
                meta: {
                  ...buttonBlock.meta,
                  action: { ...action },
                },
              });
            }}
            possibleTypes={[
              'link',
              'page',
              'click',
              'clickBySelector',
              'clickByXpath',
              'execute_command',
              'help_doc',
              'video',
              'open_chat',
              'dismiss',
              'snooze',
              'nudge',
              'questlist',
              'open_bar',
              'open_helphub',
              'open_copilot',
              ...(step.form_factor.type === 'banner' ? [] : (['step_back', 'go_to_step'] as const)),
            ]}
          />
        )}

        {hasLogic && !!logicInputBlock && (
          <LogicEditor
            nudge={nudge}
            stepIndex={stepIndex}
            onBlockChange={onBlockChange}
            block={buttonBlock}
            surveyValues={surveyValues}
            surveyType={logicInputBlock?.type}
          />
        )}

        {!hasAction && !hasLogic && (
          <AddButtons onBlockChange={onBlockChange} block={buttonBlock} hasSurveyBlock={!!logicInputBlock} />
        )}

        {showRouterWarning && <RouterWarning />}
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
