import { Step, SelectStep } from './step-utils';
import type { Option } from '../options/option-utils';
import DateTime from '../DateTime/DateTime';
import { initSelectStep } from './step-utils/SelectStep';
import { getArgumentChoiceLabel, getContextSettings } from '../selectors';
import { CBStore } from 'shared/store/global-store';
import { isDateTimeOption, isParameterOption } from '../options/helpers';

export const getSelectStepBlock = (s: SelectStep, _: CBStore): string | undefined => {
  if (!s.completed || s.selected === null) {
    return s.argument.userDefinedName;
  }

  if (s.selected.type !== 'parameter') {
    return s.argument.userDefinedName;
  }

  // Note: This block simply tries to catch the different possibilities
  // Since these fields are defined by us,
  // we should try to agree to a standard data structure for these selected elements
  if (Array.isArray(s.selected.data)) {
    throw new Error('Selected must be an object');
  } else {
    const contextSettings = getContextSettings(_);
    return getArgumentChoiceLabel(s.argument, s.selected.data, contextSettings);
  }
};

// FIXME: Not the best name in the history of function names.
export const selectSelectStep = (s: SelectStep, o: Option): Step => {
  if (!isParameterOption(o)) {
    return s;
  }

  const newStep = initSelectStep(s.argument);

  let data = o.parameter;
  if (isDateTimeOption(o)) {
    data = DateTime.clientReturnValue(o.parameter.date);
  }

  newStep.selected = {
    type: 'parameter',
    category: null,
    data,
  };
  newStep.completed = s.completed;

  return newStep;
};
