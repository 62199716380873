import React from 'react';
import styled from '@emotion/styled';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0px;

  color: var(--content-primary);

  /* label/base/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);
`;

const StyledCopilotHeader = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
  return <Header>{children}</Header>;
};

export default StyledCopilotHeader;
