const frameName = !!process.env.REACT_APP_FRAME_NAME ? process.env.REACT_APP_FRAME_NAME.toLowerCase() : '__';
let PREFIX = `commandbar.${frameName}`;
if (['commandbar', 'proxy'].includes(frameName)) {
  //  backwards compatibility
  PREFIX = 'commandbar';
}

const set = (label: string, value: string | boolean | number) => {
  try {
    localStorage.setItem(`${PREFIX}.${label}`, value.toString());
    return value;
  } catch (err) {
    return '';
  }
};

const get = (label: string, defaultValue: string | boolean | number, prefixOverride?: string) => {
  let value;
  const prefix = prefixOverride ?? PREFIX;
  try {
    value = localStorage.getItem(`${prefix}.${label}`);
  } catch (err) {
    value = null;
  }

  if (value === null) {
    return defaultValue;
  } else {
    if (value === 'false') return false;
    if (value === 'true') return true;
    if (+value) return +value;
    return value;
  }
};

const remove = (label: string): void => {
  try {
    localStorage.removeItem(`${PREFIX}.${label}`);
    return;
  } catch (err) {
    return;
  }
};

const LocalStorage = {
  set,
  get,
  remove,
};

export default LocalStorage;
