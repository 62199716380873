import React from 'react';
import { CmdButton } from '../../button';
import { CmdTypography } from '../../typography';
import { RefreshCw01, EmptyGraphError } from '@commandbar/design-system/icons/react';

interface CmdTableErrorProps {
  onClick: () => void;
  ctaText?: string;
}

export const CmdTableError = ({ onClick, ctaText }: CmdTableErrorProps): React.ReactElement => {
  return (
    <>
      <EmptyGraphError height={43} width={59} />
      <span className="flex flex-col gap-sm">
        <CmdTypography.Body fontWeight="semi-bold">We couldn’t load your data</CmdTypography.Body>
        <span className="items-center">
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            Something went wrong while trying to load your data.
          </CmdTypography.Body>
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            If this issue persists, please{' '}
            <a className="text-blue800" href="mailto:support@commandbar.com">
              contact us.
            </a>
          </CmdTypography.Body>
        </span>
      </span>

      <CmdButton icon={<RefreshCw01 />} onClick={onClick}>
        {ctaText || 'Retry'}
      </CmdButton>
    </>
  );
};
