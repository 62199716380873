// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const GoogleBrand = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      d="M23.7797 9.81577H12.2442V14.4594H18.8341C18.55 15.9604 17.6879 17.23 16.3885 18.0805C15.2901 18.7997 13.8885 19.225 12.2411 19.225C9.05148 19.225 6.3536 17.1143 5.38938 14.278C5.14673 13.5588 5.00625 12.7896 5.00625 11.9984C5.00625 11.2073 5.14673 10.438 5.38938 9.71883C6.35679 6.88573 9.05468 4.77498 12.2442 4.77498C14.0418 4.77498 15.6541 5.38163 16.9248 6.5699L20.4369 3.12704C18.3137 1.18827 15.5456 0 12.2442 0C7.45829 0 3.31728 2.68925 1.30265 6.61055C0.472529 8.23036 0 10.0628 0 12.0016C0 13.9403 0.472529 15.7696 1.30265 17.3894C3.31728 21.3107 7.45829 24 12.2442 24C15.5519 24 18.3233 22.9243 20.3475 21.0919C22.6622 19.003 24 15.926 24 12.2705C24 11.4199 23.9234 10.6038 23.7797 9.81577Z"
      fill="currentColor"
    />
  </svg>
);
export default GoogleBrand;
