import React from 'react';
import { Clock, File06, FilePlus02, List, PlaySquare } from '@commandbar/design-system/icons/react';

export const ICONS: Record<string, { icon: JSX.Element; tooltipText: string }> = {
  time: {
    icon: <Clock />,
    tooltipText: 'Date time',
  },
  text: {
    icon: <List />,
    tooltipText: 'Text',
  },
  context: {
    icon: <FilePlus02 />,
    tooltipText: 'Context record',
  },
  set: {
    icon: <List />,
    tooltipText: 'Set',
  },
  function: {
    icon: <Clock />,
    tooltipText: 'Function generated',
  },
  dependent: {
    icon: <Clock />,
    tooltipText: 'Dependent',
  },
  video: {
    icon: <PlaySquare />,
    tooltipText: 'Video',
  },
  html: {
    icon: <File06 />,
    tooltipText: 'HTML',
  },
};

export const getIcon = (arg: any) => {
  if (arg.type === 'provided') {
    return ICONS[arg.value];
  }

  return ICONS[arg.type];
};
