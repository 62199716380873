import { toPx } from '@commandbar/internal/client/theme';
import { useEffect, useRef } from 'react';
import { useMobileExperience } from './useMobileExperience';
import type { Coordinates2D } from '@commandbar/commandbar/products/nudges/components/utils';

const useDraggable = (element: HTMLElement | null, allowHorizontal = true, allowVertical = true) => {
  const { isMobileDevice } = useMobileExperience();
  const isBeingDragged = useRef(false);
  const ignoreNextclick = useRef(false);
  const initialMousePosition = useRef<Coordinates2D>();
  const initialTranslation = useRef<Coordinates2D>();
  const initialElementPosition = useRef<DOMRect>();

  useEffect(() => {
    // Disable dragging on mobile
    if (isMobileDevice) return;

    const dragZoneElement = element?.querySelector('[data-draggable="drag-zone"]');

    if (element && dragZoneElement instanceof HTMLElement) {
      const handleDragStart = (mouseDown: MouseEvent) => {
        initialMousePosition.current = [mouseDown.pageX, mouseDown.pageY];
        initialElementPosition.current = element.getBoundingClientRect();

        /*
         * The translation matrix is a 6-element array of numbers that represent the
         * transformation matrix of the element. The fifth and sixth
         * elements are the horizontal and vertical translation factors.
         * https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
         *
         * If a translation matrix is not present, the element is not being
         * translated, so we can assume that the translation is 0.
         *
         * If a translation matrix is present, we can extract the factors from the
         * matrix and use them to calculate the initial translation. This is done
         * to ensure that the element is translated relative to its initial position.
         */
        const translationMatrix = window
          .getComputedStyle(element)
          .transform.match(/matrix.*\((.+)\)/)?.[1]
          .split(', ');

        initialTranslation.current = [Number(translationMatrix?.[4] ?? 0), Number(translationMatrix?.[5] ?? 0)];
        isBeingDragged.current = true;
      };

      const handleDrag = (mouseMove: MouseEvent) => {
        if (
          isBeingDragged.current &&
          initialMousePosition.current &&
          initialTranslation.current &&
          initialElementPosition.current
        ) {
          dragZoneElement.style.setProperty('cursor', 'grabbing');
          element.style.opacity = '0.9';

          const [initialMouseX, initialMouseY] = initialMousePosition.current;
          const [initialTranslationX, initialTranslationY] = initialTranslation.current;

          const distanceToTop = window.scrollY + initialElementPosition.current.top;
          const distanceToLeft = window.scrollX + initialElementPosition.current.left;
          const distanceToRight = document.documentElement.clientWidth - element.clientWidth - distanceToLeft;
          const distanceToBottom = document.documentElement.clientHeight - element.clientHeight - distanceToTop;

          const xDelta = !allowHorizontal
            ? 0
            : Math.min(
                Math.max(
                  window.scrollX + mouseMove.clientX - initialMouseX + initialTranslationX,
                  -distanceToLeft + initialTranslationX + window.scrollX,
                ),
                distanceToRight + initialTranslationX + window.scrollX,
              );
          const yDelta = !allowVertical
            ? 0
            : Math.min(
                Math.max(
                  window.scrollY + mouseMove.clientY - initialMouseY + initialTranslationY,
                  -distanceToTop + initialTranslationY + window.scrollY,
                ),
                distanceToBottom + initialTranslationY + window.scrollY,
              );

          if (Math.abs(xDelta) > 5 || Math.abs(yDelta) > 5) {
            ignoreNextclick.current = true;
          }

          element.style.setProperty('transform', `translate(${toPx(xDelta)}, ${toPx(yDelta)})`);
        }
      };

      const handleDragEnd = () => {
        if (isBeingDragged.current && initialMousePosition.current) {
          isBeingDragged.current = false;
          dragZoneElement.style.setProperty('cursor', 'move');
          element.style.opacity = '1';
        }
      };
      const handleClick = (e: MouseEvent) => {
        if (ignoreNextclick.current) {
          ignoreNextclick.current = false;

          e.stopPropagation();
          return false;
        }
      };

      dragZoneElement.addEventListener('mousedown', handleDragStart);
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);

      dragZoneElement.addEventListener('click', handleClick);

      return () => {
        dragZoneElement.removeEventListener('mousedown', handleDragStart);
        dragZoneElement.removeEventListener('click', handleClick);
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
      };
    }
  }, [element]);
};

export default useDraggable;
