import { DashboardStep, MultiSelectStep, Step, StepWithArgument } from './step-utils';
import { CBStore } from 'shared/store/global-store';
import { StepType } from './step-utils/Step';
import { selectStep } from './helpers';
import { convertParameterToOption } from '../selectors';
import { interpolate } from 'shared/util/Interpolate';
import { RecordOption } from '../options/option-utils';
import get from 'lodash/get';
import { initParameterOption } from '../options/option-utils/ParameterOption';
import { fulfillAndRebase, selectOption } from '../actions';
import { currentStepAndIndex, getArgumentSelections } from './selectors';
import * as Command from '@commandbar/internal/middleware/command';
import * as Reporting from 'shared/services/analytics/Reporting';
import { releaseConfetti } from 'shared/components/Confetti';
import { ExecuteStep } from './step-utils';
import { CommandOption } from '../options/option-utils';
import featureFlag from 'shared/util/featureFlags';
import * as EUSActions from 'shared/store/end-user/actions';
import * as GlobalActions from 'shared/store/global-actions';
import * as NudgeServiceActions from 'products/nudges/service-actions';
import * as ChecklistServiceActions from 'products/checklists/service-actions';

export const submitLongTextInput = (_: CBStore, text: string) => {
  const { currentStep } = currentStepAndIndex(_);
  if (currentStep?.type === StepType.LongTextInput) {
    const option = initParameterOption(_, currentStep.argument.userDefinedName, text);
    selectOption(_, option);
  }
};

export const submitMultiSelect = (_: CBStore) => {
  const { currentStep } = currentStepAndIndex(_);

  if (currentStep?.type === StepType.MultiSelect) {
    fulfillAndRebase(_);
    // React-select auto updates inputText on select, but here we need to manually trigger it
    //   because we're manually triggering an execution
    // If we don't do this, we'll have a bug where the inputText doesn't reset across steps,
    //   and the options won't be refreshed because we only refresh options if the input is empty.
    if (_.spotlight.inputText) {
      _.spotlight.previousInputText = _.spotlight.inputText;
    }
    _.spotlight.inputText = '';
    _.spotlight.rawInput = '';
  }
};

export const submitDashboardStep = (_: CBStore) => {
  const { currentStep } = currentStepAndIndex(_);

  if (currentStep?.type === StepType.Dashboard) {
    const option = initParameterOption(
      _,
      (currentStep as DashboardStep).argument.userDefinedName,
      _.spotlight.inputText,
    );
    selectOption(_, option);
  }
};

export const preSelectIfApplicable = (_: CBStore, s: StepWithArgument, activeRecord?: RecordOption): Step => {
  const arg = s.argument;
  if (arg.preselected_key === undefined) return s;
  if (typeof arg.preselected_key !== 'string') return s;

  // "interpolate handlebars" here if preselected_key starts with "{{"
  // see Interpolate.tsx

  let preSelected;
  const preselected_key = arg.preselected_key;
  if (!preselected_key.startsWith('{{')) {
    preSelected = get(_.context, arg.preselected_key);
  } else {
    preSelected = interpolate(preselected_key, _, true, true, undefined, activeRecord);
  }

  if ((preSelected === undefined || preSelected === null) && !!preselected_key) {
    preSelected = preselected_key;
  }

  if (!preSelected) {
    return s;
  }

  if (s.type === StepType.MultiSelect) {
    const _preSelected = Array.isArray(preSelected) ? preSelected : [preSelected];
    let newStep = s;
    _preSelected.forEach((value) => {
      const _parameterOption = convertParameterToOption(_, value, arg);
      newStep = selectStep(newStep, _parameterOption) as MultiSelectStep;
    });
    return newStep;
  } else {
    // Arrays are not valid as preselects for SelectSteps
    // FIXME: How can we report this better to the user?
    if (Array.isArray(preSelected)) return s;

    const _parameterOption = convertParameterToOption(_, preSelected, arg);
    return selectStep(s, _parameterOption);
  }
};

export const runExecuteStepActionWithSideEffects = (_: CBStore, s: ExecuteStep) => {
  /**
   * Execute behavior is called after a timeout, but engine state is reset the moment the step is completed
   * Selections will be stale if we select them at the point of execution, instead of before execution
   */
  const argSelections = getArgumentSelections(_);
  const executable = GlobalActions.commandExecutableWithFullArgumentSelections(
    _,
    s.command,
    argSelections,
    s?.alternateBehavior,
  );

  // FIXME
  // Executing a command may have side-effects on the underlying app
  // The most relevant side-effect is the underlying app calling other
  // Command Bar APIs (like addContext)
  //
  // This timeout allows the ExecutionPathReducer to complete it's current
  // step before updating anything else.
  //
  // A more robust solution here would be to set a flag to wait for all Executes to
  // finish before allowing any API calls (add them to a pending queue while the flag is on)
  // That flag would probably live on window (we can't set it in the Reducer)
  //
  // This will probably also break if we are chaining multiple Execute steps together

  const inputToLog = s.triggeredByShortcut
    ? _.spotlight.inputText
    : _.spotlight.inputText || _.spotlight.previousInputText;
  const meta = {
    ...(s.triggeredByShortcut && { shortcut: true }),
    customShortcut: _.endUserStore.data.hotkeys[Command.commandUID(s.command)],
    ranking: _.spotlight.sortedOptions.findIndex((o) => {
      const c = o as CommandOption;
      return c?.command ? Command.commandUID(c.command) === Command.commandUID(s.command) : false;
    }),
    previousCommands: _.spotlight.executionHistory,
  };

  EUSActions.storeRecentsOnExecute(_, s);
  const metaWithSelections = { ...meta, selections: argSelections };

  const metaToReport = featureFlag({
    organization: _.organization,
    orgIds: ['5725a2a3'],
    activeState: metaWithSelections,
    defaultState: meta,
  });
  Reporting.execution(
    s.command,
    inputToLog,
    {
      ...metaToReport,
      categoryText: _.spotlight.categories.find((c) => c.id === s.command.category)?.name,
    },
    s.eventSource,
  );
  _.spotlight.executionHistory.unshift(Command.commandUID(s.command));

  NudgeServiceActions.sendIndirectTrigger(
    _,
    {
      type: 'on_command_execution',
      meta: { command: Command.commandUID(s.command) },
    },
    {
      // Trigger commands directly trigger nudges and should work when the admin is logged in
      admin: s.command.template.type === 'trigger',
    },
  );

  ChecklistServiceActions.triggerChecklists(_, {
    type: 'on_command_execution',
    meta: { command: Command.commandUID(s.command) },
  });

  if (!!s.command.celebrate) {
    releaseConfetti(s.command.celebrate);
  }

  setTimeout(() => {
    executable();
  }, 5);
};
