import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { DeviceTypeCondition as DeviceTypeConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';
import { ConditionProps } from '../../types';

const options: { label: string; value: DeviceType }[] = [
  { label: 'Desktop', value: 'desktop' },
  { label: 'Mobile', value: 'mobile' },
];

export const DeviceTypeCondition: React.FC<ConditionProps<DeviceTypeConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.value}
          options={options}
          placeholder="value"
          style={{ width: '100%', height: '24px' }}
          onBlur={() => setHasBlurred(true)}
          onChange={(value) => {
            onConditionChange({
              ...condition,
              value,
            });
          }}
          filterOption={(input, option) =>
            !!option?.value?.toLowerCase().includes(input.toLowerCase()) ||
            !!option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          size="small"
        />
      </Col>
    </Row>
  );
};
