/** @jsx jsx */
import { jsx } from '@emotion/core';
import getPlayer from './players';
import { URLVideo, EmbeddedVideo } from './Previews';
import { isValidURL } from '@commandbar/internal/util/location';

interface VideoPreviewProps {
  source: string;
  containerStyles?: React.CSSProperties;
  autoPlay?: boolean;
}

const VideoContainer = ({ source, containerStyles, autoPlay }: VideoPreviewProps) => {
  const isEmbeddablePreview = !isValidURL(source) || getPlayer(source);

  return (
    <div
      data-testid="commandbar-video"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        ...containerStyles,
      }}
    >
      {!isEmbeddablePreview ? (
        <URLVideo source={source} autoPlay={autoPlay} />
      ) : (
        <EmbeddedVideo source={getPlayer(source, autoPlay) || source} />
      )}
    </div>
  );
};

export default VideoContainer;
