import { CmdCard, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import { timeAgoFormat } from '../../utils';
import { typeIcons } from './Dashboard';

import './heatmap.css';

type InteractionTypeLegendProps = {
  name: string;
  color: string;
  tooltip: string;
};

const InteractionTypeLegend: React.FC<InteractionTypeLegendProps> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '16px', height: '16px', backgroundColor: props.color, borderRadius: '4px' }} />
      <div style={{ marginLeft: '8px' }}>
        <CmdTypography.HelpText variant="secondary">{props.name}</CmdTypography.HelpText>
      </div>
      <CmdTooltip message={props.tooltip}>
        <div style={{ marginLeft: '4px', width: 16, height: 16 }}>
          <InfoCircle color="#797C85" />
        </div>
      </CmdTooltip>
    </div>
  );
};

export type Interaction = {
  end_user: string;
  nudge_slug: string;
  nudge_type: string;
  nudge_id: number;
  num_strikes: number;
  num_dismissed: number;
  num_completed: number;
  first_seen: string;
};

export type HeatmapProps = {
  data: Interaction[];
} & Parameters<typeof CmdCard>[0];

export const Heatmap: React.FC<HeatmapProps> = (props) => {
  const { data, style, ...rest } = props;

  return (
    <CmdCard
      style={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        ...style,
      }}
      {...rest}
    >
      <CmdCard.Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <CmdTypography.Body fontWeight="semi-bold">Nudge Interactions</CmdTypography.Body>
            <CmdTypography.HelpText variant="secondary">
              How your users interacted with recent nudges
            </CmdTypography.HelpText>
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <InteractionTypeLegend name="Dismissed" color="#E7E8EA" tooltip="Nudges that were dismissed" />
          </div>

          <div style={{ marginLeft: '16px' }}>
            <InteractionTypeLegend name="Striked" color="#F87171" tooltip="User closed nudge within 1s" />
          </div>

          <div style={{ marginLeft: '16px' }}>
            <InteractionTypeLegend name="Completed" color="#95D952" tooltip="Nudges that were completed" />
          </div>
        </div>
      </CmdCard.Header>

      <CmdCard.Content
        className="strikes-heatmap"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '8px 0',
          margin: '0',
          overflowX: 'auto',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(28, 1fr)',
            gridTemplateRows: 'repeat(6, 22px)',
            gap: '6px',
            width: '100%',
          }}
        >
          {data.map((interaction) => {
            return (
              <CmdTooltip
                key={`${interaction.end_user}-${interaction.nudge_id}`}
                delayDuration={250}
                message={
                  <>
                    {typeIcons[interaction.nudge_type as keyof typeof typeIcons]}
                    <CmdTypography.Body style={{ color: '#fff' }}>{interaction.nudge_slug}</CmdTypography.Body>
                    <CmdTypography.HelpText variant="secondary">{interaction.end_user}</CmdTypography.HelpText>
                    <CmdTypography.HelpText variant="secondary">
                      {timeAgoFormat(interaction.first_seen)}
                    </CmdTypography.HelpText>
                  </>
                }
              >
                <div
                  className={
                    interaction.num_strikes > 0 ? 'strike' : interaction.num_completed > 0 ? 'completed' : 'dismissed'
                  }
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor:
                      interaction.num_strikes > 0 ? '#F87171' : interaction.num_completed > 0 ? '#95D952' : '#E7E8EA',
                    borderRadius: '4px',
                  }}
                />
              </CmdTooltip>
            );
          })}
        </div>
      </CmdCard.Content>
    </CmdCard>
  );
};

export default Heatmap;
