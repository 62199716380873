import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { capitalize } from 'lodash';
import { NotificationBox, ArrowUpRight, CheckSquareBroken } from '@commandbar/design-system/icons/react';
import React from 'react';
import { useUsage } from '../../hooks/useUsage';
import { CmdButton } from '@commandbar/design-system/cmd';

type UpgradeCTAProps = {
  product: 'nudges' | 'checklists';
  padding?: string;
};

export const UpgradeCTA: React.FC<UpgradeCTAProps> = ({ product, padding = '0' }) => {
  const { usage, exceeding, isLoading } = useUsage();

  const isOverLimit = React.useMemo(() => {
    if (isLoading || usage === null) return false;

    if (product === 'nudges') {
      return exceeding.isAtOrOverLiveNudges;
    } else if (product === 'checklists') {
      return exceeding.isAtOrOverLiveQuestlists;
    } else {
      return false;
    }
  }, [exceeding, isLoading]);

  return isOverLimit ? (
    <div style={{ padding: padding }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: CB_COLORS.blue100,
          color: CB_COLORS.blue900,
          padding: '8px 8px 8px 12px',
          borderRadius: 4,
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          {product === 'nudges' && <NotificationBox />}
          {product === 'checklists' && <CheckSquareBroken />}
          <span style={{ marginLeft: '8px', fontSize: '14px' }}>Upgrade to create unlimited {capitalize(product)}</span>
        </div>

        <a href={`${process.env.REACT_APP_DASHBOARD_URL}/billing`} target="_blank" rel="noreferrer">
          <CmdButton>
            See Plans <ArrowUpRight />
          </CmdButton>
        </a>
      </div>
    </div>
  ) : null;
};
