import styled from '@emotion/styled';

import { Menu, Dropdown, Modal } from '@commandbar/design-system/components/antd';
import { Copy07, DotsVertical, PencilLine, Play, Trash04 } from '@commandbar/design-system/icons/react';
import { useReportEvent } from '../../../hooks/useEventReporting';

import { CmdDivider, cmdToast } from '@commandbar/design-system/cmd';
import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import { HELPHUB_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { Link } from 'react-router-dom';
import { useAppContext } from 'editor/src/AppStateContext';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

export const DropdownMenu = styled(Menu)`
  display: flex;
  width: 207px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
`;

export const DropdownMenuItem = styled(Menu.Item)`
  width: 100%;
  padding: 8px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;

  & .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }
`;

interface ContentSettingsDropdownProps {
  command: IEditorCommandTypeLite;
  openEditDrawer: (id: number) => void;
}

const ContentSettingsDropdown = ({ command, openEditDrawer }: ContentSettingsDropdownProps) => {
  const { reportEvent } = useReportEvent();

  const {
    dispatch: { commands },
  } = useAppContext();

  const { user } = useAuth();

  if (!hasRequiredRole(user, 'contributor')) {
    return null;
  }

  const canDelete = hasRequiredRole(user, command.is_live ? 'editor' : 'contributor');

  return (
    <Dropdown
      overlay={
        <DropdownMenu>
          <DropdownMenuItem
            key="edit"
            onClick={() => {
              openEditDrawer(command.id);
            }}
          >
            <PencilLine />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={command.training_only}
            key="preview"
            title={command.training_only ? 'Cannot view documents that are for training only in HelpHub' : ''}
          >
            <Link
              to={`/editor${HELPHUB_ROUTE}?commandId=${command.id}`}
              style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <Play /> Preview in HelpHub
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            key="copy"
            onClick={() => {
              const element = document.createElement('textarea');
              element.value = command.id.toString();
              document.body.appendChild(element);
              element.select();
              document.execCommand('copy');
              document.body.removeChild(element);
              cmdToast.info('Id copied to clipboard.');
            }}
          >
            <Copy07 />
            Copy id
          </DropdownMenuItem>

          {canDelete && (
            <>
              <CmdDivider spacing="sm" />
              <DropdownMenuItem
                disabled={!canDelete}
                key="delete"
                onClick={() => {
                  Modal.confirm({
                    title: 'This will permanently delete this document. Are you sure you want to proceed?',
                    onOk: async () => {
                      await commands.delete(command);

                      reportEvent('content deleted', {
                        segment: true,
                        highlight: true,
                        slack: true,
                        payloadMessage: `${command.id}`,
                        eventProps: {
                          id: command.id,
                        },
                      });
                    },
                    cancelText: 'Cancel',
                  });
                }}
              >
                <Trash04 />
                Delete
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenu>
      }
      trigger={['click']}
      placement="bottomLeft"
    >
      <DotsVertical onClick={(e) => e.stopPropagation()} />
    </Dropdown>
  );
};

export default ContentSettingsDropdown;
