import styled from '@emotion/styled';
import { CB_COLORS, Typography } from '@commandbar/design-system/components/antd';

export const Container = styled.div<{ hover?: boolean; selected?: boolean; noBorder?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 52px;
  padding: 0 12px;
  align-items: center;
  transition: background 0.3s ease-in-out;

  border-color: ${({ disabled }) => (disabled ? '#fff' : '#f0f0f0')};
  border-style: solid;
  border-width: 0;
  border-bottom-width: ${({ noBorder }) => (noBorder ? '0' : '1px')};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ disabled, selected }) => (!disabled && selected ? 'rgba(39, 85, 240, 0.03)' : '#fff')};

  &:first-of-type {
    border-top-width: 1px;
  }

  &:hover {
    background: ${({ disabled }) => !disabled && 'rgba(39, 85, 240, 0.05)'};
  }
`;

export const IconContainer = styled.span<{ color?: string }>`
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Typography)<{ selected?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  && {
    color: ${({ selected }) => (selected ? CB_COLORS.primary : '#141414')};
  }
`;

export const Description = styled(Typography)`
  font-size: 11px;
  line-height: 15px;

  && {
    color: #706e78;
  }
`;
