import React from 'react';
import { Alert, Input, Form } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

const WixAnswers: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.WIX_ANSWERS,
  name: 'WixAnswers',
  slug: 'wixanswers',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/wix-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/wixanswers-icon.ico',
  isDisabled: false,
  useDryRun: true,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Wix Answers help center docs, you'll have to first find your Wix Answers API Key."}
    />
  ),
  route: '/wixanswers',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + '-domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          required={true}
          label={'Domain'}
          name={'domain'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.wixanswers.com$/gi,
              message: `Please enter a valid URL (e.g., yourdomain.wixanswers.com). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} placeholder="yourdomain.wixanswers.com" />
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      domain: data['domain'],
    };
  },
  requiredFields: ['domain'],
  fetchedFields: [],
};

export default WixAnswers;
