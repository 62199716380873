import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PlusCircle } from '@commandbar/design-system/icons/react';

import { IntegrationActionButtons, Integrations, Section, SectionTitle } from '../../styles';
import SuggestionIntegration from './SuggestionIntegration';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import SourceEditDrawer from '../../content/sources/edit-drawer/SourceEditDrawer';
import IntegrationCard from './IntegrationCard';

import type { IUserType } from '@commandbar/internal/middleware/types';
import type { HelpCenterIntegrationSpec, IntegrationSpec } from '../shared/types';
import { CmdButton } from '@commandbar/design-system/cmd';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const useIntegrations = () => {
  const { helpDocIntegrations, refreshHelpDocIntegrations } = useIntegrationsContext();
  const [activeIntegration, setActiveIntegration] = useState<IntegrationSpec>();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);

  useEffect(() => {
    refreshHelpDocIntegrations();
  }, [refreshHelpDocIntegrations]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const initIntegration = helpDocIntegrations.find((integration) => integration.slug === query.get('integration'));
    if (initIntegration) {
      setActiveIntegration(initIntegration);
      setOpenEditDrawer(true);
    }
  }, [helpDocIntegrations]);

  return {
    helpDocIntegrations,
    refreshHelpDocIntegrations,
    activeIntegration,
    setActiveIntegration,
    openEditDrawer,
    setOpenEditDrawer,
  };
};

interface IntegrationButtonsProps {
  integration: HelpCenterIntegrationSpec;
  isActive: boolean;
  onAddClick: (integration: HelpCenterIntegrationSpec) => void;
}

const IntegrationButtons = ({ integration, isActive, onAddClick }: IntegrationButtonsProps) => (
  <IntegrationActionButtons>
    {isActive ? (
      <>
        <CmdButton to="/content" fullWidth size="lg" data-testid={`view-${integration.name}`}>
          View Sources
        </CmdButton>

        <CmdButton size="lg" onClick={onAddClick.bind(this, integration)}>
          <PlusCircle />
        </CmdButton>
      </>
    ) : (
      <CmdButton
        key={integration.slug}
        fullWidth
        size="lg"
        onClick={onAddClick.bind(this, integration)}
        data-testid={`connect-${integration.slug}`}
      >
        <PlusCircle />
        Add
      </CmdButton>
    )}
  </IntegrationActionButtons>
);

interface IntegrationProps {
  integration: HelpCenterIntegrationSpec;
  setActiveIntegration: (integration: IntegrationSpec) => void;
  setOpenEditDrawer: (open: boolean) => void;
  user?: IUserType;
}

const Integration = ({ integration, setActiveIntegration, setOpenEditDrawer, user }: IntegrationProps) => {
  const isCommandBarUser = !!user && user?.email.includes('@commandbar.com');
  const isActive = !!integration.sources?.length;

  if (!(isCommandBarUser || !integration.internalOnly)) return null;

  const onOpenIntegration = (integration: HelpCenterIntegrationSpec) => {
    setActiveIntegration(integration);
    setOpenEditDrawer(true);
  };

  return (
    <div key={integration?.slug}>
      <IntegrationCard integration={integration} isActive={isActive}>
        <IntegrationButtons integration={integration} isActive={isActive} onAddClick={onOpenIntegration} />
      </IntegrationCard>
    </div>
  );
};

interface HelpCenterIntegrationSectionProps {
  searchTerm: string;
  noSearchResult: boolean;
}

const HelpCenterIntegrationSection = ({ searchTerm, noSearchResult }: HelpCenterIntegrationSectionProps) => {
  const {
    helpDocIntegrations,
    refreshHelpDocIntegrations,
    activeIntegration,
    setActiveIntegration,
    openEditDrawer,
    setOpenEditDrawer,
  } = useIntegrations();
  const { user } = useAuth();

  const history = useHistory();

  const filteredIntegrations = helpDocIntegrations.filter(
    (integration) =>
      integration.slug !== 'web_v2' &&
      integration.slug !== 'web' &&
      integration.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (!filteredIntegrations.length && !noSearchResult) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>Help Centers</SectionTitle>
      {filteredIntegrations.length ? (
        <>
          <SourceEditDrawer
            onCancel={() => setOpenEditDrawer(false)}
            onComplete={async () => {
              await refreshHelpDocIntegrations();
              history.push(`/content/sources`);
            }}
            integration={activeIntegration}
            setIntegration={setActiveIntegration}
            open={openEditDrawer}
          />
          <Integrations>
            {filteredIntegrations.map((integration) => (
              <Integration
                key={integration.slug}
                integration={integration as HelpCenterIntegrationSpec}
                user={user ? user : undefined}
                setActiveIntegration={setActiveIntegration}
                setOpenEditDrawer={setOpenEditDrawer}
              />
            ))}
            {!searchTerm && <SuggestionIntegration />}
          </Integrations>
        </>
      ) : (
        <SuggestionIntegration />
      )}
    </Section>
  );
};

export default HelpCenterIntegrationSection;
