import { CmdModal, CmdButton, CmdTypography, CmdCard } from '@commandbar/design-system/cmd';
import { ArrowUpRight } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import { ExperienceRow, SelectedRule } from './AudiencePage';

const DeleteModal = ({
  selectedRule,
  onOpenChange,
}: {
  selectedRule: SelectedRule;
  onOpenChange: (rule: SelectedRule | undefined) => void;
}) => {
  return (
    <CmdModal defaultOpen={!!selectedRule} onOpenChange={(open) => !open && onOpenChange(undefined)}>
      <CmdModal.Content style={{ width: '400px' }}>
        <CmdModal.Title>Can't delete audience</CmdModal.Title>
        <CmdModal.Description>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <CmdTypography.Body variant="secondary">
                <span style={{ fontWeight: 600 }}>{selectedRule?.name} </span>
                <span>audience is currently in use by </span>
                <span style={{ fontWeight: 600 }}>{selectedRule?.numEntitiesUsingThisRule} experiences </span>
                <span>and cannot be deleted. Remove it from the following experiences, to delete it.</span>
              </CmdTypography.Body>
            </div>

            {selectedRule && <RowsInUse entitiesUsingThisRule={selectedRule?.entitiesUsingThisRule} />}
          </div>
        </CmdModal.Description>
        <CmdModal.Footer>
          <CmdModal.Cancel />
          <CmdModal.Action buttonText="Got it" variant="primary" />
        </CmdModal.Footer>
      </CmdModal.Content>
    </CmdModal>
  );
};

const ScrollContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  padding: 4px;

  &:hover .hover-button {
    visibility: visible;
  }
`;

const HoverButton = styled.div`
  position: absolute;
  right: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
`;

const RowsInUse = ({ entitiesUsingThisRule }: { entitiesUsingThisRule: ExperienceRow[] }) => {
  return (
    <CmdCard style={{ padding: '4px', background: '#F9F9F9', marginTop: '12px' }}>
      <CmdCard.Content>
        <ScrollContainer>
          {entitiesUsingThisRule?.map((row: ExperienceRow) => (
            <a href={row.link} key={row.title} target="_blank" rel="noopener noreferrer">
              <RowContainer>
                <row.icon color="#797C85" />
                <CmdTypography.Body>{row.title}</CmdTypography.Body>
                <HoverButton className="hover-button">
                  <CmdButton to={row.link} variant="link" icon={<ArrowUpRight color="#797C85" />} />
                </HoverButton>
              </RowContainer>
            </a>
          ))}
        </ScrollContainer>
      </CmdCard.Content>
    </CmdCard>
  );
};

export default DeleteModal;
