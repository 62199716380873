import { type ComponentProps, Fragment } from 'react';

import { CmdButton } from '@commandbar/design-system/cmd';
import { Modal } from '@commandbar/design-system/components/antd';
import { PlusCircle, Settings02, Trash04 } from '@commandbar/design-system/icons/react';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { IntegrationActionButtons, Integrations } from '../../styles';
import { Section, SectionTitle } from '../../styles';
import type { IntegrationSpec } from '../shared/types';
import IntegrationCard from './IntegrationCard';
import { THIN_ANALYTICS_INTEGRATIONS, ThinAnalyticsIntegrationSection } from './ThinAnalyticsSection';

const NonHelpCenterIntegrationSection = ({
  sectionTitle,
  integrations,
  searchTerm,
  activeIntegrations,
}: {
  sectionTitle: string;
  integrations: Array<
    Pick<IntegrationSpec, 'name' | 'slug' | 'imgURL' | 'description' | 'internalOnly' | 'route' | 'sources'> & {
      handleDelete?: (integration: Pick<IntegrationSpec, 'slug' | 'name'>) => void;
      onConnect?: () => void;
      configurable?: boolean;
    }
  >;
  searchTerm: string;
  activeIntegrations: Record<string, any> | undefined;
}) => {
  const { user } = useAuth();

  const renderPrimaryButton = (
    integration: ComponentProps<typeof NonHelpCenterIntegrationSection>['integrations'][number],
    isActive: boolean,
  ) => {
    if (integration.onConnect) {
      return (
        !isActive && (
          <CmdButton
            onClick={integration.onConnect}
            size="lg"
            style={{ width: '100%', height: '40px' }}
            data-testid={`connect-${integration.slug}`}
            fullWidth
          >
            <PlusCircle />
            Connect
          </CmdButton>
        )
      );
    }

    return (
      <CmdButton
        key={integration.slug}
        to={`/integrations${integration.route}`}
        size="lg"
        fullWidth
        data-testid={`connect-${integration.slug}`}
      >
        {isActive ? <Settings02 /> : <PlusCircle />}
        {isActive ? 'Configure' : 'Connect'}
      </CmdButton>
    );
  };

  return (
    <Section>
      {!!(sectionTitle === 'Analytics' ? [...integrations, ...THIN_ANALYTICS_INTEGRATIONS] : integrations).filter(
        (integration) => integration.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ).length && <SectionTitle>{sectionTitle}</SectionTitle>}
      <div>
        <Integrations>
          {integrations
            .filter((integration) => integration.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((integration) => {
              const isCommandBarUser = user?.email.includes('@commandbar.com');
              const isActive = !!(activeIntegrations && integration.slug in activeIntegrations);
              return (
                <Fragment key={`${integration?.slug}-${!!integration.sources}`}>
                  {(isCommandBarUser || !integration.internalOnly) && (
                    <IntegrationCard integration={integration} isActive={isActive}>
                      <IntegrationActionButtons>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                          {renderPrimaryButton(integration, isActive)}
                          {isActive && (
                            <CmdButton
                              size="lg"
                              fullWidth={!integration.configurable}
                              onClick={(_) => {
                                Modal.confirm({
                                  title: integration.handleDelete ? (
                                    <div>
                                      <p>{`Are you sure you want to disconnect ${integration.name}?`}</p>
                                      <p>This will remove all {integration.name} data from CommandBar.</p>
                                    </div>
                                  ) : (
                                    <span>Please contact support to uninstall this integration.</span>
                                  ),
                                  onOk: () => {
                                    integration.handleDelete?.(integration);
                                  },
                                  cancelText: 'Cancel',
                                  okCancel: !!integration.handleDelete,
                                });
                              }}
                              data-testid={`remove-${integration.slug}`}
                            >
                              <Trash04 />
                              {!integration.configurable && ' Delete'}
                            </CmdButton>
                          )}
                        </div>
                      </IntegrationActionButtons>
                    </IntegrationCard>
                  )}
                </Fragment>
              );
            })}

          {sectionTitle === 'Analytics' && <ThinAnalyticsIntegrationSection searchTerm={searchTerm} />}
        </Integrations>
      </div>
    </Section>
  );
};

export default NonHelpCenterIntegrationSection;
