/*****************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useState } from 'react';

import { IEditorCommandType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import { Col, Row, Table, Empty, StatusBadge, Icon, commandDefault } from '@commandbar/design-system/components/antd';
import { FaceIdSquare } from '@commandbar/design-system/icons/react';
import NewCommandModal from './NewCommandModal';
import { useRouter } from '../../../hooks';
import { useAppContext } from 'editor/src/AppStateContext';
import { freshCommand } from '../../useEditor';
import { renderHotkeysColumn, renderOptionsColumn } from '../CommandTableColumns';
import { ACTIONS_ROUTE, PAGES_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useHistory, useParams } from 'react-router';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';
import FilterSelect, { StatusType } from '../../components/FilterSelect';
import { getCommandRoute } from '../../PagesOrActions';
import { CmdTag, CmdTooltip } from '@commandbar/design-system/cmd';
import collectEditorTags from '../../utils/collectEditorTags';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { CmdSearchInput } from '@commandbar/design-system/cmd';

/*******************************************************************************/
/* Props
/*******************************************************************************/

interface IProps {
  commands: IEditorCommandTypeLite[];
  type: 'page' | 'action';
  setActiveCommand: (command: IEditorCommandType) => void;
}

/*******************************************************************************/
/* Render
/*******************************************************************************/

const CommandTable = ({ commands, type, setActiveCommand }: IProps) => {
  const router = useRouter();
  const history = useHistory();
  const appState = useAppContext();
  const { hasRouter } = useWindowInfo();
  const params = useParams<{ commandId?: string }>();
  const { organization, categories } = appState;
  const { user } = useAuth();

  const storedScrollPosition = parseFloat(window.sessionStorage.getItem('commandListScrollPosition') || '0');
  const [scrollPosition] = useState(storedScrollPosition);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');
  const [selectedTag, setSelectedTag] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    scrollToCommand();
    window.sessionStorage.removeItem('commandListScrollPosition');
  }, []);

  React.useEffect(() => {
    if (params.commandId) return;
    const searchParams = new URLSearchParams(history.location.search);
    const page = searchParams.get('page');
    page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);
  }, [history.location.search]);

  const tags = collectEditorTags({ commands }).map((tag) => ({ label: tag, value: tag }));

  const columns = [
    {
      title: 'Icon',
      width: 46,
      key: 'icon',
      dataIndex: 'icon',
      render: (_: unknown, command: IEditorCommandTypeLite) => {
        const category = categories.find((obj) => obj.id === command.category);
        const icon = category?.icon ?? command.icon ?? commandDefault(command);
        const icon_color = category?.icon_color ?? command.icon_color;

        return (
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Icon icon={icon} useDefaultSVGColor size={'14px'} style={{ ...(icon_color && { color: icon_color }) }} />
          </span>
        );
      },
    },
    {
      title: type === 'page' ? 'Page' : 'Action',
      width: '61%',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
    },
    {
      title: 'Shortcut',
      width: 80,
      key: 'shortcut',
      dataIndex: 'shortcut',
      render: (_: any, command: IEditorCommandTypeLite) => renderHotkeysColumn(command),
    },
    {
      title: 'Tags',
      key: 'tags',
      width: 90,
      hidden: commands.every((command) => command.editor_tags?.length === 0),
      render: (_: any, command: IEditorCommandTypeLite) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', overflow: 'hidden' }}>
            {command.editor_tags.map((tag, index) => (
              <CmdTag key={index}>{tag}</CmdTag>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Status',
      width: 84,
      key: 'status',
      dataIndex: 'status',
      render: (_: any, command: IEditorCommandTypeLite) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {command.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
          ) : (
            <StatusBadge color="blue" text="Draft" />
          )}
          {command.copilot_suggest && (
            <CmdTooltip message="Suggested in Copilot">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaceIdSquare style={{ flexShrink: 0, marginLeft: '8px' }} />
              </div>
            </CmdTooltip>
          )}
        </div>
      ),
    },
    {
      title: '',
      width: 30,
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, command: IEditorCommandTypeLite) =>
        // TODO: can this render as a normal React component and therefore
        //  use the useAppContext hook? if so, no need to pass appState here
        //  and then this would be e.g. <OptionsColumn isMaxLiveCommandsExceeded=... />
        renderOptionsColumn({
          command,
          appState,
          history,
          user,
        }),
    },
  ];

  const scrollToCommand = () => {
    const categoryGroupWrapper = document.getElementById('category-group_wrapper');
    categoryGroupWrapper?.scrollTo({
      top: scrollPosition,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0 }}>
      <Row style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}>
        <Col style={{ flex: 1 }}>
          <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        </Col>
        {tags.length ? (
          <Col>
            <FilterSelect
              selectLabel="Tag"
              selectedOption={selectedTag}
              setSelectedOption={setSelectedTag}
              options={[{ label: 'All', value: 'all' }, ...tags]}
            />
          </Col>
        ) : null}
        <Col>
          <FilterSelect
            selectedOption={selectedStatus}
            setSelectedOption={setSelectedStatus}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Live', value: 'published' },
              { label: 'Draft', value: 'unpublished' },
            ]}
          />
        </Col>
        {hasRequiredRole(user, 'contributor') && (
          <Col>
            <NewCommandModal
              type={type}
              onCreate={(categoryID: number) => {
                const category = categories.find((obj) => obj.id === categoryID);
                const newCommand = freshCommand(
                  organization,
                  categoryID,
                  hasRouter,
                  category?.icon,
                  category?.image,
                  type === 'page' ? 'link' : 'callback',
                );
                setActiveCommand(newCommand);
                router.push(`${type === 'page' ? PAGES_ROUTE : ACTIONS_ROUTE}/${newCommand.id}`);
              }}
            />
          </Col>
        )}
      </Row>
      <Table
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          current: currentPage,
          onChange: (page) => {
            history.replace(`${history.location.pathname}?page=${page}`);
            setCurrentPage(page);
          },
        }}
        rowClassName="editable-row"
        columns={columns.filter((c) => !c.hidden)}
        dataSource={commands
          .filter((c) => c.text.toLowerCase().includes(searchText.toLowerCase()))
          .filter((c) => {
            const commandStatus = c.is_live ? 'published' : 'unpublished';
            return selectedStatus === 'all' || selectedStatus === commandStatus;
          })
          .filter((n) => {
            return selectedTag === 'all' || n.editor_tags.includes(selectedTag);
          })
          .map((c) => ({ ...c, key: c.id }))}
        onRow={(command: IEditorCommandTypeLite, _rowIndex: any) => {
          return {
            onClick: (_e: React.MouseEvent) => {
              history.push(`${getCommandRoute(command)}/${command.id}`);
            },
          };
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={`You don't have any ${type}s yet. Create one by clicking the 'New' button above.`}
            />
          ),
        }}
      />
    </div>
  );
};

export default CommandTable;
