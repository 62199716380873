import styled from '@emotion/styled';
import React from 'react';

const Content = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 12px;
`;

export const ArgumentsEmptyState = (props: { isRecordAction: boolean }) => {
  if (props.isRecordAction) {
    return (
      <div>
        <Content>
          This action gets triggered immediately when the record is selected. Add one or more arguments to make more
          advanced actions.
        </Content>
      </div>
    );
  }
  return (
    <div>
      <Content>
        This command gets triggered immediately. Add one or more arguments to make more advanced commands.
      </Content>
    </div>
  );
};
