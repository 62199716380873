import React from 'react';
import { Row, Col, Radio, CodeLine } from '@commandbar/design-system/components/antd';
import { LauncherTypeOptions } from './LauncherTypeOptions';
import { LauncherPositionSelect } from './LauncherPositionSelect';
import { LauncherOffsetInput } from './LauncherOffsetInput';
import { useAppContext } from 'editor/src/AppStateContext';
import * as S from './styles';
import { Grid03 } from '@commandbar/design-system/icons/react';
import { TargetElementClickRecorder } from '../TargetElementClickRecorder';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';
import { getHelpHubLauncherSettingKeys, getHelpHubLauncherSettings } from '@commandbar/internal/middleware/helpHub';
import { CmdButton } from '@commandbar/design-system/cmd';
import { transformIfJSPath } from '@commandbar/internal/util/dom';
import { ElementInformation } from '@commandbar/internal/middleware/types';

type LauncherSettingsProps = {
  deviceType: DeviceType;
};

export const LauncherSettings: React.FC<LauncherSettingsProps> = (props) => {
  const {
    organization,
    isStudio,
    dispatch: {
      organization: { updateSetting },
    },
  } = useAppContext();
  const settingsKeys = getHelpHubLauncherSettingKeys(props.deviceType);
  const { type, position, offsetX, offsetY, anchor } = getHelpHubLauncherSettings(props.deviceType, organization);

  const [targetElement, setTargetElement] = React.useState<ElementInformation>({
    selector: anchor,
    tag: '',
    text: '',
    attributes: {},
  });
  const [targetElementError, setTargetElementError] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] = React.useState<'custom' | 'default' | 'none'>(
    type === 'custom' ? 'custom' : type === 'none' ? 'none' : 'default',
  );

  if (!organization) {
    return null;
  }

  const onBlurX = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const intValue = parseInt(value, 10);
    if (offsetX === intValue) {
      return;
    }

    updateSetting({ [settingsKeys.offsetX]: intValue });
  };
  const onBlurY = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const intValue = parseInt(value, 10);
    if (offsetY === intValue) {
      return;
    }

    updateSetting({ [settingsKeys.offsetY]: intValue });
  };
  return (
    <div style={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
      <S.SettingContainer
        active={selectedOption === 'default'}
        onClick={
          selectedOption !== 'default'
            ? () => {
                setSelectedOption('default');
                updateSetting({ [settingsKeys.type]: 'bookOpen', [settingsKeys.position]: position });
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'default'}>
          <S.Title>Default</S.Title>
          <S.Description>Add a floating button to your app to toggle HelpHub</S.Description>
        </Radio>
        {selectedOption === 'default' && (
          <div style={{ padding: '8px 24px', width: '100%' }}>
            <div>
              Launcher Style
              <S.LauncherBox>
                <LauncherTypeOptions
                  onSetType={(launcherType) => updateSetting({ [settingsKeys.type]: launcherType })}
                  value={type}
                />
              </S.LauncherBox>
            </div>
            <div style={{ margin: '16px 0' }}>
              Screen Position
              <LauncherPositionSelect
                value={position}
                onChange={(position) => updateSetting({ [settingsKeys.position]: position })}
              />
            </div>
            <div>
              Screen Offset
              <Row gutter={[16, 16]} wrap={false}>
                <Col>
                  <LauncherOffsetInput value={offsetX} onBlur={onBlurX} title="X" />
                </Col>

                <Col>
                  <LauncherOffsetInput value={offsetY} onBlur={onBlurY} title="Y" />
                </Col>
              </Row>
            </div>
          </div>
        )}
      </S.SettingContainer>
      <S.SettingContainer
        active={selectedOption === 'custom'}
        onClick={
          selectedOption !== 'custom'
            ? () => {
                updateSetting({ [settingsKeys.type]: 'custom' });
                setSelectedOption('custom');
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'custom'}>
          <S.Title>Custom</S.Title>
          <S.Description>Toggle HelpHub with an existing element in your app</S.Description>
        </Radio>
        {selectedOption === 'custom' && (
          <div
            style={{
              width: '100%',
              margin: '24px 0 0 0',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '24px',
            }}
          >
            <S.SecondaryTitle>Target Element</S.SecondaryTitle>
            <TargetElementClickRecorder
              value={targetElement}
              onValueChange={(value) => {
                setTargetElement(value);
                updateSetting({ [settingsKeys.anchor]: value, [settingsKeys.type]: 'custom' });
              }}
            >
              <React.Fragment>
                <CmdButton icon={<Grid03 />} disabled={isStudio}>
                  Pick Element...
                </CmdButton>
                <S.CustomTextInput
                  textArea={true}
                  placeholder="CSS or XPath Selector..."
                  value={targetElement.selector}
                  onBlur={(e) => setTargetElementError(!e.target.value)}
                  status={targetElementError ? 'error' : ''}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const shadowDOMEnabledSelector = transformIfJSPath(e.target.value);
                    setTargetElementError(!shadowDOMEnabledSelector);
                    setTargetElement({ selector: shadowDOMEnabledSelector, tag: '', text: '', attributes: {} });
                    if (shadowDOMEnabledSelector) {
                      updateSetting({ [settingsKeys.anchor]: shadowDOMEnabledSelector, [settingsKeys.type]: 'custom' });
                    }
                  }}
                />
              </React.Fragment>
            </TargetElementClickRecorder>
          </div>
        )}
      </S.SettingContainer>
      <S.SettingContainer
        active={selectedOption === 'none'}
        onClick={
          selectedOption !== 'none'
            ? () => {
                setSelectedOption('none');
                updateSetting({ [settingsKeys.type]: 'none' });
              }
            : undefined
        }
      >
        <Radio checked={selectedOption === 'none'}>
          <S.Title>None</S.Title>
          <S.Description>
            Toggle HelpHub with our SDK.{' '}
            <a
              href="https://www.commandbar.com/docs/sdk/helphub/togglehelphub/"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              Learn more ↗
            </a>
          </S.Description>
        </Radio>
        {selectedOption === 'none' && (
          <S.CodeWrapper>
            <CodeLine code="window.CommandBar.toggleHelpHub()" />
          </S.CodeWrapper>
        )}
      </S.SettingContainer>
    </div>
  );
};
