import React from 'react';
import { StatusBadge, Tooltip, Typography } from '@commandbar/design-system/components/antd';

export type KeyStatus = 'active' | 'inactive' | 'search' | 'loader';

const ACTIVE_TOOLTIP_MESSAGE =
  "Values have been provided for this record. Open the Debugger's Context inspector tab to view them.";
const LOADER_TOOLTIP_MESSAGE = 'A loader function has been provided for this key';
const SEARCH_TOOLTIP_MESSAGE = 'A search function has been provided for this key';

const INACTIVE_TOOLTIP_MESSAGE = (
  <span>
    No values have been provided for this record. Use{' '}
    <Typography.Text code>window.CommandBar.addContext</Typography.Text> to add values
  </span>
);

const QUICKFIND_ON_TOOLTIP =
  'Quickfind is on for this record, so users can search for it before searching for a command';
const QUICKFIND_OFF_TOOLTIP =
  'Quickfind is used to enable users to search for records without first selecting a command';

const getTooltipMessage = (active: KeyStatus, quickFind?: boolean) => {
  switch (active) {
    case 'loader':
      return LOADER_TOOLTIP_MESSAGE;
    case 'active':
      return !!quickFind ? QUICKFIND_ON_TOOLTIP : ACTIVE_TOOLTIP_MESSAGE;
    case 'inactive':
      return !!quickFind ? QUICKFIND_OFF_TOOLTIP : INACTIVE_TOOLTIP_MESSAGE;
    case 'search':
      return SEARCH_TOOLTIP_MESSAGE;
  }
};

const getText = (active: KeyStatus) => {
  switch (active) {
    case 'loader':
      return 'Loader';
    case 'active':
      return 'Active';
    case 'inactive':
      return 'Inactive';
    case 'search':
      return 'Search function provided';
  }
};

const ActiveBadge = ({
  active,
  quickFind,
  activeTooltipContent,
  inactiveTooltipContent,
}: {
  active: KeyStatus;
  quickFind?: boolean;
  activeTooltipContent?: React.ReactNode;
  inactiveTooltipContent?: React.ReactNode;
}) => {
  const text = getText(active);
  const color = active === 'inactive' ? 'orange' : 'green';

  const getContent = () => {
    if (active === 'active') {
      return activeTooltipContent ?? getTooltipMessage(active, quickFind);
    }

    if (active === 'inactive') {
      return inactiveTooltipContent ?? getTooltipMessage(active, quickFind);
    }

    return getTooltipMessage(active, quickFind);
  };

  return (
    <Tooltip content={getContent()}>
      <StatusBadge text={text} color={color} />
    </Tooltip>
  );
};

export default ActiveBadge;
