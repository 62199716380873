import { IRecordSettings } from '@commandbar/internal/middleware/types';
import { ParameterOption, initParameterOption } from './ParameterOption';
import { CommandOption } from './CommandOption';
import { OptionCategoryMetadata } from './Option';
import { CBStore } from 'shared/store/global-store';
import { getOptionUID } from '../../selectors';

export interface RecordOption extends ParameterOption {
  recordActions: CommandOption[];
  readonly _resource: true;
  isRecent: boolean;
}

export const initRecordOption = (
  _: CBStore,
  resource: any,
  label: string,
  resourceKey: string,
  searchOptions: IRecordSettings,
  recordActions: CommandOption[],
): RecordOption => {
  // searchOptions.renderOptions.preview
  const o = initParameterOption(
    _,
    label,
    resource,
    searchOptions,
    resource?.__detailPreview || (searchOptions.detail ?? (searchOptions.show_preview ? searchOptions.content : null)),
  ) as RecordOption;
  Object.assign(o, { _resource: true });

  const category: OptionCategoryMetadata = {
    label: searchOptions.name ?? resourceKey,
    contextKey: resourceKey,
    limit: searchOptions?.max_options_count ?? null,
    renderAs: searchOptions.render_as || 'list', // FIXME: Grid
  };

  o.category = category;
  o.recordActions = [...recordActions];

  const optionUID = getOptionUID(o);
  const track_recents = searchOptions.track_recents === undefined || searchOptions.track_recents;

  o.isRecent = !!(optionUID && track_recents && _.endUserStore.derived.recentUIDs.includes(optionUID));

  return o;
};
