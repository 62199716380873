import * as t from 'io-ts';

import { NamedRuleReferenceV, RuleExpressionV } from './rules';

export const AudienceV = t.union([
  t.type({
    type: t.literal('all_users'),
  }),
  t.type({
    type: t.literal('rule_expression'),
    expression: RuleExpressionV,
  }),
  t.type({ type: t.literal('named_rule_reference'), rule_reference: NamedRuleReferenceV }),
]);
