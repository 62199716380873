import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';

export const Row = styled.div`
  display: flex;
`;

export const Col = styled(Row)`
  flex-direction: column;
  width: 100%;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral800};
  div {
    transform: translate(6px, 2px);
    opacity: 0.5;
  }
`;

export const IconContainer = styled.div<{ visible?: boolean }>`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;
