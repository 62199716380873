import { useEffect, useRef } from 'react';

// Runs an async callback function, interrupting the function if the component is umounted
// This will prevent "Can't perform a React state update on an unmounted component" errors

export function useAsyncCallback<U, R>(fn: () => Generator<Promise<U>, R | void, any>): () => Promise<R | void>;

export function useAsyncCallback<T1, U, R>(
  fn: (arg1: T1) => Generator<Promise<U>, R | void, any>,
): (arg1: T1) => Promise<R | void>;

export function useAsyncCallback<T1, T2, U, R>(
  fn: (arg1: T1, arg2: T2) => Generator<Promise<U>, R | void, any>,
): (arg1: T1, arg2: T2) => Promise<R | void>;

export function useAsyncCallback<U, R>(fn: (...args: any[]) => Generator<Promise<U>, R | void, any>) {
  const unmounted = useRef(false);
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return async (...args: any[]) => {
    const generator = fn(...args);

    let current = null;
    do {
      // run one iteration of the generator
      const result = current ? await current.value : undefined;

      // if we are unmounted in the interim, stop running the generator
      if (unmounted.current) {
        generator.return();
        return;
      }

      current = generator.next(result);
    } while (!current.done);

    return current.value;
  };
}
