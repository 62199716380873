import { cmdToast } from '@commandbar/design-system/cmd';

export const reportErrorToUser = (error: any) => {
  // this is an error thrown from our frontend code
  if (error instanceof Error) {
    cmdToast.error(error.message);
  }

  // this is an error response from a axios call
  const axiosError = error.response?.data?.error?.message;
  if (axiosError) {
    cmdToast.error(axiosError);
  } else if (error?.detail) {
    cmdToast.error(error.detail);
  }
  // this is probably an HTTP Response error OR a validation error from the backend
  else if (typeof error === 'string') {
    // check if this is a validation error list, formatted like:
    // ('action', [\"Invalid action: 'command' is a required property\"])
    // if there are multiple, just report first message
    const regex = /\[\\"(.*?)\\"\]/;
    const match = error.match(regex);
    if (match) {
      cmdToast.error(match[1]);
    } else {
      try {
        // check if it's a JSON validation error, formatted like:
        // {slug: ["invalid slug"]}
        // if there are multiple, just report first message
        if (error.startsWith('"') && error.endsWith('"')) {
          error = error.slice(1, -1);
          // eslint-disable-next-line no-useless-escape
          error = error.replace(/\\\"/g, '"');
        }
        const parsedErr = JSON.parse(error);
        let firstErr = parsedErr[Object.keys(parsedErr)[0]];
        if (Array.isArray(firstErr)) {
          cmdToast.error(firstErr[0]);
        } else {
          if (firstErr === 'Authentication credentials were not provided.') {
            firstErr = 'You are not logged in. Please try to refresh the page and re-authenticate.';
          }

          cmdToast.error(firstErr);
        }
      } catch {
        // HTTP Response error is just a string.  if we can't JSON parse the error, just report the string directly
        cmdToast.error(error);
      }
    }
  }

  // this shouldn't happen, but as a failsafe, just report a generic error message
  else {
    cmdToast.error('Something went wrong');
  }
};
