import { Input, Form, Checkbox } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { FormItemHorizontal } from '../shared/styles';

const Web: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.WEB,
  name: 'Website (Legacy)',
  slug: 'web',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/web-icon.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/web-icon.svg',
  useDryRun: false,
  isDisabled: false,
  description: 'Add content from any public website',
  guidance: null,
  route: '/website',
  internalOnly: true,

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          label="Site URL"
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.recursive_urls?.[0] ?? editingSource?.meta?.single_urls?.[0] ?? ''}
          name="domain"
          rules={[{ required: true, message: 'The domain is a required field.' }]}
          style={{ flex: 5 }}
          required
        >
          <Input placeholder="https://commandbar.com/blog" />
        </Form.Item>

        <FormItemHorizontal
          key={editingSource?.type + 'single_page'}
          label="Sync only this URL"
          name="single_page"
          initialValue={!!editingSource?.meta?.single_urls?.[0]}
          valuePropName="checked"
          rules={[{ required: false }]}
        >
          <Checkbox />
        </FormItemHorizontal>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      single_urls: !!data['single_page'] ? [data['domain']] : [],
      recursive_urls: !!data['single_page'] ? [] : [data['domain']],
    };
  },
  requiredFields: ['single_page', 'domain'],
  fetchedFields: [],
};

export default Web;
