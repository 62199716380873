import * as S from '../styled';

import type { INudgeStepContentBlockType, INudgeContentListBlock } from '@commandbar/internal/middleware/types';
import React from 'react';
import { Settings03, Trash04 } from '@commandbar/design-system/icons/react';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { Tag, Dropdown } from '@commandbar/design-system/components/antd';
import { CmdSwitch } from '@commandbar/design-system/cmd';
import { SurveyValidation } from './SurveyValidation';

interface IButton {
  block: INudgeContentListBlock;
  onBlockChange: (block: INudgeStepContentBlockType, resetLogicBlocks?: boolean, deleteLogicBlocks?: boolean) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

export const ListBlockForm = ({ block, onBlockChange, onBlockDelete }: IButton) => {
  const [isDropdownVisible, setisDropdownVisible] = React.useState(false);
  const [isMulti, setIsMulti] = React.useState(block?.meta?.list_type === 'multiple');
  const [isDropdown, setIsDropdown] = React.useState(block?.meta?.display_type === 'dropdown');

  const synTagRender = (cust_props: CustomTagProps) => {
    const { label, onClose } = cust_props;
    return (
      <Tag closable={true} onClose={onClose} style={{ whiteSpace: 'normal' }}>
        {label}
      </Tag>
    );
  };

  return (
    <S.MediaBlockContainer>
      <S.MediaBlockInnerContainer>
        <S.SectionContainer>
          <S.TitleRow>
            <S.StyledLabel htmlFor="prompt">List</S.StyledLabel>

            <div style={{ display: 'flex', gap: '4px' }}>
              <S.MenuIconContainer
                onClick={() => {
                  onBlockDelete(block);
                }}
              >
                <Trash04 opacity={0.5} />
              </S.MenuIconContainer>
              <Dropdown
                visible={isDropdownVisible}
                onVisibleChange={(visible) => setisDropdownVisible(visible)}
                placement="topRight"
                overlay={
                  <S.OverlayContainer width="210px">
                    <SurveyValidation
                      initialValue={{
                        value: block?.meta?.validation?.required?.value ?? false,
                        message: block?.meta?.validation?.required?.value
                          ? block?.meta?.validation?.required.message
                          : 'Select an option to continue',
                      }}
                      onChange={(validation) => {
                        onBlockChange({
                          ...block,
                          meta: {
                            ...block.meta,
                            validation,
                          },
                        });
                      }}
                      wrapOption={(option) => <S.DropDownOption>{option}</S.DropDownOption>}
                    />

                    <S.DropDownOption onClick={() => setisDropdownVisible(false)}>
                      <S.DropdownOptionAction>
                        Display as dropdown
                        <CmdSwitch
                          checked={isDropdown}
                          onCheckedChange={(e) => {
                            setIsDropdown(e);
                            if (!e) {
                              onBlockChange({
                                type: 'survey_list',
                                sort_key: block.sort_key,
                                meta: {
                                  ...block.meta,
                                  display_type: 'list',
                                },
                              });
                            } else {
                              setIsMulti(false);
                              onBlockChange({
                                type: 'survey_list',
                                sort_key: block.sort_key,
                                meta: {
                                  ...block.meta,
                                  display_type: 'dropdown',
                                  list_type: 'single',
                                },
                              });
                            }
                          }}
                        />
                      </S.DropdownOptionAction>
                    </S.DropDownOption>

                    <S.DropDownOption onClick={() => setisDropdownVisible(false)} disabled={isDropdown}>
                      <S.DropdownOptionAction>
                        Multiple choice
                        <CmdSwitch
                          checked={isMulti}
                          disabled={isDropdown}
                          onCheckedChange={(e) => {
                            setIsMulti(e);
                            if (!e) {
                              onBlockChange({
                                type: 'survey_list',
                                sort_key: block.sort_key,
                                meta: {
                                  ...block.meta,
                                  list_type: 'single',
                                },
                              });
                            } else {
                              onBlockChange(
                                {
                                  type: 'survey_list',
                                  sort_key: block.sort_key,
                                  meta: {
                                    ...block.meta,
                                    list_type: 'multiple',
                                  },
                                },
                                undefined,
                                // Delete Logic Blocks when switching to multiple choice
                                true,
                              );
                            }
                          }}
                        />
                      </S.DropdownOptionAction>
                    </S.DropDownOption>
                  </S.OverlayContainer>
                }
              >
                <S.MenuIconContainer>
                  <Settings03 height={16} opacity="0.5" />
                </S.MenuIconContainer>
              </Dropdown>
            </div>
          </S.TitleRow>
          <S.ChecklistSelect
            style={{ width: '100%' }}
            key="list-block-options"
            mode="tags"
            tagRender={synTagRender}
            value={block.meta.options?.map((s) => ({ value: s, label: s }))}
            placeholder="Add an item and press return"
            onChange={(tags: any) =>
              onBlockChange({
                type: 'survey_list',
                sort_key: block.sort_key,
                meta: {
                  ...block.meta,
                  options: tags,
                },
              })
            }
            open={false}
          />
        </S.SectionContainer>
      </S.MediaBlockInnerContainer>
    </S.MediaBlockContainer>
  );
};
