import React from 'react';
import styled from '@emotion/styled';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { Play } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { StudioPreviewSetupInstructions } from './StudioPreviewSetupIntructions';
import { useIsChromeExtensionInsalled } from '../../../hooks';

const Container = styled.div`
  position: relative;
`;
const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  cursor: pointer;

  width: max-content;
  height: 32px;

  background: #ffffff;
  border: 1px solid rgba(10, 10, 15, 0.24);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px -2px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  color: ${CB_COLORS.neutral700};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    border: 1px solid ${CB_COLORS.neutral1000};
    background: ${CB_COLORS.neutral0};
    color: ${CB_COLORS.neutral1000};
  }
  transition: all 0.3s;
`;

interface IPreviewButton {
  /**
   * If in studio and enableClickInStudio is off, we'll show extension installation instructions when clicked
   * Else: we'll call onClick when clicked
   */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  enableClickInStudio?: boolean;
}

export const PreviewButton = ({ onClick, enableClickInStudio }: IPreviewButton) => {
  const { isStudio } = useAppContext();
  const [showPreview, setShowPreview] = React.useState(false);
  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();

  if (isStudio && !enableClickInStudio) {
    return (
      <Container>
        <StyledButton onClick={() => setShowPreview(!showPreview)}>
          <Play width={12} />
        </StyledButton>
        {showPreview && isStudio && (
          <StudioPreviewSetupInstructions
            isChromeExtensionInstalled={isChromeExtensionInstalled}
            onClose={() => setShowPreview(false)}
          />
        )}
      </Container>
    );
  }

  return (
    <StyledButton onClick={onClick}>
      <Play width={12} />
    </StyledButton>
  );
};
