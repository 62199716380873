export type PricingPlan = {
  uid: string;
  title: string;
  sources: number;
};

// this holds limitation values that are not encoded in the backend but we still want to display in the fronted somewhere
export const pricing_plan_defaults: PricingPlan[] = [
  {
    uid: 'Starter',
    title: 'Starter',
    sources: 1,
  },
  {
    uid: 'Growth',
    title: 'Growth',
    sources: 2,
  },
  {
    uid: 'appsumo_tier1',
    title: 'AppSumo Tier 1',
    sources: 1,
  },
  {
    uid: 'appsumo_tier2',
    title: 'AppSumo Tier 2',
    sources: 2,
  },
  {
    uid: 'appsumo_tier3',
    title: 'AppSumo Tier 3',
    sources: 0,
  },
];
