import React from 'react';

import {
  CmdButton,
  CmdDataTable,
  CmdDataTableProps,
  CmdTableError,
  CmdTableNoResultCta,
} from '@commandbar/design-system/cmd';

interface TableProps {
  isLoading: boolean;
  columns: any;
  toolBarChildren?: React.ReactChild;
  empty: {
    refetchData: () => void;
    error?: boolean;
    additionalActions?: Array<{ label: string } & React.ComponentProps<typeof CmdButton>>;
  };
}

const Table = ({ isLoading, columns, toolBarChildren, empty, ...props }: TableProps & CmdDataTableProps<any, any>) => {
  return (
    <CmdDataTable
      {...props}
      columns={columns}
      isLoading={isLoading}
      emptyResultChildren={
        empty.error ? (
          <CmdTableError onClick={empty.refetchData} />
        ) : (
          <CmdTableNoResultCta onClick={empty.refetchData}>
            {(defaultAction) => (
              <div style={{ display: 'flex', gap: '4px' }}>
                {defaultAction}
                {empty.additionalActions?.map(({ label, ...buttonProps }) => (
                  <CmdButton key={label} {...buttonProps}>
                    {label}
                  </CmdButton>
                ))}
              </div>
            )}
          </CmdTableNoResultCta>
        )
      }
      toolBarChildren={toolBarChildren}
    />
  );
};

export default Table;
