import * as t from 'io-ts';

export const BillingProfileV = t.type({
  organization: t.string,
  tier: t.string,
  discount_cents: t.number,
  name: t.string,
  invoice_description: t.string,
  customer_id: t.union([t.string, t.null]),
  publishable_key: t.string,
  balance: t.union([t.number, t.null]),
  currency: t.union([t.string, t.null]),
  on_free_trial: t.boolean,
  free_trial_end_date: t.union([t.string, t.null]),
  appsumo_invoice_item_id: t.union([t.string, t.null]),
});
export type IBillingProfile = t.TypeOf<typeof BillingProfileV> & unknown;

export const UsageV = t.intersection(
  [
    t.type({
      end_user_limit: t.number,
      user_seat_limit: t.number,
      helphub_docs_limit: t.number,
      helphub_docs_usage: t.number,
      helphub_message_limit: t.number,
      helphub_message_usage: t.number,
      commands_live_limit: t.number,
      commands_live_usage: t.number,
      nudges_live_limit: t.number,
      nudges_live_usage: t.number,
      questlists_live_limit: t.number,
      questlists_live_usage: t.number,
      skins_limit: t.number,
      branding: t.string,
      help_center_sync: t.string,
    }),
    t.partial({}),
  ],
  'Usage',
);
export type IUsage = t.TypeOf<typeof UsageV> & unknown;
