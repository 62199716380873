import * as React from 'react';

import { Alert } from '@commandbar/design-system/components/antd';
import SVG from '@commandbar/internal/util/SVG';
import { CmdButton } from '@commandbar/design-system/cmd';
import { Minus, Plus, SlashCircle01 } from '@commandbar/design-system/icons/react';

// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
const FILE_TYPES = ['image/apng', 'image/avif', 'image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

interface IProps {
  defaultIcon: string | null;
  onUpload: (fileString: string) => void;
  onRemove: () => void;
  title?: string;
  value?: string;
  svgOnly?: boolean;
}

let uploadFormIdCounter = 1;
const IconUpload = (props: IProps) => {
  const [icon, setIcon] = React.useState<string | null>(props.defaultIcon);
  const [wrongTypeError, setWrongTypeError] = React.useState<string>('');

  const uploadRef = React.useRef<HTMLLabelElement>(null);

  const WRONG_TYPE_ERROR_MESSAGE = `Only ${
    props.svgOnly ? 'svg images' : 'images'
  } are allowed. Please select another file.`;

  const onRemove = () => {
    setIcon(null);
    props.onRemove();
  };

  const onFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setWrongTypeError('');

    const fileToUpload = e.target.files?.[0];
    if (fileToUpload) {
      const type = fileToUpload.type;

      if (!FILE_TYPES.includes(type)) {
        setWrongTypeError(WRONG_TYPE_ERROR_MESSAGE);
        e.target.value = '';
        return;
      }

      const reader = new FileReader();

      reader.onload = (progressEvent) => {
        if (progressEvent.target !== null) {
          const dataURL = progressEvent.target.result;
          if (typeof dataURL === 'string') {
            if (type === 'image/svg+xml' && !dataURL.startsWith('<svg')) {
              setWrongTypeError('Please add a file that begins with <svg');
            } else {
              uploadFile(dataURL);
            }
          }
        }
      };

      if (type === 'image/svg+xml') {
        reader.readAsText(fileToUpload);
      } else {
        reader.readAsDataURL(fileToUpload);
      }
    }
  };

  const uploadFile = async (text: string) => {
    setIcon(text);
    props.onUpload(text);
  };
  const uploadFormId = React.useMemo(() => {
    uploadFormIdCounter++;
    return `logo-${uploadFormIdCounter}`;
  }, []);
  const label = (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <label
        id={`upload-${props.value || uploadFormId}`}
        ref={uploadRef}
        htmlFor={props.value || uploadFormId}
        style={{
          height: 140,
          width: 140,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '6px',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          cursor: 'pointer',
          textAlign: 'center',
          backgroundPosition: '0px 0px, 10px 10px',
          backgroundSize: '20px 20px',
          backgroundImage:
            'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%)',
        }}
      >
        <SlashCircle01 color="#aaa" style={{ fontSize: '38px' }} />
      </label>
      <div style={{ height: '32px' }} />
      <CmdButton
        variant="primary"
        onClick={() => {
          if (!!uploadRef?.current) {
            uploadRef.current.click();
          }
        }}
        icon={<Plus />}
        style={{ backgroundColor: 'green', border: '0px' }}
      >
        {`Upload ${props.svgOnly ? 'SVG' : 'image'}`}
      </CmdButton>
    </div>
  );

  let upload;

  if (icon === null) {
    upload = (
      <div>
        <input
          style={{
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
          type="file"
          id={props.value || uploadFormId}
          name={props.value || uploadFormId}
          accept={props.svgOnly ? '.svg' : 'image/*'}
          onChange={onFileInput}
        />
        {label}
      </div>
    );
  } else {
    upload = (
      <>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <div
            style={{
              width: 140,
              height: 140,
              borderRadius: '6px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            {icon.startsWith('<svg') ? (
              <SVG htmlstring={icon} height="60px" width="60px" useDefaultSVGColor />
            ) : (
              <img src={icon} alt="Uploaded" width="130px" />
            )}
          </div>
          <div style={{ height: '32px' }} />
          <CmdButton variant="destructive" onClick={onRemove} icon={<Minus />}>
            {`Remove ${props.svgOnly ? 'SVG' : 'image'}`}
          </CmdButton>
        </div>
      </>
    );
  }

  return (
    <span
      style={{
        display: 'inline-block',
        padding: '16px 64px',
        borderRadius: '6px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        margin: '12px',
      }}
    >
      {props.title && (
        <div
          style={{ textTransform: 'uppercase', color: '#222', fontWeight: 700, margin: '12px', textAlign: 'center' }}
        >
          {props.title}
        </div>
      )}
      {upload}
      {!!wrongTypeError && (
        <Alert
          message={<span>{wrongTypeError}</span>}
          type="error"
          showIcon
          closable
          style={{ alignSelf: 'center', marginLeft: 'auto', marginTop: 16 }}
          onClose={() => setWrongTypeError('')}
        />
      )}
    </span>
  );
};

export default IconUpload;
