import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { getSDK } from '@commandbar/internal/client/globals';
import {
  _search,
  _user,
  _configuration,
  _userProperties,
  _instanceAttributes,
  _eventSubscriptions,
  _metaAttributes,
  _fingerprint,
} from '@commandbar/internal/client/symbols';
import {
  getEventAttributeBlockList,
  getUserType,
  shouldReport,
  shouldReportToServer,
  stripEventAttributes,
} from './helpers';
import Logger from '@commandbar/internal/util/Logger';
import { getSentry } from '@commandbar/internal/util/sentry';
import { getBaseURL } from '@commandbar/internal/middleware/network';
import { EVENT_TYPE, IEventAttributes, IEventPayload, EVENT_NAME } from './types';
import { EventQueue } from './event-queue';
import { CLIENT_HANDLER_EVENT_TYPES, EventType } from './EventHandler';

const getCurrentTimeStamp = (): string => {
  // TODO: Factor in the timezone offset
  return new Date().toISOString();
};

const sendEvents = (events: IEventPayload[], useBeacon = false) => {
  const clientFlushedTimestamp = getCurrentTimeStamp();

  const body = {
    events: events.map((e) => ({ ...e, clientFlushedTimestamp })),
    organization: getSDK()[_configuration].uuid,
    id: getSDK()[_user],
  };

  const blob = new Blob([JSON.stringify(body)], { type: 'application/json' });

  let baseUrl = getBaseURL();

  // Redirect `/t/` posts to a separate server
  if (['https://api.commandbar.com'].includes(baseUrl)) {
    baseUrl = 'https://t.commandbar.com';
  }

  if ('sendBeacon' in navigator && useBeacon) {
    // We use the sendBeacon API to reliably send events to the server when the user navigates away from the page.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    navigator.sendBeacon(`${baseUrl}/t/`, blob);
  } else {
    // If the sendBeacon API is not available, or `useBeacon` is not true, we use a normal POST request.

    // NOTE: `keepalive: true` prevents browser cancelling the request when the user navigates away from the page
    // https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
    axiosInstance.post('/t/', JSON.stringify(body), { keepalive: true }).catch((e) => {
      getSentry()?.captureException(e);
    });
  }
};

class Analytics {
  debugMode: boolean;

  hasBooted = false;
  private isAdmin = false;

  private eventQueue: EventQueue<IEventPayload>;

  constructor({ debugMode, sendToServerInLocalhost }: { debugMode: boolean; sendToServerInLocalhost: boolean }) {
    this.debugMode = debugMode;

    this.eventQueue = new EventQueue((events, { useSendBeacon }) => {
      if (!shouldReportToServer(sendToServerInLocalhost, debugMode)) return;

      try {
        sendEvents(events, useSendBeacon);
      } catch (e) {
        getSentry()?.captureException(e);
      }
    });
  }

  private enrichEvent = (type: EVENT_TYPE, name: EVENT_NAME, attrs: Partial<IEventAttributes>): IEventPayload => {
    const userType = getUserType(this.isAdmin);
    const userID = typeof getSDK()[_user] === 'string' ? getSDK()[_user] : null;
    const formFactor = getSDK()[_instanceAttributes]?.formFactor?.type;

    return {
      context: {
        page: {
          path: window.location?.pathname,
          title: document?.title,
          url: window.location?.href,
          search: window.location?.search,
        },
        userAgent: navigator?.userAgent,
        meta: getSDK()[_metaAttributes],
        groupId: getSDK()[_configuration].uuid,
        cbSource: getSDK()[_configuration],
      },
      clientEventTimestamp: getCurrentTimeStamp(),
      userType,
      type,
      attrs: { formFactor, ...attrs },
      name,
      id: userID,
      session: getSDK()[_configuration].session,
      search: getSDK()[_search],
      reportToSegment: this.shouldReportToEventHandler(name),
      ...(!userID && { fingerprint: getSDK()[_fingerprint] }),
    };
  };

  /**
   * Event destination 1: CB Server
   *
   * Events reported to CB's server are put in a queue to reduce the number of network requests.
   * When the queue exceeds maxQueueSize, or an event has the forceFlush attribute, the queue is flushed.
   * Events are enriched with context information (url, device, userType, etc.) before they're sent
   **/
  private addEventToServerQueue = (
    type: EVENT_TYPE,
    name: EVENT_NAME,
    attrs: Partial<IEventAttributes>,
    forceFlush?: boolean,
  ) => {
    const eventAttributeBlockList = getEventAttributeBlockList();
    const enrichedEvent = this.enrichEvent(type, name, attrs);
    this.eventQueue.enqueue(stripEventAttributes(enrichedEvent, eventAttributeBlockList), forceFlush);
  };

  flushServerQueue = (useSendBeacon = false) => {
    this.eventQueue.flush({
      useSendBeacon,
    });
  };

  /**
   * Event Destination 2: Client event handler
   *
   * Client can add an event handler function using window.CommandBar.addEventHandler.
   * Once added, this sends a set of whitelisted events to the client provided function as they happen.
   **/

  private whitelistedEventHandlerEvents: EVENT_NAME[] = CLIENT_HANDLER_EVENT_TYPES.map((x) => x.internal);
  private shouldReportToEventHandler = (eventName: EVENT_NAME): boolean => {
    return this.whitelistedEventHandlerEvents.includes(eventName);
  };

  /**
   * Triage the event and send it to the respective destination(s)
   **/

  private onEvent(type: EVENT_TYPE, name: EVENT_NAME, attrs: Partial<IEventAttributes>, forceFlush?: boolean) {
    try {
      if (
        shouldReport(type, this.hasBooted) ||
        name ===
          'SDK method called' /* We don't have a good way to handle this and this code is getting replaced soon ;) */
      ) {
        // Send to server
        if (this.debugMode) {
          console.log(
            '-- New Event:',
            name,
            type,
            '\nData',
            attrs,
            '\nFull payload',
            this.enrichEvent(type, name, attrs),
          );
        }

        const externalName = CLIENT_HANDLER_EVENT_TYPES.find((x) => x.internal === name)?.external;
        if (!!externalName) {
          attrs.type = externalName;
        }

        this.addEventToServerQueue(type, name, attrs, forceFlush);

        // Send to eventHandler
        if (this.shouldReportToEventHandler(name)) {
          const userAttributes = getSDK()[_userProperties] || {};
          if (!!externalName) {
            const eventHandler = getSDK().shareCallbacks()?.['commandbar-event-handler'];
            const context = { ...attrs, userAttributes };
            if (eventHandler) eventHandler(externalName, context);

            const eventSubscriptions = getSDK()[_eventSubscriptions];
            const iterator = eventSubscriptions instanceof Map ? eventSubscriptions.values() : undefined;
            if (iterator) {
              while (true) {
                const { value: subscriber, done } = iterator.next();
                if (done) break;
                subscriber(externalName as EventType, context as any);
              }
            }
          }
        }
      }
    } catch (error) {
      Logger.warn('Unexpected error logging event; ignoring', { type, name, error });

      getSentry()?.captureException(error);
    }
  }

  /**
   * API
   *
   * identify: [UserEvent] identify the user
   * log: [UserEvent] for remaining events
   * error: [ErrorEvent] for errors
   * availability: [InternalEvent] reporting which commands are available
   *
   * identifyAsAdmin: tell Analytics that the user is an admin (for data enrichment)
   * userHasBooted: tell Analytics that the user has been booted, so start tracking events
   */
  log(eventName: EVENT_NAME, attrs: Partial<IEventAttributes>, forceFlush?: boolean) {
    getSentry()?.addBreadcrumb({
      category: 'log',
      message: eventName,
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Log, eventName, attrs, forceFlush);
  }

  identify(attrs: Partial<IEventAttributes>) {
    getSentry()?.addBreadcrumb({
      category: 'identify',
      message: 'Identify',
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Identify, 'Identify', attrs, true);
  }

  error(attrs: Partial<IEventAttributes>) {
    this.onEvent(EVENT_TYPE.Error, 'Client-Error', attrs, true);
  }

  availability(commands: number[]) {
    const attrs = { commands };

    getSentry()?.addBreadcrumb({
      category: 'availability',
      message: 'Calculate availability',
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Availability, 'Internal-Event', attrs);
  }

  identifyAsAdmin() {
    this.isAdmin = true;
    LocalStorage.set('adm', true);
    this.identify({ isAdmin: true });
  }

  setBootStatus(hasBooted: boolean) {
    this.hasBooted = hasBooted;
  }
}

const analytics = new Analytics({
  /* Use commandbar.debugAnalytics to turn on debug mode */
  debugMode: !!LocalStorage.get('debugAnalytics', false),
  sendToServerInLocalhost: false,
});

export default analytics;
