import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@commandbar/design-system/components/antd';

interface Props {
  subtitle: string;
  actions: ReactNode;
  title?: string;
  className?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  padding: 16px;
  background: linear-gradient(180deg, #fafafa 0%, #ffffff 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
`;

const Title = styled(Typography.Text)`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #141414;
`;

const Subtitle = styled(Typography.Text)`
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: #141414;
  opacity: 0.5;
  margin-bottom: 6px;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const ActionsContainer = styled.div``;

export const ArgumentDetailHeader = ({ title, subtitle = 'steps', actions, className }: Props) => {
  return (
    <Container className={className}>
      <TextContainer>
        <Subtitle>{subtitle}</Subtitle>
        <Title>{title}</Title>
      </TextContainer>
      <ActionsContainer>{actions}</ActionsContainer>
    </Container>
  );
};
