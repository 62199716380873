import React from 'react';
import styled from '@emotion/styled';

import { CB_COLORS, StyledLabel, Tooltip } from '@commandbar/design-system/components/antd';
import * as S from '../styled';
import { useAppContext } from 'editor/src/AppStateContext';

import { Link05, Trash04, ZapFast } from '@commandbar/design-system/icons/react';

import type {
  INudgeStepContentBlockType,
  INudgeStepContentVideoBlockType,
  INudgeStepType,
} from '@commandbar/internal/middleware/types';
import { NudgeDropdown } from './NudgeDropdown';
import { findHelpDocBlocks, findImageBlocks, findVideoBlocks } from '../utils';
import ImageUploader from '../../components/DragUpload/ImageUploader';
import { ElementNotFoundIcon } from './Header';
import { CommandSelect } from '../../CommandSelect';

const DragUploadContainer = styled(S.MediaBlockContainer)`
  background: none;
  padding: 0px;
  & > span {
    width: 100%;
  }
`;

const ConnectHelpCenterButton = styled.button`
  all: unset;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-decoration: underline;
  color: ${CB_COLORS.blue900};
`;

const videoTypeOptions = {
  url: {
    dropdownLabel: (
      <>
        <Link05 width={16} height={16} />
        <S.DropdownLabelDetail>URL</S.DropdownLabelDetail>
      </>
    ),
    selectionLabel: <Link05 width={16} height={16} />,
  },
  command: {
    dropdownLabel: (
      <>
        <ZapFast width={16} height={16} style={{ flexShrink: 0 }} />
        <S.DropdownLabelDetail>Synced video</S.DropdownLabelDetail>
      </>
    ),
    selectionLabel: <ZapFast width={16} height={16} />,
  },
};

type VideoPartial = { type: 'url'; src: string } | { type: 'command'; command: string };

interface ISelectVideoType {
  video: INudgeStepContentVideoBlockType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
}

const SelectVideoType = ({ video, onBlockChange }: ISelectVideoType) => {
  return (
    <NudgeDropdown
      overlayWidth="160px"
      value={video.meta.type}
      onChange={(e) => {
        const emptyVideoPartial = ((): VideoPartial => {
          switch (e) {
            case 'url':
              return { type: 'url', src: '' };
            case 'command':
            default:
              return { type: 'command', command: '' };
          }
        })();

        onBlockChange({
          type: 'video',
          sort_key: video.sort_key,
          meta: {
            ...video.meta,
            ...emptyVideoPartial,
          },
        });
      }}
      options={[
        (
          Object.entries(videoTypeOptions) as Array<
            [keyof typeof videoTypeOptions, (typeof videoTypeOptions)[keyof typeof videoTypeOptions]]
          >
        ).map(([value, { dropdownLabel }]) => ({
          value,
          label: dropdownLabel,
        })),
      ]}
      gap="4px"
    >
      {videoTypeOptions[video.meta.type].selectionLabel}
    </NudgeDropdown>
  );
};

interface IMediaBlocks {
  step: INudgeStepType;
  onBlockChange: (block: INudgeStepContentBlockType) => void;
  onBlockDelete: (block: INudgeStepContentBlockType) => void;
}

export const MediaBlocks = ({ step, onBlockChange, onBlockDelete }: IMediaBlocks) => {
  const { commands, organization } = useAppContext();
  const helpDocCommands = commands.filter((command) => command.template.type === 'helpdoc' && !command.training_only);

  const images = findImageBlocks(step.content);
  const videos = findVideoBlocks(step.content);
  const helpDocs = findHelpDocBlocks(step.content);

  if (!!images.length) {
    return (
      <React.Fragment>
        {images.map((block, i) => (
          <S.MediaBlockContainer key={`${block.sort_key}-${i}`}>
            <S.MediaBlockInnerContainer>
              <S.SectionContainer>
                <S.TitleRow>
                  <StyledLabel htmlFor="label">Image</StyledLabel>
                  <S.MenuIconContainer
                    onClick={() => {
                      onBlockDelete(block);
                    }}
                  >
                    <Trash04 opacity={0.5} />
                  </S.MenuIconContainer>
                </S.TitleRow>
                <DragUploadContainer key={`${block.sort_key}-${i}`}>
                  <ImageUploader
                    onUpload={({ src, file_name, size }) => {
                      onBlockChange({
                        type: 'image',
                        sort_key: block.sort_key,
                        meta: {
                          ...block.meta,
                          src,
                          file_name,
                          size,
                        },
                      });
                    }}
                    onDelete={() => {
                      onBlockChange({
                        type: 'image',
                        sort_key: block.sort_key,
                        meta: {
                          ...block.meta,
                          src: '',
                          file_name: '',
                          size: '',
                        },
                      });
                    }}
                    thumbnail={block.meta}
                    organizationId={organization.id}
                  />
                </DragUploadContainer>
              </S.SectionContainer>
            </S.MediaBlockInnerContainer>
          </S.MediaBlockContainer>
        ))}
      </React.Fragment>
    );
  }

  if (!!videos.length) {
    return (
      <React.Fragment>
        {videos.map((block, i) => (
          <S.MediaBlockContainer key={`${block.sort_key}-${i}`}>
            <S.MediaBlockInnerContainer>
              <S.SectionContainer>
                <S.TitleRow>
                  <StyledLabel htmlFor="video">Video</StyledLabel>
                  <S.MenuIconContainer
                    onClick={() => {
                      onBlockDelete(block);
                    }}
                  >
                    <Trash04 opacity={0.5} />
                  </S.MenuIconContainer>
                </S.TitleRow>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '8px' }}>
                  <S.CombinationInput>
                    <SelectVideoType video={block} onBlockChange={onBlockChange} />
                    {block.meta.type === 'command' ? (
                      <CommandSelect
                        placeholder="Select video..."
                        type="video"
                        commandFilter={(c) => c.template.type === 'video'}
                        value={
                          commands.find((c) => block.meta.type === 'command' && c.id.toString() === block.meta.command)
                            ?.id
                        }
                        onChange={(e) => {
                          onBlockChange({
                            type: 'video',
                            sort_key: block.sort_key,
                            meta: {
                              ...block.meta,
                              type: 'command',
                              command: String(e),
                            },
                          });
                        }}
                        commands={commands}
                      />
                    ) : (
                      <S.SuffixInput
                        name="video"
                        placeholder="Paste a video link (YouTube, Vimeo, Loom, Vidyard, or .MP4)"
                        value={block.meta.src.length > 0 ? block.meta.src : undefined}
                        onChange={(e) => {
                          onBlockChange({
                            type: 'video',
                            sort_key: block.sort_key,
                            meta: {
                              ...block.meta,
                              type: 'url',
                              src: e.target.value,
                            },
                          });
                        }}
                      />
                    )}
                  </S.CombinationInput>
                  {commands?.find(
                    (c) => block.meta.type === 'command' && c.id.toString() === block.meta.command && !c.is_live,
                  ) && (
                    <Tooltip
                      containerStyle={{ display: 'flex', alignItems: 'center' }}
                      placement="bottom"
                      content={'This video is unpublished. Publish it before using it here.'}
                    >
                      <ElementNotFoundIcon />
                    </Tooltip>
                  )}
                </div>
              </S.SectionContainer>
            </S.MediaBlockInnerContainer>
          </S.MediaBlockContainer>
        ))}
      </React.Fragment>
    );
  }

  if (!!helpDocs.length) {
    return (
      <React.Fragment>
        {helpDocs.map((block, i) => (
          <S.MediaBlockContainer key={`${block.sort_key}-${i}`}>
            <S.MediaBlockInnerContainer>
              <S.SectionContainer>
                <S.TitleRow>
                  <StyledLabel htmlFor="help_doc">Article or file</StyledLabel>
                  <S.MenuIconContainer
                    onClick={() => {
                      onBlockDelete(block);
                    }}
                  >
                    <Trash04 opacity={0.5} />
                  </S.MenuIconContainer>
                </S.TitleRow>
                {helpDocCommands && helpDocCommands?.length > 0 ? (
                  <CommandSelect
                    value={
                      helpDocCommands?.find((c) => c.id.toString() === block.meta.command)
                        ? parseFloat(block.meta.command)
                        : undefined
                    }
                    type={'help doc'}
                    commandFilter={(c) => c.template.type === 'helpdoc' && !c.training_only}
                    placeholder="Select a file or synced article..."
                    standalone
                    onChange={(e) => {
                      onBlockChange({
                        type: 'help_doc_command',
                        sort_key: block.sort_key,
                        meta: {
                          ...block.meta,
                          command: e.toString(),
                        },
                      });
                    }}
                    commands={commands}
                  />
                ) : (
                  <div style={{ height: '40px', borderRadius: '4px', background: CB_COLORS.blue100 }}>
                    <ConnectHelpCenterButton
                      onClick={() => {
                        window.open(`${process.env.REACT_APP_DASHBOARD_URL}/integrations`);
                      }}
                    >
                      <span>Connect your Help Center ↗</span>
                    </ConnectHelpCenterButton>
                  </div>
                )}
              </S.SectionContainer>
            </S.MediaBlockInnerContainer>
          </S.MediaBlockContainer>
        ))}
      </React.Fragment>
    );
  }
  return null;
};
