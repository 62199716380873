/* React imports */
/* eslint react/prop-types: 0 */

import * as React from 'react';
import * as Sentry from '@sentry/react';

import { message as AntdMessage, Modal as AntdModal, ModalFuncProps } from 'antd';

import CB_Z from './zIndexes';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;

let LOCAL_SETTINGS: any;
try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  LOCAL_SETTINGS = require('../LocalSettings').LOCAL_SETTINGS;
} catch (e) {
  LOCAL_SETTINGS = {};
}

/************************************************/

export const Modal = (props: React.ComponentProps<typeof AntdModal>) => {
  return (
    <AntdModal {...props} getContainer={() => container} style={{ left: _calculateModalOffset() }}>
      {props.children}
    </AntdModal>
  );
};

Modal.confirm = (props: ModalFuncProps) =>
  AntdModal.confirm({
    ...props,
    getContainer: () => container,
    zIndex: CB_Z.Z_MODALS,
    style: { left: _calculateModalOffset() },
  });

Modal.info = (props: ModalFuncProps) =>
  AntdModal.info({
    ...props,
    getContainer: () => container,
    zIndex: CB_Z.Z_MODALS,
    style: { left: _calculateModalOffset() },
  });

Modal.success = (props: ModalFuncProps) =>
  AntdModal.success({
    ...props,
    getContainer: () => container,
    zIndex: CB_Z.Z_MODALS,
    style: { left: _calculateModalOffset() },
  });

Modal.error = (props: ModalFuncProps) =>
  AntdModal.error({
    ...props,
    getContainer: () => container,
    zIndex: CB_Z.Z_MODALS,
    style: { left: _calculateModalOffset() },
  });

/************************************************/

/************************************************/

const _calculateMessageOffset = () => {
  const windowWidth = window.innerWidth;
  const editorWidth: number = LOCAL_SETTINGS.paneWidth?.getter() || windowWidth;
  return windowWidth - editorWidth;
};

const _calculateModalOffset = () => {
  const windowWidth = window.innerWidth;
  const editorWidth: number = LOCAL_SETTINGS.paneWidth?.getter() || windowWidth;
  return (windowWidth - editorWidth) / 2;
};

// We overwrite the ant message api to center the message on the editor
// Note: We can't just change the container to the container to the editor container
//       on load because the message is absolutely positioned and will stick to the
//       closed editor position (off the screen)
export const message = {
  success: (content: string | React.ReactNode) =>
    AntdMessage.success({
      content: content,
      style: { marginLeft: _calculateMessageOffset() },
    }),

  info: (content: string | React.ReactNode) =>
    AntdMessage.info({ content: content, style: { marginLeft: _calculateMessageOffset() } }),

  loading: (content: string | React.ReactNode) =>
    AntdMessage.loading({ content: content, style: { marginLeft: _calculateMessageOffset() } }),

  error: (content: string | React.ReactNode, shouldReportToSentry = true) => {
    if (typeof content === 'string' && shouldReportToSentry) Sentry.captureMessage(content.toString(), 'error');

    // TODO: Find a better place for this
    if (typeof content === 'string' && content.includes('CSRF Failed')) {
      content =
        'Something went wrong. Please try disabling ad-blockers and enhanced tracking protection, or try using a different browser.';
    }
    return AntdMessage.error({ content: content, style: { marginLeft: _calculateMessageOffset() } });
  },

  warning: (content: string | React.ReactNode, shouldReportToSentry = true) => {
    if (typeof content === 'string' && shouldReportToSentry) Sentry.captureMessage(content.toString(), 'warning');
    return AntdMessage.warning({ content: content, style: { marginLeft: _calculateMessageOffset() } });
  },

  warn: (content: string | React.ReactNode, shouldReportToSentry = true) => {
    if (typeof content === 'string' && shouldReportToSentry) Sentry.captureMessage(content.toString(), 'warning');
    return AntdMessage.warn({ content: content, style: { marginLeft: _calculateMessageOffset() } });
  },
};
