import * as t from 'io-ts';

import { read, mkQueryString } from '../generics';
import { AdminFilter, DateFilter, TableDataV, PaginationFilter } from './common';
import * as axiosInstance from '../network';

const AnalyticsDeadendsDataV = t.type({
  table: TableDataV,
});

export type WidgetTypeFilter = 'copilot' | 'helphub' | 'spotlight' | 'all';
export type ResultTypeFilter = 'no_results' | 'unhelpful_results' | 'all';

export type AnalyticsDeadendsData = t.TypeOf<typeof AnalyticsDeadendsDataV>;

export type DeadendsParamsBase = {
  widget: string;
  result_type: ResultTypeFilter;
} & DateFilter &
  AdminFilter;

export type DeadendsParamsPagination = { search_filter: string } & DeadendsParamsBase & PaginationFilter;

export class AnalyticsDeadends {
  public static read = (params: DeadendsParamsPagination) =>
    read(AnalyticsDeadendsDataV, `analytics/v2/deadends`)(params);
  public static download = async (params: DeadendsParamsBase): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/deadends/export/${queryString}`);
  };
}
