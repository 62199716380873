import { CBStore } from 'shared/store/global-store';
import { getCommands } from 'shared/store/global-selectors';

import { getNudgeById } from '../nudges/service-selectors';

import * as SpotlightActions from './store/actions';
import * as GlobalActions from 'shared/store/global-actions';
import {
  selectSlashFilter,
  selectSlashFilterGroups,
  getContextSettings,
  isCommandOption,
  isUnfurledCommandOption,
  isParameterOptionSelected,
  isParameterOption,
  currentStepAndIndex,
} from './store/selectors';
import { sub } from 'shared/store/util/sub';
import { getSDK, SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';
import { StepType } from './store/steps/step-utils/Step';
import {
  initOptionGroupFromCommandCategory,
  initOptionGroupFromRecordCategory,
  initOptionGroupFromTab,
  initRecentsGroup,
  initRecommendedGroup,
  OptionGroup,
} from './store/options/option-utils/OptionGroup';

import slugify from '@commandbar/internal/util/slugify';
import { createSearchFilter } from './components/select/SearchTabs';

import Analytics from 'shared/services/analytics/Analytics';
import * as Reporting from 'shared/services/analytics/Reporting';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import * as Command from '@commandbar/internal/middleware/command';
import Mousetrap from '@commandbar/internal/client/mousetrap_fork.js';
import Hotkey from '@commandbar/internal/client/Hotkey';

import { initGenerateOptionsSubs } from './store/generate-options/subscriptions';
import initGetRecordsSubscriptions from './store/generate-options/get-records-subscriptions';
import { initCommandOption } from './store/options/option-utils/CommandOption';
import { botAvatarDefaultImageSrc } from '../copilot/components/BotAvatar';
import { queryExperiences } from '@commandbar/commandbar/shared/sdk/search';
import { getChecklistById } from '../checklists/service-selectors';
import { interpolate } from '@commandbar/commandbar/shared/util/Interpolate';
import { getNudgeStep } from '@commandbar/internal/util/nudges';

const MIN_LOADING_TIMEOUT = 1000;

const refreshShowLoadingIndicator = (_: CBStore) => {
  const { loadingByKey, inMinLoadingWindow } = _.spotlight;
  const anyKeyIsLoading = Object.keys(loadingByKey).some((key) => !!loadingByKey[key]);
  _.spotlight.showLoadingIndicator = anyKeyIsLoading || inMinLoadingWindow;
};

/** Also known as autoExecute in editor */
const autoChooseOption = (_: CBStore) => {
  const updateSteps = SpotlightActions.updateSteps.bind(null, _);
  const { currentStep } = currentStepAndIndex(_);

  if (
    currentStep &&
    currentStep.type === StepType.Select &&
    !currentStep.argument.allow_create &&
    currentStep.argument.auto_choose &&
    _.spotlight.initialOptions.length === 1
  ) {
    SpotlightActions.chooseOption(_, _.spotlight.initialOptions[0], updateSteps);

    return;
  }

  const activeRecord = currentStep?.type === StepType.Base && currentStep.resource;
  if (!activeRecord) return;
  const singleCommand = _.spotlight.initialOptions.length === 1 && _.spotlight.initialOptions[0];
  if (singleCommand) {
    SpotlightActions.chooseOption(_, singleCommand, updateSteps);
    return;
  }
  return;
};

const refreshMinLoadingTimeout = (_: CBStore) => {
  if (Object.keys(_.spotlight.loadingByKey).length > 0) return;

  _.spotlight.inMinLoadingWindow = true;

  if (_.spotlight.minLoadingTimeout) clearTimeout(_.spotlight.minLoadingTimeout);

  _.spotlight.minLoadingTimeout = window.setTimeout(() => {
    _.spotlight.inMinLoadingWindow = false;
  }, MIN_LOADING_TIMEOUT);
};

const refreshCommandsLoaded = (_: CBStore) => {
  SpotlightActions.setLoading(_, 'internal-commandsLoaded', !_.commandsLoaded);
};

const resetOnCloseDashboard = (_: CBStore) => {
  if (_.spotlight.dashboard === undefined && _.spotlight.inputText !== '' && getCommands(_).length > 0) {
    SpotlightActions.setInputText(_, '');
    SpotlightActions.reset(_);
  }
};

const refreshCurrentGroups = (_: CBStore) => {
  const availableCommandCategories = new Set();
  const usedRecordKeys = new Set();

  _.spotlight.initialOptions.forEach((option) => {
    if (isCommandOption(option) || isUnfurledCommandOption(option)) {
      const categoryID = option.command?.category;

      if (!!categoryID) {
        availableCommandCategories.add(categoryID);
      }

      Object.keys(option.command.arguments).forEach((k) => {
        const value = option.command.arguments[k].value;
        if (typeof value === 'string') {
          usedRecordKeys.add(value);
        }
      });
    }
  });

  _.spotlight.categories
    .filter((c) => !!c.contains_hotloaded_commands)
    .forEach((c) => availableCommandCategories.add(c.id));

  const isValidRecord = (recordKey: string): boolean => {
    const isActiveRecord = ((): boolean => {
      // Active in context
      if (Boolean(_.context[recordKey])) return true;
      // Has async search fn defined
      if (Object.keys(_.callbacks).includes(`commandbar-search-${recordKey}`)) return true;
      // Has loader defined
      if (Object.keys(_.callbacks).includes(`commandbar-initialvalue-${recordKey}`)) return true;
      return false;
    })();
    return usedRecordKeys.has(recordKey) && isActiveRecord;
  };

  const recordGroups = Object.entries(getContextSettings(_))
    .filter(([k]) => isValidRecord(k))
    .map(([k, v]) => {
      const group = initOptionGroupFromRecordCategory(k, _, v);
      return group;
    });

  const commandGroups: OptionGroup[] = _.spotlight.categories
    .filter((g) => availableCommandCategories.has(g.id))
    .map((c) => {
      const group = initOptionGroupFromCommandCategory(c, _);
      return group;
    });

  const syntheticGroups: OptionGroup[] = [];

  if (_.organization?.end_user_recents_enabled) {
    syntheticGroups.push({
      ...initRecentsGroup(_),
      slash_filter_enabled: true,
      searchTabEnabled: _.organization?.recents_tab_enabled || false,
    });
  }

  if (_.organization?.recommendations_type !== 'None') {
    syntheticGroups.push({
      ...initRecommendedGroup(_),
      slash_filter_enabled: true,
      searchTabEnabled: _.organization?.recommended_tab_enabled || false,
    });
  }

  const tabs = _.spotlight.tabs.map((t) => initOptionGroupFromTab(t, _));

  _.spotlight.currentGroups = [...commandGroups, ...recordGroups, ...syntheticGroups, ...tabs].map((group) => {
    if (!!!group.slash_filter_keyword) {
      group.slash_filter_keyword = slugify(group.name);
    }
    return group;
  });
};

// FIXME [SLASHFILTERS,OPTIONGROUPS]: can move this to `handleInputChange()` if _.spotlight.searchFilter` is moved to `engine`
const applyFilterIfMatchesSlashFilter = (_: CBStore) => {
  if (!_.organization?.slash_filters_enabled) {
    return;
  }

  const { inputText, slashFilter } = selectSlashFilter(_);

  // if length is 1 then there is only the slash
  if (slashFilter.length <= 1) {
    return;
  }

  const slashFilterKeyword = slashFilter.substring(1);

  const labelAllTab = !!_.theme ? slugify(_.theme.searchTab.labelAllTab) : 'all';

  if (slashFilterKeyword.toLowerCase() === labelAllTab.toLowerCase()) {
    _.spotlight.searchFilter = undefined;
    _.spotlight.rawInput = inputText;
    return;
  }

  const slashFilterGroups = selectSlashFilterGroups(_);

  const slashFilterGroup = slashFilterGroups.find((o: OptionGroup) => {
    if (o.slash_filter_keyword === slashFilterKeyword.toLowerCase()) {
      return true;
    }
    return false;
  });
  if (!!slashFilterGroup) {
    const filter = createSearchFilter(slashFilterGroup);

    SpotlightActions.setSearchFilter(_, filter);

    _.spotlight.rawInput = inputText;
    return;
  }
};

const refreshCategories = (_: CBStore) => {
  _.spotlight.categories = [..._.spotlight.localCategories, ..._.spotlight.serverCategories].map((category) => ({
    ...category,
    ..._.spotlight.categoryConfig[category.id],
  }));
};

const experiencesCommandName = SDK_INTERNAL_PREFIX + 'experiences_command';
export const experiencesRecordKey = SDK_INTERNAL_PREFIX + 'experiences';
export const experiencesCallbackKey = SDK_INTERNAL_PREFIX + 'show_experience';

// For backwards compatibility for customers with custom icons
const checklistResourceKey = SDK_INTERNAL_PREFIX + 'completed_checklist';
const nudgeResourceKey = SDK_INTERNAL_PREFIX + 'seen_nudge';

let checkedIfShouldSearchExperiences = false;
let shouldSearchExperiences = true;

const checkIfShouldSearchExperiences = async (_: CBStore) => {
  // Prevent searching for experiences if the user is not an admin
  // and an empty query returns no results
  if (!_.isAdmin && !checkedIfShouldSearchExperiences) {
    checkedIfShouldSearchExperiences = true;
    const results = await queryExperiences(_, '', 'spotlight', undefined, 1, undefined, true);
    if (results.length === 0) {
      shouldSearchExperiences = false;
    }
  }
};

const registerChecklistAndNudgeExperiencesAsRecords = (_: CBStore) => {
  if (!_.active || !_.flags?.['release-search-experiences-in-spotlight']) return;

  const sdk = getSDK();

  sdk.addCallback(experiencesCallbackKey, (item: any) => {
    const type = item.record?.type;
    const id = item?.record?.id;

    if (type === 'checklist') {
      const checklist = getChecklistById(_, id);
      if (checklist) {
        GlobalActions.activatePushExperience(_, checklist, 'questlist');
      }
    } else if (type === 'nudge') {
      const nudge = getNudgeById(_, id);
      if (nudge) {
        GlobalActions.activatePushExperience(_, nudge, 'nudge');
      }
    }
  });
  type ExperienceRecord = { label: string; icon: string; id: number; type: string; description?: string }[];

  const onSearchExperiences = async (query: string): Promise<ExperienceRecord> => {
    if (!shouldSearchExperiences || !query) {
      return [];
    }

    const results = await queryExperiences(_, query, 'spotlight');

    return results
      .map((hit) => {
        if (hit.type === 'nudge') {
          const nudge = getNudgeById(_, hit.experience_id);
          if (nudge && nudge.id) {
            return {
              label: nudge.slug || getNudgeStep(nudge, 0)?.title || 'Nudge',
              icon: _.organization?.resource_options[nudgeResourceKey]?.icon || 'nudge',
              id: nudge.id,
              type: 'nudge',
              description: '',
            };
          }
        } else if (hit.type === 'checklist') {
          const checklist = getChecklistById(_, hit.experience_id);
          if (checklist) {
            return {
              label: checklist.title,
              icon: _.organization?.resource_options[checklistResourceKey]?.icon || 'checklist',
              id: checklist.id,
              type: 'checklist',
              description: checklist.description,
            };
          }
        }
        return null;
      })
      .filter((experience) => experience) as ExperienceRecord;
  };

  sdk.addRecords(experiencesRecordKey, [], {
    onInputChange: onSearchExperiences,
    recordOptions: {
      categoryName: _.theme?.categoryHeader.experiencesHeaderLabel || 'Experiences',
      showInDefaultEmptyState: false,
      maxOptionsCount: 3,
      showInRecents: false,
      categorySortKey: _.organization?.experiences_sort_key || 0,
    },
    descriptionKey: 'description',
  });

  sdk.addRecordAction(experiencesRecordKey, {
    name: experiencesCommandName,
    text: 'Show item',
    template: { type: 'callback', value: experiencesCallbackKey },
  });

  return () => {
    sdk.removeCallback(experiencesCallbackKey);
    sdk.removeContext(experiencesRecordKey);
    sdk.removeCommand(experiencesCommandName);
  };
};

const registerBuiltinCommands = (_: CBStore) => {
  if (
    !_.active ||
    !_.organization ||
    !_.organization.spotlight_ask_copilot_enabled ||
    !_.organization.copilot_enabled
  ) {
    _.spotlight.builtinAskCopilotOption = null;

    return;
  }

  const sdk = getSDK();

  const callbackKey = SDK_INTERNAL_PREFIX + 'open-copilot';

  sdk.addCallback(callbackKey, () => {
    sdk.openCopilot({ query: _.spotlight.previousInputText });
  });

  _.spotlight.builtinAskCopilotOption = initCommandOption(_, {
    text: interpolate(
      _.organization.spotlight_ask_copilot_label || `Ask ${_.organization?.copilot_name ?? 'Copilot'}`,
      _,
      true,
      false,
    ),
    name: '__commandbar_ask_copilot',
    arguments: {},
    template: {
      type: 'callback',
      value: callbackKey,
      operation: 'blank',
      commandType: 'independent',
    },
    tags: [],
    availability_rules: [],
    availability_expression:
      _.organization.spotlight_ask_copilot_audience?.type === 'rule_expression'
        ? _.organization.spotlight_ask_copilot_audience.expression
        : {
            type: 'LITERAL',
            value: true,
          },
    recommend_expression: {
      type: 'LITERAL',
      value: false,
    },
    always_recommend: false,
    recommend_rules: [],
    confirm: '',
    detail: null,
    content: null,
    show_preview: false,
    thumbnail: null,
    shortcut: [],
    shortcut_mac: [],
    shortcut_win: [],
    hotkey_mac: '',
    hotkey_win: '',
    explanation: '',
    heading: '',
    is_live: true,
    category: null,
    sort_key: null,
    icon: _.organization?.copilot_avatar || _.organization?.copilot_avatar_v2?.src || botAvatarDefaultImageSrc,
    icon_color: null,
    image_color: null,
    image: null,
    celebrate: null,
    recommend_sort_key: null,
    next_steps: [],
    copilot_suggest: false,
    copilot_cta_label: '',
    copilot_description: '',
    copilot_use_verbatim: false,
    extra: null,
    id: -1,
    show_in_helphub_search: false,
    show_in_spotlight_search: true,
    audience: { type: 'all_users' },
  });
};

// Auto scroll to single select step selected option.
// Will scroll to the selected item in two cases:
//  - on the initial render of single select step
//  - when user completely deletes text from the search input
const autoScrollToSelectedOption = (_: CBStore) => {
  const { currentStep } = currentStepAndIndex(_);
  if (currentStep?.type === StepType.Select && currentStep.selected?.data && _.spotlight.inputText.length === 0) {
    const selectedIndex = _.spotlight.sortedOptions.findIndex(
      (option) => isParameterOption(option) && isParameterOptionSelected(option, currentStep),
    );

    if (selectedIndex !== -1) {
      _.spotlight.focusedIndex = selectedIndex;
    }
  }
};

const refreshCurrentStepIndex = (_: CBStore) => {
  _.spotlight.currentStepIndex = currentStepAndIndex(_).currentStepIndex;
};

const sendAnalyticsEventForCommandLastAvailableTime = (_: CBStore) => {
  // Update the last_visible time of commands
  // Need to do this on every open, because changed variables (callbacks, context, urls) change availability
  if (_.spotlight.visible && !_.testMode && _.spotlight.currentOptions.length > 0) {
    const availableCommands = _.spotlight.initialOptions.filter(isCommandOption).map((c) => c.command.id);
    Analytics.availability(availableCommands);
  }
};

const resetFocusedIndex = (_: CBStore) => {
  SpotlightActions.resetFocusedIndex(_);
};

const resetErrorIndexOnOptionsChange = (_: CBStore) => {
  // If the options change, we keep the currently selected index as-is
  // so that the focused option doesn't jump up or down the Bar.
  //
  // If the errorIndex was set in this case, in the new list of sortedOptions,
  // it may be pointing to a different option than before. Since we don't have
  // unique IDs for options, we can't be sure, so we just reset the errorIndex
  // in this case.
  _.spotlight.errorIndex = -1;
};

const setupShortcuts = (_: CBStore) => {
  if (!_?.organization) return;

  Mousetrap.reset(); // Clear all bound hotkeys

  if (['mac', 'windows', 'linux'].includes(_.platform)) {
    getCommands(_).map((cmd) => {
      let hotkey = _.platform === 'mac' ? cmd.hotkey_mac : cmd.hotkey_win;
      const cmdUID = Command.commandUID(cmd);

      if (typeof _.endUserStore.data.hotkeys?.[cmdUID] !== 'undefined') {
        hotkey = _.endUserStore.data.hotkeys[cmdUID];
      }

      if (hotkey.length === 0 || (!cmd.is_live && !_.testMode)) {
        return null;
      }

      Mousetrap.bind(hotkey, () => {
        const isDisabled = GlobalActions.executeCommand(
          _,
          cmd,
          () => {
            dispatchCustomEvent('commandbar-shortcut-executed', { detail: { keys: hotkey } });
          },
          () => Reporting.unavailableShortcut(Hotkey.toArray(hotkey), cmd),
        );

        // https://craig.is/killing/mice#api.bind.default
        // Returning false prevents the event from bubbling up, i.e., doesn't fill in the input
        return isDisabled;
      });
      return null;
    });
  }
};

export const initSpotlightSubs = (_: CBStore) => [
  sub(_, refreshShowLoadingIndicator, [
    ['spotlight', 'loadingByKey'],
    ['spotlight', 'inMinLoadingWindow'],
  ]),
  sub(_, autoChooseOption, [['spotlight', 'initialOptions']]),
  sub(_, applyFilterIfMatchesSlashFilter, [['spotlight', 'rawInput']]),
  sub(_, refreshCurrentGroups, [
    ['spotlight', 'categories'],
    ['spotlight', 'initialOptions'],
    ['localContextSettings'],
    ['serverContextSettings'],
    ['theme', 'categoryHeader'],
  ]),
  sub(_, refreshMinLoadingTimeout, [['spotlight', 'loadingByKey']]),
  sub(_, refreshCommandsLoaded, [['commandsLoaded']]),
  sub(_, resetOnCloseDashboard, [['spotlight', 'dashboard']]),
  sub(_, refreshCategories, [
    ['spotlight', 'serverCategories'],
    ['spotlight', 'localCategories'],
    ['spotlight', 'categoryConfig'],
  ]),
  sub(_, checkIfShouldSearchExperiences, [['spotlight', 'visible']]),
  sub(_, registerChecklistAndNudgeExperiencesAsRecords, [['active']]),
  sub(_, registerBuiltinCommands, [['active'], ['spotlight', 'visible'], ['organization']]),
  /**
   * [Shorcuts]: set hotkeys on each change of context, callbacks, commands
   **/
  /*
    NOTE: How this can go wrong? (can move to a doc)
    This is using our existing strategy that we might consider rethinking
    We are setting the keybinds for _all_ the commands (whether or not they are admin/drafts/available/etc)
    A protective measure we take is we check availability of a shortcut command before executing it.
    Where does the preventDefault() go?
    Decision: If a keybind (say cmd-d) is set in the host app _and_ in CB, which should fire? Should this be a setting? If CB, we definitely need to exclude draft commands
    Decision: If a command isn't available in the current state, should it preventDefault?
    Mousetrap handles duplicate keybinds by only running the first one.
    How would we handle the following situation:
      - Two commands programmed to never be available at the same time
      - Attached the same keybind (because they are conceptually similar)
      - In this case, we would need to limit shortcut setup to available commands
      - Example (although there's a more elegant solution): "Toggle Dark", "Toggle Light"
     */
  sub(_, setupShortcuts, [
    ['commands'],
    ['programmaticCommands'],
    ['hotloadedCommands'],

    ['organization'],
    ['endUserStore', 'data', 'hotkeys'],
    ['testMode'],
  ]),
  sub(_, sendAnalyticsEventForCommandLastAvailableTime, [['spotlight', 'visible']]),
  sub(_, refreshCurrentStepIndex, [['spotlight', 'steps']], true),
  sub(_, resetErrorIndexOnOptionsChange, [['spotlight', 'sortedOptions']]),
  sub(_, resetFocusedIndex, [['spotlight', 'sortedOptions']]),
  sub(_, autoScrollToSelectedOption, [
    ['spotlight', 'steps'],
    ['spotlight', 'sortedOptions'],
  ]),

  ...initGenerateOptionsSubs(_),
  ...initGetRecordsSubscriptions(_),
];
