import styled from '@emotion/styled';
import chroma from 'chroma-js';
import { Tag as AntTag, TagProps } from 'antd';
import { PresetColorTypes, PresetStatusColorTypes } from 'antd/lib/_util/colors';

const antdColorPresets: string[] = [...PresetColorTypes, ...PresetStatusColorTypes];

const OPTIMAL_CONTRAST_RATIO = 6.5;
const defaultBgColor = '#f2f2f2';
const defaultColor = 'rgba(0, 0, 0, 0.45)';

const isAntdColorPreset = (str: string) => antdColorPresets.includes(str);

const getBgColor = (color: TagProps['color']) => {
  if (!color) {
    return defaultBgColor;
  }

  // If selected color matches any of Antd color presets, return an empty string and let Antd manage colors
  if (isAntdColorPreset(color)) {
    return '';
  }

  return color;
};

const getColor = (color: TagProps['color']) => {
  if (!color) {
    return defaultColor;
  }

  // If selected color matches any of Antd color presets, return an empty string and let Antd manage colors
  if (isAntdColorPreset(color)) {
    return '';
  }

  // Make sure that there is enough contrast between tag text color and background color
  return color && chroma.contrast(color, defaultColor) < OPTIMAL_CONTRAST_RATIO ? '#fff' : defaultColor;
};

export const Tag = styled(AntTag)`
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  background-color: ${(props) => getBgColor(props.color)};
  color: ${(props) => getColor(props.color)};
  border: none;
`;
