import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--layout-gap);
  align-items: flex-start;
  width: fit-content;
`;

const StyledChatActionsContainer = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>;
};

export default StyledChatActionsContainer;
