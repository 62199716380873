// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Phone01 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5 5.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C6.52 2 7.08 2 8.2 2h7.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C19 3.52 19 4.08 19 5.2v13.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C17.48 22 16.92 22 15.8 22H8.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C5 20.48 5 19.92 5 18.8V5.2Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 17.5h.01M8.2 22h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C19 20.48 19 19.92 19 18.8V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C17.48 2 16.92 2 15.8 2H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 3.52 5 4.08 5 5.2v13.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 22 7.08 22 8.2 22Zm4.3-4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
    />
  </svg>
);
export default Phone01;
