/* @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react';
import { jsx } from '@emotion/core';
import useTheme from 'shared/util/hooks/useTheme';

import { BotMessage } from './BotMessage/BotMessage';
import { CB_COLORS } from '@commandbar/design-system/colors';
import BotAvatar from './BotAvatar';
import { IMessageType } from '@commandbar/internal/middleware/types';
import Icon from 'shared/components/Icon';
import { AnimationTransition, builtinKeyframes } from 'shared/util/hooks/useDelayUnmount';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledChatMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledChatMessage';
import StyledChatContent from '@commandbar/internal/client/themesV2/components/copilot/StyledChatContent';
import StyledBotChatMessageContainer from '@commandbar/internal/client/themesV2/components/copilot/StyledBotChatMessageContainer';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';
import AgentAvatar from './AgentAvatar';
import StyledCopilotAttachmentMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledCopilotAttachmentMessage';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';

type ChatMessageProps = {
  preview: boolean;
  history?: IMessageType[];
  isFirstMessage: boolean;
  isLastMessage: boolean;
  message: IMessageType;
  sendChat: (message: string) => void;
  isChatHistoryPreview: boolean;
  isFinishedStreaming: boolean;
  onFinishStreaming: () => void;
  previewOverrides?: { useThemeV2: boolean; customAvatar?: string };
};

export const ChatMessage = React.forwardRef<HTMLDivElement, ChatMessageProps>((props, ref) => {
  const {
    message,
    history = [],
    isFirstMessage,
    isLastMessage,
    preview,
    isChatHistoryPreview,
    isFinishedStreaming,
    onFinishStreaming,
    previewOverrides,
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { helpHub, organization, flags } = !preview ? useStore() : emptyGlobalStore();

  // do not show continuations which match previously asked questions
  const previouslyAskedQuestions = isLastMessage
    ? new Set<string>(
        history
          .slice(0, history.length - 1)
          .flatMap((item) => (item.message_type === 'USER' ? [item.value.question] : [])),
      )
    : new Set<string>();

  const { theme } = useTheme();
  const themeV2 = useThemeV2Context();

  const isUserMessage = message.message_type === 'USER';
  const isAgentMessage = message.message_type === 'AGENT';

  const renderAvatar = () => {
    if (isUserMessage) {
      return <div style={{ width: '40px', height: '40px', flexShrink: 0 }} />;
    }

    if (isAgentMessage) {
      const getInitials = (name: string) => {
        const parts = name.trim().split(' ');
        if (parts.length === 1) {
          return parts[0][0].toUpperCase();
        }

        if (parts.length >= 2) {
          const initials = parts[0][0] + parts[1][0];
          return initials.toUpperCase();
        }

        return name[0];
      };

      return <AgentAvatar initials={getInitials(message.author)} />;
    }

    const customAvatar = preview
      ? previewOverrides?.customAvatar
      : helpHub.previewedCopilotSettings !== null
      ? helpHub.previewedCopilotSettings.avatar
      : organization?.copilot_avatar || organization?.copilot_avatar_v2?.src;
    if (customAvatar) {
      return (
        <Icon
          icon={customAvatar}
          size="40px"
          style={{ width: '40px', height: '40px', flexShrink: 0 }}
          useDefaultSVGColor
        />
      );
    }

    return <BotAvatar />;
  };

  return (preview && previewOverrides?.useThemeV2) || flags?.['release-themes-v2'] ? (
    isUserMessage ? (
      <AnimationTransition
        entry={isLastMessage ? { keyframes: builtinKeyframes.fadeInSlideDownExpand, durationMs: 300 } : undefined}
        isMounted={true}
        style={{
          display: 'flex',
          justifyContent: 'end',
          padding: 'var(--layout-padding)',
          ...(isFirstMessage && {
            marginTop: '4px', // this is to allow the top message timestamp to show up!
          }),
        }}
      >
        <StyledChatContent ref={ref}>
          <StyledChatMessage timestamp={message.modified}>
            <span>{message.value.question}</span>
            {message.value.attachments.length > 0 && (
              <StyledCopilotAttachmentMessage themeV2={themeV2} attachment={message.value.attachments[0]} />
            )}
          </StyledChatMessage>
        </StyledChatContent>
      </AnimationTransition>
    ) : (
      <StyledBotChatMessageContainer
        style={{
          ...(isFirstMessage && {
            marginTop: '4px', // this is to allow the top message timestamp to show up!
          }),
          ...(message.message_type === 'AI' &&
            isLastMessage && {
              paddingBottom: 'calc(var(--form-control-height)/2 + var(--form-control-focus-ring-width))',
              // this is to allow the feedback buttons on the last bot message to have room for the focus ring
            }),
        }}
      >
        {renderAvatar()}
        {message.message_type === 'AI' ? (
          <BotMessage
            preview={preview}
            isFirstMessage={isFirstMessage}
            isLastMessage={isLastMessage}
            previousContinuations={previouslyAskedQuestions}
            message={message}
            sendChat={props.sendChat}
            isChatHistoryPreview={isChatHistoryPreview}
            isFinishedStreaming={isFinishedStreaming}
            onFinishStreaming={onFinishStreaming}
            previewOverrides={previewOverrides}
          />
        ) : message.message_type === 'AGENT' ? (
          <StyledChatContent isBotMessage>
            <StyledChatMessage isBotMessage timestamp={message.modified}>
              {message.value}
            </StyledChatMessage>
          </StyledChatContent>
        ) : null}
      </StyledBotChatMessageContainer>
    )
  ) : (
    <AnimationTransition
      entry={
        isFirstMessage
          ? { keyframes: builtinKeyframes.slideUp(17), durationMs: 300 }
          : { keyframes: builtinKeyframes.fadeInSlideDown, durationMs: 300 }
      }
      isMounted={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          gap: '16px',
          backgroundColor: isUserMessage ? CB_COLORS.neutral0 : undefined,
          color: CB_COLORS.neutral800,
        }}
      >
        {renderAvatar()}
        <div
          style={{
            flexGrow: 1,
            minWidth: 0,
            paddingTop: '8px',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500,
            fontFamily: theme.helpHub.fontFamily,
            fontStyle: 'normal',
          }}
        >
          {isUserMessage ? (
            message.value.attachments.length > 0 && preview ? (
              // TODO: eventually we want to render our copilot preview using our actual styles,
              // so if a user is on themesV2,the preview should reflect that.  in the meantime
              // this is just to render these attachments in the Copilot analytics preview,
              // so should be okay if it doesn't totally match the other copilot styles
              <div ref={ref}>
                <span>{message.value.question}</span>
                <StyledCopilotAttachmentMessage themeV2={themeV2} attachment={message.value.attachments[0]} />
              </div>
            ) : (
              <div ref={ref}>{message.value.question}</div>
            )
          ) : message.message_type === 'AI' ? (
            <BotMessage
              preview={preview}
              isFirstMessage={isFirstMessage}
              isLastMessage={isLastMessage}
              previousContinuations={previouslyAskedQuestions}
              message={message}
              sendChat={props.sendChat}
              isChatHistoryPreview={isChatHistoryPreview}
              isFinishedStreaming={isFinishedStreaming}
              onFinishStreaming={onFinishStreaming}
              previewOverrides={previewOverrides}
            />
          ) : message.message_type === 'AGENT' ? (
            <div>{message.value}</div>
          ) : null}
        </div>
      </div>
    </AnimationTransition>
  );
});

export default ChatMessage;
