import { cva } from 'class-variance-authority';

const toastVariants = cva(
  [
    'h-[40px] w-fit bg-backgroundInverse rounded-button shadow-lg',
    'group pointer-events-auto relative flex items-center justify-between overflow-hidden',
    'transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
  ],
  {
    variants: {
      variant: {
        default: '',
        success: '',
        warning: '',
        danger: '',
        info: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export { toastVariants };
