import React, { useEffect } from 'react';

import type { INudgeStepContentBlockType, INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { useIsEditorOpen } from 'editor/src/hooks';
import Sender from 'editor/src/management/Sender';
import {
  findButtonBlocks,
  findMarkdownBlocks,
  findSurveyBlocks,
  isMediaBlock,
  isNudgeLive,
  isSurveyBlock,
} from '../utils';
import { AccordionItem } from './AccordionItem';
import { BaseForm } from './BaseForm';
import { CTABlock } from './CTABlock';
import { Footer } from './Footer';
import { HeaderComponent } from './Header';
import { ListBlocks } from './ListBlocks';
import { MediaBlocks } from './MediaBlocks';
import { SurveyBlocks } from './SurveyBlocks';
import { ExperienceState } from '@commandbar/internal/client/extension/shared';
import { usePreview } from 'editor/src/hooks/usePreview';
import { Nudge } from '@commandbar/internal/middleware/nudge';

export type FormType =
  | 'image'
  | 'video'
  | 'help_doc_command'
  | 'button'
  | 'survey_text'
  | 'survey_text_short'
  | 'survey_rating'
  | 'button'
  | 'survey_list';

export interface INudgeContentForm {
  onAccordionClick: () => void;
  expanded?: boolean;
  nudge: INudgeType;
  index: number;
  onStepChange: (index: number) => (step: INudgeStepType, saveChanges?: boolean) => void;
  onDelete?: (id: number, stepIndex: number) => void;
  isDirty: boolean;
}

export const NudgeContentForm = ({
  onAccordionClick,
  expanded,
  nudge,
  index,
  onStepChange: _onStepChange,
  onDelete,
  isDirty,
}: INudgeContentForm) => {
  const { commandBarReady, isStudio, flags } = useAppContext();
  const step = nudge.steps[index];
  const nudgeHasNPSBlock =
    nudge.steps.filter((step) => step.content.some((item) => item.type === 'survey_rating' && item.meta.type === 'nps'))
      .length > 0;
  const buttons = findButtonBlocks(step.content);
  const markdownBlocks = findMarkdownBlocks(step.content);
  const { onStart } = usePreview();

  const onStepChange = _onStepChange(index);

  const onBlockDelete = React.useCallback(
    (block: INudgeStepContentBlockType, deleteLogic?: boolean) => {
      if (step.has_survey_response) return;

      const updatedContent = step.content.filter(
        (item) =>
          item.type !== block?.type ||
          (item.type === 'button' && block.type === 'button' && item.meta?.button_type !== block.meta?.button_type),
      );

      if (deleteLogic) {
        onStepChange({
          ...step,
          content: updatedContent.map((item) =>
            item.type === 'button'
              ? {
                  ...item,
                  meta: {
                    ...item.meta,
                    conditional_actions: [],
                  },
                }
              : item,
          ),
        });
      } else {
        onStepChange({
          ...step,
          content: updatedContent,
        });
      }
    },
    [onStepChange, step],
  );

  const onBlockChange = React.useCallback(
    (block: INudgeStepContentBlockType, resetLogicBlocks?: boolean, deleteLogicBlocks?: boolean) => {
      if (step.has_survey_response) return;

      const newStep = {
        ...step,
        content: [...step.content],
      };

      if (isSurveyBlock(block) && !buttons?.length) {
        newStep.content.push({
          type: 'button',
          sort_key: newStep.content.length,
          meta: {
            label: 'Submit',
            action: {
              type: 'no_action',
            },
          },
        });
      }

      let sort_key = block.sort_key;
      if (sort_key === -1) {
        sort_key = newStep.content.length;
      }
      if (sort_key === undefined) {
        /** FIXME: There are a lot of old nudges with undefined sort_keys that needs to be handled
         *  once we start using sort_keys on the frontend
         */
      }

      let blockIndex = -1;
      if (isMediaBlock(block) || isSurveyBlock(block)) {
        blockIndex = newStep.content.findIndex(
          (contentBlock) => isMediaBlock(contentBlock) || isSurveyBlock(contentBlock),
        );
      } else {
        if (block.type === 'button') {
          blockIndex = newStep.content.findIndex(
            (content) => content.type === block.type && block.meta?.button_type === content.meta?.button_type,
          );
        } else {
          blockIndex = newStep.content.findIndex((content) => content.type === block.type);
        }
      }

      if (blockIndex === -1) {
        newStep.content.push({ ...block, sort_key: sort_key });
      } else {
        newStep.content[blockIndex] = { ...block, sort_key: sort_key };
      }

      if (resetLogicBlocks) {
        newStep.content = newStep.content.map((item) =>
          item.type === 'button'
            ? {
                ...item,
                meta: {
                  ...item.meta,
                  conditional_actions: item.meta?.conditional_actions?.map((action) => ({
                    ...action,
                    operator: 'eq',
                    operand: '',
                  })),
                },
              }
            : item,
        );
      }

      if (deleteLogicBlocks) {
        newStep.content = newStep.content.map((item) =>
          item.type === 'button'
            ? {
                ...item,
                meta: {
                  ...item.meta,
                  conditional_actions: [],
                },
              }
            : item,
        );
      }
      onStepChange(newStep);
    },
    [onStepChange, step, buttons?.length],
  );

  const currentFormFactor = React.useMemo(() => {
    if (index !== undefined) {
      return nudge.steps[index]?.form_factor;
    }
  }, [index, nudge.steps]);

  const isEditorOpen = useIsEditorOpen();

  useEffect(() => {
    const isEditorOpenOrStudio = isStudio || isEditorOpen;
    if (commandBarReady && expanded && isEditorOpenOrStudio) {
      Sender.closeNudgeMocks();
      Sender.showNudgeStepMock(nudge, index);
    }
  }, [currentFormFactor, nudge.id, index, expanded, commandBarReady, isEditorOpen]);

  const onStartClickRecorder = (field: ExperienceState['field'], skipPrompt = false) => {
    onStart({ type: 'element', skipPrompt, experience: { type: 'nudge', nudge, stepIndex: index, field } });
  };

  return (
    <AccordionItem
      disabled={step.has_survey_response}
      onClick={onAccordionClick}
      expanded={expanded}
      header={
        <HeaderComponent
          shouldSort={step.form_factor.type !== 'tooltip' && step.form_factor.type !== 'banner'}
          index={index}
          hasTooltip={nudge.steps.some((step) => step.form_factor.type === 'tooltip')}
          locked={step.has_survey_response}
          expanded={expanded}
          title={step.title}
          value={step.form_factor}
          onDelete={onDelete ? () => onDelete(step.id, index) : undefined}
          onUpdate={(formFactor, saveChanges) => {
            onStepChange(
              {
                ...step,
                form_factor: formFactor,
                content:
                  formFactor.type === 'banner'
                    ? step.content.filter((item) => item.type === 'markdown' || item.type === 'button')
                    : step.content,
              },
              saveChanges,
            );
          }}
          onStartClickRecorder={flags['release-preview-v-2'] ? onStartClickRecorder : undefined}
          recorderDisabled={isStudio && (Nudge.isNew(nudge) || (isNudgeLive(nudge) && isDirty))}
          hasSurveyBlock={!!findSurveyBlocks(step.content).length}
        />
      }
    >
      {markdownBlocks.map((block, i) => (
        <BaseForm
          key={`${block.sort_key}-${i}`}
          title={step.title}
          content={block?.meta.value}
          onTitleChange={(s) => onStepChange({ ...step, title: s })}
          onContentChange={(s) => {
            onBlockChange({
              type: 'markdown',
              sort_key: block.sort_key,
              meta: { ...block.meta, value: s },
            });
          }}
        />
      ))}
      <MediaBlocks step={step} onBlockChange={onBlockChange} onBlockDelete={onBlockDelete} />
      <SurveyBlocks
        step={step}
        onBlockChange={onBlockChange}
        onBlockDelete={(block) => onBlockDelete(block, true)}
        nudgeHasNPSBlock={nudgeHasNPSBlock}
      />
      <ListBlocks step={step} onBlockChange={onBlockChange} onBlockDelete={(block) => onBlockDelete(block, true)} />
      <CTABlock nudge={nudge} stepIndex={index} onBlockChange={onBlockChange} onBlockDelete={onBlockDelete} />
      <Footer
        step={step}
        onStepChange={onStepChange}
        onBlockChange={onBlockChange}
        onCtaToggle={() => {
          const buttons = findButtonBlocks(step.content);
          if (buttons.length <= 2) {
            onBlockChange({
              type: 'button',
              meta: {
                label: 'Done',
                action: {
                  type: 'no_action',
                },
                button_type:
                  buttons.find((button) => button.meta?.button_type === 'secondary') || !buttons.length
                    ? 'primary'
                    : 'secondary',
              },
              sort_key: -1,
            });
          }
        }}
      />
    </AccordionItem>
  );
};
