import React from 'react';
import { handleStyleElementClick } from './helpers';
import { SkeletonProps } from './types';

const ListViewSkeleton = ({ setActiveCategory, onMouseOutElement, onMouseOverElement }: SkeletonProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="470"
      height="334"
      viewBox="0 0 470 334"
      fill="none"
      onClick={(e) => handleStyleElementClick(e, setActiveCategory)}
      onMouseOver={onMouseOverElement}
      onMouseOut={onMouseOutElement}
    >
      <g filter="url(#filter0_d_113_2418)">
        <g clipPath="url(#clip0_113_2418)">
          <rect x="26" y="19" width="418.257" height="283.472" rx="8.71369" fill="white" />
          <rect id="bar-styles-input" x="25.5643" y="18.5643" width="419.129" height="56.639" fill="white" />
          <rect id="bar-styles-logo" x="43.4277" y="35" width="24.0036" height="24" rx="12" fill="#E7E7E9" />
          <rect
            id="bar-styles-placeholder"
            x="79.4312"
            y="38.1702"
            width="139.419"
            height="17.4274"
            rx="3.48548"
            fill="#E7E7E9"
          />
          <rect x="26.0002" y="73.8962" width="522.822" height="0.435685" fill="black" fillOpacity="0.01" />
          <rect x="25.5643" y="18.5643" width="419.129" height="56.639" stroke="#E7E7E9" strokeWidth="0.871369" />
          <rect
            id="bar-styles-searchTab"
            x="39.9419"
            y="81.7386"
            width="38.3402"
            height="20.9129"
            rx="10.4564"
            fill="#4242CB"
          />
          <rect x="50.3982" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="white" />
          <rect x="92.2241" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="134.05" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="175.876" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <rect x="217.701" y="88.7095" width="17.4274" height="6.97095" rx="0.871369" fill="#42424D" />
          <g id="bar-styles-categoryHeader">
            <rect width="418.257" height="20.9129" transform="translate(26 109.622)" fill="#EDEDEE" />
            <rect x="42.5564" y="116.593" width="69.7095" height="6.97095" rx="1.74274" fill="#CDCDD0" />
          </g>
          <g id="bar-styles-option">
            <rect width="418.257" height="34.8548" transform="translate(26 130.535)" fill="white" />
            <rect x="43.5852" y="142.892" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
            <rect x="67.8259" y="140.992" width="104.564" height="13.9419" rx="3.48548" fill="#E7E7E9" />
            <g clipPath="url(#clip1_113_2418)">
              <rect width="65" height="14" transform="translate(347.128 141.145)" fill="#E7E7E9" />
              <rect width="14" height="14" transform="translate(413.128 141.145)" fill="#E7E7E9" />
            </g>
          </g>
          <g clipPath="url(#clip2_113_2418)" id="bar-styles-optionSelected">
            <rect width="418.257" height="46.46" transform="translate(26 165.39)" fill="#F0F0FF" />
            <rect width="3.49" height="46" transform="translate(26.1284 165.855)" fill="#4242CB" />
            <rect x="43.5852" y="183.747" width="10.4564" height="10.4564" rx="5.22822" fill="#7F7FEB" />
            <rect x="67.8259" y="175.846" width="104.564" height="13.9419" rx="3.48548" fill="#DFDFE2" />
            <rect x="68.1284" y="193.788" width="29" height="8" rx="2" fill="#DFDFE2" />
            <rect x="70.1284" y="195.855" width="25" height="4" rx="0.871369" fill="#7A7A85" />
            <rect x="99.1284" y="193.855" width="219" height="8" rx="2" fill="#DFDFE2" />
            <rect
              id="bar-styles-keyboardShortcuts"
              x="395.129"
              y="181.855"
              width="13.9419"
              height="13.9419"
              rx="3.48548"
              fill="#DFDFE2"
            />
            <rect
              id="bar-styles-keyboardShortcuts"
              x="413.071"
              y="181.855"
              width="13.9419"
              height="13.9419"
              rx="3.48548"
              fill="#DFDFE2"
            />
          </g>
          <g id="bar-styles-optionDisabled">
            <rect width="418.257" height="34.8548" transform="translate(26 211.85)" fill="white" />
            <rect x="43.5852" y="224.207" width="10.4564" height="10.4564" rx="5.22822" fill="#F7F7F8" />
            <rect x="67.8259" y="222.307" width="104.564" height="13.9419" rx="3.48548" fill="#F7F7F8" />
            <rect x="412.888" y="222.307" width="13.9419" height="13.9419" rx="3.48548" fill="#F7F7F8" />
          </g>
          <g clipPath="url(#clip3_113_2418)" id="bar-styles-optionDisabledSelected">
            <rect width="418.257" height="34.8548" transform="translate(26 246.705)" fill="white" />
            <rect x="43.5852" y="259.062" width="10.4564" height="10.4564" rx="5.22822" fill="#F7F7F8" />
            <rect x="67.8259" y="257.161" width="104.564" height="13.9419" rx="3.48548" fill="#F7F7F8" />
            <rect x="412.888" y="257.161" width="13.9419" height="13.9419" rx="3.48548" fill="#F7F7F8" />
            <rect width="3.49" height="35" transform="translate(26 247)" fill="#4242CB" />
          </g>
          <rect
            id="bar-styles-footer"
            width="418.257"
            height="20.9129"
            transform="translate(26 281.56)"
            fill="#EDEDEE"
          />
          <rect id="bar-styles-feedback" x="414.888" y="286.46" width="12" height="12" rx="3.48548" fill="white" />
        </g>
        <rect
          x="25.5643"
          y="18.5643"
          width="419.129"
          height="284.344"
          rx="9.14938"
          stroke="#EDEDEE"
          strokeWidth="0.871369"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_113_2418"
          x="0.730322"
          y="0.701215"
          width="468.796"
          height="334.012"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.97095" />
          <feGaussianBlur stdDeviation="12.1992" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.06 0 0 0 0.24 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_113_2418" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_113_2418" result="shape" />
        </filter>
        <clipPath id="clip0_113_2418">
          <rect x="26" y="19" width="418.257" height="283.472" rx="8.71369" fill="white" />
        </clipPath>
        <clipPath id="clip1_113_2418">
          <rect x="347.128" y="141.145" width="80" height="14" rx="3.49" fill="white" />
        </clipPath>
        <clipPath id="clip2_113_2418">
          <rect width="418.257" height="46.46" fill="white" transform="translate(26 165.39)" />
        </clipPath>
        <clipPath id="clip3_113_2418">
          <rect width="418.257" height="34.8548" fill="white" transform="translate(26 246.705)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListViewSkeleton;
