import { useEffect, useState } from 'react';
import { ColumnTitle, LoadingState } from '../shared/styles';
import { Check, HelpCircle, MessageCheckSquare } from '@commandbar/design-system/icons/react';
import ContentTable from '../shared/ContentTable';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import AnswerEditDrawer, { getAnswerString } from './AnswerEditDrawer';
import { IAIMessageType, IChatType, IEditorCommandType, IUserMessageType } from '@commandbar/internal/middleware/types';
import { Chat } from '@commandbar/internal/middleware/chat';
import { useHistory } from 'react-router';
import { useAppContext } from 'editor/src/AppStateContext';
import { generateCopilotChat } from '../../analytics/dashboards/copilot/chatDemoData';
import { URL_CHAT_ID_PARAM } from '../../analytics/dashboards/copilot/state';

interface AnswersProps {
  showAddContent: boolean;
  setShowAddContent: (show: boolean) => void;
}

const Answers = ({ showAddContent, setShowAddContent }: AnswersProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { organizationId } = useIntegrationsContext();
  const [activeAnswer, setActiveAnswer] = useState<IEditorCommandType>();
  const [prefilledValues, setPrefilledValues] = useState<{ question: string; answer: string; chat: IChatType }>();
  const history = useHistory();

  const { answers } = useAppContext();

  const createAnswerFromChat = async (chatId: string) => {
    try {
      if (!organizationId) {
        return;
      }

      const chat = await Chat.get(chatId);

      const firstUserMessage: IUserMessageType = chat.messages.find(
        (message) => message.message_type === 'USER',
      ) as IUserMessageType;

      const firstAiMessage: IAIMessageType = chat.messages.find(
        (message) => message.message_type === 'AI',
      ) as IAIMessageType;

      setPrefilledValues({
        question: firstUserMessage.value.question,
        answer: firstAiMessage.value.answer,
        chat: chat,
      });
      setShowAddContent(true);

      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete(URL_CHAT_ID_PARAM);
      history.replace(`${history.location.pathname}?${searchParams.toString()}`);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(history.location.search);
      const chatId = searchParams.get(URL_CHAT_ID_PARAM);
      const commandId = searchParams.get('id');
      const question = searchParams.get('question');

      if (commandId && organizationId && answers.length > 0) {
        const answer = answers.find((a) => `${a.id}` === commandId);
        if (answer) {
          setShowAddContent(true);
          setActiveAnswer(answer);
        }
      } else if (!!question) {
        setPrefilledValues({
          question: decodeURIComponent(question) || '',
          answer: '',
          chat: generateCopilotChat(chatId, decodeURIComponent(question), '') as unknown as IChatType,
        });
        setShowAddContent(true);
      } else if (chatId) {
        createAnswerFromChat(chatId);
      }
    };
    fetchData();
  }, [history.location.search, answers.length]);

  const CTAStatus = (props: { hasCTA: boolean }) => {
    return props.hasCTA ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Check />
      </div>
    ) : (
      <></>
    );
  };

  const columns = [
    {
      title: (
        <ColumnTitle>
          Question
          <HelpCircle />
        </ColumnTitle>
      ),
      dataIndex: 'question',
      key: 'question',
      ellipsis: true,
    },
    {
      title: (
        <ColumnTitle>
          Answer
          <MessageCheckSquare />
        </ColumnTitle>
      ),
      dataIndex: 'answer',
      key: 'answer',
      ellipsis: true,
    },
    {
      title: 'CTA',
      dataIndex: 'cta',
      key: 'cta',
      width: '62px',
    },
  ];

  if (isLoading) return <LoadingState />;

  return (
    <>
      <ContentTable
        columns={columns}
        data={answers}
        dataMappingFunction={(data: any[]) =>
          data.map((a) => {
            return {
              ...a,
              question: <span>{a.text === '__untitled__' ? '' : a.text}</span>,
              answer: <span>{getAnswerString(a.content)}</span>,
              cta: (
                <CTAStatus
                  hasCTA={
                    a.next_steps.length > 0 && typeof a.next_steps[0] === 'object' && a.next_steps[0].cta.length > 0
                  }
                />
              ),
            };
          })
        }
        emptyText="You don't have any answers yet. Add one by clicking the 'Add Answer' button above."
        setIsLoading={setIsLoading}
        openEditDrawer={(id: number) => {
          setActiveAnswer(answers.find((answer) => answer.id === id));
          setShowAddContent(true);
        }}
      />
      <AnswerEditDrawer
        organizationId={organizationId}
        open={showAddContent}
        onClose={() => {
          setShowAddContent(false);
          setPrefilledValues(undefined);
          setActiveAnswer(undefined);

          const searchParams = new URLSearchParams(history.location.search);
          searchParams.delete('id');
          history.replace(`${history.location.pathname}?${searchParams.toString()}`);
        }}
        activeAnswer={activeAnswer}
        prefilledValues={prefilledValues}
      />
    </>
  );
};

export default Answers;
