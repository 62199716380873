import React from 'react';
import { CmdDropdown } from '@commandbar/design-system/cmd';

// Define a generic type for option values
type Option<T> = {
  label: string;
  value: T;
};

// Updated interface using a generic type parameter
interface FilterSelectProps<T> {
  selectedOption: T;
  setSelectedOption: (value: T) => void;
  options?: Option<T>[];
  selectLabel?: string;
}

// Default options as an example, still using StatusType for this specific instance
export type StatusType = 'all' | 'published' | 'unpublished';
const statusOptions: Option<StatusType>[] = [
  { label: 'All', value: 'all' },
  { label: 'Published', value: 'published' },
  { label: 'Unpublished', value: 'unpublished' },
];

const FilterSelect = <T extends string>({
  selectedOption,
  setSelectedOption,
  options = statusOptions as Option<T>[],
  selectLabel = 'Status',
}: FilterSelectProps<T>) => {
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.SelectTrigger>
        <span style={{ fontWeight: 'bold' }}>{selectLabel}: </span>
        {options.find((o) => o.value === selectedOption)?.label}
      </CmdDropdown.SelectTrigger>
      <CmdDropdown.Content>
        <CmdDropdown.RadioGroup value={selectedOption} onValueChange={(v) => setSelectedOption(v as T)}>
          {options.map((option) => (
            <CmdDropdown.RadioItem key={option.value} value={option.value}>
              {option.label}
            </CmdDropdown.RadioItem>
          ))}
        </CmdDropdown.RadioGroup>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

export default FilterSelect;
