import React from 'react';
import { Form } from '@commandbar/design-system/components/antd';
import { CmdButton, CmdInput, CmdTypography } from '@commandbar/design-system/cmd';
import { ArrowUpRight, Globe02 } from '@commandbar/design-system/icons/react';
import { PreviewModal } from './PreviewModal';
import { isValidURL } from '@commandbar/internal/util/location';

type Props = {
  displayType?: 'preview' | 'element';
  initialPreviewURL?: string;
  onFinish: (previewUrl: string) => void;
  onCancel: () => void;
  open: boolean;
};
export const PreviewURLModal: React.FC<Props> = ({ open, displayType, onFinish, initialPreviewURL, onCancel }) => {
  const [form] = Form.useForm<{ previewUrl: string }>();

  const displayConfig = {
    preview: {
      title: 'Preview experience',
      description: 'Enter the URL where you want to start the preview',
      actionTitle: 'Preview',
    },
    element: {
      title: 'Select an element',
      description: 'Enter the URL where the element appears in your app',
      actionTitle: 'Select element',
    },
  };

  if (!displayType) {
    return null;
  }

  const display = displayConfig[displayType];
  return (
    <PreviewModal maxWidth={400} open={open} onCancel={onCancel}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ previewUrl: initialPreviewURL }}
        onFinish={(values) => {
          onFinish(values.previewUrl);
        }}
        autoComplete="off"
      >
        <CmdTypography variant="primary">{display.title}</CmdTypography>
        <CmdTypography variant="secondary">{display.description}</CmdTypography>
        <Form.Item
          name="previewUrl"
          rules={[
            { required: true, message: 'Preview URL is required' },
            {
              validator: (_, value) => {
                if (value && !isValidURL(value, true)) {
                  return Promise.reject('Invalid Preview URL');
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginTop: 16 }}
        >
          <CmdInput autoFocus prefixElement={<Globe02 />} fullWidth placeholder="app.commandbar.com" />
        </Form.Item>

        <div style={{ justifyContent: 'flex-end', display: 'flex', gap: 4 }}>
          <CmdButton name="cancel" variant="link" onClick={onCancel}>
            Nevermind
          </CmdButton>
          <CmdButton name="preview" variant="primary" type="submit">
            {display.actionTitle}
            <ArrowUpRight />
          </CmdButton>
        </div>
      </Form>
    </PreviewModal>
  );
};
