import { useEffect, useState } from 'react';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import {
  AnalyticsDeadends,
  AnalyticsDeadendsData,
  ResultTypeFilter,
} from '@commandbar/internal/middleware/analytics/deadends';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import { useCSVDownload } from '../../useCSVDownload';
import { getDeadendsDemoData } from '../../analyticDemoData';
import { PAGE_SIZE } from '../copilot/state';

const useDeadends = ({
  widgetFilter,
  typeFilter,
  searchFilter,
}: {
  widgetFilter: string;
  typeFilter: ResultTypeFilter;
  searchFilter: string;
}) => {
  const [data, setData] = useState<AnalyticsDeadendsData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [page, setPage] = useState<number>(0);

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'deadends',
    download: () =>
      AnalyticsDeadends.download({
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        widget: widgetFilter,
        result_type: typeFilter,
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchDeadendsData = () => {
    setIsLoading(true);
    setError('');
    AnalyticsDeadends.read({
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      widget: widgetFilter,
      result_type: typeFilter,
      include_admin_data: includeAdminData.toString(),
      page: page.toString(),
      page_size: PAGE_SIZE.toString(),
      search_filter: searchFilter,
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setPage(0);
  }, [timeFilterRange, typeFilter, widgetFilter, includeAdminData]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchDeadendsData, 250);

    if (isDemoData) {
      setData(getDeadendsDemoData());
      setIsLoading(false);
      return;
    }

    if (typeFilter && widgetFilter) {
      debouncedFetch();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [timeFilterRange, isDemoData, typeFilter, widgetFilter, includeAdminData, page, searchFilter]);

  return {
    data,
    isLoading,
    error,
    fetchDeadendsData,
    isDemoData,
    downloadCsv,
    isDownloading,
    page,
    setPage,
  };
};

export default useDeadends;
