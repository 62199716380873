import React, { useMemo, useRef } from 'react';
import { BarStackHorizontal } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Loading } from './loading';
import useResizeObserver from './useResizeObserver';
import { NpsChart } from './utils';

export type CmdHorizontalBarChartProps = {
  data?: NpsChart[];
  isLoading?: boolean;
};

const CmdHorizontalBar = ({ data = [], isLoading }: CmdHorizontalBarChartProps) => {
  const resizeRef = useRef<HTMLDivElement>(null);
  const { width: parentWidth } = useResizeObserver<HTMLDivElement>(resizeRef, [isLoading, data]);

  const xMax = parentWidth - 10;
  const yMax = 20;

  const keys = useMemo(() => data.map((d) => d.key), [data]);
  const yScale = useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: ['npsBar'],
      }),
    [yMax],
  );

  const totalValue = useMemo(() => data.reduce((sum, item) => sum + item.metric, 0), [data]);
  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, totalValue],
      }),
    [xMax, totalValue],
  );

  const colorScale = scaleOrdinal({
    domain: keys,
    range: ['#F87171', '#FACC15', '#59A212'],
  });

  const getNps = (d: any) => d.key;

  if (isLoading || !data.length) {
    return <Loading />;
  }

  return (
    <div ref={resizeRef} style={{ width: '100%', height: '100%' }}>
      <svg width={parentWidth} height={yMax}>
        <Group left={0} top={0}>
          <BarStackHorizontal
            data={[{ id: 'npsBar', ...data.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.metric }), {}) }]}
            keys={keys}
            height={yMax}
            y={getNps}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack, index) =>
                barStack.bars.map((bar) => {
                  const isFirstBar = index === 0;
                  const isLastBar = index === barStacks.length - 1;
                  const borderRadius = 6;
                  const indexMultiplier = index * 3;
                  const radiusTopLeft = isFirstBar ? borderRadius : 0;
                  const radiusBottomLeft = isFirstBar ? borderRadius : 0;
                  const radiusTopRight = isLastBar ? borderRadius : 0;
                  const radiusBottomRight = isLastBar ? borderRadius : 0;

                  const pathD = `
                M${bar.x + indexMultiplier},${bar.y + radiusTopLeft} 
                q0,-${radiusTopLeft} ${radiusTopLeft},-${radiusTopLeft}
                h${bar.width - radiusTopRight - radiusTopLeft}
                q${radiusTopRight},0 ${radiusTopRight},${radiusTopRight}
                v${bar.height - radiusTopRight - radiusBottomRight}
                q0,${radiusBottomRight} -${radiusBottomRight},${radiusBottomRight}
                h-${bar.width - radiusBottomRight - radiusBottomLeft}
                q-${radiusBottomLeft},0 -${radiusBottomLeft},-${radiusBottomLeft}
                Z`;

                  return (
                    <g key={`bar-stack-horizontal-${barStack.index}-${bar.index}`}>
                      {isFirstBar || isLastBar ? (
                        <path d={pathD} fill={bar.color} />
                      ) : (
                        <rect
                          x={bar.x + indexMultiplier}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                        />
                      )}
                    </g>
                  );
                }),
              )
            }
          </BarStackHorizontal>
        </Group>
      </svg>
    </div>
  );
};

export default CmdHorizontalBar;
