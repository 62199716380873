/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React, { useEffect, useState } from 'react';
/*******************************************************************************/

import { IContextType, IUserContext } from '@commandbar/internal/middleware/types';
import { Collapse, Radio } from '@commandbar/design-system/components/antd';
import { Context } from '@commandbar/internal/middleware/context';
import ContextPanel from './ContextPanel';
import { getCommandsByContextKey, ICommandsByContextKey } from '../../context/helpers';
import Sender from '../../../management/Sender';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as Organization from '@commandbar/internal/middleware/organization';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { useAppContext } from 'editor/src/AppStateContext';
import { ContextErrorAlert, parseContextForError } from './ContextError';
import { Plus } from '@commandbar/design-system/icons/react';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { reportErrorToUser } from '../../utils/ErrorReporting';

const ContextList = () => {
  const { context: windowContext, reload: reloadWindowContext } = useWindowInfo();
  const { organization, commands } = useAppContext();

  const [contexts, setContexts] = useState<IContextType[]>([]);
  const [activeContextID, setActiveContextID] = useState<number>();

  const [newObjCounter, setNewObjCounter] = React.useState(-1);

  const setCommandBarContext = (context: IUserContext | string | any[], meta?: any) => {
    Sender.setContext(context, meta);
  };

  useEffect(() => {
    if (!organization) {
      return;
    }

    const setContext = (loadedContexts: IContextType[]) => {
      const cachedContext = LocalStorage.get('customcontext', '') as string;

      if (!cachedContext) {
        return setActiveContextID(0);
      }

      const contextToSet = loadedContexts.find((item: IContextType) => {
        return item.id === JSON.parse(cachedContext).id;
      });

      if (!contextToSet || !contextToSet.value) {
        return setActiveContextID(0);
      }

      setActiveContextID(contextToSet.id);
      setCommandBarContext(contextToSet.value, {
        useCustom: true,
        customID: cachedContext,
      });
    };

    const loadContexts = async (id: string) => {
      const c = await Organization.listContexts(id);
      setContexts(c);
      setContext(c);
    };

    loadContexts(organization.id.toString());
  }, [organization]);

  const windowContextError = React.useMemo(() => parseContextForError(windowContext), [windowContext]);

  // *************************************************************************************
  // *************************** CommandBar widget interface *********************************
  // *************************************************************************************
  const onChangeActiveContext = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setActiveContextID(e.target.value);

    if (e.target.value === 0) {
      LocalStorage.remove('customcontext');

      if (typeof windowContext === 'object') {
        setCommandBarContext(windowContext);
      }

      return;
    }

    const contextToSet = contexts.find((item: IContextType) => item.id === e.target.value);

    if (!contextToSet || !contextToSet.value) {
      return;
    }

    const customID = JSON.stringify({ id: contextToSet.id, name: contextToSet.name });
    const meta = {
      useCustom: true,
      customID,
    };

    LocalStorage.set('customcontext', customID);

    setCommandBarContext(contextToSet.value, meta);
  };

  // *************************************************************************************
  // *************************** Context Item operations *********************************
  // *************************************************************************************
  const addItem = () => {
    setContexts((prevState: IContextType[]) => [
      ...prevState,
      { id: newObjCounter, name: 'New metadata', value: {}, organization: organization.id.toString() },
    ]);
    setActiveContextID(newObjCounter);
    setNewObjCounter((prevState) => prevState - 1);
  };

  const deleteItem = async (id: number) => {
    if (id > 0) {
      await Context.delete(id, undefined, () => cmdToast.success('Metadata deleted'), reportErrorToUser);
    }

    if (id === activeContextID) {
      setActiveContextID(0);
    }

    // filter out deleted item from context
    setContexts((prevState: IContextType[]) => prevState.filter((item) => item.id !== id));
  };

  const saveItem = async (obj: IContextType) => {
    let newObj: IContextType;
    if (obj.id <= 0) {
      newObj = await Context.create(obj, () => cmdToast.success('Metadata created'), reportErrorToUser);
    } else {
      newObj = await Context.update(obj, () => cmdToast.success('Metadata updated'), reportErrorToUser);
    }
    // replace the object in state
    setContexts((prevState: IContextType[]) => prevState.map((item) => (item.id === obj.id ? newObj : item)));

    // update state in command bar if context is active
    if (obj.id === activeContextID) {
      const customID = LocalStorage.get('customcontext', false);
      const meta = !!customID
        ? {
            useCustom: true,
            customID,
          }
        : undefined;
      setCommandBarContext(obj.value, meta);
    }
  };

  const commandsByContextKey: ICommandsByContextKey = getCommandsByContextKey(commands);

  // ************************** Render **************************
  // panel for the original window context

  return (
    <div id="contexts">
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CmdButton variant="primary" icon={<Plus />} onClick={addItem} id="add-new-context">
          Create test metadata
        </CmdButton>
      </div>
      <br />
      <Radio.Group onChange={onChangeActiveContext} value={activeContextID} style={{ width: '100%' }}>
        <Collapse
          expandIconPosition="end"
          defaultActiveKey={activeContextID ? [activeContextID] : ['current-context']}
          style={{ border: '0px solid transparent' }}
          ghost
        >
          <ContextErrorAlert error={windowContextError} />
          {windowContext !== undefined && !windowContextError && (
            <ContextPanel
              key={'current-context'}
              item={{
                id: 0,
                name: 'Current window metadata (read only)',
                value: windowContext || {},
                organization: organization.id.toString(),
              }}
              deleteItem={deleteItem}
              saveItem={saveItem}
              commandsByContextKey={commandsByContextKey}
              isWindowContext={true}
              reloadWindowContext={reloadWindowContext}
            />
          )}
          {contexts.map((context) => (
            <ContextPanel
              key={context.id}
              item={context}
              deleteItem={deleteItem}
              saveItem={saveItem}
              commandsByContextKey={commandsByContextKey}
              isWindowContext={false}
            />
          ))}
        </Collapse>
      </Radio.Group>
    </div>
  );
};

export default ContextList;
