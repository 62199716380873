/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React from 'react';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';

import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import markup from 'react-syntax-highlighter/dist/esm/languages/prism/markup';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import typescript from 'react-syntax-highlighter/dist/esm/languages/prism/typescript';

import '../../../style/code.css';
import { Card } from '@commandbar/design-system/components/antd';
import theme from './theme';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';

interface IProps {
  content: string;
  title?: string;
  id?: string;
  wrap?: boolean;
  language?: string;
  inline?: boolean;
}

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('html', markup);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('typescript', typescript);

const COPY_SUCCESS_MESSAGE = 'Code copied to clipboard.';
const COPY_ERROR_MESSAGE = 'Failed to copy code to clipboard.';

export const Code = (props: IProps) => {
  const fallbackCopyToClipboard = () => {
    const element = document.createElement('textarea');
    element.value = props.content;
    document.body.appendChild(element);
    element.select();

    try {
      document.execCommand('copy');
      cmdToast.info(COPY_SUCCESS_MESSAGE);
    } catch (err) {
      cmdToast.error(COPY_ERROR_MESSAGE);
    }
    document.body.removeChild(element);
  };

  const copyCodeToClipboard = () => {
    if (!props.content) {
      return;
    }
    if (!navigator.clipboard) {
      fallbackCopyToClipboard();
      return;
    }
    navigator.clipboard.writeText(props.content).then(
      () => {
        cmdToast.info(COPY_SUCCESS_MESSAGE);
      },
      () => {
        cmdToast.error(COPY_ERROR_MESSAGE);
      },
    );
  };

  let language = props.language;
  if (!language) {
    if (props.content.includes('<script')) {
      language = 'html';
    } else {
      language = 'javascript';
    }
  }

  if (props.inline) {
    return (
      <code
        style={{
          display: 'inline-block',
          fontSize: '85%',
          fontFamily: 'Source Code Pro, Menlo, monospace',
          backgroundColor: 'rgb(245, 247, 249)',
          margin: '0px 1px',
          padding: '3px 6px',
          borderRadius: '3px',
          lineHeight: '1.625',
        }}
      >
        {props.content}
      </code>
    );
  }

  return (
    <Card id={props.id} style={{ border: '0px', marginBottom: '24px' }} bodyStyle={{ padding: 0, borderRadius: 4 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 14,
          backgroundColor: '#070D1D',
          padding: '12px 20px 10px 20px',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <div style={{ color: '#fff' }}>{props.title}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 14, color: '#8895AC', textTransform: 'capitalize' }}>{language}</div>
          <CmdButton onClick={copyCodeToClipboard}>Copy</CmdButton>
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#070D1D',
          borderRadius: '0px 0px 8px 8px',
        }}
      >
        <div
          style={{
            padding: '8px 0px',
            overflow: 'auto',
          }}
        >
          <SyntaxHighlighter
            language={language}
            useInlineStyles={true}
            style={theme}
            wrapLines={true}
            showLineNumbers={true}
            lineNumberProps={{
              style: { marginRight: 10, opacity: 0.4, marginLeft: 15 },
            }}
          >
            {props.content}
          </SyntaxHighlighter>
        </div>
      </div>
    </Card>
  );
};
