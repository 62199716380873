import { Fragment, useEffect, useState } from 'react';
import { Form, Select, Alert, Spin, Result, Input } from '@commandbar/design-system/components/antd';
import { useSegmentConfigContext } from './SegmentConfigProvider';
import { ISegmentConfig } from './utils';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { CmdButton } from '@commandbar/design-system/cmd';

const { Item: FormItem, useForm } = Form;

interface SegmentConfigurationStepProps {
  handleNext: Function;
  handlePrev: Function;
}

export const SegmentConfigurationStep = ({ handleNext }: SegmentConfigurationStepProps) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [cachedCredentials, setCachedCredentials] = useState<any>(null);
  const { segmentConfig, setSegmentConfig } = useSegmentConfigContext();

  const [form] = useForm();

  const { reportEvent } = useReportEvent();

  const onFinish = async (values: any) => {
    if (!values.writeKey) {
      setError('This does not look like a valid write key.');
      return;
    }

    const { integrations } = await Organization.readInternal(segmentConfig.orgID);

    await Organization.updateInternal({
      id: segmentConfig.orgID,
      integrations: {
        ...integrations,
        segment: {
          ...values,
        },
      },
    });
    setSegmentConfig((config: any) => ({
      ...config,
      ...values,
    }));
    handleNext();
  };

  useEffect(() => {
    Organization.readInternal(segmentConfig.orgID).then(async ({ integrations }) => {
      const { segment } = integrations;

      if (segment && segment.writeKey) {
        const { writeKey, endpoint } = segment;

        setCachedCredentials({
          writeKey,
          endpoint,
        });
      }

      setLoading(false);
    });
  }, []);

  const handleUseCachedCredentials = () => {
    setSegmentConfig((config: ISegmentConfig) => ({
      ...config,
      ...cachedCredentials,
    }));
    handleNext();
    return;
  };

  const removeConfiguration = async () => {
    const { segment, ...otherIntegrations } = await (await Organization.readInternal(segmentConfig.orgID)).integrations;

    await Organization.updateInternal({
      id: segmentConfig.orgID,
      integrations: otherIntegrations,
    });
    setSegmentConfig({ orgID: segmentConfig.orgID, writeKey: '', endpoint: 'https://api.segment.io' });
    setCachedCredentials(null);
  };

  return (
    <div>
      <Spin spinning={loading} tip="Checking for previous configuration...">
        {cachedCredentials ? (
          <Result
            status="success"
            title="You have set up Command AI as a Segment source"
            subTitle="Repeat setup with existing configuration?"
            extra={[
              <CmdButton
                variant="primary"
                key="existing"
                onClick={() => {
                  handleUseCachedCredentials();
                  reportEvent('segment integration interacted with', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: 'Use Existing Credentials',
                  });
                }}
              >
                Repeat setup with existing configuration
              </CmdButton>,
              <CmdButton
                variant="destructive"
                key="remove"
                onClick={() => {
                  removeConfiguration();
                  reportEvent('segment integration interacted with', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: 'Reset Credentials',
                  });
                }}
              >
                Remove existing integration
              </CmdButton>,
            ]}
          />
        ) : (
          <Fragment>
            <p>
              Start by creating a new Source for CommandBar in your Segment account. Once CommandBar is added as a
              source in Segment, you will be able to copy a write key for that source.
            </p>
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{
                writeKey: segmentConfig?.writeKey,
                endpoint: segmentConfig?.endpoint,
              }}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              labelAlign="left"
            >
              {error && (
                <Alert
                  style={{ margin: '20px 0' }}
                  type="error"
                  message={<span style={{ color: 'black' }}>{error}</span>}
                />
              )}
              <FormItem
                name="writeKey"
                label="Write Key"
                rules={[{ required: true }]}
                help={
                  <span>
                    Please provide a write key for the new source. Click{' '}
                    <a href="https://segment.com/docs/connections/find-writekey/" target="_blank" rel="noreferrer">
                      here
                    </a>{' '}
                    to learn more.
                  </span>
                }
              >
                <Input />
              </FormItem>

              <FormItem name="endpoint" label="Region Endpoint" rules={[{ required: false }]}>
                <Select style={{ width: '100%' }}>
                  <Select.Option key="us" value="https://api.segment.io">
                    Default (US Oregon): <code>https://api.segment.io</code>
                  </Select.Option>
                  <Select.Option key="eu" value="https://events.eu1.segmentapis.com">
                    EU Dublin: <code>https://events.eu1.segmentapis.com</code>
                  </Select.Option>
                </Select>
              </FormItem>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                <CmdButton variant="primary" type="submit">
                  Next
                </CmdButton>
              </div>
            </Form>
          </Fragment>
        )}
      </Spin>
    </div>
  );
};
