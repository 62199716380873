import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useConditionEditor } from '../../state/useConditionEditor';
import type { OSCondition as OSConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { PlatformType } from '@commandbar/internal/util/operatingSystem';
import { ConditionProps } from '../../types';

const options: { label: string; value: PlatformType }[] = [
  { label: 'Android', value: 'android' },
  { label: 'iOS', value: 'ios' },
  { label: 'Linux', value: 'linux' },
  { label: 'Mac OS', value: 'mac' },
  { label: 'Windows', value: 'windows' },
];

export const OSCondition: React.FC<ConditionProps<OSConditionType>> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  return (
    <Row style={{ gap: '4px' }} align="middle" wrap={false}>
      <ConditionTypeSelect />
      <OperatorSelect />
      <Col flex="1 3 auto">
        <Select
          disabled={disabled}
          value={condition.values}
          options={options}
          placeholder="value"
          style={{ width: '100%' }}
          onBlur={() => setHasBlurred(true)}
          onChange={(values) => {
            onConditionChange({
              ...condition,
              values,
            });
          }}
          filterOption={(input, option) =>
            !!option?.value?.toLowerCase().includes(input.toLowerCase()) ||
            !!option?.label?.toLowerCase().includes(input.toLowerCase())
          }
          size="small"
          mode="multiple"
        />
      </Col>
    </Row>
  );
};
