import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useEffect, useState } from 'react';

/**
 * TODO: Replace this hook with an actual way to store/persist this data
 *
 * Temporary hook to read/store the event in localstorage and use on nudge analytics dashboard
 */
export const useGoalEventName = (
  dashboard: 'tours' | 'announcements' | 'surveys',
  id: string,
): [string | undefined, (event?: string) => void] => {
  const GOAL_LOCAL_STORAGE_KEY = `${dashboard}-nudge-${id}-goal-event`;
  const [goalEventName, setGoalEventName] = useState<string | undefined>(
    () => LocalStorage.get(GOAL_LOCAL_STORAGE_KEY, '') as string,
  );

  useEffect(() => {
    if (goalEventName) {
      LocalStorage.set(GOAL_LOCAL_STORAGE_KEY, goalEventName);
    } else {
      LocalStorage.remove(GOAL_LOCAL_STORAGE_KEY);
    }
  }, [goalEventName]);

  return [goalEventName, setGoalEventName];
};
