import * as t from 'io-ts';

import { CardDataV, TableDataV } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsStrikesDataV = t.type({
  strike_rate: CardDataV,
  heatmap: TableDataV,
  table: TableDataV,
});

export type AnalyticsStrikesData = t.TypeOf<typeof AnalyticsStrikesDataV>;

type StrikesParams = {
  start_date: string;
  end_date: string;
  include_admin_data: string;
  threshold?: string;
  show_unpublished?: string;
};

export class AnalyticsStrikes {
  public static read = (params: StrikesParams) => read(AnalyticsStrikesDataV, `analytics/v2/strikes`)(params);
  public static download = async (params: StrikesParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/strikes/export/${queryString}`);
  };
}
