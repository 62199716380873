import { Alert, Input, Form } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const Readme: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.README,
  name: 'Readme',
  slug: 'readme',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/readme.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/readme-icon.svg',
  isDisabled: false,
  useDryRun: true,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Readme help center docs, you'll have to first find your Readme API Key."}
    />
  ),
  route: '/readme',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <Form.Item
        key={editingSource?.type + 'api_key'}
        validateTrigger="onSubmit"
        initialValue={editingSource?.meta?.api_key}
        required={true}
        label={'API Key'}
        name={'api_key'}
        rules={[
          { required: true, message: 'This is a required field.' },
          {
            message: `Please enter your API Key. `,
          },
        ]}
        style={{ flex: 5 }}
      >
        <Input.Password
          iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
          style={{ width: '100%' }}
          autoComplete="new-password"
        />
      </Form.Item>
    );
  },

  getPayloadMeta(data) {
    return {
      api_key: data['api_key'],
    };
  },
  requiredFields: ['api_key'],
  fetchedFields: [],
};

export default Readme;
