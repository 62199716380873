import { useEffect, useState } from 'react';
import { Device, mediaQueries } from './mediaQueries';

const useMediaQuery = (device: Device): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = mediaQueries[device];
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const onResize = () => setMatches(media.matches);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [matches, device]);

  return matches;
};

export default useMediaQuery;
