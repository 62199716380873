import * as t from 'io-ts';
import { DetailPreviewObjectV } from './detailPreview';
import { OptionGroupRenderAsV } from './helpers/optionGroup';

export const SearchFieldConfigV = t.type({
  key: t.string,
  label: t.string,
});

// Whether users should be allowed to search a resource, and
//   what label that search should be given
export const RecordSettingsV = t.partial({
  name: t.string,
  search: t.boolean,
  label_field: t.string,
  useCategory: t.boolean,
  search_fields: t.array(t.union([t.string, SearchFieldConfigV])),
  auto_execute: t.boolean,
  unfurl: t.boolean,
  description_field: t.string,
  icon: t.string,
  icon_color: t.string,
  image_color: t.string,
  image: t.string,
  sort_key: t.number,
  max_options_count: t.union([t.number, t.null]),
  sortFunction: t.any,
  onInputChangeOptions: t.partial({
    applySort: t.boolean,
  }),
  default_command_id: t.union([t.number, t.string, t.undefined]),

  render_as: OptionGroupRenderAsV,

  showResources: t.boolean,
  show_with_no_results: t.boolean,
  search_tab_enabled: t.boolean,
  search_tab_name: t.union([t.string, t.null]),
  slash_filter_enabled: t.boolean,
  slash_filter_keyword: t.union([t.string, t.null]),
  search_tab_instruction: t.union([t.string, t.null]),
  setting_pin_to_bottom: t.boolean,
  track_recents: t.boolean,

  detail: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
  content: t.union([t.null, t.string, DetailPreviewObjectV, t.array(t.union([t.string, DetailPreviewObjectV]))]),
  show_preview: t.boolean,
});

export const RecordSettingsByContextKeyV = t.record(t.string, RecordSettingsV);
