type FlushOptions = {
  useSendBeacon?: boolean;
};

export class EventQueue<E> {
  _queue: E[];
  maxQueueSize: number;

  handleEvents: (events: E[], options: FlushOptions) => void;

  constructor(handleEvents: (events: E[], options: FlushOptions) => void, maxQueueSize = 5) {
    this._queue = [];

    this.maxQueueSize = maxQueueSize;
    this.handleEvents = handleEvents;
  }

  enqueue(event: E, forceFlush?: boolean): void {
    this._queue.push(event);

    if (forceFlush || this._queue.length >= this.maxQueueSize) {
      this.flush();
    }
  }

  flush(options?: FlushOptions): void {
    const events = this._queue.splice(0, this._queue.length);

    if (events.length === 0) {
      return;
    }

    this.handleEvents(events, options || {});
  }

  destroy(): void {
    this.flush();
  }
}
