import React, { type FC } from 'react';
import type { ActorRefFrom } from 'xstate';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import StyledNudgeActionBar from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeActionBar';
import StyledCTAButton from '@commandbar/internal/client/themesV2/components/shared/StyledCTAButton';
import type {
  INudgeStepContentButtonBlockType,
  INudgeStepType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import type NudgeMachine from '../store/nudgeMachine';
import { CTAWithTooltip } from './ContentContainer';
import type { RenderMode } from './RenderNudge';
import { useExecNudgeAction } from './hooks';

interface ActionsProps {
  step: INudgeStepType;
  stepIndex: number;
  renderMode: RenderMode;
  snooze: {
    enabled: boolean;
    label: INudgeType['snooze_label'];
    duration: INudgeType['snooze_duration'];
    enabledOnAllSteps: INudgeType['snoozable_on_all_steps'];
  };
  service?: ActorRefFrom<typeof NudgeMachine>;
  stepCount?: string;
}

export const Actions: FC<ActionsProps> = ({ step, stepIndex, renderMode, snooze, service, stepCount }) => {
  const themeV2 = useThemeV2Context();
  const execNudgeAction = useExecNudgeAction({
    stepId: step.id,
    renderMode,
    service,
  });

  const actions = step.content
    .filter((block): block is INudgeStepContentButtonBlockType => block.type === 'button')
    .sort((_a, b) => (b.type === 'button' && b.meta?.button_type === 'secondary' ? 1 : -1));

  const snoozeAction: Array<INudgeStepContentButtonBlockType> =
    snooze.enabled && (snooze.enabledOnAllSteps || stepIndex === 0)
      ? [
          {
            type: 'button',
            sort_key: -1,
            meta: {
              button_type: 'snooze',
              label: snooze.label,
              action: {
                type: 'snooze',
                ...snooze.duration,
              },
            },
          },
        ]
      : [];

  const actionsWithSnooze = snoozeAction.concat(actions);
  const { isMobileDevice, mobileStyles } = useMobileExperience();

  return (
    <StyledNudgeActionBar
      isBanner={step.form_factor.type === 'banner'}
      stepCount={stepCount}
      style={{
        flexWrap: step.form_factor.layout === 'horizontal' ? 'wrap' : undefined,
        ...(isMobileDevice && step.form_factor.type === 'banner' ? mobileStyles.nudges.banner.actionBar : {}),
      }}
    >
      {actionsWithSnooze
        .filter((block) => block.meta?.label)
        .map((block, idx) => (
          <CTAWithTooltip
            key={idx}
            id={idx}
            block={block}
            step={step}
            style={{
              top: 0,
              height: 'auto',
              border: 'none',
              flex: '1 1 0',
              display: 'flex',
              ...(stepCount ? { width: 'auto' } : { width: '100%' }),
            }}
          >
            {(action, isDisabled, disabledStyles) => (
              <StyledCTAButton
                themeV2={themeV2}
                onClick={() => execNudgeAction(action, block.meta)}
                buttonType={block.meta?.button_type}
                disabled={isDisabled}
                style={{
                  ...(isDisabled && disabledStyles),
                }}
              >
                {block.meta?.label}
              </StyledCTAButton>
            )}
          </CTAWithTooltip>
        ))}
    </StyledNudgeActionBar>
  );
};
