import React, { useEffect, useState } from 'react';
import { Typography, Layout, Steps, Page } from '@commandbar/design-system/components/antd';
import { IOrganizationType, IUserType } from '@commandbar/internal/middleware/types';
import { SegmentConfigurationStep } from './SegmentConfigurationStep';
import { SegmentTestStep } from './SegmentTestStep';
import { ISegmentConfig } from './utils';
import { SegmentConfigProvider } from './SegmentConfigProvider';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Provide Write Key',
    stepHeading: 'Provide your Segment Source write key',
    component: SegmentConfigurationStep,
  },
  {
    title: 'Test Configuration',
    stepHeading: 'Send a test event to segment',
    component: SegmentTestStep,
  },
];

interface ISegmentIntegrationProps {
  organization: IOrganizationType;
  user: IUserType;
}

const SegmentSetupSteps = (props: ISegmentIntegrationProps) => {
  const stepMarker = (dot: any) => <>{dot}</>;
  const [stepIdx, setStepIdx] = useState<number>(0);

  const { stepHeading, component: CurrentStepComponent } = STEPS[stepIdx];

  const { reportEvent } = useReportEvent();

  const handlePrev = () => {
    setStepIdx((stepIdx) => Math.max(stepIdx - 1, 0));
    reportEvent('segment integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Prev Step',
    });
  };

  const handleNext = () => {
    setStepIdx((stepIdx) => Math.min(stepIdx + 1, STEPS.length - 1));
    reportEvent('segment integration interacted with', {
      segment: true,
      highlight: true,
      slack: true,
      payloadMessage: 'Next Step',
    });
  };

  return (
    <div>
      <Steps current={stepIdx} progressDot={stepMarker}>
        {STEPS.map((e: any, idx: number) => (
          <Step key={`step-${idx}`} title={e.title} />
        ))}
      </Steps>
      <div style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '80%', border: '1px solid hsl(0, 0%, 75%)', borderRadius: '12px', padding: '34px 32px' }}>
          <Typography.Title level={4}>
            {stepIdx + 1}. {stepHeading}
          </Typography.Title>
          <div
            style={{
              marginTop: '30px',
            }}
          >
            <CurrentStepComponent handlePrev={handlePrev} handleNext={handleNext} {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SegmentIntegrationPage = (props: ISegmentIntegrationProps) => {
  const { user } = props;
  const { reportEvent } = useReportEvent();

  const [segmentConfig, setSegmentConfig] = useState<ISegmentConfig>({
    orgID: user.organization,
    writeKey: '',
    endpoint: 'https://api.segment.io',
  });

  useEffect(() => {
    reportEvent('segment integration visited', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, []);

  return (
    <Page section="Integrations" title="Segment" description="Connect Segment with Commandbar.">
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        <div style={{ background: '#fff' }}>
          <SegmentConfigProvider value={{ segmentConfig, setSegmentConfig }}>
            <SegmentSetupSteps {...props} />
          </SegmentConfigProvider>
        </div>
      </Layout.Content>
    </Page>
  );
};

export default SegmentIntegrationPage;
