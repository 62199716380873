import React from 'react';
import styled from '@emotion/styled';

const Header = styled.div`
  display: flex;
  width: 100%;
  padding: var(--layout-padding);
  justify-content: space-between;
  align-items: center;
`;

const StyledHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <Header ref={ref} {...props}>
        {children}
      </Header>
    );
  },
);

export default StyledHeader;
