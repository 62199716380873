import _, { isEqual, set } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  Input,
  Tooltip,
  SubHeading,
  CB_COLORS,
  HeaderCol,
  HeaderRow,
  ErrorSignalingInput,
  FeatureAnnouncementCard,
  Modal,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import {
  Settings03,
  InfoCircle,
  FlipBackward,
  DotsVertical,
  Trash04,
  PlusSquare,
  Copy06,
  Copy02,
  AlertHexagon,
  AlertTriangle,
  ReverseLeft,
} from '@commandbar/design-system/icons/react';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import Sender from '../../management/Sender';
import Logger from '@commandbar/internal/util/Logger';
import { StopClickRecorderMessage, StopPreviewMessage } from '@commandbar/internal/client/extension/messages';

import { INudgePinStepType, INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { isInScheduledPeriod } from '@commandbar/internal/util/time';
import {
  findButtonBlocks,
  getSanitizedNudge,
  hasSurveyBlock,
  isNudgeLive,
  isNudgeReferencingStaleCommand,
} from './utils';
import { useNeedsToUpgradeFoobarPackage } from '../../pre-auth/compatibility';

import {
  OverlaySettingsSection,
  OverlaySettingsContainer,
  MenuIconContainer,
  OverlaySettingsVerticalSection,
} from '../components/styled';
import { PreviewButton, StandaloneEditorPreviewButton } from './PreviewButton';
import { useReportEvent } from '../../hooks/useEventReporting';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { useIsEditorOpen } from '../../hooks';
import { CaretDown, StyledTextArea } from '../helphub/shared';
import NudgeScheduleDropdown from './NudgeScheduleDropdown';

import { CmdButton, CmdDivider, CmdDropdown, CmdLabel, CmdSwitch, cmdToast } from '@commandbar/design-system/cmd';
import { NudgeType } from './NudgeList';
import { NUDGE_STEP_PARAM } from '@commandbar/internal/util/location';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import Select from 'antd/lib/select';
import { duplicateNudge, getDisplayTitle, getNudgeStep, isPinStep } from '@commandbar/internal/util/nudges';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import { ExtensionMessagePayload } from '@commandbar/internal/client/extension/messages/message';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import ThemeSelect from '../components/ThemeSelect';
import Z from '@commandbar/internal/client/Z';
import useWindowInfo from 'editor/src/hooks/useWindowInfo';
import EndUserChooser from '../components/EndUserChooser';
import { getErrorList, getStepDraftErrors, getStepErrors, useTourDraftErrors } from './validation';
import {
  Container,
  PageTitle,
  CurrentFormAction,
  SlugEditor,
  SlugInput,
  NudgeForm,
  DropdownStyled,
  PlainSelect,
} from './styled';
import { NudgeDetailTabs } from './NudgeDetailTabs';
import { ShareLinkModal } from '../components/ShareLinkModal';
import { isElementInformation } from '@commandbar/internal/util/dom';

interface NudgeDetailProps {
  initialNudge: INudgeType;
  onClose: () => void;
  onDelete: (nudge: INudgeType) => void;
  onSave: (nudge: INudgeType) => Promise<INudgeType>;
  onDuplicate: (nudge: INudgeType) => Promise<INudgeType | null>;
  type: NudgeType;
}

const NudgeDetail = (props: NudgeDetailProps) => {
  const location = useLocation();
  const {
    hasUnsavedChangesRef,
    commands,
    isStudio,
    flags,
    organization,
    editorTags,
    dispatch: {
      templates: { save: saveTemplate },
    },
  } = useAppContext();
  const history = useHistory();
  const [enduserSlugToReset, setEnduserSlugToReset] = React.useState<string>('');

  const { onClose } = props;
  const [dirty, setDirty] = React.useState<INudgeType>(props.initialNudge);
  const [useCustomTheme, setUseCustomTheme] = React.useState<boolean>(
    props.initialNudge.custom_theme !== undefined && props.initialNudge.custom_theme !== null,
  );
  const dirtyRef = useRef<INudgeType>(props.initialNudge);
  const [activeNudgeFormIndex, setActiveNudgeFormIndex] = React.useState<number | undefined>(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const { reportEvent } = useReportEvent();

  const [shareLinkModalOpen, setShareLinkModalOpen] = React.useState(false);
  const toggleShareLinkModal = () => setShareLinkModalOpen((previous) => !previous);

  const isEditorOpen = useIsEditorOpen();
  const nudgeTypeName =
    props.type === 'announcement' ? 'Announcement' : props.type === 'product_tour' ? 'Tour' : 'Survey';
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const { user } = useAuth();

  const { context: windowContext } = useWindowInfo();

  const isLive = dirty.is_live || isInScheduledPeriod(dirty);
  const isDraft = !isLive;
  const isNewNudge = Nudge.isNew(props.initialNudge);
  const hasSurveyStep = !isNewNudge && hasSurveyBlock(props.initialNudge);

  const needsToUpgrade = useNeedsToUpgradeFoobarPackage('>0.4.7');

  const isMountedRef = useRef(true);

  const isEditingTemplate = location.pathname.includes('template');

  const isDirty = React.useCallback(
    (n?: INudgeType) => !_.isEqual(props.initialNudge, n ?? dirty),
    [dirty, props.initialNudge],
  );

  const preventNavigation = isDirty() && !isSaving;
  const isAllowedToSave = hasRequiredRole(user, props.initialNudge.is_live ? 'editor' : 'contributor');

  useEffect(() => {
    hasUnsavedChangesRef.current = preventNavigation && isAllowedToSave;
  }, [preventNavigation]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stepIndex = queryParams.get(NUDGE_STEP_PARAM);

    if (stepIndex) {
      setActiveNudgeFormIndex(Number(stepIndex));
    }
  }, [location.search, isEditorOpen]);

  React.useEffect(() => {
    if (
      props.initialNudge.steps.some((step) =>
        isNudgeReferencingStaleCommand(props.initialNudge, commands, findButtonBlocks(step.content)),
      )
    ) {
      cmdToast.error('Nudge refers to command that does not exist.');
    }

    setDirty(props.initialNudge);
  }, [props.initialNudge, commands]);

  const currentStep = getNudgeStep(dirty, activeNudgeFormIndex ?? 0);

  const stepErrors = currentStep ? getStepErrors(currentStep) : [];
  const stepDraftErrors = currentStep ? getStepDraftErrors(currentStep) : [];
  const allErrors = dirty.steps.flatMap((step, i) => ({
    errors: [...stepDraftErrors, ...stepErrors],
    index: i + 1,
  }));

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isContributorOrHigher = hasRequiredRole(user, 'contributor');

  const tourDraftErrors = useTourDraftErrors(props.initialNudge, dirty);

  const hasError =
    allErrors.some((e) => e.errors.some(({ condition }) => condition)) || tourDraftErrors.some((e) => e.condition);
  const hasDraftError =
    dirty.steps.flatMap(getStepDraftErrors).some((e) => e.condition) || tourDraftErrors.some((e) => e.condition);

  const onToggleSwitch = (field: 'snoozable' | 'dismissible' | 'snoozable_on_all_steps' | 'show_step_counter') => {
    const newDirty = { ...dirty, [field]: !dirty[field] };
    setDirty(newDirty);
    Sender.showNudgeStepMock(newDirty, activeNudgeFormIndex ?? 0);
  };

  const onSave = async (n: INudgeType) => {
    if (!(isDirty(n) || (isNewNudge && !isEditingTemplate)) || (isDraft ? hasDraftError : hasError) || isSaving) return;

    const sanitizedNudge = getSanitizedNudge(n);
    if ((!sanitizedNudge.is_live && !hasDraftError) || !hasError) {
      setIsSaving(true);

      try {
        const updated = await props.onSave(sanitizedNudge);
        if (isMountedRef.current) {
          // The id has now changed from -1 to the actual id so we should update the preview
          Sender.closeNudgeMocks();
          Sender.showNudgeStepMock(updated, activeNudgeFormIndex ?? 0);
        }
      } catch (error) {
        cmdToast.error('Error saving nudge.');
        Logger.red('Error saving nudge: ', error);
      } finally {
        setIsSaving(false);
      }
    }
  };

  useModS(() => onSave(dirty));

  const onChange = (n: Partial<INudgeType>, resetToIndex?: number, saveChanges = false) => {
    // HACK: For some unknown reason, the steps are not being updated without manually passing in the new nudges steps
    // This would previously result in a bug where dirty state would equal the initial nudge and block the user from saving changes
    setDirty({ ...dirty, ...n, steps: n.steps ?? [] });
    onResetPreview(n, resetToIndex ?? activeNudgeFormIndex);

    if (saveChanges) {
      onSave({ ...dirty, ...n });
    }
  };

  const onResetPreview = (n: Partial<INudgeType>, index = 0) => {
    const newDirty = { ...dirty, ...n };
    Sender.showNudgeStepMock(newDirty, index);
  };

  const onStepChange = React.useCallback(
    (index: number) =>
      (step: INudgeStepType, saveChanges = false) => {
        const newVal = {
          ...dirty,
          steps: [...dirty.steps],
        };

        const dirtyStep = dirty.steps[index] as INudgeStepType;
        // HACK: For some reason, probably all of the nested objects/state, the form_factor must be updated like this
        // or it will not be updated in the preview correctly
        const newStep = { ...dirtyStep, ...step, form_factor: step.form_factor };
        newVal.steps[index] = newStep;

        reportEvent('nudge item edited', {
          segment: true,
          highlight: true,
          slack: true,
          eventProps: {
            id: dirty.id,
            form_factor: newStep.form_factor.type,
            blocks: newStep.content.filter((b) => b.type),
            action: newStep.content.filter((a) => a.meta),
          },
        });

        onChange(newVal, activeNudgeFormIndex ?? 0, saveChanges);
        Sender.showNudgeStepMock(newVal, activeNudgeFormIndex ?? 0);
        return newVal;
      },
    [dirty, onChange, activeNudgeFormIndex],
  );

  useEffect(() => {
    dirtyRef.current = dirty;
  }, [dirty]);

  // Listener for when the extension wants to update the Nudge from the Preview flow
  const onStopPreviewMessage = async (data: ExtensionMessagePayload<typeof StopPreviewMessage>) => {
    if (data.nudge) {
      // When we stop previewing a nudge, we should update the Start Page to the preview url if it wasn't set
      const share_page_url_or_path = dirtyRef.current.share_page_url_or_path || data.nudge.preview_url || '';
      const nudge = {
        ...dirtyRef.current,
        ...data.nudge,
        share_page_url_or_path,
      };
      if (data.currentStepIndex !== undefined) {
        setActiveNudgeFormIndex(data.currentStepIndex);
      }
      onChange(nudge, data.currentStepIndex);
      if (!isNudgeLive(nudge)) {
        await props.onSave(nudge);
      }
    } else {
      cmdToast.error('Error updating nudge');
    }
  };

  const onStopClickRecorderMessage = async (data: ExtensionMessagePayload<typeof StopClickRecorderMessage>) => {
    if (data.experience?.type === 'nudge' && data.experience.field) {
      // When we stop previewing a nudge, we should update the Start Page to the preview url if it wasn't set
      const share_page_url_or_path = dirtyRef.current.share_page_url_or_path || data.experience.nudge.preview_url || '';
      let nudge = {
        ...dirtyRef.current,
        share_page_url_or_path,
      };

      if (data.experience.stepIndex !== undefined && data.experience.field) {
        if (data.experience.field === 'form_factor.anchor') {
          if (isElementInformation(data.value)) {
            nudge = set(nudge, `steps.${data.experience.stepIndex}.${data.experience.field}`, data.value.selector);
            nudge = set(nudge, `steps.${data.experience.stepIndex}.form_factor.anchor_selector`, data.value);
          } else {
            nudge = set(nudge, `steps.${data.experience.stepIndex}.${data.experience.field}`, data.value);
          }
        } else {
          nudge = set(nudge, data.experience.field, data.value);
        }

        if (data.experience.stepIndex !== undefined) {
          setActiveNudgeFormIndex(data.experience.stepIndex);
        }
        onChange(nudge, data.experience.stepIndex);
        if (!isNudgeLive(nudge)) {
          await props.onSave(nudge);
        }
      }
    }
  };

  React.useEffect(() => {
    let unsubStopPreview: (() => void) | undefined;
    let unsubStopRecorder: (() => void) | undefined;

    // Listeners for when the extension wants to update the nudge/nudge step from Recorder/Preview flows
    if (isStudio) {
      unsubStopPreview = StopPreviewMessage.addPageListener(onStopPreviewMessage);
      unsubStopRecorder = StopClickRecorderMessage.addPageListener(onStopClickRecorderMessage);
    }

    return () => {
      unsubStopPreview?.();
      unsubStopRecorder?.();
    };
  }, [isStudio]);

  const isBanner = dirty.steps.some((step) => step.form_factor.type === 'banner');

  const [firstStep] = dirty.steps;

  const isUnsaved = Number(dirty.id) < 0 || isSaving;
  const isSimulateDisabled = isUnsaved || !isEqual(props.initialNudge.audience, dirty.audience);

  return (
    <Container>
      <ShareLinkModal shareableEntity={dirty} isOpen={shareLinkModalOpen} setIsOpen={setShareLinkModalOpen} />
      <HeaderRow justify="space-between" align="middle" wrap={true} style={{ gap: '4px', flexWrap: 'nowrap' }}>
        <HeaderCol style={{ width: 'fit-content', flex: '0 1 50%' }}>
          <CmdButton onClick={onClose}>
            <FlipBackward />
          </CmdButton>

          <PageTitle>
            <CurrentFormAction>{isNewNudge ? `Create ${nudgeTypeName}` : `Edit ${nudgeTypeName}`}</CurrentFormAction>

            <SlugEditor>
              <SlugInput
                value={dirty.slug}
                onChange={(e) => onChange({ ...dirty, slug: e.target.value })}
                bordered={false}
                spellCheck={false}
                error={allErrors.flatMap(({ errors }) => errors).find(({ name }) => name === 'no_slug')?.condition}
                placeholder="name"
                autoSize
              />
            </SlugEditor>
          </PageTitle>
        </HeaderCol>

        <HeaderCol style={{ gap: '4px', flex: '1 0 50%', justifyContent: 'end' }}>
          {isStudio ? (
            <StandaloneEditorPreviewButton isDirty={isDirty()} nudge={dirty} onChange={onChange} isSaving={isSaving} />
          ) : (
            <PreviewButton nudge={dirty} isDisabled={isSimulateDisabled} />
          )}

          {!isEditingTemplate && (
            <Tooltip
              showIf={!isAllowedToPublish}
              content="You do not have the required permissions to perform this action"
            >
              <NudgeScheduleDropdown disabled={!isAllowedToPublish} dirty={dirty} setDirty={setDirty} />
            </Tooltip>
          )}

          {needsToUpgrade ? (
            <Tooltip content="Please upgrade the `@commandbar/foobar` package (version 0.4.8 or greater) to save changes.">
              <CmdButton variant="primary" disabled>
                Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
              </CmdButton>
            </Tooltip>
          ) : (
            <Tooltip
              showIf={isDraft ? hasDraftError : hasError}
              content={
                isDraft
                  ? getErrorList(
                      dirty.steps.flatMap((step, i) => ({ errors: [...getStepDraftErrors(step)], index: i })),
                      tourDraftErrors,
                    )
                  : getErrorList(allErrors, tourDraftErrors)
              }
              placement="left"
            >
              <CmdButton
                variant="primary"
                onClick={() => onSave(dirty)}
                disabled={
                  !(isDirty() || (isNewNudge && !isEditingTemplate)) || (isDraft ? hasDraftError : hasError) || isSaving
                }
                style={{
                  ...((isDraft ? hasDraftError : hasError) && {
                    border: '1px solid rgb(185, 28, 28)',
                    boxShadow: '0px 0px 0px 2px rgba(185, 28, 28, .3)',
                  }),
                }}
              >
                {(isDraft ? hasDraftError : hasError) && <AlertHexagon />}
                Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
              </CmdButton>
            </Tooltip>
          )}
          <CmdDropdown.Menu>
            <CmdDropdown.Trigger>
              <CmdButton variant="link" className="px-sm">
                <DotsVertical width={'16px'} height={'16px'} />
              </CmdButton>
            </CmdDropdown.Trigger>
            <CmdDropdown.Content style={{ width: '236px' }}>
              {isContributorOrHigher && (
                <CmdDropdown.Item
                  disabled={Number(dirty.id) < 0 || isEditingTemplate}
                  onClick={async () => {
                    await props.onDuplicate(dirty);
                  }}
                >
                  <Copy02 /> Duplicate
                </CmdDropdown.Item>
              )}

              {!isEditingTemplate && (
                <CmdDropdown.Item
                  disabled={Number(dirty.id) < 0 || isEditingTemplate}
                  onClick={() => {
                    navigator.clipboard.writeText(`${dirty.id}`).then(function () {
                      cmdToast.success('Nudge id copied to clipboard.');
                    });
                  }}
                >
                  <Copy06 /> Copy ID
                </CmdDropdown.Item>
              )}

              <CmdDropdown.Item disabled={Number(dirty.id) < 0 || isEditingTemplate} onClick={toggleShareLinkModal}>
                <Copy06 /> Trigger link...
              </CmdDropdown.Item>

              {!isEditingTemplate && isContributorOrHigher && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    onClick={async () => {
                      const template = await saveTemplate({
                        id: -1,
                        type: props.type,
                        data: duplicateNudge(dirty, `${getDisplayTitle(dirty)} template`, {
                          template_source: 'custom',
                        }),
                      });

                      if (template) {
                        history.push(`${getNudgeRoute(dirty)}/template/${template.id}`);
                      }
                    }}
                  >
                    <PlusSquare /> Create a Template
                  </CmdDropdown.Item>
                </>
              )}

              {isStudio && isAllowedToPublish && (
                <>
                  <CmdDivider />
                  <div style={{ padding: '8px', display: 'flex', gap: 8, flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', gap: 4, flexDirection: 'column', flex: 1 }}>
                      <div style={{ display: 'flex', gap: '4px' }}>
                        <CmdLabel>{'Reset user history'}</CmdLabel>{' '}
                        <Tooltip content="Reset a user’s seen, completed, and dismissed history for this experience.">
                          <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                        </Tooltip>
                      </div>
                      <EndUserChooser
                        endUserSlugs={enduserSlugToReset ? [enduserSlugToReset] : []}
                        onEndUserChange={(end_user) => setEnduserSlugToReset(end_user.slug)}
                      />
                    </div>

                    <CmdButton
                      fullWidth
                      disabled={Number(dirty.id) < 0 || !enduserSlugToReset}
                      icon={<ReverseLeft />}
                      onClick={async () => {
                        const result = await Nudge.resetNudgeInteractions(enduserSlugToReset, Number(dirty.id));

                        if (result.did_reset) {
                          cmdToast.success(`Interaction history reset for ${enduserSlugToReset}`);
                        } else {
                          cmdToast.error(
                            `There are no interactions for this experience and ${enduserSlugToReset} that could be reset`,
                          );
                        }
                      }}
                    >
                      Reset
                    </CmdButton>
                  </div>
                </>
              )}
              {!isStudio && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    onClick={async (e) => {
                      e.stopPropagation();
                      Sender.resetNudge(Number(dirty.id));
                      cmdToast.success(`Nudge history reset for ${windowContext?.id}`);
                    }}
                  >
                    <ReverseLeft />
                    Reset for current user
                  </CmdDropdown.Item>
                </>
              )}

              {isAllowedToSave && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    onClick={() => {
                      if (isNewNudge) {
                        if (isDirty()) {
                          Modal.confirm({
                            zIndex: Z.Z_MODALS,
                            icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                            title: 'Are you sure you want to delete this nudge? This cannot be undone.',
                            onOk() {
                              history.replace(getNudgeRoute(props.initialNudge));
                            },
                          });
                        } else {
                          history.replace(getNudgeRoute(props.initialNudge));
                        }
                      } else {
                        props.onDelete(dirty);
                      }
                    }}
                  >
                    <Trash04 /> Delete
                  </CmdDropdown.Item>
                </>
              )}
            </CmdDropdown.Content>
          </CmdDropdown.Menu>
        </HeaderCol>
      </HeaderRow>

      {Number(props.initialNudge.id) < 0 &&
        (flags['release-search-experiences-in-help-hub'] || flags['release-search-experiences-in-spotlight']) &&
        props.type === 'product_tour' && (
          <div style={{ padding: '8px 24px' }}>
            <FeatureAnnouncementCard
              identifier={'product-tours-search'}
              title={'Tours will now show up in Spotlight and HelpHub search results'}
            >
              This change makes it easier for users to discover Tours that might be helpful to them. You can still
              prevent them from showing up in search results by updating the searchability settings in the menu on the
              right below.
            </FeatureAnnouncementCard>
          </div>
        )}

      <UpgradeCTA product="nudges" padding="0 16px" />

      <NudgeForm>
        <DropdownStyled
          trigger={['click']}
          placement="bottomLeft"
          overlay={
            <OverlaySettingsContainer width="288px" ref={containerRef}>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <SubHeading>Dismissible</SubHeading>
                <CmdSwitch
                  checked={dirty.dismissible === undefined ? true : dirty.dismissible}
                  onCheckedChange={() => onToggleSwitch('dismissible')}
                />
              </OverlaySettingsSection>

              {!isBanner && <CmdDivider spacing="md" />}

              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <SubHeading>Snoozable</SubHeading>
                <CmdSwitch
                  checked={dirty.snoozable === undefined ? false : dirty.snoozable}
                  onCheckedChange={() => onToggleSwitch('snoozable')}
                />
              </OverlaySettingsSection>

              {!!dirty.snoozable && (
                <React.Fragment>
                  <OverlaySettingsVerticalSection>
                    <ErrorSignalingInput
                      placeholder="Snooze"
                      error={dirty.snooze_label.length === 0}
                      value={dirty.snooze_label}
                      onChange={(e) => {
                        const newDirty = { ...dirty, snooze_label: e.target.value };
                        setDirty(newDirty);
                        Sender.showNudgeStepMock(newDirty, activeNudgeFormIndex ?? 0);
                      }}
                      style={{
                        border: `1px solid ${CB_COLORS.neutral300}`,
                      }}
                    />
                  </OverlaySettingsVerticalSection>

                  <OverlaySettingsVerticalSection>
                    <SubHeading>Duration</SubHeading>
                    <div style={{ width: '100%', display: 'flex', gap: '8px' }}>
                      <Input
                        type="number"
                        min={1}
                        value={dirty.snooze_duration.value}
                        onKeyDown={(e) => {
                          // Prevent entering '.' or ',' which are used for decimals
                          if (e.key === '.' || e.key === ',') {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const value = Number.parseInt(e.target.value);
                          setDirty({
                            ...dirty,
                            snooze_duration: { ...dirty.snooze_duration, value: Number.isNaN(value) ? 1 : value },
                          });
                        }}
                        style={{ width: '50%', height: '32px' }}
                      />

                      <PlainSelect
                        value={dirty.snooze_duration.interval}
                        onChange={(e: 'hour' | 'day' | 'week') => {
                          setDirty({ ...dirty, snooze_duration: { ...dirty.snooze_duration, interval: e } });
                        }}
                        suffixIcon={<CaretDown />}
                        options={[
                          { value: 'hour', label: 'Hours' },
                          { value: 'day', label: 'Days' },
                          { value: 'week', label: 'Weeks' },
                        ]}
                        style={{ width: '50%', boxShadow: 'none' }}
                      />
                    </div>
                  </OverlaySettingsVerticalSection>

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>Show on all steps</SubHeading>
                    <CmdSwitch
                      checked={dirty.snoozable_on_all_steps === undefined ? true : dirty.snoozable_on_all_steps}
                      onCheckedChange={() => onToggleSwitch('snoozable_on_all_steps')}
                    />
                  </OverlaySettingsSection>
                </React.Fragment>
              )}

              {dirty.steps.length > 1 && (
                <React.Fragment>
                  <CmdDivider spacing="md" />

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>Show step counter</SubHeading>
                    <CmdSwitch
                      checked={dirty.show_step_counter}
                      onCheckedChange={() => onToggleSwitch('show_step_counter')}
                    />
                  </OverlaySettingsSection>
                </React.Fragment>
              )}

              {!isBanner && <CmdDivider spacing="md" />}

              {flags['release-search-experiences-in-help-hub'] && !isBanner && (
                <OverlaySettingsSection
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SubHeading>Searchable in HelpHub</SubHeading>
                  <Tooltip
                    showIf={!organization.helphub_enabled}
                    content={'You must first enable HelpHub to make this searchable.'}
                  >
                    <CmdSwitch
                      checked={dirty.show_in_helphub_search && organization.helphub_enabled}
                      onCheckedChange={() => {
                        const newDirty = { ...dirty, show_in_helphub_search: !dirty.show_in_helphub_search };
                        setDirty(newDirty);
                      }}
                      disabled={!organization.helphub_enabled}
                    />
                  </Tooltip>
                </OverlaySettingsSection>
              )}

              {flags['release-search-experiences-in-spotlight'] && !isBanner && (
                <OverlaySettingsSection
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SubHeading>Searchable in Spotlight</SubHeading>
                  <Tooltip
                    showIf={!organization.bar_enabled}
                    content={'You must first enable Spotlight to make this searchable.'}
                  >
                    <CmdSwitch
                      checked={dirty.show_in_spotlight_search && organization.bar_enabled}
                      onCheckedChange={() => {
                        const newDirty = { ...dirty, show_in_spotlight_search: !dirty.show_in_spotlight_search };
                        setDirty(newDirty);
                      }}
                      disabled={!organization.bar_enabled}
                    />
                  </Tooltip>
                </OverlaySettingsSection>
              )}

              {!isBanner && (
                <OverlaySettingsSection
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SubHeading>Suggest in Copilot</SubHeading>
                  <CmdSwitch
                    checked={dirty.copilot_suggest}
                    onCheckedChange={() => {
                      const newDirty = { ...dirty, copilot_suggest: !dirty.copilot_suggest };
                      setDirty(newDirty);
                    }}
                  />
                </OverlaySettingsSection>
              )}

              {dirty.copilot_suggest && (
                <>
                  <OverlaySettingsVerticalSection>
                    <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '4px' }}>CTA Label</div>
                    </SubHeading>
                    <Input
                      value={dirty.copilot_cta_label}
                      onChange={(e) => {
                        const newDirty = { ...dirty, copilot_cta_label: e.target.value };
                        setDirty(newDirty);
                      }}
                      placeholder={dirty.steps ? dirty.steps[0].title : 'Click here'}
                      style={{
                        border: `1px solid ${CB_COLORS.neutral300}`,
                      }}
                    />
                  </OverlaySettingsVerticalSection>
                  <OverlaySettingsVerticalSection>
                    <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '4px' }}>Description</div>
                      <Tooltip content="Copilot will use this to make smart suggestions to users.">
                        <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                      </Tooltip>
                    </SubHeading>
                    <StyledTextArea
                      value={dirty.copilot_description}
                      onChange={(e) => {
                        const newDirty = { ...dirty, copilot_description: e.target.value };
                        setDirty(newDirty);
                      }}
                      rows={2}
                      placeholder="Describe the Nudge and how the user will benefit from it."
                      style={{
                        border: `1px solid ${CB_COLORS.neutral300}`,
                      }}
                    />
                  </OverlaySettingsVerticalSection>

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>
                      <div style={{ display: 'flex', gap: '4px' }}>
                        Copilot Intro
                        <Tooltip content="Copilot will introduce this Nudge with an animated cursor callout">
                          <InfoCircle width={16} height={16} />
                        </Tooltip>
                      </div>
                    </SubHeading>
                    <Tooltip showIf={firstStep.form_factor.type !== 'pin'} content="Add a pin as the first step">
                      <CmdSwitch
                        checked={(firstStep.form_factor.type === 'pin' && firstStep.form_factor.copilot_intro) ?? true}
                        disabled={firstStep.form_factor.type !== 'pin'}
                        onCheckedChange={() => {
                          if (isPinStep(firstStep)) {
                            const updatedFirstStep: INudgePinStepType = {
                              ...firstStep,
                              form_factor: {
                                ...firstStep.form_factor,
                                copilot_intro: !(firstStep.form_factor.copilot_intro ?? true),
                              },
                            };
                            const updatedSteps = [updatedFirstStep, ...dirty.steps.slice(1)];
                            setDirty({
                              ...dirty,
                              steps: updatedSteps,
                            });
                          }
                        }}
                      />
                    </Tooltip>
                  </OverlaySettingsSection>
                </>
              )}

              {flags['release-themes-v2'] && (
                <>
                  <CmdDivider spacing="md" />

                  <OverlaySettingsSection
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SubHeading>Custom theme</SubHeading>
                    <CmdSwitch
                      checked={useCustomTheme}
                      onCheckedChange={(checked) => {
                        if (!checked) {
                          const newDirty = { ...dirty, custom_theme: null };
                          setDirty(newDirty);
                          Sender.showNudgeStepMock(newDirty, activeNudgeFormIndex ?? 0);
                        }
                        setUseCustomTheme(checked);
                      }}
                    />
                  </OverlaySettingsSection>
                  {useCustomTheme && (
                    <OverlaySettingsSection>
                      <ThemeSelect
                        value={dirty.custom_theme}
                        onChange={(themeId) => {
                          const newDirty = { ...dirty, custom_theme: themeId };
                          setDirty(newDirty);
                          Sender.showNudgeStepMock(newDirty, activeNudgeFormIndex ?? 0);
                        }}
                        style={{ flex: 1 }}
                      />
                    </OverlaySettingsSection>
                  )}
                </>
              )}

              {!isBanner && <CmdDivider spacing="md" />}

              <OverlaySettingsVerticalSection>
                <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '4px' }}>Tags</div>
                  <Tooltip content="Add tags to help organize your Tours, Announcements, and Surveys in the Editor.">
                    <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                  </Tooltip>
                </SubHeading>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder="Create or select a tag..."
                  onChange={(values) => {
                    const newDirty = { ...dirty, editor_tags: values };
                    setDirty(newDirty);
                  }}
                  value={dirty.editor_tags}
                  options={editorTags.map((tag) => ({ value: tag }))}
                />
              </OverlaySettingsVerticalSection>
            </OverlaySettingsContainer>
          }
        >
          <MenuIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Settings03 />
          </MenuIconContainer>
        </DropdownStyled>
        <NudgeDetailTabs
          dirty={dirty}
          isDirty={isDirty()}
          initialNudgeId={props.initialNudge.id}
          activeNudgeFormIndex={activeNudgeFormIndex}
          onActiveNudgeFormChange={setActiveNudgeFormIndex}
          onNudgeChange={onChange}
          onStepChange={onStepChange}
          hasAnalytics={hasSurveyStep}
        />
      </NudgeForm>
    </Container>
  );
};

export default NudgeDetail;
