import React from 'react';
import { Panel } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';
import { LauncherSettings } from './LauncherSettings';
import styled from '@emotion/styled';

const Container = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const HelpHubLauncherSettings = () => {
  const { organization } = useAppContext();

  const [activeKeys, setActiveKeys] = React.useState<DeviceType[]>([]);

  if (!organization) {
    return null;
  }

  return (
    <Container>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey="desktop" header="Desktop">
        <LauncherSettings deviceType="desktop" />
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey="mobile" header="Mobile">
        <LauncherSettings deviceType="mobile" />
      </Panel>
    </Container>
  );
};
