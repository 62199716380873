import { Form, Select } from '@commandbar/design-system/components/antd';
import { CmdButton, CmdInput, CmdLabel, cmdToast } from '@commandbar/design-system/cmd';
import { ButtonRow, DrawerTitle, FooterRow, StyledDrawer } from '../shared/styles';
import { useCallback, useEffect, useState } from 'react';
import { XClose } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import IconPicker from 'editor/src/editor/components/IconPicker/IconPicker';
import ImageUploader, { Image } from 'editor/src/editor/components/DragUpload/ImageUploader';
import Logger from '@commandbar/internal/util/Logger';
import debounce from 'lodash/debounce';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { DrawerFooterDeleteButton, LegacyOptions, hasLegacyValue } from '../shared/utils';
import { useAppContext } from 'editor/src/AppStateContext';
import * as Command from '@commandbar/internal/middleware/command';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';

type createVideoProps = {
  title: string;
  url: string;
  icon?: string;
  iconColor?: string;
  thumbnail: Image | null;
  organizationId: string;
  categoryId: number;
  isLive: boolean;
};

const VideoEditDrawer = ({
  open,
  onClose,
  organizationId,
  activeVideo,
}: {
  open: boolean;
  onClose: () => void;
  organizationId: string | number | undefined;
  activeVideo: IEditorCommandType | undefined;
}) => {
  const [form] = Form.useForm();
  const [icon, setIcon] = useState('play');
  const [iconColor, setIconColor] = useState('#000000');
  const [thumbnail, setThumbnail] = useState<Image | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [updates, setUpdates] = useState(0);
  const {
    dispatch: {
      commands: { save },
    },
  } = useAppContext();
  const { user } = useAuth();

  const shouldProvidLegacyOptions = activeVideo && hasLegacyValue(activeVideo);

  useEffect(() => {
    if (activeVideo) {
      form.setFieldsValue({
        isLive: activeVideo.is_live,
        title: activeVideo.text,
        url: activeVideo.template.value,
      });
      activeVideo.icon && setIcon(activeVideo?.icon);
      activeVideo.icon_color && setIconColor(activeVideo.icon_color);
      activeVideo.thumbnail && setThumbnail(activeVideo.thumbnail);
    } else {
      form.resetFields();
    }
  }, [activeVideo]);

  const onDrawerClose = () => {
    setIsValid(false);
    setUpdates(0);
    form.resetFields();
    setIcon('play');
    setIconColor('#000000');
    setThumbnail(null);
    onClose();
  };

  const createVideo = async ({
    title,
    url,
    icon,
    iconColor,
    thumbnail,
    organizationId,
    categoryId,
    isLive,
  }: createVideoProps) => {
    const newCommand = Command.decodeEditorCommand({
      id: -1,
      organization: organizationId,
      category: categoryId,
      text: title,
      is_live: isLive,
      icon: icon ?? null,
      icon_color: iconColor ?? null,
      thumbnail: thumbnail,
      template: {
        type: 'video',
        value: url,
        operation: 'blank',
        commandType: 'independent',
      },
      // this is required to save a video command
      arguments: {
        __video__: {
          is_private: true,
          type: 'video',
          order_key: 0,
          value: {
            source: url,
            title: title,
            description: '',
          },
        },
      },
      show_in_helphub_search: true,
      show_in_spotlight_search: true,
    });
    await save(newCommand);
  };

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, form.getFieldValue('isLive') ? 'editor' : 'contributor');

  const checkIsValid = (numUpdates: number, loading?: boolean) => {
    setIsValid(
      numUpdates > 0 &&
        isAllowedToSave &&
        !loading &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        Object.values(form.getFieldsValue(['url', 'title'])).filter((val) => val === undefined || val === '').length ===
          0,
    );
  };

  useEffect(() => {
    checkIsValid(updates);
  }, [updates]);

  useModS(() => {
    if (!isValid) return;

    onFinish({
      title: form.getFieldValue('title'),
      url: form.getFieldValue('url'),
      isLive: form.getFieldValue('isLive'),
    });
  });

  const onFinish = useCallback(
    async ({ title, url, isLive }: { title: string; url: string; isLive: boolean }) => {
      if (!organizationId) return;
      setIsSaving(true);

      try {
        if (activeVideo) {
          await save({
            ...activeVideo,
            text: title,
            is_live: isLive,
            icon: icon ?? null,
            icon_color: iconColor ?? null,
            thumbnail: thumbnail,
            template: {
              type: 'video',
              value: url,
              operation: 'blank',
              commandType: 'independent',
            },
            arguments: {
              __video__: {
                is_private: true,
                type: 'video',
                order_key: 0,
                value: {
                  source: url,
                  title: title,
                  description: '',
                },
              },
            },
          });
        } else {
          const categories = await Organization.listCommandCategories(organizationId.toString());
          const categoryId = categories.find(({ name }) => name === 'Help')?.id ?? categories[0]?.id;
          await createVideo({
            organizationId: organizationId.toString(),
            categoryId,
            title,
            url,
            icon,
            iconColor,
            thumbnail,
            isLive,
          });
        }
      } catch (error) {
        cmdToast.error('Something went wrong while creating the video.');
        Logger.error('Error creating video: ', error);
      } finally {
        setIsSaving(false);
        onDrawerClose();
      }
    },
    [createVideo, form, icon, iconColor, thumbnail],
  );

  if (!organizationId) return <></>;

  const FooterButtons = () => (
    <ButtonRow>
      <CmdButton variant={'link'} onClick={onDrawerClose}>
        Cancel
      </CmdButton>
      <CmdButton disabled={!isValid} loading={isSaving} variant="primary" type="submit" form="create-video">
        {activeVideo ? 'Save Changes' : 'Create Video'}
        <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
      </CmdButton>
    </ButtonRow>
  );

  return (
    <StyledDrawer
      key={'create-video-drawer' + activeVideo ? activeVideo?.id.toString() : '-1'}
      width="500px"
      placement="right"
      onClose={onDrawerClose}
      open={open}
      closeIcon={<XClose />}
      maskStyle={{ color: '#000000', opacity: '0.2' }}
      title={<DrawerTitle>{activeVideo ? 'Edit Video' : 'New Video'}</DrawerTitle>}
      footer={
        activeVideo ? (
          <FooterRow>
            <DrawerFooterDeleteButton
              disabled={!isAllowedToSave}
              activeCommand={activeVideo}
              onDelete={() => {
                onDrawerClose();
              }}
            />
            <FooterButtons />
          </FooterRow>
        ) : (
          <FooterButtons />
        )
      }
    >
      <Form
        style={{ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
        layout="vertical"
        key={'create-video' + activeVideo ? activeVideo?.id.toString() : '-1'}
        name="create-video"
        form={form}
        onFinish={onFinish}
        onValuesChange={debounce(() => {
          setUpdates((updates) => updates + 1);
        }, 300)}
      >
        <div>
          <Form.Item
            key={'isLive'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Status</CmdLabel>}
            name={'isLive'}
            initialValue={activeVideo?.is_live ?? true}
          >
            <Select disabled={!isAllowedToPublish}>
              <Select.Option key={'unpublished'} value={false}>
                Unpublished
              </Select.Option>
              <Select.Option key={'published'} value={true}>
                Published
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            key={'title'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Title</CmdLabel>}
            name={'title'}
            rules={[{ required: true, message: 'This is a required field.' }]}
          >
            <CmdInput fullWidth placeholder="Video title..." />
          </Form.Item>
          <Form.Item
            key={'url'}
            name={'url'}
            required={true}
            validateTrigger="onChange"
            label={<CmdLabel>Video URL</CmdLabel>}
            rules={[{ required: true, message: 'This is a required field.' }]}
          >
            <CmdInput fullWidth placeholder="YouTube, Vimeo, Loom, Vidyard, or .MP4" />
          </Form.Item>
          <Form.Item key={'icon'} label={<CmdLabel>Icon</CmdLabel>}>
            <IconPicker
              icon={icon}
              color={iconColor}
              colors={[]}
              onIconSelect={(icon) => {
                setIcon(icon);
                setUpdates((updates) => updates + 1);
              }}
              onColorSelect={(icon_color) => {
                setIconColor(icon_color);
                setUpdates((updates) => updates + 1);
              }}
            />
          </Form.Item>
          <Form.Item
            key={'thumbnail'}
            label={
              <CmdLabel tooltip="Thumbnails are displayed when a video is included as part of a HelpHub recommendation set.">
                Thumbnail
              </CmdLabel>
            }
          >
            <ImageUploader
              onUpload={(thumbnail) => {
                setThumbnail(thumbnail);
              }}
              onDelete={() => {
                setThumbnail(null);
                setUpdates((updates) => updates + 1);
              }}
              thumbnail={thumbnail}
              organizationId={organizationId}
              setIsLoading={(loading, updated) => {
                updated && setUpdates((updates) => updates + 1);
                checkIsValid(updated ? updates + 1 : updates, loading);
              }}
            />
          </Form.Item>
        </div>
        {shouldProvidLegacyOptions && <LegacyOptions id={activeVideo?.id} />}
      </Form>
    </StyledDrawer>
  );
};

export default VideoEditDrawer;
