import { DeviceType } from '../util/operatingSystem';
import * as t from 'io-ts';
import { IHelpHubLauncherSettingsType, IOrganizationType } from './types';

export const HelpHubSearchActionsV = t.union([t.literal('search'), t.literal('ask')]);

export const HelpHubLauncherPositionsV = t.union([
  t.literal('topRight'),
  t.literal('topLeft'),
  t.literal('bottomRight'),
  t.literal('bottomLeft'),
]);

export const HelpHubLauncherTypesV = t.union([
  t.literal('bookOpen'),
  t.literal('graduationHat'),
  t.literal('bookClosed'),
  t.literal('bookmark'),
  t.literal('chatCircle'),
  t.literal('askAI'),
  t.literal('custom'),
  t.literal('none'),
]);

export const HelpHubLauncherSettingsV = t.type({
  helphub_launcher_offset_x: t.number,
  helphub_launcher_offset_y: t.number,
  helphub_launcher_anchor: t.string,
  helphub_launcher_position: HelpHubLauncherPositionsV,
  helphub_launcher_type: HelpHubLauncherTypesV,
  helphub_mobile_launcher_offset_x: t.number,
  helphub_mobile_launcher_offset_y: t.number,
  helphub_mobile_launcher_anchor: t.string,
  helphub_mobile_launcher_position: HelpHubLauncherPositionsV,
  helphub_mobile_launcher_type: HelpHubLauncherTypesV,
});

export const getHelpHubLauncherSettingKeys = (
  deviceType: DeviceType,
): Record<string, keyof IHelpHubLauncherSettingsType> => {
  if (deviceType === 'mobile') {
    return {
      type: 'helphub_mobile_launcher_type',
      anchor: 'helphub_mobile_launcher_anchor',
      position: 'helphub_mobile_launcher_position',
      offsetX: 'helphub_mobile_launcher_offset_x',
      offsetY: 'helphub_mobile_launcher_offset_y',
    };
  }

  return {
    type: 'helphub_launcher_type',
    anchor: 'helphub_launcher_anchor',
    position: 'helphub_launcher_position',
    offsetX: 'helphub_launcher_offset_x',
    offsetY: 'helphub_launcher_offset_y',
  };
};

export type HelpHubLauncherMergedSettings = {
  type:
    | IHelpHubLauncherSettingsType['helphub_launcher_type']
    | IHelpHubLauncherSettingsType['helphub_mobile_launcher_type'];
  anchor:
    | IHelpHubLauncherSettingsType['helphub_launcher_anchor']
    | IHelpHubLauncherSettingsType['helphub_mobile_launcher_anchor'];
  position:
    | IHelpHubLauncherSettingsType['helphub_launcher_position']
    | IHelpHubLauncherSettingsType['helphub_mobile_launcher_position'];
  offsetX:
    | IHelpHubLauncherSettingsType['helphub_launcher_offset_x']
    | IHelpHubLauncherSettingsType['helphub_mobile_launcher_offset_x'];
  offsetY:
    | IHelpHubLauncherSettingsType['helphub_launcher_offset_y']
    | IHelpHubLauncherSettingsType['helphub_mobile_launcher_offset_y'];
};

export const getHelpHubLauncherSettings = (deviceType: DeviceType, organization: IOrganizationType) => {
  const settingsKeys = getHelpHubLauncherSettingKeys(deviceType);

  return {
    type: organization[settingsKeys.type],
    anchor: organization[settingsKeys.anchor],
    position: organization[settingsKeys.position],
    offsetX: organization[settingsKeys.offsetX],
    offsetY: organization[settingsKeys.offsetY],
  } as HelpHubLauncherMergedSettings;
};
