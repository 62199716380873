import React from 'react';

import {
  Typography,
  Space,
  Select,
  FormRow,
  Input,
  Tooltip,
  AutoComplete,
  Code,
  Checkbox,
  ButtonCollapse,
  CB_COLORS,
} from '@commandbar/design-system/components/antd';
import CodeEditor from 'react-simple-code-editor';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import 'highlight.js/styles/stackoverflow-light.css';
import JSONInput from 'react-json-editor-ajrm';
import { getNextSortKey } from './arguments/argumentUtils';

import type { ITemplateOptions } from '@cb/types/entities/command/template';
import type { RequestType, Action } from '@cb/types/entities/command/actions';
import type { IEditorCommandType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import _ from 'lodash';
import AutoCompleteTextArea from '../../components/AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from '../../../hooks/useWindowInfo';
import { getCommandsByContextKey } from '../../context/helpers';
import { ContextArgumentV } from '@commandbar/internal/middleware/helpers/argument';
import { ICommandDetailDispatch } from './CommandDetail';
import { useAppContext } from 'editor/src/AppStateContext';
import RouterWarning from '../../RouterWarning';

import { getFirstDetail } from '@commandbar/internal/middleware/detailPreview';
import PrettyCodeEditor from '../../components/PrettyCodeEditor';
import { ActionEditor } from '../../ActionEditor';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { AlertTriangle, Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { transformIfJSPath } from '@commandbar/internal/util/dom';
import { ClickRecorder } from '../../components/ClickRecorder';

hljs.registerLanguage('javascript', javascript);
/*******************************************************************************/
/* Local constants and helpers
/*******************************************************************************/

export const SELECTOR_WARNING_TEXT = `This selector could be fragile and may not be reliably available.
Please consider using a more specific one (such as a unique id).`;
const DIGITS = '[0-9]';
const LETTERS = '[a-zA-Z]';
const DEFAULT_PLACEHOLDER = '/home or https://somesite.com/path';
const REQUEST_DEFAULT_PLACEHOLDER = 'https://my-api.com/route';

const STYLED_COMPONENTS_REGEXP = new RegExp(`sc-(${DIGITS}|${LETTERS}){6,}.${LETTERS}{6}`);
const EMOTION_PREFIX_REGEXP = new RegExp(`css-(${DIGITS}|${LETTERS}){6,}`);

export const isSelectorFragile = (selector: string) => {
  // Pure css selectors
  if (/:nth-/.test(selector)) return true;

  // CSS-in-JS libs' auto generated selectors
  if (STYLED_COMPONENTS_REGEXP.test(selector) || EMOTION_PREFIX_REGEXP.test(selector)) return true;

  return false;
};

const capitalize = (s: string | null | undefined): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/*******************************************************************************/

interface IActionDetailProps {
  command: IEditorCommandType;
  onValueChange: (e: string | RequestType | string[] | Action, index?: number) => void;
  onClickElemListAdd: () => void;
  onClickElemListDelete: (index: number) => void;
  onTemplateOptionsChange: (option: ITemplateOptions) => void;
  isThirdParty?: boolean;
  updateCommandState: ICommandDetailDispatch;
}

export const ActionDetailPanel = (props: IActionDetailProps) => {
  switch (props.command.template.type) {
    case 'link':
      return <LinkSelect {...props} />;
    case 'callback':
      return <CallbackSelect {...props} />;
    case 'clickByXpath':
    case 'clickBySelector':
    case 'click':
      return <ClickSelect {...(props as IClickCommand)} />;
    case 'webhook':
      return <WebhookInput {...props} />;
    case 'appcues':
      return <AppcuesInput {...props} />;
    case 'pendo_guide':
      return <PendoGuideInput {...props} />;
    case 'script':
      return <CodeInput {...props} />;
    case 'video':
      return <VideoInput {...props} />;
    case 'helpdoc':
      return <HelpDocInput {...props} />;
    case 'request':
      return <RequestInput {...props} />;
    case 'trigger':
      return <ActionInput {...props} />;
    case 'builtin':
      return <div />;
    default:
      return <div />;
  }
};

export default ActionDetailPanel;

const getInterpolationObjects = (
  commands: IEditorCommandTypeLite[],
  command: IEditorCommandType,
  context: Record<string, any>,
) => {
  const currentArguments: { value: string; addOn: string }[] = [];

  for (const [key, value] of Object.entries(command.arguments)) {
    // html and video arguments are used for dashboard steps and will be interpolated
    if (value.type !== 'html' && value.type !== 'video') {
      currentArguments.push({ value: key, addOn: 'Argument' });
    }
  }

  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));
  const referencedKeys = Object.keys(getCommandsByContextKey(commands || []))
    .filter((keyName: string) => !Object.keys(context).includes(keyName))
    .map((s) => ({ value: `metadata.${s}`, addOn: 'Referenced' }));

  return [...currentArguments, ...contextKeys, ...referencedKeys];
};

/*******************************************************************************/
/* Specific action renders
/*******************************************************************************/

const LinkSelect = (props: IActionDetailProps) => {
  const { commands } = useAppContext();
  const { context, hasRouter } = useWindowInfo();

  const showAlert = props.command.template.operation === 'router' && !hasRouter;
  const onChange = (e: any) => {
    props.onValueChange(e);
  };

  const firstContextArgument = Object.keys(props.command.arguments).find(
    (argName) => props.command.arguments[argName].type === 'context',
  );

  let placeholder;
  const argument = firstContextArgument && props.command.arguments[firstContextArgument];

  if (firstContextArgument && ContextArgumentV.is(argument)) {
    let exampleProperty = '.id';
    const contextKey = argument.value;
    const searchObject = _.get(context, contextKey);
    if (searchObject && typeof searchObject[0] === 'object') {
      const properties = Object.keys(searchObject[0]);
      if (properties.length > 1) exampleProperty = `.${properties[0]}`;
    } else {
      exampleProperty = '';
    }
    placeholder = `/home/{{${firstContextArgument}${exampleProperty}}}`;
  } else {
    placeholder = DEFAULT_PLACEHOLDER;
  }

  const interpolationOptions = getInterpolationObjects(commands, props.command, context);

  return (
    <div style={{ marginTop: 15 }}>
      {showAlert && <RouterWarning />}
      <FormRow
        title="Behavior:"
        keepTooltipSpace
        input={
          <Select
            defaultValue={props.command.template.operation}
            style={{ width: '100%' }}
            onChange={(e) => props.onTemplateOptionsChange({ operation: e as 'router' | 'blank' | 'self' })}
          >
            <Select.Option value="blank">New tab</Select.Option>
            <Select.Option value="self">Same tab</Select.Option>
            <Select.Option value="router">Use router</Select.Option>
          </Select>
        }
      />
      <FormRow
        title="Url:"
        overflow="hidden"
        info={
          props.isThirdParty
            ? 'This field is not editable as it is provided by ' + capitalize(props.command.third_party_source)
            : `Your link can reference arguments and context.`
        }
        input={
          props.isThirdParty ? (
            <Input.TextArea
              value={props.command.template.value as string}
              disabled={true}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          ) : (
            <AutoCompleteTextArea
              onChange={onChange}
              value={props.command.template.value as string}
              placeholder={placeholder}
              options={interpolationOptions}
            />
          )
        }
      />
    </div>
  );
};

const CallbackSelect = (props: IActionDetailProps) => {
  const { commands } = useAppContext();
  const { userCallbacks } = useWindowInfo();
  const template = props.command.template;
  const [metadata, setMetadata] = React.useState(JSON.stringify(template.metadata || {}));
  const [metadataPlaceholder, setMetadataPlaceholder] = React.useState(template.metadata || {});

  const referencedCallbacks = _.uniq(
    commands
      .filter((c) => c.template.type === 'callback')
      .map((c) => c.template.value as string)
      .filter((callbackName: string) => !userCallbacks.includes(callbackName) && callbackName.length > 0)
      .filter((c) => !c.startsWith('__standalone-editor')),
  );

  const callbackOptions = [
    { label: 'Active callbacks', options: userCallbacks.map((c) => ({ label: c, value: c })) },
    {
      label: 'Callbacks referenced by other commands',
      options: referencedCallbacks.map((c) => ({ label: c, value: c })),
    },
  ].filter((option) => option.options.length > 0);

  const handleMetadataChange = () => {
    try {
      const parsedMetadata = JSON.parse(metadata);
      setMetadataPlaceholder(parsedMetadata);
      props.onTemplateOptionsChange({ metadata: parsedMetadata });
    } catch (err: any) {
      cmdToast.error('Invalid JSON.');
    }
  };

  const argNames = Object.keys(props.command.arguments);
  const exampleArgsString = argNames.length > 0 ? `{${argNames.join(', ')}}, context` : '';

  const codeExample = `function myCallback(${exampleArgsString}) {
    // Your code here
}

window.CommandBar.addCallback(
  "${props.command.template.value || '<type a key above>'}",
  myCallback
);`;

  return (
    <>
      <FormRow
        keepTooltipSpace
        title="Callback key:"
        input={
          <AutoComplete
            style={{ width: '100%' }}
            onChange={(e) => props.onValueChange(e)}
            options={callbackOptions}
            value={template.value}
          />
        }
        description={
          <div style={{ width: '100%', opacity: 0.7, display: 'flex' }}>
            <ButtonCollapse header={'Example Code'}>
              <Code content={codeExample} background="#fff" />
              <div style={{ padding: '10px 20px 10px 20px' }}>
                When the action is executed, <Typography.Text code>myCallback</Typography.Text> will be triggered.&nbsp;{' '}
                <a
                  style={{ color: CB_COLORS.primary }}
                  target="_blank"
                  href="https://www.commandbar.com/docs/sdk/callbacks/addcallback/"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            </ButtonCollapse>
          </div>
        }
      />
      <FormRow
        title={'Metadata:'}
        keepTooltipSpace
        input={
          <JSONInput
            placeholder={metadataPlaceholder}
            onChange={(e: any) => {
              setMetadata(e.json);
            }}
            onBlur={handleMetadataChange}
            height="auto"
            width="auto"
            confirmGood={false}
          />
        }
      />
    </>
  );
};

const CodeInput = (props: IActionDetailProps) => {
  return (
    <FormRow
      keepTooltipSpace
      title="Script:"
      input={<span>Execute this script when this command is executed:</span>}
      description={
        <CodeEditor
          placeholder={"// console.log('Executed command!', args, context);\n\n"}
          value={props.command.template.value.toString()}
          onValueChange={(code) => props.onValueChange(code)}
          highlight={(code) => {
            let highlighted;
            try {
              const highlight = hljs.highlight('javascript', code);
              highlighted = highlight.value;
            } catch (err) {
              highlighted = 'Error...';
            }

            return highlighted;
          }}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: '4px',
            paddingBottom: '18px',
          }}
        />
      }
    />
  );
};

const ActionInput = (props: IActionDetailProps) => {
  return (
    <FormRow
      keepTooltipSpace
      title="Experience:"
      input={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ActionEditor
            possibleTypes={[
              'click',
              'link',
              'page',
              'questlist',
              'nudge',
              'help_doc',
              'video',
              'open_chat',
              'open_copilot',
              'open_helphub',
            ]}
            action={(props.command.template.value as Action) || { type: 'no_action' }}
            onActionChange={(action) => {
              props.onValueChange(action);
            }}
          />
        </div>
      }
    />
  );
};

type IClickCommand = IActionDetailProps & {
  command: { template: { type: 'click' | 'clickBySelector' | 'clickByXpath' } };
};

const ClickSelect = (props: IClickCommand) => {
  return (
    <FormRow
      keepTooltipSpace
      title="Elements to click:"
      input={
        <span>
          {`Add elements to click (CSS or XPath) below. If multiple elements are provided, they will be clicked in
          sequence. For extra functionality, you can write 'pauseXXX' to pause for XXX ms or 'blur selector' to blur a selected element.`}
        </span>
      }
      description={
        <div>
          <div>
            {props.command.template.value.map((v, i) => (
              <div style={{ display: 'flex', alignItems: 'center' }} key={i}>
                <Input
                  placeholder={'#commandbar'}
                  onKeyDown={(e) => e.stopPropagation()}
                  value={v}
                  onChange={(e) => props.onValueChange(transformIfJSPath(e.target.value), i)}
                  addonBefore={'Click'}
                />
                <Trash04 style={{ color: 'red', marginLeft: '8px' }} onClick={() => props.onClickElemListDelete(i)} />
                {isSelectorFragile(v) && (
                  <Tooltip content={SELECTOR_WARNING_TEXT}>
                    <AlertTriangle color="rgb(216, 150, 20)" style={{ marginLeft: '8px' }} />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
          <div style={{ padding: '10px 0px 0px 0px' }}>
            <Space>
              <ClickRecorder
                onValueChange={(element) => {
                  props.onValueChange([...props.command.template.value, element.selector]);
                }}
              >
                Record new element
              </ClickRecorder>
              {props.onClickElemListAdd && (
                <CmdButton icon={<Plus />} onClick={props.onClickElemListAdd}>
                  Add Element
                </CmdButton>
              )}
            </Space>
          </div>
        </div>
      }
    />
  );
};

const VideoInput = (props: IActionDetailProps) => {
  const { commands } = useAppContext();
  const { context } = useWindowInfo();

  const interpolationOptions = getInterpolationObjects(commands, props.command, context);

  return (
    <FormRow
      keepTooltipSpace
      title="Video:"
      input={
        <AutoCompleteTextArea
          placeholder={`Video URL ('https://...') or Embed Code('<iframe ...>')`}
          onChange={(e: any) => props.onValueChange(e)}
          value={props.command.template.value as string}
          options={interpolationOptions}
        />
      }
      info={
        'When the command is executed, the video will be played within the bar. You can provide a URL to a video file, a Youtube URL, or an snippet to embed a video.'
      }
    />
  );
};

const HelpDocInput = (props: IActionDetailProps) => {
  const { commands, organization } = useAppContext();
  const { context } = useWindowInfo();

  const interpolationOptions = getInterpolationObjects(commands, props.command, context);

  const content = getFirstDetail(props.command.content);

  return (
    <div style={{ marginTop: 15 }}>
      <FormRow
        keepTooltipSpace
        title="HTML:"
        input={
          <PrettyCodeEditor
            placeholder={'<p>This content will be shown when the command is selected.</p>'}
            value={content?.value ?? (props.command.arguments?.__html__?.value as any)?.source ?? ''}
            language="jsx"
            onValueChange={(code: string) => {
              if (content?.value) {
                props.updateCommandState.updateCommand((draft) => {
                  draft.content = [{ type: 'html', value: code }];
                });
              } else if ('__html__' in props.command.arguments) {
                props.updateCommandState.updateCommand((draft) => {
                  draft.arguments = {
                    ...draft.arguments,
                    __html__: {
                      ...draft.arguments.__html__,
                      value: {
                        source: code,
                      },
                      is_private: true,
                      type: 'html',
                    },
                  };
                });
              } else {
                props.updateCommandState.updateCommand((draft) => {
                  draft.content = [{ type: 'html', value: code }];
                });
              }
            }}
          />
        }
      />
      <FormRow
        title="Source URL:"
        overflow="hidden"
        info={
          props.isThirdParty
            ? 'This field is not editable as it is provided by ' + capitalize(props.command.third_party_source)
            : `Specifying a URL will allow users to open the original content page. Your link can reference arguments and context.`
        }
        input={
          props.isThirdParty ? (
            <Input.TextArea
              value={props.command.template.value as string}
              disabled={true}
              autoSize={{ minRows: 1, maxRows: 5 }}
            />
          ) : (
            <AutoCompleteTextArea
              onChange={props.onValueChange}
              value={props.command.template.value as string}
              placeholder={DEFAULT_PLACEHOLDER}
              options={interpolationOptions}
            />
          )
        }
      />
      {!!!(props.command.arguments?.__html__?.value as any)?.source && (
        <FormRow
          title="Behavior"
          keepTooltipSpace
          info={
            'Upon command execution, if no HTML source is set, the specified source URL will be opened according to the set behaviour.'
          }
          input={
            <Select
              defaultValue={props.command.template.operation}
              style={{ width: '100%' }}
              onChange={(e) =>
                props.onTemplateOptionsChange({ operation: e as 'router' | 'blank' | 'self' | 'help_hub' })
              }
            >
              <Select.Option value="blank">New tab</Select.Option>
              <Select.Option value="self">Same tab</Select.Option>
              <Select.Option value="router">Use router</Select.Option>
            </Select>
          }
        />
      )}
      {!organization?.helphub_enabled && (
        <FormRow
          gutter={[8, 24]}
          title="Show Dashboard"
          info="Fill the bar with the doc after clicking"
          input={
            <div style={{ display: 'flex' }}>
              <Checkbox
                defaultChecked={'__html__' in props.command.arguments}
                onChange={(e) => {
                  if (e.target.checked) {
                    if (!('__html__' in props.command.arguments)) {
                      const new_key = getNextSortKey(props.command.arguments);
                      props.updateCommandState.argument.new('__html__', new_key);
                    }

                    const content = getFirstDetail(props.command.content);
                    props.updateCommandState.argument.updateValue('__html__', {
                      value: {
                        source: content?.value,
                      },
                      is_private: true,
                      type: 'html',
                    });

                    props.updateCommandState.updateCommand((draft) => {
                      draft.content = [];
                    });
                  } else {
                    const content = (props.command.arguments.__html__?.value as any)?.source;
                    props.updateCommandState.updateCommand((draft) => {
                      delete draft.arguments.__html__;
                    });

                    props.updateCommandState.updateCommand((draft) => {
                      draft.content = [{ type: 'html', value: content }];
                    });
                  }
                }}
              />
            </div>
          }
        />
      )}
    </div>
  );
};

/*******************************************************************************/
/* Click Recorder
/*******************************************************************************/

const WebhookInput = (props: IActionDetailProps) => {
  return (
    <FormRow
      title={'Endpoint:'}
      keepTooltipSpace
      input={
        <Input
          placeholder={'https://...'}
          onKeyDown={(e) => e.stopPropagation()}
          value={props.command.template.value.toString()}
          onChange={(e) => props.onValueChange(e.target.value)}
          addonBefore={'Webhook'}
        />
      }
      description={
        <div style={{ lineHeight: 1.9 }}>
          <div>The request payload will be the same as the parameters returned to a callback command.</div>
        </div>
      }
    />
  );
};

const AppcuesInput = (props: IActionDetailProps) => {
  return (
    <FormRow
      title={'Content ID:'}
      keepTooltipSpace
      input={
        <Input
          placeholder={'<contentId>'}
          onKeyDown={(e) => e.stopPropagation()}
          value={props.command.template.value.toString()}
          onChange={(e) => props.onValueChange(e.target.value)}
        />
      }
    />
  );
};

const PendoGuideInput = (props: IActionDetailProps) => {
  return (
    <FormRow
      title={'Guide ID:'}
      keepTooltipSpace
      input={
        <Input
          placeholder={'<guideId>'}
          onKeyDown={(e) => e.stopPropagation()}
          value={props.command.template.value.toString()}
          onChange={(e) => props.onValueChange(e.target.value)}
        />
      }
    />
  );
};

const RequestInput = (props: IActionDetailProps) => {
  const { commands } = useAppContext();
  const { userCallbacks, context } = useWindowInfo();
  const referencedCallbacks = _.uniq(
    commands
      .filter((c) => c.template.type === 'callback')
      .map((c) => c.template.value as string)
      .filter((callbackName: string) => !userCallbacks.includes(callbackName) && callbackName.length > 0),
  );

  const value = props.command.template.value as RequestType;
  const [headers, setHeaders] = React.useState(JSON.stringify(value.headers || {}));
  const [headerPlaceholder, setHeaderPlaceholder] = React.useState(value.headers || {});
  const [body, setBody] = React.useState(JSON.stringify(value.body || {}));
  const [bodyPlaceholder, setBodyPlacholder] = React.useState(value.body || {});
  const callbackOptions = [
    { label: 'Active callbacks', options: userCallbacks.map((c) => ({ label: c, value: c })) },
    {
      label: 'Callbacks referenced by other commands',
      options: referencedCallbacks.map((c) => ({ label: c, value: c })),
    },
  ];

  const handleMethodChange = (method: 'post' | 'get' | 'put' | 'patch' | 'delete' | 'head') => {
    props.onValueChange({ ...value, method });
  };

  const handleUrlChange = (url: string) => {
    props.onValueChange({ ...value, url });
  };

  const handleHeadersChange = () => {
    let _headers: any = {};
    try {
      _headers = JSON.parse(headers);
      setHeaderPlaceholder(_headers);
      props.onValueChange({ ...value, headers: _headers });
    } catch (err: any) {
      cmdToast.error('Invalid json');
    }
  };

  const handleBodyChange = () => {
    let _body: any = {};
    try {
      _body = JSON.parse(body);
      setBodyPlacholder(_body);
      props.onValueChange({ ...value, body: _body });
    } catch (err: any) {
      cmdToast.error('Invalid json');
    }
  };

  const handleOnSuccessChange = (e: any) => {
    props.onValueChange({ ...value, onSuccess: e });
  };

  const handleOnFailureChange = (e: any) => {
    props.onValueChange({ ...value, onError: e });
  };

  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));

  return (
    <div style={{ marginTop: 15 }}>
      <FormRow
        title="Method"
        keepTooltipSpace
        input={
          <Select
            style={{ width: '100%' }}
            defaultValue={value.method !== 'options' ? value.method : null}
            onChange={(e: 'post' | 'get' | 'put' | 'patch' | 'delete' | 'head') => handleMethodChange(e)}
          >
            <Select.Option value="post">POST</Select.Option>
            <Select.Option value="get">GET</Select.Option>
            <Select.Option value="put">PUT</Select.Option>
            <Select.Option value="patch">PATCH</Select.Option>
            <Select.Option value="delete">DELETE</Select.Option>
            <Select.Option value="head">HEAD</Select.Option>
          </Select>
        }
      />
      <FormRow
        title={'Url'}
        keepTooltipSpace
        input={
          <AutoCompleteTextArea
            placeholder={REQUEST_DEFAULT_PLACEHOLDER}
            onChange={handleUrlChange}
            options={contextKeys}
            value={value ? value.url : ''}
          />
        }
      />
      <FormRow
        title={'Headers'}
        keepTooltipSpace
        input={
          <JSONInput
            placeholder={headerPlaceholder}
            onChange={(e: any) => {
              setHeaders(e.json);
            }}
            onBlur={handleHeadersChange}
            height="auto"
            width="auto"
            confirmGood={false}
          />
        }
      />
      <FormRow
        title={'Body'}
        keepTooltipSpace
        input={
          <JSONInput
            placeholder={bodyPlaceholder}
            onChange={(e: any) => {
              setBody(e.json);
            }}
            onBlur={handleBodyChange}
            height="auto"
            width="auto"
            confirmGood={false}
          />
        }
      />
      <FormRow
        title={'onSuccess'}
        keepTooltipSpace
        input={
          <AutoComplete
            style={{ width: '100%' }}
            onChange={handleOnSuccessChange}
            options={callbackOptions}
            value={value.onSuccess}
          />
        }
      />
      <FormRow
        title={'onError'}
        keepTooltipSpace
        input={
          <AutoComplete
            style={{ width: '100%' }}
            onChange={handleOnFailureChange}
            options={callbackOptions}
            value={value.onError}
          />
        }
      />
    </div>
  );
};
