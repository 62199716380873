import React, { useEffect } from 'react';

import { Route, RouteComponentProps, Switch, useHistory } from 'react-router';
import Recommendations from './commands/RecommendationTab/Recommendations';
import Categories from './commands/CategoriesTab/Categories';
import SpotlightSettings from './commands/SettingsTab/SpotlightSettings';
import { useAppContext } from '../AppStateContext';
import { Tabs, TabPane, PaddingContainer } from '@commandbar/design-system/components/antd';
import Rules from './commands/RulesTab/Rules';
import { ArgumentChoicesTable } from './context/ArgumentChoicesTable';
import Sender from '../management/Sender';
import LogoutHeader from './components/LogoutHeader';
import { RecordsTable } from './commands/RecordsTable';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

type CommandsEditorProps = RouteComponentProps<Record<string, never>> & {
  setActiveCommand: (command: IEditorCommandType) => void;
};

const Commands: React.FC<CommandsEditorProps> = ({ match, setActiveCommand }) => {
  const history = useHistory();
  const { isStudio, commandBarReady } = useAppContext();
  const { user } = useAuth();

  useEffect(() => {
    if (isStudio && commandBarReady) {
      Sender.openBar();
      return () => {
        Sender.closeBar();
      };
    }
  }, [commandBarReady]);

  const isEditor = hasRequiredRole(user, 'editor');

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
      <Switch>
        <Route exact path={`${match.path}/:page?`}>
          {({ match }) => {
            return (
              <>
                <LogoutHeader />
                <Tabs
                  activeKey={
                    match?.params?.page
                      ? match.url
                      : isEditor
                      ? editorRoutes.SPOTLIGHT_ROUTE
                      : editorRoutes.SPOTLIGHT_ARGUMENTS_ROUTE
                  }
                  onChange={(key) => history.push(key)}
                  isStudio={isStudio}
                  destroyInactiveTabPane={true}
                  type="card"
                  tabBarStyle={{
                    paddingTop: isStudio ? '9px' : 0,
                    marginTop: -10,
                    paddingLeft: '16px',
                    display: 'inherit',
                  }}
                >
                  {isEditor && (
                    <React.Fragment>
                      <TabPane tab="Categories" key={editorRoutes.SPOTLIGHT_ROUTE}>
                        <PaddingContainer>
                          <Categories />
                        </PaddingContainer>
                      </TabPane>
                      <TabPane tab="Records" key={editorRoutes.SPOTLIGHT_RECORDS_ROUTE}>
                        <PaddingContainer>
                          <RecordsTable setActiveCommand={setActiveCommand} />
                        </PaddingContainer>
                      </TabPane>
                      <TabPane tab="Recommendations" key={editorRoutes.SPOTLIGHT_RECOMMENDATIONS_ROUTE}>
                        <PaddingContainer>
                          <Recommendations />
                        </PaddingContainer>
                      </TabPane>
                      <TabPane tab="Settings" key={editorRoutes.SPOTLIGHT_SETTINGS_ROUTE}>
                        <PaddingContainer>
                          <SpotlightSettings />
                        </PaddingContainer>
                      </TabPane>
                      <TabPane tab="Rules" key={editorRoutes.SPOTLIGHT_RULES_ROUTE}>
                        <PaddingContainer>
                          <Rules />
                        </PaddingContainer>
                      </TabPane>
                    </React.Fragment>
                  )}
                  <TabPane tab="Arguments" key={editorRoutes.SPOTLIGHT_ARGUMENTS_ROUTE}>
                    <PaddingContainer>
                      <ArgumentChoicesTable />
                    </PaddingContainer>
                  </TabPane>
                </Tabs>
              </>
            );
          }}
        </Route>
      </Switch>
    </div>
  );
};

export default Commands;
