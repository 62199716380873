import * as t from 'io-ts';

import {
  BatchEditorCommandResponseV,
  BatchOperationV,
  CommandV,
  EditorCommandV,
  EditorCommandLiteV,
  CommandLiteV,
  ContentTypeV,
} from './command';

import {
  ArgumentMapV,
  ArgumentTypeV,
  ConditionV,
  ConditionOperatorV,
  StepArgumentTypeV,
  ContextArgumentV,
  SetArgumentV,
  DynamicArgumentV,
} from './helpers/argument';
import { GenericBatchRequest } from './generics';
import { OptionGroupRenderAsV } from './helpers/optionGroup';

import { CommandCategoryV } from './commandCategory';
import {
  OrganizationStatusV,
  KeyEventV,
  RecordSettingsByContextKeyV,
  InternalSettingsV,
  OrganizationV,
} from './organization';
import { OrganizationSettingsV } from './organizationSettings';

import { UserV } from './user';
import { ContextV } from './context';
import { HistoryEventV } from './historyEvent';
import { HelpDocSyncLogMessageV, HelpDocsSyncV } from './helpDocsSync';
import { HelpDocsIntegrationV } from './helpDocsIntegration';

import { SkinV } from './skin';
import { ThemeV, ThemeV2V, ThemeModeV } from './theme';
import { ProfileV } from './profile';
import { PlaceholderV } from './placeholder';
import { EnvReleaseInfoV, ReleaseStepV, ReleaseV } from './releases';
import { EnvironmentV } from './environment';
import {
  NudgeContentBlockV,
  NudgeContentButtonBlockV,
  NudgeContentHelpDocBlockV,
  NudgeContentImageBlockV,
  NudgeContentMarkdownBlockV,
  NudgeContentVideoBlockV,
  NudgeContentSurveyTextBlockV,
  NudgeStepContentSurveyTextShortBlockTypeV,
  NudgeContentSurveyRatingBlockV,
  NudgeContentListBlockV,
  NudgeStepV,
  NudgeV,
  OldNudgeV,
  NudgeButtonActionV,
  NudgeConditionalActionV,
  ElementSelectorV,
} from './nudge';
import { TabV } from './tab';
import { RuleExpressionAndV, RuleExpressionOrV, RuleExpressionV } from './helpers/rules';
import { ChecklistItemV, ChecklistV } from './checklist';
import { PushTriggerV } from './helpers/pushTrigger';
import { FrequencyLimitV } from './helpers/frequencyLimit';
import { AudienceV } from './helpers/audience';
import { AdditionalResourceV } from './additionalResource';
import { RecommendationSetV } from './recommendationSet';

import {
  UserMessageV,
  AIMessageV,
  AIMessageExtraV,
  MessageV,
  AIAnswerPayloadV,
  AIAnswerV,
  ContinuationsPayloadV,
  ContinuationV,
  CreateAnswerFeedbackPayloadV,
  QuestionSuggestionsPayloadV,
  QuestionSuggestionsV,
  ExperienceV,
  CreateChatPayloadV,
  ChatV,
  CopilotSettingsPreviewV,
  HandoffPayloadV,
  ExternalChatPayloadV,
  ExternalChatHistoryV,
  ChatOwnershipV,
  UserAttachmentV,
  SourceDocV,
} from './chat';
import { HelpDocHitV, SearchHelpDocsPayloadV, SearchHelpDocsResponseV } from './helpDocsSearch';
import {
  ExperienceHitV,
  ExperienceTypeOptions,
  SearchExperiencesPayloadV,
  SearchExperiencesResponseV,
} from './experiencesSearch';
import { HelpHubLauncherSettingsV, HelpHubSearchActionsV } from './helpHub';

import { EntityChangeV } from './entityChanges';

import { Flags } from './flags';
import {
  CopilotPersonalityAdjectiveV,
  CopilotPersonalityResponseFormatV,
  CopilotPersonalityResponseLengthV,
  CopilotPersonalityV,
} from './helpers/copilotPersonality';
import { ExperienceTemplateV } from './experienceTemplate';
import { APIV } from './api';
import { LocalizedMessagePatchV, LocalizedMessageV, LocalizedMessages } from './localizedMessage';
import { WorkflowV } from './workflow';
import { KeywordV } from './keyword';
import { CopilotFallbackV } from './copilotFallbacks';

/*******************************************************************************/
/* Model objects
/*******************************************************************************/

export type IContextType = t.TypeOf<typeof ContextV> & unknown;
export type IUserType = t.TypeOf<typeof UserV> & unknown;
export type ICommandType = t.TypeOf<typeof CommandV> & unknown;
export type IEditorCommandType = t.TypeOf<typeof EditorCommandV> & unknown;

export type IEditorCommandTypeLite = t.TypeOf<typeof EditorCommandLiteV> & unknown;
export type ICommandTypeLite = t.TypeOf<typeof CommandLiteV> & unknown;

export type { ICommandFromClientType } from './ICommandFromClientType';
export type ICommandCategoryType = t.TypeOf<typeof CommandCategoryV> & unknown;
export type IHistoryEventType = t.TypeOf<typeof HistoryEventV> & unknown;
export type IEntityChangeType = t.TypeOf<typeof EntityChangeV> & unknown;
export type IHelpDocsSyncType = t.TypeOf<typeof HelpDocsSyncV> & unknown;
export type IHelpDocsSyncLogMessageType = t.TypeOf<typeof HelpDocSyncLogMessageV> & unknown;
export type IHelpDocsIntegrationType = t.TypeOf<typeof HelpDocsIntegrationV> & unknown;
export type IHelpHubLauncherSettingsType = t.TypeOf<typeof HelpHubLauncherSettingsV> & unknown;
export type IHelpHubSearchActionsType = t.TypeOf<typeof HelpHubSearchActionsV> & unknown;
export type IOrganizationType = t.TypeOf<typeof OrganizationV> & unknown;
export type IOrganizationStatusType = t.TypeOf<typeof OrganizationStatusV> & unknown;
export type IInternalSettingsType = t.TypeOf<typeof InternalSettingsV> & unknown;
export type ISkinType = t.TypeOf<typeof SkinV> & unknown;
export type IThemeType = t.TypeOf<typeof ThemeV> & unknown;
export type IThemeV2Type = t.TypeOf<typeof ThemeV2V> & unknown;
export type IThemeModeType = t.TypeOf<typeof ThemeModeV> & unknown;
export type IProfileType = t.TypeOf<typeof ProfileV> & unknown;
export type IOrganizationSettingsType = t.TypeOf<typeof OrganizationSettingsV> & unknown;
export type IPlaceholderType = t.TypeOf<typeof PlaceholderV> & unknown;
export type ITabType = t.TypeOf<typeof TabV> & unknown;
export type IEnvironmentType = t.TypeOf<typeof EnvironmentV> & unknown;
export type IChatType = t.TypeOf<typeof ChatV> & unknown;

export type IReleaseStep = t.TypeOf<typeof ReleaseStepV> & unknown;
export type IRelease = t.TypeOf<typeof ReleaseV> & unknown;
export type IEnvReleaseInfo = t.TypeOf<typeof EnvReleaseInfoV> & unknown;

/*******************************************************************************/
/* API request/response types
/*******************************************************************************/

export type IBatchOperation = t.TypeOf<typeof BatchOperationV> & unknown;
export type IBatchEditorCommandRequst = t.TypeOf<typeof GenericBatchRequest> & unknown;
export type IBatchEditorCommandResponse = t.TypeOf<typeof BatchEditorCommandResponseV> & unknown;

/*******************************************************************************/
/* Helper objects
/*******************************************************************************/
export type IKeyEventCategory = t.TypeOf<typeof KeyEventV> & unknown;
export type IArgumentMap = t.TypeOf<typeof ArgumentMapV> & unknown;
export type IArgumentType = t.TypeOf<typeof ArgumentTypeV> & unknown;
export type IStepArgumentType = t.TypeOf<typeof StepArgumentTypeV> & unknown;

export type IRuleExpression = t.TypeOf<typeof RuleExpressionV> & unknown;
export type IRuleExpressionAnd = t.TypeOf<typeof RuleExpressionAndV> & unknown;
export type IRuleExpressionOr = t.TypeOf<typeof RuleExpressionOrV> & unknown;

export type IConditionType = t.TypeOf<typeof ConditionV> & unknown;
export type IConditionOperatorType = t.TypeOf<typeof ConditionOperatorV> & unknown;

export type IContextArgumentType = t.TypeOf<typeof ContextArgumentV> & unknown;
export type ISetArgumentType = t.TypeOf<typeof SetArgumentV> & unknown;
export type IDynamicArgumentType = t.TypeOf<typeof DynamicArgumentV> & unknown;
export type OptionGroupRenderAsType = t.TypeOf<typeof OptionGroupRenderAsV> & unknown;

export type IOldNudgeType = t.TypeOf<typeof OldNudgeV> & unknown;
export type INudgeType = t.TypeOf<typeof NudgeV> & unknown;
export type IAudienceType = t.TypeOf<typeof AudienceV> & unknown;
export type IPushTrigger = t.TypeOf<typeof PushTriggerV> & unknown;
export type IFrequencyLimit = t.TypeOf<typeof FrequencyLimitV> & unknown;

export type ElementInformation = t.TypeOf<typeof ElementSelectorV> & unknown;
export type INudgeStepType = t.TypeOf<typeof NudgeStepV> & unknown;
export type INudgeTooltipStepType = INudgeStepType & { form_factor: { type: 'tooltip' } };
export type INudgePopoverStepType = INudgeStepType & { form_factor: { type: 'popover' } };
export type INudgeModalStepType = INudgeStepType & { form_factor: { type: 'modal' } };
export type INudgeBannerStepType = INudgeStepType & { form_factor: { type: 'banner' } };
export type INudgePinStepType = INudgeStepType & { form_factor: { type: 'pin' } };
export type INudgeCursorStepType = INudgePinStepType & { form_factor: { copilot_intro: true } };
export type INudgeAnchorableStepType = INudgeTooltipStepType | INudgePinStepType;
export type INudgeStepContentBlockType = t.TypeOf<typeof NudgeContentBlockV> & unknown;
export type INudgeStepContentMarkdownBlockType = t.TypeOf<typeof NudgeContentMarkdownBlockV> & unknown;
export type INudgeStepContentImageBlockType = t.TypeOf<typeof NudgeContentImageBlockV> & unknown;
export type INudgeStepContentVideoBlockType = t.TypeOf<typeof NudgeContentVideoBlockV> & unknown;
export type INudgeStepContentHelpDocBlockType = t.TypeOf<typeof NudgeContentHelpDocBlockV> & unknown;
export type INudgeStepContentSurveyTextBlockType = t.TypeOf<typeof NudgeContentSurveyTextBlockV> & unknown;
export type INudgeStepContentSurveyTextShortBlockType = t.TypeOf<typeof NudgeStepContentSurveyTextShortBlockTypeV> &
  unknown;
export type INudgeContentSurveyRatingBlockType = t.TypeOf<typeof NudgeContentSurveyRatingBlockV> & unknown;
export type INudgeContentListBlock = t.TypeOf<typeof NudgeContentListBlockV> & unknown;
export type INudgeStepSurveyBlockType =
  | INudgeStepContentSurveyTextBlockType
  | INudgeStepContentSurveyTextShortBlockType
  | INudgeContentSurveyRatingBlockType
  | INudgeContentListBlock;

export type INudgeStepContentButtonBlockType = t.TypeOf<typeof NudgeContentButtonBlockV> & unknown;
export type INudgeButtonAction = t.TypeOf<typeof NudgeButtonActionV> & unknown;
export type INudgeButtonConditionalAction = t.TypeOf<typeof NudgeConditionalActionV> & unknown;
export type INudgeClientType = {
  nudge: INudgeType;
  passedConditionsInLastEvaluation: boolean;
  lastTriggeredTs?: number;
};
export type IKeyword = t.TypeOf<typeof KeywordV> & unknown;

export type IExperienceTemplate = t.TypeOf<typeof ExperienceTemplateV> & unknown;

export type IChecklist = t.TypeOf<typeof ChecklistV> & {
  _editorState?: { timestamp?: number; initial_active_item?: number; expanded?: boolean };
} & unknown;
export type IChecklistItem = t.TypeOf<typeof ChecklistItemV> & unknown;

export type IMessageType = t.TypeOf<typeof MessageV> & unknown;
export type IUserMessageType = t.TypeOf<typeof UserMessageV> & unknown;
export type IAIMessageType = t.TypeOf<typeof AIMessageV> & unknown;
export type IUserAttachmentType = t.TypeOf<typeof UserAttachmentV> & unknown;
export type IAIMessageExtraType = t.TypeOf<typeof AIMessageExtraV> & unknown;
export type IQuestionSuggestionsType = t.TypeOf<typeof QuestionSuggestionsV> & unknown;
export type IQuestionSuggestionsPayloadType = t.TypeOf<typeof QuestionSuggestionsPayloadV> & unknown;
export type ICreateChatPayloadType = t.TypeOf<typeof CreateChatPayloadV> & unknown;
export type IContinuationType = t.TypeOf<typeof ContinuationV> & unknown;
export type ISourceDocType = t.TypeOf<typeof SourceDocV> & unknown;
export type IExperienceType = t.TypeOf<typeof ExperienceV> & unknown;
export type IContinuationsPayloadType = t.TypeOf<typeof ContinuationsPayloadV> & unknown;
export type IAIAnswerType = t.TypeOf<typeof AIAnswerV> & unknown;
export type IAIAnswerPayloadType = t.TypeOf<typeof AIAnswerPayloadV> & unknown;
export type IHandoffPayloadType = t.TypeOf<typeof HandoffPayloadV> & unknown;
export type IExternalChatPayloadType = t.TypeOf<typeof ExternalChatPayloadV> & unknown;
export type IExternalChatHistoryType = t.TypeOf<typeof ExternalChatHistoryV> & unknown;
export type IChatOwnershipType = t.TypeOf<typeof ChatOwnershipV> & unknown;
export type ICreateAnswerFeedbackPayloadType = t.TypeOf<typeof CreateAnswerFeedbackPayloadV> & unknown;
export type ISearchHelpDocsPayloadType = t.TypeOf<typeof SearchHelpDocsPayloadV> & unknown;
export type ISearchHelpDocsResponseType = t.TypeOf<typeof SearchHelpDocsResponseV> & unknown;
export type IHelpDocHitType = t.TypeOf<typeof HelpDocHitV> & unknown;
export type ISearchExperiencesPayloadType = t.TypeOf<typeof SearchExperiencesPayloadV> & unknown;
export type ISearchExperiencesResponseType = t.TypeOf<typeof SearchExperiencesResponseV> & unknown;
export type IExperienceTypeOptions = t.TypeOf<typeof ExperienceTypeOptions> & unknown;
export type IExperienceHitType = t.TypeOf<typeof ExperienceHitV> & unknown;
export type ContentType = t.TypeOf<typeof ContentTypeV> & unknown;
export type ICopilotPersonalityType = t.TypeOf<typeof CopilotPersonalityV> & unknown;
export type ICopilotPersonalityAdjectivesType = t.TypeOf<typeof CopilotPersonalityAdjectiveV> & unknown;
export type ICopilotPersonalityResponseLengthType = t.TypeOf<typeof CopilotPersonalityResponseLengthV> & unknown;
export type ICopilotPersonalityResponseFormatType = t.TypeOf<typeof CopilotPersonalityResponseFormatV> & unknown;
export type ICopilotSettingsPreviewType = t.TypeOf<typeof CopilotSettingsPreviewV> & unknown;

export interface ICommandInput {
  text: string;
  command: ICommandType;
  context: IUserContext;
}

export interface IArgValues {
  [arg: string]: string | number | undefined;
}

export type IHistoryType = Array<{
  activeCommand: ICommandType;
  options: ICommandType[];
}>;

// End user types

export interface IUserContext {
  [variable: string]: any;
}

export interface IEndUserAnalytics {
  num_command_executions: number;
  num_shortcut_command_executions: number;
  num_sessions: number;
  num_opens: number;
  num_deadends: number;
  first_seen_at: string;
  last_seen_at: string;
}

export interface ICallbackMap {
  [variable: string]: (...args: any[]) => unknown;
}

export interface IResourceType extends IUserContext {
  _cbLinkedCommmands: Array<{ command: number; arg: string }>;
}

export type IAdditionalResource = t.TypeOf<typeof AdditionalResourceV> & unknown;
export type IRecommendationSet = t.TypeOf<typeof RecommendationSetV> & unknown;
export type ILocalizedMessage = t.TypeOf<typeof LocalizedMessageV> & unknown;
export type ILocalizedMessagePatch = t.TypeOf<typeof LocalizedMessagePatchV> & unknown;

export type IAPI = t.TypeOf<typeof APIV> & unknown;
export type IWorkflow = t.TypeOf<typeof WorkflowV> & unknown;
export type ICopilotFallback = t.TypeOf<typeof CopilotFallbackV> & unknown;

export type IConfigEndpointResponse = {
  commands: any[];
  categories: any[];
  organization: any;
  environments_with_versions?: any[];
  placeholders?: any[];
  nudges?: IOldNudgeType[];
  nudges_v2?: INudgeType[];
  tabs?: ITabType[];
  checklists?: IChecklist[];
  helphub_additional_resources?: IAdditionalResource[];
  helphub_recommendation_sets?: IRecommendationSet[];
  __meta__?: {
    latest_foobar_version?: string;
  };
  localized_messages: LocalizedMessages;
  themes?: IThemeType[];
  keywords: IKeyword[];
};

export type IConfigType = {
  commands: ICommandType[];
  categories: ICommandCategoryType[];
  organization: IOrganizationType;
  environments?: IEnvironmentType[];
  placeholders: IPlaceholderType[];
  nudges: INudgeType[];
  tabs: ITabType[];
  checklists: IChecklist[];
  helphub_additional_resources: IAdditionalResource[];
  helphub_recommendation_sets: IRecommendationSet[];
  __meta__?: {
    latest_foobar_version?: string;
  };
  flags: Flags;
  localized_messages: LocalizedMessages;
  themes: IThemeType[];
  keywords: IKeyword[];
};

// object search
export type { IRecordSettings } from './IRecordSettings';
export type IRecordSettingsByContextKey = t.TypeOf<typeof RecordSettingsByContextKeyV> & unknown;

export type { DetailPreviewObjectType, DetailPreviewType, DataRowMetadata } from './detailPreview';

/*******************************************************************************/
/* Type guards
/*******************************************************************************/
export const isCommand = (command?: ICommandType | any): command is ICommandType => {
  if (!command) return false;
  if (!command.hasOwnProperty('text')) return false;
  if (!command.hasOwnProperty('id')) return false;
  if (!command.hasOwnProperty('organization')) return false;
  if (!command.hasOwnProperty('arguments')) return false;
  return true;
};

export const isResource = (option: any): option is IResourceType => {
  if (option && (option as IResourceType)._cbLinkedCommands) return true;
  return false;
};

export const isContextArgument = (argument: IArgumentType): argument is IContextArgumentType => {
  return argument.type === 'context';
};

export const isTimeArgument = (argument: IArgumentType): argument is IDynamicArgumentType => {
  return argument.type === 'provided' && argument.value === 'time';
};

export const isSurveyBlock = (block: INudgeStepContentBlockType): block is INudgeStepSurveyBlockType =>
  block.type === 'survey_text' ||
  block.type === 'survey_text_short' ||
  block.type === 'survey_rating' ||
  block.type === 'survey_list';

export type WidgetTableauSelection = {
  type: 'all' | 'nudge' | 'checklist' | 'spotlight' | 'helphub' | 'copilot';
  id: string | number;
  nudgeStepIndex?: number;
};

export const isAnchorableStep = (step?: INudgeStepType): step is INudgeAnchorableStepType =>
  step?.form_factor.type === 'pin' || step?.form_factor.type === 'tooltip';
