import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import styled from '@emotion/styled';
import 'tippy.js/dist/tippy.css';
import CB_Z from '../antd/zIndexes';

const Tooltip = styled(
  (props: TippyProps & { showIf?: boolean; inline?: boolean; containerStyle?: React.CSSProperties }) => {
    const { children, showIf, inline, ...tooltipProps } = props;
    const content = !inline ? <div style={props.containerStyle}>{children}</div> : children;

    if (showIf !== undefined) {
      if (!showIf && children) {
        return children;
      }

      return showIf || !children || !props.content ? (
        <Tippy zIndex={CB_Z.Z_TOOLTIPS} {...tooltipProps}>
          {content}
        </Tippy>
      ) : (
        children
      );
    }

    return (
      <Tippy zIndex={CB_Z.Z_TOOLTIPS} {...tooltipProps}>
        {content}
      </Tippy>
    );
  },
)`
  background-color: #fafafa !important;
  box-shadow: 0px 16px 24px rgba(36, 35, 41, 0.1), 0px 2px 4px rgba(36, 35, 41, 0.1) !important;
  border-radius: 4px !important;
  color: #44434a !important;
  padding: 4px 8px;
  font-size: 12px !important;

  .tippy-arrow {
    color: #fafafa;
  }
`;
export default Tooltip;
