'use client';

import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { ChevronLeft, ChevronRight } from '@commandbar/design-system/icons/react';
import { cn } from '../util';
import { buttonStyles } from '../button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function CmdCalendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  const today = React.useMemo(() => new Date(), []);
  const [currentMonth, setCurrentMonth] = React.useState(new Date(today.getFullYear(), today.getMonth() - 1));

  const handleMonthChange = (month: Date) => {
    setCurrentMonth(month);
  };

  const disabledDays = React.useMemo(
    () => ({
      after: today,
    }),
    [today],
  );

  const isCurrentMonthToday = () => {
    return currentMonth.getFullYear() === today.getFullYear() && currentMonth.getMonth() + 1 === today.getMonth();
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3 bg-white', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-base font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn('h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border-none'),
        nav_button_previous: 'absolute left-1',
        nav_button_next: cn(
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border-none absolute right-1',
          isCurrentMonthToday() ? 'hidden' : '',
        ),
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-8 text-base font-medium',
        row: 'flex w-full mt-2',
        cell: cn(
          'relative p-0 border-none bg-transparent text-center font-medium text-base focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-blue700 [&:has([aria-selected].day-outside)]:bg-transparent [&:has([aria-selected].day-range-end)]:rounded-r-md',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md',
        ),
        day: cn(
          'inline-flex items-center cursor-pointer justify-center whitespace-nowrap rounded-md font-medium text-base transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 hover:bg-blue100 hover:text-gray800',
          'h-8 w-8 p-0 aria-selected:opacity-100 border-none bg-transparent',
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-blue700 !text-gray100 hover:bg-blue700 hover:text-gray100 focus:bg-blue700 focus:text-gray100',
        day_today: '!bg-elementBase text-gray800',
        day_outside:
          'day-outside text-contentDisabled opacity-50  aria-selected:bg-transparent aria-selected:text-contentDisabled cursor-not-allowed',
        day_disabled: 'text-muted-foreground opacity-50 cursor-not-allowed',
        day_range_middle: 'aria-selected:bg-blue700 aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className={cn('h-4 w-4 px-sm', buttonStyles({ variant: 'default' }))} />,
        IconRight: () =>
          isCurrentMonthToday() ? null : (
            <ChevronRight className={cn('h-4 w-4 px-sm', buttonStyles({ variant: 'default' }))} />
          ),
      }}
      disabled={disabledDays}
      month={currentMonth}
      onMonthChange={handleMonthChange}
      {...props}
    />
  );
}

CmdCalendar.displayName = 'Calendar';

export { CmdCalendar };
