import React, { type MouseEvent, useCallback } from 'react';
import { AnimationTransition, builtinKeyframes } from 'shared/util/hooks/useDelayUnmount';

import type { Action, ChatHandoffAction, LabeledAction, OpenChatAction } from '@cb/types/entities/command/actions';
import type { IAIMessageType, ICommandType } from '@commandbar/internal/middleware/types';

import { useAction } from 'shared/util/hooks/useAction';
import * as GlobalActions from 'shared/store/global-actions';
import * as HelpHubServiceActions from 'products/helphub/service-actions';
import { isAction } from '@cb/types/entities/command/actions';

import { ActionButton } from 'shared/components/ActionButton';
import { getNativeCallback } from 'shared/util/hooks/useMobileExperience';
import { useChatState } from '../../store/useChatState';
import { getSentry } from '@commandbar/internal/util/sentry';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledChatActionsContainer from '@commandbar/internal/client/themesV2/components/copilot/StyledChatActionsContainer';
import { AlertCircle, ArrowNarrowRight } from '@commandbar/design-system/icons/react';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { showMessage } from '@commandbar/commandbar/shared/util/hooks/useToast';
import { getNudgeById, isCursorStep } from '../../../nudges/service-selectors';
import { getNudgeStep } from '@commandbar/internal/util/nudges';

type MessageActionsProps = {
  preview: boolean;
  aiMessage: IAIMessageType;
  fallbackActions?: LabeledAction[];
  isChatHistoryPreview: boolean;
  previewOverrides?: { useThemeV2: boolean };
  nextSteps: ICommandType['next_steps'];
};

export const MessageActions: React.FC<MessageActionsProps> = (props) => {
  const { aiMessage, fallbackActions, preview, isChatHistoryPreview, previewOverrides, nextSteps } = props;
  const { contactSupport, handoff, chatHistory } = useChatState();
  const setHelpHubMinimized = useAction(HelpHubServiceActions.setHelpHubMinimized);
  const _ =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    !preview ? useStore() : emptyGlobalStore();

  /** VA: FIXME: should maybe be natively implemented to the `executeAction`? */
  const shouldHandoffChat = (action: Action): action is OpenChatAction | ChatHandoffAction => {
    if (!(action.type === 'open_chat' || action.type === 'chat_handoff')) {
      return false;
    }

    if (action?.meta?.type === 'intercom') {
      if (!window.intercomSettings?.user_id) {
        getSentry()?.captureMessage('Intercom user_id not found on window.intercomSettings', 'warning');
      }

      return window.Intercom && (!!window.intercomSettings?.user_id || !!window.Intercom('getVisitorId'));
    }

    return false;
  };

  const disabled = preview || isChatHistoryPreview;

  const executeAction = useAction(
    async (_, action: LabeledAction['action'], e?: React.MouseEvent) => {
      // When HelpHub/Copilot is rendered in a Native App WebView, copilot experiences will not work but in our Native Apps we send the fallback action to the native app
      const nativeCallback = getNativeCallback();
      if (window._cbIsWebView && nativeCallback) {
        await nativeCallback(JSON.stringify(action));
      } else if (shouldHandoffChat(action)) {
        contactSupport(action);
      } else {
        let shouldMinimizeHelpHub = true;
        if (action.type === 'nudge') {
          const nudge = getNudgeById(_, action.value);
          if (nudge) {
            const step = getNudgeStep(nudge, 0);
            if (isCursorStep(nudge, step)) {
              // INFO: cursors will automatically minimize HelpHub
              shouldMinimizeHelpHub = false;
            }
          }
        }
        if (action.type === 'execute_command') {
          const command = _.commands.find((c) => c.id === parseInt(action.meta.command));
          if (command?.template.type === 'helpdoc') {
            shouldMinimizeHelpHub = false;
          }
        }
        if (shouldMinimizeHelpHub) {
          setHelpHubMinimized(true);
        }
        GlobalActions.executeAction(_, action, e, { chatHistory }, { type: 'copilot' });
      }
    },
    [contactSupport],
  );

  const noAnswer = aiMessage.no_answer;

  const hasFallbackAction = fallbackActions?.some((action) => action.cta && action.action);

  const shouldShowFallbackActions =
    noAnswer && !aiMessage.value.experiences?.length && !aiMessage.extras?.length && hasFallbackAction;

  const themeV2 = useThemeV2Context();

  const useThemesV2 = (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'];

  const renderNextSteps = (nextStep: ICommandType['next_steps'][number], index: number) => {
    if (!isAction(nextStep) || !nextStep.cta) return null;

    return useThemesV2 ? (
      <StyledPrimaryButton
        themeV2={themeV2}
        key={index}
        onClick={(e) => {
          executeAction(nextStep.action, e);
        }}
        disabled={disabled}
        suffixIcon={<ArrowNarrowRight />}
        textWrap
      >
        {nextStep.cta}
      </StyledPrimaryButton>
    ) : (
      <ActionButton
        disablePreviewClick
        preview={preview || isChatHistoryPreview}
        key={index}
        icon="ArrowNarrowRight"
        onClick={(e) => {
          executeAction(nextStep.action, e);
        }}
      >
        {nextStep.cta}
      </ActionButton>
    );
  };

  const handleFallbackCTAClick = useCallback(
    (action: Action) => (e: MouseEvent) => {
      if (
        // TODO: remove after migrating Faire Retail Demo to use the 'chat_handoff' version
        (action.type === 'open_chat' && action.meta.type === 'zendesk_handoff') ||
        (action.type === 'chat_handoff' && action.meta.type === 'zendesk')
      ) {
        if (isStudioPreview()) {
          showMessage(
            'anon-handoff-warning',
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <AlertCircle color="#FFA922" />
              <span>Handoff requires a user id to be provided. To test this, boot this in your app.</span>
            </div>,
            5000,
          );
        } else {
          handoff();
        }
      } else {
        executeAction(action, e);
      }
    },
    [handoff, executeAction],
  );

  return useThemesV2 ? (
    <>
      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={!!shouldShowFallbackActions}
      >
        <StyledChatActionsContainer>
          {fallbackActions?.map(({ cta, action }, index) => (
            <StyledPrimaryButton
              themeV2={themeV2}
              key={index}
              onClick={handleFallbackCTAClick(action)}
              disabled={disabled}
              textWrap
            >
              {cta}
            </StyledPrimaryButton>
          ))}
        </StyledChatActionsContainer>
      </AnimationTransition>

      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={nextSteps.length > 0 && nextSteps.some((nextStep) => isAction(nextStep) && !!nextStep.cta)}
      >
        <StyledChatActionsContainer>
          {nextSteps.map((nextStep, index) => renderNextSteps(nextStep, index))}
        </StyledChatActionsContainer>
      </AnimationTransition>
    </>
  ) : (
    <>
      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={!!shouldShowFallbackActions}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0px',
            alignItems: 'flex-start',
          }}
        >
          {fallbackActions?.map(({ cta, action }, index) => (
            <ActionButton
              disablePreviewClick
              key={index}
              preview={preview || isChatHistoryPreview}
              onClick={handleFallbackCTAClick(action)}
            >
              {cta}
            </ActionButton>
          ))}
        </div>
      </AnimationTransition>

      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={nextSteps.length > 0 && nextSteps.some((nextStep) => isAction(nextStep) && !!nextStep.cta)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0px',
            alignItems: 'flex-start',
          }}
        >
          {nextSteps.map((nextStep, index) => renderNextSteps(nextStep, index))}
        </div>
      </AnimationTransition>
    </>
  );
};

export default MessageActions;
