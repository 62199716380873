import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { CB_COLORS, Select, generatePicker, dayjsGenerateConfig } from '@commandbar/design-system/components/antd';
import styled from '@emotion/styled';
import { CaretDown } from '../helphub/shared';

const DayJSDatePicker = generatePicker(dayjsGenerateConfig);

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const StyledSelect = styled(Select)`
  flex: 1;
  & .ant-select-selector {
    box-shadow: none;
    font-weight: 400;
  }
`;

const NudgeDatePicker = ({
  updateNudge,
  defaultValue,
}: {
  updateNudge: (date: string | null) => void;
  defaultValue?: dayjs.Dayjs;
}) => {
  const [isDateSet, setIsDateSet] = React.useState(!!defaultValue);
  const [isTimeSet, setIsTimeSet] = React.useState(!!defaultValue);
  const selectedValue = React.useRef(defaultValue ?? dayjs());
  const possibleTimes = [
    '12:00 AM',
    '12:30 AM',
    '1:00 AM',
    '1:30 AM',
    '2:00 AM',
    '2:30 AM',
    '3:00 AM',
    '3:30 AM',
    '4:00 AM',
    '4:30 AM',
    '5:00 AM',
    '5:30 AM',
    '6:00 AM',
    '6:30 AM',
    '7:00 AM',
    '7:30 AM',
    '8:00 AM',
    '8:30 AM',
    '9:00 AM',
    '9:30 AM',
    '10:00 AM',
    '10:30 AM',
    '11:00 AM',
    '11:30 AM',
    '12:00 PM',
    '12:30 PM',
    '1:00 PM',
    '1:30 PM',
    '2:00 PM',
    '2:30 PM',
    '3:00 PM',
    '3:30 PM',
    '4:00 PM',
    '4:30 PM',
    '5:00 PM',
    '5:30 PM',
    '6:00 PM',
    '6:30 PM',
    '7:00 PM',
    '7:30 PM',
    '8:00 PM',
    '8:30 PM',
    '9:00 PM',
    '9:30 PM',
    '10:00 PM',
    '10:30 PM',
    '11:00 PM',
    '11:30 PM',
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
      <DayJSDatePicker
        placeholder="Day..."
        suffixIcon={<CaretDown />}
        style={{ flex: 1 }}
        format={'MMM D, YYYY'}
        showToday={false}
        onChange={(e) => {
          if (e) {
            selectedValue.current = selectedValue.current
              .set('date', e.date())
              .set('month', e.month())
              .set('year', e.year());
            setIsDateSet(true);
            isTimeSet && updateNudge(selectedValue.current.format() || null);
          }
        }}
        disabledDate={(date) => date.isBefore(dayjs().subtract(1, 'day'))}
        defaultValue={defaultValue}
        popupStyle={{ zIndex: '2147483647' }}
      ></DayJSDatePicker>

      <StyledSelect
        suffixIcon={<CaretDown />}
        placeholder="Time..."
        style={{ color: isTimeSet ? 'black' : CB_COLORS.neutral500 }}
        allowClear
        onClear={() => setIsTimeSet(false)}
        onChange={(e) => {
          const selectedTime = dayjs(e as string, 'h:mm A');
          if (selectedTime && !isNaN(selectedTime.hour())) {
            selectedValue.current = selectedValue.current
              .set('hour', selectedTime.hour())
              .set('minute', selectedTime.minute())
              .set('second', selectedTime.second());
            setIsTimeSet(true);
            isDateSet && updateNudge(selectedValue.current.format() || null);
          } else {
            setIsTimeSet(false);
          }
        }}
        value={isTimeSet ? selectedValue.current.format('h:mm A') : undefined}
      >
        {possibleTimes.map((time, i) => {
          return (
            <Select.Option key={i} value={time}>
              {time}
            </Select.Option>
          );
        })}
      </StyledSelect>
    </div>
  );
};

export default NudgeDatePicker;
