import Portal from '@commandbar/internal/client/Portal';
import type {
  ElementInformation,
  IChecklist,
  ICopilotSettingsPreviewType,
  INudgeType,
  IOrganizationType,
  IRecommendationSet,
  ISkinType,
} from '@commandbar/internal/middleware/types';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import type { WidgetTableauSelection } from '@cb/types/misc/widgetTableau';

import { SDKConfig } from '@commandbar/internal/client/SDKConfig';

const logout = async () => {
  const msg = {
    action: 'logout',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const synToken = async (token: string | null) => {
  const msg = {
    action: 'synToken',
    data: { token },
  };

  return await Portal.openChannel()(msg);
};

const secureEditor = async () => {
  const msg = {
    action: 'secureEditor',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const onCMDK = async () => {
  const msg = {
    action: 'onCMDK',
    data: undefined,
  };

  return await Portal.openChannel()(msg);
};

const showEditor = async () => {
  const msg = {
    action: 'showEditor',
  };

  return await Portal.openChannel()(msg);
};

const hideEditor = async () => {
  const msg = {
    action: 'hideEditor',
  };

  return await Portal.openChannel()(msg);
};

const getHostUrl = async (isStandaloneEditor: boolean): Promise<{ url: string }> => {
  if (isStandaloneEditor) {
    return { url: window.location.href };
  }

  const msg = {
    action: 'shareHostURL',
  };

  return (await Portal.openChannel()(msg)) as { url: string };
};

const getConfiguration = async (): Promise<{ data: SDKConfig }> => {
  const msg = {
    action: 'shareConfiguration',
  };

  return (await Portal.openChannel()(msg)) as { data: SDKConfig };
};

const shareLogs = async () => {
  const msg = {
    action: 'shareLogs',
  };
  return await Portal.openChannel()(msg);
};

const shareChecks = async () => {
  const msg = {
    action: 'shareChecks',
  };
  return await Portal.openChannel()(msg);
};

const shareCallbacks = async (): Promise<any> => {
  const msg = {
    action: 'shareCallbacks',
  };
  return await Portal.openChannel()(msg);
};

const shareComponentNamesByKey = async (): Promise<Record<string, string>> => {
  const msg = {
    action: 'shareComponentNamesByKey',
  };
  const result = (await Portal.openChannel()(msg)) as any;

  return result.components as Record<string, string>;
};

const openClickRecorder = async (id: number, startingSelectors: ElementInformation[], singleStep?: boolean) => {
  const msg = {
    action: 'openClickRecorder',
    selectors: startingSelectors,
    singleStep,
    id,
  };
  return await Portal.openChannel()(msg);
};

const getProgrammaticTheme = async () => {
  return (await Portal.openChannel()({ action: 'getProgrammaticTheme' })) as Promise<{ data?: string }>;
};

const shareUser = async () => {
  const msg = {
    action: 'shareUser',
  };
  return await Portal.openChannel()(msg);
};

const shareIsOpen = async () => {
  const msg = {
    action: 'shareIsOpen',
  };
  return await Portal.openChannel()(msg);
};

const setTheme = async (skin: string | Pick<ISkinType, 'logo' | 'skin'>) => {
  const msg = {
    action: 'setTheme',
    data: skin,
  };

  return await Portal.openChannel()(msg);
};

const setThemeV2 = async (themeV2?: IThemeV2Type, mode?: 'light_mode' | 'dark_mode') => {
  const msg = {
    action: 'setThemeV2',
    data: { themeV2, mode },
  };

  return await Portal.openChannel()(msg);
};

const unsetThemeV2 = async () => {
  const msg = {
    action: 'setThemeV2',
    data: {},
  };

  return await Portal.openChannel()(msg);
};
const showWidgetTableau = async (show: boolean, widgetSelection: WidgetTableauSelection) => {
  const msg = {
    action: 'showWidgetTableau',
    data: { show, widgetSelection },
  };

  return await Portal.openChannel()(msg);
};

const shareUserAuthStatus = (data: boolean) => {
  const msg = {
    action: 'shareUserAuthStatus',
    data,
  };

  return Portal.openChannel()(msg);
};

const shareOrganization = (organization: IOrganizationType | null) => {
  const msg = {
    action: 'shareOrganization',
    data: { organization },
  };

  return Portal.openChannel()(msg);
};

const getOrganizationID = () => {
  const msg = {
    action: 'shareOrganizationID',
  };

  return Portal.openChannel()(msg);
};

const checkIfSelectorValid = async (
  selector: ElementInformation | string,
): Promise<{ elementFound: boolean; elementVisible: boolean }> => {
  if (typeof selector === 'string') {
    selector = { selector, tag: '', attributes: {}, text: '' };
  }

  const msg = {
    action: 'checkIfSelectorValid',
    data: selector,
  };

  const result = (await Portal.openChannel()(msg)) as any;

  return result;
};

const getActiveChat = async (): Promise<{ chatId: string | null }> => {
  const msg = {
    action: 'getActiveChat',
  };

  const result = (await Portal.openChannel()(msg)) as any;

  return result;
};

const updateEditorCopilotOverrides = async (data: {
  user?: string;
  userProperties?: Record<string, any>;
  filter?: string[];
}) => {
  const msg = {
    action: 'updateEditorCopilotOverrides',
    data: data,
  };

  return Portal.openChannel()(msg);
};

//////////////////////////////////////
// CommandBar Client

const client = async (name: string, data?: any) => {
  const msg = {
    action: 'client',
    method: name,
    data: data,
  };
  // @ts-expect-error: FIXME portal type
  return (await Portal.openChannel()(msg))?.result;
};

const isUserVerified = async () => {
  return await client('isUserVerified');
};

const setContext = async (context: any, meta?: any) => {
  return await client('setContext', [context, meta]);
};

const shareContext = async () => {
  return await client('shareContext');
};

const instanceAttributes = async () => {
  return await client('instanceAttributes');
};

const openBar = async (text?: string) => {
  return await client('open', [text]);
};

const closeBar = async () => {
  return await client('close');
};

const closeHelpHub = async () => {
  return await client('closeHelpHub');
};

const showMessage = async (guideEvent: string, show: boolean) => {
  return await client('showMessage', [guideEvent, show]);
};

const reload = async (
  toReload: (
    | 'reloadCommands'
    | 'reloadOrganization'
    | 'reloadPlaceholders'
    | 'reloadNudges'
    | 'reloadChecklists'
    | 'reloadHelpHub'
    | 'reloadLocalizedMessages'
    | 'reloadThemes'
  )[],
) => {
  return await client('reload', [toReload]);
};

const setTestMode = async (on: boolean) => {
  return await client('setTestMode', [on]);
};

const setPreviewMode = async (on: boolean) => {
  return await client('setPreviewMode', [on]);
};

const onboard = async () => {
  return await client('onboard');
};

const shareProgrammaticCommands = async () => {
  return await client('shareProgrammaticCommands');
};

const shareContextSettings = async () => {
  return await client('shareContextSettings');
};

const startNudgeDebug = async (nudge: INudgeType) => {
  return await client('startNudgeDebug', [{ nudge }]);
};

const showNudgeStepMock = async (nudge: INudgeType, stepIndex: number, options = { forceOpen: false }) => {
  return await client('showNudgeStepMock', [{ nudge, stepIndex, forceOpen: options.forceOpen }]);
};

const closeNudgeMocks = async () => {
  return await client('closeNudgeMocks');
};

const resetNudge = async (nudgeId: number) => {
  return await client('resetNudge', [nudgeId]);
};

const resetChecklist = async (checklistId: number) => {
  return await client('resetChecklist', [checklistId]);
};

const previewChecklist = async (checklist: IChecklist, clearData: boolean) => {
  return await client('previewChecklist', [{ checklist, clearData }]);
};

const stopChecklistPreview = async () => {
  return await client('stopChecklistPreview', []);
};

const shareEditorRouteWithBar = async (path: string) => {
  return await client('shareEditorRouteWithBar', [path]);
};

const openHelpHub = async (options?: { query?: string; articleId?: number | null; chatOnly?: boolean }) => {
  return await client('openHelpHub', options ? [options] : []);
};

const previewRecommendationSet = async (recommendationSet?: IRecommendationSet) => {
  return await client('previewRecommendationSet', [{ recommendationSet }]);
};

const stopRecommendationSetPreview = async () => {
  return await client('previewRecommendationSet', []);
};

const updateEditorPreviewDevice = async (device: 'desktop' | 'mobile') => {
  return await client('updateEditorPreviewDevice', [device]);
};

const shareTrackedEvents = async () => {
  return await client('shareTrackedEvents');
};

const previewCopilotSettings = async (settings: ICopilotSettingsPreviewType) => {
  return await client('previewCopilotSettings', [{ settings }]);
};

const Sender = {
  logout,
  openClickRecorder,
  synToken,
  secureEditor,
  onCMDK,
  shareUser,
  shareIsOpen,
  openBar,
  closeBar,
  openHelpHub,
  closeHelpHub,
  previewRecommendationSet,
  stopRecommendationSetPreview,
  showMessage,
  setTestMode,
  isUserVerified,
  setPreviewMode,
  hideEditor,
  setContext,
  shareContext,
  instanceAttributes,
  shareCallbacks,
  shareComponentNamesByKey,
  showEditor,
  getHostUrl,
  shareLogs,
  shareChecks,
  checkIfSelectorValid,
  shareContextSettings,
  shareProgrammaticCommands,
  updateEditorPreviewDevice,
  onboard,
  getProgrammaticTheme,
  setTheme,
  setThemeV2,
  unsetThemeV2,
  showWidgetTableau,
  shareUserAuthStatus,
  getConfiguration,
  shareOrganization,
  getOrganizationID,
  startNudgeDebug,
  showNudgeStepMock,
  closeNudgeMocks,
  previewChecklist,
  stopChecklistPreview,
  shareEditorRouteWithBar,
  reload,
  shareTrackedEvents,
  previewCopilotSettings,
  getActiveChat,
  updateEditorCopilotOverrides,
  resetNudge,
  resetChecklist,
};

export default Sender;
