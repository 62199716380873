import * as React from 'react';

import { CmdInput } from '../../input';
import { SearchMd } from '@commandbar/design-system/icons/react';

interface CmdTableToolBarProps {
  setGlobalFilter: (value: string) => void;
  globalFilter: string;
  children?: React.ReactChild;
  showGlobalSearch?: boolean;
  shouldDebounceFilter?: boolean;
  searchPlaceholder?: string;
}

const CmdTableToolBar = ({
  setGlobalFilter,
  globalFilter,
  children,
  showGlobalSearch = true,
  shouldDebounceFilter = true,
  searchPlaceholder,
}: CmdTableToolBarProps): React.ReactElement => {
  const [inputValue, setInputValue] = React.useState(globalFilter);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  React.useEffect(() => {
    if (shouldDebounceFilter) {
      const timeoutId = setTimeout(() => {
        setGlobalFilter(inputValue);
      }, 500);

      return () => clearTimeout(timeoutId);
    }
    setGlobalFilter(inputValue);
  }, [inputValue, setGlobalFilter, shouldDebounceFilter]);

  return (
    <div className={`flex justify-${!!children ? 'between' : 'end'} w-full items-center`}>
      {children}
      {showGlobalSearch && (
        <CmdInput
          placeholder={searchPlaceholder || 'Filter by keyword'}
          value={inputValue ?? ''}
          onChange={(e) => handleInputChange(e.target.value)}
          className="h-8 w-[250px]"
          clearable
          prefixElement={<SearchMd />}
        />
      )}
    </div>
  );
};

export { CmdTableToolBar };
