import { IArgumentMap } from '@commandbar/internal/middleware/types';
import React from 'react';
import { debounce } from 'lodash';
import { FormRow, ValidatedInput } from '@commandbar/design-system/components/antd';
import { validateName } from '../../argumentUtils';

interface IStepNameProps {
  argumentName: string;
  arguments: IArgumentMap;
  updateName: (oldName: string, newName: string) => void;
}

export const ArgumentName = (props: IStepNameProps) => {
  // Debounce name updates
  const debouncedNameHandler = React.useCallback(
    debounce((e: string) => {
      if (props.argumentName !== undefined) {
        props.updateName(props.argumentName, e);
      }
    }, 1),
    [props.argumentName],
  );

  return (
    <FormRow
      title="Key"
      input={
        <ValidatedInput
          validator={(newVal: string) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return validateName(newVal, props.argumentName!, props.arguments);
          }}
          placeholder={'Argument key'}
          value={props.argumentName}
          onChange={(e) => {
            debouncedNameHandler(e.target.value);
          }}
        />
      }
    />
  );
};
