import React from 'react';
import * as Sentry from '@sentry/react';
import { Alert, Typography } from '@commandbar/design-system/components/antd';
import { Condition } from '@commandbar/internal/middleware/conditions';
import type { IRuleExpressionAnd, IRuleExpressionOr } from '@commandbar/internal/middleware/types';
import { ConditionEditorProvider } from './state/useConditionEditor';
import { ConditionTypeCategory } from './categories';
import { isValidConditionValue, isValidCondition, getUrlConditionError } from './validate';
import { camelCaseToRegular } from './helpers';
import { ConditionTypeSelect } from './components/ConditionTypeSelect';

import { UserPropertyCondition } from './components/conditions/UserPropertyCondition';
import { NamedRuleReference } from './components/conditions/NamedRuleReference';
import { ActivityCondition } from './components/conditions/ActivityCondition';
import { MetadataCondition } from './components/conditions/MetadataCondition';
import { BrowserCondition } from './components/conditions/BrowserCondition';
import { LanguageCondition } from './components/conditions/LanguageCondition';
import { OSCondition } from './components/conditions/OSCondition';
import { DeviceTypeCondition } from './components/conditions/DeviceTypeCondition';
import { FirstLastSeenCondition } from './components/conditions/FirstLastSeenCondition';
import { PageCondition } from './components/conditions/PageCondition';
import { NudgeInteractionCondition } from './components/conditions/NudgeInteractionCondition';
import { EventPropertyCondition } from './components/conditions/EventPropertyCondition';
import { ABTestCondition } from './components/conditions/ABTestCondition';
import { HeapCondition } from './components/conditions/HeapCondition';
import { HubspotCondition } from './components/conditions/HubspotCondition';
import { IntentCondition } from './components/conditions/IntentCondition';
import { HelpDocInteractionCondition } from './components/conditions/HelpDocInteractionCondition';
import { SurveyResponseCondition } from './components/conditions/SurveyResponseCondition';
import { AmplitudeCondition } from './components/conditions/AmplitudeCondition';
import { AudienceCondition } from './components/conditions/AudienceCondition';

type ConditionEditorProps = {
  condition: Condition;
  expr: IRuleExpressionAnd | IRuleExpressionOr;

  containerStyle?: React.CSSProperties;
  disabled?: boolean;

  categories: ConditionTypeCategory[];

  /* event handlers */
  onConditionChange?: (condition: Condition) => void;
  onValid?: (isValid: boolean) => void;
  // For Preview flow in the extension, we need to know which field to start recording on
  // and which field gets the value from the recorder so a path is calculated and passed down
  path?: string;
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
};

export const ConditionEditor: React.FC<ConditionEditorProps> = ({
  expr,
  condition,
  disabled = false,
  containerStyle,

  categories,
  onStartClickRecorder,
  path,
  /* event handlers */
  onConditionChange = () => {
    return;
  },
}) => {
  const [hasBlurred, setHasBlurred] = React.useState(false);

  const renderCondition = (): JSX.Element => {
    switch (condition.type) {
      case 'ab_test':
        return <ABTestCondition condition={condition} />;
      case 'audience':
        return <AudienceCondition condition={condition} />;
      case 'shortcuts':
      case 'opens':
      case 'deadends':
      case 'executions':
      case 'sessions':
        return <ActivityCondition condition={condition} />;
      case 'browser':
        return <BrowserCondition condition={condition} />;
      case 'device_type':
        return <DeviceTypeCondition condition={condition} />;
      case 'event_property':
        return <EventPropertyCondition condition={condition} />;
      case 'first_seen':
      case 'last_seen':
        return <FirstLastSeenCondition condition={condition} />;
      case 'heap':
        return <HeapCondition condition={condition} />;
      case 'help_doc_interaction':
        return <HelpDocInteractionCondition />;
      case 'hubspot':
        return <HubspotCondition condition={condition} />;
      case 'amplitude':
        return <AmplitudeCondition condition={condition} />;
      case 'intent':
        return <IntentCondition />;
      case 'language':
        return <LanguageCondition condition={condition} />;
      case 'context':
        return <MetadataCondition condition={condition} />;
      case 'named_rule':
        return <NamedRuleReference condition={condition} />;
      case 'nudge_interaction':
      case 'questlist_interaction':
        return <NudgeInteractionCondition condition={condition} />;
      case 'os':
        return <OSCondition condition={condition} />;
      case 'url':
      case 'hostname':
      case 'element':
        return <PageCondition condition={condition} onStartClickRecorder={onStartClickRecorder} path={path} />;
      case 'survey_response':
        return <SurveyResponseCondition />;
      case 'user_property':
        return <UserPropertyCondition condition={condition} />;
      case 'video_interaction':
        // Our watched video events are unintuitive, so for now we're hiding this condition for now (https://github.com/tryfoobar/monobar/pull/6266)
        return <ConditionTypeSelect />;
      case 'always':
        Sentry.captureMessage(`Invalid condition type: ${condition.type}`);
        return <ConditionTypeSelect />;
    }
  };

  const renderErrorMessage = () => {
    if (
      !isValidConditionValue(condition) &&
      'value' in condition &&
      'field' in condition &&
      condition.value &&
      condition.field
    ) {
      return (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Unable to apply operator{' '}
              <Typography.Text code>{camelCaseToRegular(condition.operator ?? '')}</Typography.Text> to{' '}
              <Typography.Text code>{condition.value}</Typography.Text>
            </span>
          }
          type="error"
        />
      );
    }

    if (!isValidCondition(condition) && hasBlurred) {
      return (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={<span>{'Invalid condition. Please fill all required fields.'}</span>}
          type="error"
        />
      );
    }

    const urlConditionError = getUrlConditionError(condition);

    if (urlConditionError) {
      return (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={<span>{`Invalid condition. ${urlConditionError} `}</span>}
          type="error"
        />
      );
    }
  };

  return (
    <ConditionEditorProvider
      condition={condition}
      onConditionChange={onConditionChange}
      expr={expr}
      disabled={disabled}
      categories={categories}
      setHasBlurred={setHasBlurred}
    >
      <div style={{ marginTop: 8, marginBottom: 8, opacity: disabled ? 0.6 : 1, ...containerStyle }}>
        {renderCondition()}
        {renderErrorMessage()}
      </div>
    </ConditionEditorProvider>
  );
};
