/** V2, V3 copilot only, not used in v1 */
import React from 'react';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import type { executeAction } from '@commandbar/commandbar/shared/store/global-actions';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import type { IAIMessageExtraType } from '@commandbar/internal/middleware/types';
import StyledChatMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledChatMessage';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import ActionButton from '@commandbar/commandbar/shared/components/ActionButton';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import { useChatState } from '../../store/useChatState';
import { getNudgeById, isCursorStep } from '@commandbar/commandbar/products/nudges/service-selectors';
import * as HelpHubServiceActions from 'products/helphub/service-actions';

import { useAction } from '@commandbar/commandbar/shared/util/hooks/useAction';
import { getNudgeStep } from '@commandbar/internal/util/nudges';

// INFO: simulates the params of `useAction(fn)`
type ExcludeFirstParam<T extends unknown[]> = T extends [any, ...infer Rest] ? Rest : never;

type MessageExtraProps = {
  idx: number;
  extra: IAIMessageExtraType;
  totalExtras: number;
  isLastMessage: boolean;
  executeAction: (...actionParams: ExcludeFirstParam<Parameters<typeof executeAction>>) => void;
  sendChat: (message: string) => void;
  preview: boolean;
  isChatHistoryPreview: boolean;
  previewOverrides?: { useThemeV2: boolean };
};

const MessageExtra: React.FC<MessageExtraProps> = (props) => {
  const { idx, extra, totalExtras, isLastMessage, executeAction, preview, isChatHistoryPreview, previewOverrides } =
    props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  const themeV2 = useThemeV2Context();
  const chatState = useChatState();

  const disabled = preview || isChatHistoryPreview;

  const setHelpHubMinimized = useAction(HelpHubServiceActions.setHelpHubMinimized);
  if (!isLastMessage && !extra.action) {
    // Only show an extra if it's the last message or if it has an action
    return null;
  }

  const onExtraClick = (e: React.MouseEvent) => {
    if (extra.action) {
      let shouldMinimizeCopilot = true;
      if (extra.action.type === 'nudge') {
        const nudge = getNudgeById(_, extra.action.value);
        if (nudge) {
          const step = getNudgeStep(nudge, 0);
          if (isCursorStep(nudge, step)) {
            // Cursor needs copilot to be open to do animation / scroll stuff
            // will handle minimizing on it's own
            shouldMinimizeCopilot = false;
          }
        }
      }
      Track.copilot.experienceClicked(chatState.chatID, chatState, extra.action);
      executeAction(extra.action, e, undefined, { type: 'copilot' });

      if (shouldMinimizeCopilot) {
        setHelpHubMinimized(true);
      }
    } else {
      props.sendChat(extra.text);
    }
  };

  let text = extra.text;
  if (!text.length) {
    text = 'Click here';
  }

  const useThemesV2 = (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'];

  if (extra.type === 'button_primary') {
    return useThemesV2 ? (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {extra.message && <StyledChatMessage isBotMessage>{extra.message}</StyledChatMessage>}
        <StyledPrimaryButton
          themeV2={themeV2}
          disabled={disabled}
          onClick={onExtraClick}
          style={{
            marginTop: idx === 0 && !extra.message ? '0px' : '8px',
            marginBottom: !extra.message || idx === totalExtras - 1 ? '4px' : '16px',
          }}
          key={idx}
          textWrap
        >
          {text}
        </StyledPrimaryButton>
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {extra.message}
        <ActionButton
          variant={'primary'}
          disabled={disabled}
          onClick={onExtraClick}
          style={{
            marginTop: idx === 0 && !extra.message ? '0px' : '8px',
            marginBottom: !extra.message || idx === totalExtras - 1 ? '4px' : '16px',
          }}
          key={idx}
          preview={preview}
        >
          {text}
        </ActionButton>
      </div>
    );
  } else if (extra.type === 'button_secondary') {
    return useThemesV2 ? (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {extra.message && <StyledChatMessage isBotMessage>{extra.message}</StyledChatMessage>}
        <StyledSecondaryButton
          themeV2={themeV2}
          disabled={disabled}
          onClick={onExtraClick}
          style={{
            marginTop: idx === 0 && !extra.message ? '0px' : '8px',
            marginBottom: !extra.message || idx === totalExtras - 1 ? '4px' : '16px',
          }}
          key={idx}
          textWrap
        >
          {text}
        </StyledSecondaryButton>
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        {extra.message}
        <ActionButton
          variant={'secondary'}
          disabled={disabled}
          onClick={onExtraClick}
          style={{
            marginTop: idx === 0 && !extra.message ? '0px' : '8px',
            marginBottom: !extra.message || idx === totalExtras - 1 ? '4px' : '16px',
          }}
          key={idx}
          preview={preview}
        >
          {text}
        </ActionButton>
      </div>
    );
  } else {
    return null;
  }
};

export default MessageExtra;
