import React from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';

import { useAppContext } from 'editor/src/AppStateContext';
import { useConditionEditor } from '../../state/useConditionEditor';
import type {
  NudgeInteractionCondition as NudgeInteractionConditionType,
  ChecklistInteractionCondition as ChecklistInteractionConditionType,
} from '@commandbar/internal/middleware/conditions';
import { Col, Row, Select, StatusBadge, Tooltip } from '@commandbar/design-system/components/antd';
import { getDisplayTitle } from '@commandbar/internal/util/nudges';
import { OperatorSelect } from '../OperatorSelect';
import { useParams } from 'react-router-dom';
import { ElementNotFoundIcon } from 'editor/src/editor/nudges/NudgeContentForm/Header';
import { ConditionProps } from '../../types';
import { defaultConditions } from '../../categories';

type NudgeOption = {
  value: string;
  id: number;
  type: 'nudge' | 'checklist';
  label: JSX.Element;
};

export const NudgeInteractionCondition: React.FC<
  ConditionProps<NudgeInteractionConditionType | ChecklistInteractionConditionType>
> = ({ condition }) => {
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();
  const { nudges, checklists } = useAppContext();
  const params = useParams<{ nudgeId?: string; checklistId?: string }>();

  const nudge =
    condition.type === 'nudge_interaction'
      ? nudges.find((nudge) => nudge.id === condition.nudge_id)
      : checklists.find((checklist) => checklist.id === condition.questlist_id);

  const nudgesOrChecklists = [
    ...nudges.map((nudge) => ({
      value: `nudge-${nudge.id}`,
      id: Number(nudge.id),
      type: 'nudge',
      label: (
        <div
          style={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {nudge.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" />
          ) : (
            <StatusBadge color="orange" />
          )}{' '}
          {getDisplayTitle(nudge)}
        </div>
      ),
    })),
    ...checklists.map((checklist) => ({
      value: `checklist-${checklist.id}`,
      id: Number(checklist.id),
      type: 'checklist',
      label: (
        <div
          style={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {checklist.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" />
          ) : (
            <StatusBadge color="orange" />
          )}{' '}
          {checklist.title}
        </div>
      ),
    })),
  ].filter((nudge) => {
    if (nudge.type === 'nudge' && nudge.id === Number(params.nudgeId)) {
      return false;
    }

    if (nudge.type === 'checklist' && nudge.id === Number(params.checklistId)) {
      return false;
    }

    return true;
  }) as NudgeOption[];

  let error = '';
  if (
    (condition.type === 'nudge_interaction' && condition.nudge_id !== -1) ||
    (condition.type === 'questlist_interaction' && condition.questlist_id !== -1)
  ) {
    if (!nudge) {
      error = 'The selected Nudge does no longer exists.';
    } else if (condition.value === 'dismissed' && 'dismissible' in nudge && !nudge.dismissible) {
      error = 'The selected Nudge is not dismissable';
    } else if (condition.value === 'dismissed' && 'skippable' in nudge && !nudge.skippable) {
      error = 'The selected Nudge is not skippable';
    } else if (!nudge.is_live) {
      error = 'The selected Nudge is not currently live.';
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Row align="middle" wrap={false} style={{ width: '100%', gap: '4px' }}>
        <ConditionTypeSelect />

        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={nudge ? `${condition.type === 'nudge_interaction' ? 'nudge' : 'checklist'}-${nudge.id}` : ''}
            options={nudgesOrChecklists}
            onSelect={(_: unknown, option: NudgeOption) => {
              const newType = option.type === 'nudge' ? 'nudge_interaction' : 'questlist_interaction';

              if (newType !== condition.type) {
                onConditionChange({
                  ...defaultConditions[newType],
                  [option.type === 'nudge' ? 'nudge_id' : 'questlist_id']: Number(option.id),
                });
                return;
              } else {
                onConditionChange({
                  ...condition,
                  [option.type === 'nudge' ? 'nudge_id' : 'questlist_id']: Number(option.id),
                });
              }
            }}
            style={{ maxWidth: '100%', width: '100%' }}
            dropdownMatchSelectWidth={false}
            size="small"
            onBlur={() => setHasBlurred(true)}
          />
        </Col>

        {error && (
          <Tooltip showIf={!!error} placement="bottom" content={error}>
            <Row align="middle">
              <ElementNotFoundIcon />
            </Row>
          </Tooltip>
        )}
      </Row>
      <Row style={{ width: '100%', gap: '4px' }} align="middle" wrap={false}>
        <OperatorSelect />

        <Col flex="1 3 auto">
          <Select
            disabled={disabled}
            value={condition.value ?? ''}
            options={[
              { label: 'Viewed', value: 'viewed' },
              { label: 'Completed', value: 'completed' },
              { label: 'Dismissed', value: 'dismissed' },
            ]}
            placeholder="value"
            style={{ width: '100%', height: '24px' }}
            onBlur={() => setHasBlurred(true)}
            onChange={(value) =>
              onConditionChange({
                ...condition,
                value,
              })
            }
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};
