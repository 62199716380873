import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn, extendRadixComponent } from '../util';
import { Check01 } from '@commandbar/design-system/icons/react';
import { CmdLabel, CmdLabelProps } from '../typography/label';
import { checkboxStyles } from './styles';

const CheckboxGroupDisabledContext = React.createContext(false);

type CmdCheckboxProps = {
  disabled?: boolean;
  label?: string | React.ReactNode;
  align?: 'start' | 'center';
} & CmdLabelProps;

const CmdCheckbox = extendRadixComponent<typeof CheckboxPrimitive.Root, CmdCheckboxProps>(
  ({ className, checked, disabled, label, tooltip, align = 'center', ...props }, ref) => {
    const groupDisabled = React.useContext(CheckboxGroupDisabledContext);
    const isDisabled = disabled || groupDisabled;

    return (
      <CmdLabel disabled={isDisabled} tooltip={tooltip}>
        <div className={`flex gap-2 ${align === 'center' ? 'items-center' : 'items-start'}`}>
          <CheckboxPrimitive.Root
            ref={ref}
            className={cn(checkboxStyles({ disabled: isDisabled }), className)}
            {...props}
            checked={checked}
            disabled={isDisabled}
          >
            <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current h-[22px] w-[22px]">
              <Check01
                height={22}
                width={22}
                style={{
                  color: isDisabled ? '#797C85' : '#fff',
                }}
              />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
          {label}
        </div>
      </CmdLabel>
    );
  },
);

CmdCheckbox.displayName = 'CmdCheckbox';

export { CmdCheckbox };
