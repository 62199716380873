import { getSDK } from '@commandbar/internal/client/globals';
import { _user, _instanceAttributes } from '@commandbar/internal/client/symbols';
import type {
  IAIAnswerPayloadType,
  IAIAnswerType,
  ICommandType,
  ICopilotSettingsPreviewType,
  IExperienceHitType,
  IExperienceTypeOptions,
  IHelpDocHitType,
  IMessageType,
  IOrganizationType,
  IQuestionSuggestionsPayloadType,
  IQuestionSuggestionsType,
  IUserAttachmentType,
} from '@commandbar/internal/middleware/types';
import type { IEndUserType } from '@cb/types/entities/endUser';
import Logger from '@commandbar/internal/util/Logger';

import { CBStore, HelpHubDoc } from '../store/global-store';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { commandToHelpHubDoc } from 'products/helphub/service-selectors';
import { Chat } from '@commandbar/internal/middleware/chat';
import { HelpDocsSearch } from '@commandbar/internal/middleware/helpDocsSearch';
import { ExperiencesSearch } from '@commandbar/internal/middleware/experiencesSearch';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import helpdocService from 'shared/services/services/helpdocService';
import { ChatHistory } from '../services/analytics/EventHandler';
import { addDeviceProperties } from 'shared/services/end-users';

const _queryHelpDocs = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
  expand_rules: boolean,
  filter: { labels?: string[] } | null,
  abortController?: AbortController,
): Promise<IHelpDocHitType[]> => {
  const response = await HelpDocsSearch.search(
    orgId,
    { query, filter, expand_rules, include_additional_docs: true },
    {
      signal: abortController?.signal,
      headers: {
        ...(!!endUser?.hmac && { 'X-USER-AUTHORIZATION': endUser?.hmac }),
        ...(!!endUser?.slug && { 'X-USER-ID': endUser?.slug }),
      },
    },
  );

  const searchResults = response.data;
  if (searchResults && searchResults.length > 0)
    return searchResults.map((d) => {
      const hit = d.hit;
      helpdocService.addToCache(hit.command.id, hit, query);
      return hit;
    });

  return [];
};

export const queryHelpDocCommands = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
  filter: { labels?: string[] } | null,
  abortController?: AbortController,
): Promise<ICommandType[]> =>
  (await _queryHelpDocs(orgId, query, endUser, true, filter, abortController)).map((hit) => ({
    ...hit.command,
  }));

export const queryHelpDocs = async (
  orgId: string | number,
  query: string,
  endUser: IEndUserType | null | undefined,
  filter: { labels?: string[] } | null,
): Promise<HelpHubDoc[]> =>
  (
    await _queryHelpDocs(orgId, query, endUser, false, filter).catch((err) => {
      Logger.warn(`Search error: ${err}`);
      return [];
    })
  ).map((hit) => commandToHelpHubDoc(hit.command, hit));

export const queryExperiences = async (
  _: CBStore,
  query: string,
  widget: 'helphub' | 'spotlight',
  experienceTypes?: IExperienceTypeOptions,
  maxResults?: number,
  abortController?: AbortController,
  ignoreAvailability?: boolean,
): Promise<IExperienceHitType[]> => {
  // Availability is handled server-side for all objects returned here
  if (!_.organization) return [];
  try {
    // XXX(pjhul): For now, I'm disabling sending these properties to the server. We were running into an issue with an org where this would exceed the maximum URL length.
    // It's not clear that we even need to do this here, so we may end up disabling entirely.
    const userProperties = {}; // getSDK()[_userProperties];
    const completeUserProperties = userProperties ? addDeviceProperties(userProperties) : undefined;

    const response = await ExperiencesSearch.search(
      _.organization.id,
      {
        query,
        widget,
        experience_types: experienceTypes?.join(','),
        filter: _.helpHub.filter,
        max_results: maxResults,
        user_properties: ignoreAvailability ? undefined : completeUserProperties,
        distinct_id: ignoreAvailability ? undefined : _.endUser?.slug,
      },
      {
        signal: abortController?.signal,
        headers: {
          ...(!!_.endUser?.hmac && { 'X-USER-AUTHORIZATION': _.endUser?.hmac }),
          ...(!!_.endUser?.slug && { 'X-USER-ID': _.endUser?.slug }),
        },
      },
    );

    const searchResults = response.data;
    if (searchResults && searchResults.length > 0)
      return searchResults.map((d) => {
        const hit = d.hit;
        if (hit.type === 'helpdoc') {
          helpdocService.addToCache(
            hit.experience_id,
            { score: hit.score, command: hit.experience, excerpt: hit.experience.content as string },
            query,
          );
        }
        return hit;
      });
    return [];
  } catch (err) {
    if (!abortController?.signal.aborted) {
      Logger.warn(`Search error: ${err}`);
    }
    return [];
  }
};

export const convertInternalChatHistoryToExternal = (history: IMessageType[] | undefined): ChatHistory => {
  const chat_history: ChatHistory = [];

  if (history) {
    for (let i = 0; i < history.length; i++) {
      const entry = history[i];

      if (i === 0 && entry.message_type !== 'USER') {
        continue;
      }

      if (entry.message_type === 'USER') {
        chat_history.push({
          question: entry.value.question,
          answer: null,
        });
      } else if (entry.message_type === 'AI') {
        chat_history[chat_history.length - 1].answer = {
          message: entry.value.answer || '',
          message_id: entry.value?.message_id,
        };
      }
    }
  }

  return chat_history;
};

enum ErrorType {
  Throttled = 'throttled',
  ModelError = 'model_error',
  Aborted = 'aborted',
  Unknwon = 'unknown',
}

export const fetchAIChatAnswer = async (
  organization: IOrganizationType,
  query: string,
  chatID?: string,
  filter?: CBStore['helpHub']['filter'],
  endUser?: IEndUserType | null,
  abortController?: AbortController,
  previewedCopilotSettings?: ICopilotSettingsPreviewType,
  attachments?: IUserAttachmentType[],
  userPropertiesOverride?: CBStore['helpHub']['editorCopilotOverrides']['userProperties'],
  isAdmin?: boolean,
): Promise<IAIAnswerType | { error: ErrorType }> => {
  const system_template_override = String(LocalStorage.get('prompt', ''));

  // We don't need to send the avatar to the backend
  const previewCopilotSettingsPayload = previewedCopilotSettings
    ? {
        name: previewedCopilotSettings?.name,
        personality: previewedCopilotSettings?.personality,
        custom_instructions: previewedCopilotSettings?.custom_instructions,
      }
    : null;

  const SDK = getSDK();

  const payload: IAIAnswerPayloadType = {
    command_ids: [],
    query,
    copilot_api_headers: SDK[_instanceAttributes]?.copilotAPIHeaders,
    user_timezone: SDK[_instanceAttributes]?.userTimezone,
    user: SDK[_user],
    hmac: endUser?.hmac,
    chat_id: chatID,
    filter,
    disable_experiences: !!window._cbIsWebView,
    ...(!!system_template_override && { system_template_override: system_template_override }),
    settings_override: previewCopilotSettingsPayload,
    attachments,
    user_properties_override: userPropertiesOverride,
    is_admin: isAdmin,
  };

  try {
    const response = await Chat.generateAIAnswer(organization.id, payload, { signal: abortController?.signal });
    return response;
  } catch (err: any) {
    if (abortController?.signal.aborted) return { error: ErrorType.Aborted };

    Logger.warn(`AI chat response error: ${err}`);

    if (!!err.detail && err.detail === 'Error getting response from model') {
      return { error: ErrorType.ModelError };
    }
    if (!!err.detail && err.detail.startsWith('Request was throttled')) {
      return { error: ErrorType.Throttled };
    }

    return { error: ErrorType.Unknwon };
  }
};

const extractPageInfo = (): {
  title: string;
  description: string | null;
  h1s: string[];
  h2s: string[];
  h3s: string[];
} => {
  const title = document.title;
  const metaDescription = document.querySelector('meta[name="description"]');
  const description = metaDescription && metaDescription instanceof HTMLMetaElement ? metaDescription.content : null;
  const h1s = Array.from(document.querySelectorAll('h1'))
    .map((h1) => h1.textContent)
    .filter((t): t is string => !!t);
  const h2s = Array.from(document.querySelectorAll('h2'))
    .map((h2) => h2.textContent)
    .filter((t): t is string => !!t);
  const h3s = Array.from(document.querySelectorAll('h3'))
    .map((h3) => h3.textContent)
    .filter((t): t is string => !!t);
  return {
    title,
    description,
    h1s,
    h2s,
    h3s,
  };
};

/** For enabling getting a list of search suggestions in the empty state */
export const fetchSearchSuggestions = async (organizationId: string): Promise<IQuestionSuggestionsType | null> => {
  const payload: IQuestionSuggestionsPayloadType = {
    ...(isStudioPreview() ? {} : { page_context: extractPageInfo() }),
    user: getSDK()[_user],
  };

  try {
    const response = await Chat.generateQuestionSuggestions(organizationId, payload);
    return response;
  } catch (err) {
    Logger.warn(`Live answer error: ${err}`);
  }
  return null;
};
