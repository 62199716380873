'use client';

import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cn } from '../util';
import { buttonStyles } from '../button';
import { Caret01 } from '@commandbar/design-system/icons/react';

const CmdPopover = PopoverPrimitive.Root;

const CmdPopoverTrigger = PopoverPrimitive.Trigger;

const CmdPopoverAnchor = PopoverPrimitive.Anchor;

type CmdPopoverSelectTriggerProps = PopoverPrimitive.PopoverTriggerProps & { caret?: boolean };

const CmdPopoverSelectTrigger: React.FC<CmdPopoverSelectTriggerProps> = ({
  children,
  className,
  disabled,
  caret = true,
  ...props
}) => {
  return (
    <CmdPopoverTrigger {...props} className={cn(buttonStyles({ variant: 'default', disabled }), 'justify-between')}>
      <div className={cn('flex items-center gap-xs')}>{children}</div>
      {caret && (
        <div className={cn('w-lg flex items-center')}>
          <Caret01 className="transform rotate-180" />
        </div>
      )}
    </CmdPopoverTrigger>
  );
};

const CmdPopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 w-72 rounded-md border bg-surfaceBase p-sm text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));

CmdPopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { CmdPopover, CmdPopoverTrigger, CmdPopoverContent, CmdPopoverAnchor, CmdPopoverSelectTrigger };
