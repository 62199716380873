import { isPrimitive } from 'shared/sdk/utils';
import get from 'lodash/get';
import { currentStepAndIndex, isSelectStep } from './steps/selectors';
import { CBStore } from 'shared/store/global-store';
import DateTime from './DateTime/DateTime';
import { StepType } from './steps/step-utils/Step';
import { getDefaultCommandId } from './options/record-option-selectors';
import { isCommandOption, isOption, isOptionGroup } from './options/helpers';

import a11y from 'shared/util/a11y';

import { CommandOption, ParameterOption, RecordOption, Option } from './options/option-utils';
import { extractSlashFilter } from 'shared/store/helpers';

import { getOptionData, getOptionMetadata } from './options/helpers';
import { getStepPlaceholder } from './steps/helpers';
import { OptionGroup } from './options/option-utils/OptionGroup';
import { compareObjs } from '@commandbar/internal/middleware/utils';
import { ITheme } from '@commandbar/internal/client/theme';
import { availableCommands } from './Available';
import { isFinalStep } from './steps/selectors';
import slugify from '@commandbar/internal/util/slugify';
import { standardize } from '@commandbar/internal/middleware/detailPreview';
import { interpolate } from 'shared/util/Interpolate';
import { DEFAULT_PLACEHOLDER } from '../components/select/input/placeholderHelpers';
import { isAction } from '@cb/types/entities/command/actions';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';

import { SUMMON_HOTKEY_SLUG } from '../../../shared/util/constants';

import type { LabeledAction } from '@cb/types/entities/command/actions';
import {
  DetailPreviewObjectType,
  IArgumentType,
  ICommandCategoryType,
  IRecordSettingsByContextKey,
  isContextArgument,
  isTimeArgument,
} from '@commandbar/internal/middleware/types';

import dayjs from 'dayjs';
import ClientSearch from './search/ClientSearch';
import { OptionType } from './options/option-utils/Option';

export * from './steps/helpers';
export * from './steps/selectors';
export * from './options/helpers';
export * from './options/selectors';
export * from './generate-options/option-list-selectors';
export * from './options/command-option-selectors';
export * from './options/parameter-option-selectors';
export * from './options/record-option-selectors';

export const currentOptionHasArguments = (_: CBStore) => {
  const currentOption = _.spotlight.sortedOptions[_.spotlight.focusedIndex];
  if (isCommandOption(currentOption)) {
    if (Object.keys(currentOption.command.arguments).length > 0) {
      return true;
    }
  }
  return false;
};

export const findDefaultFocusOptionIndex = (_: CBStore) => {
  const options = _.spotlight.sortedOptions;
  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (
      isOption(option) &&
      (!(option.type === OptionType.Command && option.command.name === '__commandbar_ask_copilot') || !options[i + 1]) //skip builtin ask copilot option unless it's the only option
    ) {
      return i;
    }
  }
  return 1; // Default of 1 because 0 is a category
};

export const getDefaultCommandForCurrentStep = (_: CBStore) => {
  const { currentStep } = currentStepAndIndex(_);

  const activeRecord = currentStep?.type === StepType.Base && currentStep.resource;
  if (!activeRecord) {
    return;
  }
  const defaultCommandId = getDefaultCommandId(activeRecord);

  // if there is no explicit default, we take the last command that was added
  if (!defaultCommandId) {
    let l = _.spotlight.initialOptions.length;
    while (l--) {
      if (isCommandOption(_.spotlight.initialOptions[l])) {
        return _.spotlight.initialOptions[l];
      }
    }
    return undefined;
  }

  return _.spotlight.initialOptions.find(
    (option) =>
      isCommandOption(option) && (option.command.id === defaultCommandId || option.command.name === defaultCommandId),
  );
};

export const getFocusableChildrenAtIndex = (index: number): NodeListOf<Element> | undefined => {
  // To add more focusable types, update the querySelectorAll query.
  const option = document.getElementById(a11y.optionId(index));
  return option?.querySelectorAll('[role="switch"]');
};

export const canFocusOnOptionGroup = (_: CBStore, tabKey: boolean | undefined, index: number): boolean => {
  // Enter option group focus iff keypress is tab, is an option group, and option group has a focusable child.
  if (typeof tabKey == 'undefined' || !tabKey) return false;
  if (!isOptionGroup(_.spotlight.sortedOptions[index])) return false;

  const children = getFocusableChildrenAtIndex(index);
  if (children && children.length > 0) {
    return true;
  }
  return false;
};

export const getNextFocusedIndex = (_: CBStore, direction: 'up' | 'down', tabKey?: boolean) => {
  const { sortedOptions, focusedIndex } = _.spotlight;
  switch (direction) {
    case 'up':
      for (let i = 1; i < sortedOptions.length; i++) {
        const offset = focusedIndex - i;
        const index = offset >= 0 ? offset : sortedOptions.length + offset;
        if (isOption(sortedOptions[index])) {
          return index;
        } else if (canFocusOnOptionGroup(_, tabKey, index)) {
          return index;
        }
      }
      break;
    case 'down':
      for (let i = 1; i < sortedOptions.length; i++) {
        const index = (focusedIndex + i) % sortedOptions.length;
        if (isOption(sortedOptions[index])) {
          return index;
        } else if (canFocusOnOptionGroup(_, tabKey, index)) {
          return index;
        }
      }
      break;
  }
  return focusedIndex;
};

export const getContextObjectLabel = (
  contextSettings: IRecordSettingsByContextKey,
  object: any,
  key: string,
  defaultLabelField = 'label',
) => {
  if (isPrimitive(object)) return object.toString();
  else {
    const labelField = getLabelFieldFromContextSettings(contextSettings, key);
    return get(object, labelField || defaultLabelField);
  }
};

export const getArgumentChoiceLabel = (
  arg: IArgumentType,
  object: any,
  contextSettings: IRecordSettingsByContextKey,
) => {
  if (isPrimitive(object)) {
    return object.toString();
  } else {
    const defaultLabelField = arg.label_field || 'label';
    if (isContextArgument(arg)) {
      return getContextObjectLabel(contextSettings, object, arg.value, defaultLabelField);
    } else if (isTimeArgument(arg) && object instanceof Date) {
      return DateTime.display(dayjs(object.toString()), arg.dateTimeArgumentTypeId || 1, false);
    } else {
      return get(object, defaultLabelField);
    }
  }
};

const getLabelFieldFromContextSettings = (contextSettings: IRecordSettingsByContextKey, key: string) => {
  const config = contextSettings[key];
  return config?.label_field;
};

export const selectInitialValueCallbacks = (_: CBStore): string[] =>
  Object.keys(_.callbacks).filter((k) => k.startsWith('commandbar-initialvalue-'));

export const selectIsHotkeyEditable = (_: CBStore): boolean => {
  if (!_.organization?.end_user_shortcuts_enabled) return false;

  return true;
};

export const selectDefaultSummonHotkey = (_: CBStore): string => {
  let summonHotkey = 'mod+k';
  if (_.organization?.summon_hotkey_override === 'none') {
    summonHotkey = '';
  } else if (_.organization?.summon_hotkey_override !== undefined && _.organization?.summon_hotkey_override !== null) {
    summonHotkey = _.organization.summon_hotkey_override;
  }

  return summonHotkey;
};

export const selectSummonHotkey = (_: CBStore): string => {
  let summonHotkey = selectDefaultSummonHotkey(_);

  if (!_.organization?.end_user_shortcuts_enabled) return summonHotkey;

  if (typeof _.endUserStore.data.hotkeys[SUMMON_HOTKEY_SLUG] === 'string') {
    summonHotkey = _.endUserStore.data.hotkeys[SUMMON_HOTKEY_SLUG];
  }
  return summonHotkey;
};

export const getCategoryField = <T extends keyof ICommandCategoryType>(_: CBStore, categoryId: number, field: T) => {
  const { categories } = _.spotlight;
  const category = categories.find((obj) => obj.id === categoryId);
  if (category && category[field]) {
    return category[field];
  }

  return null;
};

export const getContextSettings = (_: CBStore): IRecordSettingsByContextKey => _.contextSettings;

/*****
 * FIXME: The selectors below reference State, not State. Need to move them all to use State
 *
 */
export const selectHiddenCategories = (_: CBStore) =>
  _.spotlight.categories.filter((category) => category.setting_hide_before_search).map((category) => category.id);

export const selectMenuIsOpen = (_: CBStore) => {
  const inlineFormFactor = _.spotlight.formFactor.type === 'inline';
  const menuIsClosed = _.spotlight.dashboard || (inlineFormFactor && !_.spotlight.visible);

  return !menuIsClosed;
};

export const selectSlashFilterGroups = (_: CBStore) => {
  if (!_.organization?.slash_filters_enabled) {
    return [];
  }

  const retval: OptionGroup[] = _.spotlight.currentGroups.filter((group) => {
    return group.slash_filter_enabled;
  });

  return retval;
};

export const selectSlashFilterHint = (_: CBStore, keyword?: string) => {
  const groups = selectSlashFilterGroups(_);

  const getMatch = (text: string, theme?: ITheme) => {
    if (!text || text === '') {
      return;
    }

    text = text.toLowerCase();

    const labelAllTab = !!theme ? slugify(theme.searchTab.labelAllTab) : 'all';

    if (labelAllTab.toLowerCase().startsWith(text)) {
      return labelAllTab;
    }

    const match = groups
      .filter((group: OptionGroup) => {
        return (
          !!group.slash_filter_keyword &&
          group.slash_filter_keyword.toLowerCase() !== text.toLowerCase() &&
          group.slash_filter_keyword.toLowerCase().startsWith(text.toLowerCase())
        );
      })
      .sort((a: OptionGroup, b: OptionGroup) =>
        !a.slash_filter_keyword || !b.slash_filter_keyword
          ? 0
          : a.slash_filter_keyword.length - b.slash_filter_keyword.length,
      )[0];

    if (match) {
      return match.slash_filter_keyword;
    } else {
      return;
    }
  };

  if (typeof keyword === 'undefined') {
    const { slashFilterKeyword } = selectSlashFilter(_);
    keyword = slashFilterKeyword;
  }

  const match = getMatch(keyword, _.theme);
  const length = keyword.length;
  if (!match) {
    return '';
  } else {
    return match.slice(length);
  }
};

export const selectSlashFilter = (_: CBStore) => {
  return extractSlashFilter(_.spotlight.rawInput);
};

export const selectIsFinalStep = (_: CBStore) => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(_);
  if (!currentStep) {
    return false;
  }

  return isFinalStep(currentStep, _.spotlight.steps, currentStepIndex);
};

export const getTabKey = (optionGroupKey: string | undefined) => {
  if (!optionGroupKey || !optionGroupKey.startsWith('TAB')) {
    return null;
  } else {
    const lastIndex = optionGroupKey.lastIndexOf('-');
    return optionGroupKey.slice(lastIndex + 1);
  }
};

export const selectCurrentTab = (_: CBStore) => {
  const currentFilter = _.spotlight.searchFilter;
  return _.spotlight.tabs.find((t) => getTabKey(currentFilter?.slug) === `${t.id}`);
};

export const showRotatingPlaceholders = (_: CBStore) => {
  const placeholders = selectAllPlaceholders(_);
  return (
    !_.spotlight.rawInput &&
    (_.spotlight.visible || _.spotlight.formFactor.type === 'inline') &&
    Array.isArray(placeholders) &&
    placeholders.length > 1
  );
};

export const selectAllPlaceholders = (_: CBStore): string | string[] => {
  const { showLoadingIndicator, searchFilter, placeholders } = _.spotlight;
  if (showLoadingIndicator) {
    return 'Loading...';
  }

  if (!!searchFilter?.placeholder) {
    return interpolate(searchFilter.placeholder, _, true, false);
  }

  /** We have a placeholder for the step */
  const { currentStep } = currentStepAndIndex(_);
  const stepPlaceholder = currentStep && getStepPlaceholder(currentStep);
  if (stepPlaceholder) return stepPlaceholder;

  const userDefinedPlaceholders = placeholders.map((p) => p.text);
  if (!userDefinedPlaceholders.length) return DEFAULT_PLACEHOLDER;
  return userDefinedPlaceholders;
};

export const selectFirstPlaceholder = (_: CBStore) => {
  const placeholders = selectAllPlaceholders(_);
  if (Array.isArray(placeholders)) {
    return placeholders[0];
  } else {
    return placeholders;
  }
};

export const selectTabProperties = (_: CBStore, groupKey: string) => {
  const tab = _.spotlight.tabs.find((t) => getTabKey(groupKey) === `${t.id}`);

  if (!tab) {
    return {};
  }

  return { icon: tab?.icon, header: tab?.header };
};

export const isGridView = (_: CBStore) => {
  // Disable grid view for mobile web
  if (isMobileDevice()) {
    return false;
  }

  const { currentStep } = currentStepAndIndex(_);

  return (
    (currentStep?.type === StepType.Base && _.spotlight.searchFilter?.renderAs === 'grid') ||
    (isSelectStep(currentStep) &&
      isContextArgument(currentStep.argument) &&
      getContextSettings(_)[currentStep.argument.value]?.render_as === 'grid')
  );
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const optionIsInGrid = (_: CBStore, option?: CommandOption | RecordOption | ParameterOption | OptionGroup) => {
  return isGridView(_);

  // TODO: Enable mixed grid+list tabs and use below logic to determine per option if it is shown as grid item or not
  // if (option === undefined) {
  //   option = _.spotlight.sortedOptions[_.spotlight.focusedIndex];
  // }

  // if (!option || isOptionGroup(option)) {
  //   return false;
  // }

  // const isRecent =
  //   (option.type === OptionType.Command && option.isRecent) ||
  //   (option.type === OptionType.Parameter &&
  //     (option as RecordOption)?._resource === true &&
  //     (option as RecordOption)?.isRecent);

  // const isRecommended = option.type === OptionType.Command && option.isRecommended;

  // if (option.category.renderAs === 'grid' && !isRecent && !isRecommended) {
  //   return true;
  // }
  // return false;
};

export const getNextStepOptions = (_: CBStore): Array<CommandOption | LabeledAction> => {
  const { currentStep } = currentStepAndIndex(_);

  let nextStepCommands: Array<string | number | LabeledAction> = [];

  if (currentStep?.type === StepType.Dashboard) {
    nextStepCommands = currentStep?.command?.next_steps || [];
  } else {
    const focusedOption = _.spotlight.sortedOptions[_.spotlight.focusedIndex];

    if (focusedOption && isCommandOption(focusedOption) && focusedOption.detailPreview) {
      nextStepCommands = focusedOption.command.next_steps;
    }
  }

  /** Performance: Avoid expensive call to availableCommands if nextStepCommands is empty */
  if (nextStepCommands.length === 0) {
    return [];
  }

  const nextStepOptions: Array<CommandOption | LabeledAction> = availableCommands(_)
    .filter((o) => nextStepCommands.indexOf(o.command.id) !== -1)
    .sort((a, b) => {
      if (a.isRecommended && b.isRecommended) {
        return compareObjs(
          {
            sort_key: a.command.recommend_sort_key,
            id: a.command.id,
          },
          {
            sort_key: b.command.recommend_sort_key,
            id: b.command.id,
          },
        );
      }

      if (a.isRecommended) {
        return -1;
      }

      if (b.isRecommended) {
        return 1;
      }

      return nextStepCommands.indexOf(a.command.id) - nextStepCommands.indexOf(b.command.id);
    });

  const labeledActions: LabeledAction[] = nextStepCommands.filter((c) => isAction(c)) as LabeledAction[];

  return nextStepOptions.concat(labeledActions);
};

export const getDetailPreviewContent = (_: CBStore, row: Option | OptionGroup): DetailPreviewObjectType[] | null => {
  if (isOptionGroup(row)) return null;
  if (isGridView(_)) return null; // Previews are currently incompatible with grids

  if (row.detailPreview) {
    return standardize(row.detailPreview);
  } else if (!!_.detailPreviewGenerator) {
    return standardize(_.detailPreviewGenerator(getOptionData(row), getOptionMetadata(row)));
  } else {
    return null;
  }
};

export const isArgumentClientSearchActive = (_: CBStore) => {
  const getActiveArgumentClientSearchKey = () => {
    const { currentStep } = currentStepAndIndex(_);
    const activeContextKey =
      isSelectStep(currentStep) && isContextArgument(currentStep.argument) ? currentStep.argument.value : undefined;

    if (activeContextKey && ClientSearch.isDefined(activeContextKey, _)) {
      return activeContextKey;
    }
    return undefined;
  };

  return !!getActiveArgumentClientSearchKey();
};

export const isVisible = (_: CBStore) => (_.spotlight.previewMode ? true : _.spotlight.visible);
