import { INudgeStepContentButtonBlockType } from '@commandbar/internal/middleware/types';

export const isCTALinkValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length && buttons.every((button) => button?.meta?.action?.type === 'link' && !button.meta?.action.value);

export const isCTAClickValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length && buttons.every((button) => button?.meta?.action?.type === 'click' && !button.meta?.action.value);

export const isCTALabelValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length && buttons.every((button) => !button?.meta?.label);

export const isCTANudgeValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every((button) => button?.meta?.action?.type === 'nudge' && button.meta?.action.value < 0);

export const isCTAChecklistValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every((button) => button?.meta?.action?.type === 'questlist' && button.meta?.action.value < 0);

export const isCTAHelpDocValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every(
    (button) => button?.meta?.action?.type === 'execute_command' && button.meta?.action.meta.type === 'helpdoc',
  );

export const isCTAActionValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every(
    (button) => button?.meta?.action?.type === 'execute_command' && button.meta?.action.meta.type === 'action',
  );

export const isCTAChatValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every((button) => button?.meta?.action?.type === 'open_chat' && !button.meta?.action.meta.type);

export const isCTAGoToStepValid = (buttons: INudgeStepContentButtonBlockType[]) =>
  !!buttons.length &&
  buttons.every((button) => button?.meta?.action?.type === 'go_to_step' && button.meta?.action.value < 0);
