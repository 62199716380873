import { CmdTag } from '@commandbar/design-system/cmd';
import { ArrowDownRight, ArrowUpRight } from '@commandbar/design-system/icons/react';

const PercentChangeTag = ({ rate }: { rate: number }) => {
  if (rate === 0) {
    return (
      <CmdTag variant="default" prefixElement="—">
        0%
      </CmdTag>
    );
  }

  const variant = rate > 0 ? 'success' : 'failure';
  const icon = rate > 0 ? <ArrowUpRight /> : <ArrowDownRight />;
  const ratePercentage = rate * 100;

  return (
    <CmdTag variant={variant} prefixElement={icon}>
      {-0.5 < ratePercentage && ratePercentage < 0.5 ? ratePercentage.toFixed(1) : Math.round(ratePercentage)}%
    </CmdTag>
  );
};

export default PercentChangeTag;
