import { ReactNode, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CmdButton, CmdTypography, cmdToast } from '@commandbar/design-system/cmd';
import { Code, Collapse, Page } from '@commandbar/design-system/components/antd';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import { LoadingLogoAnimation } from 'commandbar.com/src/components/LoadingLogoAnimation';
import { useReportEvent } from '../../../../../src/hooks/useEventReporting';
import { StyledCollapse } from '../../../styles';
import {
  THIN_ANALYTICS_INTEGRATIONS,
  ThinAnalyticsIntegrationConfig,
} from '../../integrations-page/ThinAnalyticsSection';
import {
  DocumentationLink,
  IntegrationActionsSection,
  IntegrationContentLayout,
  IntegrationDetailsSection,
  IntegrationFeaturesContainer,
  IntegrationHeader,
  IntegrationInstructionsSection,
} from './styles';

const { Panel } = Collapse;

enum PanelKey {
  Troubleshooting = 'troubleshooting',
}

type ThinIntegrationConfigsBySlug = Record<ThinAnalyticsIntegrationConfig['slug'], ThinAnalyticsIntegrationConfig>;

const thinAnalyticsDocs = THIN_ANALYTICS_INTEGRATIONS.reduce<ThinIntegrationConfigsBySlug>((acc, curr) => {
  acc[curr.slug] = curr;
  return acc;
}, {} as ThinIntegrationConfigsBySlug);

interface SendCommandBarToDestinationProps {
  name: ThinAnalyticsIntegrationConfig['name'];
  snippet: ThinAnalyticsIntegrationConfig['snippet'];
  actionButtons: ReactNode;
}

const SendCommandBarToDestination = ({ name, snippet, actionButtons }: SendCommandBarToDestinationProps) => (
  <div>
    <IntegrationHeader>
      <CmdTypography.H2>Send CommandBar Events to {name}</CmdTypography.H2>
      {actionButtons}
    </IntegrationHeader>
    <CmdTypography.Body>
      Events generated by CommandBar will now flow to {name} from any page in your product where CommandBar is{' '}
      <DocumentationLink href="https://www.commandbar.com/docs/sdk/lifecycle/boot/" target="_blank" rel="noreferrer">
        booted
      </DocumentationLink>{' '}
      and {name} is installed.
    </CmdTypography.Body>

    <StyledCollapse expandIconPosition="end" ghost>
      <Panel key={PanelKey.Troubleshooting} header="Troubleshooting">
        <CmdTypography.Body>
          CommandBar sends events to {name} via <code>{snippet}</code>. If you use a different {name} SDK version, you
          can do the following:
        </CmdTypography.Body>
        <ol>
          <li>Disable the integration</li>
          <li>After you init CommandBar, put the following code in your app:</li>
        </ol>
        <Code
          content={`window.CommandBar.addEventSubscriber((eventName, eventData) => {
  // replace the line below with your SDK method
  ${snippet};
});`}
        />
      </Panel>
    </StyledCollapse>
  </div>
);

interface IntegrationActionButtonsProps {
  organizationId: IOrganizationType['id'];
  name: ThinAnalyticsIntegrationConfig['name'];
  slug: ThinAnalyticsIntegrationConfig['slug'];
}

const IntegrationActionButtons = ({ organizationId, slug, name }: IntegrationActionButtonsProps) => {
  const history = useHistory();
  const { reportEvent } = useReportEvent();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <IntegrationActionsSection>
      <CmdButton
        variant="primary"
        key="done"
        disabled={isLoading}
        onClick={() => {
          history.push('/integrations');
        }}
      >
        Done
      </CmdButton>
      <CmdButton
        variant="destructive"
        key="remove"
        loading={isLoading}
        onClick={async () => {
          setIsLoading(true);

          try {
            const organization = await Organization.readInternal(organizationId.toString());
            await Organization.updateInternal({
              id: organizationId.toString(),
              integrations: {
                ...organization.integrations,
                [slug]: {
                  enabled: false,
                },
              },
            });

            cmdToast.success(`Successfully disabled ${name}`);
            reportEvent(`${slug} integration interacted with`, {
              segment: true,
              highlight: true,
              slack: true,
              payloadMessage: 'Removed Integration',
            });

            history.push('/integrations');
          } catch (err) {
            Logger.error(err);
            cmdToast.error('Something went wrong');
          } finally {
            setIsLoading(false);
          }
        }}
      >
        Remove existing integration
      </CmdButton>
    </IntegrationActionsSection>
  );
};

export const additionalInstructions = {
  amplitude: {
    sendProperties: () => (
      <>
        <CmdTypography.Body>
          You can also send any of the existing user properties that you send to Amplitude to CommandBar Here’s a
          simplified code snippet:{' '}
        </CmdTypography.Body>
        <Code
          content={`// Your existing user properties
var userProperties = {
	plan: "pro", 
	role: "admin", 
	...
};

// Passed to Amplitude
amplitude.getInstance().setUserProperties(userProperties);

// And additionally sent to CommandBar:
window.CommandBar.addMetadataBatch(userProperties);

// Adding one-off or session only properties to CommandBar is easy too:
window.CommandBar.addMetadata("userIsWorkspaceOwner", true);`}
        />
      </>
    ),
    sendEvents: () => (
      <>
        <CmdTypography.Body>
          You can also send{' '}
          <DocumentationLink
            href="https://help.amplitude.com/hc/en-us/articles/115002380567-Overview-of-user-properties-and-event-properties-in-Amplitude#h_045bc2f6-4427-46bc-a379-55f0eabb2c91"
            target="_blank"
            rel="noreferrer"
          >
            events that are generated by Amplitude
          </DocumentationLink>{' '}
          directly to CommandBar. This allows you to treat any Amplitude-generated event the same was as a
          natively-generated{' '}
          <DocumentationLink
            href="https://www.commandbar.com/docs/platform/data-types/events/"
            target="_blank"
            rel="noreferrer"
          >
            CommandBar event
          </DocumentationLink>
          .
        </CmdTypography.Body>
        <Code
          content={`// Most companies uses Amplitude events have a globally available function to track events
// The simplest way to ingest all events is to add a CommandBar SDK call to trackEvent
// to this function.
                      
const reportEvent = (event, eventProperties) => {
  amplitude.getInstance().logEvent(event, eventProperties);
  CommandBar.trackEvent(event, eventProperties);
}`}
        />
      </>
    ),
  },
};

const hasIntegrationPage = (params: Record<string, unknown>): params is { slug: keyof typeof thinAnalyticsDocs } =>
  !!(params?.slug && typeof params.slug === 'string' && params.slug in thinAnalyticsDocs);

const hasAdditionalInstructions = (
  slug: ThinAnalyticsIntegrationConfig['slug'],
): slug is keyof typeof additionalInstructions => slug in additionalInstructions;

interface IntegrationPageProps {
  organizationId: IOrganizationType['id'];
}

export const IntegrationPage = ({ organizationId }: IntegrationPageProps) => {
  const history = useHistory();
  const { reportEvent } = useReportEvent();
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const reportConfig = {
      segment: true,
      highlight: true,
      slack: true,
    };

    if (hasIntegrationPage(params)) {
      reportEvent(`${params.slug} integration visited`, reportConfig);
    } else {
      reportEvent(`Unknown integration visited: ${params}`, reportConfig);
      history.push('/home');
    }
  }, [reportEvent, params, history.push]);

  // TODO: render a 404 page
  if (!hasIntegrationPage(params)) {
    return <LoadingLogoAnimation />;
  }

  const { slug } = params;
  const { name, snippet } = thinAnalyticsDocs[slug];

  return (
    <Page section="Integrations" title={name} description={`Connect ${name} with Commandbar.`}>
      <IntegrationContentLayout>
        <IntegrationDetailsSection>
          <IntegrationFeaturesContainer>
            <SendCommandBarToDestination
              name={name}
              snippet={snippet}
              actionButtons={<IntegrationActionButtons organizationId={organizationId} slug={slug} name={name} />}
            />

            {hasAdditionalInstructions(slug) && (
              <IntegrationInstructionsSection>
                <div>
                  <CmdTypography.H2>Sending Properties to CommandBar</CmdTypography.H2>
                  {additionalInstructions[slug].sendProperties()}
                </div>

                <div>
                  <CmdTypography.H2>Sending Events to CommandBar</CmdTypography.H2>
                  {additionalInstructions[slug].sendEvents()}
                </div>
              </IntegrationInstructionsSection>
            )}
          </IntegrationFeaturesContainer>
        </IntegrationDetailsSection>
      </IntegrationContentLayout>
    </Page>
  );
};
