import { type Placement, autoUpdate, hide } from '@floating-ui/dom';
import { createActorContext } from '@xstate/react';
import React, { type ReactNode, type RefObject, useRef } from 'react';
import { type StateFrom, assign, createMachine } from 'xstate';

import { setHelpHubMinimized } from '@commandbar/commandbar/products/helphub/service-actions';
import type { Coordinates2D } from '@commandbar/commandbar/products/nudges/components/utils';
import { useAction } from '@commandbar/commandbar/shared/util/hooks/useAction';
import { hideMask, showMask } from '@commandbar/commandbar/shared/util/hooks/useMask';
import { ToastElementId } from '@commandbar/commandbar/shared/util/hooks/useToast';
import Z from '@commandbar/internal/client/Z';
import type { ElementInformation, INudgeCursorStepType } from '@commandbar/internal/middleware/types';
import { getElement } from '@commandbar/internal/util/dom';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';
import {
  calculateNewScrollPosition,
  findScrollParent,
  getElementCenter,
  isElementOffScreen,
  scrollToElement,
} from '../helpers';
import { boundedRandomNumberGenerator, getRelativePosition } from './helpers';

export const TRANSLATE_DURATION = 1350;
export const SQUIRREL_DURATION = 200;
export const POP_IN_DURATION = 200;
export const TRACING_DURATION = 450;
export const MIN_MARGIN = 24;
export const CURSOR_INSET = 8;

type CursorEvents =
  | { type: 'SHOW' }
  | { type: 'HIDE' }
  | { type: 'POSITION_CONTAINER'; coordinates: Coordinates2D; placement: Placement }
  | { type: 'POSITION_ARROW'; coordinates: Coordinates2D }
  | { type: 'FINISHED_SCROLLING' }
  | { type: 'DESTROY' };

interface CursorContext {
  offset: INudgeCursorStepType['form_factor']['offset'];
  targetElement: ReturnType<typeof getElement>;
  cursor: {
    ref: RefObject<HTMLDivElement>;
    position: {
      container: { coordinates: Coordinates2D; placement: Placement };
      arrow: { coordinates: Coordinates2D };
    };
  };
}

const cursorMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QGECuAnWB7dBiAIgKIDKAKgEoDyAmgNoAMAuoqAA5awCWALp1gHYsQAD0QBaAGwAmAOwA6AJwBGejIAsMgKwKAzDokAOTQBoQAT0RqJCuVqVSDEnfX1q3EgL4fTaTDjkAtlgAbpz8UAAE3FhRAIboMNwRYAA2YAFg-Ny4AAqUxACSpAWUAHIA+shlpACCBaWE5AzMSCDsXLwCQqIIEppymlL0EkoyBlKG9KMyphYIyvLSEvQK9KpSmjr2Xj4Y2OiBIWGR0XEJYEmp6ZnZwrDcsdxgcrEAZk-oABTc6LH8sClHnx+ABKXC+faHULhKIxB7nS5pDJZZpCdo8YHdRAqKRyPo6NRSfSyBzOKSzbHDfojWQKCRaKxknYgCH+VhhfiQXL5IolCpVUq1eqNVGtdGdQStHp9AZDQxEpzKPoUhCaKZyJQGXQuHSaTSEozM1kHdn8TkQOSwAAWWAA7vxcAAJApEUVsDgYrpSxC6pR4lwSQw6BTqRxqFWDNQavrKNSbfSbGRGvZsjmQS02+2W22xVimyIAIywEDMuDuDyeL3eYC+AGN0BxYAAxWIQMBg41yU3mjN2-jZ3P5iJFktutoeiVY1XBuRqJRkzQGJRKTQqAwqmRDAZzrUyegbFdqBTJvwmtMWq2cCBth3ER2UADqY-FmO9CCX8jWBjW+5Ueno67mIgkbRtoShxno+IGCekK1lgASsKgvAwqwgK1tcWTcoUxRlJU1R1A0TRMGiE6vqAPRiM4OiKAomyalq9AaHSKpHtRag6FIujjE4Mi0UoMFslgebHBEYRlvcjzPG8HyfOwrAFKC4IpiaQlDmEz6kV65HiAethzmowwSOxtH0PqKr0hIihrlY1hbC4UgCQcsD1lgKQpMcuBNvUBR3oQ+DlMQyBUAAMsF9QAOIaR0ZEiOIzh+ke87KAoc6rpsEgqsoBh4il+4EmMrGaI5cg-LEtYifCiTiRWUnVl8pXleEHbKSVvyNSc8SJFFnqStpCB6FGnHLjIvEyKMhImEB8yEhq+7LAYkFKPSDneCyLXdum1p9nIV5pFhvK4TU5BUE+xFippvWxQgKhxnIeg4su0gqEo5l0nIVIcXomjLGBXirfwxZwEIxokdFWlXWIEGKES33qGsUiyHGKoI-Imras9RlznoxVBNCJxwp1FzJEiNygz1U6UVqchGFYu4TEtUgvVNz3ZcutKanqjGasVG0QGTk5vqMsrDOM+jBktaozFNWxRoYCgOIuugTDoSarZ2vO9va-MxT0Wx+joS59IGBhHk4k1zPN707roc6jIY-Fq+t56a-2sA5sJMIjnM7pg5duvaHdhvfYYpu6huSg2FzqzWN+X0rbsp5ds7W1ZrtYDa+DPR7vIjMqPL4ybAYgEW7R26amMDhGKsag887l7XpkGd+8BIzU4uHFuLqC3qBGUhRhLsbxviqsJ5CGuXvcODe+OvtTioOdrHZWgF7R5JTWN8i8ZqgYrMuK6eI7idwQhSEiahZUYdwTcU-ollOEMQyaCNReBuG0tWO9TjWKMKzDPuPOqREmEa+b4xAbE-CNDiC1t5jSRlNEYm9wLBlkKuCYIZirOQbG5Y4IC+qQ1MtDXU9IDL7kRubRAWhcRb0DKSNYMh6TFQahVQmV9zqzzfBxP0S4jBDBcFqUYTM5gqCMhqewFd576G2H9IAA */
    id: 'Cursor',
    initial: 'computing placement',
    states: {
      'moving to target element': {
        invoke: [
          {
            src: 'moveCursorToTarget',
            id: 'moveCursorToTarget',
          },
        ],

        on: {
          POSITION_CONTAINER: {
            actions: ['updateContainerPosition'],
            internal: true,
          },
        },

        entry: 'hideCopilot',

        after: {
          translation: 'tracing target',
        },
      },

      pinned: {
        entry: ['showMask'],
        exit: ['hideMask'],
        invoke: [
          {
            src: 'trackTargetElement',
            id: 'trackTargetElement',
          },
          {
            src: 'handleTargetClick',
            id: 'handleTargetClick',
          },
        ],

        states: {
          shown: {
            initial: 'swapping body',

            states: {
              'swapping body': {
                after: {
                  crossFade: 'idle',
                },
              },

              idle: {
                invoke: {
                  src: 'animateCursor',
                  id: 'animateCursor',
                },

                on: {
                  POSITION_ARROW: {
                    target: 'idle',
                    actions: 'updateArrowPosition',
                    internal: true,
                  },
                },
              },
            },

            on: {
              HIDE: 'hidden',
            },
          },

          hidden: {
            on: {
              SHOW: 'shown.idle',
            },
          },

          history: {
            type: 'history',
            history: 'deep',
          },
        },

        initial: 'shown',

        on: {
          POSITION_CONTAINER: {
            actions: ['updateContainerPosition'],
            target: '.history',
          },
        },
      },

      'computing placement': {
        invoke: {
          src: 'adjustPlacement',
          id: 'adjustPlacement',
        },

        on: {
          POSITION_CONTAINER: {
            target: 'popping in',
            actions: ['updateContainerPosition'],
          },
        },
      },

      'popping in': {
        after: {
          popIn: 'scrolling',
        },
      },

      scrolling: {
        invoke: {
          src: 'scrollToTargetElement',
          id: 'scrollToTargetElement',
        },

        on: {
          FINISHED_SCROLLING: 'moving to target element',
        },
      },

      'tracing target': {
        after: {
          tracing: 'pinned',
        },
      },
    },
    on: {
      DESTROY: {
        actions: ['removeCursor'],
      },
    },
    schema: {
      events: {} as CursorEvents,
      context: {} as CursorContext,
    },
    predictableActionArguments: true,
    tsTypes: {} as import('./stateMachine.typegen').Typegen0,
  },
  {
    actions: {
      updateContainerPosition: assign({
        cursor: ({ cursor }, { coordinates, placement }) => ({
          ...cursor,
          position: {
            ...cursor.position,
            container: {
              coordinates,
              placement,
            },
          },
        }),
      }),
      updateArrowPosition: assign({
        cursor: ({ cursor }, { coordinates }) => ({
          ...cursor,
          position: {
            ...cursor.position,
            arrow: {
              ...cursor.position.arrow,
              coordinates,
            },
          },
        }),
      }),
    },
  },
);

enum States {
  HAS_COMPUTED_PLACEMENT = 'hasComputedPlacement',
  IS_POPPING_IN = 'isPoppingIn',
  IS_SHOWING_PARTIAL_BODY = 'isShowingPartialBody',
  IS_RENDERING_FULL_BODY = 'isRenderingFullBody',
  IS_HIDING_PIN = 'isHidingPin',
  IS_SHOWING_PIN = 'isShowingPin',
  IS_MOVING_TO_TARGET_ELEMENT = 'isMovingToTargetElement',
  IS_TRACING_TARGET_ELEMENT = 'isTracingTargetElement',
  IS_SWAPPING_BODY = 'isSwappingBody',
}

export const stateSelectors: Record<States, (state: StateFrom<typeof cursorMachine>) => boolean> = {
  hasComputedPlacement: ({ matches }) => !matches('computing placement'),
  isPoppingIn: ({ matches }) => matches('popping in'),
  isMovingToTargetElement: ({ matches }) => matches('moving to target element'),
  isTracingTargetElement: ({ matches }) => matches('tracing target'),
  isSwappingBody: ({ matches }) => matches('pinned.shown.swapping body'),
  isShowingPartialBody: ({ matches }) =>
    matches('popping in') ||
    matches('scrolling') ||
    matches('moving to target element') ||
    matches('tracing target') ||
    matches('pinned.shown.swapping body'),
  isRenderingFullBody: ({ matches }) => matches('pinned'),
  isHidingPin: ({ matches }) => matches('pinned.hidden'),
  isShowingPin: ({ matches }) => matches('pinned.shown'),
};

export const CursorMachineContext = createActorContext(cursorMachine);

interface CursorMachineContextProviderProps {
  children: ReactNode;
  offset: INudgeCursorStepType['form_factor']['offset'];
  anchor: string | ElementInformation;
  startCoordinates: Coordinates2D;
  handleDestroy: () => void;
  onTargetElementClick: (event: Event) => void;
  shouldShowMask?: boolean;
}

export const CursorMachineContextProvider = ({
  children,
  offset: _offset,
  anchor,
  handleDestroy,
  startCoordinates,
  shouldShowMask,
  onTargetElementClick,
}: CursorMachineContextProviderProps) => {
  const hideCopilot = useAction((_) => {
    setHelpHubMinimized(_, true);
  });
  const _cursorRef = useRef<HTMLDivElement>(null);

  return (
    <CursorMachineContext.Provider
      options={{
        context: {
          offset: _offset,
          targetElement: getElement(anchor),
          cursor: {
            position: {
              container: { coordinates: startCoordinates, placement: 'right-start' },
              arrow: { coordinates: [0, 0] },
            },
            ref: _cursorRef,
          },
        },
        services: {
          adjustPlacement:
            ({ targetElement, cursor }) =>
            async (sendBack) => {
              const cursorElement = cursor.ref.current;
              if (!(targetElement && cursorElement && cursor.ref.current)) {
                return sendBack({ type: 'POSITION_CONTAINER', ...cursor.position.container });
              }

              // HACK: we want the pin so to show on top of Copilot while animating
              // It'll be put back under Copilot after it has been pinned
              const toastContainer = document.getElementById(ToastElementId.TOAST_CONTAINER);
              if (toastContainer) {
                toastContainer.style.zIndex = `${Z.Z_HELPHUB + 1}`;
              }

              const cursorElementWidth = cursor.ref.current.getBoundingClientRect().width;

              const { computeFinalPosition } = getRelativePosition(targetElement);
              const { placement } = await computeFinalPosition(cursorElement, {
                mainAxisOffset: -CURSOR_INSET + calcOffsetFromInput(_offset?.x ?? '0'),
                crossAxisOffset: -CURSOR_INSET - calcOffsetFromInput(_offset?.y ?? '0'),
                padding: cursorElementWidth + MIN_MARGIN,
              });

              // INFO: adjust the coordinates to account for the cursor's width and the placement
              const updatedCoordinates =
                placement === 'right-start'
                  ? cursor.position.container.coordinates.map((coord) => coord - MIN_MARGIN)
                  : [
                      cursor.position.container.coordinates[0] - cursorElementWidth - MIN_MARGIN - CURSOR_INSET,
                      cursor.position.container.coordinates[1] - MIN_MARGIN,
                    ];

              sendBack({
                type: 'POSITION_CONTAINER',
                placement,
                coordinates: updatedCoordinates as Coordinates2D,
              });
            },
          scrollToTargetElement:
            ({ targetElement }) =>
            (sendBack) => {
              if (!targetElement) {
                return sendBack('DESTROY');
              }

              const targetElRect = targetElement.getBoundingClientRect();
              const parentScrollable = findScrollParent(targetElement);
              if (isElementOffScreen(targetElRect, parentScrollable)) {
                const elementCenter = getElementCenter(targetElRect);
                const scrollContainer = parentScrollable || document.documentElement || window;

                const newScrollPosition = calculateNewScrollPosition(elementCenter, scrollContainer, {
                  isScrollableContainer: !!parentScrollable,
                });

                // HACK: there is a "scrollend" event we can listen for, but it's not yet supported
                // by Safari
                // This mimics the behavior by waiting until the element hasn't moved
                let priorCoordinates: Coordinates2D | [null, null] = [null, null];
                const interval = setInterval(() => {
                  const updatedCoordinates: Coordinates2D = [
                    targetElement.getBoundingClientRect().x,
                    targetElement.getBoundingClientRect().y,
                  ];

                  const entryIsInSamePosition =
                    priorCoordinates[0] === updatedCoordinates[0] && priorCoordinates[1] === updatedCoordinates[1];

                  if (entryIsInSamePosition) {
                    sendBack('FINISHED_SCROLLING');
                  } else {
                    priorCoordinates = updatedCoordinates;
                  }
                }, 100);

                scrollToElement(parentScrollable || window, {
                  behavior: 'smooth',
                  left: newScrollPosition.x,
                  top: newScrollPosition.y,
                });

                return () => {
                  clearInterval(interval);
                };
              }

              sendBack('FINISHED_SCROLLING');
            },
          moveCursorToTarget:
            ({ targetElement, cursor }) =>
            async (sendBack) => {
              const cursorElement = cursor.ref.current;

              if (!(targetElement && cursorElement && cursor.ref.current)) {
                return sendBack('DESTROY');
              }

              const cursorElementWidth = cursor.ref.current.getBoundingClientRect().width;
              const { computeFinalPosition } = getRelativePosition(targetElement);
              const {
                x: endX,
                y: endY,
                placement,
              } = await computeFinalPosition(cursorElement, {
                mainAxisOffset: -CURSOR_INSET + calcOffsetFromInput(_offset?.x ?? '0'),
                crossAxisOffset: -CURSOR_INSET - calcOffsetFromInput(_offset?.y ?? '0'),
                padding: cursorElementWidth + MIN_MARGIN,
              });
              sendBack({ type: 'POSITION_CONTAINER', coordinates: [endX, endY], placement });
            },
          trackTargetElement:
            ({ targetElement, offset: _offset, cursor }) =>
            (sendBack) => {
              const cursorElement = cursor.ref.current;
              if (!(targetElement && cursorElement)) {
                return sendBack('DESTROY');
              }

              // INFO: resetting the container z-index its original value
              const toastContainer = document.getElementById(ToastElementId.TOAST_CONTAINER);
              if (toastContainer) {
                toastContainer.style.zIndex = Z.Z_NUDGE.toString();
              }

              const mainAxisOffset = -CURSOR_INSET + calcOffsetFromInput(_offset?.x ?? '0');
              const crossAxisOffset = -CURSOR_INSET - calcOffsetFromInput(_offset?.y ?? '0');

              const updateContainerPosition = async () => {
                if (!cursor.ref.current) return;

                const cursorElementWidth = cursor.ref.current.getBoundingClientRect().width;
                const { initialPlacement, computeFinalPosition } = getRelativePosition(targetElement);
                const { x, y, placement, middlewareData } = await computeFinalPosition(cursorElement, {
                  mainAxisOffset,
                  crossAxisOffset,
                  padding: cursorElementWidth + MIN_MARGIN,
                  middleware: [
                    hide({
                      strategy: 'escaped',
                      padding: {
                        top: -calcOffsetFromInput(_offset?.y ?? '0'),
                        bottom: calcOffsetFromInput(_offset?.y ?? '0'),
                        ...(initialPlacement === 'right-start'
                          ? {
                              right: -calcOffsetFromInput(_offset?.x ?? '0'),
                              left: calcOffsetFromInput(_offset?.x ?? '0'),
                            }
                          : {
                              right: calcOffsetFromInput(_offset?.x ?? '0'),
                              left: -calcOffsetFromInput(_offset?.x ?? '0'),
                            }),
                      },
                    }),
                  ],
                });

                sendBack({ type: 'POSITION_CONTAINER', coordinates: [x, y], placement });

                if (middlewareData.hide?.escaped) {
                  sendBack('HIDE');
                } else {
                  sendBack('SHOW');
                }
              };
              const cleanupCursor = autoUpdate(targetElement, cursorElement, updateContainerPosition);

              const cleanup = () => {
                cleanupCursor();
                observer.disconnect();
                sendBack('DESTROY');
              };

              const observer = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                  if (mutation.type === 'childList') {
                    for (const removedNode of mutation.removedNodes) {
                      if (removedNode === targetElement || removedNode.contains(targetElement)) {
                        cleanup();
                      }
                    }
                  }
                }
              });

              observer.observe(document.documentElement, { childList: true, subtree: true });

              return () => {
                cleanup();
              };
            },
          handleTargetClick: () => () => {
            document.addEventListener('click', onTargetElementClick, true);

            return () => {
              document.removeEventListener('click', onTargetElementClick, true);
            };
          },
          animateCursor:
            ({ cursor }, _event) =>
            (sendBack) => {
              const MIN_INTERVAL = 1000;
              const MAX_INTERVAL = 2000;
              const UPPER_BOUNDS = 8;
              const LOWER_BOUNDS = -4;
              const MIN_DIFFERENCE = 4;

              const getRandomNumber = boundedRandomNumberGenerator(LOWER_BOUNDS, UPPER_BOUNDS, MIN_DIFFERENCE);

              let timeoutId: ReturnType<typeof setTimeout>;

              const updatePosition = () => {
                const coordinates: Coordinates2D = [
                  -1 * getRandomNumber.next().value,
                  cursor.position.container.placement === 'right-start'
                    ? -1 * getRandomNumber.next().value
                    : getRandomNumber.next().value,
                ];

                sendBack({
                  type: 'POSITION_ARROW',
                  coordinates,
                });

                const randomInterval = Math.floor(Math.random() * MAX_INTERVAL) + MIN_INTERVAL;
                timeoutId = setTimeout(updatePosition, randomInterval);
              };

              timeoutId = setTimeout(updatePosition, Math.floor(Math.random() * MAX_INTERVAL) + MIN_INTERVAL);

              return () => {
                clearTimeout(timeoutId);
              };
            },
        },
        actions: {
          removeCursor: handleDestroy,
          hideCopilot,
          showMask: ({ targetElement }, _event) => {
            if (!(targetElement && shouldShowMask)) {
              return;
            }

            showMask(targetElement);
          },
          hideMask,
        },
        delays: {
          crossFade: 400,
          translation: TRANSLATE_DURATION,
          popIn: POP_IN_DURATION,
          tracing: TRACING_DURATION,
        },
      }}
    >
      {children}
    </CursorMachineContext.Provider>
  );
};
