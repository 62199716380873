import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { CB_COLORS, Select, SubHeading, Tooltip, Dropdown } from '@commandbar/design-system/components/antd';
import {
  ArrowLeft,
  ArrowRight,
  DotsGrid,
  DotsVertical,
  InfoCircle,
  IntersectCircle,
  XClose,
} from '@commandbar/design-system/icons/react';

import type { IMessageType } from '@commandbar/internal/middleware/types';
import ChatMessage from '@commandbar/commandbar/products/copilot/components/ChatMessage';
import { ThemeProvider } from 'emotion-theming';
import { generateTheme } from '@commandbar/internal/client/theme';
import MessageInput from '@commandbar/commandbar/products/copilot/components/MessageInput';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdButton, CmdDivider, CmdSheet, cmdToast } from '@commandbar/design-system/cmd';
import { PreviewChatStateProvider } from '@commandbar/commandbar/products/copilot/store/useChatState';
import React, { ReactNode, useEffect } from 'react';
import { ChatDebugPanel } from 'editor/src/editor/copilot/ChatDebugPanel';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { OverlaySettingsOption } from 'editor/src/editor/components/styled';
import { BindThemeV2 } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import StyledAssistanceModal from '@commandbar/internal/client/themesV2/components/shared/StyledAssistanceModal';
import StyledAssistanceModalHeader from '@commandbar/internal/client/themesV2/components/shared/StyledAssistanceModalHeader';
import StyledCopilotHeader from '@commandbar/internal/client/themesV2/components/copilot/StyledCopilotHeader';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { LOCALIZED_MESSAGE_DEFAULTS } from '@commandbar/internal/middleware/localizedMessage';
import StyledHeader from '@commandbar/internal/client/themesV2/components/helphub/StyledHeader';
import { useStyles } from '@commandbar/commandbar/products/helphub/components/useStyles';

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${CB_COLORS.blue100};
`;

const DebugPanelContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-grow: 1;
  flex-direction: column;
  background: #f9f9f9;
  overflow-y: auto;
`;

const Messages = styled.div`
  overflow: auto;
  overscroll-behavior-y: contain;
  flex: 1;
  width: 100%;
  background: #fff;
`;

type PreviewTriggerProps = {
  selectedChat?: {
    id: string;
    messages: IMessageType[];
    editor_tags: string[];
  } | null;

  closeChat: () => void;
  copyChatLink: (chatId: string, isXrayOpen: boolean) => void;
  createAnswer: (chatId: string, question?: string) => void;
  nextChat: () => void;
  previousChat: () => void;
  setTags: (tags: string[]) => void;
  isXrayOpen: boolean;
};

export const Preview: React.FC<PreviewTriggerProps> = ({
  selectedChat,
  closeChat,
  createAnswer,
  copyChatLink,
  previousChat,
  nextChat,
  setTags,
  isXrayOpen,
}) => {
  const { organization, flags, localizedMessages } = useAppContext();
  const theme = generateTheme(organization.theme || {});
  const styles = useStyles(false, true, theme);
  const [debug, setDebug] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const messages = selectedChat?.messages || [];
  const { isDemoData } = useAnalyticsContext();

  useEffect(() => {
    setDebug(isXrayOpen);
  }, [isXrayOpen]);

  const copilotTitle = (localizedMessages['copilot.title'] || LOCALIZED_MESSAGE_DEFAULTS['copilot.title']).replace(
    '{{copilot-name}}',
    organization.copilot_name || 'Copilot',
  );

  const chatTitle = (
    localizedMessages['copilot.chat-label'] || LOCALIZED_MESSAGE_DEFAULTS['copilot.chat-label']
  ).replace('{{copilot-name}}', organization.copilot_name || 'Copilot');

  const handleAddAnswer = () => {
    if (isDemoData) {
      const question = messages[0].message_type === 'USER' && messages[0].value.question;
      createAnswer(selectedChat?.id as string, question || '');
    } else {
      createAnswer(selectedChat?.id as string);
    }
  };
  const { tags } = useAppContext();

  // Get the height of the dashboard banner to adjust the top position of the chat sheet
  const dashboardBanner = document.getElementById('dashboard-alerts-banner');
  const dashboardBannerHeight = dashboardBanner ? dashboardBanner.clientHeight + 8 : undefined;

  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const handleCopyLink = () => {
    copyChatLink(selectedChat?.id as string, debug);
    cmdToast.success('Link copied to clipboard');
    setIsDropdownOpen(false);
  };

  const ChatHubContainer = flags['release-themes-v2']
    ? ({ children }: { children: ReactNode }) => (
        <StyledAssistanceModal
          style={{
            position: 'unset',
            maxHeight: '100%',
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          modalRef={modalRef}
        >
          {children}
        </StyledAssistanceModal>
      )
    : ({ children }: { children: ReactNode }) => (
        <div style={{ ...styles.modal, overflow: 'hidden', ...(isMobileDevice() && { borderRadius: '0' }) }}>
          {children}
        </div>
      );

  const ChatHubHeader = flags['release-themes-v2']
    ? () => (
        <StyledAssistanceModalHeader
          themeV2={{ themeV2: organization.themeV2, mode: 'light_mode' }}
          title={copilotTitle}
          isMinimized={false}
          isMobileDevice={isMobileDevice()}
        />
      )
    : () => (
        <div style={styles.headerContainer}>
          <div style={styles.subheader}>
            <div
              style={{
                ...styles.headerIcon,
                display: 'flex',
                userSelect: 'none',
                cursor: 'move',
              }}
            >
              <DotsGrid
                css={[
                  css`
                    path {
                      stroke: ${styles.headerIcon.color};
                      opacity: 1;
                    }
                  `,
                ]}
              />
            </div>
            <div style={styles.headerText}>{copilotTitle}</div>
          </div>
        </div>
      );

  const ChatTitle = flags['release-themes-v2']
    ? () => (
        <StyledHeader style={{ marginBottom: '-4px' }}>
          <StyledCopilotHeader>{chatTitle}</StyledCopilotHeader>
        </StyledHeader>
      )
    : () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: '16px',
            color: '#51515C',
            background: CB_COLORS.white,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#000000',
              maxWidth: '50%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {chatTitle}
          </div>
        </div>
      );

  return (
    <CmdSheet open={!!selectedChat} modal={false}>
      <CmdSheet.Overlay style={{ display: 'none' }} />

      <CmdSheet.Content
        style={{
          width: debug ? '1120px' : '560px',
          maxWidth: '80vw',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          top: dashboardBannerHeight,
          right: '12px',
        }}
        onInteractOutside={(event) => event.preventDefault()}
      >
        <CmdSheet.Header
          style={{
            height: '50px',
            background: CB_COLORS.white,
            borderBottom: `1px solid ${CB_COLORS.neutral100}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px',
          }}
        >
          <CmdSheet.Close
            asChild
            onClick={() => {
              closeChat();
              setDebug(false);
            }}
          >
            <CmdButton variant="link" icon={<XClose />} style={{ padding: '8px' }} />
          </CmdSheet.Close>

          <div style={{ display: 'flex', gap: '8px' }}>
            <CmdButton
              style={{ background: 'none', boxShadow: 'none', border: 'none' }}
              onClick={() => previousChat()}
              tooltip="Previous chat"
            >
              <ArrowLeft />
            </CmdButton>

            <CmdButton
              style={{ background: 'none', boxShadow: 'none', border: 'none' }}
              onClick={() => nextChat()}
              tooltip="Next chat"
            >
              <ArrowRight />
            </CmdButton>

            {!isDemoData && (
              <CmdButton
                variant="link"
                style={{ ...(debug && { background: CB_COLORS.blue100, color: CB_COLORS.blue900 }) }}
                onClick={() => {
                  setDebug((debug) => !debug);
                }}
              >
                <IntersectCircle /> X-Ray
              </CmdButton>
            )}

            <Dropdown
              open={isDropdownOpen}
              placement="bottomLeft"
              onOpenChange={(open) => setIsDropdownOpen(open)}
              overlay={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '4px 0px',
                    boxShadow: `0 8px 16px ${CB_COLORS.neutral300}`,
                    borderRadius: '10px',
                    background: CB_COLORS.white,
                    width: '272px',
                  }}
                >
                  <OverlaySettingsOption onClick={handleAddAnswer}>Create Answer...</OverlaySettingsOption>
                  <OverlaySettingsOption onClick={handleCopyLink}>Copy Link</OverlaySettingsOption>
                  <CmdDivider />

                  {!isDemoData && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 12px' }}>
                      <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '4px' }}>Tags</div>
                        <Tooltip content="Add tags to help organize your chats">
                          <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                        </Tooltip>
                      </SubHeading>
                      <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Create or select a tag..."
                        onChange={(values) => {
                          setTags(values);
                        }}
                        value={selectedChat?.editor_tags || []}
                        options={tags.map((tag) => ({ value: tag.name }))}
                      />
                    </div>
                  )}
                </div>
              }
            >
              <CmdButton variant="link">
                <DotsVertical />
              </CmdButton>
            </Dropdown>
          </div>
        </CmdSheet.Header>

        <div
          style={{
            height: 'calc(100% - 50px)',
            display: 'flex',
            ...(debug && { display: 'grid', gridTemplateColumns: '50% 50%' }),
          }}
        >
          {debug && (
            <DebugPanelContainer>
              <ChatDebugPanel selectedChat={selectedChat} />
            </DebugPanelContainer>
          )}

          <BindThemeV2 themeV2={organization.themeV2} mode="light_mode">
            <ThemeProvider theme={{ theme }}>
              <PreviewChatStateProvider chatHistory={messages}>
                <PreviewContainer>
                  <ChatHubContainer>
                    <ChatHubHeader />
                    <ChatTitle />
                    <Messages>
                      {messages.map((message, index) => {
                        if (message.message_type === 'AI' || message.message_type === 'USER') {
                          return (
                            <ChatMessage
                              key={message.uuid}
                              preview={true}
                              isChatHistoryPreview={false}
                              message={message}
                              isFirstMessage={index === 0}
                              isLastMessage={false}
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              sendChat={() => {}}
                              isFinishedStreaming={true}
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              onFinishStreaming={() => {}}
                              previewOverrides={{
                                useThemeV2: flags['release-themes-v2'],
                                customAvatar: organization?.copilot_avatar || organization?.copilot_avatar_v2?.src,
                              }}
                            />
                          );
                        }

                        return null;
                      })}
                    </Messages>
                    <MessageInput
                      preview={true}
                      isChatHistoryPreview={false}
                      isLoading={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      resetChat={() => {}}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onSubmit={() => {}}
                      previewOverrides={{
                        useThemeV2: flags['release-themes-v2'],
                        messageInputPlaceholder:
                          localizedMessages['copilot.chat-input-placeholder'] ||
                          LOCALIZED_MESSAGE_DEFAULTS['copilot.chat-input-placeholder'],
                      }}
                    />
                  </ChatHubContainer>
                </PreviewContainer>
              </PreviewChatStateProvider>
            </ThemeProvider>
          </BindThemeV2>
        </div>
      </CmdSheet.Content>
    </CmdSheet>
  );
};
