// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Send03 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2.574 4.73c-.182-.547-.272-.82-.206-.99a.5.5 0 0 1 .331-.298c.174-.049.437.07.962.306L20.38 11.27c.514.23.771.347.85.507a.5.5 0 0 1 0 .444c-.079.16-.336.276-.85.508L3.667 20.25c-.527.237-.79.355-.965.307a.5.5 0 0 1-.331-.3c-.066-.169.026-.443.21-.99l2.334-6.976a1.06 1.06 0 0 0 .054-.188.5.5 0 0 0 0-.129c-.006-.048-.022-.095-.053-.188L2.574 4.729Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.5 12H5m-.085.291L2.58 19.267c-.183.548-.275.822-.209.99a.5.5 0 0 0 .331.3c.175.05.438-.07.965-.306l16.712-7.52c.514-.232.771-.348.85-.508a.5.5 0 0 0 0-.444c-.079-.16-.336-.276-.85-.508L3.66 3.748c-.525-.237-.788-.355-.962-.306a.5.5 0 0 0-.331.299c-.066.168.024.442.206.988l2.342 7.057c.031.094.047.14.053.188a.5.5 0 0 1 0 .129 1.06 1.06 0 0 1-.054.188Z"
    />
  </svg>
);
export default Send03;
