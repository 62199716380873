/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { IThemeV2Type } from '@commandbar/internal/middleware/types';
import useComponentOverrides from '../../helpers/useComponentOverrides';

const ChecklistProgressBar = styled.div`
  display: flex;
  padding: var(--layout-padding);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--layout-gap);
  flex-shrink: 0;
`;

const progressBarStyles: CSSObject = {
  flexGrow: 1,
  height: 'var(--form-control-padding-x)',
  borderRadius: 'var(--layout-radius-circle)',
  background: 'var(--background-secondary)',
};

const ProgressBarFill = styled.div`
  height: var(--form-control-padding-x);
  border-radius: var(--layout-radius-circle);
  background: var(--base-accent);
`;

const ProgressLabel = styled.div`
  color: var(--content-secondary);

  /* label/base/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);
`;

const slug = 'checklistProgressBar';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  numCompleted: number;
  numTotal: number;
};

const StyledChecklistProgressBar = ({ themeV2, numCompleted, numTotal }: Props) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  const MIN_PERCENTAGBE = 0.04 * 100;
  const percentCompleted = Math.max(MIN_PERCENTAGBE, Math.round((numCompleted * 100) / numTotal));

  return (
    <ChecklistProgressBar>
      <ProgressLabel>{`${numCompleted}/${numTotal}`}</ProgressLabel>
      <div css={[overrides, css(progressBarStyles)]}>
        <ProgressBarFill style={{ width: `${percentCompleted}%` }} />
      </div>
    </ChecklistProgressBar>
  );
};

StyledChecklistProgressBar.styles = progressBarStyles;
StyledChecklistProgressBar.slug = slug;
StyledChecklistProgressBar.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledProgressBarMock themeV2={themeV2} mode={mode} />
);

const StyledProgressBarMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2, mode });
  return (
    <div css={[overrides, css(progressBarStyles)]} style={{ width: '350px' }}>
      <ProgressBarFill style={{ width: '25%' }} />
    </div>
  );
};

export default StyledChecklistProgressBar;
