import { useEffect, useState } from 'react';

import { Page, Row } from '@commandbar/design-system/components/antd';
import {
  BookClosed,
  BookmarkCheck,
  Play,
  File05,
  Plus,
  Bookmark,
  Highlighter,
} from '@commandbar/design-system/icons/react';
import { IntegrationsPageHeader } from '../styles';
import Sources from './sources/Sources';
import { CmdButton, CmdTag, CmdButtonTabs } from '@commandbar/design-system/cmd';
import Files from './files/Files';
import Answers from './answers/Answers';
import Videos from './videos/Videos';
import { capitalize } from 'lodash';
import { useHistory, useParams } from 'react-router';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import Keywords from './keywords/Keywords';

type ContentType = 'sources' | 'files' | 'answers' | 'videos' | 'keywords';

const ContentPage = () => {
  const [showAddContent, setShowAddContent] = useState(false);
  const params = useParams<{ page: string }>();
  const history = useHistory();
  const { user } = useAuth();
  const [contentType, setContentType] = useState<ContentType>('sources');

  useEffect(() => {
    if (['sources', 'files', 'answers', 'videos', 'keywords'].includes(params.page)) {
      setContentType(params.page as ContentType);
    } else {
      setContentType('sources');
    }
  }, [params.page]);

  const getBodyContent = () => {
    switch (contentType) {
      case 'files':
        return <Files showAddContent={showAddContent} setShowAddContent={setShowAddContent} />;
      case 'sources':
        return <Sources showAddContent={showAddContent} setShowAddContent={setShowAddContent} />;
      case 'answers':
        return <Answers showAddContent={showAddContent} setShowAddContent={setShowAddContent} />;
      case 'videos':
        return <Videos showAddContent={showAddContent} setShowAddContent={setShowAddContent} />;
      case 'keywords':
        return <Keywords showAddContent={showAddContent} setShowAddContent={setShowAddContent} />;
    }
  };

  const disabledReasonForAddButton =
    (contentType === 'sources' && !hasRequiredRole(user, 'editor')) || !hasRequiredRole(user, 'contributor')
      ? `You do not have the required permission to add ${contentType}s.`
      : null;

  return (
    <Page
      whiteHeader={true}
      whiteBg={false}
      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      title="Content"
      description={
        <span style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%' }}>
          Manage all your content in one place.
          <CmdTag
            variant="info"
            prefixElement={<Bookmark />}
            onClick={() => window.CommandBar.openHelpHub({ articleId: 612609 })}
          >
            LEARN MORE
          </CmdTag>
        </span>
      }
      headerRowChildren={
        <IntegrationsPageHeader style={{ justifyContent: 'space-between', width: '100%' }}>
          <Row justify="space-between" align="middle">
            <CmdButtonTabs
              variant="group"
              activeKey={contentType}
              onChange={(t) => {
                setContentType(t as ContentType);
                history.push(`/content/${t}`);
              }}
              tabs={[
                {
                  label: (
                    <>
                      <BookClosed /> Sources
                    </>
                  ),
                  key: 'sources',
                },
                {
                  label: (
                    <>
                      <BookmarkCheck /> Answers
                    </>
                  ),
                  key: 'answers',
                },
                {
                  label: (
                    <>
                      <Highlighter /> Keywords
                    </>
                  ),
                  key: 'keywords',
                },
                {
                  label: (
                    <>
                      <Play /> Videos
                    </>
                  ),
                  key: 'videos',
                },
                {
                  label: (
                    <>
                      <File05 /> Files
                    </>
                  ),
                  key: 'files',
                },
              ]}
            />
          </Row>

          {!disabledReasonForAddButton && (
            <CmdButton variant="primary" onClick={() => setShowAddContent(true)}>
              <Plus />
              Add {capitalize(contentType.slice(0, -1))}
            </CmdButton>
          )}
        </IntegrationsPageHeader>
      }
    >
      {getBodyContent()}
    </Page>
  );
};

export default ContentPage;
