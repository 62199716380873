/** @jsx jsx */
import { ITheme } from '@commandbar/internal/client/theme';
import { useTheme } from 'emotion-theming';
import { ChangeEventHandler } from 'react';
import { jsx } from '@emotion/core';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledTextArea from '@commandbar/internal/client/themesV2/components/shared/StyledTextArea';
import StyledFormFieldBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledFormFieldBlock';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useSurveyResponse } from '../SurveyResponseProvider';

const TextInputBlock = ({ placeholder }: { placeholder: string }) => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const { surveyResponse, setSurveyResponse } = useSurveyResponse();

  const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value;
    setSurveyResponse({ type: 'string', value });
  };

  return _.flags?.['release-themes-v2'] ? (
    <StyledFormFieldBlock>
      <StyledTextArea
        themeV2={themeV2}
        value={(surveyResponse?.value as string) || ''}
        onChange={handleTextChange}
        aria-label={placeholder}
        placeholder={placeholder}
        onClick={(e) => e.stopPropagation()}
        rows={5}
      />
    </StyledFormFieldBlock>
  ) : (
    <textarea
      value={(surveyResponse?.value as string) || ''}
      onChange={handleTextChange}
      aria-label={placeholder}
      placeholder={placeholder}
      onClick={(e) => e.stopPropagation()}
      rows={5}
      style={{
        width: '100%',
        padding: theme.surveyTextInput.padding,
        margin: theme.surveyTextInput.margin,
        color: theme.surveyTextInput.color,
        fontFamily: theme.surveyTextInput.fontFamily,
        fontSize: isMobileDevice() ? '16px' : theme.surveyTextInput.fontSize,
        border: theme.surveyTextInput.border,
        borderRadius: theme.surveyTextInput.borderRadius,
        background: theme.surveyTextInput.background,
        boxShadow: theme.surveyTextInput.boxShadow,
        lineHeight: '16px',
        fontWeight: 400,
        resize: 'none',
      }}
      css={{
        '::placeholder': {
          color: '#A2A2A9',
        },
      }}
    />
  );
};

export default TextInputBlock;
