import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
  display: flex;
  padding: var(--layout-padding);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--layout-gap);
  align-self: stretch;
`;

const StyledInputContainer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <Container ref={ref} {...props}>
        {children}
      </Container>
    );
  },
);

export default StyledInputContainer;
