import styled from '@emotion/styled';
import React from 'react';

import { Image01 } from '@commandbar/design-system/icons/react';

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px var(--layout-padding) var(--layout-padding) var(--layout-padding);
`;

const TopBlockImageContainer = styled(ImageContainer)`
  padding: 0;
  border-radius: var(--layout-radius-card) var(--layout-radius-card) 0 0;
  overflow: hidden;
`;

const FullHeightImageContainer = styled(ImageContainer)`
  height: 100%;
  padding: 0;
  border-radius: 0 var(--layout-radius-card) var(--layout-radius-card) 0;
  overflow: hidden;
`;

const Image = styled.img`
  all: unset;

  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #f7f4ff 0%, #d5caff 100%);
`;

const TopBlockPlaceholder = styled(Placeholder)`
  border-radius: var(--layout-radius-card) var(--layout-radius-card) 0 0;
`;

const FullHeightPlaceholder = styled(Placeholder)`
  overflow: hidden;
  border-radius: 0 var(--layout-radius-card) var(--layout-radius-card) 0;
`;

const components = {
  'top-block': { Container: TopBlockImageContainer, PlaceholderContainer: TopBlockPlaceholder },
  'full-height': { Container: FullHeightImageContainer, PlaceholderContainer: FullHeightPlaceholder },
  default: { Container: ImageContainer, PlaceholderContainer: Placeholder },
};

const StyledNudgeImage = ({
  type,
  src,
  file_name,
  variant = 'default',
}: {
  type: 'pin' | 'popover' | 'modal' | 'tooltip' | 'banner';
  src: string;
  file_name: string;
  variant?: 'default' | 'top-block' | 'full-height';
}) => {
  const { Container, PlaceholderContainer } = components[variant];

  const getPlaceholderContainerHeight = () => {
    if (variant === 'full-height') {
      return '100%';
    }

    if (type === 'modal') {
      return '220px';
    }

    return '168px';
  };

  return src ? (
    <Container>
      <Image data-testid="commandbar-nudge-image" src={src} alt={file_name} />
    </Container>
  ) : (
    <Container>
      <PlaceholderContainer
        data-testid="commandbar-nudge-image-placeholder"
        style={{ height: getPlaceholderContainerHeight() }}
      >
        <Image01 width="24px" height="24px" />
      </PlaceholderContainer>
    </Container>
  );
};

export default StyledNudgeImage;
