import { useReportEvent } from '../../../hooks/useEventReporting';
import { SuggestionBody, SuggestionCard, SuggestionContent, SuggestionLink, SuggestionTitle } from '../../styles';

const SuggestionIntegration = () => {
  const { reportEvent } = useReportEvent();

  return (
    <SuggestionCard>
      <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.75001 18V12.75M8.75001 12.75C9.25598 12.7504 9.75996 12.6869 10.25 12.561M8.75001 12.75C8.24405 12.7504 7.74007 12.6869 7.25001 12.561M11 20.039C9.5133 20.3213 7.98673 20.3213 6.50001 20.039M10.25 22.422C9.25274 22.5264 8.24729 22.5264 7.25001 22.422M11 18V17.808C11 16.825 11.658 15.985 12.508 15.492C13.936 14.665 15.0514 13.3902 15.6814 11.865C16.3114 10.3399 16.4208 8.64948 15.9927 7.05582C15.5645 5.46217 14.6228 4.05419 13.3133 3.05006C12.0038 2.04594 10.3997 1.50171 8.74951 1.50171C7.09935 1.50171 5.49524 2.04594 4.18575 3.05006C2.87626 4.05419 1.93448 5.46217 1.50634 7.05582C1.07821 8.64948 1.18761 10.3399 1.8176 11.865C2.4476 13.3902 3.56301 14.665 4.99101 15.492C5.84101 15.985 6.50001 16.825 6.50001 17.808V18"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <SuggestionBody>
        <SuggestionTitle>Suggest an integration</SuggestionTitle>
        <SuggestionContent>
          Is there an integration you’d like to see us add?{' '}
          <SuggestionLink
            onClick={() => {
              // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomshow
              window.Intercom('show');
              reportEvent('suggest integration clicked', {
                segment: true,
                highlight: true,
                slack: true,
              });
            }}
          >
            Let us know &#8594;
          </SuggestionLink>
        </SuggestionContent>
      </SuggestionBody>
    </SuggestionCard>
  );
};

export default SuggestionIntegration;
