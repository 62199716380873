import React from 'react';
import { initValtioGlobalStore, initGlobalSubs as initGlobalSubscriptions } from './global-store';
import { devtools } from 'valtio/utils';
import { ref } from 'valtio';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { CBStore } from './global-store';

let globalInstanceId = 0;

export const GlobalStoreContext = React.createContext(undefined as unknown as CBStore);

export const GlobalStoreProvider = (props: { children: React.ReactNode }) => {
  const store = React.useRef(initValtioGlobalStore());
  React.useEffect(() => initGlobalSubscriptions(store.current), []);
  store.current.spotlight.refContainer = ref(React.useRef(null));
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') return;
    if (!LocalStorage.get('logChanges', false)) return;
    return devtools(store.current, `CommandBar:${globalInstanceId++}`);
  }, []);
  if (process.env.NODE_ENV !== 'production') {
    Object.assign(window, { CB: store.current });
  }
  return <GlobalStoreContext.Provider value={store.current}>{props.children}</GlobalStoreContext.Provider>;
};
