import { ITheme } from '@commandbar/internal/client/theme';

export const DEFAULT_PLACEHOLDER = 'What do you want to do?';

export const placeholderStyles = (theme: ITheme) => ({
  color: theme.placeholder.color,
  fontFamily: theme.placeholder.fontFamily,
  fontSize: theme.placeholder.fontSize,
  fontWeight: theme.placeholder.fontWeight,
});
