/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import Hotkey from '@commandbar/internal/client/Hotkey';
import defaultBrowserShortcuts, { IDefaultShortcut } from '@commandbar/internal/util/defaultShortcutList';
import { getOperatingSystem } from '@commandbar/internal/util/operatingSystem';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { FormRow, Tooltip } from '@commandbar/design-system/components/antd';
import { KeyboardShortcutInput } from './components/KeyboardShortcutInput';
import { useAppContext } from 'editor/src/AppStateContext';
import { AlertTriangle } from '@commandbar/design-system/icons/react';

/*******************************************************************************/

/* Constants and helpers */

// Cache mac and windows shortcuts to speed up searches
const MAC_DEFAULT_SHORTCUTS = defaultBrowserShortcuts.filter((s) => s.os.toLowerCase() === 'mac');
const WIN_DEFAULT_SHORTCUTS = defaultBrowserShortcuts.filter((s) => s.os.toLowerCase() === 'win');
// source: https://stackoverflow.com/questions/7295508/javascript-capture-browser-shortcuts-ctrlt-n-w/7296303#7296303
const MAC_RESERVED_SHORTCUTS_REGEXP = new RegExp('(command)\\+(shift\\+)?(t$|n$|w$)|command\\+q');
const WIN_RESERVED_SHORTCUTS_REGEXP = new RegExp('(ctrl)\\+(shift\\+)?(t$|n$|w|tab$)|(ctrl|alt)\\+f4');

/*******************************************************************************/

interface IKeyboardShortcutEditorProps {
  hotkey_mac: string;
  hotkey_win: string;
  onShortcutsChangeMac: (values: string) => void;
  onShortcutsChangeWin: (values: string) => void;

  currentCommand: IEditorCommandType;
}

const BROWSER_ICONS: Record<string, React.ReactNode> = {
  chrome: (
    <img alt="chrome icon" src="https://staticassets.commandbar.com/site-assets/browsers/chrome-logo.svg" width={16} />
  ),
  firefox: (
    <img
      alt="firefox icon"
      src="https://staticassets.commandbar.com/site-assets/browsers/firefox-logo.svg"
      width={16}
    />
  ),
  edge: <img alt="edge icon" src="https://staticassets.commandbar.com/site-assets/browsers/edge-logo.svg" width={16} />,
  safari: (
    <img alt="safari icon" src="https://staticassets.commandbar.com/site-assets/browsers/safari-logo.svg" width={16} />
  ),
  opera: (
    <img alt="opera icon" src="https://staticassets.commandbar.com/site-assets/browsers/opera-logo.svg" width={16} />
  ),
};

const KeyboardShortcutEditor = (props: IKeyboardShortcutEditorProps) => {
  const [macError, setMacError] = React.useState<string | null>(null);
  const [winError, setWinError] = React.useState<string | null>(null);

  const os = getOperatingSystem();

  const [macShortcut, setMacShortcut] = React.useState<string>(props.hotkey_mac);
  const [winShortcut, setWinShortcut] = React.useState<string>(props.hotkey_win);

  const [hasEditedWinValues, setHasEditedWinValues] = React.useState<boolean>(props.hotkey_win.length !== 0);
  const [hasEditedMacValues, setHasEditedMacValues] = React.useState<boolean>(props.hotkey_mac.length !== 0);

  React.useEffect(() => {
    if (!isEqual(macShortcut, props.hotkey_mac)) {
      props.onShortcutsChangeMac(macShortcut);
    }
  }, [macShortcut]);

  React.useEffect(() => {
    if (!isEqual(winShortcut, props.hotkey_win)) {
      props.onShortcutsChangeWin(winShortcut);
    }
  }, [winShortcut]);

  const onChangeMac = (value: string) => {
    setHasEditedMacValues(!!value);

    setMacShortcut(value);

    const [validated, reason] = Hotkey.validateEditorShortcutValues(value);
    if (!validated) {
      setMacError(reason);
    } else {
      setMacError(null);
    }

    if (!hasEditedWinValues) {
      const winValue = Hotkey.mapHotkeysMacToWin(value);
      setWinShortcut(winValue);

      const [validated, reason] = Hotkey.validateEditorShortcutValues(winValue);
      if (!validated) {
        setWinError(reason);
      } else {
        setWinError(null);
      }
    }
  };

  const onChangeWin = (value: string) => {
    setHasEditedWinValues(!!value);

    setWinShortcut(value);

    const [validated, reason] = Hotkey.validateEditorShortcutValues(value);

    if (!validated) {
      setWinError(reason);
    } else {
      setWinError(null);
    }

    if (!hasEditedMacValues) {
      const macValue = Hotkey.mapHotkeysWinToMac(value);
      setMacShortcut(macValue);

      const [validated, reason] = Hotkey.validateEditorShortcutValues(macValue);
      if (!validated) {
        setMacError(reason);
      } else {
        setMacError(null);
      }
    }
  };

  const infoText = (isCurrentPlatform: boolean) => {
    return isCurrentPlatform
      ? 'Click into the input field to record a shortcut or double click to type it manually.'
      : `Type in a shortcut string. Alternatively, use the input corresponding to your platform to record a shortcut. 
        This field will then be filled automatically.`;
  };

  return (
    <div>
      <FormRow
        title="MacOS Shortcut:"
        keepTooltipSpace
        input={
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <KeyboardShortcutInput
                value={macShortcut}
                onChange={onChangeMac}
                platform="mac"
                enableRecording={['mac'].includes(os)}
              />
              <ProblematicShortcuts shortcuts={macShortcut} os="mac" />
              <Collisions shortcut={macShortcut} currentCommand={props.currentCommand} os="mac" />
            </div>
            {macError !== null ? <div style={{ color: 'red' }}>{macError}</div> : <div />}
          </div>
        }
        info={infoText(['mac'].includes(os))}
      />
      <FormRow
        title="Windows/Linux Shortcut:"
        keepTooltipSpace
        input={
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <KeyboardShortcutInput
                value={winShortcut}
                onChange={onChangeWin}
                platform="win"
                enableRecording={['windows', 'linux'].includes(os)}
              />
              <ProblematicShortcuts shortcuts={winShortcut} os="win" />
              <Collisions shortcut={winShortcut} currentCommand={props.currentCommand} os="win" />
            </div>
            {winError !== null ? <div style={{ color: 'red' }}>{winError}</div> : <div />}
          </div>
        }
        info={infoText(['windows', 'linux'].includes(os))}
      />
    </div>
  );
};

interface IProblematicShortcuts {
  shortcuts: string;
  os: string;
}

// eslint-disable-next-line react/display-name
const ProblematicShortcuts = React.memo((props: IProblematicShortcuts) => {
  // Check if there is any conflict with default browser shortcuts
  // And notify user if any
  const { shortcuts, os } = props;
  const conflicts: IDefaultShortcut[] = [];

  const shortcutsString = shortcuts;

  const isMac = os.toLowerCase() === 'mac';

  const defaultShortcuts = isMac ? MAC_DEFAULT_SHORTCUTS : WIN_DEFAULT_SHORTCUTS;

  let includesBrowserReserverdShortcut = false;

  defaultShortcuts.forEach((defaultShortcut: IDefaultShortcut) => {
    if (os.toLowerCase() === defaultShortcut.os.toLowerCase()) {
      const platformShortcutsString = Hotkey.normalize(
        Hotkey.toPlatformSpecificString(shortcutsString, isMac ? 'mac' : 'win'),
      );

      if (Hotkey.normalize(defaultShortcut.shortcut) === platformShortcutsString) {
        conflicts.push(defaultShortcut);

        if ((isMac ? MAC_RESERVED_SHORTCUTS_REGEXP : WIN_RESERVED_SHORTCUTS_REGEXP).test(platformShortcutsString)) {
          includesBrowserReserverdShortcut = true;
        }
      }
    }
  });

  // Sometimes there might be multiple defaults defined for the same browser that we want to filter
  //   e.g., Safari: Find text on page; Safari: Find selected text on page
  const uniqConflicts = conflicts.filter(
    (conflict, index, self) =>
      index ===
      self.findIndex((_conflict) => _conflict.browser === conflict.browser && _conflict.std_desc === conflict.std_desc),
  );

  if (!conflicts.length) return null;

  const tooltipContent = (
    <div>
      <p>
        {includesBrowserReserverdShortcut
          ? 'This shortcut is reserved by browsers. It cannot be processed by CommandBar in most environments.'
          : 'This conflicts with default browser shortcuts:'}
      </p>
      <ul>
        {uniqConflicts.map((conflict: IDefaultShortcut) => (
          <li key={conflict.browser + conflict.shortcut}>
            {BROWSER_ICONS[conflict.browser.toLowerCase()] || conflict.browser}&nbsp;&nbsp; {conflict.std_desc}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Tooltip content={tooltipContent} interactive>
      <AlertTriangle
        style={{
          color: includesBrowserReserverdShortcut ? 'red' : 'rgb(216, 150, 20)',
          paddingLeft: '10px',
        }}
      />
    </Tooltip>
  );
});

interface ICollisionProps {
  currentCommand: IEditorCommandType;
  shortcut: string;
  os: 'mac' | 'win';
}

// eslint-disable-next-line react/display-name
const Collisions = React.memo((props: ICollisionProps) => {
  const { commands } = useAppContext();
  const toCompare = Hotkey.toModString(props.shortcut, props.os);

  const collisions =
    toCompare.length === 0
      ? []
      : commands.filter((c) => {
          if (c.id === props.currentCommand.id) {
            return false;
          }

          const fromCompare = props.os === 'mac' ? c.hotkey_mac : c.hotkey_win;
          return Hotkey.checkForConflicts(toCompare, fromCompare);
        });

  if (collisions.length) {
    const collisionText = (
      <div>
        <div>
          This shortcut collides with {collisions.length} other command
          {collisions.length === 1 ? '' : 's'}. Ensure that no two commands with the same sequence of keys are available
          at the same time.
        </div>
        <ul>
          {collisions.map((c) => {
            const hotkey = props.os === 'mac' ? c.hotkey_mac : c.hotkey_win;

            const formattedHotkey = Hotkey.toArray(hotkey).map((k: string, i, arr) => {
              return Hotkey.translateToPlatformSymbol(
                k,
                props.os === 'mac' ? 'mac' : 'win',
                i === arr.length - 1 || arr[i + 1] === 'then',
              );
            });

            return (
              <li key={c.text}>
                {c.text} <span style={{ fontWeight: 600, marginLeft: '4px' }}>({formattedHotkey})</span>
              </li>
            );
          })}
        </ul>
      </div>
    );

    return (
      <Tooltip content={collisionText}>
        <AlertTriangle color="red" style={{ paddingLeft: '10px' }} />
      </Tooltip>
    );
  }
  return <div />;
});

export default React.memo(KeyboardShortcutEditor);
