import useAnalyticOverview from './useAnalyticOverview';

import LinkStatCard from '../../components/LinkStatCard';
import Layout from '../../components/Layout';
import { LiveEventsTable } from './LiveEventsTable';
import { useAppContext } from 'editor/src/AppStateContext';
import {
  BookOpen01,
  CheckSquareBroken,
  FaceIdSquare,
  MessageSquare01,
  NotificationBox,
  SearchMd,
} from '@commandbar/design-system/icons/react';

const AnalyticsOverviewDashboard = () => {
  const { flags } = useAppContext();
  const { data, isLoading } = useAnalyticOverview();

  return (
    <Layout title="Analytics" disableDemoData={isLoading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '16px',
        }}
      >
        <LinkStatCard
          title="Nudge engagements"
          icon={<NotificationBox height={24} width={24} />}
          {...data?.nudge_engagements}
          isLoading={isLoading}
        />
        <LinkStatCard
          title="Checklist engagements"
          icon={<CheckSquareBroken height={24} width={24} />}
          url="checklists"
          {...data?.checklist_engagements}
          isLoading={isLoading}
        />
        <LinkStatCard
          title="Survey response"
          icon={<MessageSquare01 height={24} width={24} />}
          url="surveys"
          {...data?.survey_responses}
          isLoading={isLoading}
        />
        <LinkStatCard
          title="HelpHub engagements"
          icon={<BookOpen01 height={24} width={24} />}
          url="helphub"
          {...data?.helphub_engagements}
          isLoading={isLoading}
        />
        <LinkStatCard
          title="Copilot opens"
          icon={<FaceIdSquare height={24} width={24} />}
          url="copilot"
          {...data?.copilot_opens}
          isLoading={isLoading}
        />
        <LinkStatCard
          title="Spotlight opens"
          icon={<SearchMd height={24} width={24} />}
          url="spotlight"
          {...data?.spotlight_opens}
          isLoading={isLoading}
        />
      </div>

      {flags?.['hackweek-live-events-table'] && (
        <div style={{ marginTop: '32px' }}>
          <LiveEventsTable />
        </div>
      )}
    </Layout>
  );
};

export default AnalyticsOverviewDashboard;
