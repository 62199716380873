import React, { createContext, useState, useContext } from 'react';

import { ICommandCategoryType } from '@commandbar/internal/middleware/types';

type CategoryID = number | string;

type CategoryRecord = Record<CategoryID, ICategoryObject | undefined>;

interface ICategoryObject {
  isCollapsed?: boolean;
  page?: number;
  size?: number;
}

interface ISessionSettingsContext {
  categorySettings: CategoryRecord;
  updateCategorySettings: (category: CategoryRecord) => void;
}

const SessionSettingsContext = createContext<ISessionSettingsContext | null>(null);

export const useSessionSettingsContext = () => useContext(SessionSettingsContext) as unknown as ISessionSettingsContext;

const categoryById = (categories: ICommandCategoryType[]): { [key: number]: Omit<ICommandCategoryType, 'id'> } =>
  categories.reduce(
    (acc, { id }) => ({
      ...acc,
      [id]: {
        isCollapsed: false,
        page: 1,
      },
    }),
    {},
  );

const initializeCategoriesState = (categories: ICommandCategoryType[]): CategoryRecord => ({
  uncategorized: {
    isCollapsed: false,
    page: 1,
  },
  // INFO: Initializing state as an object with categoryID as key allows us to
  // lookup by ID rather than finding or filtering on an array
  ...categoryById(categories),
});

const SessionSettingsProvider = ({ categories, children }: { categories: ICommandCategoryType[]; children: any }) => {
  const [categorySettings, setCategorySettingsState] = useState<CategoryRecord>(initializeCategoriesState(categories));

  const updateCategorySettings = (category: CategoryRecord) => {
    setCategorySettingsState((prevState) => ({
      ...prevState,
      ...category,
    }));
  };

  React.useEffect(() => {
    // INFO: initialize categorySettings whenever a new category is created
    categories.forEach(({ id }) => {
      if (!(id in categorySettings)) {
        setCategorySettingsState({ [id]: { isCollapsed: false, page: 1 }, ...categorySettings });
      }
    });
  }, [categories]);

  return (
    <SessionSettingsContext.Provider value={{ categorySettings, updateCategorySettings }}>
      {children}
    </SessionSettingsContext.Provider>
  );
};

export default SessionSettingsProvider;
