import React from 'react';
import { Code } from '../../components/blocks';
import * as S from './styles';
import { InstallPackageCode } from './InstallPackageCode';
import useOS from '@commandbar/internal/util/useOS';
import { ChromeExtensionLink } from './ChromeExtensionLink';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

const FrameworkImage = (props: { src: string; alt: string; width: string }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={props.src} alt={props.alt} width={props.width} />
    </div>
  );
};

const snippetText = `var o="<org_id>",n="https://api.commandbar.com",a=void 0,t=window;function r(o,n){void 0===n&&(n=!1),"complete"!==document.readyState&&window.addEventListener("load",r.bind(null,o,n),{capture:!1,once:!0});var a=document.createElement("script");a.type="text/javascript",a.async=n,a.src=o,document.head.appendChild(a)}function e(){var e;if(void 0===t.CommandBar){delete t.__CommandBarBootstrap__;var c=Symbol.for("CommandBar::configuration"),d=Symbol.for("CommandBar::disposed"),i=Symbol.for("CommandBar::isProxy"),m=Symbol.for("CommandBar::queue"),u=Symbol.for("CommandBar::unwrap"),s=Symbol.for("CommandBar::eventSubscriptions"),l=[],p=localStorage.getItem("commandbar.lc");p&&p.includes("local")&&(n="http://localhost:8000",a=void 0);var f=Object.assign(((e={})[c]={uuid:o,api:n,cdn:a},e[d]=!1,e[i]=!0,e[m]=new Array,e[u]=function(){return f},e[s]=void 0,e),t.CommandBar),v=["addCommand","boot","addEventSubscriber","addRecordAction","setFormFactor"],b=f;Object.assign(f,{shareCallbacks:function(){return{}},shareContext:function(){return{}}}),t.CommandBar=new Proxy(f,{get:function(o,n){return n in b?f[n]:"then"!==n?v.includes(n)?function(){var o=Array.prototype.slice.call(arguments);return new Promise((function(a,t){o.unshift(n,a,t),f[m].push(o)}))}:function(){var o=Array.prototype.slice.call(arguments);o.unshift(n),f[m].push(o)}:void 0}}),null!==p&&l.push("lc=".concat(p)),l.push("version=2"),a&&l.push("cdn=".concat(encodeURIComponent(a))),r("".concat(n,"/latest/").concat(o,"?").concat(l.join("&")),!0)}}e();`;

export const snippetBase = (organizationUid: IOrganizationType['id']) => {
  return snippetText.replace('<org_id>', !!organizationUid ? organizationUid + '' : 'MY_ORG_ID');
};

const vanillaJSSnippet = (organizationUid: string) =>
  `<script>
  var o="${organizationUid}",n="https://api.commandbar.com",a=void 0,t=window;function r(o,n){void 0===n&&(n=!1),"complete"!==document.readyState&&window.addEventListener("load",r.bind(null,o,n),{capture:!1,once:!0});var a=document.createElement("script");a.type="text/javascript",a.async=n,a.src=o,document.head.appendChild(a)}function e(){var e;if(void 0===t.CommandBar){delete t.__CommandBarBootstrap__;var c=Symbol.for("CommandBar::configuration"),d=Symbol.for("CommandBar::disposed"),i=Symbol.for("CommandBar::isProxy"),m=Symbol.for("CommandBar::queue"),u=Symbol.for("CommandBar::unwrap"),s=Symbol.for("CommandBar::eventSubscriptions"),l=[],p=localStorage.getItem("commandbar.lc");p&&p.includes("local")&&(n="http://localhost:8000",a=void 0);var f=Object.assign(((e={})[c]={uuid:o,api:n,cdn:a},e[d]=!1,e[i]=!0,e[m]=new Array,e[u]=function(){return f},e[s]=void 0,e),t.CommandBar),v=["addCommand","boot","addEventSubscriber","addRecordAction","setFormFactor"],b=f;Object.assign(f,{shareCallbacks:function(){return{}},shareContext:function(){return{}}}),t.CommandBar=new Proxy(f,{get:function(o,n){return n in b?f[n]:"then"!==n?v.includes(n)?function(){var o=Array.prototype.slice.call(arguments);return new Promise((function(a,t){o.unshift(n,a,t),f[m].push(o)}))}:function(){var o=Array.prototype.slice.call(arguments);o.unshift(n),f[m].push(o)}:void 0}}),null!==p&&l.push("lc=".concat(p)),l.push("version=2"),a&&l.push("cdn=".concat(encodeURIComponent(a))),r("".concat(n,"/latest/").concat(o,"?").concat(l.join("&")),!0)}}e();
  const loggedInUserId = '12345'; // example
  window.CommandBar.boot(loggedInUserId);
</script>`;

// separating out the code snippets for each framework here
const noCodeSnippet = (organizationUid: string) => {
  return `<script>
  ${snippetBase(organizationUid)}
  window.CommandBar.boot();
</script>`;
};

const reactSnippet = (organizationUid: string) => {
  return `import { init } from 'commandbar';
init('${organizationUid}');

const App = ({ children }) => {
  useEffect(() => {
    const loggedInUserId = '12345'; // example
    window.CommandBar.boot(loggedInUserId).then(() => {
      // ...
    });

    return () => {
      window.CommandBar.shutdown();
    }
  }, []);
})`;
};

const nextSnippet = (organizationUid: string) => {
  return `if (typeof window !== "undefined") {
    init('${organizationUid}');
  }
  
  const App = ({ children }) => {
    useEffect(() => {
      const loggedInUserId = '12345'; // example
      window.CommandBar.boot(loggedInUserId).then(() => {
        // ...
      });
  
      return () => {
        window.CommandBar.shutdown();
      }
    }, []);
  })`;
};

const angularSnippet = (organizationUid: string) => {
  return `import { Injectable, OnDestroy } from '@angular/core';
import { init } from 'commandbar';

init('${organizationUid}');

@Injectable({
  providedIn: 'root',
})
export class CommandbarService implements OnDestroy {
  constructor() {
    const loggedInUserId = '12345'; // example
    window.CommandBar.boot(loggedInUserId);
  }

  ngOnDestroy(): void {
    window.CommandBar.shutdown();
  }
}`;
};

const remixSnippet = (organizationUid: string) => {
  return `// entry.client.tsx
  import { init } from 'commandbar';
  
  init('${organizationUid}');
  
  // root.tsx
  useEffect(() => {
      const loggedInUserId = '12345'; // example
      window.CommandBar.boot(loggedInUserId).then(() => {
        // ...
      });
  
      return () => {
        window.CommandBar.shutdown();
      }
    }, []);`;
};

const svelteSnippet = (organizationUid: string) => {
  return `// main.ts
  import { init } from 'commandbar';
  init('${organizationUid}');
  
  // App.svelte
  onMount(() => {
    const loggedInUserId = '12345'; // example
    window.CommandBar.boot(loggedInUserId).then(() => {
      // ...
    });
  
    return window.CommandBar.shutdown;
  });`;
};

const vueSnippet = (organizationUid: string) => {
  return `// App.vue
  <script setup lang="ts">
    import { init } from 'commandbar';
    init('${organizationUid}');
    
    const loggedInUserId = '12345'; // example
    window.CommandBar.boot(loggedInUserId).then(() => {
      // ...
    })
  </script>`;
};

const railsSnippet = (organizationUid: string) => {
  return `<script>
  var o="${organizationUid}",n="https://api.commandbar.com",a=void 0,t=window;function r(o,n){void 0===n&&(n=!1),"complete"!==document.readyState&&window.addEventListener("load",r.bind(null,o,n),{capture:!1,once:!0});var a=document.createElement("script");a.type="text/javascript",a.async=n,a.src=o,document.head.appendChild(a)}function e(){var e;if(void 0===t.CommandBar){delete t.__CommandBarBootstrap__;var c=Symbol.for("CommandBar::configuration"),d=Symbol.for("CommandBar::disposed"),i=Symbol.for("CommandBar::isProxy"),m=Symbol.for("CommandBar::queue"),u=Symbol.for("CommandBar::unwrap"),s=Symbol.for("CommandBar::eventSubscriptions"),l=[],p=localStorage.getItem("commandbar.lc");p&&p.includes("local")&&(n="http://localhost:8000",a=void 0);var f=Object.assign(((e={})[c]={uuid:o,api:n,cdn:a},e[d]=!1,e[i]=!0,e[m]=new Array,e[u]=function(){return f},e[s]=void 0,e),t.CommandBar),v=["addCommand","boot","addEventSubscriber","addRecordAction","setFormFactor"],b=f;Object.assign(f,{shareCallbacks:function(){return{}},shareContext:function(){return{}}}),t.CommandBar=new Proxy(f,{get:function(o,n){return n in b?f[n]:"then"!==n?v.includes(n)?function(){var o=Array.prototype.slice.call(arguments);return new Promise((function(a,t){o.unshift(n,a,t),f[m].push(o)}))}:function(){var o=Array.prototype.slice.call(arguments);o.unshift(n),f[m].push(o)}:void 0}}),null!==p&&l.push("lc=".concat(p)),l.push("version=2"),a&&l.push("cdn=".concat(encodeURIComponent(a))),r("".concat(n,"/latest/").concat(o,"?").concat(l.join("&")),!0)}}e();
  const loggedInUserId = '12345'; // example
  window.CommandBar.boot(loggedInUserId).then(() => {
    // other CommandBar code
  })

  document.addEventListener ("turbo:before-render", (turboEvent) => {
    turboEvent.detail.newBody.addEventListener ("keydown", (keyEvent) => {
      if (keyEvent.metaKey && keyEvent.key === "k") { 
        if (window.CommandBar.isOpen()) {
          window.CommandBar.close()
        } else {
          window.CommandBar.open()
        }
      }
    }, true);
  })
</script>

<body>
<%= yield %>
<div id="commandbar-mount" data-turbo-permanent />
</body>`;
};

export const codeFrameworkKeys = [
  'vanillajs',
  'react',
  'nextjs',
  'angular',
  'remix',
  'svelte',
  'vue',
  'rubyOnRails',
] as const;
type TCodeFrameworkKeyTuple = typeof codeFrameworkKeys;
export type TCodeFrameworkKey = TCodeFrameworkKeyTuple[number];

export const noCodeFrameworkKeys = ['webflow', 'googleTagManager', 'wordpress', 'squarespace', 'wix'] as const;
type TNoCodeFrameworkKeyTuple = typeof noCodeFrameworkKeys;
export type TNoCodeFrameworkKey = TNoCodeFrameworkKeyTuple[number];

export type TFrameworkKey = TNoCodeFrameworkKey | TCodeFrameworkKey;

interface IFrameworkInstructions {
  organizationUid: IOrganizationType['id'];
  frameworkKey: TFrameworkKey;
}

export const FrameworkInstructions = ({ organizationUid = 'MY_ORG_ID', frameworkKey }: IFrameworkInstructions) => {
  const { triggerKey } = useOS();
  const org = organizationUid ? organizationUid + '' : '';
  const frameworks: {
    [key in TFrameworkKey]: JSX.Element;
  } = {
    vanillajs: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install & Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Copy and paste the snippet below into your <S.InlineCode>{'<head>'}</S.InlineCode> tag on every page
                where you want the CommandBar to appear:
              </S.Paragraph>
              <Code content={vanillaJSSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you're ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
              <S.Paragraph>
                Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
                <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode>{' '}
                and press <S.InlineCode>return</S.InlineCode>.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
      </S.InstructionsContainer>
    ),
    webflow: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Add CommandBar to Webflow</S.StepTitle>
            <S.SubList>
              <S.SubListItem>
                Head to{' '}
                <S.Anchor href="https://webflow.com/dashboard" target="_blank" rel="noreferrer">
                  Webflow ↗
                </S.Anchor>{' '}
                and select the site you want to add CommandBar to
              </S.SubListItem>
              <S.SubListItem>
                Navigate to Settings and click on the <b>Custom Code</b> tab.
              </S.SubListItem>
              <S.SubListItem>
                In the <b>Header Code</b> section, copy and paste the following code snippet:
                <S.SubStepBody>
                  <Code key="block-snippet" content={noCodeSnippet(org)} />
                </S.SubStepBody>
              </S.SubListItem>
              <S.SubListItem>
                Click <b>Save</b> to save your changes.
              </S.SubListItem>
            </S.SubList>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor on your site</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode> and
          press <S.InlineCode>return</S.InlineCode>.
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    googleTagManager: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>
              Log in to your Google Tag Manager account and select the container for the website where you want to add
              CommandBar.
            </S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>In the container, click on the "Tags" section.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Click on the "New" button to create a new tag.</S.StepTitle>
            <S.StepBody>
              <FrameworkImage
                width="70%"
                src="https://staticassets.commandbar.com/docs/frameworks/gtm-new-tag.png"
                alt="New tag button in Google Tag Manager"
              />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>In the "Tag Configuration" section, navigate to “Edit” action.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Select the "Custom HTML" tag type.</S.StepTitle>
            <S.StepBody>
              <FrameworkImage
                width="70%"
                src="https://staticassets.commandbar.com/docs/frameworks/gtm-custom-html.png"
                alt="Custom HTML option in Google Tag Manager"
              />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>In the HTML field, paste the following code:</S.StepTitle>
            <S.StepBody>
              <Code key="block-snippet" content={noCodeSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>
              In the "Triggering" section, select the trigger that corresponds to the pages on your website where you
              want CommandBar to be installed.
            </S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Save the changes by clicking the "Save" button and “Publish” your changes.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor on your site</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
              <S.Paragraph>
                Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
                <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode>{' '}
                and press <S.InlineCode>return</S.InlineCode>.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
      </S.InstructionsContainer>
    ),
    wordpress: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Log in to your WordPress admin dashboard.</S.StepTitle>
            <S.StepBody></S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Go to the "Plugins" section and click on the "Add New" button.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>
              Search for a plugin that allows you to add custom JavaScript code to your site, such as "Header and Footer
              Scripts" or "Insert Headers and Footers".
            </S.StepTitle>
            <S.StepBody>
              <FrameworkImage
                width="70%"
                src="https://staticassets.commandbar.com/docs/frameworks/wordpress-plugins.png"
                alt="Example code-related plugins in WordPress"
              ></FrameworkImage>
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Install and activate the plugin.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>
              Go to the plugin's settings page, typically under the "Settings" or "Tools" section.
            </S.StepTitle>
            <S.StepBody>
              <FrameworkImage
                width="70%"
                src="https://staticassets.commandbar.com/docs/frameworks/wordpress-header.png"
                alt="Example code-related plugins in WordPress"
              ></FrameworkImage>
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>
              In the plugin's settings page, paste the following code in the designated field, ideally in a "Header
              Code" section:
            </S.StepTitle>
            <S.StepBody>
              <Code key="block-snippet" content={noCodeSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Save the changes by clicking the "Save" button.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor on your site</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
              <S.Paragraph>
                Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
                <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode>{' '}
                and press <S.InlineCode>return</S.InlineCode>.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
      </S.InstructionsContainer>
    ),
    squarespace: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>
              Log in to your Square Space account and navigate to the page where you want to add custom code.
            </S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Click the "Edit" button to enter editing mode.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>From the left-hand menu, click into "Settings" and then click into "Advanced"</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Then select “Code Injection”, and paste in the following code:</S.StepTitle>
            <S.StepBody>
              <Code key="block-snippet" content={noCodeSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Save the changes by clicking the "Save" button.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor on your site</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
              <S.Paragraph>
                Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
                <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode>{' '}
                and press <S.InlineCode>return</S.InlineCode>.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
      </S.InstructionsContainer>
    ),
    wix: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Log in to your Wix account and go to your site editor.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>
              In the site editor, click on the "Add" button and select "HTML Code" from the options.
            </S.StepTitle>
            <S.StepBody>
              <S.Paragraph>A window should pop up. Paste the following code:</S.Paragraph>
              <Code key="block-snippet" content={noCodeSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Change the "HTML Settings" to "Load code on each new page".</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Click on the "Apply" button to save the changes.</S.StepTitle>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor on your site</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
              <S.Paragraph>
                <ChromeExtensionLink />
              </S.Paragraph>
              <S.Paragraph>
                Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
                <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode>{' '}
                and press <S.InlineCode>return</S.InlineCode>.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
      </S.InstructionsContainer>
    ),
    react: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install the CommandBar package</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Install <S.InlineCode>commandbar</S.InlineCode> via NPM or Yarn:
              </S.Paragraph>
              <InstallPackageCode />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Add this as close to your app entry point as possible and ensure that it only runs once:
              </S.Paragraph>
              <Code key="block-snippet" content={reactSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.{' '}
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          <ChromeExtensionLink />
        </S.Paragraph>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for the <S.InlineCode>Open Editor</S.InlineCode>{' '}
          command and press <S.InlineCode>return</S.InlineCode>
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    nextjs: (
      <S.InstructionsContainer>
        <S.Paragraph>
          <InstallPackageCode />
        </S.Paragraph>
        <S.Paragraph>
          Next.js will first render your component server side where Window is not available. Next.js usually recommends
          putting code that depends on the Window inside a useEffect. However, because React 18 in Strict Mode can run
          useEffect callbacks multiple times in dev mode, we still want to init outside of the component. Adding a
          window check will make sure that <S.InlineCode>init</S.InlineCode>is only run on the client.
        </S.Paragraph>
        <S.Paragraph>
          See{' '}
          <a
            href="https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects"
            target="_blank"
            rel="noreferrer"
          >
            Detecting unexpected side effects
          </a>{' '}
          for more information.
        </S.Paragraph>
        <Code key="block-snippet" content={nextSnippet(org)} />
        <S.Paragraph>
          <S.StepTitle>Open the editor in your app</S.StepTitle>
          <S.Paragraph>
            Next you can access the editor in your app with our Chrome extension. There you can preview your work, make
            changes, and publish whenever you’re ready.{' '}
          </S.Paragraph>
          <S.Paragraph>
            <ChromeExtensionLink />
          </S.Paragraph>
          <S.Paragraph>
            Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
            <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode> and
            press <S.InlineCode>return</S.InlineCode>.
          </S.Paragraph>
        </S.Paragraph>
        <S.Paragraph>
          See{' '}
          <a href="https://github.com/tryfoobar/examples" target="_blank" rel="noreferrer">
            our repo of example projects
          </a>{' '}
          for more info on how to implement CommandBar with different frameworks.
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    angular: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install the CommandBar package</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Install <S.InlineCode>commandbar</S.InlineCode> via NPM or Yarn:
              </S.Paragraph>
              <InstallPackageCode />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                A common way to add CommandBar to an Angular application is to create a service for it. All of the logic
                for adding context and callbacks can be isolated to the service.
              </S.Paragraph>
              <S.Paragraph>
                Add this as close to your app entry point as possible and ensure that it only runs once:
              </S.Paragraph>
              <Code key="block-snippet" language="typescript" content={angularSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.{' '}
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          <ChromeExtensionLink />
        </S.Paragraph>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for the <S.InlineCode>Open Editor</S.InlineCode>{' '}
          command and press <S.InlineCode>return</S.InlineCode>
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    remix: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install the CommandBar package</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Install <S.InlineCode>commandbar</S.InlineCode> via NPM or Yarn:
              </S.Paragraph>
              <InstallPackageCode />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Add this as close to your app entry point as possible and ensure that it only runs once:
              </S.Paragraph>
              <Code key="block-snippet" content={remixSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.{' '}
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          <ChromeExtensionLink />
        </S.Paragraph>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for the <S.InlineCode>Open Editor</S.InlineCode>{' '}
          command and press <S.InlineCode>return</S.InlineCode>
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    svelte: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install the CommandBar package</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Install <S.InlineCode>commandbar</S.InlineCode> via NPM or Yarn:
              </S.Paragraph>
              <InstallPackageCode />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Add this as close to your app entry point as possible and ensure that it only runs once:
              </S.Paragraph>
              <Code key="block-snippet" content={svelteSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.{' '}
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          <ChromeExtensionLink />
        </S.Paragraph>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for the <S.InlineCode>Open Editor</S.InlineCode>{' '}
          command and press <S.InlineCode>return</S.InlineCode>
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    vue: (
      <S.InstructionsContainer>
        <S.List>
          <S.ListItem>
            <S.StepTitle>Install the CommandBar package</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Install <S.InlineCode>commandbar</S.InlineCode> via NPM or Yarn:
              </S.Paragraph>
              <InstallPackageCode />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Boot CommandBar</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Add this as close to your app entry point as possible and ensure that it only runs once:
              </S.Paragraph>
              <Code key="block-snippet" content={vueSnippet(org)} />
            </S.StepBody>
          </S.ListItem>
          <S.ListItem>
            <S.StepTitle>Open the editor in your app</S.StepTitle>
            <S.StepBody>
              <S.Paragraph>
                Next you can access the editor in your app with our Chrome extension. There you can preview your work,
                make changes, and publish whenever you’re ready.
              </S.Paragraph>
            </S.StepBody>
          </S.ListItem>
        </S.List>
        <S.Paragraph>
          <ChromeExtensionLink />
        </S.Paragraph>
        <S.Paragraph>
          Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
          <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for the <S.InlineCode>Open Editor</S.InlineCode>{' '}
          command and press <S.InlineCode>return</S.InlineCode>
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
    rubyOnRails: (
      <S.InstructionsContainer>
        <S.Paragraph>
          To persist CommandBar between page loads, add
          <S.InlineCode>{'<div id="commandbar-mount" data-turbo-permanent />'}</S.InlineCode> to document.body.
          CommandBar will mount to this element instead of the body so it will not be removed by Turbo when navigating
          to a new page.
        </S.Paragraph>
        <S.Paragraph>
          All event handlers are removed from document.body when it's cloned by Turbo. A cmd + k handler can be re-added
          to the cloned body using the <S.InlineCode>turbo:before-render</S.InlineCode> event.
        </S.Paragraph>
        <Code key="block-snippet" content={railsSnippet(org)} />
        <S.Paragraph>
          <S.StepTitle>Open the editor in your app</S.StepTitle>
          <S.Paragraph>
            Next you can access the editor in your app with our Chrome extension. There you can preview your work, make
            changes, and publish whenever you’re ready.{' '}
          </S.Paragraph>
          <S.Paragraph>
            <ChromeExtensionLink />
          </S.Paragraph>
          <S.Paragraph>
            Not using Chrome? First, open the bar in your app with the keyboard shortcut{' '}
            <S.InlineCode>{triggerKey}K</S.InlineCode>. Next, search for <S.InlineCode>Open Editor</S.InlineCode> and
            press <S.InlineCode>return</S.InlineCode>.
          </S.Paragraph>
        </S.Paragraph>
        <S.Paragraph>
          See{' '}
          <a href="https://github.com/tryfoobar/examples" target="_blank" rel="noreferrer">
            our repo of example projects
          </a>{' '}
          for more info on how to implement CommandBar with different frameworks.
        </S.Paragraph>
      </S.InstructionsContainer>
    ),
  };

  return frameworks[frameworkKey];
};
