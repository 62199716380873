import { cva } from 'class-variance-authority';

export const avatarContainerStyles = cva(['relative', 'flex shrink-0', 'overflow-hidden'], {
  variants: {
    size: {
      sm: ['h-xl', 'w-xl'],
      md: ['h-xxl', 'w-xxl'],
      lg: ['h-10', 'w-10'],
    },
  },
});

export const avatarStyles = cva([], {
  variants: {
    size: {
      sm: ['rounded-avatarSm', 'text-xs'],
      md: ['rounded-avatarMd', 'text-sm'],
      lg: ['rounded-avatarLg', 'text-base'],
    },
  },
});
