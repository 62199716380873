import { SortableList } from '@commandbar/design-system/components/antd';
import React from 'react';
import styled from '@emotion/styled';

import { NudgeContentForm } from './NudgeContentForm';
import { INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import Sender from 'editor/src/management/Sender';
import { AddNudgeButton } from './AddNudgeButton';
import { useReportEvent } from 'editor/src/hooks/useEventReporting';

const NudgeContentFormContainer = styled.div`
  margin-bottom: 16px;
`;

type NudgeDetailContentProps = {
  dirty: INudgeType;
  isDirty: boolean;
  activeNudgeFormIndex?: number;
  onActiveNudgeFormChange: (index: number | undefined) => void;
  onNudgeChange: (nudge: INudgeType) => void;
  onStepChange: (index: number) => (step: INudgeStepType) => void;
};

export const NudgeDetailContent = ({
  dirty,
  isDirty,
  activeNudgeFormIndex,
  onActiveNudgeFormChange,
  onNudgeChange,
  onStepChange,
}: NudgeDetailContentProps) => {
  const { reportEvent } = useReportEvent();

  const onAddStep = (formFactorType: INudgeStepType['form_factor']['type']) => {
    const steps: INudgeStepType[] = dirty.steps.map((step) => {
      const buttons = step.content.filter((block) => block.type === 'button');

      if (!!buttons.length) {
        return step;
      }

      return {
        ...step,
        content: [...step.content.filter((block) => block.type !== 'button'), ...buttons],
      };
    });

    const formFactor = ((): INudgeStepType['form_factor'] => {
      switch (formFactorType) {
        case 'modal': {
          return {
            type: formFactorType,
          };
        }
        case 'popover': {
          return {
            type: formFactorType,
            position: 'top-right',
          };
        }
        case 'pin': {
          return {
            type: formFactorType,
            anchor: '',
            advance_trigger: '',
            is_open_by_default: true,
            offset: {
              x: '0px',
              y: '0px',
            },
          };
        }
        case 'banner': {
          return {
            type: formFactorType,
            position: 'top',
            placement: 'default',
            layout: 'classic',
          };
        }
        case 'tooltip': {
          return {
            type: formFactorType,
            anchor: '',
            show_on: 'hover',
            marker: {
              type: 'beacon',
              positioning: {
                position: 'right',
                offset: {
                  x: '0px',
                  y: '0px',
                },
              },
            },
          };
        }
      }
    })();

    const newStep: INudgeStepType = {
      id: -1 * new Date().getTime(),
      title: '',
      content: [
        {
          type: 'markdown',
          sort_key: 0,
          meta: { value: '' },
        },
      ],
      is_live: dirty.is_live,
      form_factor: formFactor,
      preview_url: null,
    };

    reportEvent('nudge item created', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        id: dirty.id,
        form_factor: newStep.form_factor.type,
        blocks: newStep.content.filter((b) => b.type),
        action: newStep.content.filter((a) => a.meta),
      },
    });

    const updatedNudge = { ...dirty, steps: [...steps, newStep] };
    onNudgeChange(updatedNudge);
    onActiveNudgeFormChange(updatedNudge.steps.length - 1);
    Sender.showNudgeStepMock(updatedNudge, updatedNudge.steps.length - 1);
  };

  const onSortStep = (oldIndexOfMovedObj: number, newIndexOfMovedObj: number) => {
    const newSteps = [...dirty.steps];
    const removed = newSteps.splice(oldIndexOfMovedObj, 1)[0];

    newSteps.splice(newIndexOfMovedObj, 0, removed);

    onNudgeChange({
      ...dirty,
      steps: newSteps,
    });

    /**
     * Update "active" (expanded) nudge step if necessary
     */
    if (oldIndexOfMovedObj === activeNudgeFormIndex) {
      onActiveNudgeFormChange(newIndexOfMovedObj);
    } else if (newIndexOfMovedObj === activeNudgeFormIndex) {
      onActiveNudgeFormChange(oldIndexOfMovedObj);
    }
  };

  const onDeleteStep = (id: number, stepIndex: number) => {
    if (activeNudgeFormIndex === stepIndex) {
      Sender.closeNudgeMocks();
      onActiveNudgeFormChange(undefined);
    }

    const steps = dirty.steps.filter((step) => step.id !== id);
    const deleted = dirty.steps[stepIndex];
    onNudgeChange({ ...dirty, steps });
    reportEvent('nudge item deleted', {
      segment: true,
      highlight: true,
      slack: true,
      eventProps: {
        id: dirty.id,
        form_factor: deleted.form_factor.type,
        blocks: deleted.content.filter((b) => b.type),
        action: deleted.content.filter((a) => a.meta),
      },
    });
  };

  return (
    <>
      <SortableList
        nodes={dirty.steps.map((step, index) => (
          <NudgeContentFormContainer key={`${dirty.id}-${step.id}-NudgeStep-${index}`}>
            <NudgeContentForm
              nudge={dirty}
              isDirty={isDirty}
              onAccordionClick={() => {
                if (activeNudgeFormIndex === index) {
                  /**
                   * Deactivates the active nudge form
                   */
                  Sender.closeNudgeMocks();
                  onActiveNudgeFormChange(undefined);
                } else {
                  onActiveNudgeFormChange(index);
                }
              }}
              expanded={index === activeNudgeFormIndex}
              index={index}
              onStepChange={onStepChange}
              onDelete={dirty.steps.length <= 1 ? undefined : onDeleteStep}
            />
          </NudgeContentFormContainer>
        ))}
        onSort={onSortStep}
        useDragHandle
      />
      <AddNudgeButton onClick={(type) => onAddStep(type)} />
    </>
  );
};
