import * as t from 'io-ts';

import { createObject, deleteObject, listObject, readObject, update } from './generics';

export const SkinV = t.intersection(
  [
    t.type({
      id: t.string,
      slug: t.string,
      name: t.string,
      default: t.boolean,
      skin: t.any,
      organization: t.string,
    }),
    t.partial({
      logo: t.union([t.string, t.null]),
      chat_avatar: t.union([t.string, t.null]),
      frozen: t.boolean,
    }),
  ],
  'Skin',
);

export const SkinPatchV = t.partial({
  id: t.string,
  slug: t.string,
  name: t.string,
  default: t.boolean,
  skin: t.any,
  organization: t.string,
  logo: t.union([t.string, t.null]),
  chat_avatar: t.union([t.string, t.null]),
});

export const SkinPatchMetadataV = t.partial({
  name: t.string,
  slug: t.string,
});

export class Skin {
  public static list = listObject(SkinV, 'skins');
  public static read = readObject(SkinV, 'skins');
  public static update = (skin: t.TypeOf<typeof SkinPatchV>) => update(SkinV, SkinPatchV, `skins/${skin.slug}`)(skin);
  public static updateMetadata = (slug: string, metadata: t.TypeOf<typeof SkinPatchMetadataV>) =>
    update(SkinV, SkinPatchMetadataV, `skins/${slug}`)(metadata);
  public static create = createObject(SkinV, SkinPatchV, 'skins');
  public static delete = deleteObject(SkinV, 'skins');
}
