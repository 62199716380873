import * as t from 'io-ts';
import { createObject, decodeThrowing, deleteObject, listObject, readObject, updateObject } from './generics';

import { IWorkflow } from './types';

export const WorkflowV = t.type(
  {
    id: t.number,
    archived: t.boolean,
    is_live: t.boolean,
    title: t.string,
    prompt: t.string,
    trigger: t.any,
    allow_docs_search: t.boolean,
    automatic_handoff: t.boolean,
    exit_on_fallback: t.boolean,
  },
  'workflow',
);

export class Workflow {
  public static decode = (data: any) => {
    const workflow: IWorkflow = { ...data };

    return decodeThrowing(WorkflowV, workflow);
  };

  public static create = createObject(WorkflowV, WorkflowV, 'workflows');

  public static update = updateObject(WorkflowV, WorkflowV, 'workflows');

  public static delete = deleteObject(WorkflowV, 'workflows');
  public static list = listObject(WorkflowV, 'workflows');
  public static read = readObject(WorkflowV, 'workflows');
}
