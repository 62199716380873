import { CmdCard, CmdEmpty, CmdTypography } from '@commandbar/design-system/cmd';
import PercentChangeTag from './PercentChangeTag';
import { formatMetric } from '../utils';

type StatCardProps = {
  title: string;
  subtitle: string;
  format: 'number' | 'percentage' | 'raw';
  metric: number | string | null | undefined;
  rate?: number | null;
  isLoading?: boolean;
};

const StatCard = ({ title, subtitle, format, metric, rate, isLoading = false }: StatCardProps) => {
  return (
    <CmdCard style={{ flex: '1', minHeight: '140px', minWidth: '365px' }}>
      <CmdCard.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          gap: '32px',
        }}
      >
        <div>
          <CmdTypography.Body fontWeight="semi-bold">{title}</CmdTypography.Body>
          <CmdTypography.HelpText variant="secondary" fontWeight="medium">
            {subtitle}
          </CmdTypography.HelpText>
        </div>

        {isLoading ? (
          <div
            style={{
              maxWidth: '187px',
            }}
          >
            <CmdEmpty />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '12px',
            }}
          >
            <div
              style={{
                lineHeight: '24px',
              }}
            >
              <CmdTypography.H1 fontWeight="semi-bold">{formatMetric(metric, format)}</CmdTypography.H1>
            </div>
            {rate !== undefined && rate !== null && <PercentChangeTag rate={rate} />}
          </div>
        )}
      </CmdCard.Content>
    </CmdCard>
  );
};

export default StatCard;
