import {
  ExpressionCondition,
  ICondition,
  IMultiValueCondition,
  IRule,
  ISingleValueCondition,
  isInteractionCondition,
  isMultiValueRule,
} from '../middleware/helpers/rules';
import type { IEndUserAnalytics, IRuleExpression, IUserContext } from '../middleware/types';
import type { ChecklistInteractions, IUserRemoteProperties, NudgeInteractions } from '@cb/types/entities/endUser';
import _get from 'lodash/get';
import { checkSelector } from '../util/dom';
import { getBrowser, getDeviceType, getOperatingSystem } from '../util/operatingSystem';
import { isStudioPreview } from '../util/location';
import { Metadata } from './CommandBarClientSDK';

export const walkConditions = (expr: IRuleExpression, callback: (condition: ExpressionCondition) => void) => {
  switch (expr.type) {
    case 'AND':
    case 'OR':
      for (const e of expr.exprs) {
        walkConditions(e, callback);
      }
      break;
    case 'CONDITION':
      callback(expr.condition);
      break;
  }
};

export const trimQuotes = (str: string) => str.replace(/(^["']|["']$)/gm, '');

export const parsePossibleNumericValue = (value?: string) => {
  if (value === 'null') {
    return null;
  }
  return isNaN(Number(value)) ? `${parseStringValue(value)}` : Number(value);
};

export const parseStringValue = (value?: string) => {
  const trimmedString = `${value?.toString()}`.trim();

  return trimQuotes(trimmedString);
};

export const sanitizeUrl = (location: Location) => {
  const params = new URLSearchParams(location.search);

  // Delete CommandBar params
  params.delete('cb-eid');

  let newURL = location.pathname;

  const paramString = params.toString();

  if (paramString) {
    newURL += '?' + paramString;
  }

  newURL += location.hash;

  return newURL;
};

export const getLeftOperand = (
  rule: IRule,
  context: IUserContext,
  location: Location,
  analytics: IEndUserAnalytics | undefined | null,
  remoteProperties: IUserRemoteProperties | undefined,
  interactions: { checklists?: ChecklistInteractions; nudges?: NudgeInteractions },
  eventProperties: Metadata,
) => {
  switch (rule.type) {
    case 'executions':
    case 'shortcuts':
    case 'last_seen':
    case 'first_seen':
    case 'sessions':
    case 'opens':
    case 'deadends':
      return getAnalyticsLeftOperand(rule, analytics);
    case 'url':
      return sanitizeUrl(location);
    case 'hostname':
      return location.hostname;
    case 'context':
      if (!!rule.field) {
        return _get(context, rule.field);
      }
      break;
    case 'event_property':
      if (!!rule.field) {
        return _get(eventProperties, rule.field);
      }
      break;
    case 'heap':
      if (!!rule.field) {
        return _get(remoteProperties, 'heap.' + rule.field);
      }
      break;
    case 'hubspot':
      if (!!rule.field) {
        return _get(remoteProperties, 'hubspot.' + rule.field);
      }
      break;
    case 'amplitude': {
      if (rule.field) {
        return _get(remoteProperties, 'amplitude.' + rule.field);
      }
      break;
    }
    case 'device_type':
      return getDeviceType();
    case 'browser':
      return getBrowser();
    case 'os':
      return getOperatingSystem();
    case 'language':
      return navigator.language;
    case 'questlist_interaction':
      if (!!interactions?.checklists) {
        const isViewed = !!interactions?.checklists[rule.questlist_id]?.isSeen;
        const isCompleted = !!interactions?.checklists[rule.questlist_id]?.isCompleted;
        const isDismissed = !!interactions?.checklists[rule.questlist_id]?.isSkipped;

        const states = [];
        if (isCompleted) states.push('completed');
        if (isDismissed) states.push('dismissed');
        if (isViewed) states.push('viewed');
        return states;
      }
      break;
    case 'nudge_interaction':
      if (!!interactions?.nudges) {
        const isViewed = !!interactions?.nudges[rule.nudge_id]?.nudgeSeen;
        const isCompleted = !!interactions?.nudges[rule.nudge_id]?.nudgeCompleted;
        const isDismissed = !!interactions?.nudges[rule.nudge_id]?.nudgeDismissed;

        const states = [];
        if (isCompleted) states.push('completed');
        if (isDismissed) states.push('dismissed');
        if (isViewed) states.push('viewed');
        return states;
      }
      break;
    case 'intent':
    case 'help_doc_interaction':
    case 'video_interaction':
    case 'survey_response':
      return true;
  }
};

const getOptionalNumbericOperand = (num: number | undefined) => {
  return !!num ? num : 0;
};

const getOptionalDateOperand = (date: string | undefined) => {
  if (!!date) {
    const one_day = 1000 * 60 * 60 * 24;
    const now = new Date().getTime();
    const d = new Date(date);
    const diff_ms = now - d.getTime();
    return Math.floor(diff_ms / one_day);
  }
  return 0;
};

const getAnalyticsLeftOperand = (rule: IRule, analytics: IEndUserAnalytics | undefined | null) => {
  if (!!analytics) {
    switch (rule.type) {
      case 'executions':
        return getOptionalNumbericOperand(analytics.num_command_executions);
      case 'shortcuts':
        return getOptionalNumbericOperand(analytics.num_shortcut_command_executions);
      case 'sessions':
        return getOptionalNumbericOperand(analytics.num_sessions);
      case 'opens':
        return getOptionalNumbericOperand(analytics.num_opens);
      case 'deadends':
        return getOptionalNumbericOperand(analytics.num_deadends);
      case 'last_seen':
        return getOptionalDateOperand(analytics.last_seen_at);
      case 'first_seen':
        return getOptionalDateOperand(analytics.first_seen_at);
    }
  }

  // When analytics is falsy, we return false for booleans and 0 for numeric conditions.
  return 0;
};

export const evaluateMultiValueRule = (
  context: IUserContext,
  location: Location,
  analytics: IEndUserAnalytics | undefined | null,
  remoteProperties: IUserRemoteProperties | undefined,
  interactions: { checklists?: ChecklistInteractions; nudges?: NudgeInteractions },
  rule: IMultiValueCondition,
): boolean => {
  const { operator, values } = rule;

  const leftOperand = getLeftOperand(rule, context, location, analytics, remoteProperties, interactions, {});

  const evaluateValue = (value: string): boolean => {
    /* navigator.language can return a value that could be for example English (en) or specifically English (en-US)
     * when an Org configures a rule to be `en` it is implied that it should match all english languages (en-US, en-GB, etc)
     * when a User configures their browser to be `en` it also implies that they are using all english languages
     *
     * So if one of the configured values includes a `-` and the leftOperand does not include `-` then we should check that the leftOperand starts with the value before the `-`
     * Or if the leftOperand includes a `-` then we should check that the value starts with the leftOperand before the `-`
     * otherwise compare the languages strictly
     */
    if (rule.type === 'language') {
      const [language, country] = value.split('-');
      const [leftLanguage, leftCountry] = (leftOperand as string).split('-');
      const isEqual = (a: string, b: string) => a === b || a === undefined || b === undefined;

      return isEqual(leftLanguage, language) && isEqual(leftCountry, country);
    }

    return value.toLowerCase() === leftOperand.toLowerCase();
  };

  switch (operator) {
    case 'includes':
      return values.some(evaluateValue);
    case 'doesNotInclude':
      return !values.some(evaluateValue);
  }
};

const evaluateRule = (
  context: IUserContext,
  location: Location,
  analytics: IEndUserAnalytics | undefined | null,
  remoteProperties: IUserRemoteProperties | undefined,
  interactions: { checklists?: ChecklistInteractions; nudges?: NudgeInteractions },
  eventProperties: Metadata,
  rule: ISingleValueCondition,
): boolean => {
  // FIXME: This is a hack for when we receive an anonymous user in decide
  // In this scenario, we serialize the condition to the frontend, but we don't have the user's remote properties, so we can't evaluate the rule.
  // We should fix this by forcing every user to call identify before decide, even anonymous ones.
  if (rule.type === 'user_property' || rule.type === 'ab_test') {
    return false;
  }

  const { operator, value } = rule;

  const leftOperand = getLeftOperand(
    rule,
    context,
    location,
    analytics,
    remoteProperties,
    interactions,
    eventProperties,
  );
  const valueAsStringOrNumber = parsePossibleNumericValue(value);

  switch (operator) {
    case 'is':
      // An interaction rule is only configurable with a single value, but an interaction can be in multiple states (eg. Viewed and Dismissed/Completed)
      if (isInteractionCondition(rule)) {
        return leftOperand?.includes(valueAsStringOrNumber);
      }
      return leftOperand === valueAsStringOrNumber || `${leftOperand}` === `${valueAsStringOrNumber}`;
    case 'isNot':
      if (isInteractionCondition(rule)) {
        return !leftOperand?.includes(valueAsStringOrNumber);
      }
      return leftOperand !== valueAsStringOrNumber && `${leftOperand}` !== `${valueAsStringOrNumber}`;
    case 'isTruthy':
      return !!leftOperand;
    case 'isTrue':
      return !!leftOperand;
    case 'isFalse':
      return !leftOperand;
    case 'isFalsy':
      return !leftOperand;
    case 'isDefined':
      return leftOperand !== undefined;
    case 'isNotDefined':
      return leftOperand === undefined;
    case 'startsWith':
      return leftOperand.startsWith(parseStringValue(value));
    case 'endsWith':
      return leftOperand.endsWith(parseStringValue(value));
    case 'includes':
      return leftOperand.includes(parseStringValue(value));
    case 'doesNotInclude':
      return !leftOperand.includes(parseStringValue(value));
    case 'matchesRegex':
      return new RegExp(parseStringValue(value)).test(leftOperand);
    case 'isGreaterThan':
      return Number(leftOperand) > Number(value);
    case 'isLessThan':
      return Number(leftOperand) < Number(value);
    case 'idOnPage':
      return document.getElementById(parseStringValue(value)) !== null;
    case 'classnameOnPage':
      return document.getElementsByClassName(parseStringValue(value)).length > 0;
    case 'selectorOnPage':
      return value ? checkSelector(value) : false;
    case 'idNotOnPage':
      return document.getElementById(parseStringValue(value)) === null;
    case 'classnameNotOnPage':
      return document.getElementsByClassName(parseStringValue(value)).length === 0;
    case 'selectorNotOnPage':
      return value ? !checkSelector(value) : false;
    case undefined:
      return true;
    default:
      return true;
  }
};

const evaluateRules = (
  rules: IRule[],
  context: IUserContext,
  location: Location,
  analytics: IEndUserAnalytics | undefined | null,
  remoteProperties: IUserRemoteProperties | undefined,
  interactions: { checklists?: ChecklistInteractions; nudges?: NudgeInteractions },
): { passed: boolean; failedRules?: ICondition[] } => {
  const failedRules = [];

  for (const rule of rules) {
    if (rule.type === 'always') {
      return { passed: true };
    }

    if (isMultiValueRule(rule)) {
      if (!evaluateMultiValueRule(context, location, analytics, remoteProperties, interactions, rule)) {
        failedRules.push(rule);
      }
    } else if (!evaluateRule(context, location, analytics, remoteProperties, interactions, {}, rule)) {
      failedRules.push(rule);
    }
  }

  if (failedRules.length > 0) {
    return { passed: false, failedRules: failedRules };
  } else {
    return { passed: true };
  }
};

const end_user_analytics_keys = ['executions', 'shortcuts', 'last_seen', 'first_seen', 'sessions', 'opens', 'deadends'];

export const evaluateRuleExpression = (
  expr: IRuleExpression,
  context: IUserContext,
  location: Location,
  analytics: IEndUserAnalytics | undefined | null,
  remoteProperties: IUserRemoteProperties,
  interactions: { checklists?: ChecklistInteractions; nudges?: NudgeInteractions },
  isRemoteDataAvailable: boolean,
  syncedHeapSegments: string[],
  syncedHubSpotLists: string[],
  syncedAmplitudeCohorts: string[],
  eventProperties: Metadata,
): { passed: boolean; userDefinedReason?: string } => {
  switch (expr.type) {
    case 'AND': {
      const results = [];
      for (const e of expr.exprs) {
        const result = evaluateRuleExpression(
          e,
          context,
          location,
          analytics,
          remoteProperties,
          interactions,
          isRemoteDataAvailable,
          syncedHeapSegments,
          syncedHubSpotLists,
          syncedAmplitudeCohorts,
          eventProperties,
        );
        if (!result.passed && !result.userDefinedReason) {
          // if failed, return early to avoid having to parse the other conditions
          // note: only do this if the failed condition doesn't have a userDefinedReason
          // in order to preserve the behavior outlined here:
          //  https://linear.app/commandbar/issue/ENG-969/hide-commands-if-any-of-their-false-availability-reasons-are-blan
          return {
            passed: false,
            userDefinedReason: undefined,
          };
        }

        results.push(result);
      }

      // if all conditions for which "passed" is false have a userDefinedReason, return the first one
      // see https://linear.app/commandbar/issue/ENG-969
      const failedResults = results.filter((r) => !r.passed);
      return {
        passed: results.every((r) => r.passed),
        userDefinedReason: failedResults.every((r) => r.userDefinedReason)
          ? failedResults[0]?.userDefinedReason
          : undefined,
      };
    }
    case 'OR': {
      const results = expr.exprs.map((e) =>
        evaluateRuleExpression(
          e,
          context,
          location,
          analytics,
          remoteProperties,
          interactions,
          isRemoteDataAvailable,
          syncedHeapSegments,
          syncedHubSpotLists,
          syncedAmplitudeCohorts,
          eventProperties,
        ),
      );

      // if all conditions for which "passed" is false have a userDefinedReason, return the first one
      // see https://linear.app/commandbar/issue/ENG-969
      const failedResults = results.filter((r) => !r.passed);
      const passed = results.some((r) => r.passed) || results.length === 0;
      return {
        passed,
        userDefinedReason:
          !passed && failedResults.every((r) => r.userDefinedReason) ? failedResults[0]?.userDefinedReason : undefined,
      };
    }
    case 'LITERAL':
      return { passed: expr.value };

    case 'CONDITION':
      if (expr.condition.type === 'named_rule') {
        throw new Error('Named rule should have been replaced by an expression by the backend');
      }

      if (end_user_analytics_keys.some((e) => e === expr.condition.type)) {
        if (isRemoteDataAvailable === false) {
          throw new Error(
            'Analytics-based rules are only available when HMAC is set or force_end_user_identity_verification is disabled AND org is not in silent mode.',
          );
        }
      }

      if ('heap' === expr.condition.type) {
        if (
          (isRemoteDataAvailable === false && !isStudioPreview) ||
          !syncedHeapSegments.includes(expr.condition.field || '')
        ) {
          throw new Error('Heap Segment used in condition is not synced with CommandBar.');
        }
      }

      if ('hubspot' === expr.condition.type) {
        if (
          (isRemoteDataAvailable === false && !isStudioPreview) ||
          !syncedHubSpotLists.includes(expr.condition.field || '')
        ) {
          throw new Error('HubSpot List used in condition is not synced with CommandBar.');
        }
      }

      if ('amplitude' === expr.condition.type) {
        if (
          (isRemoteDataAvailable === false && !isStudioPreview) ||
          !syncedAmplitudeCohorts.includes(expr.condition.field || '')
        ) {
          throw new Error('Amplitude Cohort used in condition is not synced with CommandBar.');
        }
      }

      if (isMultiValueRule(expr.condition)) {
        const passed = evaluateMultiValueRule(
          context,
          location,
          analytics,
          remoteProperties,
          interactions,
          expr.condition,
        );
        return { passed };
      }

      const passed = evaluateRule(
        context,
        location,
        analytics,
        remoteProperties,
        interactions,
        eventProperties,
        expr.condition,
      );
      return { passed, userDefinedReason: expr.condition.reason };
  }
};

export default evaluateRules;
