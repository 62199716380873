import { IProfileType, IUserType } from '@commandbar/internal/middleware/types';

export const hasRequiredRole = (user: IUserType | null, requiredRole: IProfileType['role'] | undefined) => {
  if (!requiredRole) return true;
  if (!user || !user.profile) {
    return false;
  }

  switch (requiredRole) {
    case 'admin':
      return user.profile.role === 'admin';
    case 'editor':
      return user.profile.role === 'admin' || user.profile.role === 'editor';
    case 'contributor':
      return user.profile.role === 'admin' || user.profile.role === 'editor' || user.profile.role === 'contributor';
    case 'viewer':
      return (
        user.profile.role === 'admin' ||
        user.profile.role === 'editor' ||
        user.profile.role === 'contributor' ||
        user.profile.role === 'viewer'
      );
    default:
      return false;
  }
};
