import { getSDK } from '@commandbar/internal/client/globals';
import { _instanceAttributes } from '@commandbar/internal/client/symbols';

const EDITOR_SUMMON_COMMANDS = ['sudo editor', 'open editor'];

export function isEditorBeingSummoned(text: string): boolean {
  // By default allow opening editor, unless canOpenEditor is set to false
  const dontAllowEditorOpen = getSDK()[_instanceAttributes]?.canOpenEditor === false;
  if (dontAllowEditorOpen) return false;
  return EDITOR_SUMMON_COMMANDS.includes(text.toLowerCase());
}

export function isPrimitive(a: any) {
  if (typeof a === 'string') return true;
  if (typeof a === 'number') return true;
  if (typeof a === 'boolean') return true;
  return false;
}

export function isHTML(str: string) {
  // https://stackoverflow.com/a/15458987
  return /<\/?[a-z][\s\S]*>/i.test(str);
}
