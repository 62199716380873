import { IEditorCommandType } from '@commandbar/internal/middleware/types';
import styled from '@emotion/styled';
import React from 'react';
import useLinkedCommand from './useLinkedCommand';

const CommandLink = styled.div<{ disabled?: boolean }>`
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '50%' : '100%')};
  }
`;

interface IProps {
  commands: IEditorCommandType[];
}

const ReferencedCommands = ({ commands }: IProps) => {
  const { openExistingLinkedCommand } = useLinkedCommand();
  return (
    <div>
      {commands.map((command) => {
        const isProgrammatic = command.source === 'programmatic';
        return (
          <div key={`command-${command.id}`}>
            <CommandLink
              onClick={(e: any) => {
                if (!isProgrammatic) {
                  openExistingLinkedCommand(command);
                }
                e.stopPropagation();
                e.preventDefault();
              }}
              disabled={isProgrammatic}
            >
              {command.text}{' '}
            </CommandLink>
          </div>
        );
      })}
    </div>
  );
};

export default ReferencedCommands;
