import { GenericOption } from '.';
import { ICommandType } from '@commandbar/internal/middleware/types';
import { interpolate } from 'shared/util/Interpolate';
import { CBStore } from 'shared/store/global-store';
import { isCommandOptionRecommended, validateOptionDisabled, getOptionUID } from '../../selectors';
import { OptionType, initOption, OptionCategoryMetadata } from './Option';

export interface CommandOption extends GenericOption<OptionType.Command> {
  command: ICommandType;
  isRecommended: boolean;
  isRecent: boolean;
}

export const initCommandOption = (_: CBStore, command: ICommandType, parent?: CommandOption): CommandOption => {
  const o = initOption(
    _,
    OptionType.Command,
    parent ? parent.label : interpolate(command.text, _, true, false),
    command.text,
    undefined,
    command.detail ?? (command.show_preview ? command.content : null),
  ) as CommandOption;

  o.command = {
    ...command,
    explanation: interpolate(command.explanation, _, true, false),
    heading: interpolate(command.heading, _, true, false),
  };
  o.isRecommended = isCommandOptionRecommended(_, o);

  if (parent) {
    o.optionDisabled = parent.optionDisabled;
  } else {
    validateOptionDisabled(_, o);
  }

  const optionCategory = _.spotlight.categories.find((c) => c.id === command.category);

  const category: OptionCategoryMetadata = {
    label: '',
    contextKey: '',
    limit: null,
    renderAs: optionCategory?.render_as || 'list',
  };
  o.category = category;

  const optionUID = getOptionUID(o);
  o.isRecent = !!(optionUID && optionCategory?.track_recents && _.endUserStore.derived.recentUIDs.includes(optionUID));

  return o;
};
