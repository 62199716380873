import * as t from 'io-ts';

export const ConfettiConfigV = t.partial({
  angle: t.number,
  spread: t.number,
  width: t.string,
  height: t.string,
  duration: t.number,
  dragFriction: t.number,
  stagger: t.number,
  startVelocity: t.number,
  elementCount: t.number,
  decay: t.number,
  colors: t.array(t.string),
  random: t.any,
});

export type IConfettiConfig = t.TypeOf<typeof ConfettiConfigV>;
