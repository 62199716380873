import type { CSSProperties } from 'react';
import { CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import CmdHorizontalBar from '@commandbar/design-system/cmd/chart/nps';
import { NPSChartData } from '@commandbar/internal/middleware/analytics/surveys';
import { StateLegendItem } from './StateLegendItem';
import { capitalize } from 'lodash';

type NPSChartProps = {
  title: string;
  subtitle: string;
  data: NPSChartData | undefined;
  isLoading: boolean;
  className?: string;
  style?: CSSProperties;
};

const nps_colors = {
  promoters: '#59A212',
  passives: '#FACC15',
  detractors: '#F87171',
};

const nps_tooltips = {
  promoters: 'Provided a score of 9 or 10.',
  passives: 'Provided a score of 7 or 8.',
  detractors: 'Provided a score between 0 to 6.',
};

const NPSChart: React.FC<NPSChartProps> = ({ title, subtitle, data, isLoading, className, style }) => {
  return (
    <CmdCard
      className={className}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        rowGap: '12px',
      }}
    >
      <CmdCard.Header>
        <CmdTypography.H3 variant="primary" fontWeight="medium">
          {title}
        </CmdTypography.H3>
        <CmdTypography.HelpText variant="secondary">{subtitle}</CmdTypography.HelpText>
      </CmdCard.Header>
      <CmdCard.Content>
        <CmdHorizontalBar data={data?.data} isLoading={isLoading} />
      </CmdCard.Content>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {data?.data.map((item) => (
          <StateLegendItem
            key={item.key}
            title={capitalize(item.key)}
            percentageChange={item?.percent_change}
            metric={item.percent}
            format="percentage"
            isLoading={isLoading}
            lineColor={nps_colors[item.key]}
            metricTooltip={`${item.metric.toLocaleString()} respondents`}
            tooltip={nps_tooltips[item.key]}
          />
        ))}
      </div>
    </CmdCard>
  );
};

export default NPSChart;
